import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    validateNoBackbone: function (app) {
        this.errors = [];

        if (!this.attributes.ImageData && !this.attributes.ImageMimeType && !this.attributes.ImageThumbData && !this.attributes.ImageThumbMimeType) {
            this.errors.push({ name: 'customProductSheetImageInsert_inputName', message: resources.X100323 });
        }

        if (this.attributes.Name == "") {
            this.errors.push({ name: 'customProductSheetImageInsert_inputName', message: resources.X100324 });
        }

        var self = this;
        for (var i = 0; i < app.entities.collections.customProductSheetImage.fullCollection.length; i++)
        {
            if (this.attributes.Name == app.entities.collections.customProductSheetImage.fullCollection.models[i].attributes.Name)
            {
                this.errors.push({ name: 'customProductSheetImageInsert_inputName', message: resources.X100325 });
            }
        };

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    },
    "sync": function (method, model, options) {
        if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customProductSheetImage/' + '' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customProductSheetImage/' + model.attributes.id + '?AuthUserId=' + config.authUserId();

        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customProductSheetImage/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    }
});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,

    state: {
        pageSize: 2,
    },

    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },

    options: {
        full: false
    },

    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customProductSheetImage/route02/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
             : a.attributes.id < b.attributes.id ? 1
             : 0;
    }
});

