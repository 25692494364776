var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customWebpageEditItem_empty"> <div id="customWebpageEditItem_panel" class="panel disabledWebpageContent"> <div class="content-wrapper" style="padding-top:3px; padding-bottom:0px" data-help="customWebpageEditItem"> <div id="settings-trigger"><i id="settings_trigger_icon" class="mdi mdi-arrow-expand-right" style="animation-duration:0s"></i></div> <div id="customWebpageEditItemTopbar"></div> <div class="row mt-3" style="margin-top: 50px !important">  <div id="customWebpageEditItemLeftContent" class="col-9 col-sm-9 col-md-9 col-xl-9 grid-margin stretch-card">  <div class="card" style="padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px"> <div class="card-body" style="padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px">  <form class="forms-sample"> <div id="editorRegion"></div> </form> </div> </div> </div> <div id="customWebpageEditItemRightContent" style="overflow-x: auto; overflow-y:auto" class="col-3 col-sm-3 col-md-3 col-xl-3">   <div class="card" style="overflow: auto"> <div class="card-body" style="padding-left:10px;padding-right:10px"> <h4 class="card-title">'+
((__t=( resources['X100385'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customWebpageEditItem_designMode"> <div style="display:none"> <div class="control-group customWebpageEditItem_inputName"> <div class="form-group"> <div class="row"> <div class="col-md-9"> <label for="customWebpageEditItem_inputName">'+
((__t=( resources['X100147'] ))==null?'':__t)+
'</label> <input class="form-control" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customWebpageEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> <div class="col-md-3" style="padding-top: 14px"> <button type="button" class="btn btn-secondary" id="customWebpageEditItem_btnSaveWebpage">'+
((__t=( resources['X101022'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> <div class="control-group customWebpageEditItem_inputInsertWebpageName"> <div class="form-group"> <label for="Name">'+
((__t=( resources['X100136'] ))==null?'':__t)+
'</label> <input class="form-control" id="customWebpageEditItem_inputInsertWebpageName" style=""> <span class="help-inline" style="color:red"></span> </div> </div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-primary btnWebpageEdit" id="customWebpageEditItem_btnInsertWebpage">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <label class="pull-right" style="padding-top:15px;display:none" id="customWebpageEditItem_labelDuration">'+
((__t=( resources['X100450'] ))==null?'':__t)+
'</label> </div> </div> </div>  <div style="display:none"> <div class="form-group">  <div class="panel-group" id="accordion"> <ul class="side-links" id="customWebpageEditItem_selectItem"></ul> </div> </div> <div class="control-group customWebpageEditItem_selectWebpage"> <div class="form-group"> <label id="customWebpageEditItem_labelselectWebpage" for="Name">'+
((__t=( resources['X100148'] ))==null?'':__t)+
'</label> <div class="row"> <div class="col-md-10"> <select class="form-control" type="text" id="customWebpageEditItem_selectWebpage"></select> </div> <div class="col-md-2"> <button type="button" class="btn btn-secondary btnWebpageEdit" id="customWebpageEditItem_btnCopyWebpage">'+
((__t=( resources['X100290'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> <div style="height:3px"></div> </div> <div class="row" style="display:none"> <div class="control-label col-md-4"> <button type="button" class="btn btn-primary" style="width:100%" id="customWebpageEditItem_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-4"> <button type="button" class="btn btn-secondary" style="width:100%; display:none" id="customWebpageEditItem_btnPublish">'+
((__t=( resources['X100443'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-2" style="display:none"> <div id="main-nav" class="navbar navbar-default hide-icons navbar-fixed-top" style="height:0px;min-height:0px;max-height:0px;z-index:1"> <div class="container" style="border-top-width: 0px; width:95%"> <div class="navbar-collapse collapse"> <ul class="nav navbar-nav navbar-right" id=""> <li class="dropdown" id="customWebpageEditItemListSmall"> <a id="" href="#" class="dropdown-toggle active" data-toggle="dropdown" style="cursor: pointer; color: red; height:20px"><i class="icon fa fa-file-text"></i>'+
((__t=( resources['X100532'] ))==null?'':__t)+
'</a>  </li> </ul> </div> </div> </div> </div> </div> <div class="control-label col-md-2"> </div> </form> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
