﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customFormModelList from './tpl/customFormModelList.html';
import View_CustomFormModelListItem from './customFormModelListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customFormModelList',
    childViewContainer: '#customFormModelList',
    childView: View_CustomFormModelListItem,
    getTemplate: function () {
        return tpl_customFormModelList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
