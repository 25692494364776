﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';


import * as Entities_Empty from '../../../../lib/entities/empty';

import tpl_printerSetting from './tpl/printerSetting.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#printerSetting_modal',
    errors: [],
    getTemplate: function () {
        return tpl_printerSetting({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'printerSetting_inputStatus' : '#printerSetting_inputStatus',
        'printerSetting_inputName' : '#printerSetting_inputName',
        'printerSetting_inputMarginLeft' : '#printerSetting_inputMarginLeft',
        'printerSetting_inputMarginRight' : '#printerSetting_inputMarginRight',
        'printerSetting_inputMarginTop' : '#printerSetting_inputMarginTop',
        'printerSetting_inputMarginBottom' : '#printerSetting_inputMarginBottom'


    },
    events: {
        "click #printerSetting_btn01": "onBtn01",
        "click #printerSetting_btn02": "onBtn02"
    },
    onRender: function () {
        var checked = this.model.attributes.Status == 1 ? true : false;
        this.ui.printerSetting_inputStatus.attr('checked', checked);
    },
    onBtn01: function () {
        var self = this;

        var callback = function() {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    buildCss : function () {
        var marginLeft = this.ui.printerSetting_inputMarginLeft.val();
        var marginRight = this.ui.printerSetting_inputMarginRight.val();
        var marginTop = this.ui.printerSetting_inputMarginTop.val();
        var marginBottom = this.ui.printerSetting_inputMarginBottom.val();

        if (marginLeft.length == 0) {
            marginLeft = "0";
        }
        if (marginRight.length == 0) {
            marginRight = "0";
        }
        if (marginTop.length == 0) {
            marginTop = "0";
        }
        if (marginBottom.length == 0) {
            marginBottom = "0";
        }

        this.model.attributes.Css = String.format(resources.X100924, marginTop, marginBottom, marginLeft, marginRight);
    },
    validate : function(callback) {
        var self = this;
        this.hideErrors();

        this.buildCss();

        var name = this.ui.printerSetting_inputName.val();
        this.model.attributes.Name = name;
        var foundModels = this.collection.where( { Name : name });

        this.ui.printerSetting_inputStatus.is(':checked') ? this.model.attributes.Status = 1 : this.model.attributes.Status = 0;

        if (foundModels.length > 1) {
            this.errors.push({ name: 'printerSetting_inputName', message: resources.X100331 });
        } else if ((foundModels.length == 1) && (foundModels[0].attributes.id != this.model.attributes.OldId)){
            this.errors.push({ name: 'printerSetting_inputName', message: resources.X100331 });
        }

        if (this.errors.length > 0) {
            this.showErrors(this.errors);
        } else { 
            callback();
        }
    }
});