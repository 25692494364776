'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customInfopageInsert from './tpl/customInfopageInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customInfopageInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customInfopageInsert_btnSubmit': '#customInfopageInsert_btnSubmit',
        'customInfopageInsert_inputName' : '#customInfopageInsert_inputName',
        'customInfopageInsert_labelName' : '#customInfopageInsert_labelName'
    },
    events: {
        'click @ui.customInfopageInsert_btnSubmit': 'btnSubmitClick'
    },
    onRender: function () {
        switch (this.model.attributes.DisplayType)  {
            case 0 :
                this.ui.customInfopageInsert_labelName.text(resources.X100135);
                break;
            case 1 :
                this.ui.customInfopageInsert_labelName.text(resources.X100452);
                break;
        }
    },
    btnSubmitClick: function () {
        this.hideErrors();
        var tmpModel = new this.app.entities.collections.customInfopage.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.DisplayType = this.model.attributes.DisplayType;
        tmpModel.attributes.Name = this.ui.customInfopageInsert_inputName.val();
        tmpModel.attributes.customInfopage_Header = resources.X100137;
        tmpModel.attributes.customInfopage_BodyIndex = resources.X100138;
        tmpModel.attributes.customInfopage_Footer = resources.X100139;
        tmpModel.attributes.customInfopage_Info = resources.X100140;
        tmpModel.attributes.customInfopage_Poster = resources.X100141;
        tmpModel.attributes.customInfopage_ImagePrint = resources.X100142;
        tmpModel.attributes.customInfopage_Schema = resources.X100143;
        tmpModel.attributes.customInfopage_SchemaBigscreen = resources.X100506;
        tmpModel.attributes.customInfopage_ProductSheet = resources.X101138;
        tmpModel.attributes.customInfopage_QR = resources.X101230;
        tmpModel.attributes.customInfopage_Location = resources.X101231;
        tmpModel.attributes.customInfopage_Calendar = resources.X101234;
        tmpModel.attributes.customInfopage_Form = resources.X100144;

        var ret = tmpModel.validateNoBackbone(this.app);
        if (ret == false) {
            var themeModel = this.app.entities.collections.paintStrap.fullCollection.where({ Default: true });

            if (themeModel.length > 0) {
                tmpModel.attributes.Theme = themeModel[0].attributes.id;
                tmpModel.attributes.CssFile = themeModel[0].attributes.CssFile;
                tmpModel.attributes.CssUpdate = false;

                var self = this;

                var dialogWaitingModel = new Entities_Empty.Model();
                dialogWaitingModel.attributes.Title = resources.X100134;
                dialogWaitingModel.attributes.Text = resources.X100309;
                dialogWaitingModel.attributes.Button = resources.X100279;

                var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
                var callback = function (options) {
                    this.stopListening(dialogWaiting, "dialog:okclicked", callback);
                }
                this.listenTo(dialogWaiting, "dialog:okclicked", callback);
                this.app.dialogRegion.show(dialogWaiting);

                var file = file = themeModel[0].attributes.CssFile;

                var callback = function() {
                    self.app.entities.collections.customInfopageItem.fetch({
                        silent : true,
                        wait: true,
                        cache: false,
                        success: function (mode, response) {
                            //alert("read ok");
                        },
                        error: function (mode, response) {
                            //alert("read not ok");
                        }
                    });
                }

                $.when($.get(file))
                    .done(function (response) {
                        tmpModel.attributes.CssUpdate = true;
                        tmpModel.attributes.CssData = response;

                        self.collection.fullCollection.create(tmpModel, {
                            success: function (response) {
                                dialogWaiting.triggerMethod('dialog:close');
                                self.collection.fullCollection.sort();
                                self.collection.getFirstPage({ fetch: false });

                                self.ui.customInfopageInsert_inputName.val('');

                                //reread webpage item
                                //self.app.entities.collections.customInfopageItem.fetch({
                                //    silent : true,
                                //    wait: true,
                                //    success: function (mode, response) {
                                //        alert("read ok");
                                //    },
                                //    error: function (mode, response) {
                                //        alert("read not ok");
                                //    }
                                //});

                                //alert("callback");
                                callback();

                                var i = 0;
                            }
                        });

                        self.ui.customInfopageInsert_inputName.val('');
                    });
            }
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    }
});