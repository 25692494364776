var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaModelEditItem_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-table" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100150'] ))==null?'':__t)+
' - '+
((__t=( model.Name ))==null?'':__t)+
'</h5> <div class="control-group customSchemaModelEditItem_inputName"> <div class="form-group"> <label for="customSchemaModelEditItem_inputName">'+
((__t=( resources['X100176'] ))==null?'':__t)+
'</label> <input class="form-control" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customSchemaModelEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div style="height:10px"></div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_FieldUsed">'+
((__t=( resources['X100175'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_FieldUsed"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_FieldBigscreen">'+
((__t=( resources['X100171'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_FieldBigscreen"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_FontSizeSchema">'+
((__t=( resources['X100178'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_FontSizeSchema"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_MarginLeftSchema">'+
((__t=( resources['X100880'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_MarginLeftSchema"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_MarginRightSchema">'+
((__t=( resources['X100881'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_MarginRightSchema"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_FontSizeSchemaBigscreen">'+
((__t=( resources['X100179'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_FontSizeSchemaBigscreen"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_MarginLeftSchemaBigscreen">'+
((__t=( resources['X100882'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_MarginLeftSchemaBigscreen"></select> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customSchemaModelEditItem_select_MarginRightSchemaBigscreen">'+
((__t=( resources['X100883'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customSchemaModelEditItem_select_MarginRightSchemaBigscreen"></select> </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field01"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field01">'+
((__t=( resources['X100158'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen01">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen01"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent01">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent01"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field01" value="'+
((__t=( model.Field01))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field02"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field02">'+
((__t=( resources['X100159'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen02">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen02"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent02">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent02"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field02" value="'+
((__t=( model.Field02))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field03"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field03">'+
((__t=( resources['X100160'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen03">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen03"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent03">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent03"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field03" value="'+
((__t=( model.Field03))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field04"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field04">'+
((__t=( resources['X100161'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen04">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen04"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent04">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent04"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field04" value="'+
((__t=( model.Field04))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field05"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field05">'+
((__t=( resources['X100162'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen05">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen05"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent05">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent05"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field05" value="'+
((__t=( model.Field05))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field06"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field06">'+
((__t=( resources['X100163'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen06">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen06"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent06">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent06"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field06" value="'+
((__t=( model.Field06))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field07"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field07">'+
((__t=( resources['X100164'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen07">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen07"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent07">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent07"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field07" value="'+
((__t=( model.Field07))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field08"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field08">'+
((__t=( resources['X100165'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen08">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen08"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent08">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent08"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field08" value="'+
((__t=( model.Field08))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field09"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field09">'+
((__t=( resources['X100166'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen09">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen09"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent09">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent09"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field09" value="'+
((__t=( model.Field09))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field10"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field10">'+
((__t=( resources['X100167'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen10">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen10"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent10">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent10"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field10" value="'+
((__t=( model.Field10))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field11"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field11">'+
((__t=( resources['X100168'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen11">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen11"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent11">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent11"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field11" value="'+
((__t=( model.Field11))==null?'':__t)+
'">  </div> </div> </div> <div class="form-group" id="customSchemaModelEditItem_div_Field12"> <div class="row"> <div class="control-label col-md-3"> <label for="customSchemaModelEditItem_input_Field12">'+
((__t=( resources['X100169'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-5" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldBigscreen12">'+
((__t=( resources['X100423'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldBigscreen12"> </div> <div class="control-label col-md-4" style="text-align:right"> <label for="customSchemaModelEditItem_input_FieldEvent12">'+
((__t=( resources['X100170'] ))==null?'':__t)+
'</label> <input type="checkbox" id="customSchemaModelEditItem_input_FieldEvent12"> </div> </div> <div class="row"> <div class="control-label col-md-12"> <input class="form-control" id="customSchemaModelEditItem_input_Field12" value="'+
((__t=( model.Field12))==null?'':__t)+
'">  </div> </div> </div> <br> <div class="control-group customSchemaModelEditItem_div_FieldSize"> <div class="form-group"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary" id="customSchemaModelEditItem_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <div style="height:20px"></div> <div class="control-group customSchemaModelEditItem_select_ConfigCustomSchemaModel"> <div class="form-group"> <label for="customSchemaModelEditItem_select_ConfigCustomSchemaModel">'+
((__t=( resources['X100152'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customSchemaModelEditItem_select_ConfigCustomSchemaModel" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <button type="button" class="btn btn-secondary" id="customSchemaModelEditItem_btnCopy">'+
((__t=( resources['X100154'] ))==null?'':__t)+
'</button> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
