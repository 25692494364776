﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customEventPush/-1?AuthUserId=' + config.authUserId();
        }
        else if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customEventPush/-1?EventId=' + model.attributes.EventId + '&AuthUserId=' + config.authUserId();
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    }
   
});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 6,
    },
    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },
    options: {
        full: false
    },
    "sync": function (method, model, options) {
        if ((method == 'read')  && (options.readlist)) {
            options.url = config.webApiUrl() + '/api/customEventPush/route01/-1?AuthUserId=' + config.authUserId() + '&EventId=' + options.EventId;
        }
        else if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customEventPush/route02/-1?EventId=' + options.tmpModel.EventId + '&UtcLastDate=' + options.tmpModel.UtcLastDate;
        }
        
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
             : a.attributes.id < b.attributes.id ? 1
             : 0;
    }
});



