﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import helper_infopage from '../../../helper/infopage';

import tpl_font from './tpl/font.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#font_modal',
    errors: [],
    getTemplate: function () {
        return tpl_font({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'module_ColorPickerSliders_BackgroundColor': '#module_ColorPickerSliders_BackgroundColor',
        'font_ColorPickerSliders_Font1_Color': '#font_ColorPickerSliders_Font1_Color',
        'font_ColorPickerSliders_Background1_Color': '#font_ColorPickerSliders_Background1_Color',
        'font_dropDown_Font1_Size': '#font_dropDown_Font1_Size',
        'font_dropDown_Font1': '#font_dropDown_Font1',
        'font_formgroup_Font1': '#font_formgroup_Font1',
        'font_dropDown_Font1_Style': '#font_dropDown_Font1_Style',
        'font_dropDown_Font1_TextAlign': '#font_dropDown_Font1_TextAlign',
        'font_input_margin1_Top': '#font_input_margin1_Top',
        'font_input_margin1_Bottom': '#font_input_margin1_Bottom',
        'font_input_margin1_Left': '#font_input_margin1_Left',
        'font_input_margin1_Right': '#font_input_margin1_Right',
        'font_input_margin1_Top': '#font_input_margin1_Top',
        'font_input_margin1_Bottom': '#font_input_margin1_Bottom',
        'font_input_margin1_Left': '#font_input_margin1_Left',
        'font_input_margin1_Right': '#font_input_margin1_Right',

        'font_ColorPickerSliders_Font2_Color': '#font_ColorPickerSliders_Font2_Color',
        'font_dropDown_Font2_Size_01': '#font_dropDown_Font2_Size_01',
        'font_dropDown_Font2_Size_02': '#font_dropDown_Font2_Size_02',
        'font_dropDown_Font2_Size_03': '#font_dropDown_Font2_Size_03',
        'font_dropDown_Font2_Size_04': '#font_dropDown_Font2_Size_04',
        'font_dropDown_Font2_Size_05': '#font_dropDown_Font2_Size_05',
        'font_dropDown_Font2': '#font_dropDown_Font2',
        'font_formgroup_Font2': '#font_formgroup_Font2',
        'font_dropDown_Font2_Style': '#font_dropDown_Font2_Style',
        'font_dropDown_Font2_TextAlign': '#font_dropDown_Font2_TextAlign',

        'font_btn01': '#font_btn01',
        'font_btn02': '#font_btn02',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right',
        'module_input_ZoomH': '#module_input_ZoomH',
        'module_input_ZoomHValue': '#module_input_ZoomHValue',
        'module_input_ZoomV': '#module_input_ZoomV',
        'module_input_ZoomVValue': '#module_input_ZoomVValue',
        'module_input_WakeLock': '#module_input_WakeLock'
    },
    events: {
        'colorpickerChange @ui.module_ColorPickerSliders_BackgroundColor': 'colorChange_moduleBackgroundColor',
        'colorpickerChange @ui.font_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.font_ColorPickerSliders_Background1_Color': 'colorChange_Background1_Color',
        'colorpickerChange @ui.font_ColorPickerSliders_Font2_Color': 'colorChange_Font2Color',
        'click @ui.font_btn01': 'onBtn01',
        'click @ui.font_btn02': 'onBtn02',
        'change @ui.module_input_WakeLock': 'module_input_WakeLockChange',
    },
    onRender: function () {
        var checked = this.model.attributes.WakeLock == 1 ? true : false;
        this.ui.module_input_WakeLock.attr('checked', checked);
    },
    onAttach() {
        var self = this;

        this.setupEnableType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupFontTextAlign();

        var startSlider1 = document.getElementById('module_input_ZoomH');
        nouislider.create(startSlider1, {
            start: parseInt(self.model.attributes.ZoomH),
            behaviour: 'snap',
            //connect: [true, false],
            range: {
                min: 1,
                max: 200
            },
            step: 1
        });
        startSlider1.noUiSlider.on('set', function (e) {
            self.model.attributes.ZoomH = e[0];
            self.ui.module_input_ZoomHValue.html(resources.X200330 + ': ' + parseInt(e));
        });

        var startSlider2 = document.getElementById('module_input_ZoomV');
        nouislider.create(startSlider2, {
            start: parseInt(self.model.attributes.ZoomV),
            behaviour: 'snap',
            //connect: [true, false],
            range: {
                min: 1,
                max: 200
            },
            step: 1
        });
        startSlider2.noUiSlider.on('set', function (e) {
            self.model.attributes.ZoomV = e[0];
            self.ui.module_input_ZoomVValue.html(resources.X200331 + ': ' + parseInt(e));
        });
    },
    setupEnableType: function () {
    },

    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.module_ColorPickerSliders_BackgroundColor.colorpicker({
            color: self.model.attributes.ModuleBackgroundColor,
            format: "rgb"
        });

        this.ui.font_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        this.ui.font_ColorPickerSliders_Background1_Color.colorpicker({
            color: self.model.attributes.Background1_Color,
            format: "rgb"
        });

        this.ui.font_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
        });
        this.ui.font_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        this.ui.font_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        this.ui.font_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font2: this.value });
            self.model.set({ Font2_Select: this.value });
        });
        this.ui.font_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);
    },
    setupFontSize: function () {
        var self = this;

        this.ui.font_dropDown_Font1_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.font_dropDown_Font1_Size.append(options);
        }

        this.ui.font_dropDown_Font2_Size_01.empty();

        var selected = '';

        for (var i = 1; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size_01 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.font_dropDown_Font2_Size_01.append(options);
        }

        this.ui.font_dropDown_Font2_Size_02.empty();

        var selected = '';

        for (var i = 1; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size_02 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.font_dropDown_Font2_Size_02.append(options);
        }

        this.ui.font_dropDown_Font2_Size_03.empty();

        var selected = '';

        for (var i = 1; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size_03 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.font_dropDown_Font2_Size_03.append(options);
        }

        this.ui.font_dropDown_Font2_Size_04.empty();

        var selected = '';

        for (var i = 1; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size_04 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.font_dropDown_Font2_Size_04.append(options);
        }

        this.ui.font_dropDown_Font2_Size_05.empty();

        var selected = '';

        for (var i = 1; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size_05 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.font_dropDown_Font2_Size_05.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.font_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.font_dropDown_Font1_Style.append(options);
        });

        self.ui.font_dropDown_Font2_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.font_dropDown_Font2_Style.append(options);
        });
    },
    setupFontTextAlign: function () {
        var self = this;

        self.ui.font_dropDown_Font1_TextAlign.empty();

        var selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.font_dropDown_Font1_TextAlign.append(options);
        });

        self.ui.font_dropDown_Font2_TextAlign.empty();

        var selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.font_dropDown_Font2_TextAlign.append(options);
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    colorChange_moduleBackgroundColor: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.ModuleBackgroundColor = e.value;
        }
        else {
            this.model.attributes.ModuleBackgroundColor = e.value.toRgbString();
        }
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }
    },
    colorChange_Background1_Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Background1_Color = e.value;
        }
        else {
            this.model.attributes.Background1_Color = e.value.toRgbString();
        }
    },
    colorChange_Font2Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }
    },
    module_input_WakeLockChange: function (event) {
        this.setModel();       
    },
    setModel: function () {
        var checked = 1;
        this.ui.module_input_WakeLock.is(':checked') ? checked = 1 : checked = 0;

        this.model.set({
            Font1_Font: this.ui.font_dropDown_Font1.val(),
            Font1_Size: this.ui.font_dropDown_Font1_Size.val(),
            Font1_Style: this.ui.font_dropDown_Font1_Style.val(),
            Font1_TextAlign: this.ui.font_dropDown_Font1_TextAlign.val(),
            Margin1_Top: this.ui.font_input_margin1_Top.val(),
            Margin1_Bottom: this.ui.font_input_margin1_Bottom.val(),
            Margin1_Left: this.ui.font_input_margin1_Left.val(),
            Margin1_Right: this.ui.font_input_margin1_Right.val(),
            Font2_Font: this.ui.font_dropDown_Font2.val(),
            Font2_Size_01: this.ui.font_dropDown_Font2_Size_01.val(),
            Font2_Size_02: this.ui.font_dropDown_Font2_Size_02.val(),
            Font2_Size_03: this.ui.font_dropDown_Font2_Size_03.val(),
            Font2_Size_04: this.ui.font_dropDown_Font2_Size_04.val(),
            Font2_Size_05: this.ui.font_dropDown_Font2_Size_05.val(),
            Font2_Style: this.ui.font_dropDown_Font2_Style.val(),
            Font2_TextAlign: this.ui.font_dropDown_Font2_TextAlign.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val(),
            WakeLock: checked
        });
    },
    setupCssStyle: function () {
    }
});