﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customProductSheetModel from './tpl/customProductSheetModel.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomProductSheetModelInsert from './customProductSheetModelInsert';
import View_CustomProductSheetModelList from './customProductSheetModelList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customProductSheetModel();
    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();
        this.localSetting = new Entities_Empty.Model();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customProductSheetModel');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    regions: {
        customProductSheetModelInsert: "#customProductSheetModelInsert",
        customProductSheetModelList: "#customProductSheetModelList",
        customProductSheetModelPagination: "#customProductSheetModelPagination",
        information: "#customProductSheetModelInformation"
    },
    onBeforeShow: function () {
        this.customProductSheetModelInsert.show(new View_CustomProductSheetModelInsert({ collection: this.collection, localSetting: this.localSetting, app : this.app }));
        this.customProductSheetModelList.show(new View_CustomProductSheetModelList({ collection: this.collection,app : this.app }));
        this.customProductSheetModelPagination.show(new View_Pagination({ collection: this.collection,app : this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection,app : this.app }));
    },
    ui : {
        'help': '#help'
    },
    events: {
        'click #help': 'help'
    },
    onRender: function () {
        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No form model yet
        if (this.collection.length <= 0) {
            var tmpModel = new this.informationCollection.model({ Information: resources.X101139 });
            this.informationCollection.add(tmpModel);
        }
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();
    }
}, Backbone.Radio.Requests);