var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogHelpListItem_empty"> <div class="row"> <div class="col-xs-3 col-sm-3 col-md-3"> <div class="image" style="text-align:center"> <img style="height:100%; width:100%" alt="image" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'"> </div> <div style="height:5px"></div> </div> <div class="col-xs-9 col-sm-9 col-md-9"> <p class="name"><strong>'+
((__t=( model.Subject ))==null?'':__t)+
'</strong></p> <p class="name">'+
((__t=( model.Text ))==null?'':__t)+
'</p> <div style="height:5px"></div> </div> </div> </div>';
}
return __p;
};
