'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginFileValidateType from '../../plugins/filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';



import tpl_customUserImportUser from './tpl/customUserImportUser.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import helper_typo from '../../helper/typo';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customUserImportUser();
    },
    initialize: function () {
        this.app = this.options.app;

        this.localSetting = new Entities_Empty.Model();
    },
    ui: {
        'customUserImportUser_filePond': '#customUserImportUser_filePond'

    },
    events: {
    },
    childEvents: {
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;
        FilePond.registerPlugin(
            FilePondPluginFileValidateType,
            FilePondPluginFileEncode,
        );

        var labelIdle = resources.X200141 + ' <span class="filepond--label-action">' + resources.X200142 + '</span>';

        var pond = FilePond.create(this.ui.customUserImportUser_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            acceptedFileTypes: [
                'text/csv',
                'application/vnd.ms-excel',
                'application/csv'
            ],
            allowMultiple: false,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();
                    var mimeType = self.base64MimeType(base64);
                    var fileData = base64.split(',')[1];
                    var base64 = fileItem.getFileEncodeBase64String();
                    //var txt = decodeURIComponent(escape(window.atob(base64)));
                    var txt = decodeURIComponent(window.atob(base64));

                    var callbackFinish = function () {
                        pond.removeFile(fileItem);

                        self.collection.fullCollection.sort();
                        if (self.collection.state.currentPage < self.collection.state.lastPage) {
                            self.collection.getPage(self.collection.state.currentPage);
                        }
                        else {
                            self.collection.getLastPage();
                        }
                    }

                    self.import(txt, callbackFinish);
                }
            }
        });
    },
    import: function (file, callbackFinish) {
        var self = this;
        var array = [];

        var itemTotal = 0;
        var itemSave = 0;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X200126;
        dialogWaitingModel.attributes.Text = resources.X100251;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        dialogWaiting.onShow = function () {
            setTimeout(function () {
                app.progress_count++;

                var allTextLines = file.split(/\r\n|\n/);
                var entries = allTextLines[0].split(';');
                var lines = [];

                var count = 0;

                for (var j = 1; j < allTextLines.length; j++) {
                    if (allTextLines[j].length > 0) {
                        var entries = allTextLines[j].split(';');

                        var tmpModel = new this.app.entities.collections.customUserTmp.model();
                        tmpModel.attributes.AuthUserId = config.authUserId();
                        tmpModel.attributes.Name = entries[0];
                        tmpModel.attributes.CallerId = entries[1];
                        tmpModel.attributes.LuxaforId = entries[2];
                        tmpModel.attributes.Email = entries[3];

                        var enablePomodoro = false;
                        if (entries[4] == '1') enablePomodoro = true;

                        tmpModel.attributes.EnablePomodoro = enablePomodoro;

                        if (count >= self.app.entities.models.customItem.attributes.Count) {
                            tmpModel.attributes.Enable = false;
                        }
                        else {
                            tmpModel.attributes.Enable = true;
                            count++;
                        }

                        tmpModel.attributes.Department = entries[5];

                        tmpModel.attributes.File = '';

                        if (entries[6] != undefined && entries[6] != 'nopicture') {
                            tmpModel.attributes.File = entries[6];
                        }

                        tmpModel.attributes.ActiveTab = resources.X200270;
                        tmpModel.attributes.Color = this.app.entities.models.custom.attributes.SolidColor_Color_Off;
                        tmpModel.attributes.DepartmentResetText = "";
                        tmpModel.attributes.UserText = '';

                        tmpModel.attributes.Timeleft = 0;
                        tmpModel.attributes.PomodoroStatus = 0;
                        tmpModel.attributes.LongBreakCycle = 0;
                        tmpModel.attributes.EnableUserText = false;

                        tmpModel.attributes.ImageUrl = '';
                        tmpModel.attributes.ImageFilename = '';
                        tmpModel.attributes.ImageData = '';

                        //tmpModel.attributes.EncryptText01 = '';
                        //tmpModel.attributes.EncryptText02 = '';
                        //tmpModel.attributes.EncryptText03 = '';
                        //tmpModel.attributes.EncryptText04 = '';
                        //tmpModel.attributes.EncryptText05 = '';
                        //tmpModel.attributes.EncryptText06 = '';
                        //tmpModel.attributes.EncryptText07 = '';
                        //tmpModel.attributes.DeleteImage = false;

                        var utcDateTime = helper_typo.getDBUtcDataTime();
                        tmpModel.attributes.Timestamp = utcDateTime;

                        itemTotal++;

                        self.app.entities.collections.customUserTmp.create(tmpModel, {
                            wait: true,
                            success: function (response) {
                                itemSave++;

                                if (itemTotal == itemSave) {
                                    dialogWaiting.triggerMethod('dialog:close');


                                    if (count > self.app.entities.models.customItem.attributes.Count) {
                                        var dialogBoxModel = new Entities_Empty.Model();
                                        dialogBoxModel.attributes.Title = resources.X200115;

                                        var text = String.format(resources.X200398, self.app.entities.models.customItem.attributes.Count, "http://shopify");

                                        dialogBoxModel.attributes.Text = text;
                                        dialogBoxModel.attributes.Button = resources.X100279;

                                        var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });

                                        var callback = function () {
                                            self.stopListening(dialogBox, "dialog:okclicked", callback);
                                            callbackFinish();
                                        }
                                        self.listenTo(dialogBox, "dialog:okclicked", callback);
                                        self.app.dialogRegion.show(dialogBox);
                                    }
                                    else {
                                        callbackFinish();
                                    }
                                }
                            }
                        });
                    }

                }
            }, 1000);
        };

        this.app.dialogRegion.show(dialogWaiting);
    },
    base64MimeType: function (encoded) {
        var result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    }
});