﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageListItem from './tpl/customWebpageListItem.html';
import language from '../../../common/language';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customWebpageListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customWebpageListItem_btnEdit': '#customWebpageListItem_btnEdit',
        'customWebpageListItem_btnDelete': '#customWebpageListItem_btnDelete',
    },
    events: {
        'click @ui.customWebpageListItem_btnEdit': 'btnEditClick',
        'click @ui.customWebpageListItem_btnDelete': 'btnDeleteClick'
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;

        switch(this.model.attributes.DisplayType) {
            case 0 : customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); break;
            case 1 : customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Infopage: this.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    btnDeleteClick: function (event) {
        event.preventDefault();
        
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100134;

        var text = resources.X100484
        switch (this.model.attributes.DisplayType)  {
            case 0 : text = resources.X100484; break;
            case 1 : text = resources.X100485; break;
        }


        dialog2ButtonModel.attributes.Text = text;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var collection = this.app.entities.collections.customWebpage;

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            self.model.destroy({
                wait: true,
                success: function (model, response) {
                    //reread webpage item
                    self.app.entities.collections.customWebpageItem.fetch({
                        silent : true,
                        wait: true,
                        success: function (mode, response) {
                            self.customViewEvent_setActive();
                        },
                        error: function (mode, response) {
                        }
                    });

                    collection.fullCollection.sort();
                    if (collection.state.currentPage < collection.state.lastPage) {
                        collection.getPage(collection.state.currentPage);
                    }
                    else {
                        collection.getLastPage();
                    }
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);

    },
    btnEditClick: function (event) {
        event.preventDefault();
        var self = this;

        var url = '';
        switch(self.model.attributes.DisplayType) {
            case 0 : self.app.router.customWebpageEditItem(self.model.attributes.id);
                var url = 'customWebpageEditItem/' + self.model.attributes.id;
                break;
            case 1 : self.app.router.customInfopageEditItem(self.model.attributes.id);
                var url = 'customInfopageEditItem/' + self.model.attributes.id;
                break;
        }

        self.app.router.navigate(url); 
    }
}, Backbone.Radio.Requests);
