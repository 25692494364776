﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_htmlSingle from './tpl/htmlSingle.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import helper_infopage from '../../helper/infopage';
import View_Module from './module';
import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    regions: {
        'modal': '#modal'
    },
    getTemplate: function () {
        return tpl_htmlSingle();
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'htmlSingle': '#htmlSingle',
        'editor': '#editor'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setAnimation: function (className) {
        this.ui.htmlSingle.show();
        this.ui.htmlSingle.addClass(className);
        this.ui.htmlSingle.addClass('div100');
    },
    clearAnimation: function () {
        this.ui.htmlSingle.removeClass().addClass('htmlSingle');
        this.ui.htmlSingle.addClass('div100');
        this.ui.htmlSingle.css('display', 'none');
    },
    setupSetting() {
        this.setupMargin();

        var animation = this.app.entities.collections.HtmlSlideAnimation.where({ Value: this.model.attributes.Data.Collection[0].Animation })[0];
        this.setAnimation(animation.attributes.Css);
    },
    onAttach() {
        this.setupSetting();

        helper_infopage.setupCssStyle(this.app);

        var self = this;
        var nowLocalDate = new Date();
        this.editorClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();

        this.ui.editor.addClass(this.editorClass);
        this.ui.editor.addClass('infopage');

        this.EditorElement = $('.' + this.editorClass)[0];

        var animation = self.app.entities.collections.HtmlSlideAnimation.where({ Value: self.model.attributes.Data.Collection[0].Animation })[0];

        var self = this;
        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        InlineEditor
            .create(this.EditorElement, {
                //plugins: [Autosave],
                fontFamily: {
                    options: fonts,
                    supportAllValues: true
                },
                // toolbar: [ 'heading', '|', 'bold', 'italic', 'link' ],
                //toolbar: ['headings', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
                toolbar: {
                    items: [
                        'heading', '|',
                        'fontfamily', 'fontsize', '|',
                        'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
                        //'alignment', '|',
                        'fontColor', 'fontBackgroundColor', '|',
                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                        'link', '|',
                        'outdent', 'indent', '|',
                        'bulletedList', 'numberedList', 'todoList', '|',
                        'code', 'codeBlock', '|',
                        'insertTable', '|',
                        'imageUpload', 'blockQuote', '|',
                        'undo', 'redo'
                    ],
                    shouldNotGroupWhenFull: true
                },
                Autosave: {
                    save(editor) {
                        // The saveData() function must return a promise
                        // which should be resolved when the data is successfully saved.
                        //return saveData(editor.getData());
                        alert("HER");

                        return true;
                    }
                }
            })
            .then(editor => {
                self.ckeditor = editor;
                editor.setData(self.model.attributes.Data.Collection[0].Data);
                self.setAnimation(animation.attributes.Css);
            })
            .catch(err => {
                console.error(err.stack);
            });
    },
    onDestroy: function () {
    },
}, Backbone.Radio.Requests);
