var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom_empty"> <div class="modal fade" id="dialogCustomSetting_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title" id="myModalLabel" style="">'+
((__t=( resources['X100449'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <h4 style="font-size:14px" class="card-title">'+
((__t=( resources['X100439'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="row"> <div class="col-md-12" style="margin-bottom: 10px"> <div class="form-group"> <div class="form-check"> <label class="form-check-label"> <input id="customSetting_WebpageImageUrlMethod_radio00" type="radio" name="optionsRadios" class="form-check-input input-pagebg" value="0"> '+
((__t=( resources['X100441'] ))==null?'':__t)+
' <i class="input-helper"></i> </label> </div> <div class="form-check"> <label class="form-check-label"> <input id="customSetting_WebpageImageUrlMethod_radio01" type="radio" name="optionsRadios" class="form-check-input input-pagebg" value="1" data-tooltip="asdasdas"> '+
((__t=( resources['X100443'] ))==null?'':__t)+
' <i class="input-helper"></i> </label> </div> <div class="form-check"> <label class="form-check-label"> <input id="customSetting_WebpageImageUrlMethod_radio02" type="radio" name="optionsRadios" class="form-check-input input-pagebg" value="2"> '+
((__t=( resources['X100445'] ))==null?'':__t)+
' <i class="input-helper"></i> </label> </div> <div class="form-check"> <label class="form-check-label"> <input id="customSetting_WebpageImageUrlMethod_radio03" type="radio" name="optionsRadios" class="form-check-input input-pagebg" value="3"> '+
((__t=( resources['X100447'] ))==null?'':__t)+
' <i class="input-helper"></i> </label> </div> </div> </div> </div> <h4 style="font-size:14px" class="card-title">'+
((__t=( resources['X101085'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-md-12"> <div class="control-label form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X101086'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customSetting_ShowInformation_check" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> </div> </div> </form> </div> <div class="modal-footer"> <button type="button" id="dialog2Button_btn01" class="btn btn-primary">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> <button type="button" id="dialog2Button_btn02" class="btn btn-secondary">'+
((__t=( model.Button02 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
