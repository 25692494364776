'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customQRCodeInsert from './tpl/customQRCodeInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customQRCodeInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customQRCodeInsert_btnSubmit': '#customQRCodeInsert_btnSubmit',
        'customQRCodeInsert_name': '#customQRCodeInsert_name',
        'customQRCodeInsert_inputName': '#customQRCodeInsert_inputName',
        'customQRCodeInsert_qrCodeContainer': '#customQRCodeInsert_qrCodeContainer',
        'customQRCodeInsert_container': '#customQRCodeInsert_container',
        'customQRCodeInsert_qrCodeLogo': '#customQRCodeInsert_qrCodeLogo'
    },
    events: {
        'click @ui.customQRCodeInsert_btnSubmit': 'btnSubmitClick'
    },
    createDefault: function () {
        this.btnSubmitClick();
    },
    btnSubmitClick: function () {
        this.hideErrors();
        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.ui.customQRCodeInsert_inputName.val();
        tmpModel.attributes.Render = "canvas";
        tmpModel.attributes.EcLevel = "H";
        tmpModel.attributes.MinVersion = 6;
        tmpModel.attributes.Fill = "Rgb(0,0,0)";
        tmpModel.attributes.Background = "Rgb(255,255,255)";
        tmpModel.attributes.Text = resources.X100068;
        tmpModel.attributes.Size = 300;
        tmpModel.attributes.Radius = 0;
        tmpModel.attributes.Quiet = 1;
        tmpModel.attributes.Mode = 3;
        tmpModel.attributes.MSize = 11;
        tmpModel.attributes.MPosX = 50;
        tmpModel.attributes.MPosY = 50;
        tmpModel.attributes.Label = "Events4us";
        tmpModel.attributes.FontName = "Arial,Arial,Helvetica,sans-serif";
        tmpModel.attributes.FontColor = "Rgb(255,0,0)";
        tmpModel.attributes.FontStyle = 0;
        tmpModel.attributes.FontSize = "30px";
        tmpModel.attributes.Logo = "/images/Events4us.png";

        this.ui.customQRCodeInsert_container.empty();

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(app);
        if (ret == false) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100071;
            dialogWaitingModel.attributes.Text = resources.X100307;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);

            var self = this;

            this.ui.customQRCodeInsert_qrCodeLogo.load(function () {
                var tmpModelQRCode = new Entities_Empty.Model();
                //tmpModelQRCode.attributes.id = -1;

                tmpModelQRCode = helper_createWebpage.createQRCodeImage(tmpModel, tmpModelQRCode, self.ui.customQRCodeInsert_qrCodeLogo, self.ui.customQRCodeInsert_qrCodeContainer, false, this.app);

                tmpModel.attributes.ImageData = tmpModelQRCode.attributes.ImageData;
                tmpModel.attributes.ImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
                tmpModel.attributes.ImageUpdate = tmpModelQRCode.attributes.ImageUpdate;
                tmpModel.attributes.Filename = tmpModel.attributes.Name + '_' + new Date().getTime() + '.jpg';

                self.ui.customQRCodeInsert_container.empty();
                self.ui.customQRCodeInsert_qrCodeLogo.off("load");

                self.collection.fullCollection.create(tmpModel, {
                    success: function (response) {
                        dialogWaiting.triggerMethod('dialog:close');
                        self.collection.fullCollection.sort();
                        self.collection.getFirstPage({ fetch: false });
                        self.ui.customQRCodeInsert_inputName.val('');
                    }
                }, this.app);
            });
            this.ui.customQRCodeInsert_qrCodeLogo.attr("src", tmpModel.attributes.Logo);
        } else {
            this.showErrors(tmpModel.errors);
        }
    }
});