var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="footer_empty"> <div class="d-sm-flex justify-content-center justify-content-sm-between"> <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">'+
((__t=( resources['X101095'] ))==null?'':__t)+
' <a href="index.html" target="_blank">'+
((__t=( resources['X101096'] ))==null?'':__t)+
'</a>'+
((__t=( resources['X101097'] ))==null?'':__t)+
'</span> <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">'+
((__t=( resources['X101098'] ))==null?'':__t)+
' <i class="mdi mdi-heart text-danger"></i></span> </div> </div>';
}
return __p;
};
