﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customDepartmentReset/-1?AuthUserId=' + config.authUserId();
        }
        else if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customDepartmentReset/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customDepartmentReset/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    validateNoBackbone: function (app) {
        this.errors = [];

        if (this.attributes.Name == "") {
            this.errors.push({ name: 'customIPAddressInsert_inputName', message: resources.X200107 });
            this.errors.push({ name: 'customIPAddressEditItem_inputName', message: resources.X200107 });
        }
        else {
            const regexS = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
            var regex = new RegExp(regexS);
            var results = regex.exec(this.attributes.Name);
            if (results == null) {
                this.errors.push({ name: 'customIPAddressInsert_inputName', message: resources.X200111 });
                this.errors.push({ name: 'customIPAddressEditItem_inputName', message: resources.X200111 });
            }
        }

        var self = this;
        for (var i = 0; i < app.entities.collections.customIPAddress.fullCollection.length; i++) {
            if (app.entities.collections.customIPAddress.fullCollection.models[i].attributes.id != this.attributes.id) {
                if (this.attributes.Name == app.entities.collections.customIPAddress.fullCollection.models[i].attributes.Name) {
                    this.errors.push({ name: 'customIPAddressInsert_inputName', message: resources.X200108 });
                    this.errors.push({ name: 'customIPAddressEditItem_inputName', message: resources.X200108 });
                }
            }
        };

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 4,
    },
    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },
    options: {
        full: false
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customDepartmentReset/route01/-1?AuthUserId=' + config.authUserId() + '&Department=' + options.department;
        }

        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
            : a.attributes.id < b.attributes.id ? 1
                : 0;
    }
});



