﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_adSlideList from './tpl/adSlideList.html';
import View_AdSlideListItem from './adSlideListItem';

export default Marionette.CollectionView.extend({
    tagName: 'ul',
    className: '',
    attributes: function () {
        return {
            id: 'this.animationCube();',
            className: 'div100'
        }
    },
    childViewContainer: '#adSlideListItem',
    childView: View_AdSlideListItem,
    getTemplate: function () {
        return tpl_adSlideList();
    },
    ui: {
        'adSlideListItem': '#adSlideListItem'
    },

    initialize: function () {
        this.app = this.options.app;

    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    },
    onAttach() {
    }

});
