'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customIPAddressInsert from './tpl/customIPAddressInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customIPAddressInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customIPAddressInsert_btnSubmit': '#customIPAddressInsert_btnSubmit',
        'customIPAddressInsert_inputName': '#customIPAddressInsert_inputName'
    },
    events: {
        'click @ui.customIPAddressInsert_btnSubmit': 'btnSubmitClick'
    },
    childEvents: {
    },
    onRender: function () {
    },
    btnSubmitClick: function () {
        var self = this;



        var tmpModel = new this.app.entities.collections.customIPAddress.model();
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.ui.customIPAddressInsert_inputName.val();;

        if (!tmpModel.validateNoBackbone(this.app)) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200101;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

            var callback = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion.show(dialogWaiting);

            self.app.entities.collections.customIPAddress.create(tmpModel, {
                wait: true,
                success: function (response) {
                    self.hideErrors();
                    self.ui.customIPAddressInsert_inputName.val("");
                    dialogWaiting.triggerMethod('dialog:close');
                }
            });
        } else {
            this.showErrors(tmpModel.errors);
        }
    }
});

