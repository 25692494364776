﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_customWebpageEditor from './tpl/customWebpageEditor.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreview from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

import View_CustomImage from '../../views/customImage/customImage';
import View_CustomFile from '../../views/customFile/customFile';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customWebpageEditor();
    },
    regions: {
        'modal': '#modal'
    },

    initialize: function () {
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconPalette', function () {
            self.btnPaintStrapClick();
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconFileCode', function () {
            var customSetting = self.app.entities.collections.customSetting.where({ Name: 'Webeditor' });
            var webeditor = customSetting[0].attributes.Value == '1' ? true : false;
            //self.ui.customWebpageEditor_checkboxEditor.bootstrapSwitch('state', !webeditor, webeditor);
            self.checkboxEditorChange(null, !webeditor);
        });

        this.listenTo(userChannel, 'customWebpageEditItem:iconArrowsAlt', function () {
            var editorPos = $('#editorRegion').offset();

            //var editorPos = self.ui.customWebpageEditor_iframeContentBuilder_div.position();
            //var editorHeight = ($(document).height() - editorPos.top) - 15;
            //var editorHeight = ($(window).outerHeight - editorPos.top) - 15;
            var editorHeight = (window.innerHeight - editorPos.top);


            self.ui.customWebpageEditor_iframeContentBuilder.css('height', editorHeight + 'px');
            self.ui.customWebpageEditor_iframeContentBuilder_div.css('height', editorHeight + 'px');
            self.ui.customWebpageEditor_iframeCKEditor.css('height', editorHeight + 'px');

            var contentHeight = editorHeight;
            $('#customWebpageEditItemLeftContent').css('height', contentHeight + 'px');
            $('#customWebpageEditItemRightContent').css('height', contentHeight + 'px');
        });
    },
    ui: {
        'customWebpageEditor_iframeCKEditor': '#customWebpageEditor_iframeCKEditor',
        'customWebpageEditor_iframeCKEditor_div': '#customWebpageEditor_iframeCKEditor_div',
        'customWebpageEditor_iframeContentBuilder': '#customWebpageEditor_iframeContentBuilder',
        'customWebpageEditor_iframeContentBuilder_div': '#customWebpageEditor_iframeContentBuilder_div',
        'customWebpageEditor_checkboxEditor': '#customWebpageEditor_checkboxEditor',
        'customWebpageEditor_checkboxDesignMode': '#customWebpageEditor_checkboxDesignMode',
        'customWebpageEditor_btnPaintStrapSubmit': '#customWebpageEditor_btnPaintStrapSubmit',
        'customWebpageEditor_btnImage': '#customWebpageEditor_btnImage',
        'customWebpageEditor_btnFile': '#customWebpageEditor_btnFile'
    },
    events: {
        'switchChange.bootstrapSwitch  @ui.customWebpageEditor_checkboxEditor': 'checkboxEditorChange',
        'switchChange.bootstrapSwitch  @ui.customWebpageEditor_checkboxDesignMode': 'checkboxDesignModeChange',
        'click @ui.customWebpageEditor_btnPaintStrapSubmit': 'btnPaintStrapClick',
        'click @ui.customWebpageEditor_btnImage': 'btnImageClick',
        'click @ui.customWebpageEditor_btnFile': 'btnFileClick'
    },
    onRenderA: function () {
    },
    onRender: function () {
        var self = this;
        //var bust = (new Date()).getTime();
        //When edit in the editor iframe html file use the url http://www.timestampconvert.com/?go2=true&offset=-1&timestamp=1449135717&Submit=++++++Convert+to+Date++++++
        //to get a new date and build, this is for avoid reload iframe i the solution every time
        var bust = '1449135730';

        var paintStrapModel = app.entities.collections.paintStrap.fullCollection.where({ id: this.options.webpageModel.attributes.Theme });
        var cssFile = config.webApiUrl() + paintStrapModel[0].attributes.CssFile;

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });
        var webeditor = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customWebpageEditor_checkboxEditor.bootstrapSwitch('state', webeditor, true);

        this.ui.customWebpageEditor_checkboxDesignMode.bootstrapSwitch('state', this.app.entities.models.editorSetting.attributes.DesignMode, true);
        this.checkboxDesignModeChange(null, this.app.entities.models.editorSetting.attributes.DesignMode);



        //$(document).bind('iframeContentBuilderReady', function(e) { 
        /*var callback = function () {
            var content = self.getContent();
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customWebpageEditor:setOldContent', content);
            var a = content;
        }
       
        if (customSetting[0].attributes.Value == '1') {
            var userChannel = Backbone.Radio.channel('app');
            var firstContent = userChannel.request('customWebpageEditor:getContentFirstTime');
            self.setContent(firstContent, self.options.webpageItem_id, callback);
        }*/

        //});

        self.app.iframeContentBuilderReady = false;

        $(document).bind('iframeContentBuilderReady', function (e) {
            self.app.iframeContentBuilderReady = true;
            //self.ui.customWebpageEditor_iframeContentBuilder.ready ( function () {
            //var callback = function () {
            //    var content = self.getContent();
            //    var userChannel = Backbone.Radio.channel('app');
            //    userChannel.trigger('customWebpageEditor:setOldContent', content);
            //    var a = content;
            //}

            //if (customSetting[0].attributes.Value == '0') {
            //    var userChannel = Backbone.Radio.channel('app');
            //    var firstContent = userChannel.request('customWebpageEditor:getContentFirstTime');
            //    self.setContent(firstContent, self.options.webpageItem_id, callback);
            //}

            //if (customSetting[0].attributes.Value == '1') {
            var userChannel = Backbone.Radio.channel('app');
            userChannel.request('customWebpageEditor:getContentFirstTime');

            //}
        });

        //this.ui.customWebpageEditor_iframeContentBuilder.load(function () {
        //    var $head = self.ui.customWebpageEditor_iframeContentBuilder.contents().find("head");
        //    $head.append($("<link/>",
        //        { rel: "stylesheet", href: cssFile, type: "text/css" }));

        //    self.ui.customWebpageEditor_iframeContentBuilder.height = "";
        //    self.ui.customWebpageEditor_iframeContentBuilder.height = self.ui.customWebpageEditor_iframeContentBuilder[0].contentWindow.document.body.scrollHeight + "px";
        //});

        //HER
        this.ui.customWebpageEditor_iframeContentBuilder.load(function () {
            ////var $head = self.ui.customWebpageEditor_iframeContentBuilder.contents().find("head");
            ////$head.append($("<link/>",
            ////    { rel: "stylesheet", href: cssFile, type: "text/css" }));

            //self.ui.customWebpageEditor_iframeContentBuilder.height = "";
            ////self.ui.customWebpageEditor_iframeContentBuilder.height = self.ui.customWebpageEditor_iframeContentBuilder[0].contentWindow.document.body.scrollHeight + "px";

            ////var editorPos = $('#editorRegion').position().top;
            //var editorPos = $('#editorRegion').offset();

            ////var editorPos = self.ui.customWebpageEditor_iframeContentBuilder_div.position();
            //var editorHeight = ($(window).height() - editorPos.top) - 15;

            //self.ui.customWebpageEditor_iframeContentBuilder.css('height', editorHeight + 'px');
            //self.ui.customWebpageEditor_iframeContentBuilder_div.css('height', editorHeight + 'px');
            //self.ui.customWebpageEditor_iframeCKEditor.css('height', editorHeight + 'px');

            //var contentHeight = editorHeight + 55;
            //$('#customWebpageEditItemLeftContent').css('height', contentHeight + 'px');
            //$('#customWebpageEditItemRightContent').css('height', contentHeight + 'px');
        });

        var contentBuilder_e4u_iframe = '';
        var ckeditor_e4u_iframe = ''

        if (!config.online()) {
            contentBuilder_e4u_iframe = '/webpageeditor/ContentBuilder/e4u_iframe.html?bust=231210;AuthUserId=';
            ckeditor_e4u_iframe = '/webpageeditor/ckeditor/samples/e4u_iframe.html?bust='
        } else {
            contentBuilder_e4u_iframe = '/webpageeditor/ContentBuilder/e4u_iframe_Localhost.html?bust=231210;AuthUserId=';
            ckeditor_e4u_iframe = '/webpageeditor/ckeditor/samples/e4u_iframe_Localhost.html?bust='
        }

        //this.ui.customWebpageEditor_iframeContentBuilder.ready ( function () {
        //    //var callback = function () {
        //    //    var content = self.getContent();
        //    //    var userChannel = Backbone.Radio.channel('app');
        //    //    userChannel.trigger('customWebpageEditor:setOldContent', content);
        //    //    var a = content;
        //    //}
        //    //if (customSetting[0].attributes.Value == '1') {
        //    //    self.setContent(self.getContent(), self.options.webpageItem_id, callback);
        //    //}


        //});

        //Cke editor is in yet allso in a iframe so paintstrap must be included with the other styles
        var cob_cssFile = config.webApiUrl() + '/MainJS/ImportWebpageEditorCSS/' + paintStrapModel[0].attributes.Filename + '/dummy/dummy/';

        //this.ui.customWebpageEditor_iframeContentBuilder.attr('src', contentBuilder_e4u_iframe + '&bust=' + bust);

        //var ifcbSrc = contentBuilder_e4u_iframe + config.authUserId() +
        //    '&config_webapiurl=' + config.webApiUrl() +
        //    '&contentBuilder_btnViewHtml=' + resources.X100061 +
        //    '&contentBuilder_snippletBtn01=' + resources.X100062 +
        //    '&contentBuilder_snippletBtn02=' + resources.X100063 + 
        //    '&SizeTypeText0=' + resources.X100492 + 
        //    '&SizeTypeText1=' + resources.X100493 + 
        //    '&SizeTypeText2=' + resources.X100494 + 
        //    '&SizeTypeText3=' + resources.X100495 + 
        //    '&SizeTypeText4=' + resources.X100496 + 
        //    '&id=' + this.options.webpageItem_id + 
        //    '&cob_cssFile=' + cob_cssFile + 
        //    '&DisplayType=' + this.options.webpageItem_DisplayType + 
        //    '&bust=' + bust;

        //ifcbSrc = ifcbSrc.replace(new RegExp('æ', 'g'), 'aeaeae');
        //ifcbSrc = ifcbSrc.replace(new RegExp('ø', 'g'), 'oeoeoe');
        //ifcbSrc = ifcbSrc.replace(new RegExp('å', 'g'), 'aaaaaa');




        //var EditorWidth = "640px";
        var EditorWidth = "400px";


        if (this.options.webpageItem_DisplayType == 0) {
            EditorWidth = "400px";
        } else if (this.options.webpageItem_DisplayType == 1) {
            EditorWidth = "100%";
        }


        var ifcbSrc = encodeURIComponent(bust + '&AuthUserId=' + config.authUserId() +//config.authUserId() +
            '&config_webapiurl=' + config.webApiUrl() +
            '&contentBuilder_btnViewHtml=' + resources.X100061 +
            '&contentBuilder_snippletBtn01=' + resources.X100062 +
            '&contentBuilder_snippletBtn02=' + resources.X100063 +
            '&SizeTypeText0=' + resources.X100492 +
            '&SizeTypeText1=' + resources.X100493 +
            '&SizeTypeText2=' + resources.X100494 +
            '&SizeTypeText3=' + resources.X100495 +
            '&SizeTypeText4=' + resources.X100496 +
            '&EditorWidth=' + EditorWidth +
            '&id=' + this.options.webpageItem_id +
            '&cob_cssFile=' + cob_cssFile +
            '&DisplayType=' + this.options.webpageItem_DisplayType +
            '&bust=' + bust);

        //var timerId = window.setInterval(function () {
        this.ui.customWebpageEditor_iframeContentBuilder.attr('src', contentBuilder_e4u_iframe + ifcbSrc);
        //clearInterval(timerId);
        //}, 30000);

        //this.ui.customWebpageEditor_iframeContentBuilder.attr('src', contentBuilder_e4u_iframe + config.authUserId() +
        //    '&config_webapiurl=' + config.webApiUrl() +
        //    '&contentBuilder_btnViewHtml=' + resources.X100061 +
        //    '&contentBuilder_snippletBtn01=' + resources.X100062 +
        //    '&contentBuilder_snippletBtn02=' + resources.X100063 + 
        //    '&SizeTypeText0=' + resources.X100492 + 
        //    '&SizeTypeText1=' + resources.X100493 + 
        //    '&SizeTypeText2=' + resources.X100494 + 
        //    '&SizeTypeText3=' + resources.X100495 + 
        //    '&SizeTypeText4=' + resources.X100496 + 
        //    '&id=' + this.options.webpageItem_id + 
        //    '&cob_cssFile=' + cob_cssFile + 
        //    '&DisplayType=' + this.options.webpageItem_DisplayType + 
        //    '&bust=' + bust);



        //self.ui.customWebpageEditor_iframeCKEditor.ready ( function () {
        //var callback = function () {
        //    var content = self.getContent();
        //    var userChannel = Backbone.Radio.channel('app');
        //    userChannel.trigger('customWebpageEditor:setOldContent', content);
        //    var a = content;
        //}
        //if (customSetting[0].attributes.Value == '0') {
        //    self.setContent(self.getContent(), self.options.webpageItem_id, callback);
        //}
        //});

        //this.ui.customWebpageEditor_iframeCKEditor.load(function () {
        //var callback = function () {
        //    var content = self.getContent();
        //    var userChannel = Backbone.Radio.channel('app');
        //    userChannel.trigger('customWebpageEditor:setOldContent', content);
        //    var a = content;
        //}

        //if (customSetting[0].attributes.Value == '1') {
        //    var userChannel = Backbone.Radio.channel('app');
        //    var firstContent = userChannel.request('customWebpageEditor:getContentFirstTime');
        //    self.setContent(firstContent, self.options.webpageItem_id, callback);
        //}
        //});




        //HER
        self.app.ckeditorReady = false;
        $(document).bind('ckeditorReady', function (e) {
            self.app.ckeditorReady = true;
            //var callback = function () {
            //    var content = self.getContent();
            //    var userChannel = Backbone.Radio.channel('app');
            //    userChannel.trigger('customWebpageEditor:setOldContent', content);
            //    var a = content;
            //}

            //if (customSetting[0].attributes.Value == '0') {
            //    var userChannel = Backbone.Radio.channel('app');
            //    var firstContent = userChannel.request('customWebpageEditor:getContentFirstTime');
            //    self.setContent(firstContent, self.options.webpageItem_id, callback);
            //}

            //if (customSetting[0].attributes.Value == '0') {
            var userChannel = Backbone.Radio.channel('app');
            userChannel.request('customWebpageEditor:getContentFirstTime');
            //}
        });



        //Cke editor is in yet allso in a iframe so paintstrap must be included with the other styles
        var cke_cssFile = config.webApiUrl() + '/MainJS/ImportWebpageEditorCSS/' + paintStrapModel[0].attributes.Filename + '/dummy/dummy/';

        //var S0 = resources.X100492;
        //S0 = S0.replace(/[^æøå\w -]+/g, '').replace(' ', '-');
        //var S1 = resources.X100493;
        //S1 = S1.replace(/[^æøå\w -]+/g, '').replace(' ', '-');
        //var S2 = resources.X100494;
        //S2 = S2.replace(/[^æøå\w -]+/g, '').replace(' ', '-');
        //var S3 = resources.X100495;
        //S3 = S3.replace(/[^æøå\w -]+/g, '').replace(' ', '-');
        //var S4 = resources.X100496;
        //S4 = S4.replace(/[^æøå\w -]+/g, '').replace(' ', '-');

        //this.ui.customWebpageEditor_iframeCKEditor.attr('src', ckeditor_e4u_iframe + bust);

        //var ifckSrc = ckeditor_e4u_iframe  + bust + '&AuthUserId=' + config.authUserId() +
        //    '&config_webapiurl=' + config.webApiUrl() +
        //    '&htmlbuttons01_name=' + resources.X100056 +
        //    '&htmlbuttons01_title=' + resources.X100057 +
        //    '&htmlbuttons01_value=' + resources.X100058 +
        //    '&htmlbuttons02_name=' + resources.X100059 +
        //    '&htmlbuttons02_title=' + resources.X100060 +
        //    '&SimpleImageBrowser_title=' + resources.X100054 +
        //    '&SimpleImageBrowser_BrowseForImages=' + resources.X100055 +
        //    '&SizeTypeText0=' + resources.X100492 + 
        //    '&SizeTypeText1=' + resources.X100493 + 
        //    '&SizeTypeText2=' + resources.X100494 + 
        //    '&SizeTypeText3=' + resources.X100495 + 
        //    '&SizeTypeText4=' + resources.X100496 + 
        //    '&css=' + cke_cssFile + 
        //    '&id=' + this.options.webpageItem_id +
        //    '&DisplayType=' + this.options.webpageItem_DisplayType 

        //ifckSrc = ifckSrc.replace(new RegExp('æ', 'g'), 'aeaeae');
        //ifckSrc = ifckSrc.replace(new RegExp('ø', 'g'), 'oeoeoe');
        //ifckSrc = ifckSrc.replace(new RegExp('å', 'g'), 'aaaaaa');

        var ifckSrc = encodeURIComponent(bust + '&AuthUserId=' + config.authUserId() +
            '&config_webapiurl=' + config.webApiUrl() +
            '&htmlbuttons01_name=' + resources.X100056 +
            '&htmlbuttons01_title=' + resources.X100057 +
            '&htmlbuttons01_value=' + resources.X100058 +
            '&htmlbuttons02_name=' + resources.X100059 +
            '&htmlbuttons02_title=' + resources.X100060 +
            '&SimpleImageBrowser_title=' + resources.X100054 +
            '&SimpleImageBrowser_BrowseForImages=' + resources.X100055 +
            '&SizeTypeText0=' + resources.X100492 +
            '&SizeTypeText1=' + resources.X100493 +
            '&SizeTypeText2=' + resources.X100494 +
            '&SizeTypeText3=' + resources.X100495 +
            '&SizeTypeText4=' + resources.X100496 +
            '&css=' + cke_cssFile +
            '&id=' + this.options.webpageItem_id +
            '&DisplayType=' + this.options.webpageItem_DisplayType);




        //this.ui.customWebpageEditor_iframeCKEditor.attr('src', ckeditor_e4u_iframe  + bust + '&AuthUserId=' + config.authUserId() +
        //    '&config_webapiurl=' + config.webApiUrl() +
        //    '&htmlbuttons01_name=' + resources.X100056 +
        //    '&htmlbuttons01_title=' + resources.X100057 +
        //    '&htmlbuttons01_value=' + resources.X100058 +
        //    '&htmlbuttons02_name=' + resources.X100059 +
        //    '&htmlbuttons02_title=' + resources.X100060 +
        //    '&SimpleImageBrowser_title=' + resources.X100054 +
        //    '&SimpleImageBrowser_BrowseForImages=' + resources.X100055 +
        //    '&SizeTypeText0=' + resources.X100492 + 
        //    '&SizeTypeText1=' + resources.X100493 + 
        //    '&SizeTypeText2=' + resources.X100494 + 
        //    '&SizeTypeText3=' + resources.X100495 + 
        //    '&SizeTypeText4=' + resources.X100496 + 
        //    //'&SizeTypeText0=' + S0 + 
        //    //'&SizeTypeText1=' + S1 + 
        //    //'&SizeTypeText2=' + S2 + 
        //    //'&SizeTypeText3=' + S3 + 
        //    //'&SizeTypeText4=' + S4 + 
        //    '&css=' + cke_cssFile + 
        //    '&id=' + this.options.webpageItem_id +
        //    '&DisplayType=' + this.options.webpageItem_DisplayType 
        //    );

        //var timerId = window.setInterval(function () {

        this.ui.customWebpageEditor_iframeCKEditor.attr('src', ckeditor_e4u_iframe + ifckSrc);

        this.showHideEditor();
        
        //clearInterval(timerId);


        //}, 3000);


    },
    onDestroy: function () {
        $("#customWebpageEditor_iframeCKEditor").unbind();
        $("#customWebpageEditor_iframeContentBuilder").unbind();


        $("#customWebpageEditor_iframeCKEditor").remove();
        $("#customWebpageEditor_iframeContentBuilder").remove();

        this.ui.customWebpageEditor_checkboxEditor.bootstrapSwitch('destroy');
        this.ui.customWebpageEditor_checkboxDesignMode.bootstrapSwitch('destroy');

        $(document).unbind('iframeContentBuilderReady');
        $(document).unbind('ckeditorReady');
    },
    checkboxEditorChange: function (event, state) {
        var self = this;

        //var content = this.getContent();
        this.saveContent();

        var webeditor = state == true ? '1' : '0';
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });
        customSetting[0].attributes.Value = webeditor;

        this.showHideEditor();

        //save again because when editor changes the ck editor add cr
        /*var self = this;
        var callback = function () {
            self.oldContent = self.getContent();
            self.saveContent();
        }
        
        this.setContent(content, this.options.webpageItem_id, callback);
        */

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');

                //if (customSetting[0].attributes.Value == "1") {
                //    self.saveContent();
                //}
            }
        });
    },
    checkboxDesignModeChange: function (event, state) {
        var self = this;

        this.app.entities.models.editorSetting.attributes.DesignMode = state;

        if (state) {
            $('#headerRegion').css('display', 'none');
            $('#menuRegion').css('display', 'none');
            $('#customMenuRegion').css('display', 'none');
            $('#page-info-block').css('display', 'none');
            $('#customWebpageEditItem_designMode').css('display', 'none');
            $('#footerRegion').css('display', 'none');
        }
        else {
            $('#headerRegion').show();
            $('#menuRegion').show();
            $('#customMenuRegion').show();
            $('#page-info-block').show();
            $('#customWebpageEditItem_designMode').show();
            $('#footerRegion').show();
        }

    },
    showHideEditor: function () {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });

        switch (customSetting[0].attributes.Value) {
            case '1':
                this.ui.customWebpageEditor_iframeCKEditor_div.css('display', 'none');
                this.ui.customWebpageEditor_iframeContentBuilder_div.show();
                break;
            case '0':
                this.ui.customWebpageEditor_iframeCKEditor_div.show();
                this.ui.customWebpageEditor_iframeContentBuilder_div.css('display', 'none');
                break;
        }
    },
    getContent: function () {
        var content = '';
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });
        switch (customSetting[0].attributes.Value) {
            case '1': try {
                content = document.getElementById("customWebpageEditor_iframeContentBuilder").contentWindow.window.getContent();
            } catch (err) {
                content = "############ERROR############";
            }
                break;
            case '0': content = document.getElementById("customWebpageEditor_iframeCKEditor").contentWindow.window.getContent();
                break;
        }
        return content;
    },
    getContentCKCB: function (value) {
        var content = '';

        switch (value) {
            case '1': try {
                content = document.getElementById("customWebpageEditor_iframeContentBuilder").contentWindow.window.getContent();
            } catch (err) {
                content = "############ERROR1############";
            }
                break;
            case '0': try {
                content = document.getElementById("customWebpageEditor_iframeCKEditor").contentWindow.window.getContent();
            } catch (err) {
                content = "############ERROR0############";
            }
                break;
        }
        return content;
    },
    setContentCKCB: function (value, content, id, tag, callbackCK, callbackCB) {
        this.options.webpageItem_id = id;

        switch (value) {
            case '1': this.ui.customWebpageEditor_iframeContentBuilder[0].contentWindow.window.setContent(content, callbackCB, tag); break;
            case '0': this.ui.customWebpageEditor_iframeCKEditor[0].contentWindow.window.setContent(content, callbackCK, tag); break;
        }

        var self = this;
        //var $head = self.ui.customWebpageEditor_iframeContentBuilder.contents().find("head");
        //$head.append($("<link/>",
        //    { rel: "stylesheet", href: cssFile, type: "text/css" }));

        self.ui.customWebpageEditor_iframeContentBuilder.height = "";
        //self.ui.customWebpageEditor_iframeContentBuilder.height = self.ui.customWebpageEditor_iframeContentBuilder[0].contentWindow.document.body.scrollHeight + "px";

        //var editorPos = $('#editorRegion').position().top;
        var editorPos = $('#editorRegion').offset();

        //var editorPos = self.ui.customWebpageEditor_iframeContentBuilder_div.position();
        var editorHeight = (window.innerHeight - editorPos.top);

        self.ui.customWebpageEditor_iframeContentBuilder.css('height', editorHeight + 'px');
        self.ui.customWebpageEditor_iframeContentBuilder_div.css('height', editorHeight + 'px');
        self.ui.customWebpageEditor_iframeCKEditor.css('height', editorHeight + 'px');

        var contentHeight = editorHeight;
        $('#customWebpageEditItemLeftContent').css('height', contentHeight + 'px');
        $('#customWebpageEditItemRightContent').css('height', contentHeight + 'px');
    },
    setContent: function (content, id, tag, callback) {
        this.options.webpageItem_id = id;

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });

        switch (customSetting[0].attributes.Value) {
            case '1': this.ui.customWebpageEditor_iframeContentBuilder[0].contentWindow.window.setContent(content, callback, tag); break;
            case '0': this.ui.customWebpageEditor_iframeCKEditor[0].contentWindow.window.setContent(content, callback, tag); break;
        }
    },
    saveContent: function () {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditor:saveContent');
    },
    hexToRgb: function (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    },
    themeChange: function (selectedModel) {
        var rgbColor = this.hexToRgb(selectedModel.attributes.Color);
        var Color = String.format('Rgb({0},{1},{2})', rgbColor.r, rgbColor.g, rgbColor.b);

        var rgbBackgroundColor = this.hexToRgb(selectedModel.attributes.BackgroundColor);
        var BackgroundColor = String.format('Rgb({0},{1},{2})', rgbBackgroundColor.r, rgbBackgroundColor.g, rgbBackgroundColor.b);

        //SH TODO , set app color on infopage

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditor:themeChange');
    },
    btnPaintStrapClick: function (event) {
        var self = this;
        this.saveContent();

        if (self.app.entities.models.configSubscription[0].attributes.EnableTheme == false) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100131;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
            var callback = function (options) {
                self.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            self.listenTo(dialogBox, "dialog:okclicked", callback);
            self.app.dialogRegion.show(dialogBox);
        }
        else {
            //Set selected model in the collection
            var selectedModel = self.options.webpageModel.attributes.Theme;
            self.app.entities.collections.paintStrap.fullCollection.each(function (model) {
                model.attributes.Selected = model.attributes.id == selectedModel ? 1 : 0;
            });
            self.app.entities.collections.paintStrap.fullCollection.sort();
            self.app.entities.collections.paintStrap.getPage(1);

            //Create the dialog
            var dialogPaintStrapModel = new Entities_Empty.Model();
            dialogPaintStrapModel.attributes.Title = resources.X100053;
            dialogPaintStrapModel.attributes.Button01 = resources.X100279;
            dialogPaintStrapModel.attributes.Button02 = resources.X100280;
            var dialogPaintStrap = new View_DialogPaintStrap({ model: dialogPaintStrapModel, parent: self, collection: self.app.entities.collections.paintStrap });

            var themeChanged = false;

            var callback01 = function (options) {
                self.stopListening(dialogPaintStrap, "dialog:btn01clicked", callback01);
                self.stopListening(dialogPaintStrap, "dialog:btn02clicked", callback02);

                //Ok pressed, select the theme
                var tmpModel = self.app.entities.collections.paintStrap.fullCollection.where({ Selected: true });
                if (tmpModel.length > 0) {
                    if (selectedModel != tmpModel[0].attributes.id) {
                        self.options.webpageModel.attributes.Theme = tmpModel[0].attributes.id;
                        self.options.webpageModel.save(self.options.webpageModel.attributes, {
                            success: function () {
                                self.themeChange(tmpModel[0]);
                                self.render();
                            }
                        });
                    }
                }
            }
            self.listenTo(dialogPaintStrap, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialogPaintStrap, "dialog:btn01clicked", callback01);
                self.stopListening(dialogPaintStrap, "dialog:btn02clicked", callback02);
            }
            self.listenTo(dialogPaintStrap, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(dialogPaintStrap);
        }
    },
    btnImageClick: function (event) {
        var self = this;

        var customImage = new View_CustomImage({ collection: app.entities.collections.customImage, app: self.app, designMode: true });
        self.app.designRegion.show(customImage);
    },
    btnFileClick: function (event) {
        var self = this;

        var customFile = new View_CustomFile({ collection: app.entities.collections.customFile, app: self.app, designMode: true });
        self.app.designRegion.show(customFile);
    }
}, Backbone.Radio.Requests);
