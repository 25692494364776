﻿import * as Entities_Empty from '../../lib/entities/empty';
import View_DialogHelp from '../views/dialogHelp/dialogHelp';

var Help = {
    help : function(parent, searchMenu, searchValue) {
        //Create the dialog
        var dialogHelpModel = new Entities_Empty.Model();
        dialogHelpModel.attributes.Title = resources.X101460;
        dialogHelpModel.attributes.Button01 = resources.X100279;

        var collection;

        

        if (searchMenu) {
           collection = parent.app.entities.collections.help.searchMenu(searchValue);
        }else {
            collection = parent.app.entities.collections.help.search(searchValue);
        }

        parent.app.entities.collections.helpPagination.fullCollection.reset(collection, { silent : true });
        parent.app.entities.collections.helpPagination.state.totalRecords = parent.app.entities.collections.helpPagination.fullCollection.length;
        parent.app.entities.collections.helpPagination.setPageSize(parent.app.entities.collections.helpPagination.state.pageSize, {first : true});

        var dialogHelp = new View_DialogHelp({ model: dialogHelpModel, parent: parent, collection: parent.app.entities.collections.helpPagination, app : parent.app });

        var callback01 = function (options) {
            parent.stopListening(dialogHelp, "dialog:btn01clicked", callback01);

        }
        parent.listenTo(dialogHelp, "dialog:btn01clicked", callback01);

        parent.app.dialogRegion.show(dialogHelp);
    }
}

export default Help;