var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customViewEventListItem_empty"> <div id="customViewEventListItem_div_main" style="border-width:1px; border-style:solid; padding:5px;width:100%"> <div class="row"> <div class="control-label col-md-12"> <button id="customViewEventListItem_btnEvent" type="button" class="btn btn-secondary" style="width:40px;height:40px" data-toggle="tooltip" title="'+
((__t=( resources['X100542'] ))==null?'':__t)+
'">'+
((__t=( resources['X100541'] ))==null?'':__t)+
'</button> <button id="customViewEventListItem_btnEventBrowser" type="button" class="btn btn-secondary" style="width:40px;height:40px" data-toggle="tooltip" title="'+
((__t=( resources['X100544'] ))==null?'':__t)+
'">'+
((__t=( resources['X100543'] ))==null?'':__t)+
'</button> <button id="customViewEventListItem_btnEventSchemaScreen" type="button" class="btn btn-secondary" style="width:40px;height:40px" data-toggle="tooltip" title="'+
((__t=( resources['X100546'] ))==null?'':__t)+
'">'+
((__t=( resources['X100545'] ))==null?'':__t)+
'</button> <button id="customViewEventListItem_btnEventInfoScreen" type="button" class="btn btn-secondary" style="width:40px;height:40px" data-toggle="tooltip" title="'+
((__t=( resources['X100548'] ))==null?'':__t)+
'">'+
((__t=( resources['X100547'] ))==null?'':__t)+
'</button> <button id="customViewEventListItem_btnEventPoster" type="button" class="btn btn-secondary" style="width:40px;height:40px" data-toggle="tooltip" title="'+
((__t=( resources['X100550'] ))==null?'':__t)+
'">'+
((__t=( resources['X100549'] ))==null?'':__t)+
'</button> </div> </div> <div class="row"> <div class="control-label col-md-12"> <button id="customViewEventListItem_btnEventCounter" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#474D56" data-toggle="tooltip" title="'+
((__t=( resources['X100233'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-bar-chart" style=""></i> </button> <button id="customViewEventListItem_btnEventEventPush" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#474D56" data-toggle="tooltip" title="'+
((__t=( resources['X100237'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-comment" style=""></i> </button> <button id="customViewEventListItem_btnEventSchemaData" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#474D56" data-toggle="tooltip" title="'+
((__t=( resources['X100250'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-table" style=""></i> </button> <button id="customViewEventListItem_btnEventFormData" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#474D56" data-toggle="tooltip" title="'+
((__t=( resources['X100261'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-pencil-square-o" style=""></i> </button> <button id="customViewEventListItem_btnEventEdit" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#474D56" data-toggle="tooltip" title="'+
((__t=( resources['X100070'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-mobile-alt" style=""></i> </button> <button id="customViewEventListItem_btnEventLive" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#474D56" data-toggle="tooltip" title="'+
((__t=( resources['X100408'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-magic" style=""></i> </button> </div> </div> <div class="row"> <div class="control-label col-md-12"> <button id="customViewEventListItem_btnEventWebpageEditItem" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#333942" data-toggle="tooltip" title="'+
((__t=( resources['X100134'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-html5" style=""></i> </button> <button id="customViewEventListItem_btnEventInfopageEditItem" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#333942" data-toggle="tooltip" title="'+
((__t=( resources['X100451'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-television" style=""></i> </button> <button id="customViewEventListItem_btnEventQRCodeEditItem" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#333942" data-toggle="tooltip" title="'+
((__t=( resources['X100071'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-qrcode" style=""></i> </button>  <button id="customViewEventListItem_btnEventSchemaModelEditItem" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#333942" data-toggle="tooltip" title="'+
((__t=( resources['X100150'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-table" style=""></i> </button> <button id="customViewEventListItem_btnEventFormModelEditItem" type="button" class="btn btn-secondary" style="width:40px;height:40px;background-color:#333942" data-toggle="tooltip" title="'+
((__t=( resources['X100180'] ))==null?'':__t)+
'">  <i class="fa fa-2x fa-pencil-square-o" style=""></i> </button> </div> </div> <div class="row"> <div class="control-label col-md-12"> <label style="font-size: 14px;text-align:center;color:#000000;width:100%"> '+
((__t=( model.Name ))==null?'':__t)+
' </label> </div> </div> </div> </div>';
}
return __p;
};
