var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customAdBigImageListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="margin-top: 15px"> <img class="img-sm" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" alt="profile" style="width:100px;height:100px;cursor:pointer"> <div class="ml-3"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small class="text-muted mb-0"><i class="mdi mdi mdi-star mr-1"></i>'+
((__t=( model.RibbonText ))==null?'':__t)+
'</small><br> <small class="text-muted mb-0"><i class="mdi mdi mdi-move-resize-variant mr-1"></i>'+
((__t=( model.SizeText ))==null?'':__t)+
'</small><br> </div> <div class="ml-auto"> <i id="customAdBigImageListItem_btnDelete" style="color: #6c7293 !important" class="far fa-lg fa-1x fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
