﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customFileList from './tpl/customFileList.html';
import View_CustomFileListItem from './customFileListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customFileList',
    childViewContainer: '#customFileList',
    childView: View_CustomFileListItem,
    getTemplate: function () {
        return tpl_customFileList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    },
    addChild: function (model, ChildView, index) {
        if (model.attributes.TemplateType == 0) {
            return Marionette.CollectionView.prototype.addChild.call(this, model, ChildView, index);
        }
    }
});
