var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentInsert_empty">  <div class="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card" style="padding-left: 0px; padding-right: 0px"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200086'] ))==null?'':__t)+
'</h4> <form class="contact-form" style="margin-bottom:10px"> <div class="control-group customDepartmentInsert_inputName"> <div class="form-group"> <label for="customDepartmentInsert_inputName">'+
((__t=( resources['X200088'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customDepartmentInsert_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customDepartmentInsert_btnSubmit">'+
((__t=( resources['X200089'] ))==null?'':__t)+
'</button> </form> </div> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card" style="padding-left: 0px; padding-right: 0px"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200121'] ))==null?'':__t)+
'</h4> <form class="contact-form"> <div class="row" style="margin-left:0px;margin-top:25px"> <div class="col-md-6" style="padding-left:0px"> <div class="control-group Image" style="display:none"> <div class=""> <label type="button" for="customDepartmentInsert_btnImage" class="btn btn-primary">'+
((__t=( resources['X200062'] ))==null?'':__t)+
'</label> <div id="customDepartmentInsert_div_clone"> <input id="customDepartmentInsert_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> <br> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-md-12"> <input id="customDepartmentInsert_filePond" type="file" class="filepond"> </div> </div> <div class="row"> <div class="row" style="margin-left:0px;margin-right:0px"> <div class="control-label col-md-6" style="padding-left:0px"> <div class="cropper-col cropper-img-container"> <img id="customDepartmentInsert_image" alt="" src="/images/cropper.png"> </div> <div class="cropper-col cropper-img-preview"></div> </div> </div> </div> <div class="row" style="margin-top:50px"> <div class=""> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentInsert_btn_crop_zoom_plus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200063'] ))==null?'':__t)+
'"><i class="fa fa-search-plus"></i></button> <button type="button" id="customDepartmentInsert_btn_crop_zoom_minus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200064'] ))==null?'':__t)+
'"><i class="fa fa-search-minus"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentInsert_btn_crop_move_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200065'] ))==null?'':__t)+
'"><i class="fa fa-arrow-left"></i></button> <button type="button" id="customDepartmentInsert_btn_crop_move_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200066'] ))==null?'':__t)+
'"><i class="fa fa-arrow-right"></i></button> <button type="button" id="customDepartmentInsert_btn_crop_move_top" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200067'] ))==null?'':__t)+
'"><i class="fa fa-arrow-up"></i></button> <button type="button" id="customDepartmentInsert_btn_crop_move_buttom" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200068'] ))==null?'':__t)+
'"><i class="fa fa-arrow-down"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentInsert_btn_crop_rotate_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200069'] ))==null?'':__t)+
'"><i class="fa fa-undo-alt"></i></button> <button type="button" id="customDepartmentInsert_btn_crop_rotate_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200070'] ))==null?'':__t)+
'"><i class="fa fa-redo-alt"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentInsert_btn_crop_scale_x" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200071'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-h"></i></button> <button type="button" id="customDepartmentInsert_btn_crop_scale_y" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200072'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-v"></i></button> </div> </div> </div> <br> </form> </div> </div> </div> </div> ';
}
return __p;
};
