var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentUserListItem_empty"> <div class="card rounded mb-2"> <div class="card-body p-3"> <div class="d-flex justify-content-between"> <div class="mt-3"> <div id="customDepartmentUserListItem_div" class="d-flex align-items-center" style="padding-bottom: 5px !important; border-left:10px solid  #09f;margin-top: 2px"> <div style="font-size:14px;margin-top:5px; margin-left:2px" class="profileImage_small" id="customDepartmentUserListItem_profileImage"></div> <img id="customDepartmentUserListItem_imageUrl" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" class="img-lg rounded-circle mb-2" alt="profile image" style="width:50px;height:50px;margin-bottom: 0px !important;margin-top:5px"> <div class="ml-3"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small style="font-size: 0.9em" class="text-muted mb-0"><i class="mdi mdi-email mr-1"></i>'+
((__t=( model.Email ))==null?'':__t)+
'</small> </div> <div class="ml-3"> <div id="customQRCodeEditItem_qrCodeContainer" style="text-align:center"> <div id="customQRCodeEditItem_container"></div> </div> </div> </div> </div> <div class="mt-3"> <div> <div id="customQRCodeInsert_qrCodeContainer" style="display: none; text-align: center"> <div id="customQRCodeInsert_container"></div> <img id="customQRCodeInsert_qrCodeLogo" crossorigin="anonymous" src="" style="visibility:visible; height:0px"> </div> </div> <div id="render_empty" style="display:none"> <div id="customEventRender_container" style="display:none"> <img id="customEventRender_qrCodeLogo" crossorigin="anonymous" src="" style="visibility:visible; height:0px"> <div id="customEventRender_qrCodeContainer" style="visibility:hidden; height:0px"></div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
