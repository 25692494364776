var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaDataList_empty"> <div id="customSchemaDataList_schemaData"></div> <div class="row"> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3 btnSchemaData" id="customSchemaDataList_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> </div> </div>';
}
return __p;
};
