﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaSetting from './tpl/customSchemaSetting.html';

import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';

import config from '../../../common/config';
import Help from '../../helper/help';
import View_Render from '../render/render';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customSchemaSettingDesign({ model: this.model.toJSON() });
        } else {
            return tpl_customSchemaSetting({ model: this.model.toJSON() });
        }
    },
    regions: {
        information: "#customSchemaSettingInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        if (this.model) {
            customHeaderModel.attributes.Name = this.model.attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X101260;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.EventId = -1;

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick(null);
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customSchemaSetting_btnBack_click();
        });	
    },
    signalR_init: function () {
        //this.IWannaChat = $.connection.LuxaforboardHub;
        //$.connection.hub.start();
    },
    help: function () {
        Help.help(this, true, 'customSchemaLive');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.save(callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        if (this.model) {
            this.setModel();

            if (this.model.attributes.SchemaMenuName != this.oldModel.attributes.SchemaMenuName) {
                changes = true;
            }
        }

        this.setHasChanged(changes);
    },
    ui: {
        'customSchemaSettingInformation': '#customSchemaSettingInformation',
        'customSchemaSetting_div_anySchema': '#customSchemaSetting_div_anySchema',
        'customSchemaSetting_inputNameGroup': '#customSchemaSetting_inputNameGroup',
        'customSchemaSetting_selectEvent': '#customSchemaSetting_selectEvent',
        'customSchemaSetting_divSchemaMenuName': '#customSchemaSetting_divSchemaMenuName',
        'customSchemaSetting_inputSchemaMenuName': '#customSchemaSetting_inputSchemaMenuName',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customSchemaSetting_inputNameGroup': '#customSchemaSetting_inputNameGroup',
        'customSchemaSetting_btnSubmit': '#customSchemaSetting_btnSubmit',
        'customSchemaSetting_btnBack': '#customSchemaSetting_btnBack'
    },
    events: {
        'click @ui.customSchemaSetting_btnSubmit': 'btnSubmitClick',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customSchemaSetting_btnBack	': 'customSchemaSetting_btnBack_click'
    },
    onRender: function () {
        this.setupEvent();
        this.renderInformation();

        if (this.app.mode == 1) {
            this.ui.customSchemaSetting_btnSubmit.css('display', 'none');
            this.ui.customSchemaSetting_btnBack.css('display', 'none');
        }

        return this;
    },
    renderInformation: function () {
        this.informationCollection.reset();

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        if (this.localSetting.attributes.EventId != -1) {
            var tmp = { "id": this.localSetting.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }
    },
    setupEvent: function () {
    },
    setModel: function () {
        this.model.set({
            SchemaMenuName: this.ui.customSchemaSetting_inputSchemaMenuName.val(),
        }, { silent: true });
    },
    save: function (callbackRouter) {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100408;
        dialogWaitingModel.attributes.Text = resources.X100409;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        this.setModel();

        this.model.save(this.model.attributes, {
            silent: true,
            wait: true,
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
                self.oldModel = self.model.clone();

                var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: self.model.attributes.id })
                var render = new View_Render({ collection: customEvent, app: self.app });
                self.app.renderRegion.show(render);
                var renderFinishCallback = function (options) {
                    if (callbackRouter) {
                        callbackRouter();
                    }
                }
                render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
            }
        });
    },
    btnSubmitClick: function (event) {
        if (event) {
            event.preventDefault();
        }

        var self = this;

        this.setModel(this.model);

        this.model.validateNoBackbone(app, false, function (ret) {
            var modelErrors = ret;
            if (modelErrors) {
                self.hideErrors();
                self.showErrors(self.model.errors);
            }
            else {
                self.hideErrors();

                var dialogWaitingModel = new Entities_Empty.Model();
                dialogWaitingModel.attributes.Title = resources.X100408;
                dialogWaitingModel.attributes.Text = resources.X100181;
                dialogWaitingModel.attributes.Button = resources.X100279;

                var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
                var callback = function (options) {
                    self.stopListening(dialogWaiting, "dialog:okclicked", callback);
                }
                self.listenTo(dialogWaiting, "dialog:okclicked", callback);
                self.app.dialogRegion.show(dialogWaiting);

                self.model.save(self.model.attributes, {
                    silent: true,
                    wait: true,
                    success: function () {
                        dialogWaiting.triggerMethod('dialog:close');
                        self.oldModel = self.model.clone();

                        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: self.model.attributes.id })
                        var render = new View_Render({ collection: customEvent, app: self.app });
                        self.app.renderRegion.show(render);
                        var renderFinishCallback = function (options) {
                        }
                        render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
                    }
                });
            }
        });
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    },
    customSchemaSetting_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.model.attributes.id);
        this.app.router.customEventEditItem(this.model.attributes.id, this.options.scroll, 3);
    }
}, Backbone.Radio.Requests);
