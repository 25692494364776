var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFormData_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-table" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100260'] ))==null?'':__t)+
'</h5> <div id="customFormDataInformation"></div> <div class="form-group" id="customFormData_divConfigSubscription"> <div class="control-group customFormData_selectEvent"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormData_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormData_selectEvent"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div id="customFormForm"></div> <br> <h5 class="widget-title">'+
((__t=( resources['X100261'] ))==null?'':__t)+
'<i class="fa fa-list"></i></h5> <div id="customFormDataList"></div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-12"> <button type="button" class="btn btn-secondary btnFormData" id="customFormData_btnDeleteAllSubmit">'+
((__t=( resources['X100376'] ))==null?'':__t)+
'</button> </div> </div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-4"> <a class="btn btn-secondary btnFormData" id="customFormData_btnExport">'+
((__t=( resources['X100284'] ))==null?'':__t)+
'</a> </div> </div>  <div style="height:5px"></div> <div class="row"> </div> <h5 class="widget-title">'+
((__t=( resources['X100390'] ))==null?'':__t)+
'<i class="fa fa-list"></i></h5> <article class="topcontent"> <div id="customFormDataInsert"></div> </article> <br> </div> <div style="height:10px"></div> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
