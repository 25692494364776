﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

export default Marionette.Region.extend({
    el: '#designRegion',
    onShow: function (view) {
        //$('#designRegion').empty();
        //$('.designRegionDialog').remove();
        

        this.designRegion = view.designRegion;

        var self = this;

        self.setupBindings(view);
        self.$el.append(view.$el[0].innerHTML);
        //self.$el.append(view.render());

        this.removeModal = this.$el.find(view.designRegion).modal({ backdrop: 'static', keyboard: false });
        this.removeModal.on('hidden.bs.modal', function () {
            self.closeDialog();
            $('.modal-backdrop').remove();

        }).one('click', '#closebtn', function () {
            self.hideDialog();
        });

        $('.designRegionDialog').remove();
        //$('.modal-backdrop').remove();
        $('.modal-backdrop').removeClass('in');
    },
    onRender: function () {
        //$('.modal-backdrop').remove();
    },
    hideDialog: function () {
        $(this.designRegion).modal("hide");
    },
    setupBindings: function (view) {
        this.listenTo(view, "dialog:close", this.hideDialog);
        this.listenTo(view, "onBtnOk", this.onBtnOk);
    },
    closeDialog: function () {
        this.stopListening();
        this.reset();
        this.removeModal.off();
        $('.e4u-modal-backdrop').remove();
    },
    ui: {
        'designRegion_btnOk': '#designRegion_btnOk'
    },
    events: {
        "click @ui.designRegion_btnOk": "onBtnOk",
        "focus": "onfocus"
    },
    onfocus() {
        alert("HER");
    },
    onBtnOk: function () {
        this.triggerMethod('dialogRegion:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    ee: function () {
        $('#designRegion').empty();
    }
});