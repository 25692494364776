var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventInsert_empty"> <form class="contact-form" style="margin-bottom:10px"> <div class="control-group customEventInsert_inputName"> <div class="form-group"> <label for="customEventInsert_inputName">'+
((__t=( resources['X100303'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customEventInsert_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventInsert_btnSubmit">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventInsert_btnTemplate">'+
((__t=( resources['X101476'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventInsert_btnTest" style="display:none">Test</button> <br> <div id="customQRCodeInsert_qrCodeContainer" style="text-align:center;display:none"> <div id="customQRCodeInsert_container"></div> <img id="customQRCodeInsert_qrCodeLogo" crossorigin="anonymous" src="" style="visibility:visible; height:0px"> </div> </form> </div>';
}
return __p;
};
