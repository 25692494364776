﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from '../../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../../plugins/filepond-plugin-image-edit';


import helper_infopage from '../../../helper/infopage';
import helper_createWebpage from '../../../helper/createWebpage';

import * as Entities_Empty from '../../../../lib/entities/empty';

import tpl_qrcode from './tpl/qrcode.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#qrcode_modal',
    errors: [],
    getTemplate: function () {
        return tpl_qrcode({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'qrcode_input_Url': '#qrcode_input_Url',
        'qrcode_input_TextField': '#qrcode_input_TextField',
        'qrcode_input_Size': '#qrcode_input_Size',
        'qrcode_ColorPickerSliders_Fill': '#qrcode_ColorPickerSliders_Fill',
        'qrcode_ColorPickerSliders_Background': '#qrcode_ColorPickerSliders_Background',
        'qrcode_qrCodeContainer': '#qrcode_qrCodeContainer',
        'qrcode_select_Mode': '#qrcode_select_Mode',
        'qrcode_input_MSize': '#qrcode_input_MSize',
        'qrcode_input_MPosX': '#qrcode_input_MPosX',
        'qrcode_input_MPosY': '#qrcode_input_MPosY',
        'qrcode_input_Label': '#qrcode_input_Label',
        'qrcode_filePond': '#qrcode_filePond',

        'qrcode_btn_crop_zoom_plus': '#qrcode_btn_crop_zoom_plus',
        'qrcode_btn_crop_zoom_minus': '#qrcode_btn_crop_zoom_minus',
        'qrcode_btn_crop_move_left': '#qrcode_btn_crop_move_left',
        'qrcode_btn_crop_move_right': '#qrcode_btn_crop_move_right',
        'qrcode_btn_crop_move_top': '#qrcode_btn_crop_move_top',
        'qrcode_btn_crop_move_buttom': '#qrcode_btn_crop_move_buttom',
        'qrcode_btn_crop_rotate_left': '#qrcode_btn_crop_rotate_left',
        'qrcode_btn_crop_rotate_right': '#qrcode_btn_crop_rotate_right',
        'qrcode_btn_crop_scale_x': '#qrcode_btn_crop_scale_x',
        'qrcode_btn_crop_scale_y': '#qrcode_btn_crop_scale_y',
        'qrcode_btnSubmit': '#qrcode_btnSubmit',

        'qrcode_qrCodeContainer': '#qrcode_qrCodeContainer',
        'qrcode_container': '#qrcode_container',
        'qrcode_img_OrgImage': '#qrcode_img_OrgImage',
        'qrcode_img_Image': '#qrcode_img_Image',

        'qrcode_select_Font1_ColorType': '#qrcode_select_Font1_ColorType',
        'qrcode_ColorPickerSliders_Font1_Color': '#qrcode_ColorPickerSliders_Font1_Color',
        'qrcode_dropDown_Font1_FontType': '#qrcode_dropDown_Font1_FontType',
        'qrcode_dropDown_Font1_Size': '#qrcode_dropDown_Font1_Size',
        'qrcode_select_Font1_FontType': '#qrcode_select_Font1_FontType',
        'qrcode_dropDown_Font1': '#qrcode_dropDown_Font1',
        'qrcode_formgroup_Font1': '#qrcode_formgroup_Font1',
        'qrcode': '#qrcode',
        'qrcode_dropDown_Font1_Style': '#qrcode_dropDown_Font1_Style',
        'qrcode_dropDown_Font1_TextAlign': '#qrcode_dropDown_Font1_TextAlign',
        'qrcode_input_margin1_Top': '#qrcode_input_margin1_Top',
        'qrcode_input_margin1_Bottom': '#qrcode_input_margin1_Bottom',
        'qrcode_input_margin1_Left': '#qrcode_input_margin1_Left',
        'qrcode_input_margin1_Right': '#qrcode_input_margin1_Right',

        'qrcode_checkboxEnableTextField': '#qrcode_checkboxEnableTextField',
        'qrcode_dropDown_DateFormat': '#qrcode_dropDown_DateFormat',
        'qrcode_select_Font2_ColorType': '#qrcode_select_Font2_ColorType',
        'qrcode_ColorPickerSliders_Font2_Color': '#qrcode_ColorPickerSliders_Font2_Color',
        'qrcode_dropDown_Font2_Size': '#qrcode_dropDown_Font2_Size',
        'qrcode_select_Font2_FontType': '#qrcode_select_Font2_FontType',
        'qrcode_dropDown_Font2': '#qrcode_dropDown_Font2',
        'qrcode_formgroup_Font2': '#qrcode_formgroup_Font2',
        'qrcode_dropDown_Font2_Style': '#qrcode_dropDown_Font2_Style',
        'qrcode_dropDown_Font2_TextAlign': '#qrcode_dropDown_Font2_TextAlign',
        'qrcode_input_margin2_Top': '#qrcode_input_margin2_Top',
        'qrcode_input_margin2_Bottom': '#qrcode_input_margin2_Bottom',
        'qrcode_input_margin2_Left': '#qrcode_input_margin2_Left',
        'qrcode_input_margin2_Right': '#qrcode_input_margin2_Right',
        'qrcode_btn01':'#qrcode_btn01',
        'qrcode_btn02': '#qrcode_btn02',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    events: {
        'click @ui.qrcode_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.qrcode_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.qrcode_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.qrcode_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.qrcode_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.qrcode_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.qrcode_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.qrcode_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.qrcode_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.qrcode_btn_crop_scale_y': 'btnScaleYClick',
        'click @ui.qrcode_btnSubmit': 'btnSubmitClick',

        'click @ui.qrcode_btnLogo': 'qrcode_btnLogoClick',

        'change @ui.qrcode_input_Size': 'updateQrCode',
        'change @ui.qrcode_ColorPickerSliders_Fill': 'updateQrCode',
        'change @ui.qrcode_ColorPickerSliders_Background': 'updateQrCode',
        'change @ui.qrcode_input_Quiet': 'updateQrCode',
        'change @ui.qrcode_input_Radius': 'updateQrCode',
        'change @ui.qrcode_select_Mode': 'updateQrCode',
        'change @ui.qrcode_input_MSize': 'updateQrCode',
        'change @ui.qrcode_input_MPosX': 'updateQrCode',
        'change @ui.qrcode_input_MPosY': 'updateQrCode',
        'change @ui.qrcode_input_Label': 'updateQrCode',
        'change @ui.qrcode_dropDown_Font1_Size': 'updateQrCode',
        
        
        'colorpickerChange @ui.qrcode_ColorPickerSliders_Fill': 'colorChange_Fill',
        'colorpickerChange @ui.qrcode_ColorPickerSliders_Background': 'colorChange_Background',
         

       
        'change @ui.qrcode_select_Font1_FontType': 'change_Font1_FontType',
        'change @ui.qrcode_select_Font2_FontType': 'change_Font2_FontType',
        'change @ui.qrcode_select_Font1_ColorType': 'change_Font1_ColorType',
        'change @ui.qrcode_select_Font2_ColorType': 'change_Font2_ColorType',
        'colorpickerChange @ui.qrcode_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.qrcode_ColorPickerSliders_Font2_Color': 'colorChange_Font2Color',
        'click @ui.qrcode_btn01': 'onBtn01',
        'click @ui.qrcode_btn02': 'onBtn02'
    },
    qrcode_btnLogoClick() {
        this.setupFancyBoxImg();
    },
    onRender: function () {
        this.ui.qrcode_select_Mode.val(this.model.attributes.Mode);
    },
    
    onAttach() {
        var self = this;

        this.setupEnableType();
        this.setupFontColorType();
        this.setupFontType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupFontTextAlign();
        
        this.setupFilepond();

        if ($("#qrcode_input_Size").length) {
            var startSlider1 = document.getElementById('qrcode_input_Size');
            nouislider.create(startSlider1, {
                start: self.model.attributes.Size,
                connect: [true, false],
                range: {
                    min: 150,
                    max: 600
                },
                step: 50
            });
            startSlider1.noUiSlider.on('set', function (e) {
                self.model.attributes.Size = parseInt(e);
                self.updateQrCode();
            });
        }

        if ($("#qrcode_input_MSize").length) {
            var startSlider2 = document.getElementById('qrcode_input_MSize');
            nouislider.create(startSlider2, {
                start: self.model.attributes.MSize,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 35
                },
                step: 1
            });
            startSlider2.noUiSlider.on('set', function (e) {
                self.model.attributes.MSize = parseInt(e);
                self.updateQrCode();
            });
        }

        if ($("#qrcode_input_MPosX").length) {
            var startSlider3 = document.getElementById('qrcode_input_MPosX');
            nouislider.create(startSlider3, {
                start: self.model.attributes.MPosX,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 100
                },
                step: 1
            });
            startSlider3.noUiSlider.on('set', function (e) {
                self.model.attributes.MPosX = parseInt(e);
                self.updateQrCode();
            });
        }

        if ($("#qrcode_input_MPosY").length) {
            var startSlider4 = document.getElementById('qrcode_input_MPosY');
            nouislider.create(startSlider4, {
                start: self.model.attributes.MPosY,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 100
                },
                step: 1
            });
            startSlider4.noUiSlider.on('set', function (e) {
                self.model.attributes.MPosY = parseInt(e);
                self.updateQrCode();
            });
        }

        this.ui.qrcode_ColorPickerSliders_Fill.colorpicker({
            color: self.model.attributes.Fill,
            format: "rgb"
        });

        this.ui.qrcode_ColorPickerSliders_Background.colorpicker({
            color: self.model.attributes.Background,
            format: "rgb"
        });

        if (this.model.attributes.SetDefaultImage == 1 && this.model.attributes.OrgImage == "") {
            this.ui.qrcode_img_OrgImage.off("load");
            this.ui.qrcode_img_OrgImage.on('load', function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                // Set width and height
                canvas.width = self.ui.qrcode_img_OrgImage[0].width;
                canvas.height = self.ui.qrcode_img_OrgImage[0].height;
                // Draw the image
                ctx.drawImage(self.ui.qrcode_img_OrgImage[0], 0, 0);
                var ret = canvas.toDataURL('image/png');

                self.model.attributes.OrgImage = ret;
                self.model.attributes.Image = ret;

                self.ui.qrcode_img_OrgImage.off("load");

                self.setupCropper();

                self.ui.qrcode_img_Image.off("load");
                self.ui.qrcode_img_Image.on('load', function () {
                    self.updateQrCode();
                });
                self.ui.qrcode_img_Image.attr("src", self.model.attributes.Image);

            });
            this.ui.qrcode_img_OrgImage.attr("src", this.model.attributes.DefaultImage);
        } else {
            this.ui.qrcode_img_OrgImage.off("load");
            this.ui.qrcode_img_OrgImage.on('load', function () {
                self.setupCropper();
            });
            this.ui.qrcode_img_OrgImage.attr("src", this.model.attributes.OrgImage);

            this.ui.qrcode_img_Image.off("load");
            this.ui.qrcode_img_Image.on('load', function () {
                self.updateQrCode();
            });
            this.ui.qrcode_img_Image.attr("src", this.model.attributes.Image);
        }
    },
    updateQrCode: function () {
        this.setModel();

        this.ui.qrcode_container.empty();

        var tmpModelQRCode = new Entities_Empty.Model();
        var tmpModelQRCode = helper_createWebpage.createQRCodeImageInfo(this.model, tmpModelQRCode, this.ui.qrcode_img_Image, this.ui.qrcode_qrCodeContainer, false, this.app);
        this.model.attributes.ImageData = tmpModelQRCode.attributes.ImageData;
        this.model.attributes.ImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
    },
    colorChange_Fill: function (e) {
        this.model.attributes.Fill = e.value.toRgbString();
        this.updateQrCode();
    },
    colorChange_Background: function (e) {
        this.model.attributes.Background = e.value.toRgbString();
        this.updateQrCode();
    },
    setupEnableType: function () {
        var checked = this.model.attributes.EnableTextField == 1 ? true : false;
        this.ui.qrcode_checkboxEnableTextField.attr('checked', checked);
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.qrcode_select_Font1_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_select_Font1_ColorType.append(options);
        });

        self.ui.qrcode_select_Font2_ColorType.empty();

        selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_select_Font2_ColorType.append(options);
        });
    },
    setupFontType: function () {
        var self = this;

        self.ui.qrcode_select_Font1_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_select_Font1_FontType.append(options);
        });

        self.ui.qrcode_select_Font2_FontType.empty();

        selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_select_Font2_FontType.append(options);
        });
    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.qrcode_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
            self.updateQrCode();
        });
        this.ui.qrcode_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.qrcode_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.qrcode_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.qrcode_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font2: this.value });
            self.model.set({ Font2_Select: this.value });
        });
        this.ui.qrcode_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.qrcode_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.qrcode_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    setupFontSize: function () {
        var self = this;

        this.ui.qrcode_dropDown_Font1_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.qrcode_dropDown_Font1_Size.append(options);
        }

        this.ui.qrcode_dropDown_Font2_Size.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.qrcode_dropDown_Font2_Size.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.qrcode_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.QRCodeFontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_dropDown_Font1_Style.append(options);
        });

        self.ui.qrcode_dropDown_Font2_Style.empty();

        selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_dropDown_Font2_Style.append(options);
        });
    },
    setupFontTextAlign: function () {
        var self = this;

        self.ui.qrcode_dropDown_Font1_TextAlign.empty();
        
        var selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_dropDown_Font1_TextAlign.append(options);
        });

        self.ui.qrcode_dropDown_Font2_TextAlign.empty();

        selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.qrcode_dropDown_Font2_TextAlign.append(options);
        });
    },
    setupFilepond: function () {
        var self = this;

        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,

        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.qrcode_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: true,
            //allowReorder: true,
            //allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            allowImageResize: true,
            imageResizeTargetWidth: 750,
            imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    if (err == null) {
                        var base64 = fileItem.getFileEncodeDataURL();
                        self.model.attributes.OrgImage = base64;
                        self.model.attributes.Image = base64;
                        self.ui.qrcode_img_OrgImage.attr('src', self.model.attributes.OrgImage);
                        self.ui.qrcode_img_Image.attr('src', self.model.attributes.Image);

                        self.setupCropper();

                        pond.removeFile(fileItem);
                    };
                }
            }
        });
    },
    setupCropper: function () {
        var self = this;
        if (self.cropper) {
            self.cropper.destroy();
        }

        //this.ui.qrcode_img_OrgImage.attr('src', this.model.attributes.OrgImage);
        var imageDefault = document.querySelector('#qrcode_img_OrgImage'); 


        this.cropper = new Cropper(imageDefault, {
            //aspectRatio: 1 / 1,
            //minContainerWidth: 100,
            //minContainerHeight: 100,
            autoCropArea: 0.98,
            zoomOnTouch: false,
            zoomOnWheel: false,
            cropBoxResizable: true
        });
    },
    btnSubmitClick: function () {
        var self = this;
        var croppedCanvas;
        var canvas;
        var image;

        croppedCanvas = this.cropper.getCroppedCanvas();
        var imageData = croppedCanvas.toDataURL('image/png', 0.5);
        self.model.attributes.Image = imageData;
        self.ui.qrcode_img_Image.attr('src', imageData);

        this.ui.qrcode_img_Image.off("load");
        this.ui.qrcode_img_Image.on('load', function () {
            self.updateQrCode();
            self.ui.qrcode_img_Image.off("load");
        });
        this.ui.qrcode_img_Image.attr('src', imageData);
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    change_Font1_FontType: function () {
        this.model.set({
            Font1_FontType: this.ui.qrcode_select_Font1_FontType.val()
        });

        this.model.set({
            Font1: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font1_FontType, this.model.attributes.Font1_Select)
        });

        this.ui.qrcode_dropDown_Font1.trigger('setFont', this.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.qrcode_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.qrcode_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.updateQrCode();

    },
    change_Font2_FontType: function () {
        this.model.set({
            Font2_FontType: this.ui.qrcode_select_Font2_FontType.val()
        });

        this.model.set({
            Font2: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font2_FontType, this.model.attributes.Font2_Select)
        });

        this.ui.qrcode_dropDown_Font2.trigger('setFont', this.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.qrcode_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.qrcode_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.qrcode_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font1_ColorType: function () {
        this.model.set({
            Font1_ColorType: this.ui.qrcode_select_Font1_ColorType.val()
        });

        this.model.set({
            Font1_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font1_ColorType, this.model.attributes.Font1_ColorSelect)
        });

        this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable();
        this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font1_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.qrcode_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.updateQrCode();
    },
    change_Font2_ColorType: function () {
        this.model.set({
            Font2_ColorType: this.ui.qrcode_select_Font2_ColorType.val()
        });

        this.model.set({
            Font2_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font2_ColorType, this.model.attributes.Font2_ColorSelect)
        });

        this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable();
        this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font2_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.qrcode_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }

        this.updateQrCode();
    },
    colorChange_Font2Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            this.model.attributes.Font2_ColorSelect = this.model.attributes.Font2_Color;
        }
    },
    btnSetImage: function (event) {
        event.preventDefault();
        this.model.attributes.Image = this.getImage(750, 750);
    },
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    setModel: function () {
        var EnableTextField = this.model.attributes.EnableTextField;
        this.ui.qrcode_checkboxEnableTextField.is(':checked') ? EnableTextField = 1 : EnableTextField = 0;

        this.model.set({
            Url: this.ui.qrcode_input_Url.val(),
            EnableTextField: EnableTextField,
            TextField : this.ui.qrcode_input_TextField.val(),
            Font1_ColorType: this.ui.qrcode_select_Font1_ColorType.val(),
            Font1_FontType: this.ui.qrcode_select_Font1_FontType.val(),
            Font1_Font: this.ui.qrcode_dropDown_Font1.val(),
            Font1_Size: this.ui.qrcode_dropDown_Font1_Size.val(),
            Font1_Style: this.ui.qrcode_dropDown_Font1_Style.val(),
            Font1_TextAlign: this.ui.qrcode_dropDown_Font1_TextAlign.val(),
            Margin1_Top: this.ui.qrcode_input_margin1_Top.val(),
            Margin1_Bottom: this.ui.qrcode_input_margin1_Bottom.val(),
            Margin1_Left: this.ui.qrcode_input_margin1_Left.val(),
            Margin1_Right: this.ui.qrcode_input_margin1_Right.val(),
            DateFormat: this.ui.qrcode_dropDown_DateFormat.val(),
            Font2_ColorType: this.ui.qrcode_select_Font2_ColorType.val(),
            Font2_FontType: this.ui.qrcode_select_Font2_FontType.val(),
            Font2_Font: this.ui.qrcode_dropDown_Font2.val(),
            Font2_Size: this.ui.qrcode_dropDown_Font2_Size.val(),
            Font2_Style: this.ui.qrcode_dropDown_Font2_Style.val(),
            Font2_TextAlign: this.ui.qrcode_dropDown_Font2_TextAlign.val(),
            Margin2_Top: this.ui.qrcode_input_margin2_Top.val(),
            Margin2_Bottom: this.ui.qrcode_input_margin2_Bottom.val(),
            Margin2_Left: this.ui.qrcode_input_margin2_Left.val(),
            Margin2_Right: this.ui.qrcode_input_margin2_Right.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val(),

            Mode: parseInt(this.ui.qrcode_select_Mode.val()),
            Label : this.ui.qrcode_input_Label.val()
        });
    },
    setupCssStyle: function () {
    }
});