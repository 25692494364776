var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetDataImport_empty"> <form class="contact-form"> <div class="panel panel-default"> <div class="panel-heading">'+
((__t=( resources['X100264'] ))==null?'':__t)+
'</div> <div class="panel-body"> <div class="form-group" id="customProductSheetDataImport_div_ShowForm"> <div class="row"> <div class="control-label col-md-6"> <label id="">'+
((__t=( resources['X100256'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="" type="checkbox" id="customProductSheetDataImport_input_ShowForm" checked="checked" style="margin-bottom:0px"> </div> </div> </div> <div class="form-group" id="customProductSheetDataImport_div_ShowInfo"> <div class="row"> <div class="control-label col-md-6"> <label id="">'+
((__t=( resources['X100257'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="" type="checkbox" id="customProductSheetDataImport_input_ShowInfo" checked="checked" style="margin-bottom:0px"> </div> </div> </div> <label type="button" for="customProductSheetDataImport_btnImportSubmit" id="customProductSheetDataImport_labelImport" class="btn btn-secondary btnProductSheetData">'+
((__t=( resources['X100377'] ))==null?'':__t)+
'</label> <input id="customProductSheetDataImport_btnImportSubmit" type="file" accept="*" style="font-size: 20px; display: none"> </div> </div> </form> </div>';
}
return __p;
};
