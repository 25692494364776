var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="padding-bottom: 5px !important"> <div style="font-size:14px;margin-top:5px" class="profileImage_small" id="customDepartmentListItem_profileImage"></div> <img id="customDepartmentListItem_imageUrl" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" class="img-lg rounded-circle mb-2" alt="profile image" style="width:50px;height:50px;margin-bottom: 0px !important;margin-top:5px"> <div class="ml-3" style="margin-top: 5px"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small id="customDepartmentListItem_openLink" style="font-size: 0.9em; cursor: pointer" class="text-muted mb-0"><i class="mdi mdi-television mr-1"></i>'+
((__t=( resources['X200160'] ))==null?'':__t)+
'</small> <br> <small id="customDepartmentListItem_copyLink" style="font-size: 0.9em; cursor: pointer" class="text-muted mb-0"><i class="mdi mdi-clipboard mr-1"></i>'+
((__t=( resources['X200161'] ))==null?'':__t)+
'</small> <br> <small id="customDepartmentListItem_openLinkPomodoro" style="font-size: 0.9em; cursor: pointer" class="text-muted mb-0"><i class="mdi mdi-television mr-1"></i>'+
((__t=( resources['X200293'] ))==null?'':__t)+
'</small> <br>  <small id="customDepartmentListItem_copyLinkPomodoro" style="font-size: 0.9em; cursor: pointer" class="text-muted mb-0"><i class="mdi mdi-clipboard mr-1"></i>'+
((__t=( resources['X200294'] ))==null?'':__t)+
'</small> <br> </div> <input id="customDepartmentUserListItem_inputShowLink" style="height:0px;padding: 0px; border : 0px"> <input id="customDepartmentUserListItem_inputShowLinkPomodoro" style="height:0px;padding: 0px; border : 0px"> <div class="ml-auto"> <i id="customDepartmentListItem_btnEdit" style="color: #6c7293 !important; cursor:pointer" class="fa fa-lg fa-cog font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customDepartmentListItem_btnDelete" style="color: #6c7293 !important; cursor:pointer" class="far fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
