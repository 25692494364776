﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_rssCustom from './tpl/rssCustom.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';


import RSSMarquee from '../../plugins/rss-marquee';
import View_Module from './module';
import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({

    getTemplate: function () {
        return tpl_rssCustom();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.onRender, this);

        this.listenTo(userChannel, 'rightContent:setupCssStyle', function () {
            this.setupStyle();
        });
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'rssCustom': '#rssCustom',
        'marquee': '#marquee',
        'copymarquee': '#copymarquee'
    },
    events: {
    },
    onRender: function () {
        //this.setupStyle();
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        this.setupMargin();
    },
    onDestroy: function () {
    },
    setupStyle: function () {
        var self = this;

        this.ui.marquee.empty();

        const feedUrl = [
            this.getFeed()
            //'https://www.dr.dk/nyheder/service/feeds/allenyheder',
            //'https://sundhedsdatastyrelsen.dk/da/feeds/nyheder'
            //'https://www.dk.dsv.com/rssCustom/nyheder',
            //'https://www.tv2lorry.dk/rssCustom'

        ];

        var feedCount = this.app.entities.collections.FeedCount.where({ Value: this.model.attributes.Data.FeedCount })[0];

        if (this.RSSMarqueeCustom == null) {
            this.RSSMarqueeCustom = new RSSMarquee(feedUrl, this.ui.marquee[0], {
                speed: feedCount.Count,
                maxItems: this.model.attributes.Data.FeedCount,
                hostnameSelector: this.ui.copymarquee[0]
            });
        } else {
            this.RSSMarqueeCustom.setOptions({
                feedUrls: feedUrl,
                speed: this.model.attributes.Data.FeedSpeed,
                maxItems: feedCount.attributes.Count,
                hostnameSelector: this.ui.copymarquee[0],
                feed: this.model.attributes.Data.Feed
            });
        }


        //this.ui.marquee.css('border-width', '5px');
        //this.ui.marquee.css('margin-top', this.model.attributes.Data.MarginTop + 'px');
        //this.ui.marquee.css('margin-bottom', this.model.attributes.Data.MarginBottom + 'px');
        //this.ui.marquee.css('margin-left', this.model.attributes.Data.MarginLeft + 'px');
        //this.ui.marquee.css('margin-right', this.model.attributes.Data.MarginRight + 'px');

        //this.ui.marquee.css('font-family', this.model.attributes.Data.Font);

        //switch (this.model.attributes.Data.Font_FontType) {
        //    case '0': this.ui.marquee.css('font-family', this.model.attributes.Data.Font_Select); break;
        //    case '1': this.ui.marquee.css('font-family', this.model.attributes.Data.Font); break;
        //}

        this.ui.marquee.css('font-family', this.model.attributes.Data.Font);

        this.ui.marquee.css('font-size', this.model.attributes.Data.Font_Size);

        //switch (this.model.attributes.Data.Font_ColorType) {
        //    case '0': this.ui.marquee.css('color', this.model.attributes.Data.Font_ColorSelect); break;
        //    case '1': this.ui.marquee.css('color', this.model.attributes.Data.Font_Color); break;
        //}

        this.ui.marquee.css('color', this.model.attributes.Data.Font_Color);

        var font_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font_Style
        });

        font_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.marquee.css(entry.Css, entry.Value);
        });




    },
    getFeed: function () {
        var ret = "";
        switch (this.model.attributes.Data.FeedType) {
            case 0: ret = this.app.entities.collections.FeedProvider.where({ Value: this.model.attributes.Data.FeedProvider })[0].attributes.Url; break;
            case 1: ret = this.model.attributes.Data.FeedUrl; break;
        }

        return ret;
    },
}, Backbone.Radio.Requests);
