﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_empty from './tpl/empty.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Module from './module';

import View_ModuleItem from './moduleItem';

//export default View_LayoutError.extend({
export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_empty();
    },
    regions: {
        'modal': '#modal'
        //'module': '#module'
    },
    onBeforeShow: function () {
        //this.module.show(new View_Module({ model: this.model, app: this.app, parent: this }));
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'empty': '#empty'
    },
    events: {
    },
    onRender: function () {
        //this.module.show(new View_Module({ model: this.model, app: this.app, parent: this }));
    },
    onAttach() {
    },
    onDestroy: function () {
    },
    setupStyle: function () {
    }
}, Backbone.Radio.Requests);
