var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentImport_empty"> <div class="content-wrapper" data-help="customDepartmentImport"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200147'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div id="customDepartmentImportInformation" class="e4usInformation" style="display:none"></div> <div class="row row mt-3"> <div class="col-12 grid-margin"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200123'] ))==null?'':__t)+
'</h4> <form id="example-vertical-wizard" action="#"> <div> <h3>'+
((__t=( resources['X200132'] ))==null?'':__t)+
'</h3> <section> <p>'+
((__t=( resources['X200150'] ))==null?'':__t)+
'</p> <a href="" class="btn btn-primary btn-sm col-sm-6" id="customDepartmentImportImage_btnSubmit">'+
((__t=( resources['X200124'] ))==null?'':__t)+
'</a> </section> <h3>'+
((__t=( resources['X200147'] ))==null?'':__t)+
'</h3> <section> <p>'+
((__t=( resources['X200147'] ))==null?'':__t)+
'</p> <div id="customDepartmentImportUser"></div> </section> <h3>'+
((__t=( resources['X200148'] ))==null?'':__t)+
'</h3> <section> <p>'+
((__t=( resources['X200148'] ))==null?'':__t)+
'</p> <div id="customDepartmentImportImage"></div> </section> <h3>'+
((__t=( resources['X200128'] ))==null?'':__t)+
'</h3> <section> <p>'+
((__t=( resources['X200128'] ))==null?'':__t)+
'</p> <div class="control-label col-md-12 form-check"> <label class="form-check-label" for="customDepartmentImport_cb_confirm"> '+
((__t=( resources['X200149'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customDepartmentImport_cb_confirm"> <i class="input-helper"></i> </label> </div> </section> </div> </form> </div> </div> </div> </div> <div class="row"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200037'] ))==null?'':__t)+
'</h4> <div class="" id="customDepartmentImportList"></div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customDepartmentImportPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
