var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventEditItem_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customEventEditItem">  <div id="customHeader"></div>  <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="padding-bottom: 8px"> <h4 class="card-title">'+
((__t=( resources['X101265'] ))==null?'':__t)+
'</h4> <p id="charsjs_week_average" class="text-small">23 '+
((__t=( resources['X101266'] ))==null?'':__t)+
'</p> <div class="d-flex justify-content-between align-items-center"> <h2 id="charsjs_week_total" class="text-dark font-weight-bold">43<span class="text-muted text-extra-small">/ '+
((__t=( resources['X101267'] ))==null?'':__t)+
'</span></h2>  </div> <canvas id="charsjs_week"></canvas> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="padding-bottom: 8px"> <h4 class="card-title">'+
((__t=( resources['X101268'] ))==null?'':__t)+
'</h4> <p id="charsjs_month_average" class="text-small">45 '+
((__t=( resources['X101269'] ))==null?'':__t)+
'</p><p> <div class="d-flex justify-content-between align-items-center"> <h2 id="charsjs_month_total" class="text-dark font-weight-bold">120<span class="text-muted text-extra-small">/ '+
((__t=( resources['X101270'] ))==null?'':__t)+
'</span></h2>  </div> <canvas id="charsjs_month"></canvas> </p></div> </div> </div> <div class="col-lg-12 col-xl-6 grid-margin-md stretch-card flex-column d-flex"> <div class="row flex-grow"> <div class="col-sm-12 grid-margin stretch-card"> <div class="card">  <div class="card-body" style="padding-bottom: 8px"> <h4 class="card-title">'+
((__t=( resources['X101271'] ))==null?'':__t)+
'</h4> <p id="charsjs_allPeriod_average" class="text-small">45 '+
((__t=( resources['X101286'] ))==null?'':__t)+
'</p><p> <div class="d-flex justify-content-between align-items-center"> <h2 id="charsjs_allPeriod_total" class="text-dark font-weight-bold">120</h2> </div> <canvas id="charsjs_all" class="mt-3"></canvas>  </p></div> </div> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="text-align: center"> <h4 class="card-title">'+
((__t=( resources['X101272'] ))==null?'':__t)+
'</h4> <img id="customEventEditItem_QRCodeImageUrl" style="height:150px;width:150px" alt="" src="'+
((__t=( model.QRCodeImageUrl ))==null?'':__t)+
'"> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="text-align: center"> <img id="customEventEditItem_webpageImageUrl" style="height:400px; width:200px; text-align:center" alt="" src="'+
((__t=( model.WebpageImageUrl ))==null?'':__t)+
'"> </div> </div> </div> <div class="col-lg-12 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body">  <div class="table-responsive"> <table class="table"> <thead> <tr> <th></th> <th></th> <th></th> <th></th> <th></th> </tr> </thead> <tbody> <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="fa fa-2x fa-mobile-alt" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div class="font-weight-bold text-dark mb-1">'+
((__t=( resources['X101273'] ))==null?'':__t)+
'</div><div class="font-weight-medium"></div> </div> </div> </td> <td width="5%"> <a id="customEventEditItem_navigate_aEventIPad" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-tablet-alt" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101310'] ))==null?'':__t)+
'"></i></span></a> </td> <td width="5%"> <a id="customEventEditItem_navigate_aEvent" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-mobile-alt" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101311'] ))==null?'':__t)+
'"></i></span></a> </td> <td width="10%"> <a id="customEventEditItem_navigate_aEventBrowser" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-tv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101312'] ))==null?'':__t)+
'"></i></span></a> </td> <td width="5%"> <a id="customEventEditItem_navigate_spanEventDesign" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-edit" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101275'] ))==null?'':__t)+
'"></i></span></a> </td>   </tr> <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="fas fa-2x fa-table" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div class="font-weight-bold text-dark mb-1">'+
((__t=( resources['X101276'] ))==null?'':__t)+
'</div><div class="font-weight-medium"></div> </div> </div> </td> <td width="5%"><a id="customEventEditItem_navigate_spanEventSchemaScreen_Direction_H" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-h" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101473'] ))==null?'':__t)+
'"></i></span></a> </td><td width="5%"><a id="customEventEditItem_navigate_spanEventSchemaScreen_Direction_V" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-v" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101474'] ))==null?'':__t)+
'"></i></span></a> </td><td width="10%"><a id="customEventEditItem_navigate_spanEventSchemaScreen" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-tv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101277'] ))==null?'':__t)+
'"></i></span></a></td>  <td width="5%"><a id="customEventEditItem_navigate_spanEventSchemaScreenDesign" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-edit" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101275'] ))==null?'':__t)+
'"></i></span></a> </td></tr>  <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="fas fa-2x fa-desktop" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div class="font-weight-bold text-dark mb-1">'+
((__t=( resources['X101279'] ))==null?'':__t)+
'</div><div class="font-weight-medium"></div> </div> </div> </td>  <td width="5%"><a id="customEventEditItem_navigate_spanEventInfoScreen_Direction_H" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-h" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101473'] ))==null?'':__t)+
'"></i></span></a> </td><td width="5%"><a id="customEventEditItem_navigate_spanEventInfoScreen_Direction_V" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-v" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101474'] ))==null?'':__t)+
'"></i></span></a> </td><td width="5%"><a id="customEventEditItem_navigate_spanEventInfoScreen" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-tv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101280'] ))==null?'':__t)+
'"></i></span></a></td>  <td width="5%"><a id="customEventEditItem_navigate_spanEventInfoScreenDesign" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-edit" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101281'] ))==null?'':__t)+
'"></i></span></a> </td></tr>  <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="fas fa-2x fa-quidditch" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div class="font-weight-bold text-dark mb-1">'+
((__t=( resources['X101282'] ))==null?'':__t)+
'</div><div class="font-weight-medium"></div> </div> </div> </td>  <td width="5%"><a id="customEventEditItem_navigate_spanEventPoster_Direction_H" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-h" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101473'] ))==null?'':__t)+
'"></i></span></a> </td><td width="5%"><a id="customEventEditItem_navigate_spanEventPoster_Direction_V" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-v" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101474'] ))==null?'':__t)+
'"></i></span></a> </td><td width="10%"><a id="customEventEditItem_navigate_spanEventPoster" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-tv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101283'] ))==null?'':__t)+
'"></i></span></a></td>  <td width="5%"> <a id="customEventEditItem_navigate_spanEventPosterDesign" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-edit" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101284'] ))==null?'':__t)+
'"></i></span></a> </td></tr>  <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="fas fa-2x fa-city" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div class="font-weight-bold text-dark mb-1">'+
((__t=( resources['X101452'] ))==null?'':__t)+
'</div><div class="font-weight-medium"></div> </div> </div> </td>  <td width="5%"><a id="customEventEditItem_navigate_spanEventCityInfo_Direction_H" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-h" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101473'] ))==null?'':__t)+
'"></i></span></a> </td><td width="5%"><a id="customEventEditItem_navigate_spanEventCityInfo_Direction_V" href="#"><span style="background-color:transparent"><i style="" class="fas fa-2x fa-arrows-alt-v" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101474'] ))==null?'':__t)+
'"></i></span></a> </td><td width="10%"><a id="customEventEditItem_navigate_spanEventCityInfo" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-tv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101283'] ))==null?'':__t)+
'"></i></span></a></td>  <td width="5%"> <a id="customEventEditItem_navigate_spanEventCityInfoDesign" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="fas fa-2x fa-edit" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101284'] ))==null?'':__t)+
'"></i></span></a> </td></tr>  <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="far fa-2x fa-comment" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div class="font-weight-bold text-dark mb-1">'+
((__t=( resources['X100237'] ))==null?'':__t)+
'</div><div class="font-weight-medium"></div> </div> </div> </td> <td width="5%"></td> <td width="5%"></td> <td width="10%"></td> <td width="5%"><a id="customEventEditItem_navigate_spanEventPushSend" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="far fa-2x fa-comment-alt" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101285'] ))==null?'':__t)+
'"></i></span></a></td>  </tr> <tr> <td width="80%"> <div class="d-flex align-items-center"> <div id="" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="fas fa-2x fa-link" style="color:rgb(255,255,255); animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="Gem"></i></div> <div class="ml-3"> <div id="customEventEditItem_labelShowLink" class="font-weight-bold text-dark mb-1"></div><div class="font-weight-medium"></div> </div> </div> </td> <td width="5%"></td> <td width="5%"></td> <td width="10%"><a id="customEventEditItem_btnShowLink" href="#"><span style="background-color:transparent"><i id="customEventEditItem_iShowLink" style="color:rgb(108, 114, 147)" class="far fa-2x fa-eye" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X100965'] ))==null?'':__t)+
'"></i></span></a></td>  <td width="5%"><a id="customEventEditItem_btnCopyLink" href="#"><span style="background-color:transparent"><i style="color:rgb(108, 114, 147)" class="far fa-2x fa-copy" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X100964'] ))==null?'':__t)+
'"></i></span></a></td>  </tr> </tbody> </table> <input id="customEventEditItem_inputShowLink" style="height:0px;padding: 0px; border : 0px"> </div> </div> </div> </div> </div>  <div class="row"> <div class="col-lg-12 col-xl-6 grid-margin stretch-card flex-column d-flex"> <div class="card"> <div id="customEventEditItem_top" class="card-body" style=""> <h4 class="card-title">'+
((__t=( resources['X101052'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputName">'+
((__t=( resources['X100351'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customEventEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="form-group control-group customEventEditItem_inputEventName"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputEventName">'+
((__t=( resources['X100414'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" value="'+
((__t=( model.EventName))==null?'':__t)+
'" id="customEventEditItem_inputEventName"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group customEventEditItem_textarea_description"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_textarea_description">'+
((__t=( resources['X100468'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <textarea class="form-control" id="customEventEditItem_textarea_description" style="height:78px">'+
((__t=( model.Description))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="control-group customEventEditItem_inputFriendlyUrl"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputPubliclyAddress"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputInformation"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_selectFacebook"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_selectCopyright"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_selectUseDate"> <span class="help-inline" style="color:red"></span> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectPublicly">'+
((__t=( resources['X100041'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6">  <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customEventEditItem_selectPublicly"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputFriendlyUrl">'+
((__t=( resources['X100354'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" value="'+
((__t=( model.FriendlyUrl))==null?'':__t)+
'" id="customEventEditItem_inputFriendlyUrl"> </div> </div> <div class="alert alert-warning hidden" id="custom_alertWarningWrongAddress" style="display:none"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> <strong>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</strong> '+
((__t=( resources['X100287'] ))==null?'':__t)+
' </div> <div class="form-group" id="customEventEditItem_inputPubliclyAddress_div"> <div class="row"> <div class="control-label col-md-12"> <label for="customEventEditItem_inputPubliclyAddress">'+
((__t=( resources['X100042'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customEventEditItem_inputPubliclyAddress" value="'+
((__t=( model.PubliclyAddress ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group"> <label for="customEventEditItem_inputInformation">'+
((__t=( resources['X100047'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Information))==null?'':__t)+
'" id="customEventEditItem_inputInformation"> </div> <div class="row form-group" style="display:none"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectFacebook">'+
((__t=( resources['X100462'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectFacebook" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option>  </select> </div> </div> </div> <div id="customEventEditItem_divCopyright" style="display:none"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectCopyright">'+
((__t=( resources['X100480'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectCopyright" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div id="customEventEditItem_divInfopageShowQRCode" style="display:none"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectInfopageShowQRCode">'+
((__t=( resources['X100556'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectInfopageShowQRCode" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div id="customEventEditItem_divSchemaBigScreenShowQRCode" style="display:none"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectSchemaBigScreenShowQRCode">'+
((__t=( resources['X100557'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectSchemaBigScreenShowQRCode" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectUseDate">'+
((__t=( resources['X100461'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectUseDate" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100045'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100046'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> <div class="form-group" id="customEventEditItem_divDatepicker"> <label for="customEventEditItem_datepicker">'+
((__t=( resources['X100044'] ))==null?'':__t)+
'</label> <div class="input-daterange input-group col-md-12" id="customEventEditItem_datepicker"> <input class="input-sm form-control" name="start"> <span class="input-group-addon" id="customEventEditItem_spanDatePicker">'+
((__t=( resources['X100277'] ))==null?'':__t)+
'</span> <input class="input-sm form-control" name="end"> </div> </div> <div style="display:none"> <div class="form-group control-group customEventEditItem_selectWebpage"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectWebpage">'+
((__t=( resources['X100347'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectWebpage" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="form-group control-group customEventEditItem_selectInfopage"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectInfopage">'+
((__t=( resources['X100453'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectInfopage" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="form-group control-group customEventEditItem_selectQRCode"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectQRCode">'+
((__t=( resources['X100348'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectQRCode" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3" style="" id="customEventEditItem_btnSubmit01">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> </div> </form> </div> </div> </div> <div class="col-lg-12 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customEventInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X101237'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="row"> <div class="col-4"> <ul class="nav nav-tabs nav-tabs-vertical" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="customEventEditItem_nav_moduletab01" data-toggle="tab" href="#customEventEditItem_moduletab01" role="tab" aria-controls="customEventEditItem_moduletab01" aria-selected="true"> <i class="fas fa-lg fa-qrcode"></i> '+
((__t=( resources['X101240'] ))==null?'':__t)+
' </a> </li> <li class="nav-item"> <a class="nav-link" id="customEventEditItem_nav_moduletab02" data-toggle="tab" href="#customEventEditItem_moduletab02" role="tab" aria-controls="customEventEditItem_moduletab02" aria-selected="false"> <i class="fab fa-lg fa-facebook-f"></i> '+
((__t=( resources['X101241'] ))==null?'':__t)+
' </a> </li> <li class="nav-item"> <a class="nav-link" id="customEventEditItem_nav_moduletab03" data-toggle="tab" href="#customEventEditItem_moduletab03" role="tab" aria-controls="customEventEditItem_moduletab03" aria-selected="false"> <i class="fas fa-lg fa-table"></i> '+
((__t=( resources['X101242'] ))==null?'':__t)+
' </a> </li> <li class="nav-item"> <a class="nav-link" id="customEventEditItem_nav_moduletab04" data-toggle="tab" href="#customEventEditItem_moduletab04" role="tab" aria-controls="customEventEditItem_moduletab04" aria-selected="false"> <i class="fab fa-lg fa-wpforms"></i> '+
((__t=( resources['X101243'] ))==null?'':__t)+
' </a> </li> <li class="nav-item"> <a class="nav-link" id="customEventEditItem_nav_moduletab05" data-toggle="tab" href="#customEventEditItem_moduletab05" role="tab" aria-controls="customEventEditItem_moduletab05" aria-selected="false"> <i class="fas fa-lg fa-ad"></i> '+
((__t=( resources['X101262'] ))==null?'':__t)+
' </a> </li> </ul> </div> <div class="col-8"> <div class="tab-content tab-content-vertical"> <div class="tab-pane fade active show" id="customEventEditItem_moduletab01" role="tabpanel" aria-labelledby="customEventEditItem_nav_moduletab01"> <div class="d-flex justify-content-between align-items-center"> <div> <div class="d-flex align-items-center"> <h4>'+
((__t=( resources['X101240'] ))==null?'':__t)+
'</h4> </div> </div>  </div> <p> '+
((__t=( resources['X101244'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_QrCode_design_p"> <i style="min-width:20px" class="fas fa-lg fa-eye"></i> '+
((__t=( resources['X101245'] ))==null?'':__t)+
' </p><p> </div> <div class="tab-pane fade" id="customEventEditItem_moduletab02" role="tabpanel" aria-labelledby="customEventEditItem_nav_moduletab02"> <div class="d-flex justify-content-between align-items-center"> <div> <div class="d-flex align-items-center"> <h4>'+
((__t=( resources['X101241'] ))==null?'':__t)+
'</h4> </div> </div> <div> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customEventEditItem_checkbox_Module_Facebook"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <p> '+
((__t=( resources['X101246'] ))==null?'':__t)+
' </p> </div> <div class="tab-pane fade" id="customEventEditItem_moduletab03" role="tabpanel" aria-labelledby="customEventEditItem_nav_moduletab03"> <div class="d-flex justify-content-between align-items-center"> <div> <div class="d-flex align-items-center"> <h4>'+
((__t=( resources['X101242'] ))==null?'':__t)+
'</h4> </div> </div> <div> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customEventEditItem_checkbox_Module_SchemaModel"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <p> '+
((__t=( resources['X101247'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_SchemaModel_design_p"> <i style="min-width:20px" class="fas fa-lg fa-eye"></i> '+
((__t=( resources['X101248'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_SchemaModel_data_p"> <i style="min-width:20px" class="fas fa-lg fa-database"></i> '+
((__t=( resources['X101249'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_SchemaModel_setting_p"> <i style="min-width:20px" class="fa fa-lg fa-cog"></i> '+
((__t=( resources['X101250'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_SchemaModel_livedata_p"> <i style="min-width:20px" class="fas fa-lg fa-star-of-life"></i> '+
((__t=( resources['X101251'] ))==null?'':__t)+
' </p>  </div> <div class="tab-pane fade" id="customEventEditItem_moduletab04" role="tabpanel" aria-labelledby="customEventEditItem_nav_moduletab04"> <div class="d-flex justify-content-between align-items-center"> <div> <div class="d-flex align-items-center"> <h4>'+
((__t=( resources['X101243'] ))==null?'':__t)+
'</h4> </div> </div> <div> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customEventEditItem_checkbox_Module_FormModel"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <p> '+
((__t=( resources['X101252'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_FormModel_design_p"> <i style="min-width:20px" class="fas fa-lg fa-eye"></i> '+
((__t=( resources['X101253'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_FormModel_data_p"> <i style="min-width:20px" class="fas fa-lg fa-database"></i> '+
((__t=( resources['X101254'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_FormModel_setting_p"> <i style="min-width:20px" class="fa fa-lg fa-cog"></i> '+
((__t=( resources['X101255'] ))==null?'':__t)+
' </p>  </div> <div class="tab-pane fade" id="customEventEditItem_moduletab05" role="tabpanel" aria-labelledby="customEventEditItem_nav_moduletab05"> <div class="d-flex justify-content-between align-items-center"> <div> <div class="d-flex align-items-center"> <h4>'+
((__t=( resources['X101262'] ))==null?'':__t)+
'</h4> </div> </div> <div> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customEventEditItem_checkbox_Module_Ad"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <p> '+
((__t=( resources['X101263'] ))==null?'':__t)+
' </p> <p id="customEventEditItem_Ad_setting_p"> <i style="min-width:20px" class="fa fa-lg fa-cog"></i> '+
((__t=( resources['X101255'] ))==null?'':__t)+
' </p> </div> </div> </div> </div> </form> </div> </div> </div> </div> <div class="row" id="customEventEditItem_div_Admin"> <div class="col-lg-12 col-xl-6 grid-margin stretch-card flex-column d-flex"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X100048'] ))==null?'':__t)+
'</h4> </div> <form class="forms-sample"> <div class="accordion accordion-bordered" id="accordion-3" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-7"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse01" aria-expanded="false" aria-controls="collapse01"> '+
((__t=( resources['X101482'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse01" class="collapse" role="tabpanel" aria-labelledby="heading-4" data-parent="#accordion-3"> <div class="" style="margin-bottom:20px"> <div class="row" style="margin-left: 0px"> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100049'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customEventEditItem_input_HelpBtn" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100050'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customEventEditItem_input_HomeBtn" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> </div> </div> </div> </div> </div> </form> </div> </div> </div>  </div> </div>';
}
return __p;
};
