﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_footer from './tpl/footer.html';

export default Marionette.LayoutView.extend({
    initialize: function () {
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');

        var self = this;
        
        //this.listenTo(userChannel, 'customMenu:enable', function() {
        //    self.options.enable = true;
        //    self.initMenu(true);
        //});

        //this.listenTo(userChannel, 'customMenu:disable', function() {
        //    self.options.enable = false;
        //    self.initMenu(false);
        //});
    },
    getTemplate: function () {
        return tpl_footer();
    },
    ui: {
    },
    events: {
        //'click #custom_menu11_a': 'help'
    },
    onRender: function () {
        
    },
    onAttach(){
    }
}, Backbone.Radio.Requests);
