var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="digitalClock_empty"> <div class="modal fade" id="digitalClock_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X200327'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X200220'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home4-tab-1" data-toggle="tab" href="#home4-1" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X101027'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home4-tab-2" data-toggle="tab" href="#home4-2" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home4-tab-3" data-toggle="tab" href="#home4-3" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X200221'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home4-1" role="tabpanel" aria-labelledby="home4-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="control-label col-md-6"> <label for="digitalClock_checkboxEnableModule">'+
((__t=( resources['X200326'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="digitalClock_checkboxEnableModule"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home4-2" role="tabpanel" aria-labelledby="home4-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_ColorPickerSliders_Background1_Color">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</label> <div id="digitalClock_ColorPickerSliders_Background1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home4-3" role="tabpanel" aria-labelledby="home4-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X200222'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X200223'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X200224'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X200225'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-2"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2"> '+
((__t=( resources['X200226'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-2" class="collapse" role="tabpanel" aria-labelledby="heading-2" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="homeb-tab-1" data-toggle="tab" href="#homeb-1" role="tab" aria-controls="homeb-1" aria-selected="true">'+
((__t=( resources['X200220'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homeb-tab-2" data-toggle="tab" href="#homeb-2" role="tab" aria-controls="homeb-2" aria-selected="false">'+
((__t=( resources['X200227'] ))==null?'':__t)+
'</a> </li>  </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="homeb-1" role="tabpanel" aria-labelledby="homeb-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="control-label col-md-6"> <label for="digitalClock_checkboxEnableClock">'+
((__t=( resources['X200228'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="digitalClock_checkboxEnableClock"> <span class="toggle-slider round"></span> </label> </div> </div> <div class="control-label col-md-6"> <label for="digitalClock_checkboxEnable24Hours">'+
((__t=( resources['X200229'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="digitalClock_checkboxEnable24Hours"> <span class="toggle-slider round"></span> </label> </div> </div> <div class="control-label col-md-6"> <label for="digitalClock_checkboxEnableSeconds">'+
((__t=( resources['X200322'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="digitalClock_checkboxEnableSeconds"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homeb-2" role="tabpanel" aria-labelledby="homeb-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="digitalClock_dropDown_Font1">'+
((__t=( resources['X200230'] ))==null?'':__t)+
'</label> <div id="digitalClock_formgroup_Font1" class="form-group"> <input id="digitalClock_dropDown_Font1" class="form-control"> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X200231'] ))==null?'':__t)+
'</label> <div id="digitalClock_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_dropDown_Font1_Size">'+
((__t=( resources['X200232'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_Font1_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_dropDown_Font1_Style">'+
((__t=( resources['X200233'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_Font1_Style" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6" style="display:none"> <div class="form-group"> <label for="digitalClock_dropDown_Font1_TextAlign">'+
((__t=( resources['X200234'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_Font1_TextAlign" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-3" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin1_Top"> <div class="control-label"> <label for="digitalClock_input_margin1_Top">'+
((__t=( resources['X200235'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Top))==null?'':__t)+
'" id="digitalClock_input_margin1_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin1_Bottom"> <div class="control-label"> <label for="digitalClock_input_margin1_Bottom">'+
((__t=( resources['X200236'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Bottom))==null?'':__t)+
'" id="digitalClock_input_margin1_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin1_Left"> <div class="control-label"> <label for="digitalClock_input_margin1_Left">'+
((__t=( resources['X200237'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Left))==null?'':__t)+
'" id="digitalClock_input_margin1_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin1_Right"> <div class="control-label"> <label for="digitalClock_input_margin1_Right">'+
((__t=( resources['X200238'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Right))==null?'':__t)+
'" id="digitalClock_input_margin1_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-2"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3"> '+
((__t=( resources['X200239'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-3" class="collapse" role="tabpanel" aria-labelledby="heading-3" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="homea-tab-1" data-toggle="tab" href="#homea-1" role="tab" aria-controls="homea-1" aria-selected="true">'+
((__t=( resources['X200220'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homea-tab-2" data-toggle="tab" href="#homea-2" role="tab" aria-controls="homea-2" aria-selected="false">'+
((__t=( resources['X200227'] ))==null?'':__t)+
'</a> </li>  </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="homea-1" role="tabpanel" aria-labelledby="home-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="control-label col-md-6"> <label for="digitalClock_checkboxEnableDate">'+
((__t=( resources['X200240'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="digitalClock_checkboxEnableDate"> <span class="toggle-slider round"></span> </label> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_dropDown_DateFormat">'+
((__t=( resources['X200241'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_DateFormat" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homea-2" role="tabpanel" aria-labelledby="home-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="digitalClock_dropDown_Font2">'+
((__t=( resources['X200230'] ))==null?'':__t)+
'</label> <div id="digitalClock_formgroup_Font2" class="form-group"> <input id="digitalClock_dropDown_Font2" class="form-control"> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_ColorPickerSliders_Font2_Color">'+
((__t=( resources['X200231'] ))==null?'':__t)+
'</label> <div id="digitalClock_ColorPickerSliders_Font2_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_dropDown_Font2_Size">'+
((__t=( resources['X200232'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_Font2_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="digitalClock_dropDown_Font2_Style">'+
((__t=( resources['X200233'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_Font2_Style" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6" style="display:none"> <div class="form-group"> <label for="digitalClock_dropDown_Font2_TextAlign">'+
((__t=( resources['X200234'] ))==null?'':__t)+
'</label> <select id="digitalClock_dropDown_Font2_TextAlign" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homea-3" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin2_Top"> <div class="control-label"> <label for="digitalClock_input_margin2_Top">'+
((__t=( resources['X200235'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Top))==null?'':__t)+
'" id="digitalClock_input_margin2_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin2_Bottom"> <div class="control-label"> <label for="digitalClock_input_margin2_Bottom">'+
((__t=( resources['X200236'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Bottom))==null?'':__t)+
'" id="digitalClock_input_margin2_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin2_Left"> <div class="control-label"> <label for="digitalClock_input_margin2_Left">'+
((__t=( resources['X200237'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Left))==null?'':__t)+
'" id="digitalClock_input_margin2_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group digitalClock_input_margin2_Right"> <div class="control-label"> <label for="digitalClock_input_margin2_Right">'+
((__t=( resources['X200238'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Right))==null?'':__t)+
'" id="digitalClock_input_margin2_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="digitalClock_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="digitalClock_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
