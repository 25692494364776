var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogHelp_empty"> <div class="modal fade" id="dialogHelp_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="max-height:90%"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title" id="myModalLabel" style="">'+
((__t=( resources['X101460'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body" style="height: 500px;overflow-y: auto"> <div class="row"> <div class="col-md-12"> <form class="contact-form" style="margin-bottom:10px"> <div class="form-group"> <label for="Help_search">'+
((__t=( resources['X100016'] ))==null?'':__t)+
'</label> <input class="input-sm form-control" id="help_input"> <button type="button" class="btn btn-primary btn-sm col-sm-2 mt-1" id="help_btnSearch">'+
((__t=( resources['X100017'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm col-sm-2 mt-1" id="help_btnReset">'+
((__t=( resources['X100018'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> <div class="row"> <div id="dialogHelpList"></div> </div> <div class="row"> <div class="col-md-12"> <div id="dialogHelpPagination" style="text-align:center"></div> </div> </div> </div> <div class="modal-footer" style="padding-top: 5px"> <button type="button" id="dialog2Button_btn01" class="btn btn-primary btn-sm col-sm-2">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
