var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="module_empty"> <div class="modal fade" id="module_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101340'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101360'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_dropDown_backGroundType">'+
((__t=( resources['X101358'] ))==null?'':__t)+
'</label> <select id="module_dropDown_backGroundType" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_input_backGroundColorOpacity">'+
((__t=( resources['X101347'] ))==null?'':__t)+
'</label> <div id="module_input_backGroundColorOpacity" class="ul-slider slider-success" style="height: 20px"></div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-3 col-md-3 col-xl-3"> <div class="form-group"> <label for="">'+
((__t=( resources['X101360'] ))==null?'':__t)+
'</label> </div> <div class="form-group"> <img id="module_image_background" class="mr-3 rounded" style="max-width:50px;width:50px;height:auto" src=""> </div> </div> <div class="col-12 col-sm-3 col-md-3 col-xl-3"> <div class="form-group"> <label for=""> </label> </div> <div class="form-group"> <label type="button" for="module_btnImage" class="btn btn-sm col-sm-12 btn-secondary" style="margin-top: 5px">'+
((__t=( resources['X100295'] ))==null?'':__t)+
'</label> <div> <input id="module_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_dropDown_backGroundImagePosition">'+
((__t=( resources['X101364'] ))==null?'':__t)+
'</label> <select id="module_dropDown_backGroundImagePosition" class="form-control"></select> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_ColorPickerSliders_backGroundColorApp">'+
((__t=( resources['X101359'] ))==null?'':__t)+
'</label> <div id="module_ColorPickerSliders_backGroundColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_ColorPickerSliders_backGroundColor">'+
((__t=( resources['X101346'] ))==null?'':__t)+
'</label> <div id="module_ColorPickerSliders_backGroundColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101361'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="d-flex align-items-center"> <div class="col"> <p class="mb-2">'+
((__t=( resources['X101353'] ))==null?'':__t)+
'</p> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="module_cb_border"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_ColorPickerSliders_borderColor">'+
((__t=( resources['X101354'] ))==null?'':__t)+
'</label> <div id="module_ColorPickerSliders_borderColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_dropDown_borderStyle">'+
((__t=( resources['X101355'] ))==null?'':__t)+
'</label> <select id="module_dropDown_borderStyle" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="module_dropDown_borderSize">'+
((__t=( resources['X101356'] ))==null?'':__t)+
'</label> <select id="module_dropDown_borderSize" class="form-control"></select> </div> </div> </div> </div> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101348'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_marginTop"> <div class="row"> <div class="control-label col-md-3"> <label for="module_input_marginTop">'+
((__t=( resources['X101349'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-3"> <input class="form-control form-control-sm" value="'+
((__t=( model.MarginTop))==null?'':__t)+
'" id="module_input_marginTop"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group control-group module_input_marginBottom"> <div class="row"> <div class="control-label col-md-3"> <label for="module_input_marginBottom">'+
((__t=( resources['X101350'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-3"> <input class="form-control form-control-sm" value="'+
((__t=( model.MarginBottom))==null?'':__t)+
'" id="module_input_marginBottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_marginLeft"> <div class="row"> <div class="control-label col-md-3"> <label for="module_input_marginLeft">'+
((__t=( resources['X101351'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-3"> <input class="form-control form-control-sm" value="'+
((__t=( model.MarginLeft))==null?'':__t)+
'" id="module_input_marginLeft"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group control-group module_input_marginRight"> <div class="row"> <div class="control-label col-md-3"> <label for="module_input_marginRight">'+
((__t=( resources['X101352'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-3"> <input class="form-control form-control-sm" value="'+
((__t=( model.MarginRight))==null?'':__t)+
'" id="module_input_marginRight"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="module_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="module_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
