﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import tpl_customInfopageEditItemTopbar from './tpl/customInfopageEditItemTopbar.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    className: 'row',
    getTemplate: function () {
        return tpl_customInfopageEditItemTopbar({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.listenTo(userChannel, 'customInfopageEditor:saveContent', function () {
        });

        this.localSetting.attributes.themeChanged = false;

        this.listenTo(userChannel, 'customInfopageEditor:themeChange', function () {
            self.localSetting.attributes.themeChanged = true;
        });

        /*this.listenTo(userChannel, 'customInfopageEditItemTopbar:setZoom', function () {
            var zoom = self.calcZoom();
            self.updateZoom(zoom);
        });*/

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customInfopageEditItemTopbar');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmit(null, false, true, callback, false);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'customHeader_iconSave': '#customHeader_iconSave',
        'customHeader_iconBack': '#customHeader_iconBack',
        'customInfopageEditItemTopbar_iconSave': '#customInfopageEditItemTopbar_iconSave',
        'customInfopageEditItemTopbar_iconFileCode': '#customInfopageEditItemTopbar_iconFileCode',
        'customInfopageEditItemTopbar_iconFile': '#customInfopageEditItemTopbar_iconFile',
        'customInfopageEditItemTopbar_iconImage': '#customInfopageEditItemTopbar_iconImage',
        'customInfopageEditItemTopbar_iconPalette': '#customInfopageEditItemTopbar_iconPalette',
        'customInfopageEditItemTopbar_iconCog': '#customInfopageEditItemTopbar_iconCog',
        'customInfopageEditItemTopbar_iconBars': '#customInfopageEditItemTopbar_iconBars',
        'customInfopageEditItemTopbar_iconCaretDown': '#customInfopageEditItemTopbar_iconCaretDown',
        'customInfopageEditItemTopbar_iconCopy': '#customInfopageEditItemTopbar_iconCopy',
        'customInfopageEditItemTopbar_iconArrowsAlt': '#customInfopageEditItemTopbar_iconArrowsAlt',
        'customInfopageEditItemTopbar_tablet_alt': '#customInfopageEditItemTopbar_tablet_alt',
        'customInfopageEditItemTopbar_mobile_alt': '#customInfopageEditItemTopbar_mobile_alt',
        'customInfopageEditItemTopbar_wpforms': '#customInfopageEditItemTopbar_wpforms',
        'customInfopageEditItemTopbar_th': '#customInfopageEditItemTopbar_th',
        'customInfopageEditItemTopbar_div_Zoom': '#customInfopageEditItemTopbar_div_Zoom',
        'customInfopageEditItemTopbar_tv': '#customInfopageEditItemTopbar_tv',
        'customInfopageEditItemTopbar_dias': '#customInfopageEditItemTopbar_dias',

        'help': '#help'
    },
    events: {
        'click @ui.customHeader_iconSave': 'iconSaveClick',
        'click @ui.customHeader_iconBack': 'iconBackClick',
        'click @ui.customInfopageEditItemTopbar_iconSave': 'iconSaveClick',
        'click @ui.customInfopageEditItemTopbar_iconFileCode': 'iconFileCodeClick',
        'click @ui.customInfopageEditItemTopbar_iconFile': 'iconFileClick',
        'click @ui.customInfopageEditItemTopbar_iconImage': 'iconImageClick',
        'click @ui.customInfopageEditItemTopbar_iconPalette': 'iconPaletteClick',
        'click @ui.customInfopageEditItemTopbar_iconCog': 'iconCogClick',
        'click @ui.customInfopageEditItemTopbar_iconBars': 'iconBarsClick',
        'click @ui.customInfopageEditItemTopbar_iconCaretDown': 'iconCaretDownClick',
        'click @ui.customInfopageEditItemTopbar_iconCopy': 'iconCopyClick',
        'click @ui.customInfopageEditItemTopbar_iconArrowsAlt': 'iconArrowsAltClick',
        'click @ui.customInfopageEditItemTopbar_tablet_alt': 'iconTabletAltClick',
        'click @ui.customInfopageEditItemTopbar_mobile_alt': 'iconMobileAltClick',
        'click @ui.customInfopageEditItemTopbar_wpforms': 'iconWpClick',
        'click @ui.customInfopageEditItemTopbar_wpforms': 'iconThClick',
        'click @ui.customInfopageEditItemTopbar_tv': 'iconTvClick',
        'click @ui.customInfopageEditItemTopbar_dias': 'iconDiasClick',

        'click #help': 'help'
    },
    onRender: function () {
    },
    calcZoom() {

        var editorPos = $('#customInfopageEditItemLeftContent').offset();
        var editorHeight = (window.innerHeight - editorPos.top);

        var contentHeight = editorHeight;
        $('#customInfopageEditItemLeftContent').css('height', contentHeight + 'px');

        $('#rowChangeHeight').css('height', (contentHeight - 8) + 'px');
        $('#cardBodyChangeHeight').css('height', contentHeight + 'px');
        $('#formsSampleChangeHeight').css('height', contentHeight + 'px');
        $('#template').css('height', contentHeight + 'px');
        $('#templateDiv').css('height', contentHeight + 'px');

        $('#customInfopageEditItemRightContent').css('height', contentHeight + 'px');

        var maxHeight = parseInt(($('#customInfopageEditItemLeftContent').height() / 1200) * 100);
        var maxWidth = parseInt(($('#customInfopageEditItemLeftContent').width() / 1980) * 100);

        var ret = Math.min(maxHeight, maxWidth);

        return ret;
    },
    onAttach() {
        var self = this;
        var zoom = self.calcZoom();

        if ($("#customInfopageEditItemTopbar_div_Zoom").length) {
            var startSlider1 = document.getElementById('customInfopageEditItemTopbar_div_Zoom');
            nouislider.create(startSlider1, {
                start: zoom,
                connect: [true, false],
                range: {
                    min: 1,
                    max: 100
                },
                step: 1,
                pips: {
                    mode: 'values',
                    values: [1, 100],
                    density: 10
                }
            });
            startSlider1.noUiSlider.on('set', function (e) {
                //self.updateZoom(parseInt(e));
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('template:setZoomBySlider', parseInt(e));
            });
        }

        //self.updateZoom(zoom);
    },
    /*iconSaveClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customHeader:iconSave');
    },*/
    iconBackClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customHeader:iconBack');
    },
    iconSaveClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconSave');
    },
    iconFileCodeClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconFileCode');
    },
    iconFileClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconFile');
    },
    iconImageClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconImage');
    },
    iconPaletteClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconPalette');
    },
    iconCogClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconCog');
    },
    iconBarsClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconBars');
    },
    iconCaretDownClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconCaretDown');
    },
    iconCopyClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconCopy');
    },
    iconArrowsAltClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconArrowsAlt', true);
    },
    iconTabletAltClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconTabletAlt');
    },
    iconMobileAltClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconMobileAlt');
    },
    iconWpClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconWpforms');
    },
    iconThClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconTh');
    },
    iconTvClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconTv');
    },
    iconDiasClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemTopbar:iconDias');
    },

    /*setZoom(zoom, el) {

        if (el != undefined) {

            var transformOrigin = [0, 0];
            el = el || instance.getContainer();
            var p = ["webkit", "moz", "ms", "o"],
                s = "scale(" + zoom + ")",
                oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

            for (var i = 0; i < p.length; i++) {
                el.style[p[i] + "Transform"] = s;
                el.style[p[i] + "TransformOrigin"] = oString;
            }

            el.style["transform"] = s;
            el.style["transformOrigin"] = oString;

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('template:transform', s);
            userChannel.trigger('template:transformOrigin', oString);
        }
    },
    setZoomNot(zoom, el) {

        var transformOrigin = [0, 0];
        el = el || instance.getContainer();
        var p = ["webkit", "moz", "ms", "o"],
            s = "scale(" + zoom + ")",
            oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

        for (var i = 0; i < p.length; i++) {
            el.style[p[i] + "Transform"] = s;
            //el.style[p[i] + "TransformOrigin"] = oString;
        }

        el.style["transform"] = s;
        //el.style["transformOrigin"] = oString;

        userChannel.trigger('template:transform', s);
        //userChannel.trigger('template:transformOrigin', oString);
    },*/
    //updateZoom(a) {
    //    var zoomScale = Number(a) / 100;

    //    if (document.getElementsByClassName('zoomContainerH') != undefined) {
    //        this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerH')[0]);
    //    }

    //    if (document.getElementsByClassName('zoomContainerV') != undefined) {
    //        this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerV')[0]);
    //    }

    //    var zoomNotScale = 1 / zoomScale;
    //    var x = document.querySelectorAll(".zoomNot");
    //    var i;
    //    for (i = 0; i < x.length; i++) {
    //        this.setZoomNot(zoomNotScale, x[i]);
    //    }

    //    $('.infopageeditor').each(function (i, obj) {
    //        var element = obj.closest(".moduleRegion");
    //        $(obj).css('max-height', (($(element).height() - 60) - (parseInt($(element).css('border-width').slice(0, -2) * 2))) + 'px');
    //    });
    //}
}, Backbone.Radio.Requests);


