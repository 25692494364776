var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSubscriptionWaiting"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customSubscription"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0">  <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X101084'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customSubscriptionInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X101084'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="MAKELATER" style="display:none"> <div class="control-group customSubscription_selectSubscription"> <div class="form-group"> <label for="customSubscription_selectSubscription">'+
((__t=( resources['X100085'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customSubscription_selectSubscription" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="alert alert-warning" id="customSubscription_selectDescription"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> </div> <button type="button" class="btn btn-primary" id="customSubscription_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button>  </div> <div id="customSubscriptionList"></div> <div class="row"> <div class="col-md-12"> <div id="customSubscriptionPagination" style="text-align:center"></div> </div> </div> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
