var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventPush_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-comment" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100237'] ))==null?'':__t)+
'</h5> <div id="customEventPushInformation"></div> <div class="control-group customEventPush_selectEvent"> <div class="form-group"> <label for="customEventPush_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventPush_selectEvent" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div id="customEventPushInsert"></div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" id="customEventPush_btnDeleteAll" style="width:100%">'+
((__t=( resources['X100240'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" id="customEventPush_btnCreateTest" style="width:100%;display:none">Create Test</button> </div> </div> <div class="row"> <div class="col-md-12"> <div id="customEventPushList"></div> </div> </div> <div style="height:5px"></div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customEventPushPagination" style="text-align:center"></div> </div> </div> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
