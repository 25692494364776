'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';
import FilePondPluginImageSizeMetaData from '../../plugins/filepond-plugin-image-size-metadata';


import tpl_customScreen from './tpl/customScreen.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_DialogProcess from '../../../lib/views/dialogProcess/dialogProcess';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';

import helper_typo from '../../helper/typo';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customScreen();
    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.ImageSelected = false;

        this.setHasChanged(false);
    },
    ui: {
        'customScreen_filePond': '#customScreen_filePond',
        'customScreen_btnSubmit': '#customScreen_btnSubmit',
        'customScreen_btnRemoveImage': '#customScreen_btnRemoveImage',
        'customScreen_btnImage': '#customScreen_btnImage',
        'customScreen_btnTEST': '#customScreen_btnTEST',
        'customScreen_div_clone': '#customScreen_div_clone',
        'customScreen_inputName': '#customScreen_inputName',
        'customScreen_image': '#customScreen_image',
        'customScreen_roundImage': '#customScreen_roundImage',
        'customScreen_input_ImageOriginal': '#customScreen_input_ImageOriginal',
        'customScreen_input_ImageWebOptimize500': '#customScreen_input_ImageWebOptimize500',
        'customScreen_input_ImageWebOptimize1000': '#customScreen_input_ImageWebOptimize1000',
        'customScreen_input_ImageWebOptimizeRound500': '#customScreen_input_ImageWebOptimizeRound500',
        'customScreen_input_ImageWebOptimizeRound1000': '#customScreen_input_ImageWebOptimizeRound1000',
        'customScreen_input_ImageWebOptimizeAd': '#customScreen_input_ImageWebOptimizeAd',
        'customScreen_div_ImageWebOptimizeAd': '#customScreen_div_ImageWebOptimizeAd',
        'customScreen_btn_crop_zoom_plus': '#customScreen_btn_crop_zoom_plus',
        'customScreen_btn_crop_zoom_minus': '#customScreen_btn_crop_zoom_minus',
        'customScreen_btn_crop_move_left': '#customScreen_btn_crop_move_left',
        'customScreen_btn_crop_move_right': '#customScreen_btn_crop_move_right',
        'customScreen_btn_crop_move_top': '#customScreen_btn_crop_move_top',
        'customScreen_btn_crop_move_buttom': '#customScreen_btn_crop_move_buttom',
        'customScreen_btn_crop_rotate_left': '#customScreen_btn_crop_rotate_left',
        'customScreen_btn_crop_rotate_right': '#customScreen_btn_crop_rotate_right',
        'customScreen_btn_crop_scale_x': '#customScreen_btn_crop_scale_x',
        'customScreen_btn_crop_scale_y': '#customScreen_btn_crop_scale_y',
        'customScreen_btn_crop_aspectRatio_5_5': '#customScreen_btn_crop_aspectRatio_5_5',
        'customScreen_btn_crop_aspectRatio_4_3': '#customScreen_btn_crop_aspectRatio_4_3',
        'customScreen_btn_crop_aspectRatio_3_4': '#customScreen_btn_crop_aspectRatio_3_4',
        'customScreen_btn_crop_aspectRatio_6_4': '#customScreen_btn_crop_aspectRatio_6_4',
        'customScreen_btn_crop_aspectRatio_4_6': '#customScreen_btn_crop_aspectRatio_4_6',
        'customScreen_btn_crop_aspectRatio_Free': '#customScreen_btn_crop_aspectRatio_Free'


    },
    events: {
        'click @ui.customScreen_btnSubmit': 'btnSubmitClick',
        'click @ui.customScreen_btnRemoveImage': 'btnRemoveImageClick',
        'change @ui.customScreen_btnImage': 'btnImageChange',
        'change @ui.customScreen_input_ImageOriginal': 'input_ImageOriginalChange',
        'click @ui.customScreen_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.customScreen_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.customScreen_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.customScreen_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.customScreen_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.customScreen_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.customScreen_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.customScreen_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.customScreen_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.customScreen_btn_crop_scale_y': 'btnScaleYClick',
        'click @ui.customScreen_btn_crop_aspectRatio_5_5': 'btnAspectRatio_5_5_Click',
        'click @ui.customScreen_btn_crop_aspectRatio_4_3': 'btnAspectRatio_4_3_Click',
        'click @ui.customScreen_btn_crop_aspectRatio_3_4': 'btnAspectRatio_3_4_Click',
        'click @ui.customScreen_btn_crop_aspectRatio_6_4': 'btnAspectRatio_6_4_Click',
        'click @ui.customScreen_btn_crop_aspectRatio_4_6': 'btnAspectRatio_4_6_Click',
        'click @ui.customScreen_btn_crop_aspectRatio_Free': 'btnAspectRatio_Free_Click'
    },
    help: function () {
        Help.help(this, true, 'customScreen');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },

    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    aspectRemoveClass() {
        this.ui.customScreen_btn_crop_aspectRatio_5_5.removeClass('btn-primary');
        this.ui.customScreen_btn_crop_aspectRatio_4_3.removeClass('btn-primary');
        this.ui.customScreen_btn_crop_aspectRatio_3_4.removeClass('btn-primary');
        this.ui.customScreen_btn_crop_aspectRatio_3_4.removeClass('btn-primary');
        this.ui.customScreen_btn_crop_aspectRatio_6_4.removeClass('btn-primary');
        this.ui.customScreen_btn_crop_aspectRatio_4_6.removeClass('btn-primary');
        this.ui.customScreen_btn_crop_aspectRatio_Free.removeClass('btn-primary');
    },
    btnAspectRatio_5_5_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(1);

        this.localSetting.attributes.AspectText = resources.X101202;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_5_5.addClass('btn-primary');
        
    },
    btnAspectRatio_4_3_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(4 / 3);

        this.localSetting.attributes.AspectText = resources.X101203;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_4_3.addClass('btn-primary');
    },
    btnAspectRatio_3_4_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(3 / 4);

        this.localSetting.attributes.AspectText = resources.X101204;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_3_4.addClass('btn-primary');
    },
    btnAspectRatio_6_4_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(6 / 4);

        this.localSetting.attributes.AspectText = resources.X101205;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_6_4.addClass('btn-primary');
    },
    btnAspectRatio_4_6_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(4 / 6);

        this.localSetting.attributes.AspectText = resources.X101206;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_4_6.addClass('btn-primary');
    },
    btnAspectRatio_Free_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(NaN);

        this.localSetting.attributes.AspectText = resources.X101207;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_Free.addClass('btn-primary');
    },
    setupCropper: function () {
        if (this.model != undefined) {
            var self = this;


            if (this.cropper) {
                this.cropper.destroy();
            }

            this.ui.customScreen_image.attr('src', this.model.attributes.ImageUrl);

            //var previews = document.querySelectorAll('.cropper-img-preview');
            var imageDefault = document.querySelector('#customScreen_image');

            var cropBoxData;
            var canvasData;



            this.cropper = new Cropper(imageDefault, {
                aspectRatio: NaN,
                autoCropArea: 1,
                zoomOnTouch: false,
                zoomOnWheel: false,
                cropBoxResizable: true,
                build: function () {
                    var clone = this.cloneNode();

                    clone.style.cssText = (
                        'display: block;' +
                        'width: 100%;' +
                        'minWidth: 0;' +
                        'minHeight: 0;' +
                        'maxWidth: none;' +
                        'maxHeight: none;'
                    );
                },

                crop: function (e) {
                    var data = e.detail;
                    var cropper = this.cropper;
                    var imageData = cropper.getImageData();
                    var previewAspectRatio = data.width / data.height;
                }
            });
        }
    },
    onAttach() {
        var self = this;

        var oldModel = this.collection.fullCollection.where({ TableNum: 1, TableId: 1 })[0];

        if (oldModel != undefined) {
            this.ui.customScreen_image.attr('src', oldModel .attributes.ImageUrl);
        }

        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageSizeMetaData
        );

        var labelIdle = resources.X200141 + ' <span class="filepond--label-action">' + resources.X200142 + '</span>';

        var pond = FilePond.create(this.ui.customScreen_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();

                    self.localSetting.attributes.ImageSelected = true;

                    if (self.cropper) {
                        self.cropper.destroy();
                    }
                    self.ui.customScreen_image.attr('src', base64);
                    //self.ui.customScreen_roundImage.attr('src', base64);

                    //var mimeType = self.base64MimeType(base64);
                    //var fileData = base64.split(',')[1];

                    self.localSetting.attributes.FileName = fileItem.filenameWithoutExtension;

                    var previews = document.querySelectorAll('.cropper-img-preview');
                    var imageDefault = document.querySelector('#customScreen_image');

                    self.cropper = new Cropper(imageDefault, {
                        aspectRatio: NaN,
                        autoCropArea: 1,
                        zoomOnTouch: false,
                        zoomOnWheel: false,
                        cropBoxResizable: true,
                        build: function () {
                            var clone = this.cloneNode();

                            clone.style.cssText = (
                                'display: block;' +
                                'width: 100%;' +
                                'minWidth: 0;' +
                                'minHeight: 0;' +
                                'maxWidth: none;' +
                                'maxHeight: none;'
                            );
                        },

                        crop: function (e) {
                            var data = e.detail;
                            var cropper = this.cropper;
                            var imageData = cropper.getImageData();
                            var previewAspectRatio = data.width / data.height;
                        }
                    });

                    self.setupBtn();

                    pond.removeFile(fileItem);
                };
            }
        });

        this.setupCropper();
    },
    onRender: function () {
        var self = this;

        this.localSetting.attributes.AspectText = resources.X101203;
        this.aspectRemoveClass();
        this.ui.customScreen_btn_crop_aspectRatio_4_3.addClass('btn-primary');

        this.setupBtn();
    },
    setupBtn: function () {
        this.ui.customScreen_btnSubmit.prop("disabled", false);
        this.ui.customScreen_btnSubmit.removeClass('btn-disabled');
        this.ui.customScreen_btnSubmit.addClass('btn-primary');
        this.ui.customScreen_btnSubmit.removeClass('btn-secondary');
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf(".");
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1, filename.length).toLowerCase();

            switch (ext) {
                case 'gif': return true; break;
                case 'jpg': return true; break;
                case 'png': return true; break;
                case 'bmp': return true; break;
                case 'tif': return true; break;
                case 'tiff': return true; break;
            }
        }

        return false;
    },
    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();

        if (this.validateExt(filename)) {

            //remove extension
            var dotPosition = filename.lastIndexOf(".");
            if (dotPosition > 0) {
                filename = filename.substring(0, dotPosition);
            }

            this.file = FileAPI.getFiles(event)[0];

            if (this.file) {


                if (this.file.size < 40000000) {

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        self.localSetting.attributes.ImageSelected = true;

                        if (self.cropper) {
                            self.cropper.destroy();
                        }

                        //if (self.cropperRound) {
                        //    self.cropperRound.destroy();
                        //}

                        self.ui.customScreen_image.attr('src', e.target.result);
                        self.ui.customScreen_roundImage.attr('src', e.target.result);

                        var previews = document.querySelectorAll('.cropper-img-preview');
                        var imageDefault = document.querySelector('#customScreen_image');

                        self.cropper = new Cropper(imageDefault, {
                            aspectRatio: 4 / 3,
                            autoCropArea: 1,
                            zoomOnTouch: false,
                            zoomOnWheel: false,
                            cropBoxResizable: true,
                            build: function () {
                                var clone = this.cloneNode();

                                clone.style.cssText = (
                                    'display: block;' +
                                    'width: 100%;' +
                                    'minWidth: 0;' +
                                    'minHeight: 0;' +
                                    'maxWidth: none;' +
                                    'maxHeight: none;'
                                );
                            },

                            crop: function (e) {
                                var data = e.detail;
                                var cropper = this.cropper;
                                var imageData = cropper.getImageData();
                                var previewAspectRatio = data.width / data.height;
                            }
                        });

                        self.setupBtn();
                    }

                    reader.readAsDataURL(this.file);

                } else {
                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100229;
                    dialogBoxModel.attributes.Text = resources.X100530;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
                    var callback = function (options) {
                        self.stopListening(dialogBox, "dialog:okclicked", callback);
                    }
                    this.listenTo(dialogBox, "dialog:okclicked", callback);
                    this.app.dialogRegion.show(dialogBox);
                }


                self.ui.customScreen_inputName.val(filename);
                var uploadFile = self.$el.find('.file-input-name');
                uploadFile.val("");

            }
        } else {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100934;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
    },
    getOriginalImage: function () {
        if (this.cropper) {

            var cropData = this.cropper.getData();
            var height = cropData.height;
            var width = cropData.width;

            var croppedCanvas;
            var canvas;
            var image;

            // Crop
            croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: canvas.toDataURL('image/png', 0.5)
            }

            return struct;//canvas.toDataURL();
        }
    },
    getOriginalCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    getWeboptimizeImage: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            var aspectRatio = /*cropBoxData.height /*/ cropBoxData.width / cropBoxData.height ;
            height = 80;
            width = height * aspectRatio;
            //height = width * aspectRatio;

            var croppedCanvas;
            var canvas;
            var image;

            // Crop
            croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: canvas.toDataURL('image/png', 0.5)
            }

            return struct;
        }
    },
    getWeboptimizeImageNoAspectRatio: function (width, height) {
        if (this.cropper) {
            
            var cropBoxData = this.cropper.getCropBoxData();
            var aspectRatio = cropBoxData.height / cropBoxData.width;

            var croppedCanvas;
            var canvas;
            var image;

            // Crop
            croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: canvas.toDataURL('image/png', 0.5)
            }

            return struct;
        }
    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    saveImage: function (filename, imageData, sizeType, sizeText, tableNum, tableId, strUtcDate, imageDelete,  callback) {
        var self = this;
        var oldModel = this.collection.fullCollection.where({ TableNum: tableNum, TableId: tableId })[0];

        var searchString = ";base64,";
        var pos = 0;

        var tmpModel = new this.collection.model();
        tmpModel.attributes.ImageDelete = imageDelete;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = filename;
        tmpModel.attributes.ImageData = imageData;
        tmpModel.attributes.ImageMimeType = 'image/png'; //imageMimeType;
        tmpModel.attributes.ImageUpdate = true;
        tmpModel.attributes.SizeType = sizeType;
        tmpModel.attributes.SizeText = sizeText;
        tmpModel.attributes.TableNum = tableNum;
        tmpModel.attributes.TableId = tableId;
        tmpModel.attributes.UtcDate = strUtcDate;
        tmpModel.attributes.ImageUrl = '';

        tmpModel.attributes.Filename = 'logo_' + new Date().getTime() + '.png';

        this.collection.fullCollection.create(tmpModel, {
            wait: true,
            success: function (response) {
                if (oldModel != undefined) {
                    self.collection.fullCollection.remove(oldModel, { silent: true });
                }

                self.model = self.collection.fullCollection.where({ id: response.attributes.id })[0];
                self.setupCropper();

                callback();
            }
        });
    },
    fillup: function (text) {
        if (text.length <= 1) {
            text = '0' + text;
        }
        return text;
    },
    btnSubmitClick: function () {
        this.hideErrors();

        var ret = false;

        if (ret == false) {

            var self = this;

            var nowLocalDate = new Date();

            var strLocalDate = nowLocalDate.getFullYear().toString() +
                this.fillup((nowLocalDate.getMonth() + 1).toString()) +
                this.fillup(nowLocalDate.getDate().toString()) +
                this.fillup(nowLocalDate.getHours().toString()) +
                this.fillup(nowLocalDate.getMinutes().toString()) +
                this.fillup(nowLocalDate.getSeconds().toString());

            var filename = this.localSetting.attributes.AspectText + " - " + this.localSetting.attributes.FileName;

            var totalCount = 1;
            var count = 1;

            var dialogProcessModel = new Entities_Empty.Model();
            dialogProcessModel.attributes.Button = resources.X100279;
            dialogProcessModel.attributes.Title = resources.X100321;
            dialogProcessModel.attributes.Text = resources.X100297;
            dialogProcessModel.attributes.progress_count = 0;
            dialogProcessModel.attributes.progress_totalCount = totalCount;

            var dialogProcess = new View_DialogProcess({ model: dialogProcessModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogProcess, "dialog:okclicked", callback);
            }
            this.listenTo(dialogProcess, "dialog:okclicked", callback);

            this.app.dialogRegion.show(dialogProcess);

            var callbackSaveImage = function () {
                dialogProcess.changeProgress(count, totalCount);

                if (count == totalCount) {
                    dialogProcess.triggerMethod('dialog:close');

                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });
                }
            }

            var searchString = ";base64,";
            var pos = 0;
            var uuid = this.UUID();

            var changeDate = new Date();

            var image515 = this.getWeboptimizeImage(200, 100);

            var imageData512 = image515.data;
            pos = imageData512.indexOf(searchString);
            var imageMimeType512 = imageData512.substring(5, pos);
            imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            var sizeText = Math.round(image515.width) + 'X' + Math.round(image515.height);

            var utcDateTime = helper_typo.getDBUtcDataTime();

            this.saveImage(filename, imageData512, 1, sizeText, 1, 1, utcDateTime, false, callbackSaveImage);
        } else {
            this.showErrors(tmpModel.errors);
        }
    },

    btnRemoveImageClick: function () {
        this.hideErrors();

        var ret = false;

        if (ret == false) {

            var self = this;

            var nowLocalDate = new Date();

            var strLocalDate = nowLocalDate.getFullYear().toString() +
                this.fillup((nowLocalDate.getMonth() + 1).toString()) +
                this.fillup(nowLocalDate.getDate().toString()) +
                this.fillup(nowLocalDate.getHours().toString()) +
                this.fillup(nowLocalDate.getMinutes().toString()) +
                this.fillup(nowLocalDate.getSeconds().toString());

            var filename = this.localSetting.attributes.AspectText + " - " + this.localSetting.attributes.FileName;

            var totalCount = 1;
            var count = 1;

            var dialogProcessModel = new Entities_Empty.Model();
            dialogProcessModel.attributes.Button = resources.X100279;
            dialogProcessModel.attributes.Title = resources.X100321;
            dialogProcessModel.attributes.Text = resources.X200375;
            dialogProcessModel.attributes.progress_count = 0;
            dialogProcessModel.attributes.progress_totalCount = totalCount;

            var dialogProcess = new View_DialogProcess({ model: dialogProcessModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogProcess, "dialog:okclicked", callback);
            }
            this.listenTo(dialogProcess, "dialog:okclicked", callback);

            this.app.dialogRegion.show(dialogProcess);

            var callbackSaveImage = function () {
                dialogProcess.triggerMethod('dialog:close');
                $('.modal-backdrop').remove();
                var url = 'customScreen';
                self.app.router.navigate(url);
                self.app.router.customScreen();
            }

            var searchString = ";base64,";
            var pos = 0;
            var uuid = this.UUID();
            
            //var image515 = this.getWeboptimizeImage(200, 100);
            

            //var imageData512 = image515.data;
            //pos = imageData512.indexOf(searchString);
            //var imageMimeType512 = imageData512.substring(5, pos);
            //imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            var sizeText = '';// Math.round(image515.width) + 'X' + Math.round(image515.height);

            var imageData512 = '';

            var utcDateTime = helper_typo.getDBUtcDataTime();

            this.saveImage(filename, imageData512, 1, sizeText, 1, 1, utcDateTime, true, callbackSaveImage);
        } else {
            this.showErrors(tmpModel.errors);
        }
    },
    UUID: function () {
        var lut = [];
        for (var i = 0; i < 256; i++) {
            lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
        }

        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;

        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    }
});