﻿import * as Entities_Empty from '../../lib/entities/empty';
import View_DialogHelp from '../views/dialogHelp/dialogHelp';
import View_Dialog2Button from '../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogProcess from '../../lib/views/dialogProcess/dialogProcess';
import View_Render from '../views/render/render';

var EventUpdate = {
    checkVersion : function(parent, callback) {
        var self = this;
        var update = false;
        parent.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.Version != parent.app.entities.models.customEventVersion.attributes.Value) {
                update = true;
            }
        });

        if (update) {

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100533;
            dialog2ButtonModel.attributes.Text = resources.X100534;
            dialog2ButtonModel.attributes.Button01 = resources.X100535;
            dialog2ButtonModel.attributes.Button02 = resources.X100536;
        
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

            var callback01 = function (options) {
                parent.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                parent.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                dialog2Button.triggerMethod('dialog:close');

                var timerId = window.setInterval(function () {
                    self.updateMultipleVersion(parent);
                    clearInterval(timerId);
                }, 1000);
            }
            parent.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                parent.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                parent.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                dialog2Button.triggerMethod('dialog:close');
            }
            parent.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            parent.app.dialogRegion.show(dialog2Button);
        }
    },
    updateSingleVersion : function(parent, model, callback_finish) {
        if (model.attributes.Version != parent.app.entities.models.customEventVersion.attributes.Value) {
            model.attributes.Version = parent.app.entities.models.customEventVersion.attributes.Value;

            /*var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100070;
            dialogWaitingModel.attributes.Text = resources.X100525;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: parent });
            var callback = function (options) {
                parent.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            parent.listenTo(dialogWaiting, "dialog:okclicked", callback);
            parent.app.dialogRegion.show(dialogWaiting);*/

            var updateTimeOne = 30;
            var updateTimeTotal = 1 * updateTimeOne;
            var updateTime = 0;

            var seconds = this.toHHMMSS(1 * updateTimeOne);

            var dialogProcessModel = new Entities_Empty.Model();
            dialogProcessModel.attributes.Button = resources.X100279;
            dialogProcessModel.attributes.Title = resources.X100070;
            var text = String.format(resources.X100537, seconds);
            dialogProcessModel.attributes.Text = text;
            dialogProcessModel.attributes.progress_count = 0;
            dialogProcessModel.attributes.progress_totalCount = 1;

            var dialogProcess = new View_DialogProcess({ model: dialogProcessModel, parent: parent });
            var callback = function (options) {
                parent.stopListening(dialogProcess, "dialog:okclicked", callback);
            }
            parent.listenTo(dialogProcess, "dialog:okclicked", callback);
            parent.app.dialogRegion.show(dialogProcess);

            var timerId = window.setInterval(function () {
                updateTime++;

                if (updateTime == updateTimeTotal) {
                    updateTime = updateTimeTotal - (updateTimeOne/2);
                }
                dialogProcess.changeProgress(updateTime, updateTimeTotal);
            }, 1000);


            model.save(model.attributes, {
                silent: true,
                wait: true,
                updateVersion : true,
                success: function () {
                    clearTimeout(timerId);
                    dialogProcess.triggerMethod('dialog:close');
                    //dialogWaiting.triggerMethod('dialog:close');
                    callback_finish();
                }
            });
        }
    },
    updateMultipleVersion : function(parent, callback_finish) {
        var count = 0;
        //var customEvent = parent.app.entities.collections.customEvent.fullCollection.where({ Version: !parent.app.entities.models.customEventVersion.attributes.Value })

        var customEvent = parent.app.entities.collections.customEvent.fullCollection.filter(function (entry) {
            return entry.attributes.Version  != app.entities.models.customEventVersion.attributes.Value;
        });

        /*app.entities.collections.customWebpage.fullCollection.forEach(function (entry) {
            if (entry.attributes.Version  != app.entities.models.customEventVersion.attributes.Value) {
                totalCount++;
            }
        });*/

        

        if (customEvent.length > 0) {
            var updateTimeOne = 30;
            var updateTimeTotal = customEvent.length * updateTimeOne;
            var updateTime = 0;

            var seconds = this.toHHMMSS(customEvent.length * updateTimeOne);

            var dialogProcessModel = new Entities_Empty.Model();
            dialogProcessModel.attributes.Button = resources.X100279;
            dialogProcessModel.attributes.Title = resources.X100070;
            var text = String.format(resources.X100537, seconds);
            dialogProcessModel.attributes.Text = text;
            dialogProcessModel.attributes.progress_count = 0;
            dialogProcessModel.attributes.progress_totalCount = customEvent.length;

            var dialogProcess = new View_DialogProcess({ model: dialogProcessModel, parent: parent });
            var callback = function (options) {
                parent.stopListening(dialogProcess, "dialog:okclicked", callback);
            }
            parent.listenTo(dialogProcess, "dialog:okclicked", callback);
            parent.app.dialogRegion.show(dialogProcess);

            var callback_event_render = function () {
                var render = new View_Render({ collection: customEvent, app: parent.app });
                parent.app.renderRegion.show(render);


                var render = new View_Render({ collection: customEvent, app: parent.app });
                parent.app.renderRegion.show(render);
                var renderFinishCallback = function (options) {
                }
                render.doIt(renderFinishCallback, true, 'EVENT_REFRESH', true);
            }

            var timerId = window.setInterval(function () {
                updateTime++;

                if (updateTime == updateTimeTotal) {
                    updateTime = updateTimeTotal - (updateTimeOne/2);
                }
                dialogProcess.changeProgress(updateTime, updateTimeTotal);
            }, 1000);

            var callback_event_update = function () {
                count++;

                if (count == customEvent.length) {
                    clearTimeout(timerId);
                    dialogProcess.triggerMethod('dialog:close');
                    callback_event_render();
                }
            }

            app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
                if (entry.attributes.Version  != app.entities.models.customEventVersion.attributes.Value) {
                    entry.attributes.Version  = app.entities.models.customEventVersion.attributes.Value;
                    entry.save(entry.attributes, {
                        silent: true,
                        wait: true,
                        updateVersion : true,
                        success: function () {
                            //dialogWaiting.triggerMethod('dialog:close');
                            callback_event_update();
                        }
                    });
                }
            });
        }
    },
    toHHMMSS : function (sec_num) {
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }
}

export default EventUpdate;