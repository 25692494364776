﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customAdSetting from './tpl/customAdSetting.html';

import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';

import config from '../../../common/config';
import Help from '../../helper/help';
import View_Render from '../render/render';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customAdSettingDesign({ model: this.model.toJSON() });
        } else {
            return tpl_customAdSetting({ model: this.model.toJSON() });
        }
    },
    regions: {
        information: "#customAdSettingInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        if (this.model) {
            customHeaderModel.attributes.Name = this.model.attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X101264;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.EventId = -1;

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick(null);
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customAdSetting_btnBack_click();
        });
    },
    ui: {
        'customAdSetting_divAd': '#customAdSetting_divAd',
        'customAdSetting_img_AdImage': '#customAdSetting_img_AdImage',
        'customAdSetting_btnAd': '#customAdSetting_btnAd',
        'customAdSetting_selectAdImageId': '#customAdSetting_selectAdImageId',
        'customAdSetting_inputAdImageUrl': '#customAdSetting_inputAdImageUrl',
        'customAdSetting_labelAdStatus': '#customAdSetting_labelAdStatus',
        'customAdSetting_imageAdImageId': '#customAdSetting_imageAdImageId',

        'customAdSetting_divAdBig': '#customAdSetting_divAdBig',
        'customAdSetting_img_AdBigImage': '#customAdSetting_img_AdBigImage',
        'customAdSetting_btnAdBig': '#customAdSetting_btnAdBig',
        'customAdSetting_selectAdBigImageId': '#customAdSetting_selectAdBigImageId',
        'customAdSetting_inputAdBigImageUrl': '#customAdSetting_inputAdBigImageUrl',
        'customAdSetting_labelAdBigStatus': '#customAdSetting_labelAdBigStatus',
        'customAdSetting_imageAdBigImageId': '#customAdSetting_imageAdBigImageId',

        'customAdSettingInformation': '#customAdSettingInformation',

        'help': '#help',
        'customAdSetting_btnSubmit': '#customAdSetting_btnSubmit',
        'customAdSetting_btnBack': '#customAdSetting_btnBack'
    },
    events: {
        'click @ui.customAdSetting_btnAd': 'customAdSetting_btnAdClick',
        'click @ui.customAdSetting_btnAdBig': 'customAdSetting_btnAdBigClick',
        'click @ui.customAdSetting_btnSubmit': 'btnSubmitClick',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customAdSetting_btnBack	': 'customAdSetting_btnBack_click'
    },
    onRender: function () {
        this.setupEvent();
        this.renderInformation();

        if (this.app.mode == 1) {
            this.ui.customAdSetting_btnSubmit.css('display', 'none');
            this.ui.customAdSetting_btnBack.css('display', 'none');
        }

        this.setupAd();
        this.setupAdBig();

        return this;
    },
    renderInformation: function () {
        this.informationCollection.reset();

        if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X101470 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customAdSetting_btnAdClick() {
        this.setupFancyBoxAd();
    },
    customAdSetting_btnAdBigClick() {
        this.setupFancyBoxAdBig();
    },
    setupFancyBoxStyle() {
        var top = window.innerHeight * 0.1;
        var left = window.innerWidth * 0.1;
        $('.fancybox-container').css('max-width', '80%');
        $('.fancybox-container').css('max-height', '80%');
        $('.fancybox-container').css('left', left + 'px');
        $('.fancybox-container').css('top', top + 'px');
    },
    setupFancyBoxAdOnLoad() {
        if (this.model.attributes.AdImageId != -1) {
            var model = this.app.entities.collections.customAdImage.fullCollection.where({ id: this.model.attributes.AdImageId })[0];
            this.ui.customAdSetting_img_AdImage.attr('src', model.attributes.ImageUrl);
        }
    },
    setupFancyBoxAd() {
        var self = this;
        var list = [];

        var model = this.app.entities.collections.customAdImage.fullCollection.where({ SizeType: 1 });

        model.forEach(function (entry) {
            var s   

            if (self.model.attributes.AdImageId == entry.attributes.id) {
                var item = {
                    src: entry.attributes.ImageUrl,
                    opts: {
                        caption: '',
                        thumb: "'" + entry.attributes.ImageUrl + "'"
                    }
                }

                list.push(item);
            }
        });

        model.forEach(function (entry) {
            var s

            if (self.model.attributes.AdImageId != entry.attributes.id) {
                var item = {
                    src: entry.attributes.ImageUrl,
                    opts: {
                        caption: '',
                        thumb: "'" + entry.attributes.ImageUrl + "'"
                    }
                }

                list.push(item);
            }
        });

        $.fancybox.open(
            list,
            {
                loop: false,
                thumbs: {
                    autoStart: true, // Display thumbnails on opening
                    hideOnClose: true, // Hide thumbnail grid when closing animation starts
                    parentEl: ".fancybox-container", // Container is injected into this element
                    axis: "y" // Vertical (y) or horizontal (x) scrolling
                },
                opts: {

                },
                beforeClose: function (instance, current) {
                    var model = self.app.entities.collections.customAdImage.fullCollection.where({ ImageUrl: current.src })[0];
                    self.model.attributes.AdImageId = model.attributes.id;
                    self.ui.customAdSetting_img_AdImage.attr('src', current.src);
                    //self.model.attributes.AdImageUrl = current.src;
                },
                clickContent: "close",
                clickSlide: "close",
                clickOutside: "close"
            }
        );

        this.setupFancyBoxStyle();

        
    },
    setupAd: function () {
        /*if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            this.ui.customAdSetting_div_Ad.show();

            this.ui.customAdSetting_selectAdEnable.val(this.model.attributes.AdEnable);

            this.setupAdImageId();
        }
        else {
            this.setupAdImageId();
            this.ui.customAdSetting_div_Ad.css('display', 'none');
        }*/
        this.setupAdImageId();
    },
    setupAdImageId: function () {
        var self = this;
        var model = this.app.entities.collections.customAdImage.fullCollection.where({ SizeType: 1 });

        /*if (self.model.attributes.AdImageId == -1) {
            self.ui.customAdSetting_selectAdImageId.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            self.ui.customAdSetting_selectAdImageId.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }

        model.forEach(function (entry) {
            if (self.model.attributes.AdImageId == entry.attributes.id) {
                var s = '<option  style="background-image:url({0})" value="{1}" selected="selected">{2}</option>';
                //self.ui.customAdSetting_selectAdImageId.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
                self.ui.customAdSetting_selectAdImageId.append(String.format(s, entry.attributes.ImageUrl, entry.attributes.id, entry.attributes.Name));
            } else {
                var s = '<option  style="background-image:url({0})" value="{1}">{2}</option>';
                //self.ui.customAdSetting_selectAdImageId.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
                self.ui.customAdSetting_selectAdImageId.append(String.format(s, entry.attributes.ImageUrl, entry.attributes.id, entry.attributes.Name));
            }
        });*/

        var model = this.app.entities.collections.customAdImage.fullCollection.where({ id: this.model.attributes.AdImageId });

        if (model.length > 0) {
            this.ui.customAdSetting_imageAdImageId.attr('src', model[0].attributes.ImageUrl);
        }

        var status = '';

        switch (this.model.attributes.AdStatus) {
            case 0: status = resources.X100961; break;
            case 1: status = resources.X100962; break;
        }

        this.ui.customAdSetting_labelAdStatus.text(status);
    },
    setupFancyBoxAdBigOnLoad() {


        if (this.model.attributes.AdBigImageId != -1) {
            var model = this.app.entities.collections.customAdBigImage.fullCollection.where({ id: this.model.attributes.AdBigImageId })[0];
            this.ui.customAdSetting_img_AdBigImage.attr('src', model.attributes.ImageUrl);
        }
    },
    setupFancyBoxAdBig() {
        var self = this;
        var list = [];

        var model = this.app.entities.collections.customAdBigImage.fullCollection.where({ SizeType: 1 });

        model.forEach(function (entry) {
            var s

            if (self.model.attributes.AdBigImageId == entry.attributes.id) {
                var item = {
                    src: entry.attributes.ImageUrl,
                    opts: {
                        caption: '',
                        thumb: "'" + entry.attributes.ImageUrl + "'"
                    }
                }

                list.push(item);
            }
        });

        model.forEach(function (entry) {
            var s

            if (self.model.attributes.AdBigImageId != entry.attributes.id) {
                var item = {
                    src: entry.attributes.ImageUrl,
                    opts: {
                        caption: '',
                        thumb: "'" + entry.attributes.ImageUrl + "'"
                    }
                }

                list.push(item);
            }
        });

        $.fancybox.open(
            list,
            {
                
                loop: false,
                thumbs: {
                    autoStart: true, // Display thumbnails on opening
                    hideOnClose: true, // Hide thumbnail grid when closing animation starts
                    parentEl: ".fancybox-container", // Container is injected into this element
                    axis: "y" // Vertical (y) or horizontal (x) scrolling
                },
                opts: {

                },
                beforeClose: function (instance, current) {
                    var model = self.app.entities.collections.customAdBigImage.fullCollection.where({ ImageUrl: current.src })[0];
                    self.model.attributes.AdBigImageId = model.attributes.id;
                    self.ui.customAdSetting_img_AdBigImage.attr('src', current.src);
                    //self.model.attributes.AdBigImageUrl = current.src;
                },
                clickContent: "close",
                clickSlide: "close",
                clickOutside: "close"
            }
        );

        this.setupFancyBoxStyle();
    },
    setupAdBig: function () {
        /*if (app.entities.models.configSubscription[0].attributes.EnableAdBigvertising == false) {
            this.ui.customAdSetting_div_AdBig.show();

            this.ui.customAdSetting_selectAdBigEnable.val(this.model.attributes.AdBigEnable);

            this.setupAdBigImageId();
        }
        else {
            this.setupAdBigImageId();
            this.ui.customAdSetting_div_AdBig.css('display', 'none');
        }*/
        this.setupAdBigImageId();
    },
    setupAdBigImageId: function () {
        var self = this;
        /*if (self.model.attributes.AdBigImageId == -1) {
            self.ui.customAdSetting_selectAdBigImageId.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            self.ui.customAdSetting_selectAdBigImageId.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }

        model.forEach(function (entry) {
            if (self.model.attributes.AdBigImageId == entry.attributes.id) {
                var s = '<option  style="background-image:url({0})" value="{1}" selected="selected">{2}</option>';
                //self.ui.customAdSetting_selectAdBigImageId.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
                self.ui.customAdSetting_selectAdBigImageId.append(String.format(s, entry.attributes.ImageUrl, entry.attributes.id, entry.attributes.Name));
            } else {
                var s = '<option  style="background-image:url({0})" value="{1}">{2}</option>';
                //self.ui.customAdSetting_selectAdBigImageId.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
                self.ui.customAdSetting_selectAdBigImageId.append(String.format(s, entry.attributes.ImageUrl, entry.attributes.id, entry.attributes.Name));
            }
        });*/

        var model = this.app.entities.collections.customAdBigImage.fullCollection.where({ id: this.model.attributes.AdBigImageId });

        if (model.length > 0) {
            this.ui.customAdSetting_imageAdBigImageId.attr('src', model[0].attributes.ImageUrl);
        }

        var status = '';

        switch (this.model.attributes.AdBigStatus) {
            case 0: status = resources.X100961; break;
            case 1: status = resources.X100962; break;
        }

        this.ui.customAdSetting_labelAdBigStatus.text(status);
    },
    /*selectAdImageIdChange: function (event) {
        event.preventDefault();

        this.model.attributes.AdImageId = parseInt(this.ui.customAdSetting_selectAdImageId.val());

        var model = this.app.entities.collections.customAdImage.fullCollection.where({ id: this.model.attributes.AdImageId });

        if (model.length > 0) {
            this.ui.customAdSetting_imageAdImageId.attr('src', model[0].attributes.ImageUrl);
        }
    },*/
    help: function () {
        Help.help(this, true, 'customSchemaLive');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.save(callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        if (this.model) {
            this.setModel();

            if (this.model.attributes.AdImageId != this.oldModel.attributes.AdImageId) {
                changes = true;
            }

            if (this.model.attributes.AdImageUrl != this.oldModel.attributes.AdImageUrl) {
                changes = true;
            }

            if (this.model.attributes.AdBigImageId != this.oldModel.attributes.AdBigImageId) {
                changes = true;
            }

            if (this.model.attributes.AdBigImageUrl != this.oldModel.attributes.AdBigImageUrl) {
                changes = true;
            }
        }

        this.setHasChanged(changes);
    },
    
    customViewEvent_setActive: function () {
        var array = [];

        if (this.localSetting.attributes.EventId != -1) {
            var tmp = { "id": this.localSetting.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        this.setupFancyBoxAdOnLoad();
        this.setupFancyBoxAdBigOnLoad();
    },
    setupEvent: function () {
        if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            this.ui.customAdSetting_btnAd.attr("disabled", true);
            this.ui.customAdSetting_inputAdImageUrl.attr("disabled", true);
            this.ui.customAdSetting_btnAdBig.attr("disabled", true);
            this.ui.customAdSetting_inputAdBigImageUrl.attr("disabled", true);
        }
    },
    setModel: function () {
        this.model.set({
            //AdEnable: parseInt(this.ui.customAdSetting_selectAdEnable.val()),
            //AdImageId: parseInt(this.ui.customAdSetting_selectAdImageId.val()),
            AdImageUrl: this.ui.customAdSetting_inputAdImageUrl.val(),
            AdBigImageUrl: this.ui.customAdSetting_inputAdBigImageUrl.val()
        }, { silent: true });
    },
    save: function (callbackRouter) {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100408;
        dialogWaitingModel.attributes.Text = resources.X100409;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        this.setModel();

        this.model.save(this.model.attributes, {
            silent: true,
            wait: true,
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
                self.oldModel = self.model.clone();

                var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: self.model.attributes.id })
                var render = new View_Render({ collection: customEvent, app: self.app });
                self.app.renderRegion.show(render);
                var renderFinishCallback = function (options) {
                    if (callbackRouter) {
                        callbackRouter();
                    }
                }
                render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
            }
        });
    },
    btnSubmitClick: function (event) {
        if (event) {
            event.preventDefault();
        }

        var self = this;

        this.setModel(this.model);

        this.model.validateNoBackbone(app, false, function (ret) {
            var modelErrors = ret;
            if (modelErrors) {
                self.hideErrors();
                self.showErrors(self.model.errors);
            }
            else {
                self.hideErrors();

                var dialogWaitingModel = new Entities_Empty.Model();
                dialogWaitingModel.attributes.Title = resources.X100408;
                dialogWaitingModel.attributes.Text = resources.X100181;
                dialogWaitingModel.attributes.Button = resources.X100279;

                var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
                var callback = function (options) {
                    self.stopListening(dialogWaiting, "dialog:okclicked", callback);
                }
                self.listenTo(dialogWaiting, "dialog:okclicked", callback);
                self.app.dialogRegion.show(dialogWaiting);

                self.model.save(self.model.attributes, {
                    silent: true,
                    wait: true,
                    success: function () {
                        dialogWaiting.triggerMethod('dialog:close');
                        self.oldModel = self.model.clone();

                        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: self.model.attributes.id })
                        var render = new View_Render({ collection: customEvent, app: self.app });
                        self.app.renderRegion.show(render);
                        var renderFinishCallback = function (options) {
                        }
                        render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
                    }
                });
            }
        });
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    },
    customAdSetting_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.model.attributes.id);
        this.app.router.customEventEditItem(this.model.attributes.id, this.options.scroll, 5);
    }
}, Backbone.Radio.Requests);
