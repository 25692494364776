﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Selected: 0
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        options.success.apply();
    },
    initialize: function () {
        
    },
    sortByPosition: function () {
        this.comparator = function (model) {
            return model.get('Position');
        }
        this.sort();
    }
});