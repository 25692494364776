import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as Bootstrap from 'bootstrap';
import GoogleMapsLoader from 'google-maps';


import DialogRegion from '../../lib/views/dialogRegion/dialogRegion';
import DialogRegion2 from '../../lib/views/dialogRegion2/dialogRegion2';
import DialogRouterRegion from '../../lib/views/dialogRouterRegion/dialogRouterRegion';
import DesignRegion from '../../lib/views/designRegion/designRegion';

//import View_CustomSetting from '../views/customSetting/customSetting';
import View_CustomViewEvent from '../views/customViewEvent/customViewEvent';
import View_DialogBox from '../../lib/views/dialogBox/dialogBox';
import * as Entities_Empty from '../../lib/entities/empty';



import language from '../../common/language';

import config from '../../common/config';

//import aaa from '../../common/aaa';

import * as Entities_UserAnalytic from '../entities/userAnalytic';

import * as Entities_PaintStrap from '../entities/paintStrap';
import * as Entities_File from '../entities/file';
import * as Entities_Help from '../entities/help';
import * as Entities_Custom from '../entities/custom';
import * as Entities_CustomItem from '../entities/customItem';
import * as Entities_CustomColor from '../entities/customColor';
import * as Entities_CustomDepartment from '../entities/customDepartment';
import * as Entities_CustomDepartmentTmp from '../entities/customDepartmentTmp';
import * as Entities_CustomDepartmentUser from '../entities/customDepartmentUser';
import * as Entities_CustomDepartmentReset from '../entities/customDepartmentReset';
import * as Entities_CustomIPAddress from '../entities/customIPAddress';
import * as Entities_CustomCrediential from '../entities/customCrediential';
import * as Entities_CustomUser from '../entities/customUser';
import * as Entities_CustomUserTmp from '../entities/customUserTmp';

import * as Entities_CustomTmp from '../entities/customTmp';

import * as Entities_CustomEventVersion from '../entities/customEventVersion';


import * as Entities_CustomOther from '../entities/customOther';
import * as Entities_CustomSetting from '../entities/customSetting';
import * as Entities_CustomSubscription from '../entities/customSubscription';
import * as Entities_ConfigSubscription from '../entities/configSubscription';
import * as Entities_CustomSubscriptionJob from '../entities/customSubscriptionJob';
import * as Entities_ConfigSubscriptionJob from '../entities/configSubscriptionJob';
import * as Entities_ConfigSetting from '../entities/configSetting';
import * as Entities_ConfigSchemaModel from '../entities/configSchemaModel';
import * as Entities_CustomImage from '../entities/customImage';
import * as Entities_CustomImageTmp from '../entities/customImageTmp';
import * as Entities_CustomInfoImage from '../entities/customInfoImage';
import * as Entities_CustomFile from '../entities/customFile';
import * as Entities_CustomInfoFile from '../entities/customInfoFile';
import * as Entities_CustomAdImage from '../entities/customAdImage';
import * as Entities_CustomAdBigImage from '../entities/customAdBigImage';
import * as Entities_CustomEvent from '../entities/customEvent';
import * as Entities_CustomEventLive from '../entities/customEventLive';
import * as Entities_CustomEventPush from '../entities/customEventPush';
import * as Entities_CustomEventTemplate from '../entities/customEventTemplate';
import * as Entities_CustomEventTemplateSubject from '../entities/customEventTemplateSubject';
import * as Entities_CustomModuleTemplate from '../entities/customModuleTemplate';
import * as Entities_CustomQRCode from '../entities/customQRCode';
import * as Entities_CustomWebpage from '../entities/customWebpage';
import * as Entities_CustomWebpageItem from '../entities/customWebpageItem';

import * as Entities_CustomInfopage from '../entities/customInfopage';
import * as Entities_CustomInfopageItem from '../entities/customInfopageItem';

import * as Entities_CustomSchemaModel from '../entities/customSchemaModel';
import * as Entities_CustomFormModel from '../entities/customFormModel';
import * as Entities_CustomProductSheetModel from '../entities/customProductSheetModel';
import * as Entities_ResetUserAnalytic from '../entities/resetUserAnalytic';
import * as Entities_CustomSchemaData from '../entities/customSchemaData';
import * as Entities_CustomSchemaSchema from '../entities/customSchemaSchema';
import * as Entities_CustomFormData from '../entities/customFormData';
import * as Entities_CustomProductSheetData from '../entities/customProductSheetData';
import * as Entities_CustomProductSheetImage from '../entities/customProductSheetImage'

import * as Entities_CustomModule from '../entities/customModule'

import * as Entities_PublicConfigSubscriptionPayment from '../../app/entities/publicConfigSubscriptionPayment';
import * as Entities_PublicPaymentGatewayMD5 from '../../app/entities/PublicPaymentGatewayMD5';
import * as Entities_PublicConfigSubscriptionValidate from '../../app/entities/publicConfigSubscriptionValidate';
import * as Entities_PublicCustom from '../../app/entities/publicCustom';
import * as Entities_PublicShopCart from '../../app/entities/publicShopCart';

import * as Entities_PublicSetting from '../../common/entities/publicSetting';

import * as Entities_Header from '../../event/entities/header';

import * as Entities_Font from '../../common/entities/font';
import * as Entities_Module from '../../common/entities/module';
import * as Entities_DefaultTemplate from '../../common/entities/defaultTemplate';
import * as Entities_Editor from '../../common/entities/editor';
import * as Entities_Template from '../../common/entities/template';
import * as Entities_Memory from '../entities/memory';

import * as Entities_PublicCustomEventLive from '../../event/entities/publicCustomEventLive';

import DefaultSetting from '../helper/defaultSetting';
import AuthHelper from '../helper/auth';

import Region_Header from '../../common/views/region/header/header';
import Region_HeaderPortal from '../views/region/header/header';

import * as Entities_LanguageSetting from '../../common/entities/languageSetting';
import * as Entities_LanguageValidation from '../../common/entities/languageValidation';


var moment = require('moment');
var WebFont = require('webfontloader');
//import 'regenerator-runtime';
//var ckeditor = require('../../webpageeditor/ckeditor5-build-classic/ckeditor');

//require('oidc-token-manager');

const oidc = require('../../plugin/oidc-client-ts');

var headerRegion = Marionette.Region.extend({
    el: '#headerRegion'
});

var menuRegion = Marionette.Region.extend({
    el: '#menuRegion'
});

var contentRegion = Marionette.Region.extend({
    el: '#contentRegion'
});

var footerRegion = Marionette.Region.extend({
    el: '#footerRegion'
});

var customMenuRegion = Marionette.Region.extend({
    el: '#customMenuRegion'
});

var editorRegion = Marionette.Region.extend({
    el: '#editorRegion',
    allowMissingEl: true
});

var dialogRegion = DialogRegion.extend({
    el: '#dialogRegion',
    allowMissingEl: true
});

var dialogRegion2 = DialogRegion2.extend({
    el: '#dialogRegion2',
    allowMissingEl: true
});

var dialogRouterRegion = DialogRouterRegion.extend({
    el: '#dialogRouterRegion',
    allowMissingEl: true
});

var renderRegion = Marionette.Region.extend({
    el: '#renderRegion',
    allowMissingEl: true
});

var settingRegion = Marionette.Region.extend({
    el: '#settingRegion',
    allowMissingEl: true
});

var viewEventRegion = Marionette.Region.extend({
    el: '#viewEventRegion',
    allowMissingEl: true
});

var designRegion = DesignRegion.extend({
    el: '#designRegion'
});


var app = module.exports = Marionette.Application.extend({
    regions: {
        headerRegion: headerRegion,
        menuRegion: menuRegion,
        contentRegion: contentRegion,
        customMenuRegion: customMenuRegion,
        editorRegion: editorRegion,
        footerRegion: footerRegion,
        dialogRegion: dialogRegion,
        dialogRegion2: dialogRegion2,
        dialogRouterRegion: dialogRouterRegion,
        renderRegion: renderRegion,
        settingRegion: settingRegion,
        viewEventRegion: viewEventRegion,
        designRegion: designRegion
    },
    onStart: function (options) {
        var self = this;
        $.ajaxSetup({ cache: false });
        language.getDefault();
        var luxaforboard_userCultureName = language.getCookie('luxaforboard_userCultureName');
        $("body").tooltip({ selector: '[data-toggle=tooltip]' });

        var languageCallback = function (resource) {
            window.resources = resource[0].attributes;
                self.setupEntities();
                self.initContent();
        };

        var languageLoginCallback = function (resource) {
            window.resources = resource[0].attributes;
            window.app.router.navigate("#accessTokenLifetime");
            window.app.router.accessTokenLifetime();
        };

        AuthHelper.login(this, languageCallback, languageLoginCallback, language, luxaforboard_userCultureName);
    },
    addDays: function (date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    paymentOK: function () {
        var paymentStatus = false;
        var paymentOverdueEnableMenu = false;

        switch (this.entities.collections.customSubscription.fullCollection.models[0].attributes.DineroInvoicePaymentStatus) {
            case 'Draft':
            case 'Booked': paymentStatus = false; break;
            case 'Paid':
            case 'OverPaid': paymentStatus = true; break;
            case 'Overdue': paymentStatus = false; break;
        }

        var array = [];
        paymentOverdueEnableMenu = paymentStatus;
        if (this.entities.collections.customSubscription.fullCollection.length > 1 && paymentStatus == false) {
            //var now = new Date();
            //var utcDateNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(),0,1,0);
            //utcDateNow.setMinutes(0);
            //utcDateNow.setSeconds(1);

            //var utcDate = new Date(this.entities.collections.customSubscription.fullCollection.models[0].attributes.UtcDate);
            //var datePaymentOverdue = this.addDays(utcDate, this.entities.collections.customSubscription.fullCollection.models[0].attributes.PaymentOverdue);
            //var utcDatePaymentOverdue = new Date(datePaymentOverdue.getUTCFullYear(), datePaymentOverdue.getUTCMonth(), datePaymentOverdue.getUTCDate(), datePaymentOverdue.getUTCHours(),0,1,0);

            //if (utcDatePaymentOverdue > utcDateNow) {
            //    paymentOverdue = true;
            //}

            var dateNow = moment();
            dateNow.minutes(0);
            dateNow.seconds(0);
            var utcDateNow = moment([dateNow.utc().year(), dateNow.utc().month(), dateNow.utc().date(), dateNow.utc().hour(), 0, 1]);

            var utcDate = new moment(this.entities.collections.customSubscription.fullCollection.models[0].attributes.UtcDate);
            var utcDatePaymentOverdue = utcDate.add(this.entities.collections.customSubscription.fullCollection.models[0].attributes.PaymentOverdue, 'days');

            if (utcDateNow >= utcDatePaymentOverdue) {
                paymentOverdueEnableMenu = false;
            } else {
                paymentOverdueEnableMenu = true;
            }
        }
        var tmp = { 'paymentStatus': paymentStatus, 'paymentOverdueEnableMenu': paymentOverdueEnableMenu };
        array.push(tmp);

        return array;
    },
    
    setupEntities: function () {
        var self = this;

        this.mode = 1;

        this.entities = {};
        this.entities.collections = {};
        this.entities.models = {};

        this.entities.collections.customDepartment = new Entities_CustomDepartment.Collection();
        this.entities.collections.customDepartment.fullCollection.comparator = this.entities.collections.customDepartment.comparator;

        this.entities.collections.customDepartmentTmp = new Entities_CustomDepartmentTmp.Collection();
        this.entities.collections.customDepartmentTmp.fullCollection.comparator = this.entities.collections.customDepartmentTmp.comparator;

        this.entities.collections.customIPAddress = new Entities_CustomIPAddress.Collection();
        this.entities.collections.customIPAddress.fullCollection.comparator = this.entities.collections.customIPAddress.comparator;

        this.entities.collections.customDepartmentUser = new Entities_CustomDepartmentUser.Collection();
        this.entities.collections.customDepartmentUser.fullCollection.comparator = this.entities.collections.customDepartmentUser.comparator;

        this.entities.collections.customDepartmentReset = new Entities_CustomDepartmentReset.Collection();
        this.entities.collections.customDepartmentReset.fullCollection.comparator = this.entities.collections.customDepartmentReset.comparator;

        this.entities.collections.customUser = new Entities_CustomUser.Collection();
        this.entities.collections.customUser.fullCollection.comparator = this.entities.collections.customUser.comparator;

        this.entities.collections.customUserTmp = new Entities_CustomUserTmp.Collection();
        this.entities.collections.customUserTmp.fullCollection.comparator = this.entities.collections.customUserTmp.comparator;

        this.entities.collections.customTmp = new Entities_CustomTmp.Collection();

        this.entities.collections.informationCollection = new Entities_Empty.Collection();

        this.entities.collections.paintStrap = new Entities_PaintStrap.Collection();
        this.entities.collections.paintStrap.fullCollection.comparator = this.entities.collections.paintStrap.comparator;

        this.entities.collections.help = new Entities_Help.Collection();
        //this.entities.collections.help.fullCollection.comparator = this.entities.collections.help.comparator;

        this.entities.collections.helpPagination = new Entities_Help.CollectionpPagination();
        this.entities.collections.helpPagination.fullCollection.comparator = this.entities.collections.helpPagination.comparator;

        this.entities.collections.file = new Entities_File.Collection();

        this.entities.models.custom = new Entities_Custom.Model();
        this.entities.models.customItem = new Entities_CustomItem.Model();
        this.entities.models.customEventVersion = new Entities_CustomEventVersion.Model();

        this.entities.models.customColor = new Entities_CustomColor.Model();


        this.entities.models.editorSetting = new Entities_Empty.Model();
        this.entities.models.editorSetting.attributes.DesignMode = false;

        this.entities.collections.userAnalyticCollection = new Entities_UserAnalytic.Collection();

        this.entities.collections.customSetting = new Entities_CustomSetting.Collection();

        this.entities.collections.customOther = new Entities_CustomOther.Collection();
        this.entities.collections.customSubscription = new Entities_CustomSubscription.Collection();
        this.entities.collections.configSubscription = new Entities_ConfigSubscription.Collection();
        this.entities.collections.customSubscriptionJob = new Entities_CustomSubscriptionJob.Collection();
        this.entities.collections.configSubscriptionJob = new Entities_ConfigSubscriptionJob.Collection();
        this.entities.collections.configSetting = new Entities_ConfigSetting.Collection();
        this.entities.collections.configSchemaModel = new Entities_ConfigSchemaModel.Collection();
        this.entities.collections.customImage = new Entities_CustomImage.Collection();
        this.entities.collections.customImage.fullCollection.comparator = this.entities.collections.customImage.comparator;
        this.entities.collections.customImageTmp = new Entities_CustomImageTmp.Collection();
        //this.entities.collections.customImageTmp.fullCollection.comparator = this.entities.collections.customImageTmp.comparator;
        this.entities.collections.customInfoImage = new Entities_CustomInfoImage.Collection();
        this.entities.collections.customFile = new Entities_CustomFile.Collection();
        this.entities.collections.customFile.fullCollection.comparator = this.entities.collections.customFile.comparator;
        this.entities.collections.customInfoFile = new Entities_CustomInfoFile.Collection();
        this.entities.collections.customAdImage = new Entities_CustomAdImage.Collection();
        this.entities.collections.customAdImage.fullCollection.comparator = this.entities.collections.customAdImage.comparator;
        this.entities.collections.customAdBigImage = new Entities_CustomAdBigImage.Collection();
        this.entities.collections.customAdBigImage.fullCollection.comparator = this.entities.collections.customAdBigImage.comparator;
        this.entities.collections.customEvent = new Entities_CustomEvent.Collection();
        this.entities.collections.customEvent.fullCollection.comparator = this.entities.collections.customEvent.comparator;
        this.entities.collections.customEventLive = new Entities_CustomEventLive.Collection();
        this.entities.collections.customEventPush = new Entities_CustomEventPush.Collection();
        this.entities.collections.customEventPush.fullCollection.comparator = this.entities.collections.customEventPush.comparator;
        this.entities.collections.customEventTemplate = new Entities_CustomEventTemplate.Collection();
        this.entities.collections.customEventTemplateSubject = new Entities_CustomEventTemplateSubject.Collection();
        this.entities.collections.customModuleTemplate = new Entities_CustomModuleTemplate.Collection();
        this.entities.collections.customQRCode = new Entities_CustomQRCode.Collection();
        this.entities.collections.customQRCode.fullCollection.comparator = this.entities.collections.customQRCode.comparator;

        this.entities.collections.customWebpage = new Entities_CustomWebpage.Collection();
        this.entities.collections.customWebpage.fullCollection.comparator = this.entities.collections.customWebpage.comparator;

        this.entities.collections.customInfopage = new Entities_CustomInfopage.Collection();
        this.entities.collections.customInfopage.fullCollection.comparator = this.entities.collections.customInfopage.comparator;

        this.entities.collections.customWebpageItem = new Entities_CustomWebpageItem.Collection();
        this.entities.collections.customInfopageItem = new Entities_CustomInfopageItem.Collection();

        this.entities.collections.customSchemaModel = new Entities_CustomSchemaModel.Collection();
        this.entities.collections.customSchemaModel.fullCollection.comparator = this.entities.collections.customSchemaModel.comparator;
        this.entities.collections.customFormModel = new Entities_CustomFormModel.Collection();
        this.entities.collections.customFormModel.fullCollection.comparator = this.entities.collections.customFormModel.comparator;
        this.entities.collections.customProductSheetModel = new Entities_CustomProductSheetModel.Collection();
        this.entities.collections.customProductSheetModel.fullCollection.comparator = this.entities.collections.customProductSheetModel.comparator;
        this.entities.collections.resetUserAnalytic = new Entities_ResetUserAnalytic.Collection();
        this.entities.collections.customSchemaData = new Entities_CustomSchemaData.Collection();
        this.entities.collections.customSchemaSchema = new Entities_CustomSchemaSchema.Collection();
        this.entities.collections.customFormData = new Entities_CustomFormData.Collection();
        this.entities.collections.customProductSheetData = new Entities_CustomProductSheetData.Collection();
        this.entities.collections.customProductSheetImage = new Entities_CustomProductSheetImage.Collection();

        this.entities.collections.customModule = new Entities_CustomModule.Collection();

        this.entities.collections.publicConfigSubscriptionPayment = new Entities_PublicConfigSubscriptionPayment.Collection();
        this.entities.models.publicPaymentGatewayMD5 = new Entities_PublicPaymentGatewayMD5.Model();
        this.entities.collections.publicConfigSubscriptionValidate = new Entities_PublicConfigSubscriptionValidate.Collection();
        this.entities.collections.publicCustom = new Entities_PublicCustom.Collection();

        this.entities.collections.publicShopCart = new Entities_PublicShopCart.Collection();

        this.entities.collections.headerWebpage = new Entities_Header.Collection();
        this.entities.collections.headerInfopage = new Entities_Header.Collection();

        this.entities.models.publicSetting = new Entities_PublicSetting.Model();

        this.entities.collections.font = new Entities_Font.Collection();
        this.entities.collections.module = new Entities_Module.Collection();
        this.entities.collections.defaultTemplate = new Entities_DefaultTemplate.Collection();
        this.entities.collections.editor = new Entities_Editor.Collection();
        this.entities.collections.template = new Entities_Template.Collection();

        this.entities.models.publicCustomEventLive = new Entities_PublicCustomEventLive.Model();

        this.entities.collections.languageSetting = new Entities_LanguageSetting.Collection();
        this.entities.collections.languageValidation = new Entities_LanguageValidation.Collection();

        var templateDone = $.Deferred();
        var templateItemDone = $.Deferred();
        var templateCssDone = $.Deferred();

        DefaultSetting.createDefault(this);

        this.entities.collections.customTmp.fetch({
            success: function (mode, response) {
            },
            error: function (mode, response) {
            }
        }),

            //window.templateDone = false;
            //window.templateItemDone = false;

            this.entities.models.custom.fetch({
                silent: true,
                wait: true,
                success: function (mode, response) {
                    //self.entities.collections.configSubscription.fetch({
                    //    silent: true,
                    //    wait: true,
                    //    success: function (mode, response) {
                    $.when(
                        self.entities.collections.font.fetch({
                            parseField: config.cultureName(),
                            success: function (mode, response) {
                                self.entities.collections.Font = new Backbone.Collection(response.Font);
                            },
                            error: function (mode, response) {
                            }
                        }),
                        self.entities.collections.module.fetch({
                            parseField: config.cultureName(),
                            success: function (mode, response) {
                                window.module = response[config.cultureName()];

                                self.entities.collections.Module = new Backbone.Collection(window.module.Module);
                                self.entities.collections.ModuleSetting = new Backbone.Collection(window.module.ModuleSetting);
                                self.entities.collections.ModuleBackGroundType = new Backbone.Collection(window.module.ModuleBackGroundType);
                                self.entities.collections.ModuleBackGroundImagePosition = new Backbone.Collection(window.module.ModuleBackGroundImagePosition);
                                self.entities.collections.ModuleBorderStyle = new Backbone.Collection(window.module.ModuleBorderStyle);
                                self.entities.collections.GridBackGroundType = new Backbone.Collection(window.module.GridBackGroundType);
                                self.entities.collections.GridFontType = new Backbone.Collection(window.module.GridFontType);
                                self.entities.collections.GridFontColorType = new Backbone.Collection(window.module.GridFontColorType);
                                self.entities.collections.InfoFontType = new Backbone.Collection(window.module.InfoFontType);
                                self.entities.collections.InfoFontColorType = new Backbone.Collection(window.module.InfoFontColorType);
                                self.entities.collections.FontStyle = new Backbone.Collection(window.module.FontStyle);
                                self.entities.collections.LineHeight = new Backbone.Collection(window.module.LineHeight);
                                self.entities.collections.TextAlign = new Backbone.Collection(window.module.TextAlign);
                                //self.entities.collections.Font = new Backbone.Collection(window.module.Font);
                                self.entities.models.InfoPageSetting = new Entities_Memory.Model(window.module.InfoPageSetting);
                                self.entities.collections.WeatherTemplate = new Backbone.Collection(window.module.WeatherTemplate);
                                self.entities.collections.WeatherForecast = new Backbone.Collection(window.module.WeatherForecast);
                                self.entities.collections.WeatherUnit = new Backbone.Collection(window.module.WeatherUnit);
                                self.entities.collections.PictureSlideLabelAnimation = new Backbone.Collection(window.module.PictureSlideLabelAnimation);
                                self.entities.collections.PictureSlideAnimation = new Backbone.Collection(window.module.PictureSlideAnimation);
                                self.entities.collections.HtmlSlideAnimationGroup = new Backbone.Collection(window.module.HtmlSlideAnimationGroup);
                                self.entities.collections.HtmlSlideAnimation = new Backbone.Collection(window.module.HtmlSlideAnimation);
                                self.entities.collections.FeedProvider = new Backbone.Collection(window.module.FeedProvider);
                                self.entities.collections.FeedCount = new Backbone.Collection(window.module.FeedCount);
                                self.entities.collections.DigitalClockDateFormat = new Backbone.Collection(window.module.DigitalClockDateFormat);
                                self.entities.collections.DigitalClockHelper = new Backbone.Collection(window.module.DigitalClockHelper);

                                self.entities.collections.QRCodeFontStyle = new Backbone.Collection(window.module.QRCodeFontStyle);
                                self.entities.collections.LuxaforboardColor = new Backbone.Collection(window.module.LuxaforboardColor);

                            },
                            error: function (mode, response) {
                            }
                        }),
                        self.entities.models.custom.fetch({
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        }),
                        self.entities.models.customItem.fetch({
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        }),
                        //self.entities.models.customColor.fetch({
                        //    success: function (mode, response) {
                        //    },
                        //    error: function (mode, response) {
                        //    }
                        //}),
                        self.entities.collections.customImage.fetch({
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        }),
                        //self.entities.collections.customImageTmp.fetch({
                        //    success: function (mode, response) {
                        //    },
                        //    error: function (mode, response) {
                        //    }
                        //}),
                        self.entities.collections.customIPAddress.fetch({
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        }),
                        self.entities.collections.customDepartment.fetch({
                            route: 'route01',
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        }),
                        //self.entities.collections.customDepartmentUser.fetch({
                        //    route: 'route01',
                        //    success: function (mode, response) {
                        //    },
                        //    error: function (mode, response) {
                        //    }
                        //}),
                        self.entities.collections.customUser.fetch({
                            route: 'route01',
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        }),


                        //self.entities.collections.customUserTmp.fetch({
                        //    success: function (mode, response) {
                        //        //if (self.entities.collections.customUserTmp.length > 0) {
                        //        //    self.entities.collections.customUserTmp.models[0].destroy({
                        //        //        wait: true,
                        //        //        silent: true,
                        //        //        route: 'route03',
                        //        //        success: function (model, response) {
                        //        //            $.when(
                        //        //                self.app.entities.collections.customUser.fetch({
                        //        //                    success: function (mode, response) {
                        //        //                    },
                        //        //                    error: function (mode, response) {
                        //        //                    }
                        //        //                }),
                        //        //                self.app.entities.collections.customUserTmp.fetch({
                        //        //                    success: function (mode, response) {
                        //        //                    },
                        //        //                    error: function (mode, response) {
                        //        //                    }   
                        //        //                })
                        //        //            ).done(function () {
                        //        //            })
                        //        //        }
                        //        //    });
                        //        //}
                        //    },
                        //    error: function (mode, response) {
                        //    }
                        //}),
                        self.entities.collections.languageValidation.fetch({
                            parseField: config.cultureName(),
                            success: function (mode, response) {
                                self.entities.collections.languageValidation_Collection = new Entities_Memory.Collection(self.entities.collections.languageValidation.models[0].attributes.Collection);

                                var i = 0;
                            },
                            error: function (mode, response) {
                                var i = 0;
                            }
                        }),
                        self.entities.collections.languageSetting.fetch({
                            success: function (mode, response) {
                            },
                            error: function (mode, response) {
                            }
                        })
                    ).done(function () {
                        var callback = function (succes) {
                            if (succes == true) {
                                self.loadGoogleFont();

                                

                                self.initSetting();

                                Backbone.history.start();

                                var paymentOK = true;

                                self.firstRoute('#customDepartment', false, true);
                                self.setupHeader();

                                $('html').css('overflow', 'auto');
                            } else {
                                //DefaultSetting.checkCustomSetting(self, self.waitingDefaultSetting, callback);
                            }
                        }

                        //DefaultSetting.checkCustomSetting(self, self.waitingDefaultSetting, callback);

                        callback(true);
                    });
                    //},//a
                    //error: function (mode, response) {
                    //}
                    //});
                },
                error: function (mode, response) {
                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100704;
                    dialogBoxModel.attributes.Text = resources.X100734;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                    var callback2 = function () {
                        self.stopListening(dialogBox, "dialog:okclicked", callback2);

                        if (this.mgr.profile !== undefined) {
                            mgr.redirectForLogout();
                        } else {
                            var location = '';
                            switch (window.location.host) {
                                case 'localhost:7099': location = window.location.protocol + '//' + 'localhost:7099/index.html'; break;
                                case 'events4usproduction.azurewebsites.net': location = window.location.protocol + '//' + 'events4usproduction.azurewebsites.net/index.html'; break;
                                case 'events4us.net': location = window.location.protocol + '//' + 'events4us.net/index.html'; break;
                                case 'www.events4us.net': location = window.location.protocol + '//' + 'www.events4us.net/index.html'; break;
                                case 'events4us.dk': location = window.location.protocol + '//' + 'events4us.dk/index.html'; break;
                                case 'www.events4us.dk': location = window.location.protocol + '//' + 'www.events4us.dk/index.html'; break;
                                case 'events4usproduction-test.azurewebsites.net': location = window.location.protocol + '//' + 'events4usproduction-test.azurewebsites.net/index.html'; break;
                                case 'test.events4us.net': location = window.location.protocol + '//' + 'test.events4us.net/index.html'; break;
                            }
                            window.location.href = location;
                        }
                    }
                    self.listenTo(dialogBox, "dialog:okclicked", callback2);
                    self.dialogRegion.show(dialogBox);
                },
            });
    },
    firstRoute: function (route, validate, enableMenu) {
        if (route != '#waitingDefaultSetting') {
            if (validate) {
                var modelErrors = window.app.entities.models.custom.validateNoBackbone(this.entities.models.custom.attributes);
                if (modelErrors) {
                    route = '#custom';
                }
                else {
                    route = '#customEventCounter';
                }
            }

            route = "#customDepartment";
            //route = "#customEventEditItem";
            //route = "#customInfopageEditItem";
        }

        switch (route) {
            case '#waitingDefaultSetting':
                window.app.router.navigate("#waitingDefaultSetting");
                window.app.router.waitingDefaultSetting();
                break;
            case '#waiting':
                window.app.router.navigate("#waiting");
                window.app.router.waiting();
                break;
            case '#custom':
                window.app.router.navigate("#custom");
                window.app.router.custom();
                break;
            case '#customDepartment':
                window.app.router.navigate("#customDepartment");
                window.app.router.customDepartment();
                break;

            case '#customEventCounter':
                window.app.router.navigate("#customEventCounter?id=-1");
                window.app.router.customEventCounter(-1);
                break;
            case '#customSubscription':
                window.app.router.navigate("#customSubscription");
                window.app.router.customSubscription(enableMenu);
                break;
            case '#customWebpageEditItem':
                window.app.router.navigate("#customWebpageEditItem");
                window.app.router.customWebpageEditItem(24);
                break;

            case '#customEventEditItem':
                var scroll = $(document).scrollTop();
                var model = window.app.entities.collections.customEvent.fullCollection.models[0];
                window.app.router.navigate("#customEventEditItem/" + model.attributes.id);
                window.app.router.customEventEditItem(model.attributes.id, scroll, 0, 1);
                break;
            case '#customEvent':
                var scroll = $(document).scrollTop();

                window.app.router.navigate("#customEvent");
                window.app.router.customEvent();
                break;

            case '#customInfopageEditItem':
                var scroll = $(document).scrollTop();
                var model = window.app.entities.collections.customEvent.fullCollection.models[0];

                window.app.router.navigate("#customInfopageEditItem");
                //window.app.router.customInfopageEditItem(model.attributes.Infopage, model.attributes.id, scroll, 0);

                var customModuleModel = window.app.entities.collections.customModule.where({ EventId: model.attributes.id, ModuleType: 1 })[0];

                window.app.router.customInfopageEditItem(customModuleModel.attributes.id, model.attributes.id);
                break;
        }
    },
    initContent: function () {
        $.valHooks.textarea = {
            get: function (elem) {
                return elem.value.replace(/\r?\n/g, "\r\n");
            }
        };

        var self = this;
        this.firstRoute("#waitingDefaultSetting", false, false);
        //this.firstRoute("#test", false);
    },
    initSetting: function () {
        //var customSetting = new View_CustomSetting({ collection: self.app.entities.collections.customSetting, app : self.app });
        //self.app.settingRegion.show(customSetting);

        //self.app.entities.collections.customEvent.models[2].attributes.Active = false;
        var tmpModel = new Entities_Empty.Model();
        tmpModel.attributes.Render = false;

        var customViewEvent = new View_CustomViewEvent({ model: tmpModel, collection: self.app.entities.collections.customEvent.fullCollection, app: self.app });
        //self.app.viewEventRegion.show(customViewEvent);
    },
    setupHeader: function () {
        localStorage.setItem('events4usUser_FirstName', this.entities.models.custom.attributes.FirstName);
        localStorage.setItem('events4usUser_AttPerson', this.entities.models.custom.attributes.AttPerson);
        localStorage.setItem('events4usUser_Organisation', this.entities.models.custom.attributes.Organisation);

        this.headerRegion.show(new Region_HeaderPortal({ app: this, customModel: this.entities.models.custom }));
        var userChannel = Backbone.Radio.channel('app');
        //userChannel.trigger('Region_Header:setLoginName');
    },
    setupCKEditor: function () {
        //CKEDITOR.disableAutoInline = true;

        //CKEDITOR.stylesSet.add('my_styles',
        //    [
        //        // Block-level styles
        //        { name: 'Header 1', element: 'h1', styles: { 'color': 'Blue' } },
        //        { name: 'Red Title', element: 'h2', styles: { 'color': 'Red' } },
        //        { name: 'Red Title', element: 'p', styles: { 'color': 'Red' } }
        //        // Inline styles
        //        /*{ name: 'CSS Style', element: 'span', attributes: { 'class': my_styles } },
        //        { name: 'Marker: Yellow', element: 'span', styles: { 'background-color': 'Yellow' } }*/
        //    ]);
    },
    renderInformation: function (informationCollection) {
        var counter = 1;
        this.running = true;
        var self = this;
        var customSetting = this.entities.collections.customSetting.where({ Name: 'ShowInformation' });

        informationCollection.informationCollection.forEach(function (entry) {
            var show = true;

            if ((self.entities.collections.informationCollection.where({ toastType: entry.attributes.toastType, header: entry.attributes.header, Information: entry.attributes.Information }).length <= 0)) {
                if (entry.attributes.toastType != 3) {
                    self.entities.collections.informationCollection.add(entry);
                }

                if (entry.attributes.toastType == 1 && customSetting[0].attributes.Value == '0') {
                    show = false;
                }

                if (show) {
                    entry.attributes.timeout_id = setTimeout(function () {
                        if (self.running) {
                            clearTimeout(entry.attributes.timeout_id);
                            $.fn.showToast(entry.attributes.toastType, entry.attributes.header, entry.attributes.Information);
                        }
                    }, counter * 1000);
                    counter++;
                }
            }
        });

        //informationCollection.informationCollection.forEach(function (entry) {
        //    entry.attributes.timeout_id = setTimeout(function () {
        //        if (self.running) {
        //            clearTimeout(entry.attributes.timeout_id);
        //            $.fn.showToast(entry.attributes.toastType, entry.attributes.header, entry.attributes.Information);
        //        }
        //    }, counter * 1000);
        //    counter++;
        //});
    },
    iconArrowsAlt: function () {
        //if ($(".sidebar-icon-only")[0]) {
        //    $('#custom_menu_loginName').css('display', 'inline');
        //    $('#custom_menu_loginOrg').css('display', 'block');

        //} else {
        //    $('#custom_menu_loginName').css('display', 'none');
        //    $('#custom_menu_loginOrg').css('display', 'none');
        //}

        //var timerId = window.setInterval(function () {

        //    if ($(".sidebar-icon-only")[0]) {
        //        $('#custom_menu_loginName').css('display', 'none');
        //        $('#custom_menu_loginOrg').css('display', 'none');
        //    } else {
        //        $('#custom_menu_loginName').css('display', 'inline');
        //        $('#custom_menu_loginOrg').css('display', 'block');

        //    }
        //    clearInterval(timerId);
        //}, 500);

        //var timerId = window.setInterval(function () {

        //    if ($(".sidebar-icon-only")[0]) {
        //        $('#custom_menu_loginName').css('display', 'none');
        //    } else {
        //        $('#custom_menu_loginName').css('display', 'inline');
        //    }

        //    var userChannel = Backbone.Radio.channel('app');
        //    userChannel.trigger('customInfopageEditItemTopbar:setZoom');
        //    clearInterval(timerId);
        //}, 1000);
    },
    loadGoogleFont: function () {
        var fonts = [];

        this.entities.collections.Font.forEach(function (entry) {
            if (entry.attributes.data_font_style.length > 0)
                fonts.push(entry.attributes.data_font_family + ':' + entry.attributes.data_font_style);
            else
                fonts.push(entry.attributes.data_font_family);
        });

        WebFont.load({
            google: {
                families: fonts
            }
        });
    }
});



