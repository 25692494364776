﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';
import * as Skitter from 'skitter';

import tpl_pictureSingle from './tpl/pictureSingle.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_PictureSingleList from './pictureSingleList';

import * as Entities_Memory_Position from '../../entities/memoryPosition';
import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_pictureSingle();
    },
    regions: {
        modal: '#modal',
        pictureSingleList: "#pictureSingleList"
    },
    onBeforeShow: function () {
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        this.setHeight = false;

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(this.model, 'setupSetting', this.render, this);

        this.listenTo(this.model, 'change', this.render, this);

        this.listenTo(userChannel, 'rightContent:setupCssStyle', function () {
            //this.setupStyle();
        });

        this.imgWidth = 750;
        this.imgHeight = 750;
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'pictureSingle': '#pictureSingle'
    },
    events: {
    },
    onDomRefresh: function () {
        this.setupMargin();
        this.setupStyle();
    },
    onRender: function () {
        //this.setupSetting();
    },
    setupSetting() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        var self = this;
        var nowLocalDate = new Date();
        this.pictureSingleClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();
        this.ui.pictureSingle.addClass(this.editorClass);
        var element = $('.' + this.pictureSingleClass)[0];

        this.timeoutId = setInterval(function () {
            self.nextTemplate();
        }, 1000);
    },
    onBeforeDestroy: function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },
    onDestroy: function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },
    setupStyle: function () {
        this.ui.pictureSingle.find('.skitter').css('background-color', 'transparent');
        this.collection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);
        if (this.collection.length > 0) {

            /*if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }*/

            this.pictureSingleList.empty();
            this.pictureSingleList.show(new View_PictureSingleList({ app: this.app, collection: this.collection }));

            var self = this;

            this.ui.pictureSingle.find('.pictureSingle_p').css('margin-top', this.model.attributes.Data.Margin1_Top + 'px');
            this.ui.pictureSingle.find('.pictureSingle_p').css('margin-bottom', this.model.attributes.Data.Margin1_Bottom + 'px');
            this.ui.pictureSingle.find('.pictureSingle_p').css('margin-left', this.model.attributes.Data.Margin1_Left + 'px');
            this.ui.pictureSingle.find('.pictureSingle_p').css('margin-right', this.model.attributes.Data.Margin1_Right + 'px');

            var font1_textAlign = this.app.entities.collections.TextAlign.where({
                Value: this.model.attributes.Data.Font1_TextAlign
            });

            font1_textAlign[0].attributes.CssStyle.forEach(function (entry) {
                self.ui.pictureSingle.find('.pictureSingle_p').css(entry.Css, entry.Value);
            });

            this.ui.pictureSingle.find('.pictureSingle_p').css('font-family', this.model.attributes.Data.Font1);
            this.ui.pictureSingle.find('.pictureSingle_p').css('font-size', this.model.attributes.Data.Font1_Size);
            this.ui.pictureSingle.find('.pictureSingle_p').css('color', this.model.attributes.Data.Font1_Color);

            var font1_style = this.app.entities.collections.FontStyle.where({
                Value: this.model.attributes.Data.Font1_Style
            });

            font1_style[0].attributes.CssStyle.forEach(function (entry) {
                self.ui.pictureSingle.find('.pictureSingle_p').css(entry.Css, entry.Value);
            });

            var animations_empty = [];
            var animations_full = [
                'cube',
                'cubeRandom',
                'block',
                'cubeStop',
                'cubeStopRandom',
                'cubeHide',
                'cubeSize',
                'horizontal',
                'showBars',
                'showBarsRandom',
                'tube',
                'fade',
                'fadeFour',
                'paralell',
                'blind',
                'blindHeight',
                'blindWidth',
                'directionTop',
                'directionBottom',
                'directionRight',
                'directionLeft',
                'cubeSpread',
                'glassCube',
                'glassBlock',
                'circles',
                'circlesInside',
                'circlesRotate',
                'cubeShow',
                'upBars',
                'downBars',
                'hideBars',
                'swapBars',
                'swapBarsBack',
                'swapBlocks',
                'cut'
            ];

            var callback = function () {

            };

            this.ui.pictureSingle.find('.skitter-large').skitter({
                interval: 1000000000,
                label_animation: this.model.attributes.Data.LabelAnimation,
                label: this.model.attributes.Data.EnableLabel,
                show_randomly: this.model.attributes.Data.EnableRandom,
                with_animations: this.model.attributes.Data.EnableRandomAnimation ? animations_full : animations_empty,
                stop_over: false,
                auto_play: false,
                progressbar: false,
                dots: false,
                responsive: {
                    small: {
                        animation: 'fade',
                        max_width: '100%',//768,
                        suffix: '-small'
                    },
                    medium: {
                        animation: 'directionRight',
                        max_width: '100%',//1024,
                        suffix: '-medium'
                    },
                    large: {
                        animation: 'directionRight',
                        max_width: '100%',//1070,
                        suffix: '-large'
                    }
                }
            });

            this.duration = [];

            var first = this.getFirstPicture();

            this.collection.forEach(function (entry) {
                if (entry.attributes.Position != first) {
                    self.duration.push(parseInt(entry.attributes.Duration) * 1000);
                }
            });

            if (this.collection.length > 0) {
                var tmpModel = this.collection.where({ Position: first })[0];

                if (tmpModel) {
                    self.duration.push(parseInt(tmpModel.attributes.Duration) * 1000);
                }
            }

            this.current = -1;

            //this.ui.pictureSingle.find('.image_main').css('display', 'none');

            this.imgWidth = this.ui.pictureSingle.width();
            this.imgHeight = this.ui.pictureSingle.height();

            if (this.imgWidth != 0 && this.imgHeight != 0) {

                if (this.imgWidth < this.imgHeight) {
                    var aspectRatio = this.imgHeight / this.imgWidth;
                    this.imgHeight = this.imgWidth * aspectRatio;
                } else {
                    var aspectRatio = this.imgWidth / this.imgHeight;
                    this.imgWidth = this.imgHeight * aspectRatio;
                }

                this.ui.pictureSingle.find('.label_skitter').addClass('div100');

                //this.ui.pictureSingle.find('.image_main').css('display', 'none');
                this.ui.pictureSingle.find('.image').css('width', this.imgWidth + 'px');
                this.ui.pictureSingle.find('.image').css('height', this.imgHeight + 'px');
                this.ui.pictureSingle.find('.image_main').css('width', this.imgWidth + 'px');
                this.ui.pictureSingle.find('.image_main').css('height', this.imgHeight + 'px');
                this.ui.pictureSingle.find('.skitter').css('width', this.imgWidth + 'px');
                this.ui.pictureSingle.find('.skitter').css('height', this.imgHeight + 'px');

                this.ui.pictureSingle.find('.container_skitter').css('width', this.imgWidth + 'px');
                this.ui.pictureSingle.find('.container_skitter').css('height', this.imgHeight + 'px');


                this.ui.pictureSingle.find('.pictureSingle_p').css('width', this.imgWidth + 'px');
                this.ui.pictureSingle.find('.pictureSingle_p').css('height', this.imgHeight + 'px');
            }

            //this.nextTemplate();
        }
    },
    getFirstPicture: function () {
        var ret = Number.MAX_VALUE; 

        this.collection.forEach(function (entry) {
            if (entry.attributes.Position < ret) {
                ret = entry.attributes.Position;
            }
        });

        return ret;
    },
    nextTemplate: function () {
        var self = this;

        if (this.setHeight == false) {
            this.setupMargin();
            this.setupStyle();
            this.setHeight = true;
        }
        
        this.current++;
        if (this.current > this.collection.length) this.current = 0;

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            var pictureSingle = this.ui.pictureSingle.find('.skitter-large');
            if (pictureSingle != undefined) {
                pictureSingle.skitter('next');
            }
        }

        this.timeoutId = setInterval(function () {
            self.nextTemplate();
        }, this.duration[this.current]);
    },
    onDestroy: function () {
    },
}, Backbone.Radio.Requests);
