﻿'use strict'

import * as Entities_Language from './entities/language';
import config from './config';

var Language = {
    getDefault: function () {
        window.tmp = {};

        var luxaforboard_userCultureName = this.getCookie('luxaforboard_userCultureName');

        if (luxaforboard_userCultureName && luxaforboard_userCultureName != "null") {
            window.luxaforboard.config.cultureName = luxaforboard_userCultureName;
        }
        else {
            //var userLang = navigator.language || navigator.userLanguage;  not disabled

            var userLang = window.navigator.userLanguage;
            if (userLang == undefined) userLang = window.navigator.language;
            window.luxaforboard.config.cultureName = this.getLanguageCodeFromBrowser(userLang);

            //window.luxaforboard.config.cultureName = 'da-DK';
            luxaforboard_userCultureName = window.luxaforboard.config.cultureName;
        }

        this.createCookie("luxaforboard_userCultureName", luxaforboard_userCultureName, 10000);

    },
    createCookie: function (name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    },
    getCookie: function getCookie(name) {
        var re = new RegExp(name + "=([^;]+)");
        var value = re.exec(document.cookie);
        return (value != null) ? unescape(value[1]) : null;
    },
    userLanguageChange: function (language) {
        var self = this;

        var callback = function () {
            /*var luxaforboard_userCultureName = '';
            switch (language) {
                case 'DK': luxaforboard_userCultureName = 'da-DK'; break;
                case 'GB': luxaforboard_userCultureName = 'en-GB'; break;
                case 'DE': luxaforboard_userCultureName = 'de-DE'; break;
            }*/
            //self.createCookie("luxaforboard_userCultureName", luxaforboard_userCultureName, 10000);
            self.createCookie("luxaforboard_userCultureName", language, 10000);

            location.reload();
        }

        window.app.router.checkHasChanged(callback);
    },
    getLanguageCodeFromBrowser: function (language) {
        switch (language) {
            case 'en': return "en-GB"; break;
            case 'EN': return "en-GB"; break;
            case 'en-gb': return "en-GB"; break;
            case 'en-GB': return "en-GB"; break;
            case 'EN-GB': return "en-GB"; break;
            case 'da': return "da-DK"; break;
            case 'DA': return "da-DK"; break;
            case 'da-dk': return "da-DK"; break;
            case 'da-DK': return "da-DK"; break;
            case 'DA-DK': return "da-DK"; break;
            default: return ("en-GB"); break;
        };
        return "da-DK";
    },
    getLanguageFromCodeCultureName: function (language) {
        switch (language) {
            case 'en-GB': return "GB"; break;
            case 'da-DK': return "DK"; break;
            default: return ("GB"); break;
        };
        return "DK";
    },
    getCurrencyFromCultureName: function (cultureName) {
        switch (cultureName) {
            case 'en-GB': return "GBP"; break;
            case 'da-DK': return "Kr"; break;
            default: return ("GBP"); break;
        };
        return "Kr";
    },
    getLanguage_bootstrap_datePicker: function (cultureName) {
        var language_bootstrap_datePicker = '';
        switch (cultureName) {
            case 'da-DK': language_bootstrap_datePicker = 'da'; break;
            default: language_bootstrap_datePicker = 'en-GB'; break;
        }

        return language_bootstrap_datePicker;
    },
    getLanguageDataTables: function () {
        var language = new Array;

        language = {
            'sProcessing': resources.X100004,
            'sLengthMenu': resources.X100005,
            'sZeroRecords': resources.X100006,
            'sInfo': resources.X100007,
            'sInfoEmpty': resources.X100008,
            'sInfoFiltered': resources.X100009,
            'sInfoPostFix': resources.X1000010,
            'sSearch': resources.X100011,
            'sUrl': resources.X100012,
            'oPaginate': {
                'sFirst': '&lsaquo;',
                'sLast': '&rsaquo;',
                'sNext': '&raquo;',
                'sPrevious': '&laquo;'
            },
            'oAria': {
                'sSortAscending': resources.X100014,
                'sSortDescending': resources.X100015
            },
            'sRefresh': resources.X100013
        }

        return language;
    },
    //Remember to save it with UTF-8 from notepad
    setLanguage: function (cultureName, callback) {
        var entities = new Entities_Language.Collection();

        $.when(

            entities.fetch({
                parseField: cultureName,
                wait: true,
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            })
        ).done(function () {
            callback(entities.models);
        });
    },
    setLanguageFromFile: function (cultureName, filename, callback) {
        var entities = new Entities_Language.Collection();

        $.when(
            entities.fetch({
                parseField: cultureName,
                filename: filename,
                wait: true,
                SkipsCache:true,
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            })
        ).done(function () {
            callback(entities.models);
        });
    }
};

export default Language;