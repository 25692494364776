﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaDataList from './tpl/customSchemaDataList.html';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import config from '../../../common/config';
import language from '../../../common/language';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customSchemaDataList();
    },
    deleteRows: [],
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;

        //this.collection.on('change', this.render, this);
        this.listenTo(this.collection, 'change', this.render);

        //this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_add', this.render);
        //this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_remove', this.render);

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customSchemaDataList:save', function(callbackRouter) {
            self.save(callbackRouter);
        });

        this.listenTo(userChannel,'customSchemaDataList:setSchemaModel', function(schemaModel) {
            self.options.schemaModel = schemaModel
        });

        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.customSchemaDataList_btnSubmit(null);
        });
    },
    ui: {
        'customSchemaDataList_btnSubmit': '#customSchemaDataList_btnSubmit',
        'customSchemaDataList_schemaData': '#customSchemaDataList_schemaData'
    },
    events: {
        'click @ui.customSchemaDataList_btnSubmit': 'customSchemaDataList_btnSubmit',
    },
    setHasChanged: function (enable) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customSchemaData:setHasChanged', enable);
    },
    onRender: function () {
        this.addDataTable();

        this.setup_schemaData_length();

        this.setup();

        if (this.app.mode == 1) {
            this.ui.customSchemaDataList_btnSubmit.css('display', 'none');
        }
    },
    onAttach(){
    },
    onDestroy(){
        this.table.clear();
    },
    setup: function () {
        if (this.settingModel.attributes.EventId > 0 && this.settingModel.attributes.SchemaSchemaId > 0) {
            this.ui.customSchemaDataList_btnSubmit.removeClass('btn-secondary');
            this.ui.customSchemaDataList_btnSubmit.addClass('btn-primary');
            this.ui.customSchemaDataList_btnSubmit.prop('disabled', false);
        }
        else {
            this.ui.customSchemaDataList_btnSubmit.removeClass('btn-primary');
            this.ui.customSchemaDataList_btnSubmit.addClass('btn-secondary');
            this.ui.customSchemaDataList_btnSubmit.prop('disabled', true);
        }
    },
    set_schemaData_length: function (length) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'SchemaDataItemPerPage' });
        customSetting[0].attributes.Value = length;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    setup_schemaData_length: function (count) {
        var element = this.$el.find('#schemaData_length');

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'SchemaDataItemPerPage' });
        var schemaDataItemPerPage = customSetting[0].attributes.Value;
        
        var element = '#customSchemaDataList_table_length [value="' + customSetting[0].attributes.Value + '"]';
        $(element).prop('selected', true);
    },
    table_deleteAll: function () {
        this.setHasChanged(true);

        var self = this;
        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid != -1) {
                self.deleteRows.push(d);
            }
        });

        this.table.clear().draw();
    },
    table_getFirstVisibleItem: function () {
        var first = 1;
        var getFirst = false;
        this.$el.find('#customSchemaDataList_table tbody tr').each(function (i, tr) {
            if (!getFirst) {
                if ($(tr).attr('id') != undefined) {
                    first = parseInt($(tr).attr('id'));
                }
                getFirst = true;
            }
        });

        this.settingModel.attributes.TableFirstVisibleItem = first;
        return first;
    },
    table_addModel: function (tmpModel, jumpToData, jumpFirst, ignoreEmptyContent) {
        this.setHasChanged(true);
        //Import sometimes insert a empty element
        var totalContentLength = tmpModel.attributes.Field01.length + tmpModel.attributes.Field02.length + tmpModel.attributes.Field03.length +
            tmpModel.attributes.Field04.length + tmpModel.attributes.Field05.length + tmpModel.attributes.Field06.length +
            tmpModel.attributes.Field07.length + tmpModel.attributes.Field08.length + tmpModel.attributes.Field09.length +
            tmpModel.attributes.Field10.length + tmpModel.attributes.Field11.length + tmpModel.attributes.Field12.length;

        //If user insert a empty item
        if (ignoreEmptyContent) totalContentLength = 1;

        if (totalContentLength > 0) {
            //Romove all item with a bigger or equal id
            this.table.rows().every(function () {
                var d = this.data();
                if (d.id >= tmpModel.attributes.id) {
                    d.id++; // update data source for the row
                    this.invalidate(); // invalidate the data DataTables has cached for this row
                }
            });

            this.table.rows.add([{
                id: tmpModel.attributes.id,
                Position: tmpModel.attributes.id,
                EventId: tmpModel.attributes.EventId,
                Schema: tmpModel.attributes.Schema,
                Field01: tmpModel.attributes.Field01,
                Field02: tmpModel.attributes.Field02,
                Field03: tmpModel.attributes.Field03,
                Field04: tmpModel.attributes.Field04,
                Field05: tmpModel.attributes.Field05,
                Field06: tmpModel.attributes.Field06,
                Field07: tmpModel.attributes.Field07,
                Field08: tmpModel.attributes.Field08,
                Field09: tmpModel.attributes.Field09,
                Field10: tmpModel.attributes.Field10,
                Field11: tmpModel.attributes.Field11,
                Field12: tmpModel.attributes.Field12,
                ShowSchema: tmpModel.attributes.ShowSchema,
                ShowSchemaBigscreen: tmpModel.attributes.ShowSchemaBigscreen,
                orgid: -1,
            }]).draw();
        }

        if (jumpToData) {
            this.table.page.jumpToData(jumpFirst, 0);
            //this.jumpToData(jumpFirst, 0);
        }
    },
    jumpToData : function (data, column) {
        var pos = this.table.column(column, {order:'current'}).data().indexOf( data );

        if ( pos >= 0 ) {
            var page = Math.floor( pos / this.table.page.info().length );
            this.table.page( page ).draw( false );
        }
    },
    insertModel: function (tmpModel, jumpToData) {
        this.setHasChanged(true);

        var first = 1;
        var getFirst = false;
        this.$el.find('#customSchemaDataList_table tbody tr').each(function (i, tr) {
            if (!getFirst) {
                if ($(tr).attr('id') != undefined) {
                    first = parseInt($(tr).attr('id'));
                }
                getFirst = true;
            }
        });

        this.table.rows().every(function () {
            var d = this.data();
            if (d.id >= first) {
                d.id++; // update data source for the row
                this.invalidate(); // invalidate the data DataTables has cached for this row
            }
        });

        this.table.rows.add([{
            id: first,
            Position: first,
            EventId: tmpModel.attributes.EventId,
            Schema: tmpModel.attributes.Schema,
            Field01: tmpModel.attributes.Field01,
            Field02: tmpModel.attributes.Field02,
            Field03: tmpModel.attributes.Field03,
            Field04: tmpModel.attributes.Field04,
            Field05: tmpModel.attributes.Field05,
            Field06: tmpModel.attributes.Field06,
            Field07: tmpModel.attributes.Field07,
            Field08: tmpModel.attributes.Field08,
            Field09: tmpModel.attributes.Field09,
            Field10: tmpModel.attributes.Field10,
            Field11: tmpModel.attributes.Field11,
            Field12: tmpModel.attributes.Field12,
            orgid: -1,
        }]);//.draw();

        if (jumpToData) {
            this.table.page.jumpToData(parseInt(first), 0);
        }
    },
    table_rearrange: function () {
        //rearrange
        var prevdata = null;
        this.table.rows().every(function () {
            var data = this.data();

            if (prevdata != null) {
                if (data.id != (prevdata.id + 1)) {
                    data.id = prevdata.id + 1;
                    this.invalidate(); // invalidate the data DataTables has cached for this row
                }
            }
            else {
                if (data.id != 1) {
                    data.id = 1;
                    this.invalidate();
                }
            }

            prevdata = data;
        });
    },
    addDataTable: function () {
        var self = this;

        var schemaCounter = '1';
        var sElement = '';

        sElement += '<thead>';
        sElement += '</thead>';

        sElement += '<tfoot>';
        sElement += '<tr>';
        sElement += '</tr>';
        sElement += '</tfoot>';


        this.$el.find('#customSchemaDataList_schemaData').empty();
        //$(schemaElement).append('<h2 id="schemaDataHeader' + schemaCounter + '" style="text-align:center;">' + schemaSchema[schemaCounter].Name + '</h2>');
        //this.$el.find('#customSchemaDataList_schemaData').append('<div class="table-responsive"><table id="customSchemaDataList_table" width="100%" class="table table-striped"  cellspacing="0"></table></div>');
        this.$el.find('#customSchemaDataList_schemaData').append('<div class="table-responsive"><table id="customSchemaDataList_table" width="100%" class="table table-striped"  cellspacing="0"></table></div>');

        var self = this;

        var c = this.collection.toJSON();
        for (var i = 0; i < c.length; i++) {
            c[i].orgid = c[i].id;
            c[i].id = c[i].Position;
            //c[i].ShowSchemaBigscreen = "1";
            //c[i].aaa = "1";
        }

        var columns = this.getColumns();
        
        var lng = language.getLanguageDataTables();

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'SchemaDataItemPerPage' });
        var schemaDataItemPerPage = customSetting[0].attributes.Value;

        var table = this.$el.find('#customSchemaDataList_table').DataTable({
            "language": lng,
            data: c,
            "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //Initialize row id for every row
                nRow.setAttribute('id', aData.id);
            },
            "columns": columns,
            "bInfo": false,
            "iDisplayLength": parseInt(schemaDataItemPerPage)
            //"initComplete": function(settings){
            //    $('.AAA').each(function () {
            //        var $td = $(this);
            //        $td.attr('title', "TOOLTIP");

            //        $td.append('<div data-trigger="focus" data-toggle="popover"></div>');

            //    });

            //    /* Apply the tooltips */
            //    $('.AAA').tooltip(
            //    {
            //        position:'bottom center',
            //        relative:false,
            //        onShow: function(){
            //            var $trigger = this.getTrigger();
            //            this.getTip().css({
            //                'margin-top' : $trigger.css('margin-top'),
            //                'margin-left' : $trigger.css('margin-left') 
            //            });
            //        }

            //    });          
            //}  

            //rowCallback: function ( row, data ) {
            //    // Set the checked state of the checkbox in the table
            //    alert("HER1");
            //    $('input.editor-active', row).prop( 'checked', data == 1 );
            //}
        });

        this.table = table;
        this.$el.find('#customSchemaDataList_table').dataTable().rowReordering({});

        this.$el.find('#customSchemaDataList_table_wrapper').removeClass('form-inline');


        var aself = this;
        this.$el.find('#customSchemaDataList_table tbody').on( 'change', 'input.editor-active', function (row) {
            var id = row.target.id.split('_')[1];
            var iId = parseInt(id);
            var selector = row.target.id.split('_')[0];

            var checked = row.target.checked;
            
            aself.table.rows().every(function () {
                var d = this.data();
                if (d.orgid == iId ) {
                    switch (selector) {
                        case 'ShowSchemaBigscreen' : d.ShowSchemaBigscreen = checked; break;
                        case 'ShowSchema' : d.ShowSchema = checked; break;
                    }
                }
            });
            
        });

        var self = this;

        //Edit row
        this.$el.find('#customSchemaDataList_table tbody').on('click', '.btn-secondary', function (val) {
            self.setHasChanged(true);

            var nRow = $(this).parents('tr');
            var jqInputs = $('input', nRow);
            var jqtd = $('tr', nRow);

            var id = this.id;
            id = id.replace('btn_', '');
            //var tmpModel = self.collection.where({ id: parseInt(id) });
            //var fieldName = '';




            var row = table.row(self.tmpTr);

            var data = row.data();
            for (var i = 0; i < jqInputs.length - 3; i++) {
                //self.tmpnRow[0].cells[i + 1].textContent = jqInputs[i].value;
                switch (i) {
                    case 0: data.Field01 = jqInputs[i].value; break;
                    case 1: data.Field02 = jqInputs[i].value; break;
                    case 2: data.Field03 = jqInputs[i].value; break;
                    case 3: data.Field04 = jqInputs[i].value; break;
                    case 4: data.Field05 = jqInputs[i].value; break;
                    case 5: data.Field06 = jqInputs[i].value; break;
                    case 6: data.Field07 = jqInputs[i].value; break;
                    case 7: data.Field08 = jqInputs[i].value; break;
                    case 8: data.Field09 = jqInputs[i].value; break;
                    case 9: data.Field10 = jqInputs[i].value; break;
                    case 10: data.Field11 = jqInputs[i].value; break;
                    case 11: data.Field12 = jqInputs[i].value; break;
                }
            }

            data.ShowSchema = jqInputs[jqInputs.length - 3].checked;
            data.ShowSchemaBigscreen = jqInputs[jqInputs.length - 2].checked;

            row.invalidate();

            row.child.hide();
            self.tmpTr.removeClass('shown');
        });

        //Expand row for edit
        this.$el.find('#customSchemaDataList_table tbody').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);

            self.tmpnRow = $(this).parents('tr');
            self.tmpTr = tr;

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                row.child(self.format(row.data())).show();
                tr.addClass('shown');
            }
        });

        

        this.$el.find('#customSchemaDataList_table tbody').on('click', 'td.details-delete', function () {
            self.setHasChanged(true);

            var infoBefore = table.page.info();
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var data = row.data();
            var infoAfter = table.page.info();

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100250;
            dialog2ButtonModel.attributes.Text = resources.X100255;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                if (data.orgid != -1) {
                    self.deleteRows.push(data);
                }
                row.remove().draw();
                self.table_rearrange();

                if (infoAfter.recordsTotal == infoBefore.start) {
                    self.table.page.jumpToData(infoBefore.start, 0);
                }
                else {
                    self.table.page.jumpToData(infoBefore.start + 1, 0);
                }
            }
            
            self.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            self.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(dialog2Button);
        });

        // Order by the grouping

        //this.$el.find('#customSchemaDataList_table tbody').on( 'click', 'tr.group', function () {
        //    var currentOrder = table.order()[0];
        //    if ( currentOrder[0] === 2 && currentOrder[1] === 'asc') {
        //        table.order( [ 2, 'desc'] ).draw();
        //    }
        //    else {
        //        table.order( [ 2, 'asc'] ).draw();
        //    }
        //});

        //this.$el.find('#customSchemaDataList_table').removeClass('dataTable');

        this.$el.find('#customSchemaDataList_table_paginate').parent().removeClass('col-sm-7').addClass('col-sm-12');
        this.$el.find('#customSchemaDataList_table_paginate').css('text-align', 'center');

        this.$el.find('.form-control.input-sm').css('width', '150px');
        this.$el.find('.form-control.input-sm').addClass('form-control-sm');

        $('#customSchemaDataList_table').on('length.dt', function ( e, settings, len ) {
            self.set_schemaData_length(len);
        } ); 
    },
    format: function format(d) {
        // `d` is the original data object for the row

        var edit = '';
        var title = '';
        var data = '';

        for (var i = 0; i < this.options.schemaModel.attributes.FieldUsed; i++) {
            switch (i) {
                case 0: title = this.options.schemaModel.attributes.Field01; data = d.Field01; break;
                case 1: title = this.options.schemaModel.attributes.Field02; data = d.Field02; break;
                case 2: title = this.options.schemaModel.attributes.Field03; data = d.Field03; break;
                case 3: title = this.options.schemaModel.attributes.Field04; data = d.Field04; break;
                case 4: title = this.options.schemaModel.attributes.Field05; data = d.Field05; break;
                case 5: title = this.options.schemaModel.attributes.Field06; data = d.Field06; break;
                case 6: title = this.options.schemaModel.attributes.Field07; data = d.Field07; break;
                case 7: title = this.options.schemaModel.attributes.Field08; data = d.Field08; break;
                case 8: title = this.options.schemaModel.attributes.Field09; data = d.Field09; break;
                case 9: title = this.options.schemaModel.attributes.Field10; data = d.Field10; break;
                case 10: title = this.options.schemaModel.attributes.Field11; data = d.Field11; break;
                case 11: title = this.options.schemaModel.attributes.Field12; data = d.Field12; break;
            }


            edit += '<tr>' +
                '<td>' + title + '</td>' +
                '<td><input class="form-group" value="' + data + '" /></td>' +
            '</tr>';
        }

        if (d.ShowSchema) {
            edit += '<tr>' + 
                '<td>' + resources.X100256 + '</td>' +
                '<td><input id="ShowSchema" type="checkbox" checked="checked" /></td>' +
            '</tr>'
        } else {
            edit += '<tr>' + 
                '<td>' + resources.X100256 + '</td>' +
                '<td><input id="ShowSchema" type="checkbox" /></td>' +
            '</tr>'
        }

        if (d.ShowSchemaBigscreen) {
            edit += '<tr>' + 
                '<td>' + resources.X100257 + '</td>' +
                '<td><input id="ShowSchemaBigscreen" type="checkbox" checked="checked" /></td>' +
            '</tr>'
        } else {
            edit += '<tr>' + 
                '<td>' + resources.X100257 + '</td>' +
                '<td><input id="ShowSchemaBigscreen" type="checkbox" /></td>' +
            '</tr>'
        }

        return '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
            edit +
            '<tr>' +
                '<td><input class="btn btn-secondary btnSchemaData" id="btn_' + d.id + '" value="' + resources.X100258 +'"/></td>' +
            '</tr>' +

        '</table>';
    },
    getColumns: function () {
        var array = [];

        var tmp = {
            "className": 'details-control',
            //    "orderable": false,
            //    'sortable' : false,
            "data": null,
            "defaultContent": '',
            "title": ''
        };

        tmp = { "data": "id", "title": "#", "visible": true };
        array.push(tmp);

        tmp = { "data": "id", "title": "orgid", "visible": false };
        array.push(tmp);

        var data = '';
        var title = '';

        for (var i = 0; i < this.options.schemaModel.attributes.FieldUsed; i++) {
            switch (i) {
                case 0: title = this.options.schemaModel.attributes.Field01; data = 'Field01'; break;
                case 1: title = this.options.schemaModel.attributes.Field02; data = 'Field02'; break;
                case 2: title = this.options.schemaModel.attributes.Field03; data = 'Field03'; break;
                case 3: title = this.options.schemaModel.attributes.Field04; data = 'Field04'; break;
                case 4: title = this.options.schemaModel.attributes.Field05; data = 'Field05'; break;
                case 5: title = this.options.schemaModel.attributes.Field06; data = 'Field06'; break;
                case 6: title = this.options.schemaModel.attributes.Field07; data = 'Field07'; break;
                case 7: title = this.options.schemaModel.attributes.Field08; data = 'Field08'; break;
                case 8: title = this.options.schemaModel.attributes.Field09; data = 'Field09'; break;
                case 9: title = this.options.schemaModel.attributes.Field10; data = 'Field10'; break;
                case 10: title = this.options.schemaModel.attributes.Field11; data = 'Field11'; break;
                case 11: title = this.options.schemaModel.attributes.Field12; data = 'Field12'; break;
            }

            tmp = { "data": data, "title": title };
            array.push(tmp);
        }

        var dataShowSchema = { 
            data : "ShowSchema",
            title : resources.X100510,
            searchable:false,
            orderable :false,
            render: function ( data, type, row ) {
                if ( type === 'display' ) {
                    var id = 'id="ShowSchema_' + row.orgid + '"';
                    if (data == "1") {
                        return '<input type="checkbox" class="editor-active" checked="checked"' + id + '>';
                    } else {
                        return '<input type="checkbox" class="editor-active"' + id + '>';
                    }
                }
                return data;
            },
            className: "dt-body-center AAA"
        }

        array.push(dataShowSchema);

        var dataShowSchemaBigscreen = { 
            data : "ShowSchemaBigscreen",
            title : resources.X100511,
            searchable:false,
            orderable :false,
            render: function ( data, type, row ) {
                if ( type === 'display' ) {
                    var id = 'id="ShowSchemaBigscreen_' + row.orgid + '"';
                    if (data == "1") {
                        return '<input type="checkbox" class="editor-active" checked="checked"' + id + '>';
                    } else {
                        return '<input type="checkbox" class="editor-active"' + id + '>';
                    }
                }
                return data;
            },
            className: "dt-body-center"
        }

        array.push(dataShowSchemaBigscreen);

        

        tmp = {
            "className": 'details-delete',
            "orderable": false,
            "data": null,
            "defaultContent": '',
            "title": '--'
        };
        array.push(tmp);

        var tmp = {
            "className": 'details-control',
            //    "orderable": false,
            "data": null,
            "defaultContent": '',
            "title": '--'
        };
        array.push(tmp);

        return array;
    },
    customSchemaDataList_btnSubmit: function (event) {
        if (event) {
            event.preventDefault();
        }

        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100250;
        dialogWaitingModel.attributes.Text = resources.X100252;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);


        $.when(this.list_deleteAll(), this.list_insertAll(), this.list_editAll()).done(function (return_delete, return_insert, return_edit) {
            // the code here will be executed when all four ajax requests resolve.
            // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
            // status, and jqXHR object for each of the four ajax calls respectively.

            //this.app.entities.views.customSchemaData.table_readDataCollection();
            dialogWaiting.triggerMethod('dialog:close');
            self.triggerMethod('table_readDataCollection');
            self.setHasChanged(false);
        });


    },
    save: function (callbackRouter) {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100250;
        dialogWaitingModel.attributes.Text = resources.X100252;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);


        $.when(this.list_deleteAll(), this.list_insertAll(), this.list_editAll()).done(function (return_delete, return_insert, return_edit) {
            // the code here will be executed when all four ajax requests resolve.
            // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
            // status, and jqXHR object for each of the four ajax calls respectively.

            //this.app.entities.views.customSchemaData.table_readDataCollection();
            dialogWaiting.triggerMethod('dialog:close');
            self.setHasChanged(false);
            callbackRouter();
        });


    },
    list_deleteAll: function () {
        if (this.deleteRows.length > 0) {
            for (var i = 0; i < this.deleteRows.length; i++) {
                this.deleteRows[i].id = this.deleteRows[i].orgid;
            }

            var data = {
                "data": this.deleteRows
            };

            var url = config.webApiUrl() + '/api/CustomSchemaData/route02/-1?AuthUserId=' + config.authUserId() + '&Dummy=1';
            var self = this;

            this.deleteRows = [];

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_insertAll: function () {
        var insertRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid == -1) {
                insertRows.push(d);
            }
        });

        if (insertRows.length > 0) {
            for (var i = 0; i < insertRows.length; i++) {
                insertRows[i].Position = insertRows[i].id;
                //insertRows[i].id = null;
            }

            var data = {
                "data": insertRows
            };

            var url = config.webApiUrl() + '/api/CustomSchemaData/route03/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_editAll: function () {
        var editRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid != -1) {
                editRows.push(d);
            }
        });

        if (editRows.length > 0) {
            for (var i = 0; i < editRows.length; i++) {
                editRows[i].Position = editRows[i].id;
                editRows[i].id = editRows[i].orgid;
            }

            var data = {
                "data": editRows
            };

            var url = config.webApiUrl() + '/api/CustomSchemaData/route04/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1&Dummy2=2';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    }
}, Backbone.Radio.Requests);