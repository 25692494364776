var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="newPage_empty"> <div class="modal fade" id="newPage_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content modal-lg"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X100136'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="control-group newPage_inputName"> <label id="newPage_labelName" for="newPage_inputName">'+
((__t=( resources['X100918'] ))==null?'':__t)+
'</label> <input class="form-control" input id="newPage_inputName" value="'+
((__t=( model.Name ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div id="newPage_group_Duration" class="form-group"> <label>'+
((__t=( resources['X101427'] ))==null?'':__t)+
'</label> <input class="form-control" id="newPage_input_Duration" value="'+
((__t=( model.Duration ))==null?'':__t)+
'"> </div> </div>  </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="control-group newPage_inputStatus" style=""> <div class="control-label col-md-6"> <label for="newPage_labelName">'+
((__t=( resources['X100968'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="newPage_inputStatus"> <span class="toggle-slider round"></span> </label> </div> </div> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div id="pageSettingList"></div> </div> <div class="modal-footer"> <button type="button" id="newPage_btn01" class="btn btn-primary">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="newPage_btn02" class="btn btn-secondary">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
