var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="header_empty"> <ul id="headerList" class="navbar-nav navbar-nav-right"> <li id="languageSelectorRegion" class="nav-item dropdown"> </li>       <li class="nav-item nav-profile dropdown" style="display:none"> <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown-navbar"> <img src="" class="fa fa-3x fa-user-circle" style="height:0px; width:0px;padding-right:30px;padding-bottom:30px;color:#2f3cff" alt="">  </a> <div style="z-index:1050 !important" class="dropdown-menu dropdown-menu-right navbar-dropdown flat-dropdown" aria-labelledby="profileDropdown-navbar"> <div style="z-index:1050 !important" class="flat-dropdown-header"> <div class="d-flex"> <img src="" class="profile-icon mr-2 fa fa-3x fa-user-circle" style="height:0px; width:0px;padding-right:45px;padding-top:5px;color:#ffffff" alt=""> <div style=""> <span id="header_loginName" class="profile-name font-weight-bold">Cecelia Vargas</span> <p id="header_loginOrg" class="profile-designation">'+
((__t=( resources['X200034'] ))==null?'':__t)+
'</p> </div> </div> </div> <div class="profile-dropdown-body"> <ul class="list-profile-items">  <li class="profile-item"> <a href="#custom" class="profile-dropdown-link"> <div class="d-flex align-items-center"> <i class="mdi mdi-account-outline text-primary"></i> <div> <h5 class="item-title">'+
((__t=( resources['X200034'] ))==null?'':__t)+
'</h5> <p class="item-detail">'+
((__t=( resources['X200048'] ))==null?'':__t)+
'</p> </div> </div> </a> </li>  <li class="profile-item"> <a id="header_btnAuthLogoff" class="profile-dropdown-link"> <div class="d-flex align-items-center"> <i class="mdi mdi-power text-dark"></i> <div> <h5 class="item-title mt-0">'+
((__t=( resources['X200035'] ))==null?'':__t)+
'</h5> </div> </div> </a> </li> </ul> </div> </div> </li> </ul> </div>';
}
return __p;
};
