﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogPreviewDias from './tpl/dialogPreviewDias.html';

export default Marionette.ItemView.extend({
    dialogRegion : '#dialogPreviewDias_modal',
    getTemplate: function () {
        return tpl_dialogPreviewDias({ model: this.model.toJSON() });
    },
    ui: {
        'dialogPreviewDias_modalDialog': '#dialogPreviewDias_modalDialog',
        'dialogPreviewDias_iframe': '#dialogPreviewDias_iframe'
    },
    events: {
        "click #dialogPreviewDias_btnOk": "onOK"
    },
    onAttach() {
        //$('#app-container').css('zoom', '0.6');
        this.ui.dialogPreviewDias_modalDialog.css('height', this.model.attributes.WindowHeigh + 'px');
        this.ui.dialogPreviewDias_modalDialog.css('width', this.model.attributes.WindowWidth + 'px');
        this.ui.dialogPreviewDias_modalDialog.css('max-height', this.model.attributes.WindowHeigh + 'px');
        this.ui.dialogPreviewDias_modalDialog.css('max-width', this.model.attributes.WindowWidth + 'px');
        this.ui.dialogPreviewDias_iframe.css('transform', "scale(" + this.model.attributes.Transform/100 + ")");
        this.ui.dialogPreviewDias_iframe.css('transform-origin', "0% 0%");
    },
    onOK: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    }
});