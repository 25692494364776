var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentReset_empty"> <div class="modal fade" id="departmentReset_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X200258'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200267'] ))==null?'':__t)+
'</h4> <div id="customDepartmentResetInformation" class="e4usInformation" style="display:none"></div> <div id="customDepartmentResetInsert"></div> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200258'] ))==null?'':__t)+
'</h4> <div class="" id="customDepartmentResetList"></div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customDepartmentResetPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="customDepartmentReset_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button style="" type="button" id="customDepartmentReset_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
