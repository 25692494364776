﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Selected : 0
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
});


export var CollectionpPagination = PageableCollection.extend({
    mode: "client",
    model: Model,

    state: {
        pageSize: 6,
    },

    queryParams: {
        sortKey: 'Selected',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },

    options: {
        full: false
    },

    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '';
        }
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? 1
             : a.attributes.id < b.attributes.id ? -1
             : 0;
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            //options.url = config.webApiUrl() + '/mainjs/ImportFilePortalHelp/dummy/dummy/dummy/';
            options.url =  config.portalHelpJson();
        }
        return Backbone.sync(method, model, options);
    },
    parse:function(response, options) {
        return options.parseField ? response[options.parseField] : response;
    },
    searchMenu: function (letters) {
        if(letters == "") return this;

        var list = this.filter(function(data) {
            var pattern = new RegExp("\\b" + letters + "\\b", "g");
            var ret = pattern.test(data.get("Menu"));

            return ret;
        });

        return list;
    },
    search : function(letters){
        //return no one
        if(letters == "") {
            return this.filter(function(data) {
                return false;
            });
        }

        return this.filter(function(data) {
            var $lowerKey = letters.toLowerCase();
            var pattern = new RegExp($lowerKey, "gi");

            var ret = pattern.test(data.get("Subject"));
            if (!ret) ret = pattern.test(data.get("Text"));

            return ret;
        });
    }
});







