var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="empty"> <div id="container" class="container-fluid"> <div style="height:10px"></div> <div id="fixedheader"> <div id="content"> <p style="margin: 10px 15px 10px 15px"> '+
((__t=( model.Content ))==null?'':__t)+
' </p> </div> </div> <div id="fixedfooter" style="padding-top:15px;padding-bottom:0px"> <div>  <div style="width:50%;float:left; text-align:center">  <a href="events4us.net"> <img id="QRCodeImageUrl" style="width:150px;height:150px" alt="" src="#modelbegin# QRCodeImageUrl #modelend#"> </a> <div style="width:100%;margin-left:24%;clear: both"> <p id="eventPosterTemplate_header01" style="width:52%;color:black">#modelbegin# QRCodeTextPoster #modelend#</p> </div>  </div> <div class="rotate" style="width:50%;float:right; text-align:center;margin-bottom:18px"> <img id="WebpageImageUrl" style="width:192px;height:400px" src="#modelbegin# WebpageImageUrl #modelend#"> </div> </div> <div style="clear:both"></div> <div id="public_copyright"> <hr style="border-top-color:black;margin: 0px 0px 0px 0px"> <div style="height:5px"></div> <p style="text-align: center;margin: 5px 0px 10px 0px;font-size:10px; color:black;line-height: 1"> #modelbegin# resources[\'X100064\'] #modelend# <a style="color:black" href="#modelbegin# resources[\'X100021\'] #modelend#">#modelbegin# resources[\'X100065\'] #modelend#</a><br>#modelbegin# resources[\'X100066\'] #modelend# </p> </div> </div> </div> </div>';
}
return __p;
};
