var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customQRCode_empty"> <div class="content-wrapper" data-help="customQRCode">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customEventInformation"></div> <h4 class="card-title">'+
((__t=( resources['X101056'] ))==null?'':__t)+
'</h4> <div id="customQRCodeInformation" class="e4usInformation" style="display:none"></div> <div id="customQRCodeInsert"></div> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101057'] ))==null?'':__t)+
'</h4> <div class="list-wrapper" id="customQRCodeList"> </div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customQRCodePagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
