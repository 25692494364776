'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginFileValidateType from '../../plugins/filepond-plugin-file-validate-type';


import tpl_customFileInsert from './tpl/customFileInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customFileInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customFileInsert_filePond': '#customFileInsert_filePond',
        'customFileInsert_btnSubmit' : '#customFileInsert_btnSubmit',
        'customFileInsert_btnFile' : '#customFileInsert_btnFile',
        'customFileInsert_inputName' : '#customFileInsert_inputName'
    },
    events: {
        'click @ui.customFileInsert_btnSubmit': 'btnSubmitClick',
        'change @ui.customFileInsert_btnFile': 'btnFileChange'
    },
    onRender: function () {
        var self = this;

        /*FileAPI.event.on(this.ui.customFileInsert_btnFile[0], 'change', function (event){
            //remove path
            var filename = event.target.value.split('\\').pop();
            //remove extension
            var dotPosition = filename.lastIndexOf( "." );
            if (dotPosition > 0) {
                filename = filename.substring(0,dotPosition); 
            }

            self.ui.customFileInsert_inputName.val(filename);
            self.btnFileChange(event);
        });*/
    },
    onAttach() {
        var self = this;

        FilePond.registerPlugin(
            FilePondPluginFileValidateType,
            FilePondPluginFileEncode,
        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.customFileInsert_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            acceptedFileTypes: [
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
                'application/vnd.ms-excel',
                'application/vnd.ms-excel.sheet.macroEnabled.12',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                'application/vnd.ms-powerpoint',
                'application/vnd.ms-powerpoint',
                'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                'application/vnd.ms-powerpoint.template.macroEnabled.12',
                'application/vnd.ms-powerpoint.addin.macroEnabled.12',
                'application/vnd.openxmlformats-officedocument.presentationml.template',
                'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-word.document.macroEnabled.12',
                'application/vnd.ms-word.template.macroEnabled.12',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/msword',
                'text/plain'
            ],
            allowMultiple: false,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();
                    var mimeType = self.base64MimeType(base64);
                    var fileData = base64.split(',')[1];

                    self.options.localSetting.attributes.FileData = fileData;
                    self.options.localSetting.attributes.FileMimeType = mimeType;
                    self.options.localSetting.attributes.FileUpdate = true;
                    self.options.localSetting.attributes.FileName = fileItem.filename;

                    self.btnSubmitClick();
                    
                }
            }
        });
    },
    base64MimeType: function (encoded) {
        var result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf( "." );
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1,filename.length).toLowerCase(); 

            switch (ext) {
                case 'pdf' : return true; break;
                case 'txt' : return true; break;
                case 'doc' : return true; break;
                case 'docx' : return true; break;
                case 'xlx' : return true; break;
                case 'ppt' : return true; break;
            }
        }

        return false;
    },
    btnFileChange: function (event) {
        event.preventDefault();
        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();
        //remove extension
        /*var dotPosition = filename.lastIndexOf( "." );
        if (dotPosition > 0) {
            filename = filename.substring(0,dotPosition); 
        }*/

        if (this.validateExt(filename)) {

            this.ui.customFileInsert_inputName.val(filename);

        

        
            this.file = FileAPI.getFiles(event)[0];
            var uploadFile = self.$el.find('.file-input-name');
            uploadFile.val("");

            if (this.file) {
                if (this.file.size > 0 && this.file.size < 2000000) {
                    FileAPI.readAsDataURL(this.file, function (evt/**Object*/) {
                        if (evt.type == 'load') {
                            // Success
                            var arrayBuffer = evt.result;
                            var searchString = ";base64,";
                            var pos = arrayBuffer.indexOf(searchString);
                            var fileMimeType = arrayBuffer.substring(5, pos);
                            var fileData = arrayBuffer.substring(pos + searchString.length, arrayBuffer.length);

                            self.options.localSetting.attributes.FileData = fileData;
                            self.options.localSetting.attributes.FileMimeType = fileMimeType;
                            self.options.localSetting.attributes.FileUpdate = true;

                        } else if (evt.type == 'progress') {
                            var pr = evt.loaded / evt.total * 100;
                        } else {
                            // Error
                        }
                    })
                }
                else {
                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100229;
                    dialogBoxModel.attributes.Text = resources.X100531;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
                    var callback = function (options) {
                        self.stopListening(dialogBox, "dialog:okclicked", callback);
                    }
                    this.listenTo(dialogBox, "dialog:okclicked", callback);
                    this.app.dialogRegion.show(dialogBox);
                }
            }
        } else {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100935;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
    },
    btnSubmitClick: function () {
        this.hideErrors();

        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.options.localSetting.attributes.FileName;//this.ui.customFileInsert_inputName.val();
        tmpModel.attributes.FileData = this.options.localSetting.attributes.FileData;
        tmpModel.attributes.FileMimeType = this.options.localSetting.attributes.FileMimeType;
        tmpModel.attributes.FileUpdate = this.options.localSetting.attributes.FileUpdate;
        tmpModel.attributes.Filename = tmpModel.attributes.Name;
        tmpModel.attributes.TemplateType = 0;
        tmpModel.attributes.ModuleRegion = "";
        tmpModel.attributes.ModuleDiv = "";
        

        
        var pos =  tmpModel.attributes.Name.lastIndexOf('.'); 

        if (pos < tmpModel.attributes.Name.length && pos > 0) {
            var filename = tmpModel.attributes.Name.substr(0, pos) + '_' + + new Date().getTime()  +  '.' + tmpModel.attributes.Name.substr(pos+1, tmpModel.attributes.Name.length);
            tmpModel.attributes.Filename = filename;
        } else if (pos <= 0) {
            var filename = tmpModel.attributes.Name + '_' + new Date().getTime();
            tmpModel.attributes.Filename = filename;
        }

        //tmpModel.attributes.Filename = tmpModel.attributes.Name + '_' + new Date().getTime();
            
        var ret = tmpModel.validateNoBackbone(this.app);
        if (ret == false) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100322;
            dialogWaitingModel.attributes.Text = resources.X100301;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            app.dialogRegion.show(dialogWaiting);

            var self = this;
            this.collection.fullCollection.create(tmpModel, {
                wait: true,
                success: function (response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    self.ui.customFileInsert_inputName.val('');
                    self.file = undefined;

                    self.options.localSetting.attributes.FileData = null;
                    self.options.localSetting.attributes.FileMimeType = '';
                    self.options.localSetting.attributes.FileUpdate = false;
                }
            });
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    }
});