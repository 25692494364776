﻿import * as crypto from 'crypto-js';
const oidc = require('../plugin/oidc-client-ts');

var Config = {
    webApiUrl: function () {
        var location = '';
        switch (window.location.host) {
            case 'localhost:7099': location = window.location.protocol + '//' + 'localhost:7099'; break;
            case 'luxaforboard.azurewebsites.net': location = window.location.protocol + '//' + 'luxaforboard.azurewebsites.net'; break;
            case 'luxaforboard.com': location = window.location.protocol + '//' + 'luxaforboard.com'; break;
            case 'www.luxaforboard.com': location = window.location.protocol + '//' + 'www.luxaforboard.com'; break;
        }

        return location;
    },
    cultureName: function () {
        return window.luxaforboard.config.cultureName;
    },
    authUserId: function () {
        return window.luxaforboard.config.authUserId;
    },
    online: function () {
        //return window.luxaforboard.config.online;

        switch (window.location.host) {
            case 'localhost:7099': return false; break;
            case 'luxaforboard.azurewebsites.net': return false; break;
            case 'luxaforboard.com': return false; break;
            case 'www.luxaforboard.com': return false; break;
        }

        return false;
    },
    signalRUrl: function () {
        var location = '';
        switch (window.location.host) {
            case 'localhost:7099': location = window.location.protocol + '//' + 'localhost:7099'; break;
            case 'luxaforboard.azurewebsites.net': location = window.location.protocol + '//' + 'luxaforboardsignalr.azurewebsites.net'; break;
            case 'luxaforboard.com': location = window.location.protocol + '//' + 'signalr.luxaforboard.com'; break;
            case 'www.luxaforboard.com': location = window.location.protocol + '//' + 'signalr.luxaforboard.com'; break;
        }

        return location;
    },
    changePasswordUrl: function () {
        var location = '';
        switch (window.location.host) {
            case 'localhost:7099': location = window.location.protocol + '//' + 'localhost:44313'; break;
            case 'luxaforboard.azurewebsites.net': location = window.location.protocol + '//' + 'luxaforboardauth.azurewebsites.net'; break;
            case 'luxaforboard.com': location = window.location.protocol + '//' + 'auth.luxaforboard.com'; break;
            case 'www.luxaforboard.com': location = window.location.protocol + '//' + 'auth.luxaforboard.com'; break;
        }

        return location;
    },
    cdn: function () {
        var location = '';
        //switch (window.location.host) {
        //    case 'localhost:7099': location = /*window.location.protocol*/ + 'https://' + 'luxaforboard.blob.core.windows.net'; break;
        //    case 'luxaforboard.azurewebsites.net': location = window.location.protocol + '//' + 'luxaforboard.blob.core.windows.net'; break;
        //    case 'luxaforboard.com': location = window.location.protocol + '//' + 'luxaforboard.blob.core.windows.net'; break;
        //    case 'www.luxaforboard.com': location = window.location.protocol + '//' + 'luxaforboard.blob.core.windows.net'; break;

        //    //case 'luxaforboard.azurewebsites.net': location = window.location.protocol + '//' + 'cdn.events4us.net'; break;
        //    //case 'luxaforboard.com': location = window.location.protocol + '//' + 'cdn.events4us.net'; break;
        //    //case 'www.luxaforboard.com': location = window.location.protocol + '//' + 'cdn.events4us.net'; break;
        //}

        location = "https://luxaforboard.blob.core.windows.net";
        return location;
    },
    languageJson: function () {
        return window.luxaforboard.config.languageJson;
    },
    languageSettingJson: function () {
        return window.luxaforboard.config.languageSettingJson;
    },
    languageValidationJson: function () {
        return window.luxaforboard.config.languageValidationJson;
    },
    fontJson: function () {
        return window.luxaforboard.config.fontJson;
    },
    moduleJson: function () {
        return window.luxaforboard.config.moduleJson;
    },
    defaultTemplateJson: function () {
        return window.luxaforboard.config.defaultTemplateJson;
    },
    editorJson: function () {
        return window.luxaforboard.config.editorJson;
    },
    templateJson: function () {
        return window.luxaforboard.config.templateJson;
    },
    portalHelpJson: function () {
        return window.luxaforboard.config.portal_helpJson;
    },
    fileJson: function () {
        return window.luxaforboard.config.fileJson;
    },
    UserCsv: function () {
        return window.luxaforboard.config.userCsv;
    },
    DepartmentCsv: function () {
        return window.luxaforboard.config.departmentCsv;
    },
    googleMapsLoaderKEY() {
        //return 'AIzaSyB_OBFUvh8nL1aWS8iNQII5Mrl_kcLWnXU'; //steenevents4us@gmail.com
        //return 'AIzaSyBSrmFddGdjeQUvL3m0gLEn36gYD3s3Xe0'; //mash.development.net@gmail.com
        return 'AIzaSyAPiE5pIQTW3PdV734sF3geJSmcIpde2wY'; //luxaforboard@gmail.com

    },
    googleMapsLoaderLIBRARIES() {
        return ['geometry', 'places'];
    },
    googleRecaptchaSiteKey() {
        //return '6LdvhSkTAAAAAKdtDyjpCgmlfGvSCurYDg1WdpO9'; //steenevents4us@gmail.com
        //return '6LfvIgsUAAAAAIFAwrIsdK0w_y4Y126KqB8b7c6w'; //mash.development.net@gmail.com
        return '6LdTzyEiAAAAAIXqxf6oFd1B_BjqYhGESxAftcfM'; //luxaforboard@gmail.com
    },
    googleAdSenseAdslot() {
        //return '1462621237'; //steenevents4us@gmail.com
        //return '1462621237'; //mash.development.net@gmail.com
        return '0000000000';
    },
    facebookAppId() {
        switch (window.location.host) {
            case 'localhost:7099': return '226121817833273'; break; //sh.shoopster@yahoo.dk
            case 'events4usproduction.azurewebsites.net': return '262115594208005'; break;
            case 'events4us.net': return '1826662877573904'; break;
            case 'www.events4us.net': return '1826662877573904'; break;
            case 'events4us.dk': return '1826662877573904'; break;
            case 'www.events4us.dk': return '1826662877573904'; break;
            case 'events4usproduction-test.azurewebsites.net': return '998134476928041'; break;
            case 'test.events4us.net': return '1368508166561090'; break;
        }
    },
    base64URLEncode: function (str) {
        return str.toString('base64')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    },
    stringify: function (wordArray) {
        // Shortcuts
        var words = wordArray.words;
        var sigBytes = wordArray.sigBytes;

        // Convert
        var u8 = new Uint8Array(sigBytes);
        for (var i = 0; i < sigBytes; i++) {
            var byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
            u8[i] = byte;
        }

        return u8;
    },
    parse: function (u8arr) {
        // Shortcut
        var len = u8arr.length;

        // Convert
        var words = [];
        for (var i = 0; i < len; i++) {
            words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8);
        }

        return CryptoJS.lib.WordArray.create(words, len);
    },
    sha256: function (buffer) {
        const cryptoJS = require('crypto-js');
        return crypto.createHash('sha256').update(buffer).digest();
    },
    getAuthConfig: function () {
        var authority = '';
        switch (window.location.host) {
            case 'localhost:7099': authority = window.location.protocol + '//' + 'localhost:44313'; break;
            case 'luxaforboard.azurewebsites.net': authority = window.location.protocol + '//' + 'luxaforboardauth.azurewebsites.net'; break;
            case 'luxaforboard.com': authority = window.location.protocol + '//' + 'auth.luxaforboard.com'; break;
            case 'www.luxaforboard.com': authority = window.location.protocol + '//' + 'auth.luxaforboard.com'; break;
        }

        var redirect_uri = '';
        switch (window.location.host) {
            case 'localhost:7099': redirect_uri = window.location.protocol + '//' + 'localhost:7099/signin-callback.html'; break;
            case 'luxaforboard.azurewebsites.net': redirect_uri = window.location.protocol + '//' + 'luxaforboard.azurewebsites.net/signin-callback.html'; break;
            case 'luxaforboard.com': redirect_uri = window.location.protocol + '//' + 'luxaforboard.com/signin-callback.html'; break;
            case 'www.luxaforboard.com': redirect_uri = window.location.protocol + '//' + 'luxaforboard.com/signin-callback.html'; break;
        }

        var post_logout_redirect_uri = '';
        switch (window.location.host) {
            case 'localhost:7099': post_logout_redirect_uri = window.location.protocol + '//' + 'localhost:7099/index.html'; break;
            case 'luxaforboard.azurewebsites.net': post_logout_redirect_uri = window.location.protocol + '//' + 'luxaforboard.azurewebsites.net/index.html#index'; break;
            case 'luxaforboard.com': post_logout_redirect_uri = window.location.protocol + '//' + 'luxaforboard.com/index.html#index'; break;
            case 'www.luxaforboard.com': post_logout_redirect_uri = window.location.protocol + '//' + 'luxaforboard.com/index.html#index'; break;
        }

        const authConfig = {
            authority: authority,
            scope: 'openid api1',
            client_id: 'spa',
            redirect_uri: redirect_uri,
            //post_logout_redirect_uri: post_logout_redirect_uri,
            response_type: 'code',
            userStore: new oidc.WebStorageStateStore({ store: window.localStorage }),
            extraQueryParams: {
                ui_locales: 'da-DK',//config.cultureName()
                culture: 'da-DK'

            }
        };

        return authConfig;
    }
};

export default Config;

