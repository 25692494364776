﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customMenu from './tpl/customMenu.html';
import Help from '../../../helper/help';
import AuthHelper from '../../../helper/auth';

export default Marionette.LayoutView.extend({
    initialize: function () {
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(this.model, 'change', this.render, this);

        var self = this;
        //userChannel.on('customMenu:enable', function() {
        //    self.options.enable = true;
        //    self.initMenu(true);
        //});
        this.listenTo(userChannel, 'customMenu:enable', function () {
            self.options.enable = true;
            self.initMenu(true);
        });

        //userChannel.on('customMenu:disable', function() {
        //    self.options.enable = false;
        //    self.initMenu(false);
        //});
        this.listenTo(userChannel, 'customMenu:disable', function () {
            self.options.enable = false;
            self.initMenu(false);
        });

        userChannel.on('CustomMenu:setLoginName', function () {
            if (self.app.mgr.profile !== undefined) {
                //var events4usUser_FirstName = self.app.entities.models.custom.attributes.FirstName;
                //var events4usUser_AttPerson = self.app.entities.models.custom.attributes.AttPerson;
                //var events4usUser_Organisation = self.app.entities.models.custom.attributes.Organisation;

                /*
                var events4usUser_FirstName = localStorage.getItem('events4usUser_FirstName');
                var events4usUser_AttPerson = localStorage.getItem('events4usUser_AttPerson');
                var events4usUser_Organisation = localStorage.getItem('events4usUser_Organisation');
                */

                /*                var events4usUser_FirstName = localStorage.getItem('events4usUser_FirstName');
                                var events4usUser_AttPerson = localStorage.getItem('events4usUser_AttPerson');
                                var events4usUser_Organisation = localStorage.getItem('events4usUser_Organisation');
                                */
                //var displayName = events4usUser_FirstName;

                //switch (events4usUser_Organisation) {
                //    case 0: displayName = events4usUser_FirstName; break;
                //    case 1: displayName = events4usUser_AttPerson; break;
                //    case 2: displayName = events4usUser_AttPerson; break;
                //}

                //var displayOrg = '';
                //switch (events4usUser_Organisation) {
                //    case 0: displayOrg = resources['X100574']; break;
                //    case 1: displayOrg = resources['X100575']; break;
                //    case 2: displayOrg = resources['X100576']; break;
                //}

                var displayName = self.model.attributes.Name;
                var displayOrg = resources['X200030']; 

                if (displayName.length <= 0) {
                    self.ui.custom_menu_loginName.html('');
                    self.ui.custom_menu_loginOrg.html('');
                } else {
                    var userTag = displayName;
                    self.$el.find('#custom_menu_loginName').html(displayName);
                    self.$el.find('#custom_menu_loginOrg').html(displayOrg);
                    //self.ui.custom_menu_loginName.html(displayName);
                    //self.ui.custom_menu_loginOrg.html(displayOrg);
                }
            } else {
                //self.ui.custom_menu_loginName.css('display', 'none');
                //self.ui.custom_menu_loginOrg.css('display', 'none');
            }
        });
    },
    help: function () {
        Help.help(this, true, 'gettingStarted');
    },
    getTemplate: function () {
        return tpl_customMenu();
    },
    ui: {
        'custom_menu_btnAuth': '#custom_menu_btnAuth',
        'custom_menu_btnAuthLogoff': '#custom_menu_btnAuthLogoff',
        'custom_menu_CustomSetting': '#custom_menu_CustomSetting',
        'custom_menu_loginName': '#custom_menu_loginName',
        'custom_menu_loginOrg': '#custom_menu_loginOrg',
        'custom_menu_profileImage': '#custom_menu_profileImage',
        'helpMenuEvent_i': '#helpMenuEvent_i',
        'helpMenuDesign_i': '#helpMenuDesign_i',
        'helpMenuInformation_i': '#helpMenuInformation_i',
        'menu1': '#custom_menu1_a',
        'menu2': '#custom_menu2_a',
        'menu3': '#custom_menu3_a',
        'menu4': '#custom_menu4_a',
        'menu5': '#custom_menu5_a',
        'menu6': '#custom_menu6_a',
        'menu7': '#custom_menu7_a',
        'menu8': '#custom_menu8_a',
        'customMenuSideMenu01': '#customMenuSideMenu01',
        'customMenuSideMenu02': '#customMenuSideMenu02',
        'customMenuSideMenu03': '#customMenuSideMenu03',
        'customMenuSideMenu04': '#customMenuSideMenu04',
        'customMenuSideMenu05': '#customMenuSideMenu05'
    },
    events: {
        'click @ui.custom_menu_btnAuth': 'custom_menu_btnAuthClick',
        'click @ui.custom_menu_btnAuthLogoff': 'custom_menu_btnAuthLogoffClick',
        'click @ui.custom_menu_CustomSetting': 'custom_menu_CustomSettingClick',
        'click #custom_menu11_a': 'help',
        'click #customMenuSideMenu01': 'helpMenuUser',
        'click #customMenuSideMenu02': 'helpMenuDepartment',
        'click #customMenuSideMenu03': 'helpMenuScreen',
        'click #customMenuSideMenu04': 'helpMenuSecurity',
        'click #customMenuSideMenu05': 'helpMenuCustom',
    },
    onRender: function () {
        this.initMenu(this.options.enable);

        //if (this.app.mgr.profile !== undefined) {
        //    this.ui.custom_menu_btnAuth.css('display', 'none');
        //    this.ui.custom_menu_btnAuthLogoff.show();
        //    this.ui.custom_menu_loginName.show();
        //    this.ui.custom_menu_loginOrg.show();
        //} else {
        //    this.ui.custom_menu_btnAuth.show();
        //    this.ui.custom_menu_btnAuthLogoff.css('display', 'none');
        //    this.ui.custom_menu_loginName.css('display', 'none');
        //    this.ui.custom_menu_loginOrg.css('display', 'none');
        //}

        this.renderLoginName();

       

    },
    onAttach() {
        
    },
    renderLoginName: function () {
        var self = this;
        //var displayName = "";
        //if (self.app.mgr.profile !== undefined) {
        //    displayName = self.model.attributes.Name;
        //    var displayOrg = resources['X200030'];

        //    if (displayName.length <= 0) {
        //        self.ui.custom_menu_loginName.html('');
        //        self.ui.custom_menu_loginOrg.html('');
        //    } else {
        //        var userTag = displayName;
        //        self.$el.find('#custom_menu_loginName').html(displayName);
        //        self.$el.find('#custom_menu_loginOrg').html(displayOrg);
        //    }
        //} else {
        //    self.ui.custom_menu_loginName.css('display', 'none');
        //    self.ui.custom_menu_loginOrg.css('display', 'none');
        //}

        //if (displayName.length > 0) {

            this.$el.find('#custom_menu_loginName').html(this.model.attributes.Name);
            this.$el.find('#custom_menu_loginOrg').html("");

            var nameArray = this.model.attributes.Name.split(' ', 4);
            var intials = '';

            if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
            if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
            if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
            if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

            this.ui.custom_menu_profileImage.text(intials);
        //}
    },
    custom_menu_btnAuthClick: function (event) {
        if (event) {
            event.preventDefault();
        }

        window.mgr.redirectForToken().then(function () {
            //alert('Success: ');
        }, function (err) {
            //alert('Error: ');
        });
    },
    custom_menu_btnAuthLogoffClick: function (event) {
        event.preventDefault();

        AuthHelper.logout(this.app);

        //this.app.userManager.signoutRedirect()
        //    .then(res => {
        //        console.log('Redirection to signout triggered.', res);
        //})

//        (async () => {
  //          this.app.userManager.signoutRedirect(window.location.origin + "#logout"); 
    //    })();
        //const signoutUrl = this.app.userManager.signoutRedirect(window.location.href + "#frf");

        //window.location.href = signOutUrl.url;
    },
    custom_menu_CustomSettingClick: function (event) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('Region_Header:customerSetting');
    },
    initMenu: function (enable) {
        var color = enable ? '#001737' : '#a0a0a0';
        var pointer_events_value = enable ? '' : 'none';
        var cursor_value = enable ? 'pointer' : 'not-allowed';
    },
    helpMenuUser: function () {
        Help.help(this, true, 'menuUser');
    },
    helpMenuDepartment: function () {
        Help.help(this, true, 'menuDepartment');
    },
    helpMenuScreen: function () {
        Help.help(this, true, 'menuScreen');
    },
    helpMenuSecurity: function () {
        Help.help(this, true, 'menuSecurity');
    },
    helpMenuCustom: function () {
        Help.help(this, true, 'menuCustom');
    }
}, Backbone.Radio.Requests);
