var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customImageInsert_empty"> <form class="contact-form"> <div class="row" style="margin-left:0px;margin-top:25px"> <div class="col-md-6" style="padding-left:0px"> <div class="control-group Image" style="display:none"> <div class=""> <label type="button" for="customImageInsert_btnImage" class="btn btn-primary">'+
((__t=( resources['X100295'] ))==null?'':__t)+
'</label> <div id="customImageInsert_div_clone"> <input id="customImageInsert_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> <br> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-md-12"> <input id="customImageInsert_filePond" type="file" class="filepond"> </div> </div> <div class="control-group customImageInsert_inputName"> <div class="form-group"> <label style="display:none" for="customImageInsert_input">'+
((__t=( resources['X100296'] ))==null?'':__t)+
'</label> <input style="display:none" class="form-control" id="customImageInsert_inputName"> <span class="help-inline" style="color:red"></span> </div> </div>   <div class="row"> <div class="row" style="margin-left:0px;margin-right:0px"> <div class="control-label col-md-6" style="padding-left:0px">  <div class="cropper-col cropper-img-container"> <img id="customImageInsert_image" alt="" src="/images/cropper.png"> </div> <div class="cropper-col cropper-img-preview"></div> </div>  </div> </div> <div class="row" style="margin-top:50px"> <div class=""> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customImageInsert_btn_crop_zoom_plus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101208'] ))==null?'':__t)+
'"><i class="fa fa-search-plus"></i></button> <button type="button" id="customImageInsert_btn_crop_zoom_minus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101209'] ))==null?'':__t)+
'"><i class="fa fa-search-minus"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customImageInsert_btn_crop_move_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101210'] ))==null?'':__t)+
'"><i class="fa fa-arrow-left"></i></button> <button type="button" id="customImageInsert_btn_crop_move_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101211'] ))==null?'':__t)+
'"><i class="fa fa-arrow-right"></i></button> <button type="button" id="customImageInsert_btn_crop_move_top" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101212'] ))==null?'':__t)+
'"><i class="fa fa-arrow-up"></i></button> <button type="button" id="customImageInsert_btn_crop_move_buttom" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101213'] ))==null?'':__t)+
'"><i class="fa fa-arrow-down"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customImageInsert_btn_crop_rotate_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101214'] ))==null?'':__t)+
'"><i class="fa fa-undo-alt"></i></button> <button type="button" id="customImageInsert_btn_crop_rotate_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101215'] ))==null?'':__t)+
'"><i class="fa fa-redo-alt"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customImageInsert_btn_crop_scale_x" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101216'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-h"></i></button> <button type="button" id="customImageInsert_btn_crop_scale_y" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101217'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-v"></i></button> </div> </div> </div> <br> <div class="row"> <div class=""> <div class="" role="group" aria-label="Basic example"> <button id="customImageInsert_btn_crop_aspectRatio_5_5" style="width: 60px;height: 60px" type="button" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101218'] ))==null?'':__t)+
'"> <span> '+
((__t=( resources['X101202'] ))==null?'':__t)+
' </span> </button> <button id="customImageInsert_btn_crop_aspectRatio_4_3" style="width: 60px;height: 45px" type="button" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101219'] ))==null?'':__t)+
'"> <span> '+
((__t=( resources['X101203'] ))==null?'':__t)+
' </span> </button> <button id="customImageInsert_btn_crop_aspectRatio_3_4" style="width: 45px;height: 60px" type="button" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101220'] ))==null?'':__t)+
'"> <span> '+
((__t=( resources['X101204'] ))==null?'':__t)+
' </span> </button> <button id="customImageInsert_btn_crop_aspectRatio_6_4" style="width: 60px;height: 40px" type="button" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101221'] ))==null?'':__t)+
'"> <span> '+
((__t=( resources['X101205'] ))==null?'':__t)+
' </span> </button> <button id="customImageInsert_btn_crop_aspectRatio_4_6" style="width: 40px;height: 60px" type="button" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101222'] ))==null?'':__t)+
'"> <span> '+
((__t=( resources['X101206'] ))==null?'':__t)+
' </span> </button> <button id="customImageInsert_btn_crop_aspectRatio_Free" style="width: 60px;height: 45px" type="button" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101223'] ))==null?'':__t)+
'"> <span> '+
((__t=( resources['X101207'] ))==null?'':__t)+
' </span> </button> <button type="button" class="btn btn-primary" id="customImageInsert_btnSubmit" style=""> '+
((__t=( resources['X100283'] ))==null?'':__t)+
' </button> </div> </div> </div>     </form> </div>';
}
return __p;
};
