﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';


import * as Entities_Empty from '../../../../lib/entities/empty';

import View_PageSettingList from './pageSettingList';

import tpl_newPage from './tpl/newPage.html';

export default Marionette.LayoutView.extend({
    dialogRegion: '#newPage_modal',
    errors: [],
    getTemplate: function () {
        return tpl_newPage({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    regions: {
        pageSettingList: "#pageSettingList"
    },
    onBeforeShow: function () {
        this.pageSettingList.show(new View_PageSettingList({ app: this.app, collection: this.collection }));
        //this.htmlListPagination.show(new View_Pagination({ collection: this.collection }));
    },
    ui: {
        'newPage_btn01': '#newPage_btn01',
        'newPage_btn01': '#newPage_btn01',
        'newPage_inputName' : '#newPage_inputName',
        'newPage_inputStatus': '#newPage_inputStatus',
        'newPage_group_Duration': '#newPage_group_Duration',
        'newPage_input_Duration': '#newPage_input_Duration',
        'newPage_label_Duration': '#newPage_label_Duration',

    },
    events: {
        "click #newPage_btn01": "onBtn01",
        "click #newPage_btn02": "onBtn02",
        "keyup #newPage_inputName": "onInputNameChange",
        'change @ui.newPage_inputName': 'inputStatusChange'
    },
    inputStatusChange(event) {
        var status = this.model.attributes.Status;
        this.ui.newPage_inputStatus.is(':checked') ? status = 1 : status = 0;

        this.ui.newPage_btn01.prop('disabled', false);
        this.hideErrors(this.errors);

        this.model.set({
            Status: status
        });
    },
    onAttach() {
        var self = this;

        this.ui.newPage_input_Duration.durationPicker({
            showSeconds: true,
            onChanged: function (newVal) {
                self.ui.newPage_label_Duration.text(newVal);
                self.model.set({
                    Duration: newVal
                });
            }
        });

        $('#newPage_group_Duration').find('.input-sm').width(90);
    },
    onRender: function () {
        var checked = this.model.attributes.Status == 1 ? true : false;
        this.ui.newPage_inputStatus.attr('checked', checked);
    },
    onBtn01: function () {
        var self = this;

        var callback = function() {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    onInputNameChange: function () {
        var self = this;

        var callback = function () {
        }

        this.validate(callback);
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
        this.ui.newPage_btn01.prop('disabled', false);

    },
    validate : function(callback) {
        var self = this;
        this.hideErrors();

        var name = this.ui.newPage_inputName.val();
        this.model.attributes.Name = name;
        var foundModels = this.collection.where({ Name: name });

        foundModels.forEach(function (entry) {
            if (entry.attributes.id != self.model.attributes.id) {
                if (entry.attributes.Name != self.model.attributes.Name) {
                    self.errors.push({ name: 'newPage_inputName', message: resources.X100331 });
                }
            }
        });

        var countEnable = 0;
        var customInfopageItem = this.collection.where({ Webpage: this.model.attributes.Webpage, Tag : this.model.attributes.Tag, Status : 1 });
        customInfopageItem.forEach(function (model) {
            if (model.attributes.id != self.model.attributes.id) {
                countEnable++;
            }
        });
        if (this.model.attributes.Status == 1) {
            countEnable++;
        }

        if (countEnable == 0) {
            this.errors.push({ name: 'newPage_inputStatus', message: resources.X100969 });
        }

        if (this.errors.length > 0) {
            this.showErrors(this.errors);
            this.ui.newPage_btn01.prop('disabled', true);
        } else { 
            callback();
        }
    }
});