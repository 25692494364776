'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';
import FilePondPluginImageSizeMetaData from '../../plugins/filepond-plugin-image-size-metadata';


import tpl_customUserInsert from './tpl/customUserInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customUserInsert({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.ImageSelected = false;
        this.localSetting.attributes.AspectText = resources.X101203;


        this.model = new this.app.entities.collections.customUser.model();
        this.model.attributes.Color = this.app.entities.models.customColor.attributes.SolidColor_Color_Off;

        this.model.attributes.tmp = {};

        this.model.attributes.tmp.Custom_Color_Default = this.app.entities.models.customColor.attributes.Custom_Color_Default;
        this.model.attributes.tmp.Custom_Text_Default = resources.X200311;// + ' (' + this.app.entities.models.customColor.attributes.Custom_Text_Default + ')';

        this.model.attributes.tmp.SolidColor_Color_Off = this.app.entities.models.customColor.attributes.SolidColor_Color_Off;
        this.model.attributes.tmp.SolidColor_Text_Off = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Off + ')';
        this.model.attributes.tmp.SelectedOff = '';

        this.model.attributes.tmp.SolidColor_Color_Busy = this.app.entities.models.customColor.attributes.SolidColor_Color_Busy;
        this.model.attributes.tmp.SolidColor_Text_Busy = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Busy + ')';
        this.model.attributes.tmp.SelectedBusy = '';

        this.model.attributes.tmp.SolidColor_Color_Available = this.app.entities.models.customColor.attributes.SolidColor_Color_Available;
        this.model.attributes.tmp.SolidColor_Text_Available = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Available + ')';
        this.model.attributes.tmp.SelectedAvailable = '';

        this.model.attributes.tmp.SolidColor_Color_Blue = this.app.entities.models.customColor.attributes.SolidColor_Color_Blue;
        this.model.attributes.tmp.SolidColor_Text_Blue = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Blue + ')';
        this.model.attributes.tmp.SelectedBlue = '';

        this.model.attributes.tmp.SolidColor_Color_Yellow = this.app.entities.models.customColor.attributes.SolidColor_Color_Yellow;
        this.model.attributes.tmp.SolidColor_Text_Yellow = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow + ')';
        this.model.attributes.tmp.SelectedYellow = '';

        this.model.attributes.tmp.SolidColor_Color_Cyan = this.app.entities.models.customColor.attributes.SolidColor_Color_Cyan;
        this.model.attributes.tmp.SolidColor_Text_Cyan = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan + ')';
        this.model.attributes.tmp.SelectedCyan = '';

        this.model.attributes.tmp.SolidColor_Color_Magenta = this.app.entities.models.customColor.attributes.SolidColor_Color_Magenta;
        this.model.attributes.tmp.SolidColor_Text_Magenta = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta + ')';
        this.model.attributes.tmp.SelectedMagenta = '';

        this.model.attributes.tmp.SolidColor_Color_CustomColor = this.app.entities.models.customColor.attributes.SolidColor_Color_CustomColor;
        this.model.attributes.tmp.SolidColor_Text_CustomColor = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor + ')';
        this.model.attributes.tmp.SelectedCustomColor = '';

        this.model.attributes.tmp.MSTeams_Color_Online = this.app.entities.models.customColor.attributes.MSTeams_Color_Online;
        this.model.attributes.tmp.MSTeams_Text_Online = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Online + ')';

        this.model.attributes.tmp.MSTeams_Color_Busy = this.app.entities.models.customColor.attributes.MSTeams_Color_Busy;
        this.model.attributes.tmp.MSTeams_Text_Busy = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Busy + ')';

        this.model.attributes.tmp.MSTeams_Color_DoNotDisturb = this.app.entities.models.customColor.attributes.MSTeams_Color_DoNotDisturb;
        this.model.attributes.tmp.MSTeams_Text_DoNotDisturb = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb + ')';

        this.model.attributes.tmp.MSTeams_Color_BeRightBack = this.app.entities.models.customColor.attributes.MSTeams_Color_BeRightBack;
        this.model.attributes.tmp.MSTeams_Text_BeRightBack = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsNotPresent = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsNotPresent;
        this.model.attributes.tmp.MSTeams_Text_ShowAsNotPresent = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsOffline = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsOffline;
        this.model.attributes.tmp.MSTeams_Text_ShowAsOffline = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline + ')';

        this.model.attributes.tmp.Mutebutton_Color_On = this.app.entities.models.customColor.attributes.Mutebutton_Color_On;
        this.model.attributes.tmp.Mutebutton_Text_On = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_On + ')';

        this.model.attributes.tmp.Mutebutton_Color_Off = this.app.entities.models.customColor.attributes.Mutebutton_Color_Off;
        this.model.attributes.tmp.Mutebutton_Text_Off = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_Off + ')';

        this.model.attributes.tmp.Pomodoro_Color_Time = this.app.entities.models.customColor.attributes.Pomodoro_Color_Time;
        this.model.attributes.tmp.Pomodoro_Text_Time = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_Time + ')';

        this.model.attributes.tmp.Pomodoro_Color_ShortBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_ShortBreak;
        this.model.attributes.tmp.Pomodoro_Text_ShortBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak + ')';

        this.model.attributes.tmp.Pomodoro_Color_LongBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_LongBreak;
        this.model.attributes.tmp.Pomodoro_Text_LongBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak + ')';
    },
    ui: {
        'customUserInsert_btnSubmit': '#customUserInsert_btnSubmit',
        'customUserInsert_inputName': '#customUserInsert_inputName',
        'customUserInsert_inputLuxaforId': '#customUserInsert_inputLuxaforId',
        'customUserInsert_inputCallerId': '#customUserInsert_inputCallerId',
        'customUserInsert_inputEmail': '#customUserInsert_inputEmail',
        'customUserInsert_selectStatus': '#customUserInsert_selectStatus',
        'customUserInsert_checkboxEnableUserText': '#customUserInsert_checkboxEnableUserText',
        'customUserInsert_inputUserText': '#customUserInsert_inputUserText',
        'customUserInsert_filePond': '#customUserInsert_filePond',
        'customUserInsert_checkboxEnablePomodoro': '#customUserInsert_checkboxEnablePomodoro',
        'customUserInsert_checkboxEnable': '#customUserInsert_checkboxEnable',
        'customUserInsert_image': '#customUserInsert_image',
        'customUserInsert_btn_crop_zoom_plus': '#customUserInsert_btn_crop_zoom_plus',
        'customUserInsert_btn_crop_zoom_minus': '#customUserInsert_btn_crop_zoom_minus',
        'customUserInsert_btn_crop_move_left': '#customUserInsert_btn_crop_move_left',
        'customUserInsert_btn_crop_move_right': '#customUserInsert_btn_crop_move_right',
        'customUserInsert_btn_crop_move_top': '#customUserInsert_btn_crop_move_top',
        'customUserInsert_btn_crop_move_buttom': '#customUserInsert_btn_crop_move_buttom',
        'customUserInsert_btn_crop_rotate_left': '#customUserInsert_btn_crop_rotate_left',
        'customUserInsert_btn_crop_rotate_right': '#customUserInsert_btn_crop_rotate_right',
        'customUserInsert_btn_crop_scale_x': '#customUserInsert_btn_crop_scale_x',
        'customUserInsert_btn_crop_scale_y': '#customUserInsert_btn_crop_scale_y'
    },
    events: {
        'click @ui.customUserInsert_btnSubmit': 'btnSubmitClick',
        'click @ui.customUserInsert_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.customUserInsert_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.customUserInsert_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.customUserInsert_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.customUserInsert_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.customUserInsert_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.customUserInsert_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.customUserInsert_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.customUserInsert_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.customUserInsert_btn_crop_scale_y': 'btnScaleYClick'

    },
    childEvents: {
    },
    onRender: function () {

        

    },
    onAttach() {
        

        var self = this;
        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageSizeMetaData
        );

        var labelIdle = resources.X200141 + ' <span class="filepond--label-action">' + resources.X200142 + '</span>';

        var pond = FilePond.create(this.ui.customUserInsert_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            imageResizeTargetWidth: 350,
            imageResizeTargetHeight: null,
            imageResizeMode: 'contain',
            imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            /*
             *  allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,
             */

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();

                    self.localSetting.attributes.ImageSelected = true;

                    if (self.cropper) {
                        self.cropper.destroy();
                    }
                    self.ui.customUserInsert_image.attr('src', base64);
                    //self.ui.customUserInsert_roundImage.attr('src', base64);

                    //var mimeType = self.base64MimeType(base64);
                    //var fileData = base64.split(',')[1];

                    self.localSetting.attributes.FileName = fileItem.filenameWithoutExtension;

                    //var previews = document.querySelectorAll('.cropper-img-preview');
                    //var imageDefault = document.querySelector('#customUserInsert_image');

                    //self.cropper = new Cropper(imageDefault, {
                    //    aspectRatio: NaN,
                    //    autoCropArea: 1,
                    //    zoomOnTouch: false,
                    //    zoomOnWheel: false,
                    //    cropBoxResizable: true,
                    //    build: function () {
                    //        var clone = this.cloneNode();

                    //        clone.style.cssText = (
                    //            'display: block;' +
                    //            'width: 100%;' +
                    //            'minWidth: 0;' +
                    //            'minHeight: 0;' +
                    //            'maxWidth: none;' +
                    //            'maxHeight: none;'
                    //        );
                    //    },

                    //    crop: function (e) {
                    //        var data = e.detail;
                    //        var cropper = this.cropper;
                    //        var imageData = cropper.getImageData();
                    //        var previewAspectRatio = data.width / data.height;
                    //    }
                    //});

                    //self.setupBtn();

                    this.setupCropper();
                    pond.removeFile(fileItem);
                };
            }
        });

        this.setupCropper();
        this.setupStatus();

        $('.btn.dropdown-toggle.btn-light').css("width", this.ui.customUserInsert_inputEmail.width());

        this.ui.customUserInsert_checkboxEnableUserText.attr('checked', false);
        this.ui.customUserInsert_checkboxEnable.attr('checked', false);
    },
    setupCropper: function () {
        if (this.localSetting.attributes.ImageSelected == true) {
            var self = this;


            if (this.cropper) {
                this.cropper.destroy();
            }

            var imageDefault = document.querySelector('#customUserInsert_image');

            var cropBoxData;
            var canvasData;



            this.cropper = new Cropper(imageDefault, {
                aspectRatio: NaN,
                autoCropArea: 1,
                zoomOnTouch: false,
                zoomOnWheel: false,
                cropBoxResizable: true,
                build: function () {
                    var clone = this.cloneNode();

                    clone.style.cssText = (
                        'display: block;' +
                        'width: 100%;' +
                        'minWidth: 0;' +
                        'minHeight: 0;' +
                        'maxWidth: none;' +
                        'maxHeight: none;'
                    );
                },

                crop: function (e) {
                    self.localSetting.attributes.ImageSelected = true;
                    //var data = e.detail;
                    //var cropper = this.cropper;
                    //var imageData = cropper.getImageData();
                    //var previewAspectRatio = data.width / data.height;
                }
            });
        }
    },
    setupStatus() {
        var self = this;

        //this.ui.customUserEditItem_selectStatus.empty();

        var selected = '';



        self.ui.customUserInsert_selectStatus.selectpicker({ size: 10 });

        $('select[name=selValue]').val(self.model.attributes.Color);
        self.ui.customUserInsert_selectStatus.selectpicker('refresh')

        selected = '';
    },

    btnSubmitClick: function () {
        var self = this;

        var count = 0;

        self.app.entities.collections.customUser.fullCollection.forEach(function (entry) {
            if (entry.attributes.Enable == true) count++;
        });

        

        


        var tmpModel = new this.app.entities.collections.customUser.model();
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.ui.customUserInsert_inputName.val();
        tmpModel.attributes.LuxaforId = this.ui.customUserInsert_inputLuxaforId.val();
        tmpModel.attributes.CallerId = this.ui.customUserInsert_inputCallerId.val();
        tmpModel.attributes.Email = this.ui.customUserInsert_inputEmail.val();
        tmpModel.attributes.Color = this.ui.customUserInsert_selectStatus.val();
        tmpModel.attributes.DepartmentResetText = "";

        if (count >= self.app.entities.models.customItem.attributes.Count) {
            tmpModel.attributes.Enable = false;
        }
        else {
            tmpModel.attributes.Enable = true;
        }
        



        //tmpModel.attributes.EnableUsertText = false;
        //tmpModel.attributes.UserText = "";

        var enableUserText = false;
        this.ui.customUserInsert_checkboxEnableUserText.is(':checked') ? enableUserText = true : enableUserText = false;
        tmpModel.attributes.EnableUserText = enableUserText;

        tmpModel.attributes.UserText = this.ui.customUserInsert_inputUserText.val();

        var enablePomodoro = false;
        this.ui.customUserInsert_checkboxEnablePomodoro.is(':checked') ? enablePomodoro = true : enablePomodoro = false;
        tmpModel.attributes.EnablePomodoro = enablePomodoro;


        var enable = false;
        this.ui.customUserInsert_checkboxEnable.is(':checked') ? enable = true : enable = false;
        tmpModel.attributes.Enable = enable;


        var data_id = this.ui.customUserInsert_selectStatus.find(':selected').attr('data-id');

        var tmpActiveTab = this.app.entities.models.customColor.attributes.Custom_Text_Default;

        switch (data_id) {
            case "0": tmpActiveTab = this.app.entities.models.customColor.attributes.Custom_Text_Default;
                tmpActiveTab = resources.X200311;
                break;
            case "1": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Off;
                tmpActiveTab = resources.X200270;
                break;
            case "2": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Busy;
                tmpActiveTab = resources.X200270;
                break;
            case "3": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Available;
                tmpActiveTab = resources.X200270;
                break;
            case "4": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Blue;
                tmpActiveTab = resources.X200270;
                break;
            case "5": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow;
                tmpActiveTab = resources.X200270;
                break;
            case "6": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan;
                tmpActiveTab = resources.X200270;
                break;
            case "7": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta;
                tmpActiveTab = resources.X200270;
                break;
            case "8": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor;
                tmpActiveTab = resources.X200270;
                break;
            case "9": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_Online;
                tmpActiveTab = resources.X200303;
                break;
            case "10": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_Busy;
                tmpActiveTab = resources.X200303;
                break;
            case "11": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb;
                tmpActiveTab = resources.X200303;
                break;
            case "12": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack;
                tmpActiveTab = resources.X200303;
                break;
            case "13": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent;
                tmpActiveTab = resources.X200303;
                break;
            case "14": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline;
                tmpActiveTab = resources.X200303;
                break;
            case "15": tmpActiveTab = this.app.entities.models.customColor.attributes.Mutebutton_Text_On;
                tmpActiveTab = resources.X200312;
                break;
            case "16": tmpActiveTab = this.app.entities.models.customColor.attributes.Mutebutton_Text_Off;
                tmpActiveTab = resources.X200312;
                break;
            case "17": tmpActiveTab = this.app.entities.models.customColor.attributes.Pomodoro_Text_Time;
                tmpActiveTab = resources.X200286
                break;
            case "18": tmpActiveTab = this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak;
                tmpActiveTab = resources.X200286
                break;
            case "19": tmpActiveTab = this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak;
                tmpActiveTab = resources.X200286
                break;

        }

        tmpModel.attributes.ActiveTab = tmpActiveTab;

        this.getImageData(tmpModel);

        tmpModel.attributes.ImageUrl = '';
        tmpModel.attributes.EncryptText01 = '';
        tmpModel.attributes.EncryptText02 = '';
        tmpModel.attributes.EncryptText03 = '';
        tmpModel.attributes.EncryptText04 = '';
        tmpModel.attributes.EncryptText05 = '';
        tmpModel.attributes.EncryptText06 = '';
        tmpModel.attributes.EncryptText07 = '';
        tmpModel.attributes.DeleteImage = false;



        if (!tmpModel.validateNoBackbone(this.app, true)) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200115;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

            var callback = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion.show(dialogWaiting);

            self.app.entities.collections.customUser.create(tmpModel, {
                wait: true,
                success: function (response) {
                    self.hideErrors();
                    self.ui.customUserInsert_inputName.val("");
                    self.ui.customUserInsert_inputLuxaforId.val("");
                    self.ui.customUserInsert_inputCallerId.val("");
                    self.ui.customUserInsert_inputEmail.val("");
                    self.ui.customUserInsert_inputUserText.val("");

                    self.ui.customUserInsert_checkboxEnableUserText.attr('checked', false);
                    self.ui.customUserInsert_checkboxEnablePomodoro.attr('checked', false);
                    self.ui.customUserInsert_checkboxEnable.attr('checked', false);

                    if (tmpModel.attributes.ImageUrl != '') {
                        self.cropper.destroy();
                    }
                    self.ui.customUserInsert_image.attr('src', '/images/cropper.png');
                    self.localSetting.attributes.ImageSelected = false;
                    dialogWaiting.triggerMethod('dialog:close');

                    //if (count <= self.app.entities.models.customItem.attributes.Count) {
                    //    var dialogBoxModel = new Entities_Empty.Model();
                    //    dialogBoxModel.attributes.Title = resources.X200115;
                    //    dialogBoxModel.attributes.Text = resources.X200397;
                    //    dialogBoxModel.attributes.Button = resources.X100279;

                    //    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                    //}
                }
            });
        } else {
            this.showErrors(tmpModel.errors);
        }
    },
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    getWeboptimizeImage: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            if (cropBoxData.width != undefined) {
                var aspectRatio = /*cropBoxData.height /*/ cropBoxData.width / cropBoxData.height;
                height = 100;
                width = height * aspectRatio;
                //height = width * aspectRatio;

                var croppedCanvas;
                var canvas;
                var image;

                // Crop
                croppedCanvas = this.cropper.getCroppedCanvas();

                // Round
                canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

                var struct = {
                    width: width,
                    height: height,
                    data: canvas.toDataURL('image/png', 0.5)
                }

                return struct;
            }
            return null;
        }
    },
    getImageData: function (tmpModel) {
        var searchString = ";base64,";
        var pos = 0;

        var image515 = this.getWeboptimizeImage(200, 100);

        if (image515 != null) {
            var imageData512 = image515.data;
            pos = imageData512.indexOf(searchString);
            var imageMimeType512 = imageData512.substring(5, pos);
            imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            tmpModel.attributes.ImageFilename = 'User';//resources.X200122;
            tmpModel.attributes.ImageData = imageData512;
        } else {
            tmpModel.attributes.ImageFilename = '';
            tmpModel.attributes.ImageData = '';
        }
    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    }

});