'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_Module from './module';

import tpl_moduleListListItem from './tpl/moduleListListItem.html';

export default Marionette.ItemView.extend({
    /*className: ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2',*/
    getTemplate: function () {
        return tpl_moduleListListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'moduleListListItem_div': '#moduleListListItem_div'
    },
    events: {
        'click @ui.moduleListListItem_div': 'moduleListListItem_div_Click'
    },
    onRender: function () {
        var self = this;

        if (this.model.attributes.Selected) {
            this.ui.moduleListListItem_div.css('border-width', '5px');
            this.ui.moduleListListItem_div.css('margin-top', '5px');
            this.ui.moduleListListItem_div.css('border-color', 'rgb(128, 128, 128)');
        }
        else {
            this.ui.moduleListListItem_div.css('border-width', '5px');
            this.ui.moduleListListItem_div.css('margin-top', '5px');
            this.ui.moduleListListItem_div.css('border-color', 'rgb(233, 233, 233)');
        }
    },
    moduleListListItem_div_Click: function (event) {
        var self = this;
        this.model.collection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: false });
            }
        });

        this.model.set({ Selected: true });
        this.model.collection.trigger('change');
    }
});