'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customProductSheetImageInsert from './tpl/customProductSheetImageInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customProductSheetImageInsert();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
    },
    ui: {
        'customProductSheetImageInsert_btnImage': '#customProductSheetImageInsert_btnImage'
    },
    events: {
        'change @ui.customProductSheetImageInsert_btnImage': 'btnImageChange'
    },
    onRender: function () {
        var self = this;
    },
    thumb: function (file, width, height, type, o) {
        var self = o;

        var image = FileAPI.Image(file), label = width + 'x' + height, callback;

        if (type) {
            label += ' (' + type + ')';
            image.resize(width, height, type);
        } else if (width) {
            image.preview(width, height);
        } else {
            label = 'original';
        }

        image.get(function (err, img) {
            if (img) {
                callback && callback(img);
            }
        });

        return function (then) { callback = then; };
    },
    realImgDimension: function (img) {
        var i = new Image();
        i.src = img.src;
        return {
            naturalWidth: i.width,
            naturalHeight: i.height
        };
    },
    scaleSize : function (maxW, maxH, currW, currH){
        var ratio = currH / currW;

        if ((currW >= maxW) && (ratio <= 1)){
            currW = maxW;
            currH = currW * ratio;
        } else if(currH >= maxH){
            currH = maxH;
            currW = currH / ratio;
        }

        return [currW, currH];
    },

    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();
        //remove extension
        var dotPosition = filename.lastIndexOf( "." );
        if (dotPosition > 0) {
            filename = filename.substring(0,dotPosition); 
        }

        this.file = FileAPI.getFiles(event)[0];
        FileAPI.getInfo(this.file, function (error, info){
            if( !error ){
                
                var uploadFile = self.$el.find('.file-input-name');
                uploadFile.val("");

                var width = 300;
                var height = 300;

                var newSize = self.scaleSize(width, height, info.width, info.height);

                width = newSize[0];
                height = newSize[1];

                self.thumb(self.file, width, height)(function (img) {
                    var dataURL = img.toDataURL("image/jpeg");
                    var searchString = ";base64,";
                    var pos = dataURL.indexOf(searchString);
                    var imageMimeType = dataURL.substring(5, pos);
                    var imageData = dataURL.substring(pos + searchString.length, dataURL.length);

                    self.options.localSetting.attributes.ImageData = imageData;
                    self.options.localSetting.attributes.ImageMimeType = imageMimeType;
                    self.options.localSetting.attributes.ImageUpdate = true;

                    self.insertImage();
                });
            }
        });
    },
    insertImage: function () {
        this.hideErrors();

        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.EventId = this.settingModel.attributes.EventId;
        tmpModel.attributes.CommonId = this.settingModel.attributes.CommonId;
        tmpModel.attributes.ImageData = this.options.localSetting.attributes.ImageData;
        tmpModel.attributes.ImageMimeType = this.options.localSetting.attributes.ImageMimeType;
        tmpModel.attributes.ImageUpdate = this.options.localSetting.attributes.ImageUpdate;
        tmpModel.attributes.Filename = new Date().getTime() + '.jpg';

        tmpModel.attributes.ImageUrl = '';
        tmpModel.attributes.Description = '';


        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X101112;
        dialogWaitingModel.attributes.Text = resources.X100297;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        app.dialogRegion.show(dialogWaiting);

        var self = this;

        this.collection.fullCollection.create(tmpModel, {
            wait: true,
            success: function (response) {
                dialogWaiting.triggerMethod('dialog:close');

                self.collection.fullCollection.sort();
                self.collection.getFirstPage({ fetch: false });

                self.app.entities.collections.customProductSheetImage.fullCollection.add(response);
            }
        });
    }
});

