﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventTemplate from './tpl/customEventTemplate.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomEventTemplateList from './customEventTemplateList';

import * as Entities_CustomEventTemplate from '../../entities/customEventTemplate';
import config from '../../../common/config';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customEventTemplate();
    },
    regions: {
        customEventTemplateList: "#customEventTemplateList",
        customEventTemplatePagination: "#customEventTemplatePagination",
        information: "#customEventTemplateInformation",
    },
    onBeforeShow: function () {
        this.customEventTemplateList.show(new View_CustomEventTemplateList({ collection: this.collection, app: this.app }));
        this.customEventTemplatePagination.show(new View_Pagination({ collection: this.collection, app: this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();
        this.customEventTemplateCollection = new Entities_CustomEventTemplate.Collection();;
        this.customEventTemplateCollection.fullCollection.comparator = this.customEventTemplateCollection.comparator;

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(userChannel, 'customEventTemplate:getName', function () {
            var name = self.$el.find('#customEventTemplate_inputName').val();
            self.app.customEventTemplate_inputName = name;
        });
        
        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customEventTemplate');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },
    ui: {
        'customEventTemplate_selectSubject': '#customEventTemplate_selectSubject',
        'customEventTemplate_inputName': '#customEventTemplate_inputName',
        'help': '#help'
    },
    events: {
        'change @ui.customEventTemplate_selectSubject': 'selectSubjectChange',
        'click #help': 'help'
    },
    onRender: function () {
        //this.setupSubject();
        //this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events template yet
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100335 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    setupSubject: function () {
        this.ui.customEventTemplate_selectSubject.empty();
        var self = this;

        this.collection.forEach(function (entry) {
            self.ui.customEventTemplate_selectSubject.append('<option value="' + entry.attributes.Subject + '">' + entry.attributes.Subject + '</option>');
        });

        this.fetchCustomEventTemplateCollection();
    },
    selectSubjectChange: function (event) {
        event.preventDefault();
        this.fetchCustomEventTemplateCollection();
    },
    fetchCustomEventTemplateCollection: function () {
        var subject = this.ui.customEventTemplate_selectSubject.val();
        var self = this;

        this.customEventTemplateCollection.fetch({
            CultureName: config.cultureName(),
            subject: subject,
            success: function () {
                self.customEventTemplateCollection.fullCollection.sort();
                self.customEventTemplateCollection.getFirstPage({ fetch: false });
            },
            error: function () {
            }
        });
    },
    customViewEvent_setActive: function () {
        var array = [];

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();
    }
}, Backbone.Radio.Requests);