﻿import * as Backbone from 'backbone';
var Backbone_localStorage = require('backbone.localStorage');
import * as _ from 'underscore';

export var Collection = Backbone.Collection.extend({
    model: Backbone.Model,
    localStorage: new Backbone_localStorage("Events4us-PublicShopCart")
});



