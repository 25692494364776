var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogProcess_empty"> <div class="modal fade" id="dialogProcess_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title" id="dialogProcess_h4_title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div> <div class="modal-body"> <p style="text-align:center" id="dialogProcess_h2_text">'+
((__t=( model.Text ))==null?'':__t)+
'</p> <div class="progress"> <div class="progress-bar progress-bar-danger" id="dialogProcess_progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"> <span class="sr-only">0%</span> </div> </div> </div> <div class="modal-footer"> <button type="button" id="dialogProcess_btnOk" data-dismiss="modal" class="btn btn-primary">'+
((__t=( model.Button ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
