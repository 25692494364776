var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="printerSetting_empty"> <div class="modal fade" id="printerSetting_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div> <div class="modal-body"> <div class="control-group printerSetting_inputStatus" style="display:none"> <div class="form-group"> <label id="pageSetting_labelName" for="printerSetting_inputStatus">'+
((__t=( resources['X100968'] ))==null?'':__t)+
'</label> <input type="checkbox" id="printerSetting_inputStatus"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group printerSetting_inputName" style="display:none"> <div class="form-group"> <label id="printerSetting_labelName" for="printerSetting_inputName">'+
((__t=( resources['X100918'] ))==null?'':__t)+
'</label> <input class="form-control" input id="printerSetting_inputName" value="'+
((__t=( model.Name ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="side-widget"> <h5 class="widget-title">'+
((__t=( resources['X100923'] ))==null?'':__t)+
'</h5> <div class="control-group printerSetting_inputMarginLeft" style=""> <div class="form-group"> <label id="" for="printerSetting_inputMarginLeft">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control" type="number" min="1" max="600" id="printerSetting_inputMarginLeft" value="'+
((__t=( model.MarginLeft ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group printerSetting_inputMarginRight" style=""> <div class="form-group"> <label id="" for="printerSetting_inputMarginRight">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control" type="number" min="1" max="600" id="printerSetting_inputMarginRight" value="'+
((__t=( model.MarginRight ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group printerSetting_inputMarginTop" style=""> <div class="form-group"> <label id="" for="printerSetting_inputMarginTop">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control" type="number" min="1" max="600" id="printerSetting_inputMarginTop" value="'+
((__t=( model.MarginTop ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group printerSetting_inputMarginBottom" style=""> <div class="form-group"> <label id="" for="printerSetting_inputMarginBottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control" type="number" min="1" max="600" id="printerSetting_inputMarginBottom" value="'+
((__t=( model.MarginBottom ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="printerSetting_btn01" class="btn btn-primary">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> <button type="button" id="printerSetting_btn02" class="btn btn-secondary">'+
((__t=( model.Button02 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
