import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';
import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/' + '' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/route01/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
            //if (options.route == 'route01') {
            //    options.url = config.webApiUrl() + '/api/customDepartmentUser/route01/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
            //} else if (options.route == 'route02') {
            //    {
            //        options.url = config.webApiUrl() + '/api/customDepartmentUser/route02/' + model.attributes.id + '?AuthUserId=' + config.authUserId() + '&dummy=0';
            //    }
            //}

        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/-1?AuthUserId=' + config.authUserId();
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    }
});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 6,
    },
    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },
    options: {
        full: false
    },
    "sync": function (method, model, options) {
        if (method == 'read' && options.route == 'route01') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/route01/-1?AuthUserId=' + config.authUserId() + '&Department=' + options.department;
        }
        else if (method == 'read' && options.route == 'route02') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/route02/-1?AuthUserId=' + config.authUserId() + '&Department=' + options.department + '&Information=' + options.Information;
        }

        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
            : a.attributes.id < b.attributes.id ? 1
                : 0;
    }
});




/*export var Model = Backbone.Model.extend({
    constructor: function () {
        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    "sync": function (method, model, options) {
        if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/' + '' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/' + model.attributes.id + '?AuthUserId=' + config.authUserId();

        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customDepartmentUser/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    }
});*/