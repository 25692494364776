//import * as Backbone from 'backbone';
var Backbone = require('backbone');
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/dineroInvoice/' + model.attributes.id + '?AuthUserId=' + config.authUserId() + '&text04=' + options.text04 + '&text05=' + options.text05;
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    }
});

    