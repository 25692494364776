var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEvent_empty"> <div class="content-wrapper" data-help="customEvent"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0">  <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X100070'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101050'] ))==null?'':__t)+
'</h4> <div id="customEventInformation" class="e4usInformation" style="display:none"></div> <div id="customEventInsert"></div> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101051'] ))==null?'':__t)+
'</h4> <div class="" id="customEventList"></div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customEventPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
