var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="setting_empty"> <div class="modal fade" id="settingList_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101344'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="card"> <div class="card-body">  <div class="row"> <div class="col-md-12"> <div id="settingListList"></div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="dialog2Button_btn03" class="btn btn-light">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> <button type="button" id="dialog2Button_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="dialog2Button_btn02" class="btn btn-light">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
