import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync" : function() { return null; },
    "fetch" :function() { return null; },
    "save" : function() { return null; },
    //"sync": function (method, model, options) {
    //    return Backbone.sync(method, model, options);
    //},
    validateNoBackbone: function (app, element, tmpCollection) {
        this.errors = [];

        var self = this;
        for (var i = 0; i < tmpCollection.length; i++) {
            if ( (this.attributes.id != tmpCollection.models[i].attributes.id) && (this.attributes.Webpage == tmpCollection.models[i].attributes.Webpage) ) {
                if (this.attributes.Name.toLowerCase() == tmpCollection.models[i].attributes.Name.toLowerCase()) {
                    //this.errors.push({ name: 'customInfopageEditItem_inputInsertWebpageName', message: resources.X100331 });
                    //this.errors.push({ name: 'customInfopageEditItem_editSelectWebpage', message: resources.X100331 });
                    this.errors.push({ name: element, message: resources.X100331 });
                }
            }
        };

        if (this.attributes.Name == "") {
            //this.errors.push({ name: 'customInfopageEditItem_inputInsertWebpageName', message: resources.X100324 });
            //this.errors.push({ name: 'customInfopageEditItem_editSelectWebpage', message: resources.X100324 });
            this.errors.push({ name: element, message: resources.X100324 });
        }

        

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    },
    comparebyModel :function (tmpModel) {
        var ret = false;

        if  ( (this.attributes.Name != tmpModel.attributes.Name) &&
            (this.attributes.Tag != tmpModel.attributes.Tag) &&
            (this.attributes.Body != tmpModel.attributes.Body) &&
            (this.attributes.Position != tmpModel.attributes.Position) &&
            (this.attributes.Duration != tmpModel.attributes.Duration) &&
            (this.attributes.Css != tmpModel.attributes.Css) &&
            (this.attributes.Status != tmpModel.attributes.Status)) {
            ret = true;
        }
            
        return ret;
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
        this.changeSort("Position");
    },
    "sync": function (method, model, options) {
        //if (method == 'read') {
        //    options.url = config.webApiUrl() + '/api/customInfopageItem/-1?Webpage=' + options.Webpage + '&AuthUserId=' + config.authUserId();
        //}
        /*if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customInfopageItem/route02/-1?AuthUserId=' + config.authUserId();
        }*/
        return Backbone.sync(method, model, options);
    },
    comparator: function (property) {
        return selectedStrategy.apply(myModel.get(property));
    },
    strategies: {
        Position: function (person) { return person.get("Position"); }
    },
    changeSort: function (sortProperty) {
        this.comparator = this.strategies[sortProperty];
    },
    compare2Model :function (tmpModelA, tmpModelB) {
        var ret = false;

        if  ( (tmpModelA.attributes.Name != tmpModelB.attributes.Name) ||
            (tmpModelA.attributes.Tag != tmpModelB.attributes.Tag) ||
            (tmpModelA.attributes.Body != tmpModelB.attributes.Body) ||
            (tmpModelA.attributes.Position != tmpModelB.attributes.Position) ||
            (tmpModelA.attributes.Duration != tmpModelB.attributes.Duration) ||
            (tmpModelA.attributes.Css != tmpModelB.attributes.Css) || 
            (tmpModelA.attributes.Status != tmpModelB.attributes.Status)) {
            ret = true;
        }
            
        return ret;
    }
});