﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaSchemaList from './tpl/customSchemaSchemaList.html';
import View_CustomSchemaSchemaListItem from './customSchemaSchemaListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customSchemaSchemaList',
    childViewContainer: '#customSchemaSchemaList',
    childView: View_CustomSchemaSchemaListItem,
    getTemplate: function () {
        return tpl_customSchemaSchemaList();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
        this.listenTo(this.collection, 'change', this.render);
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        options.settingModel = this.settingModel;
        var view = new ChildViewClass(options);
        return view;
    },
    customSchemaSchema_addOne: function (model) {
        this.render();
    },
    filter: function (child, index, collection) {
        return child.get('EventId') === this.settingModel.attributes.EventId;
    }
});