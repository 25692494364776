var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="schemaSetting_empty"> <div class="modal fade" id="schemaSetting_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> <h4 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h4> </div> <div class="modal-body"> <div class="control-group schemaSetting_inputPosition"> <div class="form-group"> <label id="schemaSetting_labelName" for="schemaSetting_inputPosition">'+
((__t=( resources['X101023'] ))==null?'':__t)+
'</label> <input class="form-control" input id="schemaSetting_inputPosition" value="'+
((__t=( model.Position ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="modal-footer"> <button type="button" id="schemaSetting_btn01" class="btn btn-primary">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> <button type="button" id="schemaSetting_btn02" class="btn btn-secondary">'+
((__t=( model.Button02 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
