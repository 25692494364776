﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_informationListItem from './tpl/informationListItem.html';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_informationListItem({ model: this.model.toJSON() });
    }
});
