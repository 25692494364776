﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaModelEditItem from './tpl/customSchemaModelEditItem.html';
import tpl_customSchemaModelEditItemDesign from './tpl/customSchemaModelEditItemDesign.html';

import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import language from '../../../common/language';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Render from '../render/render';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import Help from '../../helper/help';
import View_SchemaSetting from './setting/SchemaSetting';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customSchemaModelEditItemDesign({ model: this.model.toJSON() });
        } else {
            return tpl_customSchemaModelEditItem({ model: this.model.toJSON() });
        }
    },
    initialize: function () {
        self = this;

        this.app = this.options.app;

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick();
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customSchemaModelEditItem_btnBack_click();
        });	
    },
    regions: {
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100150;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    help: function () {
        Help.help(this, true, 'customSchemaModelEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick();
        callback();
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        this.setModel(this.model);

        if ((this.model.attributes.Name != this.oldModel.attributes.Name) ||
            (this.model.attributes.FieldUsed != this.oldModel.attributes.FieldUsed) ||
            (this.model.attributes.FieldBigscreen != this.oldModel.attributes.FieldBigscreen) ||
            (this.model.attributes.FontSizeSchema != this.oldModel.attributes.FontSizeSchema) ||
            (this.model.attributes.MarginLeftSchema != this.oldModel.attributes.MarginLeftSchema) ||
            (this.model.attributes.MarginRightSchema != this.oldModel.attributes.MarginRightSchema) ||
            (this.model.attributes.FontSizeSchemaBigscreen != this.oldModel.attributes.FontSizeSchemaBigscreen) ||
            (this.model.attributes.MarginLeftSchemaBigscreen != this.oldModel.attributes.MarginLeftSchemaBigscreen) ||
            (this.model.attributes.MarginRightSchemaBigscreen != this.oldModel.attributes.MarginRightSchemaBigscreen) ||
            (this.model.attributes.FieldEvent01 != this.oldModel.attributes.FieldEvent01) ||
            (this.model.attributes.FieldEvent02 != this.oldModel.attributes.FieldEvent02) ||
            (this.model.attributes.FieldEvent03 != this.oldModel.attributes.FieldEvent03) ||
            (this.model.attributes.FieldEvent04 != this.oldModel.attributes.FieldEvent04) ||
            (this.model.attributes.FieldEvent05 != this.oldModel.attributes.FieldEvent05) ||
            (this.model.attributes.FieldEvent06 != this.oldModel.attributes.FieldEvent06) ||
            (this.model.attributes.FieldEvent07 != this.oldModel.attributes.FieldEvent07) ||
            (this.model.attributes.FieldEvent08 != this.oldModel.attributes.FieldEvent08) ||
            (this.model.attributes.FieldEvent09 != this.oldModel.attributes.FieldEvent09) ||
            (this.model.attributes.FieldEvent10 != this.oldModel.attributes.FieldEvent10) ||
            (this.model.attributes.FieldEvent11 != this.oldModel.attributes.FieldEvent11) ||
            (this.model.attributes.FieldEvent12 != this.oldModel.attributes.FieldEvent12) ||
            (this.model.attributes.FieldBigscreen01 != this.oldModel.attributes.FieldBigscreen01) ||
            (this.model.attributes.FieldBigscreen02 != this.oldModel.attributes.FieldBigscreen02) ||
            (this.model.attributes.FieldBigscreen03 != this.oldModel.attributes.FieldBigscreen03) ||
            (this.model.attributes.FieldBigscreen04 != this.oldModel.attributes.FieldBigscreen04) ||
            (this.model.attributes.FieldBigscreen05 != this.oldModel.attributes.FieldBigscreen05) ||
            (this.model.attributes.FieldBigscreen06 != this.oldModel.attributes.FieldBigscreen06) ||
            (this.model.attributes.FieldBigscreen07 != this.oldModel.attributes.FieldBigscreen07) ||
            (this.model.attributes.FieldBigscreen08 != this.oldModel.attributes.FieldBigscreen08) ||
            (this.model.attributes.FieldBigscreen09 != this.oldModel.attributes.FieldBigscreen09) ||
            (this.model.attributes.FieldBigscreen10 != this.oldModel.attributes.FieldBigscreen10) ||
            (this.model.attributes.FieldBigscreen11 != this.oldModel.attributes.FieldBigscreen11) ||
            (this.model.attributes.FieldBigscreen12 != this.oldModel.attributes.FieldBigscreen12) ||
            (this.model.attributes.Field01 != this.oldModel.attributes.Field01) ||
            (this.model.attributes.Field02 != this.oldModel.attributes.Field02) ||
            (this.model.attributes.Field03 != this.oldModel.attributes.Field03) ||
            (this.model.attributes.Field04 != this.oldModel.attributes.Field04) ||
            (this.model.attributes.Field05 != this.oldModel.attributes.Field05) ||
            (this.model.attributes.Field06 != this.oldModel.attributes.Field06) ||
            (this.model.attributes.Field07 != this.oldModel.attributes.Field07) ||
            (this.model.attributes.Field08 != this.oldModel.attributes.Field08) ||
            (this.model.attributes.Field09 != this.oldModel.attributes.Field09) ||
            (this.model.attributes.Field10 != this.oldModel.attributes.Field10) ||
            (this.model.attributes.Field11 != this.oldModel.attributes.Field11) ||
            (this.model.attributes.Field12 != this.oldModel.attributes.Field12)) {
            changes = true;
        }

        this.setHasChanged(changes);
    },
    ui: {
        'customSchemaModelEditItem_btnSubmit': '#customSchemaModelEditItem_btnSubmit',
        'customSchemaModelEditItem_btnSubmit01': '#customSchemaModelEditItem_btnSubmit01',

        'customSchemaModelEditItem_btnReset': '#customSchemaModelEditItem_btnReset',
        'customSchemaModelEditItem_btnCopy': '#customSchemaModelEditItem_btnCopy',
        'customSchemaModelEditItem_select_ConfigCustomSchemaModel': '#customSchemaModelEditItem_select_ConfigCustomSchemaModel',
        'customSchemaModelEditItem_select_FieldUsed': '#customSchemaModelEditItem_select_FieldUsed',
        'customSchemaModelEditItem_select_FieldBigscreen': '#customSchemaModelEditItem_select_FieldBigscreen',
        'customSchemaModelEditItem_select_FontSizeSchema': '#customSchemaModelEditItem_select_FontSizeSchema',
        'customSchemaModelEditItem_select_MarginLeftSchema': '#customSchemaModelEditItem_select_MarginLeftSchema',
        'customSchemaModelEditItem_select_MarginRightSchema': '#customSchemaModelEditItem_select_MarginRightSchema',
        'customSchemaModelEditItem_select_FontSizeSchemaBigscreen': '#customSchemaModelEditItem_select_FontSizeSchemaBigscreen',
        'customSchemaModelEditItem_select_MarginLeftSchemaBigscreen': '#customSchemaModelEditItem_select_MarginLeftSchemaBigscreen',
        'customSchemaModelEditItem_select_MarginRightSchemaBigscreen': '#customSchemaModelEditItem_select_MarginRightSchemaBigscreen',
        'customSchemaModelEditItem_inputName': '#customSchemaModelEditItem_inputName',
        'customSchemaModelEditItem_input_Field01': '#customSchemaModelEditItem_input_Field01',
        'customSchemaModelEditItem_input_Field02': '#customSchemaModelEditItem_input_Field02',
        'customSchemaModelEditItem_input_Field03': '#customSchemaModelEditItem_input_Field03',
        'customSchemaModelEditItem_input_Field04': '#customSchemaModelEditItem_input_Field04',
        'customSchemaModelEditItem_input_Field05': '#customSchemaModelEditItem_input_Field05',
        'customSchemaModelEditItem_input_Field06': '#customSchemaModelEditItem_input_Field06',
        'customSchemaModelEditItem_input_Field07': '#customSchemaModelEditItem_input_Field07',
        'customSchemaModelEditItem_input_Field08': '#customSchemaModelEditItem_input_Field08',
        'customSchemaModelEditItem_input_Field09': '#customSchemaModelEditItem_input_Field09',
        'customSchemaModelEditItem_input_Field10': '#customSchemaModelEditItem_input_Field10',
        'customSchemaModelEditItem_input_Field11': '#customSchemaModelEditItem_input_Field11',
        'customSchemaModelEditItem_input_Field12': '#customSchemaModelEditItem_input_Field12',
        'customSchemaModelEditItem_input_FieldEvent01': '#customSchemaModelEditItem_input_FieldEvent01',
        'customSchemaModelEditItem_input_FieldEvent02': '#customSchemaModelEditItem_input_FieldEvent02',
        'customSchemaModelEditItem_input_FieldEvent03': '#customSchemaModelEditItem_input_FieldEvent03',
        'customSchemaModelEditItem_input_FieldEvent04': '#customSchemaModelEditItem_input_FieldEvent04',
        'customSchemaModelEditItem_input_FieldEvent05': '#customSchemaModelEditItem_input_FieldEvent05',
        'customSchemaModelEditItem_input_FieldEvent06': '#customSchemaModelEditItem_input_FieldEvent06',
        'customSchemaModelEditItem_input_FieldEvent07': '#customSchemaModelEditItem_input_FieldEvent07',
        'customSchemaModelEditItem_input_FieldEvent08': '#customSchemaModelEditItem_input_FieldEvent08',
        'customSchemaModelEditItem_input_FieldEvent09': '#customSchemaModelEditItem_input_FieldEvent09',
        'customSchemaModelEditItem_input_FieldEvent10': '#customSchemaModelEditItem_input_FieldEvent10',
        'customSchemaModelEditItem_input_FieldEvent11': '#customSchemaModelEditItem_input_FieldEvent11',
        'customSchemaModelEditItem_input_FieldEvent12': '#customSchemaModelEditItem_input_FieldEvent12',
        'customSchemaModelEditItem_input_FieldBigscreen01': '#customSchemaModelEditItem_input_FieldBigscreen01',
        'customSchemaModelEditItem_input_FieldBigscreen02': '#customSchemaModelEditItem_input_FieldBigscreen02',
        'customSchemaModelEditItem_input_FieldBigscreen03': '#customSchemaModelEditItem_input_FieldBigscreen03',
        'customSchemaModelEditItem_input_FieldBigscreen04': '#customSchemaModelEditItem_input_FieldBigscreen04',
        'customSchemaModelEditItem_input_FieldBigscreen05': '#customSchemaModelEditItem_input_FieldBigscreen05',
        'customSchemaModelEditItem_input_FieldBigscreen06': '#customSchemaModelEditItem_input_FieldBigscreen06',
        'customSchemaModelEditItem_input_FieldBigscreen07': '#customSchemaModelEditItem_input_FieldBigscreen07',
        'customSchemaModelEditItem_input_FieldBigscreen08': '#customSchemaModelEditItem_input_FieldBigscreen08',
        'customSchemaModelEditItem_input_FieldBigscreen09': '#customSchemaModelEditItem_input_FieldBigscreen09',
        'customSchemaModelEditItem_input_FieldBigscreen10': '#customSchemaModelEditItem_input_FieldBigscreen10',
        'customSchemaModelEditItem_input_FieldBigscreen11': '#customSchemaModelEditItem_input_FieldBigscreen11',
        'customSchemaModelEditItem_input_FieldBigscreen12': '#customSchemaModelEditItem_input_FieldBigscreen12',
        'customSchemaModelEditItem_btnSetting01': '#customSchemaModelEditItem_btnSetting01',
        'customSchemaModelEditItem_btnSetting02': '#customSchemaModelEditItem_btnSetting02',
        'customSchemaModelEditItem_btnSetting03': '#customSchemaModelEditItem_btnSetting03',
        'customSchemaModelEditItem_btnSetting04': '#customSchemaModelEditItem_btnSetting04',
        'customSchemaModelEditItem_btnSetting05': '#customSchemaModelEditItem_btnSetting05',
        'customSchemaModelEditItem_btnSetting06': '#customSchemaModelEditItem_btnSetting06',
        'customSchemaModelEditItem_btnSetting07': '#customSchemaModelEditItem_btnSetting07',
        'customSchemaModelEditItem_btnSetting08': '#customSchemaModelEditItem_btnSetting08',
        'customSchemaModelEditItem_btnSetting09': '#customSchemaModelEditItem_btnSetting09',
        'customSchemaModelEditItem_btnSetting10': '#customSchemaModelEditItem_btnSetting10',
        'customSchemaModelEditItem_btnSetting11': '#customSchemaModelEditItem_btnSetting11',
        'customSchemaModelEditItem_btnSetting12': '#customSchemaModelEditItem_btnSetting12',
        'customSchemaModelEditItem_btnSettingDown01': '#customSchemaModelEditItem_btnSettingDown01',
        'customSchemaModelEditItem_btnSettingDown02': '#customSchemaModelEditItem_btnSettingDown02',
        'customSchemaModelEditItem_btnSettingDown03': '#customSchemaModelEditItem_btnSettingDown03',
        'customSchemaModelEditItem_btnSettingDown04': '#customSchemaModelEditItem_btnSettingDown04',
        'customSchemaModelEditItem_btnSettingDown05': '#customSchemaModelEditItem_btnSettingDown05',
        'customSchemaModelEditItem_btnSettingDown06': '#customSchemaModelEditItem_btnSettingDown06',
        'customSchemaModelEditItem_btnSettingDown07': '#customSchemaModelEditItem_btnSettingDown07',
        'customSchemaModelEditItem_btnSettingDown08': '#customSchemaModelEditItem_btnSettingDown08',
        'customSchemaModelEditItem_btnSettingDown09': '#customSchemaModelEditItem_btnSettingDown09',
        'customSchemaModelEditItem_btnSettingDown10': '#customSchemaModelEditItem_btnSettingDown10',
        'customSchemaModelEditItem_btnSettingDown11': '#customSchemaModelEditItem_btnSettingDown11',
        'customSchemaModelEditItem_btnSettingDown12': '#customSchemaModelEditItem_btnSettingDown12',
        'customSchemaModelEditItem_btnSettingUp01': '#customSchemaModelEditItem_btnSettingUp01',
        'customSchemaModelEditItem_btnSettingUp02': '#customSchemaModelEditItem_btnSettingUp02',
        'customSchemaModelEditItem_btnSettingUp03': '#customSchemaModelEditItem_btnSettingUp03',
        'customSchemaModelEditItem_btnSettingUp04': '#customSchemaModelEditItem_btnSettingUp04',
        'customSchemaModelEditItem_btnSettingUp05': '#customSchemaModelEditItem_btnSettingUp05',
        'customSchemaModelEditItem_btnSettingUp06': '#customSchemaModelEditItem_btnSettingUp06',
        'customSchemaModelEditItem_btnSettingUp07': '#customSchemaModelEditItem_btnSettingUp07',
        'customSchemaModelEditItem_btnSettingUp08': '#customSchemaModelEditItem_btnSettingUp08',
        'customSchemaModelEditItem_btnSettingUp09': '#customSchemaModelEditItem_btnSettingUp09',
        'customSchemaModelEditItem_btnSettingUp10': '#customSchemaModelEditItem_btnSettingUp10',
        'customSchemaModelEditItem_btnSettingUp11': '#customSchemaModelEditItem_btnSettingUp11',
        'customSchemaModelEditItem_btnSettingUp12': '#customSchemaModelEditItem_btnSettingUp12',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customSchemaModelEditItem_inputNameGroup': '#customSchemaModelEditItem_inputNameGroup',
        'customSchemaModelEditItem_btnBack': '#customSchemaModelEditItem_btnBack'

    },
    events: {
        'click @ui.customSchemaModelEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customSchemaModelEditItem_btnSubmit01': 'btnSubmitClick',

        'click @ui.customSchemaModelEditItem_btnReset': 'btnResetClick',
        'click @ui.customSchemaModelEditItem_btnCopy': 'btnCopyClick',
        'change @ui.customSchemaModelEditItem_select_ConfigCustomSchemaModel': 'selectConfigCustomSchemaModel',
        'change @ui.customSchemaModelEditItem_select_FieldUsed': 'selectFieldUsedChange',
        'click @ui.customSchemaModelEditItem_btnSetting01': 'btnSettingClick01',
        'click @ui.customSchemaModelEditItem_btnSetting02': 'btnSettingClick02',
        'click @ui.customSchemaModelEditItem_btnSetting03': 'btnSettingClick03',
        'click @ui.customSchemaModelEditItem_btnSetting04': 'btnSettingClick04',
        'click @ui.customSchemaModelEditItem_btnSetting05': 'btnSettingClick05',
        'click @ui.customSchemaModelEditItem_btnSetting06': 'btnSettingClick06',
        'click @ui.customSchemaModelEditItem_btnSetting07': 'btnSettingClick07',
        'click @ui.customSchemaModelEditItem_btnSetting08': 'btnSettingClick08',
        'click @ui.customSchemaModelEditItem_btnSetting09': 'btnSettingClick09',
        'click @ui.customSchemaModelEditItem_btnSetting10': 'btnSettingClick10',
        'click @ui.customSchemaModelEditItem_btnSetting11': 'btnSettingClick11',
        'click @ui.customSchemaModelEditItem_btnSetting12': 'btnSettingClick12',
        'click @ui.customSchemaModelEditItem_btnSettingDown01': 'btnSettingDown01',
        'click @ui.customSchemaModelEditItem_btnSettingDown02': 'btnSettingDown02',
        'click @ui.customSchemaModelEditItem_btnSettingDown03': 'btnSettingDown03',
        'click @ui.customSchemaModelEditItem_btnSettingDown04': 'btnSettingDown04',
        'click @ui.customSchemaModelEditItem_btnSettingDown05': 'btnSettingDown05',
        'click @ui.customSchemaModelEditItem_btnSettingDown06': 'btnSettingDown06',
        'click @ui.customSchemaModelEditItem_btnSettingDown07': 'btnSettingDown07',
        'click @ui.customSchemaModelEditItem_btnSettingDown08': 'btnSettingDown08',
        'click @ui.customSchemaModelEditItem_btnSettingDown09': 'btnSettingDown09',
        'click @ui.customSchemaModelEditItem_btnSettingDown10': 'btnSettingDown10',
        'click @ui.customSchemaModelEditItem_btnSettingDown11': 'btnSettingDown11',
        'click @ui.customSchemaModelEditItem_btnSettingDown12': 'btnSettingDown12',
        'click @ui.customSchemaModelEditItem_btnSettingUp01': 'btnSettingUp01',
        'click @ui.customSchemaModelEditItem_btnSettingUp02': 'btnSettingUp02',
        'click @ui.customSchemaModelEditItem_btnSettingUp03': 'btnSettingUp03',
        'click @ui.customSchemaModelEditItem_btnSettingUp04': 'btnSettingUp04',
        'click @ui.customSchemaModelEditItem_btnSettingUp05': 'btnSettingUp05',
        'click @ui.customSchemaModelEditItem_btnSettingUp06': 'btnSettingUp06',
        'click @ui.customSchemaModelEditItem_btnSettingUp07': 'btnSettingUp07',
        'click @ui.customSchemaModelEditItem_btnSettingUp08': 'btnSettingUp08',
        'click @ui.customSchemaModelEditItem_btnSettingUp09': 'btnSettingUp09',
        'click @ui.customSchemaModelEditItem_btnSettingUp10': 'btnSettingUp10',
        'click @ui.customSchemaModelEditItem_btnSettingUp11': 'btnSettingUp11',
        'click @ui.customSchemaModelEditItem_btnSettingUp12': 'btnSettingUp12',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customSchemaModelEditItem_btnBack': 'customSchemaModelEditItem_btnBack_click'
    },
    onRender: function () {
        this.setupConfigCustomSchemaModel();

        this.setupFieldUsed(this.ui.customSchemaModelEditItem_select_FieldUsed);
        this.ui.customSchemaModelEditItem_select_FieldUsed.val(this.model.attributes.FieldUsed);

        this.setupFieldBigscreen(this.ui.customSchemaModelEditItem_select_FieldBigscreen);
        this.ui.customSchemaModelEditItem_select_FieldBigscreen.val(this.model.attributes.FieldBigscreen);

        this.setupFontSizeSchema(this.ui.customSchemaModelEditItem_select_FontSizeSchema);
        this.ui.customSchemaModelEditItem_select_FontSizeSchema.val(this.model.attributes.FontSizeSchema);
        this.setupMarginLeftSchema(this.ui.customSchemaModelEditItem_select_MarginLeftSchema);
        this.ui.customSchemaModelEditItem_select_MarginLeftSchema.val(this.model.attributes.MarginLeftSchema);
        this.setupMarginRightSchema(this.ui.customSchemaModelEditItem_select_MarginRightSchema);
        this.ui.customSchemaModelEditItem_select_MarginRightSchema.val(this.model.attributes.MarginRightSchema);

        this.setupFontSizeSchemaBigscreen(this.ui.customSchemaModelEditItem_select_FontSizeSchemaBigscreen);
        this.ui.customSchemaModelEditItem_select_FontSizeSchemaBigscreen.val(this.model.attributes.FontSizeSchemaBigscreen);
        this.setupMarginLeftSchemaBigscreen(this.ui.customSchemaModelEditItem_select_MarginLeftSchemaBigscreen);
        this.ui.customSchemaModelEditItem_select_MarginLeftSchemaBigscreen.val(this.model.attributes.MarginLeftSchemaBigscreen);
        this.setupMarginRightSchemaBigscreen(this.ui.customSchemaModelEditItem_select_MarginRightSchemaBigscreen);
        this.ui.customSchemaModelEditItem_select_MarginRightSchemaBigscreen.val(this.model.attributes.MarginRightSchemaBigscreen);

        this.ui.customSchemaModelEditItem_input_FieldEvent01.attr('checked', this.model.attributes.FieldEvent01);
        this.ui.customSchemaModelEditItem_input_FieldEvent02.attr('checked', this.model.attributes.FieldEvent02);
        this.ui.customSchemaModelEditItem_input_FieldEvent03.attr('checked', this.model.attributes.FieldEvent03);
        this.ui.customSchemaModelEditItem_input_FieldEvent04.attr('checked', this.model.attributes.FieldEvent04);
        this.ui.customSchemaModelEditItem_input_FieldEvent05.attr('checked', this.model.attributes.FieldEvent05);
        this.ui.customSchemaModelEditItem_input_FieldEvent06.attr('checked', this.model.attributes.FieldEvent06);
        this.ui.customSchemaModelEditItem_input_FieldEvent07.attr('checked', this.model.attributes.FieldEvent07);
        this.ui.customSchemaModelEditItem_input_FieldEvent08.attr('checked', this.model.attributes.FieldEvent08);
        this.ui.customSchemaModelEditItem_input_FieldEvent09.attr('checked', this.model.attributes.FieldEvent09);
        this.ui.customSchemaModelEditItem_input_FieldEvent10.attr('checked', this.model.attributes.FieldEvent10);
        this.ui.customSchemaModelEditItem_input_FieldEvent11.attr('checked', this.model.attributes.FieldEvent11);
        this.ui.customSchemaModelEditItem_input_FieldEvent12.attr('checked', this.model.attributes.FieldEvent12);

        this.ui.customSchemaModelEditItem_input_FieldBigscreen01.attr('checked', this.model.attributes.FieldBigscreen01);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen02.attr('checked', this.model.attributes.FieldBigscreen02);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen03.attr('checked', this.model.attributes.FieldBigscreen03);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen04.attr('checked', this.model.attributes.FieldBigscreen04);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen05.attr('checked', this.model.attributes.FieldBigscreen05);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen06.attr('checked', this.model.attributes.FieldBigscreen06);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen07.attr('checked', this.model.attributes.FieldBigscreen07);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen08.attr('checked', this.model.attributes.FieldBigscreen08);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen09.attr('checked', this.model.attributes.FieldBigscreen09);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen10.attr('checked', this.model.attributes.FieldBigscreen10);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen11.attr('checked', this.model.attributes.FieldBigscreen11);
        this.ui.customSchemaModelEditItem_input_FieldBigscreen12.attr('checked', this.model.attributes.FieldBigscreen12);

        this.selectFieldUsedChange(null);

        if (this.app.mode == 1) {
            this.ui.customSchemaModelEditItem_inputNameGroup.css('display', 'none');
            this.ui.customSchemaModelEditItem_btnSubmit.css('display', 'none');
            this.ui.customSchemaModelEditItem_btnSubmit01.css('display', 'none');
            this.ui.customSchemaModelEditItem_btnBack.css('display', 'none');
        }

        return this;
    },
    customViewEvent_setActive: function () {
        var array = [];

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ SchemaModel: this.model.attributes.id });

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }
    },
    setupFieldUsed: function (element) {
        var FieldUsedStr = "";

        for (var i = 1; i <= 12; i++) {
            if (i < 10) {
                FieldUsedStr = "0" + i.toString();
            }
            else {
                FieldUsedStr = "" + i.toString();
            }

            element.append('<option value="' + i + '">' + FieldUsedStr + '</option>');
        }
    },
    setupFieldBigscreen: function (element) {
        //element.append('<option value="0">' + resources.X100172 + '</option>');
        element.append('<option value="0">' + resources.X100173 + '</option>');
        element.append('<option value="1">' + resources.X100174 + '</option>');
    },
    setupFontSizeSchema: function (element) {
        for (var i = 5; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupMarginLeftSchema: function (element) {
        for (var i = 1; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupMarginRightSchema: function (element) {
        for (var i = 1; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupFontSizeSchemaBigscreen: function (element) {
        for (var i = 5; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupMarginLeftSchemaBigscreen: function (element) {
        for (var i = 1; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupMarginRightSchemaBigscreen: function (element) {
        for (var i = 1; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupFieldSize: function (element) {
        var FieldSizeStr = "";

        for (var i = 1; i <= 12; i++) {
            if (i < 10) {
                FieldSizeStr = "0" + i.toString();
            }
            else {
                FieldSizeStr = "" + i.toString();
            }

            element.append('<option value="' + i + '">' + FieldSizeStr + '</option>');
        }
    },
    setupConfigCustomSchemaModel: function () {
        this.ui.customSchemaModelEditItem_select_ConfigCustomSchemaModel.empty();
        var self = this;
        this.app.entities.collections.configSchemaModel.forEach(function (entry) {
            self.ui.customSchemaModelEditItem_select_ConfigCustomSchemaModel.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
        });
    },
    selectConfigCustomSchemaModelChange: function (event) {
        event.preventDefault();

    },
    selectFieldUsedChange: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.model.attributes.FieldUsed = parseInt(this.ui.customSchemaModelEditItem_select_FieldUsed.val());

        for (var i = 1; i <= 12; i++) {
            var sId = '';
            if (i < 10) {
                sId = '#customSchemaModelEditItem_div_Field0' + i;
            }
            else {
                sId = '#customSchemaModelEditItem_div_Field' + i;
            }

            if (i <= this.model.attributes.FieldUsed) {
                this.$el.find(sId).show();
            }
            else {
                this.$el.find(sId).css('display', 'none');
            }
        }
    },
    setModel: function (tmpModel) {
        //tmpModel.attributes.Name = this.ui.customSchemaModelEditItem_inputName.val();

        tmpModel.attributes.FieldUsed = parseInt(this.ui.customSchemaModelEditItem_select_FieldUsed.val());
        tmpModel.attributes.FieldBigscreen = parseInt(this.ui.customSchemaModelEditItem_select_FieldBigscreen.val());

        tmpModel.attributes.FontSizeSchema = parseInt(this.ui.customSchemaModelEditItem_select_FontSizeSchema.val());
        tmpModel.attributes.MarginLeftSchema = parseInt(this.ui.customSchemaModelEditItem_select_MarginLeftSchema.val());
        tmpModel.attributes.MarginRightSchema = parseInt(this.ui.customSchemaModelEditItem_select_MarginRightSchema.val());

        tmpModel.attributes.FontSizeSchemaBigscreen = parseInt(this.ui.customSchemaModelEditItem_select_FontSizeSchemaBigscreen.val());
        tmpModel.attributes.MarginLeftSchemaBigscreen = parseInt(this.ui.customSchemaModelEditItem_select_MarginLeftSchemaBigscreen.val());
        tmpModel.attributes.MarginRightSchemaBigscreen = parseInt(this.ui.customSchemaModelEditItem_select_MarginRightSchemaBigscreen.val());

        this.ui.customSchemaModelEditItem_input_FieldEvent01.is(':checked') ? tmpModel.attributes.FieldEvent01 = true : tmpModel.attributes.FieldEvent01 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent02.is(':checked') ? tmpModel.attributes.FieldEvent02 = true : tmpModel.attributes.FieldEvent02 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent03.is(':checked') ? tmpModel.attributes.FieldEvent03 = true : tmpModel.attributes.FieldEvent03 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent04.is(':checked') ? tmpModel.attributes.FieldEvent04 = true : tmpModel.attributes.FieldEvent04 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent05.is(':checked') ? tmpModel.attributes.FieldEvent05 = true : tmpModel.attributes.FieldEvent05 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent06.is(':checked') ? tmpModel.attributes.FieldEvent06 = true : tmpModel.attributes.FieldEvent06 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent07.is(':checked') ? tmpModel.attributes.FieldEvent07 = true : tmpModel.attributes.FieldEvent07 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent08.is(':checked') ? tmpModel.attributes.FieldEvent08 = true : tmpModel.attributes.FieldEvent08 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent09.is(':checked') ? tmpModel.attributes.FieldEvent09 = true : tmpModel.attributes.FieldEvent09 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent10.is(':checked') ? tmpModel.attributes.FieldEvent10 = true : tmpModel.attributes.FieldEvent10 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent11.is(':checked') ? tmpModel.attributes.FieldEvent11 = true : tmpModel.attributes.FieldEvent11 = false;
        this.ui.customSchemaModelEditItem_input_FieldEvent12.is(':checked') ? tmpModel.attributes.FieldEvent12 = true : tmpModel.attributes.FieldEvent12 = false;

        this.ui.customSchemaModelEditItem_input_FieldBigscreen01.is(':checked') ? tmpModel.attributes.FieldBigscreen01 = true : tmpModel.attributes.FieldBigscreen01 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen02.is(':checked') ? tmpModel.attributes.FieldBigscreen02 = true : tmpModel.attributes.FieldBigscreen02 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen03.is(':checked') ? tmpModel.attributes.FieldBigscreen03 = true : tmpModel.attributes.FieldBigscreen03 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen04.is(':checked') ? tmpModel.attributes.FieldBigscreen04 = true : tmpModel.attributes.FieldBigscreen04 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen05.is(':checked') ? tmpModel.attributes.FieldBigscreen05 = true : tmpModel.attributes.FieldBigscreen05 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen06.is(':checked') ? tmpModel.attributes.FieldBigscreen06 = true : tmpModel.attributes.FieldBigscreen06 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen07.is(':checked') ? tmpModel.attributes.FieldBigscreen07 = true : tmpModel.attributes.FieldBigscreen07 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen08.is(':checked') ? tmpModel.attributes.FieldBigscreen08 = true : tmpModel.attributes.FieldBigscreen08 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen09.is(':checked') ? tmpModel.attributes.FieldBigscreen09 = true : tmpModel.attributes.FieldBigscreen09 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen10.is(':checked') ? tmpModel.attributes.FieldBigscreen10 = true : tmpModel.attributes.FieldBigscreen10 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen11.is(':checked') ? tmpModel.attributes.FieldBigscreen11 = true : tmpModel.attributes.FieldBigscreen11 = false;
        this.ui.customSchemaModelEditItem_input_FieldBigscreen12.is(':checked') ? tmpModel.attributes.FieldBigscreen12 = true : tmpModel.attributes.FieldBigscreen12 = false;

        tmpModel.attributes.Field01 = this.ui.customSchemaModelEditItem_input_Field01.val();
        tmpModel.attributes.Field02 = this.ui.customSchemaModelEditItem_input_Field02.val();
        tmpModel.attributes.Field03 = this.ui.customSchemaModelEditItem_input_Field03.val();
        tmpModel.attributes.Field04 = this.ui.customSchemaModelEditItem_input_Field04.val();
        tmpModel.attributes.Field05 = this.ui.customSchemaModelEditItem_input_Field05.val();
        tmpModel.attributes.Field06 = this.ui.customSchemaModelEditItem_input_Field06.val();
        tmpModel.attributes.Field07 = this.ui.customSchemaModelEditItem_input_Field07.val();
        tmpModel.attributes.Field08 = this.ui.customSchemaModelEditItem_input_Field08.val();
        tmpModel.attributes.Field09 = this.ui.customSchemaModelEditItem_input_Field09.val();
        tmpModel.attributes.Field10 = this.ui.customSchemaModelEditItem_input_Field10.val();
        tmpModel.attributes.Field11 = this.ui.customSchemaModelEditItem_input_Field11.val();
        tmpModel.attributes.Field12 = this.ui.customSchemaModelEditItem_input_Field12.val();
    },
    btnSubmitClick: function (event) {
        if (event) {
            event.preventDefault();
        }
        var self = this;

        this.setModel(this.model);

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
        }
        else {
            this.hideErrors();

            var self = this;

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100408;
            dialogWaitingModel.attributes.Text = resources.X100415;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);


            this.model.save(this.model.attributes, {
                silent: true,
                wait: true,
                success: function () {
                    self.model.attributes.Pos01 = -1;
                    self.model.attributes.Pos02 = -1;
                    self.model.attributes.Pos03 = -1;
                    self.model.attributes.Pos04 = -1;
                    self.model.attributes.Pos05 = -1;
                    self.model.attributes.Pos06 = -1;
                    self.model.attributes.Pos07 = -1;
                    self.model.attributes.Pos08 = -1;
                    self.model.attributes.Pos09 = -1;
                    self.model.attributes.Pos10 = -1;
                    self.model.attributes.Pos11 = -1;
                    self.model.attributes.Pos12 = -1;

                    dialogWaiting.triggerMethod('dialog:close');
                    self.oldModel = self.model.clone();

                    var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ SchemaModel: self.model.attributes.id })
                    var render = new View_Render({ collection: customEvent, app: self.app });
                    self.app.renderRegion.show(render);
                    var renderFinishCallback = function (options) {
                    }
                    //render.doIt(renderFinishCallback, false, 'EVENTSCHEMA_REFRESH', false);
                    render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', true);
                }
            });
        }
    },
    btnResetClick: function () {

    },
    btnCopyClick: function (event) {
        event.preventDefault();

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = resources.X100153;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function (options) {
            var value = this.ui.customSchemaModelEditItem_select_ConfigCustomSchemaModel.val();
            var configSchemaModel = this.app.entities.collections.configSchemaModel.where({ id: parseInt(value) });

            if (configSchemaModel.length > 0) {
                var self = this;
                this.model.attributes.FieldUsed = configSchemaModel[0].attributes.FieldUsed;
                this.model.attributes.FontSizeSchema = configSchemaModel[0].attributes.FontSizeSchema;
                this.model.attributes.MarginLeftSchema = configSchemaModel[0].attributes.MarginLeftSchema;
                this.model.attributes.MarginRightSchema = configSchemaModel[0].attributes.MarginRightSchema;

                this.model.attributes.FontSizeSchemaBigscreen = configSchemaModel[0].attributes.FontSizeSchemaBigscreen;
                this.model.attributes.MarginLeftSchemaBigscreen = configSchemaModel[0].attributes.MarginLeftSchemaBigscreen;
                this.model.attributes.MarginRightSchemaBigscreen = configSchemaModel[0].attributes.MarginRightSchemaBigscreen;

                this.model.attributes.Field01 = configSchemaModel[0].attributes.Field01;
                this.model.attributes.Field02 = configSchemaModel[0].attributes.Field02;
                this.model.attributes.Field03 = configSchemaModel[0].attributes.Field03;
                this.model.attributes.Field04 = configSchemaModel[0].attributes.Field04;
                this.model.attributes.Field05 = configSchemaModel[0].attributes.Field05;
                this.model.attributes.Field06 = configSchemaModel[0].attributes.Field06;
                this.model.attributes.Field07 = configSchemaModel[0].attributes.Field07;
                this.model.attributes.Field08 = configSchemaModel[0].attributes.Field08;
                this.model.attributes.Field09 = configSchemaModel[0].attributes.Field09;
                this.model.attributes.Field10 = configSchemaModel[0].attributes.Field10;
                this.model.attributes.Field11 = configSchemaModel[0].attributes.Field11;
                this.model.attributes.Field12 = configSchemaModel[0].attributes.Field12;

                this.model.attributes.FieldEvent01 = configSchemaModel[0].attributes.FieldEvent01;
                this.model.attributes.FieldEvent02 = configSchemaModel[0].attributes.FieldEvent02;
                this.model.attributes.FieldEvent03 = configSchemaModel[0].attributes.FieldEvent03;
                this.model.attributes.FieldEvent04 = configSchemaModel[0].attributes.FieldEvent04;
                this.model.attributes.FieldEvent05 = configSchemaModel[0].attributes.FieldEvent05;
                this.model.attributes.FieldEvent06 = configSchemaModel[0].attributes.FieldEvent06;
                this.model.attributes.FieldEvent07 = configSchemaModel[0].attributes.FieldEvent07;
                this.model.attributes.FieldEvent08 = configSchemaModel[0].attributes.FieldEvent08;
                this.model.attributes.FieldEvent09 = configSchemaModel[0].attributes.FieldEvent09;
                this.model.attributes.FieldEvent10 = configSchemaModel[0].attributes.FieldEvent10;
                this.model.attributes.FieldEvent11 = configSchemaModel[0].attributes.FieldEvent11;
                this.model.attributes.FieldEvent12 = configSchemaModel[0].attributes.FieldEvent12;

                this.model.attributes.FieldBigscreen = configSchemaModel[0].attributes.FieldBigscreen;
                this.model.attributes.FieldBigscreen01 = configSchemaModel[0].attributes.FieldBigscreen01;
                this.model.attributes.FieldBigscreen02 = configSchemaModel[0].attributes.FieldBigscreen02;
                this.model.attributes.FieldBigscreen03 = configSchemaModel[0].attributes.FieldBigscreen03;
                this.model.attributes.FieldBigscreen04 = configSchemaModel[0].attributes.FieldBigscreen04;
                this.model.attributes.FieldBigscreen05 = configSchemaModel[0].attributes.FieldBigscreen05;
                this.model.attributes.FieldBigscreen06 = configSchemaModel[0].attributes.FieldBigscreen06;
                this.model.attributes.FieldBigscreen07 = configSchemaModel[0].attributes.FieldBigscreen07;
                this.model.attributes.FieldBigscreen08 = configSchemaModel[0].attributes.FieldBigscreen08;
                this.model.attributes.FieldBigscreen09 = configSchemaModel[0].attributes.FieldBigscreen09;
                this.model.attributes.FieldBigscreen10 = configSchemaModel[0].attributes.FieldBigscreen10;
                this.model.attributes.FieldBigscreen11 = configSchemaModel[0].attributes.FieldBigscreen11;
                this.model.attributes.FieldBigscreen12 = configSchemaModel[0].attributes.FieldBigscreen12;

                this.render();
            }
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnSettingClick01: function (event) {
        event.preventDefault();

        this.SchemaSetting(1);
    },
    btnSettingClick02: function (event) {
        event.preventDefault();

        this.SchemaSetting(2);
    },
    btnSettingClick03: function (event) {
        event.preventDefault();

        this.SchemaSetting(3);
    },
    btnSettingClick04: function (event) {
        event.preventDefault();

        this.SchemaSetting(4);
    },
    btnSettingClick05: function (event) {
        event.preventDefault();

        this.SchemaSetting(5);
    },
    btnSettingClick06: function (event) {
        event.preventDefault();

        this.SchemaSetting(6);
    },
    btnSettingClick07: function (event) {
        event.preventDefault();

        this.SchemaSetting(7);
    },
    btnSettingClick08: function (event) {
        event.preventDefault();

        this.SchemaSetting(8);
    },
    btnSettingClick09: function (event) {
        event.preventDefault();

        this.SchemaSetting(9);
    },
    btnSettingClick10: function (event) {
        event.preventDefault();

        this.SchemaSetting(10);
    },
    btnSettingClick11: function (event) {
        event.preventDefault();

        this.SchemaSetting(11);
    },
    btnSettingClick12: function (event) {
        event.preventDefault();

        this.SchemaSetting(12);
    },
    btnSettingDown01: function (event) {
        event.preventDefault();

        this.changePosition(1, 2);
    },
    btnSettingDown02: function (event) {
        event.preventDefault();

        this.changePosition(2, 3);
    },
    btnSettingDown03: function (event) {
        event.preventDefault();

        this.changePosition(3, 4);
    },
    btnSettingDown04: function (event) {
        event.preventDefault();

        this.changePosition(4, 5);
    },
    btnSettingDown05: function (event) {
        event.preventDefault();

        this.changePosition(5, 6);
    },
    btnSettingDown06: function (event) {
        event.preventDefault();

        this.changePosition(6, 7);
    },
    btnSettingDown07: function (event) {
        event.preventDefault();

        this.changePosition(7, 8);
    },
    btnSettingDown08: function (event) {
        event.preventDefault();

        this.changePosition(8, 9);
    },
    btnSettingDown09: function (event) {
        event.preventDefault();

        this.changePosition(9, 10);
    },
    btnSettingDown10: function (event) {
        event.preventDefault();

        this.changePosition(10, 11);
    },
    btnSettingDown11: function (event) {
        event.preventDefault();

        this.changePosition(11, 12);
    },
    btnSettingDown12: function (event) {
        event.preventDefault();
    },
    btnSettingUp01: function (event) {
        event.preventDefault();
    },
    btnSettingUp02: function (event) {
        event.preventDefault();

        this.changePosition(2, 1);
    },
    btnSettingUp03: function (event) {
        event.preventDefault();

        this.changePosition(3, 2);
    },
    btnSettingUp04: function (event) {
        event.preventDefault();

        this.changePosition(4, 3);
    },
    btnSettingUp05: function (event) {
        event.preventDefault();

        this.changePosition(5, 4);
    },
    btnSettingUp06: function (event) {
        event.preventDefault();

        this.changePosition(6, 5);
    },
    btnSettingUp07: function (event) {
        event.preventDefault();

        this.changePosition(7, 6);
    },
    btnSettingUp08: function (event) {
        event.preventDefault();

        this.changePosition(8, 7);
    },
    btnSettingUp09: function (event) {
        event.preventDefault();

        this.changePosition(9, 8);
    },
    btnSettingUp10: function (event) {
        event.preventDefault();

        this.changePosition(10, 9);
    },
    btnSettingUp11: function (event) {
        event.preventDefault();

        this.changePosition(11, 10);
    },
    btnSettingUp12: function (event) {
        event.preventDefault();

        this.changePosition(12, 11);
    },

    changeAttributes: function (oldPosition, position, oldModel) {
        var attr, oldAttr, attrPos;

        switch (position) {
            case 1: attr = oldModel.attributes.Field01; attrPos = oldModel.attributes.Pos01; break;
            case 2: attr = oldModel.attributes.Field02; attrPos = oldModel.attributes.Pos02; break;
            case 3: attr = oldModel.attributes.Field03; attrPos = oldModel.attributes.Pos03; break;
            case 4: attr = oldModel.attributes.Field04; attrPos = oldModel.attributes.Pos04; break;
            case 5: attr = oldModel.attributes.Field05; attrPos = oldModel.attributes.Pos05; break;
            case 6: attr = oldModel.attributes.Field06; attrPos = oldModel.attributes.Pos06; break;
            case 7: attr = oldModel.attributes.Field07; attrPos = oldModel.attributes.Pos07; break;
            case 8: attr = oldModel.attributes.Field08; attrPos = oldModel.attributes.Pos08; break;
            case 9: attr = oldModel.attributes.Field09; attrPos = oldModel.attributes.Pos09; break;
            case 10: attr = oldModel.attributes.Field10; attrPos = oldModel.attributes.Pos10; break;
            case 11: attr = oldModel.attributes.Field11; attrPos = oldModel.attributes.Pos11; break;
            case 12: attr = oldModel.attributes.Field12; attrPos = oldModel.attributes.Pos12; break;
        }

        switch (oldPosition) {
            case 1: this.model.attributes.Field01 = attr;
                this.ui.customSchemaModelEditItem_input_Field01.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos01 = attrPos;
                } else {
                    this.model.attributes.Pos01 = position;
                }
                break;
            case 2: this.model.attributes.Field02 = attr;
                this.ui.customSchemaModelEditItem_input_Field02.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos02 = attrPos;
                } else {
                    this.model.attributes.Pos02 = position;
                }
                break;
            case 3: this.model.attributes.Field03 = attr;
                this.ui.customSchemaModelEditItem_input_Field03.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos03 = attrPos;
                } else {
                    this.model.attributes.Pos03 = position;
                }
                break;
            case 4: this.model.attributes.Field04 = attr;
                this.ui.customSchemaModelEditItem_input_Field04.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos04 = attrPos;
                } else {
                    this.model.attributes.Pos04 = position;
                }
                break;
            case 5: this.model.attributes.Field05 = attr;
                this.ui.customSchemaModelEditItem_input_Field05.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos05 = attrPos;
                } else {
                    this.model.attributes.Pos05 = position;
                }
                break;
            case 6: this.model.attributes.Field06 = attr;
                this.ui.customSchemaModelEditItem_input_Field06.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos06 = attrPos;
                } else {
                    this.model.attributes.Pos06 = position;
                }
                break;
            case 7: this.model.attributes.Field07 = attr;
                this.ui.customSchemaModelEditItem_input_Field07.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos07 = attrPos;
                } else {
                    this.model.attributes.Pos07 = position;
                }
                break;
            case 8: this.model.attributes.Field08 = attr;
                this.ui.customSchemaModelEditItem_input_Field08.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos08 = attrPos;
                } else {
                    this.model.attributes.Pos08 = position;
                }
                break;
            case 9: this.model.attributes.Field09 = attr;
                this.ui.customSchemaModelEditItem_input_Field09.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos09 = attrPos;
                } else {
                    this.model.attributes.Pos09 = position;
                }
                break;
            case 10: this.model.attributes.Field10 = attr;
                this.ui.customSchemaModelEditItem_input_Field10.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos10 = attrPos;
                } else {
                    this.model.attributes.Pos10 = position;
                }
                break;
            case 11: this.model.attributes.Field11 = attr;
                this.ui.customSchemaModelEditItem_input_Field11.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos11 = attrPos;
                } else {
                    this.model.attributes.Pos11 = position;
                }
                break;
            case 12: this.model.attributes.Field12 = attr;
                this.ui.customSchemaModelEditItem_input_Field12.val(attr);
                if (attrPos != -1) {
                    this.model.attributes.Pos12 = attrPos;
                } else {
                    this.model.attributes.Pos12 = position;
                }
                break;
        }
    },
    changePosition: function (oldPosition, position) {
        var oldModel = this.model.clone();

        this.changeAttributes(oldPosition, position, oldModel);
        this.changeAttributes(position, oldPosition, oldModel);
    },
    changePositionOld: function (oldPosition, position) {
        var oldModel = this.model;

        this.changeAttributes(oldPosition, position, oldModel);

        if (oldPosition < position) {
            /*
            2 = 4
            3 = 2
            4 = 3

            4 = 2
            2 = 3
            3 = 4
            */
            for (var i = oldPosition + 1; i <= position; i++) {
                this.changeAttributes(i, i - 1, oldModel);
            }
        } else {
            for (var i = position; i < oldPosition; i++) {
                this.changeAttributes(i, i + 1, oldModel);
            }
        }


    },
    SchemaSetting: function (position) {
        var self = this;

        var schemaSettingModel = new Entities_Empty.Model();
        schemaSettingModel.attributes.Title = resources.X100917;
        schemaSettingModel.attributes.Position = position;
        schemaSettingModel.attributes.FieldUsed = this.model.attributes.FieldUsed;
        schemaSettingModel.attributes.Button01 = resources.X100279;
        schemaSettingModel.attributes.Button02 = resources.X100280;
        schemaSettingModel.attributes.OldPosition = position;

        var schemaSetting = new View_SchemaSetting({ app: this.app, model: schemaSettingModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(schemaSetting, "dialog:btn01clicked", callback01);
            self.stopListening(schemaSetting, "dialog:btn02clicked", callback02);

            //self.model.set({ Name: pageSettingModel.attributes.Name });
            //self.model.set({ Status: pageSettingModel.attributes.Status });
            if (schemaSettingModel.attributes.Position != schemaSettingModel.attributes.OldPosition) {
                self.changePosition(schemaSettingModel.attributes.OldPosition, schemaSettingModel.attributes.Position);
            }
        }
        this.listenTo(schemaSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(schemaSetting, "dialog:btn01clicked", callback01);
            self.stopListening(schemaSetting, "dialog:btn02clicked", callback02);
        }
        this.listenTo(schemaSetting, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(schemaSetting);
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    },
    customSchemaModelEditItem_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.eventId);
        this.app.router.customEventEditItem(this.options.eventId, this.options.scroll, 3);
    }
}, Backbone.Radio.Requests);