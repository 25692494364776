var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customQRCodeEditItem_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customQRCodeEditItem">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div class="panel"> <form class="forms-sample template-demo"> <div id="customQRCodeEditItemInformation" class="e4usInformation" style="display:none"></div> <div id="customQRCodeEditItem_inputNameGroup" class="control-group customQRCodeEditItem_inputName"> <div class="form-group"> <label for="custom_customQRCodeEditItem_inputName">'+
((__t=( resources['X100359'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customQRCodeEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_Size">'+
((__t=( resources['X100360'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_input_Size" class="ul-slider slider-success" style="height: 20px"></div> </div> <div class="form-group"> <label for="customQRCodeEditItem_ColorPickerSliders_Fill">'+
((__t=( resources['X100357'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_ColorPickerSliders_Fill" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> <div class="form-group"> <label for="customQRCodeEditItem_ColorPickerSliders_Background">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_ColorPickerSliders_Background" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div>   <div id="customQRCodeEditItem_qrCodeContainer" style="text-align:center"> <div id="customQRCodeEditItem_container"></div> </div> <img id="customQRCodeEditItem_qrCodeLogo" crossorigin="anonymous" src="/images/Events4us.png" style="visibility:visible; height:0px"> <div class="form-group"> <label for="customQRCodeEditItem_select_Mode" style="padding-top:20px">'+
((__t=( resources['X100363'] ))==null?'':__t)+
'</label> <select class="form-control" id="customQRCodeEditItem_select_Mode"> <option value="0">'+
((__t=( resources['X100364'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100365'] ))==null?'':__t)+
'</option> <option value="2">'+
((__t=( resources['X100366'] ))==null?'':__t)+
'</option> <option value="3">'+
((__t=( resources['X100367'] ))==null?'':__t)+
'</option> <option value="4">'+
((__t=( resources['X100368'] ))==null?'':__t)+
'</option> </select> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_MSize">'+
((__t=( resources['X100369'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_input_MSize" class="ul-slider slider-success" style="height: 20px"> </div> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_MPosX">'+
((__t=( resources['X100370'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_input_MPosX" class="ul-slider slider-success" style="height: 20px"></div> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_MPosY">'+
((__t=( resources['X100371'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_input_MPosY" class="ul-slider slider-success" style="height: 20px"></div> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_Label">'+
((__t=( resources['X100372'] ))==null?'':__t)+
'</label> <input class="form-control" id="customQRCodeEditItem_input_Label" value="'+
((__t=( model.Label))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div>  <div class="form-group"> <label style="font-size:12px" class="form-group" for="customQRCodeEditItem_select_FontName">'+
((__t=( resources['X100373'] ))==null?'':__t)+
'</label> </div> <div class="form-group"> <input id="customQRCodeEditItem_select_FontName" class="form-control"> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="customQRCodeEditItem_dropDown_FontSize">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customQRCodeEditItem_dropDown_FontSize" class="form-control"></select> </div> </div> <div class="form-group"> <label for="customQRCodeEditItem_select_FontStyle">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customQRCodeEditItem_select_FontStyle" class="form-control"></select> </div>  <div class="form-group"> <label for="customQRCodeEditItem_ColorPickerSliders_FontColor">'+
((__t=( resources['X100374'] ))==null?'':__t)+
'</label> <div id="customQRCodeEditItem_ColorPickerSliders_FontColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> <div class="form-group" style="display:none"> <label for="customQRCodeEditItem_select_Logo">'+
((__t=( resources['X100375'] ))==null?'':__t)+
'</label> <select class="form-control" id="customQRCodeEditItem_select_Logo"></select> </div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customQRCodeEditItem_btnLogo">'+
((__t=( resources['X100956'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <img id="customQRCodeEditItem_img_Logo" style="width:320px;height:320px" src=""> </div> </div> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-4" id="customQRCodeEditItem_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-4" id="customQRCodeEditItem_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
