var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="top_empty"> <nav class="bottom-navbar"> <div class="container"> <ul class="nav page-navigation"> <li class="nav-item"> <a class="nav-link" href="index.html#index"> <img src="" alt="" class="icon fa fa-home"> <span class="menu-title">'+
((__t=( resources['X100138'] ))==null?'':__t)+
'</span> </a> </li> </ul> </div> </nav> </div>';
}
return __p;
};
