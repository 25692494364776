var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="footer_empty"> <footer id="footer"> <div class="top" style="min-height:220px"> <div class="container"> <div class="row"> <div class="col-sm-6 col-md-6"> <h5>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</h5> <ul class="links underline"> <li><a href="index.html#about">'+
((__t=( resources['X100636'] ))==null?'':__t)+
'</a></li>  <li><a href="index.html#policy">'+
((__t=( resources['X100637'] ))==null?'':__t)+
'</a></li>   <li><a href="index.html#termsOfTrade">'+
((__t=( resources['X100926'] ))==null?'':__t)+
'</a></li> <li><a href="index.html#contact">'+
((__t=( resources['X100975'] ))==null?'':__t)+
'</a></li> <li><a href="index.html#infoScreen">'+
((__t=( resources['X100977'] ))==null?'':__t)+
'</a></li> </ul> </div> <div class="col-sm-6 col-md-6"> <h5>'+
((__t=( resources['X100748'] ))==null?'':__t)+
'</h5> <ul class="links underline"> <li><a href="index.html#roadmap">'+
((__t=( resources['X100749'] ))==null?'':__t)+
'</a></li>  </ul> <br> <h5>'+
((__t=( resources['X101001'] ))==null?'':__t)+
'</h5> <ul class="links underline"></ul> <span class="">'+
((__t=( resources['X100995'] ))==null?'':__t)+
'</span> <br> <span class="">'+
((__t=( resources['X100996'] ))==null?'':__t)+
'</span> <br> <span class="">'+
((__t=( resources['X100997'] ))==null?'':__t)+
'</span> <br> <span class="">'+
((__t=( resources['X100998'] ))==null?'':__t)+
'</span> <br> <span class="">'+
((__t=( resources['X100999'] ))==null?'':__t)+
'</span> <br> <span class="">'+
((__t=( resources['X101000'] ))==null?'':__t)+
'</span> </div> </div> </div> </div> <br> <div class="bottom"> <div class="container"> <div class="row"> <div class="col-sm-6 col-md-6"> <span class="copy-text">'+
((__t=( resources['X100638'] ))==null?'':__t)+
' &copy; '+
((__t=( resources['X100639'] ))==null?'':__t)+
'</span> </div> <div class="col-sm-6 col-md-6"> <ul class="social-buttons colored-bg-on-hover round clearfix"> <li><a target="_blank" href="'+
((__t=( resources['X100640'] ))==null?'':__t)+
'"><i class="fa fa-facebook"></i></a></li>  </ul> </div> </div> </div> </div> <div class=""> <div class="container"> <div class="row"> <div class="col-sm-12 col-md-12"> <div style="text-align:center"> <img style="text-align:center" src="images/payment/payment_da_DK.png"> </div> </div> </div> </div> </div> </footer> </div>';
}
return __p;
};
