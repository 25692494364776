var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customQRCodeEditItem_empty"> <div id="customEventEditItem_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-qrcode" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100071'] ))==null?'':__t)+
' - '+
((__t=( model.Name ))==null?'':__t)+
'</h5> <div id="customQRCodeEditItemInformation"></div> <div class="control-group customQRCodeEditItem_inputName"> <div class="form-group"> <label for="custom_customQRCodeEditItem_inputName">'+
((__t=( resources['X100359'] ))==null?'':__t)+
'</label> <input class="form-control" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customQRCodeEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_Size">'+
((__t=( resources['X100360'] ))==null?'':__t)+
'</label> <input style="padding-top:0px;padding-bottom:0px" id="customQRCodeEditItem_input_Size" type="range" value="'+
((__t=( model.Size))==null?'':__t)+
'" min="150" max="600" step="50"> </div> <div class="form-group"> <label for="customQRCodeEditItem_ColorPickerSliders_Fill">'+
((__t=( resources['X100357'] ))==null?'':__t)+
'</label> <input class="form-control" id="customQRCodeEditItem_ColorPickerSliders_Fill"> </div> <div class="form-group"> <label for="customQRCodeEditItem_ColorPickerSliders_Background">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</label> <input class="form-control" id="customQRCodeEditItem_ColorPickerSliders_Background"> </div>   <div id="customQRCodeEditItem_qrCodeContainer" style="text-align:center"> <div id="customQRCodeEditItem_container"></div> <img id="customQRCodeEditItem_qrCodeLogo" crossorigin="anonymous" src="/images/Events4us.png" style="visibility:visible; height:0px"> </div> <div class="form-group"> <label for="customQRCodeEditItem_select_Mode" style="padding-top:20px">'+
((__t=( resources['X100363'] ))==null?'':__t)+
'</label> <select class="form-control" id="customQRCodeEditItem_select_Mode"> <option value="0">'+
((__t=( resources['X100364'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100365'] ))==null?'':__t)+
'</option> <option value="2">'+
((__t=( resources['X100366'] ))==null?'':__t)+
'</option> <option value="3">'+
((__t=( resources['X100367'] ))==null?'':__t)+
'</option> <option value="4">'+
((__t=( resources['X100368'] ))==null?'':__t)+
'</option> </select> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_MSize">'+
((__t=( resources['X100369'] ))==null?'':__t)+
'</label> <input style="padding-top:0px;padding-bottom:0px" id="customQRCodeEditItem_input_MSize" type="range" value="'+
((__t=( model.MSize))==null?'':__t)+
'" min="0" max="35" step="1"> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_MPosX">'+
((__t=( resources['X100370'] ))==null?'':__t)+
'</label> <input style="padding-top:0px;padding-bottom:0px" id="customQRCodeEditItem_input_MPosX" type="range" value="'+
((__t=( model.MPosX))==null?'':__t)+
'" min="0" max="100" step="1"> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_MPosY">'+
((__t=( resources['X100371'] ))==null?'':__t)+
'</label> <input style="padding-top:0px;padding-bottom:0px" id="customQRCodeEditItem_input_MPosY" type="range" value="'+
((__t=( model.MPosY))==null?'':__t)+
'" min="0" max="100" step="1"> </div> <div class="form-group"> <label for="customQRCodeEditItem_input_Label">'+
((__t=( resources['X100372'] ))==null?'':__t)+
'</label> <input class="form-control" id="customQRCodeEditItem_input_Label" value="'+
((__t=( model.Label))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div>  <div class="form-group"> <label for="customQRCodeEditItem_select_FontName">'+
((__t=( resources['X100373'] ))==null?'':__t)+
'</label> <select class="form-control" id="customQRCodeEditItem_select_FontName"> <option value="Arial,Arial,Helvetica,sans-serif">Arial,Arial,Helvetica,sans-serif</option> <option value="Arial Black,Arial Black,Gadget,sans-serif">Arial Black,Arial Black,Gadget,sans-serif</option> <option value="Comic Sans MS,Comic Sans MS,cursive">Comic Sans MS,Comic Sans MS,cursive</option> <option value="Courier New,Courier New,Courier,monospace">Courier New,Courier New,Courier,monospace</option> <option value="Georgia,Georgia,serif">Georgia,Georgia,serif</option> <option value="Impact,Charcoal,sans-serif">Impact,Charcoal,sans-serif</option> <option value="Lucida Console,Monaco,monospace">Lucida Console,Monaco,monospace</option> <option value="Lucida Sans Unicode,Lucida Grande,sans-serif">Lucida Sans Unicode,Lucida Grande,sans-serif</option> <option value="Palatino Linotype,Book Antiqua,Palatino,serif">Palatino Linotype,Book Antiqua,Palatino,serif</option> <option value="Tahoma,Geneva,sans-serif">Tahoma,Geneva,sans-serif</option> <option value="Times New Roman,Times,serif">Times New Roman,Times,serif</option> <option value="Trebuchet MS,Helvetica,sans-serif">Trebuchet MS,Helvetica,sans-serif</option> <option value="Verdana,Geneva,sans-serif">Verdana,Geneva,sans-serif</option> <option value="Gill Sans,Geneva,sans-serif">Gill Sans,Geneva,sans-serif</option> </select> </div> <div class="form-group"> <label for="customQRCodeEditItem_ColorPickerSliders_FontColor">'+
((__t=( resources['X100374'] ))==null?'':__t)+
'</label> <input class="form-control" id="customQRCodeEditItem_ColorPickerSliders_FontColor"> </div> <div class="form-group"> <label for="customQRCodeEditItem_select_Logo">'+
((__t=( resources['X100375'] ))==null?'':__t)+
'</label> <select class="form-control" id="customQRCodeEditItem_select_Logo"></select> </div> <div class="footer"> <button type="button" class="btn btn-primary" id="customQRCodeEditItem_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
