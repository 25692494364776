﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'update') {
            options.url = config.webApiUrl() + '/api/CustomSchemaSchema/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/CustomSchemaSchema/-1?AuthUserId=' + config.authUserId();
        }
        else if (options.method == 'deleteAll') {
            options.method = 'delete';
            options.url = config.webApiUrl() + '/api/CustomSchemaSchema/' + model.attributes.id + '?AuthUserId=' + config.authUserId() + '&EventId=' + model.attributes.EventId;
        }
        else if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/CustomSchemaSchema/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    /*save: function (attributes) {
        this.options.success = this.successOnSave;
        this.options.error = this.errorOnSave;

        Backbone.Model.prototype.save.call(this, attributes, options);
    },*/
    successOnSave: function (model, response) {
        model = response;
    },
    errorOnSave: function (model, response) {
        alert("FEJL");
    },
    validateNoBackbone: function (app) {
        this.errors = [];

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    }
});


export var Collection = Backbone.Collection.extend({
    model: Model,
    //queryParams: {
    //    sortKey: 'Position',
    //    order: "direction",
    //    directions: {
    //        "-1": "asc",
    //        "1": "desc"
    //    }
    //},
    //comparator: function (a, b) {
    //    return a.attributes.Position < b.attributes.Position ? -1
    //         : a.attributes.Position > b.attributes.Position ? 1
    //         : 0;
    //},
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
        this.changeSort("Position");
    },
    comparator: function (property) {
        return selectedStrategy.apply(model.get(property));
    },
    strategies: {
        Position: function (item) { return item.get("Position"); }
    },
    changeSort: function (sortProperty) {
        this.comparator = this.strategies[sortProperty];
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/CustomSchemaSchema/route01/-1?AuthUserId=' + config.authUserId();// + '&EventId=' + options.EventId;
        }
        return Backbone.sync(method, model, options);
    }
});







