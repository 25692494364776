var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogBox_empty"> <div class="modal fade" id="dialogBox_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" id="dialogBox_btnClose" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <p style="">'+
((__t=( model.Text ))==null?'':__t)+
'</p> </div> <div class="modal-footer"> <button type="button" id="dialogBox_btnOk" class="btn btn-primary btn-sm" data-dismiss="modal">'+
((__t=( model.Button ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
