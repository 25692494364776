﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    constructor: function () {
        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/event/-1?AuthUserId=' + config.authUserId() + '&Filename=' + this.attributes.Filename + '&WebSnapShot=' + options.webSnapShot;
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/event/-1?AuthUserId=' + config.authUserId();
        }

        var m = Backbone.sync(method, model, options);
        return m;
    },
});






