'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_pictureSingleListItem from './tpl/pictureSingleListItem.html';

export default Marionette.ItemView.extend({
    tagName: 'li',
    getTemplate: function () {
        return tpl_pictureSingleListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
    },
    events: {
    },
    onAttach: function () {
    },
    onRender: function () {
    }
});