﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customViewEventList from './tpl/customViewEventList.html';
import View_CustomViewEventListItem from './customViewEventListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customViewEventList',
    childViewContainer: '#customViewEventList',
    childView: View_CustomViewEventListItem,
    getTemplate: function () {
        return tpl_customViewEventList();
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.collection, 'change', this.render, this);
        this.listenTo(this.collection, 'delete', this.render, this);
        this.listenTo(this.collection, 'add', this.render, this);
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }//,
    //comparator: function(a, b) {
    //    if (a.attributes.id < b.attributes.id) {
    //        return 1;
    //    } else {
    //        return 0;
    //    }
    //}
});