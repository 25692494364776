var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customUser_empty"> <div class="content-wrapper" data-help="customUser"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200036'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div id="customUserInformation" class="e4usInformation" style="display:none"></div> <div class="row mt-3"> <div class="col-12 col-sm-6 col-md-6 col-xl-6" id="customUserInsert"></div>    <div class="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200116'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <label for="customUser_inputSearch">'+
((__t=( resources['X200146'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="control-group customUser_inputSearch"> <div class="form-group"> <input class="form-control form-control-sm" value="" id="customUser_inputSearch"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <label for="customUser_btnSearch"> </label> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customUser_btnSearch">'+
((__t=( resources['X200146'] ))==null?'':__t)+
'</button> </div> </div> <div class="" id="customUserList"></div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customUserPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
