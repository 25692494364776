﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_weather from './tpl/weather.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';


import FlatWeatherPlugin from '../../plugins/flatWeatherPlugin';

//import dummy from '../../../app/plugins';

import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_weather();
    },
    regions: {
        'modal': '#modal'
        
    },
    initialize: function () {
        self = this;
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.render, this);

        this.listenTo(userChannel, 'rightContent:setupCssStyle', function () {
            this.setupStyle();
        });
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'weather': '#weather'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        this.setupSetting();
    },
    setupStyle() {
        //var src = String.format(this.model.attributes.Data.Url, this.model.attributes.Data.VideoId, this.model.attributes.Data.VideoList);
        //this.ui.weather_weather.attr('src', src);
        //this.ui.weather_weather.attr('src', this.model.attributes.Data.VideoId);

        var self = this;

        var callback = function () {
            var font1_style = self.app.entities.collections.FontStyle.where({
                Value: self.model.attributes.Data.Font1_Style
            });

            font1_style[0].attributes.CssStyle.forEach(function (entry) {
                self.ui.weather.find('.place').css(entry.Css, entry.Value);
                self.ui.weather.find('.wiTemperature').css(entry.Css, entry.Value);
                self.ui.weather.find('.wiTemperatureDec').css(entry.Css, entry.Value);
                self.ui.weather.find('.astronomy').css(entry.Css, entry.Value);
                self.ui.weather.find('.temp').css(entry.Css, entry.Value);
                self.ui.weather.find('.atmosphere').css(entry.Css, entry.Value);
                self.ui.weather.find('.wiDay').css(entry.Css, entry.Value);
                self.ui.weather.find('.wiForecast').css(entry.Css, entry.Value);
                self.ui.weather.find('.wiText').css(entry.Css, entry.Value);
            });

            self.ui.weather.css('background-color', self.model.attributes.Data.BgColor);
            
            self.ui.weather.find('.wiIconGroup').css('color', self.model.attributes.Data.Font1_Color);
            
            self.ui.weather.find('.place').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.place').css('font-size', self.model.attributes.Data.Font1_Size_Header);
            self.ui.weather.find('.place').css('color', self.model.attributes.Data.Font1_Color);
            
            self.ui.weather.find('.wiTemperature').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.wiTemperature').css('font-size', self.model.attributes.Data.Font1_Size_Dec1);
            self.ui.weather.find('.wiTemperature').css('color', self.model.attributes.Data.Font1_Color);
            self.ui.weather.find('.wiTemperatureDec').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.wiTemperatureDec').css('font-size', self.model.attributes.Data.Font1_Size_Dec2);
            self.ui.weather.find('.wiTemperatureDec').css('color', self.model.attributes.Data.Font1_Color);
            
            self.ui.weather.find('.astronomy').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.astronomy').css('font-size', self.model.attributes.Data.Font1_Size_Detail);
            self.ui.weather.find('.astronomy').css('color', self.model.attributes.Data.Font1_Color);
            self.ui.weather.find('.temp').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.temp').css('font-size', self.model.attributes.Data.Font1_Size_Detail);
            self.ui.weather.find('.temp').css('color', self.model.attributes.Data.Font1_Color);
            self.ui.weather.find('.atmosphere').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.atmosphere').css('font-size', self.model.attributes.Data.Font1_Size_Detail);
            self.ui.weather.find('.atmosphere').css('color', self.model.attributes.Data.Font1_Color);
            
            self.ui.weather.find('.wiDay').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.wiDay').css('font-size', self.model.attributes.Data.Font1_Size_Forecast);
            self.ui.weather.find('.wiDay').css('color', self.model.attributes.Data.Font1_Color);
            self.ui.weather.find('.wiForecast').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.wiForecast').css('font-size', self.model.attributes.Data.Font1_Size_Forecast);
            self.ui.weather.find('.wiForecast').css('color', self.model.attributes.Data.Font1_Color);
            self.ui.weather.find('.wiText').css('font-family', self.model.attributes.Data.Font1);
            self.ui.weather.find('.wiText').css('font-size', self.model.attributes.Data.Font1_Size_Forecast);
            self.ui.weather.find('.wiText').css('color', self.model.attributes.Data.Font1_Color);
        }

        var ampm = [];

        this.app.entities.collections.DigitalClockHelper.models[0].attributes.Ampms.forEach(function (entry) {
            ampm.push(entry.Name);
        });


        this.ui.weather.empty();

        var example = this.ui.weather.flatWeatherPlugin({
            lat: this.model.attributes.Data.Lat,
            lon: this.model.attributes.Data.Lon,
            location: "Boston, MA", //city and region *required
            country: "USA",         //country *required
            //optional:
            api: "openweathermap", //default: openweathermap (openweathermap or yahoo)
            apikey: "926107de83b616070c5d0cc4500c9185",   //optional api key for openweather
            view: this.model.attributes.Data.Template,// "full", //default: full (partial, full, simple, today or forecast)
            displayCityNameOnly: true, //default: false (true/false) if you want to display only city name
            forecast: parseInt(this.model.attributes.Data.Forecast),//5, //default: 5 (0 -5) how many days you want forecast
            render: true, //default: true (true/false) if you want plugin to generate markup
            loadingAnimation: true, //default: true (true/false) if you want plugin to show loading animation
            //units : "metric" or "imperial" default: "auto"
            units: this.model.attributes.Data.Unit,
            callback: callback,
            enable24Hours: this.model.attributes.Data.Enable24Hours,
            ampm: ampm,
            header: this.model.attributes.Data.Header,
            lang: resources.X100003,
            weekdays: [resources.X101461, resources.X101462, resources.X101463, resources.X101464, resources.X101465, resources.X101466, resources.X101467]

        });
    },
    onDestroy: function () {
    },
}, Backbone.Radio.Requests);
