var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li style="display:inline"> <div id="customInfopageEditItemListItem_div_inner" style="margin:3px;border-width: 3px; border-color: black;border-style: none"> <div id="customInfopageEditItemListItem_div" class="menuDiv" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px;border-top-width: 1px;border-right-width: 1px;border-bottom-width: 1px;border-left-width: 1px"> <a id="customInfopageEditItemListItem_a" data_id="'+
((__t=( model.id ))==null?'':__t)+
'" data_orgid="'+
((__t=( model.orgId ))==null?'':__t)+
'" data-toggle="collapse" class="ancorli '+
((__t=( model.ClassnameA ))==null?'':__t)+
'" data-parent="#accordion" href="#" style="width:100%"> <div class="card" style="margin-left:0px; margin-right:0px; border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px"> <div class="card-body" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px;    border-bottom-width: 0px;    border-left-width: 0px"> <div class="row" style="padding: 0px; border: 0px"> <div class="col-sm-12" style="padding: 0px; border: 0px"> <div id="customInfopageEditItemListItem_template" style="width:98px; height:180px; padding: 0px; border: 0px"> <div style="transform: scale(0.094); transform-origin: 0% 0%"> <div id="grid_container"> </div> </div> </div> </div> <div class="col-sm-12" style="padding: 0px; border: 0px"> <div class="d-flex align-items-center py-3 border-bottom" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;padding-top:0px !important;padding-bottom:0px !important"> <div class="ml-3" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px"> <h6 class="mb-1" style="padding-top:8px">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <div style="position:absolute; top:4px; right:6px; padding: 0px;margin-top:4px; border: 0px"> <i id="customInfopageEditItemListItem_btnDelete" style="cursor: pointer; float: right; color: #6c7293 !important" class="far fa-lg fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> <i id="customInfopageEditItemListItem_btnSetting" style="cursor: pointer; float: right;margin-top:3px; color: #6c7293 !important" class="fa fa-lg fa-´lg fa-cog"></i> </div> </div> </div> </div> </div> </div> </div> </a> </div> </div> </li>';
}
return __p;
};
