﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventNavigator from './tpl/customEventNavigator.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_CustomFile from '../../views/customFile/customFile';
import View_CustomEventCounter from '../../views/customEventCounter/customEventCounter';
import View_CustomEventPush from '../../views/customEventPush/customEventPush';
import View_CustomEventLive from '../../views/customEventLive/customEventLive';
import View_CustomEventEditItem from '../../views/customEvent/customEventEditItem';
import View_CustomSchemaData from '../../views/customSchemaData/customSchemaData';
import View_CustomFormData from '../../views/customFormData/customFormData';
import View_CustomProductSheetData from '../../views/customProductSheetData/customProductSheetData';
import View_CustomEventTemplate from '../../views/customEventTemplate/customEventTemplate';
import View_CustomWebpageEditItem from '../../views/customWebpage/customWebpageEditItem';
import View_CustomQRCodeEditItem from '../../views/customQRCode/customQRCodeEditItem';
import View_CustomSchemaModelEditItem from '../../views/customSchemaModel/customSchemaModelEditItem';
import View_CustomFormModelEditItem from '../../views/customFormModel/customFormModelEditItem';
import View_CustomProductSheetModelEditItem from '../../views/customProductSheetModel/customProductSheetModelEditItem';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogPreviewWebpageZoom from '../dialogPreview/dialogPreviewWebpageZoom';
import View_DialogPreviewWebpageZoomIPad from '../dialogPreview/dialogPreviewWebpageZoomIPad';


export default Marionette.ItemView.extend({
    dialogRegion: '#dialogCustomEventNavigator_modal',
    eventModel: null,
    getTemplate: function () {
        return tpl_customEventNavigator({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    help: function () {
        this.style_switcher_ctrl_clicked(null);
        Help.help(this, true, 'customSetting');
    },
    regions: {
    },
    onBeforeShow: function () {
    },
    ui: {
        'customEventNavigator_SearchEvent_select': '#customEventNavigator_SearchEvent_select',
        'customeEventNavigator_tabItem1': '#customeEventNavigator_tabItem1',
        'customeEventNavigator_tabItem2': '#customeEventNavigator_tabItem2',
        'customeEventNavigator_tabItem3': '#customeEventNavigator_tabItem3',
        'customEventNavigator_QRCode1': '#customEventNavigator_QRCode1',
        'customEventNavigator_QRCode2': '#customEventNavigator_QRCode2',
        'customEventNavigator_QRCode3': '#customEventNavigator_QRCode3',
        'customEventNavigator_btnEvent': '#customEventNavigator_btnEvent',
        'customEventNavigator_btnEventIPad': '#customEventNavigator_btnEventIPad',
        'customEventNavigator_btnEventBrowser': '#customEventNavigator_btnEventBrowser',
        'customEventNavigator_btnEventSchemaScreen': '#customEventNavigator_btnEventSchemaScreen',
        'customEventNavigator_btnEventInfoScreen': '#customEventNavigator_btnEventInfoScreen',
        'customEventNavigator_btnEventPoster': '#customEventNavigator_btnEventPoster',
        'customEventNavigator_div_main': '#customEventNavigator_div_main',
        'customEventNavigator_btnEventCounter': '#customEventNavigator_btnEventCounter',
        'customEventNavigator_btnEventEventPush': '#customEventNavigator_btnEventEventPush',
        'customEventNavigator_btnEventSchemaData': '#customEventNavigator_btnEventSchemaData',
        'customEventNavigator_btnEventFormData': '#customEventNavigator_btnEventFormData',
        'customEventNavigator_btnEventEdit': '#customEventNavigator_btnEventEdit',
        'customEventNavigator_btnEventLive': '#customEventNavigator_btnEventLive',
        'customEventNavigator_btnEventWebpageEditItem': '#customEventNavigator_btnEventWebpageEditItem',
        'customEventNavigator_btnEventInfopageEditItem': '#customEventNavigator_btnEventInfopageEditItem',
        'customEventNavigator_btnEventQRCodeEditItem': '#customEventNavigator_btnEventQRCodeEditItem',
        'customEventNavigator_btnEventSchemaModelEditItem': '#customEventNavigator_btnEventSchemaModelEditItem',
        'customEventNavigator_btnEventFormModelEditItem': '#customEventNavigator_btnEventFormModelEditItem',
        'customEventNavigator_liEvent': '#customEventNavigator_liEvent',
        'customEventNavigator_liEventIPad': '#customEventNavigator_liEventIPad',
        'customEventNavigator_liEventBrowser': '#customEventNavigator_liEventBrowser',
        'customEventNavigator_liEventSchemaScreen': '#customEventNavigator_liEventSchemaScreen',
        'customEventNavigator_liEventInfoScreen': '#customEventNavigator_liEventInfoScreen',
        'customEventNavigator_liEventPoster': '#customEventNavigator_liEventPoster',
        'customEventNavigator_div_main': '#customEventNavigator_div_main',
        'customEventNavigator_liEventCounter': '#customEventNavigator_liEventCounter',
        'customEventNavigator_liEventEventPush': '#customEventNavigator_liEventEventPush',
        'customEventNavigator_liEventSchemaData': '#customEventNavigator_liEventSchemaData',
        'customEventNavigator_liEventFormData': '#customEventNavigator_liEventFormData',
        'customEventNavigator_liEventEdit': '#customEventNavigator_liEventEdit',
        'customEventNavigator_liEventLive': '#customEventNavigator_liEventLive',
        'customEventNavigator_liEventWebpageEditItem': '#customEventNavigator_liEventWebpageEditItem',
        'customEventNavigator_liEventInfopageEditItem': '#customEventNavigator_liEventInfopageEditItem',
        'customEventNavigator_liEventQRCodeEditItem': '#customEventNavigator_liEventQRCodeEditItem',
        'customEventNavigator_liEventSchemaModelEditItem': '#customEventNavigator_liEventSchemaModelEditItem',
        'customEventNavigator_liEventFormModelEditItem': '#customEventNavigator_liEventFormModelEditItem',
        'help': '#help'
    },
    events: {
        'change @ui.customEventNavigator_SearchEvent_select': 'selectEventChange',
        'click @ui.customeEventNavigator_tabItem1': 'tabChange1',
        'click @ui.customeEventNavigator_tabItem2': 'tabChange2',
        'click @ui.customeEventNavigator_tabItem3': 'tabChange3',
        'click @ui.customEventNavigator_btnEvent': 'btnEventClick',
        'click @ui.customEventNavigator_btnEventIPad': 'btnEventIPadClick',
        'click @ui.customEventNavigator_btnEventBrowser': 'btnEventBrowserClick',
        'click @ui.customEventNavigator_btnEventSchemaScreen': 'btnEventSchemaScreenClick',
        'click @ui.customEventNavigator_btnEventInfoScreen': 'btnEventInfoScreenClick',
        'click @ui.customEventNavigator_btnEventPoster': 'btnEventPosterClick',
        'click @ui.customEventNavigator_btnEventCounter': 'btnEventCounterClick',
        'click @ui.customEventNavigator_btnEventEventPush': 'btnEventEventPushClick',
        'click @ui.customEventNavigator_btnEventSchemaData': 'btnEventSchemaDataClick',
        'click @ui.customEventNavigator_btnEventFormData': 'btnEventFormDataClick',
        'click @ui.customEventNavigator_btnEventEdit': 'btnEventEditClick',
        'click @ui.customEventNavigator_btnEventLive': 'btnEventLiveClick',
        'click @ui.customEventNavigator_btnEventWebpageEditItem': 'btnEventWebpageEditItemClick',
        'click @ui.customEventNavigator_btnEventInfopageEditItem': 'btnEventInfopageEditItemClick',
        'click @ui.customEventNavigator_btnEventQRCodeEditItem': 'btnEventQRCodeEditItemClick',
        'click @ui.customEventNavigator_btnEventSchemaModelEditItem': 'btnEventSchemaModelEditItemClick',
        'click @ui.customEventNavigator_btnEventFormModelEditItem': 'btnEventFormModelEditItemClick',
        //'click #help': 'help',
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02"
    },
    onRender: function () {
        this.setupEvent();
        this.setupTab();

    },
    onAttach() {
        this.ui.customEventNavigator_SearchEvent_select.selectpicker();
        $('.dropdown.bootstrap-select').css('width', '100%');

        this.setupTab();
    },
    onDestroy() {
    },
    setupEvent: function () {
        var self = this;
        var renderGraph = false;
        this.collection.fullCollection.forEach(function (entry) {
            self.ui.customEventNavigator_SearchEvent_select.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
        });

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'NavigatorEvent' });
        if (customSetting[0].attributes.Value != '') {
            var id = parseInt(customSetting[0].attributes.Value);
            if ((this.app.entities.collections.customEvent.fullCollection.where({ id: id }).length > 0)) {
                self.ui.customEventNavigator_SearchEvent_select.val(customSetting[0].attributes.Value);
                self.selectEventChange(null);
            } else if (this.collection.fullCollection.length > 0) {
                self.ui.customEventNavigator_SearchEvent_select.val(this.collection.fullCollection.models[0].attributes.id);
                self.selectEventChange(null);
            } else {

            }
        }
    },
    setupEventItem: function (id) {
        this.eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: id });
        this.eventModel = this.eventModel[0];

        this.ui.customEventNavigator_QRCode1.prop('src', this.eventModel.attributes.QRCodeImageUrl);
        this.ui.customEventNavigator_QRCode2.prop('src', this.eventModel.attributes.QRCodeImageUrl);
        this.ui.customEventNavigator_QRCode3.prop('src', this.eventModel.attributes.QRCodeImageUrl);
    },
    setupLinkEnable: function () {
        if (this.eventModel.attributes.Webpage > 0) {
            this.ui.customEventNavigator_liEvent.removeClass('completed');
            this.ui.customEventNavigator_btnEvent.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_liEvent.addClass('completed');
            this.ui.customEventNavigator_btnEvent.prop('disabled', true);
        }

        if (this.eventModel.attributes.Webpage > 0) {
            this.ui.customEventNavigator_liEventBrowser.removeClass('completed');
            this.ui.customEventNavigator_btnEventBrowser.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_liEventBrowser.addClass('completed');
            this.ui.customEventNavigator_btnEventBrowser.prop('disabled', true);
        }

        if (this.eventModel.attributes.Webpage > 0 && this.eventModel.attributes.SchemaModel > 0) {
            this.ui.customEventNavigator_liEventSchemaScreen.removeClass('completed');
            this.ui.customEventNavigator_btnEventSchemaScreen.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_liEventSchemaScreen.addClass('completed');
            this.ui.customEventNavigator_btnEventSchemaScreen.prop('disabled', true);
        }

        if (this.eventModel.attributes.Webpage > 0 && this.eventModel.attributes.Infopage > 0) {
            this.ui.customEventNavigator_liEventInfoScreen.removeClass('completed');
            this.ui.customEventNavigator_btnEventInfoScreen.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_liEventInfoScreen.addClass('completed');
            this.ui.customEventNavigator_btnEventInfoScreen.prop('disabled', true);
        }

        var header = self.app.entities.collections.customWebpageItem.where({ Webpage: this.eventModel.attributes.Webpage, Tag: 4 });

        if (this.eventModel.attributes.Webpage > 0 && header.length > 0 && header[0].attributes.Body.length > 0) {
            this.ui.customEventNavigator_liEventPoster.removeClass('completed');
            this.ui.customEventNavigator_btnEventPoster.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_liEventPoster.addClass('completed');
            this.ui.customEventNavigator_btnEventPoster.prop('disabled', true);
        }

        if (this.eventModel.attributes.Active == 0) {
            this.ui.customEventNavigator_div_main.css('background-color', '#ff0000');
        } else {
            this.ui.customEventNavigator_div_main.css('background-color', '#ffffff');
        }

        if (this.eventModel.attributes.SchemaModel > 0) {
            this.ui.customEventNavigator_btnEventSchemaData.removeClass('completed');
            this.ui.customEventNavigator_btnEventSchemaData.prop('disabled', false);
            this.ui.customEventNavigator_btnEventSchemaModelEditItem.removeClass('completed');
            this.ui.customEventNavigator_btnEventSchemaModelEditItem.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_btnEventSchemaData.addClass('completed');
            this.ui.customEventNavigator_btnEventSchemaData.prop('disabled', true);
            this.ui.customEventNavigator_btnEventSchemaModelEditItem.addClass('completed');
            this.ui.customEventNavigator_btnEventSchemaModelEditItem.prop('disabled', true);
        }

        if (this.eventModel.attributes.FormModel > 0) {
            this.ui.customEventNavigator_btnEventFormData.removeClass('completed');
            this.ui.customEventNavigator_btnEventFormData.prop('disabled', false);
            this.ui.customEventNavigator_btnEventFormModelEditItem.removeClass('completed');
            this.ui.customEventNavigator_btnEventFormModelEditItem.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_btnEventFormData.addClass('completed');
            this.ui.customEventNavigator_btnEventFormData.prop('disabled', true);
            this.ui.customEventNavigator_btnEventFormModelEditItem.addClass('completed');
            this.ui.customEventNavigator_btnEventFormModelEditItem.prop('disabled', true);
        }

        if (this.eventModel.attributes.SchemaModel > 0) {
            this.ui.customEventNavigator_btnEventLive.removeClass('completed');
            this.ui.customEventNavigator_btnEventLive.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_btnEventLive.addClass('completed');
            this.ui.customEventNavigator_btnEventLive.prop('disabled', true);
        }

        if (this.eventModel.attributes.Webpage > 0) {
            this.ui.customEventNavigator_btnEventWebpageEditItem.removeClass('completed');
            this.ui.customEventNavigator_btnEventWebpageEditItem.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_btnEventWebpageEditItem.addClass('completed');
            this.ui.customEventNavigator_btnEventWebpageEditItem.prop('disabled', true);
        }

        if (this.eventModel.attributes.Infopage > 0) {
            this.ui.customEventNavigator_btnEventInfopageEditItem.removeClass('completed');
            this.ui.customEventNavigator_btnEventInfopageEditItem.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_btnEventInfopageEditItem.addClass('completed');
            this.ui.customEventNavigator_btnEventInfopageEditItem.prop('disabled', true);
        }

        var model = self.app.entities.collections.customQRCode.fullCollection.where({ id: this.eventModel.attributes.QRCode });
        if (this.eventModel.attributes.QRCode > 0 && model[0] != null && model[0].attributes.Name != resources.X100123) {
            this.ui.customEventNavigator_btnEventQRCodeEditItem.removeClass('completed');
            this.ui.customEventNavigator_btnEventQRCodeEditItem.prop('disabled', false);
        } else {
            this.ui.customEventNavigator_btnEventQRCodeEditItem.addClass('completed');
            this.ui.customEventNavigator_btnEventQRCodeEditItem.prop('disabled', true);
        }
    },
    selectEventChange: function (event) {
        var self = this;
        if (event) {
            event.preventDefault();
        }
        var value = self.ui.customEventNavigator_SearchEvent_select.val();

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'NavigatorEvent' });
        customSetting[0].attributes.Value = value;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupEventItem(parseInt(value));
                self.setupLinkEnable();
            }
        });

        //var eventName = this.ui.customEventCounter_selectEvent.find('option:selected').text();

    },
    setupTab: function () {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'NavigatorTab' });

        var element;

        switch (customSetting[0].attributes.Value) {
            case '1': element = this.ui.customeEventNavigator_tabItem1; break;
            case '2': element = this.ui.customeEventNavigator_tabItem2; break;
            case '3': element = this.ui.customeEventNavigator_tabItem3; break;
        }

        this.ui.customeEventNavigator_tabItem1.removeClass('show active');
        this.ui.customeEventNavigator_tabItem2.removeClass('show active');
        this.ui.customeEventNavigator_tabItem3.removeClass('show active');

        //element.addClass('show active');
        element.trigger("click");
    },
    tabChange1: function (event) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'NavigatorTab' });
        customSetting[0].attributes.Value = '1';

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    tabChange2: function (event) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'NavigatorTab' });
        customSetting[0].attributes.Value = '2';

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    tabChange3: function (event) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'NavigatorTab' });
        customSetting[0].attributes.Value = '3';

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    btnEventClickOld: function (event) {
        $('.modal-backdrop').remove();
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: true
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpage = new View_DialogPreviewWebpage({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpage = function (options) {
            this.stopListening(dialogPreviewWebpage, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);
        this.app.dialogRegion.show(dialogPreviewWebpage);
    },
    btnEventClick: function (event) {
        $('.modal-backdrop').remove();
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: true
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpage = new View_DialogPreviewWebpageZoom({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpage = function (options) {
            this.stopListening(dialogPreviewWebpage, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);

        this.app.dialogRegion.show(dialogPreviewWebpage);


    },

    btnEventIPadClick: function (event) {
        $('.modal-backdrop').remove();
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpageIPad = new View_DialogPreviewWebpageZoomIPad({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpageIPad = function (options) {
            this.stopListening(dialogPreviewWebpageIPad, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpageIPad, "dialog:okclicked", callback_dialogPreviewWebpageIPad);

        this.app.dialogRegion.show(dialogPreviewWebpageIPad);


    },
    btnEventBrowserClick: function (event) {
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);

        //window.open(webpageEvent);
    },
    btnEventSchemaScreenClick: function (event) {
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpageSchemaBigscreen = config.webApiUrl() + '/events/' + friendlyUrl;
        //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            updateHits: false
        };
        var url = [webpageSchemaBigscreen, $.param(params)].join('?');

        url += '#' + resources.X100419.toLowerCase();

        window.open(url);

        //window.open(webpageSchemaBigscreen);
    },
    btnEventInfoScreenClick: function (event) {
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpageInfo = config.webApiUrl() + '/events/' + friendlyUrl;
        //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            updateHits: false
        };
        var url = [webpageInfo, $.param(params)].join('?');

        url += '#' + resources.X100454.toLowerCase();

        window.open(url);

        //window.open(webpageInfo);
    },
    btnEventPosterClick: function (event) {
        var friendlyUrl = this.eventModel.attributes.id;
        if (this.eventModel.attributes.FriendlyUrl && this.eventModel.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.eventModel.attributes.FriendlyUrl;
        }

        var webpagePoster = config.webApiUrl() + '/events/' + friendlyUrl;
        //window.open(webpagePoster , '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            updateHits: false
        };
        var url = [webpagePoster, $.param(params)].join('?');

        url += '#' + resources.X100141.toLowerCase();

        window.open(url);

        //window.open(webpagePoster);
    },
    btnEventCounterClick: function (event) {
        /*var self = this;
        var customEventCounter = new View_CustomEventCounter({ collection: self.app.entities.collections.customEvent, app: self.app, designMode: true, event: this.eventModel.attributes.id });
        self.app.designRegion.show(customEventCounter);*/

        //var callback02 = function (options) {
        //self.app.router.navigate("#customEventCounter?id=" + this.eventModel.attributes.id);
        //self.app.router.customEventCounter(this.eventModel.attributes.id);

        //}
        //this.listenTo(this, "dialog:btn02clicked", callback02);

        //this.triggerMethod('onBtn01');
        //this.triggerMethod('dialog:btn01clicked', "msg");
        //this.trigger("dialog:close");

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customEventCounter?id=" + this.eventModel.attributes.id);
        self.app.router.customEventCounter(this.eventModel.attributes.id);

        //this.onBtn02();
        //$('.modal-backdrop').removeClass('show')
    },
    btnEventEventPushClick: function (event) {
        var self = this;

        //var customEventPush = new View_CustomEventPush({ collection: self.app.entities.collections.customEvent, app: self.app, designMode: true, event: this.eventModel.attributes.id });
        //self.app.designRegion.show(customEventPush);

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customEventPush?id=" + this.eventModel.attributes.id);
        self.app.router.customEventPush(this.eventModel.attributes.id);
    },
    btnEventSchemaDataClick: function (event) {
        var self = this;
        //var settingModel = { attributes: {} };
        //var customSchemaData = new View_CustomSchemaData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, designMode: true, event: this.eventModel.attributes.id });
        //self.app.designRegion.show(customSchemaData);

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customSchemaData?id=" + this.eventModel.attributes.id);
        self.app.router.customSchemaData(this.eventModel.attributes.id);
    },
    btnEventFormDataClick: function (event) {
        var self = this;
        //var settingModel = { attributes: {} };
        //var customFormData = new View_CustomFormData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, designMode: true, event: this.eventModel.attributes.id });
        //self.app.designRegion.show(customFormData);

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customFormData?id=" + this.eventModel.attributes.id);
        self.app.router.customFormData(this.eventModel.attributes.id);
    },
    btnEventEditClick: function (event) {
        var self = this;
        //var model = app.entities.collections.customEvent.fullCollection.where({ id: this.eventModel.attributes.id });
        //var customEventEditItem = new View_CustomEventEditItem({ model: model[0], app: self.app, designMode: true, event: this.eventModel.attributes.id });
        //self.app.designRegion.show(customEventEditItem);

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customEventEditItem?id=" + this.eventModel.attributes.id);
        self.app.router.customEventEditItem(this.eventModel.attributes.id);
    },
    btnEventLiveClick: function (event) {
        var self = this;
        /*self.app.entities.collections.customEventLive.fetch({
            success: function (mode, response) {
                var customEventLive = new View_CustomEventLive({ collection: self.app.entities.collections.customEventLive, app: self.app, designMode: true, event: self.eventModel.attributes.id });
                self.app.designRegion.show(customEventLive);
            },
            error: function (mode, response) {
            }
        });*/

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customEventLive?id=" + this.eventModel.attributes.id);
        self.app.router.customEventLive(this.eventModel.attributes.id);
    },
    btnEventWebpageEditItemClick: function (event) {
        //var self = this;
        //window.app.router.navigate("#customWebpageEditItem");
        //window.app.router.customWebpageEditItem(self.eventModel.attributes.Webpage);

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customWebpageEditItem?id=" + this.eventModel.attributes.Webpage);
        self.app.router.customWebpageEditItem(this.eventModel.attributes.Webpage);
    },
    btnEventInfopageEditItemClick: function (event) {
        var self = this;
        //window.app.router.navigate("#custoInfopageEditItem");
        //window.app.router.customInfopageEditItem(self.eventModel.attributes.Infopage);

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customInfopageEditItem?id=" + this.eventModel.attributes.Infopage);
        self.app.router.customInfopageEditItem(this.eventModel.attributes.Infopage);
    },
    btnEventQRCodeEditItemClick: function (event) {
        var self = this;
        /*var model = self.app.entities.collections.customQRCode.fullCollection.where({ id: this.eventModel.attributes.QRCode });
        /if (model[0] != null) {
            var customQRCodeEditItem = new View_CustomQRCodeEditItem({ collection: self.app.entities.collections.customQRCode, model: model[0], app: self.app, designMode: true, event: this.eventModel.attributes.id });
            self.app.designRegion.show(customQRCodeEditItem);
        }*/

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customQRCodeEditItem?id=" + this.eventModel.attributes.QRCode);
        self.app.router.customQRCodeEditItem(this.eventModel.attributes.QRCode);
    },
    btnEventSchemaModelEditItemClick: function (event) {
        var self = this;
        /*var model = self.app.entities.collections.customSchemaModel.fullCollection.where({ id: this.eventModel.attributes.SchemaModel });
        if (model[0] != null) {
            var customSchemaModelEditItem = new View_CustomSchemaModelEditItem({ model: model[0], app: self.app, designMode: true });
            self.app.designRegion.show(customSchemaModelEditItem);
        }*/

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customSchemaModelEditItem?id=" + this.eventModel.attributes.SchemaModel);
        self.app.router.customSchemaModelEditItem(this.eventModel.attributes.SchemaModel);
    },
    btnEventFormModelEditItemClick: function (event) {
        var self = this;
        /*var model = self.app.entities.collections.customFormModel.fullCollection.where({ id: this.eventModel.attributes.FormModel });
        if (model[0] != null) {
            var customFormModelEditItem = new View_CustomFormModelEditItem({ model: model[0], app: self.app, designMode: true });
            self.app.designRegion.show(customFormModelEditItem);
        }*/

        $('.modal-backdrop').remove();
        self.app.router.navigate("#customFormModelEditItem?id=" + this.eventModel.attributes.FormModel);
        self.app.router.customFormModelEditItem(this.eventModel.attributes.FormModel);
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    }
}, Backbone.Radio.Requests);
