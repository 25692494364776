﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';



import tpl_customProductSheetDataList from './tpl/customProductSheetDataList.html';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_customProductSheetDataTableEdit from './customProductSheetDataTableEdit'; 

import config from '../../../common/config';
import language from '../../../common/language';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customProductSheetDataList();
    },
    deleteRows: [],
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;

        //this.collection.on('change', this.render, this);
        this.listenTo(this.collection, 'change', this.render);

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customProductSheetDataList:save', function(callbackRouter) {
            self.save(callbackRouter);
        });

        this.listenTo(userChannel,'customProductSheetDataList:table_editModel', function(msg) {
            self.table_editModel(msg.tmpModel, msg.jumpToData, msg.jumpFirst, msg.ignoreEmptyContent);
        });
    },
    ui: {
        'customProductSheetDataList_btnSubmit': '#customProductSheetDataList_btnSubmit',
        'customProductSheetDataList_ProductSheetData': '#customProductSheetDataList_ProductSheetData',
    },
    events: {
        'click @ui.customProductSheetDataList_btnSubmit': 'customProductSheetDataList_btnSubmit',
    },
    regions: {
        customProductSheetTest: "#customProductSheetTest"
    },
    setHasChanged: function (enable) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customProductSheetData:setHasChanged', enable);
    },
    onRender: function () {
        this.addDataTable();

        this.setup_schemaData_length();

        this.setup();
    },
    onAttach(){
    },
    onDestroy(){
        this.table.clear();
    },
    setup: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableProdutSheetModel == false) {
            this.ui.customProductSheetDataList_btnSubmit.removeClass('btn-primary');
            this.ui.customProductSheetDataList_btnSubmit.addClass('btn-secondary');
            this.ui.customProductSheetDataList_btnSubmit.prop('disabled', true);
        } else if (this.settingModel.attributes.EventId > 0) {
            this.ui.customProductSheetDataList_btnSubmit.removeClass('btn-secondary');
            this.ui.customProductSheetDataList_btnSubmit.addClass('btn-primary');
            this.ui.customProductSheetDataList_btnSubmit.prop('disabled', false);
        }
        else {
            this.ui.customProductSheetDataList_btnSubmit.removeClass('btn-primary');
            this.ui.customProductSheetDataList_btnSubmit.addClass('btn-secondary');
            this.ui.customProductSheetDataList_btnSubmit.prop('disabled', true);
        }
    },
    set_schemaData_length: function (length) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'ProductSheetDataItemPerPage' });
        customSetting[0].attributes.Value = length;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    setup_schemaData_length: function (count) {
        var element = this.$el.find('#schemaData_length');

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'ProductSheetDataItemPerPage' });
        var schemaDataItemPerPage = customSetting[0].attributes.Value;
        
        var element = '#customProductSheetDataList_table_length [value="' + customSetting[0].attributes.Value + '"]';
        $(element).prop('selected', true);
    },
    table_deleteAll: function () {
        this.setHasChanged(true);
        var self = this;
        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid != -1) {
                self.deleteRows.push(d);
            }
        });

        this.table.clear().draw();
    },
    table_getFirstVisibleItem: function () {
        var first = 1;
        var getFirst = false;
        this.$el.find('#customProductSheetDataList_table tbody tr').each(function (i, tr) {
            if (!getFirst) {
                if ($(tr).attr('id') != undefined) {
                    first = parseInt($(tr).attr('id'));
                }
                getFirst = true;
            }
        });

        this.settingModel.attributes.TableFirstVisibleItem = first;
        return first;
    },
    table_editModel: function (tmpModel, jumpToData, jumpFirst, ignoreEmptyContent) {
        this.setHasChanged(true);
        //Import sometimes insert a empty element
        var totalContentLength = tmpModel.attributes.ProductName.length + 
            tmpModel.attributes.Feature.length + 
            tmpModel.attributes.Description.length + 
            tmpModel.attributes.Value01.length + tmpModel.attributes.Value02.length + tmpModel.attributes.Value03.length +
            tmpModel.attributes.Value04.length + tmpModel.attributes.Value05.length + tmpModel.attributes.Value06.length +
            tmpModel.attributes.Value07.length + tmpModel.attributes.Value08.length + tmpModel.attributes.Value09.length +
            tmpModel.attributes.Value10.length + tmpModel.attributes.Value11.length + tmpModel.attributes.Value12.length + 
            tmpModel.attributes.Value13.length + tmpModel.attributes.Value14.length + tmpModel.attributes.Value15.length +
            tmpModel.attributes.Value16.length + tmpModel.attributes.Value17.length + tmpModel.attributes.Value18.length +
            tmpModel.attributes.Value19.length + tmpModel.attributes.Value20.length + tmpModel.attributes.Value21.length + 
            tmpModel.attributes.Value22.length + tmpModel.attributes.Value23.length + tmpModel.attributes.Value24.length +
            tmpModel.attributes.Value25.length + tmpModel.attributes.Value26.length + tmpModel.attributes.Value27.length +
            tmpModel.attributes.Value28.length + tmpModel.attributes.Value29.length + tmpModel.attributes.Value30.length;

        //If user insert a empty item
        if (ignoreEmptyContent) totalContentLength = 1;

        if (totalContentLength > 0) {

            
            this.table.rows().every(function () {
                var data = this.data();
                var row = this.row();
                if (data.CommonId == tmpModel.attributes.CommonId) {
                    data.id = tmpModel.attributes.id;
                    data.orgid = tmpModel.attributes.orgid;
                    data.ProductName = tmpModel.attributes.ProductName;
                    data.Feature = tmpModel.attributes.Feature;
                    data.Description = tmpModel.attributes.Description;
                    data.Value01= tmpModel.attributes.Value01;
                    data.Value02= tmpModel.attributes.Value02;
                    data.Value03= tmpModel.attributes.Value03;
                    data.Value04= tmpModel.attributes.Value04;
                    data.Value05= tmpModel.attributes.Value05;
                    data.Value06= tmpModel.attributes.Value06;
                    data.Value07= tmpModel.attributes.Value07;
                    data.Value08= tmpModel.attributes.Value08;
                    data.Value09= tmpModel.attributes.Value09;
                    data.Value10= tmpModel.attributes.Value10;
                    data.Value11= tmpModel.attributes.Value11;
                    data.Value12= tmpModel.attributes.Value12;
                    data.Value13= tmpModel.attributes.Value13;
                    data.Value14= tmpModel.attributes.Value14;
                    data.Value15= tmpModel.attributes.Value15;
                    data.Value16= tmpModel.attributes.Value16;
                    data.Value17= tmpModel.attributes.Value17;
                    data.Value18= tmpModel.attributes.Value18;
                    data.Value19= tmpModel.attributes.Value19;
                    data.Value20= tmpModel.attributes.Value20;
                    data.Value21= tmpModel.attributes.Value21;
                    data.Value22= tmpModel.attributes.Value22;
                    data.Value23= tmpModel.attributes.Value23;
                    data.Value24= tmpModel.attributes.Value24;
                    data.Value25= tmpModel.attributes.Value25;
                    data.Value26= tmpModel.attributes.Value26;
                    data.Value27= tmpModel.attributes.Value27;
                    data.Value28= tmpModel.attributes.Value28;
                    data.Value29= tmpModel.attributes.Value29;
                    data.Value30= tmpModel.attributes.Value30;

                    // invalidate the data DataTables has cached for this row
                    this.invalidate(); 
                    this.draw();
                }
            });

            this.table.rows().every(function (r) {
                this.child.hide();
                $('.ui-sortable-handle').removeClass('shown');
                $('.ui-sortable').removeClass('shown');
                $('.odd').removeClass('shown');
                $('.even').removeClass('shown');
            });
        }
    },
    table_addModel: function (tmpModel, jumpToData, jumpFirst, ignoreEmptyContent) {
        this.setHasChanged(true);
        //Import sometimes insert a empty element
        var totalContentLength = tmpModel.attributes.ProductName.length + 
            tmpModel.attributes.Feature.length + 
            tmpModel.attributes.Description.length + 
            tmpModel.attributes.Value01.length + tmpModel.attributes.Value02.length + tmpModel.attributes.Value03.length +
            tmpModel.attributes.Value04.length + tmpModel.attributes.Value05.length + tmpModel.attributes.Value06.length +
            tmpModel.attributes.Value07.length + tmpModel.attributes.Value08.length + tmpModel.attributes.Value09.length +
            tmpModel.attributes.Value10.length + tmpModel.attributes.Value11.length + tmpModel.attributes.Value12.length + 
            tmpModel.attributes.Value13.length + tmpModel.attributes.Value14.length + tmpModel.attributes.Value15.length +
            tmpModel.attributes.Value16.length + tmpModel.attributes.Value17.length + tmpModel.attributes.Value18.length +
            tmpModel.attributes.Value19.length + tmpModel.attributes.Value20.length + tmpModel.attributes.Value21.length + 
            tmpModel.attributes.Value22.length + tmpModel.attributes.Value23.length + tmpModel.attributes.Value24.length +
            tmpModel.attributes.Value25.length + tmpModel.attributes.Value26.length + tmpModel.attributes.Value27.length +
            tmpModel.attributes.Value28.length + tmpModel.attributes.Value29.length + tmpModel.attributes.Value30.length;

        //If user insert a empty item
        if (ignoreEmptyContent) totalContentLength = 1;

        if (totalContentLength > 0) {
            //Romove all item with a bigger or equal id
            this.table.rows().every(function () {
                var d = this.data();
                if (d.id >= tmpModel.attributes.id) {
                    d.id++; // update data source for the row
                    this.invalidate(); // invalidate the data DataTables has cached for this row
                }
            });

            this.table.rows.add([{
                id: tmpModel.attributes.id,
                AuthUserId : config.authUserId(),
                Position: tmpModel.attributes.id,
                EventId: tmpModel.attributes.EventId,
                CommonId: tmpModel.attributes.CommonId,
                ProductName: tmpModel.attributes.ProductName,
                Feature: tmpModel.attributes.Feature,
                Description: tmpModel.attributes.Description,
                Value01: tmpModel.attributes.Value01,
                Value02: tmpModel.attributes.Value02,
                Value03: tmpModel.attributes.Value03,
                Value04: tmpModel.attributes.Value04,
                Value05: tmpModel.attributes.Value05,
                Value06: tmpModel.attributes.Value06,
                Value07: tmpModel.attributes.Value07,
                Value08: tmpModel.attributes.Value08,
                Value09: tmpModel.attributes.Value09,
                Value10: tmpModel.attributes.Value10,
                Value11: tmpModel.attributes.Value11,
                Value12: tmpModel.attributes.Value12,
                Value13: tmpModel.attributes.Value13,
                Value14: tmpModel.attributes.Value14,
                Value15: tmpModel.attributes.Value15,
                Value16: tmpModel.attributes.Value16,
                Value17: tmpModel.attributes.Value17,
                Value18: tmpModel.attributes.Value18,
                Value19: tmpModel.attributes.Value19,
                Value20: tmpModel.attributes.Value20,
                Value21: tmpModel.attributes.Value21,
                Value22: tmpModel.attributes.Value22,
                Value23: tmpModel.attributes.Value23,
                Value24: tmpModel.attributes.Value24,
                Value25: tmpModel.attributes.Value25,
                Value26: tmpModel.attributes.Value26,
                Value27: tmpModel.attributes.Value27,
                Value28: tmpModel.attributes.Value28,
                Value29: tmpModel.attributes.Value29,
                Value30: tmpModel.attributes.Value30,
                orgid: -1,
            }]).draw();
        }

        this.table.rows().every(function (r) {
            this.child.hide();
            $('.ui-sortable-handle').removeClass('shown');
            $('.ui-sortable').removeClass('shown');
            $('.odd').removeClass('shown');
            $('.even').removeClass('shown');
        });

        if (jumpToData) {
            this.table.page.jumpToData(jumpFirst, 0);
            //this.jumpToData(jumpFirst, 0);
        }
    },
    jumpToData : function (data, column) {
        var pos = this.table.column(column, {order:'current'}).data().indexOf( data );

        if ( pos >= 0 ) {
            var page = Math.floor( pos / this.table.page.info().length );
            this.table.page( page ).draw( false );
        }
    },
    table_rearrange: function () {
        //rearrange
        var prevdata = null;
        this.table.rows().every(function () {
            var data = this.data();

            if (prevdata != null) {
                if (data.id != (prevdata.id + 1)) {
                    data.id = prevdata.id + 1;
                    this.invalidate(); // invalidate the data DataTables has cached for this row
                }
            }
            else {
                if (data.id != 1) {
                    data.id = 1;
                    this.invalidate();
                }
            }

            prevdata = data;
        });
    },
    addDataTable: function () {
        var self = this;

        var formCounter = '1';
        var sElement = '';

        sElement += '<thead>';
        sElement += '</thead>';

        sElement += '<tfoot>';
        sElement += '<tr>';
        sElement += '</tr>';
        sElement += '</tfoot>';


        this.$el.find('#customProductSheetDataList_ProductSheetData').empty();
        this.$el.find('#customProductSheetDataList_ProductSheetData').append('<div class="table-responsive"><table id="customProductSheetDataList_table" class="table table-striped"  cellspacing="0"></table></div>');

        var self = this;

        var c = this.collection.toJSON();
        for (var i = 0; i < c.length; i++) {
            c[i].orgid = c[i].id;
            c[i].id = c[i].Position;
        }

        var columns = this.getColumns();
        var lng = language.getLanguageDataTables();

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'ProductSheetDataItemPerPage' });
        var ProductSheetDataItemPerPage = customSetting[0].attributes.Value;

        var table = this.$el.find('#customProductSheetDataList_table').DataTable({
            "language": lng,
            data: c,
            "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //Initialize row id for every row
                nRow.setAttribute('id', aData.id);
            },
            "columns": columns,
            "bInfo": false,
            "iDisplayLength": parseInt(ProductSheetDataItemPerPage)
        });

        this.table = table;
        this.$el.find('#customProductSheetDataList_table').dataTable().rowReordering({});

        var self = this;

        //Expand row for edit
        this.$el.find('#customProductSheetDataList_table tbody').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);

            self.tmpnRow = $(this).parents('tr');
            self.tmpTr = tr;

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                self.table.rows().every(function (r) {
                    this.child.hide();
                    $('.ui-sortable-handle').removeClass('shown');
                    $('.ui-sortable').removeClass('shown');
                    $('.odd').removeClass('shown');
                    $('.even').removeClass('shown');
                });

                // Open this row
                row.child(self.format(row.data())).show();
                tr.addClass('shown');

                var id='#div_' + row.data().id;
                $(id).append('<div id="' + 'someRegionDiv' + row.data().id + '"></div>');
                self.regionManager.addRegion("someRegion" + row.data().id, '#someRegionDiv' + row.data().id);
                
                var settingModel = { attributes : {}};
                settingModel.attributes.EventId = row.data().EventId;
                settingModel.attributes.CommonId = row.data().CommonId;

                var view_customProductSheetDataTableEdit = new View_customProductSheetDataTableEdit({ schemaDataModel : row.data(), collection: self.collection, productSheetModel: self.options.productSheetModel, settingModel: settingModel, app : self.options.app });
                self.regionManager.get("someRegion" + row.data().id).show(view_customProductSheetDataTableEdit);
            }
        });

        var self = this;

        this.$el.find('#customProductSheetDataList_table tbody').on('click', 'td.details-delete', function () {
            self.setHasChanged(true);

            var infoBefore = table.page.info();
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var data = row.data();
            var infoAfter = table.page.info();

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X101112;
            dialog2ButtonModel.attributes.Text = resources.X100266;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                if (data.orgid != -1) {
                    self.deleteRows.push(data);
                }
                row.remove().draw();
                self.table_rearrange();

                if (infoAfter.recordsTotal == infoBefore.start) {
                    self.table.page.jumpToData(infoBefore.start, 0);
                }
                else {
                    self.table.page.jumpToData(infoBefore.start + 1, 0);
                }
            }
            
            self.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            self.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(dialog2Button);
        });

        this.$el.find('#customProductSheetDataList_table_paginate').parent().removeClass('col-sm-7').addClass('col-sm-12');
        this.$el.find('#customProductSheetDataList_table_paginate').css('text-align', 'center');
        this.$el.find('.form-control.input-sm').css('width', '150px');

        $('#customProductSheetDataList_table').on('length.dt', function ( e, settings, len ) {
            self.set_schemaData_length(len);
        } ); 
    },
    format: function format(d) {
        var id='div_' + d.id;
        return '<div id="' + id + '"></div>';
    },
    getColumns: function () {
        var array = [];

        var tmp = {
            "className": 'details-control myFont',
            //    "orderable": false,
            //    'sortable' : false,
            "data": null,
            "defaultContent": '',
            "title": ''
        };

        tmp = { "data": "id", "title": "#", "visible": true };
        array.push(tmp);

        tmp = { "data": "id", "title": "orgid", "visible": false };
        array.push(tmp);

        var data = '';
        var title = '';

        for (var i = 0; i < this.options.productSheetModel.attributes.FieldUsed + 3; i++) {
            switch (i) {
                case 0: title = resources.X101123; data = 'ProductName'; break;
                case 1: title = resources.X101124; data = 'Feature'; break;
                case 2: title = resources.X101125; data = 'Description'; break;
                case 3: title = this.options.productSheetModel.attributes.Field01; data = 'Value01'; break;
                case 4: title = this.options.productSheetModel.attributes.Field02; data = 'Value02'; break;
                case 5: title = this.options.productSheetModel.attributes.Field03; data = 'Value03'; break;
                case 6: title = this.options.productSheetModel.attributes.Field04; data = 'Value04'; break;
                case 7: title = this.options.productSheetModel.attributes.Field05; data = 'Value05'; break;
                case 8: title = this.options.productSheetModel.attributes.Field06; data = 'Value06'; break;
                case 9: title = this.options.productSheetModel.attributes.Field07; data = 'Value07'; break;
                case 10: title = this.options.productSheetModel.attributes.Field08; data = 'Value08'; break;
                case 11: title = this.options.productSheetModel.attributes.Field09; data = 'Value09'; break;
                case 12: title = this.options.productSheetModel.attributes.Field10; data = 'Value10'; break;
                case 13: title = this.options.productSheetModel.attributes.Field11; data = 'Value11'; break;
                case 14: title = this.options.productSheetModel.attributes.Field12; data = 'Value12'; break;
                case 15: title = this.options.productSheetModel.attributes.Field13; data = 'Value13'; break;
                case 16: title = this.options.productSheetModel.attributes.Field14; data = 'Value14'; break;
                case 17: title = this.options.productSheetModel.attributes.Field15; data = 'Value15'; break;
                case 18: title = this.options.productSheetModel.attributes.Field16; data = 'Value16'; break;
                case 19: title = this.options.productSheetModel.attributes.Field17; data = 'Value17'; break;
                case 20: title = this.options.productSheetModel.attributes.Field18; data = 'Value18'; break;
                case 21: title = this.options.productSheetModel.attributes.Field19; data = 'Value19'; break;
                case 22: title = this.options.productSheetModel.attributes.Field20; data = 'Value20'; break;
                case 23: title = this.options.productSheetModel.attributes.Field21; data = 'Value21'; break;
                case 24: title = this.options.productSheetModel.attributes.Field22; data = 'Value22'; break;
                case 25: title = this.options.productSheetModel.attributes.Field23; data = 'Value23'; break;
                case 26: title = this.options.productSheetModel.attributes.Field24; data = 'Value24'; break;
                case 27: title = this.options.productSheetModel.attributes.Field25; data = 'Value25'; break;
                case 28: title = this.options.productSheetModel.attributes.Field26; data = 'Value26'; break;
                case 29: title = this.options.productSheetModel.attributes.Field27; data = 'Value27'; break;
                case 30: title = this.options.productSheetModel.attributes.Field28; data = 'Value28'; break;
                case 31: title = this.options.productSheetModel.attributes.Field29; data = 'Value29'; break;
                case 32: title = this.options.productSheetModel.attributes.Field30; data = 'Value30'; break;
            }

            tmp = { "data": data, "title": title, "className": 'myFont', };
            array.push(tmp);
        }

        tmp = {
            "className": 'details-delete',
            "orderable": false,
            "data": null,
            "defaultContent": '',
            "title": '--'
        };
        array.push(tmp);

        var tmp = {
            "className": 'details-control',
            //    "orderable": false,
            "data": null,
            "defaultContent": '',
            "title": '--'
        };
        array.push(tmp);

        return array;
    },
    customProductSheetDataList_btnSubmit: function (event) {
        event.preventDefault();

        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X101112;
        dialogWaitingModel.attributes.Text = resources.X100263;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);


        $.when(this.list_deleteAll(), this.list_insertAll(), this.list_editAll()).done(function (return_delete, return_insert, return_edit) {
            // the code here will be executed when all four ajax requests resolve.
            // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
            // status, and jqXHR object for each of the four ajax calls respectively.

            //this.app.entities.views.customProductSheetData.table_readDataCollection();
            dialogWaiting.triggerMethod('dialog:close');
            self.triggerMethod('table_readDataCollection');
            self.setHasChanged(false);
        });
    },
    save: function (callbackRouter) {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X101112;
        dialogWaitingModel.attributes.Text = resources.X100263;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        $.when(this.list_deleteAll(), this.list_insertAll(), this.list_editAll()).done(function (return_delete, return_insert, return_edit) {
            // the code here will be executed when all four ajax requests resolve.
            // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
            // status, and jqXHR object for each of the four ajax calls respectively.

            dialogWaiting.triggerMethod('dialog:close');
            self.setHasChanged(false);
            callbackRouter();
        });
    },
    list_deleteAll: function () {
        if (this.deleteRows.length > 0) {
            for (var i = 0; i < this.deleteRows.length; i++) {
                this.deleteRows[i].id = this.deleteRows[i].orgid;
            }

            var data = {
                "data": this.deleteRows
            };

            var url = config.webApiUrl() + '/api/CustomProductSheetData/route01/-1?AuthUserId=' + config.authUserId() + '&Dummy=1';
            var self = this;

            this.deleteRows = [];

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_insertAll: function () {
        var insertRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid == -1) {
                insertRows.push(d);
            }
        });

        if (insertRows.length > 0) {
            for (var i = 0; i < insertRows.length; i++) {
                insertRows[i].Position = insertRows[i].id;
                //insertRows[i].id = null;
            }

            var data = {
                "data": insertRows
            };

            var url = config.webApiUrl() + '/api/CustomProductSheetData/route02/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_editAll: function () {
        var editRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid != -1) {
                editRows.push(d);
            }
        });

        if (editRows.length > 0) {
            for (var i = 0; i < editRows.length; i++) {
                editRows[i].Position = editRows[i].id;
                editRows[i].id = editRows[i].orgid;
            }

            var data = {
                "data": editRows
            };

            var url = config.webApiUrl() + '/api/CustomProductSheetData/route03/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1&Dummy2=2';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    }
}, Backbone.Radio.Requests);