﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import config from '../../../common/config';

import tpl_schemaScreen from './tpl/schemaScreen.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';

var schema = require('../../helper/schemaScreen');

import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_schemaScreen();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        //this.listenTo(this.model, 'change', this.render, this);

        /*window.luxaforboard = {};
        window.luxaforboard.event = {};
        window.luxaforboard.event.schema = {};
        window.luxaforboard.event.schemaBigscreen = {};*/
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'schemaScreen_schemaScreen': '#schemaScreen_schemaScreen',
        'schemaScreen_margin': '#schemaScreen_margin',
        'schemaData': '#schemaData'
    },
    events: {
    },
    onRender: function () {
        //this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();

        var self = this;
        var callback = function () {
            self.refreshSchema();
        }

        //this.ui.marquee.css('font-family', this.model.attributes.Data.Font);
        //this.ui.marquee.css('font-size', this.model.attributes.Data.Font_Size);

        //if (window.luxaforboard.event.schema.schemaModelId > 0) {
            this.tables = [];
        schema.schema_init(this.options.customEvent.attributes.id,//window.luxaforboard.event.eventId,
                config.webApiUrl(),
                this.options.customEvent.attributes.SchemaModel,//window.luxaforboard.event.schema.schemaModelId,
                this.ui.schemaData,
                false,
                'da-DK', this.tables,
                this.model,
                this.app,
                callback);
        //}

        
    },
    onAttach() {
        this.setupSetting();
    },
    refreshSchema: function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);

            //if (window.luxaforboard.event.schema.schemaModelId > 0) {
                $('#ToolTables_schemaData0_0').click();
            //}
        }

        self.timeoutId = setTimeout(this.refreshSchema, 15 * 60000);
    },
    onDestroy: function () {
        $('*[data-css="schema_css"]').remove();

        this.tables.forEach(function (entry) {
            entry.clear();
        });

        clearTimeout(this.timeoutId);
    },
}, Backbone.Radio.Requests);
