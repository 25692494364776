﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogWaiting from './tpl/dialogWaiting2.html';

export default Marionette.ItemView.extend({
    dialogRegion2: '#dialogWaiting_modal',
    getTemplate: function () {
        return tpl_dialogWaiting({ model: this.model.toJSON() });
    },
    events: {
        "OnDialogOkClicked": "OnDialogOkClicked",
        "click #dialogWaiting_btnOk": "onOK",
        "click #dialogBox_btnClose": "onClose"
    },
    OnDialogOkClicked: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    },
    onOK: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    },
    onClose: function () {
        this.triggerMethod('dialog:closeclicked', "msg")
        this.trigger("dialog:close");
    },
    initialize: function () {
    },
    ui: {
        waiting_spin: '#dialogWaiting_spin'
    }
    
});
