﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';

import * as Entities_Memory_Position from '../../entities/memoryPosition';
import * as Entities_Empty from '../../../lib/entities/empty';

import tpl_customEventTemplateListItem from './tpl/customEventTemplateListItem.html';
import config from '../../../common/config';

import View_OwlCarouselList from './owlCarouselList';
import helper_createEvent from '../../helper/createEvent';

import View_Render from '../render/render';


export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customEventTemplateListItem({ model: this.model.toJSON() });
    },
    regions: {
        owlCarouselList: "#owlCarouselList",
    },
    attributes: function () {
        return {
            class: 'col-lg-6 grid-margin card',
        }
    },
    ui: {
        'customEventTemplateListItem_btnCreateEvent': '#customEventTemplateListItem_btnCreateEvent',
        'customQRCodeInsert_qrCodeContainer': '#customQRCodeInsert_qrCodeContainer',
        'customQRCodeInsert_container': '#customQRCodeInsert_container',
        'customQRCodeInsert_qrCodeLogo': '#customQRCodeInsert_qrCodeLogo',
        'customEventTemplateListItem_webpageEvent': '#customEventTemplateListItem_webpageEvent',
        'customEventTemplateListItem_webpageInfo': '#customEventTemplateListItem_webpageInfo',
        'customEventTemplateListItem_webpagePoster': '#customEventTemplateListItem_webpagePoster'
    },
    events: {
        'click @ui.customEventTemplateListItem_btnCreateEvent': 'btnCreateEvent'
    },
    initialize: function () {
        this.app = this.options.app;

        /*if (this.model.attributes.QRCodeImageUrl == null) {
            this.model.attributes.QRCodeImageUrl = '../../../../images/defaultQRCode.jpg';
        }
        if (this.model.attributes.WebpageImageUrl == null) {
            this.model.attributes.WebpageImageUrl = '../../../../images/imagePrintUnderConstruction6.png';
        }*/
    },
    onRender: function () {
        //this.setupUrl();
    },
    onBeforeShow: function () {
        var data = JSON.parse(this.model.attributes.Data)[0];
        var collection = new Entities_Memory_Position.Collection(data.Collection);

        this.owlCarouselList.show(new View_OwlCarouselList({ collection: collection, app: this.app }));
    },
    setupUrl: function () {
        var friendlyUrl = this.model.attributes.Event;
        /*if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }*/

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;
        var webpageInfo = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100454.toLowerCase();
        var webpagePoster = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100141.toLowerCase();

        this.ui.customEventTemplateListItem_webpageEvent.attr('href', webpageEvent);
        this.ui.customEventTemplateListItem_webpageInfo.attr('href', webpageInfo);
        this.ui.customEventTemplateListItem_webpagePoster.attr('href', webpagePoster);
    },
    refreshEntities: function (id, callback) {
        var self = this;
        $.when(
            self.app.entities.collections.customEvent.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customQRCode.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customSchemaModel.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customSchemaSchema.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customFormModel.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customWebpageItem.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customModule.fetch({
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            self.app.entities.collections.customWebpage.fetch({
                DisplayType: 0,
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            })
        ).done(function () {
            self.app.entities.collections.customEvent.fullCollection.sort();
            callback(id);
        });
    },
    btnCreateEvent: function (event) {
        var self = this;

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customEventTemplate:getName');
        var name = self.app.customEventTemplate_inputName;

        var callbackCreateEventDone = function (destId) {
            var tmpModel = new Entities_Empty.Model();

            var tmpModel = new self.app.entities.collections.customEventTemplate.fullCollection.model();
            tmpModel.attributes.AuthUserId = config.authUserId();

            self.app.entities.collections.customEventTemplate.fullCollection.create(tmpModel, {
                wait: true,
                sourceId: self.model.attributes.Event,
                destId: destId,
                success: function (response) {
                    callbackCopyTemplateDone(destId);
                }
            });
        }

        var callbackCopyTemplateDone = function (id) {

            self.refreshEntities(id, callback);
        }

        
        

        if (name != '') {

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100070;
            dialogWaitingModel.attributes.Text = resources.X100305;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

            var callback = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);

                var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: id })
                var render = new View_Render({ collection: customEvent, app: self.app });
                self.app.renderRegion.show(render);
                var renderFinishCallback = function (options) {
                    var scroll = $(document).scrollTop();
                    window.app.router.navigate("#customEventEditItem/" + id);
                    var scroll = $(document).scrollTop();
                    window.app.router.customEventEditItem(id, scroll, 0, 1);
                }
                render.doIt(renderFinishCallback, true, 'EVENTINFO_REFRESH', false);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion.show(dialogWaiting);

            var lat = 0;
            var lng = 0;

            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': this.app.entities.models.custom.attributes.Address_country }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();

                    helper_createEvent.createEventFromTemplate(app, self, lat, lng, name, callbackCreateEventDone);

                } else {
                    lat = 0;
                    lng = 0;

                    helper_createEvent.createEventFromTemplate(app, self, lat, lng, name, callbackCreateEventDone);
                }
            });
        } else {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100244;
            dialogBoxModel.attributes.Text = resources.X101479;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
    },
    btnCreateEvent2: function (event) {



        var self = this;

        var callbackCreateEventDone = function (destId) {
            var tmpModel = new Entities_Empty.Model();

            var tmpModel = new self.app.entities.collections.customEventTemplate.fullCollection.model();
            tmpModel.attributes.AuthUserId = config.authUserId();

            self.app.entities.collections.customEventTemplate.fullCollection.create(tmpModel, {
                wait: true,
                sourceId: self.model.attributes.Event,
                destId: destId,
                success: function (response) {
                    dialogWaiting.triggerMethod('dialog:close');

                    callbackCopyTemplateDone(response.id);

                    /*self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });
                    self.ui.customEventInsert_inputName.val('');
    
                    self.MakeAllModule(tmpModel, callbackDone);*/
                }
            });
        }

        var callbackCopyTemplateDone = function () {

            //Refress entity
            callback();
        }

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100070;
        dialogWaitingModel.attributes.Text = resources.X100305;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

        var callback = function () {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        self.listenTo(dialogWaiting, "dialog:okclicked");
        self.app.dialogRegion.show(dialogWaiting);

        var lat = 0;
        var lng = 0;

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': this.app.entities.models.custom.attributes.Address_country }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                lat = results[0].geometry.location.lat();
                lng = results[0].geometry.location.lng();

                
                helper_createEvent.createEvent(app, self, lat, lng, "test", callbackCreateEventDone);

            } else {
                lat = 0;
                lng = 0;

                helper_createEvent.createEvent(app, self, lat, lng, "test", callbackCreateEventDone);
            }
        });
    }
});