var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaDataImport_empty"> <div class="row"> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100256'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customSchemaDataImport_input_ShowSchema" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100257'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customSchemaDataImport_input_ShowSchemaBigscreen" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> </div> <div class="row"> <div class="control-label col-md-12"> <label type="button" for="customSchemaDataImport_btnImportSubmit" id="customSchemaDataImport_labelImport" class="btn btn-secondary btn-sm col-sm-3 btnSchemaData">'+
((__t=( resources['X100377'] ))==null?'':__t)+
'</label> <input id="customSchemaDataImport_btnImportSubmit" type="file" accept="*" style="font-size: 20px; display: none"> </div> </div> </div>';
}
return __p;
};
