﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customFormModelEditItem from './tpl/customFormModelEditItem.html';
import tpl_customFormModelEditItemDesign from './tpl/customFormModelEditItemDesign.html';

import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import language from '../../../common/language';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Render from '../render/render';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import Help from '../../helper/help';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customFormModelEditItemDesign({ model: this.model.toJSON() });
        } else {
            return tpl_customFormModelEditItem({ model: this.model.toJSON() });
        }
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick(null);
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customFormModelEditItem_btnBack_click();
        });	
    },
    help: function () {
        Help.help(this, true, 'customFormModelEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick();
        callback();
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        this.setModel(this.model);

        if ((this.model.attributes.Name != this.oldModel.attributes.Name) ||
            (this.model.attributes.FieldUsed != this.oldModel.attributes.FieldUsed) ||
            (this.model.attributes.FieldPosition != this.oldModel.attributes.FieldPosition) ||
            (this.model.attributes.Field01 != this.oldModel.attributes.Field01) ||
            (this.model.attributes.FieldType01 != this.oldModel.attributes.FieldType01) ||
            (this.model.attributes.FieldValue01 != this.oldModel.attributes.FieldValue01) ||
            (this.model.attributes.Field02 != this.oldModel.attributes.Field02) ||
            (this.model.attributes.FieldType02 != this.oldModel.attributes.FieldType02) ||
            (this.model.attributes.FieldValue02 != this.oldModel.attributes.FieldValue02) ||
            (this.model.attributes.Field03 != this.oldModel.attributes.Field03) ||
            (this.model.attributes.FieldType03 != this.oldModel.attributes.FieldType03) ||
            (this.model.attributes.FieldValue03 != this.oldModel.attributes.FieldValue03) ||
            (this.model.attributes.Field04 != this.oldModel.attributes.Field04) ||
            (this.model.attributes.FieldType04 != this.oldModel.attributes.FieldType04) ||
            (this.model.attributes.FieldValue04 != this.oldModel.attributes.FieldValue04) ||
            (this.model.attributes.Field05 != this.oldModel.attributes.Field05) ||
            (this.model.attributes.FieldType05 != this.oldModel.attributes.FieldType05) ||
            (this.model.attributes.FieldValue05 != this.oldModel.attributes.FieldValue05) ||
            (this.model.attributes.Field06 != this.oldModel.attributes.Field06) ||
            (this.model.attributes.FieldType06 != this.oldModel.attributes.FieldType06) ||
            (this.model.attributes.FieldValue06 != this.oldModel.attributes.FieldValue06) ||
            (this.model.attributes.Field07 != this.oldModel.attributes.Field07) ||
            (this.model.attributes.FieldType07 != this.oldModel.attributes.FieldType07) ||
            (this.model.attributes.FieldValue07 != this.oldModel.attributes.FieldValue07) ||
            (this.model.attributes.Field08 != this.oldModel.attributes.Field08) ||
            (this.model.attributes.FieldType08 != this.oldModel.attributes.FieldType08) ||
            (this.model.attributes.FieldValue08 != this.oldModel.attributes.FieldValue08) ||
            (this.model.attributes.Field09 != this.oldModel.attributes.Field09) ||
            (this.model.attributes.FieldType09 != this.oldModel.attributes.FieldType09) ||
            (this.model.attributes.FieldValue09 != this.oldModel.attributes.FieldValue09) ||
            (this.model.attributes.Field10 != this.oldModel.attributes.Field10) ||
            (this.model.attributes.FieldType10 != this.oldModel.attributes.FieldType10) ||
            (this.model.attributes.FieldValue10 != this.oldModel.attributes.FieldValue10) ||
            (this.model.attributes.Field11 != this.oldModel.attributes.Field11) ||
            (this.model.attributes.FieldType11 != this.oldModel.attributes.FieldType11) ||
            (this.model.attributes.FieldValue11 != this.oldModel.attributes.FieldValue11) ||
            (this.model.attributes.Field12 != this.oldModel.attributes.Field12) ||
            (this.model.attributes.FieldType12 != this.oldModel.attributes.FieldType12) ||
            (this.model.attributes.FieldValue12 != this.oldModel.attributes.FieldValue12) ||
            (this.model.attributes.Field13 != this.oldModel.attributes.Field13) ||
            (this.model.attributes.FieldType13 != this.oldModel.attributes.FieldType13) ||
            (this.model.attributes.FieldValue13 != this.oldModel.attributes.FieldValue13) ||
            (this.model.attributes.Field14 != this.oldModel.attributes.Field14) ||
            (this.model.attributes.FieldType14 != this.oldModel.attributes.FieldType14) ||
            (this.model.attributes.FieldValue14 != this.oldModel.attributes.FieldValue14) ||
            (this.model.attributes.Field15 != this.oldModel.attributes.Field15) ||
            (this.model.attributes.FieldType15 != this.oldModel.attributes.FieldType15) ||
            (this.model.attributes.FieldValue15 != this.oldModel.attributes.FieldValue15) ||
            (this.model.attributes.Field16 != this.oldModel.attributes.Field16) ||
            (this.model.attributes.FieldType16 != this.oldModel.attributes.FieldType16) ||
            (this.model.attributes.FieldValue16 != this.oldModel.attributes.FieldValue16) ||
            (this.model.attributes.Field17 != this.oldModel.attributes.Field17) ||
            (this.model.attributes.FieldType17 != this.oldModel.attributes.FieldType17) ||
            (this.model.attributes.FieldValue17 != this.oldModel.attributes.FieldValue17) ||
            (this.model.attributes.Field18 != this.oldModel.attributes.Field18) ||
            (this.model.attributes.FieldType18 != this.oldModel.attributes.FieldType18) ||
            (this.model.attributes.FieldValue18 != this.oldModel.attributes.FieldValue18) ||
            (this.model.attributes.Field19 != this.oldModel.attributes.Field19) ||
            (this.model.attributes.FieldType19 != this.oldModel.attributes.FieldType19) ||
            (this.model.attributes.FieldValue19 != this.oldModel.attributes.FieldValue19) ||
            (this.model.attributes.Field20 != this.oldModel.attributes.Field20) ||
            (this.model.attributes.FieldType20 != this.oldModel.attributes.FieldType20) ||
            (this.model.attributes.FieldValue20 != this.oldModel.attributes.FieldValue20) ||
            (this.model.attributes.Field21 != this.oldModel.attributes.Field21) ||
            (this.model.attributes.FieldType21 != this.oldModel.attributes.FieldType21) ||
            (this.model.attributes.FieldValue21 != this.oldModel.attributes.FieldValue21) ||
            (this.model.attributes.Field22 != this.oldModel.attributes.Field22) ||
            (this.model.attributes.FieldType22 != this.oldModel.attributes.FieldType22) ||
            (this.model.attributes.FieldValue22 != this.oldModel.attributes.FieldValue22) ||
            (this.model.attributes.Field23 != this.oldModel.attributes.Field23) ||
            (this.model.attributes.FieldType23 != this.oldModel.attributes.FieldType23) ||
            (this.model.attributes.FieldValue23 != this.oldModel.attributes.FieldValue23) ||
            (this.model.attributes.Field24 != this.oldModel.attributes.Field24) ||
            (this.model.attributes.FieldType24 != this.oldModel.attributes.FieldType24) ||
            (this.model.attributes.FieldValue24 != this.oldModel.attributes.FieldValue24) ||
            (this.model.attributes.Field25 != this.oldModel.attributes.Field25) ||
            (this.model.attributes.FieldType25 != this.oldModel.attributes.FieldType25) ||
            (this.model.attributes.FieldValue25 != this.oldModel.attributes.FieldValue25) ||
            (this.model.attributes.Field26 != this.oldModel.attributes.Field26) ||
            (this.model.attributes.FieldType26 != this.oldModel.attributes.FieldType26) ||
            (this.model.attributes.FieldValue26 != this.oldModel.attributes.FieldValue26) ||
            (this.model.attributes.Field27 != this.oldModel.attributes.Field27) ||
            (this.model.attributes.FieldType27 != this.oldModel.attributes.FieldType27) ||
            (this.model.attributes.FieldValue27 != this.oldModel.attributes.FieldValue27) ||
            (this.model.attributes.Field28 != this.oldModel.attributes.Field28) ||
            (this.model.attributes.FieldType28 != this.oldModel.attributes.FieldType28) ||
            (this.model.attributes.FieldValue28 != this.oldModel.attributes.FieldValue28) ||
            (this.model.attributes.Field29 != this.oldModel.attributes.Field29) ||
            (this.model.attributes.FieldType29 != this.oldModel.attributes.FieldType29) ||
            (this.model.attributes.FieldValue29 != this.oldModel.attributes.FieldValue29) ||
            (this.model.attributes.Field30 != this.oldModel.attributes.Field30) ||
            (this.model.attributes.FieldType30 != this.oldModel.attributes.FieldType30) ||
            (this.model.attributes.FieldValue30 != this.oldModel.attributes.FieldValue30)) {
            changes = true;
        }

        this.setHasChanged(changes);
    },
    ui: {
        'customFormModelEditItem_btnSubmit': '#customFormModelEditItem_btnSubmit',
        'customFormModelEditItem_btnReset': '#customFormModelEditItem_btnReset',
        'customFormModelEditItem_select_FieldUsed': '#customFormModelEditItem_select_FieldUsed',
        'customFormModelEditItem_select_FieldPosition': '#customFormModelEditItem_select_FieldPosition',
        'customFormModelEditItem_inputName': '#customFormModelEditItem_inputName',
        'customFormModelEditItem_input_Field01': '#customFormModelEditItem_input_Field01',
        'customFormModelEditItem_select_FieldType01': '#customFormModelEditItem_select_FieldType01',
        'customFormModelEditItem_label_FieldValue01': '#customFormModelEditItem_label_FieldValue01',
        'customFormModelEditItem_textarea_FieldValue01': '#customFormModelEditItem_textarea_FieldValue01',
        'customFormModelEditItem_div_FieldValue01': '#customFormModelEditItem_div_FieldValue01',
        'customFormModelEditItem_input_Field02': '#customFormModelEditItem_input_Field02',
        'customFormModelEditItem_select_FieldType02': '#customFormModelEditItem_select_FieldType02',
        'customFormModelEditItem_label_FieldValue02': '#customFormModelEditItem_label_FieldValue02',
        'customFormModelEditItem_textarea_FieldValue02': '#customFormModelEditItem_textarea_FieldValue02',
        'customFormModelEditItem_div_FieldValue02': '#customFormModelEditItem_div_FieldValue02',
        'customFormModelEditItem_input_Field03': '#customFormModelEditItem_input_Field03',
        'customFormModelEditItem_select_FieldType03': '#customFormModelEditItem_select_FieldType03',
        'customFormModelEditItem_label_FieldValue03': '#customFormModelEditItem_label_FieldValue03',
        'customFormModelEditItem_textarea_FieldValue03': '#customFormModelEditItem_textarea_FieldValue03',
        'customFormModelEditItem_div_FieldValue03': '#customFormModelEditItem_div_FieldValue03',
        'customFormModelEditItem_input_Field04': '#customFormModelEditItem_input_Field04',
        'customFormModelEditItem_select_FieldType04': '#customFormModelEditItem_select_FieldType04',
        'customFormModelEditItem_label_FieldValue04': '#customFormModelEditItem_label_FieldValue04',
        'customFormModelEditItem_textarea_FieldValue04': '#customFormModelEditItem_textarea_FieldValue04',
        'customFormModelEditItem_div_FieldValue04': '#customFormModelEditItem_div_FieldValue04',
        'customFormModelEditItem_input_Field05': '#customFormModelEditItem_input_Field05',
        'customFormModelEditItem_select_FieldType05': '#customFormModelEditItem_select_FieldType05',
        'customFormModelEditItem_label_FieldValue05': '#customFormModelEditItem_label_FieldValue05',
        'customFormModelEditItem_textarea_FieldValue05': '#customFormModelEditItem_textarea_FieldValue05',
        'customFormModelEditItem_div_FieldValue05': '#customFormModelEditItem_div_FieldValue05',
        'customFormModelEditItem_input_Field06': '#customFormModelEditItem_input_Field06',
        'customFormModelEditItem_select_FieldType06': '#customFormModelEditItem_select_FieldType06',
        'customFormModelEditItem_label_FieldValue06': '#customFormModelEditItem_label_FieldValue06',
        'customFormModelEditItem_textarea_FieldValue06': '#customFormModelEditItem_textarea_FieldValue06',
        'customFormModelEditItem_div_FieldValue06': '#customFormModelEditItem_div_FieldValue06',
        'customFormModelEditItem_input_Field07': '#customFormModelEditItem_input_Field07',
        'customFormModelEditItem_select_FieldType07': '#customFormModelEditItem_select_FieldType07',
        'customFormModelEditItem_label_FieldValue07': '#customFormModelEditItem_label_FieldValue07',
        'customFormModelEditItem_textarea_FieldValue07': '#customFormModelEditItem_textarea_FieldValue07',
        'customFormModelEditItem_div_FieldValue07': '#customFormModelEditItem_div_FieldValue07',
        'customFormModelEditItem_input_Field08': '#customFormModelEditItem_input_Field08',
        'customFormModelEditItem_select_FieldType08': '#customFormModelEditItem_select_FieldType08',
        'customFormModelEditItem_label_FieldValue08': '#customFormModelEditItem_label_FieldValue08',
        'customFormModelEditItem_textarea_FieldValue08': '#customFormModelEditItem_textarea_FieldValue08',
        'customFormModelEditItem_div_FieldValue08': '#customFormModelEditItem_div_FieldValue08',
        'customFormModelEditItem_input_Field09': '#customFormModelEditItem_input_Field09',
        'customFormModelEditItem_select_FieldType09': '#customFormModelEditItem_select_FieldType09',
        'customFormModelEditItem_label_FieldValue09': '#customFormModelEditItem_label_FieldValue09',
        'customFormModelEditItem_textarea_FieldValue09': '#customFormModelEditItem_textarea_FieldValue09',
        'customFormModelEditItem_div_FieldValue09': '#customFormModelEditItem_div_FieldValue09',
        'customFormModelEditItem_input_Field10': '#customFormModelEditItem_input_Field10',
        'customFormModelEditItem_select_FieldType10': '#customFormModelEditItem_select_FieldType10',
        'customFormModelEditItem_label_FieldValue10': '#customFormModelEditItem_label_FieldValue10',
        'customFormModelEditItem_textarea_FieldValue10': '#customFormModelEditItem_textarea_FieldValue10',
        'customFormModelEditItem_div_FieldValue10': '#customFormModelEditItem_div_FieldValue10',
        'customFormModelEditItem_input_Field11': '#customFormModelEditItem_input_Field11',
        'customFormModelEditItem_select_FieldType11': '#customFormModelEditItem_select_FieldType11',
        'customFormModelEditItem_label_FieldValue11': '#customFormModelEditItem_label_FieldValue11',
        'customFormModelEditItem_textarea_FieldValue11': '#customFormModelEditItem_textarea_FieldValue11',
        'customFormModelEditItem_div_FieldValue11': '#customFormModelEditItem_div_FieldValue11',
        'customFormModelEditItem_input_Field12': '#customFormModelEditItem_input_Field12',
        'customFormModelEditItem_select_FieldType12': '#customFormModelEditItem_select_FieldType12',
        'customFormModelEditItem_label_FieldValue12': '#customFormModelEditItem_label_FieldValue12',
        'customFormModelEditItem_textarea_FieldValue12': '#customFormModelEditItem_textarea_FieldValue12',
        'customFormModelEditItem_div_FieldValue12': '#customFormModelEditItem_div_FieldValue12',
        'customFormModelEditItem_input_Field13': '#customFormModelEditItem_input_Field13',
        'customFormModelEditItem_select_FieldType13': '#customFormModelEditItem_select_FieldType13',
        'customFormModelEditItem_label_FieldValue13': '#customFormModelEditItem_label_FieldValue13',
        'customFormModelEditItem_textarea_FieldValue13': '#customFormModelEditItem_textarea_FieldValue13',
        'customFormModelEditItem_div_FieldValue13': '#customFormModelEditItem_div_FieldValue13',
        'customFormModelEditItem_input_Field14': '#customFormModelEditItem_input_Field14',
        'customFormModelEditItem_select_FieldType14': '#customFormModelEditItem_select_FieldType14',
        'customFormModelEditItem_label_FieldValue14': '#customFormModelEditItem_label_FieldValue14',
        'customFormModelEditItem_textarea_FieldValue14': '#customFormModelEditItem_textarea_FieldValue14',
        'customFormModelEditItem_div_FieldValue14': '#customFormModelEditItem_div_FieldValue14',
        'customFormModelEditItem_input_Field15': '#customFormModelEditItem_input_Field15',
        'customFormModelEditItem_select_FieldType15': '#customFormModelEditItem_select_FieldType15',
        'customFormModelEditItem_label_FieldValue15': '#customFormModelEditItem_label_FieldValue15',
        'customFormModelEditItem_textarea_FieldValue15': '#customFormModelEditItem_textarea_FieldValue15',
        'customFormModelEditItem_div_FieldValue15': '#customFormModelEditItem_div_FieldValue15',
        'customFormModelEditItem_input_Field16': '#customFormModelEditItem_input_Field16',
        'customFormModelEditItem_select_FieldType16': '#customFormModelEditItem_select_FieldType16',
        'customFormModelEditItem_label_FieldValue16': '#customFormModelEditItem_label_FieldValue16',
        'customFormModelEditItem_textarea_FieldValue16': '#customFormModelEditItem_textarea_FieldValue16',
        'customFormModelEditItem_div_FieldValue16': '#customFormModelEditItem_div_FieldValue16',
        'customFormModelEditItem_input_Field17': '#customFormModelEditItem_input_Field17',
        'customFormModelEditItem_select_FieldType17': '#customFormModelEditItem_select_FieldType17',
        'customFormModelEditItem_label_FieldValue17': '#customFormModelEditItem_label_FieldValue17',
        'customFormModelEditItem_textarea_FieldValue17': '#customFormModelEditItem_textarea_FieldValue17',
        'customFormModelEditItem_div_FieldValue17': '#customFormModelEditItem_div_FieldValue17',
        'customFormModelEditItem_input_Field18': '#customFormModelEditItem_input_Field18',
        'customFormModelEditItem_select_FieldType18': '#customFormModelEditItem_select_FieldType18',
        'customFormModelEditItem_label_FieldValue18': '#customFormModelEditItem_label_FieldValue18',
        'customFormModelEditItem_textarea_FieldValue18': '#customFormModelEditItem_textarea_FieldValue18',
        'customFormModelEditItem_div_FieldValue18': '#customFormModelEditItem_div_FieldValue18',
        'customFormModelEditItem_input_Field19': '#customFormModelEditItem_input_Field19',
        'customFormModelEditItem_select_FieldType19': '#customFormModelEditItem_select_FieldType19',
        'customFormModelEditItem_label_FieldValue19': '#customFormModelEditItem_label_FieldValue19',
        'customFormModelEditItem_textarea_FieldValue19': '#customFormModelEditItem_textarea_FieldValue19',
        'customFormModelEditItem_div_FieldValue19': '#customFormModelEditItem_div_FieldValue19',
        'customFormModelEditItem_input_Field20': '#customFormModelEditItem_input_Field20',
        'customFormModelEditItem_select_FieldType20': '#customFormModelEditItem_select_FieldType20',
        'customFormModelEditItem_label_FieldValue20': '#customFormModelEditItem_label_FieldValue20',
        'customFormModelEditItem_textarea_FieldValue20': '#customFormModelEditItem_textarea_FieldValue20',
        'customFormModelEditItem_div_FieldValue20': '#customFormModelEditItem_div_FieldValue20',
        'customFormModelEditItem_input_Field21': '#customFormModelEditItem_input_Field21',
        'customFormModelEditItem_select_FieldType21': '#customFormModelEditItem_select_FieldType21',
        'customFormModelEditItem_label_FieldValue21': '#customFormModelEditItem_label_FieldValue21',
        'customFormModelEditItem_textarea_FieldValue21': '#customFormModelEditItem_textarea_FieldValue21',
        'customFormModelEditItem_div_FieldValue21': '#customFormModelEditItem_div_FieldValue21',
        'customFormModelEditItem_input_Field22': '#customFormModelEditItem_input_Field22',
        'customFormModelEditItem_select_FieldType22': '#customFormModelEditItem_select_FieldType22',
        'customFormModelEditItem_label_FieldValue22': '#customFormModelEditItem_label_FieldValue22',
        'customFormModelEditItem_textarea_FieldValue22': '#customFormModelEditItem_textarea_FieldValue22',
        'customFormModelEditItem_div_FieldValue22': '#customFormModelEditItem_div_FieldValue22',
        'customFormModelEditItem_input_Field23': '#customFormModelEditItem_input_Field23',
        'customFormModelEditItem_select_FieldType23': '#customFormModelEditItem_select_FieldType23',
        'customFormModelEditItem_label_FieldValue23': '#customFormModelEditItem_label_FieldValue23',
        'customFormModelEditItem_textarea_FieldValue23': '#customFormModelEditItem_textarea_FieldValue23',
        'customFormModelEditItem_div_FieldValue23': '#customFormModelEditItem_div_FieldValue23',
        'customFormModelEditItem_input_Field24': '#customFormModelEditItem_input_Field24',
        'customFormModelEditItem_select_FieldType24': '#customFormModelEditItem_select_FieldType24',
        'customFormModelEditItem_label_FieldValue24': '#customFormModelEditItem_label_FieldValue24',
        'customFormModelEditItem_textarea_FieldValue24': '#customFormModelEditItem_textarea_FieldValue24',
        'customFormModelEditItem_div_FieldValue24': '#customFormModelEditItem_div_FieldValue24',
        'customFormModelEditItem_input_Field25': '#customFormModelEditItem_input_Field25',
        'customFormModelEditItem_select_FieldType25': '#customFormModelEditItem_select_FieldType25',
        'customFormModelEditItem_label_FieldValue25': '#customFormModelEditItem_label_FieldValue25',
        'customFormModelEditItem_textarea_FieldValue25': '#customFormModelEditItem_textarea_FieldValue25',
        'customFormModelEditItem_div_FieldValue25': '#customFormModelEditItem_div_FieldValue25',
        'customFormModelEditItem_input_Field26': '#customFormModelEditItem_input_Field26',
        'customFormModelEditItem_select_FieldType26': '#customFormModelEditItem_select_FieldType26',
        'customFormModelEditItem_label_FieldValue26': '#customFormModelEditItem_label_FieldValue26',
        'customFormModelEditItem_textarea_FieldValue26': '#customFormModelEditItem_textarea_FieldValue26',
        'customFormModelEditItem_div_FieldValue26': '#customFormModelEditItem_div_FieldValue26',
        'customFormModelEditItem_input_Field27': '#customFormModelEditItem_input_Field27',
        'customFormModelEditItem_select_FieldType27': '#customFormModelEditItem_select_FieldType27',
        'customFormModelEditItem_label_FieldValue27': '#customFormModelEditItem_label_FieldValue27',
        'customFormModelEditItem_textarea_FieldValue27': '#customFormModelEditItem_textarea_FieldValue27',
        'customFormModelEditItem_div_FieldValue27': '#customFormModelEditItem_div_FieldValue27',
        'customFormModelEditItem_input_Field28': '#customFormModelEditItem_input_Field28',
        'customFormModelEditItem_select_FieldType28': '#customFormModelEditItem_select_FieldType28',
        'customFormModelEditItem_label_FieldValue28': '#customFormModelEditItem_label_FieldValue28',
        'customFormModelEditItem_textarea_FieldValue28': '#customFormModelEditItem_textarea_FieldValue28',
        'customFormModelEditItem_div_FieldValue28': '#customFormModelEditItem_div_FieldValue28',
        'customFormModelEditItem_input_Field29': '#customFormModelEditItem_input_Field29',
        'customFormModelEditItem_select_FieldType29': '#customFormModelEditItem_select_FieldType29',
        'customFormModelEditItem_label_FieldValue29': '#customFormModelEditItem_label_FieldValue29',
        'customFormModelEditItem_textarea_FieldValue29': '#customFormModelEditItem_textarea_FieldValue29',
        'customFormModelEditItem_div_FieldValue29': '#customFormModelEditItem_div_FieldValue29',
        'customFormModelEditItem_input_Field30': '#customFormModelEditItem_input_Field30',
        'customFormModelEditItem_select_FieldType30': '#customFormModelEditItem_select_FieldType30',
        'customFormModelEditItem_label_FieldValue30': '#customFormModelEditItem_label_FieldValue30',
        'customFormModelEditItem_textarea_FieldValue30': '#customFormModelEditItem_textarea_FieldValue30',
        'customFormModelEditItem_div_FieldValue30': '#customFormModelEditItem_div_FieldValue30',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customFormModelEditItem_inputNameGroup': '#customFormModelEditItem_inputNameGroup',
        'customFormModelEditItem_btnBack': '#customFormModelEditItem_btnBack'
    },
    events: {
        'click @ui.customFormModelEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customFormModelEditItem_btnReset': 'btnResetClick',
        'change @ui.customFormModelEditItem_select_ConfigCustomFormModel': 'selectConfigCustomFormModel',
        'change @ui.customFormModelEditItem_select_FieldUsed': 'selectFieldUsedChange',
        'change @ui.customFormModelEditItem_select_FieldType01': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType02': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType03': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType04': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType05': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType06': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType07': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType08': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType09': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType10': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType11': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType12': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType13': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType14': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType15': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType16': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType17': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType18': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType19': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType20': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType21': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType22': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType23': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType24': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType25': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType26': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType27': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType28': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType29': 'selectFieldTypeChange',
        'change @ui.customFormModelEditItem_select_FieldType30': 'selectFieldTypeChange',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customFormModelEditItem_btnBack	': 'customFormModelEditItem_btnBack_click'
    },
    onRender: function () {
        this.setupFieldUsed(this.ui.customFormModelEditItem_select_FieldUsed);
        this.ui.customFormModelEditItem_select_FieldUsed.val(this.model.attributes.FieldUsed);

        //Allways on top
        //this.setupFieldPosition(this.ui.customFormModelEditItem_select_FieldPosition);
        //this.ui.customFormModelEditItem_select_FieldPosition.val(this.model.attributes.FieldPosition);

        this.setupFieldType();

        this.selectFieldUsedChange(null);

        if (this.app.mode == 1) {
            this.ui.customFormModelEditItem_inputNameGroup.css('display', 'none');
            this.ui.customFormModelEditItem_btnSubmit.css('display', 'none');
            this.ui.customFormModelEditItem_btnBack.css('display', 'none');
        }

        return this;
    },
    regions: {
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100180;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    customViewEvent_setActive: function () {
        var array = [];

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: this.model.attributes.id });

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }
    },
    setupFieldUsed: function (element) {
        var FieldUsedStr = "";

        for (var i = 1; i <= 30; i++) {
            if (i < 10) {
                FieldUsedStr = "0" + i.toString();
            }
            else {
                FieldUsedStr = "" + i.toString();
            }

            element.append('<option value="' + i + '">' + FieldUsedStr + '</option>');
        }
    },
    setupFieldPosition: function (element) {
        //element.append('<option value="0">' + resources.X100187 + '</option>');
        element.append('<option value="1">' + resources.X100188 + '</option>');
        element.append('<option value="2">' + resources.X100189 + '</option>');
    },
    setupFieldType: function () {
        for (var i = 1; i <= 30; i++) {
            var element_select_fieldType;
            var element_label_fieldValue;
            var element_div_fieldValue;
            var fieldValueType;
            switch (i) {
                case 1: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType01;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue01;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue01;
                    fieldValueType = this.model.get('FieldType01');
                    break;
                case 2: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType02;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue02;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue02;
                    fieldValueType = this.model.get('FieldType02');
                    break;
                case 3: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType03;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue03;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue03;
                    fieldValueType = this.model.get('FieldType03');
                    break;
                case 4: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType04;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue04;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue04;
                    fieldValueType = this.model.get('FieldType04');
                    break;
                case 5: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType05;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue05;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue05;
                    fieldValueType = this.model.get('FieldType05');
                    break;
                case 6: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType06;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue06;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue06;
                    fieldValueType = this.model.get('FieldType06');
                    break;
                case 7: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType07;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue07;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue07;
                    fieldValueType = this.model.get('FieldType07');
                    break;
                case 8: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType08;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue08;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue08;
                    fieldValueType = this.model.get('FieldType08');
                    break;
                case 9: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType09;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue09;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue09;
                    fieldValueType = this.model.get('FieldType09');
                    break;
                case 10: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType10;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue10;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue10;
                    fieldValueType = this.model.get('FieldType10');
                    break;
                case 11: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType11;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue11;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue11;
                    fieldValueType = this.model.get('FieldType11');
                    break;
                case 12: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType12;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue12;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue12;
                    fieldValueType = this.model.get('FieldType12');
                    break;
                case 13: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType13;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue13;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue13;
                    fieldValueType = this.model.get('FieldType13');
                    break;
                case 14: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType14;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue14;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue14;
                    fieldValueType = this.model.get('FieldType14');
                    break;
                case 15: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType15;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue15;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue15;
                    fieldValueType = this.model.get('FieldType15');
                    break;
                case 16: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType16;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue16;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue16;
                    fieldValueType = this.model.get('FieldType16');
                    break;
                case 17: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType17;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue17;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue17;
                    fieldValueType = this.model.get('FieldType17');
                    break;
                case 18: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType18;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue18;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue18;
                    fieldValueType = this.model.get('FieldType18');
                    break;
                case 19: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType19;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue19;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue19;
                    fieldValueType = this.model.get('FieldType19');
                    break;
                case 20: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType20;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue20;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue20;
                    fieldValueType = this.model.get('FieldType20');
                    break;
                case 21: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType21;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue21;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue21;
                    fieldValueType = this.model.get('FieldType21');
                    break;
                case 22: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType22;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue22;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue22;
                    fieldValueType = this.model.get('FieldType22');
                    break;
                case 23: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType23;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue23;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue23;
                    fieldValueType = this.model.get('FieldType23');
                    break;
                case 24: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType24;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue24;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue24;
                    fieldValueType = this.model.get('FieldType24');
                    break;
                case 25: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType25;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue25;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue25;
                    fieldValueType = this.model.get('FieldType25');
                    break;
                case 26: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType26;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue26;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue26;
                    fieldValueType = this.model.get('FieldType26');
                    break;
                case 27: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType27;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue27;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue27;
                    fieldValueType = this.model.get('FieldType27');
                    break;
                case 28: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType28;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue28;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue28;
                    fieldValueType = this.model.get('FieldType28');
                    break;
                case 29: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType29;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue29;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue29;
                    fieldValueType = this.model.get('FieldType29');
                    break;
                case 30: element_select_fieldType = this.ui.customFormModelEditItem_select_FieldType30;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue30;
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue30;
                    fieldValueType = this.model.get('FieldType30');
                    break;
            }

            if (fieldValueType == 1) {
                element_select_fieldType.append('<option value="1" selected="selected">' + resources.X100221 + '</option>');
                element_label_fieldValue.text(resources.X100225);
            }
            else {
                element_select_fieldType.append('<option value="1" >' + resources.X100221 + '</option>');
            }

            if (fieldValueType == 2) {
                element_select_fieldType.append('<option value="2" selected="selected">' + resources.X100425 + '</option>');
                element_label_fieldValue.text(resources.X100426);
            }
            else {
                element_select_fieldType.append('<option value="2" >' + resources.X100425 + '</option>');
            }

            if (fieldValueType == 3) {
                element_select_fieldType.append('<option value="3" selected="selected">' + resources.X100222 + '</option>');
                element_label_fieldValue.text(resources.X100226);
            }
            else {
                element_select_fieldType.append('<option value="3" >' + resources.X100222 + '</option>');
            }

            if (fieldValueType == 4) {
                element_select_fieldType.append('<option value="4" selected="selected">' + resources.X100223 + '</option>');
                element_label_fieldValue.text(resources.X100227);
            }
            else {
                element_select_fieldType.append('<option value="4" >' + resources.X100223 + '</option>');
            }

            if (fieldValueType == 5) {
                element_select_fieldType.append('<option value="5" selected="selected">' + resources.X100224 + '</option>');
                element_label_fieldValue.text(resources.X100228);
            }
            else {
                element_select_fieldType.append('<option value="5" >' + resources.X100224 + '</option>');
            }

            if (fieldValueType == 6) {
                element_select_fieldType.append('<option value="6" selected="selected">' + resources.X100393 + '</option>');
                element_label_fieldValue.text(resources.X100394);
            }
            else {
                element_select_fieldType.append('<option value="6" >' + resources.X100393 + '</option>');
            }

            switch (fieldValueType) {
                case 1:
                case 2:
                case 3:
                case 4: element_div_fieldValue.css('display', 'none'); break;
                case 5: element_div_fieldValue.show(); break;
                case 6: element_div_fieldValue.show(); break;
            }
        }
    },
    selectFieldTypeChange: function (event) {
        if (event != null) {
            event.preventDefault();

            var element_div_fieldValue;
            var element_label_fieldValue;

            switch (event.target.id) {
                case 'customFormModelEditItem_select_FieldType01':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue01;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue01;
                    break;
                case 'customFormModelEditItem_select_FieldType02':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue02;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue02;
                    break;
                case 'customFormModelEditItem_select_FieldType03':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue03;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue03;
                    break;
                case 'customFormModelEditItem_select_FieldType04':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue04;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue04;
                    break;
                case 'customFormModelEditItem_select_FieldType05':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue05;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue05;
                    break;
                case 'customFormModelEditItem_select_FieldType06':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue06;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue06;
                    break;
                case 'customFormModelEditItem_select_FieldType07':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue07;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue07;
                    break;
                case 'customFormModelEditItem_select_FieldType08':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue08;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue08;
                    break;
                case 'customFormModelEditItem_select_FieldType09':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue09;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue09;
                    break;
                case 'customFormModelEditItem_select_FieldType10':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue10;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue10;
                    break;
                case 'customFormModelEditItem_select_FieldType11':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue11;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue11;
                    break;
                case 'customFormModelEditItem_select_FieldType12':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue12;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue12;
                    break;
                case 'customFormModelEditItem_select_FieldType13':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue13;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue13;
                    break;
                case 'customFormModelEditItem_select_FieldType14':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue14;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue14;
                    break;
                case 'customFormModelEditItem_select_FieldType15':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue15;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue15;
                    break;
                case 'customFormModelEditItem_select_FieldType16':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue16;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue16;
                    break;
                case 'customFormModelEditItem_select_FieldType17':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue17;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue17;
                    break;
                case 'customFormModelEditItem_select_FieldType18':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue18;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue18;
                    break;
                case 'customFormModelEditItem_select_FieldType19':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue19;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue19;
                    break;
                case 'customFormModelEditItem_select_FieldType20':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue20;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue20;
                    break;
                case 'customFormModelEditItem_select_FieldType21':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue21;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue21;
                    break;
                case 'customFormModelEditItem_select_FieldType22':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue22;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue22;
                    break;
                case 'customFormModelEditItem_select_FieldType23':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue23;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue23;
                    break;
                case 'customFormModelEditItem_select_FieldType24':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue24;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue24;
                    break;
                case 'customFormModelEditItem_select_FieldType25':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue25;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue25;
                    break;
                case 'customFormModelEditItem_select_FieldType26':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue26;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue26;
                    break;
                case 'customFormModelEditItem_select_FieldType27':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue27;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue27;
                    break;
                case 'customFormModelEditItem_select_FieldType28':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue28;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue28;
                    break;
                case 'customFormModelEditItem_select_FieldType29':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue29;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue29;
                    break;
                case 'customFormModelEditItem_select_FieldType30':
                    element_div_fieldValue = this.ui.customFormModelEditItem_div_FieldValue30;
                    element_label_fieldValue = this.ui.customFormModelEditItem_label_FieldValue30;
                    break;
            }

            switch (event.target.value) {
                case '1':
                case '2':
                case '3': element_div_fieldValue.css('display', 'none'); break;
                case '4': element_div_fieldValue.css('display', 'none'); break;
                case '5': element_label_fieldValue.text(resources.X100228);
                    element_div_fieldValue.show(); break;
                case '6': element_label_fieldValue.text(resources.X100394);
                    element_div_fieldValue.show(); break;
            }
        }
    },
    selectFieldUsedChange: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.model.attributes.FieldUsed = parseInt(this.ui.customFormModelEditItem_select_FieldUsed.val());

        for (var i = 1; i <= 30; i++) {
            var sId = '';
            if (i < 10) {
                sId = '#customFormModelEditItem_div_Field0' + i;
            }
            else {
                sId = '#customFormModelEditItem_div_Field' + i;
            }

            if (i <= this.model.attributes.FieldUsed) {
                this.$el.find(sId).show();
            }
            else {
                this.$el.find(sId).css('display', 'none');
            }
        }
    },
    setModel: function (tmpModel) {
        tmpModel.attributes.Name = this.ui.customFormModelEditItem_inputName.val();

        tmpModel.attributes.FieldUsed = parseInt(this.ui.customFormModelEditItem_select_FieldUsed.val());
        //Allway on top
        //tmpModel.attributes.FieldPosition = parseInt(this.ui.customFormModelEditItem_select_FieldPosition.val());
        tmpModel.attributes.FieldPosition = 1;

        tmpModel.attributes.Field01 = this.ui.customFormModelEditItem_input_Field01.val();
        tmpModel.attributes.FieldType01 = parseInt(this.ui.customFormModelEditItem_select_FieldType01.val());
        tmpModel.attributes.FieldValue01 = this.ui.customFormModelEditItem_textarea_FieldValue01.val();

        tmpModel.attributes.Field02 = this.ui.customFormModelEditItem_input_Field02.val();
        tmpModel.attributes.FieldType02 = parseInt(this.ui.customFormModelEditItem_select_FieldType02.val());
        tmpModel.attributes.FieldValue02 = this.ui.customFormModelEditItem_textarea_FieldValue02.val();

        tmpModel.attributes.Field03 = this.ui.customFormModelEditItem_input_Field03.val();
        tmpModel.attributes.FieldType03 = parseInt(this.ui.customFormModelEditItem_select_FieldType03.val());
        tmpModel.attributes.FieldValue03 = this.ui.customFormModelEditItem_textarea_FieldValue03.val();

        tmpModel.attributes.Field04 = this.ui.customFormModelEditItem_input_Field04.val();
        tmpModel.attributes.FieldType04 = parseInt(this.ui.customFormModelEditItem_select_FieldType04.val());
        tmpModel.attributes.FieldValue04 = this.ui.customFormModelEditItem_textarea_FieldValue04.val();

        tmpModel.attributes.Field05 = this.ui.customFormModelEditItem_input_Field05.val();
        tmpModel.attributes.FieldType05 = parseInt(this.ui.customFormModelEditItem_select_FieldType05.val());
        tmpModel.attributes.FieldValue05 = this.ui.customFormModelEditItem_textarea_FieldValue05.val();

        tmpModel.attributes.Field06 = this.ui.customFormModelEditItem_input_Field06.val();
        tmpModel.attributes.FieldType06 = parseInt(this.ui.customFormModelEditItem_select_FieldType06.val());
        tmpModel.attributes.FieldValue06 = this.ui.customFormModelEditItem_textarea_FieldValue06.val();

        tmpModel.attributes.Field07 = this.ui.customFormModelEditItem_input_Field07.val();
        tmpModel.attributes.FieldType07 = parseInt(this.ui.customFormModelEditItem_select_FieldType07.val());
        tmpModel.attributes.FieldValue07 = this.ui.customFormModelEditItem_textarea_FieldValue07.val();

        tmpModel.attributes.Field08 = this.ui.customFormModelEditItem_input_Field08.val();
        tmpModel.attributes.FieldType08 = parseInt(this.ui.customFormModelEditItem_select_FieldType08.val());
        tmpModel.attributes.FieldValue08 = this.ui.customFormModelEditItem_textarea_FieldValue08.val();

        tmpModel.attributes.Field09 = this.ui.customFormModelEditItem_input_Field09.val();
        tmpModel.attributes.FieldType09 = parseInt(this.ui.customFormModelEditItem_select_FieldType09.val());
        tmpModel.attributes.FieldValue09 = this.ui.customFormModelEditItem_textarea_FieldValue09.val();

        tmpModel.attributes.Field10 = this.ui.customFormModelEditItem_input_Field10.val();
        tmpModel.attributes.FieldType10 = parseInt(this.ui.customFormModelEditItem_select_FieldType10.val());
        tmpModel.attributes.FieldValue10 = this.ui.customFormModelEditItem_textarea_FieldValue10.val();

        tmpModel.attributes.Field11 = this.ui.customFormModelEditItem_input_Field11.val();
        tmpModel.attributes.FieldType11 = parseInt(this.ui.customFormModelEditItem_select_FieldType11.val());
        tmpModel.attributes.FieldValue11 = this.ui.customFormModelEditItem_textarea_FieldValue11.val();

        tmpModel.attributes.Field12 = this.ui.customFormModelEditItem_input_Field12.val();
        tmpModel.attributes.FieldType12 = parseInt(this.ui.customFormModelEditItem_select_FieldType12.val());
        tmpModel.attributes.FieldValue12 = this.ui.customFormModelEditItem_textarea_FieldValue12.val();

        tmpModel.attributes.Field13 = this.ui.customFormModelEditItem_input_Field13.val();
        tmpModel.attributes.FieldType13 = parseInt(this.ui.customFormModelEditItem_select_FieldType13.val());
        tmpModel.attributes.FieldValue13 = this.ui.customFormModelEditItem_textarea_FieldValue13.val();

        tmpModel.attributes.Field14 = this.ui.customFormModelEditItem_input_Field14.val();
        tmpModel.attributes.FieldType14 = parseInt(this.ui.customFormModelEditItem_select_FieldType14.val());
        tmpModel.attributes.FieldValue14 = this.ui.customFormModelEditItem_textarea_FieldValue14.val();

        tmpModel.attributes.Field15 = this.ui.customFormModelEditItem_input_Field15.val();
        tmpModel.attributes.FieldType15 = parseInt(this.ui.customFormModelEditItem_select_FieldType15.val());
        tmpModel.attributes.FieldValue15 = this.ui.customFormModelEditItem_textarea_FieldValue15.val();

        tmpModel.attributes.Field16 = this.ui.customFormModelEditItem_input_Field16.val();
        tmpModel.attributes.FieldType16 = parseInt(this.ui.customFormModelEditItem_select_FieldType16.val());
        tmpModel.attributes.FieldValue16 = this.ui.customFormModelEditItem_textarea_FieldValue16.val();

        tmpModel.attributes.Field17 = this.ui.customFormModelEditItem_input_Field17.val();
        tmpModel.attributes.FieldType17 = parseInt(this.ui.customFormModelEditItem_select_FieldType17.val());
        tmpModel.attributes.FieldValue17 = this.ui.customFormModelEditItem_textarea_FieldValue17.val();

        tmpModel.attributes.Field18 = this.ui.customFormModelEditItem_input_Field18.val();
        tmpModel.attributes.FieldType18 = parseInt(this.ui.customFormModelEditItem_select_FieldType18.val());
        tmpModel.attributes.FieldValue18 = this.ui.customFormModelEditItem_textarea_FieldValue18.val();

        tmpModel.attributes.Field19 = this.ui.customFormModelEditItem_input_Field19.val();
        tmpModel.attributes.FieldType19 = parseInt(this.ui.customFormModelEditItem_select_FieldType19.val());
        tmpModel.attributes.FieldValue19 = this.ui.customFormModelEditItem_textarea_FieldValue19.val();

        tmpModel.attributes.Field20 = this.ui.customFormModelEditItem_input_Field20.val();
        tmpModel.attributes.FieldType20 = parseInt(this.ui.customFormModelEditItem_select_FieldType20.val());
        tmpModel.attributes.FieldValue20 = this.ui.customFormModelEditItem_textarea_FieldValue20.val();

        tmpModel.attributes.Field21 = this.ui.customFormModelEditItem_input_Field21.val();
        tmpModel.attributes.FieldType21 = parseInt(this.ui.customFormModelEditItem_select_FieldType21.val());
        tmpModel.attributes.FieldValue21 = this.ui.customFormModelEditItem_textarea_FieldValue21.val();

        tmpModel.attributes.Field22 = this.ui.customFormModelEditItem_input_Field22.val();
        tmpModel.attributes.FieldType22 = parseInt(this.ui.customFormModelEditItem_select_FieldType22.val());
        tmpModel.attributes.FieldValue22 = this.ui.customFormModelEditItem_textarea_FieldValue22.val();

        tmpModel.attributes.Field23 = this.ui.customFormModelEditItem_input_Field23.val();
        tmpModel.attributes.FieldType23 = parseInt(this.ui.customFormModelEditItem_select_FieldType23.val());
        tmpModel.attributes.FieldValue23 = this.ui.customFormModelEditItem_textarea_FieldValue23.val();

        tmpModel.attributes.Field24 = this.ui.customFormModelEditItem_input_Field24.val();
        tmpModel.attributes.FieldType24 = parseInt(this.ui.customFormModelEditItem_select_FieldType24.val());
        tmpModel.attributes.FieldValue24 = this.ui.customFormModelEditItem_textarea_FieldValue24.val();

        tmpModel.attributes.Field25 = this.ui.customFormModelEditItem_input_Field25.val();
        tmpModel.attributes.FieldType25 = parseInt(this.ui.customFormModelEditItem_select_FieldType25.val());
        tmpModel.attributes.FieldValue25 = this.ui.customFormModelEditItem_textarea_FieldValue25.val();

        tmpModel.attributes.Field26 = this.ui.customFormModelEditItem_input_Field26.val();
        tmpModel.attributes.FieldType26 = parseInt(this.ui.customFormModelEditItem_select_FieldType26.val());
        tmpModel.attributes.FieldValue26 = this.ui.customFormModelEditItem_textarea_FieldValue26.val();

        tmpModel.attributes.Field27 = this.ui.customFormModelEditItem_input_Field27.val();
        tmpModel.attributes.FieldType27 = parseInt(this.ui.customFormModelEditItem_select_FieldType27.val());
        tmpModel.attributes.FieldValue27 = this.ui.customFormModelEditItem_textarea_FieldValue27.val();

        tmpModel.attributes.Field28 = this.ui.customFormModelEditItem_input_Field28.val();
        tmpModel.attributes.FieldType28 = parseInt(this.ui.customFormModelEditItem_select_FieldType28.val());
        tmpModel.attributes.FieldValue28 = this.ui.customFormModelEditItem_textarea_FieldValue28.val();

        tmpModel.attributes.Field29 = this.ui.customFormModelEditItem_input_Field29.val();
        tmpModel.attributes.FieldType29 = parseInt(this.ui.customFormModelEditItem_select_FieldType29.val());
        tmpModel.attributes.FieldValue29 = this.ui.customFormModelEditItem_textarea_FieldValue29.val();

        tmpModel.attributes.Field30 = this.ui.customFormModelEditItem_input_Field30.val();
        tmpModel.attributes.FieldType30 = parseInt(this.ui.customFormModelEditItem_select_FieldType30.val());
        tmpModel.attributes.FieldValue30 = this.ui.customFormModelEditItem_textarea_FieldValue30.val();
    },
    btnSubmitClick: function (event) {
        if (event) {
            event.preventDefault();
        }
        var self = this;

        this.setModel(this.model);

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
        }
        else {
            this.hideErrors();

            var self = this;

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100408;
            dialogWaitingModel.attributes.Text = resources.X100181;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);

            this.model.save(this.model.attributes, {
                silent: true,
                wait: true,
                success: function () {
                    dialogWaiting.triggerMethod('dialog:close');
                    self.oldModel = self.model.clone();

                    var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: self.model.attributes.id })
                    var render = new View_Render({ collection: customEvent, app: self.app });
                    self.app.renderRegion.show(render);
                    var renderFinishCallback = function (options) {
                    }
                    render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
                }
            });
        }
    },
    btnResetClick: function () {

    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');
    },
    customFormModelEditItem_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.eventId);
        this.app.router.customEventEditItem(this.options.eventId, this.options.scroll, 4);
    }
}, Backbone.Radio.Requests);