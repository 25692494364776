var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaSchemaListItem_empty"> <form> <div class="row"> <div class="col-md-10"> <h3><strong>'+
((__t=( model.Name ))==null?'':__t)+
'</strong></h3> </div> <div class="col-md-2"> <div class="row"> <div class="col-md-4" id="customSchemaSchemaListItem_btnUp"> <i id="customSchemaSchemaListItem_btnUpIcon" class="fa fa-lg fa-1x fa-arrow-circle-up"></i> </div> <div class="col-md-4" id="customSchemaSchemaListItem_btnDown"> <i id="customSchemaSchemaListItem_btnDownIcon" class="fa fa-lg fa-1x fa-arrow-circle-down"></i> </div> <div class="col-md-4" id="customSchemaSchemaListItem_btnExpand"> <i class="fa fa-lg fa-1x fa-plus-circle"></i> </div> </div> </div> </div> <div class="row" id="customSchemaSchemaListItem_div_editMain" style="display:none"> <div class="col-md-8"> <input class="form-control form-control-sm" style="padding-left:1px;padding-right:1px" value="'+
((__t=( model.Name ))==null?'':__t)+
'" id="customSchemaSchemaListItem_div_editMain_inputName"> </div> <div class="col-md-2"> <button type="button" class="btn btn-secondary btn-sm btn-block btnSchemaData" id="customSchemaSchemaListItem_btnEdit">'+
((__t=( resources['X100288'] ))==null?'':__t)+
'</button> </div> <div class="col-md-2"> <button type="button" class="btn btn-secondary btn-sm btn-block btnSchemaData" id="customSchemaSchemaListItem_btnDelete">'+
((__t=( resources['X100289'] ))==null?'':__t)+
'</button> </div> </div> </form> </div>';
}
return __p;
};
