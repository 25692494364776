﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

//import * as PDFObject from 'pdfobjects';

import tpl_pdf from './tpl/pdf.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';


import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({

    getTemplate: function () {
        return tpl_pdf();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.render, this);

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(userChannel, 'pdf:play', function (/*parent, */moduleDiv, moduleRegion) {
            if (this.model.attributes.ModuleDiv == moduleDiv && this.model.attributes.ModuleRegion == moduleRegion) {
            //if (this._parent.$el == parent._parent.$el) {
                switch (this.model.attributes.Play) {
                    case 1: this.play(); break;
                    case 0: this.pause(); break;
                }
            }
        });

    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'pdf_pdf': '#pdf_pdf'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        this.setupSetting();
    },
    setupStyle() {
        var param = "";

        if (this.model.attributes.Data.Slide) {
            var param = String.format('#page={0}&zoom=100&&toolbar=0&navpanes=0&scrollbar=0', this.model.attributes.Data.StartPage);
        } else {
            var param = String.format('#page={0}&zoom=100&&toolbar=0&navpanes=0&scrollbar=0', this.CurentPage);
        }
        
        var src = String.format(this.model.attributes.Data.Url, this.model.attributes.Data.InfoFile_FileUrl, param);
        this.ui.pdf_pdf.attr('src', src);
    },
    onDestroy: function () {
    },
    play: function () {
        var self = this;
        this.current = this.model.attributes.Data.StartPage-1;

        this.nextTemplate();
    },
    nextTemplate: function () {
        var self = this;

        this.current++;
        if (this.current >= (this.model.attributes.Data.EndPage + 1)) this.current = this.model.attributes.Data.StartPage;

        if (this.timeoutId) {
            clearTimeout(self.timeoutId);
            
            this.ui.pdf_pdf.on('load', function () {
                if (self.ui.pdf_pdf.attr('src') == '') {
                    var param = String.format('#page={0}&zoom=100&&toolbar=0&navpanes=0&scrollbar=0', self.current);
                    var src = String.format(self.model.attributes.Data.Url, self.model.attributes.Data.InfoFile_FileUrl, param);
                    self.ui.pdf_pdf.attr('src', src);
                }
            });

            this.ui.pdf_pdf.attr('src', '');

            /*this.ui.pdf_pdf.attr('src', '');

            this.emptyTimeoutId = setInterval(function () {
                clearTimeout(self.emptyTimeoutId);
                clearTimeout(self.timeoutId);

                var param = String.format('#page={0}&zoom=100&&toolbar=0&navpanes=0&scrollbar=0', self.current);
                var src = String.format(self.model.attributes.Data.Url, self.model.attributes.Data.InfoFile_FileUrl, param);
                self.ui.pdf_pdf.attr('src', src);
            }, 50);*/
        }

        this.timeoutId = setInterval(function () {
            self.nextTemplate();
        }, this.model.attributes.Data.Duration * 1000);
    },
    pause: function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },
}, Backbone.Radio.Requests);
