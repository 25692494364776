var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaSchemaItemHeader_empty"> <form class="form-group" style="margin-bottom: 0px"> <div class="row"> <div class="col-md-10"> <label>'+
((__t=( resources['X100157'] ))==null?'':__t)+
'</label> </div> <div class="col-md-2"> <label>'+
((__t=( resources['X100156'] ))==null?'':__t)+
'</label> </div> </div> </form> </div>';
}
return __p;
};
