'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaSchemaListItem from './tpl/customSchemaSchemaListItem.html';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';

import language from '../../../common/language';
import config from '../../../common/config';


export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customSchemaSchemaListItem({ model: this.model.toJSON() }); 
    },
    initialize: function () {
        this.app = this.options.app;
        this.schemaModel = this.options.schemaModel;
        this.settingModel = this.options.settingModel;

        this.editMode = false;
    },
    ui: {
        'customSchemaSchemaListItem_btnEdit': '#customSchemaSchemaListItem_btnEdit',
        'customSchemaSchemaListItem_btnDelete': '#customSchemaSchemaListItem_btnDelete',
        'customSchemaSchemaListItem_btnExpand': '#customSchemaSchemaListItem_btnExpand',
        'customSchemaSchemaListItem_btnUp': '#customSchemaSchemaListItem_btnUp',
        'customSchemaSchemaListItem_btnDown': '#customSchemaSchemaListItem_btnDown',
        'customSchemaSchemaListItem_btnUpIcon': '#customSchemaSchemaListItem_btnUpIcon',
        'customSchemaSchemaListItem_btnDownIcon' : '#customSchemaSchemaListItem_btnDownIcon',
        'customSchemaSchemaListItem_div_editMain': '#customSchemaSchemaListItem_div_editMain',
        'customSchemaSchemaListItem_div_editBtnBarMain': '#customSchemaSchemaListItem_div_editBtnBarMain',
        'customSchemaSchemaListItem_div_editMain_inputName': '#customSchemaSchemaListItem_div_editMain_inputName'
    },
    modelEvents: {
        "change": "modelChanged"
    },
    modelChanged: function () {
        this.render();
    },
    events: {
        'click @ui.customSchemaSchemaListItem_btnEdit': 'btnEditClick',
        'click @ui.customSchemaSchemaListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customSchemaSchemaListItem_btnExpand': 'btnExpandClick',
        'click @ui.customSchemaSchemaListItem_btnUp': 'btnUpClick',
        'click @ui.customSchemaSchemaListItem_btnDown': 'btnDownClick'
    },
    onRender: function () {
        this.showBtnUp();
        this.showBtnDown();

        return this;
    },
    showBtnUp: function () {
        var self = this;
        var show = false;
        this.model.collection.forEach(function (entry) {
            if (entry.attributes.id != self.model.attributes.id) {
                if (entry.attributes.Position < self.model.attributes.Position) {
                    show = true;
                }
            }
        });

        if (show) {
            this.ui.customSchemaSchemaListItem_btnUpIcon.show();
        }
        else {
            this.ui.customSchemaSchemaListItem_btnUpIcon.hide();
        }
    },
    showBtnDown: function () {
        var self = this;
        var show = false;
        this.model.collection.forEach(function (entry) {
            if (entry.attributes.id != self.model.attributes.id) {
                if (entry.attributes.Position > self.model.attributes.Position) {
                    show = true;
                }
            }
        });

        if (show) {
            this.ui.customSchemaSchemaListItem_btnDownIcon.show();
        }
        else {
            this.ui.customSchemaSchemaListItem_btnDownIcon.hide();
        }
    },
    btnEditClick: function (event) {
        event.preventDefault();
        this.editMode = false;

        var value = this.ui.customSchemaSchemaListItem_div_editMain_inputName.val();
        this.model.set('Name', value);
        this.model.save({ wait : true });

        this.model.collection.trigger('change');
        this.model.collection.trigger('customSchemaSchema_edit');
    },
    btnUpClick: function (event) {
        event.preventDefault();

        var oldPosition = this.model.attributes.Position;
        var position = oldPosition - 1;

        var tmpCollection = this.model.collection.where({ Position: position, EventId : this.model.attributes.EventId });

        if (tmpCollection.length > 0) {
            this.model.set('Position', position);
            this.model.save({ wait : true });
            tmpCollection[0].set('Position', oldPosition);
            tmpCollection[0].save({ wait : true });
        }
        else {
            this.model.set('Position', position);
            this.model.save({ wait : true });
        }

        this.model.collection.sort();
        this.model.collection.trigger('change');
    },
    btnDownClick: function (event) {
        event.preventDefault();

        var oldPosition = this.model.attributes.Position;
        var position = oldPosition + 1;

        var tmpCollection = this.model.collection.where({ Position: position, EventId: this.model.attributes.EventId });

        if (tmpCollection.length > 0) {
            this.model.set('Position', position);
            this.model.save({ wait : true });
            tmpCollection[0].set('Position', oldPosition);
            tmpCollection[0].save({ wait : true });
        }
        else {
            this.model.set('Position', position);
            this.model.save( { wait : true });
        }

        this.model.collection.sort();
        this.model.collection.trigger('change');
    },
    btnDeleteClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100250;
        dialog2ButtonModel.attributes.Text = resources.X100255;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
        var callback01 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var collection = self.model.collection;

            self.model.destroy({
                wait: true,
                success: function (model, response) {
                    collection.trigger('change');
                    collection.trigger('customSchemaSchema_remove', response);
                },
                error: function (model, response) {
                }
            });
        }

        self.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        self.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(dialog2Button);
    },
    btnExpandClick: function (event) {
        event.preventDefault();
        this.editMode = !this.editMode;
        if (this.editMode == true) {
            this.ui.customSchemaSchemaListItem_div_editMain.show();
            this.ui.customSchemaSchemaListItem_div_editBtnBarMain.show();
        }
        else if (this.editMode == false) {
            this.ui.customSchemaSchemaListItem_div_editMain.css('display', 'none');
            this.ui.customSchemaSchemaListItem_div_editBtnBarMain.css('display', 'none');
        }
    }
});