﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

//No internet
require('typeahead-addresspicker');

import tpl_customCrediential from './tpl/customCrediential.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_CustomCrediential from '../../entities/customCrediential';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customCrediential({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.createDefaultQRCodeExeceute = false;        
        this.createDefaultSetting = false;        
        
        this.setHasChanged(false);
        this.oldModel = this.model.clone();
    },
    help: function () {
        Help.help(this, true, 'customCrediential');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick();
        callback();
    },
    cancelHasChanged : function() {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged : function () {
        var changes = false;

        //this.setModel();

        ////If culture has change do not ask to save when goes to another menu item
        //if ((this.model.attributes.Email == this.oldModel.attributes.Email) &&
        //    (this.model.attributes.Password == this.oldModel.attributes.Password)) {
        //    changes = false;
        //    this.btnSubmitClick();
        //} else if ((this.model.attributes.Email != this.oldModel.attributes.Email) &&
        //    (this.model.attributes.Password != this.oldModel.attributes.Password)) {
        //    changes = true;
        //}

        this.setHasChanged(changes);
    },
    regions: {
        information: "#customInformation"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app  }));
    },
    ui: {
        'customCrediential_inputEmail' : '#customCrediential_inputEmail',
        'customCrediential_inputPassword' : '#customCrediential_inputPassword',
        'customCrediential_inputPasswordConfirm' : '#customCrediential_inputPasswordConfirm',
        'customCrediential_btnSubmit': '#customCrediential_btnSubmit',
        'customCrediential_btnDeleteAccount': '#customCrediential_btnDeleteAccount',
        'customCrediential_btnCancel': '#customCrediential_btnCancel',
        'customCrediential_btnBack': '#customCrediential_btnBack',
        'help': '#help'
    },
    events: {
        'click #customCrediential_btnSubmit': 'btnSubmitClick',
        'click #customCrediential_btnDeleteAccount': 'btnDeleteAccountClick',
        'click #customCrediential_btnCancel': 'btnCancelClick',
        'click #customCrediential_btnBack': 'btnBackClick',
        'click #help': 'help'
    },
    onRender: function () {
        var self = this;
        //this.renderInformation();
    },
    onAttach(){
    },
    onDestroy(){
    },
    renderInformation: function () {
        this.informationCollection.reset();
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    btnSubmitClick: function (event) {
        var self = this;
        this.hideErrors();

        if (event != null) {
            event.preventDefault();
        }

        if (this.setModel()) {
            this.model.attributes.ChangeEmail = false;
            if (this.model.attributes.Email != this.oldModel.attributes.Email) {
                this.model.attributes.ChangeEmail = true;
            }

            this.model.attributes.ChangePassword = false;
            if ((this.model.attributes.Password != this.oldModel.attributes.Password) || (this.model.attributes.PasswordConfirm != this.oldModel.attributes.PasswordConfirm)) {
                this.model.attributes.ChangePassword = true;
            }

            var callback = function (ret) {
                if (ret == true) {
                    self.save();
                } else  {
                    self.showErrors(self.model.errors);
                    $('html,body').scrollTop(0);
                } 
            }
            this.model.validateNoBackbone(this.model.attributes, this.app, callback, this.model.attributes.ChangeEmail, this.model.attributes.ChangePassword);
        }
    },
    btnDeleteAccountClick : function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = resources.X100745;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function () {
            dialog2Button.triggerMethod('dialog:close');
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100744;
            dialogWaitingModel.attributes.Text = resources.X100746;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
            var callbackWaiting = function () {
                self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

            //Pause
            var timerId = window.setInterval(function () {
                self.app.dialogRegion.show(dialogWaiting);
                clearInterval(timerId);

                var customCredentialModel = new Entities_CustomCrediential.Model();
                customCredentialModel.id = self.model.id;
                customCredentialModel.attributes.id = self.model.id;
                customCredentialModel.attributes.AuthUserId = self.model.attributes.AuthUserId;
            
                customCredentialModel.destroy({
                    wait: true,
                    //silent : true,
                    success: function (model, response) {
                        dialogWaiting.triggerMethod('dialog:close');

                        var dialogBoxModel = new Entities_Empty.Model();
                        dialogBoxModel.attributes.Title = resources.X100739;
                        dialogBoxModel.attributes.Text = resources.X100747;
                        dialogBoxModel.attributes.Button = resources.X100279;

                        var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                        var callbackDialogBox = function () {
                            self.stopListening(dialogBox, "dialog:okclicked", callbackDialogBox);

                            mgr.redirectForLogout();
                        }
                        self.listenTo(dialogBox, "dialog:okclicked", callbackDialogBox);
                        self.app.dialogRegion.show(dialogBox);
                    },
                    error: function (model, response) {
                        alert("");
                    }
                });
            }, 1000);

            
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function () {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnCancelClick: function (event) {
        this.ui.customCrediential_inputPassword.val('');
        this.ui.customCrediential_inputPasswordConfirm.val('');
    },
    btnBackClick: function (event) {
        this.app.router.navigate("#custom");
        this.app.router.custom();
    },
    setModel: function () {
        this.model.set({
            Email: this.ui.customCrediential_inputEmail.val(),
            Password: this.ui.customCrediential_inputPassword.val(),
            PasswordConfirm: this.ui.customCrediential_inputPasswordConfirm.val()
        });

        return true;
    },
    save: function () {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100739;
        dialogWaitingModel.attributes.Text = resources.X100742;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        self.listenTo(dialogWaiting, "dialog:okclicked", callback);
        self.app.dialogRegion.show(dialogWaiting);
        
        self.model.save(self.model.attributes, {
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');

                var dialogBoxModel = new Entities_Empty.Model();
                dialogBoxModel.attributes.Title = resources.X100739;
                dialogBoxModel.attributes.Text = resources.X100743;
                dialogBoxModel.attributes.Button = resources.X100279;

                var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                var callback2 = function () {
                    self.stopListening(dialogBox, "dialog:okclicked", callback2);

                    mgr.redirectForLogout();
                }
                self.listenTo(dialogBox, "dialog:okclicked", callback2);
                self.app.dialogRegion.show(dialogBox);
            }
        });
    }
}, Backbone.Radio.Requests);
