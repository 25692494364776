var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogRouter2Button_empty"> <div class="modal fade" id="dialogRouter2Button_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <p style="">'+
((__t=( model.Text ))==null?'':__t)+
'</p> </div> <div class="modal-footer"> <button type="button" id="dialogRouter2Button_btn01" data-dismiss="modal" class="btn btn-primary">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> <button type="button" id="dialogRouter2Button_btn02" data-dismiss="modal" class="btn btn-secondary">'+
((__t=( model.Button02 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
