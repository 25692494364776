﻿const oidc = require('../../plugin/oidc-client-ts');
import config from '../../common/config';

var HelperAuth = {
    signin: function (app) {
        app.userManager.signinRedirect({
            extraQueryParams: {
                //ui_locales: config.cultureName(),
                ui_locales: 'da-DK',
                culture: 'da-DK',
                'ui-culture': 'da-DK'
            }
        });
    },
    login: function (app, languageCallback, languageLoginCallback, language, luxaforboard_userCultureName) {
        app.userManager = new oidc.UserManager(config.getAuthConfig());

        if (app.userManager !== undefined) {
            window.userManager = self.userManager;
            (async () => {
                app.user = await app.userManager.getUser();

                if (app.user && app.user.access_token) {
                    window.user = app.user;
                    if (app.user.profile !== undefined) {
                        window.luxaforboard.config.authUserId = self.user.profile.sub;

                        language.setLanguage(luxaforboard_userCultureName, languageCallback);
                    }
                }
                else {
                    language.setLanguage(luxaforboard_userCultureName, languageLoginCallback);
                }
            })();
        }
    },
    logout: function (app) {
        app.userManager.signoutRedirect({
            extraQueryParams: {
                ui_locales: 'da-DK',//config.cultureName()
                culture: 'da-DK'

            }
        }).then(res => {
                console.log('Redirection to signout triggered.', res);
            })
    }



}

export default HelperAuth;    