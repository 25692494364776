﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as crypto from 'crypto-js';

import tpl_customDepartmentUserListItem from './tpl/customDepartmentUserListItem.html';
import language from '../../../common/language';
import View_CustomDepartmentUserListItem from './customDepartmentUserListItem';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import helper_createWebpage from '../../helper/createWebpage';

//import * as plugins_qrcode from '../../plugins/qrcode';

export default Marionette.ItemView.extend({
    attributes: function () {
        // Return model data
        return {
            //class: this.model.attributes.id
            //style: this.getStyleLi(),
            'data-id': this.model.attributes.id
            //id: 'customWebpageEditItemListItem_li_' + this.model.attributes.id

        }

    },
    getTemplate: function () {
        return tpl_customDepartmentUserListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'customDepartmentUserListItem_btnDelete': '#customDepartmentUserListItem_btnDelete',
        'customDepartmentUserListItem_btnEdit': '#customDepartmentUserListItem_btnEdit',
        'customDepartmentUserListItem_btnPreview': '#customDepartmentUserListItem_btnPreview',
        'customDepartmentUserListItem_aQRCode': '#customDepartmentUserListItem_aQRCode',
        'customDepartmentUserListItem_profileImage': '#customDepartmentUserListItem_profileImage',
        'customDepartmentUserListItem_imageUrl': '#customDepartmentUserListItem_imageUrl',
        'customDepartmentUserListItem_div': '#customDepartmentUserListItem_div',
        'customQRCodeEditItem_qrCodeContainer': '#customQRCodeEditItem_qrCodeContainer',
        'customQRCodeEditItem_container': '#customQRCodeEditItem_container',
        'customQRCodeInsert_qrCodeContainer': '#customQRCodeInsert_qrCodeContainer',
        'customQRCodeInsert_container': '#customQRCodeInsert_container',
        'customQRCodeInsert_qrCodeLogo': '#customQRCodeInsert_qrCodeLogo',
        'render_empty': '#render_empty',
        'customEventRender_container': '#customEventRender_container',
        'customEventRender_qrCodeLogo': '#customEventRender_qrCodeLogo',
        'customEventRender_qrCodeContainer': '#customEventRender_qrCodeContainer'
    },
    events: {
        'click @ui.customDepartmentUserListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customDepartmentUserListItem_btnEdit': 'btnEditClick',
        'click @ui.customDepartmentUserListItem_btnPreview': 'btnPreviewClick',
        'click @ui.customDepartmentUserListItem_aQRCode': 'aQRCodeClick'
    },
    onRender: function () {

        var nameArray = this.model.attributes.Name.split(' ', 4);
        var intials = '';

        if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
        if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        this.ui.customDepartmentUserListItem_profileImage.text(intials);

        if (this.model.attributes.Color != null) {
            this.ui.customDepartmentUserListItem_div.css('border-left-color', this.model.attributes.Color);
            //this.ui.customUserListItem_profileImage.css('background', this.model.attributes.Color);
        }
        else {
            this.ui.customDepartmentUserListItem_div.css('border-left-color', '#ffffff');
            //this.ui.customUserListItem_profileImage.css('background', '#512DA8');
        }

        if (this.model.attributes.ImageUrl != '' && this.model.attributes.ImageUrl.length > 0) {
            this.ui.customDepartmentUserListItem_imageUrl.show();
            this.ui.customDepartmentUserListItem_profileImage.css('display', 'none');
        } else {
            this.ui.customDepartmentUserListItem_imageUrl.css('display', 'none');
            this.ui.customDepartmentUserListItem_profileImage.show();
        }
    },
    onAttach() {
        var nameArray = this.model.attributes.Name.split(' ', 4);
        var intials = '';

        if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
        if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        this.ui.customDepartmentUserListItem_profileImage.text(intials);

        if (this.model.attributes.Color != null) {
            this.ui.customDepartmentUserListItem_div.css('border-left-color', this.model.attributes.Color);
            //this.ui.customUserListItem_profileImage.css('background', this.model.attributes.Color);
        }
        else {
            this.ui.customDepartmentUserListItem_div.css('border-left-color', '#ffffff');
            //this.ui.customUserListItem_profileImage.css('background', '#512DA8');
        }

        //this.makeQRCodeModel(this.app, 'Luxaforboard');
    },
    btnEditClick: function () {
        var url = 'customDepartmentUserEditItem/' + this.model.attributes.id;
        this.app.router.navigate(url);
        this.app.router.customDepartmentUserEditItem(this.model.attributes.id);

    },
    aQRCodeClick: function () {
        this.btnEditClick();
    },
    btnDeleteClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200122;
        dialog2ButtonModel.attributes.Text = resources.X200154;
        dialog2ButtonModel.attributes.Button01 = resources.X200095;
        dialog2ButtonModel.attributes.Button02 = resources.X200096;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {

            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200122;
            dialogWaitingModel.attributes.Text = resources.X200098;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
            var callbackWaiting = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

            self.app.dialogRegion.show(dialogWaiting);

            var collection = self.app.entities.collections.customDepartmentUser;

            self.model.destroy({
                wait: true,
                //silent: true,
                success: function (model, response) {
                    var callbackDelete = function () {
                        collection.fullCollection.sort();
                        if (collection.state.currentPage < collection.state.lastPage) {
                            collection.getPage(collection.state.currentPage);
                        }
                        else {
                            collection.getLastPage();
                        }

                        dialogWaiting.triggerMethod('dialog:close');
                    }

                    callbackDelete();
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);
        this.app.dialogRegion.show(dialog2Button);
    },
    makeQRCodeModel: function (app, name) {
        var self = this;
        var tmpModel = new Entities_Empty.Model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = name;

        tmpModel.attributes.Render = "canvas";
        tmpModel.attributes.EcLevel = "H";
        tmpModel.attributes.MinVersion = 6;
        tmpModel.attributes.Fill = "Rgb(0,0,0)";
        tmpModel.attributes.Background = "Rgb(255,255,255)";
        tmpModel.attributes.Text = resources.X200355 + this.options.parentModel.attributes.id +
            resources.X200356 + this.app.entities.models.custom.attributes.SecretKey +
            resources.X200357 + this.app.entities.models.custom.attributes.SecretCode +
            resources.X200358 + this.model.attributes.LuxaforId +
            resources.X200359 + this.model.attributes.CallerId;

        var id = tmpModel.attributes.Text.substring(
            tmpModel.attributes.Text.indexOf(resources.X200355) + resources.X200355.length,
            tmpModel.attributes.Text.lastIndexOf(resources.X200356)
        );

        var secretkey = tmpModel.attributes.Text.substring(
            tmpModel.attributes.Text.indexOf(resources.X200356) + resources.X200356.length,
            tmpModel.attributes.Text.lastIndexOf(resources.X200357)
        );

        var secretcode = tmpModel.attributes.Text.substring(
            tmpModel.attributes.Text.indexOf(resources.X200357) + resources.X200357.length,
            tmpModel.attributes.Text.lastIndexOf(resources.X200358)
        );

        var luxaforid = tmpModel.attributes.Text.substring(
            tmpModel.attributes.Text.indexOf(resources.X200358) + resources.X200358.length,
            tmpModel.attributes.Text.lastIndexOf(resources.X200359)
        );

        var callerid = tmpModel.attributes.Text.substring(
            tmpModel.attributes.Text.indexOf(resources.X200359) + resources.X200359.length,
            tmpModel.attributes.Text.length
        );

        var checkSum = crypto.MD5(tmpModel.attributes.Text);

        tmpModel.attributes.Text += resources.X200363 + checkSum;


        const CryptoJS = require('crypto-js');

        const salt = CryptoJS.lib.WordArray.random(16);
        const iv = CryptoJS.lib.WordArray.random(16);

        const key = CryptoJS.PBKDF2('my password', salt, { keySize: 256 / 32, iterations: 10000, hasher: CryptoJS.algo.SHA256 });

        const encrypted = CryptoJS.AES.encrypt(tmpModel.attributes.Text, key, { iv: iv }).ciphertext;

        const concatenned = CryptoJS.lib.WordArray.create().concat(salt).concat(iv).concat(encrypted)

        console.log({
            iv: iv.toString(CryptoJS.enc.Base64),
            salt: salt.toString(CryptoJS.enc.Base64),
            encrypted: encrypted.toString(CryptoJS.enc.Base64),
            concatenned: concatenned.toString(CryptoJS.enc.Base64)
        });


        //const CryptoJS = require('crypto-js');

        //const salt = CryptoJS.lib.WordArray.random(16);
        //const iv = CryptoJS.lib.WordArray.random(16);

        //const key = CryptoJS.PBKDF2('my password', salt, { keySize: 256 / 32, iterations: 10000, hasher: CryptoJS.algo.SHA256 });

        //const encrypted = CryptoJS.AES.encrypt('That is our super secret text', key, { iv: iv }).ciphertext;

        //const concatenned = CryptoJS.lib.WordArray.create().concat(salt).concat(iv).concat(encrypted)

        //console.log({
        //    iv: iv.toString(CryptoJS.enc.Base64),
        //    salt: salt.toString(CryptoJS.enc.Base64),
        //    encrypted: encrypted.toString(CryptoJS.enc.Base64),
        //    concatenned: concatenned.toString(CryptoJS.enc.Base64)
        //});


        ////const encrypted1_string = concatenned.toString(CryptoJS.enc.Base64);
        ////const encrypted1 = CryptoJS.enc.Base64.parse(encrypted1_string);
        //const encrypted1 = CryptoJS.enc.Base64.parse('OkEt2koR5ChtmYCZ0dXmHaDB50hEBq69OKfL5qylO5aMA5vQvCmZpfnWm/xKdPJNxabZHXgZCPQE3u0WMQ2WJQ==');



        //const salt_len = 16;
        //const iv_len = 16;

        //const salt1 = CryptoJS.lib.WordArray.create(
        //    encrypted.words.slice(0, salt_len / 4)
        //);
        //const iv1 = CryptoJS.lib.WordArray.create(
        //    encrypted.words.slice(0 + salt_len / 4, (salt_len + iv_len) / 4)
        //);

        //const key1 = CryptoJS.PBKDF2(
        //    'my password',
        //    salt1,
        //    { keySize: 256 / 32, iterations: 10000, hasher: CryptoJS.algo.SHA256 }
        //);

        //const decrypted1 = CryptoJS.AES.decrypt(
        //    {
        //        ciphertext: CryptoJS.lib.WordArray.create(
        //            encrypted1.words.slice((salt_len + iv_len) / 4)
        //        )
        //    },
        //    key1,
        //    { iv: iv1 }
        //);


        //var aaa = decrypted1.toString(CryptoJS.enc.Utf8);

        //const CryptoJS = require('crypto-js');

        //const encrypted = CryptoJS.enc.Base64.parse('OkEt2koR5ChtmYCZ0dXmHaDB50hEBq69OKfL5qylO5aMA5vQvCmZpfnWm/xKdPJNxabZHXgZCPQE3u0WMQ2WJQ==');

        //const salt_len = 16;
        //const iv_len = 16;

        //const salt = CryptoJS.lib.WordArray.create(
        //    encrypted.words.slice(0, salt_len / 4)
        //);
        //const iv = CryptoJS.lib.WordArray.create(
        //    encrypted.words.slice(0 + salt_len / 4, (salt_len + iv_len) / 4)
        //);

        //const key = CryptoJS.PBKDF2(
        //    'my password',
        //    salt,
        //    { keySize: 256 / 32, iterations: 10000, hasher: CryptoJS.algo.SHA256 }
        //);

        //const decrypted = CryptoJS.AES.decrypt(
        //    {
        //        ciphertext: CryptoJS.lib.WordArray.create(
        //            encrypted.words.slice((salt_len + iv_len) / 4)
        //        )
        //    },
        //    key,
        //    { iv: iv }
        //);

        //var aaa = decrypted.toString(CryptoJS.enc.Utf8);
        //console.log(decrypted.toString(CryptoJS.enc.Utf8));

        //var a = crypto.AES.encrypt(tmpModel.attributes.Text, "1234").toString();
        //var b = crypto.AES.decrypt(a, "1234").toString(crypto.enc.Utf8);

        //var a1 = crypto.AES.encrypt({ ciphertext : tmpModel.attributes.Text }, "1234", {
        //    iv: crypto.enc.Hex.parse('00000000000000000000000000000000'),
        //    mode: crypto.mode.CBC,
        //    padding: crypto.pad.Pkcs7}).toString();
        //var b1 = crypto.AES.decrypt(a, "1234").toString(crypto.enc.Utf8);

        tmpModel.attributes.Text = resources.X200364 + concatenned.toString(CryptoJS.enc.Base64);

        //resources.X100068;
        tmpModel.attributes.Size = 200;
        tmpModel.attributes.Radius = 0;
        tmpModel.attributes.Quiet = 1;
        tmpModel.attributes.Mode = 0;
        tmpModel.attributes.MSize = 10;
        tmpModel.attributes.MPosX = 50;
        tmpModel.attributes.MPosY = 50;
        tmpModel.attributes.Label = "Events4us";
        tmpModel.attributes.FontName = "Arial,Arial,Helvetica,sans-serif";
        tmpModel.attributes.FontColor = "Rgb(255,0,0)";
        tmpModel.attributes.FontStyle = 0;
        tmpModel.attributes.FontSize = "30px";
        tmpModel.attributes.Logo = "/images/favicon/favicon-32x32.png";

        this.ui.customQRCodeInsert_qrCodeLogo.attr("src", "/images/favicon/android-icon-48x48.png");

        //$('#customQRCodeInsert_qrCodeLogo').off("load");
        this.ui.customQRCodeInsert_qrCodeLogo.load(function () {
            var tmpModelQRCode = new Entities_Empty.Model();
            //tmpModelQRCode.attributes.id = -1;

            tmpModelQRCode = helper_createWebpage.createQRCodeImage(tmpModel, tmpModelQRCode, self.ui.customQRCodeInsert_qrCodeLogo, self.ui.customQRCodeInsert_qrCodeContainer, false, app);

            tmpModel.attributes.ImageData = tmpModelQRCode.attributes.ImageData;
            tmpModel.attributes.ImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
            tmpModel.attributes.ImageUpdate = tmpModelQRCode.attributes.ImageUpdate;
            tmpModel.attributes.Filename = tmpModel.attributes.Name + '_' + new Date().getTime() + '.jpg';

            self.ui.customQRCodeInsert_container.empty();
            self.ui.customQRCodeInsert_qrCodeLogo.off("load");

            //callback(tmpModel);
        });

        $('#customQRCodeInsert_qrCodeLogo').attr("src", tmpModel.attributes.Logo);

        //return tmpModel;
    },
});
