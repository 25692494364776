﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
//import dummy from '../../../../app/plugins';


import tpl_htmlSlideListList from './tpl/htmlSlideListList.html';
import View_HtmlSlideListListItem from './htmlSlideListListItem';

export default Marionette.CollectionView.extend({
    tagName: 'div',
    className: '',
    attributes: function () {
        return {
            id: 'htmlSlideListListItem'
        }
    },
    childViewContainer: '#htmlSlideListListItem',
    childView: View_HtmlSlideListListItem,
    getTemplate: function () {
        return tpl_htmlSlideListList();
    },
    ui: {
        'htmlSlideListListItem': '#htmlSlideListListItem'
    },

    initialize: function () {
        this.app = this.options.app;
        
    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        options.parentModel = this.options.parentModel;
        var view = new ChildViewClass(options);
        return view;
    },
    onAttach() {
        var self = this;
        
        $('#htmlSlideListList').find('#htmlSlideListListItem').sortable({
            items: '.myDiv',
            //handle:'.myDiv',
            stop: function (event, ui) {
                var position = 0;
                $('#htmlSlideListListItem .myDiv').each(function (index, item) {
                    var id = parseInt($(item).attr('data-id'));
                    if (id != undefined) {
                        var model = self.collection.where({ id: id })[0];
                        model.set({ Position: position });
                        $(item).attr('data-position', position);
                        position++;
                    }
                });
            }
        });

        $('#pictureSlideList').find('#htmlSlideListListItem_tr').each(function (index, item) {
            $(item).addClass('ui-state-default');
            $(item).addClass('ui-sortable-handle');
        });
    }
});
