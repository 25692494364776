var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventLive_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customAdSetting">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customAdSettingInformation" class="e4usInformation" style="display:none"></div> <div id="customAdSetting_divAd" disable="true"> <h4 class="card-title">'+
((__t=( resources['X101468'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="form-group" style="display:none"> <div class="row"> <div class="control-label col-md-6"> <label for="customAdSetting_selectAdImageId">'+
((__t=( resources['X100956'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customAdSetting_selectAdImageId" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customAdSetting_btnAd">'+
((__t=( resources['X100956'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <img id="customAdSetting_img_AdImage" style="width:320px;height:53px" src=""> </div> </div> <div class="" style="min-height:10px"></div> <div class="control-group customAdSetting_inputAdImageUrl"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customAdSetting_inputAdImageUrl" style="padding-top:12px">'+
((__t=( resources['X100957'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customAdSetting_inputAdImageUrl" style="margin-bottom:0px" value="'+
((__t=( model.AdImageUrl ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customAdSetting_AdStatus" style="padding-top:12px">'+
((__t=( resources['X100958'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label style="padding-top:12px" id="customAdSetting_labelAdStatus"></label> </div> </div> </div> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customAdSetting_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customAdSetting_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customAdSetting_divAdBig"> <h4 class="card-title">'+
((__t=( resources['X101469'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="form-group" style="display:none"> <div class="row"> <div class="control-label col-md-6"> <label for="customAdSetting_selectAdBigImageId">'+
((__t=( resources['X100956'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customAdSetting_selectAdBigImageId" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customAdSetting_btnAdBig">'+
((__t=( resources['X100956'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <img id="customAdSetting_img_AdBigImage" style="width:320px;height:320px" src=""> </div> </div> <div class="" style="min-height:10px"></div> <div class="control-group customAdSetting_inputAdBigImageUrl"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customAdSetting_inputAdBigImageUrl" style="padding-top:12px">'+
((__t=( resources['X100957'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customAdSetting_inputAdBigImageUrl" style="margin-bottom:0px" value="'+
((__t=( model.AdBigImageUrl ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customAdSetting_AdBigStatus" style="padding-top:12px">'+
((__t=( resources['X100958'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label style="padding-top:12px" id="customAdSetting_labelAdBigStatus"></label> </div> </div> </div> </form> </div> </div> </div> </div> </div> </div> </div> ';
}
return __p;
};
