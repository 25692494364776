﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_languageSelectorList from './tpl/languageSelectorList.html';
import View_LanguageSelectorListItem from './languageSelectorListItem';

export default Marionette.CollectionView.extend({
    tagName: 'div',
    className: 'dropdown-menu dropdown-menu-right navbar-dropdown preview-list',
    childViewContainer: '#languageSelectorListItem',
    attributes: function () {
        return {
            id: 'languageSelectorListItem',
        }
    },
    childView: View_LanguageSelectorListItem,
    getTemplate: function () {
        return tpl_languageSelectorList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    },
    addChild: function (model, ChildView, index) {
        return Marionette.CollectionView.prototype.addChild.call(this, model, ChildView, index);
    },
    ui: {
         'languageSelectorListItem': '#languageSelectorListItem'
    },
    events: {
        'click @ui.languageSelectorListItem': 'languageSelectorDropdownChange',
    },
    languageSelectorDropdownChange: function (event) {
        event.preventDefault();

        this.model = self.app.entities.collections.languageSetting.where({ Name: this.ui.languageSelectorDropdown.val().trim() })[0];
        language.userLanguageChange(this.model.attributes.Code);
    }
});
