var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogPreviewWebpage_empty"> <div class="modal fade" id="dialogPreviewWebpage_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog" style="width:580px !important; max-width: 580px !important"> <div class="modal-header"> <h5 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="" style="background-image: url(\'/images/IPhone5PreviewV2.png\'); width: 549px; height: 593px; z-index:100000;overflow:hidden"> <div style="overflow:auto;-webkit-overflow-scrolling:touch;height:553px;position:relative;top:40px;overflow-y: scroll"> <iframe id="dialogPreviewWebpage_iframe" src="'+
((__t=( model.Iframe ))==null?'':__t)+
'" frameborder="0" style="zoom:0.5;margin-left:46px;width:460px; height:540px;z-index:0"></iframe> </div> </div> </div> <div class="modal-footer"> <button type="button" id="dialogPreviewWebpage_btnOk" class="btn btn-primary">'+
((__t=( model.Button ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
