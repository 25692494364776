var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventEditItem_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-mobile-alt" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100070'] ))==null?'':__t)+
' - '+
((__t=( model.Name ))==null?'':__t)+
'</h5> <div id="customEventInformation"></div>  <div class="row"> <div class="col-md-6"> <div class="" style="text-align: center"> <img id="customEventEditItem_webpageImageUrl" style="height:50%; width:50%" alt="" src="'+
((__t=( model.WebpageImageUrl ))==null?'':__t)+
'"> </div> </div> <div class="col-md-6"> <div style="height:100px"></div> <div class="" style="text-align: center"> <img id="customEventEditItem_QRCodeImageUrl" style="height:150px;width:150px" alt="" src="'+
((__t=( model.QRCodeImageUrl ))==null?'':__t)+
'"> </div> </div> </div> </form> <form class="contact-form">  <div class=""> <div class="control-group customEventEditItem_inputName"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputName">'+
((__t=( resources['X100351'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customEventEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="control-group customEventEditItem_inputEventName"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputEventName">'+
((__t=( resources['X100414'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" value="'+
((__t=( model.EventName))==null?'':__t)+
'" id="customEventEditItem_inputEventName"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group customEventEditItem_textarea_description"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_textarea_description">'+
((__t=( resources['X100468'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <textarea class="form-control" id="customEventEditItem_textarea_description" style="height:78px">'+
((__t=( model.Description))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="control-group customEventEditItem_selectWebpage"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectWebpage">'+
((__t=( resources['X100347'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectWebpage" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="control-group customEventEditItem_selectInfopage"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectInfopage">'+
((__t=( resources['X100453'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectInfopage" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="control-group customEventEditItem_selectQRCode"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectQRCode">'+
((__t=( resources['X100348'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectQRCode" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="control-group customEventEditItem_selectSchemaModel"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputSchemaMenuName"> <span class="help-inline" style="color:red"></span> </div> <div class="panel-group" id="accordion"> <div class="panel panel-default"> <div class="panel-heading" id="customEventEditItem_div_Schema"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse01"> '+
((__t=( resources['X100113'] ))==null?'':__t)+
' </a> </h4> </div> <div id="collapse01" class="panel-collapse collapse"> <div class="panel-body"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectSchemaModel">'+
((__t=( resources['X100349'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectSchemaModel" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group" id="customEventEditItem_divSchemaMenuName"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100102'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputSchemaMenuName" style="margin-bottom:0px" value="'+
((__t=( model.SchemaMenuName ))==null?'':__t)+
'"> </div> </div> </div> </div> </div> </div> <div class="control-group customEventEditItem_selectFormModel"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputFormMenuName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputFormSubmitBtnName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputFormCancelBtnName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputFormSubmitBtnSucces"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputFormNotifyEmailAddress"> <span class="help-inline" style="color:red"></span> </div> <div class="panel panel-default"> <div class="panel-heading" id="customEventEditItem_div_Form"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse02"> '+
((__t=( resources['X100114'] ))==null?'':__t)+
' </a> </h4> </div> <div id="collapse02" class="panel-collapse collapse"> <div class="panel-body"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectFormModel">'+
((__t=( resources['X100350'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectFormModel" style="font-size:12px"></select> </div> </div> </div> <div class="form-group" id="customEventEditItem_divFormMenuName"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100106'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputFormMenuName" style="margin-bottom:0px" value="'+
((__t=( model.FormMenuName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100107'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputFormSubmitBtnName" style="margin-bottom:0px" value="'+
((__t=( model.FormSubmitBtnName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100391'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputFormCancelBtnName" style="margin-bottom:0px" value="'+
((__t=( model.FormCancelBtnName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100108'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputFormSubmitBtnSucces" style="margin-bottom:0px" value="'+
((__t=( model.FormSubmitBtnSucces ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectFormNotifyEmail">'+
((__t=( resources['X100427'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectFormNotifyEmail" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100466'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100467'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100428'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputFormNotifyEmailAddress" style="margin-bottom:0px" value="'+
((__t=( model.FormNotifyEmailAddress ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> <div class="control-group customEventEditItem_inputProductSheetMenuName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputProductSheetPreviusBtnName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputProductSheetNextBtnName"> <span class="help-inline" style="color:red"></span> </div> <div class="panel panel-default productSheet"> <div class="panel-heading" id="customEventEditItem_div_ProductSheet"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse03"> '+
((__t=( resources['X101113'] ))==null?'':__t)+
' </a> </h4> </div> <div id="collapse03" class="panel-collapse collapse"> <div class="panel-body"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectProductSheetModel">'+
((__t=( resources['X101114'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectProductSheetModel" style="font-size:12px"></select> </div> </div> </div> <div class="form-group" id="customEventEditItem_divProductSheetMenuName"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X101115'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputProductSheetMenuName" style="margin-bottom:0px" value="'+
((__t=( model.ProductSheetMenuName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X101116'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputProductSheetPreviusBtnName" style="margin-bottom:0px" value="'+
((__t=( model.ProductSheetPreviusBtnName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X101117'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputProductSheetNextBtnName" style="margin-bottom:0px" value="'+
((__t=( model.ProductSheetNextBtnName ))==null?'':__t)+
'"> </div> </div> </div> </div> </div> </div> <div class="control-group customEventEditItem_inputFriendlyUrl"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputPubliclyAddress"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_inputInformation"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_selectFacebook"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_selectCopyright"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customEventEditItem_selectUseDate"> <span class="help-inline" style="color:red"></span> </div> <div class="panel panel-default"> <div class="panel-heading" id="customEventEditItem_div_Public"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse04"> '+
((__t=( resources['X100115'] ))==null?'':__t)+
' </a> </h4> </div> <div id="collapse04" class="panel-collapse collapse"> <div class="panel-body"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectPublicly">'+
((__t=( resources['X100041'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectPublicly" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputFriendlyUrl">'+
((__t=( resources['X100354'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" value="'+
((__t=( model.FriendlyUrl))==null?'':__t)+
'" id="customEventEditItem_inputFriendlyUrl"> </div> </div> <div class="alert alert-warning hidden" id="custom_alertWarningWrongAddress"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> <strong>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</strong> '+
((__t=( resources['X100287'] ))==null?'':__t)+
' </div> <div class="form-group" id="customEventEditItem_inputPubliclyAddress_div"> <div class="row"> <div class="control-label col-md-12"> <label for="customEventEditItem_inputPubliclyAddress">'+
((__t=( resources['X100042'] ))==null?'':__t)+
'</label> <input class="form-control" id="customEventEditItem_inputPubliclyAddress" value="'+
((__t=( model.PubliclyAddress ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group"> <label for="customEventEditItem_inputInformation">'+
((__t=( resources['X100047'] ))==null?'':__t)+
'</label> <input class="form-control" value="'+
((__t=( model.Information))==null?'':__t)+
'" id="customEventEditItem_inputInformation"> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectFacebook">'+
((__t=( resources['X100462'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectFacebook" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100463'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100464'] ))==null?'':__t)+
'</option> <option value="2">'+
((__t=( resources['X100465'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> <div id="customEventEditItem_divCopyright"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectCopyright">'+
((__t=( resources['X100480'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectCopyright" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div id="customEventEditItem_divInfopageShowQRCode"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectInfopageShowQRCode">'+
((__t=( resources['X100556'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectInfopageShowQRCode" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div id="customEventEditItem_divSchemaBigScreenShowQRCode"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectSchemaBigScreenShowQRCode">'+
((__t=( resources['X100557'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectSchemaBigScreenShowQRCode" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectUseDate">'+
((__t=( resources['X100461'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectUseDate" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100045'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100046'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> <div class="form-group" id="customEventEditItem_divDatepicker"> <label for="customEventEditItem_datepicker">'+
((__t=( resources['X100044'] ))==null?'':__t)+
'</label> <div class="input-daterange input-group col-md-12" id="customEventEditItem_datepicker"> <input class="input-sm form-control" name="start"> <span class="input-group-addon" id="customEventEditItem_spanDatePicker">'+
((__t=( resources['X100277'] ))==null?'':__t)+
'</span> <input class="input-sm form-control" name="end"> </div> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" id="customEventEditItem_div_QRCode"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse05"> '+
((__t=( resources.X100945))==null?'':__t)+
' </a> </h4> </div> <div id="collapse05" class="panel-collapse collapse"> <div class="panel-body"> <div class="form-group" id="customEventEditItem_divQRCodeTextPoster"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100946'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputQRCodeTextPoster" style="margin-bottom:0px" value="'+
((__t=( model.QRCodeTextPoster ))==null?'':__t)+
'"> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectQRCodeFontSizePoster">'+
((__t=( resources['X100947'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectQRCodeFontSizePoster" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group" id="customEventEditItem_divQRCodeTextInfoscreen"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100948'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputQRCodeTextInfoscreen" style="margin-bottom:0px" value="'+
((__t=( model.QRCodeTextInfoscreen ))==null?'':__t)+
'"> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectQRCodeFontSizeInfoscreen">'+
((__t=( resources['X100949'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectQRCodeFontSizeInfoscreen" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="form-group" id="customEventEditItem_divQRCodeTextBigSchemaScreen"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100950'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputQRCodeTextBigSchemaScreen" style="margin-bottom:0px" value="'+
((__t=( model.QRCodeTextBigSchemaScreen ))==null?'':__t)+
'"> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectQRCodeFontSizeBigSchemaScreen">'+
((__t=( resources['X100951'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectQRCodeFontSizeBigSchemaScreen" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" id="customEventEditItem_div_Ad"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse06"> '+
((__t=( resources.X100954))==null?'':__t)+
' </a> </h4> </div> <div id="collapse06" class="panel-collapse collapse"> <div class="panel-body"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectAdEnable">'+
((__t=( resources['X100955'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectAdEnable" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100282'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100281'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectAdImageId">'+
((__t=( resources['X100956'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectAdImageId" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="control-group customEventEditItem_inputAdImageUrl"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_inputAdImageUrl" style="padding-top:12px">'+
((__t=( resources['X100957'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventEditItem_inputAdImageUrl" style="margin-bottom:0px" value="'+
((__t=( model.AdImageUrl ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventEditItem_AdStatus" style="padding-top:12px">'+
((__t=( resources['X100958'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label style="padding-top:12px" id="customEventEditItem_labelAdStatus"></label> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="" style="text-align: center"> <img id="customEventEditItem_imageAdImageId" style="height:50%; width:50%" alt="" src=""> </div> </div> </div> </div> </div> </div> <div class="panel panel-default" id="customEventEditItem_div_Admin"> <div class="panel-heading"> <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse07"> '+
((__t=( resources['X100048'] ))==null?'':__t)+
' </a> </h4> </div> <div id="collapse07" class="panel-collapse collapse"> <div class="panel-body"> <div class="form-group" id="customEventEditItem_div_HelpBtn"> <div class="row"> <div class="control-label col-md-6"> <label id="">'+
((__t=( resources['X100049'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="" type="checkbox" id="customEventEditItem_input_HelpBtn" checked="checked" style="margin-bottom:0px;text-align:right"> </div> </div> </div> <div class="form-group" id="customEventEditItem_div_HomeBtn"> <div class="row"> <div class="control-label col-md-6"> <label id="">'+
((__t=( resources['X100050'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="" type="checkbox" id="customEventEditItem_input_HomeBtn" checked="checked" style="margin-bottom:0px"> </div> </div> </div> </div> </div> </div> </div>  <div style="height:3px"></div> <div class="" id=""> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-primary" style="width:100%" id="customEventEditItem_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%; display:none" id="customEventEditItem_btnPublish">'+
((__t=( resources['X100443'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%; display:none" id="customEventEditItem_btnUpdateVersion">'+
((__t=( resources['X100524'] ))==null?'':__t)+
'</button> </div> </div> </div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customEventEditItem_btnCopyLink">'+
((__t=( resources['X100964'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customEventEditItem_btnShowLink">'+
((__t=( resources['X100965'] ))==null?'':__t)+
'</button> </div> </div> <div style="height:10px"></div> <div class="row"> <div class="control-label col-md-12"> <label id="customEventEditItem_labelShowLink"></label> </div> </div> <input id="customEventEditItem_inputShowLink" style="height:0px;padding: 0px; border : 0px"> <div style="height:3px"></div> <div style="display:none" id="customEventEditItem_div_newfunction"> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customEventEditItem_btnPreview">'+
((__t=( resources['X100276'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customEventEditItem_btnWebpagePoster">'+
((__t=( resources['X100099'] ))==null?'':__t)+
'</button> </div> </div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customEventEditItem_btnWebpageSchemaBigscreen">'+
((__t=( resources['X100421'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customEventEditItem_btnWebpageInfo">'+
((__t=( resources['X100093'] ))==null?'':__t)+
'</button> </div> </div> </div> <div id="customEventEditItem_divTemplate"> <h5 class="widget-title">'+
((__t=( resources['X100109'] ))==null?'':__t)+
'<i class="fa fa-list"></i></h5> <div class="alert alert-warning" id="X100110"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> <strong>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</strong> '+
((__t=( resources['X100110'] ))==null?'':__t)+
' </div> <div class="control-group customEventEditItem_selectTemplateSubject"> <div class="form-group"> <label for="customEventEditItem_selectTemplateSubject">'+
((__t=( resources['X100111'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectTemplateSubject" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="control-group customEventEditItem_selectTemplateName"> <div class="form-group"> <label for="customEventEditItem_selectTemplateName">'+
((__t=( resources['X100112'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectTemplateName" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="footer"> <button type="button" class="btn btn-secondary" id="customEventEditItem_btnTemplateCopy">'+
((__t=( resources['X100290'] ))==null?'':__t)+
'</button> </div> </div> </div> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
