﻿import $ from 'jquery';

(function ($) {
    'use strict';

    $(function () {
        /* Code for attribute data-custom-class for adding custom class to tooltip */
        if (typeof $.fn.tooltip.Constructor === 'undefined') {
            throw new Error('Bootstrap Tooltip must be included first!');
        }

        var Tooltip = $.fn.tooltip.Constructor;

        // add customClass option to Bootstrap Tooltip
        $.extend(Tooltip.Default, {
            customClass: ''
        });

        var _show = Tooltip.prototype.show;

        Tooltip.prototype.show = function () {
            $('.tooltip-info').remove();
            // invoke parent method
            _show.apply(this, Array.prototype.slice.apply(arguments));

            if (this.config.customClass) {
                var tip = this.getTipElement();
                $(tip).addClass(this.config.customClass);
            }

        };
        $('[data-toggle="tooltip"]').tooltip();

    });
})(jQuery);

export default {};

