﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customViewEvent from './tpl/customViewEvent.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import View_CustomViewEventList from './customViewEventList';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customViewEvent();
    },
    regions: {
        customViewEventList: "#customViewEventList"
    },
    onBeforeShow: function () {
        this.customViewEventList.show(new View_CustomViewEventList({ collection: this.collection, app : this.app, viewComparator : this.getViewComparator() }));
    },
    initialize: function () {
        var self = this;
        this.openAfterRender = false;
        this.app = this.options.app;
        
        //this.listenTo(this.collection, 'change', this.render, this);
        this.listenTo(this.model, 'change', this.render, this);
        
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customViewEvent:setActive', function(array) {
            self.setActive(array);
        });
        this.listenTo(userChannel,'customViewEvent:setEnable', function() {
            self.setEnable();
        });
        this.listenTo(userChannel,'customViewEvent:setDisable', function() {
            self.setDisable();
        });
    },
    help: function () {
        this.style_switcher_ctrl_clicked(null);
        Help.help(this, true, 'customViewEvent');
    },
    ui: {
        'customViewEvent_style_switcher_ctrl': '#customViewEvent_style_switcher_ctrl',
        'customViewEvent_Information' : '#customViewEvent_Information',
        'customViewEvent_input_sortByEvent' : '#customViewEvent_input_sortByEvent',
        'customViewEvent_Information_div' : '#customViewEvent_Information_div',
        'customViewEvent_information_h5' : '#customViewEvent_information_h5',
        'customViewEvent_btn_test' : '#customViewEvent_btn_test',
        'customViewEvent_div_main' : '#customViewEvent_div_main',
        'customViewEvent_WebpageImageUrlMethod_radio00' : '#customViewEvent_WebpageImageUrlMethod_radio00',
        'customViewEvent_WebpageImageUrlMethod_radio01' : '#customViewEvent_WebpageImageUrlMethod_radio01',
        'customViewEvent_WebpageImageUrlMethod_radio02' : '#customViewEvent_WebpageImageUrlMethod_radio02',
        'customViewEvent_WebpageImageUrlMethod_radio03' : '#customViewEvent_WebpageImageUrlMethod_radio03',
        'customViewEvent_h5' : '#customViewEvent_h5',
        'help': '#help',
        'customViewEventList' : '#customViewEventList'
    },
    events: {
        'click #customViewEvent_style_switcher_ctrl': 'style_switcher_ctrl_clicked',
        'click #customViewEvent_Information' : 'customViewEvent_Information_clicked',
        'click #customViewEvent_input_sortByEvent' : 'customViewEvent_input_sortByEvent_clicked',
        'click #customViewEvent_btn_test' : 'customViewEvent_btn_test_clicked',
        'click #customViewEvent_WebpageImageUrlMethod_radio00': 'webpageImageUrlMethod_radio00_clicked',
        'click #customViewEvent_WebpageImageUrlMethod_radio01': 'webpageImageUrlMethod_radio01_clicked',
        'click #customViewEvent_WebpageImageUrlMethod_radio02': 'webpageImageUrlMethod_radio02_clicked',
        'click #customViewEvent_WebpageImageUrlMethod_radio03': 'webpageImageUrlMethod_radio03_clicked',
        'click #help': 'help'
    },
    customViewEvent_btn_test_clicked: function (event) {
        //app.entities.collections.customEvent.models[0].attributes.Active = false;
        //app.entities.collections.customEvent.models[2].attributes.Active = true;
        //this.collection.trigger('change');

        //this.customViewEventList.currentView.options.viewComparator = 'Id';
        //this.collection.trigger('change');

        this.ui.customViewEvent_div_main.addClass('disabledCustomViewEvent');

    },
    onRender: function () {
        //var customViewEvent = this.app.entities.collections.customViewEvent.where({ Name : 'WebpageImageUrlMethod' });
        //switch (customViewEvent[0].attributes.Value) {
        //    case '0' : this.ui.customViewEvent_WebpageImageUrlMethod_radio00.prop('checked', true); break;
        //    case '1' : this.ui.customViewEvent_WebpageImageUrlMethod_radio01.prop('checked', true); break;
        //    case '2' : this.ui.customViewEvent_WebpageImageUrlMethod_radio02.prop('checked', true); break;
        //    case '3' : this.ui.customViewEvent_WebpageImageUrlMethod_radio03.prop('checked', true); break;
        //}

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'CustomEventViewInformation' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;

        if (checked) {
            this.ui.customViewEvent_Information.removeClass('fa-plus-circle'); 
            this.ui.customViewEvent_Information.addClass('fa-minus-circle'); 
            this.ui.customViewEvent_Information_div.show();
            this.ui.customViewEvent_information_h5.css('margin-bottom', '15px');
        } else {
            this.ui.customViewEvent_Information.removeClass('fa-minus-circle'); 
            this.ui.customViewEvent_Information.addClass('fa-plus-circle'); 
            this.ui.customViewEvent_Information_div.css('display', 'none');
            this.ui.customViewEvent_information_h5.css('margin-bottom', '0px');
        }

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'CustomEventViewSortByEvent' });
        switch (customSetting[0].attributes.Value) {
            case '0' : this.ui.customViewEvent_input_sortByEvent.attr('checked', false); break;
            case '1' : this.ui.customViewEvent_input_sortByEvent.attr('checked', true); break;
        }

        var maxSize = 56*6;

        var size = $(window).height() - (213) ;

        var listSize = 353;

        if (!checked) {
            listSize -= 160;
        }

        maxSize += listSize;

        if (size > maxSize) {
            size = maxSize;
        }

        this.ui.customViewEvent_div_main.css('height',size + 'px');

        //var listSize = this.ui.customViewEventList.position().top;

        

        this.ui.customViewEventList.css('height',size - listSize  + 'px');
    },
    onAttach(){
    },
    onDestroy(){
    },
    style_switcher_ctrl_clicked : function (event) {
        $('#style-switcher-viewevent .styles').toggle('slow'); 
    },
    customViewEvent_Information_clicked: function (event) {
        var self = this;
        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'CustomEventViewInformation' });
        
        var value = customSetting[0].attributes.Value == '1' ? '0' : '1';
        customSetting[0].attributes.Value = value;
        var checked = customSetting[0].attributes.Value == '1' ? true : false;

        if (checked) {
            this.ui.customViewEvent_Information.removeClass('fa-plus-circle'); 
            this.ui.customViewEvent_Information.addClass('fa-minus-circle'); 
            this.ui.customViewEvent_information_h5.css('margin-bottom', '15px');
            
            this.ui.customViewEvent_Information_div.show();
        } else {
            this.ui.customViewEvent_Information.removeClass('fa-minus-circle'); 
            this.ui.customViewEvent_Information.addClass('fa-plus-circle'); 
            this.ui.customViewEvent_information_h5.css('margin-bottom', '0px');
            
            this.ui.customViewEvent_Information_div.css('display', 'none');
        }

        var maxSize = 56*6;

        var size = $(window).height() - (213) ;

        var listSize = 353;

        if (!checked) {
            listSize -= 160;
        }

        maxSize += listSize;

        if (size > maxSize) {
            size = maxSize;
        }

        this.ui.customViewEvent_div_main.css('height',size + 'px');

        //var listSize = this.ui.customViewEventList.position().top;

        

        this.ui.customViewEventList.css('height',size - listSize  + 'px');
        

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                //self.model.set({ Render : !self.model.attributes.Render});
                //self.model.trigger('change');
            }
        });
    },
    customViewEvent_input_sortByEvent_clicked: function (event) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'CustomEventViewSortByEvent' });

        var value = customSetting[0].attributes.Value == '1' ? '0' : '1';
        this.ui.customViewEvent_input_sortByEvent.is(':checked') ? value = '1' : value = '0';
        customSetting[0].attributes.Value = value;

        this.customViewEventList.currentView.options.viewComparator = this.getViewComparator();
        this.collection.trigger('change');

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    getViewComparator: function () {
        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'CustomEventViewSortByEvent' });
        var viewComparator = 'Id';
        switch (customSetting[0].attributes.Value) {
            case '0' : viewComparator = 'Id'; break;
            case '1' : viewComparator = 'Active'; break;
        }

        return viewComparator;
    },
    setActive: function (array) {
        //if (this.getViewComparator() == 'Active') {
            var self = this;

        //this.collection.fullCollection.forEach(function (entry) {
            this.collection.forEach(function (entry) {
                entry.attributes.Active = true;
            });
        
            array.forEach(function (entry) {
                //var customEvent = self.collection.fullCollection.where({ id: entry.id });
                var customEvent = self.collection.where({ id: entry.id });
                if (customEvent.length > 0) {
                    customEvent[0].attributes.Active = false;
                }
            });

            this.collection.trigger('change');
            //if (array.length > 0) {
            //    this.collection.trigger('change');
            //}
        //} 
    },
    setEnable: function () {
        this.ui.customViewEvent_div_main.removeClass('disabledCustomViewEvent');
        this.collection.trigger('change');

        if (this.openAfterRender) {
            //this.style_switcher_ctrl_clicked(null);
        }
    },
    setDisable: function () {
        this.ui.customViewEvent_div_main.addClass('disabledCustomViewEvent');

        if(this.ui.customViewEvent_div_main.css('display') == 'block'){
            //this.style_switcher_ctrl_clicked(null);
            this.openAfterRender = true;
        } else {
            this.openAfterRender = false;
        }
    }
}, Backbone.Radio.Requests);
