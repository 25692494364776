﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogPaintStrap from './tpl/dialogPaintStrap.html';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_DialogPaintStrapList from './dialogPaintStrapList';

export default Marionette.LayoutView.extend({
    dialogRegion: '#dialogPaintStrap_modal',
    getTemplate: function () {
        return tpl_dialogPaintStrap({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    regions: {
        dialogPaintStrapList: "#dialogPaintStrapList",
        dialogPaintStrapPagination: "#dialogPaintStrapPagination",
    },
    onBeforeShow: function () {
        this.dialogPaintStrapList.show(new View_DialogPaintStrapList({ collection: this.collection }));
        this.dialogPaintStrapPagination.show(new View_Pagination({ collection: this.collection }));
    },
    events: {
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02"
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    }
});
