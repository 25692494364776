'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartmentResetInsert from './tpl/customDepartmentResetInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting2 from '../../../lib/views/dialogWaiting2/dialogWaiting2';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customDepartmentResetInsert({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.model = new this.app.entities.collections.customDepartmentReset.fullCollection.model();

        this.model.attributes.ActiveTab = this.app.entities.models.customColor.attributes.Custom_Text_Default;//resources.X200311;

        this.model.attributes.tmp = {};

        this.model.attributes.tmp.Custom_Activepage = resources.X200311;
        this.model.attributes.tmp.SolidColor_Activepage = resources.X200270;
        this.model.attributes.tmp.MSTeams_Activepage = resources.X200275;
        this.model.attributes.tmp.Mutebutton_Activepage = resources.X200312;
        this.model.attributes.tmp.Pomodoro_Activepage = resources.X200286;


        this.model.attributes.tmp.Custom_Color_Default = this.app.entities.models.customColor.attributes.Custom_Color_Default;
        this.model.attributes.tmp.Custom_Text_Default = resources.X200311;// + ' (' + this.app.entities.models.customColor.attributes.Custom_Text_Default + ')';

        this.model.attributes.tmp.SolidColor_Color_Off = this.app.entities.models.customColor.attributes.SolidColor_Color_Off;
        this.model.attributes.tmp.SolidColor_Text_Off = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Off + ')';
        this.model.attributes.tmp.SelectedOff = '';

        this.model.attributes.tmp.SolidColor_Color_Busy = this.app.entities.models.customColor.attributes.SolidColor_Color_Busy;
        this.model.attributes.tmp.SolidColor_Text_Busy = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Busy + ')';
        this.model.attributes.tmp.SelectedBusy = '';

        this.model.attributes.tmp.SolidColor_Color_Available = this.app.entities.models.customColor.attributes.SolidColor_Color_Available;
        this.model.attributes.tmp.SolidColor_Text_Available = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Available + ')';
        this.model.attributes.tmp.SelectedAvailable = '';

        this.model.attributes.tmp.SolidColor_Color_Blue = this.app.entities.models.customColor.attributes.SolidColor_Color_Blue;
        this.model.attributes.tmp.SolidColor_Text_Blue = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Blue + ')';
        this.model.attributes.tmp.SelectedBlue = '';

        this.model.attributes.tmp.SolidColor_Color_Yellow = this.app.entities.models.customColor.attributes.SolidColor_Color_Yellow;
        this.model.attributes.tmp.SolidColor_Text_Yellow = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow + ')';
        this.model.attributes.tmp.SelectedYellow = '';

        this.model.attributes.tmp.SolidColor_Color_Cyan = this.app.entities.models.customColor.attributes.SolidColor_Color_Cyan;
        this.model.attributes.tmp.SolidColor_Text_Cyan = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan + ')';
        this.model.attributes.tmp.SelectedCyan = '';

        this.model.attributes.tmp.SolidColor_Color_Magenta = this.app.entities.models.customColor.attributes.SolidColor_Color_Magenta;
        this.model.attributes.tmp.SolidColor_Text_Magenta = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta + ')';
        this.model.attributes.tmp.SelectedMagenta = '';

        this.model.attributes.tmp.SolidColor_Color_CustomColor = this.app.entities.models.customColor.attributes.SolidColor_Color_CustomColor;
        this.model.attributes.tmp.SolidColor_Text_CustomColor = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor + ')';
        this.model.attributes.tmp.SelectedCustomColor = '';

        this.model.attributes.tmp.MSTeams_Color_Online = this.app.entities.models.customColor.attributes.MSTeams_Color_Online;
        this.model.attributes.tmp.MSTeams_Text_Online = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Online + ')';

        this.model.attributes.tmp.MSTeams_Color_Busy = this.app.entities.models.customColor.attributes.MSTeams_Color_Busy;
        this.model.attributes.tmp.MSTeams_Text_Busy = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Busy + ')';

        this.model.attributes.tmp.MSTeams_Color_DoNotDisturb = this.app.entities.models.customColor.attributes.MSTeams_Color_DoNotDisturb;
        this.model.attributes.tmp.MSTeams_Text_DoNotDisturb = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb + ')';

        this.model.attributes.tmp.MSTeams_Color_BeRightBack = this.app.entities.models.customColor.attributes.MSTeams_Color_BeRightBack;
        this.model.attributes.tmp.MSTeams_Text_BeRightBack = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsNotPresent = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsNotPresent;
        this.model.attributes.tmp.MSTeams_Text_ShowAsNotPresent = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsOffline = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsOffline;
        this.model.attributes.tmp.MSTeams_Text_ShowAsOffline = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline + ')';

        this.model.attributes.tmp.Mutebutton_Color_On = this.app.entities.models.customColor.attributes.Mutebutton_Color_On;
        this.model.attributes.tmp.Mutebutton_Text_On = resources.X200312 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_On + ')';

        this.model.attributes.tmp.Mutebutton_Color_Off = this.app.entities.models.customColor.attributes.Mutebutton_Color_Off;
        this.model.attributes.tmp.Mutebutton_Text_Off = resources.X200312 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_Off + ')';

        this.model.attributes.tmp.Pomodoro_Color_Time = this.app.entities.models.customColor.attributes.Pomodoro_Color_Time;
        this.model.attributes.tmp.Pomodoro_Text_Time = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_Time + ')';

        this.model.attributes.tmp.Pomodoro_Color_ShortBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_ShortBreak;
        this.model.attributes.tmp.Pomodoro_Text_ShortBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak + ')';

        this.model.attributes.tmp.Pomodoro_Color_LongBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_LongBreak;
        this.model.attributes.tmp.Pomodoro_Text_LongBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak + ')';
    },
    ui: {
        'customDepartmentResetInsert_btnSubmit': '#customDepartmentResetInsert_btnSubmit',
        'customDepartmentResetInsert_inputName': '#customDepartmentResetInsert_inputName',
        //'customDepartmentResetInsert_inputTime': '#customDepartmentResetInsert_inputTime',
        'customDepartmentResetInsert_selectStatus': '#customDepartmentResetInsert_selectStatus',
        'customDepartmentResetInsert_selectActiveTab': '#customDepartmentResetInsert_selectActiveTab',
        'customDepartmentResetInsert_select_Hour': '#customDepartmentResetInsert_select_Hour',
        'customDepartmentResetInsert_select_Minut': '#customDepartmentResetInsert_select_Minut',
        'customDepartmentResetInsert_inputText': '#customDepartmentResetInsert_inputText'
    },
    events: {
        'click @ui.customDepartmentResetInsert_btnSubmit': 'btnSubmitClick',
        'change @ui.customDepartmentResetInsert_selectStatus': 'change_selectStatus'
    },
    childEvents: {
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;

        //this.ui.customDepartmentResetInsert_inputTime.durationPicker({
        //    translations: {
        //        day: resources.X200259,
        //        hour: resources.X200260,
        //        minute: resources.X200261,
        //        second: resources.X200262,
        //        days: resources.X200263,
        //        hours: resources.X200264,
        //        minutes: resources.X200265,
        //        seconds: resources.X200266,
        //    },
        //    showSeconds: false,
        //    showDays: false,
            
            
        //    onChanged: function (newVal) {
        //        //self.ui.adSlideListItemProperties_label_Duration.text(newVal);
        //        self.model.set({
        //            Time: newVal
        //        });
        //    }
        //});

        //$('.bdp-block.hidden').css('display', 'none');

        

        //$('#customDepartmentResetInsert_group_Time').find('.input-sm').width(90);

        this.setupStatus("0");//this.model.attributes.tmp.Custom_Color_Default);
        $('.btn.dropdown-toggle.btn-light').css("width", "320px");
    },
    setupStatus(color) {
        var self = this;

        self.ui.customDepartmentResetInsert_selectStatus.selectpicker({ size: 10 });

        $('select[name=selValue]').val(color);
        self.ui.customDepartmentResetInsert_selectStatus.selectpicker('refresh');
        this.change_selectStatus();
    },
    change_selectStatus: function () {
        var value = this.ui.customDepartmentResetInsert_selectStatus.val();
        var text = '';
        var disabled = false;

        switch (value) {
            case "0": text = '';//this.model.attributes.tmp.Custom_Text_Default;
                disabled = true;
                break;
            case "1": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Off;
                break;
            case "2": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Busy;
                break;
            case "3": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Available;
                break;
            case "4": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Blue;
                break;
            case "5": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow;
                break;
            case "6": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan;
                break;
            case "7": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta;
                break;
            case "8": text = this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor;
                break;
            case "9": text = this.app.entities.models.customColor.attributes.tmp.MSTeams_Text_Online;
                break;
            case "10": text = this.app.entities.models.customColor.attributes.tmp.MSTeams_Text_Busy;
                break;
            case "11": text = this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb;
                break;
            case "12": text = this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack;
                break;
            case "13": text = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent;
                break;
            case "14": text = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline;
                break;
            case "15": text = this.app.entities.models.customColor.attributes.Mutebutton_Text_On;
                break;
            case "16": text = this.app.entities.models.customColor.attributes.Mutebutton_Text_Off;
                break;
            case "17": text = this.app.entities.models.customColor.attributes.Pomodoro_Text_Time;
                break;
            case "18": text = this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak;
                break;
            case "19": text = this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak;
                break;

        }

        this.ui.customDepartmentResetInsert_inputText.val(text);
        this.ui.customDepartmentResetInsert_inputText.prop('disabled', disabled);

    },
    btnSubmitClick: function () {
        var self = this;

        

        var tmpModel = new this.app.entities.collections.customDepartmentReset.model();
        tmpModel.attributes.AuthUserId = config.authUserId();

        self.model.attributes.Time = ((parseInt(this.ui.customDepartmentResetInsert_select_Hour.val()) * 60) + parseInt(this.ui.customDepartmentResetInsert_select_Minut.val())) * 60;



        tmpModel.attributes.Time = self.model.attributes.Time;

        var tmpColor = "";
        var tmpColorSelected = 0;
        var tmpActiveTab = "";

        var value = this.ui.customDepartmentResetInsert_selectStatus.val();
        switch (value) {
            case "0": tmpColor = this.model.attributes.tmp.Custom_Color_Default;
                tmpActiveTab = resources.X200311;
                tmpColorSelected = 0;
                break;
            case "1": tmpColor = this.model.attributes.tmp.SolidColor_Color_Off;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 1;
                break;
            case "2": tmpColor = this.model.attributes.tmp.SolidColor_Color_Busy;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 2;
                break;
            case "3": tmpColor = this.model.attributes.tmp.SolidColor_Color_Available;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 3;
                break;
            case "4": tmpColor = this.model.attributes.tmp.SolidColor_Color_Blue;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 4;
                break;
            case "5": tmpColor = this.model.attributes.tmp.SolidColor_Color_Yellow;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 5;
                break;
            case "6": tmpColor = this.model.attributes.tmp.SolidColor_Color_Cyan;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 6;
                break;
            case "7": tmpColor = this.model.attributes.tmp.SolidColor_Color_Magenta;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 7;
                break;
            case "8": tmpColor = this.model.attributes.tmp.SolidColor_Color_CustomColor;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 8;
                break;
            case "9": tmpColor = this.model.attributes.tmp.MSTeams_Color_Online;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 9;
                break;
            case "10": tmpColor = this.model.attributes.tmp.MSTeams_Color_Busy;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 10;
                break;
            case "11": tmpColor = this.model.attributes.tmp.MSTeams_Color_DoNotDisturb;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 11;
                break;
            case "12": tmpColor = this.model.attributes.tmp.MSTeams_Color_BeRightBack;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 12;
                break;
            case "13": tmpColor = this.model.attributes.tmp.MSTeams_Color_ShowAsNotPresent;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 13;
                break;
            case "14": tmpColor = this.model.attributes.tmp.MSTeams_Color_ShowAsOffline;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 14;
                break;
            case "15": tmpColor = this.model.attributes.tmp.Mutebutton_Color_On;
                tmpActiveTab = resources.X200312;
                tmpColorSelected = 15;
                break;
            case "16": tmpColor = this.model.attributes.tmp.Mutebutton_Color_Off;
                tmpActiveTab = resources.X200312;
                tmpColorSelected = 16;
                break;
            case "17": tmpColor = this.model.attributes.tmp.Pomodoro_Color_Time;
                tmpActiveTab = resources.X200286;
                tmpColorSelected = 17;
                break;
            case "18": tmpColor = this.model.attributes.tmp.Pomodoro_Color_ShortBreak;
                tmpActiveTab = resources.X200286;
                tmpColorSelected = 18;
                break;
            case "19": tmpColor = this.model.attributes.tmp.Pomodoro_Color_LongBreak;
                tmpActiveTab = resources.X200286;
                tmpColorSelected = 19;
                break;

        }

        var text = this.ui.customDepartmentResetInsert_inputText.val();
        tmpModel.attributes.ColorSelected = tmpColorSelected;


        var g = $('.mydata').text();

        tmpModel.attributes.Color = tmpColor;//this.ui.customDepartmentResetInsert_selectStatus.val();
        tmpModel.attributes.ColorSelected = tmpColorSelected;//this.ui.customDepartmentResetInsert_selectStatus.val();
        //tmpModel.attributes.ActiveTab = this.ui.customDepartmentResetInsert_selectActiveTab.val();
        //tmpModel.attributes.ActiveTab = this.ui.customDepartmentResetInsert_selectStatus.attr('data-activetab');
        tmpModel.attributes.ActiveTab = tmpActiveTab;
        tmpModel.attributes.Department = this.options.departmentId;
        tmpModel.attributes.Text = text;

        //if (!tmpModel.validateNoBackbone(this.app)) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200258;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: self });

            var callback = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion2.show(dialogWaiting);

            self.app.entities.collections.customDepartmentReset.create(tmpModel, {
                wait: true,
                success: function (response) {
                    self.setupStatus("0");
                    self.ui.customDepartmentResetInsert_select_Hour.val("17").change();
                    self.ui.customDepartmentResetInsert_select_Minut.val("0").change();

                    //self.ui.customDepartmentResetInsert_select_Hour.find('option[value="' + 17 + '"]').attr("selected", "selected");
                    //self.ui.customDepartmentResetInsert_select_Minut.find('option[value="' + 0 + '"]').attr("selected", "selected");
                    self.hideErrors();
                    //self.ui.customDepartmentResetInsert_inputName.val("");
                    dialogWaiting.triggerMethod('dialog:close');
                }
            });
        /*} else {
            this.showErrors(tmpModel.errors);
        }*/
    }
});

