﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_moduleListList from './tpl/moduleListList.html';
import View_ModuleListListItem from './moduleListListItem';

export default Marionette.CollectionView.extend({
    tagName: 'moduleListList',
    className: 'row',
    childViewContainer: '#moduleListList',
    childView: View_ModuleListListItem,
    getTemplate: function () {
        return tpl_moduleListList();
    },
    initialize: function () {
        this.app = this.options.app;
        this.moduleSupported = this.options.moduleSupported;
    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    },
    addChild: function (model, ChildView, index) {
        //var borderStyle = this.moduleSupported.findIndex(function (post, index) {
        //    if (post == self.model.attributes.Supported)
        //        return true;
        //});
        var found = this.moduleSupported.find(function (element) {
            return element == model.attributes.Supported;
        });

        if (found != undefined) {
            return Marionette.CollectionView.prototype.addChild.call(this, model, ChildView, index);
        }
    }
});
