var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventPushInsert_empty"> <div class="control-group customEventPushInsert_inputPush"> <div class="forms-sample"> <div class="form-group" style="margin-bottom:0px"> <div id="customEventPushInsert_badge" class="badge badge-pill badge-primary">0</div> <label for="customEventPushInsert_inputPush">'+
((__t=( resources['X100238'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customEventPushInsert_inputPush"> <span class="help-inline" style="color:red"></span> <div class="form-check form-check-flat form-check-primary"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventPushInsert_btnPushSubmit">'+
((__t=( resources['X100239'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
