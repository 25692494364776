﻿import $ from 'jquery';


require('datatables');
require('datatables/plugins/api/page.jumpToData()');
require('datatables/plugins/integration/bootstrap/3/dataTables.bootstrap');
require('datatables-tabletools');
require('DataTables.RowReordering');
require('jquery-datatables-row-grouping');

