﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_editor from './tpl/editor.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_editor();
    },
    regions: {
        'modal': '#modal'
    },

    initialize: function () {
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');
        var self = this;
    },
    ui: {
        'editor_iframeContentBuilder': '#editor_iframeContentBuilder',
        'editor_iframeContentBuilder_div': '#editor_iframeContentBuilder_div',
    },
    events: {
    },
    onRender: function () {
        var self = this;
        var bust = '1449135730';

        var paintStrapModel = app.entities.collections.paintStrap.fullCollection.where({ id: 8 });
        var cssFile = config.webApiUrl() + paintStrapModel[0].attributes.CssFile;


        self.app.iframeContentBuilderReady = false;

        $(document).bind('iframeContentBuilderReady', function (e) {
            self.app.iframeContentBuilderReady = true;
            var userChannel = Backbone.Radio.channel('app');
            userChannel.request('editor:getContentFirstTime');
        });

        this.ui.editor_iframeContentBuilder.load(function () {
        });

        var contentBuilder_e4u_iframe = '';

        if (!config.online()) {
            contentBuilder_e4u_iframe = '/webpageeditor/ContentBuilder/e4u_iframe.html?bust=231210;AuthUserId=';
        } else {
            contentBuilder_e4u_iframe = '/webpageeditor/ContentBuilder/e4u_iframe_Localhost.html?bust=231210;AuthUserId=';
        }


        //Cke editor is in yet allso in a iframe so paintstrap must be included with the other styles

        var paintStrapModel = app.entities.collections.paintStrap.fullCollection.where({ id: 8 });


        var cob_cssFile = config.webApiUrl() + '/MainJS/ImportWebpageEditorCSS/' + paintStrapModel[0].attributes.Filename + '/dummy/dummy/';

        var EditorWidth = "400px";


        if (this.options.webpageItem_DisplayType == 0) {
            EditorWidth = "400px";
        } else if (this.options.webpageItem_DisplayType == 1) {
            //EditorWidth = "100%";
            EditorWidth = "400px";
        }


        var ifcbSrc = encodeURIComponent(bust + '&AuthUserId=' + config.authUserId() +//config.authUserId() +
            '&config_webapiurl=' + config.webApiUrl() +
            '&contentBuilder_btnViewHtml=' + resources.X100061 +
            '&contentBuilder_snippletBtn01=' + resources.X100062 +
            '&contentBuilder_snippletBtn02=' + resources.X100063 +
            '&SizeTypeText0=' + resources.X100492 +
            '&SizeTypeText1=' + resources.X100493 +
            '&SizeTypeText2=' + resources.X100494 +
            '&SizeTypeText3=' + resources.X100495 +
            '&SizeTypeText4=' + resources.X100496 +
            '&EditorWidth=' + EditorWidth +
            '&id=' + this.options.webpageItem_id +
            '&cob_cssFile=' + cob_cssFile +
            '&DisplayType=' + this.options.webpageItem_DisplayType +
            '&bust=' + bust);

        //var timerId = window.setInterval(function () {
        this.ui.editor_iframeContentBuilder.attr('src', contentBuilder_e4u_iframe + ifcbSrc);
        //clearInterval(timerId);
        //}, 30000);
    },
    onDestroy: function () {
        $("#editor_iframeContentBuilder").unbind();
        $("#editor_iframeContentBuilder").remove();
        $(document).unbind('iframeContentBuilderReady');
    },
    getContent: function () {
        var content = '';
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });
        switch (customSetting[0].attributes.Value) {
            case '1': try {
                content = document.getElementById("editor_iframeContentBuilder").contentWindow.window.getContent();
            } catch (err) {
                content = "############ERROR############";
            }
                break;
            case '0': content = document.getElementById("editor_iframeCKEditor").contentWindow.window.getContent();
                break;
        }
        return content;
    },
    setContent: function (content, id, tag, callback) {
        this.options.webpageItem_id = id;

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });

        switch (customSetting[0].attributes.Value) {
            case '1': this.ui.editor_iframeContentBuilder[0].contentWindow.window.setContent(content, callback, tag); break;
            case '0': this.ui.editor_iframeCKEditor[0].contentWindow.window.setContent(content, callback, tag); break;
        }
    },
    saveContent: function () {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('editor:saveContent');
    },
    themeChange: function () {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('editor:themeChange');
    }
}, Backbone.Radio.Requests);
