﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as nouislider from 'nouislider';

import tpl_customInfopageEditItemTemplateTopbar from './tpl/customInfopageEditItemTemplateTopbar.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    className:'row',
    getTemplate: function () {
        return tpl_customInfopageEditItemTemplateTopbar({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customInfopageEditItemTopbar');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmit(null, false, true, callback, false);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'customInfopageEditItemTemplateTopbar_input_Zoom': '#customInfopageEditItemTemplateTopbar_input_Zoom',
    },
    events: {
        //'change @ui.customInfopageEditItemTemplateTopbar_input_Zoom': 'updateZoom',

    },
    onRender: function () {
    },
    onAttach() {
        var self = this;

        self.model.attributes.Zoom = parseInt(($('#customInfopageEditItemLeftContent').width() / 1980) * 100);

        if ($("#customInfopageEditItemTemplateTopbar_input_Zoom").length) {
            var startSlider1 = document.getElementById('customInfopageEditItemTemplateTopbar_input_Zoom');
            nouislider.create(startSlider1, {
                start: self.model.attributes.Zoom,
                connect: [true, false],
                range: {
                    min: 1,
                    max: 100
                },
                step: 1
            });
            startSlider1.noUiSlider.on('set', function (e) {
                self.model.attributes.Zoom = parseInt(e);
                //self.updateZoom(self.model.attributes.Zoom);
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('template:setZoomBySlider', self.model.attributes.Zoom);
            });
        }
    },
    /*setZoom(zoom, el) {

        if (el != undefined) {

            var transformOrigin = [0, 0];
            el = el || instance.getContainer();
            var p = ["webkit", "moz", "ms", "o"],
                s = "scale(" + zoom + ")",
                oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

            for (var i = 0; i < p.length; i++) {
                el.style[p[i] + "Transform"] = s;
                el.style[p[i] + "TransformOrigin"] = oString;
            }

            el.style["transform"] = s;
            el.style["transformOrigin"] = oString;
        }
    },*/
    /*setZoomNot(zoom, el) {

        var transformOrigin = [0, 0];
        el = el || instance.getContainer();
        var p = ["webkit", "moz", "ms", "o"],
            s = "scale(" + zoom + ")",
            oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

        for (var i = 0; i < p.length; i++) {
            el.style[p[i] + "Transform"] = s;
            //el.style[p[i] + "TransformOrigin"] = oString;
        }

        el.style["transform"] = s;
        //el.style["transformOrigin"] = oString;
    },
    updateZoom(a) {
        var zoomScale = Number(a) / 100;

        if (document.getElementsByClassName('zoomContainerH') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerH')[0])
        }
        if (document.getElementsByClassName('zoomContainerV') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerV')[0])
        }

        var zoomScale2 = zoomScale * 100;
        this.setZoomNot(zoomScale2, document.getElementsByClassName('zoomNot')[0])

        
    },
    updateZoom(a) {
        var zoomScale = Number(a) / 100;

        if (document.getElementsByClassName('zoomContainerH') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerH')[0]);
        }

        if (document.getElementsByClassName('zoomContainerV') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerV')[0]);
        }

        var zoomNotScale = 1 / zoomScale;
        var x = document.querySelectorAll(".zoomNot");
        var i;
        for (i = 0; i < x.length; i++) {
            this.setZoomNot(zoomNotScale, x[i]);
        }

        $('.infopageeditor').each(function (i, obj) {
            var element = obj.closest(".moduleRegion");
            $(obj).css('max-height', (($(element).height() - 60) - (parseInt($(element).css('border-width').slice(0, -2) * 2))) + 'px');
        });
    }*/
}, Backbone.Radio.Requests);
