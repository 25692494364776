﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_infoPageList from './tpl/infoPageList.html';
import View_infoPageListItem from './infoPageListItem';

export default Marionette.CollectionView.extend({
    //tagName: 'div',
    className: "row",
    //childViewContainer: '#infoPageList',
    childView: View_infoPageListItem,
    //getTemplate: function () {
    //    //return tpl_infoPageList();
    //},
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
