﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customInfopage/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customInfopage/-1?AuthUserId=' + config.authUserId();
        }
        else if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customInfopage/' + model.attributes.id + '?AuthUserId=' + config.authUserId();

        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    /*save: function (attributes) {
        this.options.success = this.successOnSave;
        this.options.error = this.errorOnSave;

        Backbone.Model.prototype.save.call(this, attributes, options);
    },*/
    successOnSave: function (model, response) {
        model = response;
    },
    errorOnSave: function (model, response) {
        alert("FEJL");
    },
    validateNoBackbone: function (app) {
        this.errors = [];

        var self = this;

        if (this.attributes.Name == "") {
            this.errors.push({ name: 'customInfopageEditItem_inputName', message: resources.X100324 });
            this.errors.push({ name: 'customInfopageInsert_inputName', message: resources.X100324 });
        }

        switch (this.attributes.DisplayType) {
            case 0 :
                for (var i = 0; i < app.entities.collections.customInfopage.fullCollection.length; i++) {
                    if (app.entities.collections.customInfopage.fullCollection.models[i].attributes.id != this.attributes.id) {
                        if (this.attributes.Name == app.entities.collections.customInfopage.fullCollection.models[i].attributes.Name) {
                            this.errors.push({ name: 'customInfopageEditItem_inputName', message: resources.X100330 });
                            this.errors.push({ name: 'customInfopageInsert_inputName', message: resources.X100330 });
                            this.errors.push({ name: 'customInfopageSetting_inputName', message: resources.X100330 });
                        }
                    }
                };
                break;
            case 1 :
                for (var i = 0; i < app.entities.collections.customInfopage.fullCollection.length; i++) {
                    if (app.entities.collections.customInfopage.fullCollection.models[i].attributes.id != this.attributes.id) {
                        if (this.attributes.Name == app.entities.collections.customInfopage.fullCollection.models[i].attributes.Name) {
                            this.errors.push({ name: 'customInfopageEditItem_inputName', message: resources.X101021 });
                            this.errors.push({ name: 'customInfopageInsert_inputName', message: resources.X101021 });
                            this.errors.push({ name: 'customInfopageSetting_inputName', message: resources.X101021 });
                        }
                    }
                };
                break;
        }

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    }
});


export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,

    state: {
        pageSize: 6,
    },

    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },

    options: {
        full: false
    },

    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customInfopage/-1?AuthUserId=' + config.authUserId() + '&DisplayType=' + options.DisplayType;
        }
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
             : a.attributes.id < b.attributes.id ? 1
             : 0;
    }
});






