'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customSchemaSchemaInsert from './tpl/customSchemaSchemaInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customSchemaSchemaInsert();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
    },
    ui: {
        'customSchemaSchemaInsert_btnSubmit': '#customSchemaSchemaInsert_btnSubmit',
        'customSchemaSchemaInsert_input_Name': '#customSchemaSchemaInsert_input_Name'
    },
    events: {
        'click @ui.customSchemaSchemaInsert_btnSubmit': 'btnSubmitClick',
    },
    btnSubmitClick: function () {
        var self = this;
        this.hideErrors();

        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.EventId = this.settingModel.attributes.EventId;

        var largestPosition = 0;

        this.collection.forEach(function (entry) {
            if (entry.attributes.EventId == self.settingModel.attributes.EventId) {
                if (largestPosition < entry.attributes.Position) {
                    largestPosition = entry.attributes.Position;
                }
            }
        });
        largestPosition++;

        tmpModel.attributes.Position = largestPosition;
        tmpModel.attributes.Name = this.ui.customSchemaSchemaInsert_input_Name.val();

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(this.app);
        if (ret == false) {
            var self = this;
            this.collection.create(tmpModel, {
                silent : true,
                wait : true,
                success: function (response) {
                    self.collection.trigger('customSchemaSchema_add', response);
                    self.ui.customSchemaSchemaInsert_input_Name.val('');
                    self.collection.trigger('change');
                }
            });
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    }
});
