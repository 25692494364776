'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventInsert from './tpl/customEventInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import helper_createWebpage from '../../helper/createWebpage';
import helper_createEvent from '../../helper/createEvent';
import config from '../../../common/config';
import View_Render from '../../views/render/render';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customEventInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customEventInsert_btnSubmit': '#customEventInsert_btnSubmit',
        'customEventInsert_inputName': '#customEventInsert_inputName',
        'customEventInsert_btnTest': '#customEventInsert_btnTest',
        'customQRCodeInsert_qrCodeContainer': '#customQRCodeInsert_qrCodeContainer',
        'customQRCodeInsert_container': '#customQRCodeInsert_container',
        'customQRCodeInsert_qrCodeLogo': '#customQRCodeInsert_qrCodeLogo'
    },
    events: {
        'click @ui.customEventInsert_btnSubmit': 'btnSubmitClick',
        'click @ui.customEventInsert_btnTest': 'btnSubmitTest'
    },
    childEvents: {
        'table_addModel': function (childView, msg) {
        }
    },
    onRender: function () {
    },
    btnSubmitClick: function () {
        var self = this;
        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100070;
        dialogWaitingModel.attributes.Text = resources.X100305;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

        var callback = function (id) {
            dialogWaiting.triggerMethod('dialog:close');
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);

            var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: id })
            var render = new View_Render({ collection: customEvent, app: self.app });
            self.app.renderRegion.show(render);
            var renderFinishCallback = function (options) {
                var scroll = $(document).scrollTop();
                window.app.router.navigate("#customEventEditItem/" + id);
                var scroll = $(document).scrollTop();
                window.app.router.customEventEditItem(id, scroll, 0, 1);
            }
            render.doIt(renderFinishCallback, true, 'EVENTINFO_REFRESH', false);
        }
        self.listenTo(dialogWaiting, "dialog:okclicked");
        self.app.dialogRegion.show(dialogWaiting);

        var lat = 0;
        var lng = 0;

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': this.app.entities.models.custom.attributes.Address_country }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                lat = results[0].geometry.location.lat();
                lng = results[0].geometry.location.lng();

                helper_createEvent.createEventDefault(self.app, self, lat, lng, self.$el.find('#customEventInsert_inputName').val(), callback);

            } else {
                lat = 0;
                lng = 0;

                helper_createEvent.createEventDefault(self.app, self, lat, lng, self.$el.find('#customEventInsert_inputName').val(), callback);
            }
        });
    },
    btnSubmitTest: function () {
        var self = this;

        var counter = 0;
        var maxCounter = 40;
        var flag = true;

        var currentEvent = 0;

        //Online
        //var event1  = {id : 119, Webpage: 53, Infopage: 51, QRCode: 129, SchemaModel : 13, FormModel : 7}; 
        //var event2  = {id : 120, Webpage: 52, Infopage: 50, QRCode: 128, SchemaModel : 12, FormModel : 6}; 

        var length = self.app.entities.collections.customEvent.fullCollection.length;

        if (length >= 2) {

            //var event1  = {id : 2197, Webpage: 1018, Infopage: 1009, QRCode: 48, SchemaModel : 2, FormModel : 1}; 
            //var event2  = {id : 2197, Webpage: 1021, Infopage: 1020, QRCode: 55, SchemaModel : 3, FormModel : 2}; 

            var event1 = {
                id: self.app.entities.collections.customEvent.fullCollection.models[length - 1].attributes.id,
                Webpage: self.app.entities.collections.customEvent.fullCollection.models[length - 1].attributes.Webpage,
                Infopage: self.app.entities.collections.customEvent.fullCollection.models[length - 1].attributes.Infopage,
                QRCode: self.app.entities.collections.customEvent.fullCollection.models[length - 1].attributes.QRCode,
                SchemaModel: self.app.entities.collections.customEvent.fullCollection.models[length - 1].attributes.SchemaModel,
                FormModel: self.app.entities.collections.customEvent.fullCollection.models[length - 1].attributes.FormModel
            };

            var event2 = {
                id: self.app.entities.collections.customEvent.fullCollection.models[length - 2].attributes.id,
                Webpage: self.app.entities.collections.customEvent.fullCollection.models[length - 2].attributes.Webpage,
                Infopage: self.app.entities.collections.customEvent.fullCollection.models[length - 2].attributes.Infopage,
                QRCode: self.app.entities.collections.customEvent.fullCollection.models[length - 2].attributes.QRCode,
                SchemaModel: self.app.entities.collections.customEvent.fullCollection.models[length - 2].attributes.SchemaModel,
                FormModel: self.app.entities.collections.customEvent.fullCollection.models[length - 2].attributes.FormModel
            };

            var event = [];
            event.push(event1);
            event.push(event2);

            var callback_event_render = function (id) {
                var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: event[0].id })

                self.app.renderRegion.empty();

                var renderFinishCallback = function (options) {
                    callback();
                }

                var timerId = window.setInterval(function () {
                    clearInterval(timerId);
                    var render = new View_Render({ collection: customEvent, app: self.app });
                    self.app.renderRegion.show(render);
                    render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
                }, 1000);
            }

            var callback = function () {
                currentEvent++;
                if (currentEvent >= event.length) {
                    currentEvent = 0;
                }

                var customEvent = app.entities.collections.customEvent.fullCollection.where({ id: event[0].id });

                if (customEvent[0].attributes.Webpage == event[currentEvent].Webpage) {
                    callback();
                }

                customEvent[0].attributes.Webpage = event[currentEvent].Webpage;
                customEvent[0].attributes.Infopage = event[currentEvent].Infopage;
                customEvent[0].attributes.QRCode = event[currentEvent].QRCode;
                customEvent[0].attributes.SchemaModel = event[currentEvent].SchemaModel;
                customEvent[0].attributes.FormModel = event[currentEvent].FormModel;

                customEvent[0].save(customEvent[0].attributes, {
                    wait: true,
                    silent: true,
                    success: function () {
                        if (counter < maxCounter) {
                            callback_event_render(customEvent[0].attributes.id);
                        }
                        counter++;
                    },
                    error: function () {
                    }
                });
            }

            callback();
        }
    }
});

