import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'create') {
            options.url = config.webApiUrl() + '/api/UserAnalytic/-1?EventId=' + model.attributes.EventId + '&AuthUserId=' + model.attributes.AuthUserId + '&UtcDate=' + model.attributes.UtcDate + '&Days=' + model.attributes.Days + '&MaxCount=' + model.attributes.MaxCount;
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/userAnalytic/-1?EventId=' + options.id + '&AuthUserId=' + config.authUserId();
        } 
        return Backbone.sync(method, model, options);
    }
});