var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom_empty"> <div id="style-switcher-viewevent" style="top: 196px;z-index:799"> <div class="ctrl" id="customViewEvent_style_switcher_ctrl" style="margin-right: 21px;width:60px;height:60px"><i class="fa fa-3x fa-eye" style="margin-top:10px"></i></div> <div class="styles" id="customViewEvent_div_main" style="background: rgba(255, 255, 255, 1.0);margin-right: 26px"> <h5 class="widget-title" style="width:100%"><i id="help" class="fa fa-2x fa-question-circle" style="cursor:pointer; margin-top:-18px"></i>'+
((__t=( resources['X100538'] ))==null?'':__t)+
'</h5>  <div class="radio-inline"> <label style="font-size:12px"> <input id="customViewEvent_input_sortByEvent" type="checkbox" name="bg" class="input-pagebg" style="font-size:12px" value="0"> '+
((__t=( resources['X100553'] ))==null?'':__t)+
' </label> </div> <h5 class="widget-title" id="customViewEvent_information_h5" style="width:100%">'+
((__t=( resources['X100539'] ))==null?'':__t)+
'<i id="customViewEvent_Information" class="fa fa-2x fa-minus-circle" style="cursor:pointer; margin-top:-18px"></i></h5> <div id="customViewEvent_Information_div"> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px">'+
((__t=( resources['X100541'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100542'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px">'+
((__t=( resources['X100543'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100544'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px">'+
((__t=( resources['X100545'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100546'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px">'+
((__t=( resources['X100547'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100548'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px">'+
((__t=( resources['X100549'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100550'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-primary" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px"></button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100551'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn x-btn-disabled btn-event4us-disable" style="width:20px;height:20px;font-size:12px;padding-left: 6px;padding-right: 6px;padding-top: 3px"></button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100552'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#474D56"> <i class="fa fa-2x fa-bar-chart" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100233'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#474D56"> <i class="fa fa-2x fa-comment" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100237'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#474D56"> <i class="fa fa-2x fa-table" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100250'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#474D56"> <i class="fa fa-2x fa-pencil-square-o" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100261'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#474D56"> <i class="fa fa-2x fa-mobile-alt" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100070'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#474D56"> <i class="fa fa-2x fa-magic" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100408'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#333942"> <i class="fa fa-2x fa-html5" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100134'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#333942"> <i class="fa fa-2x fa-television" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100451'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#333942"> <i class="fa fa-2x fa-qrcode" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100071'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#333942"> <i class="fa fa-2x fa-table" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100150'] ))==null?'':__t)+
' </label> </div> </div> <div style="height:1px"></div> <div class="row"> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary" style="width:20px;height:20px;font-size:12px;padding-left: 2px;padding-right: 6px;padding-top: 3px;background-color:#333942"> <i class="fa fa-2x fa-pencil-square-o" style=""></i> </button> </div> <div class="control-label col-md-10"> <label style="font-size: 12px"> '+
((__t=( resources['X100180'] ))==null?'':__t)+
' </label> </div> </div> </div> <div style="height:1px"></div> <h5 id="customViewEvent_h5" style="width:100%">'+
((__t=( resources['X100540'] ))==null?'':__t)+
'</h5> <div id="customViewEventList" style="overflow-y:scroll;overflow-x:hidden"></div>  </div> </div> </div>';
}
return __p;
};
