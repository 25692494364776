﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customProductSheetModelList from './tpl/customProductSheetModelList.html';
import View_CustomProductSheetModelListItem from './customProductSheetModelListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customProductSheetModelList',
    childViewContainer: '#customProductSheetModelList',
    childView: View_CustomProductSheetModelListItem,
    getTemplate: function () {
        return tpl_customProductSheetModelList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
