﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customProductSheetModelListItem from './tpl/customProductSheetModelListItem.html';
import language from '../../../common/language';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customProductSheetModelListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.model.bind('change', this.render, this);
        this.model.bind('destroy', this.remove, this);
    },
    events: {
        'click #customProductSheetModelListItem_btnEdit': 'btnEditClick',
        'click #customProductSheetModelListItem_btnDelete': 'btnDeleteClick'
    },
    cleanup: function () {
        $(this.el).undelegate('#customProductSheetModelListItem_btnEdit', 'click');
        $(this.el).undelegate('#customProductSheetModelListItem_btnDelete', 'click');
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;
        customEvent = this.app.entities.collections.customEvent.fullCollection.where({ ProductSheetModel: this.model.attributes.id }); 

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    btnDeleteClick: function (event) {
        event.preventDefault();

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100180;
        dialog2ButtonModel.attributes.Text = resources.X101105;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100180;
            dialogWaitingModel.attributes.Text = resources.X100384;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);

            var collection = this.app.entities.collections.customProductSheetModel;

            if (this.model.attributes.Name != null) {
                this.model.destroy({
                    wait: true,
                    success: function (model, response) {
                        self.customViewEvent_setActive();
                        dialogWaiting.triggerMethod('dialog:close');
                        collection.fullCollection.sort();
                        if (collection.state.currentPage < collection.state.lastPage) {
                            collection.getPage(collection.state.currentPage);
                        }
                        else {
                            collection.getLastPage();
                        }
                    },
                    error: function (model, response) {
                    }
                });
            }
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnEditClick: function (event) {
        event.preventDefault();
        this.app.router.customProductSheetModelEditItem(this.model.attributes.id);
        var url = 'customProductSheetModelEditItem/' + this.model.attributes.id;
        this.app.router.navigate(url);
    },
    onRender: function () {
        if (this.model.attributes.Name == resources.X100123) {
            this.$el.find('#customProductSheetModelListItem_btnEdit').removeClass('btn-primary');
            this.$el.find('#customProductSheetModelListItem_btnEdit').addClass('btn-disabled');
            this.$el.find('#customProductSheetModelListItem_btnDelete').removeClass('btn-secondary');
            this.$el.find('#customProductSheetModelListItem_btnDelete').addClass('btn-disabled');
        }

        return this;
    }
}, Backbone.Radio.Requests);
