﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import * as Entities_Empty from '../../../lib/entities/empty';

import tpl_module from './tpl/module.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#module_modal',
    errors: [],
    getTemplate: function () {
        return tpl_module({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'module_dropDown_backGroundType': '#module_dropDown_backGroundType',
        'module_btnImage': '#module_btnImage',
        'module_image_background': '#module_image_background',
        'module_inputImage': '#module_inputImage',
        'module_dropDown_backGroundImagePosition': '#module_dropDown_backGroundImagePosition',
        'module_ColorPickerSliders_backGroundColorApp': '#module_ColorPickerSliders_backGroundColorApp',
        'module_ColorPickerSliders_backGroundColor': '#module_ColorPickerSliders_backGroundColor',
        'module_ColorPickerSliders_borderColor': '#module_ColorPickerSliders_borderColor',
        'module_input_backGroundColorOpacity': '#module_input_backGroundColorOpacity',
        'module_input_marginTop': '#module_input_marginTop',
        'module_input_marginBottom': '#module_input_marginBottom',
        'module_input_marginLeft': '#module_input_marginLeft',
        'module_input_marginRight': '#module_input_marginRight',
        'module_cb_border': '#module_cb_border',
        'module_dropDown_borderStyle': '#module_dropDown_borderStyle',
        'module_dropDown_borderSize': '#module_dropDown_borderSize'
    },
    events: {
        //'change @ui.module_ColorPickerSliders_backGroundColor': 'updatebackGroundColor',
        'colorpickerChange @ui.module_ColorPickerSliders_backGroundColor': 'updatebackGroundColor',
        'colorpickerChange @ui.module_ColorPickerSliders_backGroundColorApp': 'updatebackGroundColorApp',
        'colorpickerChange @ui.module_ColorPickerSliders_borderColor': 'updateBorderColor',
        'change @ui.module_btnImage': 'btnImageChange',

        "click #module_btn01": "onBtn01",
        "click #module_btn02": "onBtn02"
    },
    onRender: function () {
        this.ui.module_cb_border.attr('checked', this.model.attributes.Border);
    },
    onAttach() {
        var self = this;

        self.setupImage();

        if ($("#module_input_backGroundColorOpacity").length) {
            var startSlider1 = document.getElementById('module_input_backGroundColorOpacity');
            nouislider.create(startSlider1, {
                start: self.model.attributes.BackGroundColorOpacity,
                connect: [true, false],
                range: {
                    min: 1,
                    max: 100
                },
                step: 1
            });
            startSlider1.noUiSlider.on('set', function (e) {
                self.model.attributes.BackGroundColorOpacity = parseInt(e);
            });
        }

        this.ui.module_ColorPickerSliders_backGroundColorApp.colorpicker({
        //var cp = $('#module_ColorPickerSliders_backGroundColorApp').colorpicker({
            color: self.model.attributes.BackGroundColorApp,
            format: "rgb"
        });

        var cp = this.ui.module_ColorPickerSliders_backGroundColorApp.colorpicker('colorpicker');
        cp.disable();

        this.ui.module_ColorPickerSliders_backGroundColor.colorpicker({
            color: self.model.attributes.BackGroundColor,
            format: "rgb"
        }).on('colorpickerChange', function (e) {
            self.updateBackGroundColor(e);
        });

        this.ui.module_ColorPickerSliders_borderColor.colorpicker({
            color: self.model.attributes.BorderColor,
            format: "rgb"
        }).on('colorpickerChange', function (e) {
            self.updateBorderColor(e);
        });


        self.setupBackGroundImagePosition();
        self.setupBackGroundType();
        self.setupBorderStyle();
        self.setupBorderSize();
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();

        /*this.ui.hfModule_inputStatus.is(':checked') ? this.model.attributes.Status = 1 : this.model.attributes.Status = 0;

        if (this.errors.length > 0) {
            this.showErrors(this.errors);
        } else {
            callback();
        }*/

        callback();
    },
    updateBackGroundColorApp: function (e) {
        this.model.attributes.BackGroundColorApp = e.value.toRgbString();
    },
    updateBackGroundColor: function (e) {
        this.model.attributes.BackGroundColor = e.value.toRgbString();
    },
    updateBorderColor: function (e) {
        this.model.attributes.BorderColor = e.value.toRgbString();
    },
    setupImage: function () {
        if (this.model.attributes.BGImage != '') {
            this.ui.module_image_background.attr('src', this.model.attributes.BackGroundImage);
        }
    },
    setupBackGroundType: function () {
        var self = this;

        this.ui.module_dropDown_backGroundType.empty();

        var selected = '';

        self.app.entities.collections.ModuleBackGroundType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.BackGroundType == entry.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.Name, entry.Value, selected);
            self.ui.module_dropDown_backGroundType.append(options);
        });
    },
    setupBackGroundImagePosition: function () {
        var self = this;

        this.ui.module_dropDown_backGroundImagePosition.empty();

        var selected = '';

        self.app.entities.collections.ModuleBackGroundImagePosition.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.BackGroundImagePosition == entry.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.Name, entry.Value, selected);
            self.ui.module_dropDown_backGroundImagePosition.append(options);
        });
    },
    setupBorderStyle: function() {
        var self = this;

        this.ui.module_dropDown_borderStyle.empty();

        var selected = '';

        self.app.entities.collections.ModuleBorderStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.BorderStyle == entry.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.Name, entry.Value, selected);
            self.ui.module_dropDown_borderStyle.append(options);
        });
    },
    setupBorderSize: function() {
        var self = this;

        this.ui.module_dropDown_borderSize.empty();

        var selected = '';

        for (var i = 1; i <= 20; i++) {
            selected = '';

            if (self.model.attributes.BorderSize == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.module_dropDown_borderSize.append(options);
        }
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf(".");
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1, filename.length).toLowerCase();

            switch (ext) {
                case 'gif': return true; break;
                case 'jpg': return true; break;
                case 'png': return true; break;
                case 'bmp': return true; break;
                case 'tif': return true; break;
                case 'tiff': return true; break;
            }
        }

        return false;
    },
    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();

        if (this.validateExt(filename)) {

            //remove extension
            var dotPosition = filename.lastIndexOf(".");
            if (dotPosition > 0) {
                filename = filename.substring(0, dotPosition);
            }

            this.file = FileAPI.getFiles(event)[0];

            if (this.file) {


                if (this.file.size < 40000000) {

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        self.ui.module_image_background.attr('src', e.target.result);
                        self.model.attributes.BackGroundImage = e.target.result;

                    }
                    reader.readAsDataURL(this.file);
                }
            }
        }
    },
    setModel: function () {
        var border = this.model.attributes.Border;
        this.ui.module_cb_border.is(':checked') ? border = true : border = false;

        this.model.set({
            BackGroundType: this.ui.module_dropDown_backGroundType.val(),
            BackGroundImage: this.model.attributes.BackGroundImage,
            BackGroundImagePosition: this.ui.module_dropDown_backGroundImagePosition.val(),
            BackGroundColor: this.model.attributes.BackGroundColor,
            BackGroundColorApp: this.model.attributes.BackGroundColorApp,
            BorderColor: this.model.attributes.BorderColor,
            BackGroundColorOpacity : this.model.attributes.BackGroundColorOpacity,
            Border: border,
            BorderStyle: this.ui.module_dropDown_borderStyle.val(),
            BorderSize: this.ui.module_dropDown_borderSize.val(),
            MarginTop: this.ui.module_input_marginTop.val(),
            MarginBottom: this.ui.module_input_marginBottom.val(),
            MarginLeft: this.ui.module_input_marginLeft.val(),
            MarginRight: this.ui.module_input_marginRight.val()
        });
    }
});