var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div> <div id="pageSettingListItem_div_inner" style="margin:3px;border-width: 3px; border-color: black;border-style: none;width:108px; height:187px;max-width:108px; max-height:187px"> <div id="pageSettingListItem_div" class="menuDiv" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px;border-top-width: 1px;border-right-width: 1px;border-bottom-width: 1px;border-left-width: 1px"> <a id="pageSettingListItem_a" data_id="'+
((__t=( model.id ))==null?'':__t)+
'" data_orgid="'+
((__t=( model.orgId ))==null?'':__t)+
'" data-toggle="collapse" class="ancorli '+
((__t=( model.ClassnameA ))==null?'':__t)+
'" data-parent="#accordion" href="#" style="width:100%"> <div class="card" style="margin-left:0px; margin-right:0px; border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px"> <div class="card-body" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px;    border-bottom-width: 0px;    border-left-width: 0px"> <div id="pageSettingListItem_template" style="width:98px; height:180px; padding: 0px; border: 0px"> <div style="transform: scale(0.094); transform-origin: 0% 0%"> <div id="grid_container"> </div> </div> </div> </div> </div> </a> </div> </div> </div>';
}
return __p;
};
