import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Calculation: {}
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'update') {
            options.url = config.webApiUrl() + '/api/custom/route03/' + model.attributes.id + '?AuthUserId=' + config.authUserId() + '&dummy=dummy';
        }else if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/custom/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        
        return Backbone.sync(method, model, options);
    },
    initialize: function () {
        var that = this;
    },
    validateNoBackbone: function (attrs, app, callback, validateEmail, validatePassword) {
        this.errors = [];

        var self = this;

        
        
        var tmpModel = {};
        tmpModel.Method = 'GetEmailUsed';
        tmpModel.Value = attrs.Email;

        app.entities.collections.publicCustom.fetch ({
            silens : true,
            wait : true,
            tmpModel: tmpModel,
            success: function (mode, response) {
                callbackInternal(false);
            },
            error : function (mode, response) {
                callbackInternal(true);
            },
        });


        var callbackInternal = function (error) {

            if (validateEmail) {
                //Fields required
                if (error) {
                    self.errors.push({ name: 'customCrediential_inputEmail', message: resources.X100599 });
                }

                if (attrs.Email.length > 0) {
                    if (!attrs.Email) {
                        self.errors.push({ name: 'customCrediential_inputEmail', message: resources.X100324 });
                    }
                    //^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$
                    var validate_Email = app.entities.collections.languageValidation_Collection.where({ Name: 'register_inputEmail' });
                    //var validate_Email = app.entities.collections.publicConfigSubscriptionValidate.where( {Name : 'register_inputEmail'});
                    if (validate_Email && validate_Email.length > 0) {
                        var re = new RegExp(validate_Email[0].attributes.Value, 'g');
                        if(attrs.Email.match(re) == null) {
                            self.errors.push({ name: 'customCrediential_inputEmail', message: validate_Email[0].attributes.Error });
                        }
                    }
                }
            }

            if (validatePassword) {
                if (attrs.Password.length > 0) {
                    if (!attrs.Password) {
                        self.errors.push({ name: 'customCrediential_inputPassword', message: resources.X100324 });
                    }
                    //^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$
                    var validate_Password = app.entities.collections.languageValidation_Collection.where({ Name: 'register_inputPassword' });
                    //var validate_Password = app.entities.collections.publicConfigSubscriptionValidate.where( {Name : 'register_inputPassword'});
                    if (validate_Password && validate_Password.length > 0) {
                        var re = new RegExp(validate_Password[0].attributes.Value, 'g');
                        if(attrs.Password.match(re) == null) {
                            self.errors.push({ name: 'customCrediential_inputPassword', message: validate_Password[0].attributes.Error });
                        }
                    }

                    if (!attrs.PasswordConfirm) {
                        self.errors.push({ name: 'customCrediential_inputPasswordConfirm', message: resources.X100324 });
                    }
                    if (attrs.PasswordConfirm != attrs.Password) {
                        self.errors.push({ name: 'customCrediential_inputPasswordConfirm', message: resources.X100597 });
                    }
                }
            }

            if (self.errors.length > 0) {
                callback(false);
                //return true
            }
            else {
                callback(true);
                //return false;
            }
        }
    }
});

    