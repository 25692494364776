'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogWaiting from '../../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button2 from '../../../../lib/views/dialog2Button2/dialog2Button2';
import View_DialogBox2 from '../../../../lib/views/dialogBox2/dialogBox2';
import * as Entities_Empty from '../../../../lib/entities/empty';

//import View_HtmlSlideShow from '../../module/htmlSlide';
import View_HtmlSlide from './htmlSlide';

import View_HtmlSlideListListItemProperties from './htmlSlideListListItemProperties';
import tpl_htmlSlideListListItem from './tpl/htmlSlideListListItem.html';

export default Marionette.ItemView.extend({
    className: 'myDiv',
    tagName: 'div',
    attributes: function () {
        return { //transform: translate(0px, -340px); 
            id: "htmlSlideListListItem_tr",// + this.model.attributes.id,
            style: "border-width: 3px; border-color: black;border-style: none; max-width:150px;display:inline-block;",
            'data-id': this.model.attributes.id,
            'data-position': this.model.attributes.Position
        };
    },
    getTemplate: function () {
        return tpl_htmlSlideListListItem({ model: this.model.toJSON() });
    },
    //onBeforeShow: function () {
    //    this.htmlSlideshow.show(new View_HtmlSlideShow({ app: this.app, model: model }));
    //},
    //regions: {
    //    htmlSlideshow: "#htmlSlideshow",
    //},
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
        //this.model.bind('destroy', this.remove, this);
    },
    ui: {
        'htmlSlideListListItem_tr': '#htmlSlideListListItem_tr',
        'htmlSlideListListItem_div': '#htmlSlideListListItem_div',
        'htmlSlideListItem_div_inner': '#htmlSlideListItem_div_inner',
        'htmlSlideListListItem_iHtmlSlide': '#htmlSlideListListItem_iHtmlSlide',
        'htmlSlideListListItem_iDelete': '#htmlSlideListListItem_iDelete',
        'htmlSlideListItem_div_htmlSlide': '#htmlSlideListItem_div_htmlSlide'
    },
    events: {
        'click @ui.htmlSlideListItem_div_inner': 'htmlSlideListItem_div_inner_Click',
        'click @ui.htmlSlideListListItem_iHtmlSlide': 'iHtmlSlideClick',
        'click @ui.htmlSlideListListItem_iDelete': 'iDeleteClick'
    },
    onAttach: function () {
    },
    setupSetting() {
        var self = this;

        var element = this.ui.htmlSlideListItem_div_htmlSlide;

        //element.css('all','unset');

        this.settingModel = this.app.entities.collections.ModuleSetting.where({ id: this.options.parentModel.attributes.ModuleSetting })[0];

        var style = this.app.entities.collections.ModuleBorderStyle.where({ Value: this.settingModel.attributes.BorderStyle })[0].attributes.Style;

        if (this.settingModel.attributes.Border) {
            style.forEach(styleValue => element.css(styleValue.Name, styleValue.Value));
            element.css('border-color', this.settingModel.attributes.BorderColor);
            element.css('border-width', this.settingModel.attributes.BorderSize);
        }
        else {
            element.css('border-style', 'none');
        }

        var margin = element.find('#margin');
        margin.css('margin-top', this.settingModel.attributes.MarginTop + 'px');
        margin.css('margin-bottom', this.settingModel.attributes.MarginBottom + 'px');
        margin.css('margin-left', this.settingModel.attributes.MarginLeft + 'px');
        margin.css('margin-right', this.settingModel.attributes.MarginRight + 'px');

        switch (this.settingModel.attributes.BackGroundType) {
            case "1": element.css('background-color', this.settingModel.attributes.BackGroundColor); break;
            case "0": element.css('background-color', this.settingModel.attributes.BackGroundColorApp); break;
        }

        element.css('opacity', this.settingModel.attributes.BackGroundColorOpacity / 100);

        /*var backGroundImagePosition = self.app.entities.collections.ModuleBackGroundImagePosition.findIndex(function (post, index) {
            if (post.Value == self.model.attributes.BackGroundImagePosition)
                return true;
        });
        var style = self.app.entities.collections.ModuleBackGroundImagePosition[backGroundImagePosition].Style;
        */
        var style = self.app.entities.collections.ModuleBackGroundImagePosition.where({ Value: self.settingModel.attributes.BackGroundImagePosition })[0].attributes.Style;
        style.forEach(styleValue => element.css(styleValue.Name, styleValue.Value));

        switch (this.settingModel.attributes.BackGroundType) {
            case "0": element.css('background-image', 'none'); break;
            case "1": element.css('background-image', 'none'); break;
            case "2": element.css('background-image', String.format("url('{0}'", self.settingModel.attributes.BackGroundImage));
                break;
        }
    },
    onRender: function () {
        if (this.model.attributes.Selected) {
            this.ui.htmlSlideListItem_div_inner.css('border-style', 'solid');
            this.ui.htmlSlideListItem_div_inner.css('border-color', 'black');
        }
        else {
            this.ui.htmlSlideListItem_div_inner.css('border-style', 'solid');
            this.ui.htmlSlideListItem_div_inner.css('border-color', 'white');
        }

        this.setupSetting();
    },
    htmlSlideListItem_div_inner_Click: function (event) {
        var self = this;
        this.model.collection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: false });
            }
        });

        this.model.set({ Selected: true });
        this.model.collection.trigger('change');
    },
    iHtmlSlideClick: function (event) {
        /*var self = this;

        var htmlSlide = new View_HtmlSlide({ app: this.app, model: this.model, parent: self });

        var callback01 = function () {
            self.stopListening(htmlSlide, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlide, "dialog:btn02clicked", callback02);
            self.model.trigger('change');
        }
        this.listenTo(htmlSlide, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(htmlSlide, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlide, "dialog:btn02clicked", callback02);

        }
        this.listenTo(htmlSlide, "dialog:btn02clicked", callback02);

        self.app.dialogRegion2.show(htmlSlide);*/

        var self = this;

        var properties = new View_HtmlSlideListListItemProperties({ app: this.app, model: this.model, parent: self });

        var callback01 = function () {
            self.stopListening(htmlSlide, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlide, "dialog:btn02clicked", callback02);
            self.model.trigger('change');
        }
        this.listenTo(properties, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(htmlSlide, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlide, "dialog:btn02clicked", callback02);

        }
        this.listenTo(properties, "dialog:btn02clicked", callback02);

        self.app.dialogRegion2.show(properties);
    },
    iDeleteClick: function (event) {
        if (this.model.collection.length <= 1) {
            this.message(resources.X101459);
        }
        else {

            var self = this;

            event.preventDefault();

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X101341;
            dialog2ButtonModel.attributes.Text = resources.X101365;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button2({ model: dialog2ButtonModel, parent: this });

            var callback01 = function (options) {
                this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                this.model.collection.remove(this.model);

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('htmlSlideList:reOrder');
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion2.show(dialog2Button);
        }
    },
    message: function (text) {
        var self = this;

        var dialogBoxModel = new Entities_Empty.Model();
        dialogBoxModel.attributes.Title = resources.X100229;
        dialogBoxModel.attributes.Text = text;
        dialogBoxModel.attributes.Button = resources.X100279;

        var dialogBox = new View_DialogBox2({ model: dialogBoxModel, parent: this });
        var callback = function (options) {
        }
        this.listenTo(dialogBox, "dialog:okclicked", callback);
        this.app.dialogRegion2.show(dialogBox);
    }
});