﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import config from '../../../../common/config';
import language from '../../../../common/language';
import * as Entities_Empty from '../../../../lib/entities/empty';
import tpl_languageSelector from './tpl/languageSelector.html';
import View_LanguageSelectorList from './languageSelectorList';

export default Marionette.LayoutView.extend({
    tagName: 'li',
    className: 'nav-item dropdown',
    attributes: function () {
        return {
            id: 'languageSelector_li',
        }
    },
    initialize: function () {
        this.app = this.options.app;

        var luxaforboard_userCultureName = language.getCookie('luxaforboard_userCultureName');
        this.model = self.app.entities.collections.languageSetting.where({ Code: luxaforboard_userCultureName })[0];

        //this.model = new Entities_Empty.Model();
        //this.model.attributes.CultureName = language.getLanguageFromCodeCultureName(config.cultureName());
    },
    regions: {
        languageSelectorList: "#languageSelectorList"
    },
    onBeforeShow: function () {
        this.languageSelectorList.show(new View_LanguageSelectorList({ collection: this.collection, app: this.app }));
    },
    getTemplate: function () {
        return tpl_languageSelector({ model: this.model.toJSON() });
    },
    ui: {
       // 'languageSelectorListItem': '#languageSelectorListItem'
    },
    events: {
        //'change @ui.languageSelectorListItem': 'languageSelectorDropdownChange',
    },
    onRender: function () {
    },
    onAttach() {
    },
    languageSelectorDropdownChange: function (event) {
        event.preventDefault();

        this.model = self.app.entities.collections.languageSetting.where({ Name: this.ui.languageSelectorDropdown.val() })[0];
        language.userLanguageChange(this.model.attributes.Code);
    },
}, Backbone.Radio.Requests);