'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';

import * as Bootstrap from 'bootstrap';

import dummy from '../../app/plugins';


import tpl_customEventPushListItem from './tpl/customEventPushListItem.html';
import language from '../../../common/language';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customEventPushListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.setupMessage();
    },
    ui: {
        'customEventPushListItem_btnDelete': '#customEventPushListItem_btnDelete',
        'customEventPushListItem_btnResend': '#customEventPushListItem_btnResend',
    },
    events: {
        'click @ui.customEventPushListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customEventPushListItem_btnResend': 'btnResendClick'
    },
    onRender: function () {
        
    },
    setupMessage: function () {

        

        var utcDate = new Date(this.model.attributes.UtcDate + 'Z');

        //var utcDateTime = utcDate .toLocaleDateString() + '-' + utcDate.toLocaleTimeString();
        

        //2013-02-27T17:00:00Z

        var utcDateTime = utcDate.getFullYear().toString() + '-' +
            (utcDate.getMonth() + 1) + '-' +
             utcDate.getDate().toString() + 'T' +
             utcDate.getHours() + ':' + utcDate.getMinutes() + ':' + utcDate.getSeconds() + 'Z';

        var utcDateTime2 = new Date(utcDate);
        var utcDisplayDateTime = utcDateTime2.toLocaleDateString() + '-' + utcDateTime2.toLocaleTimeString();

        var onlyTime = utcDateTime2.toLocaleTimeString().substring(0, utcDateTime2.toLocaleTimeString().length - 3);
        utcDisplayDateTime = utcDisplayDateTime.substring(0, utcDisplayDateTime.length - 3);
        //var utcDateTime = utcDate .toLocaleDateString() + '-' + utcDate.toLocaleTimeString();

        this.model.attributes.Date = utcDateTime2.getDate().toLocaleString();
        this.model.attributes.Date = utcDateTime2.getMonth().toLocaleString();


        //this.model.attributes.TmpMessage = utcDateTime + '  -  ' + this.model.attributes.Message;
        this.model.attributes.TmpMessage = this.model.attributes.Message;
        this.model.attributes.TmpMonth = this.getMonth3Letter(utcDateTime2.getMonth()+1);
        this.model.attributes.TmpDate = utcDateTime2.getDate().toLocaleString();
        this.model.attributes.TmpYear = utcDateTime2.getFullYear();
        this.model.attributes.TmpTime = onlyTime;
    },
    getMonth3Letter: function (month) {
        switch (month) {
            case 1: return resources.X101033; break;
            case 2: return resources.X101034; break;
            case 3: return resources.X101035; break;
            case 4: return resources.X101036; break;
            case 5: return resources.X101037; break;
            case 6: return resources.X101038; break;
            case 7: return resources.X101039; break;
            case 8: return resources.X101040; break;
            case 9: return resources.X101041; break;
            case 10: return resources.X101042; break;
            case 11: return resources.X101043; break;
            case 12: return resources.X101044; break;

        }
    },
    btnDeleteClick: function (event) {
        event.preventDefault();

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100237;
        dialog2ButtonModel.attributes.Text = resources.X100243;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100237;
            dialogWaitingModel.attributes.Text = resources.X100243;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            app.dialogRegion.show(dialogWaiting);

            var collection = this.app.entities.collections.customEventPush;

            this.model.destroy({
                wait: true,
                success: function (model, response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    collection.fullCollection.sort();
                    if (collection.state.currentPage < collection.state.lastPage) {
                        collection.getPage(collection.state.currentPage);
                    }
                    else {
                        collection.getLastPage();
                    }

                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customEventPush:eventChanged');
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnResendClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100070;
        dialog2ButtonModel.attributes.Text = resources.X100389;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            var now = new Date();

            var utcDateTime = now.getUTCFullYear().toString() + '-' +
                (now.getUTCMonth() + 1) + '-' +
                now.getUTCDate().toString() + ' ' +
                now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();

            var tmpModel = new this.app.entities.collections.customEventPush.model();
            //tmpModel.attributes.id = null;
            tmpModel.attributes.AuthUserId = config.authUserId();
            tmpModel.attributes.EventId = this.model.attributes.EventId;
            tmpModel.attributes.UtcDate = utcDateTime;
            tmpModel.attributes.Message = this.model.attributes.Message;

            this.app.entities.collections.customEventPush.create(tmpModel, {
                silens :true,
                wait: true,
                success: function (response) {
                    self.options.localSetting.attributes.LastMessage = tmpModel.attributes.Message;
                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customEventPush:pushMessage');

                    self.app.entities.collections.customEventPush.fullCollection.sort();
                    self.app.entities.collections.customEventPush.getPage(1);

                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customEventPush:eventChanged');
                
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    }
});

