﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customInfopageEditItem from './tpl/customInfopageEditItem.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';


import View_DialogPreviewWebpageZoom from '../dialogPreview/dialogPreviewWebpageZoom';
import View_DialogPreviewWebpageZoomIPad from '../dialogPreview/dialogPreviewWebpageZoomIPad';
import View_DialogPreviewDias from '../dialogPreview/dialogPreviewDias';

import * as Entities_Memory_Position from '../../entities/memoryPosition';
import * as Entities_Memory from '../../entities/memory';

import config from '../../../common/config';
import View_Render from '../render/render';
import helper_createWebpage from '../../helper/createWebpage';
import Help from '../../helper/help';

import * as Entities_TmpcustomInfopageItem from '../../entities/tmpcustomInfopageItem';

import * as Entities_customInfopageItem from '../../entities/customInfopageItem';
import View_customInfopageEditItemTemplateTopbar from './customInfopageEditItemTemplateTopbar';
import View_customInfopageEditItemTopbar from './customInfopageEditItemTopbar';
import View_CustomInfopageEditItemRightContent from './customInfopageEditItemRightContent';

import View_NewPage from './setting/NewPage';
import View_CopyTemplate from './setting/CopyTemplate';
import View_customInfopageSetting from './setting/customInfopageSetting';

import View_Template from '../../info/template/template';



import * as Entities_ModuleData from '../../entities/moduleData';

import GsapRegion from '../../../lib/views/gsapRegion/gsapRegion';


var gsapRegion = GsapRegion.extend({
    //el: "#template"
});


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customInfopageEditItem({ model: this.model.toJSON() });
    },
    regions: {
        customInfopageEditItemTemplateTopbar: "#customInfopageEditItemTemplateTopbar",
        customInfopageEditItemRightContent: "#customInfopageEditItemRightContent",
        customInfopageEditItemTopbar: "#customInfopageEditItemTopbar",
        //template:
        template: {
            selector: "#template", regionClass: gsapRegion
        }
    },
    setupModelTemplateTop: function () {
        var modelTemplateTop = new Entities_Empty.Model();
        modelTemplateTop.attributes.Zoom = 1;
        
        return modelTemplateTop;
    },
    setupModelTopBar: function () {
        var modelTopBar = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
        if (customEvent.length > 0) {
            modelTopBar.attributes.Name = customEvent[0].attributes.Name;
        } else {
            modelTopBar.attributes.Name = "";
        }

        modelTopBar.attributes.Title = resources.X100134;
        modelTopBar.attributes.ShowSave = true;
        modelTopBar.attributes.ShowBack = true;

        switch (this.model.attributes.DisplayType) {
            case 1:
                modelTopBar.attributes.TemplateTypeName = resources.X101279;
                break;
            case 2:
                modelTopBar.attributes.TemplateTypeName = resources.X101276;
                break;
            case 3:
                modelTopBar.attributes.TemplateTypeName = resources.X101282;
                break;
            case 4:
                modelTopBar.attributes.TemplateTypeName = resources.X101452;
                break;
        }

        return modelTopBar;
    },
    onBeforeShow: function () {
        var modelTemplateTop = this.setupModelTemplateTop();
        var modelTopBar = this.setupModelTopBar();
        
        this.selectedModel = this.collection.where({ Selected: 1 })[0];
        this.collection.sortByPosition();
        this.selectedModel_collection = new Entities_Memory_Position.Collection(this.selectedModel.attributes.Collection);
        this.selectedModel_collection.sortByPosition();

        var tmpSelectedModelSetting = this.model.collection.where({ EventId: this.model.attributes.EventId, ModuleType: this.model.attributes.ModuleType + 10 })[0];

        var data = JSON.parse(tmpSelectedModelSetting.attributes.Data);
        this.selectedModelSetting = new Entities_Memory.Model(data[0].Data);

        app.entities.models.InfoPageSetting = this.selectedModelSetting;


        /*var data = this.app.entities.collections.customModule.where({ id: 2 })[0];
        var myDataArray = JSON.parse(data.attributes.Data);
        var dataListCollection = new Backbone.Collection(myDataArray);
        var dataListCollectionModel = dataListCollection.where({ Selected: 1 })[0];
        dataListCollectionModel.attributes.obj = "obj";

        var strJson = JSON.stringify(dataListCollection);
        this.app.entities.collections.customModule.where({ id: 2 })[0].attributes.Data = strJson;*/




        //this.app.entities.collections.ModuleData = new Backbone.Collection(ModuleData.attributes.Collection);



        //this.addRegion("template", "#template");

        this.old_View_Template = new View_Template({ model: this.selectedModel, collection: this.selectedModel_collection, app: this.app, eventId: this.options.eventId, customEvent: this.options.customEvent });
        this.template.show(this.old_View_Template);

        this.customInfopageEditItemTemplateTopbar.show(new View_customInfopageEditItemTemplateTopbar({ model: modelTemplateTop, app: this.app }));
        this.customInfopageEditItemRightContent.show(new View_CustomInfopageEditItemRightContent({ customModuleModel: this.model, model: this.selectedModelSetting/*self.app.entities.models.InfoPageSetting*//*infoPageRightContent*/, collection: this.collection, app: this.app }));
        this.customInfopageEditItemTopbar.show(new View_customInfopageEditItemTopbar({ app: this.app, model: modelTopBar }));
    },
    save: function (callbackRender) {

        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100408;
        dialogWaitingModel.attributes.Text = resources.X100181;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        this.selectedModel.attributes.Collection = this.selectedModel_collection;
        var data = [{ Collection: this.collection }];
        var strJson = JSON.stringify(data);
        this.app.entities.collections.customModule.where({ id: this.model.attributes.id })[0].attributes.Data = strJson;

        this.app.entities.collections.customModule.where({ id: this.model.attributes.id })[0].save(this.app.entities.collections.customModule.where({ id: this.model.attributes.id })[0].attributes, {
            silent: true,
            wait: true,
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
                self.oldModel = self.model.clone();

                var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ FormModel: self.model.attributes.id })
                var render = new View_Render({ collection: customEvent, app: self.app });
                self.app.renderRegion.show(render);
                var renderFinishCallback = function (options) {
                }
                render.doIt(renderFinishCallback, false, 'EVENTINFO_REFRESH', false);
            }
        });

        var data = [{ Data: this.selectedModelSetting.attributes }];
        var strJson = JSON.stringify(data);
        this.app.entities.collections.customModule.where({ EventId: this.model.attributes.EventId, ModuleType: this.model.attributes.ModuleType + 10 })[0].attributes.Data = strJson;
        this.app.entities.collections.customModule.where({ EventId: this.model.attributes.EventId, ModuleType: this.model.attributes.ModuleType + 10 })[0].save(this.app.entities.collections.customModule.where({ EventId: this.model.attributes.EventId, ModuleType: this.model.attributes.ModuleType + 10 })[0].attributes, {
            silent: true,
            wait: true,
            success: function () {
            }
        });


    },
    saveOld: function (callbackRender) {
        var self = this;

        self.app.dialogRegion.empty();

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100134;
        dialogWaitingModel.attributes.Text = resources.X100309;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        //var timeout_id = setTimeout(function () {       // start counting down till fail

        //}, 5000);

        //this.customInfopageEditItemList.updateWebpagePositionNew();

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemList:updateWebpagePosition');

        var oldModel = this.collection.where({ Selected: true });

        $('#dialogWaiting_modal').on('shown.bs.modal', function () {
            $.when(self.list_deleteAll(), self.list_insertAll(), self.list_editAll()).done(function (return_delete, return_insert, return_edit/*, return_refresh*/) {
                // the code here will be executed when all four ajax requests resolve.
                // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
                // status, and jqXHR object for each of the four ajax calls respectively.

                //this.app.entities.views.customSchemaData.table_readDataCollection();

                var callbackRenderFromFetch = function () {
                    dialogWaiting.triggerMethod('dialog:closeclicked', "msg")
                    dialogWaiting.trigger("dialog:close");

                    //dialogWaiting.triggerMethod('dialog:close');
                    /*dialogWaiting.triggerMethod('OnDialogOkClicked');
                    dialogWaiting.triggerMethod('dialog:okclicked');

                    dialogWaiting.triggerMethod('dialog:onOK');*/




                    /*var timeout_id = setTimeout(function () {       // start counting down till fail
                        this.app.dialogRegion.empty();
                        $('.modal-backdrop').remove();
    
                        dialogWaiting.modal('show');
    
                        dialogWaiting.triggerMethod('dialog:okclicked');
    
    
                    }, 5000);*/

                    //dialogWaiting.triggerMethod('modal:close');

                    self.setHasChanged(false);
                    callbackRender();
                }

                self.list_refresh(oldModel, callbackRenderFromFetch);
            });
        });
    },
    btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.eventId);
        this.app.router.customEventEditItem(this.options.eventId, this.options.scroll, this.options.module_selected);
    },
    initialize: function () {
        this.app = this.options.app;
        var self = this;

        //this.listenTo(this.model, 'change', this.render);

        this.localSetting = new Entities_Empty.Model();

        this.copyCollection = this.options.copyCollection;

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.btnBack_click();
        });

        this.listenTo(userChannel, 'customInfopageEditor:saveContent', function () {
            self.saveSelectedModel();
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconSave', function () {
            this.btnSubmit(null, false, true, null, false);
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconFile', function () {
            this.app.router.navigate("#customFile?id=" + this.options.eventId);
            this.app.router.customFile(this.options.eventId, this.options.scroll, this.options.module_selected, 0);
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconImage', function () {
            //var scroll = $(document).scrollTop();

            this.app.router.navigate("#customImage?id=" + this.options.eventId);
            this.app.router.customImage(this.options.eventId, this.options.scroll, this.options.module_selected, 0);
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconCog', function () {
            self.customInfopageSetting();
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconBars', function () {
            self.newPage();
        });


        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconCopy', function () {
            self.copyTemplate();
        });

        this.listenTo(userChannel, 'customInfopageEditItem:requestFullScreen', function () {
            $('Body').addClass('sidebar-icon-only');

            var timerId = window.setInterval(function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customInfopageEditItem:iconArrowsAlt');
                userChannel.trigger('template:setZoom');
                clearInterval(timerId);
            }, 1000);
        });
        this.listenTo(userChannel, 'customInfopageEditItem:cancelFullScreen', function () {
            $('Body').removeClass('sidebar-icon-only');

            var timerId = window.setInterval(function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customInfopageEditItem:iconArrowsAlt');
                userChannel.trigger('template:setZoom');
                clearInterval(timerId);
            }, 1000);
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconArrowsAlt', function () {
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (document.documentElement.requestFullScreen) {
                    document.documentElement.requestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullscreen();
                }
            } else {

                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        });


        this.localSetting.attributes.themeChanged = false;

        this.listenTo(userChannel, 'customInfopageEditor:themeChange', function () {
            self.localSetting.attributes.themeChanged = true;
        });

        this.listenTo(userChannel, 'customSetting:refreshPublishBtn', function () {
            var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
            if (customSetting[0].attributes.Value == '1') {
                if (this.model.attributes.DisplayType == 0) {
                    self.ui.customInfopageEditItem_btnPublish.show();
                } else {
                    self.ui.customInfopageEditItem_btnPublish.css('display', 'none');
                }
            } else {
                this.ui.customInfopageEditItem_btnPublish.css('display', 'none');
            }
        });

        this.listenTo(userChannel, 'customInfopageEditItemListItem:deleteModel', function (model) {
            self.deleteModel(model);
        });

        this.listenTo(userChannel, 'customInfopageEditItemListItem:saveSelectedModel', function () {
            self.saveSelectedModel();
        });

        this.listenTo(userChannel, 'customInfopageEditItemListItem:setSelectedModel', function () {
            //self.model.trigger('change');
            var ModuleData = self.collection.where({ Selected: 1 })[0];
            self.app.entities.collections.ModuleData = new Backbone.Collection(ModuleData.attributes.Collection);

            this.new_View_Template = new View_Template({ model: ModuleData, collection: self.app.entities.collections.ModuleData, app: self.app, eventId: self.options.eventId, customEvent: self.options.customEvent });

            self.template.showAnimated(this.new_View_Template, { preventDestroy: true, animationType: 'flipLeft' });
            //self.template.show(this.new_View_Template);
        });

        this.listenTo(userChannel, 'customInfopageEditItemListItem:disabledWebpageContent', function (model) {
            self.ui.customInfopageEditItem_panel.addClass('disabledWebpageContent');
        });
        this.listenTo(userChannel, 'customInfopageEditItemListItem:enableWebpageContent', function (model) {
            var timeout_id = setTimeout(function () {
                clearTimeout(timeout_id);
                if (self.ui.customInfopageEditItem_panel.removeClass != undefined) {
                    self.ui.customInfopageEditItem_panel.removeClass('disabledWebpageContent');
                    //Maybe here error
                }
            }, 500);
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconTabletAlt', function () {
            self.iconTabletAlt();
        });


        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconMobileAlt', function () {
            self.iconMobileAlt();
        });


        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconWpforms', function () {
            self.iconWpforms();
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconTh', function () {
            self.iconTh();
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconTv', function () {
            self.iconTv();
        });

        this.listenTo(userChannel, 'customInfopageEditItemTopbar:iconDias', function () {
            self.iconDias();
        });

        this.setHasChanged(false);
    },
    help: function () {
        switch (this.model.attributes.DisplayType) {
            case 0: Help.help(this, true, 'customInfopageEditItem'); break;
            case 1: Help.help(this, true, 'customInfopageEditItem'); break;
        }
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        var self = this;
        var timeout_id = setTimeout(function () {
            clearTimeout(timeout_id);
            self.btnSubmit(null, false, true, callback, false);
        }, 500);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {

        this.saveSelectedModel();
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');
        userChannel.trigger('customInfopageEditItemList: reorder');


        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'customInfopageEditItem_inputName': '#customInfopageEditItem_inputName',
        'customInfopageEditItem_btnSubmit': '#customInfopageEditItem_btnSubmit',
        'customInfopageEditItem_btnPublish': '#customInfopageEditItem_btnPublish',
        'customInfopageEditItem_btnRename': '#customInfopageEditItem_btnRename',
        'customInfopageEditItem_btnPaintStrapSubmit': '#customInfopageEditItem_btnPaintStrapSubmit',
        'customInfopageEditItem_btnTest': '#customInfopageEditItem_btnTest',
        'customInfopageEditItem_selectItem': '#customInfopageEditItem_selectItem',

        'customInfopageEditItem_inputInsertWebpageName': '#customInfopageEditItem_inputInsertWebpageName',
        'customInfopageEditItem_btnInsertWebpage': '#customInfopageEditItem_btnInsertWebpage',
        'customInfopageEditItem_selectWebpage': '#customInfopageEditItem_selectWebpage',
        'customInfopageEditItem_btnCopyWebpage': '#customInfopageEditItem_btnCopyWebpage',

        'customInfopageEditItem_labelselectWebpage': '#customInfopageEditItem_labelselectWebpage',
        'customInfopageEditItem_h5': '#customInfopageEditItem_h5',
        'customInfopageEditItem_panel': '#customInfopageEditItem_panel',
        'customInfopageEditItem_btnSaveWebpage': "#customInfopageEditItem_btnSaveWebpage",
        'settings-trigger': '#settings-trigger',
        'settings_trigger_icon': '#settings_trigger_icon',
        'help': '#help'
    },
    events: {
        'click @ui.customInfopageEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customInfopageEditItem_btnPublish': 'btnPublishClick',
        'click @ui.customInfopageEditItem_btnInsertWebpage': 'btnInsertWebpageClick',
        'click @ui.customInfopageEditItem_btnTest': 'btnTest',
        'click @ui.customInfopageEditItem_btnCopyWebpage': 'btnCopyWebpage',
        'change @ui.customInfopageEditItem_selectWebpage': 'selectWebpageChange',
        'click @ui.customInfopageEditItem_btnSaveWebpage': 'btnSaveWebpageClick',
        'click @ui.settings-trigger': 'btnSettingClick',
        'click @ui.settings_trigger_icon': 'btnSettingClick',
        'click #help': 'help'
    },
    updateWebpagePosition: function () {
        /*var zeroIndex = -1;
        switch (this.model.attributes.DisplayType) {
            case 0: zeroIndex = 0; break;
            case 1: zeroIndex = -1; break;
        }
        var self = this;
        this.$el.find('#customInfopageEditItem_selectItem li').each(function (index) {
            var liId = parseInt(String($(this).data('id')));
            var tmpModel = self.collection.where({ id: liId });
            if (tmpModel[0].attributes.Position != zeroIndex
                && tmpModel[0].attributes.Position != 2147483647
                && tmpModel[0].attributes.Position != 2147483646
                && tmpModel[0].attributes.Position != 2147483645
                && tmpModel[0].attributes.Position != 2147483644
                && tmpModel[0].attributes.Position != 2147483643
                && tmpModel[0].attributes.Position != 2147483642
                && tmpModel[0].attributes.Position != 2147483641
                && tmpModel[0].attributes.Position != index) {
                tmpModel[0].attributes.Position = index;
            }
        });*/
    },
    onRender: function () {
        var self = this;

        //var ModuleData = this.collection.where({ Selected: 1 })[0];
        //this.app.entities.collections.ModuleData = new Backbone.Collection(ModuleData.attributes.Collection);
        //this.template.show(new View_Template({ collection: self.app.entities.collections.ModuleData, app: this.app }));


        /*var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100134;
        dialogWaitingModel.attributes.Text = resources.X100529;
        dialogWaitingModel.attributes.Button = resources.X100279;
    
        this.dialogWaiting_initialize = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(self.dialogWaiting_initialize, "dialog:okclicked", callback);
        }
        this.listenTo(this.dialogWaiting_initialize, "dialog:okclicked", callback);
        this.app.dialogRegion.show(this.dialogWaiting_initialize);*/



        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
        if (customSetting[0].attributes.Value == '1') {
            if (this.model.attributes.DisplayType == 0) {
                this.ui.customInfopageEditItem_btnPublish.show();
            } else {
                this.ui.customInfopageEditItem_btnPublish.css('display', 'none');
            }
        } else {
            this.ui.customInfopageEditItem_btnPublish.css('display', 'none');
        }


        this.setupCopyWebpage();

        var text = '';

        switch (this.model.attributes.DisplayType) {
            case 0:
                this.ui.customInfopageEditItem_labelselectWebpage.text(resources.X100148);
                this.ui.customInfopageEditItem_h5.text(resources['X100134'] + ' - ' + this.model.attributes.Name);
                var text = resources['X100134'] + ' - ' + this.model.attributes.Name;
                text = '<i class="fa fa-2x fa-html5" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
                break;
            case 1:
                this.ui.customInfopageEditItem_labelselectWebpage.text(resources.X100490);
                var text = resources['X100451'] + ' - ' + this.model.attributes.Name;
                this.ui.customInfopageEditItem_h5.text(resources['X100451'] + ' - ' + this.model.attributes.Name);
                text = '<i class="fa fa-2x fa-television" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
                break;
        }
        this.ui.customInfopageEditItem_h5.html(text);
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;

        switch (this.model.attributes.DisplayType) {
            case 0: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); break;
            case 1: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Infopage: this.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        var self = this;

        this.customViewEvent_setActive();

        this.setupSetting();


    },
    getContent: function () {
        if (this.app.editorRegion.currentView) {
            return this.app.editorRegion.currentView.getContent();
        }
    },
    getContentCKCB: function (value) {
        if (this.app.editorRegion.currentView) {
            return this.app.editorRegion.currentView.getContentCKCB(value);
        }
    },
    //callbackCK : function () {
    //    var self = window.c_parent;
    //    var model = self.collection.where( {id : window.c_id} );

    //    if (model[0].attributes.BodyCK == '' ) {
    //        var body = self.getContentCKCB('0');
    //        if (body.length > 0) {
    //            if (body[body.length-1] == '\n') {
    //                body = body.substr(0,body.length-1);
    //            }
    //        }

    //        model[0].attributes.BodyCK = body;


    //        if (window.c_customSetting[0].attributes.Value == 0) {
    //            model[0].attributes.Body = body;//self.getContent();
    //        }
    //    }
    //},
    //callbackCB : function () {
    //    var self = window.c_parent;

    //    var model = self.collection.where( {id : window.c_id} );

    //    if (model[0].attributes.BodyCB == '' ) {
    //        var body = self.getContentCKCB('1');
    //        if (body.length > 0) {
    //            if (body[body.length-1] == '\n') {
    //                body = body.substr(0,body.length-1);
    //            }
    //        }

    //        model[0].attributes.BodyCB = body;

    //        if (window.c_customSetting[0].attributes.Value == 1) {
    //            model[0].attributes.Body = body;//self.getContent();
    //        }
    //    }
    //},
    setContent: function (content, id, tag) {
        //NOBODYCHANGEcontent = content.split('<p>&nbsp;</p>').join('');

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });

        var self = this;
        //var callback = function () {
        //    self.oldContent = self.getContent();
        //    var model = self.collection.where( {id : id} );

        //    if (model[0].attributes.Checksum == '' ) {
        //        var body = self.getContent();
        //        if (body.length > 0) {
        //            if (body[body.length-1] == '\n') {
        //                body = body.substr(0,body.length-1);
        //            }
        //        }

        //        model[0].attributes.Body = body;//self.getContent();
        //        model[0].attributes.Checksum = self.calcChecksumBody(body);//self.calcChecksumBody(self.getContent());
        //        self.oldCalcChecksum = self.calcChecksum();
        //    }
        //}

        //Herfra
        var callbackCK = function () {
            var model = self.collection.where({ id: id });

            if (model[0].attributes.BodyCK == '') {
                var body = self.getContentCKCB('0');

                if (body !== undefined) {

                    if (body.length > 0) {
                        if (body[body.length - 1] == '\n') {
                            body = body.substr(0, body.length - 1);
                        }
                    }

                    model[0].attributes.BodyCK = body;


                    if (customSetting[0].attributes.Value == 0) {
                        model[0].attributes.Body = body;//self.getContent();
                    }
                }
            }

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItemListItem:enableWebpageContent');
        }

        var callbackCB = function () {
            var model = self.collection.where({ id: id });

            if (model[0].attributes.BodyCB == '') {
                var body = self.getContentCKCB('1');

                if (body !== undefined) {
                    if (body.length > 0) {
                        if (body[body.length - 1] == '\n') {
                            body = body.substr(0, body.length - 1);
                        }
                    }

                    model[0].attributes.BodyCB = body;

                    if (customSetting[0].attributes.Value == 1) {
                        model[0].attributes.Body = body;//self.getContent();
                    }
                }
            }

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItemListItem:enableWebpageContent');

        }


        //window.c_parent = this;
        //window.c_id = id;
        //window.c_customSetting = customSetting;

        if (this.app.editorRegion.currentView) {
            //this.app.editorRegion.currentView.setContent(content, id, callback);
            this.app.editorRegion.currentView.setContentCKCB('0', content, id, tag, callbackCK, callbackCB);
            this.app.editorRegion.currentView.setContentCKCB('1', content, id, tag, callbackCK, callbackCB);
            //set old because when changing editor the code changes

            //if (customSetting[0].attributes.Value == '1') {
            //    this.oldContent = this.getContent();
            //}
        } else {
            this.oldContent = content;
        }
    },
    saveSelectedModel: function () {
        var self = this;


        var updateModel = this.collection.where({ Selected: 1 });
        if (updateModel.length > 0) {
            if (updateModel && updateModel[0]) {
                //Update selected model
            }

        } else {
            alert("HER ER EN FEJL 1");
        }
    },
    deleteModel: function (model) {
        var self = this;

        var selectAnotherModel = model.attributes.Selected;

        self.collection.remove(model);

        if (selectAnotherModel) {
            var selectedModelId = this.collection.models[0].attributes.id;

            switch (self.model.attributes.DisplayType) {
                case 0:
                    selectedModelId = this.collection.models[0].attributes.id;

                    var customInfopageItem = self.collection.where({ Webpage: self.model.attributes.id, Tag: 0 });
                    if (customInfopageItem.length == 1) {
                        selectedModelId = customInfopageItem[0].attributes.id;
                    }
                    break;
                case 1:
                    var selectedModel = this.collection.where({ Tag: 0 });
                    if (selectedModel.length > 0) {
                        var nextPosition = 2147483647;
                        self.collection.forEach(function (entry) {
                            if (entry.attributes.Webpage == self.model.attributes.id
                                && entry.attributes.Position <= nextPosition) {
                                nextPosition = entry.attributes.Position;
                                selectedModelId = entry.attributes.id;
                            }
                        });
                    }
            }

            self.collection.forEach(function (entry) {
                if (entry.attributes.id == selectedModelId) {
                    entry.set({ Selected: true });
                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');
                } else {
                    entry.set({ Selected: false });
                }
            });
        }
    },
    createWebPage: function (preview, webpage, renderFinishCallback, publish) {
        var self = this;

        var customEvent = null;

        switch (self.model.attributes.DisplayType) {
            case 0: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Webpage: self.model.attributes.id }); break;
            case 1: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Infopage: self.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            var render = new View_Render({ collection: customEvent, app: self.app });
            this.app.renderRegion.show(render);

            var refreshType = 'EVENT_REFRESH';

            switch (self.model.attributes.DisplayType) {
                case 0: refreshType = 'EVENT_REFRESH'; break;
                case 1:
                    if (this.localSetting.attributes.themeChanged) {
                        refreshType = 'EVENT_REFRESH';
                        this.localSetting.attributes.themeChanged = false;
                    } else {
                        refreshType = 'INFO_REFRESH';
                    }
                    break;
            }
            render.doIt(renderFinishCallback, publish, refreshType, false);
        }
        else if ((customEvent.length == 0) && (preview)) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100117;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
    },
    setupCopyWebpage: function (event) {
        var self = this;
        var total = 0;

        self.ui.customInfopageEditItem_selectWebpage.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');

        this.copyCollection.fullCollection.forEach(function (entry) {
            if (entry.id != self.model.attributes.id && entry.attributes.DisplayType == self.model.attributes.DisplayType) {
                total++;
                self.ui.customInfopageEditItem_selectWebpage.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });

        this.ui.customInfopageEditItem_btnCopyWebpage.prop('disabled', true);
    },
    selectWebpageChange: function (event) {
        event.preventDefault();

        var webpage = parseInt(this.ui.customInfopageEditItem_selectWebpage.val());

        if (webpage == '-1') {
            this.ui.customInfopageEditItem_btnCopyWebpage.prop('disabled', true);
        } else {
            this.ui.customInfopageEditItem_btnCopyWebpage.prop('disabled', false);
        }
    },
    copyWebpage: function (id) {
        var webpage = id;

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();

        var Text = '';

        switch (this.model.attributes.DisplayType) {
            case 0:
                Text = resources.X100149;
                break;
            case 1:
                Text = resources.X100491;
                break;
        }


        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = Text;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');

            var customInfopage = self.copyCollection.fullCollection.where({ id: webpage });
            if (customInfopage.length > 0) {
                this.collection.reset();

                var customInfopageItem = self.app.entities.collections.customInfopageItem.where({ Webpage: customInfopage[0].attributes.id });

                var count = self.app.entities.collections.customInfopageItem.length;

                customInfopageItem.forEach(function (entry) {
                    var tmpModel = new Entities_TmpcustomInfopageItem.Model();
                    tmpModel.attributes.Webpage = self.model.attributes.id;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = count;
                    tmpModel.id = count;
                    tmpModel.attributes.orgId = -1;

                    tmpModel.attributes.DisplayType = self.model.attributes.DisplayType;
                    tmpModel.attributes.Selected = false;


                    switch (self.model.attributes.DisplayType) {
                        case 0: tmpModel.attributes.EnableDuration = false; break;
                        case 1: tmpModel.attributes.EnableDuration = true; break;
                    }

                    self.collection.add(tmpModel);
                    count++;
                });

                switch (self.model.attributes.DisplayType) {
                    case 0: var header = self.collection.where({ Tag: 0 });
                        if (header.length > 0) {
                            header[0].set({ Selected: true });
                        }
                        break;
                    case 1:
                        var tmpPosition = 2147483647;
                        var tmpId = -1;
                        self.collection.forEach(function (entry) {
                            if (entry.attributes.Position < tmpPosition) {
                                tmpPosition = entry.attributes.Position;
                                tmpId = entry.attributes.id;
                            }
                        });

                        var firstModel = self.collection.where({ id: tmpId });
                        if (firstModel.length > 0) {
                            firstModel[0].set({ Selected: true });
                        }
                        break;
                }
            }
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');
        }

        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');
            //dialog2Button.trigger("dialog:close");
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnCopyWebpage: function (event) {
        event.preventDefault();

        var webpage = parseInt(this.ui.customInfopageEditItem_selectWebpage.val());

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();

        var Text = '';

        switch (this.model.attributes.DisplayType) {
            case 0:
                Text = resources.X100149;
                break;
            case 1:
                Text = resources.X100491;
                break;
        }


        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = Text;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');

            var customInfopage = self.copyCollection.fullCollection.where({ id: webpage });
            if (customInfopage.length > 0) {
                this.collection.reset();

                var customInfopageItem = self.app.entities.collections.customInfopageItem.where({ Webpage: customInfopage[0].attributes.id });

                var count = self.app.entities.collections.customInfopageItem.length;

                customInfopageItem.forEach(function (entry) {
                    var tmpModel = new Entities_TmpcustomInfopageItem.Model();
                    tmpModel.attributes.Webpage = self.model.attributes.id;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = count;
                    tmpModel.id = count;
                    tmpModel.attributes.orgId = -1;

                    tmpModel.attributes.DisplayType = self.model.attributes.DisplayType;
                    tmpModel.attributes.Selected = false;


                    switch (self.model.attributes.DisplayType) {
                        case 0: tmpModel.attributes.EnableDuration = false; break;
                        case 1: tmpModel.attributes.EnableDuration = true; break;
                    }

                    self.collection.add(tmpModel);
                    count++;
                });

                switch (self.model.attributes.DisplayType) {
                    case 0: var header = self.collection.where({ Tag: 0 });
                        if (header.length > 0) {
                            header[0].set({ Selected: true });
                        }
                        break;
                    case 1:
                        var tmpPosition = 2147483647;
                        var tmpId = -1;
                        self.collection.forEach(function (entry) {
                            if (entry.attributes.Position < tmpPosition) {
                                tmpPosition = entry.attributes.Position;
                                tmpId = entry.attributes.id;
                            }
                        });

                        var firstModel = self.collection.where({ id: tmpId });
                        if (firstModel.length > 0) {
                            firstModel[0].set({ Selected: true });
                        }
                        break;
                }
            }
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');
        }

        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');
            //dialog2Button.trigger("dialog:close");
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnSubmit: function (event, preview, createWebpage, callbackRouter, publish) {
        var self = this;



        this.saveSelectedModel();

        var callbackSaveAll = function () {
            self.save(callbackRender);
        }

        var callbackRender = function () {
            if (createWebpage) {
                var renderFinishCallback = function (options) {
                    if (callbackRouter) {
                        callbackRouter();
                    }
                }

                var customEvent = null;

                switch (self.model.attributes.DisplayType) {
                    case 0: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Webpage: self.model.attributes.id }); break;
                    case 1: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Infopage: self.model.attributes.id }); break;
                }

                if (customEvent && customEvent.length > 0) {
                    self.createWebPage(preview, self.model.attributes.id, renderFinishCallback, publish);
                } else {
                    if (callbackRouter) {
                        callbackRouter();
                    }
                }
            } else {
                if (callbackRouter) {
                    callbackRouter();
                }
            }
        }

        callbackSaveAll();
    },
    btnPublishClick: function (event) {
        this.btnSubmit(event, false, true, null, true);
    },
    btnSubmitClick: function (event) {
        this.btnSubmit(event, false, true, null, false);
    },
    btnSaveWebpageClick: function (event) {
        var self = this;
        if (this.validate()) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100134;
            dialogWaitingModel.attributes.Text = resources.X100309;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            self.model.save(this.model.attributes, {
                silent: true,
                success: function () {
                    dialogWaiting.triggerMethod('dialog:close');
                }
            });
        }


    },
    setupSetting: function () {
        var customSetting = app.entities.collections.customSetting.where({ Name: 'InfoPageSetting' })[0];

        switch (customSetting.attributes.Value) {
            case '0': this.ui.settings_trigger_icon.removeClass();
                this.ui.settings_trigger_icon.addClass('mdi mdi-arrow-expand-left');
                $('#customInfopageEditItemLeftContent').removeClass('col-9 col-sm-9 col-md-9 col-xl-9 grid-margin stretch-card');
                $('#customInfopageEditItemLeftContent').addClass('col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card');
                $('#customInfopageEditItemRightContent').removeClass('col-3 col-sm-3 col-md-3 col-xl-3');
                $('#customInfopageEditItemRightContent').addClass('col-3 col-sm-3 col-md-3 col-xl-3 d-none');
                break;
            case '1': this.ui.settings_trigger_icon.removeClass('mdi-arrow-expand-left');
                this.ui.settings_trigger_icon.addClass('mdi-arrow-expand-right');
                $('#customInfopageEditItemLeftContent').removeClass('col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card');
                $('#customInfopageEditItemLeftContent').addClass('col-9 col-sm-9 col-md-9 col-xl-9 grid-margin stretch-card');
                $('#customInfopageEditItemRightContent').removeClass('col-3 col-sm-3 col-md-3 col-xl-3 d-none');
                $('#customInfopageEditItemRightContent').addClass('col-3 col-sm-3 col-md-3 col-xl-3');
                break;
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('template:setZoom');
    },
    btnSettingClick: function () {
        var self = this;
        var customSetting = app.entities.collections.customSetting.where({ Name: 'InfoPageSetting' })[0];

        switch (customSetting.attributes.Value) {
            case '0': customSetting.attributes.Value = '1'; break;
            case '1': customSetting.attributes.Value = '0'; break;
        }

        customSetting.save(customSetting.attributes, {
            silent: true,
            success: function () {
                self.setupSetting();
            }
        });
    },
    validate: function () {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customInfopageEditItem_inputName.val();

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        return true;
    },
    copyTemplate: function () {
        var self = this;

        var copyTemplateModel = new Entities_Empty.Model();
        copyTemplateModel.attributes.Title = resources.X101081;
        copyTemplateModel.attributes.Text = '';
        copyTemplateModel.attributes.Button01 = resources.X100279;
        copyTemplateModel.attributes.Button02 = resources.X100280;
        copyTemplateModel.attributes.Name = '';
        copyTemplateModel.attributes.Status = 1;
        copyTemplateModel.attributes.Tag = 1;
        copyTemplateModel.attributes.Webpage = -1;
        copyTemplateModel.attributes.OldId = -1;

        copyTemplateModel.attributes.id = this.model.attributes.id;
        copyTemplateModel.attributes.DisplayType = self.model.attributes.DisplayType

        var copyTemplate = new View_CopyTemplate({ app: this.app, model: copyTemplateModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(copyTemplate, "dialog:btn01clicked", callback01);
            self.stopListening(copyTemplate, "dialog:btn02clicked", callback02);

            if (copyTemplateModel.attributes.Webpage != -1) {
                self.copyWebpage(copyTemplateModel.attributes.Webpage);
            }
        }
        this.listenTo(copyTemplate, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(copyTemplate, "dialog:btn01clicked", callback01);
            self.stopListening(copyTemplate, "dialog:btn02clicked", callback02);

        }
        this.listenTo(copyTemplate, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(copyTemplate);
    },
    customInfopageSettingSave: function (event) {
        var self = this;
        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100134;
        dialogWaitingModel.attributes.Text = resources.X100309;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        self.app.dialogRegion.show(dialogWaiting);

        self.model.save(this.model.attributes, {
            silent: true,
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
            }
        });
    },
    customInfopageSetting: function () {
        var self = this;

        /*var customInfopageSetting = new Entities_Empty.Model();
        customInfopageSetting.attributes.Title = resources.X101082;
        customInfopageSetting.attributes.Text = '';
        customInfopageSetting.attributes.Button01 = resources.X100279;
        customInfopageSetting.attributes.Button02 = resources.X100280;
        customInfopageSetting.attributes.Name = '';
        customInfopageSetting.attributes.Status = 1;
        customInfopageSetting.attributes.Tag = 1;
        customInfopageSetting.attributes.Webpage = -1;
        customInfopageSetting.attributes.OldId = -1;*/

        var customInfopageSetting = new View_customInfopageSetting({ app: this.app, model: this.model, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(customInfopageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(customInfopageSetting, "dialog:btn02clicked", callback02);

            self.customInfopageSettingSave();
        }
        this.listenTo(customInfopageSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(customInfopageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(customInfopageSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(customInfopageSetting, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(customInfopageSetting);
    },
    newPage: function () {
        var self = this;

        var newPageModel = new Entities_Empty.Model();
        newPageModel.attributes.Name = '';
        newPageModel.attributes.Duration = 30;
        newPageModel.attributes.Status = 1;
        newPageModel.attributes.Tag = 1;
        //newPageModel.attributes.Webpage = 1;
        //newPageModel.attributes.Body = '';

        var collection = new Entities_Empty.Collection();

        var selected = false;

        this.app.entities.collections.Template.forEach(function (entry) {
            
            if (entry.attributes.ModuleType.includes(self.model.attributes.ModuleType)) {
                if (!selected) {
                    entry.attributes.Selected = 1;
                    selected = true;
                }
                else {
                    entry.attributes.Selected = 0;
                }

                collection.add(entry);
            }
        });

        var newPage = new View_NewPage({ app: this.app, model: newPageModel, collection: collection, parent: self });

        

        var callback01 = function () {
            self.stopListening(newPage, "dialog:btn01clicked", callback01);
            self.stopListening(newPage, "dialog:btn02clicked", callback02);

            var selectedTemplate;

            collection.forEach(function (entry) {
               if (entry.attributes.Selected == true) {
                    selectedTemplate = entry;
                }
            });

            self.newWebpage(newPageModel, selectedTemplate);
            //self.model.set({ Name: newPageModel.attributes.Name });
            //self.model.set({ Status: newPageModel.attributes.Status });
        }
        this.listenTo(newPage, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(newPage, "dialog:btn01clicked", callback01);
            self.stopListening(newPage, "dialog:btn02clicked", callback02);

        }
        this.listenTo(newPage, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(newPage);
    },
    btnInsertWebpageClick: function () {


        var self = this;

        this.saveSelectedModel();

        var callback = function () {
            self.hideErrors();
            var tmpModel = new self.collection.model();
            tmpModel.attributes.AuthUserId = config.authUserId();
            tmpModel.attributes.Webpage = self.model.attributes.id;
            tmpModel.attributes.Name = self.ui.customInfopageEditItem_inputInsertWebpageName.val();
            tmpModel.attributes.Body = ''; //TESTONLY'<h1>' + tmpModel.attributes.Name + '</h1>';

            tmpModel.attributes.Css = '';
            tmpModel.attributes.Status = 1;

            var foundNextId = false;
            var nextId = self.collection.length;

            while (!foundNextId) {
                var foundModels = self.collection.where({ id: nextId });
                if (foundModels.length == 0) {
                    foundNextId = true;
                } else {
                    nextId++;
                }
            }

            tmpModel.attributes.id = nextId;
            tmpModel.id = nextId;
            tmpModel.attributes.orgId = -1;
            tmpModel.attributes.DisplayType = self.model.attributes.DisplayType
            tmpModel.attributes.Selected = true;

            var nextPosition = 1;

            switch (self.model.attributes.DisplayType) {
                case 0: tmpModel.attributes.Tag = 1;
                    self.collection.forEach(function (entry) {
                        if (entry.attributes.Webpage == self.model.attributes.id
                            && entry.attributes.Position >= nextPosition
                            && entry.attributes.Position != 0
                            && entry.attributes.Position != 2147483647
                            && entry.attributes.Position != 2147483646
                            && entry.attributes.Position != 2147483645
                            && entry.attributes.Position != 2147483644
                            && entry.attributes.Position != 2147483643
                            && entry.attributes.Position != 2147483642
                            && entry.attributes.Position != 2147483641) {

                            nextPosition = entry.attributes.Position + 1;
                        }
                    });

                    tmpModel.attributes.Position = nextPosition;
                    tmpModel.attributes.Duration = 0;
                    tmpModel.attributes.EnableDuration = false;
                    break;
                case 1: tmpModel.attributes.Tag = 9;
                    //var length = self.collection.filter(function(n) { return (n.get('Webpage') == self.model.attributes.id && n.get('Tag') == 9); }).length;
                    self.collection.forEach(function (entry) {
                        if (entry.attributes.Webpage == self.model.attributes.id
                            && entry.attributes.Position >= nextPosition) {
                            nextPosition = entry.attributes.Position + 1;
                        }
                    });
                    tmpModel.attributes.Position = nextPosition;
                    tmpModel.attributes.Duration = 5;
                    tmpModel.attributes.EnableDuration = true;

                    break;
            }
            var ret = tmpModel.validateNoBackbone(app, 'customInfopageEditItem_inputInsertWebpageName', self.collection);
            if (ret == false) {
                self.collection.forEach(function (entry) {
                    entry.set({ Selected: false });
                });

                self.collection.add(tmpModel);
                self.ui.customInfopageEditItem_inputInsertWebpageName.val('');

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');
            }
            else {
                self.showErrors(tmpModel.errors);
            }
        }

        callback();
    },
    nextCollectionId() {
        var ret = 0;
        this.collection.forEach(function (entry) {
            if (entry.id > ret) ret = entry.id;
        });

        return ret;
    },
    newWebpage: function (newPageModel, selectedTemplate) {


        var self = this;

        //var userChannel = Backbone.Radio.channel('app');
        //userChannel.trigger('customInfopageEditItemList:updateWebpagePosition');

        //this.saveSelectedModel();

        var callback = function () {
            self.hideErrors();

            var selectedModel = self.collection.where({ Selected: 1 })[0];
            selectedModel.set({ Selected: 0 });

            var tmpModel = new self.collection.model();
            tmpModel.attributes.id = self.nextCollectionId() + 1;
            tmpModel.attributes.EventId = self.options.eventId;
            tmpModel.attributes.AuthUserId = config.authUserId();
            tmpModel.attributes.Webpage = 1;
            tmpModel.attributes.Name = newPageModel.attributes.Name;
            tmpModel.attributes.Tag = newPageModel.attributes.Tag = 1;
            tmpModel.attributes.Position = self.collection.length + 1;
            tmpModel.attributes.Duration = newPageModel.attributes.Duration = 30;
            tmpModel.attributes.Status = newPageModel.attributes.Status = 1;
            tmpModel.attributes.Selected = 1;
            tmpModel.attributes.Template = selectedTemplate.attributes.id;
            tmpModel.attributes.EnableDelete = selectedTemplate.attributes.EnableDelete;
            tmpModel.attributes.Transform = selectedTemplate.attributes.Transform;
            tmpModel.attributes.TransformOrigin = selectedTemplate.attributes.TransformOrigin;
            tmpModel.attributes.Collection = selectedTemplate.attributes.Collection;

            self.collection.add(tmpModel);

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');
        }

        callback();
    },
    iconTabletAlt: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id });

        $('.modal-backdrop').remove();
        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpageIPad = new View_DialogPreviewWebpageZoomIPad({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpageIPad = function (options) {
            this.stopListening(dialogPreviewWebpageIPad, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpageIPad, "dialog:okclicked", callback_dialogPreviewWebpageIPad);

        this.app.dialogRegion.show(dialogPreviewWebpageIPad);
    },
    iconMobileAlt: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id });

        $('.modal-backdrop').remove();
        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: true
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpage = new View_DialogPreviewWebpageZoom({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpage = function (options) {
            this.stopListening(dialogPreviewWebpage, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);

        this.app.dialogRegion.show(dialogPreviewWebpage);


    },
    iconWpforms: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id });

        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);

    },
    iconTh: function () {
        alert("Wfwf");
    },
    iconTv: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ id: this.model.attributes.EventId })[0];

        var friendlyUrl = customEvent.attributes.id;
        if (this.model.attributes.FriendlyUrl && customEvent.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/infopage/' + friendlyUrl;

        var params = {
            autoupdate: 'true',
            updateHits: false,
            eventId: this.model.attributes.EventId,
            moduleType: this.model.attributes.ModuleType
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);
        
    },
    iconDias: function () {
        var maxHeight = 0;
        var maxWidth = 0;
        var ret = 0;
        var windowHeigh = 0;
        var windowWidth = 0;

        if ($('#templateDiv').find('.zoomContainerH')[0] != undefined) {
            var maxHeight = ((window.innerHeight - 90) / 1080) * 100; 
            var maxWidth = ((window.innerWidth - 90) / 1980) * 100;

            ret = Math.min(maxHeight, maxWidth);
            windowHeigh = (1080 / 100) * ret;
            windowWidth = (1920 / 100) * ret;
        }

        if ($('#templateDiv').find('.zoomContainerV')[0] != undefined) {
            var maxHeight = (window.innerHeight - 90 / 1900) * 100;
            var maxWidth = (window.innerWidth - 90 / 1080) * 100; 

            ret = Math.min(maxHeight, maxWidth);
            windowHeigh = (1920 / 100) * ret;
            windowWidth = (1080 / 100) * ret;
        }

        //var ret = Math.min(maxHeight, maxWidth);

        //var windowHeigh = (1080/100) * ret;
        //var windowWidth = (1920/100) * ret;


        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ id: this.model.attributes.EventId })[0];

        var friendlyUrl = customEvent.attributes.id;
        if (this.model.attributes.FriendlyUrl && customEvent.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/infopage/' + friendlyUrl;

        var params = {
            autoupdate: 'true',
            updateHits: false,
            eventId: this.model.attributes.EventId,
            moduleType: this.model.attributes.ModuleType,
            transform:0
        };
        var url = [webpageEvent, $.param(params)].join('?');


        var dialogPreviewDiasModel = new Entities_Empty.Model();
        dialogPreviewDiasModel.attributes.Title = resources.X100276;
        dialogPreviewDiasModel.attributes.Iframe = url;
        dialogPreviewDiasModel.attributes.Button = resources.X100279;

        dialogPreviewDiasModel.attributes.WindowHeigh = windowHeigh;
        dialogPreviewDiasModel.attributes.WindowWidth = windowWidth;
        dialogPreviewDiasModel.attributes.Transform = ret;
        dialogPreviewDiasModel.attributes.TransformOrigin 

        var dialogPreviewDias = new View_DialogPreviewDias({ model: dialogPreviewDiasModel, parent: this });
        var callback_dialogPreviewDias = function (options) {
            this.stopListening(dialogPreviewDias, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewDias, "dialog:okclicked", callback_dialogPreviewDias);

        this.app.dialogRegion.show(dialogPreviewDias);
    }


}, Backbone.Radio.Requests);
