﻿'use strict'

import * as Backbone from 'backbone';

import * as Marionette from 'backbone.marionette';

import Region_Header from '../../common/views/region/header/header';
import Region_Menu from '../../common/views/region/menu/menu';
import Region_MenuPortal from '../../common/views/region/menu/menuPortal';
import Region_Footer from '../../common/views/region/footer/footer';
import Region_FooterPortal from '../views/region/footer/footer';

import Waiting from '../../lib/views/waiting/waiting';

import View_InfoPage from '../views/infoPage/infoPage';
import View_CustomMenu from '../views/region/menu/customMenu';
import View_Custom from '../views/custom/custom';
import View_CustomDepartment from '../views/customDepartment/customDepartment';
import View_CustomDepartmentEditItem from '../views/customDepartment/customDepartmentEditItem';
import View_CustomDepartmentImport from '../views/customDepartmentImport/customDepartmentImport';

import View_CustomIPAddress from '../views/customIPAddress/customIPAddress';
import View_CustomSecurity from '../views/customSecurity/customSecurity';
import View_CustomIPAddressEditItem from '../views/customIPAddress/customIPAddressEditItem';
import View_CustomCrediential from '../views/customCrediential/customCrediential';
import View_CustomUser from '../views/customUser/customUser';
import View_CustomUserEditItem from '../views/customUser/customUserEditItem';
import View_CustomUserImport from '../views/customUserImport/customUserImport';

import View_CustomEvent from '../views/customEvent/customEvent';
import View_CustomEventLive from '../views/customEventLive/customEventLive';
import View_CustomEventEditItem from '../views/customEvent/customEventEditItem';

import View_CustomSchemaSetting from '../views/customSchemaSetting/customSchemaSetting';
import View_CustomFormSetting from '../views/customFormSetting/customFormSetting';
import View_CustomAdSetting from '../views/customAdSetting/customAdSetting';


import View_CustomEventCounter from '../views/customEventCounter/customEventCounter';
import View_CustomEventPush from '../views/customEventPush/customEventPush';
import View_CustomSchemaData from '../views/customSchemaData/customSchemaData';
import View_CustomFormData from '../views/customFormData/customFormData';
import View_CustomProductSheetData from '../views/customProductSheetData/customProductSheetData';
import View_CustomEventTemplate from '../views/customEventTemplate/customEventTemplate';
import View_CustomWebpage from '../views/customWebpage/customWebpage';
import View_CustomWebpageEditItem from '../views/customWebpage/customWebpageEditItem';
import View_CustomWebpageEditor from '../views/customWebpage/customWebpageEditor';

import View_CustomInfopage from '../views/customInfopage/customInfopage';
import View_CustomInfopageEditItem from '../views/customInfopage/customInfopageEditItem';
//import View_CustomInfopageEditor from '../views/customInfopage/customInfopageEditor';

import View_CustomQRCode from '../views/customQRCode/customQRCode';
import View_CustomQRCodeEditItem from '../views/customQRCode/customQRCodeEditItem';
import View_CustomSchemaModel from '../views/customSchemaModel/customSchemaModel';
import View_CustomSchemaModelEditItem from '../views/customSchemaModel/customSchemaModelEditItem';
import View_CustomFormModel from '../views/customFormModel/customFormModel';
import View_CustomFormModelEditItem from '../views/customFormModel/customFormModelEditItem';
import View_CustomProductSheetModel from '../views/customProductSheetModel/customProductSheetModel';
import View_CustomProductSheetModelEditItem from '../views/customProductSheetModel/customProductSheetModelEditItem';
import View_CustomImage from '../views/customImage/customImage';
import View_CustomScreen from '../views/customScreen/customScreen';
import View_CustomColor from '../views/customColor/customColor';
import View_CustomFile from '../views/customFile/customFile';
import View_CustomAdImage from '../views/customAdImage/customAdImage';
import View_CustomAdBigImage from '../views/customAdBigImage/customAdBigImage';

import View_CustomSubscription from '../views/customSubscription/customSubscription';

import * as Entities_Empty from '../../lib/entities/empty';
import * as Entities_CustomSchemaData from '../entities/customSchemaData';
import * as Entities_CustomCrediential from '../entities/customCrediential';
import View_Dialog2Button from '../../lib/views/dialog2Button/dialog2Button';
import View_DialogRouter2Button from '../../lib/views/dialogRouter2Button/dialogRouter2Button';
import View_DialogBox from '../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../lib/views/dialogWaiting/dialogWaiting';

import View_Render from '../views/render/render';
import View_DialogProcess from '../../lib/views/dialogProcess/dialogProcess';

import * as Entities_Memory_Position from '../entities/memoryPosition';




import * as Entities_TmpCustomWebpageItem from '../entities/tmpCustomWebpageItem';
import * as Entities_TmpCustomInfopageItem from '../entities/tmpCustomInfopageItem';

import config from '../../common/config';

import Help from '../helper/help';
import AuthHelper from '../helper/auth';

export default Marionette.AppRouter.extend({
    initialize: function () {
        this.app = this.options.app;

        this.oldView = null;
    },
    //'default': function () { },
    //defaultRoute: function () { },
    /*route: function (route, name, callback) {
        var that = this;
        if (!_.isRegExp(route)) route = this._routeToRegExp(route);
        if (!callback) callback = this[name];
        Backbone.history.route(route, _.bind(function (fragment) {
            var args = that._extractParameters(route, fragment);
            //if (myCustomLogic) {
                callback && callback.apply(that, args);
                that.trigger.apply(that, ['route:' + name].concat(args));
                that.trigger('route', name, args);
                Backbone.history.trigger('route', that, name, args);
            //}
        }, that));
        return that;
    },*/
    routes: {
        "waiting": "waiting",
        "waitingDefaultSetting": "waitingDefaultSetting",
        "customEventCounter/:id": "customEventCounter",
        "customEventPush/:id": "customEventPush",
        "customSchemaData/:id": "customSchemaData",
        "customFormData/:id": "customFormData",
        "customProductSheetData/:id": "customProductSheetData",
        "customEvent": "customEvent",
        "customEventLive/:id": "customEventLive",
        "customEventEditItem/:id": "customEventEditItem",
        "customEventTemplate": "customEventTemplate",
        "customWebpage": "customWebpage",
        "customWebpageEditItem/:id": "customWebpageEditItem",
        "customInfopage": "customInfopage",
        "customInfopageEditItem/:id": "customInfopageEditItem",
        "customSchemaScreenEditItem/:id": "customSchemaScreenEditItem",
        "customSchemaModel": "customSchemaModel",
        "customSchemaModelEditItem/:id": "customSchemaModelEditItem",
        "customFormModel": "customFormModel",
        "customFormModelEditItem/:id": "customFormModelEditItem",
        "customProductSheetModel": "customProductSheetModel",
        "customProductSheetModelEditItem/:id": "customProductSheetModelEditItem",
        "customQRCode": "customQRCode",
        "customQRCodeEditItem/:id": "customQRCodeEditItem",
        "customImage/:id": "customImage",
        "customFile/:id": "customFile",
        "customAdImage/:id": "customAdImage",
        "customAdBigImage/:id": "customAdBigImage",
        "infoPage": "infoPage",
        "custom": "custom",
        "customScreen": "customScreen",
        "customColor": "customColor",
        "customIPAddress": "customIPAddress",
        "customIPAddressEditItem/:id": "customIPAddressEditItem",
        "customDepartment": "customDepartment",
        "customDepartmentEditItem/:id": "customDepartmentEditItem",
        "customDepartmentImport": "customDepartmentImport",
        "customUser": "customUser",
        "customUserEditItem/:id": "customUserEditItem",
        "customUserImport": "customUserImport",
        "customSecurity": "customSecurity",
        "customCrediential": "customCrediential",
        "customSubscription/:enableMenu": "customSubscription",
        "gettingStarted": "gettingStarted",
        "accessTokenLifetime": "accessTokenLifetime",
        //menu
        "index": "index",
        "contact": "contact",
        "infoScreen": "infoScreen",
        "infoScreenContact": "infoScreenContact",
        "price": "price",
        "searchEvent": "searchEvent",
        "about": "about",
        "portal": "portal",
        //footer
        "policy": "policy",
        "termsOfTrade": "termsOfTrade",
        "roadmap": "roadmap",
        //Header
        "btnForgotPassword": "btnForgotPassword",
        "btnAuthLogoff": "btnAuthLogoff"
    },
    displayOther: function () {
        //self.app.headerRegion.show(new Region_Header({app : this.app}));
        //this.app.menuRegion.show(new Region_MenuPortal({app : this.app}));
        //this.app.footerRegion.show(new Region_FooterPortal({}));
    },
    waitingDefaultSetting: function () {
        $('#custom_li_01').text(resources.X100069);
        $('#custom_li_02').text(resources.X100231);
        $('#custom_li_03').css('display', 'none');

        var waitingModel = new Entities_Empty.Model();
        waitingModel.attributes.Header = resources.X100027;
        waitingModel.attributes.HeaderSub = resources.X100028;
        waitingModel.attributes.Spin = resources.X100029;

        app.waitingDefaultSetting = new Waiting({ model: waitingModel });

        //this.app.contentRegion.show(new Waiting({ model: waitingModel }));
        this.displayOther();
        this.app.contentRegion.show(app.waitingDefaultSetting);

        this.scrollTop();
    },
    waiting: function () {
        $('#custom_li_01').text(resources.X100069);
        $('#custom_li_02').text(resources.X100231);
        $('#custom_li_03').css('display', 'none');

        var waitingModel = new Entities_Empty.Model();
        waitingModel.attributes.Header = resources.X100027;
        waitingModel.attributes.HeaderSub = resources.X100028;
        waitingModel.attributes.Spin = resources.X100029;

        this.displayOther();
        this.app.contentRegion.show(new Waiting({ model: waitingModel }));
        this.scrollTop();
    },
    checkHasChanged: function (callback) {
        this.app.dialogRegion.ee();
        this.app.dialogRouterRegion.ee();

        var self = this;

        if (this.oldView != null) {

            this.oldView.modelHasChanged();

            if (this.oldView.getHasChanged()) {
                var dialogRouter2ButtonModel = new Entities_Empty.Model();
                dialogRouter2ButtonModel.attributes.Title = resources.X100229;
                dialogRouter2ButtonModel.attributes.Text = resources.X100395;
                dialogRouter2ButtonModel.attributes.Button01 = resources.X100281;
                dialogRouter2ButtonModel.attributes.Button02 = resources.X100282;
                var dialogRouter2Button = new View_DialogRouter2Button({ model: dialogRouter2ButtonModel, parent: self });
                var callback01 = function (options) {
                    self.stopListening(dialogRouter2Button, "dialogRouter:btn01clicked", callback01);
                    self.stopListening(dialogRouter2Button, "dialogRouter:btn02clicked", callback02);

                    dialogRouter2Button.triggerMethod('dialog:close');
                    self.oldView.saveHasChanged(callback);

                }
                this.listenTo(dialogRouter2Button, "dialogRouter:btn01clicked", callback01);

                var callback02 = function (options) {
                    self.stopListening(dialogRouter2Button, "dialogRouter:btn01clicked", callback01);
                    self.stopListening(dialogRouter2Button, "dialogRouter:btn02clicked", callback02);

                    self.oldView.cancelHasChanged();
                    callback();
                }
                this.listenTo(dialogRouter2Button, "dialogRouter:btn02clicked", callback02);

                this.app.dialogRouterRegion.show(dialogRouter2Button);

            } else {
                callback();
            }
        } else {
            callback();
        }
    },
    scrollTop: function () {
        $('.tooltip-info').remove();
        $('html,body').scrollTop(0);
    },
    infoPage: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X100735 + resources.X200159;

            self.displayOther();

            var infoPage = new View_InfoPage({ collection: self.app.entities.collections.customUser, app: self.app });
            self.app.contentRegion.show(infoPage);

            self.oldView = infoPage;

            var enable = self.app.entities.models.custom.getMenuStatus();
            var customMenu = new View_CustomMenu({ enable: enable, app: self.app, model: self.app.entities.models.custom });

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    custom: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X100735 + resources.X200034;

            //self.app.headerRegion.show(new Region_Header({app : self.app}));
            self.displayOther();

            // var render = new View_Render();
            //  self.app.renderRegion.show(render);
            //self.app.renderRegion.empty();

            // var customWebpageEditor = new View_CustomWebpageEditor();
            // self.app.editorRegion.show(customWebpageEditor);
            //self.app.editorRegion.empty();

            /* var dialogBoxModel = new Entities_Empty.Model();
             dialogBoxModel.attributes.Title = resources.X100901;
             dialogBoxModel.attributes.Text = resources.X100906;
             dialogBoxModel.attributes.Button = resources.X100279;
             var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
             var callback2 = function () {
                 self.stopListening(dialogBox, "dialog:okclicked", callback2);
 
                 self.clearView();
             }
             self.listenTo(dialogBox, "dialog:okclicked", callback2);
             self.app.dialogRegion.show(dialogBox);*/

            //self.app.dialogRegion.empty();

            var custom = new View_Custom({ model: self.app.entities.models.custom, app: self.app });
            self.app.contentRegion.show(custom);

            self.oldView = custom;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //var enable = self.app.entities.models.custom.getMenuStatus();
            //var customMenu = new View_CustomMenu({ enable: enable, app: self.app, model: self.app.entities.models.custom });

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customSecurity: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200040;

            self.displayOther();

            var customSecurity = new View_CustomSecurity({ model: self.app.entities.models.custom, app: self.app });
            self.app.contentRegion.show(customSecurity);

            self.oldView = customSecurity;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //var enable = self.app.entities.models.custom.getMenuStatus();
            //var customMenu = new View_CustomMenu({ enable: enable, app: self.app, model: self.app.entities.models.custom });

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customIPAddress: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200041;

            self.displayOther();

            var customIPAddress = new View_CustomIPAddress({ collection: self.app.entities.collections.customIPAddress, app: self.app });
            self.app.contentRegion.show(customIPAddress);

            self.oldView = customIPAddress;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customIPAddressEditItem: function (id, eventId, scroll) {
        var self = this;
        var callback = function () {
            var model = self.app.entities.collections.customIPAddress.fullCollection.where({ id: id });
            if (model[0] != null) {
                document.title = resources.X200099 + resources.X200041;

                self.displayOther();

                var customIPAddressEditItem = new View_CustomIPAddressEditItem({ collection: self.app.entities.collections.customIPAddress, model: model[0], app: self.app, eventId: eventId, scroll: scroll });
                self.app.contentRegion.show(customIPAddressEditItem);

                self.oldView = customIPAddressEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);
                self.scrollTop();

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();
            }
        }
        this.checkHasChanged(callback);
    },
    customDepartment: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200038;

            self.displayOther();

            var customDepartment = new View_CustomDepartment({ collection: self.app.entities.collections.customDepartment, app: self.app });
            self.app.contentRegion.show(customDepartment);

            self.oldView = customDepartment;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customDepartmentEditItem: function (id, eventId, scroll) {
        var self = this;
        var callback = function () {

            self.app.entities.collections.customDepartmentUser.fetch({
                route: 'route01',
                department: id,
                success: function (mode, response) {
                    self.app.entities.collections.customDepartmentReset.fetch({
                        route: 'route01',
                        department: id,
                        success: function (mode, response) {

                            var model = self.app.entities.collections.customDepartment.fullCollection.where({ id: id })[0];
                            if (model != null) {

                                var data = JSON.parse(model.attributes.Data)[0];
                                var moduleCollection = new Entities_Memory_Position.Collection(data.Collection);

                                document.title = resources.X200099 + resources.X200038;

                                self.displayOther();

                                var customDepartmentEditItem = new View_CustomDepartmentEditItem({ collectionCustomDepartmentReset: self.app.entities.collections.customDepartmentReset, collection: self.app.entities.collections.customDepartment, moduleCollection: moduleCollection, model: model, app: self.app, eventId: eventId, scroll: scroll, department: id });
                                self.app.contentRegion.show(customDepartmentEditItem);

                                self.oldView = customDepartmentEditItem;

                                var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
                                self.app.customMenuRegion.show(customMenu);

                                self.scrollTop();

                                //self.app.editorRegion.empty();
                                self.app.editorRegion.reset();
                            }
                        }
                    });
                },
                error: function (mode, response) {
                }
            });



        }
        this.checkHasChanged(callback);
    },
    customDepartmentImport: function () {
        var self = this;
        var callback = function () {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200147;
            dialogWaitingModel.attributes.Text = resources.X200279;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

            var callback_dialog = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback_dialog);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion.show(dialogWaiting);

            $.when(

                self.app.entities.collections.customDepartmentTmp.fetch({
                    success: function (mode, response) {
                    },
                    error: function (mode, response) {
                    }
                })
            ).done(function () {
                dialogWaiting.triggerMethod('dialog:close');

                document.title = resources.X200099 + resources.X200147;

                self.displayOther();

                var customDepartmentImport = new View_CustomDepartmentImport({ collection: self.app.entities.collections.customDepartmentTmp, app: self.app });
                self.app.contentRegion.show(customDepartmentImport);

                self.oldView = customDepartmentImport;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
                self.app.customMenuRegion.show(customMenu);

                self.scrollTop();
            })
        }
        this.checkHasChanged(callback);
    },
    customUser: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200122;

            self.displayOther();

            var customUser = new View_CustomUser({ collection: self.app.entities.collections.customUser, app: self.app });
            self.app.contentRegion.show(customUser);

            self.oldView = customUser;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customUserEditItem: function (id, eventId, scroll) {
        var self = this;
        var callback = function () {
            var model = self.app.entities.collections.customUser.fullCollection.where({ id: id });
            if (model[0] != null) {
                document.title = resources.X200099 + resources.X200122;

                self.displayOther();

                var customUserEditItem = new View_CustomUserEditItem({ collection: self.app.entities.collections.customUser, model: model[0], app: self.app, eventId: eventId, scroll: scroll });
                self.app.contentRegion.show(customUserEditItem);

                self.oldView = customUserEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
                self.app.customMenuRegion.show(customMenu);
                self.scrollTop();

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();
            }
        }
        this.checkHasChanged(callback);
    },
    customUserImport: function () {
        var self = this;
        var callback = function () {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200126;
            dialogWaitingModel.attributes.Text = resources.X200279;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

            var callback_dialog = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback_dialog);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion.show(dialogWaiting);

            $.when(
                self.app.entities.collections.customUserTmp.fetch({
                    success: function (mode, response) {

                    },
                    error: function (mode, response) {
                    }
                })
            ).done(function () {
                dialogWaiting.triggerMethod('dialog:close');

                document.title = resources.X200099 + resources.X200126;

                self.displayOther();

                var customUserImport = new View_CustomUserImport({ collection: self.app.entities.collections.customUserTmp, app: self.app });
                self.app.contentRegion.show(customUserImport);

                self.oldView = customUserImport;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
                self.app.customMenuRegion.show(customMenu);

                self.scrollTop();
            })
        }
        this.checkHasChanged(callback);
    },
    customCrediential: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200039;

            self.displayOther();

            var customCredentialModel = new Entities_CustomCrediential.Model();

            customCredentialModel.id = self.app.entities.models.custom.id;
            customCredentialModel.attributes.id = self.app.entities.models.custom.id;
            customCredentialModel.attributes.AuthUserId = self.app.entities.models.custom.attributes.AuthUserId;
            customCredentialModel.attributes.Email = self.app.entities.models.custom.attributes.Email;
            customCredentialModel.attributes.Pasword = '';
            customCredentialModel.attributes.PasswordConfirm = '';



            var customCrediential = new View_CustomCrediential({ model: customCredentialModel, app: self.app });
            self.app.contentRegion.show(customCrediential);

            self.oldView = customCrediential;

            var enable = self.app.entities.models.custom.getMenuStatus();

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();
            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customEventCounter: function (id) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100233);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100233;

            self.displayOther();

            var customEventCounter = new View_CustomEventCounter({ collection: self.app.entities.collections.customEvent, app: self.app, event: id });
            self.app.contentRegion.show(customEventCounter);

            self.oldView = customEventCounter;

            var customMenu = new View_CustomMenu({ enable: self, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();
            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customEventPush: function (id, scroll, module_selected) {
        var self = this;
        var callback = function () {
            //self.app.editorRegion.empty();

            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100237);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100237;

            self.displayOther();

            var customEventPush = new View_CustomEventPush({ collection: self.app.entities.collections.customEvent, app: self.app, event: id, scroll: scroll, module_selected: module_selected });
            self.app.contentRegion.show(customEventPush);

            self.oldView = customEventPush;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customSchemaData: function (id, scroll) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100250);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100250;

            self.displayOther();

            //var model = new Entities_CustomSchemaData.Model();
            //var settingModel = new Entities_Empty.Model();

            var settingModel = { attributes: {} };

            var customSchemaData = new View_CustomSchemaData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, event: id, scroll: scroll });
            self.app.contentRegion.show(customSchemaData);

            self.oldView = customSchemaData;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customFormData: function (id, scroll) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100261);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100261;

            self.displayOther();

            var settingModel = { attributes: {} };

            var customFormData = new View_CustomFormData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, event: id, scroll: scroll });
            self.app.contentRegion.show(customFormData);

            self.oldView = customFormData;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customProductSheetData: function (id) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X101112);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X101112;

            self.displayOther();

            var settingModel = { attributes: {} };

            var customProductSheetData = new View_CustomProductSheetData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, event: id });
            self.app.contentRegion.show(customProductSheetData);

            self.oldView = customProductSheetData;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customEvent: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100070);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100070;

            self.displayOther();

            var customEvent = new View_CustomEvent({ collection: self.app.entities.collections.customEvent, app: self.app });
            self.app.contentRegion.show(customEvent);

            self.oldView = customEvent;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customEventEditItem: function (id, scroll, module_selected) {
        var self = this;

        var callback = function () {
            var model = app.entities.collections.customEvent.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100070);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100070 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customEventEditItem = new View_CustomEventEditItem({ model: model[0], app: self.app, scroll: scroll, module_selected: module_selected });
                self.app.contentRegion.show(customEventEditItem);

                self.oldView = customEventEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                //self.scrollTop();
            }
        }

        var callbackFetchFinished = function () {
            self.checkHasChanged(callback);
        }

        if (app.lastFetchId != id) {
            app.lastFetchId = id;

            self.app.entities.collections.userAnalyticCollection.fetch({
                id: id,
                success: function (mode, response) {
                    app.currYear = [];
                    response.forEach(function (item) {
                        var date = item.UtcDate.substring(0, item.UtcDate.lastIndexOf("T"));
                        var obj = [date, item.Count];
                        var a = { date: date, count: item.Count };
                        app.currYear.push(a);
                    });

                    if (app.currYear.length == 0) {
                        var today = new Date();

                        var month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
                        var day = (today.getDate()) < 10 ? '0' + (today.getDate()) : (today.getDate());

                        var datestr = today.getFullYear().toString() + '-' + month + '-' + day;

                        var a = { date: datestr, count: 1 };
                        app.currYear.push(a);
                    }

                    callbackFetchFinished();;
                },
                error: function (mode, response) {
                    callbackFetchFinished();
                }
            });
        } else {
            callbackFetchFinished();
        }
    },
    customSchemaSetting: function (id, scroll) {
        var self = this;
        var callback = function () {
            var model = app.entities.collections.customEvent.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100070);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100070 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customSchemaSetting = new View_CustomSchemaSetting({ model: model[0], app: self.app, scroll: scroll });
                self.app.contentRegion.show(customSchemaSetting);

                self.oldView = customSchemaSetting;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },
    customFormSetting: function (id, scroll) {
        var self = this;
        var callback = function () {
            var model = app.entities.collections.customEvent.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100070);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100070 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customFormSetting = new View_CustomFormSetting({ model: model[0], app: self.app, scroll: scroll });
                self.app.contentRegion.show(customFormSetting);

                self.oldView = customFormSetting;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },
    customAdSetting: function (id, scroll) {
        var self = this;
        var callback = function () {
            var model = app.entities.collections.customEvent.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100070);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100070 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customAdSetting = new View_CustomAdSetting({ model: model[0], app: self.app, scroll: scroll });
                self.app.contentRegion.show(customAdSetting);

                self.oldView = customAdSetting;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },
    customEventLive: function (id, scroll) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100408);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100408;

            self.displayOther();

            self.app.entities.collections.customEventLive.fetch({
                success: function (mode, response) {
                    var customEventLive = new View_CustomEventLive({ collection: self.app.entities.collections.customEventLive, app: self.app, event: id, scroll: scroll });
                    self.app.contentRegion.show(customEventLive);

                    self.oldView = customEventLive;

                    var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                    self.app.customMenuRegion.show(customMenu);

                    //self.app.editorRegion.empty();
                    self.app.editorRegion.reset();

                    self.scrollTop();
                },
                error: function (mode, response) {
                }
            });
        }
        this.checkHasChanged(callback);
    },
    customEventTemplate: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100244);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100244;

            self.displayOther();

            var customEventTemplate = new View_CustomEventTemplate({ collection: self.app.entities.collections.customEventTemplate, app: self.app });
            self.app.contentRegion.show(customEventTemplate);

            self.oldView = customEventTemplate;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customWebpage: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100134);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100134;

            self.displayOther();

            var tmpModel = new Entities_Empty.Model();
            tmpModel.attributes.DisplayType = 0;

            var customWebpage = new View_CustomWebpage({ collection: self.app.entities.collections.customWebpage, app: self.app, model: tmpModel });
            self.app.contentRegion.show(customWebpage);

            self.oldView = customWebpage;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customWebpageEditItem: function (id, eventId, scroll, module_selected) {
        var self = this;

        var callback = function () {
            self.app.editorRegion.reset();
            var model = self.app.entities.collections.customWebpage.fullCollection.where({ id: id });

            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100134);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100134 + ' ' + model[0].attributes.Name;

                //self.displayOther();

                var collection = new Entities_TmpCustomWebpageItem.Collection();
                var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: model[0].attributes.id })
                customWebpageItem.forEach(function (entry) {
                    var tmpModel = new Entities_TmpCustomWebpageItem.Model();
                    tmpModel.attributes.Webpage = entry.attributes.Webpage;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;

                    //NOBODYCHANGEtmpModel.attributes.Body = entry.attributes.Body.split('<p>&nbsp;</p>').join('');
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.OldBody = entry.attributes.Body;
                    tmpModel.attributes.Checksum = '';
                    tmpModel.attributes.BodyCK = '';
                    tmpModel.attributes.BodyCB = '';
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = entry.attributes.id;
                    tmpModel.id = entry.id;
                    tmpModel.attributes.orgId = entry.attributes.id;

                    tmpModel.attributes.EnableDuration = false;
                    tmpModel.attributes.DisplayType = 0;
                    tmpModel.attributes.Parent = entry.attributes.Parent;
                    tmpModel.attributes.Dropdown = entry.attributes.Dropdown;

                    if (tmpModel.attributes.Tag == 1 && tmpModel.attributes.Position == 1) {
                        tmpModel.attributes.Selected = true;
                    } else {
                        tmpModel.attributes.Selected = false;
                    }

                    collection.add(tmpModel);
                });

                var header = collection.where({ Webpage: model[0].attributes.id, Tag: 1, Position: 1 });
                //var firstPage = collection.where({ Webpage: model[0].attributes.id, Tag: 1, Position : 1 });

                var customWebpageEditItem = new View_CustomWebpageEditItem({ SelectedWebpageItemId: header[0].attributes.id, collection: collection, model: model[0], app: self.app, copyCollection: self.app.entities.collections.customWebpage, eventId: eventId, scroll: scroll, module_selected: module_selected });
                self.app.contentRegion.show(customWebpageEditItem);



                var customWebpageEditor = new View_CustomWebpageEditor({ webpageItem_id: header[0].attributes.id, webpageItem_DisplayType: model[0].attributes.DisplayType, webpageModel: model[0], app: self.app });
                self.app.editorRegion.show(customWebpageEditor);

                self.oldView = customWebpageEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);
                self.scrollTop();



            }
        }
        this.checkHasChanged(callback);
    },
    customInfopage: function () {
        var self = this;

        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100451);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100451;

            self.displayOther();

            var tmpModel = new Entities_Empty.Model();
            tmpModel.attributes.DisplayType = 1;

            var customInfopage = new View_CustomInfopage({ collection: self.app.entities.collections.customInfopage, app: self.app, model: tmpModel });
            self.app.contentRegion.show(customInfopage);

            self.oldView = customInfopage;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    /*customInfopageEditItem: function (id) {
        var self = this;

        var callback = function () {
            self.app.editorRegion.reset();

            var model = self.app.entities.collections.customInfopage.fullCollection.where({ id: id });

            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100451);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100451 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var tmpPosition = 2147483647;
                var tmpId = -1;
                self.app.entities.collections.customInfopageItem.forEach(function (entry) {
                    if (entry.attributes.Webpage == model[0].attributes.id && entry.attributes.Position < tmpPosition) {
                        tmpPosition = entry.attributes.Position;
                        tmpId = entry.attributes.id;
                    }
                });

                var collection = new Entities_TmpCustomInfopageItem.Collection();
                var customInfopageItem = self.app.entities.collections.customInfopageItem.where({ Webpage: model[0].attributes.id })
                customInfopageItem.forEach(function (entry) {
                    var tmpModel = new Entities_TmpCustomInfopageItem.Model();
                    tmpModel.attributes.Webpage = entry.attributes.Webpage;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;


                    //NOBODYCHANGEtmpModel.attributes.Body = entry.attributes.Body.split('<p>&nbsp;</p>').join('');
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.OldBody = entry.attributes.Body;
                    tmpModel.attributes.Checksum = '';
                    tmpModel.attributes.BodyCK = '';
                    tmpModel.attributes.BodyCB = '';
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = entry.attributes.id;
                    tmpModel.id = entry.id;
                    tmpModel.attributes.orgId = entry.attributes.id;

                    tmpModel.attributes.EnableDuration = true;
                    tmpModel.attributes.DisplayType = 1;

                    if (entry.id == tmpId) {
                        tmpModel.attributes.Selected = true;
                    } else {
                        tmpModel.attributes.Selected = false;
                    }

                    collection.add(tmpModel);
                });

                var header = collection.where({ id: tmpId });

                var customInfopageEditItem = new View_CustomInfopageEditItem({ SelectedWebpageItemId: tmpId, collection: collection, model: model[0], app: self.app, copyCollection: self.app.entities.collections.customInfopage });

                self.app.contentRegion.show(customInfopageEditItem);

                self.oldView = customInfopageEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                var customInfopageEditor = new View_CustomInfopageEditor({ webpageItem_id: tmpId, webpageItem_DisplayType: model[0].attributes.DisplayType, webpageModel: model[0], app: self.app });
                self.app.editorRegion.show(customInfopageEditor);
                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },*/
    customInfopageEditItem: function (id, eventId) {
        var self = this;

        var callback = function () {
            self.app.editorRegion.reset();
            //var model = self.app.entities.collections.customInfopage.fullCollection.where({ EventId: eventId, DisplayType: 1 })[0];

            var customEvent = app.entities.collections.customEvent.fullCollection.where({ id: eventId })[0];

            var model = app.entities.collections.customModule.where({ id: id })[0];
            var data = JSON.parse(model.attributes.Data)[0];
            var collection = new Entities_Memory_Position.Collection(data.Collection);

            //var myArray = JSON.parse(data.attributes.Data);

            var customInfopageEditItem = new View_CustomInfopageEditItem({ collection: collection /*self.app.entities.collections.DiasListCollection*/, model: model, app: self.app, copyCollection: self.app.entities.collections.customInfopage, eventId: eventId, scroll: scroll, customEvent: customEvent });
            self.app.contentRegion.show(customInfopageEditItem);

            self.oldView = customInfopageEditItem;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);
            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customSchemaScreenEditItem: function (id, eventId, scroll, module_selected) {
        var self = this;

        var callback = function () {
            self.app.editorRegion.reset();
            var model = self.app.entities.collections.customInfopage.fullCollection.where({ id: id, DisplayType: 2 })[0];

            var customInfopageEditItem = new View_CustomInfopageEditItem({ collection: self.app.entities.collections.DiasListCollection, model: model, app: self.app, copyCollection: self.app.entities.collections.customInfopage, eventId: eventId, scroll: scroll, module_selected: module_selected });
            self.app.contentRegion.show(customInfopageEditItem);

            self.oldView = customInfopageEditItem;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);
            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customQRCode: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100071);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100071;

            self.displayOther();

            var customQRCode = new View_CustomQRCode({ collection: self.app.entities.collections.customQRCode, app: self.app });
            self.app.contentRegion.show(customQRCode);

            self.oldView = customQRCode;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customQRCodeEditItem: function (id, eventId, scroll) {
        var self = this;
        var callback = function () {
            var model = self.app.entities.collections.customQRCode.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100071);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100071 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customQRCodeEditItem = new View_CustomQRCodeEditItem({ collection: self.app.entities.collections.customQRCode, model: model[0], app: self.app, eventId: eventId, scroll: scroll });
                self.app.contentRegion.show(customQRCodeEditItem);

                self.oldView = customQRCodeEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);
                self.scrollTop();

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();
            }
        }
        this.checkHasChanged(callback);
    },
    customSchemaModel: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100381);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100381;

            self.displayOther();

            var customSchemaModel = new View_CustomSchemaModel({ collection: self.app.entities.collections.customSchemaModel, app: self.app });
            self.app.contentRegion.show(customSchemaModel);

            self.oldView = customSchemaModel;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customSchemaModelEditItem: function (id, eventId, scroll) {
        var self = this;
        var callback = function () {
            var model = self.app.entities.collections.customSchemaModel.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100381);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100381 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customSchemaModelEditItem = new View_CustomSchemaModelEditItem({ model: model[0], app: self.app, eventId: eventId, scroll: scroll });
                self.app.contentRegion.show(customSchemaModelEditItem);

                self.oldView = customSchemaModelEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },
    customFormModel: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100180);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100180;

            self.displayOther();

            var customFormModel = new View_CustomFormModel({ collection: self.app.entities.collections.customFormModel, app: self.app });
            self.app.contentRegion.show(customFormModel);

            self.oldView = customFormModel;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customFormModelEditItem: function (id, eventId, scroll) {
        var self = this;
        var callback = function () {
            var model = self.app.entities.collections.customFormModel.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X100180);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100180 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customFormModelEditItem = new View_CustomFormModelEditItem({ model: model[0], app: self.app, eventId: eventId, scroll: scroll });
                self.app.contentRegion.show(customFormModelEditItem);

                self.oldView = customFormModelEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },
    customProductSheetModel: function () {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X101102);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X101102;

            self.displayOther();

            var customProductSheetModel = new View_CustomProductSheetModel({ collection: self.app.entities.collections.customProductSheetModel, app: self.app });
            self.app.contentRegion.show(customProductSheetModel);

            self.oldView = customProductSheetModel;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customProductSheetModelEditItem: function (id) {
        var self = this;
        var callback = function () {
            var model = self.app.entities.collections.customProductSheetModel.fullCollection.where({ id: id });
            if (model[0] != null) {
                $('#custom_li_01').text(resources.X100069);
                $('#custom_li_02').text(resources.X101102);
                $('#custom_li_03').text(model[0].attributes.Name);
                $('#custom_li_03').show();
                document.title = resources.X100735 + resources.X100069 + ' ' + resources.X101102 + ' ' + model[0].attributes.Name;

                self.displayOther();

                var customProductSheetModelEditItem = new View_CustomProductSheetModelEditItem({ model: model[0], app: self.app });
                self.app.contentRegion.show(customProductSheetModelEditItem);

                self.oldView = customProductSheetModelEditItem;

                var customMenu = new View_CustomMenu({ enable: true, app: self.app });
                self.app.customMenuRegion.show(customMenu);

                //self.app.editorRegion.empty();
                self.app.editorRegion.reset();

                self.scrollTop();
            }
        }
        this.checkHasChanged(callback);
    },
    customImage: function (eventId, scroll, module_selected, viewType) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100321);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100321;

            self.displayOther();

            var customImage = new View_CustomImage({ collection: app.entities.collections.customImage, app: self.app, designMode: false, eventId: eventId, scroll: scroll, module_selected: module_selected, viewType: viewType });
            self.app.contentRegion.show(customImage);

            self.oldView = customImage;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customScreen: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200032;

            self.displayOther();

            var model = self.app.entities.collections.customImage.where({ TableNum: 1, TableId: 1 })[0];

            var customScreen = new View_CustomScreen({ collection: self.app.entities.collections.customImage, app: self.app, model: model/*self.app.entities.models.custom*/ });
            self.app.contentRegion.show(customScreen);

            self.oldView = customScreen;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customColor: function () {
        var self = this;
        var callback = function () {
            document.title = resources.X200099 + resources.X200162;

            self.displayOther();

            var customColor = new View_CustomColor({ model: self.app.entities.models.customColor, app: self.app });
            self.app.contentRegion.show(customColor);

            self.oldView = customColor;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app, model: self.app.entities.models.custom });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customFile: function (eventId, scroll, module_selected, viewType) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100322);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100321;

            self.displayOther();

            var customFile = new View_CustomFile({ collection: self.app.entities.collections.customFile, app: self.app, eventId: eventId, scroll: scroll, module_selected: module_selected, viewType: viewType });
            self.app.contentRegion.show(customFile);

            self.oldView = customFile;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customAdImage: function (eventId, scroll, module_selected, viewType) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100321);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100321;

            self.displayOther();

            var customAdImage = new View_CustomAdImage({ collection: app.entities.collections.customAdImage, app: self.app, designMode: false, eventId: eventId, scroll: scroll, module_selected: module_selected, viewType: viewType });
            self.app.contentRegion.show(customAdImage);

            self.oldView = customAdImage;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customAdBigImage: function (eventId, scroll, module_selected, viewType) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100321);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100321;

            self.displayOther();

            var customAdBigImage = new View_CustomAdBigImage({ collection: app.entities.collections.customAdBigImage, app: self.app, designMode: false, eventId: eventId, scroll: scroll, module_selected: module_selected, viewType: viewType });
            self.app.contentRegion.show(customAdBigImage);

            self.oldView = customAdBigImage;

            var customMenu = new View_CustomMenu({ enable: true, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    customSubscription: function (enableMenu) {
        var self = this;
        var callback = function () {
            $('#custom_li_01').text(resources.X100069);
            $('#custom_li_02').text(resources.X100081);
            $('#custom_li_03').css('display', 'none');
            document.title = resources.X100735 + resources.X100069 + ' ' + resources.X100081;

            self.displayOther();

            var customSubscription = new View_CustomSubscription({ collection: self.app.entities.collections.customSubscription, app: self.app });
            self.app.contentRegion.show(customSubscription);

            self.oldView = customSubscription;

            var customMenu = new View_CustomMenu({ enable: enableMenu, app: self.app });
            self.app.customMenuRegion.show(customMenu);

            //self.app.editorRegion.empty();
            self.app.editorRegion.reset();

            self.scrollTop();
        }
        this.checkHasChanged(callback);
    },
    gettingStarted: function () {
        var self = this;
        var callback = function () {
            Help.help(self, true, 'gettingStarted');
        }
        this.checkHasChanged(callback);
    },
    accessTokenLifetime: function () {
        var self = this;
        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100878;
        dialog2ButtonModel.attributes.Text = resources.X100879;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });

        var callback01 = function () {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            self.stopListening(dialog2Button, "dialog:closeclicked", callback02);

            AuthHelper.signin(self.app);

            //self.app.userManager.signinRedirect({
            //    extraQueryParams: {
            //        ui_locales: config.cultureName()
            //    }
            //});
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
            var authConfig = config.getAuthConfig();
            window.location = authConfig.post_logout_redirect_uri;
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(dialog2Button);
    },
    //Menu
    index: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html';
        }
        this.checkHasChanged(callback);
    },
    contact: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#contact';
        }
        this.checkHasChanged(callback);
    },
    infoScreen: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#infoScreen';
        }
        this.checkHasChanged(callback);
    },
    infoScreenContact: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#infoScreenContact';
        }
        this.checkHasChanged(callback);
    },
    price: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#price';
        }
        this.checkHasChanged(callback);
    },
    searchEvent: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#searchEvent';
        }
        this.checkHasChanged(callback);
    },
    about: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#about';
        }
        this.checkHasChanged(callback);
    },
    portal: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/portal.html';
        }
        this.checkHasChanged(callback);
    },
    //Footer
    policy: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#policy';
        }
        this.checkHasChanged(callback);
    },
    termsOfTrade: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#termsOfTrade';
        }
        this.checkHasChanged(callback);
    },
    roadmap: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#roadmap';
        }
        this.checkHasChanged(callback);
    },
    //Header
    btnForgotPassword: function () {
        var self = this;
        var callback = function () {
            window.location.href = window.location.protocol + '//' + window.location.host + '/index.html#forgotPassword';
        }
        this.checkHasChanged(callback);
    },
    btnAuthLogoff: function () {
        var self = this;
        var callback = function () {
            mgr.redirectForLogout();
        }
        this.checkHasChanged(callback);
    }


    //,
    //onRoute: function(routeName, routePath, routeArgs) {

    //    // What fun stuff should we put here?
    //    // Keep a session alive somehow?
    //    // Add analytics/tracking?
    //}
});




