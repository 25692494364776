var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customInfopageListItem_empty"> <div class="col-md-4 grid-margin stretch-card d-flex align-items-center pb-3 border-bottom"> <div class="card"> <div class="card-body"> <div class="d-sm-flex flex-row flex-wrap text-center text-sm-left align-items-center"> <img src="../../../../images/faces/face11.jpg" class="img-lg rounded" alt="profile image"> <div class="ml-sm-3 ml-md-0 ml-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0"> <h6 class="mb-0">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <div class="ml-auto"> <i id="customInfopageListItem_btnEdit" style="color: #6c7293 !important" class="fa fa-lg fa-1x fa-cog font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customInfopageListItem_btnDelete" style="color: #6c7293 !important" class="far fa-lg fa-1x fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div> </div> </div> </div> </div> ';
}
return __p;
};
