var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customIPAddressListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="margin-top: 15px">  <div class="ml-3"> <h6 class="mb-1"><i class="mdi mdi-24px mdi-ip-network mr-1"></i>'+
((__t=( model.Name ))==null?'':__t)+
'</h6> </div> <div class="ml-auto"> <i id="customIPAddressListItem_btnEdit" style="color: #6c7293 !important; cursor:pointer" class="fa fa-lg fa-cog font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customIPAddressListItem_btnDelete" style="color: #6c7293 !important; cursor:pointer" class="far fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
