﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_infoPageListItem from './tpl/infoPageListItem.html';
import language from '../../../common/language';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';

export default Marionette.ItemView.extend({
    className: 'col-2 col-sm-6 col-md-2 col-lg-2 col-xl-2',
    attributes: function () {
        return {
            style: 'border-style: solid; border-width: 1px; border-color: rgb(233, 233, 233) !important; margin:2px;'
        }
    },
    getTemplate: function () {
        return tpl_infoPageListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        var nameArray = this.model.attributes.Name.split(' ', 4);
        this.intials = '';

        if (nameArray.length > 0) {
            this.intials += nameArray[0].charAt(0).toUpperCase();
            this.model.attributes.FirstName = nameArray[0];
        }
        if (nameArray.length > 1) this.intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) this.intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) this.intials += nameArray[3].charAt(0).toUpperCase();
    },
    ui: {
        'infoPageListItem_light': '#infoPageListItem_light',
        'infoPageListItem_profileImage': '#infoPageListItem_profileImage',
        'infoPageListItem_imageUrl': '#infoPageListItem_imageUrl'
    },
    events: {
    },
    onRender: function () {
        //this.ui.infoPageListItem_light.css('background-color', this.model.attributes.Color + ' !Important;');


        this.ui.infoPageListItem_light.attr('style', 'background-color:' + this.model.attributes.Color + ' !Important;height: 70px;');
        
        this.ui.infoPageListItem_profileImage.text(this.intials);

        if (this.model.attributes.ImageUrl != '' && this.model.attributes.ImageUrl.length > 0) {
            this.ui.infoPageListItem_imageUrl.show();
            this.ui.infoPageListItem_profileImage.css('display', 'none');
        } else {
            this.ui.infoPageListItem_imageUrl.css('display', 'none');
            this.ui.infoPageListItem_profileImage.show();
        }
    },
    onAttach() {
        /*var nameArray = this.model.attributes.Name.split(' ', 4);
        var intials = '';

        if (nameArray.length > 0) {
            intials += nameArray[0].charAt(0).toUpperCase();
            this.model.attributes.FirstName = nameArray[0];
        }
        if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        this.ui.infoPageListItem_profileImage.text(intials);*/
    }
}, Backbone.Radio.Requests);
