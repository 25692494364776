﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customProductSheetData from './tpl/customProductSheetData.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomProductSheetDataImport from './customProductSheetDataImport';
import View_CustomProductSheetDataInsert from './customProductSheetDataInsert';
import View_CustomProductSheetDataList from './customProductSheetDataList';



import config from '../../../common/config';
import Help from '../../helper/help';


export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customProductSheetData();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
        
        this.informationCollection = new Entities_Empty.Collection();

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customProductSheetData:setHasChanged', function(enable) {
            self.setHasChanged(enable);
        });

        this.listenTo(userChannel,'customProductSheetData:setUUID', function() {
            self.setUUID();
        });

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customProductSheetData');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customProductSheetDataList:save', callback);
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    ui: {
        'customProductSheetData_select_FormForm': '#customProductSheetData_select_FormForm',
        'customProductSheetData_selectEvent': '#customProductSheetData_selectEvent',
        'customProductSheetData_btnDeleteAllSubmit': '#customProductSheetData_btnDeleteAllSubmit',
        'customProductSheetData_btnExport': '#customProductSheetData_btnExport',
        'help': '#help'
    },
    events: {
        'click @ui.customProductSheetData_btnDeleteAllSubmit': 'btnDeleteAllSubmit',
        'change @ui.customProductSheetData_selectEvent': 'selectEventChange',
        'change @ui.customProductSheetData_select_FormForm': 'selectFormFormChange',
        'click #help': 'help'
    },
    childEvents: {
        'table_addModel': function (childView, msg) {
            this.customProductSheetDataList.currentView.table_addModel(msg.tmpModel, msg.jumpToData, msg.jumpFirst, msg.ignoreEmptyContent);
        },
        'table_getFirstVisibleItem': function (childView, msg) {
            this.customProductSheetDataList.currentView.table_getFirstVisibleItem(msg);
        },
        'table_readDataCollection': function (childView, msg) {
            this.readDataCollection(/*this*/childView.settingModel.attributes.EventId);
        },

    },
    regions: {
        //customProductSheetDataImport: "#customProductSheetDataImport",
        customFormForm: "#customFormForm",
        customProductSheetDataInsert: "#customProductSheetDataInsert",
        customProductSheetDataList: "#customProductSheetDataList",
        information: "#customProductSheetDataInformation"
    },
    UUID : function () {
        var lut = [];
        for(var i = 0; i < 256; i++) {
            lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
        }

        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;

        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
          lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
          lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
          lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    },
    setUUID : function () {
        var uuid = this.UUID();
        this.settingModel.attributes.CommonId = uuid;
    },
    onBeforeShow: function () {
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: parseInt(this.$el.find('#customProductSheetData_selectEvent').val()) });

        if (eventModel.length > 0) {

            if (eventModel[0].attributes.ProductSheetModel != -1) {
                var productSheetModel = this.app.entities.collections.customProductSheetModel.fullCollection.where({ id: eventModel[0].attributes.ProductSheetModel });
                if (productSheetModel.length > 0) {
                    this.productSheetModel = productSheetModel[0];
                    //this.customProductSheetDataImport.show(new View_CustomProductSheetDataImport({ collection: this.collection, productSheetModel: this.productSheetModel, settingModel: this.settingModel, app : this.app }));
                    
                    this.setUUID();

                    this.customProductSheetDataInsert.show(new View_CustomProductSheetDataInsert({ collection: this.collection, productSheetModel: this.productSheetModel, settingModel: this.settingModel, app : this.app }));
                    this.customProductSheetDataList.show(new View_CustomProductSheetDataList({ collection: this.app.entities.collections.customProductSheetData, productSheetModel: this.productSheetModel, settingModel: this.settingModel, app : this.app }));

                    this.readDataCollection(this.settingModel.attributes.EventId);
                }
            }
        }

        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    onRender: function () {
        this.setupEvent(true);
        this.setupDeleteAllBtn();
        this.renderInformation();
        this.setupExport();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.app.entities.collections.customEvent.fullCollection.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }

        //Any form model selected on events
        var hasItems = false;
        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.ProductSheetModel != -1) {
                hasItems = true;
            }
        });

        //No form selectes on events
        if (!hasItems) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X101140 });
            this.informationCollection.add(tmpModel);
        }

        //No data on the events form
        if (this.collection.length == 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100345 });
            this.informationCollection.add(tmpModel);
        }

        //Form not enable in config subscription, only display this message
        if (this.app.entities.models.configSubscription[0].attributes.EnableProdutSheetModel == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100130 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        if (this.settingModel.attributes.EventId != -1) {
            var tmp = { "id": this.settingModel.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();
    },
    setupDeleteAllBtn: function () {
        var enable = true;

        if (this.app.entities.collections.customEvent.fullCollection.length <= 0) {
            enable = false;
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableProdutSheetModel == false) {
            enable = false;
        }

        if (enable) {
            //this.ui.customProductSheetData_btnDeleteAllSubmit.removeClass('btn-secondary');
            //this.ui.customProductSheetData_btnDeleteAllSubmit.addClass('btn-primary');
            this.ui.customProductSheetData_btnDeleteAllSubmit.prop('disabled', false);
            this.ui.customProductSheetData_btnExport.prop('disabled', false);
        } else {
            //this.ui.customProductSheetData_btnDeleteAllSubmit.removeClass('btn-primary');
            //this.ui.customProductSheetData_btnDeleteAllSubmit.addClass('btn-secondary');
            this.ui.customProductSheetData_btnDeleteAllSubmit.prop('disabled', true);
            this.ui.customProductSheetData_btnExport.prop('disabled', true);
        }
    },
    disableAll: function() {
        this.ui.customProductSheetData_btnDeleteAllSubmit.prop('disabled', true);
    },
    setupEvent: function (first) {
        this.ui.customProductSheetData_selectEvent.empty();
        var self = this;

        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.ProductSheetModel != -1) {
                if (self.settingModel.attributes.EventId == entry.attributes.id) {
                    self.ui.customProductSheetData_selectEvent.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
                }
                else {
                    self.ui.customProductSheetData_selectEvent.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
                }
            }
        });

        if (first) {
            this.setModelFirst('EventId');
        }
    },
    setupExport: function () {
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: parseInt(this.$el.find('#customProductSheetData_selectEvent').val()) });

        if (eventModel.length > 0) {
            if (eventModel[0].attributes.ProductSheetModel != -1) {
                var href = config.webApiUrl() + '/api/CustomExportData/route03/-1?AuthUserId=' + config.authUserId() + '&EventId=' + this.settingModel.attributes.EventId
                        + '&CustomProductSheetModelId=' + eventModel[0].attributes.ProductSheetModel
                        + '&FileName=' + resources.X100268
                        + '&Date=' + resources.X100294
                        + '&CultureName=' + config.cultureName();
                this.ui.customProductSheetData_btnExport.prop('href', href);
                this.ui.customProductSheetData_btnExport.prop('download', resources.X100268);
            }
        }
    },
    getSelectEvent: function (event) {
        var eventId = parseInt(this.$el.find('#customProductSheetData_selectEvent').first().val());
        return eventId;
    },
    setModelFirst: function (attributes) {
        switch (attributes) {
            case 'EventId': 
                this.settingModel.attributes.EventId = parseInt(this.ui.customProductSheetData_selectEvent.first().val()); 
                break;
        }
    },
    setModel: function (attributes) {
        switch (attributes) {
            case 'EventId': 
                this.settingModel.attributes.EventId = parseInt(this.ui.customProductSheetData_selectEvent.val()); 
                break;
        }
    },
    setModelUI: function (attributes) {
        switch (attributes) {
            case 'EventId': this.ui.customProductSheetData_selectEvent.val(this.settingModel.attributes.EventId); break;
        }
    },
    save: function(callback) {
        if (this.getHasChanged() ) {
            var self = this;

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = resources.X100395;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                dialog2Button.triggerMethod('dialog:close');
                self.saveHasChanged(callback);
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                this.setHasChanged(false);
                callback();
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);
        } else {
            callback();
        }
    },
    selectEventChange: function (event) {
        event.preventDefault();

        var self = this;
        var callback = function () {
            self.setModel('EventId');
            self.readDataCollection(self.settingModel.attributes.EventId);
            self.setupExport();
            self.customViewEvent_setActive();
        }
        this.save(callback);
    },
    readDataCollection: function (eventId) {
        var self = this;

        var callback = function () {
            self.app.entities.collections.customProductSheetData.fetch({
                EventId: eventId,
                success: function () {
                    self.app.entities.collections.customProductSheetData.trigger('change');
                },
                error: function () {
                }
            });
        }

        this.app.entities.collections.customProductSheetImage.fetch({
            EventId: eventId,
            success: function () {
                callback();
            },
            error: function () {
            }
        });

        
    },
    btnDeleteAllSubmit: function (event) {
        event.preventDefault();

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X101112;
        dialog2ButtonModel.attributes.Text = resources.X100265;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var collection = this.app.entities.collections.customEvent;

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            self.customProductSheetDataList.currentView.table_deleteAll();
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    }
}, Backbone.Radio.Requests);
