'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogWaiting from '../../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../../lib/entities/empty';



import tpl_pageSettingListItemH from './tpl/pageSettingListItemH.html';
import tpl_pageSettingListItemV from './tpl/pageSettingListItemV.html';

//import tpl_001_item from '../../../info/template/tpl/001_item.html';
//import tpl_002_item from '../../../info/template/tpl/002_item.html';

export default Marionette.ItemView.extend({
    tagName: 'div',
    className: '',
    attributes: function () {
        switch (this.model.attributes.Direction) {
            case 0: return {
                style: 'width:190px; height:108px;max-width:190px; max-height:108px;display:inline-block;',
                id: 'pageSettingListItem_li_' + this.model.attributes.id,
                'data-id': this.model.id
            };
                break;
            case 1: return {
                style: 'width:108px; height:190px;max-width:108px; max-height:190px;display:inline-block;',
                id: 'pageSettingListItem_li_' + this.model.attributes.id,
                'data-id': this.model.id
            };
                break;
        }
    },
    getTemplate: function () {
        switch (this.model.attributes.Direction) {
            case 0: return tpl_pageSettingListItemH({ model: this.model.toJSON() }); break;
            case 1: return tpl_pageSettingListItemV({ model: this.model.toJSON() }); break;
        }
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'grid_container': '#grid_container',
        'pageSettingListItem_div_inner': '#pageSettingListItem_div_inner',
        'pageSettingListItem_li': '#pageSettingListItem_li',
        'pageSettingListItem_a': '#pageSettingListItem_a',

    },
    events: {
        'click @ui.pageSettingListItem_a': 'btnEditClick',
        'click @ui.pageSettingListItem_btnEdit': 'btnEditClick',
    },
    onAttach: function () {
    },
    onRender: function () {
        var self = this;

        //self.ui.grid_container.addClass(self.getTemplateClass());
        //self.ui.grid_container.html(tpl_001_item({}));//html_string);

        /*var callback = function (html_string) {
 
            self.ui.grid_container.addClass(self.getTemplateClass());
            self.ui.grid_container.html(html_string);
 
            switch (self.model.attributes.Selected) {
                case 1:
                    self.ui.pageSettingListItem_div_inner.css('border-style', 'solid');
                    self.ui.pageSettingListItem_div_inner.css('border-color', 'black');
                    break;
                case 0:
                    self.ui.pageSettingListItem_div_inner.css('border-style', 'solid');
                    self.ui.pageSettingListItem_div_inner.css('border-color', 'white');
                    break;
            }
        }
        
        $.get(self.model.attributes.TemplateItem, function (html_string) {
            callback(html_string);
        }, 'html');    */

        self.ui.grid_container.addClass(self.model.attributes.TemplateItemClass);
        self.ui.grid_container.html(window.atob(self.model.attributes.TemplateItemBase64));

        switch (self.model.attributes.Selected) {
            case 1:
                self.ui.pageSettingListItem_div_inner.css('border-style', 'solid');
                self.ui.pageSettingListItem_div_inner.css('border-color', 'black');
                break;
            case 0:
                self.ui.pageSettingListItem_div_inner.css('border-style', 'solid');
                self.ui.pageSettingListItem_div_inner.css('border-color', 'white');
                break;
        }



    },
    btnEditClick: function (event) {
        event.preventDefault();
        event.stopPropagation();

        this.model.collection.forEach(function (entry) {
            entry.set({ Selected: 0 });
        });

        this.model.set({ Selected: 1 });
    }
});