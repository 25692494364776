﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogBoxInactive from './tpl/dialogBoxInactive.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#dialogBoxInactive_modal',
    getTemplate: function () {
        return tpl_dialogBoxInactive({ model: this.model.toJSON() });
    }
});
