﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_moduleList from './tpl/moduleList.html';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_ModuleListList from './moduleListList';

export default Marionette.LayoutView.extend({
    dialogRegion: '#moduleList_modal',
    getTemplate: function () {
        return tpl_moduleList({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.moduleSupported = this.options.moduleSupported;
    },
    regions: {
        moduleListList: "#moduleListList",
        //moduleListPagination: "#moduleListPagination",
    },
    onBeforeShow: function () {
        this.moduleListList.show(new View_ModuleListList({ app: this.app, collection: this.collection, moduleSupported: this.options.moduleSupported }));
        //this.moduleListPagination.show(new View_Pagination({ collection: this.collection }));
    },
    onAttach() {
        //this.calcPageSize();
    },
    onRender: function () {
        //this.calcPageSize();
    },
    calcPageSize: function () {
        //className: ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2';

        var maxWidth = $('.card-body').width();

        var factor = 2;

        if (maxWidth < 576) factor = 2;
        if (maxWidth >= 576) factor = 2;
        if (maxWidth > 720) factor = 2;
        if (maxWidth > 960) factor = 4;
        if (maxWidth > 1140) factor = 6;

        //this.collection.fullCollection.pageableCollection.state.pageSize = factor * 2;
        this.collection.fullCollection.pageableCollection.state.pageSize = 12;

    },
    events: {
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02"
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    }
});
