var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFormModelEditItem_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-pencil-square-o" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100180'] ))==null?'':__t)+
' - '+
((__t=( model.Name ))==null?'':__t)+
'</h5> <div class="control-group customFormModelEditItem_inputName"> <div class="form-group"> <label for="customFormModelEditItem_inputName">'+
((__t=( resources['X100182'] ))==null?'':__t)+
'</label> <input class="form-control" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customFormModelEditItem_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div style="height:10px"></div> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label class="control-label" for="customFormModelEditItem_select_FieldUsed">'+
((__t=( resources['X100184'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldUsed"></select> </div> </div> </div>  <div class="form-group" id="customFormModelEditItem_div_Field01"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field01">'+
((__t=( resources['X100190'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType01">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field01" value="'+
((__t=( model.Field01))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType01"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue01"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue01" id="customFormModelEditItem_label_FieldValue01"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue01" style="height:78px">'+
((__t=( model.FieldValue01))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field02"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field02">'+
((__t=( resources['X100191'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType02">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field02" value="'+
((__t=( model.Field02))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType02"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue02"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue02" id="customFormModelEditItem_label_FieldValue02"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue02" style="height:78px">'+
((__t=( model.FieldValue02))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field03"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field03">'+
((__t=( resources['X100192'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType03">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field03" value="'+
((__t=( model.Field03))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType03"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue03"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue03" id="customFormModelEditItem_label_FieldValue03"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue03" style="height:78px">'+
((__t=( model.FieldValue03))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field04"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field04">'+
((__t=( resources['X100193'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType04">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field04" value="'+
((__t=( model.Field04))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType04"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue04"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue04" id="customFormModelEditItem_label_FieldValue04"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue04" style="height:78px">'+
((__t=( model.FieldValue04))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field05"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field05">'+
((__t=( resources['X100194'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType05">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field05" value="'+
((__t=( model.Field05))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType05"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue05"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue05" id="customFormModelEditItem_label_FieldValue05"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue05" style="height:78px">'+
((__t=( model.FieldValue05))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field06"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field06">'+
((__t=( resources['X100195'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType06">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field06" value="'+
((__t=( model.Field06))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType06"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue06"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue06" id="customFormModelEditItem_label_FieldValue06"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue06" style="height:78px">'+
((__t=( model.FieldValue06))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field07"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field07">'+
((__t=( resources['X100196'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType07">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field07" value="'+
((__t=( model.Field07))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType07"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue07"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue07" id="customFormModelEditItem_label_FieldValue07"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue07" style="height:78px">'+
((__t=( model.FieldValue07))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field08"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field08">'+
((__t=( resources['X100197'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType08">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field08" value="'+
((__t=( model.Field08))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType08"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue08"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue08" id="customFormModelEditItem_label_FieldValue08"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue08" style="height:78px">'+
((__t=( model.FieldValue08))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field09"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field09">'+
((__t=( resources['X100198'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType09">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field09" value="'+
((__t=( model.Field09))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType09"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue09"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue09" id="customFormModelEditItem_label_FieldValue09"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue09" style="height:78px">'+
((__t=( model.FieldValue09))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field10"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field10">'+
((__t=( resources['X100199'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType10">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field10" value="'+
((__t=( model.Field10))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType10"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue10"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue10" id="customFormModelEditItem_label_FieldValue10"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue10" style="height:78px">'+
((__t=( model.FieldValue10))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field11"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field11">'+
((__t=( resources['X100200'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType11">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field11" value="'+
((__t=( model.Field11))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType11"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue11"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue11" id="customFormModelEditItem_label_FieldValue11"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue11" style="height:78px">'+
((__t=( model.FieldValue11))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field12"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field12">'+
((__t=( resources['X100201'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType12">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field12" value="'+
((__t=( model.Field12))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType12"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue12"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue12" id="customFormModelEditItem_label_FieldValue12"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue12" style="height:78px">'+
((__t=( model.FieldValue12))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field13"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field13">'+
((__t=( resources['X100202'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType13">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field13" value="'+
((__t=( model.Field13))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType13"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue13"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue13" id="customFormModelEditItem_label_FieldValue13"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue13" style="height:78px">'+
((__t=( model.FieldValue13))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field14"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field14">'+
((__t=( resources['X100203'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType14">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field14" value="'+
((__t=( model.Field14))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType14"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue14"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue14" id="customFormModelEditItem_label_FieldValue14"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue14" style="height:78px">'+
((__t=( model.FieldValue14))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field15"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field15">'+
((__t=( resources['X100204'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType15">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field15" value="'+
((__t=( model.Field15))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType15"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue15"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue15" id="customFormModelEditItem_label_FieldValue15"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue15" style="height:78px">'+
((__t=( model.FieldValue15))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field16"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field16">'+
((__t=( resources['X100205'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType16">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field16" value="'+
((__t=( model.Field16))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType16"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue16"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue16" id="customFormModelEditItem_label_FieldValue16"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue16" style="height:78px">'+
((__t=( model.FieldValue16))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field17"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field17">'+
((__t=( resources['X100206'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType17">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field17" value="'+
((__t=( model.Field17))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType17"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue17"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue17" id="customFormModelEditItem_label_FieldValue17"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue17" style="height:78px">'+
((__t=( model.FieldValue17))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field18"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field18">'+
((__t=( resources['X100207'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType18">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field18" value="'+
((__t=( model.Field18))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType18"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue18"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue18" id="customFormModelEditItem_label_FieldValue18"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue18" style="height:78px">'+
((__t=( model.FieldValue18))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field19"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field19">'+
((__t=( resources['X100208'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType19">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field19" value="'+
((__t=( model.Field19))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType19"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue19"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue19" id="customFormModelEditItem_label_FieldValue19"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue19" style="height:78px">'+
((__t=( model.FieldValue19))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field20"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field20">'+
((__t=( resources['X100209'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType20">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field20" value="'+
((__t=( model.Field20))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType20"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue20"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue20" id="customFormModelEditItem_label_FieldValue20"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue20" style="height:78px">'+
((__t=( model.FieldValue20))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field21"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field21">'+
((__t=( resources['X100210'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType21">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field21" value="'+
((__t=( model.Field21))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType21"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue21"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue21" id="customFormModelEditItem_label_FieldValue21"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue21" style="height:78px">'+
((__t=( model.FieldValue21))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field22"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field22">'+
((__t=( resources['X100211'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType22">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field22" value="'+
((__t=( model.Field22))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType22"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue22"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue22" id="customFormModelEditItem_label_FieldValue22"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue22" style="height:78px">'+
((__t=( model.FieldValue22))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field23"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field23">'+
((__t=( resources['X100212'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType23">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field23" value="'+
((__t=( model.Field23))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType23"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue23"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue23" id="customFormModelEditItem_label_FieldValue23"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue23" style="height:78px">'+
((__t=( model.FieldValue23))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field24"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field24">'+
((__t=( resources['X100213'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType24">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field24" value="'+
((__t=( model.Field24))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType24"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue24"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue24" id="customFormModelEditItem_label_FieldValue24"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue24" style="height:78px">'+
((__t=( model.FieldValue24))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field25"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field25">'+
((__t=( resources['X100214'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType25">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field25" value="'+
((__t=( model.Field25))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType25"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue25"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue25" id="customFormModelEditItem_label_FieldValue25"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue25" style="height:78px">'+
((__t=( model.FieldValue25))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field26"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field26">'+
((__t=( resources['X100215'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType26">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field26" value="'+
((__t=( model.Field26))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType26"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue26"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue26" id="customFormModelEditItem_label_FieldValue26"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue26" style="height:78px">'+
((__t=( model.FieldValue26))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field27"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field27">'+
((__t=( resources['X100216'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType27">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field27" value="'+
((__t=( model.Field27))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType27"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue27"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue27" id="customFormModelEditItem_label_FieldValue27"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue27" style="height:78px">'+
((__t=( model.FieldValue27))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field28"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field28">'+
((__t=( resources['X100217'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType28">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field28" value="'+
((__t=( model.Field28))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType28"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue28"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue28" id="customFormModelEditItem_label_FieldValue28"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue28" style="height:78px">'+
((__t=( model.FieldValue28))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field29"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field29">'+
((__t=( resources['X100218'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType29">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field29" value="'+
((__t=( model.Field29))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType29"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue29"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue29" id="customFormModelEditItem_label_FieldValue29"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue29" style="height:78px">'+
((__t=( model.FieldValue29))==null?'':__t)+
'</textarea> </div> </div> </div> <div class="form-group" id="customFormModelEditItem_div_Field30"> <div class="row"> <div class="control-label col-md-6"> <label for="customFormModelEditItem_input_Field30">'+
((__t=( resources['X100219'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <label for="customFormModelEditItem_select_FieldType30">'+
((__t=( resources['X100220'] ))==null?'':__t)+
'</label> </div> </div> <div class="row"> <div class="control-label col-md-6"> <input class="form-control" id="customFormModelEditItem_input_Field30" value="'+
((__t=( model.Field30))==null?'':__t)+
'"> </div> <div class="control-label col-md-6"> <select class="form-control" id="customFormModelEditItem_select_FieldType30"></select> </div> </div> <div style="height:3px"></div> <div class="row" id="customFormModelEditItem_div_FieldValue30"> <div class="control-label col-md-12"> <label for="customFormModelEditItem_input_FieldValue30" id="customFormModelEditItem_label_FieldValue30"></label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customFormModelEditItem_textarea_FieldValue30" style="height:78px">'+
((__t=( model.FieldValue30))==null?'':__t)+
'</textarea> </div> </div> </div> <br> <button type="button" class="btn btn-primary" id="customFormModelEditItem_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
