var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventLive_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customFormSetting">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customFormSettingInformation" class="e4usInformation" style="display:none"></div> <div id="customFormSetting_div_anySchema"> <h4 class="card-title">'+
((__t=( resources['X101258'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group customFormSetting_inputFormMenuName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customFormSetting_inputFormSubmitBtnName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customFormSetting_inputFormCancelBtnName"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customFormSetting_inputFormSubmitBtnSucces"> <span class="help-inline" style="color:red"></span> </div> <div class="control-group customFormSetting_inputFormNotifyEmailAddress"> <span class="help-inline" style="color:red"></span> </div> <div id="customFormSetting_inputNameGroup" class="control-group customEventLive_selectEvent"> <div class="form-group"> <label for="customFormSetting_selectEvent">'+
((__t=( resources['X101257'] ))==null?'':__t)+
'</label> <div class="form-group" id="customFormSetting_divSchemaMenuName"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100106'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customFormSetting_inputFormMenuName" style="margin-bottom:0px" value="'+
((__t=( model.FormMenuName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100107'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customFormSetting_inputFormSubmitBtnName" style="margin-bottom:0px" value="'+
((__t=( model.FormSubmitBtnName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100391'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customFormSetting_inputFormCancelBtnName" style="margin-bottom:0px" value="'+
((__t=( model.FormCancelBtnName ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100108'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customFormSetting_inputFormSubmitBtnSucces" style="margin-bottom:0px" value="'+
((__t=( model.FormSubmitBtnSucces ))==null?'':__t)+
'"> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label for="customFormSetting_selectFormNotifyEmail">'+
((__t=( resources['X100427'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customFormSetting_selectFormNotifyEmail" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100466'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100467'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100428'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customFormSetting_inputFormNotifyEmailAddress" style="margin-bottom:0px" value="'+
((__t=( model.FormNotifyEmailAddress ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customFormSetting_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customFormSetting_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
