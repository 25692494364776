﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';


import * as Entities_Memory_Position from '../../entities/memoryPosition';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';
import FilePondPluginImageSizeMetaData from '../../plugins/filepond-plugin-image-size-metadata';
import View_DigitalClock from '../../info/module/setting/digitalClock';
import View_Pomodoro from '../../info/module/setting/pomodoro';
import View_Font from '../../info/module/setting/font';
import View_CustomDepartmentReset from '../CustomDepartmentReset/CustomDepartmentReset';


import * as Entities_CustomPush from '../../entities/customPush';

import * as plugins_qrcode from '../../plugins/qrcode';
import * as nouislider from 'nouislider';


import tpl_customDepartmentEditItem from './tpl/customDepartmentEditItem.html';

import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogWaiting2 from '../../../lib/views/dialogWaiting2/dialogWaiting2';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_CustomHeader from '../region/customHeader/customHeader';

import View_CustomDepartmentUser from './customDepartmentUser';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customDepartmentEditItem({ model: this.model.toJSON() });
    },
    regions: {
        customDepartmentUser: "#customDepartmentUser",
        information: "#customDepartmentEditItemInformation",
    },
    onBeforeShow: function () {
        this.customDepartmentUser.show(new View_CustomDepartmentUser({ collection: this.collection, app: this.app, department: this.options.department, parentModel : this.model }));
        
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.ImageSelected = false;
        this.localSetting.attributes.AspectText = resources.X101203;
        this.localSetting.attributes.ImageModify = false;
        this.localSetting.attributes.AutoCrop = false;

        this.signalR_init();
    },
    signalR_init: function () {
        //this.IWannaChat = $.connection.LuxaforboardHub;
        //$.connection.hub.start();
    },
    help: function () {
        Help.help(this, true, 'customDepartmentEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick(null, callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    setModel: function () {
        var Module_DigitalClock = this.model.attributes.Module_DigitalClock;
        this.ui.customDepartmentEditItem_checkbox_Module_DigitalClock.is(':checked') ? Module_DigitalClock = true : Module_DigitalClock = false;

        this.model.set({
            Module_DigitalClock: Module_DigitalClock,
            TimerView: parseInt(this.ui.customDepartmentEditItem_select_TimerView.val()),
            PersonOnView: parseInt(this.ui.customEventEditItem_selectPersonOnView.val())
        });
    },
    modelHasChanged: function () {
        var changes = false;

        /*if (this.model.attributes.Name != this.oldModel.attributes.Name)  {
            changes = true;
        }*/

        if ((this.model.attributes.BackgroundColor != this.oldModel.attributes.BackgroundColor)
            && (this.model.attributes.Module_DigitalClock != this.oldModel.attributes.Module_DigitalClock))
        {
            changes = true;
        }   

        changes = false;

        this.setHasChanged(changes);
    },
    ui: {
        'customDepartmentEditItem_btnDigitalClock': '#customDepartmentEditItem_btnDigitalClock',
        'customDepartmentEditItem_btnSetting': '#customDepartmentEditItem_btnSetting',
        'customDepartmentEditItem_btnCustomDepartmentReset': '#customDepartmentEditItem_btnCustomDepartmentReset',
        'customDepartmentEditItem_btnCustomDepartmentPomodoro': '#customDepartmentEditItem_btnCustomDepartmentPomodoro',
        'customDepartmentEditItem_checkbox_Module_DigitalClock': '#customDepartmentEditItem_checkbox_Module_DigitalClock',
        'customEventEditItem_selectDigitalClock': '#customEventEditItem_selectDigitalClock',
        'customEventEditItem_selectPersonOnView': '#customEventEditItem_selectPersonOnView',
        'customDepartmentEditItem_ColorPickerSliders_BackgroundColor': '#customDepartmentEditItem_ColorPickerSliders_BackgroundColor',
        'customDepartmentEditItem_inputName': '#customDepartmentEditItem_inputName',
        'customDepartmentEditItem_btnSubmit': '#customDepartmentEditItem_btnSubmit',
        'customDepartmentEditItem_btnCancel': '#customDepartmentEditItem_btnCancel',
        'customDepartmentEditItem_btnRemoveImage': '#customDepartmentEditItem_btnRemoveImage',
        'customDepartmentEditItem_btnSendPomodoroMail': '#customDepartmentEditItem_btnSendPomodoroMail',
        'customDepartmentEdit_filePond': '#customDepartmentEdit_filePond',
        'customDepartmentEdit_image': '#customDepartmentEdit_image',
        'customDepartmentEdit_btn_crop_zoom_plus': '#customDepartmentEdit_btn_crop_zoom_plus',
        'customDepartmentEdit_btn_crop_zoom_minus': '#customDepartmentEdit_btn_crop_zoom_minus',
        'customDepartmentEdit_btn_crop_move_left': '#customDepartmentEdit_btn_crop_move_left',
        'customDepartmentEdit_btn_crop_move_right': '#customDepartmentEdit_btn_crop_move_right',
        'customDepartmentEdit_btn_crop_move_top': '#customDepartmentEdit_btn_crop_move_top',
        'customDepartmentEdit_btn_crop_move_buttom': '#customDepartmentEdit_btn_crop_move_buttom',
        'customDepartmentEdit_btn_crop_rotate_left': '#customDepartmentEdit_btn_crop_rotate_left',
        'customDepartmentEdit_btn_crop_rotate_right': '#customDepartmentEdit_btn_crop_rotate_right',
        'customDepartmentEdit_btn_crop_scale_x': '#customDepartmentEdit_btn_crop_scale_x',
        'customDepartmentEdit_btn_crop_scale_y': '#customDepartmentEdit_btn_crop_scale_y',
        'customDepartmentEditItem_select_TimerView': '#customDepartmentEditItem_select_TimerView',
        'help': '#help',
    },
    events: {
        'click @ui.customDepartmentEditItem_btnDigitalClock': 'btnDigitalClock',
        'click @ui.customDepartmentEditItem_btnSetting': 'btnSetting',
        'click @ui.customDepartmentEditItem_btnCustomDepartmentReset': 'btnCustomDepartmentReset',
        'click @ui.customDepartmentEditItem_btnCustomDepartmentPomodoro': 'btnPomodoro',
        'change @ui.customEventEditItem_selectPersonOnView': 'change_customEventEditItem_selectPersonOnView',
        'colorpickerChange @ui.customDepartmentEditItem_ColorPickerSliders_BackgroundColor': 'colorChange_BackgroundColor',
        'click @ui.customDepartmentEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customDepartmentEditItem_btnCancel': 'btnCancelClick',
        'click @ui.customDepartmentEditItem_btnRemoveImage': 'btnRemoveImageClick',
        'click @ui.customDepartmentEditItem_btnSendPomodoroMail': 'btnSendPomodoroMailClick',
        'click @ui.customDepartmentEdit_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.customDepartmentEdit_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.customDepartmentEdit_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.customDepartmentEdit_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.customDepartmentEdit_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.customDepartmentEdit_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.customDepartmentEdit_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.customDepartmentEdit_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.customDepartmentEdit_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.customDepartmentEdit_btn_crop_scale_y': 'btnScaleYClick',
        'click #help': 'help'

    },
    onRender: function () {
        this.ui.customEventEditItem_selectDigitalClock.val(this.model.attributes.DigitalClock);
        this.ui.customDepartmentEditItem_checkbox_Module_DigitalClock.attr('checked', this.model.attributes.Module_DigitalClock);

        this.ui.customEventEditItem_selectPersonOnView.val(this.model.attributes.PersonOnView);

        this.ui.customDepartmentEditItem_select_TimerView.val(this.model.attributes.TimerView);
    },
    renderInformation: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: resources.X100072 });
        this.informationCollection.add(tmpModel);

    },
    onAttach() {
        var self = this;

        this.ui.customDepartmentEditItem_ColorPickerSliders_BackgroundColor.colorpicker({
            color: self.model.attributes.BackgroundColor,
            format: "rgb"
        });

        this.ui.customDepartmentEdit_image.attr('src', this.model.attributes.ImageUrl);

        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageSizeMetaData
        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.customDepartmentEdit_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            imageResizeTargetWidth: 350,
            imageResizeTargetHeight: null,
            imageResizeMode: 'contain',
            imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            /*
             * allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,
             */

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();

                    self.localSetting.attributes.ImageSelected = true;

                    if (self.cropper) {
                        self.cropper.destroy();
                    }
                    self.ui.customDepartmentEdit_image.attr('src', base64);
                    //self.ui.customDepartmentEdit_roundImage.attr('src', base64);

                    //var mimeType = self.base64MimeType(base64);
                    //var fileData = base64.split(',')[1];

                    self.localSetting.attributes.FileName = fileItem.filenameWithoutExtension;

                    //var previews = document.querySelectorAll('.cropper-img-preview');
                    //var imageDefault = document.querySelector('#customDepartmentEdit_image');

                    //self.cropper = new Cropper(imageDefault, {
                    //    aspectRatio: NaN,
                    //    autoCropArea: 1,
                    //    zoomOnTouch: false,
                    //    zoomOnWheel: false,
                    //    cropBoxResizable: true,
                    //    build: function () {
                    //        var clone = this.cloneNode();

                    //        clone.style.cssText = (
                    //            'display: block;' +
                    //            'width: 100%;' +
                    //            'minWidth: 0;' +
                    //            'minHeight: 0;' +
                    //            'maxWidth: none;' +
                    //            'maxHeight: none;'
                    //        );
                    //    },

                    //    crop: function (e) {
                    //        /*var data = e.detail;
                    //        var cropper = this.cropper;
                    //        var imageData = cropper.getImageData();
                    //        var previewAspectRatio = data.width / data.height;*/
                    //    }
                    //});

                    //self.setupBtn();

                    self.setupCropper();
                    self.localSetting.attributes.ImageModify = true;

                    pond.removeFile(fileItem);
                };
            }
        });

        this.setupCropper();
    },
    setupCropper: function () {
        var self = this;
        if (this.model != undefined) {
            var self = this;


            if (this.cropper) {
                this.cropper.destroy();
            }

            //var previews = document.querySelectorAll('.cropper-img-preview');
            var imageDefault = document.querySelector('#customDepartmentEdit_image');

            var cropBoxData;
            var canvasData;



            this.cropper = new Cropper(imageDefault, {
                aspectRatio: NaN,
                autoCropArea: 1,
                zoomOnTouch: false,
                zoomOnWheel: false,
                cropBoxResizable: true,
                build: function () {
                    var clone = this.cloneNode();

                    clone.style.cssText = (
                        'display: block;' +
                        'width: 100%;' +
                        'minWidth: 0;' +
                        'minHeight: 0;' +
                        'maxWidth: none;' +
                        'maxHeight: none;'
                    );
                },

                crop: function (e) {
                    //First time when autoCrop er true it will trigger crop
                    if (!self.localSetting.attributes.AutoCrop) {
                        self.localSetting.attributes.AutoCrop = true;
                    } else {
                        self.localSetting.attributes.ImageModify = true;
                    }
                    /*var data = e.detail;
                    var cropper = this.cropper;
                    var imageData = cropper.getImageData();
                    var previewAspectRatio = data.width / data.height;*/
                }
            });
        }
    },
    onDestroy() {
    },
    validate: function () {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customDepartmentEditItem_inputName.val();
        
        this.getImageData(this.model);

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            //this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        return true;
    },
    change_customEventEditItem_selectPersonOnView: function () {
        this.model.set({
            PersonOnView: parseInt(this.ui.customEventEditItem_selectPersonOnView.val())
        });
        
    },
    btnDigitalClock: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        var self = this;
        var data = JSON.parse(this.model.attributes.Data)[0];
        var moduleCollection = new Entities_Memory_Position.Collection(data.Collection);

        var tmpModelData = moduleCollection.where({ ModuleType: 4 })[0];
        //var tmpModel = new Entities_Empty.Model(moduleCollection.models[0].attributes.Data);
        var tmpModel = new Entities_Empty.Model(tmpModelData.attributes.Data);

        var digitalClock = new View_DigitalClock({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(digitalClock, "dialog:btn01clicked", callback01);
            self.stopListening(digitalClock, "dialog:btn02clicked", callback02);

            tmpModelData.attributes.Data = digitalClock.model.toJSON();
            //moduleCollection.models[0].attributes.Data = digitalClock.model.toJSON();
            var strJson = JSON.stringify(moduleCollection.toJSON());
            self.model.attributes.Data = '[{ "Collection" : ' + strJson + '}]';
            self.setModel();
            self.getImageData(this.model);
            self.setHasChanged(false);
            self.model.attributes.DeleteImage = false;
            self.model.save(self.model.attributes);
        }
        self.listenTo(digitalClock, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(digitalClock, "dialog:btn01clicked", callback01);
            self.stopListening(digitalClock, "dialog:btn02clicked", callback02);
        }
        self.listenTo(digitalClock, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(digitalClock);
    },
    btnPomodoro: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        var self = this;
        var data = JSON.parse(this.model.attributes.Data)[0];
        var moduleCollection = new Entities_Memory_Position.Collection(data.Collection);

        var tmpModelData = moduleCollection.where({ ModuleType: 5 })[0];
        var tmpModel = new Entities_Empty.Model(tmpModelData.attributes.Data);

        var pomodoro = new View_Pomodoro({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(pomodoro, "dialog:btn01clicked", callback01);
            self.stopListening(pomodoro, "dialog:btn02clicked", callback02);

            tmpModelData.attributes.Data = pomodoro.model.toJSON();
            //moduleCollection.models[0].attributes.Data = digitalClock.model.toJSON();
            var strJson = JSON.stringify(moduleCollection.toJSON());
            self.model.attributes.Data = '[{ "Collection" : ' + strJson + '}]';
            self.setModel();
            self.getImageData(this.model);
            self.setHasChanged(false);
            self.model.attributes.DeleteImage = false;
            self.model.save(self.model.attributes);
        }
        self.listenTo(pomodoro, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(pomodoro, "dialog:btn01clicked", callback01);
            self.stopListening(pomodoro, "dialog:btn02clicked", callback02);
        }
        self.listenTo(pomodoro, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(pomodoro);
    },
    btnSetting: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        var self = this;
        var data = JSON.parse(this.model.attributes.Data)[0];
        var moduleCollection = new Entities_Memory_Position.Collection(data.Collection);



        var tmpModelData = moduleCollection.where({ ModuleType: this.model.attributes.PersonOnView })[0];
        var tmpModel = new Entities_Empty.Model(tmpModelData.attributes.Data);

        var fontName = new View_Font({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(fontName, "dialog:btn01clicked", callback01);
            self.stopListening(fontName, "dialog:btn02clicked", callback02);

            tmpModelData.attributes.Data = fontName.model.toJSON();
            //moduleCollection.models[0].attributes.Data = fontName.model.toJSON();
            var strJson = JSON.stringify(moduleCollection.toJSON());
            self.model.attributes.Data = '[{ "Collection" : ' + strJson + '}]';
            self.setModel();
            self.getImageData(this.model);
            self.setHasChanged(false);
            self.model.attributes.DeleteImage = false;
            self.model.save(self.model.attributes);
        }
        self.listenTo(fontName, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(fontName, "dialog:btn01clicked", callback01);
            self.stopListening(fontName, "dialog:btn02clicked", callback02);
        }
        self.listenTo(fontName, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(fontName);
    },
    btnCustomDepartmentReset: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        var self = this;
        //var data = JSON.parse(this.model.attributes.Data)[0];
        //var moduleCollection = new Entities_Memory_Position.Collection(data.Collection);



        //var tmpModelData = moduleCollection.where({ ModuleType: this.model.attributes.PersonOnView })[0];
        //var tmpModel = new Entities_Empty.Model(tmpModelData.attributes.Data);

        var customDepartmentReset = new View_CustomDepartmentReset({ app: this.app, parent: self, model: this.model, collection: this.options.collectionCustomDepartmentReset });

        var callback01 = function (options) {
            self.stopListening(customDepartmentReset, "dialog:btn01clicked", callback01);
            self.stopListening(customDepartmentReset, "dialog:btn02clicked", callback02);

            //tmpModelData.attributes.Data = fontName.model.toJSON();
            ////moduleCollection.models[0].attributes.Data = fontName.model.toJSON();
            //var strJson = JSON.stringify(moduleCollection.toJSON());
            //self.model.attributes.Data = '[{ "Collection" : ' + strJson + '}]';
            //self.setModel();
            //self.getImageData(this.model);
            //self.setHasChanged(false);
            //self.model.save(self.model.attributes);
        }
        self.listenTo(customDepartmentReset, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(customDepartmentReset, "dialog:btn01clicked", callback01);
            self.stopListening(customDepartmentReset, "dialog:btn02clicked", callback02);
        }
        self.listenTo(customDepartmentReset, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(customDepartmentReset);
    },
    pushMessage: function () {
        var message = String.format("?SecretKey={0}&SecretCode={1}&Department={2}&Refresh=Portal",
            this.app.entities.models.custom.attributes.SecretKey,
            this.app.entities.models.custom.attributes.SecretCode,
            this.model.attributes.id);

        var tmpModel = new Entities_CustomPush.Model();
        tmpModel.attributes.Message = message;
        tmpModel.attributes.id = this.model.attributes.id;
        tmpModel.attributes.TokenKey = "";

        tmpModel.save(tmpModel, {
            silens: true,
            wait: true,
            success: function (response) {
                
           }
        });
    },

    btnSubmitClick: function (event, callbackMenu) {
        //var tmpMessage = this.localSetting.attributes.EventId + "#¤%&/()=?" + message;
        //this.IWannaChat.server.send(tmpMessage);


        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.setModel();
            this.setHasChanged(false);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200085;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function () {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            this.model.attributes.DeleteImage = false;
            self.model.save(this.model.attributes, {
                route: 'route01',
                success: function () {
                    self.pushMessage();
                    dialogWaiting.triggerMethod('dialog:okclicked');

                    
                    /*self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    if (callbackMenu != undefined) {
                        callbackMenu();
                    } else {*/
                        $('.modal-backdrop').remove();
                        self.app.router.navigate("#customDepartment");
                        self.app.router.customDepartment();
                    //}
                }
            });
        }
    },
    btnCancelClick: function (event) {

        if (event != null) {
            event.preventDefault();
        }

        this.model.attributes.Name = this.oldModel.attributes.Name;
        
        self.app.router.navigate("#customDepartment");
        self.app.router.customDepartment();
    },

    btnRemoveImageClick: function (event, callbackMenu) {
        //var tmpMessage = this.localSetting.attributes.EventId + "#¤%&/()=?" + message;
        //this.IWannaChat.server.send(tmpMessage);


        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.setModel();
            this.setHasChanged(false);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200085;
            dialogWaitingModel.attributes.Text = resources.X200375;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function () {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            this.model.attributes.DeleteImage = true;
            self.model.save(this.model.attributes, {
                route: 'route01',
                success: function () {
                    self.pushMessage();
                    dialogWaiting.triggerMethod('dialog:okclicked');


                    /*self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    if (callbackMenu != undefined) {
                        callbackMenu();
                    } else {*/
                    $('.modal-backdrop').remove();
                    var url = 'customDepartmentEditItem/' + self.model.attributes.id;
                    self.app.router.navigate(url);
                    self.app.router.customDepartmentEditItem(self.model.attributes.id);
                    //}
                }
            });
        }
    },

    btnSendPomodoroMailClick: function (event, callbackMenu) {
        //var tmpMessage = this.localSetting.attributes.EventId + "#¤%&/()=?" + message;
        //this.IWannaChat.server.send(tmpMessage);


        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.setModel();
            this.setHasChanged(false);

            var self = this;

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X200392;
            dialog2ButtonModel.attributes.Text = resources.X200394;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;

            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });

            var callback01 = function () {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
                $('.modal-backdrop').remove();
                self.sendPomodoroMail();
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(dialog2Button);

            
        }
    },
    sendPomodoroMail: function () {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X200378;
        dialogWaitingModel.attributes.Text = resources.X200392;
        dialogWaitingModel.attributes.Button = resources.X200025;

        var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: this });
        var callback = function () {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        self.app.dialogRegion2.show(dialogWaiting);

        var tmpModel = new Entities_Empty.Model();

        tmpModel.attributes.id = this.model.attributes.id;
        tmpModel.attributes.EncryptText01 = resources.X200366;
        tmpModel.attributes.EncryptText02 = resources.X200367;
        tmpModel.attributes.EncryptText03 = resources.X200368;
        tmpModel.attributes.EncryptText04 = resources.X200369;
        tmpModel.attributes.EncryptText05 = resources.X200370;
        tmpModel.attributes.EncryptText06 = resources.X200371;
        tmpModel.attributes.EncryptText07 = resources.X200364;


        self.model.save(tmpModel.attributes, {
            sendPomodoroMail: true,
            success: function () {
                //self.pushMessage();
                dialogWaiting.triggerMethod('dialog:close');


                /*self.collection.fullCollection.sort();
                self.collection.getFirstPage({ fetch: false });

                if (callbackMenu != undefined) {
                    callbackMenu();
                } else {*/
                $('.modal-backdrop').remove();
                //var url = 'customDepartmentEditItem/' + self.model.attributes.id;
                //self.app.router.navigate(url);
                //self.app.router.customDepartmentEditItem(self.model.attributes.id);
                //}
            }
        });
    },
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    getWeboptimizeImage: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            if (cropBoxData.width != undefined) {
                var aspectRatio = /*cropBoxData.height /*/ cropBoxData.width / cropBoxData.height;
                height = 76;
                width = height * aspectRatio;
                //height = width * aspectRatio;

                var croppedCanvas;
                var canvas;
                var image;

                // Crop
                croppedCanvas = this.cropper.getCroppedCanvas();

                // Round
                canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

                var struct = {
                    width: width,
                    height: height,
                    data: canvas.toDataURL('image/png', 0.5)
                }

                return struct;
            }
            return null;
        }
    },
    getImageData: function (tmpModel) {
        var searchString = ";base64,";
        var pos = 0;

        var image515 = this.getWeboptimizeImage(200, 76);

        if (image515 != null && this.localSetting.attributes.ImageModify == true) {
            var imageData512 = image515.data;
            pos = imageData512.indexOf(searchString);
            var imageMimeType512 = imageData512.substring(5, pos);
            imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            tmpModel.attributes.ImageFilename = 'Department';//resources.X200122;
            tmpModel.attributes.ImageData = imageData512;
        } else {
            tmpModel.attributes.ImageFilename = '';
            tmpModel.attributes.ImageData = '';
        }
    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    colorChange_BackgroundColor: function (e) {
        this.model.attributes.BackgroundColor = e.value.toRgbString();
    },
}, Backbone.Radio.Requests);