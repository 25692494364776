﻿'use strict'

import config from '../../common/config';
import language from '../../common/language';

export default (function () {
    var API = {
        schema_init: function (eventId, webapiurl, schemaModelId, schemaElement, schemaBigscreen, userLang, tables, model, app, callback) {
            var schemaModelUrl = webapiurl + '/api/publicCustomSchemaModel/' + schemaModelId;
            var schemaSchemaUrl = webapiurl + '/api/publicCustomSchemaSchema/-1?EventId=' + eventId;
            this.schemaModel = {};
            var self = this;

            $(schemaElement).empty();

            app.entities.models.publicCustomEventLive.fetch({
                silent: true,
                wait: true,
                eventId: eventId,
                success: function (mode, response) {
                    $.ajax({
                        type: "GET",
                        url: schemaModelUrl,
                        dataType: "json",
                        contentType: "application/json",
                        success: function (schemaModel) {

                            self.schemaModel = schemaModel;

                            $.ajax({
                                type: "GET",
                                url: schemaSchemaUrl,
                                dataType: "json",
                                contentType: "application/json",
                                success: function (schemaSchema) {
                                    self.schemaSchema = schemaSchema;

                                    var showSchema = '&ShowSchema=false';
                                    var showSchemaBigscreen = '&ShowSchemaBigscreen=false';

                                    var iDisplayLength = 25;
                                    var showHeader = true;
                                    var showSearchBar = true;

                                    if (schemaBigscreen) {
                                        var showSchema = '&ShowSchema=false';
                                        var showSchemaBigscreen = '&ShowSchemaBigscreen=true';

                                        if (app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenShowSearchBar == false) {
                                            iDisplayLength = 200;
                                        }

                                        showHeader = app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenShowHeader;
                                        showSearchBar = app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenShowSearchBar;


                                    } else {
                                        var showSchema = '&ShowSchema=true';
                                        var showSchemaBigscreen = '&ShowSchemaBigscreen=false';

                                        if (app.entities.models.publicCustomEventLive.attributes.SchemaShowSearchBar == false) {
                                            iDisplayLength = 200;
                                        }

                                        showHeader = app.entities.models.publicCustomEventLive.attributes.SchemaShowHeader;
                                        showSearchBar = app.entities.models.publicCustomEventLive.attributes.SchemaShowSearchBar;
                                    }

                                    var SchemaBigscreenQueryField = '&SchemaBigscreenQueryField=' + app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenQueryField;
                                    var SchemaBigscreenQueryValue = '&SchemaBigscreenQueryValue=' + encodeURIComponent(app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenQueryValue);
                                    var SchemaQueryField = '&SchemaQueryField=' + app.entities.models.publicCustomEventLive.attributes.SchemaQueryField;
                                    var SchemaQueryValue = '&SchemaQueryValue=' + encodeURIComponent(app.entities.models.publicCustomEventLive.attributes.SchemaQueryValue);

                                    
                                    callback();

                                    var url = webapiurl + '/api/publicCustomSchemaData/-1?EventId=' + eventId + '&Schema=' + 1 + showSchema + showSchemaBigscreen + SchemaBigscreenQueryField + SchemaBigscreenQueryValue + SchemaQueryField + SchemaQueryValue;
                                    $.ajax({
                                        type: "GET",
                                        url: url,
                                        dataType: "json",
                                        contentType: "application/json",
                                        success: function (data) {
                                            if (schemaSchema.length == 0) {
                                                $(schemaElement).append('<h2 style="text-align:center;">' + resources.X100343 + '</h2>');
                                                $('#spin').css('display', 'none');

                                                //if (schemaBigscreen) {
                                                //    document.title = resources.X100506;
                                                //}
                                            } else {
                                                var table_marginLeft = '10px';
                                                var table_marginRight = '10px';


                                                for (var schemaCounter = 0; schemaCounter < schemaSchema.length; schemaCounter++) {

                                                    if (schemaBigscreen) {
                                                        var event_eventName_record = app.entities.collections.text.where({ name: 'event_eventName' });
                                                        var event_eventName = event_eventName_record[0].attributes.value;
                                                        if (event_eventName.length > 0) {
                                                            document.title = event_eventName + ' - ' + resources.X100506 + ' - ' + schemaSchema[0].Name
                                                        } else {
                                                            document.title = resources['X100070'] + ' - ' + resources.X100506 + ' - ' + schemaSchema[0].Name;
                                                        }
                                                    }

                                                    var sElement = '';

                                                    sElement += '<thead>';
                                                    sElement += '<tr>';

                                                    sElement += '<th class="fontClass" style="width:0px;">' + 'position' + '</th>';

                                                    var fieldValue = '';
                                                    var fieldEventValue = true;
                                                    var fieldBigscreenValue = true;


                                                    for (var i = 0; i < self.schemaModel.FieldUsed; i++) {
                                                        switch (i) {
                                                            case 0: fieldValue = self.schemaModel.Field01; fieldEventValue = self.schemaModel.FieldEvent01; fieldBigscreenValue = self.schemaModel.FieldBigscreen01; break;
                                                            case 1: fieldValue = self.schemaModel.Field02; fieldEventValue = self.schemaModel.FieldEvent02; fieldBigscreenValue = self.schemaModel.FieldBigscreen02; break;
                                                            case 2: fieldValue = self.schemaModel.Field03; fieldEventValue = self.schemaModel.FieldEvent03; fieldBigscreenValue = self.schemaModel.FieldBigscreen03; break;
                                                            case 3: fieldValue = self.schemaModel.Field04; fieldEventValue = self.schemaModel.FieldEvent04; fieldBigscreenValue = self.schemaModel.FieldBigscreen04; break;
                                                            case 4: fieldValue = self.schemaModel.Field05; fieldEventValue = self.schemaModel.FieldEvent05; fieldBigscreenValue = self.schemaModel.FieldBigscreen05; break;
                                                            case 5: fieldValue = self.schemaModel.Field06; fieldEventValue = self.schemaModel.FieldEvent06; fieldBigscreenValue = self.schemaModel.FieldBigscreen06; break;
                                                            case 6: fieldValue = self.schemaModel.Field07; fieldEventValue = self.schemaModel.FieldEvent07; fieldBigscreenValue = self.schemaModel.FieldBigscreen07; break;
                                                            case 7: fieldValue = self.schemaModel.Field08; fieldEventValue = self.schemaModel.FieldEvent08; fieldBigscreenValue = self.schemaModel.FieldBigscreen08; break;
                                                            case 8: fieldValue = self.schemaModel.Field09; fieldEventValue = self.schemaModel.FieldEvent09; fieldBigscreenValue = self.schemaModel.FieldBigscreen09; break;
                                                            case 9: fieldValue = self.schemaModel.Field10; fieldEventValue = self.schemaModel.FieldEvent10; fieldBigscreenValue = self.schemaModel.FieldBigscreen10; break;
                                                            case 10: fieldValue = self.schemaModel.Field11; fieldEventValue = self.schemaModel.FieldEvent11; fieldBigscreenValue = self.schemaModel.FieldBigscreen11; break;
                                                            case 11: fieldValue = self.schemaModel.Field12; fieldEventValue = self.schemaModel.FieldEvent12; fieldBigscreenValue = self.schemaModel.FieldBigscreen12; break;
                                                        }

                                                        if (schemaBigscreen == true) {
                                                            if (fieldBigscreenValue) {
                                                                var style = String.format('style="padding-left:{0};padding-right:{1}"', table_marginLeft, table_marginRight)
                                                                sElement += '<th class="fontClass"' + style + '>' + fieldValue + '</th>';
                                                            }
                                                        }
                                                        else {
                                                            if (fieldEventValue) {
                                                                var style = String.format('style="padding-left:{0};padding-right:{1}"', table_marginLeft, table_marginRight)
                                                                sElement += '<th class="fontClass"' + style + '>' + fieldValue + '</th>';
                                                            }
                                                        }
                                                    }

                                                    sElement += '</tr>';
                                                    sElement += '</thead>';

                                                    sElement += '<tfoot>';
                                                    sElement += '<tr>';
                                                    sElement += '</tr>';
                                                    sElement += '</tfoot>';

                                                    sElement += '<tbody>';

                                                    $.each(data, function (key, value) {
                                                        if (value.Schema == schemaSchema[schemaCounter].id) {
                                                            sElement += '<tr>';

                                                            sElement += '<th class="fontClass" style="width:0px;">' + value.Position + '</th>';

                                                            var fieldValue = '';
                                                            var fieldBigscreenValue = true;
                                                            var fieldEventValue = true;

                                                            for (var i = 0; i < self.schemaModel.FieldUsed; i++) {
                                                                switch (i) {
                                                                    case 0: fieldValue = value.Field01; fieldEventValue = self.schemaModel.FieldEvent01; fieldBigscreenValue = self.schemaModel.FieldBigscreen01; break;
                                                                    case 1: fieldValue = value.Field02; fieldEventValue = self.schemaModel.FieldEvent02; fieldBigscreenValue = self.schemaModel.FieldBigscreen02; break;
                                                                    case 2: fieldValue = value.Field03; fieldEventValue = self.schemaModel.FieldEvent03; fieldBigscreenValue = self.schemaModel.FieldBigscreen03; break;
                                                                    case 3: fieldValue = value.Field04; fieldEventValue = self.schemaModel.FieldEvent04; fieldBigscreenValue = self.schemaModel.FieldBigscreen04; break;
                                                                    case 4: fieldValue = value.Field05; fieldEventValue = self.schemaModel.FieldEvent05; fieldBigscreenValue = self.schemaModel.FieldBigscreen05; break;
                                                                    case 5: fieldValue = value.Field06; fieldEventValue = self.schemaModel.FieldEvent06; fieldBigscreenValue = self.schemaModel.FieldBigscreen06; break;
                                                                    case 6: fieldValue = value.Field07; fieldEventValue = self.schemaModel.FieldEvent07; fieldBigscreenValue = self.schemaModel.FieldBigscreen07; break;
                                                                    case 7: fieldValue = value.Field08; fieldEventValue = self.schemaModel.FieldEvent08; fieldBigscreenValue = self.schemaModel.FieldBigscreen08; break;
                                                                    case 8: fieldValue = value.Field09; fieldEventValue = self.schemaModel.FieldEvent09; fieldBigscreenValue = self.schemaModel.FieldBigscreen09; break;
                                                                    case 9: fieldValue = value.Field10; fieldEventValue = self.schemaModel.FieldEvent10; fieldBigscreenValue = self.schemaModel.FieldBigscreen10; break;
                                                                    case 10: fieldValue = value.Field11; fieldEventValue = self.schemaModel.FieldEvent11; fieldBigscreenValue = self.schemaModel.FieldBigscreen11; break;
                                                                    case 11: fieldValue = value.Field12; fieldEventValue = self.schemaModel.FieldEvent12; fieldBigscreenValue = self.schemaModel.FieldBigscreen12; break;
                                                                }

                                                                if (schemaBigscreen == true) {
                                                                    if (fieldBigscreenValue) {
                                                                        var style = String.format('style="padding-left:{0};padding-right:{1}"', table_marginLeft, table_marginRight)
                                                                        sElement += '<th class="fontClass" ' + style + '>' + fieldValue + '</th>';
                                                                    }
                                                                }
                                                                else {
                                                                    if (fieldEventValue) {
                                                                        var style = String.format('style="padding-left:{0};padding-right:{1}"', table_marginLeft, table_marginRight)
                                                                        sElement += '<th class="fontClass" ' + style + '>' + fieldValue + '</th>';
                                                                    }
                                                                }
                                                            }
                                                            sElement += '</tr>';
                                                        }
                                                    });

                                                    sElement += '</tbody>';

                                                    if (showHeader) {
                                                        var font2_style = app.entities.collections.FontStyle.where({
                                                            Value: model.attributes.Data.Font2_Style
                                                        });

                                                        var style = font2_style[0].attributes.Style;

                                                        var css = String.format('margin-top:{0}px;margin-bottom:{1}px;margin-left:{2}px;margin-right:{3}px;font-family:{4};font-size:{5};color:{6};{7}',
                                                            model.attributes.Data.Margin2_Top,
                                                            model.attributes.Data.Margin2_Bottom,
                                                            model.attributes.Data.Margin2_Left,
                                                            model.attributes.Data.Margin2_Right,
                                                            model.attributes.Data.Font2,
                                                            model.attributes.Data.Font2_Size,
                                                            model.attributes.Data.Font2_Color,
                                                            style);

                                                        

                                                            
                                                        //$(schemaElement).append('<h2 id="schemaDataHeader' + schemaCounter + '" style="margin-top:0px; margin-bottom:5px;text-align:center;">' + schemaSchema[schemaCounter].Name + '</h2>');
                                                        $(schemaElement).append('<h2 id="schemaDataHeader' + schemaCounter + '" style="text-align:center;' + css + '">' + schemaSchema[schemaCounter].Name + '</h2>');
                                                    }
                                                    $(schemaElement).append('<div class="table-responsive"><table id="schemaData' + schemaCounter + '" class="table table-striped"  cellspacing="0" style="width:100%;"></table></div>');

                                                    $('#schemaData' + schemaCounter).html(sElement);

                                                    var lng = language.getLanguageDataTables();

                                                    $.fn.dataTable.TableTools.buttons.refresh = $.extend(
                                                        true,
                                                        {},
                                                        $.fn.dataTable.TableTools.buttonBase,
                                                        {
                                                            "sNewLine": "<br>",
                                                            "sButtonText": lng.sRefresh,
                                                            "target": "",//"#refresh" + schemaCounter,
                                                            "fnClick": function (button, conf) {
                                                                $('#spin').show();
                                                                self.schema_init(eventId, webapiurl, schemaModelId, schemaElement, schemaBigscreen, userLang, tables, model, app, callback);
                                                            }
                                                        }
                                                    );


                                                    var order = '';
                                                    var sortOrder = 'desc';

                                                    if (schemaBigscreen) {
                                                        if (app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenQuerySortField > -1) {
                                                            switch (app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenQuerySortValue) {
                                                                case 0: sortOrder = 'desc'; break;
                                                                case 1: sortOrder = 'asc'; break;
                                                            }
                                                            order = [[app.entities.models.publicCustomEventLive.attributes.SchemaBigscreenQuerySortField, sortOrder]]
                                                        }
                                                    } else {
                                                        if (app.entities.models.publicCustomEventLive.attributes.SchemaQuerySortField > -1) {
                                                            switch (app.entities.models.publicCustomEventLive.attributes.SchemaQuerySortValue) {
                                                                case 0: sortOrder = 'desc'; break;
                                                                case 1: sortOrder = 'asc'; break;
                                                            }
                                                            order = [[app.entities.models.publicCustomEventLive.attributes.SchemaQuerySortField, sortOrder]]
                                                        }
                                                    }


                                                    var table = $('#schemaData' + schemaCounter).DataTable({
                                                        "bAutoWidth": false,
                                                        "order": order,
                                                        "drawCallback": function (settings) {
                                                            $('.fontClass').css('font-family', model.attributes.Data.Font1);
                                                            $('.fontClass').css('font-size', model.attributes.Data.Font1_Size);
                                                            $('.fontClass').css('color', model.attributes.Data.Font1_Color);

                                                            var font_style = app.entities.collections.FontStyle.where({
                                                                Value: model.attributes.Data.Font1_Style
                                                            });
                                                            font_style[0].attributes.CssStyle.forEach(function (entry) {
                                                                $('.fontClass').css(entry.Css, entry.Value);
                                                            });
                                                        },
                                                        "rowCallback": function (row, data) {
                                                            if (row.className == 'even') {
                                                                $(row).css('background-color', model.attributes.Data.Even_Color);
                                                            }
                                                            else if (row.className == 'odd') {
                                                                $(row).css('background-color', model.attributes.Data.Odd_Color);
                                                            }
                                                        },
                                                        "language": lng,
                                                        "columnDefs": [{
                                                            "targets": [0],
                                                            "visible": false,
                                                            "searchable": false
                                                        }],
                                                        dom: 'T<"clear">lfrtip',
                                                        tableTools: {
                                                            "aButtons": [{
                                                                "sExtends": "refresh",
                                                                "sButtonText": lng.sRefresh,
                                                                "target": "#refresh" + schemaCounter
                                                            }]
                                                        },
                                                        "bInfo": false,
                                                        "iDisplayLength": iDisplayLength
                                                        //"oLanguage": {
                                                        //    "oPaginate": {
                                                        //        "sFirst": "<-", // This is the link to the first page
                                                        //        "sPrevious": "<<", // This is the link to the previous page
                                                        //        "sNext": ">>", // This is the link to the next page
                                                        //        "sLast": "<-" // This is the link to the last page

                                                        //    }
                                                        //}
                                                    });

                                                    

                                                    $('.DTTT_button').addClass('btn btn-secondary dataTables_btn_event4us');
                                                    $('.DTTT_button').css('margin-bottom', '5px');
                                                    $('.form-control.input-sm').css('width', '150px');
                                                    $('.dataTables_paginate').css('text-align', 'center');

                                                    $('.pagination').addClass('d-flex flex-wrap justify-content-center')

                                                    $('#spin').css('display', 'none');

                                                    //$('.btn-primary').removeClass('DTTT_button_text');
                                                    //$('.btn-primary').removeClass('DTTT_button');
                                                    //$('.table').removeClass('dataTable');

                                                    //$(":input").each(function (item) {
                                                    //    $(this).addClass('form-control');
                                                    //});

                                                    if (!showSearchBar) {
                                                        $('.dataTables_filter').css('display', 'none');
                                                        $('.dataTables_length').css('display', 'none');
                                                        $('.dataTables_btn_event4us').css('display', 'none');
                                                    }

                                                    tables.push(table);

                                                    //$('select[name=' + 'schemaData' + schemaCounter + '_length' + ']').css('height', '35px;');


                                                    $('.fontClass').css('family', model.attributes.Data.Font1);
                                                    $('.fontClass').css('font-size', model.attributes.Data.Font1_Size);
                                                    $('.fontClass').css('color', model.attributes.Data.Font1_Color);
                                                    var font_style = app.entities.collections.FontStyle.where({
                                                        Value: model.attributes.Data.Font1_Style
                                                    });
                                                    font_style[0].attributes.CssStyle.forEach(function (entry) {
                                                        $('.fontClass').css(entry.Css, entry.Value);
                                                    });

                                                    $('.dataTables_paginate').css('display', 'none');
                                                }
                                                //refresh column
                                                if (table) {
                                                    $(schemaElement).find('.dataTables_wrapper').css('margin-top', model.attributes.Data.Margin1_Top+ 'px');
                                                    $(schemaElement).find('.dataTables_wrapper').css('margin-bottom', model.attributes.Data.Margin1_Bottom + 'px');
                                                    $(schemaElement).find('.dataTables_wrapper').css('margin-left', model.attributes.Data.Margin1_Left + 'px');
                                                    $(schemaElement).find('.dataTables_wrapper').css('margin-right', model.attributes.Data.Margin1_Right + 'px');
                                                    table.columns.adjust().draw();
                                                }
                                            }
                                        },
                                        error: function () {
                                            $(schemaElement).append('<h2 style="text-align:center;">' + resources.X100343 + '</h2>');
                                            $('#spin').css('display', 'none');
                                        }
                                    });
                                }
                            });
                        }
                    });
                },
                error: function (mode, response) {
                }
            });
        }
    };

    return API;
})();



