'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaSchemaListItemHeader from './tpl/customSchemaSchemaListItemHeader.html';
import language from '../../../common/language';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customSchemaSchemaListItemHeader();
    }
});