﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventLive from './tpl/customEventLive.html';
import tpl_customEventLiveDesign from './tpl/customEventLiveDesign.html';

import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';

import config from '../../../common/config';
import Help from '../../helper/help';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customEventLiveDesign();
        } else {
            return tpl_customEventLive();
        }
    },
    regions: {
        information: "#customEventLiveInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.event });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100408;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.EventId = -1;

        this.setHasChanged(false);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick();
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customEventLive_btnBack_click();
        });	
    },
    signalR_init: function () {
        //this.IWannaChat = $.connection.LuxaforboardHub;
        //$.connection.hub.start();
    },
    help: function () {
        Help.help(this, true, 'customSchemaLive');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.save(callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        if (this.model) {
            this.setModel();

            if ((this.model.attributes.SchemaBigscreenShowHeader != this.oldModel.attributes.SchemaBigscreenShowHeader) ||
                (this.model.attributes.SchemaBigscreenShowSearchBar != this.oldModel.attributes.SchemaBigscreenShowSearchBar) ||
                (this.model.attributes.SchemaBigscreenQueryField != this.oldModel.attributes.SchemaBigscreenQueryField) ||
                (this.model.attributes.SchemaBigscreenQueryValue != this.oldModel.attributes.SchemaBigscreenQueryValue) ||
                (this.model.attributes.SchemaBigscreenRefreshInterval != this.oldModel.attributes.SchemaBigscreenRefreshInterval) ||
                (this.model.attributes.SchemaShowHeader != this.oldModel.attributes.SchemaShowHeader) ||
                (this.model.attributes.SchemaShowSearchBar != this.oldModel.attributes.SchemaShowSearchBar) ||
                (this.model.attributes.SchemaQueryField != this.oldModel.attributes.SchemaQueryField) ||
                (this.model.attributes.SchemaQueryValue != this.oldModel.attributes.SchemaQueryValue) ||
                (this.model.attributes.SchemaRefreshInterval != this.oldModel.attributes.SchemaRefreshInterval)) {
                changes = true;
            }
        }

        this.setHasChanged(changes);
    },
    ui: {
        'customEventLive_selectEvent': '#customEventLive_selectEvent',
        'customEventLive_btnSubmit': '#customEventLive_btnSubmit',
        'customEventLive_btnSubmit02': '#customEventLive_btnSubmit02',
        'customEventLive_input_SchemaBigscreenShowHeader': '#customEventLive_input_SchemaBigscreenShowHeader',
        'customEventLive_input_SchemaBigscreenShowSearchBar': '#customEventLive_input_SchemaBigscreenShowSearchBar',
        'customEventLive_select_SchemaBigscreenQueryField': '#customEventLive_select_SchemaBigscreenQueryField',
        'customEventLive_input_SchemaBigscreenQueryValue': '#customEventLive_input_SchemaBigscreenQueryValue',
        'customEventLive_select_SchemaBigscreenRefreshInterval': '#customEventLive_select_SchemaBigscreenRefreshInterval',
        'customEventLive_select_SchemaBigscreenQuerySortField': '#customEventLive_select_SchemaBigscreenQuerySortField',
        'customEventLive_select_SchemaBigscreenQuerySortValue': '#customEventLive_select_SchemaBigscreenQuerySortValue',
        'customEventLive_input_SchemaShowHeader': '#customEventLive_input_SchemaShowHeader',
        'customEventLive_input_SchemaShowSearchBar': '#customEventLive_input_SchemaShowSearchBar',
        'customEventLive_select_SchemaQueryField': '#customEventLive_select_SchemaQueryField',
        'customEventLive_input_SchemaQueryValue': '#customEventLive_input_SchemaQueryValue',
        'customEventLive_select_SchemaRefreshInterval': '#customEventLive_select_SchemaRefreshInterval',
        'customEventLive_select_SchemaQuerySortField': '#customEventLive_select_SchemaQuerySortField',
        'customEventLive_select_SchemaQuerySortValue': '#customEventLive_select_SchemaQuerySortValue',
        'customEventLive_div_anySchema': '#customEventLive_div_anySchema',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customEventLive_inputNameGroup': '#customEventLive_inputNameGroup',
        'customEventLive_btnBack': '#customEventLive_btnBack'
    },
    events: {
        'click @ui.customEventLive_btnSubmit': 'btnSubmitClick',
        'click @ui.customEventLive_btnSubmit02': 'btnSubmitClick',
        'change @ui.customEventLive_selectEvent': 'selectEventChange',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customEventLive_btnBack	': 'customEventLive_btnBack_click'
    },
    onRender: function () {
        this.setupEvent();
        this.renderInformation();

        if (this.app.mode == 1) {
            this.ui.customEventLive_inputNameGroup.css('display', 'none');
            this.ui.customEventLive_btnSubmit.css('display', 'none');
            this.ui.customEventLive_btnSubmit02.css('display', 'none');
            this.ui.customEventLive_btnBack.css('display', 'none');
        }

        return this;
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }

        //Any schema model selected on events
        var hasItems = false;
        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.SchemaModel != -1) {
                hasItems = true;
            }
        });

        //No schema selectes on events
        if (!hasItems) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100341 });
            this.informationCollection.add(tmpModel);
        }

        //No schema page enable on subscribtions
        if (this.app.entities.models.configSubscription[0].attributes.EnableSchema == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100095 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        if (this.localSetting.attributes.EventId != -1) {
            var tmp = { "id": this.localSetting.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        //if (this.options.event != undefined && this.options.event != -1) {
        //    this.ui.customEventLive_selectEvent.val(this.options.event.toString());
        //}
    },

    setupEvent: function () {
        this.ui.customEventLive_selectEvent.empty();
        var self = this;

        this.localSetting.attributes.EventId = -1;


        //Any schema model selected on events
        var hasItems = false;
        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.SchemaModel != -1) {
                hasItems = true;
            }
        });

        if (hasItems) {
            this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
                if (entry.attributes.SchemaModel != -1) {
                    //Select the first only
                    /*if (self.localSetting.attributes.EventId == -1) {
                        self.ui.customEventLive_selectEvent.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
                        self.localSetting.attributes.EventId = entry.attributes.id;
                    } else {*/
                    self.ui.customEventLive_selectEvent.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
                    //}
                }
            });

            if (this.options.event != undefined && this.options.event != -1) {
                this.ui.customEventLive_selectEvent.val(this.options.event.toString());
                this.localSetting.attributes.EventId = parseInt(this.options.event.toString());
            }

            if (this.localSetting.attributes.EventId != -1) {
                this.setupSchema(this.localSetting.attributes.EventId);
            }
        } else {
            this.ui.customEventLive_div_anySchema.css('display', 'none');
        }

    },
    setupSchema() {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ id: this.localSetting.attributes.EventId });
        var schemaModel = this.app.entities.collections.customSchemaModel.fullCollection.where({ id: customEvent[0].attributes.SchemaModel });
        var models = this.collection.where({ EventId: this.localSetting.attributes.EventId });

        if (models.length == 1) {
            this.model = models[0];
            this.oldModel = this.model.clone();
        }

        this.setupSchemaBigscreen(schemaModel[0]);
        this.setupSchemaSchema(schemaModel[0]);
    },
    setupSchemaBigscreen: function (schemaModel) {
        var SchemaBigscreenShowHeader = this.model.attributes.SchemaBigscreenShowHeader ? 'checked' : '';
        var SchemaBigscreenShowSearchBar = this.model.attributes.SchemaBigscreenShowSearchBar ? 'checked' : '';
        this.ui.customEventLive_input_SchemaBigscreenShowHeader.prop('checked', SchemaBigscreenShowHeader);
        this.ui.customEventLive_input_SchemaBigscreenShowSearchBar.prop('checked', SchemaBigscreenShowSearchBar);

        this.ui.customEventLive_input_SchemaBigscreenQueryValue.val(this.model.attributes.SchemaBigscreenQueryValue);

        var element = this.ui.customEventLive_select_SchemaBigscreenQueryField;
        element.empty();

        if (schemaModel) {
            var title = '';
            var value = '0';
            //0 No selection
            for (var i = 0; i < schemaModel.attributes.FieldUsed + 1; i++) {
                switch (i) {
                    case 0: title = resources.X100292; value = '-1'; break;
                    case 1: title = schemaModel.attributes.Field01; value = '1'; break;
                    case 2: title = schemaModel.attributes.Field02; value = '2'; break;
                    case 3: title = schemaModel.attributes.Field03; value = '3'; break;
                    case 4: title = schemaModel.attributes.Field04; value = '4'; break;
                    case 5: title = schemaModel.attributes.Field05; value = '5'; break;
                    case 6: title = schemaModel.attributes.Field06; value = '6'; break;
                    case 7: title = schemaModel.attributes.Field07; value = '7'; break;
                    case 8: title = schemaModel.attributes.Field08; value = '8'; break;
                    case 9: title = schemaModel.attributes.Field09; value = '9'; break;
                    case 10: title = schemaModel.attributes.Field10; value = '10'; break;
                    case 11: title = schemaModel.attributes.Field11; value = '11'; break;
                    case 12: title = schemaModel.attributes.Field12; value = '12'; break;
                }

                if (this.model.attributes.SchemaBigscreenQueryField == i) {
                    element.append('<option value="' + value + '" selected="selected" >' + title + '</option>');
                } else {
                    element.append('<option value="' + value + '" >' + title + '</option>');
                }
            }
        } else {
            element.append('<option value="-1" selected="selected" >' + resources.X100292 + '</option>');
        }

        this.ui.customEventLive_select_SchemaBigscreenRefreshInterval.val(this.model.attributes.SchemaBigscreenRefreshInterval);

        var element = this.ui.customEventLive_select_SchemaBigscreenQuerySortField;
        element.empty();

        if (schemaModel) {
            var title = '';
            var value = '0';
            //0 No selection
            for (var i = 0; i < schemaModel.attributes.FieldUsed + 1; i++) {
                switch (i) {
                    case 0: title = resources.X100292; value = '-1'; break;
                    case 1: title = schemaModel.attributes.Field01; value = '1'; break;
                    case 2: title = schemaModel.attributes.Field02; value = '2'; break;
                    case 3: title = schemaModel.attributes.Field03; value = '3'; break;
                    case 4: title = schemaModel.attributes.Field04; value = '4'; break;
                    case 5: title = schemaModel.attributes.Field05; value = '5'; break;
                    case 6: title = schemaModel.attributes.Field06; value = '6'; break;
                    case 7: title = schemaModel.attributes.Field07; value = '7'; break;
                    case 8: title = schemaModel.attributes.Field08; value = '8'; break;
                    case 9: title = schemaModel.attributes.Field09; value = '9'; break;
                    case 10: title = schemaModel.attributes.Field10; value = '10'; break;
                    case 11: title = schemaModel.attributes.Field11; value = '11'; break;
                    case 12: title = schemaModel.attributes.Field12; value = '12'; break;
                }

                if (this.model.attributes.SchemaBigscreenQuerySortField == i) {
                    element.append('<option value="' + value + '" selected="selected" >' + title + '</option>');
                } else {
                    element.append('<option value="' + value + '" >' + title + '</option>');
                }
            }
        } else {
            element.append('<option value="-1" selected="selected" >' + resources.X100292 + '</option>');
        }

        this.ui.customEventLive_select_SchemaBigscreenQuerySortValue.val(this.model.attributes.SchemaBigscreenQuerySortValue);
    },
    setupSchemaSchema: function (schemaModel) {
        var SchemaShowHeader = this.model.attributes.SchemaShowHeader ? 'checked' : '';
        var SchemaShowSearchBar = this.model.attributes.SchemaShowSearchBar ? 'checked' : '';

        this.ui.customEventLive_input_SchemaShowHeader.prop('checked', SchemaShowHeader);
        this.ui.customEventLive_input_SchemaShowSearchBar.prop('checked', SchemaShowSearchBar);
        this.ui.customEventLive_input_SchemaQueryValue.val(this.model.attributes.SchemaQueryValue);

        var element = this.ui.customEventLive_select_SchemaQueryField;
        element.empty();

        if (schemaModel) {
            var title = '';
            var value = '0';
            //0 No selection
            for (var i = 0; i < schemaModel.attributes.FieldUsed + 1; i++) {
                switch (i) {
                    case 0: title = resources.X100292; value = '-1'; break;
                    case 1: title = schemaModel.attributes.Field01; value = '1'; break;
                    case 2: title = schemaModel.attributes.Field02; value = '2'; break;
                    case 3: title = schemaModel.attributes.Field03; value = '3'; break;
                    case 4: title = schemaModel.attributes.Field04; value = '4'; break;
                    case 5: title = schemaModel.attributes.Field05; value = '5'; break;
                    case 6: title = schemaModel.attributes.Field06; value = '6'; break;
                    case 7: title = schemaModel.attributes.Field07; value = '7'; break;
                    case 8: title = schemaModel.attributes.Field08; value = '8'; break;
                    case 9: title = schemaModel.attributes.Field09; value = '9'; break;
                    case 10: title = schemaModel.attributes.Field10; value = '10'; break;
                    case 11: title = schemaModel.attributes.Field11; value = '11'; break;
                    case 12: title = schemaModel.attributes.Field12; value = '12'; break;
                }

                if (this.model.attributes.SchemaQueryField == i) {
                    element.append('<option value="' + value + '" selected="selected" >' + title + '</option>');
                } else {
                    element.append('<option value="' + value + '" >' + title + '</option>');
                }
            }
        } else {
            element.append('<option value="-1" selected="selected" >' + resources.X100292 + '</option>');
        }
        this.ui.customEventLive_select_SchemaRefreshInterval.val(this.model.attributes.SchemaRefreshInterval);

        var element = this.ui.customEventLive_select_SchemaQuerySortField;
        element.empty();

        if (schemaModel) {
            var title = '';
            var value = '0';
            //0 No selection
            for (var i = 0; i < schemaModel.attributes.FieldUsed + 1; i++) {
                switch (i) {
                    case 0: title = resources.X100292; value = '-1'; break;
                    case 1: title = schemaModel.attributes.Field01; value = '1'; break;
                    case 2: title = schemaModel.attributes.Field02; value = '2'; break;
                    case 3: title = schemaModel.attributes.Field03; value = '3'; break;
                    case 4: title = schemaModel.attributes.Field04; value = '4'; break;
                    case 5: title = schemaModel.attributes.Field05; value = '5'; break;
                    case 6: title = schemaModel.attributes.Field06; value = '6'; break;
                    case 7: title = schemaModel.attributes.Field07; value = '7'; break;
                    case 8: title = schemaModel.attributes.Field08; value = '8'; break;
                    case 9: title = schemaModel.attributes.Field09; value = '9'; break;
                    case 10: title = schemaModel.attributes.Field10; value = '10'; break;
                    case 11: title = schemaModel.attributes.Field11; value = '11'; break;
                    case 12: title = schemaModel.attributes.Field12; value = '12'; break;
                }

                if (this.model.attributes.SchemaQuerySortField == i) {
                    element.append('<option value="' + value + '" selected="selected" >' + title + '</option>');
                } else {
                    element.append('<option value="' + value + '" >' + title + '</option>');
                }
            }
        } else {
            element.append('<option value="-1" selected="selected" >' + resources.X100292 + '</option>');
        }
        this.ui.customEventLive_select_SchemaQuerySortValue.val(this.model.attributes.SchemaQuerySortValue);
    },
    selectEventChange: function (event) {
        event.preventDefault();

        var self = this;
        var callback = function () {
            self.localSetting.attributes.EventId = parseInt(self.ui.customEventLive_selectEvent.val());
            self.setupSchema();
            self.customViewEvent_setActive();
        }

        this.saveIntern(callback);
    },
    setModel: function () {
        var SchemaBigscreenShowHeader = false;
        this.ui.customEventLive_input_SchemaBigscreenShowHeader.is(':checked') ? SchemaBigscreenShowHeader = true : SchemaBigscreenShowHeader = false;
        var SchemaBigscreenShowSearchBar = false;
        this.ui.customEventLive_input_SchemaBigscreenShowSearchBar.is(':checked') ? SchemaBigscreenShowSearchBar = true : SchemaBigscreenShowSearchBar = false;


        var SchemaShowHeader = false;
        this.ui.customEventLive_input_SchemaShowHeader.is(':checked') ? SchemaShowHeader = true : SchemaShowHeader = false;
        var SchemaShowSearchBar = false;
        this.ui.customEventLive_input_SchemaShowSearchBar.is(':checked') ? SchemaShowSearchBar = true : SchemaShowSearchBar = false;

        this.model.set({
            SchemaBigscreenShowHeader: SchemaBigscreenShowHeader,
            SchemaBigscreenShowSearchBar: SchemaBigscreenShowSearchBar,
            SchemaBigscreenQueryField: parseInt(this.ui.customEventLive_select_SchemaBigscreenQueryField.val()),
            SchemaBigscreenQueryValue: this.ui.customEventLive_input_SchemaBigscreenQueryValue.val(),
            SchemaBigscreenRefreshInterval: parseInt(this.ui.customEventLive_select_SchemaBigscreenRefreshInterval.val()),
            SchemaBigscreenQuerySortField: parseInt(this.ui.customEventLive_select_SchemaBigscreenQuerySortField.val()),
            SchemaBigscreenQuerySortValue: parseInt(this.ui.customEventLive_select_SchemaBigscreenQuerySortValue.val()),
            SchemaShowHeader: SchemaShowHeader,
            SchemaShowSearchBar: SchemaShowSearchBar,
            SchemaQueryField: parseInt(this.ui.customEventLive_select_SchemaQueryField.val()),
            SchemaQueryValue: this.ui.customEventLive_input_SchemaQueryValue.val(),
            SchemaRefreshInterval: parseInt(this.ui.customEventLive_select_SchemaRefreshInterval.val()),
            SchemaQuerySortField: parseInt(this.ui.customEventLive_select_SchemaQuerySortField.val()),
            SchemaQuerySortValue: parseInt(this.ui.customEventLive_select_SchemaQuerySortValue.val()),
        }, { silent: true });
    },
    saveIntern: function (callback) {
        this.modelHasChanged();

        if (this.getHasChanged()) {
            var self = this;

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = resources.X100395;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                dialog2Button.triggerMethod('dialog:close');
                self.saveHasChanged(callback);
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                self.cancelHasChanged(false);
                callback();
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);
        } else {
            callback();
        }
    },
    save: function (callbackRouter) {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100408;
        dialogWaitingModel.attributes.Text = resources.X100409;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        this.setModel();

        this.model.save(this.model.attributes, {
            silent: true,
            wait: true,
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
                self.oldModel = self.model.clone();

                if (callbackRouter) {
                    callbackRouter();
                }
            }
        });
    },
    btnSubmitClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.save();
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    },
    customEventLive_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.event);
        this.app.router.customEventEditItem(this.options.event, this.options.scroll, 3);
    }
}, Backbone.Radio.Requests);
