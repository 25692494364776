﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

require('typeahead-addresspicker');

import helper_infopage from '../../../helper/infopage';

import tpl_weather from './tpl/weather.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#weather_modal',
    errors: [],
    getTemplate: function () {
        return tpl_weather({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'weather_dropDown_Template': '#weather_dropDown_Template',
        'weather_dropDown_Forecast': '#weather_dropDown_Forecast',
        'weather_dropDown_Unit': '#weather_dropDown_Unit',
        'weather_dropDown_TextType': '#weather_dropDown_TextType',
        'weather_checkboxEnable24Hours': '#weather_checkboxEnable24Hours',
        'weather_inputPubliclyAddress': '#weather_inputPubliclyAddress',
        'weather_input_Header': '#weather_input_Header',
        'weather_alertWarningWrongAddress': '#weather_alertWarningWrongAddress',
        'weather_ColorPickerSliders_BgColor': '#weather_ColorPickerSliders_BgColor',
        'weather_select_Font1_ColorType': '#weather_select_Font1_ColorType',
        'weather_ColorPickerSliders_Font1_Color': '#weather_ColorPickerSliders_Font1_Color',
        'weather_dropDown_Font1_FontType': '#weather_dropDown_Font1_FontType',
        'weather_dropDown_Font1_Size_Header': '#weather_dropDown_Font1_Size_Header',
        'weather_dropDown_Font1_Size_Dec1': '#weather_dropDown_Font1_Size_Dec1',
        'weather_dropDown_Font1_Size_Dec2': '#weather_dropDown_Font1_Size_Dec2',
        'weather_dropDown_Font1_Size_Detail': '#weather_dropDown_Font1_Size_Detail',
        'weather_dropDown_Font1_Size_Forecast': '#weather_dropDown_Font1_Size_Forecast',
        'weather_select_Font1_FontType': '#weather_select_Font1_FontType',
        'weather_dropDown_Font1': '#weather_dropDown_Font1',
        'weather_formgroup_Font1': '#weather_formgroup_Font1',
        'weather': '#weather',
        'weather_dropDown_Font1_Style': '#weather_dropDown_Font1_Style',
        'weather_dropDown_Font1_TextAlign': '#weather_dropDown_Font1_TextAlign',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right',

        'weather_btn01': '#weather_btn01',
        'weather_btn02': '#weather_btn02'
    },
    events: {
        'change @ui.weather_select_Font1_FontType': 'change_Font1_FontType',
        'change @ui.weather_select_Font1_ColorType': 'change_Font1_ColorType',
        'colorpickerChange @ui.weather_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.weather_ColorPickerSliders_BgColor': 'colorChange_BgColor',
        'click @ui.weather_btn01': 'onBtn01',
        'click @ui.weather_btn02': 'onBtn02'
    },
    onRender: function () {
        this.ui.weather_inputPubliclyAddress.val(this.model.attributes.Address);
    },
    showErrorsAddress: function () {
        this.ui.weather_alertWarningWrongAddress.removeClass('hidden');
        this.ui.weather_alertWarningWrongAddress.addClass('show');
    },
    hideErrorsAddress: function () {
        this.ui.weather_alertWarningWrongAddress.removeClass('show');
        this.ui.weather_alertWarningWrongAddress.addClass('hidden');

    },
    _parseGeocodeResult: function (geocodeResult) {
        this._addressParts = {
            street_number: null, route: null, locality: null, sublocality: null,
            administrative_area_level_3: null, administrative_area_level_2: null,
            administrative_area_level_1: null, country: null, postal_code: null, type: null
        };

        this._parseLatAndLng = function (location) {
            var longitude, latitude;

            if (typeof (location.lat) === 'function') {
                latitude = location.lat();
                longitude = location.lng();
            } else {
                latitude = location.lat;
                longitude = location.lng;
            }

            return { lat: latitude, lng: longitude };
        };

        this._findInfo = function (result, type) {
            for (var i = 0; i < result.address_components.length; i++) {
                var component = result.address_components[i];
                if (component.types.indexOf(type) != -1) {
                    return component.long_name;
                }
            }
            return false;
        };

        var parsed = this._parseLatAndLng(geocodeResult.geometry.location);

        for (var addressPart in this._addressParts) {
            parsed[addressPart] = this._findInfo(geocodeResult, addressPart);
        }

        parsed.type = geocodeResult.types[0];

        return parsed;
    },
    onDestroy() {
        $(this.addressPicker).unbind();
    },
    onAttach() {
        var self = this;


        this.addressPicker = new AddressPicker();
        this.ui.weather_inputPubliclyAddress.typeahead(null, {
            displayKey: 'description',
            source: self.addressPicker.ttAdapter()
        });

        this.addressPicker.bindDefaultTypeaheadEvent(this.ui.weather_inputPubliclyAddress);
        $(this.addressPicker).on('addresspicker:selected', function (event, parsedGeocodeResult) {
            var _parsedGeocodeResult = self._parseGeocodeResult(parsedGeocodeResult.placeResult);

            if ((_parsedGeocodeResult.lat == 0) && (_parsedGeocodeResult.lng == 0)) {
                self.showErrorsAddress();
            }
            else {
                self.hideErrorsAddress();

                self.model.attributes.Lat = _parsedGeocodeResult.lat;
                self.model.attributes.Lon = _parsedGeocodeResult.lng;
                self.model.attributes.Address = self.ui.weather_inputPubliclyAddress.val();

                /*if (_parsedGeocodeResult.lat != false) {
                    self.model.Lat = parsedGeocodeResult.lat;
                }
                if (_parsedGeocodeResult.lng != false) {
                    self.model.Lng = parsedGeocodeResult.lng;
                }*/

                //self.setModel(_parsedGeocodeResult);
            }
        });

        this.setupEnableType();
        this.setupTemplate();
        this.setupForecast();
        this.setupUnit();
        this.setupFontColorType();
        this.setupFontType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupFontTextAlign();
    },
    setupEnableType: function () {
        var checked = this.model.attributes.Enable24Hours == 1 ? true : false;
        this.ui.weather_checkboxEnable24Hours.attr('checked', checked);
    },
    setupTemplate: function () {
        var self = this;

        this.ui.weather_dropDown_Template.empty();

        var selected = '';

        self.app.entities.collections.WeatherTemplate.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Template == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.weather_dropDown_Template.append(options);
        });
    },
    setupForecast: function () {
        var self = this;

        this.ui.weather_dropDown_Forecast.empty();

        var selected = '';

        self.app.entities.collections.WeatherForecast.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Forecast == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.weather_dropDown_Forecast.append(options);
        });
    },
    setupUnit: function () {
        var self = this;

        this.ui.weather_dropDown_Unit.empty();

        var selected = '';

        self.app.entities.collections.WeatherUnit.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Unit == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.weather_dropDown_Unit.append(options);
        });
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.weather_select_Font1_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.weather_select_Font1_ColorType.append(options);
        });
    },
    setupFontType: function () {
        var self = this;

        self.ui.weather_select_Font1_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.weather_select_Font1_FontType.append(options);
        });
    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.cp_Font1_Color = this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.weather_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
        });
        this.ui.weather_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.weather_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.weather_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.weather_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.weather_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }


        this.ui.weather_ColorPickerSliders_BgColor.colorpicker({
            color: self.model.attributes.BgColor,
            format: "rgb"
        });
    },
    setupFontSize: function () {
        var self = this;

        this.ui.weather_dropDown_Font1_Size_Header.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size_Header == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.weather_dropDown_Font1_Size_Header.append(options);
        }

        this.ui.weather_dropDown_Font1_Size_Dec1.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size_Dec1 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.weather_dropDown_Font1_Size_Dec1.append(options);
        }

        this.ui.weather_dropDown_Font1_Size_Dec2.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size_Dec2 == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.weather_dropDown_Font1_Size_Dec2.append(options);
        }

        this.ui.weather_dropDown_Font1_Size_Detail.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size_Detail == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.weather_dropDown_Font1_Size_Detail.append(options);
        }

        this.ui.weather_dropDown_Font1_Size_Forecast.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size_Forecast == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.weather_dropDown_Font1_Size_Forecast.append(options);
        }

        
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.weather_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.weather_dropDown_Font1_Style.append(options);
        });
    },
    setupFontTextAlign: function () {
        //var self = this;

        //self.ui.weather_dropDown_Font1_TextAlign.empty();

        //var selected = '';

        //self.app.entities.collections.TextAlign.forEach(function (entry) {
        //    selected = '';
        //    if (self.model.attributes.Font1_TextAlign == entry.attributes.Value) {
        //        selected = 'selected = "selected"';
        //    }
        //    var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
        //    self.ui.weather_dropDown_Font1_TextAlign.append(options);
        //});

        //self.ui.weather_dropDown_Font2_TextAlign.empty();

        //selected = '';

        //self.app.entities.collections.TextAlign.forEach(function (entry) {
        //    selected = '';
        //    if (self.model.attributes.Font2_TextAlign == entry.attributes.Value) {
        //        selected = 'selected = "selected"';
        //    }
        //    var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
        //    self.ui.weather_dropDown_Font2_TextAlign.append(options);
        //});
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    change_Font1_FontType: function () {
        this.model.set({
            Font1_FontType: this.ui.weather_select_Font1_FontType.val()
        });

        this.model.set({
            Font1: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font1_FontType, this.model.attributes.Font1_Select)
        });

        this.ui.weather_dropDown_Font1.trigger('setFont', this.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.weather_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.weather_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.weather_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.weather_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

    },
    change_Font1_ColorType: function () {
        this.model.set({
            Font1_ColorType: this.ui.weather_select_Font1_ColorType.val()
        });

        this.model.set({
            Font1_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font1_ColorType, this.model.attributes.Font1_ColorSelect)
        });

        this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable();
        this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font1_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.weather_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }
    },
    colorChange_BgColor: function (e) {
        this.model.attributes.BgColor = e.value.toRgbString();
    },
    setModel: function () {

        var Enable24Hours = this.model.attributes.Enable24Hours;
        this.ui.weather_checkboxEnable24Hours.is(':checked') ? Enable24Hours = 1 : Enable24Hours = 0;

        this.model.set({
            Template: this.ui.weather_dropDown_Template.val(),
            Forecast: this.ui.weather_dropDown_Forecast.val(),
            Unit: this.ui.weather_dropDown_Unit.val(),
            Enable24Hours: Enable24Hours,
            Header : this.ui.weather_input_Header.val(),
            Font1_ColorType: this.ui.weather_select_Font1_ColorType.val(),
            Font1_FontType: this.ui.weather_select_Font1_FontType.val(),
            Font1_Font: this.ui.weather_dropDown_Font1.val(),
            Font1_Size_Header: this.ui.weather_dropDown_Font1_Size_Header.val(),
            Font1_Size_Dec1: this.ui.weather_dropDown_Font1_Size_Dec1.val(),
            Font1_Size_Dec2: this.ui.weather_dropDown_Font1_Size_Dec2.val(),
            Font1_Size_Detail: this.ui.weather_dropDown_Font1_Size_Detail.val(),
            Font1_Size_Forecast: this.ui.weather_dropDown_Font1_Size_Forecast.val(),
            Font1_Style: this.ui.weather_dropDown_Font1_Style.val(),
            Font1_TextAlign: this.ui.weather_dropDown_Font1_TextAlign.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    }
});