﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customSchemaData from './tpl/customSchemaData.html';
import tpl_customSchemaDataDesign from './tpl/customSchemaDataDesign.html';

import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomSchemaDataImport from './customSchemaDataImport';
import View_CustomSchemaSchema from './customSchemaSchema';
import View_CustomSchemaDataInsert from './customSchemaDataInsert';
import View_CustomSchemaDataList from './customSchemaDataList';
import config from '../../../common/config';
import Help from '../../helper/help';

import View_CustomHeader from '../region/customHeader/customHeader';

export default Marionette.LayoutView.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customSchemaDataDesign();
        } else {
            return tpl_customSchemaData();
        }
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;
        this.settingModel = this.options.settingModel;

        this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_add', this.customSchemaSchema_add);
        this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_remove', this.customSchemaSchema_remove);
        this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_edit', this.customSchemaSchema_edit);

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customSchemaData:setHasChanged', function (enable) {
            self.setHasChanged(enable);
        });

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            //self.btnSubmitClick();
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customSchemaData_btnBack_click();
        });	
    },
    help: function () {
        Help.help(this, true, 'customSchemaData');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customSchemaDataList:save', callback);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },
    ui: {
        'customSchemaData_select_SchemaSchema': '#customSchemaData_select_SchemaSchema',
        'customSchemaData_selectEvent': '#customSchemaData_selectEvent',
        'customSchemaData_btnDeleteAllSubmit': '#customSchemaData_btnDeleteAllSubmit',
        'customSchemaData_btnExport': '#customSchemaData_btnExport',
        'customSchemaData_btnWebpageSchemaBigscreen': '#customSchemaData_btnWebpageSchemaBigscreen',
        'customSchemaData_btnWebpageSchemaApp': '#customSchemaData_btnWebpageSchemaApp',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customSchemaData_inputNameGroup': '#customSchemaData_inputNameGroup',
        'customSchemaData_btnBack': '#customSchemaData_btnBack'
      
    },
    events: {
        'click @ui.customSchemaData_btnDeleteAllSubmit': 'btnDeleteAllSubmit',
        'change @ui.customSchemaData_selectEvent': 'selectEventChange',
        'change @ui.customSchemaData_select_SchemaSchema': 'selectSchemaSchemaChange',
        'click @ui.customSchemaData_btnWebpageSchemaBigscreen': 'btnWebpageSchemaBigscreen',
        'click @ui.customSchemaData_btnWebpageSchemaApp': 'btnWebpageSchemaApp',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customSchemaData_btnBack	': 'customSchemaData_btnBack_click'
    },
    childEvents: {
        'table_addModel': function (childView, msg) {
            this.customSchemaDataList.currentView.table_addModel(msg.tmpModel, msg.jumpToData, msg.jumpFirst, msg.ignoreEmptyContent);
        },
        'table_getFirstVisibleItem': function (childView, msg) {
            this.customSchemaDataList.currentView.table_getFirstVisibleItem(msg);
        },
        'table_readDataCollection': function (childView, msg) {
            this.readDataCollection(/*this*/childView.settingModel.attributes.EventId);
        },

    },
    regions: {
        customSchemaDataImport: "#customSchemaDataImport",
        customSchemaSchema: "#customSchemaSchema",
        customSchemaDataInsert: "#customSchemaDataInsert",
        customSchemaDataList: "#customSchemaDataList",
        information: "#customSchemaDataInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: parseInt(this.$el.find('#customSchemaData_selectEvent').val()) });

        if (eventModel.length > 0) {

            if (eventModel[0].attributes.SchemaModel != -1) {
                var schemaModel = this.app.entities.collections.customSchemaModel.fullCollection.where({ id: eventModel[0].attributes.SchemaModel });
                if (schemaModel.length > 0) {
                    this.schemaModel = schemaModel[0];

                    this.setupSchemaSchema(true);

                    this.customSchemaDataImport.show(new View_CustomSchemaDataImport({ collection: this.collection, schemaModel: this.schemaModel, settingModel: this.settingModel, app: this.app }));
                    this.customSchemaSchema.show(new View_CustomSchemaSchema({ collection: this.app.entities.collections.customSchemaSchema, schemaModel: this.schemaModel, settingModel: this.settingModel, app: this.app }));
                    this.customSchemaDataInsert.show(new View_CustomSchemaDataInsert({ collection: this.collection, schemaModel: this.schemaModel, settingModel: this.settingModel, app: this.app }));
                    this.customSchemaDataList.show(new View_CustomSchemaDataList({ collection: this.app.entities.collections.customSchemaData, schemaModel: this.schemaModel, settingModel: this.settingModel, app: this.app }));

                    this.readDataCollection(this.settingModel.attributes.EventId);
                }
            }
        }

        this.renderInformation();

        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.event });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100249;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    customSchemaSchema_add: function (tmpModel) {
        var self = this;
        var callback = function () {
            self.settingModel.attributes.EventId = tmpModel.attributes.EventId; //FUSK
            self.settingModel.attributes.SchemaSchemaId = parseInt(tmpModel.attributes.id);
            self.setupSchemaSchema(false);
            self.setModelUI('SchemaSchemaId');

            //self.updateInformationCollection();
            self.setupDeleteAllBtn();

            self.readDataCollection(self.settingModel.attributes.EventId);
        }

        this.save(callback);
    },
    customSchemaSchema_remove: function (tmpModel) {
        this.settingModel.attributes.EventId = tmpModel.EventId; //FUSK

        this.setupSchemaSchema(false);

        if (tmpModel.id == this.settingModel.attributes.SchemaSchemaId) {
            this.setModelFirst('SchemaSchemaId');
        }

        //this.updateInformationCollection();
        this.setupDeleteAllBtn();

        this.readDataCollection(this.settingModel.attributes.EventId);
    },
    customSchemaSchema_edit: function () {
        this.settingModel.attributes.EventId = tmpModel.attributes.EventId; //FUSK

        this.setupSchemaSchema(false);
        this.setModelUI('SchemaSchemaId');

        //this.updateInformationCollection();

        this.readDataCollection(this.settingModel.attributes.EventId);
    },
    onRender: function () {
        this.setupEvent(true);
        this.setupDeleteAllBtn();

        this.setupExport();

        if (this.app.mode == 1) {
            this.ui.customSchemaData_inputNameGroup.css('display', 'none');
            this.ui.customSchemaData_btnBack.css('display', 'none');
        }
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.app.entities.collections.customEvent.fullCollection.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }

        //Any schema model selected on events
        var hasItems = false;
        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.SchemaModel != -1) {
                hasItems = true;
            }
        });

        //No schema selectes on events
        if (!hasItems) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100341 });
            this.informationCollection.add(tmpModel);
        }

        //No schema selected
        if ((!this.settingModel.attributes.SchemaSchemaId) || (this.settingModel.attributes.SchemaSchemaId < 1)) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100342 });
            this.informationCollection.add(tmpModel);
        }

        //No data on the events schema
        if (this.collection.length == 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100343 });
            this.informationCollection.add(tmpModel);
        }

        //Schema not enable in config subscription, only display this message
        if (this.app.entities.models.configSubscription[0].attributes.EnableSchemaModel == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100129 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        if (this.settingModel.attributes.EventId != -1) {
            var tmp = { "id": this.settingModel.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        //if (this.options.event != undefined && this.options.event != -1) {
        //    this.ui.customSchemaData_selectEvent.val(this.options.event.toString());
        //    //this.ui.customSchemaData_selectEvent.val(this.settingModel.attributes.EventId.toString());
        //}
    },
    setupDeleteAllBtn: function () {
        var enable = true;

        var collectionSchemaSchema = this.app.entities.collections.customSchemaSchema.where({ EventId: this.settingModel.attributes.EventId });
        if (this.app.entities.collections.customEvent.fullCollection.length <= 0 || collectionSchemaSchema.length <= 0) {
            enable = false;
        }

        if (enable) {
            //this.ui.customSchemaData_btnDeleteAllSubmit.removeClass('btn-secondary');
            //this.ui.customSchemaData_btnDeleteAllSubmit.addClass('btn-primary');
            this.ui.customSchemaData_btnDeleteAllSubmit.prop('disabled', false);
            this.ui.customSchemaData_btnExport.prop('disabled', false);
        } else {
            //this.ui.customSchemaData_btnDeleteAllSubmit.removeClass('btn-primary');
            //this.ui.customSchemaData_btnDeleteAllSubmit.addClass('btn-secondary');
            this.ui.customSchemaData_btnDeleteAllSubmit.prop('disabled', true);
            this.ui.customSchemaData_btnExport.prop('disabled', true);
        }
    },
    setupEvent: function (first) {
        this.ui.customSchemaData_selectEvent.empty();
        var self = this;

        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.SchemaModel != -1) {
                if (self.settingModel.attributes.EventId == entry.attributes.id) {
                    self.ui.customSchemaData_selectEvent.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
                }
                else {
                    self.ui.customSchemaData_selectEvent.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
                }
            }
        });

        if (this.options.event != undefined && this.options.event != -1) {
            this.ui.customSchemaData_selectEvent.val(this.options.event.toString());
        }

        if (first) {
            this.setModelFirst('EventId');
        }
    },
    setupExport: function () {
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: parseInt(this.$el.find('#customSchemaData_selectEvent').val()) });

        if (eventModel.length > 0) {
            if (eventModel[0].attributes.SchemaModel != -1) {
                var href = config.webApiUrl() + '/api/CustomExportData/route02/-1?AuthUserId=' + config.authUserId() + '&EventId=' + this.settingModel.attributes.EventId
                    + '&CustomSchemaModelId=' + eventModel[0].attributes.SchemaModel
                    + '&FileName=' + resources.X100259
                    + '&Date=' + resources.X100294
                    + '&CultureName=' + config.cultureName()
                    + '&Dummy=Dummy';
                this.ui.customSchemaData_btnExport.prop('href', href);
                this.ui.customSchemaData_btnExport.prop('download', resources.X100259);
            }
        }
    },
    setupSchemaSchema: function (first) {
        this.ui.customSchemaData_select_SchemaSchema.empty();
        var self = this;

        this.collectionSchemaSchema = this.app.entities.collections.customSchemaSchema.where({ EventId: this.settingModel.attributes.EventId });

        this.collectionSchemaSchema.forEach(function (entry) {
            if (self.settingModel.attributes.SchemaSchemaId == entry.attributes.id) {
                self.ui.customSchemaData_select_SchemaSchema.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                self.ui.customSchemaData_select_SchemaSchema.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });

        if (first) {
            this.setModelFirst('SchemaSchemaId');
        }

        //this.updateInformationCollection();
    },
    getSelectEvent: function (event) {
        var eventId = parseInt(this.$el.find('#customSchemaData_selectEvent').first().val());
        return eventId;
    },
    setChildSchemaModel: function () {
        //set schema model
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: this.settingModel.attributes.EventId });
        var schemaModel = this.app.entities.collections.customSchemaModel.fullCollection.where({ id: eventModel[0].attributes.SchemaModel });
        this.schemaModel = schemaModel[0];
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customSchemaDataList:setSchemaModel', this.schemaModel);
        userChannel.trigger('customSchemaDataInsert:setSchemaModel', this.schemaModel);
        userChannel.trigger('customSchemaDataImport:setSchemaModel', this.schemaModel);

        this.setupDeleteAllBtn();
        //this.renderInformation();
        this.setupExport();
    },
    setModelFirst: function (attributes) {
        switch (attributes) {
            case 'EventId':
                this.settingModel.attributes.EventId = parseInt(this.ui.customSchemaData_selectEvent.first().val());
                break;
            case 'SchemaSchemaId': this.settingModel.attributes.SchemaSchemaId = parseInt(this.ui.customSchemaData_select_SchemaSchema.first().val());
                this.setChildSchemaModel();
                this.readDataCollection(this.settingModel.attributes.EventId);
                break;
        }
    },
    setModel: function (attributes) {
        switch (attributes) {
            case 'EventId':
                this.settingModel.attributes.EventId = parseInt(this.ui.customSchemaData_selectEvent.val());
                break;
            case 'SchemaSchemaId': this.settingModel.attributes.SchemaSchemaId = parseInt(this.ui.customSchemaData_select_SchemaSchema.val());
                this.setChildSchemaModel();
                break;
        }
    },
    setModelUI: function (attributes) {
        switch (attributes) {
            case 'EventId': this.ui.customSchemaData_selectEvent.val(this.settingModel.attributes.EventId); break;
            case 'SchemaSchemaId': this.ui.customSchemaData_select_SchemaSchema.val(this.settingModel.attributes.SchemaSchemaId); break;
        }
    },
    save: function (callback) {
        if (this.getHasChanged()) {
            var self = this;

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = resources.X100395;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                dialog2Button.triggerMethod('dialog:close');
                self.saveHasChanged(callback);
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                this.setHasChanged(false);
                callback();
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);
        } else {
            callback();
        }
    },
    selectEventChange: function (event) {
        event.preventDefault();

        var self = this;
        var callback = function () {
            self.setModel('EventId');
            self.setupSchemaSchema(true);
            self.app.entities.collections.customSchemaSchema.trigger('change');
            self.updateInformationCollection();
            //self.customViewEvent_setActive();
        }

        this.save(callback);
    },
    selectSchemaSchemaChange: function (event) {
        event.preventDefault();

        var self = this;
        var callback = function () {
            self.setModel('SchemaSchemaId');
            self.readDataCollection(self.settingModel.attributes.EventId);
        }

        this.save(callback);
    },
    readDataCollection: function (eventId) {
        if (isNaN(this.settingModel.attributes.SchemaSchemaId)) {
            this.app.entities.collections.customSchemaData.reset();
            this.app.entities.collections.customSchemaData.trigger('change');
            //this.updateInformationCollection();
        }
        else {
            var self = this;
            this.app.entities.collections.customSchemaData.fetch({
                EventId: eventId,
                Schema: self.settingModel.attributes.SchemaSchemaId,
                success: function () {
                    self.app.entities.collections.customSchemaData.trigger('change');
                },
                error: function () {
                }
            });
        }
    },
    btnDeleteAllSubmit: function (event) {
        event.preventDefault();

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100250;
        dialog2ButtonModel.attributes.Text = resources.X100254;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var collection = this.app.entities.collections.customEvent;

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            self.customSchemaDataList.currentView.table_deleteAll();
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnWebpageSchemaBigscreen: function (event) {
        event.preventDefault();

        var self = this;

        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: this.settingModel.attributes.EventId });

        var callback = function () {
            if (self.app.entities.models.configSubscription[0].attributes.EnableSchema == false) {
                var dialogBoxModel = new Entities_Empty.Model();
                dialogBoxModel.attributes.Title = resources.X100229;
                dialogBoxModel.attributes.Text = resources.X100420;
                dialogBoxModel.attributes.Button = resources.X100279;

                var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                var callback2 = function (options) {
                    self.stopListening(dialogBox, "dialog:okclicked", callback2);
                }
                self.listenTo(dialogBox, "dialog:okclicked", callback2);
                self.app.dialogRegion.show(dialogBox);
            } else {
                if (eventModel[0].attributes.SchemaModel > 0) {
                    var friendlyUrl = eventModel[0].attributes.id;
                    if (eventModel[0].attributes.FriendlyUrl && eventModel[0].attributes.FriendlyUrl.length > 0) {
                        friendlyUrl = eventModel[0].attributes.FriendlyUrl;
                    }

                    var webpageSchemaBigscreen = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100419.toLowerCase();
                    //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);
                    window.open(webpageSchemaBigscreen);
                } else {
                    var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                    dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                    dialogBoxModelNoWebpage.attributes.Text = resources.X100422;
                    dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                    var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                    var callbackNoWebpage = function (options) {
                        self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    }
                    self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    self.app.dialogRegion.show(dialogBoxNoWebpage);
                }
            }
        }

        this.save(callback);
    },
    btnWebpageSchemaApp: function (event) {
        event.preventDefault();

        var self = this;

        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: this.settingModel.attributes.EventId });

        var callback = function () {
            if (self.app.entities.models.configSubscription[0].attributes.EnableSchema == false) {
                var dialogBoxModel = new Entities_Empty.Model();
                dialogBoxModel.attributes.Title = resources.X100229;
                dialogBoxModel.attributes.Text = resources.X100420;
                dialogBoxModel.attributes.Button = resources.X100279;

                var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                var callback2 = function (options) {
                    self.stopListening(dialogBox, "dialog:okclicked", callback2);
                }
                self.listenTo(dialogBox, "dialog:okclicked", callback2);
                self.app.dialogRegion.show(dialogBox);
            } else {
                if (eventModel[0].attributes.SchemaModel > 0) {
                    var friendlyUrl = eventModel[0].attributes.id;
                    if (eventModel[0].attributes.FriendlyUrl && eventModel[0].attributes.FriendlyUrl.length > 0) {
                        friendlyUrl = eventModel[0].attributes.FriendlyUrl;
                    }

                    var webpageSchemaBigscreen = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100143.toLowerCase(); //eventModel[0].attributes.SchemaMenuName;
                    //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);
                    window.open(webpageSchemaBigscreen);
                } else {
                    var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                    dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                    dialogBoxModelNoWebpage.attributes.Text = resources.X100422;
                    dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                    var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                    var callbackNoWebpage = function (options) {
                        self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    }
                    self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    self.app.dialogRegion.show(dialogBoxNoWebpage);
                }
            }
        }
        this.save(callback);
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    },
    customSchemaData_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.event);
        this.app.router.customEventEditItem(this.options.event, this.options.scroll, 3);
    }
}, Backbone.Radio.Requests);
