﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';
import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Selected: 0
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
});


export var CollectionPagination = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 6,
    },
    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },
    options: {
        full: false
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
            : a.attributes.id < b.attributes.id ? 1
                : 0;
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    parse: function (response, options) {
        return options.parseField ? response[options.parseField] : response;
    },
    search: function (letters) {
        //return no one
        if (letters == "") {
            return this.filter(function (data) {
                return true;
            });
        }

        return this.filter(function (data) {
            var $lowerKey = letters.toLowerCase();
            var pattern = new RegExp($lowerKey, "gi");

            var ret = pattern.test(data.get("Name"));
            if (!ret) ret = pattern.test(data.get("Email"));
            if (!ret) ret = pattern.test(data.get("CallerId"));

            return ret;
        });
    }
});