﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customProductSheetImage from './tpl/customProductSheetImage.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomProductSheetImageInsert from './customProductSheetImageInsert';
import View_CustomProductSheetImageList from './customProductSheetImageList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customProductSheetImage();
    },
    initialize: function () {
        this.app = this.options.app;

        this.listenTo(this.collection, 'change', this.render, this);
        
        this.localSetting = new Entities_Empty.Model();
        this.settingModel = this.options.settingModel;

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customProductSheetImage');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    regions: {
        customProductSheetImageInsert: "#customProductSheetImageInsert",
        customProductSheetImageList: "#customProductSheetImageList",
        customProductSheetImagePagination: "#customProductSheetImagePagination"
    },
    onBeforeShow: function () {
        this.customProductSheetImageInsert.show(new View_CustomProductSheetImageInsert({ collection: this.collection, settingModel: this.settingModel, localSetting: this.localSetting, app : this.app }));
        this.customProductSheetImageList.show(new View_CustomProductSheetImageList({ collection: this.collection, app : this.app }));
        this.customProductSheetImagePagination.show(new View_Pagination({ collection: this.collection, app : this.app }));
    },
    ui : {
        'help': '#help'
    },
    events: {
        'click #help': 'help'
    },
    onRender: function () {
    }
});