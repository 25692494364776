var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="custom"> <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 78px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X100739'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customInformation"></div> <div class="control-group customCrediential_inputEmail"> <div class="form-group"> <label for="customCrediential_inputEmail">'+
((__t=( resources['X100581'] ))==null?'':__t)+
'</label> <input class="form-control" value="'+
((__t=( model.Email))==null?'':__t)+
'" id="customCrediential_inputEmail"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customCrediential_inputPassword"> <div class="form-group"> <label for="customCrediential_inputPassword">'+
((__t=( resources['X100582'] ))==null?'':__t)+
'</label> <input class="form-control" type="password" value="'+
((__t=( model.Password ))==null?'':__t)+
'" id="customCrediential_inputPassword"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customCrediential_inputPasswordConfirm"> <div class="form-group"> <label for="customCrediential_inputPasswordConfirm">'+
((__t=( resources['X100583'] ))==null?'':__t)+
'</label> <input class="form-control" type="password" value="'+
((__t=( model.PasswordConfirm ))==null?'':__t)+
'" id="customCrediential_inputPasswordConfirm"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary" id="customCrediential_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary" id="customCrediential_btnDeleteAccount">'+
((__t=( resources['X100744'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary" id="customCrediential_btnCancel">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary" id="customCrediential_btnBack">'+
((__t=( resources['X200325'] ))==null?'':__t)+
'</button> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
