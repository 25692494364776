﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventPushInsert from './tpl/customEventPushInsert.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';

import config from '../../../common/config';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customEventPushInsert();
    },
    regions: {
    },
    onBeforeShow: function () {
    },
    initialize: function () {
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');
        var self = this;
        //userChannel.on('customEventPush:eventChanged', function() {
        //    self.selectEventChange();
        //});
        this.listenTo(userChannel, 'customEventPush:eventChanged', function() {
            self.selectEventChange();
        });
    },
    ui: {
        'customEventPushInsert_btnPushSubmit': '#customEventPushInsert_btnPushSubmit',
        'customEventPushInsert_inputPush': '#customEventPushInsert_inputPush',
        'customEventPushInsert_badge' : '#customEventPushInsert_badge'
    },
    events: {
        'click @ui.customEventPushInsert_btnPushSubmit': 'btnPushSubmit'
    },
    onRender: function () {
        this.setupPushBtn(this.app.entities.models.configSubscription[0].attributes.EnablePush);
        this.setupBadge();

        return this;
    },
    setupBadge: function () {
        var badgeCounter = this.app.entities.collections.customEventPush.fullCollection.length;//({ EventId: this.options.localSetting.attributes.EventId });
        //this.ui.customEventPushInsert_badge.text(badgeCounter.toString()); NOT SURE WHY
        this.$el.find('#customEventPushInsert_badge').text(badgeCounter.toString());
    },
    selectEventChange : function () {
        this.setupBadge();
    },
    setupPushBtn: function (enable) {
        if (this.collection.fullCollection.length <= 0) {
            enable = false;
        }

        if (enable) {
            this.ui.customEventPushInsert_btnPushSubmit.removeClass('btn-secondary');
            this.ui.customEventPushInsert_btnPushSubmit.addClass('btn-primary');
            this.ui.customEventPushInsert_btnPushSubmit.prop('disabled', false);
        } else {
            this.ui.customEventPushInsert_btnPushSubmit.removeClass('btn-primary');
            this.ui.customEventPushInsert_btnPushSubmit.addClass('btn-secondary');
            this.ui.customEventPushInsert_btnPushSubmit.prop('disabled', true);
        }
    },
    btnPushSubmit: function (event) {
        var self = this;
        var errors = [];

        var message = this.ui.customEventPushInsert_inputPush.val();

        if (!message) {
            errors.push({ name: 'customEventPushInsert_inputPush', message: resources.X100324 });
        }

        if (errors.length > 0) {
            this.showErrors(errors);
        }
        else {
            var now = new Date();

            var utcDateTime = now.getUTCFullYear().toString() + '-' +
                (now.getUTCMonth() + 1) + '-' +
                now.getUTCDate().toString() + ' ' +
                now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();

            var tmpModel = new this.app.entities.collections.customEventPush.model();
            //tmpModel.attributes.id = null;
            tmpModel.attributes.AuthUserId = config.authUserId();
            tmpModel.attributes.EventId = this.options.localSetting.attributes.EventId;
            tmpModel.attributes.UtcDate = utcDateTime;
            tmpModel.attributes.Message = message;

            this.app.entities.collections.customEventPush.create(tmpModel, {
                silens :true,
                wait: true,
                success: function (response) {
                    self.hideErrors();
                    self.ui.customEventPushInsert_inputPush.val("");
                    self.selectEventChange(null);

                    self.setupBadge();

                    self.options.localSetting.attributes.LastMessage = tmpModel.attributes.Message;
                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customEventPush:pushMessage');
                }
            });
        }
    }
}, Backbone.Radio.Requests);
