﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';
import * as FilePond from 'filepond';

import * as Entities_Empty from '../../../../lib/entities/empty';
import * as Entities_Memory_Position from '../../../entities/memoryposition';

import FilePondPluginFileEncode from '../../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../../plugins/filepond-plugin-image-edit';

import View_PictureSingleList from './pictureSingleList';

import helper_infopage from '../../../helper/infopage';

import config from '../../../../common/config';

import tpl_pictureSingle from './tpl/pictureSingle.html';

export default Marionette.LayoutView.extend({
    dialogRegion: '#pictureSingle_modal',
    errors: [],
    getTemplate: function () {
        return tpl_pictureSingle({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    regions: {
        pictureSingleList: "#pictureSingleListA"
    },
    onBeforeShow: function () {
        this.pictureSingleList.show(new View_PictureSingleList({ app: this.app, collection: this.collection }));
        //this.htmlListPagination.show(new View_Pagination({ collection: this.collection }));
    },
    ui: {
        'pictureSingle_checkboxEnableLabel': '#pictureSingle_checkboxEnableLabel',
        'pictureSingle_dropDown_LabelAnimation': '#pictureSingle_dropDown_LabelAnimation',

        'pictureSingle_select_Font1_ColorType': '#pictureSingle_select_Font1_ColorType',
        'pictureSingle_ColorPickerSliders_Font1_Color': '#pictureSingle_ColorPickerSliders_Font1_Color',
        'pictureSingle_dropDown_Font1_FontType': '#pictureSingle_dropDown_Font1_FontType',
        'pictureSingle_dropDown_Font1_Size': '#pictureSingle_dropDown_Font1_Size',
        'pictureSingle_select_Font1_FontType': '#pictureSingle_select_Font1_FontType',
        'pictureSingle_dropDown_Font1': '#pictureSingle_dropDown_Font1',
        'pictureSingle_formgroup_Font1': '#pictureSingle_formgroup_Font1',
        'pictureSingle': '#pictureSingle',
        'pictureSingle_dropDown_Font1_Style': '#pictureSingle_dropDown_Font1_Style',
        'pictureSingle_dropDown_Font1_TextAlign': '#pictureSingle_dropDown_Font1_TextAlign',
        'pictureSingle_input_margin1_Top': '#pictureSingle_input_margin1_Top',
        'pictureSingle_input_margin1_Bottom': '#pictureSingle_input_margin1_Bottom',
        'pictureSingle_input_margin1_Left': '#pictureSingle_input_margin1_Left',
        'pictureSingle_input_margin1_Right': '#pictureSingle_input_margin1_Right',

        'pictureSingle_checkboxEnableRandom': '#pictureSingle_checkboxEnableRandom',
        'pictureSingle_checkboxEnableRandomAnimation': '#pictureSingle_checkboxEnableRandomAnimation',
        
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right',

        'pictureSingle_filePond': '#pictureSingle_filePond',

        'pictureSingle_btn01': '#pictureSingle_btn01',
        'pictureSingle_btn02': '#pictureSingle_btn02'
    },
    events: {
        'change @ui.pictureSingle_select_Font1_FontType': 'change_Font1_FontType',
        'change @ui.pictureSingle_select_Font1_ColorType': 'change_Font1_ColorType',
        'colorpickerChange @ui.pictureSingle_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'click @ui.pictureSingle_btn01': 'onBtn01',
        'click @ui.pictureSingle_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    //getCollectionNextPosition: function () {
    //    var ret = 0;
    //    this.collection.forEach(function (entry) {
    //        if (entry.attributes.id >= ret) {
    //            ret = entry.attributes.id;
    //        }
    //    });
    //    return ret+1;
    //},
    nextCollectionId() {
        var ret = 0;
        this.collection.forEach(function (entry) {
            if (entry.id > ret) ret = entry.id;
        });

        return ret;
    },
    reOrder() {
        var self = this;
        var position = 0;
        $('#pictureListItem.myDiv').each(function (index, item) {
            var id = parseInt($(item).attr('data-id'));
            if (id != undefined) {
                var model = self.collection.where({ id: id })[0];
                model.set({ Position: position });
                $(item).attr('data-position', position);
                position++;
            }
        });
    },
    onAttach() {
        var self = this;
        this.setupLabelAnimation();
        this.setupEnableType();
        this.setupFontColorType();
        this.setupFontType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupFontTextAlign();

        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,

        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.pictureSingle_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: true,
            //allowReorder: true,
            //allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            allowImageResize: true,
            imageResizeTargetWidth: 750,
            imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    //console.log(err, fileItem.getMetadata('resize'));
                    var base64 = fileItem.getFileEncodeDataURL();
                    //var base64 = fileItem.getFileEncodeBase64String();

                    var canvas = document.createElement('canvas');
                    canvas.width = 750;
                    canvas.height = 750;

                    var context = canvas.getContext('2d');

                    var image = new Image();
                    image.src = base64;

                    image.onload = function () {


                        var wrh = image.width / image.height;
                        var newWidth = canvas.width;
                        var newHeight = newWidth / wrh;
                        if (newHeight > canvas.height) {
                            newHeight = canvas.height;
                            newWidth = newHeight * wrh;
                        }
                        var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
                        var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;


                        context.beginPath();
                        //context.fillStyle = "white";
                        //context.fillRect(0, 0, canvas.width, canvas.height);
                        context.drawImage(image, xOffset, yOffset, newWidth, newHeight);

                        base64 = canvas.toDataURL();

                        //self.collection.reset();

                        var defaultModel = self.app.entities.collections.Module.where({ id: 10 })[0];

                        var tmpModel = new Entities_Memory_Position.Model();
                        //tmpModel.attributes.OrgImage = base64;
                        //tmpModel.attributes.Image = base64;

                        //tmpModel.attributes.OrgImage = "";
                        //tmpModel.attributes.Image = "";

                        tmpModel.attributes.Selected = true;
                        //var id = self.nextCollectionId() + 1;
                        /*tmpModel.id = 1;
                        tmpModel.attributes.id = 1;
                        tmpModel.attributes.Position = 1;*/
                        tmpModel.attributes.Animation = defaultModel.attributes.Data.Model.Animation;
                        tmpModel.attributes.Text = defaultModel.attributes.Data.Model.Text;
                        tmpModel.attributes.Duration = defaultModel.attributes.Data.Model.Duration;

                        var searchString = ";base64,";
                        var pos = base64.indexOf(searchString);
                        var imageMime = base64.substring(5, pos);
                        var imageData = base64.substring(pos + searchString.length, base64.length);

                        //self.reOrder();

                        var callbackSaveImage = function (model) {
                            tmpModel.attributes.Position = model.attributes.id;
                            tmpModel.attributes.id = model.attributes.id;
                            tmpModel.attributes.InfoImage_id = model.attributes.id;
                            tmpModel.attributes.InfoImage_ImageUrl = model.attributes.ImageUrl;
                            tmpModel.attributes.InfoImage_Name = model.attributes.Name;

                            /*tmpModel.attributes.ImageUrl = model.attributes.ImageUrl;
                            tmpModel.attributes.CommonId = model.attributes.CommonId;
                            tmpModel.attributes.id = model.attributes.id;*/

                            self.collection.add(tmpModel);
                            pond.removeFile(fileItem);

                            //Remove old model in both this collection and CustomImageInfo
                            self.collection.forEach(function (entry) {
                                var customInfoImageModel = self.app.entities.collections.customInfoImage.where({ id: entry.attributes.id })[0];
                                if (customInfoImageModel != undefined && customInfoImageModel.attributes.id != model.attributes.id) {

                                    var collection = self.app.entities.collections.customInfoImage;

                                    customInfoImageModel.destroy({
                                        wait: true,
                                        success: function (model, response) {
                                            self.collection.remove(entry);
                                        },
                                        error: function (model, response) {
                                        }
                                    });


                                } else if (customInfoImageModel == undefined && entry.attributes.id != model.attributes.id) {
                                    self.collection.remove(entry);
                                }
                            });

                            
                        }

                        self.saveImage(callbackSaveImage, imageData, imageMime);
                        
                    };
                }
            }
        });

        //FilePond.parse(document.body);
    },
    fillup: function (text) {
        if (text.length <= 1) {
            text = '0' + text;
        }
        return text;
    },
    UUID: function () {
        var lut = [];
        for (var i = 0; i < 256; i++) {
            lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
        }

        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;

        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    },
    saveImage: function (callback, imageData, imageMime) {
        var nowLocalDate = new Date();

        var strLocalDate = nowLocalDate.getFullYear().toString() +
            this.fillup((nowLocalDate.getMonth() + 1).toString()) +
            this.fillup(nowLocalDate.getDate().toString()) +
            this.fillup(nowLocalDate.getHours().toString()) +
            this.fillup(nowLocalDate.getMinutes().toString()) +
            this.fillup(nowLocalDate.getSeconds().toString()) +
            nowLocalDate.getMilliseconds().toString();


        var filename = strLocalDate + " - " + "Test";
        var sizeType = 1;
        var sizeText = "750X750";
        var sortType = 1;
        var uuid = this.UUID();

        var changeDate = new Date();
        var strUtcDate = changeDate.getUTCFullYear().toString() + '-' +
            (changeDate.getUTCMonth() + 1) + '-' +
            changeDate.getUTCDate().toString() + ' ' +
            changeDate.getUTCHours() + ':' +
            changeDate.getUTCMinutes() + ':' +
            changeDate.getUTCSeconds() + '.';



        var tmpModel = new this.app.entities.collections.customInfoImage.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = filename;
        tmpModel.attributes.ImageData = imageData;
        tmpModel.attributes.ImageMimeType = imageMime; 
        tmpModel.attributes.ImageUpdate = true;

        tmpModel.attributes.EventId = this.options.parent.options.templateModel.attributes.EventId;
        tmpModel.attributes.TemplateId = this.options.parent.options.templateModel.attributes.id;
        tmpModel.attributes.ModuleRegion = this.options.parent.model.attributes.ModuleRegion;
        tmpModel.attributes.ModuleDiv = this.options.parent.model.attributes.ModuleDiv;
        tmpModel.attributes.ModuleType = this.options.parent.model.attributes.ModuleType;

        /*tmpModel.attributes.SizeType = sizeType;
        tmpModel.attributes.SizeText = sizeText;
        tmpModel.attributes.SortType = sortType;
        tmpModel.attributes.CommonId = uuid;
        tmpModel.attributes.UtcDate = strUtcDate;*/
        tmpModel.attributes.Filename = filename + '.png';

        this.app.entities.collections.customInfoImage.create(tmpModel, {
            wait: true,
            success: function (response) {
                callback(response);
            }
        });

    },
    setupLabelAnimation: function () {
        var self = this;

        this.ui.pictureSingle_dropDown_LabelAnimation.empty();

        var selected = '';

        self.app.entities.collections.PictureSlideLabelAnimation.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.LabelAnimation == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.pictureSingle_dropDown_LabelAnimation.append(options);
        });
    },
    setupEnableType: function () {
        var checked = this.model.attributes.EnableLabel == 1 ? true : false;
        this.ui.pictureSingle_checkboxEnableLabel.attr('checked', checked);

        var checked = this.model.attributes.EnableRandom == 1 ? true : false;
        this.ui.pictureSingle_checkboxEnableRandom.attr('checked', checked);

        var checked = this.model.attributes.EnableRandomAnimation == 1 ? true : false;
        this.ui.pictureSingle_checkboxEnableRandomAnimation.attr('checked', checked);
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.pictureSingle_select_Font1_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.pictureSingle_select_Font1_ColorType.append(options);
        });
    },
    setupFontType: function () {
        var self = this;

        self.ui.pictureSingle_select_Font1_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.pictureSingle_select_Font1_FontType.append(options);
        });
    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.pictureSingle_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
        });
        this.ui.pictureSingle_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.pictureSingle_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.pictureSingle_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.pictureSingle_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.pictureSingle_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    setupFontSize: function () {
        var self = this;

        this.ui.pictureSingle_dropDown_Font1_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.pictureSingle_dropDown_Font1_Size.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.pictureSingle_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.pictureSingle_dropDown_Font1_Style.append(options);
        });
    },
    setupFontTextAlign: function () {
        var self = this;

        self.ui.pictureSingle_dropDown_Font1_TextAlign.empty();

        var selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.pictureSingle_dropDown_Font1_TextAlign.append(options);
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    change_Font1_FontType: function () {
        this.model.set({
            Font1_FontType: this.ui.pictureSingle_select_Font1_FontType.val()
        });

        this.model.set({
            Font1: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font1_FontType, this.model.attributes.Font1_Select)
        });

        this.ui.pictureSingle_dropDown_Font1.trigger('setFont', this.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.pictureSingle_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.pictureSingle_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.pictureSingle_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.pictureSingle_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

    },
    change_Font1_ColorType: function () {
        this.model.set({
            Font1_ColorType: this.ui.pictureSingle_select_Font1_ColorType.val()
        });

        this.model.set({
            Font1_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font1_ColorType, this.model.attributes.Font1_ColorSelect)
        });

        this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable();
        this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font1_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.pictureSingle_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }
    },
    setModel: function () {
        var EnableLabel = this.model.attributes.EnableLabel;
        this.ui.pictureSingle_checkboxEnableLabel.is(':checked') ? EnableLabel = 1 : EnableLabel = 0;

        var EnableRandom = this.model.attributes.EnableRandom;
        this.ui.pictureSingle_checkboxEnableRandom.is(':checked') ? EnableRandom = 1 : EnableRandom = 0;

        var EnableRandomAnimation = this.model.attributes.EnableRandomAnimation;
        this.ui.pictureSingle_checkboxEnableRandomAnimation.is(':checked') ? EnableRandomAnimation = 1 : EnableRandomAnimation = 0;

        this.model.set({
            EnableLabel: EnableLabel,
            EnableRandom: EnableRandom,
            EnableRandomAnimation: EnableRandomAnimation,
            LabelAnimation: this.ui.pictureSingle_dropDown_LabelAnimation.val(),
            Font1_ColorType: this.ui.pictureSingle_select_Font1_ColorType.val(),
            Font1_FontType: this.ui.pictureSingle_select_Font1_FontType.val(),
            Font1_Font: this.ui.pictureSingle_dropDown_Font1.val(),
            Font1_Size: this.ui.pictureSingle_dropDown_Font1_Size.val(),
            Font1_Style: this.ui.pictureSingle_dropDown_Font1_Style.val(),
            Font1_TextAlign: this.ui.pictureSingle_dropDown_Font1_TextAlign.val(),
            Margin1_Top: this.ui.pictureSingle_input_margin1_Top.val(),
            Margin1_Bottom: this.ui.pictureSingle_input_margin1_Bottom.val(),
            Margin1_Left: this.ui.pictureSingle_input_margin1_Left.val(),
            Margin1_Right: this.ui.pictureSingle_input_margin1_Right.val(),

            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    },
    setupCssStyle: function () {
    }
});