var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customUserListItem_empty"> <div id="customUserListItem_div" class="d-flex align-items-center pb-3 border-bottom" style="padding-bottom: 5px !important; border-left:10px solid  #09f;margin-top: 2px"> <div style="font-size:14px;margin-top:5px;margin-left:2px" class="profileImage_small" id="customUserListItem_profileImage"></div> <img id="customUserListItem_imageUrl" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" class="img-lg rounded-circle mb-2" alt="profile image" style="width:50px;height:50px;margin-bottom: 0px !important;margin-top:5px"> <div class="ml-3"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small style="font-size: 0.9em" class="text-muted mb-0"><i class="mdi mdi-email mr-1"></i>'+
((__t=( model.Email ))==null?'':__t)+
'</small> </div> <div class="ml-auto"> <i id="customUserListItem_btnEdit" style="color: #6c7293 !important; cursor:pointer" class="fa fa-lg fa-cog font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customUserListItem_btnDelete" style="color: #6c7293 !important; cursor:pointer" class="far fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
