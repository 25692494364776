var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetDataTableEdit_empty"> <form class="contact-form"> <div class="" id="customProductSheetDataTableEdit_div_ProductName"> <div class="row"> <div class="control-label col-md-12"> <label id="customProductSheetDataTableEdit_label_ProductName">'+
((__t=( resources['X101129'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customProductSheetDataTableEdit_textarea_ProductName" style="height:78px"></textarea> </div> </div> </div> <div class="" id="customProductSheetDataTableEdit_div_Feature"> <div class="row"> <div class="control-label col-md-12"> <label id="customProductSheetDataTableEdit_label_Feature">'+
((__t=( resources['X101137'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customProductSheetDataTableEdit_textarea_Feature" style="height:78px"></textarea> </div> </div> </div> <div class="" id="customProductSheetDataTableEdit_div_Description"> <div class="row"> <div class="control-label col-md-12"> <label id="customProductSheetDataTableEdit_label_Description">'+
((__t=( resources['X101132'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customProductSheetDataTableEdit_textarea_Description" style="height:78px"></textarea> </div> </div> </div> <div style=""> <div id="customProductSheetDataTableEdit_divForm"></div> </div> <div id="customProductSheetDataTableEdit_div_ImageList"></div> <button type="button" class="btn btn-secondary btnProductSheetData" id="customProductSheetDataTableEdit_btnSubmit">'+
((__t=( resources['X100288'] ))==null?'':__t)+
'</button> </form> </div>';
}
return __p;
};
