var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetModelInsert_empty"> <form class="contact-form" style="margin-bottom:10px"> <div class="control-group customProductSheetModelInsert_inputName"> <div class="form-group"> <label for="customProductSheetModelInsert_inputName">'+
((__t=( resources['X101108'] ))==null?'':__t)+
'</label> <input class="form-control" id="customProductSheetModelInsert_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div id="container" style="visibility:hidden; height:0px"></div> <button type="button" class="btn btn-primary" id="customProductSheetModelInsert_btnSubmit">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> </form> </div>';
}
return __p;
};
