﻿import View_DialogBox from '../../lib/views/dialogBox/dialogBox';
import * as Entities_Empty from '../../lib/entities/empty';

var RenderError = {
    messageBox : function(app, view, title, text) {
        view.options.parent.app.dialogRegion.currentView.trigger("dialog:close");

        var self = this;
        var dialogBoxModel = new Entities_Empty.Model();
        dialogBoxModel.attributes.Title = title;
        dialogBoxModel.attributes.Text = text;
        dialogBoxModel.attributes.Button = resources.X100279;

        var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: view.options.parent.app.dialogRegion });
        var callback = function (options) {
            view.options.parent.app.dialogRegion.stopListening(dialogBox, "dialog:okclicked", callback);
        }
        view.options.parent.app.dialogRegion.listenTo(dialogBox, "dialog:okclicked", callback);
        view.options.parent.app.dialogRegion.show(dialogBox);
    }
}

export default RenderError;