var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetDataInsert_empty"> <form class="contact-form"> <div class="form-group" id="customProductSheetDataInsert_div_ProductName"> <div class="row"> <div class="control-label col-md-12"> <label id="customProductSheetDataInsert_label_ProductName">'+
((__t=( resources['X101129'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customProductSheetDataInsert_textarea_ProductName" style="height:78px"></textarea> </div> </div> </div> <div class="form-group" id="customProductSheetDataInsert_div_Feature"> <div class="row"> <div class="control-label col-md-12"> <label id="customProductSheetDataInsert_label_Feature">'+
((__t=( resources['X101137'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customProductSheetDataInsert_textarea_Feature" style="height:78px"></textarea> </div> </div> </div> <div class="form-group" id="customProductSheetDataInsert_div_Description"> <div class="row"> <div class="control-label col-md-12"> <label id="customProductSheetDataInsert_label_Description">'+
((__t=( resources['X101132'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12"> <textarea class="form-control" id="customProductSheetDataInsert_textarea_Description" style="height:78px"></textarea> </div> </div> </div> <div style=""> <div id="customProductSheetDataInsert_divForm"></div> </div> <div id="customProductSheetDataInsert_div_ImageList"></div> <button type="button" class="btn btn-primary btnProductSheetData" id="customProductSheetDataInsert_btnSubmit">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> </form> </div>';
}
return __p;
};
