﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageEditItemListItem from './tpl/customWebpageEditItemListItem.html';
import language from '../../../common/language';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_HFSetting from './setting/hfSetting';
import View_PageSetting from './setting/PageSetting';
import View_PrinterSetting from './setting/PrinterSetting';


export default Marionette.ItemView.extend({
    tagName: 'li',
    attributes: function () {
        // Return model data
        return {
            class: this.getClassLi(),
            style: this.getStyleLi(),
            'data-id': this.getData_idLi(),
            id: 'customWebpageEditItemListItem_li_' + this.model.attributes.id 

        }

    },
    getTemplate: function () {
        return tpl_customWebpageEditItemListItem({ model: this.model.toJSON() });
    },
    /*serialize: function(){
        return {model: this.model};
    },*/
    initialize: function () {
        this.app = this.options.app;

        //this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'customWebpageEditItemListItem_btnEdit': '#customWebpageEditItemListItem_btnEdit',
        'customWebpageEditItemListItem_btnDelete': '#customWebpageEditItemListItem_btnDelete',
        'customWebpageEditItemListItem_btnSetting': '#customWebpageEditItemListItem_btnSetting',
        'customWebpageEditItemListItem_inputDuration': '#customWebpageEditItemListItem_inputDuration',
        'customWebpageEditItemListItem_li': '#customWebpageEditItemListItem_li',
        'customWebpageEditItemListItem_a': '#customWebpageEditItemListItem_a',
        'customWebpageEditItemListItem_btnDropdown': '#customWebpageEditItemListItem_btnDropdown',
        'customWebpageEditItemListItem_div': '#customWebpageEditItemListItem_div'

    },
    events: {
        'click @ui.customWebpageEditItemListItem_a': 'btnEditClick',
     //   'click @ui.customWebpageEditItemListItem_li': 'btnEditClick',
        'click @ui.customWebpageEditItemListItem_btnEdit': 'btnEditClick',
        'click @ui.customWebpageEditItemListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customWebpageEditItemListItem_btnSetting': 'btnSettingClick',
        'change @ui.customWebpageEditItemListItem_inputDuration': 'inputDurationChange'
    },
    onAttach: function () {
        switch (this.model.attributes.Tag) {
            case 3:
                //this.ui.customWebpageEditItemListItem_li.attr("style", "display: none !important");
                this.ui.customWebpageEditItemListItem_li.css('display', 'none !important;');

                break;

            case 6:
                //this.ui.customWebpageEditItemListItem_li.attr("style", "display: none !important");
                this.ui.customWebpageEditItemListItem_li.css('display', 'none !important;');

                break;
        }

        
       /* if (this.model.attributes.Parent != -1) {
            //Remove li tag
            var id_from = '#customWebpageEditItemListItem_li_' + this.model.attributes.id;
            var id_to = '#customWebpageEditItemListItem_li_' + this.model.attributes.Parent;



            var ol = $(id_to).find("ol");

            //$(id_from).closest('.li').remove();
            //$(id_from).unwrap();
            //$(id_from).appendTo($(ol));
        }*/
    },
    onRender: function () {

        if (this.model.attributes.EnableDuration) {
            this.ui.customWebpageEditItemListItem_inputDuration.show();
        } else {
            this.ui.customWebpageEditItemListItem_inputDuration.css('display', 'none');
        }

        var styleType = 'visibility';
        var styleValue = 'hidden';


        switch (this.model.attributes.Tag) {
            case 0: this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#5b616a');
                this.ui.customWebpageEditItemListItem_btnSetting.css(styleType, styleValue);
                break;
            case 2: this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#5b616a');
                this.ui.customWebpageEditItemListItem_btnSetting.css(styleType, styleValue);
                break;
            case 1: this.ui.customWebpageEditItemListItem_btnDelete.show();
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#ffffff');
                break;
            case 3: this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#5b616a');
                this.ui.customWebpageEditItemListItem_btnSetting.css(styleType, styleValue);
                break;
            case 4: this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#ffffff');
                break;

            case 5:  this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#5b616a');
                this.ui.customWebpageEditItemListItem_btnSetting.css(styleType, styleValue);
                break;
            case 6: this.ui.customWebpageEditItemListItem_li.css('display', 'none !important;');
                break;

            case 7: this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#5b616a');
                this.ui.customWebpageEditItemListItem_btnSetting.css(styleType, styleValue);
                break;

            case 8: this.ui.customWebpageEditItemListItem_btnDelete.css(styleType, styleValue);
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#5b616a');
                this.ui.customWebpageEditItemListItem_btnSetting.css(styleType, styleValue);
                break;
            case 9: this.ui.customWebpageEditItemListItem_btnDelete.show();
                //this.ui.customWebpageEditItemListItem_btnSetting.css('color', '#ffffff');
                break;
        }

        //this.setupClass();

        this.ui.customWebpageEditItemListItem_a.attr('class', '');
        this.ui.customWebpageEditItemListItem_a.addClass(this.getClassA());//this.model.attributes.ClassnameA);

        //Uncomment 200724
        //this.ui.customWebpageEditItemListItem_a.parent().attr('class', '');
        //this.ui.customWebpageEditItemListItem_a.parent().addClass(this.getClassLi());
        this.ui.customWebpageEditItemListItem_div.parent().attr('class', '');
        this.ui.customWebpageEditItemListItem_div.parent().addClass(this.getClassLi());

        if (this.model.attributes.Dropdown) {
            this.ui.customWebpageEditItemListItem_btnDropdown.show();
        }

        switch (this.model.attributes.Selected) {
            case true:
                this.ui.customWebpageEditItemListItem_div.css('border-style', 'solid');
                this.ui.customWebpageEditItemListItem_div.css('border-color', 'black');
                break;
            case false:
                this.ui.customWebpageEditItemListItem_div.css('border-style', 'solid');
                this.ui.customWebpageEditItemListItem_div.css('border-color', 'white');
                break;
        }

        if (this.model.attributes.Selected) {
            this.ui.customWebpageEditItemListItem_div.addClass('mjs_select');
        } else {
            this.ui.customWebpageEditItemListItem_div.removeClass('mjs_select');
        }

        //this.ui.customWebpageEditItemListItem_li.attr('class', '');
        //this.ui.customWebpageEditItemListItem_li.addClass(this.getClassLi());//;this.model.attributes.ClassnameLi);

        /*if (this.model.attributes.Parent != -1) {
            //Remove li tag
            var id_from = '#customWebpageEditItemListItem_li_' + this.model.attributes.id;
            var id_to = '#customWebpageEditItemListItem_li_' + this.model.attributes.Parent;



            var ol = $(id_to).find("ol");

            //$(id_from).closest('.li').remove();
            //$(id_from).unwrap();
            //$(id_from).appendTo($(ol));
        }*/
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;

        switch (this.model.attributes.DisplayType) {
            case 0: customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); break;
            case 1: customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Infopage: this.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    getClassLi: function () {
        var classnameLi = '';
        //if (this.model.attributes.Tag != 3 && this.model.attributes.Tag != 6) {


        switch (this.model.attributes.Tag) {
            case 0: classnameLi = 'clearfix ui-state-disabled li_Header li_DummyHeader '; break;
            case 2: classnameLi = 'clearfix ui-state-disabled li_Footer li_DummyFooter '; break;
            case 1: classnameLi = 'clearfix li_Body li_DummyBody ui-state-default ui-sortable-handle'; break;
            case 3: classnameLi = 'clearfix ui-state-disabled li_SchemaBigscreen li_DummySchemaBigscreen '; break;
            case 4: classnameLi = 'clearfix ui-state-disabled li_Poster li_DummyPoster '; break;
            case 5: classnameLi = 'clearfix ui-state-disabled li_Schema li_DummySchema '; break;
            //  case 6: classnameLi = 'dpnoim'; break;
            case 7: classnameLi = 'clearfix ui-state-disabled li_Form li_DummyForm '; break;
            case 8: classnameLi = 'clearfix ui-state-disabled li_ProductSheet li_DummyProductSheet '; break;
            case 9: classnameLi = 'clearfix li_Info li_DummyInfo ui-state-default ui-sortable-handle'; break;
            case 10: classnameLi = 'clearfix li_dropdown li_Body li_DummyBody ui-state-default ui-sortable-handle'; break;
        }

        if (this.model.attributes.Tag != 3 && this.model.attributes.Tag != 6) {
            if (this.model.attributes.Selected == true) {
                classnameLi += 'ui-state-selected li_Selected';

                classnameLi = classnameLi.replace('li_Header', '');
                classnameLi = classnameLi.replace('li_Footer', '');
                classnameLi = classnameLi.replace('li_Body', '');
                classnameLi = classnameLi.replace('li_SchemaBigscreen', '');
                classnameLi = classnameLi.replace('li_Schema', '');
                classnameLi = classnameLi.replace('li_Poster', '');
                classnameLi = classnameLi.replace('li_Form', '');
                classnameLi = classnameLi.replace('li_ProductSheet', '');
                classnameLi = classnameLi.replace('li_Info', '');
            }
        }

        //if (this.model.attributes.Dropdown) {
        //    classnameLi = classnameLi + ' mjs-nestedSortable-leaf';
        //}

        if (this.model.attributes.Tag == 1 && this.model.attributes.Position > 1) {
            classnameLi = classnameLi + ' mjs_canmove mjs-nestedSortable-leaf';
        }
        else if (this.model.attributes.Tag == 10) {
            classnameLi = classnameLi + ' mjs_dropdown mjs-nestedSortable-leaf';
        }
        else {
            classnameLi = classnameLi + ' mjs-nestedSortable-disabled mjs-nestedSortable-no-nesting';
        }

        return classnameLi;
    },
    getStyleLi: function () {
        var stylenameLi = 'opacity: 1;border-bottom-width:2px;padding-top: 0px; padding-bottom: 0px;';
        switch (this.model.attributes.Tag) {
            case 0: break;
            case 2: break;
            case 1: break;
            case 3: stylenameLi += 'display:none !important;'; break;
            case 4: break;
            case 5: break;
            case 6: stylenameLi += 'display:none !important;'; break;
            case 7: break;
            case 8: break;
            case 9: break;
        }
        return stylenameLi;
    },
    getData_idLi: function () {
        return this.model.id;
    },
    getAllClassA: function () {
        var classnameA = 'ancorli li_aSelected';
        return classnameA;
    },
    getClassA: function () {
        var classnameA = 'ancorli';
        if (this.model.attributes.Selected) {
            classnameA = 'ancorli li_aSelected';
        }

        return classnameA;
    },
    setupClass: function () {
        var classnameLi = '';
        var classnameA = '';

        classnameLi = this.getClassLi();
        classnameA = this.classnameA();

        this.model.attributes.ClassnameLi = classnameLi;
        this.model.attributes.ClassnameA = classnameA;

        /*if (this.model.attributes.Tag != 3 && this.model.attributes.Tag != 6) {
            var classnameLi  = '';
            var classnameA = 'ancorli ';

            switch (this.model.attributes.Tag) {
                case 0 : classnameLi = 'ui-state-disabled li_Header li_DummyHeader '; break;
                case 2 : classnameLi = 'ui-state-disabled li_Footer li_DummyFooter '; break;
                case 1 : classnameLi = 'li_Body li_DummyBody '; break;
                case 3 : classnameLi = 'ui-state-disabled li_SchemaBigscreen li_DummySchemaBigscreen '; break;
                case 4 : classnameLi = 'ui-state-disabled li_Poster li_DummyPoster ';break;
                case 7 : classnameLi = 'ui-state-disabled li_Form li_DummyForm '; break;
                case 8 : classnameLi = 'ui-state-disabled li_ProductSheet li_DummyProductSheet '; break;
                case 9 : classnameLi = 'li_Info li_DummyInfo '; break;
            }

            if (this.model.attributes.Selected == true) {
                classnameLi += 'ui-state-selected li_Selected';
                classnameA += 'li_aSelected';
            }
            this.model.attributes.ClassnameLi = classnameLi;
            this.model.attributes.ClassnameA = classnameA;
        }*/
    },
    btnDeleteClick: function (event) {
        event.preventDefault();

        var self = this;
        //Do not delete if there is only 1 element
        var length = 0;

        switch (this.model.attributes.DisplayType) {
            case 0: var length = this.model.collection.where({ Tag: 1 }).length; break;
            case 1: var length = this.model.collection.where({ Tag: 9 }).length; break;
        }

        var lengthStatus = 0;
        var errorLengthStatus = false;
        switch (this.model.attributes.DisplayType) {
            case 0: var lengthStatus = this.model.collection.where({ Tag: 1, Status: 1 }).length; break;
            case 1: var lengthStatus = this.model.collection.where({ Tag: 9, Status: 1 }).length; break;
        }
        if ((lengthStatus <= 1) && (this.model.attributes.Status == 1)) {
            errorLengthStatus = true;
        }

        var callbackDelete = function () {
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customWebpageEditItemListItem:deleteModel', self.model);
        }

        if (length <= 1) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            switch (this.model.attributes.DisplayType) {
                case 0: dialogBoxModel.attributes.Text = resources.X100437; break;
                case 1: dialogBoxModel.attributes.Text = resources.X100970; break;
            }
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        } else if (errorLengthStatus) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            switch (this.model.attributes.DisplayType) {
                case 0: dialogBoxModel.attributes.Text = resources.X100971; break;
                case 1: dialogBoxModel.attributes.Text = resources.X100972; break;
            }
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        } else {
            var text = '';
            switch (this.model.attributes.DisplayType) {
                case 0: text = String.format(resources.X100558, this.model.attributes.Name); break;
                case 1: text = String.format(resources.X100559, this.model.attributes.Name); break;
            }

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = text;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;

            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                //self.saveSelectedModel();
                callbackDelete();
            }

            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);

        }
    },
    btnEditClick: function (event) {


        event.preventDefault();
        event.stopPropagation();

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemListItem:saveSelectedModel');

        userChannel.trigger('customWebpageEditItemListItem:disabledWebpageContent');

        this.model.collection.forEach(function (entry) {
            //entry.set({ Selected: false });
            entry.attributes.Selected = false;
        });

        this.model.set({ Selected: true });
        //this.model.Selected = true;

        $('.menuDiv').removeClass('mjs_select');

        if (this.model.attributes.Selected) {
            this.ui.customWebpageEditItemListItem_div.addClass('mjs_select');
        } 
        
        //this.model.collection.trigger('change');

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');


    },
    inputDurationChange: function (event) {
        event.preventDefault();

        this.model.attributes.Duration = parseInt(this.ui.customWebpageEditItemListItem_inputDuration.val());
    },
    hfSetting: function () {
        var self = this;

        var hfSettingModel = new Entities_Empty.Model();
        hfSettingModel.attributes.Title = resources.X100917;
        hfSettingModel.attributes.Text = '';
        hfSettingModel.attributes.Button01 = resources.X100279;
        hfSettingModel.attributes.Button02 = resources.X100280;
        hfSettingModel.attributes.Name = this.model.attributes.Name;
        hfSettingModel.attributes.Status = this.model.attributes.Status;
        hfSettingModel.attributes.Tag = this.model.attributes.Tag;
        hfSettingModel.attributes.OldId = this.model.attributes.id;

        var hfSetting = new View_HFSetting({ app: this.app, model: hfSettingModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(hfSetting, "dialog:btn01clicked", callback01);
            self.stopListening(hfSetting, "dialog:btn02clicked", callback02);

            self.model.set({ Status: hfSettingModel.attributes.Status });
        }
        this.listenTo(hfSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(hfSetting, "dialog:btn01clicked", callback01);
            self.stopListening(hfSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(hfSetting, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(hfSetting);
    },
    pageSetting: function () {
        var self = this;

        var pageSettingModel = new Entities_Empty.Model();
        pageSettingModel.attributes.Title = resources.X100917;
        pageSettingModel.attributes.Text = '';
        pageSettingModel.attributes.Button01 = resources.X100279;
        pageSettingModel.attributes.Button02 = resources.X100280;
        pageSettingModel.attributes.Name = this.model.attributes.Name;
        pageSettingModel.attributes.Status = this.model.attributes.Status;
        pageSettingModel.attributes.Tag = this.model.attributes.Tag;
        pageSettingModel.attributes.Webpage = this.model.attributes.Webpage;
        pageSettingModel.attributes.OldId = this.model.attributes.id;

        var pageSetting = new View_PageSetting({ app: this.app, model: pageSettingModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(pageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(pageSetting, "dialog:btn02clicked", callback02);

            self.model.set({ Name: pageSettingModel.attributes.Name });
            self.model.set({ Status: pageSettingModel.attributes.Status });
        }
        this.listenTo(pageSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(pageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(pageSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(pageSetting, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(pageSetting);
    },
    getexp: function (Css, key1, key2, defaultValue) {
        var ret = {};

        var tmpCss = Css;
        var subStrA = tmpCss.match(key1);
        var subStrB = tmpCss.match(key2);
        var value = tmpCss.substring(subStrA.index + key1.length, subStrB.index);
        if (value.length > 0) {
            tmpCss = tmpCss.replace(tmpCss.substring(subStrA.index, subStrB.index + key2.length), "");
            ret = { Css: tmpCss, value: value };
        } else {
            ret = { Css: Css, value: defaultValue };
        }

        return ret;
    },
    addPrinterSetting: function (model) {
        var tmpCss = model.attributes.Css;

        var ret = this.getexp(tmpCss, "margin-top:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginTop = ret.value;

        var ret = this.getexp(tmpCss, "margin-bottom:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginBottom = ret.value;

        var ret = this.getexp(tmpCss, "margin-left:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginLeft = ret.value;

        var ret = this.getexp(tmpCss, "margin-right:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginRight = ret.value;
    },
    printerSetting: function () {
        var self = this;



        var printerSettingModel = new Entities_Empty.Model();
        printerSettingModel.attributes.Title = resources.X100917;
        printerSettingModel.attributes.Text = '';
        printerSettingModel.attributes.Button01 = resources.X100279;
        printerSettingModel.attributes.Button02 = resources.X100280;
        printerSettingModel.attributes.Name = this.model.attributes.Name;
        printerSettingModel.attributes.OldId = this.model.attributes.id;
        printerSettingModel.attributes.Css = this.model.attributes.Css;
        printerSettingModel.attributes.Status = this.model.attributes.Status;


        this.addPrinterSetting(printerSettingModel);

        var printerSetting = new View_PrinterSetting({ app: this.app, model: printerSettingModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(printerSetting, "dialog:btn01clicked", callback01);
            self.stopListening(printerSetting, "dialog:btn02clicked", callback02);

            self.model.set({ Name: printerSettingModel.attributes.Name, Css: printerSettingModel.attributes.Css });
            self.model.set({ Status: printerSettingModel.attributes.Status });
        }
        this.listenTo(printerSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(printerSetting, "dialog:btn01clicked", callback01);
            self.stopListening(printerSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(printerSetting, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(printerSetting);
    },
    btnSettingClick: function (event) {
        event.preventDefault();

        switch (this.model.attributes.Tag) {
            case 0:
            case 2: this.hfSetting(); break;
            case 1: this.pageSetting();
                break;
            case 3: break;
            case 4: this.printerSetting();
                break;
            case 7:
            case 8: break;
            case 9: this.pageSetting();
                break;
        }
    },
    saveSelectedModel: function () {

    },
    selectModelCollection: function () {
        var self = this;
        var nextPosition = 2147483647;
        var valueNextPosition = -1;
        this.collection.forEach(function (entry) {
            if (entry.attributes.Webpage == self.model.attributes.id
                && entry.attributes.Position <= nextPosition) {
                nextPosition = entry.attributes.Position;
                valueNextPosition = entry.attributes.id;
            }
        });
    }
}, Backbone.Radio.Requests);
