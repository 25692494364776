﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as Entities_Empty from '../../../../lib/entities/empty';

import tpl_menu from './tpl/menu.html';

export default Marionette.LayoutView.extend({
    initialize: function () {
        this.app = this.options.app;
        
        this.model = new Entities_Empty.Model();
        var html = String.format(resources.X100847, this.app.entities.collections.publicShopCart.length);
        this.model.attributes.MenuShopCart = html;

        this.listenTo(this.model, 'change', this.render, this);
        //this.model.bind('change', this.render, this);

        var userChannel = Backbone.Radio.channel('app');

        var self = this;
        
        //this.listenTo(userChannel, 'customMenu:enable', function() {
        //    self.options.enable = true;
        //    self.initMenu(true);
        //});

        //this.listenTo(userChannel, 'customMenu:disable', function() {
        //    self.options.enable = false;
        //    self.initMenu(false);
        //});

        this.listenTo(userChannel, 'menu:shopProduct', function(model) {
            window.app.router.navigate("#product");
            window.app.router.product(model);
        });

        this.listenTo(userChannel, 'menu:shopRefreshCart', function() {
            var html = String.format(resources.X100847, self.app.entities.collections.publicShopCart.length);
            self.model.attributes.MenuShopCart = html;
            self.model.trigger('change');
        });

        this.listenTo(userChannel, 'menu:shopAddToCart', function(model) {
            var foundModel = self.app.entities.collections.publicShopCart.where({ tmpId : model.attributes.id, ConfigShopFreight : model.attributes.ConfigShopFreight } );

            if (foundModel.length > 0) {
                foundModel[0].attributes.Qty = foundModel[0].attributes.Qty + model.attributes.TempQty;
                foundModel[0].attributes.TotalPrice = foundModel[0].attributes.Qty + foundModel[0].attributes.Price;
            } else {
                model.attributes.Qty = model.attributes.TempQty;
                self.app.entities.collections.publicShopCart.create({ 
                    ConfigShopFreight : model.attributes.ConfigShopFreight,
                    Image01 : model.attributes.Image01,
                    tmpId : model.attributes.id, 
                    ShopOrder : model.attributes.ShopOrder,
                    Qty : model.attributes.Qty,
                    CultureName : model.attributes.CultureName,
                    Currency : model.attributes.Currency,
                    ItemId : model.attributes.ItemId,
                    Name : model.attributes.Name,
                    InvoiceDescriptionText  : model.attributes.InvoiceDescriptionText,
                    InvoiceCommentsText : model.attributes.InvoiceCommentsText,
                    AccountNumber : model.attributes.AccountNumber,
                    Price : model.attributes.Price,
                    Vat : model.attributes.Vat,
                });
            }
            var html = String.format(resources.X100847, self.app.entities.collections.publicShopCart.length);
            self.model.attributes.MenuShopCart = html;
            //$('#menuShopCart').html(html);

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('menu:cart');
        });

        this.listenTo(userChannel, 'menu:cart', function() {
            window.app.router.navigate("#cart");
            window.app.router.cart();
        });
    },
    getTemplate: function () {
        return tpl_menu({ model: this.model.toJSON() });
    },
    ui: {
        'top_selectFlagstrip': '#top_selectFlagstrip',
        'menu_li_portal' : '#menu_li_portal',
        'menuShopCart' : '#menuShopCart'
    },
    events: {
        //'click #custom_menu11_a': 'help'
    },
    onRender: function () {
        //this.ui.menuShopCart.html(this.model.attributes.MenuShopCart);
    },
    onAttach(){
        if (this.app.mgr.profile !== undefined) {
            this.ui.menu_li_portal.show();
        } else {
            this.ui.menu_li_portal.css('display', 'none');
        }
    }
}, Backbone.Radio.Requests);
