var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customWebpageEditor_empty"> <div id="#modal" style="z-index:1000"></div> <row> <div style="display:none"> <input type="checkbox" style="display:none" id="customWebpageEditor_checkboxEditor" data-on-text="'+
((__t=( resources['X100079'] ))==null?'':__t)+
'" data-off-text="'+
((__t=( resources['X100080'] ))==null?'':__t)+
'" data-size="mini" checked="checked"> <input type="checkbox" style="display:none" id="customWebpageEditor_checkboxDesignMode" data-on-text="'+
((__t=( resources['X101007'] ))==null?'':__t)+
'" data-off-text="'+
((__t=( resources['X101006'] ))==null?'':__t)+
'" data-size="mini" checked="checked"> <button type="button" style="display:none" class="btn btn-secondary" id="customWebpageEditor_btnPaintStrapSubmit">'+
((__t=( resources['X100053'] ))==null?'':__t)+
'</button> </div> <button type="button" style="display:none" class="btn btn-secondary" id="customWebpageEditor_btnImage">'+
((__t=( resources['X100321'] ))==null?'':__t)+
'</button> <button type="button" style="display:none" class="btn btn-secondary" id="customWebpageEditor_btnFile">'+
((__t=( resources['X100322'] ))==null?'':__t)+
'</button> <div id="customWebpageEditor_iframeCKEditor_div" style="text-align:center"> <iframe id="customWebpageEditor_iframeCKEditor" src="" style="height:800px; width:100%;margin: 0 auto"></iframe> </div> <div id="customWebpageEditor_iframeContentBuilder_div"> <iframe id="customWebpageEditor_iframeContentBuilder" src="" style="height:800px; width:100%"></iframe> </div> </row> </div>';
}
return __p;
};
