var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSubscriptionListItem_empty"> <div class="row">  <div style="margin-top:10px"> <div class="col-md-7"> <div class="testimonial clearfix">  <div class="quote-box" style="width:100%"> <div class="quote" style="padding-top: 14px;padding-bottom: 14px;font-size:14px;color:#000;font-style:normal"><i class="arrow"></i>'+
((__t=( model.TmpMessage ))==null?'':__t)+
'</div> </div> </div> </div> <div class="col-md-5"> <div id="registerMailConfirmedSucces_div_paymentGateway"> </div>  <button type="button" class="btn btn-secondary btn-sm" id="customSubscriptionListItem_btnInvoice">'+
((__t=( resources['X100837'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
