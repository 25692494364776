﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_pageSettingList from './tpl/pageSettingList.html';
import View_PageSettingListItem from './pageSettingListItem';

export default Marionette.CollectionView.extend({
    tagName: 'div',
    className: 'row',
    attributes: function () {
        return {
            id: 'pageSettingListItem'
        }
    },
    childViewContainer: '#pageSettingListItem',
    childView: View_PageSettingListItem,
    getTemplate: function () {
        return tpl_pageSettingList();
    },
    ui: {
        'pageSettingListItem': '#pageSettingListItem'
    },

    initialize: function () {
        this.app = this.options.app;

    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
