﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as nouislider from 'nouislider';


import * as plugins_jquery_fontselect from '../../plugins/jquery.fontselect'

import tpl_customInfopageEditItemRightContent from './tpl/customInfopageEditItemRightContent.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import * as Entities_CustomInfopageItem from '../../entities/customInfopageItem';
import * as Entities_CustomWebpageItem from '../../entities/customWebpageItem';
import * as Entities_CustomBuilder from '../../entities/customBuilder';

import View_customInfopageEditItemList from './customInfopageEditItemList';

import config from '../../../common/config';
import Help from '../../helper/help';

import helper_infopage from '../../helper/infopage';

export default View_LayoutError.extend({
    className: 'row',
    getTemplate: function () {
        return tpl_customInfopageEditItemRightContent({ model: this.model.toJSON() });
    },
    regions: {
        customInfopageEditItemList: "#customInfopageEditItemList"
    },
    onBeforeShow: function () {
        var self = this;

        
        //self.customInfopageEditItemList.show(new View_customInfopageEditItemList({ collection: self.app.entities.collections.DiasListCollection, app: this.app, childViewContainer: '#customInfopageEditItemList' }));
        self.customInfopageEditItemList.show(new View_customInfopageEditItemList({ customModuleModel: this.options.customModuleModel, collection: this.collection, app: this.app, childViewContainer: '#customInfopageEditItemList' }));


        /*var lst = _.map(window.editor.DiasList, function (val) {
            return { val };
        });

        var adultListCollection = new Backbone.Collection(lst);

        var adultListCollection2 = new Entities_CustomInfopageItem.Collection();
        var adultListCollection3 = new Entities_CustomWebpageItem.Collection();

        self.app.entities.collections.adultListCollection4 = new Entities_CustomWebpageItem.Collection();//new Entities_CustomBuilder.Collection();
        

        

        //adultListCollection2.models = self.app.entities.collections.customWebpageItem.models;
        //adultListCollection3.models = self.app.entities.collections.customWebpageItem.models;

        var model = new Entities_CustomBuilder.Model({
            "id": 1,
            "AuthUserId": "295a42b2-e705-4cb4-b0d8-3084cdf2953f",
            "Webpage": 3,
            "Name": "Test 1",
            "Tag": 1,
            "Body": "asasdasd",
            "Position": 2,
            "Duration": 1,
            "Css": "",
            "Status": 1,
            "CommonId": "00000000-0000-0000-0000-000000000000",
            "Parent": -1,
            "Dropdown": 0});

        //adultListCollection3.push(model);
        self.app.entities.collections.adultListCollection4.push(model);

        var timeoutId = setInterval(function () {
            clearTimeout(timeoutId); 
            self.customInfopageEditItemList.show(new View_customInfopageEditItemList({ collection: self.app.entities.collections.adultListCollection4, app: self.app, childViewContainer: '#customInfopageEditItemList' }));
        }, 2 * 1000);

        //this.customInfopageEditItemList.show(new View_customInfopageEditItemList({ collection: self.app.entities.collections.customWebpageItem, app: this.app, childViewContainer: '#customInfopageEditItemList' }));
        //this.customInfopageEditItemList.show(new View_customInfopageEditItemList({ collection: adultListCollection3, app: this.app, childViewContainer: '#customInfopageEditItemList' }));*/


    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.setHasChanged(false);
        this.listenTo(this.model, 'change', this.change);
    },
    change: function () {
        helper_infopage.setupCssStyle(this.app);
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('html:setupCssStyle');
    },
    help: function () {
        Help.help(this, true, 'customInfopageEditItemTopbar');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmit(null, false, true, callback, false);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'customInfopageEditItemRightContent_select_BackGroundType': '#customInfopageEditItemRightContent_select_BackGroundType',
        'customInfopageEditItemRightContent_btnImage': '#customInfopageEditItemRightContent_btnImage',
        'customInfopageEditItemRightContent_inputImage': '#customInfopageEditItemRightContent_inputImage',
        'customInfopageEditItemRightContent_image_background': '#customInfopageEditItemRightContent_image_background',
        'customInfopageEditItemRightContent_dropDown_backGroundImagePosition': '#customInfopageEditItemRightContent_dropDown_backGroundImagePosition',
        'customInfopageEditItemRightContent_btnDeleteImage': '#customInfopageEditItemRightContent_btnDeleteImage',
        'customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColorApp': '#customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColorApp',
        'customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColor': '#customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColor',
        'customInfopageEditItemRightContent_btnClearBackGroundColor': '#customInfopageEditItemRightContent_btnClearBackGroundColor',
        'customInfopageEditItemRightContent_inputImage': '#customInfopageEditItemRightContent_inputImage',
        'customInfopageEditItemRightContent_select_Font1_FontType': '#customInfopageEditItemRightContent_select_Font1_FontType',
        'customInfopageEditItemRightContent_dropDown_Font1': '#customInfopageEditItemRightContent_dropDown_Font1',
        'customInfopageEditItemRightContent_formgroup_Font1': '#customInfopageEditItemRightContent_formgroup_Font1',
        
        'customInfopageEditItemRightContent_dropDown_FontApp1': '#customInfopageEditItemRightContent_dropDown_FontApp1',
        'customInfopageEditItemRightContent_select_Font1_ColorType': '#customInfopageEditItemRightContent_select_Font1_ColorType',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font1_ColorApp': '#customInfopageEditItemRightContent_ColorPickerSliders_Font1_ColorApp',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color': '#customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color',
        'customInfopageEditItemRightContent_dropDown_Font_Size1': '#customInfopageEditItemRightContent_dropDown_Font_Size1',
        'customInfopageEditItemRightContent_dropDown_Font_LineHeight1': '#customInfopageEditItemRightContent_dropDown_Font_LineHeight1',
        'customInfopageEditItemRightContent_select_Font2_FontType': '#customInfopageEditItemRightContent_select_Font2_FontType',
        'customInfopageEditItemRightContent_dropDown_Font2': '#customInfopageEditItemRightContent_dropDown_Font2',
        'customInfopageEditItemRightContent_formgroup_Font2': '#customInfopageEditItemRightContent_formgroup_Font2',
        'customInfopageEditItemRightContent_dropDown_FontApp2': '#customInfopageEditItemRightContent_dropDown_FontApp2',
        'customInfopageEditItemRightContent_dropDown_Font_Style1': '#customInfopageEditItemRightContent_dropDown_Font_Style1',
        'customInfopageEditItemRightContent_select_Font2_ColorType': '#customInfopageEditItemRightContent_select_Font2_ColorType',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font2_ColorApp': '#customInfopageEditItemRightContent_ColorPickerSliders_Font2_ColorApp',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color': '#customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color',
        'customInfopageEditItemRightContent_dropDown_Font_Size2': '#customInfopageEditItemRightContent_dropDown_Font_Size2',
        'customInfopageEditItemRightContent_dropDown_Font_Style2': '#customInfopageEditItemRightContent_dropDown_Font_Style2',
        'customInfopageEditItemRightContent_dropDown_Font_LineHeight2': '#customInfopageEditItemRightContent_dropDown_Font_LineHeight2',
        'customInfopageEditItemRightContent_select_Font3_FontType': '#customInfopageEditItemRightContent_select_Font3_FontType',
        'customInfopageEditItemRightContent_dropDown_Font3': '#customInfopageEditItemRightContent_dropDown_Font3',
        'customInfopageEditItemRightContent_formgroup_Font3': '#customInfopageEditItemRightContent_formgroup_Font3',
        'customInfopageEditItemRightContent_dropDown_FontApp3': '#customInfopageEditItemRightContent_dropDown_FontApp3',
        'customInfopageEditItemRightContent_select_Font3_ColorType': '#customInfopageEditItemRightContent_select_Font3_ColorType',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font3_ColorApp': '#customInfopageEditItemRightContent_ColorPickerSliders_Font3_ColorApp',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color': '#customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color',
        'customInfopageEditItemRightContent_dropDown_Font_Size3': '#customInfopageEditItemRightContent_dropDown_Font_Size3',
        'customInfopageEditItemRightContent_dropDown_Font_Style3': '#customInfopageEditItemRightContent_dropDown_Font_Style3',
        'customInfopageEditItemRightContent_dropDown_Font_LineHeight3': '#customInfopageEditItemRightContent_dropDown_Font_LineHeight3',
        'customInfopageEditItemRightContent_select_Font4_FontType': '#customInfopageEditItemRightContent_select_Font4_FontType',
        'customInfopageEditItemRightContent_dropDown_Font4': '#customInfopageEditItemRightContent_dropDown_Font4',
        'customInfopageEditItemRightContent_formgroup_Font4': '#customInfopageEditItemRightContent_formgroup_Font4',
        'customInfopageEditItemRightContent_dropDown_FontApp4': '#customInfopageEditItemRightContent_dropDown_FontApp4',
        'customInfopageEditItemRightContent_select_Font4_ColorType': '#customInfopageEditItemRightContent_select_Font4_ColorType',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font4_ColorApp': '#customInfopageEditItemRightContent_ColorPickerSliders_Font4_ColorApp',
        'customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color': '#customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color',
        'customInfopageEditItemRightContent_dropDown_Font_Size4': '#customInfopageEditItemRightContent_dropDown_Font_Size4',
        'customInfopageEditItemRightContent_dropDown_Font_Style4': '#customInfopageEditItemRightContent_dropDown_Font_Style4',
        'customInfopageEditItemRightContent_dropDown_Font_LineHeight4': '#customInfopageEditItemRightContent_dropDown_Font_LineHeight4',
        'demoBasic': '#demoBasic',
        'font1': '#font1'
    },
    events: {
        'colorpickerChange @ui.customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColor': 'colorChange_BackGroundColor',
        'colorpickerChange @ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color': 'colorChange_Font2Color',
        'colorpickerChange @ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color': 'colorChange_Font3Color',
        'colorpickerChange @ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color': 'colorChange_Font4Color',
        'change @ui.customInfopageEditItemRightContent_dropDown_backGroundImagePosition': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Size1': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Size2': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Size3': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Size4': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight1': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight2': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight3': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight4': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Style1': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Style2': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Style3': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_dropDown_Font_Style4': 'modifyRest',
        'change @ui.customInfopageEditItemRightContent_select_Font1_ColorType': 'change_Font1_ColorType',
        'change @ui.customInfopageEditItemRightContent_select_Font2_ColorType': 'change_Font2_ColorType',
        'change @ui.customInfopageEditItemRightContent_select_Font3_ColorType': 'change_Font3_ColorType',
        'change @ui.customInfopageEditItemRightContent_select_Font4_ColorType': 'change_Font4_ColorType',
        'change @ui.customInfopageEditItemRightContent_select_Font1_FontType': 'change_Font1_FontType',
        'change @ui.customInfopageEditItemRightContent_select_Font2_FontType': 'change_Font2_FontType',
        'change @ui.customInfopageEditItemRightContent_select_Font3_FontType': 'change_Font3_FontType',
        'change @ui.customInfopageEditItemRightContent_select_Font4_FontType': 'change_Font4_FontType',

        'change @ui.customInfopageEditItemRightContent_btnImage': 'btnImageChange',
        'click @ui.customInfopageEditItemRightContent_btnDeleteImage': 'btnImageDelete',
        'change @ui.customInfopageEditItemRightContent_select_BackGroundType': 'modifyRest'

    },
    updateAll: function () {
        this.model.trigger('change');

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('InfoPageSetting:change');
    },
    onRender: function () {
        this.setupFont1();
    },
    onAttach() {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColorApp.colorpicker({
            color: self.model.attributes.BackGroundColorApp,
            format: "rgb"
        });
        var cp = this.ui.customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColorApp.colorpicker('colorpicker');
        cp.disable();

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColor.colorpicker({
            color: self.model.attributes.BackGroundColor,
            format: "rgb"
        });

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_ColorApp.colorpicker({
            color: self.model.attributes.Font1_ColorApp,
            format: "rgb"
        });
        var cp = this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_ColorApp.colorpicker('colorpicker');
        cp.disable();

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }
        
        this.ui.customInfopageEditItemRightContent_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            //self.model.attributes.Font1 = this.value;
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
            self.updateAll();
        });
        this.ui.customInfopageEditItemRightContent_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }


        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_ColorApp.colorpicker({
            color: self.model.attributes.Font2_ColorApp,
            format: "rgb"
        });
        var cp = this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_ColorApp.colorpicker('colorpicker');
        cp.disable();

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.customInfopageEditItemRightContent_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            //self.model.attributes.Font2 = this.value;
            self.model.set({ Font2: this.value });
            self.model.set({ Font2_Select: this.value });
            self.updateAll();
        });
        this.ui.customInfopageEditItemRightContent_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_ColorApp.colorpicker({
            color: self.model.attributes.Font3_ColorApp,
            format: "rgb"
        });
        var cp = this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_ColorApp.colorpicker('colorpicker');
        cp.disable();

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker({
            color: self.model.attributes.Font3_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font3_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.customInfopageEditItemRightContent_dropDown_Font3.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            //self.model.attributes.Font3 = this.value;
            self.model.set({ Font3: this.value });
            self.model.set({ Font3_Select: this.value });
            self.updateAll();
        });
        this.ui.customInfopageEditItemRightContent_dropDown_Font3.trigger('setFont', self.model.attributes.Font3);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font3_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font3.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font3.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font3.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font3.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_ColorApp.colorpicker({
            color: self.model.attributes.Font4_ColorApp,
            format: "rgb"
        });
        var cp = this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_ColorApp.colorpicker('colorpicker');
        cp.disable();

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker({
            color: self.model.attributes.Font4_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font4_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.customInfopageEditItemRightContent_dropDown_Font4.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            //self.model.attributes.Font4 = this.value;
            self.model.set({ Font4: this.value });
            self.model.set({ Font4_Select: this.value });
            self.updateAll();
        });
        this.ui.customInfopageEditItemRightContent_dropDown_Font4.trigger('setFont', self.model.attributes.Font4);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font4_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font4.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font4.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font4.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font4.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }


        //var width = this.ui.customInfopageEditItemRightContent_dropDown_Font1.css('width');
        $('.font-select').css('width', '100%');

        self.setupBackGroundType();
        self.setupBackGroundImagePosition();
        self.setupFontColorType();
        self.setupFontType();
        self.setupFontApp();
        self.setupFontSize();
        self.setupFontStyle();
        self.setupFontLineHeight();
        self.setupImage();

        //customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color
    },
    setupBackGroundType: function () {
        var self = this;

        self.ui.customInfopageEditItemRightContent_select_BackGroundType.empty();

        var selected = '';

        self.app.entities.collections.GridBackGroundType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.BackGroundType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_BackGroundType.append(options);
        });
    },
    setupBackGroundImagePosition: function () {
        var self = this;

        this.ui.customInfopageEditItemRightContent_dropDown_backGroundImagePosition.empty();

        var selected = '';

        self.app.entities.collections.ModuleBackGroundImagePosition.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.BackGroundImagePosition == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_backGroundImagePosition.append(options);
        });
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.customInfopageEditItemRightContent_select_Font1_ColorType.empty();
        self.ui.customInfopageEditItemRightContent_select_Font2_ColorType.empty();
        self.ui.customInfopageEditItemRightContent_select_Font3_ColorType.empty();
        self.ui.customInfopageEditItemRightContent_select_Font4_ColorType.empty();

        var selected = '';

        self.app.entities.collections.GridFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font1_ColorType.append(options);
        });

        self.app.entities.collections.GridFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font2_ColorType.append(options);
        });

        self.app.entities.collections.GridFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font3_ColorType.append(options);
        });

        self.app.entities.collections.GridFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font4_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font4_ColorType.append(options);
        });
    },
    /*setupBackGroundType: function () {
        var self = this;

        this.ui.customInfopageEditItemRightContent_select_BackGroundType.empty();

        var selected = '';

        if (self.model.attributes.BackGroundType == "0") {
            selected = 'selected = "selected"';
        }

        var options = String.format('<option {1} value="0">{0}</option>', resources.X101329, selected);
        this.ui.customInfopageEditItemRightContent_select_BackGroundType.append(options);

        selected = '';

        if (self.model.attributes.BackGroundType == "1") {
            selected = 'selected = "selected"';
        }

        var options = String.format('<option {1} value="1">{0}</option>', resources.X101330, selected);
        this.ui.customInfopageEditItemRightContent_select_BackGroundType.append(options);
    },*/
    setupFontSize: function () {
        var self = this;

        this.ui.customInfopageEditItemRightContent_dropDown_Font_Size1.empty();
        this.ui.customInfopageEditItemRightContent_dropDown_Font_Size2.empty();
        this.ui.customInfopageEditItemRightContent_dropDown_Font_Size3.empty();
        this.ui.customInfopageEditItemRightContent_dropDown_Font_Size4.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customInfopageEditItemRightContent_dropDown_Font_Size1.append(options);
        }
        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customInfopageEditItemRightContent_dropDown_Font_Size2.append(options);
        }
        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font3_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customInfopageEditItemRightContent_dropDown_Font_Size3.append(options);
        }
        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font4_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customInfopageEditItemRightContent_dropDown_Font_Size4.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.customInfopageEditItemRightContent_dropDown_Font_Style1.empty();
        self.ui.customInfopageEditItemRightContent_dropDown_Font_Style2.empty();
        self.ui.customInfopageEditItemRightContent_dropDown_Font_Style3.empty();
        self.ui.customInfopageEditItemRightContent_dropDown_Font_Style4.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_Style1.append(options);
        });
        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_Style2.append(options);
        });
        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_Style3.append(options);
        });
        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font4_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_Style4.append(options);
        });

    },
    setupFontType: function () {
        var self = this;

        self.ui.customInfopageEditItemRightContent_select_Font1_FontType.empty();

        var selected = '';

        self.app.entities.collections.GridFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font1_FontType.append(options);
        });

        self.ui.customInfopageEditItemRightContent_select_Font2_FontType.empty();

        var selected = '';

        self.app.entities.collections.GridFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font2_FontType.append(options);
        });

        self.ui.customInfopageEditItemRightContent_select_Font3_FontType.empty();

        var selected = '';

        self.app.entities.collections.GridFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font3_FontType.append(options);
        });


        var selected = '';

        self.app.entities.collections.GridFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font4_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_select_Font4_FontType.append(options);
        });


       
    },
    setupFontApp: function () {
        var self = this;

        self.ui.customInfopageEditItemRightContent_dropDown_FontApp1.empty();
        var selected = 'selected = "selected"';
        var options = String.format('<option value="{1}" {2}>{0}</option>', this.model.attributes.Font1_App, this.model.attributes.Font1_App, selected);
        self.ui.customInfopageEditItemRightContent_dropDown_FontApp1.append(options);

        self.ui.customInfopageEditItemRightContent_dropDown_FontApp2.empty();
        selected = 'selected = "selected"';
        var options = String.format('<option value="{1}" {2}>{0}</option>', this.model.attributes.Font2_App, this.model.attributes.Font2_App, selected);
        self.ui.customInfopageEditItemRightContent_dropDown_FontApp2.append(options);

        self.ui.customInfopageEditItemRightContent_dropDown_FontApp3.empty();
        selected = 'selected = "selected"';
        var options = String.format('<option value="{1}" {2}>{0}</option>', this.model.attributes.Font3_App, this.model.attributes.Font3_App, selected);
        self.ui.customInfopageEditItemRightContent_dropDown_FontApp3.append(options);

        self.ui.customInfopageEditItemRightContent_dropDown_FontApp4.empty();
        selected = 'selected = "selected"';
        var options = String.format('<option value="{1}" {2}>{0}</option>', this.model.attributes.Font4_App, this.model.attributes.Font4_App, selected);
        self.ui.customInfopageEditItemRightContent_dropDown_FontApp4.append(options);
    },
    setupFontLineHeight: function () {
        var self = this;
        self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight1.empty();
        self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight2.empty();
        self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight3.empty();
        self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight4.empty();

        var selected = '';

        self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_LineHeight == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight1.append(options);
        });
        self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_LineHeight == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight2.append(options);
        });
        self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_LineHeight == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight3.append(options);
        });
        self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font4_LineHeight == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight4.append(options);
        });
    },
    setupImage: function () {
        if (this.model.attributes.BackGroundImage != '') {
            this.ui.customInfopageEditItemRightContent_image_background.attr('src', this.model.attributes.BackGroundImage);
        }
    },
    setupFont1: function () {
        this.ui.customInfopageEditItemRightContent_dropDown_Font1.empty();
        var self = this;
        var i = 0;
        self.app.entities.collections.Font.forEach(function (entry) {
            i++;

            /*if (i < 100) {
                var a_start = '<a class="dropdown-item" value="' + entry.data_font_family + '">';
                var a_end = '</a>'
                var img = '<img src="/images/fonts/' + entry.image + '"/>';
     
                var html = a_start + img + a_end;
     
                self.ui.customInfopageEditItemRightContent_dropDown_Font1.append(html);
            }*/

            if (i < 100) {
                //                var options = String.format("<option style=\"background-image:url('images/fonts/{0}')\"; data-tokens='{1}'>{1}</option>", entry.image, entry.data_font_family);

                //var options = String.format("<option style={2}font-family:'{1}';{2} data-tokens='{1}'>{1}</option>", entry.image, entry.data_font_family, '"');




                //var options = String.format("<option data-content='<span class={2}text{2} style={2}font-family:'{1}';{2}</span>' data-tokens='{1}'>{1}</option>", entry.image, entry.data_font_family, '"');


                //                var options = '<option style="background-image:url(';
                //              options += "'/images/fonts/" + entry.image + "');" + '"' + ' data-tokens="' + entry.data_font_family + '">' + entry.data_font_family + "</option>";
                //images / fonts / { 0}); ' data-tokens='{ 1 } '>{1}</option>", entry.image, entry.data_font_family);


                var options = String.format("<option data-content=\"<span style=\"font-family:'{0}'\" class='text'>{1}</span>\">{1}</option>", entry.image, entry.data_font_family);



                self.ui.customInfopageEditItemRightContent_dropDown_Font1.append(options);
            }


        });
    },
    colorChange_BackGroundColor: function (e) {
        this.model.attributes.BackGroundColor = e.value.toRgbString();
        this.updateAll();
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }
        this.updateAll();
    },
    colorChange_Font2Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            this.model.attributes.Font2_ColorSelect = this.model.attributes.Font2_Color;
        }
        this.updateAll();
    },
    colorChange_Font3Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font3_Color = e.value;
        }
        else {
            this.model.attributes.Font3_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font3_ColorType)) {
            this.model.attributes.Font3_ColorSelect = this.model.attributes.Font3_Color;
        }
        this.updateAll();
    },
    colorChange_Font4Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font4_Color = e.value;
        }
        else {
            this.model.attributes.Font4_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font4_ColorType)) {
            this.model.attributes.Font4_ColorSelect = this.model.attributes.Font4_Color;
        }
        this.updateAll();
    },
    modifyRest: function () {
        this.model.attributes.BackGroundType = this.ui.customInfopageEditItemRightContent_select_BackGroundType.val();
        this.model.attributes.BackGroundImagePosition = this.ui.customInfopageEditItemRightContent_dropDown_backGroundImagePosition.val();

        this.model.attributes.Font1_FontType = this.ui.customInfopageEditItemRightContent_select_Font1_FontType.val();
        this.model.attributes.Font2_FontType = this.ui.customInfopageEditItemRightContent_select_Font2_FontType.val();
        this.model.attributes.Font3_FontType = this.ui.customInfopageEditItemRightContent_select_Font3_FontType.val();
        this.model.attributes.Font4_FontType = this.ui.customInfopageEditItemRightContent_select_Font4_FontType.val();

        this.model.attributes.Font1_FontApp = this.ui.customInfopageEditItemRightContent_dropDown_FontApp1.val();
        this.model.attributes.Font2_FontApp = this.ui.customInfopageEditItemRightContent_dropDown_FontApp2.val();
        this.model.attributes.Font3_FontApp = this.ui.customInfopageEditItemRightContent_dropDown_FontApp3.val();
        this.model.attributes.Font4_FontApp = this.ui.customInfopageEditItemRightContent_dropDown_FontApp4.val();

        this.model.attributes.Font1_Size = this.ui.customInfopageEditItemRightContent_dropDown_Font_Size1.val();
        this.model.attributes.Font2_Size = this.ui.customInfopageEditItemRightContent_dropDown_Font_Size2.val();
        this.model.attributes.Font3_Size = this.ui.customInfopageEditItemRightContent_dropDown_Font_Size3.val();
        this.model.attributes.Font4_Size = this.ui.customInfopageEditItemRightContent_dropDown_Font_Size4.val();

        this.model.attributes.Font1_LineHeight = this.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight1.val();
        this.model.attributes.Font2_LineHeight = this.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight2.val();
        this.model.attributes.Font3_LineHeight = this.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight3.val();
        this.model.attributes.Font4_LineHeight = this.ui.customInfopageEditItemRightContent_dropDown_Font_LineHeight4.val();

        this.model.attributes.Font1_Style = this.ui.customInfopageEditItemRightContent_dropDown_Font_Style1.val();
        this.model.attributes.Font2_Style = this.ui.customInfopageEditItemRightContent_dropDown_Font_Style2.val();
        this.model.attributes.Font3_Style = this.ui.customInfopageEditItemRightContent_dropDown_Font_Style3.val();
        this.model.attributes.Font4_Style = this.ui.customInfopageEditItemRightContent_dropDown_Font_Style4.val();

        this.model.attributes.Font1_ColorType = this.ui.customInfopageEditItemRightContent_select_Font1_ColorType.val();
        this.model.attributes.Font2_ColorType = this.ui.customInfopageEditItemRightContent_select_Font2_ColorType.val();
        this.model.attributes.Font3_ColorType = this.ui.customInfopageEditItemRightContent_select_Font3_ColorType.val();
        this.model.attributes.Font4_ColorType = this.ui.customInfopageEditItemRightContent_select_Font4_ColorType.val();


        this.updateAll();
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf(".");
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1, filename.length).toLowerCase();

            switch (ext) {
                case 'gif': return true; break;
                case 'jpg': return true; break;
                case 'png': return true; break;
                case 'bmp': return true; break;
                case 'tif': return true; break;
                case 'tiff': return true; break;
            }
        }

        return false;
    },
    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();

        if (this.validateExt(filename)) {

            //remove extension
            var dotPosition = filename.lastIndexOf(".");
            if (dotPosition > 0) {
                filename = filename.substring(0, dotPosition);
            }

            this.file = FileAPI.getFiles(event)[0];

            if (this.file) {


                if (this.file.size < 40000000) {

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        self.ui.customInfopageEditItemRightContent_image_background.attr('src', e.target.result);
                        self.model.attributes.BackGroundImage = e.target.result;
                        self.updateAll();

                    }
                    reader.readAsDataURL(this.file);
                }
            }
        }
    },
    btnImageDelete: function (event) {
        this.ui.customInfopageEditItemRightContent_image_background.attr('src', '');
        self.model.attributes.BackGroundImage = "";
    },
    change_Font1_FontType: function () {
        this.model.set({
            Font1_FontType: this.ui.customInfopageEditItemRightContent_select_Font1_FontType.val()
        });

        this.model.set({
            Font1: helper_infopage.getSelectedFontApp(this.app, this.model.attributes.Font1_FontType, this.model.attributes.Font1_Select, this.model.attributes.Font_App)
        });

        this.ui.customInfopageEditItemRightContent_dropDown_Font1.trigger('setFont', this.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font2_FontType: function () {
        this.model.set({
            Font2_FontType: this.ui.customInfopageEditItemRightContent_select_Font2_FontType.val()
        });

        this.model.set({
            Font2: helper_infopage.getSelectedFontApp(this.app, this.model.attributes.Font2_FontType, this.model.attributes.Font2_Select, this.model.attributes.Font_App)
        });

        this.ui.customInfopageEditItemRightContent_dropDown_Font2.trigger('setFont', this.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font3_FontType: function () {
        this.model.set({
            Font3_FontType: this.ui.customInfopageEditItemRightContent_select_Font3_FontType.val()
        });

        this.model.set({
            Font3: helper_infopage.getSelectedFontApp(this.app, this.model.attributes.Font3_FontType, this.model.attributes.Font3_Select, this.model.attributes.Font_App)
        });

        this.ui.customInfopageEditItemRightContent_dropDown_Font3.trigger('setFont', this.model.attributes.Font3);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font3_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font3.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font3.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font3.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font3.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font4_FontType: function () {
        this.model.set({
            Font4_FontType: this.ui.customInfopageEditItemRightContent_select_Font4_FontType.val()
        });

        this.model.set({
            Font4: helper_infopage.getSelectedFontApp(this.app, this.model.attributes.Font4_FontType, this.model.attributes.Font4_Select, this.model.attributes.Font_App)
        });

        this.ui.customInfopageEditItemRightContent_dropDown_Font4.trigger('setFont', this.model.attributes.Font4);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font4_FontType)) {
            case false: this.ui.customInfopageEditItemRightContent_formgroup_Font4.addClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font4.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.customInfopageEditItemRightContent_formgroup_Font4.removeClass('disabledFontSelect_FormGroup');
                this.ui.customInfopageEditItemRightContent_formgroup_Font4.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font1_ColorType: function () {
        this.model.set({
            Font1_ColorType: this.ui.customInfopageEditItemRightContent_select_Font1_ColorType.val()
        });

        this.model.set({
            Font1_Color: helper_infopage.getSelectedColorApp(this.app, this.model.attributes.Font1_ColorType, this.model.attributes.Font1_ColorSelect, this.model.attributes.Font_ColorApp)
        });

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable();
        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font1_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.modifyRest();
    },
    change_Font2_ColorType: function () {
        this.model.set({
            Font2_ColorType: this.ui.customInfopageEditItemRightContent_select_Font2_ColorType.val()
        });

        this.model.set({
            Font2_Color: helper_infopage.getSelectedColorApp(this.app, this.model.attributes.Font2_ColorType, this.model.attributes.Font2_ColorSelect, this.model.attributes.Font_ColorApp)
        });

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable();
        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font2_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }

        this.modifyRest();
    },
    change_Font3_ColorType: function () {
        this.model.set({
            Font3_ColorType: this.ui.customInfopageEditItemRightContent_select_Font3_ColorType.val()
        });

        this.model.set({
            Font3_Color: helper_infopage.getSelectedColorApp(this.app, this.model.attributes.Font3_ColorType, this.model.attributes.Font3_ColorSelect, this.model.attributes.Font_ColorApp)
        });

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').enable();
        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font3_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font3_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').disable(); break;
        }

        this.modifyRest();
    },
    change_Font4_ColorType: function () {
        this.model.set({
            Font4_ColorType: this.ui.customInfopageEditItemRightContent_select_Font4_ColorType.val()
        });

        this.model.set({
            Font4_Color: helper_infopage.getSelectedColorApp(this.app, this.model.attributes.Font4_ColorType, this.model.attributes.Font4_ColorSelect, this.model.attributes.Font_ColorApp)
        });

        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker('colorpicker').enable();
        this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font4_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font4_ColorType)) {
            case true: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color.colorpicker('colorpicker').disable(); break;
        }

        this.modifyRest();
    },
}, Backbone.Radio.Requests);
