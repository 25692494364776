var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customWebpageList_empty"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="dias-tab" data-toggle="tab" href="#dias" role="tab" aria-controls="home-1" aria-selected="true">'+
((__t=( resources['X100385'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="css-tab" data-toggle="tab" href="#css" role="tab" aria-controls="profile-1" aria-selected="false">'+
((__t=( resources['X101484'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="css-tab" data-toggle="tab" href="#frontpage" role="tab" aria-controls="profile-2" aria-selected="false">'+
((__t=( resources['X101483'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content" style="padding-left:0px; padding-right:0px;padding-top:0px"> <div class="tab-pane fade show active" id="dias" role="tabpanel" aria-labelledby="dias-tab"> <div class="media"> <div class="media-body"> <div class="card" style="background-color:rgb(234, 234, 234) !important"> <div class="card-body" style="padding-left:2px;padding-right:2px; padding-top:0px"> <div id="customWebpageEditItemListTop"></div> <div id="customWebpageEditItemList"></div> <div id="customWebpageEditItemListBottom"></div> </div> </div> </div> </div> </div> <div class="tab-pane fade" id="css" role="tabpanel" aria-labelledby="css-tab"> <div class="media"> <div class="media-body"> <div class="card" style="background-color:rgb(234, 234, 234) !important"> <div class="card-body" style="padding-left:2px;padding-right:2px; padding-top:0px"> <div class="mt-4"> <div class="accordion accordion-bordered" id="accordion-2" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-5"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5"> '+
((__t=( resources['X101485'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-5" class="collapse" role="tabpanel" aria-labelledby="heading-5" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <label for="customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font1">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <input id="customWebpageEditItemRightContent_dropDown_Font1"> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font_Size1">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_dropDown_Font_Size1" class="form-control"></select> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font_Style1">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_dropDown_Font_Style1" class="form-control"></select> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font_LineHeight1">'+
((__t=( resources['X101327'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_dropDown_Font_LineHeight1" class="form-control"></select> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-7"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-7" aria-expanded="true" aria-controls="collapse-7"> '+
((__t=( resources['X101322'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-7" class="collapse" role="tabpanel" aria-labelledby="heading-7" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <label for="customWebpageEditItemRightContent_ColorPickerSliders_Font2_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customWebpageEditItemRightContent_ColorPickerSliders_Font2_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font2">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <input id="customWebpageEditItemRightContent_dropDown_Font2"> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font_Size2">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_dropDown_Font_Size2" class="form-control"></select> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font_Style2">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_dropDown_Font_Style2" class="form-control"></select> </div> <div class="form-group"> <label for="customWebpageEditItemRightContent_dropDown_Font_LineHeight2">'+
((__t=( resources['X101327'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_dropDown_Font_LineHeight2" class="form-control"></select> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade" id="frontpage" role="tabpanel" aria-labelledby="css-tab"> <div class="media"> <div class="media-body"> <div class="card" style="background-color:rgb(234, 234, 234) !important"> <div class="card-body" style="padding-left:2px;padding-right:2px; padding-top:0px"> <div class="mt-4"> <div class="accordion accordion-bordered" id="accordion-3" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-8"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8"> '+
((__t=( resources['X100449'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-8" class="collapse" role="tabpanel" aria-labelledby="heading-8" data-parent="#accordion-3"> <div class="card-body">  <div class="form-group"> <div class="d-flex justify-content-between align-items-center"> <div> <div class="d-flex align-items-center"> <h4>'+
((__t=( resources['X101483'] ))==null?'':__t)+
'</h4> </div> </div> <div> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customWebpageEditItemRightContent_checkbox_EnableWindowPage"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <p> '+
((__t=( resources['X101486'] ))==null?'':__t)+
' </p> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-7"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7"> '+
((__t=( resources['X101315'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-7" class="collapse" role="tabpanel" aria-labelledby="heading-7" data-parent="#accordion-3"> <div class="card-body"> <div class="form-group"> <label for="customWebpageEditItemRightContent_select_BGType">'+
((__t=( resources['X101328'] ))==null?'':__t)+
'</label> <select id="customWebpageEditItemRightContent_select_BGType" class="form-control"></select> </div> <img id="customWebpageEditItemRightContent_image_background" class="mr-3 rounded" style="max-width:100%;width:100%;height:auto" src=""> <label type="button" for="customWebpageEditItemRightContent_btnImage" class="btn btn-sm col-sm-12 btn-secondary">'+
((__t=( resources['X100295'] ))==null?'':__t)+
'</label> <div> <input id="customWebpageEditItemRightContent_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div>  <div style="height:10px"></div> <div class="form-group"> <label for="customWebpageEditItemRightContent_ColorPickerSliders_BGColor">'+
((__t=( resources['X101318'] ))==null?'':__t)+
'</label> <div id="customWebpageEditItemRightContent_ColorPickerSliders_BGColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div>  </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
