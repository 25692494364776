'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';
import FilePondPluginImageSizeMetaData from '../../plugins/filepond-plugin-image-size-metadata';


import tpl_customDepartmentInsert from './tpl/customDepartmentInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customDepartmentInsert();
    },
    initialize: function () {
        this.app = this.options.app;

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.ImageSelected = false;
        this.localSetting.attributes.AspectText = resources.X101203;
    },
    ui: {
        'customDepartmentInsert_btnSubmit': '#customDepartmentInsert_btnSubmit',
        'customDepartmentInsert_inputName': '#customDepartmentInsert_inputName',
        'customDepartmentInsert_filePond': '#customDepartmentInsert_filePond',
        'customDepartmentInsert_image': '#customDepartmentInsert_image',
        'customDepartmentInsert_btn_crop_zoom_plus': '#customDepartmentInsert_btn_crop_zoom_plus',
        'customDepartmentInsert_btn_crop_zoom_minus': '#customDepartmentInsert_btn_crop_zoom_minus',
        'customDepartmentInsert_btn_crop_move_left': '#customDepartmentInsert_btn_crop_move_left',
        'customDepartmentInsert_btn_crop_move_right': '#customDepartmentInsert_btn_crop_move_right',
        'customDepartmentInsert_btn_crop_move_top': '#customDepartmentInsert_btn_crop_move_top',
        'customDepartmentInsert_btn_crop_move_buttom': '#customDepartmentInsert_btn_crop_move_buttom',
        'customDepartmentInsert_btn_crop_rotate_left': '#customDepartmentInsert_btn_crop_rotate_left',
        'customDepartmentInsert_btn_crop_rotate_right': '#customDepartmentInsert_btn_crop_rotate_right',
        'customDepartmentInsert_btn_crop_scale_x': '#customDepartmentInsert_btn_crop_scale_x',
        'customDepartmentInsert_btn_crop_scale_y': '#customDepartmentInsert_btn_crop_scale_y'
    },
    events: {
        'click @ui.customDepartmentInsert_btnSubmit': 'btnSubmitClick',
        'click @ui.customDepartmentInsert_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.customDepartmentInsert_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.customDepartmentInsert_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.customDepartmentInsert_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.customDepartmentInsert_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.customDepartmentInsert_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.customDepartmentInsert_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.customDepartmentInsert_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.customDepartmentInsert_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.customDepartmentInsert_btn_crop_scale_y': 'btnScaleYClick'

    },
    childEvents: {
    },
    onRender: function () {

    },
    onAttach() {
        var self = this;
        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageSizeMetaData
        );

        var labelIdle = resources.X200141 + ' <span class="filepond--label-action">' + resources.X200142 + '</span>';

        var pond = FilePond.create(this.ui.customDepartmentInsert_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            imageResizeTargetWidth: 350,
            imageResizeTargetHeight: null,
            imageResizeMode: 'contain',
            imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            /*
             * allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,
             */

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();

                    self.localSetting.attributes.ImageSelected = true;

                    if (self.cropper) {
                        self.cropper.destroy();
                    }
                    self.ui.customDepartmentInsert_image.attr('src', base64);
                    //self.ui.customDepartmentInsert_roundImage.attr('src', base64);

                    //var mimeType = self.base64MimeType(base64);
                    //var fileData = base64.split(',')[1];

                    self.localSetting.attributes.FileName = fileItem.filenameWithoutExtension;

                    //var previews = document.querySelectorAll('.cropper-img-preview');
                    //var imageDefault = document.querySelector('#customDepartmentInsert_image');

                    //self.cropper = new Cropper(imageDefault, {
                    //    aspectRatio: NaN,
                    //    autoCropArea: 1,
                    //    zoomOnTouch: false,
                    //    zoomOnWheel: false,
                    //    cropBoxResizable: true,
                    //    build: function () {
                    //        var clone = this.cloneNode();

                    //        clone.style.cssText = (
                    //            'display: block;' +
                    //            'width: 100%;' +
                    //            'minWidth: 0;' +
                    //            'minHeight: 0;' +
                    //            'maxWidth: none;' +
                    //            'maxHeight: none;'
                    //        );
                    //    },

                    //    crop: function (e) {
                    //        var data = e.detail;
                    //        var cropper = this.cropper;
                    //        var imageData = cropper.getImageData();
                    //        var previewAspectRatio = data.width / data.height;
                    //    }
                    //});

                    //self.setupBtn();

                    this.setupCropper();
                    pond.removeFile(fileItem);
                };
            }
        });

        this.setupCropper();
    },
    setupCropper: function () {
        if (this.localSetting.attributes.ImageSelected == true) {
            var self = this;


            if (this.cropper) {
                this.cropper.destroy();
            }

            var imageDefault = document.querySelector('#customDepartmentInsert_image');

            var cropBoxData;
            var canvasData;



            this.cropper = new Cropper(imageDefault, {
                aspectRatio: NaN,
                autoCropArea: 1,
                zoomOnTouch: false,
                zoomOnWheel: false,
                cropBoxResizable: true,
                build: function () {
                    var clone = this.cloneNode();

                    clone.style.cssText = (
                        'display: block;' +
                        'width: 100%;' +
                        'minWidth: 0;' +
                        'minHeight: 0;' +
                        'maxWidth: none;' +
                        'maxHeight: none;'
                    );
                },

                crop: function (e) {
                    self.localSetting.attributes.ImageSelected = true;
                    //var data = e.detail;
                    //var cropper = this.cropper;
                    //var imageData = cropper.getImageData();
                    //var previewAspectRatio = data.width / data.height;
                }
            });
        }
    },

    btnSubmitClick: function () {
        var self = this;

        var tmpModel = new this.app.entities.collections.customDepartment.model();
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.ui.customDepartmentInsert_inputName.val();

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 4 })[0];
        var strJson_4 = JSON.stringify(defaultModel.toJSON());

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 5 })[0];
        var strJson_5 = JSON.stringify(defaultModel.toJSON());

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 10 })[0];
        var strJson_10 = JSON.stringify(defaultModel.toJSON());

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 20 })[0];
        var strJson_20 = JSON.stringify(defaultModel.toJSON());

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 30 })[0];
        var strJson_30 = JSON.stringify(defaultModel.toJSON());

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 42 })[0];
        var strJson_42 = JSON.stringify(defaultModel.toJSON());

        var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 110 })[0];
        var strJson_110 = JSON.stringify(defaultModel.toJSON());

        tmpModel.attributes.Data = '[{ "Collection" : [' + strJson_4 + ',' + strJson_5 + ',' + strJson_10 + ',' + strJson_20 + ',' + strJson_30 + ',' + strJson_42 + ',' + strJson_110 +']}]';

        tmpModel.attributes.BackgroundColor = 'rgba(160, 16, 16, 0.65)';
        tmpModel.attributes.Module_DigitalClock = 1;
        tmpModel.attributes.TimerView = 10;
        tmpModel.attributes.PersonOnView = 10;
        
        
        this.getImageData(tmpModel);


        if (!tmpModel.validateNoBackbone(this.app)) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200101;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

            var callback = function (id) {
                dialogWaiting.triggerMethod('dialog:close');
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked");
            self.app.dialogRegion.show(dialogWaiting);

            self.app.entities.collections.customDepartment.create(tmpModel, {
                wait: true,
                success: function (response) {
                    self.hideErrors();
                    self.ui.customDepartmentInsert_inputName.val("");
                    if (tmpModel.attributes.ImageUrl != '') {
                        self.cropper.destroy();
                    }
                    self.ui.customDepartmentInsert_image.attr('src', '/images/cropper.png');
                    self.localSetting.attributes.ImageSelected = false;
                    dialogWaiting.triggerMethod('dialog:close');
                }
            });
        } else {
            this.showErrors(tmpModel.errors);
        }
    },
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    getWeboptimizeImage: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            if (cropBoxData.width != undefined) {
                var aspectRatio = /*cropBoxData.height /*/ cropBoxData.width / cropBoxData.height;
                height = 100;
                width = height * aspectRatio;
                //height = width * aspectRatio;

                var croppedCanvas;
                var canvas;
                var image;

                // Crop
                croppedCanvas = this.cropper.getCroppedCanvas();

                // Round
                canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

                var struct = {
                    width: width,
                    height: height,
                    data: canvas.toDataURL('image/png', 0.5)
                }

                return struct;
            }
            return null;
        }
    },
    getImageData: function (tmpModel) {
        var searchString = ";base64,";
        var pos = 0;
        tmpModel.attributes.ImageData = '';

        var image515 = this.getWeboptimizeImage(200, 100);

        if (image515 != null) {
            var imageData512 = image515.data;
            pos = imageData512.indexOf(searchString);
            var imageMimeType512 = imageData512.substring(5, pos);
            imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            tmpModel.attributes.ImageFilename = 'Department';//resources.X200122;
            tmpModel.attributes.ImageData = imageData512;
        } else {
            tmpModel.attributes.ImageFilename = '';
            tmpModel.attributes.ImageData = '';
        }
    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    }

});