﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialog2Button2 from './tpl/dialog2Button2.html';

export default Marionette.ItemView.extend({
    dialogRegion2: '#dialog2Button_modal',
    getTemplate: function () {
        return tpl_dialog2Button2({ model: this.model.toJSON() });
    },
    events: {
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02"
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    }
});