var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="schemaScreen_empty"> <div class="modal fade" id="schemaScreen_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101456'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X101392'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home4-tab-1" data-toggle="tab" href="#home4-1" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home4-1" role="tabpanel" aria-labelledby="home4-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-2"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2"> '+
((__t=( resources['X101456'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-2" class="collapse" role="tabpanel" aria-labelledby="heading-2" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist">  <li class="nav-item"> <a class="nav-link active" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home-2" aria-selected="true">'+
((__t=( resources['X101393'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-3" data-toggle="tab" href="#home-3" role="tab" aria-controls="home-3" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home-2" role="tabpanel" aria-labelledby="home-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_select_Font1_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="schemaScreen_select_Font1_FontType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="schemaScreen_dropDown_Font1">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <div id="schemaScreen_formgroup_Font1" class="form-group"> <input id="schemaScreen_dropDown_Font1" class="form-control"> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_select_Font1_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="schemaScreen_select_Font1_ColorType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="schemaScreen_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_dropDown_Font1_Size">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="schemaScreen_dropDown_Font1_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_dropDown_Font1_Style">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="schemaScreen_dropDown_Font1_Style" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_ColorPickerSliders_Even_Color">'+
((__t=( resources['X101454'] ))==null?'':__t)+
'</label> <div id="schemaScreen_ColorPickerSliders_Even_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_ColorPickerSliders_Odd_Color">'+
((__t=( resources['X101455'] ))==null?'':__t)+
'</label> <div id="schemaScreen_ColorPickerSliders_Odd_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-3" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin1_Top"> <div class="control-label"> <label for="schemaScreen_input_Margin1_Top">'+
((__t=( resources['X101377'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Top))==null?'':__t)+
'" id="schemaScreen_input_Margin1_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin1_Bottom"> <div class="control-label"> <label for="schemaScreen_input_Margin1_Bottom">'+
((__t=( resources['X101378'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Bottom))==null?'':__t)+
'" id="schemaScreen_input_Margin1_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin1_Left"> <div class="control-label"> <label for="schemaScreen_input_Margin1_Left">'+
((__t=( resources['X101379'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Left))==null?'':__t)+
'" id="schemaScreen_input_Margin1_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin1_Right"> <div class="control-label"> <label for="schemaScreen_input_Margin1_Right">'+
((__t=( resources['X101380'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Right))==null?'':__t)+
'" id="schemaScreen_input_Margin1_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-3"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3"> '+
((__t=( resources['X101457'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-3" class="collapse" role="tabpanel" aria-labelledby="heading-3" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist">  <li class="nav-item"> <a class="nav-link active" id="homeb-tab-2" data-toggle="tab" href="#homeb-2" role="tab" aria-controls="home-2" aria-selected="true">'+
((__t=( resources['X101393'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homeb-tab-3" data-toggle="tab" href="#homeb-3" role="tab" aria-controls="homeb-3" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content">  <div class="tab-pane fade show active" id="homeb-2" role="tabpanel" aria-labelledby="homeb-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_select_Font2_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="schemaScreen_select_Font2_FontType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="schemaScreen_dropDown_Font2">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <div id="schemaScreen_formgroup_Font2" class="form-group"> <input id="schemaScreen_dropDown_Font2" class="form-control"> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_select_Font2_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="schemaScreen_select_Font2_ColorType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_ColorPickerSliders_Font2_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="schemaScreen_ColorPickerSliders_Font2_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_dropDown_Font2_Size">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="schemaScreen_dropDown_Font2_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="schemaScreen_dropDown_Font2_Style">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="schemaScreen_dropDown_Font2_Style" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homeb-3" role="tabpanel" aria-labelledby="homeb-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin2_Top"> <div class="control-label"> <label for="schemaScreen_input_Margin2_Top">'+
((__t=( resources['X101377'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Top))==null?'':__t)+
'" id="schemaScreen_input_Margin2_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin2_Bottom"> <div class="control-label"> <label for="schemaScreen_input_2_Bottom">'+
((__t=( resources['X101378'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Bottom))==null?'':__t)+
'" id="schemaScreen_input_Margin2_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin2_Left"> <div class="control-label"> <label for="schemaScreen_input_Margin2_Left">'+
((__t=( resources['X101379'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Left))==null?'':__t)+
'" id="schemaScreen_input_Margin2_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group schemaScreen_input_Margin2_Right"> <div class="control-label"> <label for="schemaScreen_input_Margin2_Right">'+
((__t=( resources['X101380'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin2_Right))==null?'':__t)+
'" id="schemaScreen_input_Margin2_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="schemaScreen_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="schemaScreen_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
