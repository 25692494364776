'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';
import FilePondPluginImageSizeMetaData from '../../plugins/filepond-plugin-image-size-metadata';


import tpl_customAdImageInsert from './tpl/customAdImageInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_DialogProcess from '../../../lib/views/dialogProcess/dialogProcess';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customAdImageInsert();
    },
    initialize: function () {
        this.app = this.options.app;
        this.options.localSetting.attributes.ImageSelected = false;
    },
    ui: {
        'customAdImageInsert_filePond': '#customAdImageInsert_filePond',
        'customAdImageInsert_btnSubmit': '#customAdImageInsert_btnSubmit',
        'customAdImageInsert_btnImage': '#customAdImageInsert_btnImage',
        'customAdImageInsert_btnTEST': '#customAdImageInsert_btnTEST',
        'customAdImageInsert_div_clone': '#customAdImageInsert_div_clone',
        'customAdImageInsert_inputName': '#customAdImageInsert_inputName',
        'customAdImageInsert_image': '#customAdImageInsert_image',
        'customAdImageInsert_roundImage': '#customAdImageInsert_roundImage',
        'customAdImageInsert_input_ImageOriginal': '#customAdImageInsert_input_ImageOriginal',
        'customAdImageInsert_input_ImageWebOptimize500': '#customAdImageInsert_input_ImageWebOptimize500',
        'customAdImageInsert_input_ImageWebOptimize1000': '#customAdImageInsert_input_ImageWebOptimize1000',
        'customAdImageInsert_input_ImageWebOptimizeRound500': '#customAdImageInsert_input_ImageWebOptimizeRound500',
        'customAdImageInsert_input_ImageWebOptimizeRound1000': '#customAdImageInsert_input_ImageWebOptimizeRound1000',
        'customAdImageInsert_input_ImageWebOptimizeAd': '#customAdImageInsert_input_ImageWebOptimizeAd',
        'customAdImageInsert_div_ImageWebOptimizeAd': '#customAdImageInsert_div_ImageWebOptimizeAd',
        'customAdImageInsert_btn_crop_zoom_plus': '#customAdImageInsert_btn_crop_zoom_plus',
        'customAdImageInsert_btn_crop_zoom_minus': '#customAdImageInsert_btn_crop_zoom_minus',
        'customAdImageInsert_btn_crop_move_left': '#customAdImageInsert_btn_crop_move_left',
        'customAdImageInsert_btn_crop_move_right': '#customAdImageInsert_btn_crop_move_right',
        'customAdImageInsert_btn_crop_move_top': '#customAdImageInsert_btn_crop_move_top',
        'customAdImageInsert_btn_crop_move_buttom': '#customAdImageInsert_btn_crop_move_buttom',
        'customAdImageInsert_btn_crop_rotate_left': '#customAdImageInsert_btn_crop_rotate_left',
        'customAdImageInsert_btn_crop_rotate_right': '#customAdImageInsert_btn_crop_rotate_right',
        'customAdImageInsert_btn_crop_scale_x': '#customAdImageInsert_btn_crop_scale_x',
        'customAdImageInsert_btn_crop_scale_y': '#customAdImageInsert_btn_crop_scale_y',
        'customAdImageInsert_btn_crop_aspectRatio_5_5': '#customAdImageInsert_btn_crop_aspectRatio_5_5',
        'customAdImageInsert_btn_crop_aspectRatio_4_3': '#customAdImageInsert_btn_crop_aspectRatio_4_3',
        'customAdImageInsert_btn_crop_aspectRatio_3_4': '#customAdImageInsert_btn_crop_aspectRatio_3_4',
        'customAdImageInsert_btn_crop_aspectRatio_6_4': '#customAdImageInsert_btn_crop_aspectRatio_6_4',
        'customAdImageInsert_btn_crop_aspectRatio_4_6': '#customAdImageInsert_btn_crop_aspectRatio_4_6',
        'customAdImageInsert_btn_crop_aspectRatio_Free': '#customAdImageInsert_btn_crop_aspectRatio_Free'


    },
    events: {
        'click @ui.customAdImageInsert_btnSubmit': 'btnSubmitClick',
        'change @ui.customAdImageInsert_btnImage': 'btnImageChange',
        'click @ui.customAdImageInsert_btnTEST': 'btnTESTClick',
        'change @ui.customAdImageInsert_input_ImageOriginal': 'input_ImageOriginalChange',
        'change @ui.customAdImageInsert_input_ImageWebOptimize500': 'input_ImageWebOptimize500Change',
        'change @ui.customAdImageInsert_input_ImageWebOptimize1000': 'input_ImageWebOptimize1000Change',
        'change @ui.customAdImageInsert_input_ImageWebOptimizeRound500': 'input_ImageWebOptimizeRound500Change',
        'change @ui.customAdImageInsert_input_ImageWebOptimizeRound1000': 'input_ImageWebOptimizeRound1000Change',
        'change @ui.customAdImageInsert_input_ImageWebOptimizeAd': 'input_ImageWebOptimizeAdChange',
        'click @ui.customAdImageInsert_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.customAdImageInsert_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.customAdImageInsert_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.customAdImageInsert_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.customAdImageInsert_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.customAdImageInsert_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.customAdImageInsert_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.customAdImageInsert_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.customAdImageInsert_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.customAdImageInsert_btn_crop_scale_y': 'btnScaleYClick',
        'click @ui.customAdImageInsert_btn_crop_aspectRatio_5_5': 'btnAspectRatio_5_5_Click',
        'click @ui.customAdImageInsert_btn_crop_aspectRatio_4_3': 'btnAspectRatio_4_3_Click',
        'click @ui.customAdImageInsert_btn_crop_aspectRatio_3_4': 'btnAspectRatio_3_4_Click',
        'click @ui.customAdImageInsert_btn_crop_aspectRatio_6_4': 'btnAspectRatio_6_4_Click',
        'click @ui.customAdImageInsert_btn_crop_aspectRatio_4_6': 'btnAspectRatio_4_6_Click',
        'click @ui.customAdImageInsert_btn_crop_aspectRatio_Free': 'btnAspectRatio_Free_Click'
    },
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    btnAspectRatio_5_5_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(1);
    },
    btnAspectRatio_4_3_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(4 / 3);
    },
    btnAspectRatio_3_4_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(3 / 4);
    },
    btnAspectRatio_6_4_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(6 / 4);
    },
    btnAspectRatio_4_6_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(4 / 6);
    },
    btnAspectRatio_Free_Click: function (event) {
        event.preventDefault();
        this.cropper.setAspectRatio(NaN);
    },
    onRender: function () {
        var self = this;

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageOriginal' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customAdImageInsert_input_ImageOriginal.attr('checked', checked);

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize500' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customAdImageInsert_input_ImageWebOptimize500.attr('checked', checked);

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize1000' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customAdImageInsert_input_ImageWebOptimize1000.attr('checked', checked);

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound500' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customAdImageInsert_input_ImageWebOptimizeRound500.attr('checked', checked);

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound1000' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customAdImageInsert_input_ImageWebOptimizeRound1000.attr('checked', checked);

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeAd' });
        var checked = customSetting[0].attributes.Value == '1' ? true : false;
        this.ui.customAdImageInsert_input_ImageWebOptimizeAd.attr('checked', checked);

        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
            this.ui.customAdImageInsert_div_ImageWebOptimizeAd.css('display', 'none');
        }

        this.setupBtn();
    },
    onAttach() {
        var self = this;
        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageSizeMetaData
        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.customAdImageInsert_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();

                    self.options.localSetting.attributes.ImageSelected = true;

                    if (self.cropper) {
                        self.cropper.destroy();
                    }
                    self.ui.customAdImageInsert_image.attr('src', base64);
                    //self.ui.customImageInsert_roundImage.attr('src', base64);

                    //var mimeType = self.base64MimeType(base64);
                    //var fileData = base64.split(',')[1];

                    self.options.localSetting.attributes.FileName = fileItem.filenameWithoutExtension;

                    var previews = document.querySelectorAll('.cropper-img-preview');
                    var imageDefault = document.querySelector('#customAdImageInsert_image');

                    self.cropper = new Cropper(imageDefault, {
                        aspectRatio: 6 / 1,
                        autoCropArea: 1,
                        zoomOnTouch: false,
                        zoomOnWheel: false,
                        cropBoxResizable: true,
                        build: function () {
                            var clone = this.cloneNode();

                            clone.style.cssText = (
                                'display: block;' +
                                'width: 100%;' +
                                'minWidth: 0;' +
                                'minHeight: 0;' +
                                'maxWidth: none;' +
                                'maxHeight: none;'
                            );
                        },

                        crop: function (e) {
                            var data = e.detail;
                            var cropper = this.cropper;
                            var imageData = cropper.getImageData();
                            var previewAspectRatio = data.width / data.height;
                        }
                    });

                    self.setupBtn();

                    pond.removeFile(fileItem);
                };
            }
        });
    },
    setupBtn: function () {
        //if (this.getCount() == 0 || this.options.localSetting.attributes.ImageSelected == false) {
        //    this.ui.customAdImageInsert_btnSubmit.prop("disabled", true);
        //    this.ui.customAdImageInsert_btnSubmit.addClass('btn-disabled');
        //    this.ui.customAdImageInsert_btnSubmit.removeClass('btn-primary');
        //    this.ui.customAdImageInsert_btnSubmit.addClass('btn-secondary');

        //} else {
        this.ui.customAdImageInsert_btnSubmit.prop("disabled", false);
        this.ui.customAdImageInsert_btnSubmit.removeClass('btn-disabled');
        this.ui.customAdImageInsert_btnSubmit.addClass('btn-primary');
        this.ui.customAdImageInsert_btnSubmit.removeClass('btn-secondary');
        //}
    },
    input_ImageOriginalChange: function (event) {
        event.preventDefault();
        var self = this;

        var checked = 1;
        this.ui.customAdImageInsert_input_ImageOriginal.is(':checked') ? checked = '1' : checked = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageOriginal' });
        customSetting[0].attributes.Value = checked;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupBtn();
            }
        });
    },
    input_ImageWebOptimize500Change: function (event) {
        event.preventDefault();
        var self = this;

        var checked = 1;
        this.ui.customAdImageInsert_input_ImageWebOptimize500.is(':checked') ? checked = '1' : checked = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize500' });
        customSetting[0].attributes.Value = checked;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupBtn();
            }
        });
    },
    input_ImageWebOptimize1000Change: function (event) {
        event.preventDefault();
        var self = this;

        var checked = 1;
        this.ui.customAdImageInsert_input_ImageWebOptimize1000.is(':checked') ? checked = '1' : checked = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize1000' });
        customSetting[0].attributes.Value = checked;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupBtn();
            }
        });
    },
    input_ImageWebOptimizeRound500Change: function (event) {
        event.preventDefault();
        var self = this;

        var checked = 1;
        this.ui.customAdImageInsert_input_ImageWebOptimizeRound500.is(':checked') ? checked = '1' : checked = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound500' });
        customSetting[0].attributes.Value = checked;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupBtn();
            }
        });
    },
    input_ImageWebOptimizeRound1000Change: function (event) {
        event.preventDefault();
        var self = this;

        var checked = 1;
        this.ui.customAdImageInsert_input_ImageWebOptimizeRound1000.is(':checked') ? checked = '1' : checked = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound1000' });
        customSetting[0].attributes.Value = checked;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupBtn();
            }
        });
    },
    input_ImageWebOptimizeAdChange: function (event) {
        event.preventDefault();
        var self = this;

        var checked = 1;
        this.ui.customAdImageInsert_input_ImageWebOptimizeAd.is(':checked') ? checked = '1' : checked = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeAd' });
        customSetting[0].attributes.Value = checked;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupBtn();
            }
        });
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf(".");
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1, filename.length).toLowerCase();

            switch (ext) {
                case 'gif': return true; break;
                case 'jpg': return true; break;
                case 'png': return true; break;
                case 'bmp': return true; break;
                case 'tif': return true; break;
                case 'tiff': return true; break;
            }
        }

        return false;
    },
    btnTESTClick: function (event) {

        //$("body").append('<div class="modal-backdrop fade in" style="position:fixed;z-index:1041; opacity:0.5;"></div>');

        event.preventDefault();

        var self = this;

        var dialogBoxModel = new Entities_Empty.Model();
        dialogBoxModel.attributes.Title = resources.X100229;
        dialogBoxModel.attributes.Text = resources.X100530;
        dialogBoxModel.attributes.Button = resources.X100279;

        var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
        var callback = function (options) {
            $("body").append('<div class="modal-backdrop fade in" style="position:fixed;z-index:1040; opacity:0.5;"></div>');
            //self.stopListening(dialogBox, "dialog:okclicked", callback);

        }
        this.listenTo(dialogBox, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogBox);
    },
    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();

        if (this.validateExt(filename)) {

            //remove extension
            var dotPosition = filename.lastIndexOf(".");
            if (dotPosition > 0) {
                filename = filename.substring(0, dotPosition);
            }

            this.file = FileAPI.getFiles(event)[0];

            if (this.file) {


                if (this.file.size < 40000000) {

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        self.options.localSetting.attributes.ImageSelected = true;

                        if (self.cropper) {
                            self.cropper.destroy();
                        }

                        //if (self.cropperRound) {
                        //    self.cropperRound.destroy();
                        //}

                        self.ui.customAdImageInsert_image.attr('src', e.target.result);
                        self.ui.customAdImageInsert_roundImage.attr('src', e.target.result);

                        var previews = document.querySelectorAll('.cropper-img-preview');
                        var imageDefault = document.querySelector('#customAdImageInsert_image');

                        self.cropper = new Cropper(imageDefault, {
                            aspectRatio: 6 / 1,
                            autoCropArea: 1,
                            zoomOnTouch: false,
                            zoomOnWheel: false,
                            cropBoxResizable: true,
                            build: function () {
                                var clone = this.cloneNode();

                                clone.style.cssText = (
                                    'display: block;' +
                                    'width: 100%;' +
                                    'minWidth: 0;' +
                                    'minHeight: 0;' +
                                    'maxWidth: none;' +
                                    'maxHeight: none;'
                                );
                            },

                            crop: function (e) {
                                var data = e.detail;
                                var cropper = this.cropper;
                                var imageData = cropper.getImageData();
                                var previewAspectRatio = data.width / data.height;
                            }
                        });

                        //var imageRound = document.querySelector('#customAdImageInsert_roundImage');
                        //var croppable = false;
                        //self.cropperRound = new Cropper(imageRound, {
                        //    aspectRatio: 1,
                        //    viewMode: 1,
                        //    built: function () {
                        //        croppable = true;
                        //        var cropper_faceRound = $('.onlyround').find('.cropper-face');
                        //        cropper_faceRound.addClass('cropper-faceRound');
                        //    }
                        //});

                        self.setupBtn();
                        //self.clone();
                    }

                    reader.readAsDataURL(this.file);

                } else {
                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100229;
                    dialogBoxModel.attributes.Text = resources.X100530;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
                    var callback = function (options) {
                        self.stopListening(dialogBox, "dialog:okclicked", callback);
                    }
                    this.listenTo(dialogBox, "dialog:okclicked", callback);
                    this.app.dialogRegion.show(dialogBox);
                }


                self.ui.customAdImageInsert_inputName.val(filename);
                var uploadFile = self.$el.find('.file-input-name');
                uploadFile.val("");

            }
        } else {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100934;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
    },
    //clone: function () {
    //    var clone = this.ui.customAdImageInsert_btnImage.clone();
    //    $('#customAdImageInsert_div_clone').html(clone);
    //},
    getOriginalImage: function () {
        if (this.cropper) {

            var cropData = this.cropper.getData();
            var height = cropData.height;
            var width = cropData.width;

            var croppedCanvas;
            var canvas;
            var image;

            // Crop
            croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: canvas.toDataURL('image/png', 0.5)
            }

            return struct;//canvas.toDataURL();
        }
    },
    getOriginalCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    getWeboptimizeImage: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            var aspectRatio = cropBoxData.height / cropBoxData.width;
            height = width * aspectRatio;

            var croppedCanvas;
            var canvas;
            var image;

            // Crop
            croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: canvas.toDataURL('image/png', 0.5)
            }

            return struct;//canvas.toDataURL();
            //return canvas.toDataURL();
        }
    },
    getWeboptimizeImageNoAspectRatio: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            var aspectRatio = cropBoxData.height / cropBoxData.width;
            //height = width * aspectRatio;

            var croppedCanvas;
            var canvas;
            var image;

            // Crop
            croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: canvas.toDataURL('image/png', 0.5)
            }

            return struct;//canvas.toDataURL();
            //return canvas.toDataURL();
        }
    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    getWeboptimizeImageRound: function (width, height) {
        if (this.cropperRound) {
            var croppedCanvas;
            var roundedCanvas;
            var roundedImage;

            // Crop
            croppedCanvas = this.cropperRound.getCroppedCanvas();

            // Round
            roundedCanvas = this.getWeboptimizeRoundedCanvas(croppedCanvas, width, height);

            var struct = {
                width: width,
                height: height,
                data: roundedCanvas.toDataURL('image/png', 0.5)
            }

            return struct;//canvas.toDataURL();
            //return roundedCanvas.toDataURL();
        }
    },
    getWeboptimizeRoundedCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI);
        context.strokeStyle = 'rgba(0,0,0,0)';
        context.stroke();
        context.clip();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    getCount: function () {
        var count = 0;
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageOriginal' });
        if (customSetting[0].attributes.Value == '1') {
            count++;
        }
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize500' });
        if (customSetting[0].attributes.Value == '1') {
            count++;
        }
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize1000' });
        if (customSetting[0].attributes.Value == '1') {
            count++;
        }
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound500' });
        if (customSetting[0].attributes.Value == '1') {
            count++;
        }
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound1000' });
        if (customSetting[0].attributes.Value == '1') {
            count++;
        }
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeAd' });
        if (customSetting[0].attributes.Value == '1') {
            count++;
        }

        return count;
    },
    saveImage: function (filename, filenameText, nameText, imageData, imageMimeType, sizeType, sizeText, sortType, uuid, strUtcDate, callback) {
        var searchString = ";base64,";
        var pos = 0;

        //var imageDataOriginal = this.getOriginalImage();
        //pos = imageDataOriginal.indexOf(searchString);
        //var imageMimeTypeOriginal = imageDataOriginal.substring(5, pos);
        //imageDataOriginal = imageDataOriginal.substring(pos + searchString.length, imageDataOriginal.length);

        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = filename;//this.ui.customAdImageInsert_inputName.val();// + ' ' + nameText;
        tmpModel.attributes.ImageData = imageData;
        tmpModel.attributes.ImageMimeType = 'image/png'; //imageMimeType;
        tmpModel.attributes.ImageUpdate = true;
        tmpModel.attributes.SizeType = sizeType;
        tmpModel.attributes.SizeText = sizeText;
        tmpModel.attributes.SortType = sortType;
        tmpModel.attributes.CommonId = uuid;
        tmpModel.attributes.UtcDate = strUtcDate;

        //tmpModel.attributes.Filename = filename + '_' + filenameText + '.jpg';
        tmpModel.attributes.Filename = filename + '.png'//'_' + filenameText + '.png';

        this.collection.fullCollection.create(tmpModel, {
            wait: true,
            success: function (response) {
                callback();
            }
        });
    },
    fillup: function (text) {
        if (text.length <= 1) {
            text = '0' + text;
        }
        return text;
    },
    btnSubmitClick: function () {
        this.hideErrors();

        //Only for check name
        /*var tmpModel = new this.collection.model();
        tmpModel.attributes.OrgName = this.ui.customAdImageInsert_inputName.val();

        tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val() + ' ' + resources.X100492;
        var ret0 = tmpModel.validateNoBackbone(app);
        tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val() + ' ' + resources.X100493;
        var ret1 = tmpModel.validateNoBackbone(app);
        tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val() + ' ' + resources.X100494;
        var ret2 = tmpModel.validateNoBackbone(app);
        tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val() + ' ' + resources.X100495;
        var ret3 = tmpModel.validateNoBackbone(app);
        tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val() + ' ' + resources.X100496;
        var ret4 = tmpModel.validateNoBackbone(app);
        tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val() + ' ' + resources.X100960;
        var ret5 = tmpModel.validateNoBackbone(app);

        var ret = false;

        if ((ret1) || (ret2) || (ret3) || (ret3) || (ret4) || (ret5)) {
            ret = true;
        }*/

        var ret = false;

        if (ret == false) {

            var self = this;

            var nowLocalDate = new Date();

            var strLocalDate = nowLocalDate.getFullYear().toString() +
                this.fillup((nowLocalDate.getMonth() + 1).toString()) +
                this.fillup(nowLocalDate.getDate().toString()) +
                this.fillup(nowLocalDate.getHours().toString()) +
                this.fillup(nowLocalDate.getMinutes().toString()) +
                this.fillup(nowLocalDate.getSeconds().toString());

            //var filename = this.ui.customAdImageInsert_inputName.val() + '_' + strLocalDate;
            var filename = resources.X101451 + " - " + this.options.localSetting.attributes.FileName;

            //var totalCount = this.getCount();
            var totalCount = 1;
            var count = 1;

            var dialogProcessModel = new Entities_Empty.Model();
            dialogProcessModel.attributes.Button = resources.X100279;
            dialogProcessModel.attributes.Title = resources.X100321;
            dialogProcessModel.attributes.Text = resources.X100297;
            dialogProcessModel.attributes.progress_count = 0;
            dialogProcessModel.attributes.progress_totalCount = totalCount;

            var dialogProcess = new View_DialogProcess({ model: dialogProcessModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogProcess, "dialog:okclicked", callback);
            }
            this.listenTo(dialogProcess, "dialog:okclicked", callback);

            this.app.dialogRegion.show(dialogProcess);

            var callbackSaveImage = function () {
                //count++;

                dialogProcess.changeProgress(count, totalCount);

                if (count == totalCount) {
                    dialogProcess.triggerMethod('dialog:close');

                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });
                    self.ui.customAdImageInsert_inputName.val('');
                }
            }

            var searchString = ";base64,";
            var pos = 0;
            var uuid = this.UUID();

            var changeDate = new Date();

            var strUtcDate = changeDate.getUTCFullYear().toString() + '-' +
                (changeDate.getUTCMonth() + 1) + '-' +
                changeDate.getUTCDate().toString() + ' ' +
                changeDate.getUTCHours() + ':' +
                changeDate.getUTCMinutes() + ':' +
                changeDate.getUTCSeconds() + '.';
            //changeDate.getUTCSeconds();

            var image515 = this.getWeboptimizeImage(640, 100);

            var imageData512 = image515.data;
            //var imageData512 = this.getWeboptimizeImage(512,512);
            pos = imageData512.indexOf(searchString);
            var imageMimeType512 = imageData512.substring(5, pos);
            imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            var sizeText = Math.round(image515.width) + 'X' + Math.round(image515.height);

            this.saveImage(filename, resources.X100500, resources.X101289, imageData512, imageMimeType512, 1, sizeText, 0, uuid, strUtcDate + '005', callbackSaveImage);

            //var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageOriginal' });
            //if (customSetting[0].attributes.Value == '1') {
            //    var imageOriginal = this.getOriginalImage();

            //    var imageDataOriginal = imageOriginal.data;
            //    //var imageDataOriginal = this.getOriginalImage();

            //    pos = imageDataOriginal.indexOf(searchString);
            //    var imageMimeTypeOriginal = imageDataOriginal.substring(5, pos);
            //    var imageData = imageDataOriginal.substring(pos + searchString.length, imageDataOriginal.length);

            //    var sizeText = Math.round(imageOriginal.width) + 'X' + Math.round(imageOriginal.height);

            //    this.saveImage(filename, resources.X100499, resources.X100492, imageData, imageMimeTypeOriginal, 0, sizeText, 4, uuid, strUtcDate + '001', callbackSaveImage);
            //}

            //var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize500' });
            //if (customSetting[0].attributes.Value == '1') {
            //    var image515 = this.getWeboptimizeImage(512, 512);

            //    var imageData512 = image515.data;
            //    //var imageData512 = this.getWeboptimizeImage(512,512);
            //    pos = imageData512.indexOf(searchString);
            //    var imageMimeType512 = imageData512.substring(5, pos);
            //    imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            //    var sizeText = Math.round(image515.width) + 'X' + Math.round(image515.height);

            //    this.saveImage(filename, resources.X100500, resources.X100493, imageData512, imageMimeType512, 1, sizeText, 0, uuid, strUtcDate + '005', callbackSaveImage);
            //}

            //var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimize1000' });
            //if (customSetting[0].attributes.Value == '1') {
            //    var image1024 = this.getWeboptimizeImage(1024, 1024);

            //    var imageData1024 = image1024.data;
            //    //var imageData1024 = this.getWeboptimizeImage(1024,1024);
            //    pos = imageData1024.indexOf(searchString);
            //    var imageMimeType1024 = imageData1024.substring(5, pos);
            //    imageData1024 = imageData1024.substring(pos + searchString.length, imageData1024.length);

            //    var sizeText = Math.round(image1024.width) + 'X' + Math.round(image1024.height);

            //    this.saveImage(filename, resources.X100501, resources.X100494, imageData1024, imageMimeType1024, 2, sizeText, 1, uuid, strUtcDate + '004', callbackSaveImage);
            //}

            //var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound500' });
            //if (customSetting[0].attributes.Value == '1') {
            //    var imageRound512 = this.getWeboptimizeImageRound(512, 512);

            //    var imageDataRound512 = imageRound512.data;
            //    //var imageDataRound512 = this.getWeboptimizeImageRound(512,512);
            //    pos = imageDataRound512.indexOf(searchString);
            //    var imageMimeTypeRound512 = imageDataRound512.substring(5, pos);
            //    imageDataRound512 = imageDataRound512.substring(pos + searchString.length, imageDataRound512.length);

            //    var sizeText = Math.round(imageRound512.width) + 'X' + Math.round(imageRound512.height);

            //    this.saveImage(filename, resources.X100502, resources.X100495, imageDataRound512, imageMimeTypeRound512, 3, sizeText, 2, uuid, strUtcDate + '003', callbackSaveImage);
            //}

            //var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeRound1000' });
            //if (customSetting[0].attributes.Value == '1') {
            //    var imageRound1024 = this.getWeboptimizeImageRound(1024, 1024);

            //    var imageDataRound1024 = imageRound1024.data;
            //    //var imageDataRound1024 = this.getWeboptimizeImageRound(1024,1024);
            //    pos = imageDataRound1024.indexOf(searchString);
            //    var imageMimeTypeRound1024 = imageDataRound1024.substring(5, pos);
            //    imageDataRound1024 = imageDataRound1024.substring(pos + searchString.length, imageDataRound1024.length);

            //    var sizeText = Math.round(imageRound1024.width) + 'X' + Math.round(imageRound1024.height);

            //    this.saveImage(filename, resources.X100503, resources.X100496, imageDataRound1024, imageMimeTypeRound1024, 4, sizeText, 3, uuid, strUtcDate + '002', callbackSaveImage);
            //}

            //var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ImageWebOptimizeAd' });
            //if (customSetting[0].attributes.Value == '1') {
            //    var imageAd = this.getWeboptimizeImageNoAspectRatio(640, 100);

            //    var imageDataAd = imageAd.data;
            //    pos = imageDataAd.indexOf(searchString);
            //    var imageMimeTypeAd = imageDataAd.substring(5, pos);
            //    imageDataAd = imageDataAd.substring(pos + searchString.length, imageDataAd.length);

            //    var sizeText = Math.round(imageAd.width) + 'X' + Math.round(imageAd.height);

            //    this.saveImage(filename, resources.X100960, resources.X100959, imageDataAd, imageMimeTypeAd, 5, sizeText, 3, uuid, strUtcDate + '007', callbackSaveImage);
            //}
        } else {
            this.showErrors(tmpModel.errors);
        }

        //var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        //tmpModel.attributes.AuthUserId = config.authUserId();
        //tmpModel.attributes.Name = this.ui.customAdImageInsert_inputName.val();
        /*tmpModel.attributes.ImageData = this.options.localSetting.attributes.ImageData;
        tmpModel.attributes.ImageMimeType = this.options.localSetting.attributes.ImageMimeType;
        tmpModel.attributes.ImageUpdate = this.options.localSetting.attributes.ImageUpdate;*/

        //tmpModel.attributes.ImageData = imageDataRound512;
        //tmpModel.attributes.ImageMimeType = imageMimeTypeRound512;
        //tmpModel.attributes.ImageUpdate = true;

        //tmpModel.attributes.Filename = tmpModel.attributes.Name + '_' + new Date().getTime() + '.jpg';


        //var ret = tmpModel.validateNoBackbone(app);
        ////ret = true;
        //if (ret == false) {
        //    var dialogWaitingModel = new Entities_Empty.Model();
        //    dialogWaitingModel.attributes.Title = resources.X100321;
        //    dialogWaitingModel.attributes.Text = resources.X100297;
        //    dialogWaitingModel.attributes.Button = resources.X100279;

        //    var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        //    var callback = function (options) {
        //        this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        //    }
        //    this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        //    app.dialogRegion.show(dialogWaiting);

        //    var self = this;

        //    this.collection.fullCollection.create(tmpModel, {
        //        wait: true,
        //        success: function (response) {
        //            dialogWaiting.triggerMethod('dialog:close');
        //            self.collection.fullCollection.sort();
        //            self.collection.getFirstPage({ fetch: false });
        //            self.ui.customAdImageInsert_inputName.val('');
        //            self.file = undefined;

        //            self.options.localSetting.attributes.ImageData = null;
        //            self.options.localSetting.attributes.ImageMimeType = '';
        //            self.options.localSetting.attributes.ImageUpdate = false;
        //        }
        //    });
        //}
        //else {
        //    this.showErrors(tmpModel.errors);
        //}
    },
    UUID: function () {
        var lut = [];
        for (var i = 0; i < 256; i++) {
            lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
        }

        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;

        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    },
});

