﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageEditItem from './tpl/customWebpageEditItem.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';
import * as Entities_Memory from '../../entities/memory';
//import View_CustomSetting from '../views/customSetting/customSetting';

import View_DialogPreviewWebpageZoom from '../dialogPreview/dialogPreviewWebpageZoom';
import View_DialogPreviewWebpageZoomIPad from '../dialogPreview/dialogPreviewWebpageZoomIPad';



import config from '../../../common/config';
import View_Render from '../render/render';
import helper_createWebpage from '../../helper/createWebpage';
import Help from '../../helper/help';

import * as Entities_TmpCustomWebpageItem from '../../entities/tmpCustomWebpageItem';
import * as Entities_CustomWebpageItem from '../../entities/customWebpageItem';
import View_CustomWebpageEditItemList from './customWebpageEditItemList';
import View_CustomWebpageEditItemListTop from './customWebpageEditItemListTop';
import View_CustomWebpageEditItemListBottom from './customWebpageEditItemListBottom';

import View_CustomWebpageEditItemListSmall from './customWebpageEditItemListSmall';
import View_CustomWebpageEditItemTopbar from './customWebpageEditItemTopbar';
import View_NewPage from './setting/NewPage';
import View_NewSubMenu from './setting/NewSubMenu';

import View_CopyTemplate from './setting/CopyTemplate';
import View_CustomWebpageSetting from './setting/customWebpageSetting';
import View_CustomWebpageEditItemRightContent from './customWebpageEditItemRightContent';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customWebpageEditItem({ model: this.model.toJSON() });
    },
    regions: {
        customWebpageEditItemRightContent: "#customWebpageEditItemRightContent",

        customWebpageEditItemListSmall: "#customWebpageEditItemListSmall",
        customWebpageEditItemTopbar: "#customWebpageEditItemTopbar",
    },
    onBeforeShow: function () {
        /*this.collectionTop = new Backbone.Collection(this.collection.filter(function (model) {
             return (
                 model.get('Tag') == 0 ||
                 model.get('Tag') == 2 ||
                 model.get('Position') == 1
             )
         }));
 
         this.collectionMiddle = new Backbone.Collection(this.collection.filter(function (model) {
             return (
                 model.get('Tag') == 1 && model.get('Position') > 1 ||
                 model.get('Tag') == 10
             )
         }));
 
         this.collectionBottom = new Backbone.Collection(this.collection.filter(function (model) {
             return (
                 model.get('Tag') == 3 ||
                 model.get('Tag') == 4 ||
                 model.get('Tag') == 5 ||
                 model.get('Tag') == 6 ||
                 model.get('Tag') == 7
             )
         }));*/

        /*var selectedModel = this.collection.where({ Tag: 1, Position: 1 });
        selectedModel[0].Selected = true;*/

        var tmpSelectedModelSetting = this.app.entities.collections.customModule.where({ EventId: this.options.eventId, ModuleType: 100 })[0];
        var data = JSON.parse(tmpSelectedModelSetting.attributes.Data);
        this.selectedModelSetting = new Entities_Memory.Model(data[0].Data);

        /*var infoPageRightContent = new Entities_Empty.Model();
        infoPageRightContent.attributes.Zoom = 1;
        infoPageRightContent.attributes.BGType = "0";
        infoPageRightContent.attributes.BGColor = "rgb(67, 81, 21)";
        infoPageRightContent.attributes.BGImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAqCAIAAAB6LU8tAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFPSURBVFhHzZWxjcMwDEVdpHATIIBxq2Qfb+MlsoGbLOE2dXZwlQUuF+uQE58tiZQPYIDXPFmACH9RbL4fnwXdHbo7dHfo7tDdobtDd4fuDt0dujt0G+Px0nwNEZfhwD1G6Cbuvahm4XRfbTNBt9BeWc2L64htNugGxhNK+aVvudMCXU+UVzcNcXG7UqOrifI6H2cZ357U6ErmoXsfHzpLXPAdqdF1HKbz+/huui2L4krVp0ZXcYuen1deYf1/UqNrWOcVEKn9FWqDrmArr4BITX5SQy+znVdApFY3RuhFUnktxD+vMjV6CXHk+ubG5dalRi+QGhcJKlKj59ka71nsqdGzbI/3PNYHiZ5D+RbHbfiDcYzQM6inlew14xihpzFMBtlrttToSUyv8I7U6CnmuKDyATK1vtX3Gt0dujt0d+ju0N2hu0N3h+4O3ZlH8wQP3TsorYs4YQAAAABJRU5ErkJggg==";
        infoPageRightContent.attributes.Font1 = 'Alice';
        infoPageRightContent.attributes.Font1_Color = "rgb(1, 81, 21)";
        infoPageRightContent.attributes.Font1_Size = "50px";
        infoPageRightContent.attributes.Font1_Style = "Fed";
        infoPageRightContent.attributes.Font1_LineHeight = "0";
        infoPageRightContent.attributes.Font2 = 'Anton';
        infoPageRightContent.attributes.Font2_Color = "rgb(221, 221, 221)";
        infoPageRightContent.attributes.Font2_Size = "156px";
        infoPageRightContent.attributes.Font2_Style = "Kursiv";
        infoPageRightContent.attributes.Font2_LineHeight = "1";
        infoPageRightContent.attributes.Font3 = 'Bad Script';
        infoPageRightContent.attributes.Font3_Color = "rgb(121, 121, 121)";
        infoPageRightContent.attributes.Font3_Size = "28px";
        infoPageRightContent.attributes.Font3_Style = "Fed kursiv";
        infoPageRightContent.attributes.Font3_LineHeight = "2";*/




        this.customWebpageEditItemRightContent.show(new View_CustomWebpageEditItemRightContent({ model: this.selectedModelSetting, collection: this.collection, app: this.app }));

        /*this.customWebpageEditItemListTop.show(new View_CustomWebpageEditItemListTop({ collection: this.collection, app: this.app, childViewContainer: '#customWebpageEditItemListTop' }));
        this.customWebpageEditItemList.show(new View_CustomWebpageEditItemList({ collection: this.collection, app: this.app, childViewContainer: '#customWebpageEditItemList' }));
        this.customWebpageEditItemListBottom.show(new View_CustomWebpageEditItemListBottom({ collection: this.collection, app: this.app, childViewContainer: '#customWebpageEditItemListBottom' }));*/

        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100134;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;


        //this.customWebpageEditItemListSmall.show(new View_CustomWebpageEditItemListSmall({ collection: this.collection, app: this.app }));
        this.customWebpageEditItemTopbar.show(new View_CustomWebpageEditItemTopbar({ app: this.app, model: customHeaderModel }));
    },
    save: function (callbackRender) {
        var self = this;

        self.app.dialogRegion.empty();

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100134;
        dialogWaitingModel.attributes.Text = resources.X100309;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        //var timeout_id = setTimeout(function () {       // start counting down till fail

        //}, 5000);

        //this.customWebpageEditItemList.updateWebpagePositionNew();

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemList:updateWebpagePositionNew');

        var oldModel = this.collection.where({ Selected: true });

        var data = [{ Data: this.selectedModelSetting.attributes }];
        var strJson = JSON.stringify(data);
        this.app.entities.collections.customModule.where({ EventId: this.options.eventId, ModuleType: 100 })[0].attributes.Data = strJson;
        this.app.entities.collections.customModule.where({ EventId: this.options.eventId, ModuleType: 100 })[0].save(this.app.entities.collections.customModule.where({ EventId: this.options.eventId, ModuleType: 100 })[0].attributes, {
            silent: true,
            wait: true,
            success: function () {
            }
        });

        $('#dialogWaiting_modal').on('shown.bs.modal', function () {
            $.when(self.list_deleteAll(), self.list_insertAll(), self.list_editAll()).done(function (return_delete, return_insert, return_edit/*, return_refresh*/) {
                // the code here will be executed when all four ajax requests resolve.
                // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
                // status, and jqXHR object for each of the four ajax calls respectively.

                //this.app.entities.views.customSchemaData.table_readDataCollection();

                var callbackRenderFromFetch = function () {
                    dialogWaiting.triggerMethod('dialog:closeclicked', "msg")
                    dialogWaiting.trigger("dialog:close");

                    //dialogWaiting.triggerMethod('dialog:close');
                    /*dialogWaiting.triggerMethod('OnDialogOkClicked');
                    dialogWaiting.triggerMethod('dialog:okclicked');

                    dialogWaiting.triggerMethod('dialog:onOK');*/




                    /*var timeout_id = setTimeout(function () {       // start counting down till fail
                        this.app.dialogRegion.empty();
                        $('.modal-backdrop').remove();
    
                        dialogWaiting.modal('show');
    
                        dialogWaiting.triggerMethod('dialog:okclicked');
    
    
                    }, 5000);*/

                    //dialogWaiting.triggerMethod('modal:close');

                    self.setHasChanged(false);
                    callbackRender();
                }

                self.list_refresh(oldModel, callbackRenderFromFetch);




            });
        });
    },
    list_deleteAll: function () {
        var self = this;
        var deleteRows = [];
        var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: this.model.attributes.id });

        customWebpageItem.forEach(function (entry) {
            var model = self.collection.where({ id: entry.attributes.id });
            if (model.length == 0) {
                deleteRows.push(entry.toJSON());
            }
        });

        if (deleteRows.length > 0) {
            var data = {
                "data": deleteRows
            };

            var url = config.webApiUrl() + '/api/CustomWebpageItem/route02/-1?AuthUserId=' + config.authUserId() + '&Dummy=1';

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_insertAll: function () {
        var self = this;
        var insertRows = [];
        var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: this.model.attributes.id });

        self.collection.forEach(function (entry) {
            if (entry.attributes.orgId == -1) {
                insertRows.push(entry.toJSON());
            }
        });

        if (insertRows.length > 0) {
            var data = {
                "data": insertRows
            };

            var url = config.webApiUrl() + '/api/CustomWebpageItem/route03/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1';

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_editAll: function () {
        var self = this;
        var editRows = [];


        /*self.collectionBottom.forEach(function (entry) {
            var model = self.collection.where({ id: entry.attributes.id });
            this.model.set({
                Name: entry.Name,
                Name: entry.Name,
                EmailFrom: this.ui.contact_inputEmailFrom.val(),
                EmailTo: resources.X100689,
                Phone: this.ui.contact_inputPhone.val(),
                Subject: this.ui.contact_inputSubject.val(),
                Body: this.ui.contact_textareaBody.val(),
                Text01: resources.X100681,
                Text02: resources.X100682
            });
        });*/

        self.collection.forEach(function (entry) {
            if (entry.attributes.orgId != -1) {
                var orgModel = self.app.entities.collections.customWebpageItem.where({ id: entry.attributes.orgId });
                if (orgModel.length > 0) {
                    if (self.collection.compare2Model(entry, orgModel[0])) {
                        editRows.push(entry.toJSON());
                    }
                }
            }
        });

        if (editRows.length > 0) {
            var data = {
                "data": editRows
            };

            var url = config.webApiUrl() + '/api/CustomWebpageItem/route04/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1&Dummy2=2';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_refreshOld: function (oldModel) {
        var self = this;
        var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: this.model.attributes.id });
        customWebpageItem.forEach(function (entry) {
            app.entities.collections.customWebpageItem.remove(entry);
        });

        this.collection.reset();

        var tmpCustomWebpageItem = new Entities_CustomWebpageItem.Collection();

        tmpCustomWebpageItem.fetch({
            silent: true,
            wait: true,
            readonlyOnepage: true,
            Webpage: this.model.attributes.id,
            success: function (mode, response) {
                tmpCustomWebpageItem.forEach(function (entry) {
                    app.entities.collections.customWebpageItem.add(entry);

                    var tmpModel = new Entities_TmpCustomWebpageItem.Model();
                    tmpModel.attributes.Webpage = entry.attributes.Webpage;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.OldBody = entry.attributes.Body;
                    tmpModel.attributes.BodyCK = '';
                    tmpModel.attributes.BodyCB = '';
                    //tmpModel.attributes.Checksum = '';
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = entry.attributes.id;
                    tmpModel.id = entry.id;
                    tmpModel.attributes.orgId = entry.attributes.id;

                    tmpModel.attributes.DisplayType = self.model.attributes.DisplayType

                    switch (self.model.attributes.DisplayType) {
                        case 0: tmpModel.attributes.EnableDuration = false;
                            //tmpModel.attributes.Duration = 0; 
                            break;
                        case 1: tmpModel.attributes.EnableDuration = true;
                            //tmpModel.attributes.Duration = 5;
                            break;
                    }


                    tmpModel.attributes.Selected = false;


                    self.collection.add(tmpModel);
                });
                tmpCustomWebpageItem.reset();

                var newModel = self.collection.where({ Name: oldModel[0].attributes.Name });
                newModel[0].set({ Selected: true });

                self.oldCalcChecksum = self.calcChecksum();
            },
            error: function (mode, response) {
            }
        });


    },
    list_refresh: function (oldModel, callbackRenderFromFetch) {
        var self = this;
        var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: this.model.attributes.id });
        customWebpageItem.forEach(function (entry) {
            app.entities.collections.customWebpageItem.remove(entry);
        });

        this.collection.reset();

        var tmpCustomWebpageItem = new Entities_CustomWebpageItem.Collection();

        tmpCustomWebpageItem.fetch({
            silent: true,
            wait: true,
            readonlyOnepage: true,
            Webpage: this.model.attributes.id,
            success: function (mode, response) {
                tmpCustomWebpageItem.forEach(function (entry) {
                    app.entities.collections.customWebpageItem.add(entry);

                    var tmpModel = new Entities_TmpCustomWebpageItem.Model();
                    tmpModel.attributes.Webpage = entry.attributes.Webpage;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.OldBody = entry.attributes.Body;
                    tmpModel.attributes.BodyCK = '';
                    tmpModel.attributes.BodyCB = '';
                    //tmpModel.attributes.Checksum = '';
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = entry.attributes.id;
                    tmpModel.id = entry.id;
                    tmpModel.attributes.orgId = entry.attributes.id;
                    tmpModel.attributes.Parent = entry.attributes.Parent;
                    tmpModel.attributes.Dropdown = entry.attributes.Dropdown;

                    tmpModel.attributes.DisplayType = self.model.attributes.DisplayType

                    switch (self.model.attributes.DisplayType) {
                        case 0: tmpModel.attributes.EnableDuration = false;
                            //tmpModel.attributes.Duration = 0; 
                            break;
                        case 1: tmpModel.attributes.EnableDuration = true;
                            //tmpModel.attributes.Duration = 5;
                            break;
                    }


                    tmpModel.attributes.Selected = false;
                    if (tmpModel.attributes.Name == oldModel[0].attributes.Name) {
                        tmpModel.attributes.Selected = true;
                    }

                    self.collection.add(tmpModel);
                });
                tmpCustomWebpageItem.reset();

                //var newModel = self.collection.where({ Name: oldModel[0].attributes.Name });
                //newModel[0].set({ Selected: true });

                self.oldCalcChecksum = self.calcChecksum();

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItemList:reorder');

                callbackRenderFromFetch();
            },
            error: function (mode, response) {
                callbackRenderFromFetch();
            }
        });


    },
    saveCollection: function () {
        var self = this;
        var count = 0;

        customWebpageItem.forEach(function (entry) {
            var oldEntry = self.oldCollection.where({ id: entry.attributes.id });
            if (oldEntry.length > 0) {
                if ((oldEntry.attributes.Position != entry.attributes.Position) &&
                    (oldEntry.attributes.Body != entry.attributes.Body) &&
                    (oldEntry.attributes.Duration != entry.attributes.Duration) &&
                    (oldEntry.attributes.Css != entry.attributes.Css) &&
                    (oldEntry.attributes.Status != entry.attributes.Status)) {
                    count++;
                }
            } else {
                count++;
            }
        });

        if (count > 0) {
            customWebpageItem.forEach(function (entry) {
                var update = false;
                var create = false;

                var oldEntry = self.oldCollection.where({ id: entry.attributes.id });
                if (oldEntry.length > 0) {
                    if ((oldEntry.attributes.Position != entry.attributes.Position) &&
                        (oldEntry.attributes.Body != entry.attributes.Body) &&
                        (oldEntry.attributes.Duration != entry.attributes.Duration) &&
                        (oldEntry.attributes.Css != entry.attributes.Css) &&
                        (oldEntry.attributes.Status != entry.attributes.Status)) {
                        update = true;
                    }
                } else {
                    create = true;
                }

                if (update) {
                    entry.save(entry.attributes, {
                        wait: true,
                        silent: true,
                        success: function () {
                        }
                    });
                }

                if (create) {
                    entry.save(entry.attributes, {
                        wait: true,
                        silent: true,
                        success: function () {
                        }
                    });
                }
            });
        }
    },
    //calcChecksumLast : function () {
    //    var self = this;

    //    var criteria = '';
    //    this.collection.forEach(function (entry) {
    //        var Checksum = entry.attributes.Checksum;
    //        if (Checksum != '') {
    //            Checksum = self.calcChecksumBody(entry.attributes.Body);
    //        }

    //        criteria += entry.attributes.id + 
    //        entry.attributes.Name + 
    //        entry.attributes.Position + 
    //        //entry.attributes.Body +
    //        Checksum +
    //        entry.attributes.Duration + 
    //        entry.attributes.Css;
    //    });

    //    var md5 = require('js-md5');
    //    return md5(criteria);
    //},
    calcChecksum: function () {
        var criteria = '';
        this.collection.forEach(function (entry) {
            criteria += entry.attributes.id +
                entry.attributes.Name +
                entry.attributes.Position +
                //entry.attributes.Body +
                //entry.attributes.Checksum +
                entry.attributes.Duration +
                entry.attributes.Css +
                entry.attributes.Status;
        });

        var md5 = require('js-md5');
        return md5(criteria);
    },
    calcBodyChange: function () {
        var change = false;
        this.collection.forEach(function (entry) {
            if ((entry.attributes.BodyCK != '') && (entry.attributes.BodyCB != '')) {
                if ((entry.attributes.Body != entry.attributes.BodyCK) && (entry.attributes.Body != entry.attributes.BodyCB)) {
                    change = true;
                }
            } else if ((entry.attributes.BodyCK == '') && (entry.attributes.BodyCB == '') && (entry.attributes.Body != '') && (entry.attributes.orgId == -1)) {
                change = true;
            }
            else if (entry.attributes.Body != entry.attributes.OldBody) {
                change = true;
            }
            //else if ( (entry.attributes.BodyCK == '') && (entry.attributes.BodyCB == '') && (entry.attributes.Body != '')  ) {
            //    change = true;
            //}
        });
        return change;
    },

    getSelectedModel: function () {
        var selectedModel = this.collection.where({ Selected: true });
        if (selectedModel.length > 0) {
            return selectedModel.attributes.id;
        } else {
            alert("HER ER EN FEJL 2");
        }
    },
    btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.eventId);
        this.app.router.customEventEditItem(this.options.eventId, this.options.scroll, this.options.module_selected);
    },
    initialize: function () {
        this.app = this.options.app;
        this.oldCalcChecksum = this.calcChecksum();

        this.localSetting = new Entities_Empty.Model();

        this.copyCollection = this.options.copyCollection;

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.btnBack_click();
        });

        this.listenTo(userChannel, 'customWebpageEditor:saveContent', function () {
            self.saveSelectedModel();
        });

        this.listenTo(userChannel, 'customWebpageEditor:setOldContent', function (content) {
            //NOBODYCHANGEcontent = content.split('<p>&nbsp;</p>').join('');
            self.oldContent = content;
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconSave', function () {
            this.btnSubmit(null, false, true, null, false);
        });


        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconFile', function () {
            this.app.router.navigate("#customFile?id=" + this.options.eventId);
            this.app.router.customFile(this.options.eventId, this.options.scroll, this.options.module_selected, 0);
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconImage', function () {
            //var scroll = $(document).scrollTop();

            this.app.router.navigate("#customImage?id=" + this.options.eventId);
            this.app.router.customImage(this.options.eventId, this.options.scroll, this.options.module_selected, 0);
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconCog', function () {
            self.customWebpageSetting();
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconBars', function () {
            self.newPage();

        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconCaretDown', function () {
            self.newSubMenu();

        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconCopy', function () {
            self.copyTemplate();
        });

        this.listenTo(userChannel, 'customWebpageEditItem:requestFullScreen', function () {
            $('Body').addClass('sidebar-icon-only');

            var timerId = window.setInterval(function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItem:iconArrowsAlt');
                userChannel.trigger('template:setZoom');
                clearInterval(timerId);
            }, 1000);
        });
        this.listenTo(userChannel, 'customWebpageEditItem:cancelFullScreen', function () {
            $('Body').removeClass('sidebar-icon-only');

            var timerId = window.setInterval(function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItem:iconArrowsAlt');
                userChannel.trigger('template:setZoom');
                clearInterval(timerId);
            }, 1000);
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconArrowsAlt', function () {
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (document.documentElement.requestFullScreen) {
                    document.documentElement.requestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullscreen();
                }
            } else {

                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        });




        this.localSetting.attributes.themeChanged = false;

        this.listenTo(userChannel, 'customWebpageEditor:themeChange', function () {
            self.localSetting.attributes.themeChanged = true;
        });

        userChannel.reply('customWebpageEditor:getContentFirstTime', function (requestName) {


            if ((self.app.iframeContentBuilderReady) && (self.app.ckeditorReady)) {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');

                //Add a puse because you can click an edit button
                var timeout_id = setTimeout(function () {       // start counting down till fail
                    clearInterval(timeout_id);             // clear the counters
                    clearTimeout(timeout_id);

                    self.ui.customWebpageEditItem_panel.removeClass('disabledWebpageContent');
                    self.dialogWaiting_initialize.triggerMethod('dialog:close');

                    $('#cke_editor').width("100%");

                }, 1000);                                 // how long to wait before failing
            }

            /*var model = self.collection.where({ Selected: true });
    
            if (model && model[0]) {
                return model[0].attributes.Body;
            }*/

            return '';
        });

        this.listenTo(userChannel, 'customSetting:refreshPublishBtn', function () {
            var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
            if (customSetting[0].attributes.Value == '1') {
                if (this.model.attributes.DisplayType == 0) {
                    self.ui.customWebpageEditItem_btnPublish.show();
                } else {
                    self.ui.customWebpageEditItem_btnPublish.css('display', 'none');
                }
            } else {
                this.ui.customWebpageEditItem_btnPublish.css('display', 'none');
            }
        });

        this.listenTo(userChannel, 'customWebpageEditItemListItem:deleteModel', function (model) {
            self.deleteModel(model);
        });

        this.listenTo(userChannel, 'customWebpageEditItemListItem:saveSelectedModel', function () {
            self.saveSelectedModel();
        });

        this.listenTo(userChannel, 'customWebpageEditItemListItem:setSelectedModel', function () {
            var model = this.collection.where({ Selected: true });

            if (model && model[0]) {
                this.setContent(model[0].attributes.Body, model[0].attributes.id, model[0].attributes.Tag);


            } else {

            }
        });

        this.listenTo(userChannel, 'customWebpageEditItemListItem:disabledWebpageContent', function (model) {
            self.ui.customWebpageEditItem_panel.addClass('disabledWebpageContent');
        });
        this.listenTo(userChannel, 'customWebpageEditItemListItem:enableWebpageContent', function (model) {
            var timeout_id = setTimeout(function () {
                clearTimeout(timeout_id);
                if (self.ui.customWebpageEditItem_panel.removeClass != undefined) {
                    self.ui.customWebpageEditItem_panel.removeClass('disabledWebpageContent');
                    //Maybe here error
                }
            }, 500);
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconTabletAlt', function () {
            self.iconTabletAlt();
        });


        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconMobileAlt', function () {
            self.iconMobileAlt();
        });

        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconTv', function () {
            self.iconTv();
        });


        this.listenTo(userChannel, 'customWebpageEditItemTopbar:iconWpforms', function () {
            //self.iconWpforms();
            self.newPage();
        });


        this.setHasChanged(false);
    },
    help: function () {
        switch (this.model.attributes.DisplayType) {
            case 0: Help.help(this, true, 'customWebpageEditItem'); break;
            case 1: Help.help(this, true, 'customInfopageEditItem'); break;
        }
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        var self = this;
        var timeout_id = setTimeout(function () {
            clearTimeout(timeout_id);
            self.btnSubmit(null, false, true, callback, false);
        }, 500);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {

        this.saveSelectedModel();
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');
        userChannel.trigger('customWebpageEditItemList: reorder');


        var changes = false;

        var currentChecksum = this.calcChecksum();//Last();
        if ((this.oldCalcChecksum != currentChecksum) || (this.calcBodyChange())) {
            changes = true;
        }


        ////Now
        //this.oldContent = this.oldContent.split('<p>&nbsp;</p>').join('');
        //var body = this.getContent();
        //body = body.split('<p>&nbsp;</p>').join('');
        //if (this.oldContent != body) {
        //    changes = true;
        //}

        //if (this.model.attributes.Name != this.ui.customWebpageEditItem_inputName.val()) {
        //    changes = true;
        //}

        this.setHasChanged(changes);
    },
    ui: {
        'customWebpageEditItem_inputName': '#customWebpageEditItem_inputName',
        'customWebpageEditItem_btnSubmit': '#customWebpageEditItem_btnSubmit',
        'customWebpageEditItem_btnPublish': '#customWebpageEditItem_btnPublish',
        'customWebpageEditItem_btnRename': '#customWebpageEditItem_btnRename',
        'customWebpageEditItem_btnPaintStrapSubmit': '#customWebpageEditItem_btnPaintStrapSubmit',
        'customWebpageEditItem_btnTest': '#customWebpageEditItem_btnTest',
        'customWebpageEditItem_selectItem': '#customWebpageEditItem_selectItem',

        'customWebpageEditItem_inputInsertWebpageName': '#customWebpageEditItem_inputInsertWebpageName',
        'customWebpageEditItem_btnInsertWebpage': '#customWebpageEditItem_btnInsertWebpage',
        'customWebpageEditItem_selectWebpage': '#customWebpageEditItem_selectWebpage',
        'customWebpageEditItem_btnCopyWebpage': '#customWebpageEditItem_btnCopyWebpage',

        'customWebpageEditItem_labelselectWebpage': '#customWebpageEditItem_labelselectWebpage',
        'customWebpageEditItem_h5': '#customWebpageEditItem_h5',
        'customWebpageEditItem_panel': '#customWebpageEditItem_panel',
        'customWebpageEditItem_btnSaveWebpage': "#customWebpageEditItem_btnSaveWebpage",
        'settings-trigger': '#settings-trigger',
        'settings_trigger_icon': '#settings_trigger_icon',
        'help': '#help'
    },
    events: {
        'click @ui.customWebpageEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customWebpageEditItem_btnPublish': 'btnPublishClick',
        'click @ui.customWebpageEditItem_btnInsertWebpage': 'btnInsertWebpageClick',
        //'click @ui.customWebpageEditItem_btnInsertSubMenu': 'btnInsertSubMenuClick',
        'click @ui.customWebpageEditItem_btnPaintStrapSubmit': 'btnPaintStrapClick',
        'click @ui.customWebpageEditItem_btnTest': 'btnTest',
        'click @ui.customWebpageEditItem_btnCopyWebpage': 'btnCopyWebpage',
        'change @ui.customWebpageEditItem_selectWebpage': 'selectWebpageChange',
        'click @ui.customWebpageEditItem_btnSaveWebpage': 'btnSaveWebpageClick',
        'click @ui.settings-trigger': 'btnSettingClick',
        'click @ui.settings_trigger_icon': 'btnSettingClick',
        'click #help': 'help'
    },
    updateWebpagePosition: function () {
        var zeroIndex = -1;
        switch (this.model.attributes.DisplayType) {
            case 0: zeroIndex = 0; break;
            case 1: zeroIndex = -1; break;
        }
        var self = this;
        this.$el.find('#customWebpageEditItem_selectItem li').each(function (index) {
            var liId = parseInt(String($(this).data('id')));
            var tmpModel = self.collection.where({ id: liId });
            if (tmpModel[0].attributes.Position != zeroIndex
                && tmpModel[0].attributes.Position != 2147483647
                && tmpModel[0].attributes.Position != 2147483646
                && tmpModel[0].attributes.Position != 2147483645
                && tmpModel[0].attributes.Position != 2147483644
                && tmpModel[0].attributes.Position != 2147483643
                && tmpModel[0].attributes.Position != 2147483642
                && tmpModel[0].attributes.Position != 2147483641
                && tmpModel[0].attributes.Position != index) {
                tmpModel[0].attributes.Position = index;
            }
        });
    },
    onRender: function () {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100134;
        dialogWaitingModel.attributes.Text = resources.X100529;
        dialogWaitingModel.attributes.Button = resources.X100279;

        this.dialogWaiting_initialize = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(self.dialogWaiting_initialize, "dialog:okclicked", callback);
        }
        this.listenTo(this.dialogWaiting_initialize, "dialog:okclicked", callback);
        this.app.dialogRegion.show(this.dialogWaiting_initialize);



        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
        if (customSetting[0].attributes.Value == '1') {
            if (this.model.attributes.DisplayType == 0) {
                this.ui.customWebpageEditItem_btnPublish.show();
            } else {
                this.ui.customWebpageEditItem_btnPublish.css('display', 'none');
            }
        } else {
            this.ui.customWebpageEditItem_btnPublish.css('display', 'none');
        }


        this.setupCopyWebpage();

        var text = '';

        switch (this.model.attributes.DisplayType) {
            case 0:
                this.ui.customWebpageEditItem_labelselectWebpage.text(resources.X100148);
                this.ui.customWebpageEditItem_h5.text(resources['X100134'] + ' - ' + this.model.attributes.Name);
                var text = resources['X100134'] + ' - ' + this.model.attributes.Name;
                text = '<i class="fa fa-2x fa-html5" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
                break;
            case 1:
                this.ui.customWebpageEditItem_labelselectWebpage.text(resources.X100490);
                var text = resources['X100451'] + ' - ' + this.model.attributes.Name;
                this.ui.customWebpageEditItem_h5.text(resources['X100451'] + ' - ' + this.model.attributes.Name);
                text = '<i class="fa fa-2x fa-television" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
                break;
        }
        this.ui.customWebpageEditItem_h5.html(text);
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;

        switch (this.model.attributes.DisplayType) {
            case 0: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); break;
            case 1: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Infopage: this.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        var self = this;

        this.customViewEvent_setActive();

        this.setupSetting();
    },
    getContent: function () {
        if (this.app.editorRegion.currentView) {
            return this.app.editorRegion.currentView.getContent();
        }
    },
    getContentCKCB: function (value) {
        if (this.app.editorRegion.currentView) {
            return this.app.editorRegion.currentView.getContentCKCB(value);
        }
    },
    //callbackCK : function () {
    //    var self = window.c_parent;
    //    var model = self.collection.where( {id : window.c_id} );

    //    if (model[0].attributes.BodyCK == '' ) {
    //        var body = self.getContentCKCB('0');
    //        if (body.length > 0) {
    //            if (body[body.length-1] == '\n') {
    //                body = body.substr(0,body.length-1);
    //            }
    //        }

    //        model[0].attributes.BodyCK = body;


    //        if (window.c_customSetting[0].attributes.Value == 0) {
    //            model[0].attributes.Body = body;//self.getContent();
    //        }
    //    }
    //},
    //callbackCB : function () {
    //    var self = window.c_parent;

    //    var model = self.collection.where( {id : window.c_id} );

    //    if (model[0].attributes.BodyCB == '' ) {
    //        var body = self.getContentCKCB('1');
    //        if (body.length > 0) {
    //            if (body[body.length-1] == '\n') {
    //                body = body.substr(0,body.length-1);
    //            }
    //        }

    //        model[0].attributes.BodyCB = body;

    //        if (window.c_customSetting[0].attributes.Value == 1) {
    //            model[0].attributes.Body = body;//self.getContent();
    //        }
    //    }
    //},
    setContent: function (content, id, tag) {
        //NOBODYCHANGEcontent = content.split('<p>&nbsp;</p>').join('');

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'Webeditor' });

        var self = this;
        //var callback = function () {
        //    self.oldContent = self.getContent();
        //    var model = self.collection.where( {id : id} );

        //    if (model[0].attributes.Checksum == '' ) {
        //        var body = self.getContent();
        //        if (body.length > 0) {
        //            if (body[body.length-1] == '\n') {
        //                body = body.substr(0,body.length-1);
        //            }
        //        }

        //        model[0].attributes.Body = body;//self.getContent();
        //        model[0].attributes.Checksum = self.calcChecksumBody(body);//self.calcChecksumBody(self.getContent());
        //        self.oldCalcChecksum = self.calcChecksum();
        //    }
        //}

        //Herfra
        var callbackCK = function () {
            var model = self.collection.where({ id: id });

            if (model[0].attributes.BodyCK == '') {
                var body = self.getContentCKCB('0');

                if (body !== undefined && body != "############ERROR0############") {

                    if (body.length > 0) {
                        if (body[body.length - 1] == '\n') {
                            body = body.substr(0, body.length - 1);
                        }
                    }

                    model[0].attributes.BodyCK = body;


                    if (customSetting[0].attributes.Value == 0) {
                        model[0].attributes.Body = body;//self.getContent();
                    }
                }
            }

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customWebpageEditItemListItem:enableWebpageContent');
        }

        var callbackCB = function () {
            var model = self.collection.where({ id: id });

            if (model[0].attributes.BodyCB == '') {
                var body = self.getContentCKCB('1');

                if (body !== undefined && body != "############ERROR1############") {
                    if (body.length > 0) {
                        if (body[body.length - 1] == '\n') {
                            body = body.substr(0, body.length - 1);
                        }
                    }

                    model[0].attributes.BodyCB = body;

                    if (customSetting[0].attributes.Value == 1) {
                        model[0].attributes.Body = body;//self.getContent();
                    }
                }
            }

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customWebpageEditItemListItem:enableWebpageContent');

        }


        //window.c_parent = this;
        //window.c_id = id;
        //window.c_customSetting = customSetting;

        if (this.app.editorRegion.currentView) {
            //this.app.editorRegion.currentView.setContent(content, id, callback);
            this.app.editorRegion.currentView.setContentCKCB('0', content, id, tag, callbackCK, callbackCB);
            this.app.editorRegion.currentView.setContentCKCB('1', content, id, tag, callbackCK, callbackCB);
            //set old because when changing editor the code changes

            //if (customSetting[0].attributes.Value == '1') {
            //    this.oldContent = this.getContent();
            //}
        } else {
            this.oldContent = content;
        }
    },
    saveSelectedModel: function () {
        var self = this;


        var updateModel = this.collection.where({ Selected: true });
        if (updateModel.length > 0) {
            if (updateModel && updateModel[0]) {
                var body = this.getContent();

                if (body !== undefined) {
                    //NOBODYCHANGEbody = body.split('<p>&nbsp;</p>').join('');
                    if (body.length > 0) {
                        if (body[body.length - 1] == '\n') {
                            body = body.substr(0, body.length - 1);
                        }
                    }
                    updateModel[0].attributes.Body = body;

                    //if (updateModel[0].attributes.Checksum == '') {
                    //    //updateModel[0].attributes.Body = body;
                    //    updateModel[0].attributes.Checksum = self.calcChecksumBody(body);
                    //    self.oldCalcChecksum = self.calcChecksum();
                    //}
                }
            }
        } else {
            alert("HER ER EN FEJL 1");
        }
    },
    deleteModel: function (model) {
        var self = this;

        var selectAnotherModel = model.attributes.Selected;

        self.collection.remove(model);

        if (selectAnotherModel) {
            var selectedModelId = this.collection.models[0].attributes.id;

            switch (self.model.attributes.DisplayType) {
                case 0:
                    selectedModelId = this.collection.models[0].attributes.id;

                    var customWebpageItem = self.collection.where({ Webpage: self.model.attributes.id, Tag: 0 });
                    if (customWebpageItem.length == 1) {
                        selectedModelId = customWebpageItem[0].attributes.id;
                    }
                    break;
                case 1:
                    var selectedModel = this.collection.where({ Tag: 0 });
                    if (selectedModel.length > 0) {
                        var nextPosition = 2147483647;
                        self.collection.forEach(function (entry) {
                            if (entry.attributes.Webpage == self.model.attributes.id
                                && entry.attributes.Position <= nextPosition) {
                                nextPosition = entry.attributes.Position;
                                selectedModelId = entry.attributes.id;
                            }
                        });
                    }
            }

            self.collection.forEach(function (entry) {
                if (entry.attributes.id == selectedModelId) {
                    entry.set({ Selected: true });
                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');
                } else {
                    entry.set({ Selected: false });
                }
            });
        }
    },
    createWebPage: function (preview, webpage, renderFinishCallback, publish) {
        var self = this;

        var customEvent = null;

        switch (self.model.attributes.DisplayType) {
            case 0: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Webpage: self.model.attributes.id }); break;
            case 1: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Infopage: self.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            var render = new View_Render({ collection: customEvent, app: self.app });
            this.app.renderRegion.show(render);

            var refreshType = 'EVENT_REFRESH';

            switch (self.model.attributes.DisplayType) {
                case 0: refreshType = 'EVENT_REFRESH'; break;
                case 1:
                    if (this.localSetting.attributes.themeChanged) {
                        refreshType = 'EVENT_REFRESH';
                        this.localSetting.attributes.themeChanged = false;
                    } else {
                        refreshType = 'INFO_REFRESH';
                    }
                    break;
            }
            render.doIt(renderFinishCallback, publish, refreshType, false);
        }
        else if ((customEvent.length == 0) && (preview)) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100117;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
    },
    setupCopyWebpage: function (event) {
        var self = this;
        var total = 0;

        self.ui.customWebpageEditItem_selectWebpage.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');

        this.copyCollection.fullCollection.forEach(function (entry) {
            if (entry.id != self.model.attributes.id && entry.attributes.DisplayType == self.model.attributes.DisplayType) {
                total++;
                self.ui.customWebpageEditItem_selectWebpage.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });

        this.ui.customWebpageEditItem_btnCopyWebpage.prop('disabled', true);
    },
    selectWebpageChange: function (event) {
        event.preventDefault();

        var webpage = parseInt(this.ui.customWebpageEditItem_selectWebpage.val());

        if (webpage == '-1') {
            this.ui.customWebpageEditItem_btnCopyWebpage.prop('disabled', true);
        } else {
            this.ui.customWebpageEditItem_btnCopyWebpage.prop('disabled', false);
        }
    },
    copyWebpage: function (id) {
        var webpage = id;

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();

        var Text = '';

        switch (this.model.attributes.DisplayType) {
            case 0:
                Text = resources.X100149;
                break;
            case 1:
                Text = resources.X100491;
                break;
        }


        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = Text;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');

            var customWebpage = self.copyCollection.fullCollection.where({ id: webpage });
            if (customWebpage.length > 0) {
                this.collection.reset();

                var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: customWebpage[0].attributes.id });

                var count = self.app.entities.collections.customWebpageItem.length;

                customWebpageItem.forEach(function (entry) {
                    var tmpModel = new Entities_TmpCustomWebpageItem.Model();
                    tmpModel.attributes.Webpage = self.model.attributes.id;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = count;
                    tmpModel.id = count;
                    tmpModel.attributes.orgId = -1;

                    tmpModel.attributes.DisplayType = self.model.attributes.DisplayType;
                    tmpModel.attributes.Selected = false;


                    switch (self.model.attributes.DisplayType) {
                        case 0: tmpModel.attributes.EnableDuration = false; break;
                        case 1: tmpModel.attributes.EnableDuration = true; break;
                    }

                    self.collection.add(tmpModel);
                    count++;
                });

                switch (self.model.attributes.DisplayType) {
                    case 0: var header = self.collection.where({ Tag: 0 });
                        if (header.length > 0) {
                            header[0].set({ Selected: true });
                        }
                        break;
                    case 1:
                        var tmpPosition = 2147483647;
                        var tmpId = -1;
                        self.collection.forEach(function (entry) {
                            if (entry.attributes.Position < tmpPosition) {
                                tmpPosition = entry.attributes.Position;
                                tmpId = entry.attributes.id;
                            }
                        });

                        var firstModel = self.collection.where({ id: tmpId });
                        if (firstModel.length > 0) {
                            firstModel[0].set({ Selected: true });
                        }
                        break;
                }
            }
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');
        }

        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');
            //dialog2Button.trigger("dialog:close");
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnCopyWebpage: function (event) {
        event.preventDefault();

        var webpage = parseInt(this.ui.customWebpageEditItem_selectWebpage.val());

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();

        var Text = '';

        switch (this.model.attributes.DisplayType) {
            case 0:
                Text = resources.X100149;
                break;
            case 1:
                Text = resources.X100491;
                break;
        }


        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = Text;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');

            var customWebpage = self.copyCollection.fullCollection.where({ id: webpage });
            if (customWebpage.length > 0) {
                this.collection.reset();

                var customWebpageItem = self.app.entities.collections.customWebpageItem.where({ Webpage: customWebpage[0].attributes.id });

                var count = self.app.entities.collections.customWebpageItem.length;

                customWebpageItem.forEach(function (entry) {
                    var tmpModel = new Entities_TmpCustomWebpageItem.Model();
                    tmpModel.attributes.Webpage = self.model.attributes.id;
                    tmpModel.attributes.Name = entry.attributes.Name;
                    tmpModel.attributes.Tag = entry.attributes.Tag;
                    tmpModel.attributes.Body = entry.attributes.Body;
                    tmpModel.attributes.Position = entry.attributes.Position;
                    tmpModel.attributes.Duration = entry.attributes.Duration;
                    tmpModel.attributes.Css = entry.attributes.Css;
                    tmpModel.attributes.Status = entry.attributes.Status;
                    tmpModel.attributes.id = count;
                    tmpModel.id = count;
                    tmpModel.attributes.orgId = -1;

                    tmpModel.attributes.DisplayType = self.model.attributes.DisplayType;
                    tmpModel.attributes.Selected = false;


                    switch (self.model.attributes.DisplayType) {
                        case 0: tmpModel.attributes.EnableDuration = false; break;
                        case 1: tmpModel.attributes.EnableDuration = true; break;
                    }

                    self.collection.add(tmpModel);
                    count++;
                });

                switch (self.model.attributes.DisplayType) {
                    case 0: var header = self.collection.where({ Tag: 0 });
                        if (header.length > 0) {
                            header[0].set({ Selected: true });
                        }
                        break;
                    case 1:
                        var tmpPosition = 2147483647;
                        var tmpId = -1;
                        self.collection.forEach(function (entry) {
                            if (entry.attributes.Position < tmpPosition) {
                                tmpPosition = entry.attributes.Position;
                                tmpId = entry.attributes.id;
                            }
                        });

                        var firstModel = self.collection.where({ id: tmpId });
                        if (firstModel.length > 0) {
                            firstModel[0].set({ Selected: true });
                        }
                        break;
                }
            }
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');
        }

        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            dialog2Button.triggerMethod('dialog:close');
            //dialog2Button.trigger("dialog:close");
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnSubmit: function (event, preview, createWebpage, callbackRouter, publish) {
        var self = this;



        this.saveSelectedModel();

        var callbackSaveAll = function () {
            self.save(callbackRender);
        }

        var callbackRender = function () {
            if (createWebpage) {
                var renderFinishCallback = function (options) {
                    if (callbackRouter) {
                        callbackRouter();
                    }
                }

                var customEvent = null;

                switch (self.model.attributes.DisplayType) {
                    case 0: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Webpage: self.model.attributes.id }); break;
                    case 1: customEvent = self.app.entities.collections.customEvent.fullCollection.where({ Infopage: self.model.attributes.id }); break;
                }

                if (customEvent && customEvent.length > 0) {
                    self.createWebPage(preview, self.model.attributes.id, renderFinishCallback, publish);
                } else {
                    if (callbackRouter) {
                        callbackRouter();
                    }
                }
            } else {
                if (callbackRouter) {
                    callbackRouter();
                }
            }
        }

        callbackSaveAll();
    },
    btnPublishClick: function (event) {
        this.btnSubmit(event, false, true, null, true);
    },
    btnSubmitClick: function (event) {
        this.btnSubmit(event, false, true, null, false);
    },
    btnSaveWebpageClick: function (event) {
        var self = this;
        if (this.validate()) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100134;
            dialogWaitingModel.attributes.Text = resources.X100309;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            self.model.save(this.model.attributes, {
                silent: true,
                success: function () {
                    dialogWaiting.triggerMethod('dialog:close');
                }
            });
        }


    },
    setupSetting: function () {
        var customSetting = app.entities.collections.customSetting.where({ Name: 'WebPageSetting' })[0];

        switch (customSetting.attributes.Value) {
            case '0': this.ui.settings_trigger_icon.removeClass();
                this.ui.settings_trigger_icon.addClass('mdi mdi-arrow-expand-left');
                $('#customWebpageEditItemLeftContent').removeClass('col-9 col-sm-9 col-md-9 col-xl-9 grid-margin stretch-card');
                $('#customWebpageEditItemLeftContent').addClass('col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card');
                $('#customWebpageEditItemRightContent').removeClass('col-3 col-sm-3 col-md-3 col-xl-3');
                $('#customWebpageEditItemRightContent').addClass('col-3 col-sm-3 col-md-3 col-xl-3 d-none');
                break;
            case '1': this.ui.settings_trigger_icon.removeClass('mdi-arrow-expand-left');
                this.ui.settings_trigger_icon.addClass('mdi-arrow-expand-right');
                $('#customWebpageEditItemLeftContent').removeClass('col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card');
                $('#customWebpageEditItemLeftContent').addClass('col-9 col-sm-9 col-md-9 col-xl-9 grid-margin stretch-card');
                $('#customWebpageEditItemRightContent').removeClass('col-3 col-sm-3 col-md-3 col-xl-3 d-none');
                $('#customWebpageEditItemRightContent').addClass('col-3 col-sm-3 col-md-3 col-xl-3');
                break;
        }
    },
    btnSettingClick: function () {
        var customSetting = app.entities.collections.customSetting.where({ Name: 'WebPageSetting' })[0];

        switch (customSetting.attributes.Value) {
            case '0': customSetting.attributes.Value = '1'; break;
            case '1': customSetting.attributes.Value = '0'; break;
        }

        this.setupSetting();
    },
    validate: function () {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customWebpageEditItem_inputName.val();

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        return true;
    },
    copyTemplate: function () {
        var self = this;

        var copyTemplateModel = new Entities_Empty.Model();
        copyTemplateModel.attributes.Title = resources.X101081;
        copyTemplateModel.attributes.Text = '';
        copyTemplateModel.attributes.Button01 = resources.X100279;
        copyTemplateModel.attributes.Button02 = resources.X100280;
        copyTemplateModel.attributes.Name = '';
        copyTemplateModel.attributes.Status = 1;
        copyTemplateModel.attributes.Tag = 1;
        copyTemplateModel.attributes.Webpage = -1;
        copyTemplateModel.attributes.OldId = -1;

        copyTemplateModel.attributes.id = this.model.attributes.id;
        copyTemplateModel.attributes.DisplayType = self.model.attributes.DisplayType

        var copyTemplate = new View_CopyTemplate({ app: this.app, model: copyTemplateModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(copyTemplate, "dialog:btn01clicked", callback01);
            self.stopListening(copyTemplate, "dialog:btn02clicked", callback02);

            if (copyTemplateModel.attributes.Webpage != -1) {
                self.copyWebpage(copyTemplateModel.attributes.Webpage);
            }
        }
        this.listenTo(copyTemplate, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(copyTemplate, "dialog:btn01clicked", callback01);
            self.stopListening(copyTemplate, "dialog:btn02clicked", callback02);

        }
        this.listenTo(copyTemplate, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(copyTemplate);
    },
    customWebpageSettingSave: function (event) {
        var self = this;
        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100134;
        dialogWaitingModel.attributes.Text = resources.X100309;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        self.app.dialogRegion.show(dialogWaiting);

        self.model.save(this.model.attributes, {
            silent: true,
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
            }
        });
    },
    customWebpageSetting: function () {
        var self = this;

        /*var customWebpageSetting = new Entities_Empty.Model();
        customWebpageSetting.attributes.Title = resources.X101082;
        customWebpageSetting.attributes.Text = '';
        customWebpageSetting.attributes.Button01 = resources.X100279;
        customWebpageSetting.attributes.Button02 = resources.X100280;
        customWebpageSetting.attributes.Name = '';
        customWebpageSetting.attributes.Status = 1;
        customWebpageSetting.attributes.Tag = 1;
        customWebpageSetting.attributes.Webpage = -1;
        customWebpageSetting.attributes.OldId = -1;*/

        var customWebpageSetting = new View_CustomWebpageSetting({ app: this.app, model: this.model, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(customWebpageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(customWebpageSetting, "dialog:btn02clicked", callback02);

            self.customWebpageSettingSave();
        }
        this.listenTo(customWebpageSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(customWebpageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(customWebpageSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(customWebpageSetting, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(customWebpageSetting);
    },
    newPage: function () {
        var self = this;

        var newPageModel = new Entities_Empty.Model();
        newPageModel.attributes.Title = resources.X100136;
        newPageModel.attributes.Text = '';
        newPageModel.attributes.Button01 = resources.X100279;
        newPageModel.attributes.Button02 = resources.X100280;
        newPageModel.attributes.Name = '';
        newPageModel.attributes.Status = 1;
        newPageModel.attributes.Tag = 1;
        newPageModel.attributes.Webpage = -1;
        newPageModel.attributes.OldId = -1;
        /*newPageModel.attributes.Body = '';
        newPageModel.attributes.Parent = -1;
        newPageModel.attributes.Dropdown = false;*/

        var newPage = new View_NewPage({ app: this.app, model: newPageModel, collection: this.collection, parent: self });

        var callback01 = function () {
            self.stopListening(newPage, "dialog:btn01clicked", callback01);
            self.stopListening(newPage, "dialog:btn02clicked", callback02);

            self.newWebpage(newPageModel.attributes.Name, -1, false);
            //self.model.set({ Name: newPageModel.attributes.Name });
            //self.model.set({ Status: newPageModel.attributes.Status });
        }
        this.listenTo(newPage, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(newPage, "dialog:btn01clicked", callback01);
            self.stopListening(newPage, "dialog:btn02clicked", callback02);

        }
        this.listenTo(newPage, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(newPage);
    },
    newSubMenu: function () {
        var self = this;

        var newSubMenuModel = new Entities_Empty.Model();
        newSubMenuModel.attributes.Title = resources.X101224;
        newSubMenuModel.attributes.Text = '';
        newSubMenuModel.attributes.Button01 = resources.X100279;
        newSubMenuModel.attributes.Button02 = resources.X100280;
        newSubMenuModel.attributes.Name = '';
        newSubMenuModel.attributes.Status = 1;
        newSubMenuModel.attributes.Tag = 1;
        newSubMenuModel.attributes.Webpage = -1;
        newSubMenuModel.attributes.OldId = -1;

        var newSubMenu = new View_NewSubMenu({ app: this.app, model: newSubMenuModel, collection: this.collection, parent: self });

        var callback01 = function () {
            self.stopListening(newSubMenu, "dialog:btn01clicked", callback01);
            self.stopListening(newSubMenu, "dialog:btn02clicked", callback02);

            self.newWebpage(newSubMenuModel.attributes.Name, -1, true);
            self.model.set({ Name: newSubMenuModel.attributes.Name });
            self.model.set({ Status: newSubMenuModel.attributes.Status });
            self.model.set({ Dropdown: true });
        }
        this.listenTo(newSubMenu, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(newSubMenu, "dialog:btn01clicked", callback01);
            self.stopListening(newSubMenu, "dialog:btn02clicked", callback02);

        }
        this.listenTo(newSubMenu, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(newSubMenu);
    },
    btnInsertWebpageClick: function () {


        var self = this;

        this.saveSelectedModel();

        var callback = function () {
            self.hideErrors();
            var tmpModel = new self.collection.model();
            tmpModel.attributes.AuthUserId = config.authUserId();
            tmpModel.attributes.Webpage = self.model.attributes.id;
            tmpModel.attributes.Name = self.ui.customWebpageEditItem_inputInsertWebpageName.val();
            tmpModel.attributes.Body = ''; //TESTONLY'<h1>' + tmpModel.attributes.Name + '</h1>';

            tmpModel.attributes.Css = '';
            tmpModel.attributes.Status = 1;

            var foundNextId = false;
            var nextId = self.collection.length;

            while (!foundNextId) {
                var foundModels = self.collection.where({ id: nextId });
                if (foundModels.length == 0) {
                    foundNextId = true;
                } else {
                    nextId++;
                }
            }

            tmpModel.attributes.id = nextId;
            tmpModel.id = nextId;
            tmpModel.attributes.orgId = -1;
            tmpModel.attributes.DisplayType = self.model.attributes.DisplayType
            tmpModel.attributes.Selected = true;

            var nextPosition = 1;

            switch (self.model.attributes.DisplayType) {
                case 0: tmpModel.attributes.Tag = 1;
                    self.collection.forEach(function (entry) {
                        if (entry.attributes.Webpage == self.model.attributes.id
                            && entry.attributes.Position >= nextPosition
                            && entry.attributes.Position != 0
                            && entry.attributes.Position != 2147483647
                            && entry.attributes.Position != 2147483646
                            && entry.attributes.Position != 2147483645
                            && entry.attributes.Position != 2147483644
                            && entry.attributes.Position != 2147483643
                            && entry.attributes.Position != 2147483642
                            && entry.attributes.Position != 2147483641) {

                            nextPosition = entry.attributes.Position + 1;
                        }
                    });

                    tmpModel.attributes.Position = nextPosition;
                    tmpModel.attributes.Duration = 0;
                    tmpModel.attributes.EnableDuration = false;
                    break;
                case 1: tmpModel.attributes.Tag = 9;
                    //var length = self.collection.filter(function(n) { return (n.get('Webpage') == self.model.attributes.id && n.get('Tag') == 9); }).length;
                    self.collection.forEach(function (entry) {
                        if (entry.attributes.Webpage == self.model.attributes.id
                            && entry.attributes.Position >= nextPosition) {
                            nextPosition = entry.attributes.Position + 1;
                        }
                    });
                    tmpModel.attributes.Position = nextPosition;
                    tmpModel.attributes.Duration = 5;
                    tmpModel.attributes.EnableDuration = true;

                    break;
            }
            var ret = tmpModel.validateNoBackbone(app, 'customWebpageEditItem_inputInsertWebpageName', self.collection);
            if (ret == false) {
                self.collection.forEach(function (entry) {
                    entry.set({ Selected: false });
                });

                self.collection.add(tmpModel);
                self.ui.customWebpageEditItem_inputInsertWebpageName.val('');

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');
            }
            else {
                self.showErrors(tmpModel.errors);
            }
        }

        callback();
    },
    newWebpage: function (name, parent, dropdown) {


        var self = this;

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemList:updateWebpagePositionNew');

        this.saveSelectedModel();

        var callback = function () {
            self.hideErrors();
            var tmpModel = new self.collection.model();
            tmpModel.attributes.AuthUserId = config.authUserId();
            tmpModel.attributes.Webpage = self.model.attributes.id;
            tmpModel.attributes.Name = name;

            tmpModel.attributes.Parent = parent;
            tmpModel.attributes.Dropdown = dropdown;

            tmpModel.attributes.Body = ''; //TESTONLY'<h1>' + tmpModel.attributes.Name + '</h1>';

            tmpModel.attributes.Css = '';
            tmpModel.attributes.Status = 1;

            var foundNextId = false;
            var nextId = self.collection.length;

            while (!foundNextId) {
                var foundModels = self.collection.where({ id: nextId });
                if (foundModels.length == 0) {
                    foundNextId = true;
                } else {
                    nextId++;
                }
            }

            tmpModel.attributes.id = nextId;
            tmpModel.id = nextId;
            tmpModel.attributes.orgId = -1;
            tmpModel.attributes.DisplayType = self.model.attributes.DisplayType
            tmpModel.attributes.Selected = true;

            var nextPosition = 1;

            switch (self.model.attributes.DisplayType) {
                case 0: tmpModel.attributes.Tag = 1;
                    self.collection.forEach(function (entry) {
                        if (entry.attributes.Webpage == self.model.attributes.id
                            && entry.attributes.Position >= nextPosition
                            && entry.attributes.Position != 0
                            && entry.attributes.Position != 2147483647
                            && entry.attributes.Position != 2147483646
                            && entry.attributes.Position != 2147483645
                            && entry.attributes.Position != 2147483644
                            && entry.attributes.Position != 2147483643
                            && entry.attributes.Position != 2147483642
                            && entry.attributes.Position != 2147483641) {

                            nextPosition = entry.attributes.Position + 1;
                        }
                    });

                    tmpModel.attributes.Position = nextPosition;
                    tmpModel.attributes.Duration = 0;
                    tmpModel.attributes.EnableDuration = false;
                    break;
                case 1: tmpModel.attributes.Tag = 9;
                    //var length = self.collection.filter(function(n) { return (n.get('Webpage') == self.model.attributes.id && n.get('Tag') == 9); }).length;
                    self.collection.forEach(function (entry) {
                        if (entry.attributes.Webpage == self.model.attributes.id
                            && entry.attributes.Position >= nextPosition) {
                            nextPosition = entry.attributes.Position + 1;
                        }
                    });
                    tmpModel.attributes.Position = nextPosition;
                    tmpModel.attributes.Duration = 5;
                    tmpModel.attributes.EnableDuration = true;

                    break;
            }
            var ret = tmpModel.validateNoBackbone(app, 'customWebpageEditItem_inputInsertWebpageName', self.collection);
            if (ret == false) {
                self.collection.forEach(function (entry) {
                    //entry.set({ Selected: false });
                    entry.attributes.Selected = false;
                });

                self.collection.add(tmpModel);
                // self.collectionMiddle.add(tmpModel);
                self.ui.customWebpageEditItem_inputInsertWebpageName.val('');

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');
                userChannel.trigger('customWebpageEditItemList:reorder');

            }
            else {
                self.showErrors(tmpModel.errors);
            }
        }

        callback();
    },
    iconTabletAlt: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); 

        $('.modal-backdrop').remove();
        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpageIPad = new View_DialogPreviewWebpageZoomIPad({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpageIPad = function (options) {
            this.stopListening(dialogPreviewWebpageIPad, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpageIPad, "dialog:okclicked", callback_dialogPreviewWebpageIPad);

        this.app.dialogRegion.show(dialogPreviewWebpageIPad);
    },
    iconMobileAlt: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); 

        $('.modal-backdrop').remove();
        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: true
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpage = new View_DialogPreviewWebpageZoom({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpage = function (options) {
            this.stopListening(dialogPreviewWebpage, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);

        this.app.dialogRegion.show(dialogPreviewWebpage);


    },
    iconTv: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id });

        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);
    },
    iconWpforms: function () {
        var customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); 

        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);

    }
}, Backbone.Radio.Requests);
