﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_htmlSlide from './tpl/htmlSlide.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import helper_infopage from '../../helper/infopage';
import View_Module from './module';
import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    regions: {
        'modal': '#modal'
    },
    getTemplate: function () {
        return tpl_htmlSlide();
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(userChannel, 'htmlSlide:play', function (moduleDiv, moduleRegion, oldSelected) {//parent) {
            //if (parent == self) {
            if (self.model.attributes.ModuleDiv == moduleDiv && self.model.attributes.ModuleRegion == moduleRegion) {

                var found = self.model.attributes.Data.Collection.findIndex(function (post, index) {
                    if (post.id == oldSelected) return true;
                });
                self.model.attributes.Data.Collection[found].Data = this.ckeditor.getData();

                switch (this.model.attributes.Play) {
                    case 1: this.play(); break;
                    case 0: this.pause(); break;
                }
            }
        });

        this.listenTo(userChannel, 'htmlSlide:changeModelBefore', function (moduleDiv, oldSelected) { //parent, oldSelected) {
            //if (parent == self) {
            if (self.model.attributes.ModuleDiv == moduleDiv) {
                /*self.model.attributes.Play = 0;*/
                self.pause();

                self.clearAnimation();

                var found = self.model.attributes.Data.Collection.findIndex(function (post, index) {
                    if (post.id == oldSelected) return true;
                });

                self.model.attributes.Data.Collection[found].Data = this.ckeditor.getData();
            }
        });

        this.listenTo(userChannel, 'htmlSlide:changeModelAfter', function (moduleDiv, oldSelected) { //parent, oldSelected) {
            //if (parent == self) {
            if (self.model.attributes.ModuleDiv == moduleDiv) {
                self.setupSetting();

                var found = self.model.attributes.Data.Collection.findIndex(function (post, index) {
                    if (post.Selected == true) return true;
                });

                var data = self.model.attributes.Data.Collection[found].Data;
                var animation = self.app.entities.collections.HtmlSlideAnimation.where({ Value: self.model.attributes.Data.Collection[found].Animation })[0];
                this.ckeditor.setData(data);

                if (self.timeoutId) {
                    clearTimeout(self.timeoutId);
                }

                self.timeoutId = setInterval(function () {
                    self.setAnimation(animation.attributes.Css);
                    if (self.timeoutId) {
                        clearTimeout(self.timeoutId);
                    }


                    /*switch (self.model.attributes.Play) {
                        case 1: self.play(); break;
                        case 0: self.pause(); break;
                    }*/

                }, 500);
            }
        });
    },

    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'htmlSlide': '#htmlSlide',
        'editor': '#editor'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();

    },
    setupSetting() {
        var self = this;

        this.setupMargin();
    },
    onAttach() {
        this.setupSetting();

        helper_infopage.setupCssStyle(this.app);

        var self = this;
        var nowLocalDate = new Date();
        this.editorClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();

        this.ui.editor.addClass(this.editorClass);
        this.ui.editor.addClass('infopage');

        var element = $('.' + this.editorClass)[0];

        var found = self.model.attributes.Data.Collection.findIndex(function (post, index) {
            if (post.Selected == true) return true;
        });


        //var my_styles = 'my_styles' + + this.editorClass;

        var data = self.model.attributes.Data.Collection[found].Data;
        var animation = self.app.entities.collections.HtmlSlideAnimation.where({ Value: self.model.attributes.Data.Collection[found].Animation })[0];

        //data = this.setStyle(data);

        //CKEDITOR.disableAutoInline = true;

        /*CKEDITOR.editorConfig = function (config) {
            config.toolbarGroups = [
                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                { name: 'forms', groups: ['forms'] },
                '/',
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                { name: 'links', groups: ['links'] },
                { name: 'insert', groups: ['insert'] },
                '/',
                { name: 'styles', groups: ['styles'] },
                { name: 'colors', groups: ['colors'] },
                { name: 'tools', groups: ['tools'] },
                { name: 'others', groups: ['others'] },
                { name: 'about', groups: ['about'] }
            ];
        };*/

        /*if (CKEDITOR.stylesSet.registered['my_styles'] !== undefined) {
            CKEDITOR.stylesSet.add('my_styles',
                [
                    // Block-level styles
                    { name: 'Header 1', element: 'h1', styles: { 'color': 'Blue' } },
                    { name: 'Red Title', element: 'h2', styles: { 'color': 'Red' } },
                    { name: 'Red Title', element: 'p', styles: { 'color': 'Red' } }
                    // Inline styles
                    //{ name: 'CSS Style', element: 'span', attributes: { 'class': my_styles } },
                    //{ name: 'Marker: Yellow', element: 'span', styles: { 'background-color': 'Yellow' } }
                ]);
        }*/

        /*CKEDITOR.editorConfig = function (config) {
            config.toolbar = 'MyToolbar';
    
            config.toolbar_MyToolbar =
                [
                    { name: 'document', items: ['NewPage', 'Preview'] },
                    { name: 'clipboard', items: ['Cut', 'Copy', 'PasteText', '-', 'Undo', 'Redo'] },
                    { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll'] },
                    {
                        name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar']
                    },
                    '/',
                    { name: 'styles', items: ['Styles', 'Format'] },
                    { name: 'basicstyles', items: ['Bold', 'Italic', '-', 'RemoveFormat'] },
                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] }
                ];
    
    
            config.stylesSet = 'my_styles';
        };         
    
        */
        /*   this.editor = CKEDITOR.create(element, {
               allowedContent: true,
               stylesSet: 'my_styles'
           });*/




        //this.editor = CKEDITOR.inline(element, {
        //     allowedContent: true,
        //     stylesSet: 'my_styles'/*,
        //     toolbarGroups: [{
        //         "name": "basicstyles",
        //         "groups": ["basicstyles"]
        //     },
        //     {
        //         "name": "links",
        //         "groups": ["links"]
        //     },
        //     {
        //         "name": "paragraph",
        //         "groups": ["list", "blocks"]
        //     },
        //     {
        //         "name": "document",
        //         "groups": ["mode"]
        //     },
        //     {
        //         "name": "insert",
        //         "groups": ["insert"]
        //     },
        //     {
        //         "name": "styles",
        //         "groups": ["styles"]
        //     },
        //     {
        //         "name": "about",
        //         "groups": ["about"]
        //     },
        //     {
        //         "name": "heading",
        //         "groups": ["heading"]
        //     }
        //     ],
        //     heading: {
        //         options: [
        //             { model: 'heading1', view: 'h4', title: 'Titre de section', class: 'section-title' },
        //             { model: 'paragraph', title: 'Texte', class: 'ck-heading_paragraph' }
        //         ]
        //     }*/
        //     // Remove the redundant buttons from toolbar groups defined above.
        //     //removeButtons: 'Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar'

        //     //extraAllowedContent: '*(*);*{*}'
        //     //extraPlugins: 'divarea'
        // });//.setData(data); //  {

        var self = this;
        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });


        InlineEditor
            .create(element, {
                fontFamily: {
                    options: fonts,
                    supportAllValues: true
                },
                // toolbar: [ 'heading', '|', 'bold', 'italic', 'link' ],
                //toolbar: ['headings', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
                toolbar: {
                    items: [
                        'heading', '|',
                        'fontfamily', 'fontsize', '|',
                        'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
                        //'alignment', '|',
                        'fontColor', 'fontBackgroundColor', '|',
                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                        'link', '|',
                        'outdent', 'indent', '|',
                        'bulletedList', 'numberedList', 'todoList', '|',
                        'code', 'codeBlock', '|',
                        'insertTable', '|',
                        'imageUpload', 'blockQuote', '|',
                        'undo', 'redo'
                    ],
                    shouldNotGroupWhenFull: true
                }
            })
            .then(editor => {
                self.ckeditor = editor;
                editor.setData(data);
                self.setAnimation(animation.attributes.Css);
            })
            .catch(err => {
                console.error(err.stack);
            });

        //self.ckeditor.setData(data);
        //this.editor.setData(data);

        //$(element).setContent(self.model.attributes.Data);

        //CKEDITOR.disableAutoInline = true;
        //InlineEditor.disableAutoInline = true;
        //InlineEditor
        //    .create(document.querySelector('#editor'), {
        //        // toolbar: [ 'heading', '|', 'bold', 'italic', 'link' ]
        //    })
        //    .then(editor => {
        //        window.editor = editor;
        //    })
        //    .catch(err => {
        //        console.error(err.stack);
        //    });
    },
    getFirstItem: function () {
        var ret = Number.MAX_VALUE;

        this.model.attributes.Data.Collection.forEach(function (entry) {
            if (entry.Position < ret) {
                ret = entry.Position;
            }
        });

        return ret;
    },
    play: function () {
        var self = this;
        this.duration = [];
        this.id = [];
        this.current = -1;

        /*var first = this.getFirstItem();
    
        this.model.attributes.Data.forEach(function (entry) {
            if (entry.Position != first) {
                self.duration.push(parseInt(entry.Duration) * 1000);
                self.id.push(entry.id);
            }
        });
    
        if (this.model.attributes.Data.length > 0) {
            var found = self.model.attributes.Data.findIndex(function (entry, index) {
                if (entry.Position == first) return true;
            });
    
            var tmpModel = self.model.attributes.Data[found];
    
            if (tmpModel) {
                self.duration.push(parseInt(tmpModel.Duration) * 1000);
                self.id.push(tmpModel.id);
            }
        }*/

        this.model.attributes.Data.Collection.forEach(function (entry) {
            self.duration.push(parseInt(entry.Duration) * 1000);
            self.id.push(entry.id);
        });

        this.nextTemplate();
    },

    nextTemplate: function () {
        var self = this;

        this.current++;
        if (this.current >= this.id.length) this.current = 0;

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);

            self.clearAnimation();

            var currentId = this.id[this.current];

            var found = self.model.attributes.Data.Collection.findIndex(function (post, index) {
                if (post.id == currentId) return true;
            });

            var data = self.model.attributes.Data.Collection[found].Data;

            this.model.attributes.Data.Collection.forEach(function (entry) {
                entry.Selected = false;
            });

            self.model.attributes.Data.Collection[found].Selected = true;

            var animation = self.app.entities.collections.HtmlSlideAnimation.where({ Value: self.model.attributes.Data.Collection[found].Animation })[0];

            this.ckeditor.setData(data);
            self.setAnimation(animation.attributes.Css);
        }

        this.timeoutId = setInterval(function () {
            self.nextTemplate();
        }, this.duration[this.current]);
    },
    pause: function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },


    setStyle(data) {
        var searchstr = "infopageh1style";
        data = data.replaceAll(searchstr, 'font-family:"Open Sans";font-size: 80px;color: rgb(1, 81, 21);');

        return data;
    },
    onAttach2() {

        helper_infopage.setupCssStyle(this.app);

        var self = this;
        var nowLocalDate = new Date();
        this.editorClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();

        this.ui.editor.addClass(this.editorClass);
        this.ui.editor.addClass('infopage');

        var element = $('.' + this.editorClass)[0];

        var found = self.model.attributes.Data.Collection.findIndex(function (post, index) {
            if (post.Selected == true) return true;
        });






        //var my_styles = 'my_styles' + + this.editorClass;

        var data = self.model.attributes.Data.Collection[found].Data;

        var emailBodyConfig = {
            selector: '.' + this.editorClass,
            menubar: false,
            inline: true,
            plugins: [
                'link',
                'lists',
                'powerpaste',
                'autolink',
                'tinymcespellchecker'
            ],
            toolbar: [
                'undo redo | bold italic underline | fontselect fontsizeselect',
                'forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent'
            ],
            //valid_elements: 'p[style],strong,em,span[style],a[href],ul,ol,li',
            valid_styles: {
                '*': 'font-size,font-family,color,text-decoration,text-align'
            },
            powerpaste_word_import: 'clean',
            powerpaste_htmlSlide_import: 'clean',
        };

        tinymce.init(emailBodyConfig);
        //tinymce.setContent('<strong>Some contents</strong>');
        //tinymce.activeEditor.selection.setContent('<strong>Some contents</strong>');
        //tinymce.get(element).setContent(data);
        //tinymce.execCommand('mceInsertRawHTML', false, data);
    },
    setAnimation: function (className) {
        this.ui.htmlSlide.show();
        this.ui.htmlSlide.addClass(className);
        this.ui.htmlSlide.addClass('div100');
    },
    clearAnimation: function () {
        /*
        this.ui.htmlSlide.removeClass(function (index, className) {
            return (className.match(/(^|\s)animate__\S+/g) || []).join(' ');
        });*/

        //this.ui.htmlSlide.className = 'htmlSlide';

        this.ui.htmlSlide.removeClass().addClass('htmlSlide');
        this.ui.htmlSlide.addClass('div100');

        this.ui.htmlSlide.css('display', 'none');
    },
    onDestroy: function () {
    }
}, Backbone.Radio.Requests);
