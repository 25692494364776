﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_owlCarouselList from './tpl/owlCarouselList.html';
import View_OwlCarouselListItem from './owlCarouselListItem';

export default Marionette.CollectionView.extend({
    //tagName: 'div',
    childViewContainer: '#owlCarouselList',
    childView: View_OwlCarouselListItem,
    getTemplate: function () {
        return tpl_owlCarouselList();
    },
    attributes: function () {
        return {
            class: 'owl-carousel owl-theme full-width',
        }
    },
    initialize: function () {
        this.app = this.options.app;
    },
    onAttach: function () {
        $.fn.andSelf = function () {
            return this.addBack.apply(this, arguments);
        }

        $('.full-width').owlCarousel({
            loop: true,
            margin: 10,
            items: 1,
            nav: true,
            autoplay: false,
            autoplayTimeout: 5500,
            navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
        });

    },

    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
