var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventLive_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-magic" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100408'] ))==null?'':__t)+
'</h5> <div id="customEventLiveInformation"></div> <div id="customEventLive_div_anySchema"> <div class="control-group customEventLive_selectEvent"> <div class="form-group"> <label for="customEventLive_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventLive_selectEvent" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" id="customEventLive_div_SchemaBigscreen">'+
((__t=( resources['X100396'] ))==null?'':__t)+
'</div> <div class="panel-body"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_input_SchemaBigscreenShowHeader">'+
((__t=( resources['X100397'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input type="checkbox" id="customEventLive_input_SchemaBigscreenShowHeader"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_input_SchemaBigscreenShowSearchBar">'+
((__t=( resources['X100398'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input type="checkbox" id="customEventLive_input_SchemaBigscreenShowSearchBar"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customEventLive_select_SchemaBigscreenRefreshInterval"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenRefreshInterval">'+
((__t=( resources['X100478'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenRefreshInterval" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100447'] ))==null?'':__t)+
'</option> <option value="1">1</option> <option value="5">5</option> <option value="10">10</option> <option value="15">15</option> <option value="20">20</option> <option value="25">25</option> <option value="30">30</option> <option value="35">35</option> <option value="40">40</option> <option value="45">45</option> <option value="50">50</option> <option value="55">55</option> <option value="60">60</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" style="padding: 7px 7px"> <h3 class="panel-title">'+
((__t=( resources['X100526'] ))==null?'':__t)+
'</h3> </div> <div class="panel-body"> <div class="control-group customEventLive_select_SchemaBigscreenQueryField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenQueryField">'+
((__t=( resources['X100400'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenQueryField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100401'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventLive_input_SchemaBigscreenQueryValue" style="margin-bottom:0px"> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" style="padding: 7px 7px"> <h3 class="panel-title">'+
((__t=( resources['X100527'] ))==null?'':__t)+
'</h3> </div> <div class="panel-body"> <div class="control-group customEventLive_select_SchemaBigscreenQuerySortField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenQuerySortField">'+
((__t=( resources['X100486'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenQuerySortField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-group customEventLive_select_SchemaBigscreenQuerySortValue"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenQuerySortValue">'+
((__t=( resources['X100487'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenQuerySortValue" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100488'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100489'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" id="customEventLive_div_Schema">'+
((__t=( resources['X100402'] ))==null?'':__t)+
'</div> <div class="panel-body"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_input_SchemaShowHeader">'+
((__t=( resources['X100403'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input type="checkbox" id="customEventLive_input_SchemaShowHeader"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_input_SchemaShowSearchBar">'+
((__t=( resources['X100404'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input type="checkbox" id="customEventLive_input_SchemaShowSearchBar"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customEventLive_select_SchemaRefreshInterval"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaRefreshInterval">'+
((__t=( resources['X100478'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaRefreshInterval" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100447'] ))==null?'':__t)+
'</option> <option value="1">1</option> <option value="5">5</option> <option value="10">10</option> <option value="15">15</option> <option value="20">20</option> <option value="25">25</option> <option value="30">30</option> <option value="35">35</option> <option value="40">40</option> <option value="45">45</option> <option value="50">50</option> <option value="55">55</option> <option value="60">60</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" style="padding: 7px 7px"> <h3 class="panel-title">'+
((__t=( resources['X100526'] ))==null?'':__t)+
'</h3> </div> <div class="panel-body"> <div class="control-group customEventLive_select_SchemaQueryField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaQueryField">'+
((__t=( resources['X100406'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaQueryField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100401'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventLive_input_SchemaQueryValue" style="margin-bottom:0px"> </div> </div> </div> </div> </div> <div class="panel panel-default"> <div class="panel-heading" style="padding: 7px 7px"> <h3 class="panel-title">'+
((__t=( resources['X100527'] ))==null?'':__t)+
'</h3> </div> <div class="panel-body"> <div class="control-group customEventLive_select_SchemaQuerySortField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaQuerySortField">'+
((__t=( resources['X100486'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaQuerySortField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-group customEventLive_select_SchemaQuerySortValue"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaQuerySortValue">'+
((__t=( resources['X100487'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaQuerySortValue" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100488'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100489'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="footer"> <button type="button" class="btn btn-primary" id="customEventLive_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> </div> </form> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
