﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customHeader from './tpl/customHeader.html';
import language from '../../../../common/language';
import View_LayoutError from '../../../../lib/views/layoutError/layoutError';
import * as Entities_Empty from '../../../../lib/entities/empty';

import config from '../../../../common/config';
import Help from '../../../helper/help';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customHeader({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;
    },
    ui: {
        'customHeader_name': '#customHeader_name',
        'customHeader_name_footer': '#customHeader_name_footer',
        'customHeader_iconSave': '#customHeader_iconSave',
        'customHeader_iconBack': '#customHeader_iconBack'
    },
    events: {
        'click @ui.customHeader_iconSave': 'iconSaveClick',
        'click @ui.customHeader_iconBack': 'iconBackClick'
    },
    onRender: function () {
        if (!this.model.attributes.ShowSave) {
            this.ui.customHeader_iconSave.css('display', 'none');
            this.ui.customHeader_iconBack.css('margin-right','0px');
        }

        if (!this.model.attributes.ShowBack) {
            this.ui.customHeader_iconBack.css('display', 'none');
        }

        if (this.model.attributes.Name == "") {
            this.ui.customHeader_name.css('display', 'none');
            this.ui.customHeader_name_footer.css('display', 'none');
        }
    },
    onAttach() {
    },
    iconSaveClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customHeader:iconSave');
    },
    iconBackClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customHeader:iconBack');
    }
}, Backbone.Radio.Requests);
