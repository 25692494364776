﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogPreviewWebpage from './tpl/dialogPreviewWebpage.html';

export default Marionette.ItemView.extend({
    dialogRegion : '#dialogPreviewWebpage_modal',
    getTemplate: function () {
        return tpl_dialogPreviewWebpage({ model: this.model.toJSON() });
    },
    events: {
        "click #dialogPreviewWebpage_btnOk": "onOK"
    },
    onOK: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    }
});