﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartment from './tpl/customDepartment.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomDepartmentInsert from './customDepartmentInsert';
import View_CustomDepartmentList from './customDepartmentList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customDepartment();
    },
    regions: {
        customDepartmentInsert: "#customDepartmentInsert",
        customDepartmentList: "#customDepartmentList",
        customDepartmentPagination: "#customDepartmentPagination",
        information: "#customDepartmentInformation",
    },
    onBeforeShow: function () {
        this.customDepartmentInsert.show(new View_CustomDepartmentInsert({ collection: this.collection, app: this.app }));
        this.customDepartmentList.show(new View_CustomDepartmentList({ collection: this.collection, app : this.app }));
        this.customDepartmentPagination.show(new View_Pagination({ collection: this.collection, app : this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    ui : {
        'help': '#help',
        'customDepartment_inputSearch': '#customDepartment_inputSearch',
        'customDepartment_btnSearch': '#customDepartment_btnSearch',
    },
    events: {
        'click #help': 'help',
        'click #customDepartment_btnSearch': 'search'

    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customDepartment');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged: function () {
    },
    onRender: function () {
        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        //if (this.collection.models.length <= 0) {
        //    var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X200090, Information: resources.X200092 });
        //    this.informationCollection.add(tmpModel);
        //}
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
    },
    onAttach(){
    },
    search: function () {
        var self = this;

        var information = this.ui.customDepartment_inputSearch.val();

        this.app.entities.collections.customDepartment.fetch({
            route : 'route02',
            Information: encodeURIComponent(information),
            success: function (data) {

                var d = data;
                //dialogWaiting.triggerMethod('dialog:close');

                /*var timeout_id = setTimeout(function () {
                    document.body.className = document.body.className.replace("modal-open", "");
                    $('.modal.fade.show').remove();
                    $('.modal-backdrop').remove();
                    self.triggerMethod('rerender');
                }, 1000);*/
            }
        });
    }
}, Backbone.Radio.Requests);