var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogPreviewWebpageZoom_empty"> <div class="modal fade" id="dialogPreviewWebpageZoom_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog" style="border-top-width: 0px;border-right-width: 0px;border-bottom-width: 0px;border-left-width: 0px;background-color: transparent !important; width:300px !important; max-width: 580px !important"> <div class="ml-auto"> <img id="dialogPreviewWebpageZoom_btnOk" src="" alt="" class="fas fa-lg fa-2x fa-times-circle" style="color:#ffffff"> </div>  <div class="modal-body" style="background-color: transparent !important;padding-top: 0px"> <div class="" style="background-image: url(\'/images/iphone12_2.png\'); width: 260px; height: 531px; z-index:100000;overflow:hidden"> <iframe scrolling="auto" frameborder="0" id="dialogPreviewWebpageZoom_iframe" src="'+
((__t=( model.Iframe ))==null?'':__t)+
'" style="overflow-y:auto; overflow:hidden;  -webkit-overflow-scrolling: touch;  width:237px; height: 510px; left:14px;top:10px;position:relative; z-index:0;border-radius: 30px"></iframe> </div> </div>  </div> </div> </div>';
}
return __p;
};
