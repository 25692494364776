﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';


import * as Entities_Empty from '../../../../lib/entities/empty';

import tpl_customWebpageSetting from './tpl/customWebpageSetting.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#customWebpageSetting_modal',
    errors: [],
    getTemplate: function () {
        return tpl_customWebpageSetting({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customWebpageSetting_btn01': '#customWebpageSetting_btn01',
        'customWebpageSetting_btn01': '#customWebpageSetting_btn01',
        'customWebpageSetting_inputName' : '#customWebpageSetting_inputName',
        'customWebpageSetting_inputStatus' : '#customWebpageSetting_inputStatus'
    },
    events: {
        "click #customWebpageSetting_btn01": "onBtn01",
        "click #customWebpageSetting_btn02": "onBtn02",
        "keyup #customWebpageSetting_inputName": "onInputNameChange"
    },
    onRender: function () {
        var checked = this.model.attributes.Status == 1 ? true : false;
        this.ui.customWebpageSetting_inputStatus.attr('checked', checked);
    },
    onBtn01: function () {
        var self = this;

        var callback = function() {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    onInputNameChange: function () {
        var self = this;

        var callback = function () {
        }

        this.validate(callback);
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
        this.ui.customWebpageSetting_btn01.prop('disabled', false);

    },
    validate: function (callback) {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customWebpageSetting_inputName.val();

        var modelErrors = this.model.validateNoBackbone(this.app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        if (modelErrors.length > 0) {
            this.showErrors(this.errors);
            this.ui.customWebpageSetting_btn01.prop('disabled', true);
        } else { 
            callback();
        }
    }
});