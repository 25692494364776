﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customProductSheetDataImport from './tpl/customProductSheetDataImport.html';
import language from '../../../common/language';
import * as Entities_CustomProductSheetData from '../../entities/customProductSheetData';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customProductSheetDataImport();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;

        this.listenTo(this.app.entities.collections.customFormForm, 'customFormForm_add', this.render);
        this.listenTo(this.app.entities.collections.customFormForm, 'customFormForm_remove', this.render);
    },
    ui : {
        'customProductSheetDataImport_btnImportSubmit' : '#customProductSheetDataImport_btnImportSubmit',
        'customProductSheetDataImport_labelImport' : '#customProductSheetDataImport_labelImport',
        'customProductSheetDataImport_selectForm' : '#customProductSheetDataImport_selectForm',
        'customProductSheetDataImport_input_ShowForm' : '#customProductSheetDataImport_input_ShowForm',
        'customProductSheetDataImport_input_ShowInfo' : '#customProductSheetDataImport_input_ShowInfo'
    },
    events: {
        'change @ui.customProductSheetDataImport_btnImportSubmit': 'btnImportSubmit',
        'change @ui.customProductSheetDataImport_selectForm': 'selectFormChange'
    },
    onRender: function () {
        var self = this;

        //FileAPI.event.on(this.ui.customProductSheetDataImport_btnImportSubmit[0], 'change', function (event){
        //    self.btnImportSubmit(event);
        //});
        
        //this.$el.find('.file-input-wrapper').removeClass('btn-default');
        this.setup();

        return this;
    },
    setup: function () {
        if (this.settingModel.attributes.EventId > 0 && this.settingModel.attributes.FormFormId > 0) {
            this.ui.customProductSheetDataImport_labelImport.prop('disabled', false);
        }
        else {
            this.ui.customProductSheetDataImport_labelImport.prop('disabled', true);
        }
    },
    btnImportSubmit: function (event) {
        event.preventDefault();

        if (this.settingModel.attributes.EventId > 0 && this.settingModel.attributes.FormFormId > 0) {

            var self = this;
            var file = FileAPI.getFiles(event)[0];
            var array = [];

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X101112;
            dialogWaitingModel.attributes.Text = resources.X100262;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            var showForm;
            var showInfo;

            this.ui.customProductSheetDataImport_input_ShowForm.is(':checked') ? showForm = true : showForm = false;
            this.ui.customProductSheetDataImport_input_ShowInfo.is(':checked') ? showInfo = true : showInfo = false;

            dialogWaiting.onShow =  function(){
                setTimeout(function(){
                FileAPI.readAsBinaryString(file, function (param) {
                    if (param.type == 'load') {
                    

                        app.progress_count++;
         
                        var record_num = self.options.productSheetModel.attributes.FieldUsed;  // or however many elements there are in each row
                        var allTextLines = param.result.split(/\r\n|\n/);
                        var entries = allTextLines[0].split(';');
                        var lines = [];

                        //var table_firstVisibleItem = app.views.customProductSheetDataList.table_getFirstVisibleItem();
                        //var jumpFirst = table_firstVisibleItem;
                        self.triggerMethod('table_getFirstVisibleItem');
                        var table_firstVisibleItem = self.settingModel.attributes.TableFirstVisibleItem;
                        var jumpFirst = table_firstVisibleItem;

                        for (var j = 0; j < allTextLines.length; j++) {
                            var entries = allTextLines[j].split(';');

                            var tmpModel = new Entities_CustomProductSheetData.Model();
                            tmpModel.attributes.id = table_firstVisibleItem;
                            tmpModel.attributes.orgid = -1;
                            tmpModel.attributes.EventId = self.settingModel.attributes.EventId;

                            tmpModel.attributes.Form = self.settingModel.attributes.FormFormId;
                            tmpModel.attributes.Value01 = '';
                            tmpModel.attributes.Value02 = '';
                            tmpModel.attributes.Value03 = '';
                            tmpModel.attributes.Value04 = '';
                            tmpModel.attributes.Value05 = '';
                            tmpModel.attributes.Value06 = '';
                            tmpModel.attributes.Value07 = '';
                            tmpModel.attributes.Value08 = '';
                            tmpModel.attributes.Value09 = '';
                            tmpModel.attributes.Value10 = '';
                            tmpModel.attributes.Value11 = '';
                            tmpModel.attributes.Value12 = '';
                            tmpModel.attributes.Value13 = '';
                            tmpModel.attributes.Value14 = '';
                            tmpModel.attributes.Value15 = '';
                            tmpModel.attributes.Value16 = '';
                            tmpModel.attributes.Value17 = '';
                            tmpModel.attributes.Value18 = '';
                            tmpModel.attributes.Value19 = '';
                            tmpModel.attributes.Value20 = '';
                            tmpModel.attributes.Value21 = '';
                            tmpModel.attributes.Value22 = '';
                            tmpModel.attributes.Value23 = '';
                            tmpModel.attributes.Value24 = '';
                            tmpModel.attributes.Value25 = '';
                            tmpModel.attributes.Value26 = '';
                            tmpModel.attributes.Value27 = '';
                            tmpModel.attributes.Value28 = '';
                            tmpModel.attributes.Value29 = '';
                            tmpModel.attributes.Value30 = '';

                            tmpModel.attributes.ShowForm = showForm;
                            tmpModel.attributes.ShowInfo = showInfo;

                            var insert = false;

                            for (var j1 = 0; j1 < entries.length; j1++) {
                                if (entries[j1] != '') {
                                    insert = true;
                                }
                                switch (j1) {
                                    case 0: tmpModel.attributes.Field01 = entries[j1]; break;
                                    case 1: tmpModel.attributes.Field02 = entries[j1]; break;
                                    case 2: tmpModel.attributes.Field03 = entries[j1]; break;
                                    case 3: tmpModel.attributes.Field04 = entries[j1]; break;
                                    case 4: tmpModel.attributes.Field05 = entries[j1]; break;
                                    case 5: tmpModel.attributes.Field06 = entries[j1]; break;
                                    case 6: tmpModel.attributes.Field07 = entries[j1]; break;
                                    case 7: tmpModel.attributes.Field08 = entries[j1]; break;
                                    case 8: tmpModel.attributes.Field09 = entries[j1]; break;
                                    case 9: tmpModel.attributes.Field10 = entries[j1]; break;
                                    case 10: tmpModel.attributes.Field11 = entries[j1]; break;
                                    case 11: tmpModel.attributes.Field12 = entries[j1]; break;
                                    case 12: tmpModel.attributes.Field13 = entries[j1]; break;
                                    case 13: tmpModel.attributes.Field14 = entries[j1]; break;
                                    case 14: tmpModel.attributes.Field15 = entries[j1]; break;
                                    case 15: tmpModel.attributes.Field16 = entries[j1]; break;
                                    case 16: tmpModel.attributes.Field17 = entries[j1]; break;
                                    case 17: tmpModel.attributes.Field18 = entries[j1]; break;
                                    case 18: tmpModel.attributes.Field19 = entries[j1]; break;
                                    case 19: tmpModel.attributes.Field20 = entries[j1]; break;
                                    case 20: tmpModel.attributes.Field21 = entries[j1]; break;
                                    case 21: tmpModel.attributes.Field22 = entries[j1]; break;
                                    case 22: tmpModel.attributes.Field23 = entries[j1]; break;
                                    case 23: tmpModel.attributes.Field24 = entries[j1]; break;
                                    case 24: tmpModel.attributes.Field25 = entries[j1]; break;
                                    case 25: tmpModel.attributes.Field26 = entries[j1]; break;
                                    case 26: tmpModel.attributes.Field27 = entries[j1]; break;
                                    case 27: tmpModel.attributes.Field28 = entries[j1]; break;
                                    case 28: tmpModel.attributes.Field29 = entries[j1]; break;
                                    case 29: tmpModel.attributes.Field30 = entries[j1]; break;
                                }
                            }

                            table_firstVisibleItem++;


                            if (j == (allTextLines.length - 1)) {
                                //app.views.customProductSheetDataList.table_addModel(tmpModel, true, jumpFirst, false);
                                var msg = {
                                    tmpModel: tmpModel,
                                    jumpToData: true,
                                    jumpFirst: jumpFirst,
                                    ignoreEmptyContent: false
                                }
                                dialogWaiting.triggerMethod('dialog:close');
                                self.triggerMethod('table_addModel', msg);
                            }
                            else {
                                //app.views.customProductSheetDataList.table_addModel(tmpModel, false, jumpFirst, false);
                                var msg = {
                                    tmpModel: tmpModel,
                                    jumpToData: false,
                                    jumpFirst: jumpFirst,
                                    ignoreEmptyContent: false
                                }

                                self.triggerMethod('table_addModel', msg);
                            }
                        }
                    }
                });
                }, 1000);
            };

            this.app.dialogRegion.show(dialogWaiting);

        }
    }
});
