﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartmentReset from './tpl/customDepartmentReset.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomDepartmentResetInsert from './customDepartmentResetInsert';
import View_CustomDepartmentResetList from './customDepartmentResetList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    dialogRegion: '#departmentReset_modal',
    getTemplate: function () {
        return tpl_customDepartmentReset();
    },
    regions: {
        customDepartmentResetInsert: "#customDepartmentResetInsert",
        customDepartmentResetList: "#customDepartmentResetList",
        customDepartmentResetPagination: "#customDepartmentResetPagination",
        information: "#customDepartmentResetInformation",
    },
    onBeforeShow: function () {
        this.collection.state.currentPage = 1;
        this.customDepartmentResetInsert.show(new View_CustomDepartmentResetInsert({ collection: this.collection, app : this.app, departmentId : this.model.attributes.id }));
        this.customDepartmentResetList.show(new View_CustomDepartmentResetList({ collection: this.collection, app : this.app }));
        this.customDepartmentResetPagination.show(new View_Pagination({ collection: this.collection, app : this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    ui : {
        'help': '#help',
        'customDepartmentReset_btn01': '#customDepartmentReset_btn01',
        'customDepartmentReset_btn02': '#customDepartmentReset_btn02'
        
    },
    events: {
        'click #help': 'help',
        'click @ui.customDepartmentReset_btn01': 'onBtn01',
        'click @ui.customDepartmentReset_btn02': 'onBtn02'
    },
    initialize: function () {
        this.app = this.options.app;

        //this.collection.on('change', this.render, this);
        //this.listenTo(this.collection, 'change', this.render, this);
        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customDepartmentReset');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged: function () {
    },
    onRender: function () {
        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X200090, Information: resources.X200092 });
            this.informationCollection.add(tmpModel);
        }
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
    },
    onAttach() {
        //if ($("#timepicker-example").length) {
        //    $('#timepicker-example').datetimepicker({
        //        format: 'LT'
        //    });
        //}
        //$('#timepicker1').timepicker();
        //$('#durationpicker1').Durationpicker();

        //this.ui.adSlideListItemProperties_input_Duration.durationPicker({
        //    showSeconds: true,
        //    onChanged: function (newVal) {
        //        //self.ui.adSlideListItemProperties_label_Duration.text(newVal);
        //        //self.model.set({
        //            //Duration: newVal
        //        //});
        //    }
        //});
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    }
}, Backbone.Radio.Requests);