var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="pictureSingle_empty"> <div class="modal fade" id="pictureSingle_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101434'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X101419'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home-tab-1" data-toggle="tab" href="#home-1" role="tab" aria-controls="home-1" aria-selected="true">'+
((__t=( resources['X101424'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home-2" aria-selected="false">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-3" data-toggle="tab" href="#home-3" role="tab" aria-controls="home-3" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home-1" role="tabpanel" aria-labelledby="home-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-md-12"> <input id="pictureSingle_filePond" type="file" class="filepond"> <div id="pictureSingleListA"></div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-2" role="tabpanel" aria-labelledby="home-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="control-label col-md-6"> <label for="pictureSingle_checkboxEnableRandom">'+
((__t=( resources['X101422'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="pictureSingle_checkboxEnableRandom"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label for="pictureSingle_checkboxEnableRandomAnimation">'+
((__t=( resources['X101423'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="pictureSingle_checkboxEnableRandomAnimation"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-3" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-2"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2"> '+
((__t=( resources['X101418'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-2" class="collapse" role="tabpanel" aria-labelledby="heading-2" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="homea-tab-1" data-toggle="tab" href="#homea-1" role="tab" aria-controls="homea-1" aria-selected="true">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homea-tab-2" data-toggle="tab" href="#homea-2" role="tab" aria-controls="homea-2" aria-selected="false">'+
((__t=( resources['X101393'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homea-tab-3" data-toggle="tab" href="#homea-3" role="tab" aria-controls="homea-3" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="homea-1" role="tabpanel" aria-labelledby="homea-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="control-label col-md-6"> <label for="pictureSingle_checkboxEnableLabel">'+
((__t=( resources['X101420'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="pictureSingle_checkboxEnableLabel"> <span class="toggle-slider round"></span> </label> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_dropDown_LabelAnimation">'+
((__t=( resources['X101421'] ))==null?'':__t)+
'</label> <select id="pictureSingle_dropDown_LabelAnimation" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homea-2" role="tabpanel" aria-labelledby="homea-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_select_Font1_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="pictureSingle_select_Font1_FontType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="pictureSingle_dropDown_Font1">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <div id="pictureSingle_formgroup_Font1" class="form-group"> <input id="pictureSingle_dropDown_Font1" class="form-control"> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_select_Font1_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="pictureSingle_select_Font1_ColorType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="pictureSingle_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_dropDown_Font1_Size">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="pictureSingle_dropDown_Font1_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_dropDown_Font1_Style">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="pictureSingle_dropDown_Font1_Style" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="pictureSingle_dropDown_Font1_TextAlign">'+
((__t=( resources['X101381'] ))==null?'':__t)+
'</label> <select id="pictureSingle_dropDown_Font1_TextAlign" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homea-3" role="tabpanel" aria-labelledby="homea-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group pictureSingle_input_margin1_Top"> <div class="control-label"> <label for="pictureSingle_input_margin1_Top">'+
((__t=( resources['X101377'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Top))==null?'':__t)+
'" id="pictureSingle_input_margin1_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group pictureSingle_input_margin1_Bottom"> <div class="control-label"> <label for="pictureSingle_input_margin1_Bottom">'+
((__t=( resources['X101378'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Bottom))==null?'':__t)+
'" id="pictureSingle_input_margin1_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group pictureSingle_input_margin1_Left"> <div class="control-label"> <label for="pictureSingle_input_margin1_Left">'+
((__t=( resources['X101379'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Left))==null?'':__t)+
'" id="pictureSingle_input_margin1_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group pictureSingle_input_margin1_Right"> <div class="control-label"> <label for="pictureSingle_input_margin1_Right">'+
((__t=( resources['X101380'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Right))==null?'':__t)+
'" id="pictureSingle_input_margin1_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="pictureSingle_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="pictureSingle_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
