﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customFormData from './tpl/customFormData.html';
import tpl_customFormDataDesign from './tpl/customFormDataDesign.html';

import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomFormDataImport from './customFormDataImport';
import View_CustomFormDataInsert from './customFormDataInsert';
import View_CustomFormDataList from './customFormDataList';
import config from '../../../common/config';
import Help from '../../helper/help';

import View_CustomHeader from '../region/customHeader/customHeader';


export default Marionette.LayoutView.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customFormDataDesign();
        } else {
            return tpl_customFormData();
        }
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
        
        this.informationCollection = new Entities_Empty.Collection();

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customFormData:setHasChanged', function(enable) {
            self.setHasChanged(enable);
        });

        this.setHasChanged(false);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            //self.btnSubmitClick();
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customFormData_btnBack_click();
        });	
    },
    help: function () {
        Help.help(this, true, 'customFormData');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customFormDataList:save', callback);
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    ui: {
        'customFormData_select_FormForm': '#customFormData_select_FormForm',
        'customFormData_selectEvent': '#customFormData_selectEvent',
        'customFormData_btnDeleteAllSubmit': '#customFormData_btnDeleteAllSubmit',
        'customFormData_btnExport': '#customFormData_btnExport',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customFormData_inputNameGroup': '#customFormData_inputNameGroup',
        'customFormData_btnBack': '#customFormData_btnBack'
    },
    events: {
        'click @ui.customFormData_btnDeleteAllSubmit': 'btnDeleteAllSubmit',
        'change @ui.customFormData_selectEvent': 'selectEventChange',
        'change @ui.customFormData_select_FormForm': 'selectFormFormChange',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'click @ui.customFormData_btnBack	': 'customFormData_btnBack_click'
    },
    childEvents: {
        'table_addModel': function (childView, msg) {
            this.customFormDataList.currentView.table_addModel(msg.tmpModel, msg.jumpToData, msg.jumpFirst, msg.ignoreEmptyContent);
        },
        'table_getFirstVisibleItem': function (childView, msg) {
            this.customFormDataList.currentView.table_getFirstVisibleItem(msg);
        },
        'table_readDataCollection': function (childView, msg) {
            this.readDataCollection(/*this*/childView.settingModel.attributes.EventId);
        },

    },
    regions: {
        //customFormDataImport: "#customFormDataImport",
        customFormForm: "#customFormForm",
        customFormDataInsert: "#customFormDataInsert",
        customFormDataList: "#customFormDataList",
        information: "#customFormDataInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: parseInt(this.$el.find('#customFormData_selectEvent').val()) });

        if (eventModel.length > 0) {

            if (eventModel[0].attributes.FormModel != -1) {
                var formModel = this.app.entities.collections.customFormModel.fullCollection.where({ id: eventModel[0].attributes.FormModel });
                if (formModel.length > 0) {
                    this.formModel = formModel[0];

                    //this.customFormDataImport.show(new View_CustomFormDataImport({ collection: this.collection, formModel: this.formModel, settingModel: this.settingModel, app : this.app }));
                    this.customFormDataInsert.show(new View_CustomFormDataInsert({ collection: this.collection, formModel: this.formModel, settingModel: this.settingModel, app : this.app }));
                    this.customFormDataList.show(new View_CustomFormDataList({ collection: this.app.entities.collections.customFormData, formModel: this.formModel, settingModel: this.settingModel, app : this.app }));

                    this.readDataCollection(this.settingModel.attributes.EventId);
                }
            }
        }

        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        if (eventModel.length > 0) {
            customHeaderModel.attributes.Name = eventModel[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100260;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    onRender: function () {
        this.setupEvent(true);
        this.setupDeleteAllBtn();
        this.renderInformation();
        this.setupExport();

        if (this.app.mode == 1) {
            this.ui.customFormData_inputNameGroup.css('display', 'none');
            this.ui.customFormData_btnBack.css('display', 'none');
        }
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.app.entities.collections.customEvent.fullCollection.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }

        //Any form model selected on events
        var hasItems = false;
        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.FormModel != -1) {
                hasItems = true;
            }
        });

        //No form selectes on events
        if (!hasItems) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100344 });
            this.informationCollection.add(tmpModel);
        }

        //No data on the events form
        if (this.collection.length == 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100345 });
            this.informationCollection.add(tmpModel);
        }

        //Form not enable in config subscription, only display this message
        if (this.app.entities.models.configSubscription[0].attributes.EnableFormModel == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100130 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        if (this.settingModel.attributes.EventId != -1) {
            var tmp = { "id": this.settingModel.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        
    },
    setupDeleteAllBtn: function () {
        var enable = true;

        if (this.app.entities.collections.customEvent.fullCollection.length <= 0) {
            enable = false;
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableFormModel == false) {
            enable = false;
        }

        if (enable) {
            //this.ui.customFormData_btnDeleteAllSubmit.removeClass('btn-secondary');
            //this.ui.customFormData_btnDeleteAllSubmit.addClass('btn-primary');
            this.ui.customFormData_btnDeleteAllSubmit.prop('disabled', false);
            this.ui.customFormData_btnExport.prop('disabled', false);
        } else {
            //this.ui.customFormData_btnDeleteAllSubmit.removeClass('btn-primary');
            //this.ui.customFormData_btnDeleteAllSubmit.addClass('btn-secondary');
            this.ui.customFormData_btnDeleteAllSubmit.prop('disabled', true);
            this.ui.customFormData_btnExport.prop('disabled', true);
        }
    },
    disableAll: function() {
        this.ui.customFormData_btnDeleteAllSubmit.prop('disabled', true);
    },
    setupEvent: function (first) {
        this.ui.customFormData_selectEvent.empty();
        var self = this;

        this.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
            if (entry.attributes.FormModel != -1) {
                if (self.settingModel.attributes.EventId == entry.attributes.id) {
                    self.ui.customFormData_selectEvent.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
                }
                else {
                    self.ui.customFormData_selectEvent.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
                }
            }
        });

        if (this.options.event != undefined && this.options.event != -1) {
            this.ui.customFormData_selectEvent.val(this.options.event.toString());
        }

        if (first) {
            this.setModelFirst('EventId');
        }
    },
    setupExport: function () {
        var eventModel = this.app.entities.collections.customEvent.fullCollection.where({ id: parseInt(this.$el.find('#customFormData_selectEvent').val()) });

        if (eventModel.length > 0) {
            if (eventModel[0].attributes.FormModel != -1) {
                var href = config.webApiUrl() + '/api/CustomExportData/route01/-1?AuthUserId=' + config.authUserId() + '&EventId=' + this.settingModel.attributes.EventId
                        + '&CustomFormModelId=' + eventModel[0].attributes.FormModel
                        + '&FileName=' + resources.X100268
                        + '&Date=' + resources.X100294
                        + '&CultureName=' + config.cultureName();
                this.ui.customFormData_btnExport.prop('href', href);
                this.ui.customFormData_btnExport.prop('download', resources.X100268);
            }
        }
    },
    getSelectEvent: function (event) {
        var eventId = parseInt(this.$el.find('#customFormData_selectEvent').first().val());
        return eventId;
    },
    setModelFirst: function (attributes) {
        switch (attributes) {
            case 'EventId': 
                this.settingModel.attributes.EventId = parseInt(this.ui.customFormData_selectEvent.first().val()); 
                break;
        }
    },
    setModel: function (attributes) {
        switch (attributes) {
            case 'EventId': 
                this.settingModel.attributes.EventId = parseInt(this.ui.customFormData_selectEvent.val()); 
                break;
        }
    },
    setModelUI: function (attributes) {
        switch (attributes) {
            case 'EventId': this.ui.customFormData_selectEvent.val(this.settingModel.attributes.EventId); break;
        }
    },
    save: function(callback) {
        if (this.getHasChanged() ) {
            var self = this;

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = resources.X100395;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                dialog2Button.triggerMethod('dialog:close');
                self.saveHasChanged(callback);
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                this.setHasChanged(false);
                callback();
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);
        } else {
            callback();
        }
    },
    selectEventChange: function (event) {
        event.preventDefault();

        var self = this;
        var callback = function () {
            self.setModel('EventId');
            self.readDataCollection(self.settingModel.attributes.EventId);
            self.setupExport();
            self.customViewEvent_setActive();
        }
        this.save(callback);
    },
    readDataCollection: function (eventId) {
        var self = this;
        this.app.entities.collections.customFormData.fetch({
            EventId: eventId,
            success: function () {
                self.app.entities.collections.customFormData.trigger('change');
            },
            error: function () {
            }
        });
    },
    btnDeleteAllSubmit: function (event) {
        event.preventDefault();

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100261;
        dialog2ButtonModel.attributes.Text = resources.X100265;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var collection = this.app.entities.collections.customEvent;

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            self.customFormDataList.currentView.table_deleteAll();
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');
    },
    customFormData_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.event);
        this.app.router.customEventEditItem(this.options.event, this.options.scroll, 4);
    }
}, Backbone.Radio.Requests);
