var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaSchemaInsert_empty"> <form> <div class="" id="customSchemaSchemaInsert_div_Name"> <div class="row"> <div class="control-label col-md-3"> <label>'+
((__t=( resources['X100285'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-7"> <input class="form-control form-control-sm" id="customSchemaSchemaInsert_input_Name" style="margin-bottom:0px"> </div> <div class="control-label col-md-2"> <button type="button" class="btn btn-secondary btn-sm btn-block btnSchemaData" id="customSchemaSchemaInsert_btnSubmit">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> </div> </div> </div> </form> </div>';
}
return __p;
};
