var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogPreviewDias_empty"> <div class="modal fade" id="dialogPreviewDias_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div id="dialogPreviewDias_modalDialog" class="modal-content modal-dialog" style="border-top-width: 0px;border-right-width: 0px;border-bottom-width: 0px;border-left-width: 0px;background-color: transparent !important; margin-top:70px"> <div class="ml-auto"> <img id="dialogPreviewDias_btnOk" src="" alt="" class="fas fa-lg fa-2x fa-times-circle" style="color:#ffffff;padding-bottom: 10px"> </div>  <div class="modal-body" style="background-color: transparent !important;padding-top: 0px"> <iframe scrolling="auto" class="iframe_ipad" frameborder="0" id="dialogPreviewDias_iframe" src="'+
((__t=( model.Iframe ))==null?'':__t)+
'" style="width: 1920px;height: 1080px;-webkit-transform-origin: 0 0;overflow-y:auto; overflow:hidden;  -webkit-overflow-scrolling: touch; left:0px;top:0px;position:relative; z-index:0;border:solid; border-color:black; border-width:10px 10px 10px 10px"></iframe> </div>  </div> </div> </div>';
}
return __p;
};
