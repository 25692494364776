﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import dummy from '../../app/plugins';

//No internet
require('typeahead-addresspicker');

import tpl_customColor from './tpl/customColor.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';

import * as Entities_CustomPush from '../../entities/customPush';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import EventUpdate from '../../helper/eventUpdate';
import View_CustomHeader from '../region/customHeader/customHeader';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customColor({ model: this.model.toJSON() });
    },
    attributes: function () {
        return {
            style: "",
        }
    },
    initialize: function () {
        self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();
    },
    help: function () {
        Help.help(this, true, 'custom');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick();
        callback();
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    setModel: function () {
        var Custom_Text_Default = this.ui.customColor_inputCustom_Text_Default.val();

        var SolidColor_Text_Off = this.ui.customColor_inputSolidColor_Text_Off.val();
        var SolidColor_Text_Busy = this.ui.customColor_inputSolidColor_Text_Busy.val();
        var SolidColor_Text_Available = this.ui.customColor_inputSolidColor_Text_Available.val();
        var SolidColor_Text_Blue = this.ui.customColor_inputSolidColor_Text_Blue.val();
        var SolidColor_Text_Yellow = this.ui.customColor_inputSolidColor_Text_Yellow.val();
        var SolidColor_Text_Cyan = this.ui.customColor_inputSolidColor_Text_Cyan.val();
        var SolidColor_Text_Magenta = this.ui.customColor_inputSolidColor_Text_Magenta.val();

        var MSTeams_Text_Online = this.ui.customColor_inputMSTeams_Text_Online.val();
        var MSTeams_Text_Busy = this.ui.customColor_inputMSTeams_Text_Busy.val();
        var MSTeams_Text_DoNotDisturb = this.ui.customColor_inputMSTeams_Text_DoNotDisturb.val();
        var MSTeams_Text_BeRightBack = this.ui.customColor_inputMSTeams_Text_BeRightBack.val();
        var MSTeams_Text_ShowAsNotPresent = this.ui.customColor_inputMSTeams_Text_ShowAsNotPresent.val();
        var MSTeams_Text_ShowAsOffline = this.ui.customColor_inputMSTeams_Text_ShowAsOffline.val();

        var Mutebutton_Text_On = this.ui.customColor_inputMutebutton_Text_On.val();
        var Mutebutton_Text_Off = this.ui.customColor_inputMutebutton_Text_Off.val();

        var Pomodoro_Text_Time = this.ui.customColor_inputPomodoro_Text_Time.val();
        var Pomodoro_Text_ShortBreak = this.ui.customColor_inputPomodoro_Text_ShortBreak.val();
        var Pomodoro_Text_LongBreak = this.ui.customColor_inputPomodoro_Text_LongBreak.val();
        

        this.model.set({
            Custom_Text_Default: Custom_Text_Default,

            SolidColor_Text_Off: SolidColor_Text_Off,
            SolidColor_Text_Busy: SolidColor_Text_Busy,
            SolidColor_Text_Available: SolidColor_Text_Available,
            SolidColor_Text_Blue: SolidColor_Text_Blue,
            SolidColor_Text_Yellow: SolidColor_Text_Yellow,
            SolidColor_Text_Cyan: SolidColor_Text_Cyan,
            SolidColor_Text_Magenta: SolidColor_Text_Magenta,

            MSTeams_Text_Online: MSTeams_Text_Online,
            MSTeams_Text_Busy: MSTeams_Text_Busy,
            MSTeams_Text_DoNotDisturb: MSTeams_Text_DoNotDisturb,
            MSTeams_Text_BeRightBack: MSTeams_Text_BeRightBack,
            MSTeams_Text_ShowAsNotPresent: MSTeams_Text_ShowAsNotPresent,
            MSTeams_Text_ShowAsOffline: MSTeams_Text_ShowAsOffline,
            Mutebutton_Text_On: Mutebutton_Text_On,
            Mutebutton_Text_Off: Mutebutton_Text_Off,
            Pomodoro_Text_Time: Pomodoro_Text_Time,
            Pomodoro_Text_ShortBreak: Pomodoro_Text_ShortBreak,
            Pomodoro_Text_LongBreak: Pomodoro_Text_LongBreak
        });
    },
    pushMessage: function () {
        var message = String.format("?SecretKey={0}&SecretCode={1}&Refresh=PortalAllDepartment",
            this.app.entities.models.custom.attributes.SecretKey,
            this.app.entities.models.custom.attributes.SecretCode,
            this.model.attributes.id);

        var tmpModel = new Entities_CustomPush.Model();
        tmpModel.attributes.Message = message;
        tmpModel.attributes.id = 0;
        tmpModel.attributes.TokenKey = "";

        tmpModel.save(tmpModel, {
            silens: true,
            wait: true,
            success: function (response) {
            }
        });
    },
    modelHasChanged: function () {
        var changes = false;

        //If culture has change do not ask to save when goes to another menu item
        if (
            (this.model.attributes.Custom_Color_Default == this.oldModel.attributes.Custom_Color_Default) &&

            (this.model.attributes.SolidColor_Color_Off != this.oldModel.attributes.SolidColor_Color_Off) &&
            (this.model.attributes.SolidColor_Color_Busy != this.oldModel.attributes.SolidColor_Color_Busy) &&
            (this.model.attributes.SolidColor_Color_Available != this.oldModel.attributes.SolidColor_Color_Available) &&
            (this.model.attributes.SolidColor_Color_Blue != this.oldModel.attributes.SolidColor_Color_Blue) &&
            (this.model.attributes.SolidColor_Color_Yellow != this.oldModel.attributes.SolidColor_Color_Yellow) &&
            (this.model.attributes.SolidColor_Color_Cyan != this.oldModel.attributes.SolidColor_Color_Cyan) &&
            (this.model.attributes.SolidColor_Color_Magenta != this.oldModel.attributes.SolidColor_Color_Magenta) &&

            (this.model.attributes.MSTeams_Color_Online == this.oldModel.attributes.MSTeams_Color_Online) &&
            (this.model.attributes.MSTeams_Color_Busy == this.oldModel.attributes.MSTeams_Color_Busy) &&
            (this.model.attributes.MSTeams_Color_DoNotDisturb == this.oldModel.attributes.MSTeams_Color_DoNotDisturb) &&
            (this.model.attributes.MSTeams_Color_BeRightBack == this.oldModel.attributes.MSTeams_Color_BeRightBack) &&
            (this.model.attributes.MSTeams_Color_ShowAsNotPresent == this.oldModel.attributes.MSTeams_Color_ShowAsNotPresent) &&
            (this.model.attributes.MSTeams_Color_ShowAsOffline == this.oldModel.attributes.MSTeams_Color_ShowAsOffline) &&

            (this.model.attributes.Mutebutton_Color_On == this.oldModel.attributes.Mutebutton_Color_On) &&
            (this.model.attributes.Mutebutton_Color_Off == this.oldModel.attributes.Mutebutton_color_Off) &&

            (this.model.attributes.Pomodoro_Color_Time == this.oldModel.attributes.Pomodoro_Color_Time) &&
            (this.model.attributes.Pomodoro_Color_ShortBreak == this.oldModel.attributes.Pomodoro_Color_ShortBreak) &&
            (this.model.attributes.Pomodoro_Color_LongBreak == this.oldModel.attributes.Pomodoro_Color_LongBreak) &&

            (this.model.attributes.Custom_Text_Default == this.oldModel.attributes.Custom_Text_Default) &&

            (this.model.attributes.SolidColor_Text_Off != this.oldModel.attributes.SolidColor_Text_Off) &&
            (this.model.attributes.SolidColor_Text_Busy != this.oldModel.attributes.SolidColor_Text_Busy) &&
            (this.model.attributes.SolidColor_Text_Available != this.oldModel.attributes.SolidColor_Text_Available) &&
            (this.model.attributes.SolidColor_Text_Blue != this.oldModel.attributes.SolidColor_Text_Blue) &&
            (this.model.attributes.SolidColor_Text_Yellow != this.oldModel.attributes.SolidColor_Text_Yellow) &&
            (this.model.attributes.SolidColor_Text_Cyan != this.oldModel.attributes.SolidColor_Text_Cyan) &&
            (this.model.attributes.SolidColor_Text_Magenta != this.oldModel.attributes.SolidColor_Text_Magenta) &&

            (this.model.attributes.MSTeams_Text_Online == this.oldModel.attributes.MSTeams_Text_Online) &&
            (this.model.attributes.MSTeams_Text_Busy == this.oldModel.attributes.MSTeams_Text_Busy) &&
            (this.model.attributes.MSTeams_Text_DoNotDisturb == this.oldModel.attributes.MSTeams_Text_DoNotDisturb) &&
            (this.model.attributes.MSTeams_Text_BeRightBack == this.oldModel.attributes.MSTeams_Text_BeRightBack) &&
            (this.model.attributes.MSTeams_Text_ShowAsNotPresent == this.oldModel.attributes.MSTeams_Text_ShowAsNotPresent) &&
            (this.model.attributes.MSTeams_Text_ShowAsOffline == this.oldModel.attributes.MSTeams_Text_ShowAsOffline) &&

            (this.model.attributes.Mutebutton_Text_On == this.oldModel.attributes.Mutebutton_Text_On) &&
            (this.model.attributes.Mutebutton_Text_Off == this.oldModel.attributes.Mutebutton_Text_Off) &&

            (this.model.attributes.Pomodoro_Text_Time == this.oldModel.attributes.Pomodoro_Text_Time) &&
            (this.model.attributes.Pomodoro_Text_ShortBreak == this.oldModel.attributes.Pomodoro_Text_ShortBreak) &&
            (this.model.attributes.Pomodoro_Text_LongBreak == this.oldModel.attributes.Pomodoro_Text_LongBreak)
        ) {
            changes = false;
            this.btnSubmitClick();
        } else if (
            (this.model.attributes.Custom_Color_Default == this.oldModel.attributes.Custom_Color_Default) &&

            (this.model.attributes.SolidColor_Color_Off != this.oldModel.attributes.SolidColor_Color_Off) &&
            (this.model.attributes.SolidColor_Color_Busy != this.oldModel.attributes.SolidColor_Color_Busy) &&
            (this.model.attributes.SolidColor_Color_Available != this.oldModel.attributes.SolidColor_Color_Available) &&
            (this.model.attributes.SolidColor_Color_Blue != this.oldModel.attributes.SolidColor_Color_Blue) &&
            (this.model.attributes.SolidColor_Color_Yellow != this.oldModel.attributes.SolidColor_Color_Yellow) &&
            (this.model.attributes.SolidColor_Color_Cyan != this.oldModel.attributes.SolidColor_Color_Cyan) &&
            (this.model.attributes.SolidColor_Color_Magenta != this.oldModel.attributes.SolidColor_Color_Magenta) &&

            (this.model.attributes.MSTeams_Color_Online == this.oldModel.attributes.MSTeams_Color_Online) &&
            (this.model.attributes.MSTeams_Color_Busy == this.oldModel.attributes.MSTeams_Color_Busy) &&
            (this.model.attributes.MSTeams_Color_DoNotDisturb == this.oldModel.attributes.MSTeams_Color_DoNotDisturb) &&
            (this.model.attributes.MSTeams_Color_BeRightBack == this.oldModel.attributes.MSTeams_Color_BeRightBack) &&
            (this.model.attributes.MSTeams_Color_ShowAsNotPresent == this.oldModel.attributes.MSTeams_Color_ShowAsNotPresent) &&
            (this.model.attributes.MSTeams_Color_ShowAsOffline == this.oldModel.attributes.MSTeams_Color_ShowAsOffline) &&

            (this.model.attributes.Mutebutton_Color_On == this.oldModel.attributes.Mutebutton_Color_On) &&
            (this.model.attributes.Mutebutton_Color_Off == this.oldModel.attributes.Mutebutton_color_Off) &&

            (this.model.attributes.Pomodoro_Color_Time == this.oldModel.attributes.Pomodoro_Color_Time) &&
            (this.model.attributes.Pomodoro_Color_ShortBreak == this.oldModel.attributes.Pomodoro_Color_ShortBreak) &&
            (this.model.attributes.Pomodoro_Color_LongBreak == this.oldModel.attributes.Pomodoro_Color_LongBreak) &&

            (this.model.attributes.Custom_Text_Default == this.oldModel.attributes.Custom_Text_Default) &&

            
            (this.model.attributes.Mutebutton_Text_On == this.oldModel.attributes.Mutebutton_Text_On) &&
            (this.model.attributes.Mutebutton_Text_Off == this.oldModel.attributes.Mutebutton_Text_Off) &&

            (this.model.attributes.SolidColor_Text_Off != this.oldModel.attributes.SolidColor_Text_Off) &&
            (this.model.attributes.SolidColor_Text_Busy != this.oldModel.attributes.SolidColor_Text_Busy) &&
            (this.model.attributes.SolidColor_Text_Available != this.oldModel.attributes.SolidColor_Text_Available) &&
            (this.model.attributes.SolidColor_Text_Blue != this.oldModel.attributes.SolidColor_Text_Blue) &&
            (this.model.attributes.SolidColor_Text_Yellow != this.oldModel.attributes.SolidColor_Text_Yellow) &&
            (this.model.attributes.SolidColor_Text_Cyan != this.oldModel.attributes.SolidColor_Text_Cyan) &&
            (this.model.attributes.SolidColor_Text_Magenta != this.oldModel.attributes.SolidColor_Text_Magenta) &&

            (this.model.attributes.MSTeams_Text_Online == this.oldModel.attributes.MSTeams_Text_Online) &&
            (this.model.attributes.MSTeams_Text_Busy == this.oldModel.attributes.MSTeams_Text_Busy) &&
            (this.model.attributes.MSTeams_Text_DoNotDisturb == this.oldModel.attributes.MSTeams_Text_DoNotDisturb) &&
            (this.model.attributes.MSTeams_Text_BeRightBack == this.oldModel.attributes.MSTeams_Text_BeRightBack) &&
            (this.model.attributes.MSTeams_Text_ShowAsNotPresent == this.oldModel.attributes.MSTeams_Text_ShowAsNotPresent) &&
            (this.model.attributes.MSTeams_Text_ShowAsOffline == this.oldModel.attributes.MSTeams_Text_ShowAsOffline) &&
            (this.model.attributes.Mutebutton_Text_On == this.oldModel.attributes.Mutebutton_Text_On) &&
            (this.model.attributes.Mutebutton_Text_Off == this.oldModel.attributes.Mutebutton_Text_Off) &&

            (this.model.attributes.Pomodoro_Text_Time == this.oldModel.attributes.Pomodoro_Text_Time) &&
            (this.model.attributes.Pomodoro_Text_ShortBreak == this.oldModel.attributes.Pomodoro_Text_ShortBreak) &&
            (this.model.attributes.Pomodoro_Text_LongBreak == this.oldModel.attributes.Pomodoro_Text_LongBreak)
        ) {
            changes = true;
        }

        this.setHasChanged(changes);
    },
    regions: {
        information: "#customInformation"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    ui: {
        'customColor_btnSubmit': '#custom_btnSubmit',


        'customColor_ColorPickerSliders_Custom_Color_Default': '#customColor_ColorPickerSliders_Custom_Color_Default',

        'customColor_ColorPickerSliders_SolidColor_Color_Off': '#customColor_ColorPickerSliders_SolidColor_Color_Off',
        'customColor_ColorPickerSliders_SolidColor_Color_Busy': '#customColor_ColorPickerSliders_SolidColor_Color_Busy',
        'customColor_ColorPickerSliders_SolidColor_Color_Available': '#customColor_ColorPickerSliders_SolidColor_Color_Available',
        'customColor_ColorPickerSliders_SolidColor_Color_Blue': '#customColor_ColorPickerSliders_SolidColor_Color_Blue',
        'customColor_ColorPickerSliders_SolidColor_Color_Yellow': '#customColor_ColorPickerSliders_SolidColor_Color_Yellow',
        'customColor_ColorPickerSliders_SolidColor_Color_Cyan': '#customColor_ColorPickerSliders_SolidColor_Color_Cyan',
        'customColor_ColorPickerSliders_SolidColor_Color_Magenta': '#customColor_ColorPickerSliders_SolidColor_Color_Magenta',

        'customColor_ColorPickerSliders_MSTeams_Color_Online': '#customColor_ColorPickerSliders_MSTeams_Color_Online',
        'customColor_ColorPickerSliders_MSTeams_Color_Busy': '#customColor_ColorPickerSliders_MSTeams_Color_Busy',
        'customColor_ColorPickerSliders_MSTeams_Color_DoNotDisturb': '#customColor_ColorPickerSliders_MSTeams_Color_DoNotDisturb',
        'customColor_ColorPickerSliders_MSTeams_Color_BeRightBack': '#customColor_ColorPickerSliders_MSTeams_Color_BeRightBack',
        'customColor_ColorPickerSliders_MSTeams_Color_ShowAsNotPresent': '#customColor_ColorPickerSliders_MSTeams_Color_ShowAsNotPresent',
        'customColor_ColorPickerSliders_MSTeams_Color_ShowAsOffline': '#customColor_ColorPickerSliders_MSTeams_Color_ShowAsOffline',

        'customColor_ColorPickerSliders_Mutebutton_Color_On': '#customColor_ColorPickerSliders_Mutebutton_Color_On',
        'customColor_ColorPickerSliders_Mutebutton_Color_Off': '#customColor_ColorPickerSliders_Mutebutton_Color_Off',

        'customColor_ColorPickerSliders_Pomodoro_Color_Time': '#customColor_ColorPickerSliders_Pomodoro_Color_Time',
        'customColor_ColorPickerSliders_Pomodoro_Color_ShortBreak': '#customColor_ColorPickerSliders_Pomodoro_Color_ShortBreak',
        'customColor_ColorPickerSliders_Pomodoro_Color_LongBreak': '#customColor_ColorPickerSliders_Pomodoro_Color_LongBreak',

        'customColor_inputCustom_Text_Default': '#customColor_inputCustom_Text_Default',

        'customColor_inputSolidColor_Text_Off': '#customColor_inputSolidColor_Text_Off',
        'customColor_inputSolidColor_Text_Busy': '#customColor_inputSolidColor_Text_Busy',
        'customColor_inputSolidColor_Text_Available': '#customColor_inputSolidColor_Text_Available',
        'customColor_inputSolidColor_Text_Blue': '#customColor_inputSolidColor_Text_Blue',
        'customColor_inputSolidColor_Text_Yellow': '#customColor_inputSolidColor_Text_Yellow',
        'customColor_inputSolidColor_Text_Cyan': '#customColor_inputSolidColor_Text_Cyan',
        'customColor_inputSolidColor_Text_Magenta': '#customColor_inputSolidColor_Text_Magenta',

        'customColor_inputMSTeams_Text_Online': '#customColor_inputMSTeams_Text_Online',
        'customColor_inputMSTeams_Text_Busy': '#customColor_inputMSTeams_Text_Busy',
        'customColor_inputMSTeams_Text_DoNotDisturb': '#customColor_inputMSTeams_Text_DoNotDisturb',
        'customColor_inputMSTeams_Text_BeRightBack': '#customColor_inputMSTeams_Text_BeRightBack',
        'customColor_inputMSTeams_Text_ShowAsNotPresent': '#customColor_inputMSTeams_Text_ShowAsNotPresent',
        'customColor_inputMSTeams_Text_ShowAsOffline': '#customColor_inputMSTeams_Text_ShowAsOffline',

        'customColor_inputMutebutton_Text_On': '#customColor_inputMutebutton_Text_On',
        'customColor_inputMutebutton_Text_Off': '#customColor_inputMutebutton_Text_Off',

        'customColor_inputPomodoro_Text_Time': '#customColor_inputPomodoro_Text_Time',
        'customColor_inputPomodoro_Text_ShortBreak': '#customColor_inputPomodoro_Text_ShortBreak',
        'customColor_inputPomodoro_Text_LongBreak': '#customColor_inputPomodoro_Text_LongBreak',

        'help': '#help'
    },
    events: {
        'click #customColor_btnSubmit': 'btnSubmitClick',

        'colorpickerChange @ui.customColor_ColorPickerSliders_Custom_Color_Default': 'colorChange_Custom_Color_Default',

        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Off': 'colorChange_SolidColor_Color_Off',
        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Busy': 'colorChange_SolidColor_Color_Busy',
        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Available': 'colorChange_SolidColor_Color_Available',
        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Blue': 'colorChange_SolidColor_Color_Blue',
        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Yellow': 'colorChange_SolidColor_Color_Yellow',
        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Cyan': 'colorChange_SolidColor_Color_Cyan',
        'colorpickerChange @ui.customColor_ColorPickerSliders_SolidColor_Color_Magenta': 'colorChange_SolidColor_Color_Magenta',

        'colorpickerChange @ui.customColor_ColorPickerSliders_Color_Online': 'colorChange_MSTeams_Color_Online',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Color_Busy': 'colorChange_MSTeams_Color_Busy',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Color_DoNotDisturb': 'colorChange_MSTeams_Color_DoNotDisturb',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Color_BeRightBack': 'colorChange_MSTeams_Color_BeRightBack',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Color_ShowAsNotPresent': 'colorChange_MSTeams_Color_ShowAsNotPresent',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Color_ShowAsOffline': 'colorChange_MSTeams_Color_ShowAsOffline',

        'colorpickerChange @ui.customColor_ColorPickerSliders_Mutebutton_Color_On': 'colorChange_Mutebutton_Color_On',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Mutebutton_Color_Off': 'colorChange_Mutebutton_Color_Off',

        'colorpickerChange @ui.customColor_ColorPickerSliders_Pomodoro_Color_Time': 'colorChange_Pomodoro_Color_Time',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Pomodoro_Color_ShortBreak': 'colorChange_Pomodoro_Color_ShortBreak',
        'colorpickerChange @ui.customColor_ColorPickerSliders_Pomodoro_Color_LongBreak': 'colorChange_Pomodoro_Color_LongBreak',

        'click #help': 'help'
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;

        this.ui.customColor_ColorPickerSliders_Custom_Color_Default.colorpicker({
            color: self.model.attributes.Custom_Color_Default,
            format: "rgb"
        });

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Off.colorpicker({
            color: self.model.attributes.SolidColor_Color_Off,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Off.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Busy.colorpicker({
            color: self.model.attributes.SolidColor_Color_Busy,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Busy.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Available.colorpicker({
            color: self.model.attributes.SolidColor_Color_Available,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Available.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Blue.colorpicker({
            color: self.model.attributes.SolidColor_Color_Blue,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Blue.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Yellow.colorpicker({
            color: self.model.attributes.SolidColor_Color_Yellow,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Yellow.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Cyan.colorpicker({
            color: self.model.attributes.SolidColor_Color_Cyan,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Cyan.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_SolidColor_Color_Magenta.colorpicker({
            color: self.model.attributes.SolidColor_Color_Magenta,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_SolidColor_Color_Magenta.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_MSTeams_Color_Online.colorpicker({
            color: self.model.attributes.MSTeams_Color_Online,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_MSTeams_Color_Online.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_MSTeams_Color_Busy.colorpicker({
            color: self.model.attributes.MSTeams_Color_Busy,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_MSTeams_Color_Busy.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_MSTeams_Color_DoNotDisturb.colorpicker({
            color: self.model.attributes.MSTeams_Color_DoNotDisturb,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_MSTeams_Color_DoNotDisturb.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_MSTeams_Color_BeRightBack.colorpicker({
            color: self.model.attributes.MSTeams_Color_BeRightBack,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_MSTeams_Color_BeRightBack.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_MSTeams_Color_ShowAsNotPresent.colorpicker({
            color: self.model.attributes.MSTeams_Color_ShowAsNotPresent,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_MSTeams_Color_ShowAsNotPresent.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_MSTeams_Color_ShowAsOffline.colorpicker({
            color: self.model.attributes.MSTeams_Color_ShowAsOffline,
            format: "rgb"
        });

        var cp = this.ui.customColor_ColorPickerSliders_MSTeams_Color_ShowAsOffline.colorpicker('colorpicker');
        cp.disable();

        this.ui.customColor_ColorPickerSliders_Mutebutton_Color_On.colorpicker({
            color: self.model.attributes.Mutebutton_Color_On,
            format: "rgb"
        });

        this.ui.customColor_ColorPickerSliders_Mutebutton_Color_Off.colorpicker({
            color: self.model.attributes.Mutebutton_Color_Off,
            format: "rgb"
        });

        this.ui.customColor_ColorPickerSliders_Pomodoro_Color_Time.colorpicker({
            color: self.model.attributes.Pomodoro_Color_Time,
            format: "rgb"
        });

        this.ui.customColor_ColorPickerSliders_Pomodoro_Color_ShortBreak.colorpicker({
            color: self.model.attributes.Pomodoro_Color_ShortBreak,
            format: "rgb"
        });

        this.ui.customColor_ColorPickerSliders_Pomodoro_Color_LongBreak.colorpicker({
            color: self.model.attributes.Pomodoro_Color_LongBreak,
            format: "rgb"
        });
    },
    onDestroy() {
    },
    renderInformation: function () {
        this.informationCollection.reset();

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    btnSubmitClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.setModel();

        this.save();
    },
    colorChange_Custom_Color_Default: function (e) {
        this.model.attributes.Custom_Color_Default = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Off: function (e) {
        this.model.attributes.SolidColor_Color_Off = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Busy: function (e) {
        this.model.attributes.SolidColor_Color_Busy = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Available: function (e) {
        this.model.attributes.SolidColor_Color_Available = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Blue: function (e) {
        this.model.attributes.SolidColor_Color_Blue = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Yellow: function (e) {
        this.model.attributes.SolidColor_Color_Yellow = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Cyan: function (e) {
        this.model.attributes.SolidColor_Color_Cyan = e.value.toHexString();
    },
    colorChange_SolidColor_Color_Magenta: function (e) {
        this.model.attributes.SolidColor_Color_Magenta = e.value.toHexString();
    },
    colorChange_MSTeams_Color_Online: function (e) {
        this.model.attributes.MSTeams_Color_Online = e.value.toHexString();
    },
    colorChange_MSTeams_Color_Busy: function (e) {
        this.model.attributes.MSTeams_Color_Busy = e.value.toHexString();
    },
    colorChange_MSTeams_Color_DoNotDisturb: function (e) {
        this.model.attributes.MSTeams_Color_DoNotDisturb = e.value.toHexString();
    },
    colorChange_MSTeams_Color_BeRightBack: function (e) {
        this.model.attributes.MSTeams_Color_BeRightBack = e.value.toHexString();
    },
    colorChange_MSTeams_Color_ShowAsNotPresent: function (e) {
        this.model.attributes.MSTeams_Color_ShowAsNotPresent = e.value.toHexString();
    },
    colorChange_MSTeams_Color_ShowAsOffline: function (e) {
        this.model.attributes.MSTeams_Color_ShowAsOffline = e.value.toHexString();
    },
    colorChange_Mutebutton_Color_On: function (e) {
        this.model.attributes.Mutebutton_Color_On = e.value.toHexString();
    },
    colorChange_Mutebutton_Color_Off: function (e) {
        this.model.attributes.Mutebutton_Color_Off = e.value.toHexString();
    },
    colorChange_Pomodoro_Color_Time: function (e) {
        this.model.attributes.Pomodoro_Color_Time = e.value.toHexString();
    },
    colorChange_Pomodoro_Color_ShortBreak: function (e) {
        this.model.attributes.Pomodoro_Color_ShortBreak = e.value.toHexString();
    },
    colorChange_Pomodoro_Color_LongBreak: function (e) {
        this.model.attributes.Pomodoro_Color_LongBreak = e.value.toHexString();
    },
    save: function () {
        var self = this;

        this.hideErrors();

        //var ret = this.model.validateNoBackbone(this.model.attributes);
        //if (ret == false) {

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100232;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);


            this.model.save(this.model.attributes, {
                route01: false,
                success: function () {
                    self.pushMessage();

                    dialogWaiting.triggerMethod('dialog:close');

                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('Region_Header:setLoginName');
                }
            });
       // } else {
            this.showErrors(this.model.errors);
       // }
    },
    _parseGeocodeResult: function (geocodeResult) {
        this._addressParts = {
            street_number: null, route: null, locality: null, sublocality: null,
            administrative_area_level_3: null, administrative_area_level_2: null,
            administrative_area_level_1: null, country: null, postal_code: null, type: null
        };

        this._parseLatAndLng = function (location) {
            var longitude, latitude;

            if (typeof (location.lat) === 'function') {
                latitude = location.lat();
                longitude = location.lng();
            } else {
                latitude = location.lat;
                longitude = location.lng;
            }

            return { lat: latitude, lng: longitude };
        };

        this._findInfo = function (result, type) {
            for (var i = 0; i < result.address_components.length; i++) {
                var component = result.address_components[i];
                if (component.types.indexOf(type) != -1) {
                    return component.long_name;
                }
            }
            return false;
        };

        var parsed = this._parseLatAndLng(geocodeResult.geometry.location);

        for (var addressPart in this._addressParts) {
            parsed[addressPart] = this._findInfo(geocodeResult, addressPart);
        }

        parsed.type = geocodeResult.types[0];

        return parsed;
    }
}, Backbone.Radio.Requests);
