﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customFormDataList from './tpl/customFormDataList.html';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import config from '../../../common/config';
import language from '../../../common/language';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customFormDataList();
    },
    deleteRows: [],
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;

        //this.collection.on('change', this.render, this);
        this.listenTo(this.collection, 'change', this.render);

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customFormDataList:save', function(callbackRouter) {
            self.save(callbackRouter);
        });

        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.customFormDataList_btnSubmit(null);
        });
    },
    ui: {
        'customFormDataList_btnSubmit': '#customFormDataList_btnSubmit',
        'customFormDataList_formData': '#customFormDataList_formData',
    },
    events: {
        'click @ui.customFormDataList_btnSubmit': 'customFormDataList_btnSubmit',
    },
    setHasChanged: function (enable) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customFormData:setHasChanged', enable);
    },
    onRender: function () {
        this.addDataTable();

        this.setup_schemaData_length();

        this.setup();

        if (this.app.mode == 1) {
            this.ui.customFormDataList_btnSubmit.css('display', 'none');
        }
    },
    onAttach(){
    },
    onDestroy(){
        this.table.clear();
    },
    setup: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableFormModel == false) {
            this.ui.customFormDataList_btnSubmit.removeClass('btn-primary');
            this.ui.customFormDataList_btnSubmit.addClass('btn-secondary');
            this.ui.customFormDataList_btnSubmit.prop('disabled', true);
        } else if (this.settingModel.attributes.EventId > 0) {
            this.ui.customFormDataList_btnSubmit.removeClass('btn-secondary');
            this.ui.customFormDataList_btnSubmit.addClass('btn-primary');
            this.ui.customFormDataList_btnSubmit.prop('disabled', false);
        }
        else {
            this.ui.customFormDataList_btnSubmit.removeClass('btn-primary');
            this.ui.customFormDataList_btnSubmit.addClass('btn-secondary');
            this.ui.customFormDataList_btnSubmit.prop('disabled', true);
        }
    },
    set_schemaData_length: function (length) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'FormDataItemPerPage' });
        customSetting[0].attributes.Value = length;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
            }
        });
    },
    setup_schemaData_length: function (count) {
        var element = this.$el.find('#schemaData_length');

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'FormDataItemPerPage' });
        var schemaDataItemPerPage = customSetting[0].attributes.Value;
        
        var element = '#customFormDataList_table_length [value="' + customSetting[0].attributes.Value + '"]';
        $(element).prop('selected', true);
    },
    table_deleteAll: function () {
        this.setHasChanged(true);
        var self = this;
        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid != -1) {
                self.deleteRows.push(d);
            }
        });

        this.table.clear().draw();
    },
    table_getFirstVisibleItem: function () {
        var first = 1;
        var getFirst = false;
        this.$el.find('#customFormDataList_table tbody tr').each(function (i, tr) {
            if (!getFirst) {
                if ($(tr).attr('id') != undefined) {
                    first = parseInt($(tr).attr('id'));
                }
                getFirst = true;
            }
        });

        this.settingModel.attributes.TableFirstVisibleItem = first;
        return first;
    },
    table_addModel: function (tmpModel, jumpToData, jumpFirst, ignoreEmptyContent) {
        this.setHasChanged(true);
        //Import sometimes insert a empty element
        var totalContentLength = tmpModel.attributes.Value01.length + tmpModel.attributes.Value02.length + tmpModel.attributes.Value03.length +
            tmpModel.attributes.Value04.length + tmpModel.attributes.Value05.length + tmpModel.attributes.Value06.length +
            tmpModel.attributes.Value07.length + tmpModel.attributes.Value08.length + tmpModel.attributes.Value09.length +
            tmpModel.attributes.Value10.length + tmpModel.attributes.Value11.length + tmpModel.attributes.Value12.length + 
            tmpModel.attributes.Value13.length + tmpModel.attributes.Value14.length + tmpModel.attributes.Value15.length +
            tmpModel.attributes.Value16.length + tmpModel.attributes.Value17.length + tmpModel.attributes.Value18.length +
            tmpModel.attributes.Value19.length + tmpModel.attributes.Value20.length + tmpModel.attributes.Value21.length + 
            tmpModel.attributes.Value22.length + tmpModel.attributes.Value23.length + tmpModel.attributes.Value24.length +
            tmpModel.attributes.Value25.length + tmpModel.attributes.Value26.length + tmpModel.attributes.Value27.length +
            tmpModel.attributes.Value28.length + tmpModel.attributes.Value29.length + tmpModel.attributes.Value30.length;

        //If user insert a empty item
        if (ignoreEmptyContent) totalContentLength = 1;

        if (totalContentLength > 0) {
            //Romove all item with a bigger or equal id
            this.table.rows().every(function () {
                var d = this.data();
                if (d.id >= tmpModel.attributes.id) {
                    d.id++; // update data source for the row
                    this.invalidate(); // invalidate the data DataTables has cached for this row
                }
            });

            this.table.rows.add([{
                id: tmpModel.attributes.id,
                Position: tmpModel.attributes.id,
                EventId: tmpModel.attributes.EventId,
                Value01: tmpModel.attributes.Value01,
                Value02: tmpModel.attributes.Value02,
                Value03: tmpModel.attributes.Value03,
                Value04: tmpModel.attributes.Value04,
                Value05: tmpModel.attributes.Value05,
                Value06: tmpModel.attributes.Value06,
                Value07: tmpModel.attributes.Value07,
                Value08: tmpModel.attributes.Value08,
                Value09: tmpModel.attributes.Value09,
                Value10: tmpModel.attributes.Value10,
                Value11: tmpModel.attributes.Value11,
                Value12: tmpModel.attributes.Value12,
                Value13: tmpModel.attributes.Value13,
                Value14: tmpModel.attributes.Value14,
                Value15: tmpModel.attributes.Value15,
                Value16: tmpModel.attributes.Value16,
                Value17: tmpModel.attributes.Value17,
                Value18: tmpModel.attributes.Value18,
                Value19: tmpModel.attributes.Value19,
                Value20: tmpModel.attributes.Value20,
                Value21: tmpModel.attributes.Value21,
                Value22: tmpModel.attributes.Value22,
                Value23: tmpModel.attributes.Value23,
                Value24: tmpModel.attributes.Value24,
                Value25: tmpModel.attributes.Value25,
                Value26: tmpModel.attributes.Value26,
                Value27: tmpModel.attributes.Value27,
                Value28: tmpModel.attributes.Value28,
                Value29: tmpModel.attributes.Value29,
                Value30: tmpModel.attributes.Value30,
                orgid: -1,
            }]).draw();
        }

        if (jumpToData) {
            this.table.page.jumpToData(jumpFirst, 0);
            //this.jumpToData(jumpFirst, 0);
        }
    },
    jumpToData : function (data, column) {
        var pos = this.table.column(column, {order:'current'}).data().indexOf( data );

        if ( pos >= 0 ) {
            var page = Math.floor( pos / this.table.page.info().length );
            this.table.page( page ).draw( false );
        }
    },
    insertModel: function (tmpModel, jumpToData) {
        this.setHasChanged(true);

        var first = 1;
        var getFirst = false;
        this.$el.find('#customFormDataList_table tbody tr').each(function (i, tr) {
            if (!getFirst) {
                if ($(tr).attr('id') != undefined) {
                    first = parseInt($(tr).attr('id'));
                }
                getFirst = true;
            }
        });

        this.table.rows().every(function () {
            var d = this.data();
            if (d.id >= first) {
                d.id++; // update data source for the row
                this.invalidate(); // invalidate the data DataTables has cached for this row
            }
        });

        this.table.rows.add([{
            id: first,
            Position: first,
            EventId: tmpModel.attributes.EventId,
            Value01: tmpModel.attributes.Value01,
            Value02: tmpModel.attributes.Value02,
            Value03: tmpModel.attributes.Value03,
            Value04: tmpModel.attributes.Value04,
            Value05: tmpModel.attributes.Value05,
            Value06: tmpModel.attributes.Value06,
            Value07: tmpModel.attributes.Value07,
            Value08: tmpModel.attributes.Value08,
            Value09: tmpModel.attributes.Value09,
            Value10: tmpModel.attributes.Value10,
            Value11: tmpModel.attributes.Value11,
            Value12: tmpModel.attributes.Value12,
            Value13: tmpModel.attributes.Value13,
            Value14: tmpModel.attributes.Value14,
            Value15: tmpModel.attributes.Value15,
            Value16: tmpModel.attributes.Value16,
            Value17: tmpModel.attributes.Value17,
            Value18: tmpModel.attributes.Value18,
            Value19: tmpModel.attributes.Value19,
            Value20: tmpModel.attributes.Value20,
            Value21: tmpModel.attributes.Value21,
            Value22: tmpModel.attributes.Value22,
            Value23: tmpModel.attributes.Value23,
            Value24: tmpModel.attributes.Value24,
            Value25: tmpModel.attributes.Value25,
            Value26: tmpModel.attributes.Value26,
            Value27: tmpModel.attributes.Value27,
            Value28: tmpModel.attributes.Value28,
            Value29: tmpModel.attributes.Value29,
            Value30: tmpModel.attributes.Value30,
            orgid: -1,
        }]);//.draw();

        if (jumpToData) {
            this.table.page.jumpToData(parseInt(first), 0);
        }
    },
    table_rearrange: function () {
        //rearrange
        var prevdata = null;
        this.table.rows().every(function () {
            var data = this.data();

            if (prevdata != null) {
                if (data.id != (prevdata.id + 1)) {
                    data.id = prevdata.id + 1;
                    this.invalidate(); // invalidate the data DataTables has cached for this row
                }
            }
            else {
                if (data.id != 1) {
                    data.id = 1;
                    this.invalidate();
                }
            }

            prevdata = data;
        });
    },
    addDataTable: function () {
        var self = this;

        var formCounter = '1';
        var sElement = '';

        sElement += '<thead>';
        sElement += '</thead>';

        sElement += '<tfoot>';
        sElement += '<tr>';
        sElement += '</tr>';
        sElement += '</tfoot>';


        this.$el.find('#customFormDataList_formData').empty();
        //$(formElement).append('<h2 id="formDataHeader' + formCounter + '" style="text-align:center;">' + formForm[formCounter].Name + '</h2>');
        //this.$el.find('#customFormDataList_formData').append('<div class="table-responsive"><table id="customFormDataList_table" width="100%" class="table table-striped"  cellspacing="0"></table></div>');
        this.$el.find('#customFormDataList_formData').append('<div class="table-responsive"><table id="customFormDataList_table" width="100%" class="table table-striped"  cellspacing="0"></table></div>');

        var self = this;

        var c = this.collection.toJSON();
        for (var i = 0; i < c.length; i++) {
            c[i].orgid = c[i].id;
            c[i].id = c[i].Position;
        }

        var columns = this.getColumns();

        
        
        var lng = language.getLanguageDataTables();

        var customSetting = this.app.entities.collections.customSetting.where({ Name : 'FormDataItemPerPage' });
        var FormDataItemPerPage = customSetting[0].attributes.Value;

        var table = this.$el.find('#customFormDataList_table').DataTable({
            "language": lng,
            data: c,
            "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //Initialize row id for every row
                nRow.setAttribute('id', aData.id);
            },
            "columns": columns,
            "bInfo": false,
            "iDisplayLength": parseInt(FormDataItemPerPage)
        });

        this.table = table;
        this.$el.find('#customFormDataList_table').dataTable().rowReordering({});

        this.$el.find('#customFormDataList_table_wrapper').removeClass('form-inline');

        var self = this;

        //Edit row
        this.$el.find('#customFormDataList_table tbody').on('click', '.btn-secondary', function (val) {
            self.setHasChanged(true);

            var nRow = $(this).parents('tr');
            var jqInputs = $('input', nRow);
            var jqTextAreas = $('textarea', nRow);
            var jqtd = $('tr', nRow);

            var id = this.id;
            id = id.replace('btn_', '');
        
            var row = table.row(self.tmpTr);
            var data = row.data();

            var length = jqInputs.length + jqTextAreas.length;
            var index_jqInputs = 0;
            var index_jqTextAreas = 0;

            var fieldType = 1;
            var value = '';

            for (var i = 1; i < length; i++) {
                switch (i) {
                    case 1: fieldType = self.options.formModel.attributes.FieldType01; break;
                    case 2: fieldType = self.options.formModel.attributes.FieldType02; break;
                    case 3: fieldType = self.options.formModel.attributes.FieldType03; break;
                    case 4: fieldType = self.options.formModel.attributes.FieldType04; break;
                    case 5: fieldType = self.options.formModel.attributes.FieldType05; break;
                    case 6: fieldType = self.options.formModel.attributes.FieldType06; break;
                    case 7: fieldType = self.options.formModel.attributes.FieldType07; break;
                    case 8: fieldType = self.options.formModel.attributes.FieldType08; break;
                    case 9: fieldType = self.options.formModel.attributes.FieldType09; break;
                    case 10:fieldType = self.options.formModel.attributes.FieldType10; break;
                    case 11:fieldType = self.options.formModel.attributes.FieldType11; break;
                    case 12:fieldType = self.options.formModel.attributes.FieldType12; break;
                    case 13:fieldType = self.options.formModel.attributes.FieldType13; break;
                    case 14:fieldType = self.options.formModel.attributes.FieldType14; break;
                    case 15:fieldType = self.options.formModel.attributes.FieldType15; break;
                    case 16:fieldType = self.options.formModel.attributes.FieldType16; break;
                    case 17:fieldType = self.options.formModel.attributes.FieldType17; break;
                    case 18:fieldType = self.options.formModel.attributes.FieldType18; break;
                    case 19:fieldType = self.options.formModel.attributes.FieldType19; break;
                    case 20:fieldType = self.options.formModel.attributes.FieldType20; break;
                    case 21:fieldType = self.options.formModel.attributes.FieldType21; break;
                    case 22:fieldType = self.options.formModel.attributes.FieldType22; break;
                    case 23:fieldType = self.options.formModel.attributes.FieldType23; break;
                    case 24:fieldType = self.options.formModel.attributes.FieldType24; break;
                    case 25:fieldType = self.options.formModel.attributes.FieldType25; break;
                    case 26:fieldType = self.options.formModel.attributes.FieldType26; break;
                    case 27:fieldType = self.options.formModel.attributes.FieldType27; break;
                    case 28:fieldType = self.options.formModel.attributes.FieldType28; break;
                    case 29:fieldType = self.options.formModel.attributes.FieldType29; break;
                    case 30:fieldType = self.options.formModel.attributes.FieldType30; break;
                }

                switch (fieldType) {
                    case 1 :
                    case 3 :
                    case 4 :
                    case 5 :
                    case 6 : value = jqInputs[index_jqInputs].value; index_jqInputs++; break;
                    case 2 : value = jqTextAreas[index_jqTextAreas].value; index_jqTextAreas++; break; 
                }

                switch (i) {
                    case 1: data.Value01 = value; break;
                    case 2: data.Value02 = value; break;
                    case 3: data.Value03 = value; break;
                    case 4: data.Value04 = value; break;
                    case 5: data.Value05 = value; break;
                    case 6: data.Value06 = value; break;
                    case 7: data.Value07 = value; break;
                    case 8: data.Value08 = value; break;
                    case 9: data.Value09 = value; break;
                    case 10: data.Value10 = value; break;
                    case 11: data.Value11 = value; break;
                    case 12: data.Value12 = value; break;
                    case 13: data.Value13 = value; break;
                    case 14: data.Value14 = value; break;
                    case 15: data.Value15 = value; break;
                    case 16: data.Value16 = value; break;
                    case 17: data.Value17 = value; break;
                    case 18: data.Value18 = value; break;
                    case 19: data.Value19 = value; break;
                    case 20: data.Value20 = value; break;
                    case 21: data.Value21 = value; break;
                    case 22: data.Value22 = value; break;
                    case 23: data.Value23 = value; break;
                    case 24: data.Value24 = value; break;
                    case 25: data.Value25 = value; break;
                    case 26: data.Value26 = value; break;
                    case 27: data.Value27 = value; break;
                    case 28: data.Value28 = value; break;
                    case 29: data.Value29 = value; break;
                    case 30: data.Value30 = value; break;
                }
            }

            row.invalidate();

            row.child.hide();
            self.tmpTr.removeClass('shown');
        });

        //Expand row for edit
        this.$el.find('#customFormDataList_table tbody').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);

            self.tmpnRow = $(this).parents('tr');
            self.tmpTr = tr;

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                row.child(self.format(row.data())).show();
                tr.addClass('shown');
            }
        });

        var self = this;

        this.$el.find('#customFormDataList_table tbody').on('click', 'td.details-delete', function () {
            self.setHasChanged(true);

            //var infoBefore = table.page.info();

            //var tr = $(this).closest('tr');
            //var row = table.row(tr);

            //var data = row.data();
            //if (data.orgid != -1) {
            //    self.deleteRows.push(data);
            //}
            //row.remove().draw();
            //var infoAfter = table.page.info();
            //self.table_rearrange();

            //if (infoAfter.recordsTotal == infoBefore.start) {
            //    table.page.jumpToData(infoBefore.start, 0);
            //}
            //else {
            //    table.page.jumpToData(infoBefore.start + 1, 0);
            //}

            var infoBefore = table.page.info();
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var data = row.data();
            var infoAfter = table.page.info();

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100261;
            dialog2ButtonModel.attributes.Text = resources.X100266;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
            var callback01 = function (options) {
                if (data.orgid != -1) {
                    self.deleteRows.push(data);
                }
                row.remove().draw();
                self.table_rearrange();

                if (infoAfter.recordsTotal == infoBefore.start) {
                    self.table.page.jumpToData(infoBefore.start, 0);
                }
                else {
                    self.table.page.jumpToData(infoBefore.start + 1, 0);
                }
            }
            
            self.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            self.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(dialog2Button);
        });

        // Order by the grouping

        //this.$el.find('#customFormDataList_table tbody').on( 'click', 'tr.group', function () {
        //    var currentOrder = table.order()[0];
        //    if ( currentOrder[0] === 2 && currentOrder[1] === 'asc') {
        //        table.order( [ 2, 'desc'] ).draw();
        //    }
        //    else {
        //        table.order( [ 2, 'asc'] ).draw();
        //    }
        //});

        //this.$el.find('#customFormDataList_table').removeClass('dataTable');

        this.$el.find('#customFormDataList_table_paginate').parent().removeClass('col-sm-7').addClass('col-sm-12');
        this.$el.find('#customFormDataList_table_paginate').css('text-align', 'center');

        this.$el.find('.form-control.input-sm').css('width', '150px');

        $('#customFormDataList_table').on('length.dt', function ( e, settings, len ) {
            self.set_schemaData_length(len);
        } ); 
    },
    format: function format(d) {
        // `d` is the original data object for the row

        var edit = '';
        var title = '';
        var data = '';

        var fieldType = 1;

        for (var i = 0; i < this.options.formModel.attributes.FieldUsed; i++) {
            switch (i) {
                case 0: title = this.options.formModel.attributes.Field01; data = d.Value01; fieldType = this.options.formModel.attributes.FieldType01; break;
                case 1: title = this.options.formModel.attributes.Field02; data = d.Value02; fieldType = this.options.formModel.attributes.FieldType02; break;
                case 2: title = this.options.formModel.attributes.Field03; data = d.Value03; fieldType = this.options.formModel.attributes.FieldType03; break;
                case 3: title = this.options.formModel.attributes.Field04; data = d.Value04; fieldType = this.options.formModel.attributes.FieldType04; break;
                case 4: title = this.options.formModel.attributes.Field05; data = d.Value05; fieldType = this.options.formModel.attributes.FieldType05; break;
                case 5: title = this.options.formModel.attributes.Field06; data = d.Value06; fieldType = this.options.formModel.attributes.FieldType06; break;
                case 6: title = this.options.formModel.attributes.Field07; data = d.Value07; fieldType = this.options.formModel.attributes.FieldType07; break;
                case 7: title = this.options.formModel.attributes.Field08; data = d.Value08; fieldType = this.options.formModel.attributes.FieldType08; break;
                case 8: title = this.options.formModel.attributes.Field09; data = d.Value09; fieldType = this.options.formModel.attributes.FieldType09; break;
                case 9: title = this.options.formModel.attributes.Field10; data = d.Value10; fieldType = this.options.formModel.attributes.FieldType10; break;
                case 10: title = this.options.formModel.attributes.Field11; data = d.Value11;fieldType = this.options.formModel.attributes.FieldType11; break;
                case 11: title = this.options.formModel.attributes.Field12; data = d.Value12;fieldType = this.options.formModel.attributes.FieldType12; break;
                case 12: title = this.options.formModel.attributes.Field13; data = d.Value13;fieldType = this.options.formModel.attributes.FieldType13; break;
                case 13: title = this.options.formModel.attributes.Field14; data = d.Value14;fieldType = this.options.formModel.attributes.FieldType14; break;
                case 14: title = this.options.formModel.attributes.Field15; data = d.Value15;fieldType = this.options.formModel.attributes.FieldType15; break;
                case 15: title = this.options.formModel.attributes.Field16; data = d.Value16;fieldType = this.options.formModel.attributes.FieldType16; break;
                case 16: title = this.options.formModel.attributes.Field17; data = d.Value17;fieldType = this.options.formModel.attributes.FieldType17; break;
                case 17: title = this.options.formModel.attributes.Field18; data = d.Value18;fieldType = this.options.formModel.attributes.FieldType18; break;
                case 18: title = this.options.formModel.attributes.Field19; data = d.Value19;fieldType = this.options.formModel.attributes.FieldType19; break;
                case 20: title = this.options.formModel.attributes.Field21; data = d.Value21;fieldType = this.options.formModel.attributes.FieldType20; break;
                case 21: title = this.options.formModel.attributes.Field22; data = d.Value22;fieldType = this.options.formModel.attributes.FieldType21; break;
                case 22: title = this.options.formModel.attributes.Field23; data = d.Value23;fieldType = this.options.formModel.attributes.FieldType22; break;
                case 19: title = this.options.formModel.attributes.Field20; data = d.Value20;fieldType = this.options.formModel.attributes.FieldType23; break;
                case 23: title = this.options.formModel.attributes.Field24; data = d.Value24;fieldType = this.options.formModel.attributes.FieldType24; break;
                case 24: title = this.options.formModel.attributes.Field25; data = d.Value25;fieldType = this.options.formModel.attributes.FieldType25; break;
                case 25: title = this.options.formModel.attributes.Field26; data = d.Value26;fieldType = this.options.formModel.attributes.FieldType26; break;
                case 26: title = this.options.formModel.attributes.Field27; data = d.Value27;fieldType = this.options.formModel.attributes.FieldType27; break;
                case 27: title = this.options.formModel.attributes.Field28; data = d.Value28;fieldType = this.options.formModel.attributes.FieldType28; break;
                case 28: title = this.options.formModel.attributes.Field29; data = d.Value29;fieldType = this.options.formModel.attributes.FieldType29; break;
                case 29: title = this.options.formModel.attributes.Field30; data = d.Value30;fieldType = this.options.formModel.attributes.FieldType30; break;

            }

            switch(fieldType) {
                case 1 :
                case 3 :
                case 4 :
                case 5 :
                case 6 :
                    edit += '<tr>' +
                        '<td>' + title + '</td>' +
                        '<td><input value="' + data + '" /></td>' +
                    '</tr>';
                    break;
                case 2 :
                    edit += '<tr>' +
                        '<td>' + title + '</td>' +
                        '<td><textarea style="height:78px;width:144px;">' + data +'</textarea></td>' +
                    '</tr>';
                    break;

            }
        }

        return '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
            edit +
            '<tr>' +
                '<td><input class="btn btn-secondary btnFormData" id="btn_' + d.id + '" value="' + resources.X100267 +'"/></td>' +
            '</tr>' +

        '</table>';
    },
    getColumns: function () {
        var array = [];

        var tmp = {
            "className": 'details-control myFont',
            //    "orderable": false,
            //    'sortable' : false,
            "data": null,
            "defaultContent": '',
            "title": ''
        };

        tmp = { "data": "id", "title": "#", "visible": true };
        array.push(tmp);

        tmp = { "data": "id", "title": "orgid", "visible": false };
        array.push(tmp);

        var data = '';
        var title = '';

        for (var i = 0; i < this.options.formModel.attributes.FieldUsed; i++) {
            switch (i) {
                case 0: title = this.options.formModel.attributes.Field01; data = 'Value01'; break;
                case 1: title = this.options.formModel.attributes.Field02; data = 'Value02'; break;
                case 2: title = this.options.formModel.attributes.Field03; data = 'Value03'; break;
                case 3: title = this.options.formModel.attributes.Field04; data = 'Value04'; break;
                case 4: title = this.options.formModel.attributes.Field05; data = 'Value05'; break;
                case 5: title = this.options.formModel.attributes.Field06; data = 'Value06'; break;
                case 6: title = this.options.formModel.attributes.Field07; data = 'Value07'; break;
                case 7: title = this.options.formModel.attributes.Field08; data = 'Value08'; break;
                case 8: title = this.options.formModel.attributes.Field09; data = 'Value09'; break;
                case 9: title = this.options.formModel.attributes.Field10; data = 'Value10'; break;
                case 10: title = this.options.formModel.attributes.Field11; data = 'Value11'; break;
                case 11: title = this.options.formModel.attributes.Field12; data = 'Value12'; break;
                case 12: title = this.options.formModel.attributes.Field13; data = 'Value13'; break;
                case 13: title = this.options.formModel.attributes.Field14; data = 'Value14'; break;
                case 14: title = this.options.formModel.attributes.Field15; data = 'Value15'; break;
                case 15: title = this.options.formModel.attributes.Field16; data = 'Value16'; break;
                case 16: title = this.options.formModel.attributes.Field17; data = 'Value17'; break;
                case 17: title = this.options.formModel.attributes.Field18; data = 'Value18'; break;
                case 18: title = this.options.formModel.attributes.Field19; data = 'Value19'; break;
                case 19: title = this.options.formModel.attributes.Field20; data = 'Value20'; break;
                case 20: title = this.options.formModel.attributes.Field21; data = 'Value21'; break;
                case 21: title = this.options.formModel.attributes.Field22; data = 'Value22'; break;
                case 22: title = this.options.formModel.attributes.Field23; data = 'Value23'; break;
                case 23: title = this.options.formModel.attributes.Field24; data = 'Value24'; break;
                case 24: title = this.options.formModel.attributes.Field25; data = 'Value25'; break;
                case 25: title = this.options.formModel.attributes.Field26; data = 'Value26'; break;
                case 26: title = this.options.formModel.attributes.Field27; data = 'Value27'; break;
                case 27: title = this.options.formModel.attributes.Field28; data = 'Value28'; break;
                case 28: title = this.options.formModel.attributes.Field29; data = 'Value29'; break;
                case 29: title = this.options.formModel.attributes.Field30; data = 'Value30'; break;
            }

            tmp = { "data": data, "title": title, "className": 'myFont', };
            array.push(tmp);
        }

        tmp = {
            "className": 'details-delete',
            "orderable": false,
            "data": null,
            "defaultContent": '',
            "title": '--'
        };
        array.push(tmp);

        var tmp = {
            "className": 'details-control',
            //    "orderable": false,
            "data": null,
            "defaultContent": '',
            "title": '--'
        };
        array.push(tmp);

        return array;
    },
    customFormDataList_btnSubmit: function (event) {
        if (event) {
            event.preventDefault();
        }

        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100261;
        dialogWaitingModel.attributes.Text = resources.X100263;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);


        $.when(this.list_deleteAll(), this.list_insertAll(), this.list_editAll()).done(function (return_delete, return_insert, return_edit) {
            // the code here will be executed when all four ajax requests resolve.
            // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
            // status, and jqXHR object for each of the four ajax calls respectively.

            //this.app.entities.views.customFormData.table_readDataCollection();
            dialogWaiting.triggerMethod('dialog:close');
            self.triggerMethod('table_readDataCollection');
            self.setHasChanged(false);
        });
    },
    save: function (callbackRouter) {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100261;
        dialogWaitingModel.attributes.Text = resources.X100263;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        $.when(this.list_deleteAll(), this.list_insertAll(), this.list_editAll()).done(function (return_delete, return_insert, return_edit) {
            // the code here will be executed when all four ajax requests resolve.
            // return_delete, return_insert, return_edit are lists of length 3 containing the response text,
            // status, and jqXHR object for each of the four ajax calls respectively.

            //this.app.entities.views.customSchemaData.table_readDataCollection();
            dialogWaiting.triggerMethod('dialog:close');
            self.setHasChanged(false);
            callbackRouter();
        });
    },
    list_deleteAll: function () {
        if (this.deleteRows.length > 0) {
            for (var i = 0; i < this.deleteRows.length; i++) {
                this.deleteRows[i].id = this.deleteRows[i].orgid;
            }

            var data = {
                "data": this.deleteRows
            };

            var url = config.webApiUrl() + '/api/CustomFormData/route02/-1?AuthUserId=' + config.authUserId() + '&Dummy=1';
            var self = this;

            this.deleteRows = [];

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_insertAllOld: function () {
        var insertRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid == -1) {
                insertRows.push(d);
            }
        });

        if (insertRows.length > 0) {
            for (var i = 0; i < insertRows.length; i++) {
                insertRows[i].Position = insertRows[i].id;
                //insertRows[i].id = null;
            }

            var data = {
                "data": insertRows
            };

            var url = config.webApiUrl() + '/api/CustomFormData/route03/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_insertAll: function () {
        var insertRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid == -1) {
                insertRows.push(d);
            }
        });

        if (insertRows.length > 0) {
            for (var i = 0; i < insertRows.length; i++) {
                insertRows[i].Position = insertRows[i].id;
                //insertRows[i].id = null;
            }

            var data = {
                "data": insertRows
            };

            var url = config.webApiUrl() + '/api/CustomFormData/route03/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    },
    list_editAll: function () {
        var editRows = [];

        this.table.rows().every(function () {
            var d = this.data();
            if (d.orgid != -1) {
                editRows.push(d);
            }
        });

        if (editRows.length > 0) {
            for (var i = 0; i < editRows.length; i++) {
                editRows[i].Position = editRows[i].id;
                editRows[i].id = editRows[i].orgid;
            }

            var data = {
                "data": editRows
            };

            var url = config.webApiUrl() + '/api/CustomFormData/route04/-1?AuthUserId=' + config.authUserId() + '&Dummy=1&Dummy1=1&Dummy2=2';
            var self = this;

            return $.ajax({
                type: "POST",
                url: url,
                dataType: 'json',
                contentType: "application/json",
                data: JSON.stringify(data),//data,
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
                success: function (html) {
                }
            });
        }
    }
}, Backbone.Radio.Requests);