import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);

    },
    "sync": function (method, model, options) {
        if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customInfopageItem/' + model.attributes.id + '?AuthUserId=' + config.authUserId();

        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customInfopageItem/' + model.attributes.id + '?AuthUserId=' + config.authUserId();

        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customInfopageItem/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    },
    validateNoBackbone: function (app, element) {
        this.errors = [];

        var self = this;
        for (var i = 0; i < app.entities.collections.customInfopageItem.length; i++) {
            if ( (this.attributes.id != app.entities.collections.customInfopageItem.models[i].attributes.id) && (this.attributes.Webpage == app.entities.collections.customInfopageItem.models[i].attributes.Webpage) ) {
                if (this.attributes.Name.toLowerCase() == app.entities.collections.customInfopageItem.models[i].attributes.Name.toLowerCase()) {
                    //this.errors.push({ name: 'customInfopageEditItem_inputInsertWebpageName', message: resources.X100331 });
                    //this.errors.push({ name: 'customInfopageEditItem_editSelectWebpage', message: resources.X100331 });
                    this.errors.push({ name: element, message: resources.X100331 });
                }
            }
        };

        if (this.attributes.Name == "") {
            //this.errors.push({ name: 'customInfopageEditItem_inputInsertWebpageName', message: resources.X100324 });
            //this.errors.push({ name: 'customInfopageEditItem_editSelectWebpage', message: resources.X100324 });
            this.errors.push({ name: element, message: resources.X100324 });
        }

        

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
        this.changeSort("Position");
    },
    "sync": function (method, model, options) {
        //if (method == 'read') {
        //    options.url = config.webApiUrl() + '/api/customInfopageItem/-1?Webpage=' + options.Webpage + '&AuthUserId=' + config.authUserId();
        //}
        if (method == 'read' && options.readonlyOnepage == true) {
            options.url = config.webApiUrl() + '/api/customInfopageItem/route01/-1?Webpage=' + options.Webpage + '&AuthUserId=' + config.authUserId();
        }
        else if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customInfopageItem/route02/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    },
    comparator: function (property) {
        return selectedStrategy.apply(myModel.get(property));
    },
    strategies: {
        Position: function (person) { return person.get("Position"); }
    },
    changeSort: function (sortProperty) {
        this.comparator = this.strategies[sortProperty];
    },

});