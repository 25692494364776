import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if(method == 'create') {
            options.url = config.webApiUrl() + '/api/customEventTemplate/-1?AuthUserId=' + config.authUserId() + '&SourceId=' + options.sourceId + '&DestId=' + options.destId;
        }

        var m = Backbone.sync(method, model, options);

        return m;
    }
        
});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    

    state: {
        pageSize: 6,
    },

    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },

    options: {
        full: false
    },

    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customEventTemplate/-1?CultureName=' + config.cultureName() + '&Subject=' + options.subject;
        }
        
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
                : a.attributes.id < b.attributes.id ? 1
                : 0;
    }
});



