﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

export default Marionette.Region.extend({
    el: '#dialogRegion2',
    onShow: function (view) {
        //$('.modal-backdrop').remove();

        this.dialogRegion2 = view.dialogRegion2;

        var self = this;

        


        self.setupBindings(view);
        //self.$el.append(view.$el[0].innerHTML);
        //self.$el.append(view.render());

        $('#dialog2Process_modal').modal('show');

        
        this.removeModal = this.$el.find(view.dialogRegion2).modal({ backdrop: 'static', keyboard: false });
        this.removeModal.on('hidden.bs.modal', function () {
            self.closeDialog();
            //$('.modal-backdrop').remove();

        }).one('click', '#closebtn', function () {
            self.hideDialog();
        });

        this.removeModal.modal();

        if ($(".modal-backdrop.fade.show").length == 2) {
            $(".modal-backdrop.fade.show")[1].remove();
        }
        
        //Make pause after show
        //this.sleep(2000);

    },
    sleep: function (miliseconds) {
        var currentTime = new Date().getTime();

        while (currentTime + miliseconds >= new Date().getTime()) {
        }
        //return new Promise(resolve => setTimeout(resolve, ms));
    },
    hideDialog: function () {
        $(this.dialogRegion2).modal("hide");
    },
    setupBindings: function (view) {
        this.listenTo(view, "dialog:close", this.hideDialog);
    },
    closeDialog: function () {
        this.stopListening();
        this.reset();
        this.removeModal.off();

        document.body.className = "modal-open";

    },
    ee : function () {
        $('#dialogRegion2').empty();
    }
});