'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customFormDataInsert from './tpl/customFormDataInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import config from '../../../common/config';
import * as Entities_CustomFormData from '../../entities/customFormData';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customFormDataInsert();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
    },
    ui: {
        'customFormDataInsert_btnSubmit': '#customFormDataInsert_btnSubmit',
        'customFormDataInsert_input_Value01': '#customFormDataInsert_input_Value01',
        'customFormDataInsert_input_Value02': '#customFormDataInsert_input_Value02',
        'customFormDataInsert_input_Value03': '#customFormDataInsert_input_Value03',
        'customFormDataInsert_input_Value04': '#customFormDataInsert_input_Value04',
        'customFormDataInsert_input_Value05': '#customFormDataInsert_input_Value05',
        'customFormDataInsert_input_Value06': '#customFormDataInsert_input_Value06',
        'customFormDataInsert_input_Value07': '#customFormDataInsert_input_Value07',
        'customFormDataInsert_input_Value08': '#customFormDataInsert_input_Value08',
        'customFormDataInsert_input_Value09': '#customFormDataInsert_input_Value09',
        'customFormDataInsert_input_Value10': '#customFormDataInsert_input_Value10',
        'customFormDataInsert_input_Value11': '#customFormDataInsert_input_Value11',
        'customFormDataInsert_input_Value12': '#customFormDataInsert_input_Value12',
        'customFormDataInsert_input_Value13': '#customFormDataInsert_input_Value13',
        'customFormDataInsert_input_Value14': '#customFormDataInsert_input_Value14',
        'customFormDataInsert_input_Value15': '#customFormDataInsert_input_Value15',
        'customFormDataInsert_input_Value16': '#customFormDataInsert_input_Value16',
        'customFormDataInsert_input_Value17': '#customFormDataInsert_input_Value17',
        'customFormDataInsert_input_Value18': '#customFormDataInsert_input_Value18',
        'customFormDataInsert_input_Value19': '#customFormDataInsert_input_Value19',
        'customFormDataInsert_input_Value20': '#customFormDataInsert_input_Value20',
        'customFormDataInsert_input_Value21': '#customFormDataInsert_input_Value21',
        'customFormDataInsert_input_Value22': '#customFormDataInsert_input_Value22',
        'customFormDataInsert_input_Value23': '#customFormDataInsert_input_Value23',
        'customFormDataInsert_input_Value24': '#customFormDataInsert_input_Value24',
        'customFormDataInsert_input_Value25': '#customFormDataInsert_input_Value25',
        'customFormDataInsert_input_Value26': '#customFormDataInsert_input_Value26',
        'customFormDataInsert_input_Value27': '#customFormDataInsert_input_Value27',
        'customFormDataInsert_input_Value28': '#customFormDataInsert_input_Value28',
        'customFormDataInsert_input_Value29': '#customFormDataInsert_input_Value29',
        'customFormDataInsert_input_Value30': '#customFormDataInsert_input_Value30',

        'customFormDataInsert_textarea_Value01': '#customFormDataInsert_textarea_Value01',
        'customFormDataInsert_textarea_Value02': '#customFormDataInsert_textarea_Value02',
        'customFormDataInsert_textarea_Value03': '#customFormDataInsert_textarea_Value03',
        'customFormDataInsert_textarea_Value04': '#customFormDataInsert_textarea_Value04',
        'customFormDataInsert_textarea_Value05': '#customFormDataInsert_textarea_Value05',
        'customFormDataInsert_textarea_Value06': '#customFormDataInsert_textarea_Value06',
        'customFormDataInsert_textarea_Value07': '#customFormDataInsert_textarea_Value07',
        'customFormDataInsert_textarea_Value08': '#customFormDataInsert_textarea_Value08',
        'customFormDataInsert_textarea_Value09': '#customFormDataInsert_textarea_Value09',
        'customFormDataInsert_textarea_Value10': '#customFormDataInsert_textarea_Value10',
        'customFormDataInsert_textarea_Value11': '#customFormDataInsert_textarea_Value11',
        'customFormDataInsert_textarea_Value12': '#customFormDataInsert_textarea_Value12',
        'customFormDataInsert_textarea_Value13': '#customFormDataInsert_textarea_Value13',
        'customFormDataInsert_textarea_Value14': '#customFormDataInsert_textarea_Value14',
        'customFormDataInsert_textarea_Value15': '#customFormDataInsert_textarea_Value15',
        'customFormDataInsert_textarea_Value16': '#customFormDataInsert_textarea_Value16',
        'customFormDataInsert_textarea_Value17': '#customFormDataInsert_textarea_Value17',
        'customFormDataInsert_textarea_Value18': '#customFormDataInsert_textarea_Value18',
        'customFormDataInsert_textarea_Value19': '#customFormDataInsert_textarea_Value19',
        'customFormDataInsert_textarea_Value20': '#customFormDataInsert_textarea_Value20',
        'customFormDataInsert_textarea_Value21': '#customFormDataInsert_textarea_Value21',
        'customFormDataInsert_textarea_Value22': '#customFormDataInsert_textarea_Value22',
        'customFormDataInsert_textarea_Value23': '#customFormDataInsert_textarea_Value23',
        'customFormDataInsert_textarea_Value24': '#customFormDataInsert_textarea_Value24',
        'customFormDataInsert_textarea_Value25': '#customFormDataInsert_textarea_Value25',
        'customFormDataInsert_textarea_Value26': '#customFormDataInsert_textarea_Value26',
        'customFormDataInsert_textarea_Value27': '#customFormDataInsert_textarea_Value27',
        'customFormDataInsert_textarea_Value28': '#customFormDataInsert_textarea_Value28',
        'customFormDataInsert_textarea_Value29': '#customFormDataInsert_textarea_Value29',
        'customFormDataInsert_textarea_Value30': '#customFormDataInsert_textarea_Value30',

        'customFormDataInsert_label_Value01': '#customFormDataInsert_label_Value01',
        'customFormDataInsert_label_Value02': '#customFormDataInsert_label_Value02',
        'customFormDataInsert_label_Value03': '#customFormDataInsert_label_Value03',
        'customFormDataInsert_label_Value04': '#customFormDataInsert_label_Value04',
        'customFormDataInsert_label_Value05': '#customFormDataInsert_label_Value05',
        'customFormDataInsert_label_Value06': '#customFormDataInsert_label_Value06',
        'customFormDataInsert_label_Value07': '#customFormDataInsert_label_Value07',
        'customFormDataInsert_label_Value08': '#customFormDataInsert_label_Value08',
        'customFormDataInsert_label_Value09': '#customFormDataInsert_label_Value09',
        'customFormDataInsert_label_Value10': '#customFormDataInsert_label_Value10',
        'customFormDataInsert_label_Value11': '#customFormDataInsert_label_Value11',
        'customFormDataInsert_label_Value12': '#customFormDataInsert_label_Value12',
        'customFormDataInsert_label_Value13': '#customFormDataInsert_label_Value13',
        'customFormDataInsert_label_Value14': '#customFormDataInsert_label_Value14',
        'customFormDataInsert_label_Value15': '#customFormDataInsert_label_Value15',
        'customFormDataInsert_label_Value16': '#customFormDataInsert_label_Value16',
        'customFormDataInsert_label_Value17': '#customFormDataInsert_label_Value17',
        'customFormDataInsert_label_Value18': '#customFormDataInsert_label_Value18',
        'customFormDataInsert_label_Value19': '#customFormDataInsert_label_Value19',
        'customFormDataInsert_label_Value20': '#customFormDataInsert_label_Value20',
        'customFormDataInsert_label_Value21': '#customFormDataInsert_label_Value21',
        'customFormDataInsert_label_Value22': '#customFormDataInsert_label_Value22',
        'customFormDataInsert_label_Value23': '#customFormDataInsert_label_Value23',
        'customFormDataInsert_label_Value24': '#customFormDataInsert_label_Value24',
        'customFormDataInsert_label_Value25': '#customFormDataInsert_label_Value25',
        'customFormDataInsert_label_Value26': '#customFormDataInsert_label_Value26',
        'customFormDataInsert_label_Value27': '#customFormDataInsert_label_Value27',
        'customFormDataInsert_label_Value28': '#customFormDataInsert_label_Value28',
        'customFormDataInsert_label_Value29': '#customFormDataInsert_label_Value29',
        'customFormDataInsert_label_Value30': '#customFormDataInsert_label_Value30',
    },
    events: {
        'click @ui.customFormDataInsert_btnSubmit': 'btnSubmitClick',
    },
    showHideElement : function (id, element_input, element_textarea)
    {
        var fieldType = this.getFieldType(id);

        switch(fieldType) {
            case 1 :
            case 3 :
            case 4 :
            case 5 :
            case 6 :
                element_input.show();
                break;
            case 2 :
                element_textarea.show();
                break;

        }
    },
    setupFieldType : function ()
    {
        this.showHideElement(1, this.ui.customFormDataInsert_input_Value01, this.ui.customFormDataInsert_textarea_Value01);
        this.showHideElement(2, this.ui.customFormDataInsert_input_Value02, this.ui.customFormDataInsert_textarea_Value02);
        this.showHideElement(3, this.ui.customFormDataInsert_input_Value03, this.ui.customFormDataInsert_textarea_Value03);
        this.showHideElement(4, this.ui.customFormDataInsert_input_Value04, this.ui.customFormDataInsert_textarea_Value04);
        this.showHideElement(5, this.ui.customFormDataInsert_input_Value05, this.ui.customFormDataInsert_textarea_Value05);
        this.showHideElement(6, this.ui.customFormDataInsert_input_Value06, this.ui.customFormDataInsert_textarea_Value06);
        this.showHideElement(7, this.ui.customFormDataInsert_input_Value07, this.ui.customFormDataInsert_textarea_Value07);
        this.showHideElement(8, this.ui.customFormDataInsert_input_Value08, this.ui.customFormDataInsert_textarea_Value08);
        this.showHideElement(9, this.ui.customFormDataInsert_input_Value09, this.ui.customFormDataInsert_textarea_Value09);
        this.showHideElement(10, this.ui.customFormDataInsert_input_Value10, this.ui.customFormDataInsert_textarea_Value10);
        this.showHideElement(11, this.ui.customFormDataInsert_input_Value11, this.ui.customFormDataInsert_textarea_Value11);
        this.showHideElement(12, this.ui.customFormDataInsert_input_Value12, this.ui.customFormDataInsert_textarea_Value12);
        this.showHideElement(13, this.ui.customFormDataInsert_input_Value13, this.ui.customFormDataInsert_textarea_Value13);
        this.showHideElement(14, this.ui.customFormDataInsert_input_Value14, this.ui.customFormDataInsert_textarea_Value14);
        this.showHideElement(15, this.ui.customFormDataInsert_input_Value15, this.ui.customFormDataInsert_textarea_Value15);
        this.showHideElement(16, this.ui.customFormDataInsert_input_Value16, this.ui.customFormDataInsert_textarea_Value16);
        this.showHideElement(17, this.ui.customFormDataInsert_input_Value17, this.ui.customFormDataInsert_textarea_Value17);
        this.showHideElement(18, this.ui.customFormDataInsert_input_Value18, this.ui.customFormDataInsert_textarea_Value18);
        this.showHideElement(19, this.ui.customFormDataInsert_input_Value19, this.ui.customFormDataInsert_textarea_Value19);
        this.showHideElement(20, this.ui.customFormDataInsert_input_Value20, this.ui.customFormDataInsert_textarea_Value20);
        this.showHideElement(21, this.ui.customFormDataInsert_input_Value21, this.ui.customFormDataInsert_textarea_Value21);
        this.showHideElement(22, this.ui.customFormDataInsert_input_Value22, this.ui.customFormDataInsert_textarea_Value22);
        this.showHideElement(23, this.ui.customFormDataInsert_input_Value23, this.ui.customFormDataInsert_textarea_Value23);
        this.showHideElement(24, this.ui.customFormDataInsert_input_Value24, this.ui.customFormDataInsert_textarea_Value24);
        this.showHideElement(25, this.ui.customFormDataInsert_input_Value25, this.ui.customFormDataInsert_textarea_Value25);
        this.showHideElement(26, this.ui.customFormDataInsert_input_Value26, this.ui.customFormDataInsert_textarea_Value26);
        this.showHideElement(27, this.ui.customFormDataInsert_input_Value27, this.ui.customFormDataInsert_textarea_Value27);
        this.showHideElement(28, this.ui.customFormDataInsert_input_Value28, this.ui.customFormDataInsert_textarea_Value28);
        this.showHideElement(29, this.ui.customFormDataInsert_input_Value29, this.ui.customFormDataInsert_textarea_Value29);
        this.showHideElement(30, this.ui.customFormDataInsert_input_Value30, this.ui.customFormDataInsert_textarea_Value30);
    },
    getFieldType : function (id)
    {
        var fieldType = 1;
        switch(id) {
            case 1 : fieldType = this.options.formModel.attributes.FieldType01; break;
            case 2 : fieldType = this.options.formModel.attributes.FieldType02; break;
            case 3 : fieldType = this.options.formModel.attributes.FieldType03; break;
            case 4 : fieldType = this.options.formModel.attributes.FieldType04; break;
            case 5 : fieldType = this.options.formModel.attributes.FieldType05; break;
            case 6 : fieldType = this.options.formModel.attributes.FieldType06; break;
            case 7 : fieldType = this.options.formModel.attributes.FieldType07; break;
            case 8 : fieldType = this.options.formModel.attributes.FieldType08; break;
            case 9 : fieldType = this.options.formModel.attributes.FieldType09; break;
            case 10 : fieldType = this.options.formModel.attributes.FieldType10; break;
            case 11 : fieldType = this.options.formModel.attributes.FieldType11; break;
            case 12 : fieldType = this.options.formModel.attributes.FieldType12; break;
            case 13 : fieldType = this.options.formModel.attributes.FieldType13; break;
            case 14 : fieldType = this.options.formModel.attributes.FieldType14; break;
            case 15 : fieldType = this.options.formModel.attributes.FieldType15; break;
            case 16 : fieldType = this.options.formModel.attributes.FieldType16; break;
            case 17 : fieldType = this.options.formModel.attributes.FieldType17; break;
            case 18 : fieldType = this.options.formModel.attributes.FieldType18; break;
            case 19 : fieldType = this.options.formModel.attributes.FieldType19; break;
            case 20 : fieldType = this.options.formModel.attributes.FieldType20; break;
            case 21 : fieldType = this.options.formModel.attributes.FieldType21; break;
            case 22 : fieldType = this.options.formModel.attributes.FieldType22; break;
            case 23 : fieldType = this.options.formModel.attributes.FieldType23; break;
            case 24 : fieldType = this.options.formModel.attributes.FieldType24; break;
            case 25 : fieldType = this.options.formModel.attributes.FieldType25; break;
            case 26 : fieldType = this.options.formModel.attributes.FieldType26; break;
            case 27 : fieldType = this.options.formModel.attributes.FieldType27; break;
            case 28 : fieldType = this.options.formModel.attributes.FieldType28; break;
            case 29 : fieldType = this.options.formModel.attributes.FieldType29; break;
            case 30 : fieldType = this.options.formModel.attributes.FieldType30; break;
        }
        return fieldType;
    },
    getInputValue : function (id, element_input, element_textarea)
    {
        var fieldType = this.getFieldType(id);

        switch(fieldType) {
            case 1 :
            case 3 :
            case 4 :
            case 5 :
            case 6 :
                return element_input.val();
                break;
            case 2 :
                return element_textarea.val();
                break;

        }
    },
    btnSubmitClick: function (event) {
        event.preventDefault();
        this.hideErrors();
        //var tmpModel = new this.app.entities.collections.customFormData.model();
        var tmpModel = new Entities_CustomFormData.Model(); 
        //tmpModel.attributes.id = null;

        tmpModel.attributes.Value01 = '';
        tmpModel.attributes.Value02 = '';
        tmpModel.attributes.Value03 = '';
        tmpModel.attributes.Value04 = '';
        tmpModel.attributes.Value05 = '';
        tmpModel.attributes.Value06 = '';
        tmpModel.attributes.Value07 = '';
        tmpModel.attributes.Value08 = '';
        tmpModel.attributes.Value09 = '';
        tmpModel.attributes.Value10 = '';
        tmpModel.attributes.Value11 = '';
        tmpModel.attributes.Value12 = '';
        tmpModel.attributes.Value13 = '';
        tmpModel.attributes.Value14 = '';
        tmpModel.attributes.Value15 = '';
        tmpModel.attributes.Value16 = '';
        tmpModel.attributes.Value17 = '';
        tmpModel.attributes.Value18 = '';
        tmpModel.attributes.Value19 = '';
        tmpModel.attributes.Value20 = '';
        tmpModel.attributes.Value21 = '';
        tmpModel.attributes.Value22 = '';
        tmpModel.attributes.Value23 = '';
        tmpModel.attributes.Value24 = '';
        tmpModel.attributes.Value25 = '';
        tmpModel.attributes.Value26 = '';
        tmpModel.attributes.Value27 = '';
        tmpModel.attributes.Value28 = '';
        tmpModel.attributes.Value29 = '';
        tmpModel.attributes.Value30 = '';

        tmpModel.attributes.EventId = this.settingModel.attributes.EventId;
        tmpModel.attributes.id = 1;//app.views.customFormDataList.table_getFirstVisibleItem();
        tmpModel.attributes.orgid = -1;

        tmpModel.attributes.Value01 = this.getInputValue(1, this.ui.customFormDataInsert_input_Value01, this.ui.customFormDataInsert_textarea_Value01);
        tmpModel.attributes.Value02 = this.getInputValue(2, this.ui.customFormDataInsert_input_Value02, this.ui.customFormDataInsert_textarea_Value02);
        tmpModel.attributes.Value03 = this.getInputValue(3, this.ui.customFormDataInsert_input_Value03, this.ui.customFormDataInsert_textarea_Value03);
        tmpModel.attributes.Value04 = this.getInputValue(4, this.ui.customFormDataInsert_input_Value04, this.ui.customFormDataInsert_textarea_Value04);
        tmpModel.attributes.Value05 = this.getInputValue(5, this.ui.customFormDataInsert_input_Value05, this.ui.customFormDataInsert_textarea_Value05);
        tmpModel.attributes.Value06 = this.getInputValue(6, this.ui.customFormDataInsert_input_Value06, this.ui.customFormDataInsert_textarea_Value06);
        tmpModel.attributes.Value07 = this.getInputValue(7, this.ui.customFormDataInsert_input_Value07, this.ui.customFormDataInsert_textarea_Value07);
        tmpModel.attributes.Value08 = this.getInputValue(8, this.ui.customFormDataInsert_input_Value08, this.ui.customFormDataInsert_textarea_Value08);
        tmpModel.attributes.Value09 = this.getInputValue(9, this.ui.customFormDataInsert_input_Value09, this.ui.customFormDataInsert_textarea_Value09);
        tmpModel.attributes.Value10 = this.getInputValue(10, this.ui.customFormDataInsert_input_Value10, this.ui.customFormDataInsert_textarea_Value10);
        tmpModel.attributes.Value11 = this.getInputValue(11, this.ui.customFormDataInsert_input_Value11, this.ui.customFormDataInsert_textarea_Value11);
        tmpModel.attributes.Value12 = this.getInputValue(12, this.ui.customFormDataInsert_input_Value12, this.ui.customFormDataInsert_textarea_Value12);
        tmpModel.attributes.Value13 = this.getInputValue(13, this.ui.customFormDataInsert_input_Value13, this.ui.customFormDataInsert_textarea_Value13);
        tmpModel.attributes.Value14 = this.getInputValue(14, this.ui.customFormDataInsert_input_Value14, this.ui.customFormDataInsert_textarea_Value14);
        tmpModel.attributes.Value15 = this.getInputValue(15, this.ui.customFormDataInsert_input_Value15, this.ui.customFormDataInsert_textarea_Value15);
        tmpModel.attributes.Value16 = this.getInputValue(16, this.ui.customFormDataInsert_input_Value16, this.ui.customFormDataInsert_textarea_Value16);
        tmpModel.attributes.Value17 = this.getInputValue(17, this.ui.customFormDataInsert_input_Value17, this.ui.customFormDataInsert_textarea_Value17);
        tmpModel.attributes.Value18 = this.getInputValue(18, this.ui.customFormDataInsert_input_Value18, this.ui.customFormDataInsert_textarea_Value18);
        tmpModel.attributes.Value19 = this.getInputValue(19, this.ui.customFormDataInsert_input_Value19, this.ui.customFormDataInsert_textarea_Value19);
        tmpModel.attributes.Value20 = this.getInputValue(20, this.ui.customFormDataInsert_input_Value20, this.ui.customFormDataInsert_textarea_Value20);
        tmpModel.attributes.Value21 = this.getInputValue(21, this.ui.customFormDataInsert_input_Value21, this.ui.customFormDataInsert_textarea_Value21);
        tmpModel.attributes.Value22 = this.getInputValue(22, this.ui.customFormDataInsert_input_Value22, this.ui.customFormDataInsert_textarea_Value22);
        tmpModel.attributes.Value23 = this.getInputValue(23, this.ui.customFormDataInsert_input_Value23, this.ui.customFormDataInsert_textarea_Value23);
        tmpModel.attributes.Value24 = this.getInputValue(24, this.ui.customFormDataInsert_input_Value24, this.ui.customFormDataInsert_textarea_Value24);
        tmpModel.attributes.Value25 = this.getInputValue(25, this.ui.customFormDataInsert_input_Value25, this.ui.customFormDataInsert_textarea_Value25);
        tmpModel.attributes.Value26 = this.getInputValue(26, this.ui.customFormDataInsert_input_Value26, this.ui.customFormDataInsert_textarea_Value26);
        tmpModel.attributes.Value27 = this.getInputValue(27, this.ui.customFormDataInsert_input_Value27, this.ui.customFormDataInsert_textarea_Value27);
        tmpModel.attributes.Value28 = this.getInputValue(28, this.ui.customFormDataInsert_input_Value28, this.ui.customFormDataInsert_textarea_Value28);
        tmpModel.attributes.Value29 = this.getInputValue(29, this.ui.customFormDataInsert_input_Value29, this.ui.customFormDataInsert_textarea_Value29);
        tmpModel.attributes.Value30 = this.getInputValue(30, this.ui.customFormDataInsert_input_Value30, this.ui.customFormDataInsert_textarea_Value30);
        

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(app);
        if (ret == false) {
            this.ui.customFormDataInsert_input_Value01.val('');
            this.ui.customFormDataInsert_input_Value02.val('');
            this.ui.customFormDataInsert_input_Value03.val('');
            this.ui.customFormDataInsert_input_Value04.val('');
            this.ui.customFormDataInsert_input_Value05.val('');
            this.ui.customFormDataInsert_input_Value06.val('');
            this.ui.customFormDataInsert_input_Value07.val('');
            this.ui.customFormDataInsert_input_Value08.val('');
            this.ui.customFormDataInsert_input_Value09.val('');
            this.ui.customFormDataInsert_input_Value10.val('');
            this.ui.customFormDataInsert_input_Value11.val('');
            this.ui.customFormDataInsert_input_Value12.val('');
            this.ui.customFormDataInsert_input_Value13.val('');
            this.ui.customFormDataInsert_input_Value14.val('');
            this.ui.customFormDataInsert_input_Value15.val('');
            this.ui.customFormDataInsert_input_Value16.val('');
            this.ui.customFormDataInsert_input_Value17.val('');
            this.ui.customFormDataInsert_input_Value18.val('');
            this.ui.customFormDataInsert_input_Value19.val('');
            this.ui.customFormDataInsert_input_Value20.val('');
            this.ui.customFormDataInsert_input_Value21.val('');
            this.ui.customFormDataInsert_input_Value22.val('');
            this.ui.customFormDataInsert_input_Value23.val('');
            this.ui.customFormDataInsert_input_Value24.val('');
            this.ui.customFormDataInsert_input_Value25.val('');
            this.ui.customFormDataInsert_input_Value26.val('');
            this.ui.customFormDataInsert_input_Value27.val('');
            this.ui.customFormDataInsert_input_Value28.val('');
            this.ui.customFormDataInsert_input_Value29.val('');
            this.ui.customFormDataInsert_input_Value30.val('');

            this.ui.customFormDataInsert_textarea_Value01.val('');
            this.ui.customFormDataInsert_textarea_Value02.val('');
            this.ui.customFormDataInsert_textarea_Value03.val('');
            this.ui.customFormDataInsert_textarea_Value04.val('');
            this.ui.customFormDataInsert_textarea_Value05.val('');
            this.ui.customFormDataInsert_textarea_Value06.val('');
            this.ui.customFormDataInsert_textarea_Value07.val('');
            this.ui.customFormDataInsert_textarea_Value08.val('');
            this.ui.customFormDataInsert_textarea_Value09.val('');
            this.ui.customFormDataInsert_textarea_Value10.val('');
            this.ui.customFormDataInsert_textarea_Value11.val('');
            this.ui.customFormDataInsert_textarea_Value12.val('');
            this.ui.customFormDataInsert_textarea_Value13.val('');
            this.ui.customFormDataInsert_textarea_Value14.val('');
            this.ui.customFormDataInsert_textarea_Value15.val('');
            this.ui.customFormDataInsert_textarea_Value16.val('');
            this.ui.customFormDataInsert_textarea_Value17.val('');
            this.ui.customFormDataInsert_textarea_Value18.val('');
            this.ui.customFormDataInsert_textarea_Value19.val('');
            this.ui.customFormDataInsert_textarea_Value20.val('');
            this.ui.customFormDataInsert_textarea_Value21.val('');
            this.ui.customFormDataInsert_textarea_Value22.val('');
            this.ui.customFormDataInsert_textarea_Value23.val('');
            this.ui.customFormDataInsert_textarea_Value24.val('');
            this.ui.customFormDataInsert_textarea_Value25.val('');
            this.ui.customFormDataInsert_textarea_Value26.val('');
            this.ui.customFormDataInsert_textarea_Value27.val('');
            this.ui.customFormDataInsert_textarea_Value28.val('');
            this.ui.customFormDataInsert_textarea_Value29.val('');
            this.ui.customFormDataInsert_textarea_Value30.val('');
        

            var self = this;
            //app.views.customFormDataList.table_addModel(tmpModel, true, tmpModel.attributes.id, true);
            var msg = {
                tmpModel: tmpModel,
                jumpToData: true,
                jumpFirst: tmpModel.attributes.id,
                ignoreEmptyContent: true
            }
            this.triggerMethod('table_addModel', msg);
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    },
    onRender: function () {
        this.ui.customFormDataInsert_label_Value01.text(this.options.formModel.attributes.Field01);
        this.ui.customFormDataInsert_label_Value02.text(this.options.formModel.attributes.Field02);
        this.ui.customFormDataInsert_label_Value03.text(this.options.formModel.attributes.Field03);
        this.ui.customFormDataInsert_label_Value04.text(this.options.formModel.attributes.Field04);
        this.ui.customFormDataInsert_label_Value05.text(this.options.formModel.attributes.Field05);
        this.ui.customFormDataInsert_label_Value06.text(this.options.formModel.attributes.Field06);
        this.ui.customFormDataInsert_label_Value07.text(this.options.formModel.attributes.Field07);
        this.ui.customFormDataInsert_label_Value08.text(this.options.formModel.attributes.Field08);
        this.ui.customFormDataInsert_label_Value09.text(this.options.formModel.attributes.Field09);
        this.ui.customFormDataInsert_label_Value10.text(this.options.formModel.attributes.Field10);
        this.ui.customFormDataInsert_label_Value11.text(this.options.formModel.attributes.Field11);
        this.ui.customFormDataInsert_label_Value12.text(this.options.formModel.attributes.Field12);
        this.ui.customFormDataInsert_label_Value13.text(this.options.formModel.attributes.Field13);
        this.ui.customFormDataInsert_label_Value14.text(this.options.formModel.attributes.Field14);
        this.ui.customFormDataInsert_label_Value15.text(this.options.formModel.attributes.Field15);
        this.ui.customFormDataInsert_label_Value16.text(this.options.formModel.attributes.Field16);
        this.ui.customFormDataInsert_label_Value17.text(this.options.formModel.attributes.Field17);
        this.ui.customFormDataInsert_label_Value18.text(this.options.formModel.attributes.Field18);
        this.ui.customFormDataInsert_label_Value19.text(this.options.formModel.attributes.Field19);
        this.ui.customFormDataInsert_label_Value20.text(this.options.formModel.attributes.Field20);
        this.ui.customFormDataInsert_label_Value21.text(this.options.formModel.attributes.Field21);
        this.ui.customFormDataInsert_label_Value22.text(this.options.formModel.attributes.Field22);
        this.ui.customFormDataInsert_label_Value23.text(this.options.formModel.attributes.Field23);
        this.ui.customFormDataInsert_label_Value24.text(this.options.formModel.attributes.Field24);
        this.ui.customFormDataInsert_label_Value25.text(this.options.formModel.attributes.Field25);
        this.ui.customFormDataInsert_label_Value26.text(this.options.formModel.attributes.Field26);
        this.ui.customFormDataInsert_label_Value27.text(this.options.formModel.attributes.Field27);
        this.ui.customFormDataInsert_label_Value28.text(this.options.formModel.attributes.Field28);
        this.ui.customFormDataInsert_label_Value29.text(this.options.formModel.attributes.Field29);
        this.ui.customFormDataInsert_label_Value30.text(this.options.formModel.attributes.Field30);

        this.selectFieldUsedChange();

        this.setupFieldType();

        if (this.app.entities.models.configSubscription[0].attributes.EnableFormModel == false) {
            this.ui.customFormDataInsert_btnSubmit.prop('disabled', true);
        } else if (this.settingModel.attributes.EventId > 0) {
            this.ui.customFormDataInsert_btnSubmit.prop('disabled', false);
        }
        else {
            this.ui.customFormDataInsert_btnSubmit.prop('disabled', true);
        }

        return this;
    },
    selectFieldUsedChange: function () {
        for (var i = 1; i <= 30; i++) {
            var sId = '';
            if (i < 10) {
                sId = '#customFormDataInsert_div_Value0' + i;
            }
            else {
                sId = '#customFormDataInsert_div_Value' + i;
            }

            if (i <= this.options.formModel.attributes.FieldUsed) {
                this.$el.find(sId).show();
            }
            else {
                this.$el.find(sId).css('display', 'none');
            }
        }
    }
});