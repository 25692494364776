var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetImageTableListItem_empty"> <div class="col-xs-4 col-sm-4 col-md-4 col-4">  <div style="margin-top:10px"> <div class="image" style="text-align:center;max-height:300px;min-height:300px"> <img alt="image" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'"> </div> <p class="name" style="height:42px; overflow: hidden;text-overflow: ellipsis;text-align:center">'+
((__t=( model.Name ))==null?'':__t)+
'</p> <div class="row"> <div class="col-md-12"> <button type="button" class="btn btn-primary btn-block" id="customProductSheetImageTableListItem_btnDelete">'+
((__t=( resources['X100289'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
