'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
//import * as moment from 'moment';
var moment = require('moment');

import tpl_customSubscriptionListItem from './tpl/customSubscriptionListItem.html';
import language from '../../../common/language';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import paymentGateway from '../../../common/helper/paymentGateway';
import * as Entities_DineroInvoice from '../../entities/dineroInvoice';
import View_DialogBoxInactive from '../../../lib/views/dialogBoxInactive/dialogBoxInactive';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customSubscriptionListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.foundModelConfigSubscription = this.app.entities.collections.configSubscription.where({ GlobalId : this.model.attributes.ConfigSubscriptionGlobalId, Currency :  this.model.attributes.Currency});
        this.modelConfigSubscription = this.foundModelConfigSubscription[0];

        this.localSetting = new Entities_Empty.Model();

        this.setupMessage();
    },
    ui: {
        //'customSubscriptionListItem_btnPay': '#customSubscriptionListItem_btnPay'
        'registerMailConfirmedSucces_div_paymentGateway' : '#registerMailConfirmedSucces_div_paymentGateway',
        'customSubscriptionListItem_btnInvoice' : '#customSubscriptionListItem_btnInvoice'
    },
    events: {
        //'click @ui.customSubscriptionListItem_btnPay': 'btnPayClick'
        'click @ui.customSubscriptionListItem_btnInvoice': 'btnInvoiceClick',
        "submit": "Inactive"
    },
    onRender: function () {
        var self = this;
        var btnText = '';

        this.localSetting.attributes.PaymentEvent = 0;

        var price = this.model.attributes.Price;
        var customSubscriptionJob = this.app.entities.collections.customSubscriptionJob.where( { SubscriptionGuid : this.model.attributes.SubscriptionGuid });

        if (customSubscriptionJob.length > 0) {
            price += customSubscriptionJob[0].attributes.Price;
        }

        if (price > 0) {
            switch (this.model.attributes.DineroInvoicePaymentStatus) {
                case 'Draft' : 
                case 'Booked' : btnText = resources.X100711; this.localSetting.attributes.PaymentEvent = 1; break;
                case 'Paid' : 
                case 'OverPaid' : btnText = resources.X100712; this.localSetting.attributes.PaymentEvent = 0; break;
                case 'Overdue' : btnText = resources.X100713; this.localSetting.attributes.PaymentEvent = 1; break;
            }
        }

        /*if (price > 0) {
            this.ui.customSubscriptionListItem_btnInvoice.prop('disabled', false);
        } else {
            this.ui.customSubscriptionListItem_btnInvoice.prop('disabled', true);
        }*/

        if (price > 0) {
            switch (this.model.attributes.DineroInvoicePaymentStatus) {
                case 'Draft' : 
                    //case 'Booked' : this.ui.customSubscriptionListItem_btnInvoice.prop('disabled', true); break;
                case 'Booked' : this.ui.customSubscriptionListItem_btnInvoice.css('display', 'none'); break;
                case 'Paid' : 
                case 'OverPaid' : 
                    //case 'Overdue' : this.ui.customSubscriptionListItem_btnInvoice.prop('disabled', false); break;
                case 'Overdue' : this.ui.customSubscriptionListItem_btnInvoice.show(); break;
            }
        } else {
            this.ui.customSubscriptionListItem_btnInvoice.css('display', 'none');
        }

        //this.ui.customSubscriptionListItem_btnPay.html(btnText);

        var callbackRender = function(html) {
            self.ui.registerMailConfirmedSucces_div_paymentGateway.html(html);
        }

        if (this.localSetting.attributes.PaymentEvent == 1) {
            var callbackGetPaymentGateway = function () {
                var modelConfigSubscriptionPayment = self.app.entities.collections.publicConfigSubscriptionPayment.where( {id : self.model.attributes.ConfigSubscriptionPaymentId});

                // if type = 0 (Banktransfer) find the default payment gateway
                if (modelConfigSubscriptionPayment.length > 0) {
                    if (modelConfigSubscriptionPayment[0].attributes.Type == 0) {
                        modelConfigSubscriptionPayment = self.app.entities.collections.publicConfigSubscriptionPayment.where( {Type : 1, IsDefault : true});        
                    }
                }

                var modelCustomSubscriptionJob = null;
                if (app.entities.collections.customSubscriptionJob.fullCollection.length > 0) {
                    //modelCustomSubscriptionJob = app.entities.collections.publicCustomSubscriptionJob.fullCollection.models[0];
                    var modelCustomSubscriptionJobList = app.entities.collections.customSubscriptionJob.fullCollection.where({ SubscriptionGuid: self.model.attributes.SubscriptionGuid });

                    if (modelCustomSubscriptionJobList.length > 0) {
                        modelCustomSubscriptionJob = modelCustomSubscriptionJobList[0];
                    } 
                }

                if (modelConfigSubscriptionPayment.length > 0) {
                    switch (modelConfigSubscriptionPayment[0].attributes.GatewayType) {
                        case 0 : break;
                        case 1 : paymentGateway.EPay(self.model.attributes.AuthUserId, 'X100731', self.app.entities.models.custom.attributes.FirstName,self.model, modelCustomSubscriptionJob, callbackRender); break;
                        case 2 : paymentGateway.Events4us(self.model.attributes.AuthUserId, 'X100731', self.app.entities.models.custom.attributes.FirstName,self.model, modelCustomSubscriptionJob, callbackRender); break;
                    }
                }
            }
            callbackGetPaymentGateway();
        }
    },
    getPaymentStatus : function () {
        var ret = false;

        switch (this.model.attributes.DineroInvoicePaymentStatus) {
            case 'Draft' : 
            case 'Booked' : ret = false; break;
            case 'Paid' : 
            case 'OverPaid' : ret = true; break;
            case 'Overdue' : ret = false; break;
        }

        return ret;
    },
    addDays: function(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    getPaymentOverdue : function () {
        var strPaymentOverdue = '';
        var paymentOverdue = false;
        if ((this.model.collection.fullCollection.length > 1) && (this.getPaymentStatus() == false)) {
            if (this.model.attributes.id == this.model.collection.fullCollection.models[0].attributes.id) {
                
                //var now = new Date();
                
                //var utcDateNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(),0,1,0);
                //utcDateNow.setMinutes(0);
                //utcDateNow.setSeconds(1);

                var dateNow = moment(); 
                dateNow.minutes(0);
                dateNow.seconds(0);
                var utcDateNow = moment([dateNow.utc().year(), dateNow.utc().month(), dateNow.utc().date(), dateNow.utc().hour(), 0, 0]);

                //var utcDate = new Date(this.model.attributes.UtcDate);
                var utcDate = new moment(this.model.attributes.UtcDate);
                
                //var datePaymentOverdue = this.addDays(utcDate, this.model.attributes.PaymentOverdue);
                //var utcDatePaymentOverdue = new Date(datePaymentOverdue.getUTCFullYear(), datePaymentOverdue.getUTCMonth(), datePaymentOverdue.getUTCDate(), datePaymentOverdue.getUTCHours(),0,1,0);

                var utcDatePaymentOverdue = utcDate.add(this.model.attributes.PaymentOverdue, 'days');

                if (utcDateNow > utcDatePaymentOverdue) {
                    paymentOverdue = true;
                    strPaymentOverdue = resources.X100714;
                } else {
                    var dateLeft = utcDatePaymentOverdue - utcDateNow;
                    var utcDateLeft = moment(dateLeft);

                    var format = resources.X100717 + (utcDateLeft.date()-1) + ' - ' + resources.X100718 + (utcDateLeft.hour());

                    strPaymentOverdue = String.format(resources.X100716, format);
                }
            }
        }

        if (strPaymentOverdue.length > 0) {
            strPaymentOverdue += '</br>';
        }

        return strPaymentOverdue;
    },
    getPaymentOverdue1 : function () {
        var strPaymentOverdue = '';
        var paymentOverdue = false;
        if ((this.model.collection.fullCollection.length > 1) && (this.getPaymentStatus() == false)) {
            if (this.model.attributes.id == this.model.collection.fullCollection.models[0].attributes.id) {
                var now = new Date();
                var utcDateNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(),0,1,0);
                utcDateNow.setMinutes(0);
                utcDateNow.setSeconds(1);

                var utcDate = new Date(this.model.attributes.UtcDate);
                var datePaymentOverdue = this.addDays(utcDate, this.model.attributes.PaymentOverdue);
                var utcDatePaymentOverdue = new Date(datePaymentOverdue.getUTCFullYear(), datePaymentOverdue.getUTCMonth(), datePaymentOverdue.getUTCDate(), datePaymentOverdue.getUTCHours(),0,1,0);

                if (utcDatePaymentOverdue > utcDateNow) {
                    paymentOverdue = true;
                    strPaymentOverdue = resources.X100714;
                } else {
                    var dateLeft = utcDateNow - utcDatePaymentOverdue;
                    var utcDateLeft = new Date(dateLeft);
                    strPaymentOverdue = String.format(resources.X100715, dateLeft.getDate());
                }
            }
        }

        return strPaymentOverdue;
    },
    getPaymentSubscriptionText : function (total, id) {
        //UHM01

        var text = '';
        var modelConfigSubscriptionPayment = self.app.entities.collections.publicConfigSubscriptionPayment.where( {id : this.model.attributes.ConfigSubscriptionPaymentId});

        // if type = 0 (Banktransfer) find the default payment gateway
        if (modelConfigSubscriptionPayment.length > 0) {
            
            //if (modelConfigSubscriptionPayment[0].attributes.Type == 0) {
            text = String.format(modelConfigSubscriptionPayment[0].attributes.SubscriptionText, total, id);
            //} else {
            //    text = modelConfigSubscriptionPayment[0].attributes.SubscriptionText;
            //}
        }

        return text;
    },
    setupMessage: function () {
        var ordertext = '';
        var jobordertext = '';
        var subTotalText = resources.X100868;
        var subTotalNoVat = '';

        var tableStart = '<table><thead><tr><th style="width:50%"></th><th style="width:50%"></th></tr></thead><tbody>';
        var trStart = '<tr>';
        var pStartLeft = '<td>'; 
        var pStartRight = '<td>'; 
        var pEnd = '</td>';
        var trEnd = '</tr>';
        var tableEnd = '</tr></tbody></table>'

        var paymentOverdue = this.getPaymentOverdue();
        

        var paymentStatus = '';
        if (this.model.attributes.Price == 0) {
            paymentStatus = resources.X100840; 
        } else {
            switch (this.getPaymentStatus()) {
                case true : paymentStatus = resources.X100729; break;
                case false : paymentStatus = resources.X100730; break;
            }
        }

        var customSubscriptionJob = this.app.entities.collections.customSubscriptionJob.where( { SubscriptionGuid : this.model.attributes.SubscriptionGuid });

        var price = this.model.attributes.Price;
        var vat = this.model.attributes.Vat;
        var totalPrice = this.model.attributes.TotalPrice;

        var utcDate = new Date(this.model.attributes.UtcDate);
        var utcDisplayDate = utcDate.toLocaleDateString();
        utcDisplayDate = utcDisplayDate.substring(0, utcDisplayDate.length);

        var ekstra = '';

        if (this.model.attributes.DineroInvoiceNumber > 0) {
            if (this.getPaymentStatus() == true) {
                ekstra = resources.X100909 + ' ' + utcDisplayDate + '</br>' + resources.X100910 + ' ' + this.model.attributes.id + '</br>' + resources.X100973 + ' ' + this.model.attributes.DineroInvoiceNumber + '</br>' + '</br>';
            } else {
                ekstra = resources.X100909 + ' ' + utcDisplayDate + '</br>' + resources.X100910 + ' ' + this.model.attributes.id + '</br>' + '</br>';
            }
        } else {
            ekstra = resources.X100909 + ' ' + utcDisplayDate + '</br>' + resources.X100910 + ' ' + this.model.attributes.id + '</br>' + '</br>';
        }

        ordertext = ekstra + this.model.attributes.InvoiceDescriptionText + '</br>' + 
            this.model.attributes.InvoiceCommentsText + '</br>' +
            '</br>' + 
            tableStart + trStart + pStartLeft + resources.X100849 + pEnd + pStartRight + (this.model.attributes.EventQty * this.model.attributes.PaymentPeriodMonth)  + ' ' + resources.X100890 + pEnd + trEnd + 
            trStart + pStartLeft + resources.X100894 + pEnd + pStartRight + this.model.attributes.Currency + ' ' + this.model.attributes.EventPrice.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd +
            trStart + pStartLeft + resources.X100895 + pEnd + pStartRight + this.model.attributes.Currency + ' ' + this.model.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd + tableEnd;

        var customSubscriptionJob_Price = 0;
        var customSubscriptionJob_Vat = 0;
        var customSubscriptionJob_TotalPrice = 0;

        if (customSubscriptionJob.length > 0) {
            customSubscriptionJob_Price = customSubscriptionJob[0].attributes.Price;
            customSubscriptionJob_Vat = customSubscriptionJob[0].attributes.Vat;
            customSubscriptionJob_TotalPrice = customSubscriptionJob[0].attributes.TotalPrice;

            jobordertext = '</br>' + customSubscriptionJob[0].attributes.InvoiceDescriptionText + '</br>' + 
                customSubscriptionJob[0].attributes.InvoiceCommentsText + '</br>' +
            '</br>' +
            tableStart + trStart + pStartLeft + resources.X100849 + pEnd + pStartRight + '1'  + ' ' + resources.X100890 + pEnd + trEnd + 
            trStart + pStartLeft + resources.X100894 + pEnd + pStartRight + customSubscriptionJob[0].attributes.Currency + ' ' + customSubscriptionJob[0].attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd +
            trStart + pStartLeft + resources.X100895 + pEnd + pStartRight + customSubscriptionJob[0].attributes.Currency + ' ' + customSubscriptionJob[0].attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd + tableEnd;
        }

        ordertext += jobordertext;

        var totalText  = tableStart + trStart + pStartLeft + resources.X100896 + pEnd + pStartRight + this.model.attributes.Currency + ' ' + (this.model.attributes.Price + customSubscriptionJob_Price).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd + 
        trStart + pStartLeft + resources.X100897 + pEnd + pStartRight + this.model.attributes.Currency + ' ' + (this.model.attributes.Vat + customSubscriptionJob_Vat).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd +
        trStart + pStartLeft + resources.X100898 + pEnd + pStartRight + this.model.attributes.Currency + ' ' + (this.model.attributes.TotalPrice + customSubscriptionJob_TotalPrice).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd +
        trStart + pStartLeft + '</br>' + pEnd + pStartRight + ' ' + pEnd + trEnd +
        trStart + pStartLeft + resources.X100899 + pEnd + pStartRight + paymentStatus + pEnd + trEnd + tableEnd;
            
        ordertext += '</br>' + totalText + '</br>';

        //var totalPaymentStatus = tableStart + trStart + pStartLeft + resources.X100899 + ' '+ pEnd + pStartRight + paymentStatus + pEnd + trEnd + tableEnd;

        //ordertext += '</br>' + totalPaymentStatus;
        var paymentSubscriptionText = this.getPaymentSubscriptionText((this.model.attributes.TotalPrice + customSubscriptionJob_TotalPrice).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), this.model.attributes.id);

        this.model.attributes.TmpMessage = 
            ordertext +
            paymentOverdue + '</strong>' + '</br>' + paymentSubscriptionText;
    },
    customSubscriptionListItem_btnPay: function (event) {
        event.preventDefault();

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100237;
        dialog2ButtonModel.attributes.Text = resources.X100243;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100237;
            dialogWaitingModel.attributes.Text = resources.X100243;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            app.dialogRegion.show(dialogWaiting);

            var collection = this.app.entities.collections.customEventPush;

            this.model.destroy({
                wait: true,
                success: function (model, response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    collection.fullCollection.sort();
                    if (collection.state.currentPage < collection.state.lastPage) {
                        collection.getPage(collection.state.currentPage);
                    }
                    else {
                        collection.getLastPage();
                    }

                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customEventPush:eventChanged');
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnInvoiceClick : function (event) {
        var self = this;
        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100081;
        dialogWaitingModel.attributes.Text = resources.X100838;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            dialogWaiting.triggerMethod('dialog:close');
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);
        this.app.dialogRegion.show(dialogWaiting);

        var dineroInvoiceModel = new Entities_DineroInvoice.Model();

        dineroInvoiceModel.attributes.id = this.model.attributes.id;
        dineroInvoiceModel.attributes.AuthUserId = config.authUserId();

        dineroInvoiceModel.fetch({
            text04 : encodeURIComponent(resources.X100836),
            text05 : encodeURIComponent(resources.X100839),
            silent: true,
            wait:true,
            success: function (mode, response) {
                dialogWaiting.triggerMethod('dialog:okclicked');
            }
        });
    },
    Inactive: function () {
        var dialogBoxInactiveModel = new Entities_Empty.Model();
        dialogBoxInactiveModel.attributes.Text = resources.X100885;
        var dialogBoxInactive = new View_DialogBoxInactive({ model: dialogBoxInactiveModel, parent: self });
        this.app.dialogRegion.show(dialogBoxInactive);
    }
});



