﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import BootstrapDatepicker from 'bootstrap-datepicker';
import language_da from '../../../../bower_components/bootstrap-datepicker/js/locales/bootstrap-datepicker.da';

require('typeahead-addresspicker');

import tpl_customEventEditItem from './tpl/customEventEditItem.html';
import tpl_customEventEditItemDesign from './tpl/customEventEditItemDesign.html';

import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_Information from '../../../lib/views/information/information';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';
import View_Render from '../render/render';
import Help from '../../helper/help';
import EventUpdate from '../../helper/eventUpdate';

import View_CustomHeader from '../region/customHeader/customHeader';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogPreviewWebpageZoom from '../dialogPreview/dialogPreviewWebpageZoom';
import View_DialogPreviewWebpageZoomIPad from '../dialogPreview/dialogPreviewWebpageZoomIPad';
import View_CustomWebpageEditItem from '../../views/customWebpage/customWebpageEditItem';
import View_CustomEventPush from '../../views/customEventPush/customEventPush';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customEventEditItemDesign({ model: this.model.toJSON() });
        } else {
            return tpl_customEventEditItem({ model: this.model.toJSON() });
        }
    },
    regions: {
        information: "#customEventInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        //$(document).scrollTop(this.options.scroll);
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));


        var customHeaderModel = new Entities_Empty.Model();

        if (this.model) {
            customHeaderModel.attributes.Name = this.model.attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100070;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = false;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));

        this.updateHVcontrols();
    },
    updateHVcontrols: function() {
        switch (this.model.attributes.Module_SchemaScreen_Direction_H) {
            case true: this.ui.customEventEditItem_navigate_spanEventSchemaScreen_Direction_H.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventSchemaScreen_Direction_H.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_SchemaScreen_Direction_V) {
            case true: this.ui.customEventEditItem_navigate_spanEventSchemaScreen_Direction_V.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventSchemaScreen_Direction_V.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_InfoScreen_Direction_H) {
            case true: this.ui.customEventEditItem_navigate_spanEventInfoScreen_Direction_H.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventInfoScreen_Direction_H.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_InfoScreen_Direction_V) {
            case true: this.ui.customEventEditItem_navigate_spanEventInfoScreen_Direction_V.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventInfoScreen_Direction_V.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_Poster_Direction_H) {
            case true: this.ui.customEventEditItem_navigate_spanEventPoster_Direction_H.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventPoster_Direction_H.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_Poster_Direction_V) {
            case true: this.ui.customEventEditItem_navigate_spanEventPoster_Direction_V.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventPoster_Direction_V.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_CityInfo_Direction_H) {
            case true: this.ui.customEventEditItem_navigate_spanEventCityInfo_Direction_H.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventCityInfo_Direction_H.css('color', 'rgb(108, 114, 147)'); break;
        }

        switch (this.model.attributes.Module_CityInfo_Direction_V) {
            case true: this.ui.customEventEditItem_navigate_spanEventCityInfo_Direction_V.css('color', '#2f3cff'); break;
            case false: this.ui.customEventEditItem_navigate_spanEventCityInfo_Direction_V.css('color', 'rgb(108, 114, 147)'); break;
        }
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        if (this.app.entities.collections.customEventTemplateSubject.length > 0) {
            this.selectedSubject = this.app.entities.collections.customEventTemplateSubject.models[0].attributes.Subject;
        }
        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customSetting:refreshPublishBtn', function () {
            var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
            if (customSetting[0].attributes.Value == '1') {
                this.ui.customEventEditItem_btnPublish.show();
            }
            else {
                this.ui.customEventEditItem_btnPublish.css('display', 'none');
            }
        });

        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick(null);
        });

        if (this.options.module_selected == undefined || this.options.module_selected == 0) {
            this.options.module_selected = 1;
        }
    },
    help: function () {
        Help.help(this, true, 'customEventEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick(null, callback, false);

    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        this.setModel();

        //CHECK IE //checkout http://momentjs.com/

        var oldUtcStartDate = new Date(this.oldModel.attributes.UtcStartDate);
        oldUtcStartDate.setHours(0);
        oldUtcStartDate.setMinutes(0);
        oldUtcStartDate.setSeconds(1);
        var strOldUtcStartDate = oldUtcStartDate.getUTCFullYear().toString() + '-' +
            (oldUtcStartDate.getUTCMonth() + 1) + '-' +
            oldUtcStartDate.getUTCDate().toString() + ' ' +
            oldUtcStartDate.getUTCHours().toString() + ':' +
            oldUtcStartDate.getUTCMinutes().toString() + ':' +
            oldUtcStartDate.getUTCSeconds().toString();
        if (strOldUtcStartDate != this.model.attributes.UtcStartDate) {
            //changes = true;
        }

        var oldUtcEndDate = new Date(this.oldModel.attributes.UtcEndDate);
        oldUtcEndDate.setHours(23);
        oldUtcEndDate.setMinutes(59);
        oldUtcEndDate.setSeconds(59);
        var strOldUtcEndDate = oldUtcEndDate.getUTCFullYear().toString() + '-' +
            (oldUtcEndDate.getUTCMonth() + 1) + '-' +
            oldUtcEndDate.getUTCDate().toString() + ' ' +
            oldUtcEndDate.getUTCHours().toString() + ':' +
            oldUtcEndDate.getUTCMinutes().toString() + ':' +
            oldUtcEndDate.getUTCSeconds().toString();
        if (strOldUtcEndDate != this.model.attributes.UtcEndDate) {
            //changes = true;
        }

        //Test
        //if (this.model.attributes.Name != this.oldModel.attributes.Name) { alert("Name"); }
        //if (this.model.attributes.EventName != this.oldModel.attributes.EventName)  { alert("EventName"); }
        //if (this.model.attributes.Description != this.oldModel.attributes.Description)  { alert("Description"); }
        //if (this.model.attributes.FriendlyUrl != this.oldModel.attributes.FriendlyUrl)  { alert("FriendlyUrl"); }
        //if (this.model.attributes.SchemaMenuName != this.oldModel.attributes.SchemaMenuName)  { alert("SchemaMenuName"); }
        //if (this.model.attributes.FormMenuName != this.oldModel.attributes.FormMenuName)  { alert("FormMenuName"); }
        //if (this.model.attributes.FormSubmitBtnName != this.oldModel.attributes.FormSubmitBtnName)  { alert("FormSubmitBtnName"); }
        //if (this.model.attributes.FormCancelBtnName != this.oldModel.attributes.FormCancelBtnName)  { alert("FormCancelBtnName"); }
        //if (this.model.attributes.FormSubmitBtnSucces != this.oldModel.attributes.FormSubmitBtnSucces)  { alert("FormSubmitBtnSucces"); }
        //if (this.model.attributes.ProductSheetModel != this.oldModel.attributes.ProductSheetModel)  { alert("ProductSheetModel"); }
        //if (this.model.attributes.ProductSheetMenuName != this.oldModel.attributes.ProductSheetMenuName)  { alert("ProductSheetMenuName"); }
        //if (this.model.attributes.ProductSheetPreviusBtnName != this.oldModel.attributes.ProductSheetPreviusBtnName)  { alert("ProductSheetPreviusBtnName"); }
        //if (this.model.attributes.ProductSheetNextBtnName != this.oldModel.attributes.ProductSheetNextBtnName)  { alert("ProductSheetNextBtnName"); }
        //if (this.model.attributes.Webpage != this.oldModel.attributes.Webpage)  { alert("Webpage"); }
        //if (this.model.attributes.Infopage != this.oldModel.attributes.Infopage)  { alert("Infopage"); }
        //if (this.model.attributes.Publicly != this.oldModel.attributes.Publicly)  { alert("Publicly"); }
        //if (this.model.attributes.AdminDisable != this.oldModel.attributes.AdminDisable)  { alert("AdminDisable"); }
        //if (this.model.attributes.AdminDisableReason  != this.oldModel.attributes.AdminDisableReason)  { alert("AdminDisableReason"); }
        //if (this.model.attributes.AdminEnableDelete != this.oldModel.attributes.AdminEnableDelete)  { alert("AdminEnableDelete"); }
        //if (this.model.attributes.AdminInformation != this.oldModel.attributes.AdminInformation)  { alert("AdminInformation"); }
        //if (this.model.attributes.ShowInfo != this.oldModel.attributes.ShowInfo)  { alert("ShowInfo"); }
        //if (this.model.attributes.ShowPoster != this.oldModel.attributes.ShowPoster)  { alert("ShowPoster"); }
        //if (this.model.attributes.Information != this.oldModel.attributes.Information)  { alert("Information"); }
        //if (this.model.attributes.QRCode  != this.oldModel.attributes.QRCode)  { alert("QRCode"); }
        //if (this.model.attributes.SchemaModel != this.oldModel.attributes.SchemaModel)  { alert("SchemaModel"); }
        //if (this.model.attributes.Address != this.oldModel.attributes.Address)  { alert("Address"); }
        //if (this.model.attributes.UseDate != this.oldModel.attributes.UseDate)  { alert("UseDate"); }
        //if (this.model.attributes.Facebook != this.oldModel.attributes.Facebook)  { alert("Facebook"); }
        //if (this.model.attributes.Copyright != this.oldModel.attributes.Copyright)  { alert("Copyright"); }
        //if (this.model.attributes.InfopageShowQRCode != this.oldModel.attributes.InfopageShowQRCode)  { alert("InfopageShowQRCode"); }
        //if (this.model.attributes.SchemaBigScreenShowQRCode != this.oldModel.attributes.SchemaBigScreenShowQRCode)  { alert("SchemaBigScreenShowQRCode"); }
        //if (this.model.attributes.HelpBtn != this.oldModel.attributes.HelpBtn)  { alert("HelpBtn"); }
        //if (this.model.attributes.HomeBtn  != this.oldModel.attributes.HomeBtn)  { alert("HomeBtn"); }
        //if (this.model.attributes.SchemaModel != this.oldModel.attributes.SchemaModel) { alert("SchemaModel"); }

        if ((this.model.attributes.Name != this.oldModel.attributes.Name) ||
            (this.model.attributes.EventName != this.oldModel.attributes.EventName) ||
            (this.model.attributes.Description != this.oldModel.attributes.Description) ||
            //(this.model.attributes.UtcDate != this.oldModel.attributes.UtcDate) ||
            (this.model.attributes.FriendlyUrl != this.oldModel.attributes.FriendlyUrl) ||
            (this.model.attributes.SchemaMenuName != this.oldModel.attributes.SchemaMenuName) ||

            //(this.model.attributes.FormMenuModel != this.oldModel.attributes.FormMenuModel) ||
            (this.model.attributes.FormMenuName != this.oldModel.attributes.FormMenuName) ||
            (this.model.attributes.FormSubmitBtnName != this.oldModel.attributes.FormSubmitBtnName) ||
            (this.model.attributes.FormCancelBtnName != this.oldModel.attributes.FormCancelBtnName) ||
            (this.model.attributes.FormSubmitBtnSucces != this.oldModel.attributes.FormSubmitBtnSucces) ||

            (this.model.attributes.ProductSheetModel != this.oldModel.attributes.ProductSheetModel) ||
            (this.model.attributes.ProductSheetMenuName != this.oldModel.attributes.ProductSheetMenuName) ||
            (this.model.attributes.ProductSheetPreviusBtnName != this.oldModel.attributes.ProductSheetPreviusBtnName) ||
            (this.model.attributes.ProductSheetNextBtnName != this.oldModel.attributes.ProductSheetNextBtnName) ||

            (this.model.attributes.QRCodeTextPoster != this.oldModel.attributes.QRCodeTextPoster) ||
            (this.model.attributes.QRCodeTextInfoscreen != this.oldModel.attributes.QRCodeTextInfoscreen) ||
            (this.model.attributes.QRCodeTextBigSchemaScreen != this.oldModel.attributes.QRCodeTextBigSchemaScreen) ||
            (this.model.attributes.QRCodeTextQRCode != this.oldModel.attributes.QRCodeTextQRCode) ||
            (this.model.attributes.QRCodeFontSizePoster != this.oldModel.attributes.QRCodeFontSizePoster) ||
            (this.model.attributes.QRCodeFontSizeInfoscreen != this.oldModel.attributes.QRCodeFontSizeInfoscreen) ||
            (this.model.attributes.QRCodeFontSizeBigSchemaScreen != this.oldModel.attributes.QRCodeFontSizeBigSchemaScreen) ||
            (this.model.attributes.QRCodeFontSizeQRCode != this.oldModel.attributes.QRCodeFontSizeQRCode) ||
            //(this.model.attributes.AdEnable  != this.oldModel.attributes.AdEnable) ||
            //(this.model.attributes.AdStatus  != this.oldModel.attributes.AdStatus) ||
            //(this.model.attributes.AdImageUrl  != this.oldModel.attributes.AdImageUrl) ||
            //(this.model.attributes.AdImageLink  != this.oldModel.attributes.AdImageLink) ||

            (this.model.attributes.Webpage != this.oldModel.attributes.Webpage) ||
            (this.model.attributes.Infopage != this.oldModel.attributes.Infopage) ||
            (this.model.attributes.Publicly != this.oldModel.attributes.Publicly) ||
            (this.model.attributes.AdminDisable != this.oldModel.attributes.AdminDisable) ||
            (this.model.attributes.AdminDisableReason != this.oldModel.attributes.AdminDisableReason) ||
            (this.model.attributes.AdminEnableDelete != this.oldModel.attributes.AdminEnableDelete) ||
            (this.model.attributes.AdminInformation != this.oldModel.attributes.AdminInformation) ||
            (this.model.attributes.ShowInfo != this.oldModel.attributes.ShowInfo) ||
            (this.model.attributes.ShowPoster != this.oldModel.attributes.ShowPoster) ||
            (this.model.attributes.Information != this.oldModel.attributes.Information) ||
            (this.model.attributes.QRCode != this.oldModel.attributes.QRCode) ||
            (this.model.attributes.SchemaModel != this.oldModel.attributes.SchemaModel) ||
            (this.model.attributes.Address != this.oldModel.attributes.Address) ||
            (this.model.attributes.UseDate != this.oldModel.attributes.UseDate) ||
            (this.model.attributes.Facebook != this.oldModel.attributes.Facebook) ||
            (this.model.attributes.Copyright != this.oldModel.attributes.Copyright) ||
            (this.model.attributes.InfopageShowQRCode != this.oldModel.attributes.InfopageShowQRCode) ||
            (this.model.attributes.SchemaBigScreenShowQRCode != this.oldModel.attributes.SchemaBigScreenShowQRCode) ||
            //(this.model.attributes.UtcStartDate != this.oldModel.attributes.UtcStartDate) ||
            //(this.model.attributes.UtcEndDate != this.oldModel.attributes.UtcEndDate) ||
            (this.model.attributes.HelpBtn != this.oldModel.attributes.HelpBtn) ||
            (this.model.attributes.HomeBtn != this.oldModel.attributes.HomeBtn) ||
            (this.model.attributes.SchemaModel != this.oldModel.attributes.SchemaModel) ||
            (this.model.attributes.Module_QRCode != this.oldModel.attributes.Module_QRCode) ||
            (this.model.attributes.Module_Facebook != this.oldModel.attributes.Module_Facebook) ||
            (this.model.attributes.Module_SchemaModel != this.oldModel.attributes.Module_SchemaModel) ||
            (this.model.attributes.Module_FormModel != this.oldModel.attributes.Module_FormModel) ||
            (this.model.attributes.Module_ProductSheetModel != this.oldModel.attributes.Module_ProductSheetModel) ||
            (this.model.attributes.Module_Ad != this.oldModel.attributes.Module_Ad) ||
            (this.model.attributes.Module_SchemaScreen_Direction_H != this.oldModel.attributes.Module_SchemaScreen_Direction_H) ||
            (this.model.attributes.Module_SchemaScreen_Direction_V != this.oldModel.attributes.Module_SchemaScreen_Direction_V) ||
            (this.model.attributes.Module_InfoScreen_Direction_H != this.oldModel.attributes.Module_InfoScreen_Direction_H) ||
            (this.model.attributes.Module_InfoScreen_Direction_V != this.oldModel.attributes.Module_InfoScreen_Direction_V) ||
            (this.model.attributes.Module_Poster_Direction_H != this.oldModel.attributes.Module_Poster_Direction_H) ||
            (this.model.attributes.Module_Poster_Direction_V != this.oldModel.attributes.Module_Poster_Direction_V) ||
            (this.model.attributes.Module_CityInfo_Direction_H != this.oldModel.attributes.Module_CityInfo_Direction_H) ||
            (this.model.attributes.Module_CityInfo_Direction_V != this.oldModel.attributes.Module_CityInfo_Direction_V)) {
                changes = true;
        }

        this.setHasChanged(changes);

        //Refresh SchemaSchema again because if user select none then the server delete the SchemaData and SchemaSchema
        //Not anymore, the data is not delete when user changes schema
        //if (this.model.attributes.SchemaModel != this.oldModel.attributes.SchemaModel) {
        //    this.app.entities.collections.customSchemaSchema.fetch({
        //        success: function (mode, response) {
        //        },
        //        error: function (mode, response) {
        //        }
        //    })
        //}
    },
    ui: {
        'charsjs_week_total': '#charsjs_week_total',
        'charsjs_week_average': '#charsjs_week_average',
        'charsjs_month_total': '#charsjs_month_total',
        'charsjs_month_average': '#charsjs_month_average',
        'charsjs_allPeriod_total': '#charsjs_allPeriod_total',
        'charsjs_allPeriod_average': '#charsjs_allPeriod_average',

        'customEventEditItem_navigate_aEventIPad': '#customEventEditItem_navigate_aEventIPad',
        'customEventEditItem_navigate_aEvent': '#customEventEditItem_navigate_aEvent',
        'customEventEditItem_navigate_aEventBrowser': '#customEventEditItem_navigate_aEventBrowser',
        'customEventEditItem_navigate_spanEventDesign': '#customEventEditItem_navigate_spanEventDesign',
        'customEventEditItem_navigate_spanEventSchemaScreen': '#customEventEditItem_navigate_spanEventSchemaScreen',
        'customEventEditItem_navigate_spanEventSchemaScreen_Direction_V': '#customEventEditItem_navigate_spanEventSchemaScreen_Direction_V',
        'customEventEditItem_navigate_spanEventSchemaScreen_Direction_H': '#customEventEditItem_navigate_spanEventSchemaScreen_Direction_H',
        'customEventEditItem_navigate_spanEventSchemaScreenDesign': '#customEventEditItem_navigate_spanEventSchemaScreenDesign',
        'customEventEditItem_navigate_spanEventInfoScreen': '#customEventEditItem_navigate_spanEventInfoScreen',
        'customEventEditItem_navigate_spanEventInfoScreen_Direction_V': '#customEventEditItem_navigate_spanEventInfoScreen_Direction_V',
        'customEventEditItem_navigate_spanEventInfoScreen_Direction_H': '#customEventEditItem_navigate_spanEventInfoScreen_Direction_H',
        'customEventEditItem_navigate_spanEventInfoScreenDesign': '#customEventEditItem_navigate_spanEventInfoScreenDesign',
        'customEventEditItem_navigate_spanEventPoster': '#customEventEditItem_navigate_spanEventPoster',
        'customEventEditItem_navigate_spanEventPoster_Direction_V': '#customEventEditItem_navigate_spanEventPoster_Direction_V',
        'customEventEditItem_navigate_spanEventPoster_Direction_H': '#customEventEditItem_navigate_spanEventPoster_Direction_H',
        'customEventEditItem_navigate_spanEventPosterDesign': '#customEventEditItem_navigate_spanEventPosterDesign',
        'customEventEditItem_navigate_spanEventCityInfo': '#customEventEditItem_navigate_spanEventCityInfo',
        'customEventEditItem_navigate_spanEventCityInfo_Direction_V': '#customEventEditItem_navigate_spanEventCityInfo_Direction_V',
        'customEventEditItem_navigate_spanEventCityInfo_Direction_H': '#customEventEditItem_navigate_spanEventCityInfo_Direction_H',
        'customEventEditItem_navigate_spanEventCityInfoDesign': '#customEventEditItem_navigate_spanEventCityInfoDesign',
        'customEventEditItem_navigate_spanEventPushSend': '#customEventEditItem_navigate_spanEventPushSend',

        'customEventEditItem_btnSubmit': '#customEventEditItem_btnSubmit',
        'customEventEditItem_btnSubmit01': '#customEventEditItem_btnSubmit01',
        'customEventEditItem_btnPublish': '#customEventEditItem_btnPublish',
        'customEventEditItem_btnUpdateVersion': '#customEventEditItem_btnUpdateVersion',
        'customEventEditItem_btnReset': '#customEventEditItem_btnReset',
        'customEventEditItem_btnWebpageEvent': '#customEventEditItem_btnWebpageEvent',
        'customEventEditItem_btnWebpageInfo': '#customEventEditItem_btnWebpageInfo',
        'customEventEditItem_btnWebpagePoster': '#customEventEditItem_btnWebpagePoster',
        'customEventEditItem_btnPreview': '#customEventEditItem_btnPreview',
        'customEventEditItem_btnWebpageSchemaBigscreen': '#customEventEditItem_btnWebpageSchemaBigscreen',
        'customEventEditItem_selectWebpage': '#customEventEditItem_selectWebpage',
        'customEventEditItem_selectInfopage': '#customEventEditItem_selectInfopage',
        'customEventEditItem_selectQRCode': '#customEventEditItem_selectQRCode',
        'customEventEditItem_selectSchemaModel': '#customEventEditItem_selectSchemaModel',
        'customEventEditItem_selectFormModel': '#customEventEditItem_selectFormModel',
        'customEventEditItem_selectProductSheetModel': '#customEventEditItem_selectProductSheetModel',
        'customEventEditItem_selectTemplateSubject': '#customEventEditItem_selectTemplateSubject',
        'customEventEditItem_selectTemplateName': '#customEventEditItem_selectTemplateName',
        'customEventEditItem_btnTemplateCopy': '#customEventEditItem_btnTemplateCopy',
        'customEventEditItem_selectPublicly': '#customEventEditItem_selectPublicly',
        'customEventEditItem_selectUseDate': '#customEventEditItem_selectUseDate',
        'customEventEditItem_selectFacebook': '#customEventEditItem_selectFacebook',
        'customEventEditItem_selectCopyright': '#customEventEditItem_selectCopyright',
        'customEventEditItem_divCopyright': '#customEventEditItem_divCopyright',
        'customEventEditItem_selectInfopageShowQRCode': '#customEventEditItem_selectInfopageShowQRCode',
        'customEventEditItem_selectSchemaBigScreenShowQRCode': '#customEventEditItem_selectSchemaBigScreenShowQRCode',
        'customEventEditItem_divInfopageShowQRCode': '#customEventEditItem_divInfopageShowQRCode',
        'customEventEditItem_divSchemaBigScreenShowQRCode': '#customEventEditItem_divSchemaBigScreenShowQRCode',
        'customEventEditItem_inputPubliclyAddress': '#customEventEditItem_inputPubliclyAddress',
        'customEventEditItem_inputInformation': '#customEventEditItem_inputInformation',
        'customEventEditItem_datepicker': '#customEventEditItem_datepicker',
        'customEventEditItem_spanDatePicker': '#customEventEditItem_spanDatePicker',
        'customEventEditItem_divDatepicker': '#customEventEditItem_divDatepicker',
        'customEventEditItem_divTemplate': '#customEventEditItem_divTemplate',
        'customEventEditItem_webpageEvent': '#customEventEditItem_webpageEvent',
        'customEventEditItem_webpageInfo': '#customEventEditItem_webpageInfo',
        'customEventEditItem_webpagePoster': '#customEventEditItem_webpagePoster',

        'customEventEditItem_inputName': '#customEventEditItem_inputName',
        'customEventEditItem_textarea_description': '#customEventEditItem_textarea_description',
        'customEventEditItem_inputEventName': '#customEventEditItem_inputEventName',
        'customEventEditItem_inputFriendlyUrl': '#customEventEditItem_inputFriendlyUrl',
        'customEventEditItem_inputSchemaMenuName': '#customEventEditItem_inputSchemaMenuName',
        'customEventEditItem_inputFormMenuName': '#customEventEditItem_inputFormMenuName',
        'customEventEditItem_inputFormSubmitBtnName': '#customEventEditItem_inputFormSubmitBtnName',
        'customEventEditItem_inputFormCancelBtnName': '#customEventEditItem_inputFormCancelBtnName',
        'customEventEditItem_inputFormSubmitBtnSucces': '#customEventEditItem_inputFormSubmitBtnSucces',
        'customEventEditItem_selectFormNotifyEmail': '#customEventEditItem_selectFormNotifyEmail',
        'customEventEditItem_inputFormNotifyEmailAddress': '#customEventEditItem_inputFormNotifyEmailAddress',
        'customEventEditItem_inputProductSheetMenuName': '#customEventEditItem_inputProductSheetMenuName',
        'customEventEditItem_inputProductSheetPreviusBtnName': '#customEventEditItem_inputProductSheetPreviusBtnName',
        'customEventEditItem_inputProductSheetNextBtnName': '#customEventEditItem_inputProductSheetNextBtnName',
        'customEventEditItem_webpageImageUrl': 'customEventEditItem_webpageImageUrl',
        'customEventEditItem_QRCodeImageUrl': 'customEventEditItem_QRCodeImageUrl',
        'customEventEditItem_div_Admin': '#customEventEditItem_div_Admin',
        'customEventEditItem_input_HelpBtn': '#customEventEditItem_input_HelpBtn',
        'customEventEditItem_input_HomeBtn': '#customEventEditItem_input_HomeBtn',
        'customEventEditItem_inputQRCodeTextPoster': '#customEventEditItem_inputQRCodeTextPoster',
        'customEventEditItem_selectQRCodeFontSizePoster': '#customEventEditItem_selectQRCodeFontSizePoster',
        'customEventEditItem_inputQRCodeTextInfoscreen': '#customEventEditItem_inputQRCodeTextInfoscreen',
        'customEventEditItem_selectQRCodeFontSizeInfoscreen': '#customEventEditItem_selectQRCodeFontSizeInfoscreen',
        'customEventEditItem_inputQRCodeTextBigSchemaScreen': '#customEventEditItem_inputQRCodeTextBigSchemaScreen',
        'customEventEditItem_selectQRCodeFontSizeBigSchemaScreen': '#customEventEditItem_selectQRCodeFontSizeBigSchemaScreen',
        'customEventEditItem_div_Ad': '#customEventEditItem_div_Ad',
        'customEventEditItem_selectAdEnable': '#customEventEditItem_selectAdEnable',
        'customEventEditItem_selectAdImageId': '#customEventEditItem_selectAdImageId',
        'customEventEditItem_inputAdImageUrl': '#customEventEditItem_inputAdImageUrl',
        'customEventEditItem_labelAdStatus': '#customEventEditItem_labelAdStatus',
        'customEventEditItem_imageAdImageId': '#customEventEditItem_imageAdImageId',
        'customEventEditItem_btnCopyLink': '#customEventEditItem_btnCopyLink',
        'customEventEditItem_btnShowLink': '#customEventEditItem_btnShowLink',
        'customEventEditItem_iShowLink': '#customEventEditItem_iShowLink',
        'customEventEditItem_labelShowLink': '#customEventEditItem_labelShowLink',
        'customEventEditItem_inputShowLink': '#customEventEditItem_inputShowLink',
        'custom_alertWarningWrongAddress': '#custom_alertWarningWrongAddress',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',

        'customEventEditItem_top': '#customEventEditItem_top',
        'customEventEditItem_checkbox_Module_Facebook': '#customEventEditItem_checkbox_Module_Facebook',
        'customEventEditItem_checkbox_Module_SchemaModel': '#customEventEditItem_checkbox_Module_SchemaModel',
        'customEventEditItem_checkbox_Module_FormModel': '#customEventEditItem_checkbox_Module_FormModel',
        'customEventEditItem_checkbox_Module_Ad': '#customEventEditItem_checkbox_Module_Ad',


        'customEventEditItem_moduletab01': '#customEventEditItem_moduletab01',
        'customEventEditItem_moduletab02': '#customEventEditItem_moduletab02',
        'customEventEditItem_moduletab03': '#customEventEditItem_moduletab03',
        'customEventEditItem_moduletab04': '#customEventEditItem_moduletab04',
        'customEventEditItem_moduletab05': '#customEventEditItem_moduletab05',
        'customEventEditItem_nav_moduletab01': '#customEventEditItem_nav_moduletab01',
        'customEventEditItem_nav_moduletab02': '#customEventEditItem_nav_moduletab02',
        'customEventEditItem_nav_moduletab03': '#customEventEditItem_nav_moduletab03',
        'customEventEditItem_nav_moduletab04': '#customEventEditItem_nav_moduletab04',
        'customEventEditItem_nav_moduletab05': '#customEventEditItem_nav_moduletab05',

        'customEventEditItem_QrCode_design_p': '#customEventEditItem_QrCode_design_p',
        'customEventEditItem_SchemaModel_design_p': '#customEventEditItem_SchemaModel_design_p',
        'customEventEditItem_SchemaModel_data_p': '#customEventEditItem_SchemaModel_data_p',
        'customEventEditItem_SchemaModel_setting_p': '#customEventEditItem_SchemaModel_setting_p',
        'customEventEditItem_SchemaModel_livedata_p': '#customEventEditItem_SchemaModel_livedata_p',
        'customEventEditItem_FormModel_design_p': '#customEventEditItem_FormModel_design_p',
        'customEventEditItem_FormModel_data_p': '#customEventEditItem_FormModel_data_p',
        'customEventEditItem_FormModel_setting_p': '#customEventEditItem_FormModel_setting_p',
        'customEventEditItem_Ad_setting_p': '#customEventEditItem_Ad_setting_p'
    },
    events: {
        'click @ui.customEventEditItem_nav_moduletab01': 'customEventEditItem_nav_moduletab01_click',
        'click @ui.customEventEditItem_nav_moduletab02': 'customEventEditItem_nav_moduletab02_click',
        'click @ui.customEventEditItem_nav_moduletab03': 'customEventEditItem_nav_moduletab03_click',
        'click @ui.customEventEditItem_nav_moduletab04': 'customEventEditItem_nav_moduletab04_click',
        'click @ui.customEventEditItem_nav_moduletab05': 'customEventEditItem_nav_moduletab05_click',

        'click @ui.customEventEditItem_navigate_aEventIPad': 'customEventEditItem_navigate_aEventIPad_click',
        'click @ui.customEventEditItem_navigate_aEvent': 'customEventEditItem_navigate_aEvent_click',
        'click @ui.customEventEditItem_navigate_aEventBrowser': 'customEventEditItem_navigate_aEventBrowser_click',
        'click @ui.customEventEditItem_navigate_spanEventDesign': 'customEventEditItem_navigate_spanEventDesign_click',
        'click @ui.customEventEditItem_navigate_spanEventSchemaScreen': 'customEventEditItem_navigate_spanEventSchemaScreen_click',
        'click @ui.customEventEditItem_navigate_spanEventSchemaScreen_Direction_V': 'customEventEditItem_navigate_spanEventSchemaScreen_Direction_V_click',
        'click @ui.customEventEditItem_navigate_spanEventSchemaScreen_Direction_H': 'customEventEditItem_navigate_spanEventSchemaScreen_Direction_H_click',
        'click @ui.customEventEditItem_navigate_spanEventSchemaScreenDesign': 'customEventEditItem_navigate_spanEventSchemaScreenDesign_click',
        'click @ui.customEventEditItem_navigate_spanEventInfoScreen': 'customEventEditItem_navigate_spanEventInfoScreen_click',
        'click @ui.customEventEditItem_navigate_spanEventInfoScreen_Direction_V': 'customEventEditItem_navigate_spanEventInfoScreen_Direction_V_click',
        'click @ui.customEventEditItem_navigate_spanEventInfoScreen_Direction_H': 'customEventEditItem_navigate_spanEventInfoScreen_Direction_H_click',
        'click @ui.customEventEditItem_navigate_spanEventInfoScreenDesign': 'customEventEditItem_navigate_spanEventInfoScreenDesign_click',
        'click @ui.customEventEditItem_navigate_spanEventPoster': 'customEventEditItem_navigate_spanEventPoster_click',
        'click @ui.customEventEditItem_navigate_spanEventPoster_Direction_V': 'customEventEditItem_navigate_spanEventPoster_Direction_V_click',
        'click @ui.customEventEditItem_navigate_spanEventPoster_Direction_H': 'customEventEditItem_navigate_spanEventPoster_Direction_H_click',
        'click @ui.customEventEditItem_navigate_spanEventPosterDesign': 'customEventEditItem_navigate_spanEventPosterDesign_click',
        'click @ui.customEventEditItem_navigate_spanEventCityInfo': 'customEventEditItem_navigate_spanEventCityInfo_click',
        'click @ui.customEventEditItem_navigate_spanEventCityInfo_Direction_V': 'customEventEditItem_navigate_spanEventCityInfo_Direction_V_click',
        'click @ui.customEventEditItem_navigate_spanEventCityInfo_Direction_H': 'customEventEditItem_navigate_spanEventCityInfo_Direction_H_click',
        'click @ui.customEventEditItem_navigate_spanEventCityInfoDesign': 'customEventEditItem_navigate_spanEventCityInfoDesign_click',
        'click @ui.customEventEditItem_navigate_spanEventPushSend': 'customEventEditItem_navigate_spanEventPushSend_click',

        'click @ui.customEventEditItem_QrCode_design_p': 'QrCode_design_click',
        'click @ui.customEventEditItem_SchemaModel_design_p': 'SchemaModel_design_click',
        'click @ui.customEventEditItem_SchemaModel_data_p': 'SchemaModel_data_click',
        'click @ui.customEventEditItem_SchemaModel_setting_p': 'SchemaModel_setting_click',
        'click @ui.customEventEditItem_SchemaModel_livedata_p': 'SchemaModel_livedata_click',
        'click @ui.customEventEditItem_FormModel_design_p': 'FormModel_design_click',
        'click @ui.customEventEditItem_FormModel_data_p': 'FormModel_data_click',
        'click @ui.customEventEditItem_FormModel_setting_p': 'FormModel_setting_click',
        'click @ui.customEventEditItem_Ad_setting_p': 'Ad_setting_click',

        'click @ui.customEventEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customEventEditItem_btnSubmit01': 'btnSubmitClick',
        'click @ui.customEventEditItem_btnPublish': 'btnPublishClick',
        'click @ui.customEventEditItem_btnUpdateVersion': 'btnUpdateVersionClick',
        'click @ui.customEventEditItem_btnReset': 'btnResetClick',
        'click @ui.customEventEditItem_btnWebpageEvent': 'btnWebpageEventClick',
        'click @ui.customEventEditItem_btnPreview': 'btnWebpagePreviewClick',
        'click @ui.customEventEditItem_btnWebpageInfo': 'btnWebpageInfoClick',
        'click @ui.customEventEditItem_btnWebpagePoster': 'btnWebpagePosterClick',
        'click @ui.customEventEditItem_btnWebpageSchemaBigscreen': 'btnWebpageSchemaBigscreenClick',
        'change @ui.customEventEditItem_selectWebpage': 'selectWebpageChange',
        'change @ui.customEventEditItem_selectInfopage': 'selectInfopageChange',
        'change @ui.customEventEditItem_selectQRCode': 'selectQRCodeChange',
        'change @ui.customEventEditItem_selectSchemaModel': 'selectSchemaModelChange',
        'change @ui.customEventEditItem_selectFormModel': 'selectFormModelChange',
        'change @ui.customEventEditItem_selectProductSheetModel': 'selectProductSheetModelChange',
        'change @ui.customEventEditItem_selectTemplateSubject': 'selectTemplateSubjectChange',
        'change @ui.customEventEditItem_selectPublicly': 'selectPubliclyChange',
        'click @ui.customEventEditItem_btnTemplateCopy': 'btnTemplateClick',
        'change @ui.CustomEventEditItem_selectUseDate': 'selectUseDateChange',
        'change @ui.customEventEditItem_selectAdImageId': 'selectAdImageIdChange',
        'click @ui.customEventEditItem_btnCopyLink': 'btnCopyLink',
        'click @ui.customEventEditItem_btnShowLink': 'btnShowLink',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk'
    },
    customEventEditItem_nav_moduletab01_click: function () {
        this.options.module_selected = 1;
    },
    customEventEditItem_nav_moduletab02_click: function () {
        this.options.module_selected = 2;
    },
    customEventEditItem_nav_moduletab03_click: function () {
        this.options.module_selected = 3;
    },
    customEventEditItem_nav_moduletab04_click: function () {
        this.options.module_selected = 4;
    },
    customEventEditItem_nav_moduletab05_click: function () {
        this.options.module_selected = 5;
    },
    onRender: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
            this.ui.customEventEditItem_divCopyright.css('display', 'none');
            this.ui.customEventEditItem_divInfopageShowQRCode.css('display', 'none');
            this.ui.customEventEditItem_divSchemaBigScreenShowQRCode.css('display', 'none');
        }

        this.setupAttributesOnEventDoesNotExits();

        this.model.attributes.URL = config.webApiUrl() + '/Events/' + this.model.id + '_imagePrint.html?bust=' + (new Date()).getTime();

        this.setupWebpage();
        this.setupInfopage();
        this.setupQRCode();
        this.setupQRCode2();
        this.setupSchemaModel();
        this.setupFormModel();
        this.setupProductSheetModel();
        this.setupFriendlyUrl();
        this.setupInfopageShowQRCode();
        this.setupSchemaBigScreenShowQRCode();
        this.setupUrl();
        this.setupTemplate();
        this.setupAd();
        this.setupUrlLink();



        this.btnSubmitShow();

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
        if (customSetting[0].attributes.Value == '1') {
            this.ui.customEventEditItem_btnPublish.show();
        }

        this.setupDate();

        this.setupAdmin();

        this.setupButton();

        var self = this;



        this.ui.customEventEditItem_selectFormNotifyEmail.val(this.model.attributes.FormNotifyEmail);
        this.ui.customEventEditItem_selectUseDate.val(this.model.attributes.UseDate);
        this.ui.customEventEditItem_selectFacebook.val(this.model.attributes.Facebook);
        this.ui.customEventEditItem_selectCopyright.val(this.model.attributes.Copyright);
        this.ui.customEventEditItem_selectInfopageShowQRCode.val(this.model.attributes.InfopageShowQRCode);
        this.ui.customEventEditItem_selectSchemaBigScreenShowQRCode.val(this.model.attributes.SchemaBigScreenShowQRCode);
        //this.ui.customEventEditItem_selectPublicly.val(this.model.attributes.Publicly);
        this.ui.customEventEditItem_selectPublicly.attr('checked', this.model.attributes.Publicly == 0 ? false : true);


        this.ui.customEventEditItem_inputPubliclyAddress.val(this.model.attributes.Address);

        this.renderInformation();


        this.ui.customEventEditItem_checkbox_Module_Facebook.attr('checked', this.model.attributes.Module_Facebook);
        this.ui.customEventEditItem_checkbox_Module_SchemaModel.attr('checked', this.model.attributes.Module_SchemaModel);
        this.ui.customEventEditItem_checkbox_Module_FormModel.attr('checked', this.model.attributes.Module_FormModel);
        this.ui.customEventEditItem_checkbox_Module_Ad.attr('checked', this.model.attributes.Module_Ad);


        if (this.options.module_selected != undefined) {

            this.ui.customEventEditItem_moduletab01.removeClass('active show');
            this.ui.customEventEditItem_moduletab02.removeClass('active show');
            this.ui.customEventEditItem_moduletab03.removeClass('active show');
            this.ui.customEventEditItem_moduletab04.removeClass('active show');
            this.ui.customEventEditItem_moduletab05.removeClass('active show');
            this.ui.customEventEditItem_nav_moduletab01.attr('aria-selected', 'false');
            this.ui.customEventEditItem_nav_moduletab02.attr('aria-selected', 'false');
            this.ui.customEventEditItem_nav_moduletab03.attr('aria-selected', 'false');
            this.ui.customEventEditItem_nav_moduletab04.attr('aria-selected', 'false');
            this.ui.customEventEditItem_nav_moduletab05.attr('aria-selected', 'false');
            this.ui.customEventEditItem_nav_moduletab01.removeClass('active');
            this.ui.customEventEditItem_nav_moduletab02.removeClass('active');
            this.ui.customEventEditItem_nav_moduletab03.removeClass('active');
            this.ui.customEventEditItem_nav_moduletab04.removeClass('active');
            this.ui.customEventEditItem_nav_moduletab05.removeClass('active');

            switch (this.options.module_selected) {
                case 1: this.ui.customEventEditItem_moduletab01.addClass('active show');
                    this.ui.customEventEditItem_nav_moduletab01.attr('aria-selected', 'true');
                    this.ui.customEventEditItem_nav_moduletab01.addClass('active');
                    break;
                case 2: this.ui.customEventEditItem_moduletab02.addClass('active show');
                    this.ui.customEventEditItem_nav_moduletab02.attr('aria-selected', 'true');
                    this.ui.customEventEditItem_nav_moduletab02.addClass('active');
                    break;
                case 3: this.ui.customEventEditItem_moduletab03.addClass('active show');
                    this.ui.customEventEditItem_nav_moduletab03.attr('aria-selected', 'true');
                    this.ui.customEventEditItem_nav_moduletab03.addClass('active');
                    break;
                case 4: this.ui.customEventEditItem_moduletab04.addClass('active show');
                    this.ui.customEventEditItem_nav_moduletab04.attr('aria-selected', 'true');
                    this.ui.customEventEditItem_nav_moduletab04.addClass('active');
                    break;
                case 5: this.ui.customEventEditItem_moduletab05.addClass('active show');
                    this.ui.customEventEditItem_nav_moduletab05.attr('aria-selected', 'true');
                    this.ui.customEventEditItem_nav_moduletab05.addClass('active');
                    break;
            }
        }

        if (this.app.mode == 1) {
            this.ui.customEventEditItem_btnSubmit.css('display', 'none');
            this.ui.customEventEditItem_btnSubmit01.css('display', 'none');
        }
    },
    reRender: function () {
        var model = this.app.entities.collections.customEvent.fullCollection.where({ id: this.model.attributes.id });
        this.model = model[0];

        this.$el.find('#customEventEditItem_webpageImageUrl').attr('src', this.model.attributes.WebpageImageUrl);
        this.$el.find('#customEventEditItem_QRCodeImageUrl').attr('src', this.model.attributes.QRCodeImageUrl);

        //this.onRender();

        this.setupWebpage();
        this.setupInfopage();
        this.setupSchemaModel();
        this.setupFormModel();
        this.setupProductSheetModel();
        this.setupFriendlyUrl();
        this.setupInfopageShowQRCode();

        this.updateInformationCollection();

        this.setupButton();
        this.setupAd();

        if (this.app.mode == 1) {
            this.ui.customEventEditItem_btnSubmit.css('display', 'none');
            this.ui.customEventEditItem_btnSubmit01.css('display', 'none');
        }
    },
    setupDate: function () {
        var self = this;
        var localStartDate = new Date(this.model.attributes.UtcStartDate);
        var strStartDate = localStartDate.getDate().toString() + '/' + (localStartDate.getMonth() + 1) + '/' + localStartDate.getFullYear().toString();

        var localEndDate = new Date(this.model.attributes.UtcEndDate);
        var strEndDate = localEndDate.getDate().toString() + '/' + (localEndDate.getMonth() + 1) + '/' + localEndDate.getFullYear().toString();

        var lng = language.getLanguage_bootstrap_datePicker(config.cultureName());

        this.ui.customEventEditItem_datepicker.datepicker({
            todayBtn: true,
            autoclose: true,
            todayHighlight: true,
            language: lng
        }).on('changeDate', function (e) {
            self.utcStartDate = $(self.el).find('[name=start]').datepicker('getUTCDate');
            self.utcEndDate = $(self.el).find('[name=end]').datepicker('getUTCDate');
        });
        $(this.el).find('[name=start]').datepicker('setDate', strStartDate);
        $(this.el).find('[name=end]').datepicker('setDate', strEndDate);
    },
    setupAdmin: function () {
        if (this.app.entities.models.custom.attributes.Admin) {
            this.ui.customEventEditItem_div_Admin.show();
            this.ui.customEventEditItem_input_HelpBtn.attr('checked', this.model.attributes.HelpBtn);
            this.ui.customEventEditItem_input_HomeBtn.attr('checked', this.model.attributes.HomeBtn);
        } else {
            this.ui.customEventEditItem_div_Admin.css('display', 'none');
            this.ui.customEventEditItem_input_HelpBtn.attr('checked', this.model.attributes.HelpBtn);
            this.ui.customEventEditItem_input_HomeBtn.attr('checked', this.model.attributes.HomeBtn);
        }
    },
    setupButton: function () {
        if (this.model.attributes.Infopage != -1) {
            this.ui.customEventEditItem_btnWebpageInfo.show();
            //this.ui.customEventEditItem_btnWebpageInfo.prop('disabled', false);
        } else {
            this.ui.customEventEditItem_btnWebpageInfo.css('display', 'none');
            //this.ui.customEventEditItem_btnWebpageInfo.prop('disabled', true);
        }

        if (this.model.attributes.SchemaModel != -1) {
            this.ui.customEventEditItem_btnWebpageSchemaBigscreen.show();
            //this.ui.customEventEditItem_btnWebpageSchemaBigscreen.prop('disabled', false);
        } else {
            this.ui.customEventEditItem_btnWebpageSchemaBigscreen.css('display', 'none');
            //this.ui.customEventEditItem_btnWebpageSchemaBigscreen.prop('disabled', true);
        }

        if (this.model.attributes.Version != this.app.entities.models.customEventVersion.attributes.Value) {
            this.ui.customEventEditItem_btnUpdateVersion.show();
        } else {
            this.ui.customEventEditItem_btnUpdateVersion.css('display', 'none');
        }
    },
    setupAttributesOnEventDoesNotExits: function () {
        var self = this;
        //setupDefaultValues
        var QRCodeSubcriptionChange = false;
        var SchemaModelSubcriptionChange = false;

        /*if (this.app.entities.models.configSubscription[0].attributes.EnableQRCode == false) {
            var qrCode = this.app.entities.collections.customQRCode.fullCollection.where({ Name: resources.X100123 });
            if (qrCode.length > 0) {
                this.model.attributes.QRCode = qrCode[0].attributes.id;
                QRCodeSubcriptionChange = true;
            }
        }
        if (this.app.entities.models.configSubscription[0].attributes.EnableSchemaModel == false) {
            this.model.attributes.SchemaModel = -1;
            SchemaModelSubcriptionChange = true;
        }*/

        var WebpageNotExits = false;
        var InfopageNotExits = false;
        var QRCodeNotExits = false;
        var SchemaModelNotExits = false;
        var FormModelNotExits = false;
        var ProductSheetModelNotExits = false;
        var EnableAdvertising = false;

        if (this.model.attributes.Webpage != -1) {
            if ((this.app.entities.collections.customWebpage.fullCollection.where({ id: this.model.attributes.Webpage }).length <= 0)) {
                WebpageNotExits = true;
                this.model.attributes.Webpage = -1;
            }
        }

        if (this.model.attributes.Infopage != -1) {
            if ((this.app.entities.collections.customInfopage.fullCollection.where({ id: this.model.attributes.Infopage }).length <= 0)) {
                InfopageNotExits = true;
                this.model.attributes.Infopage = -1;
            }
        }

        if (this.model.attributes.QRCode != -1) {
            if (!QRCodeSubcriptionChange) {
                if ((this.app.entities.collections.customQRCode.fullCollection.where({ id: this.model.attributes.QRCode }).length <= 0)) {
                    var customQRCode = self.app.entities.collections.customQRCode.fullCollection.where({ Name: resources.X100123 });
                    QRCodeNotExits = true;
                    this.model.attributes.QRCode = customQRCode[0].attributes.id;
                }
            }
        }

        if (this.model.attributes.SchemaModel != -1) {
            if (!SchemaModelSubcriptionChange) {
                if ((this.app.entities.collections.customSchemaModel.fullCollection.where({ id: this.model.attributes.SchemaModel })).length <= 0) {
                    SchemaModelNotExits = true;
                    this.model.attributes.SchemaModel = -1;
                }
            }
        }

        if (this.model.attributes.FormModel != -1) {
            if ((this.app.entities.collections.customFormModel.fullCollection.where({ id: this.model.attributes.FormModel })).length <= 0) {
                FormModelNotExits = true;
                this.model.attributes.FormModel = -1;
            }
        }

        if (this.model.attributes.ProductSheetModel != -1) {
            if ((this.app.entities.collections.customProductSheetModel.fullCollection.where({ id: this.model.attributes.ProductSheetModel })).length <= 0) {
                ProductSheetModelNotExits = true;
                this.model.attributes.ProductSheetModel = -1;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
            if (this.model.attributes.Copyright != 1) {
                this.model.attributes.Copyright = 1;
                EnableAdvertising = true;
            }
            if (this.model.attributes.InfopageShowQRCode != 1) {
                this.model.attributes.InfopageShowQRCode = 1;
                EnableAdvertising = true;
            }
            if (this.model.attributes.SchemaBigScreenShowQRCode != 1) {
                this.model.attributes.SchemaBigScreenShowQRCode = 1;
                EnableAdvertising = true;
            }
        }

        var message = '';
        if (WebpageNotExits) message += resources.X100911 + '<br>';
        if (InfopageNotExits) message += resources.X100912 + '<br>';
        if (QRCodeNotExits) message += resources.X100913 + '<br>';
        if (SchemaModelNotExits) message += resources.X100914 + '<br>';
        if (FormModelNotExits) message += resources.X100915 + '<br>';
        if (ProductSheetModelNotExits) message += resources.X100916 + '<br>';
        if (EnableAdvertising) message += resources.X100925 + '<br>';

        if (message.length > 0) {
            this.model.save(this.model.attributes, {
                //silent: true,
                wait: true,
                updateVersion: false,
                success: function () {
                    self.oldModel = self.model.clone();

                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100229;
                    dialogBoxModel.attributes.Text = message;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                    var callback = function (options) {
                        self.stopListening(dialogBox, "dialog:okclicked", callback);
                    }
                    self.listenTo(dialogBox, "dialog:okclicked", callback);
                    self.app.dialogRegion.show(dialogBox);
                }
            });


        }
    },
    customViewEvent_setActive: function () {
        var array = [];
        var tmp = { "id": this.model.id };
        array.push(tmp);
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    getPreviousMonday: function () {
        var date = new Date();
        var day = date.getDay();
        var prevMonday;
        if (date.getDay() == 0) {
            prevMonday = new Date().setDate(date.getDate() - 7);
        }
        else {
            prevMonday = new Date().setDate(date.getDate() - day);
        }

        return prevMonday;
    },
    getDateString: function (back) {
        var today = new Date();
        today.setDate(today.getDate() - back);

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var dateString = yyyy + '-' + mm + '-' + dd;

        return dateString;
    },
    getDateString_month: function (back) {
        var today = new Date();
        var toDate = new Date(today.getFullYear(), today.getMonth(), 1);
        toDate.setMonth(toDate.getMonth() - back);

        var mm = String(toDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = toDate.getFullYear();
        var dateString = yyyy + '-' + mm;

        return dateString;
    },
    getWeek: function () {
        var week = [];
        var label = [];
        var total = [];
        var totalVisit = 0;

        var date = [];

        date[0] = this.getDateString(0);
        date[1] = this.getDateString(1);
        date[2] = this.getDateString(2);
        date[3] = this.getDateString(3);
        date[4] = this.getDateString(4);
        date[5] = this.getDateString(5);
        date[6] = this.getDateString(6);

        for (var i = 6; i >= 0; i--) {
            var found = app.currYear.find(x => x.date === date[i]);


            if (found != undefined) {
                week.push(found.count);
                totalVisit += found.count;
                total.push(totalVisit);
                //var labelStr = found.date + " - " + found.count;
                //label.push(labelStr.substr(5, labelStr.length - 5));
                label.push(date[i].substr(5, date[i].length - 5));
            } else {
                week.push(0);
                total.push(totalVisit);
                //label.push(0);
                //label.push(date[i] + " - 0");
                label.push(date[i].substr(5, date[i].length - 5));
            }
        }

        return { week: week, label: label, total: total.reverse() };
    },
    getLast8Week: function () {
        var week = [];
        var label = [];
        var total = [];
        var today = new Date();
        var days = 0;
        var date = today.getDate();
        var countWeek = 0;
        var totalWeeks = 0;
        var totalVisit = 0;

        do {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - days);
            var currentDateStr = this.getDateString(days);

            var found = app.currYear.find(x => x.date === currentDateStr);

            if (found != undefined) {
                countWeek += found.count;
                totalVisit += found.count;
            }

            // new week
            if (currentDate.getDay() == 1) {
                totalWeeks++;
                label.push(currentDate.getWeek());
                week.push(countWeek);
                total.push(totalVisit);
                countWeek = 0;
            }
            days++;

        } while (totalWeeks <= 7);

        return { week: week.reverse(), label: label.reverse(), total: total.reverse() };
    },
    monthDiff: function (d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    },
    getAllPeriod: function () {
        var period = [];
        var month = [];
        var label = [];
        var total = [];
        var today = new Date();
        var days = 0;
        var date = today.getDate();
        var countWeek = 0;
        var totalWeeks = 0;
        var totalVisit = 0;

        var smallestDate;
        //var biggestDate;

        var currentDate = new Date();
        var toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        for (var i = 0; i < app.currYear.length; i++) {
            var found = app.currYear[i];
            var currentDateStr = found.date.substr(0, 7);

            if (i == 0) {
                smallestDate = currentDateStr;
                //biggestDate = currentDateStr;
            }

            if (currentDateStr < smallestDate) smallestDate = currentDateStr;
            //if (currentDateStr > biggestDate) biggestDate = currentDateStr;
        }


        var smallestDate_year = smallestDate.substr(0, 4);
        var smallestDate_month = smallestDate.substr(5, 2);

        /*var biggestDate_year = biggestDate.substr(0, 4);
        var biggestDate_month = biggestDate.substr(5, 2);*/


        var fromDate = new Date(parseInt(smallestDate_year), parseInt(smallestDate_month) - 1, 1);
        var monthDiff = 1;

        if (fromDate != toDate) {
            monthDiff = this.monthDiff(fromDate, toDate);
        }

        for (var total_month = 0; total_month <= monthDiff; total_month++) {
            var date = this.getDateString_month(total_month);
            var newPeriod = { date: date, count: 0 };
            period.push(newPeriod);
            //var fromDate = new Date(parseInt(smallestDate_year), parseInt(smallestDate_month) - 1, 1);
        }

        var totalcount = 0;
        for (var i = 0; i < app.currYear.length; i++) {
            var found = app.currYear[i];

            var currentDateStr = found.date.substr(0, 7);

            var foundPeriod = period.find(x => x.date === currentDateStr);

            if (foundPeriod != undefined) {
                foundPeriod.count += found.count;
                totalcount += foundPeriod.count;
            }
            else {
                //var foundPeriod { date: currentDateStr, count : count}
                foundPeriod.date = currentDateStr;
                foundPeriod.count += found.count;
                totalcount += foundPeriod.count;
            }
        }

        for (var i = 0; i < period.length; i++) {
            label.push(period[i].date);
            month.push(period[i].count);
            total.push(totalcount);
        }

        return { month: month.reverse(), label: label.reverse(), total: total.reverse() };
    },
    onAttach() {
        var self = this;


        this.addressPicker = new AddressPicker();
        this.ui.customEventEditItem_inputPubliclyAddress.typeahead(null, {
            displayKey: 'description',
            source: self.addressPicker.ttAdapter()
        });

        this.addressPicker.bindDefaultTypeaheadEvent(this.ui.customEventEditItem_inputPubliclyAddress);
        $(this.addressPicker).on('addresspicker:selected', function (event, parsedGeocodeResult) {
            var _parsedGeocodeResult = self._parseGeocodeResult(parsedGeocodeResult.placeResult);

            if ((_parsedGeocodeResult.lat == 0) && (_parsedGeocodeResult.lng == 0)) {
                self.showErrorsAddress();
            }
            else {
                self.hideErrorsAddress();
                self.setModel(_parsedGeocodeResult);
            }
        });

        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        //$(document).scrollTop(this.options.scroll);
        //this.ui.customEventEditItem_top.scrollTop(this.options.scroll);

        var week_label = this.getWeek();
        var week8_label = this.getLast8Week();
        var allPeriod = this.getAllPeriod();

        this.ui.charsjs_week_total.text(week_label.total[0]);
        var week_average = parseInt(week_label.total[0] / 7);
        this.ui.charsjs_week_average.text(week_average + " " + resources['X101266'])


        this.ui.charsjs_month_total.text(week8_label.total[0]);
        var week8_average = parseInt(week8_label.total[0] / 8);
        this.ui.charsjs_month_average.text(week8_average + " " + resources['X101269']);

        this.ui.charsjs_allPeriod_total.text(allPeriod.total[0]);
        var allPeriod_average = parseInt(allPeriod.total[0] / allPeriod.total.length);
        this.ui.charsjs_allPeriod_average.text(allPeriod_average + " " + resources['X101286']);


        //Global Chart.js options
        //Chart.defaults.global.elements.responsive = true;
        //Chart.defaults.global.tooltips.xPadding = 30;
        //Chart.defaults.global.tooltips.yPadding = 30;
        Chart.defaults.global.tooltips.position = 'nearest';
        //Chart.defaults.global.tooltips.caretPadding = -20;

        //register custome positioner
        //Chart.Tooltip.positioners.custom = function (elements, position) {
        //    if (!elements.length) {
        //        return false;
        //    }
        //    var offset = 0;
        //    //adjust the offset left or right depending on the event position
        //    if (elements[0]._chart.width / 2 > position.x) {
        //        offset = 20;
        //    } else {
        //        offset = -20;
        //    }
        //    return {
        //        x: position.x + offset,
        //        y: position.y
        //    }
        //}

        var charsjs_week_Data = {
            labels: week_label.label,//["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
            datasets: [{
                label: resources['X101267'],//'New Tickets',
                data: week_label.week,
                backgroundColor: [
                    '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee',
                ],
                borderColor: [
                    '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee',
                ],
                borderWidth: 1,
                fill: false
            }
            ]
        };
        var charsjs_week_Options = {
            //layout: {
            //    padding: {
            //        left: 0,
            //        right: 0,
            //        top: 0,
            //        bottom: 0
            //    }
            //},
            scales: {
                //scaleLabel:
                //    [{
                //        padding: {
                //            top: 30,
                //            bottom: 40
                //        }
                //    }],
                xAxes: [{
                    barPercentage: 1,
                    position: 'bottom',
                    display: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: true, //this will remove only the label
                        stepSize: 300,
                    }
                }],
                yAxes: [{
                    display: false,
                    gridLines: {
                        drawBorder: true,
                        display: true,
                        color: "#f0f3f6",
                        borderDash: [8, 4],
                    },
                    ticks: {
                        display: true,
                        beginAtZero: true,
                    },
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
            },
            plugins: {
                datalabels: {
                    display: true,
                    align: 'center',
                    anchor: 'center'
                }
            }
        };
        if ($("#charsjs_week").length) {
            var barChartCanvas = $("#charsjs_week").get(0).getContext("2d");
            // This will get the first returned node in the jQuery collection.
            if (screen.width > 767) {
                var chartHeight = document.getElementById("charsjs_week");
                chartHeight.height = 120;
            }
            var barChart = new Chart(barChartCanvas, {
                type: 'bar',
                data: charsjs_week_Data,
                options: charsjs_week_Options//,
                //tooltips: {
                //use our new custom position
                //  position: 'custom'
                //},
            });
        }

        var charsjs_month_Data = {
            labels: week8_label.label,//["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
            datasets: [{
                label: resources['X101270'],
                data: week8_label.week, //[4, 9, 22, 29, 39, 25, 16, 11],
                backgroundColor: [
                    '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee',
                ],
                borderColor: [
                    '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee',
                ],
                borderWidth: 1,
                fill: false
            }
            ]
        };
        var charsjs_month_Options = {
            scales: {
                xAxes: [{
                    barPercentage: 1,
                    position: 'bottom',
                    display: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: true, //this will remove only the label
                        stepSize: 300,
                    }
                }],
                yAxes: [{
                    display: false,
                    gridLines: {
                        drawBorder: false,
                        display: true,
                        color: "#f0f3f6",
                        borderDash: [8, 4],
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
            },
            plugins: {
                datalabels: {
                    display: false,
                    align: 'center',
                    anchor: 'center'
                }
            }
        };
        if ($("#charsjs_month").length) {
            var barChartCanvas = $("#charsjs_month").get(0).getContext("2d");
            // This will get the first returned node in the jQuery collection.
            if (screen.width > 767) {
                var chartHeight = document.getElementById("charsjs_month");
                chartHeight.height = 120;
            }

            var barChart = new Chart(barChartCanvas, {
                type: 'bar',
                data: charsjs_month_Data,
                options: charsjs_month_Options
            });
        }

        var charsjs_all_Data = {
            labels: allPeriod.label,// ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: resources['X101287'],
                    data: allPeriod.month,//[20, 69, 73, 66, 69, 73, 40, 59, 63, 91, 73, 40, 73],
                    backgroundColor: [
                        '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7',
                    ],
                    borderColor: [
                        '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7', '#0ad7f7'
                    ],
                    borderWidth: 1,
                    fill: false
                }//,
                //{
                //    label: 'New Users',
                //    data: [11, 12, 1, 26, 12, 20, 2, 7, 18, 7, 7, 24, 7],
                //    backgroundColor: [
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //    ],
                //    borderColor: [
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //        '#464dee',
                //    ],
                //    borderWidth: 1,
                //    fill: false
                //}
            ]
        };
        var charsjs_all_Options = {
            scales: {
                xAxes: [{
                    barPercentage: 0.4,
                    stacked: true,
                    gridLines: {
                        display: false, //this will remove only the label
                        drawBorder: false,
                        color: "#eaeaea",
                    },
                    ticks: {
                        display: true,
                        fontColor: "#6c7293",
                    }
                }],
                yAxes: [{
                    stacked: true,
                    display: true,
                    gridLines: {
                        display: true, //this will remove only the label
                        drawBorder: false,
                        color: "#eaeaea",
                    },
                    ticks: {
                        display: false,
                        stepSize: 40,
                        color: '#6c7293',
                    }
                }]
            },
            legend: {
                display: false,
                position: "bottom"
            },
            elements: {
                point: {
                    radius: 0
                }
            }
        };
        if ($("#charsjs_all").length) {
            var barChartCanvas = $("#charsjs_all").get(0).getContext("2d");
            // This will get the first returned node in the jQuery collection.
            if (screen.width > 767) {
                var ctx = document.getElementById("charsjs_all");
                ctx.height = 88;
            }
            var barChart = new Chart(barChartCanvas, {
                type: 'bar',
                data: charsjs_all_Data,
                options: charsjs_all_Options
            });
        }


    },

    onAfterShow: function () {
        //$(document).scrollTop(100);
    },
    onShow: function () {
        $(document).scrollTop(this.options.scroll);
    },
    onDestroy() {
        $(this.addressPicker).unbind();
        this.ui.customEventEditItem_datepicker.datepicker("destroy");
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No webpage is selected, no event will be generated
        if (this.model.attributes.Webpage <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100097 });
            this.informationCollection.add(tmpModel);
        }

        //No QRCode is selected, no event will be generated
        if (this.model.attributes.QRCode <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100098 });
            this.informationCollection.add(tmpModel);
        }

        //No infopage enable on subscribtions
        if (this.app.entities.models.configSubscription[0].attributes.EnableInfo == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100094 });
            this.informationCollection.add(tmpModel);
        }

        //No poster page enable on subscribtions
        if (this.app.entities.models.configSubscription[0].attributes.EnablePoster == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100100 });
            this.informationCollection.add(tmpModel);
        }

        //No schema page enable on subscribtions
        if (this.app.entities.models.configSubscription[0].attributes.EnableSchema == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100095 });
            this.informationCollection.add(tmpModel);
        }

        //No form page enable on subscribtions
        if (this.app.entities.models.configSubscription[0].attributes.EnableForm == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100096 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    setModel: function (parsedGeocodeResult) {
        var ShowInfo = this.app.entities.models.configSubscription[0].attributes.EnableInfo;
        var ShowPoster = this.app.entities.models.configSubscription[0].attributes.EnablePoster;

        var changeDate = new Date();

        var strUtcDate = changeDate.getUTCFullYear().toString() + '-' +
            (changeDate.getUTCMonth() + 1) + '-' +
            changeDate.getUTCDate().toString() + ' ' +
            changeDate.getUTCHours() + ':' +
            changeDate.getUTCMinutes() + ':' +
            changeDate.getUTCSeconds();

        this.utcStartDate.setHours(0);
        this.utcStartDate.setMinutes(0);
        this.utcStartDate.setSeconds(1);

        var strUtcStartDate = this.utcStartDate.getUTCFullYear().toString() + '-' +
            (this.utcStartDate.getUTCMonth() + 1) + '-' +
            this.utcStartDate.getUTCDate().toString() + ' ' +
            this.utcStartDate.getUTCHours().toString() + ':' +
            this.utcStartDate.getUTCMinutes().toString() + ':' +
            this.utcStartDate.getUTCSeconds().toString();

        this.utcEndDate.setHours(23);
        this.utcEndDate.setMinutes(59);
        this.utcEndDate.setSeconds(59);

        var strUtcEndDate = this.utcEndDate.getUTCFullYear().toString() + '-' +
            (this.utcEndDate.getUTCMonth() + 1) + '-' +
            this.utcEndDate.getUTCDate().toString() + ' ' +
            this.utcEndDate.getUTCHours().toString() + ':' +
            this.utcEndDate.getUTCMinutes().toString() + ':' +
            this.utcEndDate.getUTCSeconds().toString();

        var administrative_area_level_1 = '';
        var administrative_area_level_2 = '';
        var administrative_area_level_3 = '';
        var country = '';
        var lat = 0;
        var lng = 0;
        var locality = '';
        var postal_code = '';
        var route = '';
        var street_number = '';
        var sublocality = '';

        //var webpageImageUrl = '/Events/' + this.model.id + '_imagePrint.jpg';

        var HelpBtn = this.model.attributes.HelpBtn;
        var HomeBtn = this.model.attributes.HomeBtn;

        if (this.app.entities.models.custom.attributes.Admin) {
            this.ui.customEventEditItem_input_HelpBtn.is(':checked') ? HelpBtn = true : HelpBtn = false;
            this.ui.customEventEditItem_input_HomeBtn.is(':checked') ? HomeBtn = true : HomeBtn = false;
        }

        var Publicly = this.model.attributes.Publicly;
        this.ui.customEventEditItem_selectPublicly.is(':checked') ? Publicly = 1 : Publicly = 0;

        var Module_Facebook = this.model.attributes.Module_Facebook;
        this.ui.customEventEditItem_checkbox_Module_Facebook.is(':checked') ? Module_Facebook = true : Module_Facebook = false;

        var Module_SchemaModel = this.model.attributes.Module_SchemaModel;
        this.ui.customEventEditItem_checkbox_Module_SchemaModel.is(':checked') ? Module_SchemaModel = true : Module_SchemaModel = false;

        var Module_FormModel = this.model.attributes.Module_FormModel;
        this.ui.customEventEditItem_checkbox_Module_FormModel.is(':checked') ? Module_FormModel = true : Module_FormModel = false;

        var Module_Ad = this.model.attributes.Module_Ad;
        this.ui.customEventEditItem_checkbox_Module_Ad.is(':checked') ? Module_Ad = true : Module_Ad = false;




        var v = this.ui.customEventEditItem_textarea_description.val();
        var a = JSON.stringify(v);

        var Webpage = parseInt(this.ui.customEventEditItem_selectWebpage.val());

        if (parsedGeocodeResult) {
            if (parsedGeocodeResult.administrative_area_level_1 != false) {
                administrative_area_level_1 = parsedGeocodeResult.administrative_area_level_1;
            }
            if (parsedGeocodeResult.administrative_area_level_2 != false) {
                administrative_area_level_2 = parsedGeocodeResult.administrative_area_level_2;
            }
            if (parsedGeocodeResult.administrative_area_level_3 != false) {
                administrative_area_level_3 = parsedGeocodeResult.administrative_area_level_3;
            }
            if (parsedGeocodeResult.country != false) {
                country = parsedGeocodeResult.country;
            }
            if (parsedGeocodeResult.lat != false) {
                lat = parsedGeocodeResult.lat;
            }
            if (parsedGeocodeResult.lng != false) {
                lng = parsedGeocodeResult.lng;
            }
            if (parsedGeocodeResult.locality != false) {
                locality = parsedGeocodeResult.locality;
            }
            if (parsedGeocodeResult.postal_code != false) {
                postal_code = parsedGeocodeResult.postal_code;
            }
            if (parsedGeocodeResult.route != false) {
                route = parsedGeocodeResult.route;
            }
            if (parsedGeocodeResult.street_number != false) {
                street_number = parsedGeocodeResult.street_number;
            }
            if (parsedGeocodeResult.sublocality != false) {
                sublocality = parsedGeocodeResult.sublocality;
            }

            this.model.set({
                Name: this.ui.customEventEditItem_inputName.val(),
                EventName: this.ui.customEventEditItem_inputEventName.val(),
                Description: this.ui.customEventEditItem_textarea_description.val(),
                UtcDate: strUtcDate,
                FriendlyUrl: this.ui.customEventEditItem_inputFriendlyUrl.val(),
                //SchemaMenuName: this.ui.customEventEditItem_inputSchemaMenuName.val(),
                //FormModel: parseInt(this.ui.customEventEditItem_selectFormModel.val()),
                //FormMenuName: this.ui.customEventEditItem_inputFormMenuName.val(),
                //FormSubmitBtnName: this.ui.customEventEditItem_inputFormSubmitBtnName.val(),
                //FormCancelBtnName: this.ui.customEventEditItem_inputFormCancelBtnName.val(),
                //FormSubmitBtnSucces: this.ui.customEventEditItem_inputFormSubmitBtnSucces.val(),
                //FormNotifyEmail: parseInt(this.ui.customEventEditItem_selectFormNotifyEmail.val()),
                //FormNotifyEmailAddress: this.ui.customEventEditItem_inputFormNotifyEmailAddress.val(),

                //ProductSheetModel: parseInt(this.ui.customEventEditItem_selectProductSheetModel.val()),
                //ProductSheetMenuName: this.ui.customEventEditItem_inputProductSheetMenuName.val(),
                //ProductSheetPreviusBtnName: this.ui.customEventEditItem_inputProductSheetPreviusBtnName.val(),
                //ProductSheetNextBtnName: this.ui.customEventEditItem_inputProductSheetNextBtnName.val(),

                //Webpage: parseInt(this.ui.customEventEditItem_selectWebpage.val()),
                //Infopage: parseInt(this.ui.customEventEditItem_selectInfopage.val()),
                AdminDisable: this.model.attributes.AdminDisable,
                AdminDisableReason: this.model.attributes.AdminDisableReason,
                AdminEnableDelete: this.model.attributes.AdminEnableDelete,
                AdminInformation: this.model.attributes.AdminInformation,
                ShowInfo: ShowInfo,
                ShowPoster: ShowPoster,
                Information: this.ui.customEventEditItem_inputInformation.val(),
                //WebpageImageUrl: webpageImageUrl,
                picasaUpdateImage: false,
                QRCode: parseInt(this.ui.customEventEditItem_selectQRCode.val()),
                SchemaModel: parseInt(this.ui.customEventEditItem_selectSchemaModel.val()),

                //QRCodeTextPoster: this.ui.customEventEditItem_inputQRCodeTextPoster.val(),
                //QRCodeTextInfoscreen: this.ui.customEventEditItem_inputQRCodeTextInfoscreen.val(),
                //QRCodeTextBigSchemaScreen: this.ui.customEventEditItem_inputQRCodeTextBigSchemaScreen.val(),
                ////QRCodeTextQRCode  this.ui.customEventEditItem_inputQRCodeTextQRCode.val(),
                QRCodeFontSizePoster: parseInt(this.ui.customEventEditItem_selectQRCodeFontSizePoster.val()),
                QRCodeFontSizeInfoscreen: parseInt(this.ui.customEventEditItem_selectQRCodeFontSizeInfoscreen.val()),
                QRCodeFontSizeBigSchemaScreen: parseInt(this.ui.customEventEditItem_selectQRCodeFontSizeBigSchemaScreen.val()),
                //QRCodeFontSizeQRCode  : parseInt(this.ui.customEventEditItem_selectQRCodeFontSizeQRCode.val()),

                //AdEnable: parseInt(this.ui.customEventEditItem_selectAdEnable.val()),
                //AdImageId: parseInt(this.ui.customEventEditItem_selectAdImageId.val()),
                //AdImageUrl: this.ui.customEventEditItem_inputAdImageUrl.val(),

                //AdEnable  != this.oldModel.attributes.AdEnable) ||
                //AdStatus  : 
                //AdImageUrl   this.ui.customEventEditItem_inputAdImageUrl.val(),
                //AdImageLink  : this.ui.customEventEditItem_inputAdImageLink.val(),

                Address: this.ui.customEventEditItem_inputPubliclyAddress.val(),
                Address_administrative_area_level_1: administrative_area_level_1,
                Address_administrative_area_level_2: administrative_area_level_2,
                Address_administrative_area_level_3: administrative_area_level_3,
                Address_country: country,
                Address_lat: lat,
                Address_lng: lng,
                Address_locality: locality,
                Address_postal_code: postal_code,
                Address_route: route,
                Address_street_number: street_number,
                Address_sublocality: sublocality,
                //Publicly: parseInt(this.ui.customEventEditItem_selectPublicly.val()),
                Publicly: Publicly,
                UseDate: parseInt(this.ui.customEventEditItem_selectUseDate.val()),
                Facebook: parseInt(this.ui.customEventEditItem_selectFacebook.val()),
                Copyright: parseInt(this.ui.customEventEditItem_selectCopyright.val()),
                InfopageShowQRCode: parseInt(this.ui.customEventEditItem_selectInfopageShowQRCode.val()),
                SchemaBigScreenShowQRCode: parseInt(this.ui.customEventEditItem_selectSchemaBigScreenShowQRCode.val()),
                UtcStartDate: strUtcStartDate,
                UtcEndDate: strUtcEndDate,
                HelpBtn: HelpBtn,
                HomeBtn: HomeBtn,
                Module_Facebook: Module_Facebook,
                Module_SchemaModel: Module_SchemaModel,
                Module_FormModel: Module_FormModel,
                Module_ProductSheetModel: Module_ProductSheetModel,
                Module_Ad: Module_Ad,
                Module_SchemaScreen_Direction_H: this.model.attributes.Module_SchemaScreen_Direction_H,
                Module_SchemaScreen_Direction_V: this.model.attributes.Module_SchemaScreen_Direction_V,
                Module_InfoScreen_Direction_H: this.model.attributes.Module_InfoScreen_Direction_H,
                Module_InfoScreen_Direction_V: this.model.attributes.Module_InfoScreen_Direction_V,
                Module_Poster_Direction_H: this.model.attributes.Module_Poster_Direction_H,
                Module_Poster_Direction_V: this.model.attributes.Module_Poster_Direction_V,
                Module_CityInfo_Direction_H: this.model.attributes.Module_CityInfo_Direction_H,
                Module_CityInfo_Direction_V: this.model.attributes.Module_CityInfo_Direction_V

            }, { silent: true });
        }
        else {
            this.model.set({
                Name: this.ui.customEventEditItem_inputName.val(),
                EventName: this.ui.customEventEditItem_inputEventName.val(),
                Description: this.ui.customEventEditItem_textarea_description.val(),
                UtcDate: strUtcDate,
                FriendlyUrl: this.ui.customEventEditItem_inputFriendlyUrl.val(),
                //SchemaMenuName: this.ui.customEventEditItem_inputSchemaMenuName.val(),
                //FormModel: parseInt(this.ui.customEventEditItem_selectFormModel.val()),
                //FormMenuName: this.ui.customEventEditItem_inputFormMenuName.val(),
                //FormSubmitBtnName: this.ui.customEventEditItem_inputFormSubmitBtnName.val(),
                //FormCancelBtnName: this.ui.customEventEditItem_inputFormCancelBtnName.val(),
                //FormSubmitBtnSucces: this.ui.customEventEditItem_inputFormSubmitBtnSucces.val(),
                //FormNotifyEmail: parseInt(this.ui.customEventEditItem_selectFormNotifyEmail.val()),
                //FormNotifyEmailAddress: this.ui.customEventEditItem_inputFormNotifyEmailAddress.val(),

                //ProductSheetModel: parseInt(this.ui.customEventEditItem_selectProductSheetModel.val()),
                //ProductSheetMenuName: this.ui.customEventEditItem_inputProductSheetMenuName.val(),
                //ProductSheetPreviusBtnName: this.ui.customEventEditItem_inputProductSheetPreviusBtnName.val(),
                //ProductSheetNextBtnName: this.ui.customEventEditItem_inputProductSheetNextBtnName.val(),

                //Webpage: parseInt(this.ui.customEventEditItem_selectWebpage.val()),
                //Infopage: parseInt(this.ui.customEventEditItem_selectInfopage.val()),
                AdminDisable: this.model.attributes.AdminDisable,
                AdminDisableReason: this.model.attributes.AdminDisableReason,
                AdminEnableDelete: this.model.attributes.AdminEnableDelete,
                AdminInformation: this.model.attributes.AdminInformation,
                ShowInfo: ShowInfo,
                ShowPoster: ShowPoster,
                Information: this.ui.customEventEditItem_inputInformation.val(),
                //WebpageImageUrl: webpageImageUrl,
                picasaUpdateImage: false,
                //QRCode: parseInt(this.ui.customEventEditItem_selectQRCode.val()),
                //SchemaModel: parseInt(this.ui.customEventEditItem_selectSchemaModel.val()),

                //QRCodeTextPoster: this.ui.customEventEditItem_inputQRCodeTextPoster.val(),
                //QRCodeTextInfoscreen: this.ui.customEventEditItem_inputQRCodeTextInfoscreen.val(),
                //QRCodeTextBigSchemaScreen: this.ui.customEventEditItem_inputQRCodeTextBigSchemaScreen.val(),
                //QRCodeTextQRCode  this.ui.customEventEditItem_inputQRCodeTextQRCode.val(),
                //QRCodeFontSizePoster: parseInt(this.ui.customEventEditItem_selectQRCodeFontSizePoster.val()),
                //QRCodeFontSizeInfoscreen: parseInt(this.ui.customEventEditItem_selectQRCodeFontSizeInfoscreen.val()),
                //QRCodeFontSizeBigSchemaScreen: parseInt(this.ui.customEventEditItem_selectQRCodeFontSizeBigSchemaScreen.val()),
                //QRCodeFontSizeQRCode  : parseInt(this.ui.customEventEditItem_selectQRCodeFontSizeQRCode.val()),

                //AdEnable: parseInt(this.ui.customEventEditItem_selectAdEnable.val()),
                //AdImageId: parseInt(this.ui.customEventEditItem_selectAdImageId.val()),
                //AdImageUrl: this.ui.customEventEditItem_inputAdImageUrl.val(),

                //Address: this.ui.customEventEditItem_inputPubliclyAddress.val(),
                //Publicly: parseInt(this.ui.customEventEditItem_selectPublicly.val()),
                Publicly: Publicly,
                UseDate: parseInt(this.ui.customEventEditItem_selectUseDate.val()),
                Facebook: parseInt(this.ui.customEventEditItem_selectFacebook.val()),
                Copyright: parseInt(this.ui.customEventEditItem_selectCopyright.val()),
                InfopageShowQRCode: parseInt(this.ui.customEventEditItem_selectInfopageShowQRCode.val()),
                SchemaBigScreenShowQRCode: parseInt(this.ui.customEventEditItem_selectSchemaBigScreenShowQRCode.val()),
                UtcStartDate: strUtcStartDate,
                UtcEndDate: strUtcEndDate,
                HelpBtn: HelpBtn,
                HomeBtn: HomeBtn,
                Module_Facebook: Module_Facebook,
                Module_SchemaModel: Module_SchemaModel,
                Module_FormModel: Module_FormModel,
                Module_ProductSheetModel: false,
                Module_Ad: Module_Ad,
                Module_SchemaScreen_Direction_H: this.model.attributes.Module_SchemaScreen_Direction_H,
                Module_SchemaScreen_Direction_V: this.model.attributes.Module_SchemaScreen_Direction_V,
                Module_InfoScreen_Direction_H: this.model.attributes.Module_InfoScreen_Direction_H,
                Module_InfoScreen_Direction_V: this.model.attributes.Module_InfoScreen_Direction_V,
                Module_Poster_Direction_H: this.model.attributes.Module_Poster_Direction_H,
                Module_Poster_Direction_V: this.model.attributes.Module_Poster_Direction_V,
                Module_CityInfo_Direction_H: this.model.attributes.Module_CityInfo_Direction_H,
                Module_CityInfo_Direction_V: this.model.attributes.Module_CityInfo_Direction_V

            }, { silent: true });
        }

        return true;
    },
    setupDefaultValues: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableQRCode == false) {
            var qrCode = this.app.entities.collections.customQRCode.fullCollection.where({ Name: resources.X100123 });
            if (qrCode.length > 0) {
                this.model.attributes.QRCode = qrCode[0].attributes.id;
            }
        }
        if (this.app.entities.models.configSubscription[0].attributes.EnableSchemaModel == false) {
            this.model.attributes.SchemaModel = -1;
        }
    },
    showErrorsAddress: function () {
        this.ui.custom_alertWarningWrongAddress.removeClass('hidden');
        this.ui.custom_alertWarningWrongAddress.addClass('show');
    },
    hideErrorsAddress: function () {
        this.ui.custom_alertWarningWrongAddress.removeClass('show');
        this.ui.custom_alertWarningWrongAddress.addClass('hidden');

    },
    btnSubmitShow: function () {
    },
    setupWebpage: function () {
        var element = this.ui.customEventEditItem_selectWebpage;
        element.empty();

        if (this.model.attributes.Webpage == -1) {
            element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            element.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }
        var self = this;
        this.app.entities.collections.customWebpage.fullCollection.forEach(function (entry) {
            if (self.model.attributes.Webpage == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });

    },
    setupInfopage: function () {
        var element = this.ui.customEventEditItem_selectInfopage;
        element.empty();

        if (this.model.attributes.Infopage == -1) {
            element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            element.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }
        var self = this;
        this.app.entities.collections.customInfopage.fullCollection.forEach(function (entry) {
            if (self.model.attributes.Infopage == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    setupQRCode: function () {
        var element = this.ui.customEventEditItem_selectQRCode;
        element.empty();
        var self = this;
        this.app.entities.collections.customQRCode.fullCollection.forEach(function (entry) {
            if (self.model.attributes.QRCode == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    setupFontSizeQRCode: function (element) {
        for (var i = 10; i <= 30; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupQRCode2: function () {
        var self = this;

        this.setupFontSizeQRCode(this.ui.customEventEditItem_selectQRCodeFontSizePoster)
        this.ui.customEventEditItem_selectQRCodeFontSizePoster.val(this.model.attributes.QRCodeFontSizePoster);

        this.setupFontSizeQRCode(this.ui.customEventEditItem_selectQRCodeFontSizeInfoscreen)
        this.ui.customEventEditItem_selectQRCodeFontSizeInfoscreen.val(this.model.attributes.QRCodeFontSizeInfoscreen);

        this.setupFontSizeQRCode(this.ui.customEventEditItem_selectQRCodeFontSizeBigSchemaScreen)
        this.ui.customEventEditItem_selectQRCodeFontSizeBigSchemaScreen.val(this.model.attributes.QRCodeFontSizeBigSchemaScreen);
    },
    setupSchemaModel: function () {
        var element = this.ui.customEventEditItem_selectSchemaModel;
        element.empty();
        if (this.model.attributes.SchemaModel == -1) {
            element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            element.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }
        var self = this;
        this.app.entities.collections.customSchemaModel.fullCollection.forEach(function (entry) {
            if (self.model.attributes.SchemaModel == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    setupFormModel: function () {
        var element = this.ui.customEventEditItem_selectFormModel;
        element.empty();
        if (this.model.attributes.FormModel == -1) {
            element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            element.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }
        var self = this;
        this.app.entities.collections.customFormModel.fullCollection.forEach(function (entry) {
            if (self.model.attributes.FormModel == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    setupProductSheetModel: function () {
        var element = this.ui.customEventEditItem_selectProductSheetModel;
        element.empty();
        if (this.model.attributes.ProductSheetModel == -1) {
            element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            element.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }
        var self = this;
        this.app.entities.collections.customProductSheetModel.fullCollection.forEach(function (entry) {
            if (self.model.attributes.ProductSheetModel == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    setupFriendlyUrl: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            this.ui.customEventEditItem_inputFriendlyUrl.prop('disabled', true);
            this.ui.customEventEditItem_inputFriendlyUrl.val('');
        } 
    },
    setupInfopageShowQRCode: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
            //this.ui.customEventEditItem_inputFriendlyUrl.prop('disabled', true);
            this.ui.customEventEditItem_selectInfopageShowQRCode.prop('disabled', true);
        }
    },
    setupSchemaBigScreenShowQRCode: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
            this.ui.customEventEditItem_selectSchemaBigScreenShowQRCode.prop('disabled', true);
        }
    },
    selectWebpageChange: function () {
        this.model.attributes.Webpage = parseInt(this.ui.customEventEditItem_selectWebpage.val());
        this.btnSubmitShow();

        this.updateInformationCollection();
    },
    selectInfopageChange: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableInfo == false) {
            this.ui.customEventEditItem_selectInfopage.val(-1);

            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100120;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            this.model.attributes.Infopage = parseInt(this.ui.customEventEditItem_selectInfopage.val());
        }
    },
    setupUrl: function () {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;
        var webpageInfo = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100140;
        var webpagePoster = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100141;

        this.ui.customEventEditItem_webpageEvent.attr('href', webpageEvent);
        this.ui.customEventEditItem_webpageInfo.attr('href', webpageInfo);
        this.ui.customEventEditItem_webpagePoster.attr('href', webpagePoster);

        if (this.app.entities.models.configSubscription[0].attributes.EnableInfo == false) {
            this.ui.customEventEditItem_webpageInfo.css('display', 'none');
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnablePoster == false) {
            this.ui.customEventEditItem_webpagePoster.css('display', 'none');
        }
    },
    setupUrlLink: function () {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        this.ui.customEventEditItem_inputShowLink.val(webpageEvent);
        this.ui.customEventEditItem_labelShowLink.text(webpageEvent);

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ShowLink' });
        var enable = customSetting[0].attributes.Value == '1' ? true : false;

        if (enable) {
            //this.ui.customEventEditItem_btnShowLink.text(resources.X100966);
            this.ui.customEventEditItem_labelShowLink.show();
            this.ui.customEventEditItem_iShowLink.removeClass('fa-eye');
            this.ui.customEventEditItem_iShowLink.addClass('fa-eye-slash');
            //this.ui.customEventEditItem_iShowLink.title = resources.X100966;
            this.ui.customEventEditItem_iShowLink.attr("data-original-title", resources.X100966);
        } else {
            //this.ui.customEventEditItem_btnShowLink.text(resources.X100965);
            this.ui.customEventEditItem_labelShowLink.css('display', 'none');
            this.ui.customEventEditItem_iShowLink.removeClass('fa-eye-slash');
            this.ui.customEventEditItem_iShowLink.addClass('fa-eye');
            //this.ui.customEventEditItem_iShowLink.title = resources.X100965;
            this.ui.customEventEditItem_iShowLink.attr("data-original-title", resources.X100965);
        }
    },
    selectQRCode: function (event) {
        event.preventDefault();

        var value = this.$el.find('#customEventEditItem_selectQRCode').val();
        var self = this;
        if (this.app.entities.models.configSubscription[0].attributes.EnableQRCode == false) {
            var qrCode = this.app.entities.collections.customQRCode.fullCollection.where({ Name: resources.X100123 });
            if (qrCode.length > 0) {
                this.ui.customEventEditItem_selectQRCode.val(qrCode[0].attributes.id)
            }

            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100128;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            this.model.attributes.QRCode = value;
        }

        this.updateInformationCollection();
    },
    selectQRCodeChange: function (event) {
        event.preventDefault();

        var value = this.$el.find('#customEventEditItem_selectQRCode').val();
        var self = this;
        if (this.app.entities.models.configSubscription[0].attributes.EnableQRCode == false) {
            var qrCode = this.app.entities.collections.customQRCode.fullCollection.where({ Name: resources.X100123 });
            if (qrCode.length > 0) {
                this.ui.customEventEditItem_selectQRCode.val(qrCode[0].attributes.id)
            }

            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100128;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            this.model.attributes.QRCode = value;
        }

        this.updateInformationCollection();
    },

    selectSchemaModelChange: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableSchemaModel == false) {
            this.ui.customEventEditItem_selectSchemaModel.val(-1);

            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100129;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            this.model.attributes.SchemaModel = parseInt(this.ui.customEventEditItem_selectSchemaModel.val());
        }
    },
    selectFormModelChange: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableFormModel == false) {
            this.ui.customEventEditItem_selectFormModel.val(-1);

            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100130;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            this.model.attributes.FormModel = parseInt(this.ui.customEventEditItem_selectFormModel.val());
        }
    },
    selectProductSheetModelChange: function () {
        if (this.app.entities.models.configSubscription[0].attributes.EnableProductSheetModel == false) {
            this.ui.customEventEditItem_selectProductSheetModel.val(-1);

            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X101121;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            this.model.attributes.ProductSheetModel = parseInt(this.ui.customEventEditItem_selectProductSheetModel.val());
        }
    },
    btnPublishClick: function (event, callback) {
        this.btnSubmitClick(null, null, true);
    },
    btnUpdateVersionClick: function (event) {
        var self = this;
        var callback_updateSingleVersion_finish = function () {
            //self.btnSubmitClick(null, null, false);
            self.btnSubmitClick(null, null, true);
        }

        EventUpdate.updateSingleVersion(self, self.model, callback_updateSingleVersion_finish);

        //var self = this;
        //this.model.attributes.Version = this.app.entities.models.customEventVersion.attributes.Value;

        //var dialogWaitingModel = new Entities_Empty.Model();
        //dialogWaitingModel.attributes.Title = resources.X100070;
        //dialogWaitingModel.attributes.Text = resources.X100525;
        //dialogWaitingModel.attributes.Button = resources.X100279;

        //var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
        //var callback = function (options) {
        //    self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        //}
        //self.listenTo(dialogWaiting, "dialog:okclicked", callback);
        //self.app.dialogRegion.show(dialogWaiting);

        //self.model.save(self.model.attributes, {
        //    silent: true,
        //    wait: true,
        //    updateVersion : true,
        //    success: function () {
        //        dialogWaiting.triggerMethod('dialog:close');

        //    }
        //});
    },
    btnSubmitClick: function (event, callback, publish) {

        if (publish == undefined) {
            publish = false;
        }

        var self = this;
        this.setModel(null);
        this.setupUrlLink();
        var modelErrors = false;

        this.model.validateNoBackbone(app, false, function (ret) {
            modelErrors = ret;
            if (modelErrors) {
                self.hideErrors();
                self.showErrors(self.model.errors);
            }
            else {
                self.hideErrors();

                var dialogWaitingModel = new Entities_Empty.Model();
                dialogWaitingModel.attributes.Title = resources.X100070;
                dialogWaitingModel.attributes.Text = resources.X100416;
                dialogWaitingModel.attributes.Button = resources.X100279;

                var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
                var callbackDialogWaiting = function (options) {
                    self.stopListening(dialogWaiting, "dialog:okclicked", callbackDialogWaiting);
                }
                self.listenTo(dialogWaiting, "dialog:okclicked", callbackDialogWaiting);
                self.app.dialogRegion.show(dialogWaiting);

                self.model.save(self.model.attributes, {
                    silent: true,
                    wait: true,
                    updateVersion: false,
                    success: function () {
                        var timeout_id = setTimeout(function () {
                            clearInterval(timeout_id);

                            dialogWaiting.triggerMethod('dialog:close');

                            var refreshSchemaSchema = false;
                            if (self.model.attributes.SchemaModel != self.oldModel.attributes.SchemaModel) {
                                refreshSchemaSchema = true;
                            }

                            self.oldModel = self.model.clone();

                            var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: self.model.attributes.id })
                            var render = new View_Render({ collection: customEvent, app: self.app });
                            self.app.renderRegion.show(render);
                            var renderFinishCallback = function (options) {
                                self.reRender();
                                self.customViewEvent_setActive();

                                //Refresh SchemaSchema again because if user select none then the server delete the SchemaData and SchemaSchema
                                if (refreshSchemaSchema) {
                                    self.app.entities.collections.customSchemaSchema.fetch({
                                        success: function (mode, response) {
                                        },
                                        error: function (mode, response) {
                                        }
                                    })
                                }

                                if (callback) {
                                    callback();
                                }
                            }
                            render.doIt(renderFinishCallback, publish, 'EVENTINFO_REFRESH', false);
                        }, 1000);
                    }
                });
            }
        });
    },
    btnResetClick: function () {

    },
    btnWebpageEventClick: function () {
        var self = this;
        var callback = function () {
            var webpage = parseInt(self.ui.customEventEditItem_selectWebpage.val());
            if (webpage > 0) {
                var friendlyUrl = self.model.attributes.id;
                if (self.model.attributes.FriendlyUrl && self.model.attributes.FriendlyUrl.length > 0) {
                    friendlyUrl = self.model.attributes.FriendlyUrl;
                }

                var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;// + '#' + resources.X100138;
                //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);
                window.open(webpageEvent);

            } else {
                var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                dialogBoxModelNoWebpage.attributes.Text = resources.X100411;
                dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                var callbackNoWebpage = function (options) {
                    self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                }
                self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                self.app.dialogRegion.show(dialogBoxNoWebpage);
            }
        }

        this.btnSubmitClick(null, callback, false);
    },
    btnWebpagePreviewClick: function () {
        var self = this;
        var callback = function () {
            var webpage = parseInt(self.ui.customEventEditItem_selectWebpage.val());
            if (webpage > 0) {
                var friendlyUrl = self.model.attributes.id;
                if (self.model.attributes.FriendlyUrl && self.model.attributes.FriendlyUrl.length > 0) {
                    friendlyUrl = self.model.attributes.FriendlyUrl;
                }

                var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

                var dialogPreviewWebpageModel = new Entities_Empty.Model();
                dialogPreviewWebpageModel.attributes.Title = resources.X100276;
                dialogPreviewWebpageModel.attributes.Iframe = webpageEvent;
                dialogPreviewWebpageModel.attributes.Button = resources.X100279;

                var dialogPreviewWebpage = new View_DialogPreviewWebpage({ model: dialogPreviewWebpageModel, parent: this });
                var callback_dialogPreviewWebpage = function (options) {
                    self.stopListening(dialogPreviewWebpage, "dialog:okclicked", callback);
                }
                self.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);
                self.app.dialogRegion.show(dialogPreviewWebpage);

            } else {
                var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                dialogBoxModelNoWebpage.attributes.Text = resources.X100481;
                dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                var callbackNoWebpage = function (options) {
                    self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                }
                self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                self.app.dialogRegion.show(dialogBoxNoWebpage);
            }
        }

        this.btnSubmitClick(null, callback, false);
    },
    btnWebpageInfoClick: function () {
        var self = this;
        var callback = function () {

            if (self.app.entities.models.configSubscription[0].attributes.EnableInfo == false) {
                var dialogBoxModel = new Entities_Empty.Model();
                dialogBoxModel.attributes.Title = resources.X100229;
                dialogBoxModel.attributes.Text = resources.X100094;
                dialogBoxModel.attributes.Button = resources.X100279;

                var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                var callback2 = function (options) {
                    self.stopListening(dialogBox, "dialog:okclicked", callback2);
                }
                self.listenTo(dialogBox, "dialog:okclicked", callback2);
                self.app.dialogRegion.show(dialogBox);
            } else {
                var webpage = parseInt(self.ui.customEventEditItem_selectInfopage.val());
                if (webpage > 0) {
                    var friendlyUrl = self.model.attributes.id;
                    if (self.model.attributes.FriendlyUrl && self.model.attributes.FriendlyUrl.length > 0) {
                        friendlyUrl = self.model.attributes.FriendlyUrl;
                    }

                    var webpageInfo = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100454.toLowerCase();
                    //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);
                    window.open(webpageInfo);
                } else {
                    var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                    dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                    dialogBoxModelNoWebpage.attributes.Text = resources.X100412;
                    dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                    var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                    var callbackNoWebpage = function (options) {
                        self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    }
                    self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    self.app.dialogRegion.show(dialogBoxNoWebpage);
                }
            }
        }
        this.btnSubmitClick(null, callback, false);
    },
    btnWebpagePosterClick: function () {
        var self = this;
        var callback = function () {
            if (self.app.entities.models.configSubscription[0].attributes.EnablePoster == false) {
                var dialogBoxModel = new Entities_Empty.Model();
                dialogBoxModel.attributes.Title = resources.X100229;
                dialogBoxModel.attributes.Text = resources.X100121;
                dialogBoxModel.attributes.Button = resources.X100279;

                var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                var callback2 = function (options) {
                    self.stopListening(dialogBox, "dialog:okclicked", callback2);
                }
                self.listenTo(dialogBox, "dialog:okclicked", callback2);
                self.app.dialogRegion.show(dialogBox);
            } else {
                var webpage = parseInt(self.ui.customEventEditItem_selectWebpage.val());
                if (webpage > 0) {
                    var friendlyUrl = self.model.attributes.id;
                    if (self.model.attributes.FriendlyUrl && self.model.attributes.FriendlyUrl.length > 0) {
                        friendlyUrl = self.model.attributes.FriendlyUrl;
                    }

                    var webpagePoster = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100141.toLowerCase();
                    //window.open(webpagePoster , '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);
                    window.open(webpagePoster);
                } else {
                    var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                    dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                    dialogBoxModelNoWebpage.attributes.Text = resources.X100413;
                    dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                    var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                    var callbackNoWebpage = function (options) {
                        self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    }
                    self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    self.app.dialogRegion.show(dialogBoxNoWebpage);
                }
            }
        }
        this.btnSubmitClick(null, callback, false);
    },
    btnWebpageSchemaBigscreenClick: function () {
        var self = this;
        var callback = function () {
            if (self.app.entities.models.configSubscription[0].attributes.EnableSchema == false) {
                var dialogBoxModel = new Entities_Empty.Model();
                dialogBoxModel.attributes.Title = resources.X100229;
                dialogBoxModel.attributes.Text = resources.X100420;
                dialogBoxModel.attributes.Button = resources.X100279;

                var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                var callback2 = function (options) {
                    self.stopListening(dialogBox, "dialog:okclicked", callback2);
                }
                self.listenTo(dialogBox, "dialog:okclicked", callback2);
                self.app.dialogRegion.show(dialogBox);
            } else {
                var schemaModel = parseInt(self.ui.customEventEditItem_selectSchemaModel.val());
                if (schemaModel > 0) {
                    var friendlyUrl = self.model.attributes.id;
                    if (self.model.attributes.FriendlyUrl && self.model.attributes.FriendlyUrl.length > 0) {
                        friendlyUrl = self.model.attributes.FriendlyUrl;
                    }

                    var webpageSchemaBigscreen = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100419.toLowerCase();
                    //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);
                    window.open(webpageSchemaBigscreen);
                } else {
                    var dialogBoxModelNoWebpage = new Entities_Empty.Model();
                    dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
                    dialogBoxModelNoWebpage.attributes.Text = resources.X100422;
                    dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

                    var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
                    var callbackNoWebpage = function (options) {
                        self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    }
                    self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
                    self.app.dialogRegion.show(dialogBoxNoWebpage);
                }
            }
        }
        this.btnSubmitClick(null, callback, false);
    },
    selectUseDateChange: function () {
        var useDate = parseInt(this.ui.CustomEventEditItem_selectUseDate.value);
        var showDatapicker = false;

        switch (useDate) {
            case 0: showDatapicker = false; break;
            case 1: showDatapicker = true; break;
        }

        switch (useDate) {
            case true: this.ui.customEventEditItem_divDatepicker.show(); break;
            case false: this.ui.customEventEditItem_divDatepicker.css('display', 'none'); break;
        }
    },
    radioUseDate02Change: function () {

    },
    setupTemplate: function () {
        if (this.app.entities.collections.customEventTemplateSubject.length > 0) {
            this.ui.customEventEditItem_divTemplate.show();

            this.addTemplateSubject();
            this.addTemplateName();
            this.setupTemplateBtn();
        }
        else {
            this.ui.customEventEditItem_divTemplate.css('display', 'none');
        }


    },
    setupAd: function () {
        if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            this.ui.customEventEditItem_div_Ad.show();

            this.ui.customEventEditItem_selectAdEnable.val(this.model.attributes.AdEnable);

            this.setupAdImageId();
        }
        else {
            this.setupAdImageId();
            this.ui.customEventEditItem_div_Ad.css('display', 'none');
        }
    },
    setupAdImageId: function () {
        var self = this;
        var model = this.app.entities.collections.customImage.fullCollection.where({ SizeType: 5 });

        if (self.model.attributes.AdImageId == -1) {
            self.ui.customEventEditItem_selectAdImageId.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        }
        else {
            self.ui.customEventEditItem_selectAdImageId.append('<option value="' + -1 + '">' + resources.X100292 + '</option>');
        }

        model.forEach(function (entry) {
            if (self.model.attributes.AdImageId == entry.attributes.id) {
                self.ui.customEventEditItem_selectAdImageId.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            } else {
                self.ui.customEventEditItem_selectAdImageId.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });

        var model = this.app.entities.collections.customImage.fullCollection.where({ id: this.model.attributes.AdImageId });

        if (model.length > 0) {
            this.ui.customEventEditItem_imageAdImageId.attr('src', model[0].attributes.ImageUrl);
        }

        var status = '';

        switch (this.model.attributes.AdStatus) {
            case 0: status = resources.X100961; break;
            case 1: status = resources.X100962; break;
        }

        this.ui.customEventEditItem_labelAdStatus.text(status);
    },
    selectAdImageIdChange: function (event) {
        event.preventDefault();

        this.model.attributes.AdImageId = parseInt(this.ui.customEventEditItem_selectAdImageId.val());

        var model = this.app.entities.collections.customImage.fullCollection.where({ id: this.model.attributes.AdImageId });

        if (model.length > 0) {
            this.ui.customEventEditItem_imageAdImageId.attr('src', model[0].attributes.ImageUrl);
        }
    },
    selectTemplateSubjectChange: function (event) {
        event.preventDefault();

        this.setupTemplateBtn();

        this.selectedSubject = this.ui.customEventEditItem_selectTemplateSubject.val();

        this.addTemplateName();
    },
    selectPubliclyChange: function (event) {
        //event.preventDefault();
        var self = this;

        var publicly = this.model.attributes.Publicly;

        this.ui.customEventEditItem_selectPublicly.is(':checked') ? publicly = 1 : publicly = 0;

        //if (self.ui.customEventEditItem_selectPublicly.val() == '1') {
        if (publicly == 1) {
            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = resources.X100555;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;

            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                self.ui.customEventEditItem_selectPublicly.attr('checked', true);
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                //self.ui.customEventEditItem_selectPublicly.val('0');
                self.ui.customEventEditItem_selectPublicly.attr('checked', false);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);
        }
    },
    addTemplateSubject: function () {
        var element = this.ui.customEventEditItem_selectTemplateSubject;
        element.empty();
        var self = this;

        element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');

        this.app.entities.collections.customEventTemplateSubject.forEach(function (entry) {
            /*if (self.selectedSubject == entry.attributes.Subject) {
                element.append('<option value="' + entry.attributes.Subject + '" selected="selected">' + entry.attributes.Subject + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.Subject + '">' + entry.attributes.Subject + '</option>');
            }*/
            element.append('<option value="' + entry.attributes.Subject + '">' + entry.attributes.Subject + '</option>');
        });
    },
    addTemplateName: function () {
        var subject = this.ui.customEventEditItem_selectTemplateSubject.val();

        var self = this;

        var element = this.ui.customEventEditItem_selectTemplateName;
        element.empty();

        if (subject == '-1') {
            element.append('<option value="' + -1 + '" selected="selected">' + resources.X100292 + '</option>');
        } else {
            this.app.entities.collections.customEventTemplate.fetch({
                silent: true,
                CultureName: config.cultureName(),
                subject: subject,
                success: function () {
                    self.app.entities.collections.customEventTemplate.forEach(function (entry) {
                        element.append('<option value="' + entry.attributes.Event + '">' + entry.attributes.Name + '</option>');
                    });
                },
                error: function () {
                }
            });
        }
    },
    setupTemplateBtn: function () {
        var subject = this.ui.customEventEditItem_selectTemplateSubject.val();
        if (subject == '-1') {
            this.ui.customEventEditItem_btnTemplateCopy.prop('disabled', true);
        } else {
            this.ui.customEventEditItem_btnTemplateCopy.prop('disabled', false);
        }
    },
    btnTemplateClick: function (event) {
        event.preventDefault();

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100229;
        dialog2ButtonModel.attributes.Text = resources.X100116;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

        var callback01 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            self.model.attributes.TemplateUpdate = true;
            self.model.attributes.Template = parseInt(self.ui.customEventEditItem_selectTemplateName.val());

            self.model.save(self.model.attributes, {
                silent: true,
                wait: true,
                updateVersion: false,
                success: function () {
                    //Reload Webpage and Schema
                    self.app.entities.collections.customWebpage.fetch({
                        DisplayType: 0,
                        silent: true,
                        wait: true,
                        remove: true,
                        success: function (mode, response) {
                            self.app.entities.collections.customInfopage.fetch({
                                DisplayType: 1,
                                silent: true,
                                wait: true,
                                remove: true,
                                success: function (mode, response) {

                                    self.app.entities.collections.customWebpageItem.fetch({
                                        success: function (mode, response) {

                                            self.app.entities.collections.customSchemaModel.fetch({
                                                silent: true,
                                                wait: true,
                                                remove: true,
                                                success: function (mode, response) {
                                                    self.app.entities.collections.customFormModel.fetch({
                                                        silent: true,
                                                        wait: true,
                                                        remove: true,
                                                        success: function (mode, response) {
                                                            self.model.attributes.TemplateUpdate = false;
                                                            self.model.attributes.Template = -1;
                                                            self.reRender();

                                                            //Force every thing
                                                            self.btnSubmitClick(null, null, true);
                                                        },
                                                        error: function (mode, response) {
                                                        }
                                                    });
                                                },
                                                error: function (mode, response) {
                                                }
                                            });
                                        }
                                    })
                                },
                                error: function (mode, response) {
                                }
                            });
                        }
                    })
                },
                error: function () {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnCopyLink: function (event) {
        event.preventDefault();

        document.querySelector("#customEventEditItem_inputShowLink").select();
        document.execCommand('copy')
    },
    btnShowLink: function (event) {
        event.preventDefault();

        var self = this;

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ShowLink' });
        var enable = customSetting[0].attributes.Value == '1' ? '0' : '1';

        customSetting[0].attributes.Value = enable;

        customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                self.setupUrlLink();
            }
        });
    },
    _parseGeocodeResult: function (geocodeResult) {
        this._addressParts = {
            street_number: null, route: null, locality: null, sublocality: null,
            administrative_area_level_3: null, administrative_area_level_2: null,
            administrative_area_level_1: null, country: null, postal_code: null, type: null
        };

        this._parseLatAndLng = function (location) {
            var longitude, latitude;

            if (typeof (location.lat) === 'function') {
                latitude = location.lat();
                longitude = location.lng();
            } else {
                latitude = location.lat;
                longitude = location.lng;
            }

            return { lat: latitude, lng: longitude };
        };

        this._findInfo = function (result, type) {
            for (var i = 0; i < result.address_components.length; i++) {
                var component = result.address_components[i];
                if (component.types.indexOf(type) != -1) {
                    return component.long_name;
                }
            }
            return false;
        };

        var parsed = this._parseLatAndLng(geocodeResult.geometry.location);

        for (var addressPart in this._addressParts) {
            parsed[addressPart] = this._findInfo(geocodeResult, addressPart);
        }

        parsed.type = geocodeResult.types[0];

        return parsed;
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');
    },
    QrCode_design_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();


        this.app.router.navigate("#customQRCodeEditItem?id=" + this.model.attributes.QRCode, { trigger: true });
        this.app.router.customQRCodeEditItem(this.model.attributes.QRCode, this.model.attributes.id, scroll);
    },
    SchemaModel_design_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();


        this.app.router.navigate("#customSchemaModelEditItem?id=" + this.model.attributes.SchemaModel);
        this.app.router.customSchemaModelEditItem(this.model.attributes.SchemaModel, this.model.attributes.id, scroll);
    },
    SchemaModel_data_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();

        self.app.router.navigate("#customSchemaData?id=" + this.model.attributes.id);
        self.app.router.customSchemaData(this.model.attributes.id, scroll);
    },
    SchemaModel_setting_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();


        this.app.router.navigate("#customSchemaSetting?id=" + this.model.attributes.id);
        this.app.router.customSchemaSetting(this.model.attributes.id, scroll);
    },
    SchemaModel_livedata_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();

        self.app.router.navigate("#customEventLive?id=" + this.model.attributes.id);
        self.app.router.customEventLive(this.model.attributes.id, scroll);
    },
    FormModel_design_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();

        this.app.router.navigate("#customFormModelEditItem?id=" + this.model.attributes.FormModel);
        this.app.router.customFormModelEditItem(this.model.attributes.FormModel, this.model.attributes.id, scroll);
    },
    FormModel_data_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();


        self.app.router.navigate("#customFormData?id=" + this.model.attributes.id);
        self.app.router.customFormData(this.model.attributes.id, scroll);
    },
    FormModel_setting_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();

        this.app.router.navigate("#customFormSetting?id=" + this.model.attributes.id);
        this.app.router.customFormSetting(this.model.attributes.id, scroll);
    },
    Ad_setting_click() {
        //var scroll = this.ui.customEventEditItem_top.scrollTop();
        //$(window).scrollTop();
        var scroll = $(document).scrollTop();

        this.app.router.navigate("#customAdSetting?id=" + this.model.attributes.id);
        this.app.router.customAdSetting(this.model.attributes.id, scroll);
    },
    customEventEditItem_navigate_aEventIPad_click() {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpageIPad = new View_DialogPreviewWebpageZoomIPad({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpageIPad = function (options) {
            this.stopListening(dialogPreviewWebpageIPad, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpageIPad, "dialog:okclicked", callback_dialogPreviewWebpageIPad);

        this.app.dialogRegion.show(dialogPreviewWebpageIPad);

    },
    customEventEditItem_navigate_aEvent_click() {
        $('.modal-backdrop').remove();
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false,
            zoom: true
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpage = new View_DialogPreviewWebpageZoom({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpage = function (options) {
            this.stopListening(dialogPreviewWebpage, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);

        this.app.dialogRegion.show(dialogPreviewWebpage);
    },
    customEventEditItem_navigate_aEventBrowser_click() {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);
    },
    customEventEditItem_navigate_spanEventDesign_click() {
        var scroll = $(document).scrollTop();

        this.app.router.navigate("#customWebpageEditItem?id=" + this.model.attributes.Webpage);
        this.app.router.customWebpageEditItem(this.model.attributes.Webpage, this.model.attributes.id, scroll, this.options.module_selected);
    },
    customEventEditItem_navigate_spanEventSchemaScreen_click() {
        var moduleType = 0;

        switch (this.model.attributes.Module_SchemaScreen_Direction_H) {
            case true: moduleType = 1; break;
            case false: moduleType = 2; break;
        }

        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/infopage/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false,
            eventId: this.model.attributes.id,
            moduleType: moduleType
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);  
    },
    customEventEditItem_navigate_spanEventSchemaScreenDesign_click() {
        var scroll = $(document).scrollTop();

        var ModuleType = 0;

        switch (this.model.attributes.Module_SchemaScreen_Direction_H) {
            case true: ModuleType = 1; break;
            case false: ModuleType = 2; break;
        }

        var model = this.app.entities.collections.customModule.where({ EventId: this.model.attributes.id, ModuleType: ModuleType })[0];

        this.app.router.navigate("#customInfopageEditItem?id=" + model.attributes.id);
        this.app.router.customInfopageEditItem(model.attributes.id, this.model.attributes.id);
    },
    customEventEditItem_navigate_spanEventInfoScreen_click() {
        var moduleType = 0;

        switch (this.model.attributes.Module_InfoScreen_Direction_H) {
            case true: moduleType = 3; break;
            case false: moduleType = 4; break;
        }

        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/infopage/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false,
            eventId: this.model.attributes.id,
            moduleType: moduleType
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);
    },
    customEventEditItem_navigate_spanEventInfoScreenDesign_click() {
        var scroll = $(document).scrollTop();

        var ModuleType = 0;

        switch (this.model.attributes.Module_InfoScreen_Direction_H) {
            case true: ModuleType = 3; break;
            case false: ModuleType = 4; break;
        }

        var model = this.app.entities.collections.customModule.where({ EventId: this.model.attributes.id, ModuleType: ModuleType })[0];

        this.app.router.navigate("#customInfopageEditItem?id=" + model.attributes.id);
        this.app.router.customInfopageEditItem(model.attributes.id, this.model.attributes.id);
    },
    customEventEditItem_navigate_spanEventPoster_click() {
        var moduleType = 0;

        switch (this.model.attributes.Module_SchemaScreen_Direction_H) {
            case true: moduleType = 5; break;
            case false: moduleType = 6; break;
        }

        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/infopage/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false,
            eventId: this.model.attributes.id,
            moduleType: moduleType
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);
    },
    customEventEditItem_navigate_spanEventPosterDesign_click() {
        var scroll = $(document).scrollTop();

        var ModuleType = 0;

        switch (this.model.attributes.Module_Poster_Direction_H) {
            case true: ModuleType = 5; break;
            case false: ModuleType = 6; break;
        }

        var model = this.app.entities.collections.customModule.where({ EventId: this.model.attributes.id, ModuleType: ModuleType })[0];

        this.app.router.navigate("#customInfopageEditItem?id=" + model.attributes.id);
        this.app.router.customInfopageEditItem(model.attributes.id, this.model.attributes.id);
    },
    customEventEditItem_navigate_spanEventCityInfo_click() {
        var moduleType = 0;

        switch (this.model.attributes.Module_SchemaScreen_Direction_H) {
            case true: moduleType = 7; break;
            case false: moduleType = 8; break;
        }

        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/infopage/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false,
            eventId: this.model.attributes.id,
            moduleType: moduleType
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);
    },
    customEventEditItem_navigate_spanEventCityInfoDesign_click() {
        var scroll = $(document).scrollTop();

        var ModuleType = 0;

        switch (this.model.attributes.Module_CityInfo_Direction_H) {
            case true: ModuleType = 5; break;
            case false: ModuleType = 6; break;
        }

        var model = this.app.entities.collections.customModule.where({ EventId: this.model.attributes.id, ModuleType: ModuleType })[0];

        this.app.router.navigate("#customInfopageEditItem?id=" + model.attributes.id);
        this.app.router.customInfopageEditItem(model.attributes.id, this.model.attributes.id);
    },
    customEventEditItem_navigate_spanEventPushSend_click() {
        this.app.router.navigate("#customEventPush?id=" + this.model.attributes.id);
        this.app.router.customEventPush(this.model.attributes.id);
    },
    customEventEditItem_navigate_spanEventSchemaScreen_Direction_V_click() {
        this.model.attributes.Module_SchemaScreen_Direction_V = true;
        this.model.attributes.Module_SchemaScreen_Direction_H = false;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventSchemaScreen_Direction_H_click() {
        this.model.attributes.Module_SchemaScreen_Direction_V = false;
        this.model.attributes.Module_SchemaScreen_Direction_H = true;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventInfoScreen_Direction_V_click() {
        this.model.attributes.Module_InfoScreen_Direction_V = true;
        this.model.attributes.Module_InfoScreen_Direction_H = false;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventInfoScreen_Direction_H_click() {
        this.model.attributes.Module_InfoScreen_Direction_V = false;
        this.model.attributes.Module_InfoScreen_Direction_H = true;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventPoster_Direction_V_click() {
        this.model.attributes.Module_Poster_Direction_V = true;
        this.model.attributes.Module_Poster_Direction_H = false;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventPoster_Direction_H_click() {
        this.model.attributes.Module_Poster_Direction_V = false;
        this.model.attributes.Module_Poster_Direction_H = true;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventCityInfo_Direction_V_click() {
        this.model.attributes.Module_CityInfo_Direction_V = true;
        this.model.attributes.Module_CityInfo_Direction_H = false;

        this.updateHVcontrols();
        this.setModel();
    },
    customEventEditItem_navigate_spanEventCityInfo_Direction_H_click() {
        this.model.attributes.Module_CityInfo_Direction_V = false;
        this.model.attributes.Module_CityInfo_Direction_H = true;

        this.updateHVcontrols();
        this.setModel();
    }
});
