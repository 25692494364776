﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customAdBigImageList from './tpl/customAdBigImageList.html';
import View_CustomAdBigImageListItem from './customAdBigImageListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customAdBigImageList',
    childViewContainer: '#customAdBigImageList',
    childView: View_CustomAdBigImageListItem,
    getTemplate: function () {
        return tpl_customAdBigImageList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
