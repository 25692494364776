var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetDataList_empty"> <div id="customProductSheetDataList_ProductSheetData"></div> <div style="height:3px"></div> <button type="button" class="btn btn-primary btnProductSheetData" id="customProductSheetDataList_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <br> <div id="customProductSheetTest"></div> </div>';
}
return __p;
};
