var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="empty"> <div class="item"> <div class="card text-white"> <img class="card-img" src="../images/carousel/banner_4.jpg" alt="Card image"> <div class="card-img-overlay d-flex"> <div class="mt-auto text-center w-100"> <h3>'+
((__t=( model.Text ))==null?'':__t)+
'</h3> <h6 class="card-text mb-4 font-weight-normal">'+
((__t=( model.Description ))==null?'':__t)+
'</h6> </div> </div> </div> </div> </div>';
}
return __p;
};
