﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        var m = Backbone.sync(method, model, options);
        return m;
    },
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customTmp/route01/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    }
});

