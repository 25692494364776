'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button2 from '../../../lib/views/dialog2Button2/dialog2Button2';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_Setting from './setting';

import tpl_settingListListItem from './tpl/settingListListItem.html';

export default Marionette.ItemView.extend({
    //className: 'col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2',
    attributes: function () {
        return {
            style: "display:inline-block;"
        };
    },
    getTemplate: function () {
        return tpl_settingListListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
        //this.model.bind('destroy', this.remove, this);
    },
    ui: {
        'settingListListItem_btnSubmit': '#settingListListItem_btnSubmit',
        //'settingListListItem_image': '#settingListListItem_image',
        'settingListListItem_div': '#settingListListItem_div',
        'settingListListItem_iSetting': '#settingListListItem_iSetting',
        'settingListListItem_iDelete': '#settingListListItem_iDelete',
        'settingListListItem_div_inner': '#settingListListItem_div_inner',
        'settingListListItem_div_html': '#settingListListItem_div_html'

    },
    events: {
        'click @ui.settingListListItem_div': 'btnSubmitClick',
        'click @ui.settingListListItem_iSetting': 'iSettingClick',
        'click @ui.settingListListItem_iDelete': 'iDeleteClick'
    },
    onRender: function () {
        var self = this;

        if (this.model.attributes.Selected) {
            this.ui.settingListListItem_div_inner.css('border-style', 'solid');
            this.ui.settingListListItem_div_inner.css('border-color', 'black');
        }
        else {
            this.ui.settingListListItem_div_inner.css('border-style', 'solid');
            this.ui.settingListListItem_div_inner.css('border-color', 'white');
        }


        //if (this.model.attributes.Selected) {
        //    this.ui.settingListListItem_btnSubmit.css('border-style', 'solid');
        //    this.ui.settingListListItem_btnSubmit.css('border-color', 'black');
        //}
        //else {
        //    //this.ui.settingListListItem_btnSubmit.css('border-style', 'none');
        //    this.ui.settingListListItem_btnSubmit.css('border-style', 'solid');
        //    this.ui.settingListListItem_btnSubmit.css('border-color', 'white');
        //}

        /*var borderStyle = self.app.entities.collections.ModuleBorderStyle.findIndex(function (post, index) {
            if (post.Value == self.model.attributes.BorderStyle)
                return true;
        });


        var borderStyle = self.app.entities.collections.ModuleBorderStyle.forEach(function (entry) {
            if (entry.Value == self.model.attributes.BorderStyle)
                return true;
        });*/

        var style = self.app.entities.collections.ModuleBorderStyle.where({ Value: self.model.attributes.BorderStyle })[0].attributes.Style;

        if (this.model.attributes.Border) {
            style.forEach(styleValue => self.ui.settingListListItem_div.css(styleValue.Name, styleValue.Value));
            self.ui.settingListListItem_div.css('border-color', this.model.attributes.BorderColor);
            self.ui.settingListListItem_div.css('border-width', this.model.attributes.BorderSize);
        }
        else {
            self.ui.settingListListItem_div.css('border-style', 'none');
        }

        //self.ui.settingListListItem_div.css('margin-top', this.model.attributes.MarginTop + 'px');
        //self.ui.settingListListItem_div.css('margin-bottom', this.model.attributes.MarginBottom + 'px');
        //self.ui.settingListListItem_div.css('margin-left', this.model.attributes.MarginLeft + 'px');
        //self.ui.settingListListItem_div.css('margin-right', this.model.attributes.MarginRight + 'px');


        switch (this.model.attributes.BackGroundType) {
            case "0": self.ui.settingListListItem_div.css('background-color', this.model.attributes.BackGroundColorApp); break;
            case "1": self.ui.settingListListItem_div.css('background-color', this.model.attributes.BackGroundColor); break;
        }

        switch (this.model.attributes.Standard) {
            case 0:
                self.ui.settingListListItem_iDelete.css('display', 'none');
                self.ui.settingListListItem_iSetting.css('display', 'none');
                break;
            case 1:
                self.ui.settingListListItem_iSetting.css('display', 'none');
                break;
            case 2:
                break;
        }

        self.ui.settingListListItem_div.css('opacity', this.model.attributes.BackGroundColorOpacity / 100);

        /*var backGroundImagePosition = self.app.entities.collections.ModuleBackGroundImagePosition.findIndex(function (post, index) {
            if (post.Value == self.model.attributes.BackGroundImagePosition)
                return true;
        });
        var style = self.app.entities.collections.ModuleBackGroundImagePosition[backGroundImagePosition].Style;
        */
        var style = self.app.entities.collections.ModuleBackGroundImagePosition.where({ Value: self.model.attributes.BackGroundImagePosition })[0].attributes.Style;
        style.forEach(styleValue => self.ui.settingListListItem_div.css(styleValue.Name, styleValue.Value));

        switch (this.model.attributes.BackGroundType) {
            case "0": self.ui.settingListListItem_div.css('background-image', 'none'); break;
            case "1": self.ui.settingListListItem_div.css('background-image', 'none'); break;
            case "2": self.ui.settingListListItem_div.css('background-image', String.format("url('{0}'", self.model.attributes.BackGroundImage));
                break;
        }
    },
    btnSubmitClick: function (event) {
        var self = this;
        //this.model.collection.pageableCollection.fullCollection.each(function (tmpModel) {
        //    if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
        //        tmpModel.set({ Selected: false });
        //    }
        //});

        this.model.collection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == 1) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: 0 });
            }
        });

        this.model.set({ Selected: 1 });
        this.model.collection.trigger('change');
    },
    iSettingClick: function (event) {
        var self = this;

        var setting = new View_Setting({ app: this.app, model: this.model, parent: self });

        var callback01 = function () {
            self.stopListening(setting, "dialog:btn01clicked", callback01);
            self.stopListening(setting, "dialog:btn02clicked", callback02);
            self.model.trigger('change');
        }
        this.listenTo(setting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(setting, "dialog:btn01clicked", callback01);
            self.stopListening(setting, "dialog:btn02clicked", callback02);
        }
        this.listenTo(setting, "dialog:btn02clicked", callback02);

        self.app.dialogRegion2.show(setting);
    },
    iDeleteClick: function (event) {
        var self = this;

        event.preventDefault();

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X101344;
        dialog2ButtonModel.attributes.Text = resources.X101367;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button2({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            this.model.collection.remove(this.model);
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion2.show(dialog2Button);
    }
});