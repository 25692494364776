﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import tpl_htmlSlideListListItemProperties from './tpl/htmlSlideListListItemProperties.html';

export default Marionette.LayoutView.extend({
    dialogRegion2: '#htmlSlideListListItemProperties_modal',
    errors: [],
    getTemplate: function () {
        return tpl_htmlSlideListListItemProperties({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    regions: {
    },
    onBeforeShow: function () {
    },
    ui: {
        'htmlSlideListListItemProperties_group_Duration': '#htmlSlideListListItemProperties_group_Duration',
        'htmlSlideListListItemProperties_input_Duration': '#htmlSlideListListItemProperties_input_Duration',
        'htmlSlideListListItemProperties_label_Duration': '#htmlSlideListListItemProperties_label_Duration',
        'htmlSlideListListItemProperties_dropDown_AnimationGroup': '#htmlSlideListListItemProperties_dropDown_AnimationGroup',
        'htmlSlideListListItemProperties_dropDown_Animation': '#htmlSlideListListItemProperties_dropDown_Animation',

        'htmlSlideListListItemProperties_btn01': '#htmlSlideListListItemProperties_btn01',
        'htmlSlideListListItemProperties_btn02': '#htmlSlideListListItemProperties_btn02'
    },
    events: {
        'change @ui.htmlSlideListListItemProperties_dropDown_AnimationGroup': 'selectionChangeAnimationGroup',
        'click @ui.htmlSlideListListItemProperties_btn01': 'onBtn01',
        'click @ui.htmlSlideListListItemProperties_btn02': 'onBtn02'
    },
    onRender: function () {
        var i = 0;
    },
    onAttach() {
        var self = this;

        this.ui.htmlSlideListListItemProperties_input_Duration.durationPicker({
            showSeconds: true,
            onChanged: function (newVal) {
                self.ui.htmlSlideListListItemProperties_label_Duration.text(newVal);
                self.model.set({
                    Duration: newVal
                });
            }
        });

        this.setupAnimationGroup();
        this.setupAnimation();

        $('#htmlSlideListListItemProperties_group_Duration').find('.input-sm').width(90);
        //this.ui.htmlSlideListListItemProperties_group_Duration.find('.input-sm').css('width', '90px;');
    },
    selectionChangeAnimationGroup: function () {
        this.model.attributes.AnimationGroup = this.ui.htmlSlideListListItemProperties_dropDown_AnimationGroup.val();
        this.setupAnimation();
    },
    setupAnimationGroup: function () {
        var self = this;

        this.ui.htmlSlideListListItemProperties_dropDown_AnimationGroup.empty();

        var selected = '';

        self.app.entities.collections.HtmlSlideAnimationGroup.forEach(function (entry) {
            if (entry.attributes.Enable) {
                selected = '';
                if (self.model.attributes.AnimationGroup == entry.attributes.Value) {
                    selected = 'selected = "selected"';
                }
                var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
                self.ui.htmlSlideListListItemProperties_dropDown_AnimationGroup.append(options);
            }
        });
    },
    setupAnimation: function () {
        var self = this;

        var found = false;

        this.ui.htmlSlideListListItemProperties_dropDown_Animation.empty();

        var selected = '';

        self.app.entities.collections.HtmlSlideAnimation.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Animation == entry.attributes.Value) {
                selected = 'selected = "selected"';
                found = true;
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            if (entry.attributes.Group == self.model.attributes.AnimationGroup) {
                self.ui.htmlSlideListListItemProperties_dropDown_Animation.append(options);
            }
        });

        if (!found) {
            self.ui.htmlSlideListListItemProperties_dropDown_Animation.selectedIndex = 0;
        }

    },
    setupDuration: function () {
        var self = this;

        this.ui.htmlSlideListListItemProperties_dropDown_Duration.empty();

        var selected = '';

        self.app.entities.collections.PictureSlideDuration.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Duration == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.htmlSlideListListItemProperties_dropDown_Duration.append(options);
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    setModel: function () {
        this.model.set({
            AnimationGroup: this.ui.htmlSlideListListItemProperties_dropDown_AnimationGroup.val(),
            Animation: this.ui.htmlSlideListListItemProperties_dropDown_Animation.val()
        });
    },
    setupCssStyle: function () {
    }
});