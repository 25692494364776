var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentImportListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="padding-bottom: 5px !important"> <div style="font-size:14px;margin-top:5px" class="profileImage_small" id="customDepartmentImportListItem_profileImage"></div> <img id="customDepartmentImportListItem_imageUrl" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" class="img-lg rounded-circle mb-2" alt="profile image" style="width:50px;height:50px;margin-bottom: 0px !important;margin-top:5px"> <div class="ml-3 mt-2"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small class="text-muted mb-0"><i class="mdi mdi-file-download mr-1"></i>'+
((__t=( model.File ))==null?'':__t)+
'</small> <br> </div> <div class="ml-auto"> <i id="customDepartmentImportListItem_btnDelete" style="color: #6c7293 !important" class="far fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
