﻿'use strict'

import config from '../../../common/config';
import language from '../../../common/language';

import BootstrapDatepicker from 'bootstrap-datepicker';
import language_da from '../../../../bower_components/bootstrap-datepicker/js/locales/bootstrap-datepicker.da';

require('typeahead-addresspicker');

import tpl_stringTemplate from './tplRender/stringTemplate.html';
import tpl_stringAreaTemplate from './tplRender/stringAreaTemplate.html';
import tpl_addressTemplate from './tplRender/addressTemplate.html';
import tpl_dateTemplate from './tplRender/dateTemplate.html';
import tpl_multipleChoiseTemplate from './tplRender/multipleChoiseTemplate.html';
import tpl_selectTemplate from './tplRender/selectTemplate.html';
import tpl_submitTemplate from './tplRender/submitTemplate.html';

export default (function () {
    var API = {
        buildElement : function(tmpModel) {
            var renderedTemplate = '';

            switch(tmpModel.FieldType) {
                case 1 : renderedTemplate = this.buildInputString(tmpModel); break;
                case 2 : renderedTemplate = this.buildInputStringArea(tmpModel); break;
                case 3 : renderedTemplate = this.buildInputAddress(tmpModel); break;
                case 4 : renderedTemplate = this.buildInputDate(tmpModel); break;
                case 5 : renderedTemplate = this.buildInputMultipleChoise(tmpModel); break;
                case 6 : renderedTemplate = this.buildInputSelect(tmpModel); break;
                case 100 : renderedTemplate = this.buildSubmit(tmpModel); break;
            }

            return renderedTemplate;
        },
        buildInputStringArea : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_stringAreaTemplate({ model: tmpModel }); 

            return renderedTemplate;
        },
        buildInputString : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_stringTemplate({ model: tmpModel }); 

            return renderedTemplate;
        },
        buildInputAddress : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_addressTemplate({ model: tmpModel }); 

            return renderedTemplate;
        },
        buildInputDate : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_dateTemplate({ model: tmpModel }); 

            return renderedTemplate;
        },
        
        buildInputMultipleChoise : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_multipleChoiseTemplate({ model: tmpModel }); 

            var a = tmpModel.FieldValue.split("\n");
            
            var str_radio = '';
            var selected_radio = 0;
            for (var i = 0; i < a.length; i++) {
                var s = a[i];
                s = s.replace(/^\s+|\s+$/g, '');

                if (i == 0 && s.substring(0,7) == '#EMPTY#'){
                    selected_radio = -1;
                } else {
                    if (i == selected_radio) {
                        str_radio += '<label><input checked="true" type="radio" name="' + tmpModel.RefId + '" value="' + s + '">' + s + '</label></br>';
                    } else {
                        str_radio += '<label><input type="radio" name="' + tmpModel.RefId + '" value="' + s + '">' + s + '</label></br>';
                    }
                }
            }

            renderedTemplate = renderedTemplate.replace('!"#¤%&/()', str_radio);

            return renderedTemplate;
        },
        buildInputSelect : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_selectTemplate({ model: tmpModel }); 

            var a = tmpModel.FieldValue.split("\n");
            
            var str_select = '';

            for (var i = 0; i < a.length; i++) {
                if (i == 0 && a[i].substring(0,7) == '#EMPTY#'){
                    str_select += '<option disabled selected value="">' + '' + '</option>'

                } else {
                    var s = a[i];
                    s = s.replace(/^\s+|\s+$/g, '');

                    str_select += '<option value="' + s + '">' + s  + '</option>'
                }
            }

            renderedTemplate = renderedTemplate.replace('!"#¤%&/()', str_select);

            return renderedTemplate;
        },
        buildSubmit : function(tmpModel) {
            var renderedTemplate = '';
            renderedTemplate = tpl_submitTemplate({ model: tmpModel }); 

            return renderedTemplate;
        },
        resetInputMultipleChoise : function(tmpModel) {
            var a = tmpModel.FieldValue.split("\n");
            
            var value_radio = '';
            var selected_radio = 0;
            for (var i = 0; i < a.length; i++) {
                if (i == 0 && a[i].substring(0,7) == '#EMPTY#'){
                    value_radio = ''
                } else {
                    if (i == selected_radio) {
                        value_radio += a[i];
                    } //else {
                    //    value_radio += '';
                    //}
                }
            }

            return value_radio;
        },
        resetInputSelect: function(tmpModel) {
            var a = tmpModel.FieldValue.split("\n");
            
            var value_select = '';
            var selected_select = 0;
            for (var i = 0; i < a.length; i++) {
                if (i == 0 && a[i].substring(0,7) == '#EMPTY#'){
                    value_select = ''
                } else {
                    if (i == selected_select) {
                        value_select += a[i];
                    } //else {
                    //    value_select += '';
                    //}
                }
            }

            return value_select;
        },
        resetElement : function(customProductSheetModel) {
            var value = '';

            var tmpModel = {};
            for (var i = 1; i <= customProductSheetModel.attributes.FieldUsed; i++) {
                tmpModel = this.getModel(i, customProductSheetModel);

                switch(tmpModel.FieldType) {
                    case 1 : 
                    case 2 : 
                    case 3 : 
                    case 4 : value = ''; 
                        $('#' + tmpModel.RefId).val(value);
                        break;
                    case 5 : value = this.resetInputMultipleChoise(tmpModel); 
                        if (value.length == 0) {
                            $('#' + tmpModel.RefId + ' input').prop('checked', false);
                        } else {
                            $('input[name="' + tmpModel.RefId + '"][value='  + value + ']').prop('checked', true); 
                        }
                        break;
                    case 6 : value = this.resetInputSelect(tmpModel); 
                        if (value.length == 0) {
                            $('#' + tmpModel.RefId).val('');
                        } else {
                            $('#' + tmpModel.RefId + ' option[value=' + value + ']').prop('selected', true);
                        }
                        break;
                    case 100 : value = ''; break;
                }              
            }
        },
        initElementForm: function(customProductSheetModel) {
            var tmpModel = {};
            for (var i = 1; i <= customProductSheetModel.attributes.FieldUsed; i++) {
                tmpModel = this.getModel(i, customProductSheetModel);

                switch(tmpModel.FieldType) {
                    case 1 : break;
                    case 2 : break;
                    case 3 : this.initAddressInput(tmpModel); break;
                    case 4 : this.initDateInput(tmpModel); break;
                    case 5 : break;
                    case 6 : break;
                }
            }
        },
        initAddressInput(tmpModel) {
            var self = this;
            var element = '#' + tmpModel.RefId;
            var addressPicker = new AddressPicker();
            $(element).typeahead(null, {
                displayKey: 'description',
                source: addressPicker.ttAdapter()
            });

            addressPicker.bindDefaultTypeaheadEvent($(element));
        },
        initDateInput(tmpModel) {
            var self = this;
            var element = '#' + tmpModel.RefId;
            var lng = language.getLanguage_bootstrap_datePicker(config.cultureName());

            $(element).datepicker({
                todayBtn : true,
                autoclose: true,
                todayHighlight: true,
                language: lng
            });
          
        },
        getSchemaDataModelValue: function(i, schemaDataModel) {
            var value = '';
            switch (i) {
                case 1 : 
                    value = schemaDataModel.Value01;
                    break;
                case 2 : 
                    value = schemaDataModel.Value02;
                    break;
                case 3 : 
                    value = schemaDataModel.Value03;
                    break;
                case 4 : 
                    value = schemaDataModel.Value04;
                    break;
                case 5 : 
                    value = schemaDataModel.Value05;
                    break;
                case 6 : 
                    value = schemaDataModel.Value06;
                    break;
                case 7 : 
                    value = schemaDataModel.Value07;
                    break;
                case 8 : 
                    value = schemaDataModel.Value08;
                    break;
                case 9 : 
                    value = schemaDataModel.Value09;
                    break;
                case 10 : 
                    value = schemaDataModel.Value10;
                    break;
                case 11 : 
                    value = schemaDataModel.Value11;
                    break;
                case 12 : 
                    value = schemaDataModel.Value12;
                    break;
                case 13 : 
                    value = schemaDataModel.Value13;
                    break;
                case 14 : 
                    value = schemaDataModel.Value14;
                    break;
                case 15 : 
                    value = schemaDataModel.Value15;
                    break;
                case 16 : 
                    value = schemaDataModel.Value16;
                    break;
                case 17 : 
                    value = schemaDataModel.Value17;
                    break;
                case 18 : 
                    value = schemaDataModel.Value18;
                    break;
                case 19 : 
                    value = schemaDataModel.Value19;
                    break;
                case 20 : 
                    value = schemaDataModel.Value20;
                    break;
                case 21 : 
                    value = schemaDataModel.Value21;
                    break;
                case 22 : 
                    value = schemaDataModel.Value22;
                    break;
                case 23 : 
                    value = schemaDataModel.Value23;
                    break;
                case 24 : 
                    value = schemaDataModel.Value24;
                    break;
                case 25 : 
                    value = schemaDataModel.Value25;
                    break;
                case 26 : 
                    value = schemaDataModel.Value26;
                    break;
                case 27 : 
                    value = schemaDataModel.Value27;
                    break;
                case 28 : 
                    value = schemaDataModel.Value28;
                    break;
                case 29 : 
                    value = schemaDataModel.Value29;
                    break;
                case 30 : 
                    value = schemaDataModel.Value30;
                    break;
            }

            return value;
        },
        getModel : function(i, customProdusctSheetModel) {
            var model = {};

            switch (i) {
                case 1 : 
                    model.RefId = 'RefId_1';
                    model.Field = customProdusctSheetModel.attributes.Field01;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType01;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue01;
                    break;
                case 2 : 
                    model.RefId = 'RefId_2';
                    model.Field = customProdusctSheetModel.attributes.Field02;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType02;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue02;
                    break;
                case 3 : 
                    model.RefId = 'RefId_3';
                    model.Field = customProdusctSheetModel.attributes.Field03;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType03;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue03;
                    break;
                case 4 : 
                    model.RefId = 'RefId_4';
                    model.Field = customProdusctSheetModel.attributes.Field04;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType04;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue04;
                    break;
                case 5 : 
                    model.RefId = 'RefId_5';
                    model.Field = customProdusctSheetModel.attributes.Field05;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType05;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue05;
                    break;
                case 6 : 
                    model.RefId = 'RefId_6';
                    model.Field = customProdusctSheetModel.attributes.Field06;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType06;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue06;
                    break;
                case 7 : 
                    model.RefId = 'RefId_7';
                    model.Field = customProdusctSheetModel.attributes.Field07;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType07;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue07;
                    break;
                case 8 : 
                    model.RefId = 'RefId_8';
                    model.Field = customProdusctSheetModel.attributes.Field08;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType08;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue08;
                    break;
                case 9 : 
                    model.RefId = 'RefId_9';
                    model.Field = customProdusctSheetModel.attributes.Field09;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType09;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue09;
                    break;
                case 10 : 
                    model.RefId = 'RefId_10';
                    model.Field = customProdusctSheetModel.attributes.Field10;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType10;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue10;
                    break;
                case 11 : 
                    model.RefId = 'RefId_11';
                    model.Field = customProdusctSheetModel.attributes.Field11;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType11;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue11;
                    break;
                case 12 : 
                    model.RefId = 'RefId_12';
                    model.Field = customProdusctSheetModel.attributes.Field12;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType12;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue12;
                    break;
                case 13 : 
                    model.RefId = 'RefId_13';
                    model.Field = customProdusctSheetModel.attributes.Field13;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType13;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue13;
                    break;
                case 14 : 
                    model.RefId = 'RefId_14';
                    model.Field = customProdusctSheetModel.attributes.Field14;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType14;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue14;
                    break;
                case 15 : 
                    model.RefId = 'RefId_15';
                    model.Field = customProdusctSheetModel.attributes.Field15;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType15;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue15;
                    break;
                case 16 : 
                    model.RefId = 'RefId_16';
                    model.Field = customProdusctSheetModel.attributes.Field16;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType16;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue16;
                    break;
                case 17 : 
                    model.RefId = 'RefId_17';
                    model.Field = customProdusctSheetModel.attributes.Field17;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType17;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue17;
                    break;
                case 18 : 
                    model.RefId = 'RefId_18';
                    model.Field = customProdusctSheetModel.attributes.Field18;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType18;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue18;
                    break;
                case 19 : 
                    model.RefId = 'RefId_19';
                    model.Field = customProdusctSheetModel.attributes.Field19;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType19;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue19;
                    break;
                case 20 : 
                    model.RefId = 'RefId_20';
                    model.Field = customProdusctSheetModel.attributes.Field20;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType20;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue20;
                    break;
                    		
                case 21 : 
                    model.RefId = 'RefId_21';
                    model.Field = customProdusctSheetModel.attributes.Field21;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType21;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue21;
                    break;
                case 22 : 
                    model.RefId = 'RefId_22';
                    model.Field = customProdusctSheetModel.attributes.Field22;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType22;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue22;
                    break;
                case 23 : 
                    model.RefId = 'RefId_23';
                    model.Field = customProdusctSheetModel.attributes.Field23;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType23;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue23;
                    break;
                case 24 : 
                    model.RefId = 'RefId_24';
                    model.Field = customProdusctSheetModel.attributes.Field24;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType24;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue24;
                    break;
                case 25 : 
                    model.RefId = 'RefId_25';
                    model.Field = customProdusctSheetModel.attributes.Field25;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType25;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue25;
                    break;
                case 26 : 
                    model.RefId = 'RefId_26';
                    model.Field = customProdusctSheetModel.attributes.Field26;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType26;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue26;
                    break;
                case 27 : 
                    model.RefId = 'RefId_27';
                    model.Field = customProdusctSheetModel.attributes.Field27;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType27;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue27;
                    break;
                case 28 : 
                    model.RefId = 'RefId_28';
                    model.Field = customProdusctSheetModel.attributes.Field28;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType28;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue28;
                    break;
                case 29 : 
                    model.RefId = 'RefId_29';
                    model.Field = customProdusctSheetModel.attributes.Field29;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType29;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue29;
                    break;
                case 30 : 
                    model.RefId = 'RefId_30';
                    model.Field = customProdusctSheetModel.attributes.Field30;
                    model.FieldType = customProdusctSheetModel.attributes.FieldType30;
                    model.FieldValue = customProdusctSheetModel.attributes.FieldValue30;
                    break;
            }

            return model;
        },
        form_init: function(eventId, customProductSheetModel, formElement, model) {
            var self = this;

            $(formElement).empty();

            var tmpModel = {};
            var renderedTemplate = '';

            for (var i = 1; i <= customProductSheetModel.attributes.FieldUsed; i++) {
                tmpModel = self.getModel(i,customProductSheetModel)
                renderedTemplate += self.buildElement(tmpModel);
            }

            $(formElement).empty();
            $(formElement).append(renderedTemplate);
        },
        form_attach: function(eventId, customProductSheetModel, formElement) {
            var self = this;

            self.initElementForm(customProductSheetModel);

            $('.twitter-typeahead').each(function() {
                $(this).css('width', '100%');
            });
        },
        form_setModelValue: function(eventId, customProductSheetModel, formElement, schemaDataModel) {
            var self = this;

            for (var i = 1; i <= customProductSheetModel.attributes.FieldUsed; i++) {
                var tmpModel = self.getModel(i,customProductSheetModel)

                self.setDataValue(i, tmpModel, schemaDataModel);
            }
        },
        setDataValue(i, tmpModel, schemaDataModel) {
            var value = this.getSchemaDataModelValue(i, schemaDataModel);

            switch(tmpModel.FieldType) {
                case 1 : $('#' + tmpModel.RefId).val(value); break;
                case 2 : $('#' + tmpModel.RefId).val(value); break;
                case 3 : $('#' + tmpModel.RefId).val(value); break;
                case 4 : $('#' + tmpModel.RefId).datepicker('update', value); break;
                case 5 : $('input[name=' + tmpModel.RefId + '][value="'  + value + '"]').prop('checked', true); break;
                    //case 6 : $('#' + tmpModel.RefId + ' option:eq("' + value + '")').prop('selected', true); break;
                case 6 : $('#' + tmpModel.RefId + ' option[value="' + value + '"]').prop('selected', true);
                //case 3 : renderedTemplate = this.buildInputAddress(tmpModel); break;
                //case 4 : renderedTemplate = this.buildInputDate(tmpModel); break;
                //case 5 : renderedTemplate = this.buildInputMultipleChoise(tmpModel); break;
                //case 6 : renderedTemplate = this.buildInputSelect(tmpModel); break;
                //case 100 : renderedTemplate = this.buildSubmit(tmpModel); break;
            }
        },
        getData : function(tmpModel, customProductSheetModel) {
            var self = this;

            var sId = '';

            for (var i = 1; i <= 30; i++) {
                if (i < 10) {
                    sId = "0" + i.toString();
                }
                else {
                    sId = "" + i.toString();
                }

                if (i <= customProductSheetModel.attributes.FieldUsed) {
                    var model = this.getModel(i, customProductSheetModel);

                    var val = this.getValues(i, model.FieldType);
                    if (val == undefined) {
                        val = '';
                    }
                    tmpModel.set('Value' + sId, val);
                } else {
                    tmpModel.set('Value' + sId, '');
                }
            }

            return tmpModel;
        },
        getValues : function(id, fieldType) {

            var value = '';
            var sId = 'RefId_' + id;

            switch(fieldType) {
                case 1 : 
                case 2 : 
                case 3 : 
                case 4 : value = $('#' + sId).val(); break;
                case 5 : value = $('input[name=' + sId + ']:checked').val(); break;
                case 6 : value = $('#' + sId + ' option:selected').val(); break;
            }

            //$('input[name=RefId_4]:checked').val()
            if (value == undefined) {
                value = '';
            }

            value = value.replace(/^\s+|\s+$/g, '');
            return value;
        },
        resetValues : function(id, fieldType) {

            var value = '';
            var sId = 'RefId_' + id;

            switch(fieldType) {
                case 1 : 
                case 2 : 
                case 3 : 
                case 4 : value = $('#' + sId).val(''); break;
                case 5 : value = $('input[name=' + sId + ']:checked').val(); break;
                case 6 : value = $('#' + sId + ' option:selected').val(); break;
            }

            //$('input[name=RefId_4]:checked').val()
            if (value == undefined) {
                value = '';
            }

            value = value.replace(/^\s+|\s+$/g, '');
            return value;
        }

    };

    return API;
})();



