var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div> <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style="top: 50px;z-index:1000 !important"> <div class="navbar-brand-wrapper d-flex align-items-center justify-content-between" style="max-width:0px;width:0px;z-index:0;padding-left: 0px;padding-right: 0px"> </div> <div id="headerRegionV2" class="navbar-menu-wrapper d-flex align-items-center justify-content-end" style="z-index:1029; height:53px;padding-right: 20px;margin-top: 10px"> <blockquote style="padding-top: 0px; margin-bottom: 0px; border-bottom-width: 0px; border-top-width: 0px; border-left-width: 0px; border-right-width: 0px;margin-top:5px !important" class="blockquote blockquote-primary"> <h5 style="color:rgb(108, 114, 147) !important">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <footer id="customHeader_name_footer" class="blockquote-footer"><cite id="customHeader_name" title="Source Title">'+
((__t=( model.Name ))==null?'':__t)+
'</cite></footer> </blockquote>  <ul id="headerList" class="navbar-nav navbar-nav-right"> <li> <div id="customHeader_iconBack" style="background-color:rgb(108, 114, 147);margin-right:10px" class="mysettings-trigger"><i class="fas fa-2x fa-arrow-left" style="animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X100275'] ))==null?'':__t)+
'"></i></div> </li> <li> <div id="customHeader_iconSave" style="background-color:rgb(108, 114, 147)" class="mysettings-trigger"><i class="far fa-2x fa-save" style="animation-name:none" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X100275'] ))==null?'':__t)+
'"></i></div> </li>  </ul> </div> </nav> </div>';
}
return __p;
};
