﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as plugins_qrcode from '../../plugins/qrcode';
import * as nouislider from 'nouislider';


import tpl_customDepartmentResetEditItem from './tpl/customDepartmentResetEditItem.html';

import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting2 from '../../../lib/views/dialogWaiting2/dialogWaiting2';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    dialogRegion2: '#customDepartmentResetEditItem_modal',
    getTemplate: function () {
        return tpl_customDepartmentResetEditItem({ model: this.model.toJSON() });
    },
    regions: {
        information: "#customDepartmentResetEditItemInformation",
    },
    onBeforeShow: function () {
        //this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');

        //this.model = new this.app.entities.collections.customDepartmentReset.fullCollection.model();

        this.model.attributes.tmp = {};
        this.model.attributes.tmp.Custom_Color_Default = this.app.entities.models.customColor.attributes.Custom_Color_Default;
        this.model.attributes.tmp.Custom_Text_Default = resources.X200311;// + ' (' + this.app.entities.models.customColor.attributes.Custom_Text_Default + ')';

        this.model.attributes.tmp.SolidColor_Color_Off = this.app.entities.models.customColor.attributes.SolidColor_Color_Off;
        this.model.attributes.tmp.SolidColor_Text_Off = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Off + ')';
        this.model.attributes.tmp.SelectedOff = '';

        this.model.attributes.tmp.SolidColor_Color_Busy = this.app.entities.models.customColor.attributes.SolidColor_Color_Busy;
        this.model.attributes.tmp.SolidColor_Text_Busy = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Busy + ')';
        this.model.attributes.tmp.SelectedBusy = '';

        this.model.attributes.tmp.SolidColor_Color_Available = this.app.entities.models.customColor.attributes.SolidColor_Color_Available;
        this.model.attributes.tmp.SolidColor_Text_Available = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Available + ')';
        this.model.attributes.tmp.SelectedAvailable = '';

        this.model.attributes.tmp.SolidColor_Color_Blue = this.app.entities.models.customColor.attributes.SolidColor_Color_Blue;
        this.model.attributes.tmp.SolidColor_Text_Blue = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Blue + ')';
        this.model.attributes.tmp.SelectedBlue = '';

        this.model.attributes.tmp.SolidColor_Color_Yellow = this.app.entities.models.customColor.attributes.SolidColor_Color_Yellow;
        this.model.attributes.tmp.SolidColor_Text_Yellow = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow + ')';
        this.model.attributes.tmp.SelectedYellow = '';

        this.model.attributes.tmp.SolidColor_Color_Cyan = this.app.entities.models.customColor.attributes.SolidColor_Color_Cyan;
        this.model.attributes.tmp.SolidColor_Text_Cyan = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan + ')';
        this.model.attributes.tmp.SelectedCyan = '';

        this.model.attributes.tmp.SolidColor_Color_Magenta = this.app.entities.models.customColor.attributes.SolidColor_Color_Magenta;
        this.model.attributes.tmp.SolidColor_Text_Magenta = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta + ')';
        this.model.attributes.tmp.SelectedMagenta = '';

        this.model.attributes.tmp.SolidColor_Color_CustomColor = this.app.entities.models.customColor.attributes.SolidColor_Color_CustomColor;
        this.model.attributes.tmp.SolidColor_Text_CustomColor = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor + ')';
        this.model.attributes.tmp.SelectedCustomColor = '';

        this.model.attributes.tmp.MSTeams_Color_Online = this.app.entities.models.customColor.attributes.MSTeams_Color_Online;
        this.model.attributes.tmp.MSTeams_Text_Online = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Online + ')';

        this.model.attributes.tmp.MSTeams_Color_Busy = this.app.entities.models.customColor.attributes.MSTeams_Color_Busy;
        this.model.attributes.tmp.MSTeams_Text_Busy = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Busy + ')';

        this.model.attributes.tmp.MSTeams_Color_DoNotDisturb = this.app.entities.models.customColor.attributes.MSTeams_Color_DoNotDisturb;
        this.model.attributes.tmp.MSTeams_Text_DoNotDisturb = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb + ')';

        this.model.attributes.tmp.MSTeams_Color_BeRightBack = this.app.entities.models.customColor.attributes.MSTeams_Color_BeRightBack;
        this.model.attributes.tmp.MSTeams_Text_BeRightBack = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsNotPresent = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsNotPresent;
        this.model.attributes.tmp.MSTeams_Text_ShowAsNotPresent = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsOffline = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsOffline;
        this.model.attributes.tmp.MSTeams_Text_ShowAsOffline = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline + ')';

        this.model.attributes.tmp.Mutebutton_Color_On = this.app.entities.models.customColor.attributes.Mutebutton_Color_On;
        this.model.attributes.tmp.Mutebutton_Text_On = resources.X200312 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_On + ')';

        this.model.attributes.tmp.Mutebutton_Color_Off = this.app.entities.models.customColor.attributes.Mutebutton_Color_Off;
        this.model.attributes.tmp.Mutebutton_Text_Off = resources.X200312 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_Off + ')';

        this.model.attributes.tmp.Pomodoro_Color_Time = this.app.entities.models.customColor.attributes.Pomodoro_Color_Time;
        this.model.attributes.tmp.Pomodoro_Text_Time = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_Time + ')';

        this.model.attributes.tmp.Pomodoro_Color_ShortBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_ShortBreak;
        this.model.attributes.tmp.Pomodoro_Text_ShortBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak + ')';

        this.model.attributes.tmp.Pomodoro_Color_LongBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_LongBreak;
        this.model.attributes.tmp.Pomodoro_Text_LongBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak + ')';
    },
    help: function () {
        Help.help(this, true, 'customDepartmentResetEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick(null, callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        /*if (this.model.attributes.Name != this.oldModel.attributes.Name)  {
            changes = true;
        }*/

        this.setHasChanged(changes);
    },
    ui: {
        //'customDepartmentResetEditItem_inputTime': '#customDepartmentResetEditItem_inputTime',
        'customDepartmentResetEditItem_selectStatus': '#customDepartmentResetEditItem_selectStatus',
        'customDepartmentResetEditItem_selectActiveTab': '#customDepartmentResetEditItem_selectActiveTab',
        'customDepartmentResetEditItem_btnSubmit': '#customDepartmentResetEditItem_btnSubmit',
        'customDepartmentResetEditItem_btnCancel': '#customDepartmentResetEditItem_btnCancel',
        'customDepartmentResetEditItem_select_Hour': '#customDepartmentResetEditItem_select_Hour',
        'customDepartmentResetEditItem_select_Minut': '#customDepartmentResetEditItem_select_Minut',
        'customDepartmentResetEditItem_inputActiveTab': '#customDepartmentResetEditItem_inputActiveTab',
        'customDepartmentResetEditItem_inputText': '#customDepartmentResetEditItem_inputText',
        'help': '#help',
    },
    events: {
        'click @ui.customDepartmentResetEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customDepartmentResetEditItem_btnCancel': 'btnCancelClick',
        'change @ui.customDepartmentResetEditItem_selectStatus': 'change_selectStatus',
        'click #help': 'help'

    },
    renderInformation: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: resources.X100072 });
        this.informationCollection.add(tmpModel);

    },
    onAttach() {
        var self = this;

        //this.ui.customDepartmentResetEditItem_inputTime.durationPicker({
        //    translations: {
        //        day: resources.X200259,
        //        hour: resources.X200260,
        //        minute: resources.X200261,
        //        second: resources.X200262,
        //        days: resources.X200263,
        //        hours: resources.X200264,
        //        minutes: resources.X200265,
        //        seconds: resources.X200266,
        //    },
        //    showSeconds: false,
        //    showDays: false,
            

        //    onChanged: function (newVal) {
        //        //self.ui.adSlideListItemProperties_label_Duration.text(newVal);
        //        self.model.set({
        //            Time: newVal
        //        });
        //    }
        //});

        //$('.bdp-block.hidden').css('display', 'none');
        //$('#customDepartmentResetEditItem_group_Time').find('.input-sm').width(90);

        this.setupStatus(this.model.attributes.Color);
        this.setupActiveTab();
        this.setupTime();

        $('.btn.dropdown-toggle.btn-light').css("width", "320px");
    },
    setupStatus(color) {
        var self = this;



        this.ui.customDepartmentResetEditItem_selectStatus.selectpicker({ size: 10 });
        //$('.selectpicker').selectpicker();

        /*if (this.model.attributes.ActiveTab == resources.X200311) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.Custom_Color_Default: color = "0"; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200270) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.SolidColor_Color_Off: color = "1"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Busy: color = "2"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Available: color = "3"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Blue: color = "4"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Yellow: color = "5"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Cyan: color = "6"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Magenta: color = "7"; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_CustomColor: color = "8"; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200275) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.MSTeams_Color_Online: color = "9"; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_Busy: color = "10"; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_DoNotDisturb: color = "11"; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_BeRightBack: color = "12"; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_ShowAsNotPresent: color = "13"; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_ShowAsOffline: color = "14"; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200312) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.Mutebutton_Color_On: color = "15"; break;
                case this.app.entities.models.custom.attributes.Mutebutton_Color_Off: color = "16"; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200286) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.Pomodoro_Color_Time: color = "17"; break;
                case this.app.entities.models.custom.attributes.Pomodoro_Color_ShortBreak: color = "18"; break;
                case this.app.entities.models.custom.attributes.Pomodoro_Color_LongBreak: color = "19"; break;
            }
        }*/

        

        //$('select[name=selValue]').val(color);
        $('select[name=selValue]').val(this.model.attributes.ColorSelected);
        this.ui.customDepartmentResetEditItem_selectStatus.selectpicker('refresh');
        //$('.selectpicker').selectpicker('refresh');
        this.change_selectStatus();

        this.ui.customDepartmentResetEditItem_inputText.val(this.model.attributes.Text);
    },
    change_selectStatus: function () {
        var value = this.ui.customDepartmentResetEditItem_selectStatus.val();
        var text = '';
        var disabled = false;

        switch (value) {
            case "0": text = '';//this.model.attributes.tmp.Custom_Text_Default;
                disabled = true;
                break;
            case "1": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Off;
                break;
            case "2": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Busy;
                break;
            case "3": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Available;
                break;
            case "4": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Blue;
                break;
            case "5": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow;
                break;
            case "6": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan;
                break;
            case "7": text = this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta;
                break;
            case "8": text = this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor;
                break;
            case "9": text = this.app.entities.models.customColor.attributes.tmp.MSTeams_Text_Online;
                break;
            case "10": text = this.app.entities.models.customColor.attributes.tmp.MSTeams_Text_Busy;
                break;
            case "11": text = this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb;
                break;
            case "12": text = this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack;
                break;
            case "13": text = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent;
                break;
            case "14": text = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline;
                break;
            case "15": text = this.app.entities.models.customColor.attributes.Mutebutton_Text_On;
                break;
            case "16": text = this.app.entities.models.customColor.attributes.Mutebutton_Text_Off;
                break;
            case "17": text = this.app.entities.models.customColor.attributes.Pomodoro_Text_Time;
                break;
            case "18": text = this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak;
                break;
            case "19": text = this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak;
                break;

        }

        this.ui.customDepartmentResetEditItem_inputText.val(text);
        this.ui.customDepartmentResetEditItem_inputText.prop('disabled', disabled);

    },
    setupActiveTab() {
        this.ui.customDepartmentResetEditItem_selectActiveTab.find('option[value="' + this.model.attributes.ActiveTab + '"]').attr("selected", "selected");
    },
    setupTime() {
        var time = this.model.attributes.Time / 60;
        var hours = Math.floor(time / 60);
        var minutes = time % 60;

        this.ui.customDepartmentResetEditItem_select_Hour.find('option[value="' + hours + '"]').attr("selected", "selected");
        this.ui.customDepartmentResetEditItem_select_Minut.find('option[value="' + minutes + '"]').attr("selected", "selected");
    },
    onDestroy() {
    },
    validate: function () {
        var self = this;
        //var oldname = this.model.attributes.Name;

        tmpModel.attributes.Color = $('.selectpicker').val();//this.ui.customDepartmentResetEditItem_selectStatus.val();

        /*var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }*/

        return true;
    },
    secondsToHms: function (d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h;// > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m;// > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s;// > 0 ? s + (s == 1 ? " second" : " seconds") : "";

        if (h < 10) hDisplay = '0' + hDisplay;
        if (m < 10) mDisplay = '0' + mDisplay;
        if (s < 10) sDisplay = '0' + sDisplay;


        return hDisplay + ':' + mDisplay;// + ':' +  sDisplay;
    },
    btnSubmitClick: function (event, callbackMenu) {
        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        //if (this.validate()) {

        this.model.attributes.Time = ((parseInt(this.ui.customDepartmentResetEditItem_select_Hour.val()) * 60) + parseInt(this.ui.customDepartmentResetEditItem_select_Minut.val())) * 60;

        var tmpColor = "";
        var tmpColorSelected = 0;
        var tmpActiveTab = "";

        var value = this.ui.customDepartmentResetEditItem_selectStatus.val();
        switch (value) {
            case "0": tmpColor = this.model.attributes.tmp.Custom_Color_Default;
                tmpActiveTab = resources.X200311;
                tmpColorSelected = 0;
                break;
            case "1": tmpColor = this.model.attributes.tmp.SolidColor_Color_Off;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 1;
                break;
            case "2": tmpColor = this.model.attributes.tmp.SolidColor_Color_Busy;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 2;
                break;
            case "3": tmpColor = this.model.attributes.tmp.SolidColor_Color_Available;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 3;
                break;
            case "4": tmpColor = this.model.attributes.tmp.SolidColor_Color_Blue;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 4;
                break;
            case "5": tmpColor = this.model.attributes.tmp.SolidColor_Color_Yellow;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 5;
                break;
            case "6": tmpColor = this.model.attributes.tmp.SolidColor_Color_Cyan;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 6;
                break;
            case "7": tmpColor = this.model.attributes.tmp.SolidColor_Color_Magenta;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 7;
                break;
            case "8": tmpColor = this.model.attributes.tmp.SolidColor_Color_CustomColor;
                tmpActiveTab = resources.X200270;
                tmpColorSelected = 8;
                break;
            case "9": tmpColor = this.model.attributes.tmp.MSTeams_Color_Online;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 9;
                break;
            case "10": tmpColor = this.model.attributes.tmp.MSTeams_Color_Busy;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 10;
                break;
            case "11": tmpColor = this.model.attributes.tmp.MSTeams_Color_DoNotDisturb;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 11;
                break;
            case "12": tmpColor = this.model.attributes.tmp.MSTeams_Color_BeRightBack;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 12;
                break;
            case "13": tmpColor = this.model.attributes.tmp.MSTeams_Color_ShowAsNotPresent;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 13;
                break;
            case "14": tmpColor = this.model.attributes.tmp.MSTeams_Color_ShowAsOffline;
                tmpActiveTab = resources.X200275;
                tmpColorSelected = 14;
                break;
            case "15": tmpColor = this.model.attributes.tmp.Mutebutton_Color_On;
                tmpActiveTab = resources.X200312;
                tmpColorSelected = 15;
                break;
            case "16": tmpColor = this.model.attributes.tmp.Mutebutton_Color_Off;
                tmpActiveTab = resources.X200312;
                tmpColorSelected = 16;
                break;
            case "17": tmpColor = this.model.attributes.tmp.Pomodoro_Color_Time;
                tmpActiveTab = resources.X200286;
                tmpColorSelected = 17;
                break;
            case "18": tmpColor = this.model.attributes.tmp.Pomodoro_Color_ShortBreak;
                tmpActiveTab = resources.X200286;
                tmpColorSelected = 18;
                break;
            case "19": tmpColor = this.model.attributes.tmp.Pomodoro_Color_LongBreak;
                tmpActiveTab = resources.X200286;
                tmpColorSelected = 19;
                break;

        }

        var text = this.ui.customDepartmentResetEditItem_inputText.val();

        this.model.set({
            //Time: ((parseInt(this.ui.customDepartmentResetEditItem_select_Hour.val()) * 60) + parseInt(this.ui.customDepartmentResetEditItem_select_Minut.val())) * 60,
            TmpTime: this.secondsToHms(this.model.attributes.Time),
            Color: tmpColor,//this.ui.customDepartmentResetEditItem_selectStatus.val(),
            ColorSelected : tmpColorSelected,
            ActiveTab: tmpActiveTab,//this.ui.customDepartmentResetEditItem_selectStatus.attr('data-activetab') //this.ui.customDepartmentResetEditItem_selectActiveTab.val()
            Text : text
        });

        this.model.attributes.Text = text;
        //this.model.set({ TmpTime: this.secondsToHms(this.model.attributes.Time) });
        //this.model.set({ Color: this.ui.customDepartmentResetEditItem_selectStatus.val() });
        //this.model.set({ ActiveTab: this.ui.customDepartmentResetEditItem_selectActiveTab.val() });
            //this.model.set({ Color: $('.selectpicker').val() });

            this.setHasChanged(false);

            //var dialogWaitingModel = new Entities_Empty.Model();
            //dialogWaitingModel.attributes.Title = resources.X200258;
            //dialogWaitingModel.attributes.Text = resources.X200093;
            //dialogWaitingModel.attributes.Button = resources.X200025;

            //var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: this });
            //var callback = function () {
            //    this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            //}
            //this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            //self.app.dialogRegion.show(dialogWaiting);

            this.model.save(this.model.attributes, {
                success: function () {
                    //dialogWaiting.triggerMethod('dialog:okclicked');
                    //self.model.trigger('change');
                    self.trigger("dialog:close");
                    self.triggerMethod('dialog:btn01clicked', "msg");
                    //self.model.trigger('change');
                    //self.collection.fullCollection.sort();
                    //self.collection.getFirstPage({ fetch: false });

                    /*if (callbackMenu != undefined) {
                        callbackMenu();
                    } else {
                        //$('.modal-backdrop').remove();
                        //self.app.router.navigate("#customDepartmentReset");
                        //self.app.router.customDepartmentReset();
                        dialogWaiting.triggerMethod('dialog:close');
                    }*/
                }
            });
        //}
    },
    btnCancelClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.trigger("dialog:close");
        this.triggerMethod('dialog:btn01clicked', "msg");

        //dialogWaiting.triggerMethod('dialog:close');
        //self.app.router.navigate("#customDepartmentReset");
        //self.app.router.customDepartmentReset();
    }
}, Backbone.Radio.Requests);