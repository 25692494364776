var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogPreviewWebpageZoomIPad_empty"> <div class="modal fade" id="dialogPreviewWebpageZoomIPad_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog" style="border-top-width: 0px;border-right-width: 0px;border-bottom-width: 0px;border-left-width: 0px;background-color: transparent !important; width:840px !important; max-width: 840px !important"> <div class="ml-auto"> <img id="dialogPreviewWebpageZoomIPad_btnOk" src="" alt="" class="fas fa-lg fa-2x fa-times-circle" style="color:#ffffff"> </div>  <div class="modal-body" style="background-color: transparent !important;padding-top: 0px"> <div class="" style="background-image: url(\'/images/NewIPad.png\'); width: 800px; height: 576px; z-index:100000;overflow:hidden"> <iframe scrolling="auto" class="iframe_ipad" frameborder="0" id="dialogPreviewWebpageZoomIPad_iframe" src="'+
((__t=( model.Iframe ))==null?'':__t)+
'" style="width: 1112px;height: 820px;-moz-transform: scale(0.59);-moz-transform-orgin: 0 0;-o-transform: scale(0.59);-o-transform-origin: 0 0;-webkit-transform: scale(0.59);-webkit-transform-origin: 0 0;overflow-y:auto; overflow:hidden;  -webkit-overflow-scrolling: touch; left:73px;top:51px;position:relative; z-index:0"></iframe> </div> </div>  </div> </div> </div>';
}
return __p;
};
