﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import helper_infopage from '../../../helper/infopage';

import tpl_analogClock from './tpl/analogClock.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#analogClock_modal',
    errors: [],
    getTemplate: function () {
        return tpl_analogClock({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'analogClock_select_Font3_ColorType': '#analogClock_select_Font3_ColorType',
        'analogClock_ColorPickerSliders_Font3_Color': '#analogClock_ColorPickerSliders_Font3_Color',
        'analogClock_ColorPickerSliders_BgColor': '#analogClock_ColorPickerSliders_BgColor',
        'analogClock_ColorPickerSliders_FrameColor': '#analogClock_ColorPickerSliders_FrameColor',
        'analogClock_ColorPickerSliders_HandsColor': '#analogClock_ColorPickerSliders_HandsColor',
        'analogClock_select_Font3_FontType': '#analogClock_select_Font3_FontType',
        'analogClock_dropDown_Font3': '#analogClock_dropDown_Font3',
        'analogClock_formgroup_Font3': '#analogClock_formgroup_Font3',
        'analogClock_dropDown_Font3_Style': '#analogClock_dropDown_Font3_Style',
        'analogClock_input_margin3_Top': '#analogClock_input_margin3_Top',
        'analogClock_input_margin3_Bottom': '#analogClock_input_margin3_Bottom',
        'analogClock_input_margin3_Left': '#analogClock_input_margin3_Left',
        'analogClock_input_margin3_Right': '#analogClock_input_margin3_Right',

        'analogClock_checkboxEnableClock': '#analogClock_checkboxEnableClock',
        'analogClock_checkboxEnable24Hours': '#analogClock_checkboxEnable24Hours',
        'analogClock_select_Font1_ColorType': '#analogClock_select_Font1_ColorType',
        'analogClock_ColorPickerSliders_Font1_Color': '#analogClock_ColorPickerSliders_Font1_Color',
        'analogClock_dropDown_Font1_FontType': '#analogClock_dropDown_Font1_FontType',
        'analogClock_dropDown_Font1_Size': '#analogClock_dropDown_Font1_Size',
        'analogClock_select_Font1_FontType': '#analogClock_select_Font1_FontType',
        'analogClock_dropDown_Font1': '#analogClock_dropDown_Font1',
        'analogClock_formgroup_Font1': '#analogClock_formgroup_Font1',
        'analogClock': '#analogClock',
        'analogClock_dropDown_Font1_Style': '#analogClock_dropDown_Font1_Style',
        'analogClock_dropDown_Font1_TextAlign': '#analogClock_dropDown_Font1_TextAlign',
        'analogClock_input_margin1_Top': '#analogClock_input_margin1_Top',
        'analogClock_input_margin1_Bottom': '#analogClock_input_margin1_Bottom',
        'analogClock_input_margin1_Left': '#analogClock_input_margin1_Left',
        'analogClock_input_margin1_Right': '#analogClock_input_margin1_Right',

        'analogClock_checkboxEnableDate': '#analogClock_checkboxEnableDate',
        'analogClock_dropDown_DateFormat': '#analogClock_dropDown_DateFormat',
        'analogClock_select_Font2_ColorType': '#analogClock_select_Font2_ColorType',
        'analogClock_ColorPickerSliders_Font2_Color': '#analogClock_ColorPickerSliders_Font2_Color',
        'analogClock_dropDown_Font2_Size': '#analogClock_dropDown_Font2_Size',
        'analogClock_select_Font2_FontType': '#analogClock_select_Font2_FontType',
        'analogClock_dropDown_Font2': '#analogClock_dropDown_Font2',
        'analogClock_formgroup_Font2': '#analogClock_formgroup_Font2',
        'analogClock_dropDown_Font2_Style': '#analogClock_dropDown_Font2_Style',
        'analogClock_dropDown_Font2_TextAlign': '#analogClock_dropDown_Font2_TextAlign',
        'analogClock_input_margin2_Top': '#analogClock_input_margin2_Top',
        'analogClock_input_margin2_Bottom': '#analogClock_input_margin2_Bottom',
        'analogClock_input_margin2_Left': '#analogClock_input_margin2_Left',
        'analogClock_input_margin2_Right': '#analogClock_input_margin2_Right',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right',
        'analogClock_btn01': '#analogClock_btn01',
        'analogClock_btn02': '#analogClock_btn02'
    },
    events: {
        'change @ui.analogClock_select_Font1_FontType': 'change_Font1_FontType',
        'change @ui.analogClock_select_Font2_FontType': 'change_Font2_FontType',
        'change @ui.analogClock_select_Font3_FontType': 'change_Font3_FontType',
        'change @ui.analogClock_select_Font1_ColorType': 'change_Font1_ColorType',
        'change @ui.analogClock_select_Font2_ColorType': 'change_Font2_ColorType',
        'change @ui.analogClock_select_Font3_ColorType': 'change_Font3_ColorType',
        'colorpickerChange @ui.analogClock_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.analogClock_ColorPickerSliders_Font2_Color': 'colorChange_Font2Color',
        'colorpickerChange @ui.analogClock_ColorPickerSliders_Font3_Color': 'colorChange_Font3Color',
        'colorpickerChange @ui.analogClock_ColorPickerSliders_BgColor': 'colorChange_BgColor',
        'colorpickerChange @ui.analogClock_ColorPickerSliders_FrameColor': 'colorChange_FrameColor',
        'colorpickerChange @ui.analogClock_ColorPickerSliders_HandsColor': 'colorChange_HandsColor',
        'click @ui.analogClock_btn01': 'onBtn01',
        'click @ui.analogClock_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    onAttach() {
        this.setupEnableType();
        this.setupFontColorType();
        this.setupFontType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupFontTextAlign();
        this.setupDateFormat();
    },
    setupEnableType: function () {
        var checked = this.model.attributes.EnableClock == 1 ? true : false;
        this.ui.analogClock_checkboxEnableClock.attr('checked', checked);

        var checked = this.model.attributes.Enable24Hours == 1 ? true : false;
        this.ui.analogClock_checkboxEnable24Hours.attr('checked', checked);

        var checked = this.model.attributes.EnableDate == 1 ? true : false;
        this.ui.analogClock_checkboxEnableDate.attr('checked', checked);
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.analogClock_select_Font1_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_select_Font1_ColorType.append(options);
        });

        self.ui.analogClock_select_Font2_ColorType.empty();

        selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_select_Font2_ColorType.append(options);
        });

        selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_select_Font3_ColorType.append(options);
        });
    },
    setupFontType: function () {
        var self = this;

        self.ui.analogClock_select_Font1_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_select_Font1_FontType.append(options);
        });

        self.ui.analogClock_select_Font2_FontType.empty();

        selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_select_Font2_FontType.append(options);
        });

        selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_select_Font3_FontType.append(options);
        });
    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.cp_Font1_Color = this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.analogClock_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
        });
        this.ui.analogClock_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.analogClock_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.analogClock_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.analogClock_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font2: this.value });
            self.model.set({ Font2_Select: this.value });
        });
        this.ui.analogClock_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.analogClock_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.analogClock_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker({
            color: self.model.attributes.Font3_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font3_ColorType)) {
            case true: this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.analogClock_dropDown_Font3.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font3: this.value });
            self.model.set({ Font3_Select: this.value });
        });
        this.ui.analogClock_dropDown_Font3.trigger('setFont', self.model.attributes.Font3);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font3_FontType)) {
            case false: this.ui.analogClock_formgroup_Font3.addClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font3.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.analogClock_formgroup_Font3.removeClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font3.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.analogClock_ColorPickerSliders_BgColor.colorpicker({
            color: self.model.attributes.BgColor,
            format: "rgb"
        });

        this.ui.analogClock_ColorPickerSliders_FrameColor.colorpicker({
            color: self.model.attributes.FrameColor,
            format: "rgb"
        });

        this.ui.analogClock_ColorPickerSliders_HandsColor.colorpicker({
            color: self.model.attributes.HandsColor,
            format: "rgb"
        });
    },
    setupFontSize: function () {
        var self = this;

        this.ui.analogClock_dropDown_Font1_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.analogClock_dropDown_Font1_Size.append(options);
        }

        this.ui.analogClock_dropDown_Font2_Size.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.analogClock_dropDown_Font2_Size.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.analogClock_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_dropDown_Font1_Style.append(options);
        });

        self.ui.analogClock_dropDown_Font2_Style.empty();

        selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_dropDown_Font2_Style.append(options);
        });

        selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_dropDown_Font3_Style.append(options);
        });
    },
    setupFontTextAlign: function () {
        var self = this;

        self.ui.analogClock_dropDown_Font1_TextAlign.empty();

        var selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_dropDown_Font1_TextAlign.append(options);
        });

        self.ui.analogClock_dropDown_Font2_TextAlign.empty();

        selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.analogClock_dropDown_Font2_TextAlign.append(options);
        });
    },
    setupDateFormat: function () {
        var self = this;

        self.ui.analogClock_dropDown_DateFormat.empty();

        var selected = '';

        var date = new Date();


        var vA = date.getDate();
        if (vA < 10) vA = "0" + vA;

        var vB = date.getMonth() + 1;
        if (vB < 10) vB = "0" + vB;

        var vC = date.getFullYear();

        var vF = date.getDate();
        var vG = date.getMonth() + 1;


        var dayOffWeek = date.getDay();
        var vD = self.app.entities.collections.DigitalClockHelper.models[0].attributes.Days[dayOffWeek].Name;
        var dayOffMonth = date.getMonth();
        var vE = self.app.entities.collections.DigitalClockHelper.models[0].attributes.Months[dayOffMonth].Name;


        self.app.entities.collections.DigitalClockDateFormat.forEach(function (entry) {

            var name = entry.attributes.Name;

            name = name.replaceAll("A", vA);
            name = name.replaceAll("B", vB);
            name = name.replaceAll("C", vC);
            name = name.replaceAll("D", vD);
            name = name.replaceAll("E", vE);
            name = name.replaceAll("F", vF);
            name = name.replaceAll("G", vG);

            selected = '';
            if (self.model.attributes.DateFormat == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', name, entry.attributes.Value, selected);
            self.ui.analogClock_dropDown_DateFormat.append(options);
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    change_Font1_FontType: function () {
        this.model.set({
            Font1_FontType: this.ui.analogClock_select_Font1_FontType.val()
        });

        this.model.set({
            Font1: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font1_FontType, this.model.attributes.Font1_Select)
        });

        this.ui.analogClock_dropDown_Font1.trigger('setFont', this.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.analogClock_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.analogClock_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

    },
    change_Font2_FontType: function () {
        this.model.set({
            Font2_FontType: this.ui.analogClock_select_Font2_FontType.val()
        });

        this.model.set({
            Font2: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font2_FontType, this.model.attributes.Font2_Select)
        });

        this.ui.analogClock_dropDown_Font2.trigger('setFont', this.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.analogClock_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.analogClock_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font3_FontType: function () {
        this.model.set({
            Font3_FontType: this.ui.analogClock_select_Font3_FontType.val()
        });

        this.model.set({
            Font3: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font3_FontType, this.model.attributes.Font3_Select)
        });

        this.ui.analogClock_dropDown_Font3.trigger('setFont', this.model.attributes.Font3);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font3_FontType)) {
            case false: this.ui.analogClock_formgroup_Font3.addClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font3.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.analogClock_formgroup_Font3.removeClass('disabledFontSelect_FormGroup');
                this.ui.analogClock_formgroup_Font3.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font1_ColorType: function () {
        this.model.set({
            Font1_ColorType: this.ui.analogClock_select_Font1_ColorType.val()
        });

        this.model.set({
            Font1_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font1_ColorType, this.model.attributes.Font1_ColorSelect)
        });

        this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable();
        this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font1_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.analogClock_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    change_Font2_ColorType: function () {
        this.model.set({
            Font2_ColorType: this.ui.analogClock_select_Font2_ColorType.val()
        });

        this.model.set({
            Font2_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font2_ColorType, this.model.attributes.Font2_ColorSelect)
        });

        this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable();
        this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font2_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.analogClock_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    change_Font3_ColorType: function () {
        this.model.set({
            Font3_ColorType: this.ui.analogClock_select_Font3_ColorType.val()
        });

        this.model.set({
            Font3_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font3_ColorType, this.model.attributes.Font3_ColorSelect)
        });

        this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').enable();
        this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font2_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font3_ColorType)) {
            case true: this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.analogClock_ColorPickerSliders_Font3_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }
    },
    colorChange_Font2Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            this.model.attributes.Font2_ColorSelect = this.model.attributes.Font2_Color;
        }
    },
    colorChange_Font3Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font3_Color = e.value;
        }
        else {
            this.model.attributes.Font3_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font3_ColorType)) {
            this.model.attributes.Font3_ColorSelect = this.model.attributes.Font3_Color;
        }
    },
    colorChange_BgColor: function (e) {
        this.model.attributes.BgColor = e.value.toRgbString();
    },
    colorChange_FrameColor: function (e) {
        this.model.attributes.FrameColor = e.value.toRgbString();
    },
    colorChange_HandsColor: function (e) {
        this.model.attributes.HandsColor = e.value.toRgbString();
    },
    setModel: function () {
        var EnableClock = this.model.attributes.EnableClock;
        this.ui.analogClock_checkboxEnableClock.is(':checked') ? EnableClock = 1 : EnableClock = 0;

        var Enable24Hours = this.model.attributes.Enable24Hours;
        this.ui.analogClock_checkboxEnable24Hours.is(':checked') ? Enable24Hours = 1 : Enable24Hours = 0;

        var EnableDate = this.model.attributes.EnableDate;
        this.ui.analogClock_checkboxEnableDate.is(':checked') ? EnableDate = 1 : EnableDate = 0;



        this.model.set({
            Font3_ColorType: this.ui.analogClock_select_Font3_ColorType.val(),
            Font3_FontType: this.ui.analogClock_select_Font3_FontType.val(),
            Font3_Font: this.ui.analogClock_dropDown_Font3.val(),
            //Font3_Size: this.ui.analogClock_dropDown_Font3_Size.val(),
            Font3_Style: this.ui.analogClock_dropDown_Font3_Style.val(),
            Margin3_Top: this.ui.analogClock_input_margin3_Top.val(),
            Margin3_Bottom: this.ui.analogClock_input_margin3_Bottom.val(),
            Margin3_Left: this.ui.analogClock_input_margin3_Left.val(),
            Margin3_Right: this.ui.analogClock_input_margin3_Right.val(),

            EnableClock: EnableClock,
            Enable24Hours: Enable24Hours,
            EnableDate: EnableDate,
            Font1_ColorType: this.ui.analogClock_select_Font1_ColorType.val(),
            Font1_FontType: this.ui.analogClock_select_Font1_FontType.val(),
            Font1_Font: this.ui.analogClock_dropDown_Font1.val(),
            Font1_Size: this.ui.analogClock_dropDown_Font1_Size.val(),
            Font1_Style: this.ui.analogClock_dropDown_Font1_Style.val(),
            Font1_TextAlign: this.ui.analogClock_dropDown_Font1_TextAlign.val(),
            Margin1_Top: this.ui.analogClock_input_margin1_Top.val(),
            Margin1_Bottom: this.ui.analogClock_input_margin1_Bottom.val(),
            Margin1_Left: this.ui.analogClock_input_margin1_Left.val(),
            Margin1_Right: this.ui.analogClock_input_margin1_Right.val(),
            DateFormat: this.ui.analogClock_dropDown_DateFormat.val(),
            Font2_ColorType: this.ui.analogClock_select_Font2_ColorType.val(),
            Font2_FontType: this.ui.analogClock_select_Font2_FontType.val(),
            Font2_Font: this.ui.analogClock_dropDown_Font2.val(),
            Font2_Size: this.ui.analogClock_dropDown_Font2_Size.val(),
            Font2_Style: this.ui.analogClock_dropDown_Font2_Style.val(),
            Font2_TextAlign: this.ui.analogClock_dropDown_Font2_TextAlign.val(),
            Margin2_Top: this.ui.analogClock_input_margin2_Top.val(),
            Margin2_Bottom: this.ui.analogClock_input_margin2_Bottom.val(),
            Margin2_Left: this.ui.analogClock_input_margin2_Left.val(),
            Margin2_Right: this.ui.analogClock_input_margin2_Right.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    }
});