﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_rss from './tpl/analogClock.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_rss();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.setupSetting, this);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'rightContent:setupCssStyle', function () {
            this.setupStyle();
        });
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'analogClock': '#analogClock',
        'clock': '#clock',
        'divStamp': '#divStamp',
        'ampm': '#ampm'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        var self = this;
        var nowLocalDate = new Date();
        this.editorClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();

        this.ui.divStamp.addClass(this.editorClass);
        this.searchElement = $('.' + this.editorClass)[0];

        this.setupSetting();
    },
    onDestroy: function () {
    },
    AnalogClock: function (id, option) {

        var dateTimeFormat = function (time) {
            var str = "";
            str += time.getYear() + (time.getYear() > 1900 ? 0 : 1900) + "-";
            str += time.getMonth() + 1 + "-";
            str += time.getDate() + "<br/> ";
            str += time.getHours() + ":";
            str += time.getMinutes() + ":";
            str += time.getSeconds();
            return str;
        }

        if (!option)
            option = {};//avoid undefined exception
        this.foreColor = option.foreColor ? option.foreColor : "#000";//text color
        this.bgColor = option.bgColor ? option.bgColor : "#eee";
        this.handsColor = option.handsColor ? option.handsColor : '#000';
        this.width = option.width ? option.width : 400;
        this.fontFamily = option.fontFamily ? option.fontFamily : 'open-sans';
        this.fontColor = option.fontColor ? option.fontColor : '#000';

        //this.container = document.getElementById(id);
        this.container = this.ui.clock[0];
        
        if (!this.container)
            return;
        this.container.style.fontcolor = this.foreColor;

        //the static part

        //the outer panel of the clock, including the background
        this.panel = document.createElement("div");//border-radius:50%;background-color:#eee;border:solid 1px #ccc;width:400px;height:400px;position:relative;
        this.panel.style.borderRadius = "50%";
        this.panel.style.backgroundColor = this.bgColor;
        this.panel.style.border = "solid 8px";// #ccc";
        this.panel.style.borderColor = this.foreColor;

        this.panel.style.width = this.width + 16 + "px";
        this.panel.style.height = this.width + 16 + "px";
        this.panel.style.position = "relative";
        this.panel.style.margin = "0 auto";
        this.container.appendChild(this.panel);

        //the digital clock on the panel
        var label = document.createElement("h4");//width:80%;line-height:40px;text-align:center;margin-top:250px;color:#333;
        label.style.width = "100%";
        label.style.textAlign = "center";
        label.style.fontWeight = "normal";
        label.style.fontSize = this.width / 15 + "px";
        label.style.marginTop = this.width * 0.6 + "px";
        label.style.color = this.foreColor;
        label.innerHTML = dateTimeFormat(new Date());
        if (this.width >= 100)//hide if the width is not enough to show the digital clock
            //this.panel.appendChild(label);

        //the container of hour numbers on the panel
        var ul = document.createElement("ul");//padding:0;margin:0;list-style:none;position:absolute;left:180px;            
        ul.style.height = "100%";

        ul.style.padding = "0";
        ul.style.margin = "0";
        ul.style.listStyle = "none";
        ul.style.position = "absolute";
        ul.style.width = 40 + "px";
        ul.style.top = 0;
        ul.style.left = this.width / 2 - 20 + "px";
        ul.style.color = this.fontColor;
        this.panel.appendChild(ul);

        //the list of hour numbers on the panel
        for (var i = 0; i <= 5; i++) {
            if (!localStorage)//if html5 not supported
                break;

            var list = document.createElement("li");//padding:0;margin:0; position: absolute; text-align:center;width:40px;height:400px;font-size:40px; 
            list.style.padding = "0";
            list.style.margin = "0";
            list.style.position = "absolute";
            list.style.textAlign = "center";
            list.style.width = "40px";
            list.style.height = this.width + "px";
            list.style.fontSize = this.width / 10 + "px";
            ul.appendChild(list);

            list.style.transform = "rotate(" + 360 / 12 * (i + 1) + "deg)";

            //a pair of numbers, such as  1 and 7, 3 and 9, etc.
            var numTop = document.createElement("div");//width:100%;position:absolute;text-align:center;
            numTop.style.width = "100%";
            numTop.style.position = "absolute";
            numTop.style.textAlign = "center";
            numTop.style.fontColor = this.fontColor;
            numTop.style.fontFamily = this.fontFamily;
            numTop.innerHTML = i + 1;
            if (this.width < 100)
                numTop.innerHTML = "●";

            numTop.className = "analogClock_hours";

            list.appendChild(numTop);

            numTop.style.transform = "rotate(" + -360 / 12 * (i + 1) + "deg)";//recover the rotation

            var numBottom = document.createElement("div");//width:100%;position:absolute;text-align:center;
            numBottom.style.width = "100%";
            numBottom.style.position = "absolute";
            numBottom.style.textAlign = "center";
            numBottom.style.bottom = "0";
            numBottom.style.fontColor = this.fontColor;
            numBottom.style.fontFamily = this.fontFamily;
            numBottom.innerHTML = i + 7;
            if (this.width < 100)
                numBottom.innerHTML = "●";

            numBottom.className = "analogClock_hours";

            list.appendChild(numBottom);

            numBottom.style.transform = "rotate(" + -360 / 12 * (i + 1) + "deg)";//recover the rotation
        }

        //hour hand
        var hour = document.createElement("div");//width:8px;height:8px;left:196px;top:96px;border-top:solid 100px #ff6a00; border-bottom-width:100px;  
        var hourWidth = this.width * 0.03;
        var hourTop = this.width * 0.25 - (hourWidth * 0.5);
        var hourleft = this.width * 0.5 - hourWidth * 0.5;
        hour.style.width = hourWidth + "px";
        hour.style.height = hourWidth + "px";
        hour.style.position = "absolute";
        hour.style.border = "solid 0px transparent";
        hour.style.left = hourleft + "px";
        hour.style.top = hourTop + "px";
        hour.style.borderTop = "solid " + (this.width * 0.5 - hourTop) + "px " + this.handsColor;//#f60";
        hour.style.borderBottomWidth = (this.width * 0.5 - hourTop) + "px";
        if (localStorage) //only visible in HTML5 supported browser
            this.panel.appendChild(hour);

        //minute hand
        var min = document.createElement("div");//width:4px;height:4px;left:198px;top:48px;border-top:solid 150px #0094ff; border-bottom-width:150px;  
        var minWidth = this.width * 0.02;
        var minTop = this.width * 0.1 - (minWidth * 0.5);
        var minleft = this.width * 0.5 - minWidth * 0.5;
        min.style.width = minWidth + "px";
        min.style.height = minWidth + "px";
        min.style.position = "absolute";
        min.style.border = "solid 0px transparent";
        min.style.left = minleft + "px";
        min.style.top = minTop + "px";
        min.style.borderTop = "solid " + (this.width * 0.5 - minTop) + "px " + this.handsColor;//#09f";
        min.style.borderBottomWidth = (this.width * 0.5 - minTop) + "px";
        if (localStorage)
            this.panel.appendChild(min);

        //second hand
        var sec = document.createElement("div");//width:1px;height:1px;position:absolute;border:solid 0px transparent;left:199px;top:19px;border-top:solid 180px #333; border-bottom-width:180px;  
        var secWidth = this.width * 0.01;
        var secTop = this.width * 0.05;
        sec.style.width = secWidth + "px";
        sec.style.height = secWidth + "px";
        sec.style.position = "absolute";
        sec.style.border = "solid 0px transparent";
        sec.style.left = (this.width * 0.5 - secWidth) + "px";
        sec.style.top = secTop + "px";
        sec.style.borderTop = "solid " + (this.width * 0.5 - secTop) + "px " + this.foreColor;
        sec.style.borderBottomWidth = (this.width * 0.5 - secTop) + "px";
        if (localStorage)
            this.panel.appendChild(sec);

        //the center point
        var point = document.createElement("div");//content:"";background-color:#333;width:10px;height:10px;position:absolute;left:195px;top:195px;border-radius:50%; 
        var pointWidth = this.width * 0.05;
        point.style.width = pointWidth + "px";
        point.style.height = pointWidth + "px";
        point.style.position = "absolute";
        point.style.backgroundColor = this.foreColor;
        point.style.left = this.width * 0.5 - (pointWidth * 0.5) + "px";
        point.style.top = this.width * 0.5 - (pointWidth * 0.5) + "px";
        point.style.borderRadius = "50%";
        if (localStorage)
            this.panel.appendChild(point);


        //start the clock (the animation part)
        this.loop = setInterval(function () {
            var now = new Date();
            //label.innerHTML = dateTimeFormat(now);

            var roS = 1.0 * 360 / 60 * now.getSeconds();
            var roM = 1.0 * 360 / 60 * now.getMinutes();
            var roH = 1.0 * 360 / 12 * (now.getHours() % 12) + 1.0 * 360 / 12 * (now.getMinutes() / 60);

            sec.style.transform = 'rotate(' + roS + 'deg)';
            min.style.transform = 'rotate(' + roM + 'deg)';
            hour.style.transform = 'rotate(' + roH + 'deg)';
        }, 1000);
    },
    setupStyle: function () {
        var self = this;

        //this.searchElement.find('#clock').empty();
        //$('.' + this.editorClass).find('#clock').empty();

        this.ui.clock.empty();

        var fontFamily3;

        /*switch (this.model.attributes.Data.Font3_FontType) {
            case '0': fontFamily3 = this.model.attributes.Data.Font3_Select; break;
            case '1': fontFamily3 = this.model.attributes.Data.Font3; break;
        }*/


        fontFamily3 = this.model.attributes.Data.Font3; 

        var font3_Color;

        /*switch (this.model.attributes.Data.Font3_ColorType) {
            case '0': font3_Color = this.model.attributes.Data.Font3_ColorSelect; break;
            case '1': font3_Color = this.model.attributes.Data.Font3_Color; break;
        }*/

        font3_Color = this.model.attributes.Data.Font3_Color; 

        this.AnalogClock('clock', {
            width: 300,
            foreColor: this.model.attributes.Data.FrameColor,
            bgColor: this.model.attributes.Data.BgColor,
            handsColor: this.model.attributes.Data.HandsColor,
            fontFamily: fontFamily3,
            fontColor: font3_Color
        });//simple option


        var font3_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font3_Style
        });

        font3_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.analogClock.find('.analogClock_hours').css(entry.Css, entry.Value);
        });

        //this.ui.analogClock.find('.mydate').empty();
        this.ui.analogClock.find('.mydate').empty();
        var html = self.app.entities.collections.DigitalClockDateFormat.where({ Value: this.model.attributes.Data.DateFormat })[0];

        //this.ui.analogClock.find('.mydate').html(html.attributes.Html);
        this.ui.analogClock.find('.mydate').html(html.attributes.Html);

        var days = [];
        var months = [];
        var ampm = [];

        self.app.entities.collections.DigitalClockHelper.models[0].attributes.Days.forEach(function (entry) {
            days.push(entry.Name);
        });
        self.app.entities.collections.DigitalClockHelper.models[0].attributes.Months.forEach(function (entry) {
            months.push(entry.Name);
        });
        self.app.entities.collections.DigitalClockHelper.models[0].attributes.Ampms.forEach(function (entry) {
            ampm.push(entry.Name);
        });

        W3FCT.lang.Lang = {
            days: days,
            months: months,
            ampm: ampm
        };

        
        //var clock = document.getElementById('analogClock');
        this.ui.analogClock[0].W3FClockTimer({
            step: 100,
            /*renderer: {
                type: 'analog'
            },*/
            device: {
                //offset: -10800
            },
            lang: 'Lang'
        });

        this.ui.analogClock.find('.w3f-analog-face').css('background-color', 'transparent');

        this.ui.clock.css('margin-top', this.model.attributes.Data.Margin3_Top + 'px');
        this.ui.clock.css('margin-bottom', this.model.attributes.Data.Margin3_Bottom + 'px');
        this.ui.clock.css('margin-left', this.model.attributes.Data.Margin3_Left + 'px');
        this.ui.clock.css('margin-right', this.model.attributes.Data.Margin3_Right + 'px');

        
        switch (this.model.attributes.Data.Enable24Hours) {
            case 0: this.ui.ampm.show(); break;
            case 1: this.ui.ampm.css('display', 'none'); break;
        }

        switch (this.model.attributes.Data.EnableClock) {
            case 0: this.ui.analogClock.find('.dititalClock_time').css('display', 'none'); $('#ampm').css('display', 'none'); break;
            case 1: this.ui.analogClock.find('.dititalClock_time').show(); break;
        }

        this.ui.analogClock.find('.mytime').css('margin-top', this.model.attributes.Data.Margin1_Top + 'px');
        this.ui.analogClock.find('.mytime').css('margin-bottom', this.model.attributes.Data.Margin1_Bottom + 'px');
        this.ui.analogClock.find('.mytime').css('margin-left', this.model.attributes.Data.Margin1_Left + 'px');
        this.ui.analogClock.find('.mytime').css('margin-right', this.model.attributes.Data.Margin1_Right + 'px');

        var font1_textAlign = this.app.entities.collections.TextAlign.where({
            Value: this.model.attributes.Data.Font1_TextAlign
        });

        font1_textAlign[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.analogClock.find('.mytime').css(entry.Css, entry.Value);
        });

        //$('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1);

        //switch (this.model.attributes.Data.Font1_FontType) {
        //    case '0': $('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1_Select); break;
        //    case '1': $('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1); break;
        //}

        this.ui.analogClock.find('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1);

        this.ui.analogClock.find('.dititalClock_time').css('font-size', this.model.attributes.Data.Font1_Size);

        //switch (this.model.attributes.Data.Font1_ColorType) {
        //    case '0': $('.dititalClock_time').css('color', this.model.attributes.Data.Font1_ColorSelect); break;
        //    case '1': $('.dititalClock_time').css('color', this.model.attributes.Data.Font1_Color); break;
        //}

        this.ui.analogClock.find('.dititalClock_time').css('color', this.model.attributes.Data.Font1_Color);


        var font1_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font1_Style
        });

        font1_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.analogClock.find('.dititalClock_time').css(entry.Css, entry.Value);
        });

        this.ui.analogClock.find('.mydate').css('margin-top', this.model.attributes.Data.Margin2_Top + 'px');
        this.ui.analogClock.find('.mydate').css('margin-bottom', this.model.attributes.Data.Margin2_Bottom + 'px');
        this.ui.analogClock.find('.mydate').css('margin-left', this.model.attributes.Data.Margin2_Left + 'px');
        this.ui.analogClock.find('.mydate').css('margin-right', this.model.attributes.Data.Margin2_Right + 'px');

        var font2_textAlign = this.app.entities.collections.TextAlign.where({
            Value: this.model.attributes.Data.Font2_TextAlign
        });

        font2_textAlign[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.analogClock.find('.mydate').css(entry.Css, entry.Value);
        });

        //$('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2);

        //switch (this.model.attributes.Data.EnableDate) {
        //    case 0: this.ui.dititalClock_date.css('display', 'none'); break;
        //    case 1: this.ui.dititalClock_date.show(); break;
        //}

        switch (this.model.attributes.Data.EnableDate) {
            case 0: this.ui.analogClock.find('.dititalClock_date').css('display', 'none'); break;
            case 1: this.ui.analogClock.find('.dititalClock_date').show(); break;
        }

        //switch (this.model.attributes.Data.Font2_FontType) {
        //    case '0': $('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2_Select); break;
        //    case '1': $('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2); break;
        //}

        this.ui.analogClock.find('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2);

        this.ui.analogClock.find('.dititalClock_date').css('font-size', this.model.attributes.Data.Font2_Size);

        //switch (this.model.attributes.Data.Font2_ColorType) {
        //    case '0': $('.dititalClock_date').css('color', this.model.attributes.Data.Font2_ColorSelect); break;
        //    case '1': $('.dititalClock_date').css('color', this.model.attributes.Data.Font2_Color); break;
        //}

        this.ui.analogClock.find('.dititalClock_date').css('color', this.model.attributes.Data.Font2_Color);


        var font2_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font2_Style
        });

        font2_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.analogClock.find('.dititalClock_date').css(entry.Css, entry.Value);
        });


    }
}, Backbone.Radio.Requests);
