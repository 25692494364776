﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customViewEventListItem from './tpl/customViewEventListItem.html';
import language from '../../../common/language';
import View_CustomViewEventListItem from './customViewEventListItem';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

import View_CustomFile from '../../views/customFile/customFile';
import View_CustomEventCounter from '../../views/customEventCounter/customEventCounter';
import View_CustomEventPush from '../../views/customEventPush/customEventPush';
import View_CustomEventLive from '../../views/customEventLive/customEventLive';
import View_CustomEventEditItem from '../../views/customEvent/customEventEditItem';
import View_CustomSchemaData from '../../views/customSchemaData/customSchemaData';
import View_CustomFormData from '../../views/customFormData/customFormData';
import View_CustomProductSheetData from '../../views/customProductSheetData/customProductSheetData';
import View_CustomEventTemplate from '../../views/customEventTemplate/customEventTemplate';
import View_CustomWebpageEditItem from '../../views/customWebpage/customWebpageEditItem';
import View_CustomQRCodeEditItem from '../../views/customQRCode/customQRCodeEditItem';
import View_CustomSchemaModelEditItem from '../../views/customSchemaModel/customSchemaModelEditItem';
import View_CustomFormModelEditItem from '../../views/customFormModel/customFormModelEditItem';
import View_CustomProductSheetModelEditItem from '../../views/customProductSheetModel/customProductSheetModelEditItem';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customViewEventListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render, this);
    },
    ui: {
        'customViewEventListItem_btnEvent': '#customViewEventListItem_btnEvent',
        'customViewEventListItem_btnEventBrowser': '#customViewEventListItem_btnEventBrowser',
        'customViewEventListItem_btnEventSchemaScreen': '#customViewEventListItem_btnEventSchemaScreen',
        'customViewEventListItem_btnEventInfoScreen': '#customViewEventListItem_btnEventInfoScreen',
        'customViewEventListItem_btnEventPoster': '#customViewEventListItem_btnEventPoster',
        'customViewEventListItem_div_main': '#customViewEventListItem_div_main',
        'customViewEventListItem_btnEventCounter': '#customViewEventListItem_btnEventCounter',
        'customViewEventListItem_btnEventEventPush': '#customViewEventListItem_btnEventEventPush',
        'customViewEventListItem_btnEventSchemaData': '#customViewEventListItem_btnEventSchemaData',
        'customViewEventListItem_btnEventFormData': '#customViewEventListItem_btnEventFormData',
        'customViewEventListItem_btnEventEdit': '#customViewEventListItem_btnEventEdit',
        'customViewEventListItem_btnEventLive': '#customViewEventListItem_btnEventLive',
        'customViewEventListItem_btnEventWebpageEditItem': '#customViewEventListItem_btnEventWebpageEditItem',
        'customViewEventListItem_btnEventInfopageEditItem': '#customViewEventListItem_btnEventInfopageEditItem',
        'customViewEventListItem_btnEventQRCodeEditItem': '#customViewEventListItem_btnEventQRCodeEditItem',
        'customViewEventListItem_btnEventSchemaModelEditItem': '#customViewEventListItem_btnEventSchemaModelEditItem',
        'customViewEventListItem_btnEventFormModelEditItem': '#customViewEventListItem_btnEventFormModelEditItem'
    },
    events: {
        'click @ui.customViewEventListItem_btnEvent': 'btnEventClick',
        'click @ui.customViewEventListItem_btnEventBrowser': 'btnEventBrowserClick',
        'click @ui.customViewEventListItem_btnEventSchemaScreen': 'btnEventSchemaScreenClick',
        'click @ui.customViewEventListItem_btnEventInfoScreen': 'btnEventInfoScreenClick',
        'click @ui.customViewEventListItem_btnEventPoster': 'btnEventPosterClick',
        'click @ui.customViewEventListItem_btnEventCounter': 'btnEventCounterClick',
        'click @ui.customViewEventListItem_btnEventEventPush': 'btnEventEventPushClick',
        'click @ui.customViewEventListItem_btnEventSchemaData': 'btnEventSchemaDataClick',
        'click @ui.customViewEventListItem_btnEventFormData': 'btnEventFormDataClick',
        'click @ui.customViewEventListItem_btnEventEdit': 'btnEventEditClick',
        'click @ui.customViewEventListItem_btnEventLive': 'btnEventLiveClick',
        'click @ui.customViewEventListItem_btnEventWebpageEditItem': 'btnEventWebpageEditItemClick',
        'click @ui.customViewEventListItem_btnEventInfopageEditItem': 'btnEventInfopageEditItemClick',
        'click @ui.customViewEventListItem_btnEventQRCodeEditItem': 'btnEventQRCodeEditItemClick',
        'click @ui.customViewEventListItem_btnEventSchemaModelEditItem': 'btnEventSchemaModelEditItemClick',
        'click @ui.customViewEventListItem_btnEventFormModelEditItem': 'btnEventFormModelEditItemClick'
    },
    onRender: function () {
        if (this.model.attributes.Webpage > 0) {
            this.ui.customViewEventListItem_btnEvent.prop('disabled', false);
            this.ui.customViewEventListItem_btnEvent.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEvent.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEvent.prop('disabled', true);
            this.ui.customViewEventListItem_btnEvent.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEvent.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.Webpage > 0) {
            this.ui.customViewEventListItem_btnEventBrowser.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventBrowser.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventBrowser.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventBrowser.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventBrowser.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventBrowser.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.Webpage > 0 && this.model.attributes.SchemaModel > 0) {
            this.ui.customViewEventListItem_btnEventSchemaScreen.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventSchemaScreen.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventSchemaScreen.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventSchemaScreen.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventSchemaScreen.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventSchemaScreen.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.Webpage > 0 && this.model.attributes.Infopage > 0) {
            this.ui.customViewEventListItem_btnEventInfoScreen.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventInfoScreen.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventInfoScreen.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventInfoScreen.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventInfoScreen.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventInfoScreen.addClass('btn-disabled btn-event4us-disable');
        }

        var header = self.app.entities.collections.customWebpageItem.where({ Webpage: this.model.attributes.Webpage, Tag: 4 });

        if (this.model.attributes.Webpage > 0 && header.length > 0 && header[0].attributes.Body.length > 0) {
            this.ui.customViewEventListItem_btnEventPoster.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventPoster.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventPoster.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventPoster.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventPoster.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventPoster.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.Active == 0) {
            this.ui.customViewEventListItem_div_main.css('background-color', '#ff0000');
        } else {
            this.ui.customViewEventListItem_div_main.css('background-color', '#ffffff');
        }

        if (this.model.attributes.SchemaModel > 0) {
            this.ui.customViewEventListItem_btnEventSchemaData.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventSchemaData.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventSchemaData.removeClass('btn-disabled btn-event4us-disable');
            this.ui.customViewEventListItem_btnEventSchemaModelEditItem.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventSchemaModelEditItem.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventSchemaModelEditItem.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventSchemaData.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventSchemaData.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventSchemaData.addClass('btn-disabled btn-event4us-disable');
            this.ui.customViewEventListItem_btnEventSchemaModelEditItem.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventSchemaModelEditItem.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventSchemaModelEditItem.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.FormModel > 0) {
            this.ui.customViewEventListItem_btnEventFormData.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventFormData.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventFormData.removeClass('btn-disabled btn-event4us-disable');
            this.ui.customViewEventListItem_btnEventFormModelEditItem.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventFormModelEditItem.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventFormModelEditItem.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventFormData.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventFormData.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventFormData.addClass('btn-disabled btn-event4us-disable');
            this.ui.customViewEventListItem_btnEventFormModelEditItem.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventFormModelEditItem.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventFormModelEditItem.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.SchemaModel > 0) {
            this.ui.customViewEventListItem_btnEventLive.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventLive.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventLive.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventLive.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventLive.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventLive.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.Webpage > 0 ) {
            this.ui.customViewEventListItem_btnEventWebpageEditItem.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventWebpageEditItem.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventWebpageEditItem.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventWebpageEditItem.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventWebpageEditItem.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventWebpageEditItem.addClass('btn-disabled btn-event4us-disable');
        }

        if (this.model.attributes.Infopage > 0) {
            this.ui.customViewEventListItem_btnEventInfopageEditItem.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventInfopageEditItem.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventInfopageEditItem.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventInfopageEditItem.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventInfopageEditItem.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventInfopageEditItem.addClass('btn-disabled btn-event4us-disable');
        }

        var model = self.app.entities.collections.customQRCode.fullCollection.where({ id: this.model.attributes.QRCode });
        if (this.model.attributes.QRCode > 0 && model[0] != null && model[0].attributes.Name != resources.X100123) {
            this.ui.customViewEventListItem_btnEventQRCodeEditItem.prop('disabled', false);
            this.ui.customViewEventListItem_btnEventQRCodeEditItem.addClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventQRCodeEditItem.removeClass('btn-disabled btn-event4us-disable');
        } else {
            this.ui.customViewEventListItem_btnEventQRCodeEditItem.prop('disabled', true);
            this.ui.customViewEventListItem_btnEventQRCodeEditItem.removeClass('btn-secondary');
            this.ui.customViewEventListItem_btnEventQRCodeEditItem.addClass('btn-disabled btn-event4us-disable');
        }
    },
    btnEventClick: function (event) {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        var params = {
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        var dialogPreviewWebpageModel = new Entities_Empty.Model();
        dialogPreviewWebpageModel.attributes.Title = resources.X100276;
        dialogPreviewWebpageModel.attributes.Iframe = url;
        dialogPreviewWebpageModel.attributes.Button = resources.X100279;

        var dialogPreviewWebpage = new View_DialogPreviewWebpage({ model: dialogPreviewWebpageModel, parent: this });
        var callback_dialogPreviewWebpage = function (options) {
            this.stopListening(dialogPreviewWebpage, "dialog:okclicked");
        }
        this.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);
        this.app.dialogRegion.show(dialogPreviewWebpage);
    },
    btnEventBrowserClick: function (event) {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        //window.open(webpageEvent, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            autoupdate: 'true',
            updateHits: false
        };
        var url = [webpageEvent, $.param(params)].join('?');

        window.open(url);

        //window.open(webpageEvent);
    },
    btnEventSchemaScreenClick: function (event) {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageSchemaBigscreen = config.webApiUrl() + '/events/' + friendlyUrl;
        //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            updateHits: false
        };
        var url = [webpageSchemaBigscreen, $.param(params)].join('?');

        url += '#' + resources.X100419.toLowerCase();

        window.open(url);

        //window.open(webpageSchemaBigscreen);
    },
    btnEventInfoScreenClick: function (event) {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpageInfo = config.webApiUrl() + '/events/' + friendlyUrl;
        //window.open(webpageInfo, '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            updateHits: false
        };
        var url = [webpageInfo, $.param(params)].join('?');

        url += '#' + resources.X100454.toLowerCase();

        window.open(url);

        //window.open(webpageInfo);
    },
    btnEventPosterClick: function (event) {
        var friendlyUrl = this.model.attributes.id;
        if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }

        var webpagePoster = config.webApiUrl() + '/events/' + friendlyUrl;
        //window.open(webpagePoster , '_blank', 'fullWindow=yes, scrollbars=yes, width=' + window.outerWidth + ', height=' + window.outerHeight);

        var params = {
            updateHits: false
        };
        var url = [webpagePoster, $.param(params)].join('?');

        url += '#' + resources.X100141.toLowerCase();

        window.open(url);

        //window.open(webpagePoster);
    },
    btnEventCounterClick: function (event) {
        var self = this;
        var customEventCounter = new View_CustomEventCounter({ collection: self.app.entities.collections.customEvent, app: self.app, designMode: true, event: this.model.attributes.id });
        self.app.designRegion.show(customEventCounter);
    },
    btnEventEventPushClick: function (event) {
        var self = this;
        var customEventPush = new View_CustomEventPush({ collection: self.app.entities.collections.customEvent, app: self.app, designMode: true, event: this.model.attributes.id });
        self.app.designRegion.show(customEventPush);
    },
    btnEventSchemaDataClick: function (event) {
        var self = this;
        var settingModel = { attributes: {} };
        var customSchemaData = new View_CustomSchemaData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, designMode: true, event: this.model.attributes.id });
        self.app.designRegion.show(customSchemaData);
    },
    btnEventFormDataClick: function (event) {
        var self = this;
        var settingModel = { attributes: {} };
        var customFormData = new View_CustomFormData({ collection: self.app.entities.collections.customEvent, settingModel: settingModel, app: self.app, designMode: true, event: this.model.attributes.id });
        self.app.designRegion.show(customFormData);
    },
    btnEventEditClick: function (event) {
        var self = this;
        var model = app.entities.collections.customEvent.fullCollection.where({ id: this.model.attributes.id });
        var customEventEditItem = new View_CustomEventEditItem({ model: model[0], app: self.app, designMode: true, event: this.model.attributes.id });
        self.app.designRegion.show(customEventEditItem);
    },
    btnEventLiveClick: function (event) {
        var self = this;
        self.app.entities.collections.customEventLive.fetch({
            success: function (mode, response) {
                var customEventLive = new View_CustomEventLive({ collection: self.app.entities.collections.customEventLive, app: self.app, designMode: true, event: self.model.attributes.id });
                self.app.designRegion.show(customEventLive);
            },
            error: function (mode, response) {
            }
        });
    },
    btnEventWebpageEditItemClick: function (event) {
        var self = this;
        window.app.router.navigate("#customWebpageEditItem");
        window.app.router.customWebpageEditItem(self.model.attributes.Webpage);
    },
    btnEventInfopageEditItemClick: function (event) {
        var self = this;
        window.app.router.navigate("#custoInfopageEditItem");
        window.app.router.customInfopageEditItem(self.model.attributes.Infopage);
    },
    btnEventQRCodeEditItemClick: function (event) {
        var self = this;
        var model = self.app.entities.collections.customQRCode.fullCollection.where({ id: this.model.attributes.QRCode });
        if (model[0] != null) {
            var customQRCodeEditItem = new View_CustomQRCodeEditItem({ collection: self.app.entities.collections.customQRCode, model: model[0], app: self.app, designMode: true, event: this.model.attributes.id });
            self.app.designRegion.show(customQRCodeEditItem);
        }
    },
    btnEventSchemaModelEditItemClick: function (event) {
        var self = this;
        var model = self.app.entities.collections.customSchemaModel.fullCollection.where({ id: this.model.attributes.SchemaModel });
        if (model[0] != null) {
            var customSchemaModelEditItem = new View_CustomSchemaModelEditItem({ model: model[0], app: self.app, designMode: true });
            self.app.designRegion.show(customSchemaModelEditItem);
        }
    },
    btnEventFormModelEditItemClick: function (event) {
        var self = this;
        var model = self.app.entities.collections.customFormModel.fullCollection.where({ id: this.model.attributes.FormModel });
        if (model[0] != null) {
            var customFormModelEditItem = new View_CustomFormModelEditItem({ model: model[0], app: self.app, designMode: true });
            self.app.designRegion.show(customFormModelEditItem);
        }
    }

});
