var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetModelListItem_empty"> <div class="col-xs-4 col-sm-4 col-md-4 col-4">  <div style="margin-top:10px"> <p class="name" style="overflow: hidden;text-overflow: ellipsis; text-align:center; min-height:40px">'+
((__t=( model.Name ))==null?'':__t)+
'</p> <div class="row"> <div class="col-md-12"> <button type="button" class="btn btn-primary btn-block" id="customProductSheetModelListItem_btnEdit">'+
((__t=( resources['X100288'] ))==null?'':__t)+
'</button> </div> <div class="col-md-12"> <button type="button" class="btn btn-secondary btn-block" id="customProductSheetModelListItem_btnDelete">'+
((__t=( resources['X100289'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
