﻿'use strict'

import dummy2 from '../../common/app/appPlugins';
import dummy from './plugins';
import $ from 'jquery';
import * as Bootstrap from 'bootstrap';
import AppRouter from './router';
import App from './app';
import * as Backbone from 'backbone';

//230311 1128

$(document).ready(function () {
    var app = window.app = new App();

    app.oldbuildHeaderJson = '';
    app.oldbuildHeaderInfoJson = '';
    app.eventUpdateQuestion = false;

    app.router = new AppRouter({ app: app });

    //var timeout_id = setTimeout(function () {
    //}, 10000);

    app.start({});


    $(document).bind('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange', function (e) {
        if (
            document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled
        ) {
            var fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement;

            if (fullscreenElement) {
                //$('Body').removeClass('sidebar-icon-only');
                //console.log('Leaving full-screen mode...');
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customInfopageEditItem:requestFullScreen');
                userChannel.trigger('customWebpageEditItem:requestFullScreen');
            } else {
                //console.log('Entering full-screen mode...');
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customInfopageEditItem:cancelFullScreen');
                userChannel.trigger('customWebpageEditItem:cancelFullScreen');
                
            }
        }
    });

    window.onresize = function (event) {
        var maxHeight = window.screen.height,
            maxWidth = window.screen.width,
            curHeight = window.innerHeight,
            curWidth = window.innerWidth;

        if (maxWidth == curWidth && maxHeight == curHeight) {
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItem:requestFullScreen');
            userChannel.trigger('customWebpageEditItem:requestFullScreen');
        } else {
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItem:cancelFullScreen');
            userChannel.trigger('customWebpageEditItem:cancelFullScreen');
        }
    }
});
