﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

export default Marionette.Region.extend({
    el: '#dialogRouterRegion',
    onShow: function (view) {
        this.dialogRouterRegion = view.dialogRouterRegion;

        var self = this;

        self.setupBindings(view);
        self.$el.append(view.$el[0].innerHTML);
        //self.$el.append(view.render());

        this.removeModal = this.$el.find(view.dialogRouterRegion).modal();
        this.removeModal.on('hidden.bs.modal', function () {
            self.closeDialog();
            $('.modal-backdrop').remove();

        }).one('click', '#closebtn', function () {
            self.hideDialog();
        });

        this.removeModal.modal();

        //Make pause after show
        this.sleep(2000);
    },
    sleep: function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    hideDialog: function () {
        $(this.dialogRouterRegion).modal("hide");
    },
    setupBindings: function (view) {
        this.listenTo(view, "dialog:close", this.hideDialog);
    },
    closeDialog: function () {
        this.stopListening();
        this.reset();
        this.removeModal.off();

    },
    ee : function () {
        $('#dialogRouterRegion').empty();
    }
});