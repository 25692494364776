'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginFileValidateType from '../../plugins/filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';



import tpl_customDepartmentImportUser from './tpl/customDepartmentImportUser.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customDepartmentImportUser();
    },
    initialize: function () {
        this.app = this.options.app;

        this.localSetting = new Entities_Empty.Model();
    },
    ui: {
        'customDepartmentImportUser_filePond': '#customDepartmentImportUser_filePond'

    },
    events: {
    },
    childEvents: {
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;
        FilePond.registerPlugin(
            FilePondPluginFileValidateType,
            FilePondPluginFileEncode,
        );

        var labelIdle = resources.X200141 + ' <span class="filepond--label-action">' + resources.X200142 + '</span>';

        var pond = FilePond.create(this.ui.customDepartmentImportUser_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            acceptedFileTypes: [
                'text/csv',
                'application/vnd.ms-excel',
                'application/csv'
            ],
            allowMultiple: false,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();
                    var mimeType = self.base64MimeType(base64);
                    var fileData = base64.split(',')[1];
                    var base64 = fileItem.getFileEncodeBase64String();
                    //var txt = decodeURIComponent(escape(window.atob(base64)));
                    var txt = decodeURIComponent(window.atob(base64));

                    var callbackFinish = function () {
                        pond.removeFile(fileItem);

                        self.collection.fullCollection.sort();
                        if (self.collection.state.currentPage < self.collection.state.lastPage) {
                            self.collection.getPage(self.collection.state.currentPage);
                        }
                        else {
                            self.collection.getLastPage();
                        }
                    }

                    self.import(txt, callbackFinish);
                }
            }
        });
    },
    import: function (file, callbackFinish) {
        var self = this;
        var array = [];

        var itemTotal = 0;
        var itemSave = 0;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X200147;
        dialogWaitingModel.attributes.Text = resources.X100251;
        dialogWaitingModel.attributes.Button = resources.X100279;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        dialogWaiting.onShow = function () {
            setTimeout(function () {
                app.progress_count++;

                var allTextLines = file.split(/\r\n|\n/);
                var entries = allTextLines[0].split(';');
                var lines = [];

                for (var j = 1; j < allTextLines.length; j++) {
                    var entries = allTextLines[j].split(';');

                    var tmpModel = new this.app.entities.collections.customDepartmentTmp.model();
                    tmpModel.attributes.AuthUserId = config.authUserId();
                    tmpModel.attributes.Name = entries[0];
                    tmpModel.attributes.File = ''

                    if (entries[1] != undefined && entries[1] != 'nopicture') {
                        tmpModel.attributes.File = entries[1];
                    }

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 4 })[0];
                    var strJson_4 = JSON.stringify(defaultModel.toJSON());

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 5 })[0];
                    var strJson_5 = JSON.stringify(defaultModel.toJSON());

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 10 })[0];
                    var strJson_10 = JSON.stringify(defaultModel.toJSON());

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 20 })[0];
                    var strJson_20 = JSON.stringify(defaultModel.toJSON());

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 30 })[0];
                    var strJson_30 = JSON.stringify(defaultModel.toJSON());

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 42 })[0];
                    var strJson_42 = JSON.stringify(defaultModel.toJSON());

                    var defaultModel = self.app.entities.collections.Module.where({ ModuleType: 110 })[0];
                    var strJson_110 = JSON.stringify(defaultModel.toJSON());

                    tmpModel.attributes.Data = '[{ "Collection" : [' + strJson_4 + ',' + strJson_5 + ','  + strJson_10 + ',' + strJson_20 + ',' + strJson_30 + ',' + strJson_42 + ',' + strJson_110 +']}]';


                    //tmpModel.attributes.Data = '[{ "Collection" : ' + strJson + '}]';
                    tmpModel.attributes.BackgroundColor = 'rgba(160, 16, 16, 0.65)';
                    tmpModel.attributes.Module_DigitalClock = 1;
                    tmpModel.attributes.TimerView = 10;
                    tmpModel.attributes.PersonOnView = 20;
                    tmpModel.attributes.ImageFilename = '';
                    tmpModel.attributes.ImageData = '';

                    itemTotal++;

                    self.app.entities.collections.customDepartmentTmp.create(tmpModel, {
                        wait: true,
                        success: function (response) {
                            itemSave++;

                            if (itemTotal == itemSave) {
                                dialogWaiting.triggerMethod('dialog:close');
                                callbackFinish();
                            }
                        }
                    });

                }
            }, 1000);
        };

        this.app.dialogRegion.show(dialogWaiting);
    },
    base64MimeType: function (encoded) {
        var result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    }
});