﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

//import * as PDFObject from 'videoobjects';

import tpl_video from './tpl/video.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';


import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({

    getTemplate: function () {
        return tpl_video();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.render, this);

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(userChannel, 'video:play', function (/*parent, */moduleDiv, moduleRegion) {
            //if (this._parent.$el == parent._parent.$el) {
              //if (this._parent.$el == parent.$el) {
            if (this.model.attributes.ModuleDiv == moduleDiv && this.model.attributes.ModuleRegion == moduleRegion) {
                switch (this.model.attributes.Play) {
                    case 1: this.play(); break;
                    case 0: this.pause(); break;
                }
            }
        });

    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'video_video': '#video_video'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        this.setupSetting();
    },
    setupStyle() {
        var param = "";

        var src = String.format(this.model.attributes.Data.Url, this.model.attributes.Data.InfoFile_FileUrl, param);
        this.ui.video_video.attr('src', src);

        this.ui.video_video[0].volume = this.model.attributes.Data.Volume / 100;

        if (this.model.attributes.Data.Loop == 1) {
            //this.ui.video_video[0].loop = this.model.attributes.Data.Loop;
            this.ui.video_video.attr('loop','loop');
        } else if (this.model.attributes.Data.Loop == 0) {
            this.ui.video_video.removeAttr('loop');
        }

        if (this.model.attributes.Data.Autoplay == 1) {
            //this.ui.video_video[0].loop = this.model.attributes.Data.Loop;
            this.ui.video_video.attr('autoplay','true');
        } else if (this.model.attributes.Data.Autoplay == 0) {
            this.ui.video_video.attr('autoplay','false');
        }
    },
    onDestroy: function () {
    },
    play: function () {
        this.ui.video_video[0].play();
    },
    pause: function () {
        this.ui.video_video[0].pause();
    },
}, Backbone.Radio.Requests);
