var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div> <div class="col-12 grid-margin stretch-card" style="display:none"> <div class="card"> <div class="card-body" style="padding-top: 0px;padding-bottom: 0px"> <div class="panel"> <form class="forms-sample template-demo"> <div class="form-group"> <label for="customInfopageEditItemTemplateTopbar_input_Size">'+
((__t=( resources['X100360'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemTemplateTopbar_input_Zoom" class="ul-slider slider-success" style="height: 20px;margin-top: 0px"></div> </div> </form> </div> </div> </div> </div> </div>';
}
return __p;
};
