﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import config from '../../../common/config';


import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginFileValidateType from '../../plugins/filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';

import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';


import tpl_customUserImport from './tpl/customUserImport.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomUserImportUser from './customUserImportUser';
import View_CustomUserImportImage from './customUserImportImage';
import View_CustomUserImportList from './customUserImportList';

//import View_CustomUserImportList from './customUserImportList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customUserImport();
    },
    regions: {
        customUserImportUser: "#customUserImportUser",
        customUserImportImage: "#customUserImportImage",
        customUserImportList: "#customUserImportList",
        customUserImportPagination: "#customUserImportPagination",
        information: "#customUserImportInformation",
    },
    onBeforeShow: function () {
        //this.customUserImportUser.show(new View_CustomUserImportUser({ collection: this.collection, app: this.app }));
        //this.customUserImportImage.show(new View_CustomUserImportImage({ collection: this.collection, app: this.app }));

        this.customUserImportList.show(new View_CustomUserImportList({ collection: this.collection, app: this.app }));
        this.customUserImportPagination.show(new View_Pagination({ collection: this.collection, app: this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    ui: {
        'example-vertical-wizard': '#example-vertical-wizard',
        'example-validation-form': '#example-validation-form',
        'example-vertical-wizard': '#example-vertical-wizard',
        'customUserImportUser_filePond': '#customUserImportUser_filePond',
        'customUserImport_cb_confirm': '#customUserImport_cb_confirm',
        'customUserImportImage_btnSubmit': '#customUserImportImage_btnSubmit',
        'help': '#help'

    },
    events: {
        'click #customUserImport_cb_confirm': 'customUserImport_cb_confirm_clicked',
        'click #help': 'help'
    },
    customUserImport_cb_confirm_clicked(event) {
        this.options.confirm = !this.options.confirm;
    },
    initialize: function () {
        this.app = this.options.app;

        this.options.confirm = false;

        //this.collection.on('change', this.render, this);
        //this.listenTo(this.collection, 'change', this.render, this);
        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customUserImport');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },
    onRender: function () {
        this.renderInformation();
        this.ui.customUserImport_cb_confirm.attr('checked', false);

        this.ui.customUserImportImage_btnSubmit.attr('href', config.UserCsv());
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X200090, Information: resources.X200092 });
            this.informationCollection.add(tmpModel);
        }
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
    },
    onAttach() {
        var self = this;
        var form = $("#example-form");
        form.children("div").steps({
            headerTag: "h3",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            onFinished: function (event, currentIndex) {
                alert("Submitted!");
            }
        });
        var validationForm = $("#example-validation-form");
        validationForm.val({
            errorPlacement: function errorPlacement(error, element) {
                element.before(error);
            },
            rules: {
                confirm: {
                    equalTo: "#password"
                }
            }
        });
        validationForm.children("div").steps({
            headerTag: "h3",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            onStepChanging: function (event, currentIndex, newIndex) {
                alert("dff");


                validationForm.val({
                    ignore: [":disabled", ":hidden"]
                })
                return validationForm.val();
            },
            onFinishing: function (event, currentIndex) {
                validationForm.val({
                    ignore: [':disabled']
                })
                return validationForm.val();
            },
            onFinished: function (event, currentIndex) {
                alert("Submitted!");
            }
        });
        var verticalForm = $("#example-vertical-wizard");
        verticalForm.children("div").steps({
            headerTag: "h3",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            stepsOrientation: "vertical",
            onFinished: function (event, currentIndex) {
                if (self.options.confirm && self.collection.fullCollection.length > 0) {
                    self.updateAll();
                }
                else if (self.collection.fullCollection.length == 0) {
                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100229;
                    dialogBoxModel.attributes.Text = resources.X200136;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                    var callback2 = function (options) {
                        self.stopListening(dialogBox, "dialog:okclicked", callback2);
                    }
                    self.listenTo(dialogBox, "dialog:okclicked", callback2);
                    self.app.dialogRegion.show(dialogBox);
                } else if (!self.options.confirm) {

                    var dialogBoxModel = new Entities_Empty.Model();
                    dialogBoxModel.attributes.Title = resources.X100229;
                    dialogBoxModel.attributes.Text = resources.X200138;
                    dialogBoxModel.attributes.Button = resources.X100279;

                    var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                    var callback2 = function (options) {
                        self.stopListening(dialogBox, "dialog:okclicked", callback2);
                    }
                    self.listenTo(dialogBox, "dialog:okclicked", callback2);
                    self.app.dialogRegion.show(dialogBox);
                }
            }
        });

        $('a[href$="previous"]').text(resources.X200129);
        $('a[href$="next"]').text(resources.X200130);
        $('a[href$="finish"]').text(resources.X200131);

        self.customUserImportUser.show(new View_CustomUserImportUser({ collection: this.collection, app: this.app }));
        self.customUserImportImage.show(new View_CustomUserImportImage({ collection: this.collection, app: this.app }));
    },
    updateAll: function () {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X200126;
        dialogWaitingModel.attributes.Text = resources.X200093;
        dialogWaitingModel.attributes.Button = resources.X200025;

        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });

        var callback = function (id) {
            dialogWaiting.triggerMethod('dialog:close');
            self.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        self.listenTo(dialogWaiting, "dialog:okclicked");
        self.app.dialogRegion.show(dialogWaiting);

        var itemSave = 0;
        var itemTotal = self.collection.fullCollection.length;

        self.collection.models[0].destroy({
            wait: true,
            silent: true,
            route: 'route02',
            success: function (model, response) {
                $.when(
                    self.app.entities.collections.customUser.fetch({
                        route: 'route01',
                        success: function (mode, response) {
                        },
                        error: function (mode, response) {
                        }
                    }),
                    self.app.entities.collections.customUserTmp.fetch({
                        success: function (mode, response) {
                        },
                        error: function (mode, response) {
                        }
                    })
                ).done(function () {
                    dialogWaiting.triggerMethod('dialog:close');

                    setTimeout(function () {
                        var dialogBoxModel = new Entities_Empty.Model();
                        dialogBoxModel.attributes.Title = resources.X200126;
                        dialogBoxModel.attributes.Text = resources.X200137;
                        dialogBoxModel.attributes.Button = resources.X100279;

                        var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                        var callback2 = function (options) {
                            self.stopListening(dialogBox, "dialog:okclicked", callback2);
                            $('.modal-backdrop').remove();

                            this.app.router.navigate("#customUserImport");
                            this.app.router.customUserImport();
                        }
                        self.listenTo(dialogBox, "dialog:okclicked", callback2);
                        self.app.dialogRegion.show(dialogBox);
                    }, 1000);
                })
            },
            error: function (model, response) {
            }
        });
    }
}, Backbone.Radio.Requests);