var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="empty"> <div class="card-body"> <div class="row"> <button type="button" id="customEventTemplateListItem_btnCreateEvent" class="btn btn-outline-secondary btn-sm">'+
((__t=( resources['X101478'] ))==null?'':__t)+
'</button>  </div> <div id="owlCarouselList"></div> <div class="card-body"> <h4 class="card-title mt-3">'+
((__t=( model.EventName ))==null?'':__t)+
'</h4> <p class="card-text">'+
((__t=( model.Description ))==null?'':__t)+
'</p> </div> </div> <div id="customQRCodeInsert_qrCodeContainer" style="text-align:center;display:none"> <div id="customQRCodeInsert_container"></div> <img id="customQRCodeInsert_qrCodeLogo" crossorigin="anonymous" src="" style="visibility:visible; height:0px"> </div> </div>';
}
return __p;
};
