var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="digitalClock_empty"> <div class="modal fade" id="pomodoro_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X200286'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X200286'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home4-tab-1" data-toggle="tab" href="#home4-1" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X200291'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home4-1" role="tabpanel" aria-labelledby="home4-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-3 col-sm-6 col-md-3 col-xl-3"> <div id="pomodoro_group_PomodoroTime" class="form-group"> <label>'+
((__t=( resources['X200287'] ))==null?'':__t)+
'</label> <input class="form-control" id="pomodoro_inputPomodoroTime" value="'+
((__t=( model.PomodoroTime))==null?'':__t)+
'"> </div> </div>  </div> <div class="row"> <div class="col-3 col-sm-6 col-md-3 col-xl-3"> <div id="pomodoro_group_ShortBreak" class="form-group"> <label>'+
((__t=( resources['X200288'] ))==null?'':__t)+
'</label> <input class="form-control" id="pomodoro_inputShortBreak" value="'+
((__t=( model.ShortBreak))==null?'':__t)+
'"> </div> </div>  </div> <div class="row"> <div class="col-3 col-sm-6 col-md-3 col-xl-3"> <div id="pomodoro_group_LongBreak" class="form-group"> <label>'+
((__t=( resources['X200289'] ))==null?'':__t)+
'</label> <input class="form-control" id="pomodoro_inputLongBreak" value="'+
((__t=( model.LongBreak))==null?'':__t)+
'"> </div> </div>  </div> <div class="form-group"> <label for="pomodoro_input_LongBreakCycle">'+
((__t=( resources['X200290'] ))==null?'':__t)+
'</label> <div id="pomodoro_input_LongBreakCycle" class="ul-slider slider-success" style="height: 20px"></div> <p class="mt-3">'+
((__t=( resources['X200292'] ))==null?'':__t)+
' <span id="pomodoro_input_LongBreakCycleValue">'+
((__t=( model.LongBreakCycle))==null?'':__t)+
'</span></p> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="pomodoro_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="pomodoro_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
