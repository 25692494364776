import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Calculation: {}
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customSubscriptionJob/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    }
});

//export var Collection = Backbone.Collection.extend({
//    model: Model,
//    constructor: function () {
//        Backbone.Collection.prototype.constructor.apply(this, arguments);
//    },
//    "sync": function (method, model, options) {
//        if (method == 'read') {
//            options.url = config.webApiUrl() + '/api/customSubscription/-1?AuthUserId=' + config.authUserId();
//        }
//        return Backbone.sync(method, model, options);
//    }
//});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 6,
    },
    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },
    options: {
        full: false
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customSubscriptionJob/-1?AuthUserId=' + config.authUserId();
        }
        
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
             : a.attributes.id < b.attributes.id ? 1
             : 0;
    }
});

