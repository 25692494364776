﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as plugins_qrcode from '../../plugins/qrcode';
import * as nouislider from 'nouislider';


import tpl_customQRCodeEditItem from './tpl/customQRCodeEditItem.html';
import tpl_customQRCodeEditItemDesign from './tpl/customQRCodeEditItemDesign.html';

import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import View_Render from '../render/render';
import helper_createWebpage from '../../helper/createWebpage';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customQRCodeEditItemDesign({ model: this.model.toJSON() });
        } else {
            return tpl_customQRCodeEditItem({ model: this.model.toJSON() });
        }
    },
    regions: {
        information: "#customQRCodeEditItemInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100071;
        customHeaderModel.attributes.ShowSave = true;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            self.btnSubmitClick();
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customQRCodeEditItem_btnBack_click();
        });	
    },
    help: function () {
        Help.help(this, true, 'customQRCodeEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick(null, callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        if ((this.model.attributes.Size != this.oldModel.attributes.Size) ||
            (this.model.attributes.Radius != this.oldModel.attributes.Radius) ||
            (this.model.attributes.Quiet != this.oldModel.attributes.Quiet) ||
            (this.model.attributes.Mode != this.oldModel.attributes.Mode) ||
            (this.model.attributes.MSize != this.oldModel.attributes.MSize) ||
            (this.model.attributes.MPosX != this.oldModel.attributes.MPosX) ||
            (this.model.attributes.MPosY != this.oldModel.attributes.MPosY) ||
            (this.model.attributes.Label != this.oldModel.attributes.Label) ||
            (this.model.attributes.FontName != this.oldModel.attributes.FontName) ||
            (this.model.attributes.Fill != this.oldModel.attributes.Fill) ||
            (this.model.attributes.Background != this.oldModel.attributes.Background) ||
            (this.model.attributes.FontColor != this.oldModel.attributes.FontColor) ||
            (this.model.attributes.FontSize != this.oldModel.attributes.FontSize) ||
            (this.model.attributes.FontStyle != this.oldModel.attributes.FontStyle) ||
            (this.model.attributes.Logo != this.oldModel.attributes.Logo)) {
            changes = true;
        }

        this.setHasChanged(changes);
    },
    ui: {
        'customQRCodeEditItem_btnLogo': '#customQRCodeEditItem_btnLogo',
        'customQRCodeEditItem_img_Logo': '#customQRCodeEditItem_img_Logo',
        'customQRCodeEditItem_inputName': '#customQRCodeEditItem_inputName',
        'customQRCodeEditItem_btnSubmit': '#customQRCodeEditItem_btnSubmit',
        'customQRCodeEditItem_input_Size': '#customQRCodeEditItem_input_Size',
        'customQRCodeEditItem_ColorPickerSliders_Fill': '#customQRCodeEditItem_ColorPickerSliders_Fill',
        'customQRCodeEditItem_ColorPickerSliders_Background': '#customQRCodeEditItem_ColorPickerSliders_Background',
        'customQRCodeEditItem_input_Quiet': '#customQRCodeEditItem_input_Quiet',
        'customQRCodeEditItem_input_Radius': '#customQRCodeEditItem_input_Radius',
        'customQRCodeEditItem_select_Mode': '#customQRCodeEditItem_select_Mode',
        'customQRCodeEditItem_input_MSize': '#customQRCodeEditItem_input_MSize',
        'customQRCodeEditItem_input_MPosX': '#customQRCodeEditItem_input_MPosX',
        'customQRCodeEditItem_input_MPosY': '#customQRCodeEditItem_input_MPosY',
        'customQRCodeEditItem_input_Label': '#customQRCodeEditItem_input_Label',
        'customQRCodeEditItem_select_FontName': '#customQRCodeEditItem_select_FontName',
        'customQRCodeEditItem_dropDown_FontSize': '#customQRCodeEditItem_dropDown_FontSize',
        'customQRCodeEditItem_select_FontStyle': '#customQRCodeEditItem_select_FontStyle',
        'customQRCodeEditItem_ColorPickerSliders_FontColor': '#customQRCodeEditItem_ColorPickerSliders_FontColor',
        'customQRCodeEditItem_select_Logo': '#customQRCodeEditItem_select_Logo',
        'customQRCodeEditItem_qrCodeContainer': '#customQRCodeEditItem_qrCodeContainer',
        'customQRCodeEditItem_container': '#customQRCodeEditItem_container',
        'customQRCodeEditItem_qrCodeLogo': '#customQRCodeEditItem_qrCodeLogo',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customQRCodeEditItem_inputNameGroup': '#customQRCodeEditItem_inputNameGroup',
        'customQRCodeEditItem_btnBack': '#customQRCodeEditItem_btnBack'
    },
    events: {
        'click @ui.customQRCodeEditItem_btnSubmit': 'btnSubmitClick',
        'change @ui.customQRCodeEditItem_input_Size': 'updateQrCode',
        'change @ui.customQRCodeEditItem_ColorPickerSliders_Fill': 'updateQrCode',
        'change @ui.customQRCodeEditItem_ColorPickerSliders_Background': 'updateQrCode',
        'change @ui.customQRCodeEditItem_input_Quiet': 'updateQrCode',
        'change @ui.customQRCodeEditItem_input_Radius': 'updateQrCode',
        'change @ui.customQRCodeEditItem_select_Mode': 'updateQrCode',
        'change @ui.customQRCodeEditItem_input_MSize': 'updateQrCode',
        'change @ui.customQRCodeEditItem_input_MPosX': 'updateQrCode',
        'change @ui.customQRCodeEditItem_input_MPosY': 'updateQrCode',
        'change @ui.customQRCodeEditItem_input_Label': 'updateQrCode',
        'change @ui.customQRCodeEditItem_select_FontName': 'updateQrCode',
        'change @ui.customQRCodeEditItem_ColorPickerSliders_FontColor': 'updateQrCode',
        'change @ui.customQRCodeEditItem_select_Logo': 'updateLogo',
        'change @ui.customQRCodeEditItem_select_FontStyle': 'updateLogo',
        'change @ui.customQRCodeEditItem_dropDown_FontSize': 'updateLogo',
        'keyup @ui.customQRCodeEditItem_input_Label': 'updateQrCode',
        'paste @ui.customQRCodeEditItem_input_Label': 'updateQrCode',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk',
        'colorpickerChange @ui.customQRCodeEditItem_ColorPickerSliders_Fill': 'colorChange_Fill',
        'colorpickerChange @ui.customQRCodeEditItem_ColorPickerSliders_Background': 'colorChange_Background',
        'colorpickerChange @ui.customQRCodeEditItem_ColorPickerSliders_FontColor': 'colorChange_FontColor',
        'click @ui.customQRCodeEditItem_btnBack': 'customQRCodeEditItem_btnBack_click',   

        'click @ui.customQRCodeEditItem_btnLogo': 'customQRCodeEditItem_btnLogoClick',
    },
    customQRCodeEditItem_btnLogoClick() {
        this.setupFancyBoxImg();
    },
    setupFancyBoxImgLoad() {

        this.model.attributes.Logo = this.ui.customQRCodeEditItem_select_Logo.val();
        if (this.model.attributes.Logo == '/images/Events4us.png') {
            var src = this.model.attributes.Logo;
        }
        else {
            var model = self.app.entities.collections.customImage.fullCollection.where({ id: parseInt(this.model.attributes.Logo) })[0];
            var src = model.attributes.ImageUrl;
        }

        this.ui.customQRCodeEditItem_img_Logo.attr('src', src);
    },
    setupFancyBoxImg() {
        var self = this;
        var list = [];

        if (this.model.attributes.Logo == '/images/Events4us.png') {
            var src = this.model.attributes.Logo;
        }
        else {
            var model = self.app.entities.collections.customImage.fullCollection.where({ id: parseInt(this.model.attributes.Logo) })[0];

            if (model != undefined) {
                var src = model.attributes.ImageUrl;
            }
        }

        var item = {
            src: src,
            opts: {
                caption: '',
                thumb: "'" + src + "'"
            }
        }

        list.push(item);

        if (this.model.attributes.Logo != '/images/Events4us.png') {
            var src = '/images/Events4us.png';

            var item = {
                src: src,
                opts: {
                    caption: '',
                    thumb: "'" + src + "'"
                }
            }

            list.push(item);
        }

        this.app.entities.collections.customImage.fullCollection.models.forEach(function (entry) {
            var s;

            if (self.model.attributes.Logo != entry.attributes.id) {
                var item = {
                    src: entry.attributes.ImageUrl,
                    opts: {
                        caption: '',
                        thumb: "'" + entry.attributes.ImageUrl + "'"
                    }
                }

                list.push(item);
            }
        });

        $.fancybox.open(
            list,
            {

                loop: false,
                thumbs: {
                    autoStart: true, // Display thumbnails on opening
                    hideOnClose: true, // Hide thumbnail grid when closing animation starts
                    parentEl: ".fancybox-container", // Container is injected into this element
                    axis: "y" // Vertical (y) or horizontal (x) scrolling
                },
                opts: {

                },
                beforeClose: function (instance, current) {
                    

                    if (current.src == '/images/Events4us.png') {
                        self.model.attributes.Logo = current.src;
                    }
                    else {
                        var model = self.app.entities.collections.customImage.fullCollection.where({ ImageUrl: current.src })[0];
                        self.model.attributes.Logo = model.attributes.id;
                    }
                    self.ui.customQRCodeEditItem_img_Logo.attr('src', current.src);
                    self.updateLogo();
                    self.updateQrCode();
                },
                clickContent: "close",
                clickSlide: "close",
                clickOutside: "close"
            }
        );

        this.setupFancyBoxStyle();
    },
    setupFancyBoxStyle() {
        var top = window.innerHeight * 0.1;
        var left = window.innerWidth * 0.1;
        $('.fancybox-container').css('max-width', '80%');
        $('.fancybox-container').css('max-height', '80%');
        $('.fancybox-container').css('left', left + 'px');
        $('.fancybox-container').css('top', top + 'px');
    },
    colorChange_Fill: function (e) {
        this.model.attributes.Fill = e.value.toRgbString();
        this.updateQrCode();
    },
    colorChange_Background: function (e) {
        this.model.attributes.Background = e.value.toRgbString();
        this.updateQrCode();
    },
    colorChange_FontColor: function (e) {
        this.model.attributes.FontColor = e.value.toRgbString();
        this.updateQrCode();
    },
    onRender: function () {
        this.ui.customQRCodeEditItem_select_Mode.val(this.model.attributes.Mode);

        this.insertLogo(this.ui.customQRCodeEditItem_select_Logo);
        

        this.renderInformation();

        if (this.app.mode == 1) {
            this.ui.customQRCodeEditItem_inputNameGroup.css('display', 'none');
            this.ui.customQRCodeEditItem_btnSubmit.css('display', 'none');
            this.ui.customQRCodeEditItem_btnBack.css('display', 'none');
        }

        this.setupFont();
        this.setupFontStyle();
        this.setupFontSize();

        //this.setupLogo();

        this.setupFancyBoxImgLoad();
    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.customQRCodeEditItem_select_FontName.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ FontName: this.value });
        });
        this.ui.customQRCodeEditItem_select_FontName.trigger('setFont', self.model.attributes.FontName);
    },
    setupFontSize: function () {
        var self = this;

        this.ui.customQRCodeEditItem_dropDown_FontSize.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.FontSize == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customQRCodeEditItem_dropDown_FontSize.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.customQRCodeEditItem_select_FontStyle.empty();

        var selected = '';

        self.app.entities.collections.QRCodeFontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.FontStyle == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customQRCodeEditItem_select_FontStyle.append(options);
        });
    },
    renderInformation: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: resources.X100072 });
        this.informationCollection.add(tmpModel);

    },
    customViewEvent_setActive: function () {
        var array = [];

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ QRCode: this.model.attributes.id });

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        var self = this;

        this.customViewEvent_setActive();

        this.updateLogo();

        if ($("#customQRCodeEditItem_input_Size").length) {
            var startSlider1 = document.getElementById('customQRCodeEditItem_input_Size');
            nouislider.create(startSlider1, {
                start: self.model.attributes.Size,
                connect: [true, false],
                range: {
                    min: 150,
                    max: 600
                },
                step : 50
            });
            startSlider1.noUiSlider.on('set', function (e) {
                self.model.attributes.Size = parseInt(e);
                self.updateQrCode();
            });
        }

        if ($("#customQRCodeEditItem_input_MSize").length) {
            var startSlider2 = document.getElementById('customQRCodeEditItem_input_MSize');
            nouislider.create(startSlider2, {
                start: self.model.attributes.MSize,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 35
                },
                step: 1
            });
            startSlider2.noUiSlider.on('set', function (e) {
                self.model.attributes.MSize = parseInt(e);
                self.updateQrCode();
            });
        }

        if ($("#customQRCodeEditItem_input_MPosX").length) {
            var startSlider3 = document.getElementById('customQRCodeEditItem_input_MPosX');
            nouislider.create(startSlider3, {
                start: self.model.attributes.MPosX,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 100
                },
                step: 1
            });
            startSlider3.noUiSlider.on('set', function (e) {
                self.model.attributes.MPosX = parseInt(e);
                self.updateQrCode();
            });
        }

        if ($("#customQRCodeEditItem_input_MPosY").length) {
            var startSlider4 = document.getElementById('customQRCodeEditItem_input_MPosY');
            nouislider.create(startSlider4, {
                start: self.model.attributes.MPosY,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 100
                },
                step: 1
            });
            startSlider4.noUiSlider.on('set', function (e) {
                self.model.attributes.MPosY = parseInt(e);
                self.updateQrCode();
            });
        }

        this.ui.customQRCodeEditItem_ColorPickerSliders_Fill.colorpicker({
            color : self.model.attributes.Fill,
            format: "rgb"
        });

        this.ui.customQRCodeEditItem_ColorPickerSliders_Background.colorpicker({
            color: self.model.attributes.Background,
            format: "rgb"
        });

        this.ui.customQRCodeEditItem_ColorPickerSliders_FontColor.colorpicker({
            color: self.model.attributes.FontColor,
            format: "rgb"
        });
        
        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }
    },
    onDestroy(){
    },
    validate: function () {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customQRCodeEditItem_inputName.val();

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        return true;
    },
    oldbtnSubmitClick: function (event, callbackMenu) {
        var self = this;
        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X100071;
        dialogWaitingModel.attributes.Text = resources.X100307;
        dialogWaitingModel.attributes.Button = resources.X100279;

                
        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
        var callback = function (options) {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);

            setTimeout(function() {
                // rest of code here
                var dialog2ButtonModel = new Entities_Empty.Model();
                dialog2ButtonModel.attributes.Title = resources.X100229;
                dialog2ButtonModel.attributes.Text = resources.X100395;
                dialog2ButtonModel.attributes.Button01 = resources.X100281;
                dialog2ButtonModel.attributes.Button02 = resources.X100282;
                var dialog2Buttonbla = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });
                var callback01bla = function (options) {
                    self.stopListening(dialog2Buttonbla, "dialog:btn01clicked", callback01bla);
                    self.stopListening(dialog2Buttonbla, "dialog:btn02clicked", callback02bla);
                }
                self.listenTo(dialog2Buttonbla, "dialog:btn01clicked", callback01bla);

                var callback02bla = function (options) {
                    self.stopListening(dialog2Buttonbla, "dialog:btn01clicked", callback01bla);
                    self.stopListening(dialog2Buttonbla, "dialog:btn02clicked", callback02bla);

                    callback();
                }
                self.listenTo(dialog2Buttonbla, "dialog:btn02clicked", callback02bla);

                self.app.dialogRegion.show(dialog2Buttonbla);
            }, 2000);

        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        self.app.dialogRegion.show(dialogWaiting);
    },
    btnSubmitClick: function (event, callbackMenu) {
        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.updateQrCode();
            this.setHasChanged(false);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100071;
            dialogWaitingModel.attributes.Text = resources.X100307;
            dialogWaitingModel.attributes.Button = resources.X100279;
                
            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            self.model.save(this.model.attributes, {
                silent: true,
                success: function () {
                    self.oldModel = self.model.clone();

                    dialogWaiting.triggerMethod('dialog:close');
                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ QRCode: self.model.attributes.id })
                    var render = new View_Render({ collection: customEvent, app : self.app });
                    self.app.renderRegion.show(render);
                    var renderFinishCallback = function (options) {
                        if (callbackMenu) {
                            callbackMenu();
                        }
                        //self.app.router.customQRCode();
                        //var url = 'customQRCode';
                        //self.app.router.navigate(url);
                    }
                    //render.doIt(renderFinishCallback, false, 'EVENTINFO_SCHEMASCREEN_POSTER_REFRESH', false);
                    render.doIt(renderFinishCallback, false, 'EVENTINFO_REFRESH', false);
                } 
            });
        }
    },
    insertLogo: function (element) {
        var self = this;

        element.append('<option value="' + '/images/Events4us.png' + '">' + 'Events4us' + '</option>');

        this.app.entities.collections.customImage.fullCollection.forEach(function (entry) {
            if (self.model.attributes.Logo == entry.attributes.id) {
                element.append('<option value="' + entry.attributes.id + '" selected="selected">' + entry.attributes.Name + '</option>');
            }
            else {
                element.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    updateLogo: function (event) {

        if (event !=  null) {
            this.setHasChanged(true);
        }

        //this.model.attributes.Logo = this.ui.customQRCodeEditItem_select_Logo.val();
        if (this.model.attributes.Logo == '/images/Events4us.png') {
            var src = this.model.attributes.Logo;
        }
        else {
            var model = this.app.entities.collections.customImage.fullCollection.where({ id: parseInt(this.model.attributes.Logo) })[0];

            if (model != undefined) {
                var src = model.attributes.ImageUrl;
            }

            //var src = config.webApiUrl() + '/api/PublicCustomImage/route01/' + this.model.attributes.Logo;

        }

        var self = this;
        this.ui.customQRCodeEditItem_qrCodeLogo.off("load");
        //JQUpdate
        //this.ui.customQRCodeEditItem_qrCodeLogo.load(function () {
        this.ui.customQRCodeEditItem_qrCodeLogo.on('load', function () {
            self.updateQrCode();
            self.ui.customQRCodeEditItem_qrCodeLogo.off("load");
        });
        this.ui.customQRCodeEditItem_qrCodeLogo.attr("src", src);
    },
    updateQrCode: function () {
        //this.model.attributes.Size = parseInt(this.ui.customQRCodeEditItem_input_Size.val(), 10);
        //this.model.attributes.Radius = parseInt(this.ui.customQRCodeEditItem_input_Radius.val(), 10);
        //this.model.attributes.Quiet = parseInt(this.ui.customQRCodeEditItem_input_Quiet.val(), 10);
        this.model.attributes.Mode = parseInt(this.ui.customQRCodeEditItem_select_Mode.val());
        //this.model.attributes.MSize = parseInt(this.ui.customQRCodeEditItem_input_MSize.val(), 10);
        //this.model.attributes.MPosX = parseInt(this.ui.customQRCodeEditItem_input_MPosX.val(), 10);
        //this.model.attributes.MPosY = parseInt(this.ui.customQRCodeEditItem_input_MPosY.val(), 10);
        this.model.attributes.Label = this.ui.customQRCodeEditItem_input_Label.val();
        //this.model.attributes.FontName = this.ui.customQRCodeEditItem_select_FontName.val();
        //this.model.attributes.Logo = this.ui.customQRCodeEditItem_select_Logo.val();
        this.model.attributes.FontStyle = parseInt(this.ui.customQRCodeEditItem_select_FontStyle.val());
        this.model.attributes.FontSize = this.ui.customQRCodeEditItem_dropDown_FontSize.val();

        this.ui.customQRCodeEditItem_container.empty();

        var tmpModelQRCode = new Entities_Empty.Model();
        //tmpModelQRCode.attributes.id = -1;

        var tmpModelQRCode = helper_createWebpage.createQRCodeImage(this.model, tmpModelQRCode, this.ui.customQRCodeEditItem_qrCodeLogo, this.ui.customQRCodeEditItem_qrCodeContainer, false, this.app);

        this.model.attributes.ImageData = tmpModelQRCode.attributes.ImageData;
        this.model.attributes.ImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
        this.model.attributes.ImageUpdate = tmpModelQRCode.attributes.ImageUpdate;
        this.model.attributes.Filename = this.model.attributes.Name + '_' + new Date().getTime() + '.jpg';
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');
    },
    customQRCodeEditItem_btnBack_click: function (event) {
        this.app.router.navigate("#customEventEditItem?id=" + this.options.eventId, { trigger: true });
        this.app.router.customEventEditItem(this.options.eventId, this.options.scroll, 1);
    }
}, Backbone.Radio.Requests);