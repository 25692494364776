var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventCounter_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customEventCounter"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0">  <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X100233'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customEventCounterInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X101046'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group customEventCounter_selectEvent"> <div class="form-group"> <label for="customEventCounter_selectEvent">'+
((__t=( resources['X100234'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventCounter_selectEvent" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="ui-widget ui-corner-all"> <div class="ui-widget-header ui-corner-top" id="customEventCounter_divEventName" style="background-color:#006699; text-align:center; padding-top:5px; padding-bottom:5px">Hi Powered Data</div> <div class="ui-widget-content ui-corner-bottom" id="customEventCounter_chartdiv" style="background-color: rgb(57, 57, 57)"> <div id="customEventCounter_chart" style="margin-left:20px; margin-right:20px"></div> </div> </div> <div class="form-check form-check-flat form-check-primary"> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customEventCounter_btnZoomOut">'+
((__t=( resources['X100875'] ))==null?'':__t)+
'</button> </div> <div class="form-check form-check-flat form-check-primary"> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customEventCounter_btnZoomIn">'+
((__t=( resources['X100900'] ))==null?'':__t)+
'</button> </div> <div class="form-check form-check-flat form-check-primary"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventCounter_btnReset">'+
((__t=( resources['X100236'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
