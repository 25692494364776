﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Active: true
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customEvent/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        if (method == 'update' && options.updateVersion) {
            options.url = config.webApiUrl() + '/api/customEvent/route01/' + model.attributes.id + '?AuthUserId=' + config.authUserId() + '&Dummy=none';
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customEvent/route02/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/CustomEvent/-1?AuthUserId=' + config.authUserId();
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    /*save: function (attributes) {
        this.options.success = this.successOnSave;
        this.options.error = this.errorOnSave;

        Backbone.Model.prototype.save.call(this, attributes, options);
    },*/
    /*successOnSave: function (model, response) {
        model = response;
    },
    errorOnSave: function (model, response) {
        alert("FEJL");
    },*/
    validateNoBackbone: function (app, validateWebpage, callback) {
        var self = this;
        this.errors = [];

        var callbackInternal = function () {

            if (self.attributes.Name == "") {
                self.errors.push({ name: 'customEventEditItem_inputName', message: resources.X100324 });
            }

            var letterNumber = '^[ÆØÅæøåAA-Za-z0-9 _]*[ÆØÅæøåAA-Za-z0-9][ÆØÅæøåAA-Za-z0-9 _]*$';
            var re = new RegExp(letterNumber, 'g');
            if (self.attributes.EventName) {
                //if(self.attributes.EventName.match(re) == null) {
                //    self.errors.push({ name: 'customEventEditItem_inputEventName', message: resources.X100515 });
                //}
            }

            if (self.attributes.SchemaMenuName) {
                //if(self.attributes.SchemaMenuName.match(re) == null) {
                //    self.errors.push({ name: 'customEventEditItem_inputSchemaMenuName', message: resources.X100516 });
                //}
            }

            if (self.attributes.FormMenuName) {
                //if(self.attributes.FormMenuName.match(re) == null) {
                //    self.errors.push({ name: 'customEventEditItem_inputFormMenuName', message: resources.X100517 });
                //}
            }

            if (self.attributes.FormSubmitBtnName) {
                //if(self.attributes.FormSubmitBtnName.match(re) == null) {
                //    self.errors.push({ name: 'customEventEditItem_inputFormSubmitBtnName', message: resources.X100518 });
                //}
            }

            if (self.attributes.FormCancelBtnName) {
                //if(self.attributes.FormCancelBtnName.match(re) == null) {
                //    self.errors.push({ name: 'customEventEditItem_inputFormCancelBtnName', message: resources.X100519 });
                //}
            }

            var letterNumberSpecialCarecter = "/^[\u00C0-\u00FF]+$/";
            var reletterNumberSpecialCarecter = new RegExp(letterNumberSpecialCarecter, 'g');
            if (self.attributes.FormSubmitBtnSucces) {
                /*if(self.attributes.FormSubmitBtnSucces.match(reletterNumberSpecialCarecter) == null) {
                    self.errors.push({ name: 'customEventEditItem_inputFormSubmitBtnSucces', message: resources.X100520 });
                }*/
            }

            var mail = '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([ÆØÅæøåa-zA-Z\-0-9]+\.)+[ÆØÅæøåa-zA-Z]{2,}))$/';
            var reMail = new RegExp(mail, 'g');
            if (self.attributes.FormNotifyEmailAddress) {
                //if(self.attributes.FormNotifyEmailAddress.match(reMail) == null) {
                //    self.errors.push({ name: 'customEventEditItem_inputFormNotifyEmailAddress', message: resources.X100521 });
                //}
            }

            var urlRegExp = '^[ÆØÅæøåAA-Za-z0-9 _-]*[ÆØÅæøåAA-Za-z0-9][ÆØÅæøåAA-Za-z0-9 _-]*$';
            var reUrlRegExp = new RegExp(urlRegExp, 'g');
            if (self.attributes.FriendlyUrl) {
                if (self.attributes.FriendlyUrl.match(reUrlRegExp) == null) {
                    self.errors.push({ name: 'customEventEditItem_inputFriendlyUrl', message: resources.X100522 });
                }
            }

            /*var urlAdRegExp = new RegExp('(http | ftp | https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?', 'g');

            if (self.attributes.AdImageUrl) {
                if (self.attributes.AdImageUrl.match(urlAdRegExp) == null) {
                    self.errors.push({ name: 'customEventEditItem_inputAdImageUrl', message: resources.X100994 });
                }
            }*/

            if (self.attributes.ProductSheetMenuName) {
                if (self.attributes.ProductSheetMenuName.match(re) == null) {
                    self.errors.push({ name: 'customEventEditItem_inputProductSheetMenuName', message: resources.X101141 });
                }
            }

            if (self.attributes.ProductSheetPreviusBtnName) {
                if (self.attributes.ProductSheetPreviusBtnName.match(re) == null) {
                    self.errors.push({ name: 'customEventEditItem_inputProductSheetPreviusBtnName', message: resources.X101142 });
                }
            }

            if (self.attributes.ProductSheetNextBtnName) {
                if (self.attributes.ProductSheetNextBtnName.match(re) == null) {
                    self.errors.push({ name: 'customEventEditItem_inputProductSheetNextBtnName', message: resources.X101143 });
                }
            }


            for (var i = 0; i < app.entities.collections.customEvent.fullCollection.length; i++) {
                if ((self.attributes.Name == app.entities.collections.customEvent.fullCollection.models[i].attributes.Name) && (self.attributes.id != app.entities.collections.customEvent.fullCollection.models[i].attributes.id)) {
                    self.errors.push({ name: 'customEventEditItem_inputName', message: resources.X100327 });
                    self.errors.push({ name: 'customEventInsert_inputName', message: resources.X100327 });
                }
            };

            if (validateWebpage) {
                if (self.attributes.Webpage == -1) {
                    self.errors.push({ name: 'customEventEditItem_selectWebpage', message: resources.X100328 });
                }
            }

            if (self.attributes.Publicly) {
                //if ((self.attributes.Address.length <= 0) || (self.attributes.Address_lat == 0 && self.attributes.Address_lng == 0))
                if (self.attributes.Address_lat == 0 && self.attributes.Address_lng == 0 ||
                    self.attributes.Address_lat == null && self.attributes.Address_lng == null) {
                    self.errors.push({ name: 'customEventEditItem_inputPubliclyAddress', message: resources.X100043 });
                }
            }

            if (self.errors.length > 0) {
                //       return true
                callback(true);
            }
            else {
                //        return false;
                callback(false);
            }
        }


        if ((this.attributes.FriendlyUrl) && (this.attributes.FriendlyUrl != '')) {

            var tmpModel = {};
            tmpModel.Id = this.attributes.id;
            tmpModel.FriendlyUrl = this.attributes.FriendlyUrl;
            tmpModel.Method = 'GetFriendlyUrlUsed';

            app.entities.collections.customOther.fetch({
                silens: true,
                wait: true,
                tmpModel: tmpModel,
                success: function (mode, response) {
                    if (response.length > 0) {
                        self.errors.push({ name: 'customEventEditItem_inputFriendlyUrl', message: resources.X100355 });
                    }
                    callbackInternal();
                }
            });
        }
        else {
            callbackInternal();
        }
    }
});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 6,
    },

    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },

    options: {
        full: false
    },

    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customEvent/-1?AuthUserId=' + config.authUserId();
        }
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
            : a.attributes.id < b.attributes.id ? 1
                : 0;
    }
});






