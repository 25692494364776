var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom_empty"> <div class="content-wrapper" data-help="custom"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200034'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X200034'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group custom_inputName"> <div class="form-group"> <label id="custom_labelName" for="custom_inputName">'+
((__t=( resources['X200051'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="custom_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group custom_inputContactperson_Name" id="custom_divContactperson_Name"> <div class="form-group"> <label id="custom_labelContactperson_Name" for="custom_inputContactperson_Name">'+
((__t=( resources['X200052'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Contactperson_Name))==null?'':__t)+
'" id="custom_inputContactperson_Name" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group custom_inputContactperson_Phone" id="custom_divContactperson_Phone"> <div class="form-group"> <label id="custom_labelContactperson_Phone" for="custom_inputContactperson_Phone">'+
((__t=( resources['X200053'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Contactperson_Phone))==null?'':__t)+
'" id="custom_inputContactperson_Phone" maxlength="15"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group custom_inputContactperson_Email" id="custom_divContactperson_Email" style="display:none"> <div class="form-group"> <label id="custom_labelContactperson_Email" for="custom_inputContactperson_Email">'+
((__t=( resources['X200054'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Contactperson_Email))==null?'':__t)+
'" id="custom_inputContactperson_Email" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group custom_inputAddress01"> <div class="form-group"> <label for="custom_inputAddress01">'+
((__t=( resources['X200055'] ))==null?'':__t)+
'</label>  <input class="form-control form-control-sm" id="custom_inputAddress01" value="'+
((__t=( model.Address01 ))==null?'':__t)+
'" maxlength="255"> <span class="help-inline" style="color:red"></span>  </div> </div> <div class="control-group custom_inputAddress01_Supplement"> <div class="form-group"> <label for="custom_inputAddress01_Supplement">'+
((__t=( resources['X200056'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="custom_inputAddress01_Supplement" value="'+
((__t=( model.Address01_Supplement ))==null?'':__t)+
'" maxlength="255"> <span class="help-inline" style="color:red"></span> </div> </div>        <button type="button" class="btn btn-primary btn-sm" id="custom_btnSubmit">'+
((__t=( resources['X200059'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm" id="custom_btnChangePassword">'+
((__t=( resources['X200060'] ))==null?'':__t)+
'</button> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
