var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="weather_empty"> <div class="modal fade" id="weather_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101407'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion-1" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X101407'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion-1"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="homeb-tab-1" data-toggle="tab" href="#homeb-1" role="tab" aria-controls="homeb-1" aria-selected="true">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homeb-tab-2" data-toggle="tab" href="#homeb-2" role="tab" aria-controls="homeb-2" aria-selected="false">'+
((__t=( resources['X101393'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homeb-tab-3" data-toggle="tab" href="#homeb-3" role="tab" aria-controls="homeb-3" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="homeb-1" role="tabpanel" aria-labelledby="homeb-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_ColorPickerSliders_BgColor">'+
((__t=( resources['X101397'] ))==null?'':__t)+
'</label> <div id="weather_ColorPickerSliders_BgColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Template">'+
((__t=( resources['X101408'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Template" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Forecast">'+
((__t=( resources['X101409'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Forecast" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Unit">'+
((__t=( resources['X101415'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Unit" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="control-label col-md-6"> <label for="weather_checkboxEnable24Hours">'+
((__t=( resources['X101385'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="weather_checkboxEnable24Hours"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <div class="alert alert-warning hidden" id="weather_alertWarningWrongAddress" style="display:none"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> <strong>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</strong> '+
((__t=( resources['X100287'] ))==null?'':__t)+
' </div> <div class="form-group" id="weather_inputPubliclyAddress_div"> <div class="row"> <div class="control-label col-md-12"> <label for="weather_inputPubliclyAddress">'+
((__t=( resources['X100042'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="weather_inputPubliclyAddress" value="'+
((__t=( model.PubliclyAddress ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="control-group weather_input_Header"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="row"> <div class="control-label col-6 col-sm-6 col-md-6 col-xl-6"> <label style="padding-top:12px">'+
((__t=( resources['X101416'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-6 col-sm-6 col-md-6 col-xl-6"> <input class="form-control form-control-sm" id="weather_input_Header" style="margin-bottom:0px" value="'+
((__t=( model.Header ))==null?'':__t)+
'"> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homeb-2" role="tabpanel" aria-labelledby="homeb-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_select_Font1_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="weather_select_Font1_FontType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="weather_dropDown_Font1">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <div id="weather_formgroup_Font1" class="form-group"> <input id="weather_dropDown_Font1" class="form-control"> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_select_Font1_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="weather_select_Font1_ColorType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="weather_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Font1_Size_Header">'+
((__t=( resources['X101410'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Font1_Size_Header" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Font1_Size_Dec1">'+
((__t=( resources['X101411'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Font1_Size_Dec1" class="form-control"></select> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Font1_Size_Dec2">'+
((__t=( resources['X101412'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Font1_Size_Dec2" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Font1_Size_Detail">'+
((__t=( resources['X101413'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Font1_Size_Detail" class="form-control"></select> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Font1_Size_Forecast">'+
((__t=( resources['X101414'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Font1_Size_Forecast" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="weather_dropDown_Font1_Style">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="weather_dropDown_Font1_Style" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div id="homeb-3" class="tab-pane fade show" role="tabpanel" aria-labelledby="homeb-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="weather_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="weather_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
