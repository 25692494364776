﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageEditItemListSmall from './tpl/customWebpageEditItemListSmall.html';
import View_CustomWebpageEditItemListItemSmall from './customWebpageEditItemListItemSmall';

export default Marionette.CollectionView.extend({
    className: 'dropdown-menu',
    tagName: 'ul',
    //el:'#customWebpageEditItemListItemSmall',
    attributes : function () {
        // Return model data
        return {
            //class: 'dropdown-menu',
            style : 'top:74px;'//,
            //id : 'customWebpageEditItemListItemSmall'
        }
            
    },
    ui : {
        'customWebpageEditItemListItemSmall': '#customWebpageEditItemListItemSmall'
    },
    childViewContainer: '#customWebpageEditItemListItemSmall',
    childView: View_CustomWebpageEditItemListItemSmall,
    

    getTemplate: function () {
        return "";//tpl_customWebpageEditItemListSmall();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    },
    onAttach(){
        var self = this;    
        //$('#customWebpageEditItemListItemSmall').sortable({
        //    items: "li:not(.ui-state-disabled)",
        //    stop: function (event, ui) {
        //        self.updateWebpagePosition();
        //    }
        //});

        $('#customWebpageEditItemListSmall').append('<a id="" href="#" class="dropdown-toggle active" data-toggle="dropdown" style="cursor: pointer; color: red; height:20px;font-size:16px;"><i class="icon fa fa-file-text"></i>' + resources.X101020 + '</a>');
    },
    updateWebpagePosition() {
        var selectedModel = this.collection.where({ Selected : true });
        if (selectedModel.length > 0) {
            var zeroIndex = -1;
            switch(selectedModel[0].attributes.DisplayType) {
                case 0 : zeroIndex = 0; break;
                case 1 : zeroIndex = -1; break;
            }
            var self = this;
            $('#customWebpageEditItemListItemSmall li').each(function (index, item) {
                var value= parseInt($(item).data('id'));
                //var liId = parseInt(String($(this).data('id')));
                var tmpModel = self.collection.where({ id: value });
                if (tmpModel[0].attributes.Position != zeroIndex
                    && tmpModel[0].attributes.Position != 2147483647
                    && tmpModel[0].attributes.Position != 2147483646 
                    && tmpModel[0].attributes.Position != 2147483645
                    && tmpModel[0].attributes.Position != 2147483644
                    && tmpModel[0].attributes.Position != 2147483643
                    && tmpModel[0].attributes.Position != 2147483642
                    && tmpModel[0].attributes.Position != 2147483641
                    && tmpModel[0].attributes.Position != index) {
                    tmpModel[0].attributes.Position = index;
                }
            });
        }
    }
});
