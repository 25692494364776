﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogProcess from './tpl/dialogProcess.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#dialogProcess_modal',
    getTemplate: function () {
        return tpl_dialogProcess({ model: this.model.toJSON() });
    },
    ui: {
        'dialogProcess_progress': '#dialogProcess_progress',
        'dialogProcess_h2_text': '#dialogProcess_h2_text',
        'dialogProcess_h4_title' : '#dialogProcess_h4_title'
    },
    events: {
        "click #dialogProcess_btnOk": "onOK"
    },
    onOK: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    },
    changeTitle: function (title) {
        this.model.attributes.Title = title;
        try {
            this.ui.dialogProcess_h4_title.text(this.model.attributes.Title);
        } catch(err) {
        }
    },
    changeText: function (text) {
        this.model.attributes.Text = text;
        try {
            this.ui.dialogProcess_h2_text.text(this.model.attributes.Text);
        } catch(err) {
        }
    },
    wait: function (ms) {
        var d = new Date();
        var d2 = null;
        do { d2 = new Date(); }
        while (d2 - d < ms);
    },
    changeProgress: function (counter, max) {
        var value = parseInt((100 / max) * counter);
        if (this.ui.dialogProcess_progress) {
            $(this.ui.dialogProcess_progress).css("width", value + "%");
        }
    },
    onRender: function () {
    }
});
