var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="newSubMenu_empty"> <div class="modal fade" id="newSubMenu_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div> <div class="modal-body"> <div class="control-group newSubMenu_inputStatus" style="display:none"> <div class="form-group"> <label id="newSubMenu_labelName" for="newSubMenu_inputStatus">'+
((__t=( resources['X100968'] ))==null?'':__t)+
'</label> <input type="checkbox" id="newSubMenu_inputStatus"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group newSubMenu_inputName"> <div class="form-group"> <label id="newSubMenu_labelName" for="newSubMenu_inputName">'+
((__t=( resources['X100918'] ))==null?'':__t)+
'</label> <input class="form-control" input id="newSubMenu_inputName" value="'+
((__t=( model.Name ))==null?'':__t)+
'"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="modal-footer"> <button type="button" id="newSubMenu_btn01" class="btn btn-primary">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> <button type="button" id="newSubMenu_btn02" class="btn btn-secondary">'+
((__t=( model.Button02 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
