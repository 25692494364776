'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogHelpListItem from './tpl/dialogHelpListItem.html';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_dialogHelpListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'dialogHelpListItem_btnSubmit': '#dialogHelpListItem_btnSubmit',
        'dialogHelpListItem_ribbonSuccess': '#dialogHelpListItem_ribbonSuccess',
        'dialogHelpListItem_ribbonWrapper': '#dialogHelpListItem_ribbonWrapper'
    },
    events: {
        'click @ui.dialogHelpListItem_btnSubmit': 'btnSubmitClick'
    },
    btnSubmitClick: function (event) {
        var self = this;
        this.model.collection.fullCollection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: false });
            }
        });

        this.model.set({ Selected: true });
        this.model.collection.trigger('change');
    },
    onRender: function () {
        if (this.model.attributes.Selected) {
            this.ui.dialogHelpListItem_btnSubmit.removeClass('btn-secondary');
            this.ui.dialogHelpListItem_btnSubmit.addClass('btn-disabled');
        }
        else {
            this.ui.dialogHelpListItem_ribbonWrapper.html('');
            this.ui.dialogHelpListItem_ribbonSuccess.html('');
            this.ui.dialogHelpListItem_btnSubmit.removeClass('btn-disabled');
            this.ui.dialogHelpListItem_btnSubmit.addClass('btn-secondary');
        }
    }
});