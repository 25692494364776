import $ from 'jquery';

(function ($) {
  'use strict';
  //Open submenu on hover in compact sidebar mode and horizontal menu mode
  $(document).on('mouseenter mouseleave', '.sidebar .nav-item', function(ev) {
    var body = $('body');
    var sidebarIconOnly = body.hasClass("sidebar-icon-only");
    var sidebarFixed = body.hasClass("sidebar-fixed");
    if (!('ontouchstart' in document.documentElement)) { 
      if (sidebarIconOnly) {
        if (sidebarFixed) {
          if (ev.type === 'mouseenter') {
            body.removeClass('sidebar-icon-only');
          }
        } else {
          var $menuItem = $(this);
          if (ev.type === 'mouseenter') {
            $menuItem.addClass('hover-open')
          } else {
            $menuItem.removeClass('hover-open')
          }
        }
      }
    }
  });
  $(document).on('mouseenter mouseleave', '.submenu-wrapper li', function(ev) {
    var body = $('body');
    var sidebarIconOnly = body.hasClass("sidebar-icon-only");
        if (sidebarIconOnly) {
          var $subMenuItem = $(this);
          if (ev.type === 'mouseenter') {
            $subMenuItem.addClass('submenu-hover-open')
          } else {
            $subMenuItem.removeClass('submenu-hover-open')
          }
        }
    });
})(jQuery);

export default {};