﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEvent from './tpl/customEvent.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomEventInsert from './customEventInsert';
import View_CustomEventList from './customEventList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customEvent();
    },
    regions: {
        customEventInsert: "#customEventInsert",
        customEventList: "#customEventList",
        customEventPagination: "#customEventPagination",
        information: "#customEventInformation",
    },
    onBeforeShow: function () {
        this.customEventInsert.show(new View_CustomEventInsert({ collection: this.collection, app : this.app }));
        this.customEventList.show(new View_CustomEventList({ collection: this.collection, app : this.app }));
        this.customEventPagination.show(new View_Pagination({ collection: this.collection, app : this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    ui : {
        'help': '#help',
        'customEventInsert_btnTemplate': '#customEventInsert_btnTemplate'
    },
    events: {
        'click #help': 'help',
        'click #customEventInsert_btnTemplate': 'customEventInsert_btnTemplate_click'
    },
    initialize: function () {
        this.app = this.options.app;

        //this.collection.on('change', this.render, this);
        //this.listenTo(this.collection, 'change', this.render, this);
        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    customEventInsert_btnTemplate_click: function () {
        self.app.router.navigate("#customEventTemplate");
        self.app.router.customEventTemplate();
    },
    help: function () {
        Help.help(this, true, 'customEvent');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    onRender: function () {
        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();
    }
}, Backbone.Radio.Requests);