﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import dummy from '../../app/plugins';

//No internet
require('typeahead-addresspicker');

import tpl_customSecurity from './tpl/customSecurity.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogWaiting2 from '../../../lib/views/dialogWaiting2/dialogWaiting2';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import EventUpdate from '../../helper/eventUpdate';
import View_CustomHeader from '../region/customHeader/customHeader';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customSecurity({ model: this.model.toJSON() });
    },
    attributes: function () {
        return {
            style: "",
        }
    },
    initialize: function () {
        self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        this.listenTo(this.model, 'change', this.render);
    },
    help: function () {
        Help.help(this, true, 'customSecurity');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick();
        callback();
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        /*this.setModel();

        //If culture has change do not ask to save when goes to another menu item
        if ((this.model.attributes.SecretKey == this.oldModel.attributes.SecretKey) &&
            (this.model.attributes.SecretCode == this.oldModel.attributes.SecretCode)) {
            changes = false;
            this.btnSubmitClick();
        } else if ((this.model.attributes.SecretKey != this.oldModel.attributes.SecretKey) &&
            (this.model.attributes.SecretCode != this.oldModel.attributes.SecretCode)) {
            changes = true;
        }*/

        this.setHasChanged(changes);
    },
    test: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: "TEST" });
        this.informationCollection.add(tmpModel);
        this.informationCollection.trigger('change');
    },
    test2: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: "TEST" });
        this.informationCollection.add(tmpModel);
        this.informationCollection.trigger('change');
    },
    regions: {
        information: "#customInformation"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    ui: {
        'customSecurity_btnSubmit': '#customSecurity_btnSubmit',
        'customSecurity_btnSendPomodoroMail': '#customSecurity_btnSendPomodoroMail',
        'customSecurity_btnSecretKey': '#customSecurity_btnSecretKey',
        'customSecurity_btnSecretCode': '#customSecurity_btnSecretCode',
        'customSecurity_inputSecretKey': '#customSecurity_inputSecretKey',
        'customSecurity_inputSecretCode': '#customSecurity_inputSecretCode',
        'customSecurity_copyWebhookURL': '#customSecurity_copyWebhookURL',
        'customSecurity_copyWebhookURLMuteButton': '#customSecurity_copyWebhookURLMuteButton',
        'help': '#help'
    },
    events: {
        'click #customSecurity_btnSubmit': 'btnSubmitClick',
        'click #customSecurity_btnSendPomodoroMail': 'btnSendPomodoroMailClick',
        'click #customSecurity_btnChangePassword': 'btnChangePassword',
        'click #customSecurity_btnGoogleSubmit': 'btnGoogleFind',
        'change @ui.customSecurity_btnImage': 'btnImageChange',
        'click #customSecurity_copyWebhookURL': 'copyWebhookURL',
        'click #customSecurity_copyWebhookURLMuteButton': 'copyWebhookURLMuteButton',
        'click #help': 'help'
    },
    onRender: function () {
    },
    onAttach() {
    },
    onDestroy() {
    },
    renderInformation: function () {
        this.informationCollection.reset();
        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    btnSubmitClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200040;
        dialog2ButtonModel.attributes.Text = resources.X200377;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });

        var callback01 = function () {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            self.stopListening(dialog2Button, "dialog:closeclicked", callback02);

            if (this.setModel()) {
                this.save();
            }
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(dialog2Button);
    },
    btnSendPomodoroMailClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200392;
        dialog2ButtonModel.attributes.Text = resources.X200393;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;

        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });

        var callback01 = function () {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
            $('.modal-backdrop').remove();
            self.sendPomodoroMail();
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(dialog2Button);
    },
    sendPomodoroMail: function () {
        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X200378;
        dialogWaitingModel.attributes.Text = resources.X200392;
        dialogWaitingModel.attributes.Button = resources.X200025;

        var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: this });
        var callback = function () {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        self.app.dialogRegion2.show(dialogWaiting);
        

        var tmpModel = new Entities_Empty.Model();

        tmpModel.attributes.id = this.model.attributes.id;
        tmpModel.attributes.EncryptText01 = resources.X200366;
        tmpModel.attributes.EncryptText02 = resources.X200367;
        tmpModel.attributes.EncryptText03 = resources.X200368;
        tmpModel.attributes.EncryptText04 = resources.X200369;
        tmpModel.attributes.EncryptText05 = resources.X200370;
        tmpModel.attributes.EncryptText06 = resources.X200371;
        tmpModel.attributes.EncryptText07 = resources.X200364;


        self.model.save(tmpModel.attributes, {
            route: 'route04',
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
            }
        });
    },
    btnChangePassword: function (event) {
        window.app.router.navigate("#customSecurityCrediential");
        window.app.router.customSecurityCrediential();
    },
    setModel: function (parsedGeocodeResult01, parsedGeocodeResult02) {
        /*this.model.set({
            SecretKey: this.ui.customSecurity_inputSecretKey.val(),
            SecretCode: this.ui.customSecurity_inputSecretCode.val()
        });*/

        return true;
    },
    save: function () {
        var self = this;

        this.hideErrors();

        var ret = this.model.validateNoBackbone(this.model.attributes);
        if (ret == false) {

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200040;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);


            this.model.save(this.model.attributes, {
                route: 'route01',
                success: function (response) {
                    self.model.attributes.SecretKey = response.attributes.SecretKey;
                    self.model.attributes.SecretCode = response.attributes.SecretCode;

                    dialogWaiting.triggerMethod('dialog:close');
                    self.model.trigger('change');
                }
            });
        } else {
            this.showErrors(this.model.errors);
        }
    },
    copyWebhookURL: function (event) {
        event.preventDefault();

        var page = config.webApiUrl() + '/api/publicLuxaforboard/' + this.app.entities.models.custom.attributes.SecretKey +
            '/' + this.app.entities.models.custom.attributes.SecretCode;

        this.copyTextToClipboard(page);
    },
    copyWebhookURLMuteButton: function (event) {
        event.preventDefault();

        var page = config.webApiUrl() + '/api/publicLuxaforboardMutebutton/' + this.app.entities.models.custom.attributes.SecretKey +
            '/' + this.app.entities.models.custom.attributes.SecretCode;

        this.copyTextToClipboard(page);
    },

    fallbackCopyTextToClipboard: function (text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    },
}, Backbone.Radio.Requests);
