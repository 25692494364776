var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="htmlSlide_empty"> <div class="modal fade" id="htmlSlide_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101344'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101360'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_dropDown_backGroundType">'+
((__t=( resources['X101358'] ))==null?'':__t)+
'</label> <select id="htmlSlide_dropDown_backGroundType" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_input_backGroundColorOpacity">'+
((__t=( resources['X101347'] ))==null?'':__t)+
'</label> <div id="htmlSlide_input_backGroundColorOpacity" class="ul-slider slider-success" style="height: 20px"></div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-3 col-md-3 col-xl-3"> <div class="form-group"> <label for="">'+
((__t=( resources['X101315'] ))==null?'':__t)+
'</label> </div> <div class="form-group"> <img id="htmlSlide_image_background" class="mr-3 rounded" style="max-width:50px;width:50px;height:auto" src=""> </div> </div> <div class="col-12 col-sm-3 col-md-3 col-xl-3"> <div class="form-group"> <label for=""> </label> </div> <div class="form-group"> <label type="button" for="htmlSlide_btnImage" class="btn btn-sm col-sm-12 btn-secondary" style="margin-top: 5px">'+
((__t=( resources['X100295'] ))==null?'':__t)+
'</label> <div> <input id="htmlSlide_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_dropDown_backGroundImagePosition">'+
((__t=( resources['X101364'] ))==null?'':__t)+
'</label> <select id="htmlSlide_dropDown_backGroundImagePosition" class="form-control"></select> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_ColorPickerSliders_backGroundColorApp">'+
((__t=( resources['X101359'] ))==null?'':__t)+
'</label> <div id="htmlSlide_ColorPickerSliders_backGroundColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_ColorPickerSliders_backGroundColor">'+
((__t=( resources['X101346'] ))==null?'':__t)+
'</label> <div id="htmlSlide_ColorPickerSliders_backGroundColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101361'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="d-flex align-items-center"> <div class="col"> <p class="mb-2">'+
((__t=( resources['X101353'] ))==null?'':__t)+
'</p> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="htmlSlide_cb_border"> <span class="toggle-slider round"></span> </label> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_ColorPickerSliders_borderColor">'+
((__t=( resources['X101354'] ))==null?'':__t)+
'</label> <div id="htmlSlide_ColorPickerSliders_borderColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_dropDown_borderStyle">'+
((__t=( resources['X101355'] ))==null?'':__t)+
'</label> <select id="htmlSlide_dropDown_borderStyle" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="htmlSlide_dropDown_borderSize">'+
((__t=( resources['X101356'] ))==null?'':__t)+
'</label> <select id="htmlSlide_dropDown_borderSize" class="form-control"></select> </div> </div> </div> </div> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101348'] ))==null?'':__t)+
'</h4> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="htmlSlide_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="htmlSlide_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> </div></div>';
}
return __p;
};
