var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventPush_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customEventPush">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customEventPushInformation" class="e4usInformation"></div> <h4 class="card-title">'+
((__t=( resources['X101044'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group customEventPush_selectEvent"> <div class="form-group" id="customEventPush_inputNameGroup"> <label for="customEventPush_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventPush_selectEvent"></select> <span class="help-inline" style="color:red"></span> </div> </div> <div id="customEventPushInsert"></div> <div class="form-check form-check-flat form-check-primary"> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customEventPush_btnDeleteAll" style="">'+
((__t=( resources['X100240'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary btn-sm" id="customEventPush_btnCreateTest" style="width:100%;display:none">Create Test</button> </div> </div> </form> <div class="col-md-12"> <div id="aaa_customEventPushList"></div> </div> <div style="height:5px"></div> <div id="main-col" class="col-sm-12 col-md-12"> <div id="aaa_customEventPushPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> <div class="row"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101045'] ))==null?'':__t)+
'</h4> <div class="border p-3 mb-3"> <div class="pb-3"> <div id="customEventPushList"></div> </div> </div> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customEventPushPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
