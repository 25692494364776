﻿import config from '../../common/config';
import * as Entities_Empty from '../../lib/entities/empty';

var DefaultSetting = {
    createDefault: function (app) {
        var self = this;
        app.entities.models.customColor.fetch({
            success: function (mode, response) {
            },
            error: function (mode, response) {
                self.createCustomColor(app);
            }
        });
    },
    createCustomColor: function (app) {
        var tmpCustomColor = new Entities_Empty.Model(); 

        tmpCustomColor.set({
            AuthUserId: config.authUserId(),
            Custom_Color_Default: '#CDCDCD',
            SolidColor_Color_Off: '#000000',
            SolidColor_Color_Busy: '#FF0000',
            SolidColor_Color_Available: '#00FF00',
            SolidColor_Color_Blue: '#0000FF',
            SolidColor_Color_Yellow: '#FFFF00',
            SolidColor_Color_Cyan: '#00FFFF',
            SolidColor_Color_Magenta: '#FF00FF',
            SolidColor_Color_CustomColor: '#00FF00',
            MSTeams_Color_Online: '#008000',
            MSTeams_Color_Busy: '#FF0000',
            MSTeams_Color_DoNotDisturb: '#800080',
            MSTeams_Color_BeRightBack: '#FF9600',
            MSTeams_Color_ShowAsNotPresent: '#FF9600',
            MSTeams_Color_ShowAsOffline: '#CCCCCC',
            Mutebutton_Color_On: '#00FF00',
            Mutebutton_Color_Off: '#FF0000',
            Pomodoro_Color_Time: '#FF0000',
            Pomodoro_Color_ShortBreak: '#00FF00',
            Pomodoro_Color_LongBreak: '#FFFF00',
            Custom_Text_Default: resources.X200311,
            SolidColor_Text_Off: resources.X200163,
            SolidColor_Text_Busy: resources.X200164,
            SolidColor_Text_Available: resources.X200165,
            SolidColor_Text_Blue: resources.X200166,
            SolidColor_Text_Yellow: resources.X200167,
            SolidColor_Text_Cyan: resources.X200168,
            SolidColor_Text_Magenta: resources.X200169,
            SolidColor_Text_CustomColor: resources.X200175,
            MSTeams_Text_Online: resources.X200304,
            MSTeams_Text_Busy: resources.X200305,
            MSTeams_Text_DoNotDisturb: resources.X200306,
            MSTeams_Text_BeRightBack: resources.X200307,
            MSTeams_Text_ShowAsNotPresent: resources.X200308,
            MSTeams_Text_ShowAsOffline: resources.X200309,
            Mutebutton_Text_On: resources.X200313,
            Mutebutton_Text_Off: resources.X200314,
            Pomodoro_Text_Time: resources.X200315,
            Pomodoro_Text_ShortBreak: resources.X200316,
            Pomodoro_Text_LongBreak: resources.X200317
        });

        app.entities.models.customColor.save(tmpCustomColor, {
            wait: true,
            success: function (response) {
            },
            error: function (mode, response) {
            }
        });
    }
}

export default DefaultSetting;