﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customIPAddressListItem from './tpl/customIPAddressListItem.html';
import language from '../../../common/language';
import View_CustomIPAddressListItem from './customIPAddressListItem';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    // tagName: 'li',
    // className: '',
    getTemplate: function () {
        return tpl_customIPAddressListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customIPAddressListItem_btnDelete': '#customIPAddressListItem_btnDelete',
        'customIPAddressListItem_btnEdit': '#customIPAddressListItem_btnEdit',
        'customIPAddressListItem_btnPreview': '#customIPAddressListItem_btnPreview',
        'customIPAddressListItem_aQRCode': '#customIPAddressListItem_aQRCode'
    },
    events: {
        'click @ui.customIPAddressListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customIPAddressListItem_btnEdit': 'btnEditClick',
        'click @ui.customIPAddressListItem_btnPreview': 'btnPreviewClick',
        'click @ui.customIPAddressListItem_aQRCode': 'aQRCodeClick'
    },
    onRender: function () {
        if (this.model.attributes.QRCodeImage == null) {
            this.model.attributes.QRCodeImage = '../../../../images/defaultQRCode.jpg';
        }
        if (this.model.attributes.WebpageImage == null) {
            this.model.attributes.WebpageImage = '../../../../images/IPhone5.png';
        }

        this.model.attributes.WebpageUrl = config.webApiUrl() + '/Events/' + this.model.attributes.id + '_imageView.html?bust=' + (new Date()).getTime() + "'";
    },
    btnEditClick: function () {
        var url = 'customIPAddressEditItem/' + this.model.attributes.id;
        this.app.router.navigate(url);
        this.app.router.customIPAddressEditItem(this.model.attributes.id);
        
    },
    aQRCodeClick: function () {
        this.btnEditClick();
    },
    btnDeleteClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200101;
        dialog2ButtonModel.attributes.Text = resources.X200105;
        dialog2ButtonModel.attributes.Button01 = resources.X200095;
        dialog2ButtonModel.attributes.Button02 = resources.X200096;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {

            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200101;
            dialogWaitingModel.attributes.Text = resources.X200098;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
            var callbackWaiting = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

            self.app.dialogRegion.show(dialogWaiting);

            var collection = self.app.entities.collections.customIPAddress;

            self.model.destroy({
                wait: true,
                //silent: true,
                success: function (model, response) {
                    var callbackDelete = function () {
                        collection.fullCollection.sort();
                        if (collection.state.currentPage < collection.state.lastPage) {
                            collection.getPage(collection.state.currentPage);
                        }
                        else {
                            collection.getLastPage();
                        }

                        dialogWaiting.triggerMethod('dialog:close');
                    }

                    callbackDelete();
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);
        this.app.dialogRegion.show(dialog2Button);
    }
});
