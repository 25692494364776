﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSetting from './tpl/customSetting.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

export default Marionette.ItemView.extend({
    dialogRegion: '#dialogCustomSetting_modal',
    getTemplate: function () {
        return tpl_customSetting({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        //Test 240105 03
    },
    help: function () {
        this.style_switcher_ctrl_clicked(null);
        Help.help(this, true, 'customSetting');
    },
    regions: {
    },
    onBeforeShow: function () {
    },
    ui: {
        //'customSetting_style_switcher_ctrl': '#customSetting_style_switcher_ctrl',
        'customSetting_WebpageImageUrlMethod_radio00': '#customSetting_WebpageImageUrlMethod_radio00',
        'customSetting_WebpageImageUrlMethod_radio01': '#customSetting_WebpageImageUrlMethod_radio01',
        'customSetting_WebpageImageUrlMethod_radio02': '#customSetting_WebpageImageUrlMethod_radio02',
        'customSetting_WebpageImageUrlMethod_radio03': '#customSetting_WebpageImageUrlMethod_radio03',
        'customSetting_ShowInformation_check': '#customSetting_ShowInformation_check',
        'customSetting_h5': '#customSetting_h5',
        'help': '#help'
    },
    events: {
        //'click #customSetting_style_switcher_ctrl': 'style_switcher_ctrl_clicked',
        'click #customSetting_WebpageImageUrlMethod_radio00': 'webpageImageUrlMethod_radio00_clicked',
        'click #customSetting_WebpageImageUrlMethod_radio01': 'webpageImageUrlMethod_radio01_clicked',
        'click #customSetting_WebpageImageUrlMethod_radio02': 'webpageImageUrlMethod_radio02_clicked',
        'click #customSetting_WebpageImageUrlMethod_radio03': 'webpageImageUrlMethod_radio03_clicked',
        'click #customSetting_ShowInformation_check': 'customSetting_ShowInformation_check_clicked',
        'click #help': 'help',
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02"
    },
    onRender: function () {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
        if (customSetting.length > 0) {
            switch (customSetting[0].attributes.Value) {
                case '0': this.ui.customSetting_WebpageImageUrlMethod_radio00.prop('checked', true); break;
                case '1': this.ui.customSetting_WebpageImageUrlMethod_radio01.prop('checked', true); break;
                case '2': this.ui.customSetting_WebpageImageUrlMethod_radio02.prop('checked', true); break;
                case '3': this.ui.customSetting_WebpageImageUrlMethod_radio03.prop('checked', true); break;
            }
        } else {
            this.ui.customSetting_WebpageImageUrlMethod_radio00.prop('checked', true);
        }

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ShowInformation' });
        if (customSetting.length > 0) {
            switch (customSetting[0].attributes.Value) {
                case '0': this.ui.customSetting_ShowInformation_check.prop('checked', false); break;
                case '1': this.ui.customSetting_ShowInformation_check.prop('checked', true); break;
            }
        } else {
            this.ui.customSetting_ShowInformation_check.prop('checked', true);
        }
    },
    onAttach() {
    },
    onDestroy() {
    },
    /*    style_switcher_ctrl_clicked : function (event) {
            $('#style-switcher .styles').toggle('slow'); 
        },*/
    webpageImageUrlMethod_radio00_clicked: function (event) {
        this.WebpageImageUrlMethod_change('0');
    },
    webpageImageUrlMethod_radio01_clicked: function (event) {
        this.WebpageImageUrlMethod_change('1');
    },
    webpageImageUrlMethod_radio02_clicked: function (event) {
        this.WebpageImageUrlMethod_change('2');
    },
    webpageImageUrlMethod_radio03_clicked: function (event) {
        this.WebpageImageUrlMethod_change('3');
    },
    customSetting_ShowInformation_check_clicked: function (event) {
        var showInformation = '1';
        this.ui.customSetting_ShowInformation_check.is(':checked') ? showInformation = '1' : showInformation = '0';

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'ShowInformation' });
        customSetting[0].attributes.Value = showInformation;
    },
    WebpageImageUrlMethod_change: function (value) {
        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
        customSetting[0].attributes.Value = value;

        /*customSetting[0].save(customSetting[0].attributes, {
            silent: true,
            success: function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customSetting:refreshPublishBtn');
            }
        });*/
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    }
}, Backbone.Radio.Requests);
