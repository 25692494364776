var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="empty"> <div id="container" class="container-fluid"> <div id="content" style="padding-bottom:200px"> <div id="spin" class="row"> <div class="col-xs-12 col-md-12"> <div style="text-align:center"> <i class="fa fa-cog fa-spin" style="font-size:220px; color:#e74c3c"></i> </div> <h4 class="text-center" data-backdrop="static">#modelbegin# EventSpinText #modelend#</h4> </div> </div> <div id="schemaBefore" class="row" style=""> <div id="schemaDataBefore"></div> </div> <div style="margin: 0px -15px 0px -15px"> <div class="row" id="body"> <div class="col-xs-12 col-md-12"> '+
((__t=( model.Content ))==null?'':__t)+
' </div> </div> </div> <div id="schemaAfter" class="row" style=""> <div id="schemaDataAfter"></div> </div> </div> <div id="fixedfooter" style="background-color:white"> <div> <hr style="margin: 10px 10px 10px 10px"> </div> <div style="clear:both"></div> <div> <div id="public_schemaBigScreenShowQRCode" style="width:200px;float:left"> <img id="QRCodeImageUrl" style="margin-left:50px;width:100px;height:100px" alt="" src="#modelbegin# QRCodeImageUrl #modelend#"> <p id="schemaBigScreenTemplate_header01" style="text-align: center;margin-top:0px; margin-bottom:0px;color:black">#modelbegin# QRCodeTextBigSchemaScreen #modelend#</p>  </div> <div id="public_copyright" style="width:200px;float:right"> <p style="height:50px"></p> <p style="text-align: center; margin-bottom:0px;color:black">#modelbegin# resources[\'X100075\'] #modelend#</p> <a href="events4us.net"><img style="margin-left:21px; width:158px;height:20px" alt="" src="/images/Events4us.png"></a> <p style="text-align: center;color:black">#modelbegin# resources[\'X100076\'] #modelend#</p> </div> </div> </div> </div> </div>';
}
return __p;
};
