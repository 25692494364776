﻿import * as Entities_Empty from '../../lib/entities/empty';
import * as Entities_PublicPaymentGatewayMD5 from '../../app/entities/PublicPaymentGatewayMD5';

import config from '../config';

var paymentGateway = {
    createPayment : function(authUserId, callback) {
        var callbackGetPaymentGateway = function () {
            alert('callbackGetPaymentGateway');
        }

        this.getPaymentGateway(authUserId, callbackGetPaymentGateway);
    },
    getPaymentGateway: function(app, authUserId, callback) {
        $.when(
            app.entities.collections.publicCustomSubscription.fetch({
                authUserId : authUserId,
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            }),
            app.entities.collections.publicCustomSubscriptionJob.fetch({
                authUserId : authUserId,
                success: function (mode, response) {
                },
                error: function (mode, response) {
                }
            })
            ).done(function () {
                callback();
            });    
    },
    Curanet : function(authUserId) {

    },
    Events4us : function(authUserId, messageid, firstName, modelCustomSubscription, modelCustomSubscriptionJob, callback) {
        //var price = app.entities.collections.publicCustomSubscription.fullCollection.models[0].attributes.Price;
        //var vat = app.entities.collections.publicCustomSubscription.fullCollection.models[0].attributes.Vat;
        //var totalPrice = app.entities.collections.publicCustomSubscription.fullCollection.models[0].attributes.TotalPrice;

        //if (app.entities.collections.publicCustomSubscriptionJob.fullCollection.length > 0) {
        //    var price = app.entities.collections.publicCustomSubscription.fullCollection.models[0].attributes.Price + app.entities.collections.publicCustomSubscriptionJob.fullCollection.models[0].attributes.Price;
        //    var vat = app.entities.collections.publicCustomSubscription.fullCollection.models[0].attributes.Vat + app.entities.collections.publicCustomSubscriptionJob.fullCollection.models[0].attributes.Vat;
        //    var totalPrice = app.entities.collections.publicCustomSubscription.fullCollection.models[0].attributes.TotalPrice + app.entities.collections.publicCustomSubscriptionJob.fullCollection.models[0].attributes.TotalPrice;
        //}

        var price = modelCustomSubscription.attributes.Price;
        var vat = modelCustomSubscription.attributes.Vat;
        var totalPrice = modelCustomSubscription.attributes.TotalPrice;

        if (modelCustomSubscriptionJob) {
            var price = modelCustomSubscription.attributes.Price + modelCustomSubscriptionJob.attributes.Price;
            var vat = modelCustomSubscription.attributes.Vat + modelCustomSubscriptionJob.attributes.Vat;
            var totalPrice = modelCustomSubscription.attributes.TotalPrice + modelCustomSubscriptionJob.attributes.TotalPrice;
        }

        var shopidName = 'shopid';
        var shopidValue = 'SHOPID';
        var currencyName = 'currency';
        var currencyValue = modelCustomSubscription.attributes.Currency;
        var amountName = 'amount';
        var amountValue = totalPrice;
        var orderidName = 'orderid';
        var orderidValue = modelCustomSubscription.attributes.DineroInvoiceNumber;
        var orderidprefixName = 'orderidprefix';
        var orderidprefixValue = '';
        var paymenttypeName = 'paytype';
        var paymenttypeValue = 'creditcard';
        var uniqueorderName = 'uniqueorderid';
        var uniqueorderValue = 'true';
        var langName = 'lang';
        var langValue = 'da/en';
        var accepturlName = 'accepturl';
        var accepturlValue = config.webApiUrl() + '/index.html#messageWithName/X100724/' + messageid + '/' + encodeURIComponent(firstName) + '/false';
        var declineurlName = 'declineurl';
        var declineurlValue = config.webApiUrl() + '/index.html#pgEvents4usError/X100704/X100726/' + encodeURIComponent(firstName);
        var callbackName = 'declineurl';
        var callbackurlValue = config.webApiUrl() + '/api/PublicPaymentGatewayEvents4usCallback/' + modelCustomSubscription.attributes.id + '/' + authUserId;
        var macName = 'mac';


        var value = shopidValue.concat(orderidValue, orderidprefixValue, currencyValue, amountValue);
        //var md5 = require('js-md5');
        //var macValue = md5(md5hash);
        app.entities.models.publicPaymentGatewayMD5.destroy();
        app.entities.models.publicPaymentGatewayMD5 = new Entities_PublicPaymentGatewayMD5.Model();
        app.entities.models.publicPaymentGatewayMD5.attributes.MD5hash = value;

        var callbackMD5 = function() {
            //app.entities.models.publicPaymentGatewayMD5.fetch({
            app.entities.models.publicPaymentGatewayMD5.save(app.entities.models.publicPaymentGatewayMD5, {
                //value : value,
                silent: true,
                wait:true,
                success: function (mode, response) {
                    var action = config.webApiUrl() + '/api/PublicPaymentGatewayEvents4us';
                    var submit = resources['X100711'];
                    //enctype="multipart/form-data" 

                    var ret = '<form target="_self" action="' + action + '" method="post" enctype="application/x-www-form-urlencoded" ContentType="application/json" target="curanet_paymentwindow" name="events4usPaymentGateway" id="events4usPaymentGateway">' + 
                               '<input type="hidden" name="' + shopidName + '"' + 'value="' + shopidValue + '">' + 
                               '<input type="hidden" name="' + currencyName + '"' + 'value="' + currencyValue + '">' + 
                               '<input type="hidden" name="' + amountName + '"' + 'value="' + amountValue + '">' + 
                               '<input type="hidden" name="' + orderidName + '"' + 'value="' + orderidValue + '">' + 
                               '<input type="hidden" name="' + orderidprefixName + '"' + 'value="' + orderidprefixValue + '">' + 
                               '<input type="hidden" name="' + paymenttypeName + '"' + 'value="' + paymenttypeValue + '">' + 
                               '<input type="hidden" name="' + uniqueorderName + '"' + 'value="' + uniqueorderValue + '">' + 
                               '<input type="hidden" name="' + langName + '"' + 'value="' + langValue + '">' + 
                               '<input type="hidden" name="' + accepturlName + '"' + 'value="' + accepturlValue + '">' + 
                               '<input type="hidden" name="' + declineurlName + '"' + 'value="' + declineurlValue + '">' + 
                               '<input type="hidden" name="' + callbackName + '"' + 'value="' + callbackurlValue + '">' + 
                               '<input type="hidden" name="' + macName + '"' + 'value="' + app.entities.models.publicPaymentGatewayMD5.attributes.MD5hash + '">' + 
                               '<input style="" type="submit" class="btn btn-primary btn-block" value="' + submit + '">' +
                               '</form>';
                               
                    callback(ret);
                },
                error: function (mode, response) {
                    callback('');
                }
            });
        }

        callbackMD5();
    },
    EPay_get_currency : function(currency) {
        var ret = ''
        switch (currency) {
            case 'DKK' : ret = '208'; break;
            case 'GBP' : ret = '826'; break;
        }

        return ret;
    },
    EPay_get_language : function (cultureName) {
        var ret = ''
        switch (cultureName) {
            case 'da-DK': ret = '1'; break;
            case 'en-GB': ret = '2'; break;
        }
        
        return ret;
    },
    EPaySetupMessage: function (modelCustomSubscription, modelCustomSubscriptionJob) {
        var ordertext = "";
        var jobordertext = '';
        var subTotalText = resources.X100868;
        var subTotalNoVat = '';

        //var tableStart = "<table><thead><tr><th style='width:50%;'></th><th style='width:50%;'></th></tr></thead><tbody>";
        //var trStart = '<tr>';
        //var pStartLeft = '<td>'; 
        //var pStartRight = '<td>'; 
        //var pEnd = '</td>';
        //var trEnd = '</tr>';
        //var tableEnd = '</tr></tbody></table>'

        var price = modelCustomSubscription.attributes.Price;
        var vat = modelCustomSubscription.attributes.Vat;
        var totalPrice = modelCustomSubscription.attributes.TotalPrice;

        ordertext = '<div>' + modelCustomSubscription.attributes.InvoiceDescriptionText + '</br>' + 
            modelCustomSubscription.attributes.InvoiceCommentsText +
            '</br>' + 
            //tableStart + trStart + pStartLeft + resources.X100849 + pEnd + pStartRight + (modelCustomSubscription.attributes.EventQty * modelCustomSubscription.attributes.PaymentPeriodMonth)  + ' ' + resources.X100890 + pEnd + trEnd + 
            //trStart + pStartLeft + resources.X100894 + pEnd + pStartRight + modelCustomSubscription.attributes.Currency + ' ' + modelCustomSubscription.attributes.EventPrice.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd +
            //trStart + pStartLeft + resources.X100895 + pEnd + pStartRight + modelCustomSubscription.attributes.Currency + ' ' + modelCustomSubscription.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd + tableEnd;

            resources.X100849 + ' ' + (modelCustomSubscription.attributes.EventQty * modelCustomSubscription.attributes.PaymentPeriodMonth)  + ' ' + resources.X100890 + '</br>' +
            resources.X100894 + ' ' + modelCustomSubscription.attributes.Currency + ' ' + modelCustomSubscription.attributes.EventPrice.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 })  + '</br>' +
            resources.X100895 + ' ' + modelCustomSubscription.attributes.Currency + ' ' + modelCustomSubscription.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + '</br>';

        var customSubscriptionJob_Price = 0;
        var customSubscriptionJob_Vat = 0;
        var customSubscriptionJob_TotalPrice = 0;

        if (modelCustomSubscriptionJob != null) {
            customSubscriptionJob_Price = modelCustomSubscriptionJob.attributes.Price;
            customSubscriptionJob_Vat = modelCustomSubscriptionJob.attributes.Vat;
            customSubscriptionJob_TotalPrice = modelCustomSubscriptionJob.attributes.TotalPrice;

            jobordertext = '</br>' + modelCustomSubscriptionJob.attributes.InvoiceDescriptionText + '</br>' + 
                modelCustomSubscriptionJob.attributes.InvoiceCommentsText +
            '</br>' +
            resources.X100849 + ' '  + '1'  + ' ' + resources.X100890 + '</br>' + 
            resources.X100894 + ' ' + modelCustomSubscriptionJob.attributes.Currency + ' ' + modelCustomSubscriptionJob.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + '</br>' + 
            resources.X100895 + ' ' + modelCustomSubscriptionJob.attributes.Currency + ' ' + modelCustomSubscriptionJob.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + '</br>';
        }

        ordertext += jobordertext;

        //var totalText  = tableStart + trStart + pStartLeft + resources.X100896 + pEnd + pStartRight + modelCustomSubscription.attributes.Currency + ' ' + (modelCustomSubscription.attributes.Price + customSubscriptionJob_Price).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd + 
        //trStart + pStartLeft + resources.X100897 + pEnd + pStartRight + modelCustomSubscription.attributes.Currency + ' ' + (modelCustomSubscription.attributes.Vat + customSubscriptionJob_Vat).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd +
        //trStart + pStartLeft + resources.X100898 + pEnd + pStartRight + modelCustomSubscription.attributes.Currency + ' ' + (modelCustomSubscription.attributes.TotalPrice + customSubscriptionJob_TotalPrice).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + pEnd + trEnd + tableEnd;
        var totalText  = resources.X100896 + ' ' + modelCustomSubscription.attributes.Currency + ' ' + (modelCustomSubscription.attributes.Price + customSubscriptionJob_Price).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + '</br>' + 
        resources.X100897 + ' ' + modelCustomSubscription.attributes.Currency + ' ' + (modelCustomSubscription.attributes.Vat + customSubscriptionJob_Vat).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + '</br>' + 
        resources.X100898 + ' ' + modelCustomSubscription.attributes.Currency + ' ' + (modelCustomSubscription.attributes.TotalPrice + customSubscriptionJob_TotalPrice).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) + '</br>';
            
        ordertext += '</br>' + totalText + '</br>' + '</div>';

        return ordertext;
    },
    EPay : function(authUserId, messageid, firstName, modelCustomSubscription, modelCustomSubscriptionJob, callback) {
        var ordertext = this.EPaySetupMessage(modelCustomSubscription, modelCustomSubscriptionJob);

        var price = modelCustomSubscription.attributes.Price;
        var vat = modelCustomSubscription.attributes.Vat;
        var totalPrice = modelCustomSubscription.attributes.TotalPrice;

        if (modelCustomSubscriptionJob != null) {
            price += modelCustomSubscriptionJob.attributes.Price;
            vat += modelCustomSubscriptionJob.attributes.Vat;
            totalPrice += modelCustomSubscriptionJob.attributes.TotalPrice;
        }

        /*var ordertext = '';
        var subTotalText = resources.X100868;
        var subTotalNoVat = '';
        var price = modelCustomSubscription.attributes.Price;
        var vat = modelCustomSubscription.attributes.Vat;
        var totalPrice = modelCustomSubscription.attributes.TotalPrice;
        //ordertext = String.format(resources.X100867, 1, modelCustomSubscription.attributes.InvoiceDescriptionText, modelCustomSubscription.attributes.Price, modelCustomSubscription.attributes.Currency, modelCustomSubscription.attributes.Price, modelCustomSubscription.attributes.Currency) + '<br>';
        ordertext = String.format(resources.X100867, modelCustomSubscription.attributes.EventQty, modelCustomSubscription.attributes.InvoiceDescriptionText, modelCustomSubscription.attributes.EventPrice.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscription.attributes.Currency, modelCustomSubscription.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscription.attributes.Currency) + '<br>';
        
        if (modelCustomSubscriptionJob != null) {
            price += modelCustomSubscriptionJob.attributes.Price;
            vat += modelCustomSubscriptionJob.attributes.Vat;
            totalPrice += modelCustomSubscriptionJob.attributes.TotalPrice;
            var jobordertext = String.format(resources.X100867, 1, modelCustomSubscriptionJob.attributes.InvoiceDescriptionText, modelCustomSubscriptionJob.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscriptionJob.attributes.Currency, modelCustomSubscriptionJob.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscriptionJob.attributes.Currency) + '<br>';
            ordertext += jobordertext;

            var subTotal = String.format(subTotalText, (modelCustomSubscription.attributes.Price + modelCustomSubscriptionJob.attributes.Price).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) , modelCustomSubscription.attributes.Currency);
            var subTotalVat = '<br>' + String.format(resources.X100871, (modelCustomSubscription.attributes.Vat + modelCustomSubscriptionJob.attributes.Vat).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscription.attributes.Currency);
            var total = '<br>' + String.format(resources.X100872, (modelCustomSubscription.attributes.TotalPrice + modelCustomSubscriptionJob.attributes.TotalPrice).toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }) , modelCustomSubscription.attributes.Currency);
        } else {
            var subTotal = String.format(subTotalText, modelCustomSubscription.attributes.Price.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscription.attributes.Currency);
            var subTotalVat = '<br>' + String.format(resources.X100871, + modelCustomSubscription.attributes.Vat.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscription.attributes.Currency);
            var total = '<br>' + String.format(resources.X100872, + modelCustomSubscription.attributes.TotalPrice.toLocaleString(config.cultureName(), { minimumFractionDigits : 2 }), modelCustomSubscription.attributes.Currency);
        }

        ordertext += subTotal + subTotalNoVat + subTotalVat + total;*/

        var merchantnumberName = 'merchantnumber';
        var merchantnumberValue = config.paymentGateway_EPay_merchantnumberValue();
        var currencyName = 'currency';
        var currencyValue = this.EPay_get_currency(modelCustomSubscription.attributes.Currency);
        var amountName = 'amount';
        var amountValue = Math.floor(totalPrice * 100); //.001? now skip decimal because * 100
        var orderidName = 'orderid';
        //var orderidValue = modelCustomSubscription.attributes.DineroInvoiceNumber;
        //var orderidValue = modelCustomSubscription.attributes.DineroInvoiceGuid;
        var orderidValue = modelCustomSubscription.attributes.id;
        var windowidName = 'windowid';
        var windowidValue = config.paymentGateway_EPay_windowidValue();
        //var paymentcollectionName = 'paymentcollection';
        //var paymentcollectionValue = '1';
        //var paymenttypeName = 'paytype';
        //var paymenttypeValue = '1,3';
        var languageName = 'language';
        var languageValue = this.EPay_get_language(config.cultureName());
        var instantcaptureName = 'instantcapture';
        var instantcaptureValue = '1';
        var accepturlName = 'accepturl';
        var accepturlValue = config.webApiUrl() + '/index.html#messageWithName/X100724/' + messageid + '/' + encodeURIComponent(firstName) + '/false';
        var cancelurlName = 'cancelurl';
        var cancelurlValue = config.webApiUrl() + '/index.html#pgEvents4usError/X100704/X100726/' + encodeURIComponent(firstName);
        var instantcallbackName = 'instantcallback';
        var instantcallbackValue = '1';
        var callbackurlName = 'callbackurl';
        //var callbackurlValue = config.webApiUrl() + '/api/PublicPaymentGatewayEPayCallback/' + modelCustomSubscription.attributes.id + '/' + authUserId;
        var callbackurlValue = config.webApiUrl() + '/api/PublicPaymentGatewayEPayCallback';
        var ownreceiptName = 'ownreceipt';
        var ownreceiptValue = '1';
        var ordertextName = 'ordertext';
        var ordertextValue = ordertext;
        //var descriptionName = 'ordertext';
        //var descriptionValue = '';
        var hashName = 'hash';

        var value = merchantnumberValue.concat(currencyValue, amountValue, orderidValue, windowidValue, languageValue, 
            instantcaptureValue, accepturlValue, cancelurlValue, instantcallbackValue, callbackurlValue, ownreceiptValue, ordertextValue);

        app.entities.models.publicPaymentGatewayMD5.destroy();
        app.entities.models.publicPaymentGatewayMD5 = new Entities_PublicPaymentGatewayMD5.Model();
        

        app.entities.models.publicPaymentGatewayMD5.attributes.MD5hash = value;

        var callbackMD5 = function() {
            app.entities.models.publicPaymentGatewayMD5.save(app.entities.models.publicPaymentGatewayMD5, {
                //value : value,
                silent: true,
                wait:true,
                success: function (response) {
                    //var action = config.webApiUrl() + '/api/PublicPaymentGatewayEPay';
                    var action = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/Default.aspx';
                    var submit = resources['X100711'];
                    //enctype="multipart/form-data" 

                    var ret = '<form target="_self" action="' + action + '" method="post" enctype="application/x-www-form-urlencoded" ContentType="application/json" target="curanet_paymentwindow" name="events4usPaymentGateway" id="events4usPaymentGateway">' + 
                               '<input type="hidden" name="' + merchantnumberName + '"' + 'value="' + merchantnumberValue + '">' + 
                               '<input type="hidden" name="' + currencyName + '"' + 'value="' + currencyValue + '">' + 
                               '<input type="hidden" name="' + amountName + '"' + 'value="' + amountValue + '">' + 
                               '<input type="hidden" name="' + orderidName + '"' + 'value="' + orderidValue + '">' + 
                               '<input type="hidden" name="' + windowidName + '"' + 'value="' + windowidValue + '">' + 
                               '<input type="hidden" name="' + languageName + '"' + 'value="' + languageValue + '">' + 
                               '<input type="hidden" name="' + instantcaptureName + '"' + 'value="' + instantcaptureValue + '">' + 
                               '<input type="hidden" name="' + accepturlName + '"' + 'value="' + accepturlValue + '">' + 
                               '<input type="hidden" name="' + cancelurlName + '"' + 'value="' + cancelurlValue + '">' + 
                               '<input type="hidden" name="' + instantcallbackName + '"' + 'value="' + instantcallbackValue + '">' + 
                               '<input type="hidden" name="' + callbackurlName + '"' + 'value="' + callbackurlValue + '">' + 
                               '<input type="hidden" name="' + ownreceiptName + '"' + 'value="' + ownreceiptValue + '">' + 
                               '<input type="hidden" name="' + ordertextName + '"' + 'value="' + ordertextValue + '">' +
                               '<input type="hidden" name="' + hashName + '"' + 'value="' + app.entities.models.publicPaymentGatewayMD5.attributes.MD5hash + '">' + 
                               '<input style="" type="submit" class="btn btn-primary btn-block" value="' + submit + '">' +
                               '</form>';
                               
                    callback(ret);
                },
                error: function (mode, response) {
                    callback('');
                }
            });
        }

        callbackMD5();
    },
    EPayShop : function(messageid, firstName, shopOrder, shopProduct, callback) {
        var ordertext = '';
        shopProduct.forEach(function (entry) {
            ordertext += String.format(resources.X100867, entry.attributes.Qty, entry.attributes.InvoiceDescriptionText, entry.attributes.Price, entry.attributes.Currency, (entry.attributes.Qty * entry.attributes.Price), entry.attributes.Currency) + '<br>';
                //entry.attributes.Qty + ' ' + resources.X100867 + ' ' + entry.attributes.InvoiceDescriptionText + ' '  + entry.attributes.Qty + ' ' + entry.attributes.Price + ' ' + (entry.attributes.Qty + entry.attributes.Price) + '<br>';
        });

        var subTotalText = resources.X100868;
        var subTotalNoVat = '';
        if (shopOrder.attributes.SubTotalNoVat > 0) {
            var subTotalText = resources.X100869;
            var subTotalNoVat = '<br>' + String.format(resources.X100870, + shopOrder.attributes.SubTotalNoVat , shopOrder.attributes.Currency);
        } 

        var subTotal = String.format(subTotalText, + shopOrder.attributes.SubTotal , shopOrder.attributes.Currency);
        var subTotalVat = '<br>' + String.format(resources.X100871, + shopOrder.attributes.SubTotalVat , shopOrder.attributes.Currency);
        var total = '<br>' + String.format(resources.X100872, + shopOrder.attributes.Total , shopOrder.attributes.Currency);

        ordertext += subTotal + subTotalNoVat + subTotalVat + total;

        var merchantnumberName = 'merchantnumber';
        var merchantnumberValue = config.paymentGateway_EPay_merchantnumberValue();
        var currencyName = 'currency';
        var currencyValue = this.EPay_get_currency(shopOrder.attributes.Currency);
        var amountName = 'amount';
        var amountValue = shopOrder.attributes.Total * 100;
        var orderidName = 'orderid';
        var orderidValue = shopOrder.attributes.DineroInvoiceNumber;
        var windowidName = 'windowid';
        var windowidValue = config.paymentGateway_EPay_windowidValue();
        //var paymentcollectionName = 'paymentcollection';
        //var paymentcollectionValue = '1';
        //var paymenttypeName = 'paytype';
        //var paymenttypeValue = '1,3';
        var languageName = 'language';
        var languageValue = this.EPay_get_language(config.cultureName());
        var instantcaptureName = 'instantcapture';
        var instantcaptureValue = '1';
        var accepturlName = 'accepturl';
        var accepturlValue = config.webApiUrl() + '/index.html#messageWithName/X100724/' + messageid + '/' + encodeURIComponent(firstName) + '/false';
        var cancelurlName = 'cancelurl';
        var cancelurlValue = config.webApiUrl() + '/index.html#pgEvents4usError/X100704/X100726/' + encodeURIComponent(firstName) + '/false';
        var instantcallbackName = 'instantcallback';
        var instantcallbackValue = '1';
        var callbackurlName = 'callbackurl';
        //var callbackurlValue = config.webApiUrl() + '/api/PublicPaymentGatewayEPayCallback/' + modelCustomSubscription.attributes.id + '/' + authUserId;
        var callbackurlValue = config.webApiUrl() + '/api/PublicPaymentGatewayEPayCallbackShop';
        var ownreceiptName = 'ownreceipt';
        var ownreceiptValue = '1';
        var ordertextName = 'ordertext';
        var ordertextValue = ordertext;
        //var descriptionName = 'ordertext';
        //var descriptionValue = '';
        var hashName = 'hash';

        var value = merchantnumberValue.concat(currencyValue, amountValue, orderidValue, windowidValue, languageValue, 
            instantcaptureValue, accepturlValue, cancelurlValue, instantcallbackValue, callbackurlValue, ownreceiptValue, ordertextValue);

        app.entities.models.publicPaymentGatewayMD5.attributes.MD5hash = value;

        var callbackMD5 = function() {
            app.entities.models.publicPaymentGatewayMD5.save(app.entities.models.publicPaymentGatewayMD5, {
                //value : value,
                silent: true,
                wait:true,
                success: function (response) {
                    //var action = config.webApiUrl() + '/api/PublicPaymentGatewayEPay';
                    var action = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/Default.aspx';
                    var submit = resources['X100711'];
                    //enctype="multipart/form-data" 

                    var ret = '<form target="_self" action="' + action + '" method="post" enctype="application/x-www-form-urlencoded" ContentType="application/json" target="curanet_paymentwindow" name="events4usPaymentGateway" id="events4usPaymentGateway">' + 
                               '<input type="hidden" name="' + merchantnumberName + '"' + 'value="' + merchantnumberValue + '">' + 
                               '<input type="hidden" name="' + currencyName + '"' + 'value="' + currencyValue + '">' + 
                               '<input type="hidden" name="' + amountName + '"' + 'value="' + amountValue + '">' + 
                               '<input type="hidden" name="' + orderidName + '"' + 'value="' + orderidValue + '">' + 
                               '<input type="hidden" name="' + windowidName + '"' + 'value="' + windowidValue + '">' + 
                               '<input type="hidden" name="' + languageName + '"' + 'value="' + languageValue + '">' + 
                               '<input type="hidden" name="' + instantcaptureName + '"' + 'value="' + instantcaptureValue + '">' + 
                               '<input type="hidden" name="' + accepturlName + '"' + 'value="' + accepturlValue + '">' + 
                               '<input type="hidden" name="' + cancelurlName + '"' + 'value="' + cancelurlValue + '">' + 
                               '<input type="hidden" name="' + instantcallbackName + '"' + 'value="' + instantcallbackValue + '">' + 
                               '<input type="hidden" name="' + callbackurlName + '"' + 'value="' + callbackurlValue + '">' + 
                               '<input type="hidden" name="' + ownreceiptName + '"' + 'value="' + ownreceiptValue + '">' + 
                               '<input type="hidden" name="' + ordertextName + '"' + 'value="' + ordertextValue + '">' +
                               '<input type="hidden" name="' + hashName + '"' + 'value="' + app.entities.models.publicPaymentGatewayMD5.attributes.MD5hash + '">' + 
                               '<input style="" type="submit" class="btn btn-primary btn-block" value="' + submit + '">' +
                               '</form>';
                               
                    callback(ret);
                },
                error: function (mode, response) {
                    callback('');
                }
            });
        }

        callbackMD5();
    }


}

export default paymentGateway;