var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom_empty"> <div class="modal fade" id="dialogCustomEventNavigator_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title" id="myModalLabel" style="">'+
((__t=( resources['X101087'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <form class="forms-sample"> <div class="row"> <div class="col-md-12 col-xl-12 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="min-width: 500px">  <p class="card-description" style="margin-bottom: 0px">'+
((__t=( resources['X100234'] ))==null?'':__t)+
'</p> <select id="customEventNavigator_SearchEvent_select" style="width:100%" class="selectpicker" data-live-search="true"></select> <div style="height:14px"></div> <ul class="nav nav-pills nav-pills-success" id="pills-tab" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="customeEventNavigator_tabItem1" data-toggle="pill" href="#customeEventNavigator_tabContent1" role="tab" aria-controls="customeEventNavigator_tabContent1" aria-selected="true"><i class="fa fa-lg fa-2x fab fa-edge"></i> '+
((__t=( resources['X101089'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="customeEventNavigator_tabItem2" data-toggle="pill" href="#customeEventNavigator_tabContent2" role="tab" aria-controls="customeEventNavigator_tabContent2" aria-selected="false"><i class="fa fa-lg fa-2x fa-mobile-alt"></i> '+
((__t=( resources['X100532'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="customeEventNavigator_tabItem3" data-toggle="pill" href="#customeEventNavigator_tabContent3" role="tab" aria-controls="customeEventNavigator_tabContent3" aria-selected="false"><i class="fa fa-lg fa-2x fa-edit"></i> '+
((__t=( resources['X100380'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content" style="padding-top: 10px; padding-bottom: 10px; padding-left: 10px; padding-right: 10px"> <div class="tab-pane fade show active" id="customeEventNavigator_tabContent1" role="tabpanel" aria-labelledby="customeEventNavigator_tabItem1"> <div class="media"> <img id="customEventNavigator_QRCode1" style="height:120px;width:120px" class="" src="" alt="sample image"> <div class="media-body"> <div class="list-wrapper"> <ul class="d-flex flex-column-reverse todo-list"> <li class="completed"> <div class="form-check"> <label class="form-check-label"> </label> </div> </li> <li id="customEventNavigator_liEventPoster"> <div class="form-check"> <label id="customEventNavigator_btnEventPoster" class="form-check-label"> '+
((__t=( resources['X100550'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventInfoScreen"> <div class="form-check"> <label id="customEventNavigator_btnEventInfoScreen" class="form-check-label"> '+
((__t=( resources['X100548'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventSchemaScreen"> <div class="form-check"> <label id="customEventNavigator_btnEventSchemaScreen" class="form-check-label"> '+
((__t=( resources['X100546'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventBrowser"> <div class="form-check"> <label id="customEventNavigator_btnEventBrowser" class="form-check-label"> '+
((__t=( resources['X100544'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEvent"> <div class="form-check"> <label id="customEventNavigator_btnEvent" class="form-check-label"> '+
((__t=( resources['X101099'] ))==null?'':__t)+
' </label> </div>  </li> <li id="customEventNavigator_liEventIPad"> <div class="form-check"> <label id="customEventNavigator_btnEventIPad" class="form-check-label"> '+
((__t=( resources['X101200'] ))==null?'':__t)+
' </label> </div>  </li> </ul> </div> </div> </div> </div> <div class="tab-pane fade" id="customeEventNavigator_tabContent2" role="tabpanel" aria-labelledby="customeEventNavigator_tabItem2"> <div class="media"> <img id="customEventNavigator_QRCode2" style="height:120px;width:120px" class="" src="" alt="sample image"> <div class="media-body"> <div class="list-wrapper"> <ul class="d-flex flex-column-reverse todo-list"> <li id="customEventNavigator_liEventLive"> <div class="form-check"> <label id="customEventNavigator_btnEventLive" class="form-check-label"> '+
((__t=( resources['X100408'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventEdit"> <div class="form-check"> <label id="customEventNavigator_btnEventEdit" class="form-check-label"> '+
((__t=( resources['X100070'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventFormData"> <div class="form-check"> <label id="customEventNavigator_btnEventFormData" class="form-check-label"> '+
((__t=( resources['X100261'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventSchemaData"> <div class="form-check"> <label id="customEventNavigator_btnEventSchemaData" class="form-check-label"> '+
((__t=( resources['X100250'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventEventPush"> <div class="form-check"> <label id="customEventNavigator_btnEventEventPush" class="form-check-label"> '+
((__t=( resources['X100237'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventCounter"> <div class="form-check"> <label id="customEventNavigator_btnEventCounter" class="form-check-label"> '+
((__t=( resources['X100233'] ))==null?'':__t)+
' </label> </div> </li> </ul> </div> </div> </div> </div> <div class="tab-pane fade" id="customeEventNavigator_tabContent3" role="tabpanel" aria-labelledby="customeEventNavigator_tabItem3"> <div class="media"> <img id="customEventNavigator_QRCode3" style="height:120px;width:120px" class="" src="" alt="sample image"> <div class="media-body"> <div class="list-wrapper"> <ul class="d-flex flex-column-reverse todo-list"> <li class="completed"> <div class="form-check"> <label class="form-check-label"> </label> </div> </li> <li id="customEventNavigator_liEventFormModelEditItem"> <div class="form-check"> <label id="customEventNavigator_btnEventFormModelEditItem" class="form-check-label"> '+
((__t=( resources['X100180'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventSchemaModelEditItem"> <div class="form-check"> <label id="customEventNavigator_btnEventSchemaModelEditItem" class="form-check-label"> '+
((__t=( resources['X100150'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventQRCodeEditItem"> <div class="form-check"> <label id="customEventNavigator_btnEventQRCodeEditItem" class="form-check-label"> '+
((__t=( resources['X100071'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventInfopageEditItem"> <div class="form-check"> <label id="customEventNavigator_btnEventInfopageEditItem" class="form-check-label"> '+
((__t=( resources['X100451'] ))==null?'':__t)+
' </label> </div> </li> <li id="customEventNavigator_liEventWebpageEditItem"> <div class="form-check"> <label id="customEventNavigator_btnEventWebpageEditItem" class="form-check-label"> '+
((__t=( resources['X100134'] ))==null?'':__t)+
' </label> </div> </li> </ul> </div> </div> </div> </div> </div> </div> </div> </div> </div> </form> </div> <div class="modal-footer"> <button type="button" id="dialog2Button_btn01" class="btn btn-primary">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
