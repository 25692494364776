var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFile_empty"> <div class="e4u-modal-backdrop"></div> <div class="modal fade" id="designRegion_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:1041"> <div class="modal-content modal-dialog" style="width:90%;overflow-y: initial">  <div id="designRegion_body" class="modal-body" style="overflow-y: auto"> <div class="panel"> <h5 class="widget-title"><i class="fa fa-2x fa-file" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X100322'] ))==null?'':__t)+
'</h5> <div id="customFileInformation"></div> <div id="customFileInsert"></div> <div class="row"> <div class="col-md-12"> <div id="customFileList"></div> </div> </div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customFilePagination" style="text-align:center"></div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="designRegion_btnOk" class="btn btn-primary">'+
((__t=( resources['X101008'] ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
