﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartmentListItem from './tpl/customDepartmentListItem.html';
import language from '../../../common/language';
import View_CustomDepartmentListItem from './customDepartmentListItem';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    // tagName: 'li',
    // className: '',
    getTemplate: function () {
        return tpl_customDepartmentListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'customDepartmentListItem_btnDelete': '#customDepartmentListItem_btnDelete',
        'customDepartmentListItem_btnEdit': '#customDepartmentListItem_btnEdit',
        'customDepartmentListItem_btnPreview': '#customDepartmentListItem_btnPreview',
        'customDepartmentListItem_aQRCode': '#customDepartmentListItem_aQRCode',
        'customDepartmentListItem_profileImage': '#customDepartmentListItem_profileImage',
        'customDepartmentListItem_imageUrl': '#customDepartmentListItem_imageUrl',
        'customDepartmentListItem_openLink': '#customDepartmentListItem_openLink',
        'customDepartmentListItem_copyLink': '#customDepartmentListItem_copyLink',
        'customDepartmentListItem_openLinkPomodoro': '#customDepartmentListItem_openLinkPomodoro',
        'customDepartmentListItem_openLinkPomodoro1': '#customDepartmentListItem_openLinkPomodoro1',
        'customDepartmentListItem_copyLinkPomodoro': '#customDepartmentListItem_copyLinkPomodoro',
        'customDepartmentListItem_copyWebhookURL': '#customDepartmentListItem_copyWebhookURL',
        'customDepartmentUserListItem_inputShowLink': '#customDepartmentUserListItem_inputShowLink',
        'customDepartmentUserListItem_inputShowLinkPomodoro': '#customDepartmentUserListItem_inputShowLinkPomodoro'

    },
    events: {
        'click @ui.customDepartmentListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customDepartmentListItem_btnEdit': 'btnEditClick',
        'click @ui.customDepartmentListItem_btnPreview': 'btnPreviewClick',
        'click @ui.customDepartmentListItem_openLink': 'openLink',
        'click @ui.customDepartmentListItem_copyLink': 'copyLink',
        'click @ui.customDepartmentListItem_openLinkPomodoro': 'openLinkPomodoro',
        'click @ui.customDepartmentListItem_openLinkPomodoro1': 'openLinkPomodoro1',
        'click @ui.customDepartmentListItem_copyLinkPomodoro': 'copyLinkPomodoro',
        'click @ui.customDepartmentListItem_copyWebhookURL': 'copyWebhookURL',
    },
    onRender: function () {
        var nameArray = this.model.attributes.Name.split(' ', 4);
        var intials = '';

        if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
        if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        this.ui.customDepartmentListItem_profileImage.text(intials);

        if (this.model.attributes.ImageUrl != '' && this.model.attributes.ImageUrl.length > 0) {
            this.ui.customDepartmentListItem_imageUrl.show();
            this.ui.customDepartmentListItem_profileImage.css('display', 'none');
        } else {
            this.ui.customDepartmentListItem_imageUrl.css('display', 'none');
            this.ui.customDepartmentListItem_profileImage.show();
        }

        this.setupUrlLink();
    },
    onAttach() {
        //var nameArray = this.model.attributes.Name.split(' ', 4);
        //var intials = '';

        //if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
        //if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        //if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        //if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        //this.ui.customDepartmentListItem_profileImage.text(intials);
    },
    btnEditClick: function () {
        var url = 'customDepartmentEditItem/' + this.model.attributes.id;
        this.app.router.navigate(url);
        this.app.router.customDepartmentEditItem(this.model.attributes.id);

    },
    aQRCodeClick: function () {
        this.btnEditClick();
    },
    btnDeleteClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200122;
        dialog2ButtonModel.attributes.Text = resources.X200154;
        dialog2ButtonModel.attributes.Button01 = resources.X200095;
        dialog2ButtonModel.attributes.Button02 = resources.X200096;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {

            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200122;
            dialogWaitingModel.attributes.Text = resources.X200098;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
            var callbackWaiting = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

            self.app.dialogRegion.show(dialogWaiting);

            var collection = self.app.entities.collections.customDepartment;

            self.model.destroy({
                wait: true,
                //silent: true,
                success: function (model, response) {
                    var callbackDelete = function () {
                        collection.fullCollection.sort();
                        if (collection.state.currentPage < collection.state.lastPage) {
                            collection.getPage(collection.state.currentPage);
                        }
                        else {
                            collection.getLastPage();
                        }

                        dialogWaiting.triggerMethod('dialog:close');
                    }

                    callbackDelete();
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);
        this.app.dialogRegion.show(dialog2Button);
    },
    copyLink: function (event) {
        event.preventDefault();

        this.ui.customDepartmentUserListItem_inputShowLink.select();
        document.execCommand('copy')
    },
    copyLinkPomodoro: function (event) {
        event.preventDefault();

        this.ui.customDepartmentUserListItem_inputShowLinkPomodoro.select();
        document.execCommand('copy')
    },
    copyWebhookURL: function (event) {
        event.preventDefault();

        var page = config.webApiUrl() + '/api/publicLuxaforboard/' + this.app.entities.models.custom.attributes.SecretKey +
            '/' + this.app.entities.models.custom.attributes.SecretCode;

        this.copyTextToClipboard(page);
    },
    fallbackCopyTextToClipboard : function(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch(err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    },
    copyTextToClipboard : function(text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    },
    openLink: function (event) {
        event.preventDefault();

        window.open(this.getPage());
    },
    openLinkPomodoro: function (event) {
        event.preventDefault();

        window.open(this.getPagePomodoro());
    },
    openLinkPomodoro1: function (event) {
        event.preventDefault();

        window.open(this.getPagePomodoro1());
    },
    getPage: function () {
        var page = config.webApiUrl() + '/infopage.html?SecretKey=' + this.app.entities.models.custom.attributes.SecretKey +
            '&SecretCode=' + this.app.entities.models.custom.attributes.SecretCode + '&Department=' + this.model.attributes.id + '&Page=infoPage';
        return page;
    },
    getPagePomodoro: function () {
        //var page = config.webApiUrl() + '/pomodoro.html?SecretKey=' + this.app.entities.models.custom.attributes.SecretKey +
            //'&SecretCode=' + this.app.entities.models.custom.attributes.SecretCode + '&Department=' + this.model.attributes.id + '&Page=1';
        var page = config.webApiUrl() + '/pomodoro.html';
        return page;
    },
    getPagePomodoro1: function () {
        var page = config.webApiUrl() + '/pomodoro.html?SecretKey=' + this.app.entities.models.custom.attributes.SecretKey +
            '&SecretCode=' + this.app.entities.models.custom.attributes.SecretCode + '&Department=' + this.model.attributes.id + '&Page=2';
        return page;
    },
    setupUrlLink: function () {
        this.ui.customDepartmentUserListItem_inputShowLink.val(this.getPage());
        this.ui.customDepartmentUserListItem_inputShowLink.text(this.getPage());

        this.ui.customDepartmentUserListItem_inputShowLinkPomodoro.val(this.getPagePomodoro());
        this.ui.customDepartmentUserListItem_inputShowLinkPomodoro.text(this.getPagePomodoro());
    }
});
