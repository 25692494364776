﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
//import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Selected: 0
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        /*if (method == 'read') {
            options.url = config.fileJson();
        } else if( method == 'create') {
            options.url =  '';
        }
        return Backbone.sync(method, model, options);*/
        options.success.apply();
    }
});

/*


export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Selected : 0
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,

    state: {
        pageSize: 12,
    },

    queryParams: {
        sortKey: 'Selected',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },

    options: {
        full: false
    },

    "sync": function (method, model, options) {
        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.Selected > b.attributes.Selected ? -1
             : a.attributes.Selected < b.attributes.Selected ? 1
             : 0;
    }
});

*/




