﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_information from './tpl/information.html';
import View_InformationList from './informationList';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_information();
    },
    initialize: function () {
        this.listenTo(this.collection, 'change', this.setVisible);
    },
    regions: {
        informationList: "#informationList"
    },
    ui: {
        'information_content': '#information_content'
    },
    onBeforeShow: function () {
        this.informationList.show(new View_InformationList({ collection: this.collection }));
    },
    onRender: function () {
        this.setVisible();
    },
    setVisible: function () {
        if (this.collection.length > 0) {
            this.ui.information_content.show();
        }
        else {
            this.ui.information_content.css('display', 'none');
        }
    }
});
