'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_languageSelectorListItem from './tpl/languageSelectorListItem.html';
import language from '../../../../common/language';
import View_DialogWaiting from '../../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../../lib/entities/empty';
import config from '../../../../common/config';

export default Marionette.ItemView.extend({
    tagName: 'div',
    //className: 'dropdown-item preview-item',
    //attributes: function () {
    //    return {
    //        id: 'languageSelectorListItemItem',
    //    }
    //},

    getTemplate: function () {
        return tpl_languageSelectorListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'languageSelectorListItemItem': '#languageSelectorListItemItem'
    },
    events: {
        'click @ui.languageSelectorListItemItem': 'languageSelectorListItemItem_click',
    },
    languageSelectorListItemItem_click : function() {
        this.model = self.app.entities.collections.languageSetting.where({ Name: this.ui.languageSelectorListItemItem.text().trim() })[0];
        language.userLanguageChange(this.model.attributes.Code);
    }
});

