﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSchemaSchema from './tpl/customSchemaSchema.html';
import language from '../../../common/language';

import View_CustomSchemaSchemaListItemHeader from './customSchemaSchemaListItemHeader';
import View_CustomSchemaSchemaList from './customSchemaSchemaList';
import View_CustomSchemaSchemaInsert from './customSchemaSchemaInsert';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customSchemaSchema();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
    },
    regions: {
        customSchemaSchemaListItemHeader: "#customSchemaSchemaListItemHeader",
        customSchemaSchemaList: "#customSchemaSchemaList",
        customSchemaSchemaInsert: "#customSchemaSchemaInsert"
    },
    onBeforeShow: function () {
        this.customSchemaSchemaListItemHeader.show(new View_CustomSchemaSchemaListItemHeader( { app : this.app }));
        this.customSchemaSchemaList.show(new View_CustomSchemaSchemaList({ collection: this.collection, settingModel : this.settingModel, app : this.app }));
        this.customSchemaSchemaInsert.show(new View_CustomSchemaSchemaInsert({ collection: this.collection, settingModel : this.settingModel, app : this.app }));
    }
});
