var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customQRCodeListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="margin-top: 15px"> <img id="" class="img-sm" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" alt="profile" style="width:100px;height:100px;cursor:pointer"> <div class="ml-3"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> </div> <div class="ml-auto"> <i id="customQRCodeListItem_btnEdit" style="color: #6c7293 !important" class="fa fa-lg fa-1x fa-cog font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customQRCodeListItem_btnDelete" style="color: #6c7293 !important" class="far fa-lg fa-1x fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
