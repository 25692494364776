import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
        
    initialize: function () {
        var that = this;
    },
        
    "sync": function (method, model, options) {
        if (method == 'read') {
            //options.url = config.webApiUrl() + '/api/publicPaymentGatewayMD5/' + options.value;
            options.url = config.webApiUrl() + '/api/publicPaymentGatewayMD5?value=' + options.value;
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/publicPaymentGatewayMD5';
        }   
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    }
});

    