﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageEditItemList from './tpl/customWebpageEditItemList.html';
import View_CustomWebpageEditItemListItem from './customWebpageEditItemListItem';

export default Marionette.CollectionView.extend({
    tagName: 'ol',
    className:'d-flex flex-column todo-list sortable ui-sortable mjs-nestedSortable-branch mjs-nestedSortable-expanded',
    attributes : function () {
        // Return model data
        return {
            class : 'side-links ui-sortable',
            //style : 'padding-left:0px;',
            id : 'customWebpageEditItemListItemTop'
        }
            
    },
    ui : {
        'customWebpageEditItemListItemTop': '#customWebpageEditItemListItemTop'
    },
    //childViewContainer: '#customWebpageEditItemList',
    childView: View_CustomWebpageEditItemListItem,

    getTemplate: function () {
        return tpl_customWebpageEditItemList();
    },
    initialize: function () {
        this.app = this.options.app;
        this.childViewContainer = this.options.childViewContainer; 
    },
    addChild: function (model, ChildView, index) {
        if (model.get('Tag') == 0 || model.get('Tag') == 2 || model.get('Position') == 1) {
            return Marionette.CollectionView.prototype.addChild.call(this, model, ChildView, index);
        }
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    },
    onAttach(){
        var self = this;    
        /*$('#customWebpageEditItemListItem').sortable({
            items: "li:not(.ui-state-disabled)",
            connectWith: '.sort_these',
            stop: function (event, ui) {
                self.updateWebpagePosition();
            }
        });*/


        $('#customWebpageEditItemListItemTop').nestedSortable({
            forcePlaceholderSize: true,
            handle: 'div',
            helper: 'clone',
            items: 'li',
            listType: 'ol',
            opacity: .6,
            placeholder: 'placeholder',
            revert: 250,
            tabSize: 25,
            tolerance: 'pointer',
            toleranceElement: '> div',
            maxLevels: 2,
            isTree: true,
            expandOnHover: 700,
            startCollapsed: false,
            change: function () {
                console.log('Relocated item');
            },
            isAllowed: function (placeholder, placeholderParent, currentItem) { return false; }
        });

        $('#customWebpageEditItemListItemTop li').each(function (index, item) {
            var value = parseInt($(item).data('id'));
            var tmpModel = self.collection.where({ id: value });

            switch (tmpModel[0].attributes.Tag) {
                case 5:
                    $(item).attr("style", "display: none !important");
                    $(item).css('display', 'none !important;');
                    break;

                case 6:
                    $(item).attr("style", "display: none !important");
                    $(item).css('display', 'none !important;');
                    break;
            }
        });
    },
    updateWebpagePosition() {
        var selectedModel = this.collection.where({ Selected : true });
        if (selectedModel.length > 0) {
            var zeroIndex = -1;
            switch(selectedModel[0].attributes.DisplayType) {
                case 0 : zeroIndex = 0; break;
                case 1 : zeroIndex = -1; break;
            }
            var self = this;
            $('#customWebpageEditItemListItemTop li').each(function (index, item) {
                var value= parseInt($(item).data('id'));
                //var liId = parseInt(String($(this).data('id')));
                var tmpModel = self.collection.where({ id: value });
                if (tmpModel[0].attributes.Position != zeroIndex
                    && tmpModel[0].attributes.Position != 2147483647
                    && tmpModel[0].attributes.Position != 2147483646 
                    && tmpModel[0].attributes.Position != 2147483645
                    && tmpModel[0].attributes.Position != 2147483644
                    && tmpModel[0].attributes.Position != 2147483643
                    && tmpModel[0].attributes.Position != 2147483642
                    && tmpModel[0].attributes.Position != 2147483641
                    && tmpModel[0].attributes.Position != index) {
                    tmpModel[0].attributes.Position = index;
                }
            });
        }
        $('#customWebpageEditItemListItemTop li').each(function (index, item) {
            var value = parseInt($(item).data('id'));
            var tmpModel = self.collection.where({ id: value });

            switch (tmpModel[0].attributes.Tag) {
                case 5:
                    $(item).attr("style", "display: none !important");
                    $(item).css('display', 'none !important;');
                    break;

                case 6:
                    $(item).attr("style", "display: none !important");
                    $(item).css('display', 'none !important;');
                    break;
            }
        });
    }
});
