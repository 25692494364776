var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customMenu_empty"> <ul class="nav"> <li class="nav-item nav-profile dropdown"> <div> <a class="dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown" aria-expanded="false"> <div class="d-flex">  <div style="font-size:14px;min-width:50px !important" class="profileImage_smallLogin" id="custom_menu_profileImage"></div> <div style="padding-left:10px;white-space:normal;line-height:0.9 !important"> <span id="custom_menu_loginName" style="" class="sidebar-profile-name font-weight-bold">Cecelia Vargas</span> <p id="custom_menu_loginOrg" class="sidebar-profile-designation text-muted text-small">Sales head</p> </div> </div> </a> <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown" style=""> <a class="dropdown-item" id="custom_menu_CustomSetting_notused" href="#custom"> <i class="mdi mdi-settings-outline text-primary"></i> '+
((__t=( resources['X200034'] ))==null?'':__t)+
' </a> <a id="custom_menu_btnAuthLogoff" class="dropdown-item" style="cursor:pointer"> <i class="mdi mdi-power text-dark"></i> '+
((__t=( resources['X200035'] ))==null?'':__t)+
' </a> </div> </div> </li> <li class="nav-item"> <div class="sidebar-menu-title" style="cursor:pointer" id="customMenuSideMenu02"> <img src="" alt="" class="fa fa-2x fas fa-users" style="margin-right:8px"> <span>'+
((__t=( resources['X200038'] ))==null?'':__t)+
'</span> </div> <ul class="nav submenu-wrapper"> <li class="nav-item" id="custom_menu3"><a class="nav-link" id="custom_menu3_a" href="#customDepartment"><span class="menu-title">'+
((__t=( resources['X200037'] ))==null?'':__t)+
'</span></a></li> <li class="nav-item" id="custom_menu4"><a class="nav-link" id="custom_menu4_a" href="#customDepartmentImport"><span class="menu-title">'+
((__t=( resources['X200147'] ))==null?'':__t)+
'</span></a></li> </ul> </li> <li class="nav-item"> <div class="sidebar-menu-title" style="cursor:pointer" id="customMenuSideMenu01"> <img src="" alt="" class="fa fa-2x fas fa-user" style="margin-right:8px"> <span>'+
((__t=( resources['X200036'] ))==null?'':__t)+
'</span> </div> <ul class="nav submenu-wrapper"> <li class="nav-item" id="custom_menu1"><a class="nav-link" id="custom_menu1_a" href="#customUser"><span class="menu-title">'+
((__t=( resources['X200036'] ))==null?'':__t)+
'</span></a></li> <li class="nav-item" id="custom_menu2"><a class="nav-link" id="custom_menu2_a" href="#customUserImport"><span class="menu-title">'+
((__t=( resources['X200126'] ))==null?'':__t)+
'</span></a></li> </ul> </li> <li class="nav-item"> <div class="sidebar-menu-title" style="cursor:pointer" id="customMenuSideMenu03"> <img src="" alt="" class="fa fa-2x fas fa-desktop" style="margin-right:8px"> <span>'+
((__t=( resources['X200061'] ))==null?'':__t)+
'</span> </div> <ul class="nav submenu-wrapper"> <li class="nav-item" id="custom_menu5"><a class="nav-link" id="custom_menu5_a" href="#customScreen"><span class="menu-title">'+
((__t=( resources['X200032'] ))==null?'':__t)+
'</span></a></li> <li class="nav-item" id="custom_menu10"><a class="nav-link" id="custom_menu10_b" href="#customColor"><span class="menu-title">'+
((__t=( resources['X200162'] ))==null?'':__t)+
'</span></a></li> </ul> </li> <li class="nav-item"> <div class="sidebar-menu-title" style="cursor:pointer" id="customMenuSideMenu04"> <img src="" alt="" class="fa fa-2x fas fa-lock" style="margin-right:8px"> <span>'+
((__t=( resources['X200039'] ))==null?'':__t)+
'</span> </div> <ul class="nav submenu-wrapper"> <li class="nav-item" id="custom_menu6"><a class="nav-link" id="custom_menu6_a" href="#customSecurity"><span class="menu-title">'+
((__t=( resources['X200040'] ))==null?'':__t)+
'</span></a></li> <li class="nav-item" id="custom_menu7"><a class="nav-link" id="custom_menu7_a" href="#customIPAddress"><span class="menu-title">'+
((__t=( resources['X200041'] ))==null?'':__t)+
'</span></a></li> </ul> </li> <li class="nav-item"> <div class="sidebar-menu-title" style="cursor:pointer" id="customMenuSideMenu05"> <img src="" alt="" class="fa fa-2x fas fa-user-cog" style="margin-right:8px"> <span>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</span> </div> <ul class="nav submenu-wrapper"> <li class="nav-item" id="custom_menu8"><a class="nav-link" id="custom_menu8_a" href="#custom"><span class="menu-title">'+
((__t=( resources['X200034'] ))==null?'':__t)+
'</span></a></li> </ul> </li> <li class="nav-item" style="display:none"> <div class="sidebar-menu-title" style="cursor:pointer" id="customMenuSideMenu06"> <img src="" alt="" class="fa fa-2x fas fa-tv" style="margin-right:8px"> <span>'+
((__t=( resources['X200159'] ))==null?'':__t)+
'</span> </div> <ul class="nav submenu-wrapper"> <li class="nav-item" id="custom_menu9"><a class="nav-link" id="custom_menu9_a" href="#infoPage"><span class="menu-title">'+
((__t=( resources['X200159'] ))==null?'':__t)+
'</span></a></li> </ul> </li> </ul> </div>';
}
return __p;
};
