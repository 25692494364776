'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogWaiting from '../../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../../lib/entities/empty';

import View_PictureSingleListItemProperties from './pictureSingleListItemProperties';

import tpl_pictureSingleListItem from './tpl/pictureSingleListItem.html';

export default Marionette.ItemView.extend({
    className: 'myDiv',
    tagName: 'div',
    attributes: function () {
        return {
            id: "pictureSingleListItem_tr",// + this.model.attributes.id,
            style: "border-width: 3px; border-color: black;border-style: none; max-width:150px;display:inline-block;",
            'data-id': this.model.attributes.id,
            'data-position': this.model.attributes.Position
        };
    },
    getTemplate: function () {
        return tpl_pictureSingleListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
        //this.model.bind('destroy', this.remove, this);
    },
    ui: {
        'pictureSingleListItem_tr': '#pictureSingleListItem_tr',
        'pictureSingleListItem_div': '#pictureSingleListItem_div',
        'pictureSingleListItem_div_inner': '#pictureSingleListItem_div_inner',
        'pictureSingleListItem_iHtml': '#pictureSingleListItem_iHtml',
        'pictureSingleListItem_iDelete': '#pictureSingleListItem_iDelete'
    },
    events: {
        'click @ui.pictureSingleListItem_div_inner': 'pictureSingleListItem_div_inner_Click',
        'click @ui.pictureSingleListItem_iHtml': 'iHtmlClick',
        'click @ui.pictureSingleListItem_iDelete': 'iDeleteClick'
    },
    onAttach: function () {
    },
    onRender: function () {
        if (this.model.attributes.Selected) {
            this.ui.pictureSingleListItem_div_inner.css('border-style', 'solid');
            this.ui.pictureSingleListItem_div_inner.css('border-color', 'black');
        }
        else {
            this.ui.pictureSingleListItem_div_inner.css('border-style', 'solid');
            this.ui.pictureSingleListItem_div_inner.css('border-color', 'white');
        }
    },
    pictureSingleListItem_div_inner_Click: function (event) {
        var self = this;
        this.model.collection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: false });
            }
        });

        this.model.set({ Selected: true });
        this.model.collection.trigger('change');
    },
    iHtmlClick: function (event) {
        var self = this;

        var properties = new View_PictureSingleListItemProperties({ app: this.app, model: this.model, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(properties, "dialog:btn01clicked", callback01);
            self.stopListening(properties, "dialog:btn02clicked", callback02);
            self.model.trigger('change');
        }
        this.listenTo(properties, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(properties, "dialog:btn01clicked", callback01);
            self.stopListening(properties, "dialog:btn02clicked", callback02);

        }
        this.listenTo(properties, "dialog:btn02clicked", callback02);

        self.app.dialogRegion2.show(properties);
    },
    iDeleteClick: function (event) {
        var self = this;

        event.preventDefault();

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X101341;
        dialog2ButtonModel.attributes.Text = resources.X101365;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            this.model.collection.remove(this.model);
            var customInfoImageModel = this.app.entities.collections.customInfoImage.where({ id: this.model.attributes.id })[0];
            customInfoImageModel.destroy();

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('pictureSingleList:reOrder');
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion2.show(dialog2Button);
    }
});