var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentResetListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="margin-top: 15px">  <div class="ml-3"> <div class="media"> <i id="customDepartmentResetListItem_div" class="mdi icon-md text-info d-flex align-self-center mr-3" style="width:40px; height:40px;background-color:red"></i> <div class="media-body"> <p style="font-size: 0.9em" id="customDepartmentResetListItem_divActiveTab" class="card-text">'+
((__t=( model.ActiveTab ))==null?'':__t)+
'</p> <p style="font-size: 0.9em" id="customDepartmentResetListItem_divText" class="card-text">'+
((__t=( model.Text ))==null?'':__t)+
'</p> <p style="font-size: 0.9em" id="customDepartmentResetListItem_divTime" class="card-text">'+
((__t=( model.TmpTime ))==null?'':__t)+
'</p> </div> </div> </div> <div class="ml-auto"> <i id="customDepartmentResetListItem_btnEdit" style="color: #6c7293 !important; cursor:pointer" class="fa fa-lg fa-cog font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customDepartmentResetListItem_btnDelete" style="color: #6c7293 !important; cursor:pointer" class="far fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
