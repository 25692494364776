﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import config from '../../../../common/config';
import Help from '../../../helper/help';
import language from '../../../../common/language';
import tpl_header from './tpl/header.html';

import * as Entities_Empty from '../../../../lib/entities/empty';

import View_CustomSetting from '../../customSetting/customSetting';
import View_CustomEventNavigator from '../../customEventNavigator/customEventNavigator';
import Region_LanguageSelector from '../languageSelector/languageSelector';


export default Marionette.LayoutView.extend({
    //tagName: 'ul',
    //className: 'navbar-nav navbar-nav-right"',
    initialize: function () {
        this.app = this.options.app;

        this.model = new Entities_Empty.Model();
        this.model.attributes.CultureName = language.getLanguageFromCodeCultureName(config.cultureName());

        this.modelCustom = this.options.modelCustom;

        var userChannel = Backbone.Radio.channel('app');

        var self = this;

        userChannel.on('Region_Header:login', function() {
            self.header_btnAuthClick();
        });

        //userChannel.on('Region_Header:setLoginName', function() {
        //    if (self.app.mgr.profile !== undefined) {
        //        self.ui.header_btnAuth.css('display', 'none');
        //        self.ui.header_btnAuthLogoff.show();
        //        self.ui.header_btnForgotPassword.css('display', 'none');
        //        self.ui.header_li_price.css('display', 'none');
        //        self.ui.header_loginName.show();
            
        //        var displayName = self.options.customModel.attributes.Name;
                

        //        var displayOrg = resources['X200030'];  

        //        if (displayName.length <= 0) {
        //            //self.ui.header_li_loginName.html('');
        //            //$(self.ui.header_li_loginName).html('');

        //            //var html = '<a href="#price" style="font-size:14px"><i class="fa fa-tag"></i>' + resources['X100602'] + '</a>'
        //            //self.ui.header_li_price.html(html);
        //            //$(self.ui.header_li_loginName).html(html);

        //            self.ui.header_loginName.html('');
        //            self.ui.header_loginOrg.html('');

        //        }else {
        //            //self.ui.header_li_price.html('');
        //            //$(self.ui.header_li_loginName).html('');

        //            //var html = '<a id="header_loginName" style="color:rgb(220,220,220);font-size:14px;padding-top:10px;"><i class="fa fa-user"></i>' + ' ' + resources.X100278 +  model.attributes.FirstName + '!' + '</a>';
        //            //var html = '<a id="header_loginName" style="font-size:14px;padding-top:10px;"><i class="fa fa-user"></i>' + ' ' + resources.X100278 +  ' ' + displayName + '!' + '</a>';

        //            //self.ui.header_li_loginName.html(html);
        //            //$(self.ui.header_li_loginName).html(html);

        //            //var userTag = ' ' + resources.X100278 +  ' ' + displayName + '!';
        //            var userTag = displayName;

        //            // self.ui.header_loginName.html('<i class="fa fa-user"></i>' + ' ' + resources.X100278 + ' ' + displayName + '!');
        //            self.ui.header_loginName.html(displayName);
        //            self.ui.header_loginOrg.html(displayOrg);
        //        }
        //    } else {
        //        self.ui.header_btnAuth.show();
        //        self.ui.header_btnAuthLogoff.css('display', 'none');
        //        self.ui.header_btnForgotPassword.show();
        //        self.ui.header_li_price.show();
        //        //$(self.ui.header_li_loginName).html('');
        //        self.ui.header_loginName.css('display', 'none');
        //        self.ui.header_loginOrg.css('display', 'none');
        //    }
        //});

        userChannel.on('Region_Header:customerSetting', function () {
            self.headerCustomSettingClick();
        });


        ////userChannel.on('customMenu:enable', function() {
        ////    self.options.enable = true;
        ////    self.initMenu(true);
        ////});
        //this.listenTo(userChannel, 'customMenu:enable', function() {
        //    self.options.enable = true;
        //    self.initMenu(true);
        //});

        ////userChannel.on('customMenu:disable', function() {
        ////    self.options.enable = false;
        ////    self.initMenu(false);
        ////});
        //this.listenTo(userChannel, 'customMenu:disable', function() {
        //    self.options.enable = false;
        //    self.initMenu(false);
        //});
    },
    getTemplate: function () {
        

        return tpl_header({ model: this.model.toJSON() });
        //return tpl_header();
    },
    regions: {
        languageSelectorRegion: "#languageSelectorRegion"
    },
    ui: {
        'headerCustomSetting': '#headerCustomSetting',
        'headerCustomSetting01': '#headerCustomSetting01',
        'headerCustomEvent': '#headerCustomEvent',
        'headerHelp': '#headerHelp',
        //'header_selectFlagstrip': '#header_selectFlagstrip',
        'header_loginName' : '#header_loginName',
        'header_loginOrg': '#header_loginOrg',
        'header_btnAuth': '#header_btnAuth',
        'header_btnAuthLogoff' : '#header_btnAuthLogoff',
        'header_li_price' : '#header_li_price',
        'header_li_loginName' : '#header_li_loginName',
        'header_btnForgotPassword' : '#header_btnForgotPassword'
    },
    events: {
        'click @ui.headerCustomSetting': 'headerCustomSettingClick',
        'click @ui.headerCustomSetting01': 'headerCustomSettingClick',
        'click @ui.headerCustomEvent': 'headerCustomEventClick',

        'click @ui.headerHelp': 'headerHelpClick',

        //'click #custom_menu11_a': 'help',
        
        'click @ui.header_btnAuth' : 'header_btnAuthClick',
        'click @ui.header_btnAuthLogoff' : 'header_btnAuthLogoffClick',
        'click @ui.header_btnForgotPassword' : 'header_btnForgotPasswordClick'
    },
    onBeforeShow: function () {
        this.languageSelectorRegion.show(new Region_LanguageSelector({ app: self.app, collection: self.app.entities.collections.languageSetting }));
    },
    onRender: function () {
        //var userChannel = Backbone.Radio.channel('app');
        //userChannel.trigger('Region_Header:setLoginName');
        this.$el = this.$el.children();
        // Unwrap the element to prevent infinitely 
        // nesting elements during re-render.
        this.$el.unwrap();
        this.setElement(this.$el);
    },
    onAttach() {
        $('#headerList').after('<button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas"><img src = "../../images/sidebar/menu.svg" alt = "" class= "" ></button >');

        $('[data-toggle="offcanvas"]').on("click", function () {
            $('.sidebar-offcanvas').toggleClass('active')
        });

        //$('#header_selectFlagstrip').flagStrap({
        //    countries: {
        //        "DK": "Denmark",
        //        "GB": "United Kingdom"
        //    },
        //    buttonSize: "btn-sm",
        //    buttonType: "btn-info",
        //    labelMargin: "10px",
        //    scrollable: false,
        //    scrollableHeight: "350px",
        //});

        //$('.dropdown-toggle').css('background-color', 'transparent');
        //$('.dropdown-toggle').css('padding-top', '4px');
        //$('.dropdown-toggle').css('color', 'rgb(248,201,196)');
        //$('.dropdown-toggle').css('font-size', '14px');

        //$('.dropdown-menu').css('margin-top','0px');

        //$('.dropdown-menu').val(language.getLanguageFromCodeCultureName(config.cultureName()));

        if (this.app.user && this.app.user.access_token) {
            this.ui.header_btnAuth.css('display', 'none');
            this.ui.header_btnAuthLogoff.show();
            this.ui.header_btnForgotPassword.css('display', 'none');
            this.ui.header_li_price.css('display', 'none');
            this.ui.header_loginName.show();
            this.ui.header_loginOrg.show();
        } else {
            this.ui.header_btnAuth.show();
            this.ui.header_btnAuthLogoff.css('display', 'none');
            this.ui.header_btnForgotPassword.show();
            this.ui.header_li_price.show();
            this.ui.header_loginName.css('display', 'none');
            this.ui.header_loginOrg.css('display', 'none');
        }
    },
    headerCustomSettingClick: function (event) {
        var self = this;

        var dialogBoxModel = new Entities_Empty.Model();
        dialogBoxModel.attributes.Title = resources.X100449;
        dialogBoxModel.attributes.Button01 = resources.X100279;
        dialogBoxModel.attributes.Button02 = resources.X100280;

        var customSetting = new View_CustomSetting({ app: this.app, model: dialogBoxModel, parent: self });

        var callback01 = function () {
            self.stopListening(customSetting, "dialog:btn01clicked", callback01);
            self.stopListening(customSetting, "dialog:btn02clicked", callback02);

            var customSetting = self.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });

            customSetting[0].save(customSetting[0].attributes, {
                silent: true,
                success: function () {
                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customSetting:refreshPublishBtn');
                }
            });

            var customSetting = self.app.entities.collections.customSetting.where({ Name: 'ShowInformation' });

            customSetting[0].save(customSetting[0].attributes, {
                silent: true,
                success: function () {
                }
            });
        }
        this.listenTo(customSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(customSetting, "dialog:btn01clicked", callback01);
            self.stopListening(customSetting, "dialog:btn02clicked", callback02);
        }
        this.listenTo(customSetting, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(customSetting);

        //self.app.settingRegion.show(customSetting);
    },
    headerCustomEventClick: function (event) {
        //var self = this;

        //var dialogBoxModel = new Entities_Empty.Model();
        //dialogBoxModel.attributes.Title = resources.X100449;
        //dialogBoxModel.attributes.Button01 = resources.X101008;

        //var customSetting = new View_CustomEventNavigator({ app: this.app, model: dialogBoxModel, collection: self.app.entities.collections.customEvent, parent: self });

        //var callback01 = function () {
        //    self.stopListening(customSetting, "dialog:btn01clicked", callback01);

        //    var customSetting = self.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });

        //    customSetting[0].save(customSetting[0].attributes, {
        //        silent: true,
        //        success: function () {
        //            var userChannel = Backbone.Radio.channel('app');
        //            userChannel.trigger('customSetting:refreshPublishBtn');
        //        }
        //    });

        //    var customSetting = self.app.entities.collections.customSetting.where({ Name: 'ShowInformation' });

        //    customSetting[0].save(customSetting[0].attributes, {
        //        silent: true,
        //        success: function () {
        //        }
        //    });
        //}
        //this.listenTo(customSetting, "dialog:btn01clicked", callback01);

        
        //this.app.dialogRegion.show(customSetting);
    },
    headerHelpClick: function (event) {
        var helpString = $('.content-wrapper').data("help"); 
        Help.help(this, true, helpString);
    },
    header_btnAuthClick: function (event) {
        if (event) {
            event.preventDefault();
        }

        window.mgr.redirectForToken().then(function () {
            //alert('Success: ');
        }, function (err) {
            //alert('Error: ');
        });
    },
    header_btnAuthLogoffClick: function (event) {
        event.preventDefault();

        mgr.redirectForLogout();
    },
    header_btnForgotPasswordClick: function (event) {
        event.preventDefault();

        window.app.router.navigate("#forgotPassword");
        window.app.router.forgotPassword();
    }

}, Backbone.Radio.Requests);
