var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="dialogBoxInactive_empty"> <div class="modal-backdrop fade in" style="opacity:0.1"> </div> <div class="modal-content" style="top: 25px;\r\n         background-color: #fff;\r\n         right: -50px;\r\n         left: auto;\r\n         transform: rotate(45deg);\r\n         -webkit-transform: rotate(45deg);\r\n         position: fixed;\r\n         width: 200px;\r\n         z-index:2000;\r\n         position: absolute;\r\n         top: 25px;\r\n         left: -50px;\r\n         text-align: center;\r\n         line-height: 50px;\r\n         letter-spacing: 1px;\r\n         color: #f0f0f0;\r\n         transform: rotate(-45deg);\r\n         -webkit-transform: rotate(-45deg);\r\n         font-size:25px;\r\n         opacity: 1;\r\n         background: #39d"> '+
((__t=( model.Text ))==null?'':__t)+
' </div> </div>';
}
return __p;
};
