var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li> <a id="customWebpageEditItemListItemSmall_a" data_id="'+
((__t=( model.id ))==null?'':__t)+
'" data_orgid="'+
((__t=( model.orgId ))==null?'':__t)+
'" data-toggle="" style="white-space:normal" class="" data-parent="" href="#">'+
((__t=( model.Name ))==null?'':__t)+
'</a>  </li>';
}
return __p;
};
