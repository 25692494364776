﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customInfopageEditItemList from './tpl/customInfopageEditItemList.html';
import View_customInfopageEditItemListItem from './customInfopageEditItemListItem';

export default Marionette.CollectionView.extend({
    tagName: 'ol',
    className: 'd-flex flex-column todo-list sortable ui-sortable',
    attributes: function () {
        // Return model data
        return {
            class: 'side-links ui-sortable',
            id: 'customInfopageEditItemListItem',
            style: 'padding-left: 0px',
        }
    },
    ui: {
        'customInfopageEditItemListItem': '#customInfopageEditItemListItem'
    },
    childViewContainer: '#customInfopageEditItemListItem',
    childView: View_customInfopageEditItemListItem,

    getTemplate: function () {
        return tpl_customInfopageEditItemList();
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;
        this.childViewContainer = this.options.childViewContainer;
        this.customModuleModel = this.options.customModuleModel;

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customInfopageEditItemList:updateWebpagePosition', function (content) {
            self.updateWebpagePosition();
        });

        this.listenTo(userChannel, 'customInfopageEditItemList:reorder', function (content) {
            self.reorder();
        });
    },
    addChild: function (model, ChildView, index) {
        if (model.get('Tag') == 1 && model.get('Position') >= 0 ||
            model.get('Tag') == 10) {
            return Marionette.CollectionView.prototype.addChild.call(this, model, ChildView, index);
        }
    },
    buildChildView: function (child, ChildViewClass, childViewOptions) {
        var options = _.extend({ model: child }, childViewOptions);
        options.app = this.app;
        options.customModuleModel = this.customModuleModel;

        var view = new ChildViewClass(options);
        return view;
    },
    onAttach() {
        var self = this;
        $('#customInfopageEditItemListItem').sortable({
            //items: "li:not(.ui-state-disabled)",
            items: ".fffggg",
            connectWith: '.sort_these',
            stop: function (event, ui) {
                self.updateWebpagePosition();
            }
        });

        //this.reorder();
    },
    reorder: function () {
        this.collection.forEach(function (entry) {
            if (entry.attributes.Parent != -1) {
                var id_from = '#customInfopageEditItemListItem_li_' + entry.attributes.id;
                var id_ol = '#customInfopageEditItemListItem_ol_' + entry.attributes.Parent;
                var ol = $(id_ol);
                $(id_from).appendTo($(ol));
            }
        });
    },
    updateWebpagePosition() {
        var self = this;

        //var selectedModel = this.collection.where({ Selected: 1 });
        //if (selectedModel.length > 0) {
        //    var zeroIndex = -1;
        //    /*switch (selectedModel[0].attributes.DisplayType) {
        //        case 0: zeroIndex = 0; break;
        //        case 1: zeroIndex = -1; break;
        //    }*/
        //    var self = this;
        //    $('#customInfopageEditItemListItem li').each(function (index, item) {
        //        var value = parseInt($(item).data('id'));
        //        var tmpModel = self.collection.where({ id: value });
        //        tmpModel[0].attributes.Position = index;
        //    });
        //}
        var position = 0;
        $('#customInfopageEditItemListItem li').each(function (index, item) {
            var value = parseInt($(item).data('id'));
            var tmpModel = self.collection.where({ id: value });
            //tmpModel[0].attributes.Position = index;
            //tmpModel[0].set({ Position: index });
            //$(item).attr('data-position', index);
            tmpModel[0].set({ Position: position });
            $(item).attr('data-position', position);
            $(item).addClass('ui-state-default');
            $(item).addClass('ui-sortable-handle');
            position++;
        });
    }
});
