﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartment from './tpl/customDepartment.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomDepartmentInsert from './customDepartmentInsert';
import View_CustomDepartmentList from './customDepartmentList';

import * as Entities_MemoryPageable from '../../entities/memoryPageable';

import tpl_customDepartmentUser from './tpl/customDepartmentUser.html';
import View_CustomDepartmentUserListA from './customDepartmentUserListA';
import View_CustomDepartmentUserListB from './customDepartmentUserListB';

import config from '../../../common/config';

import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customDepartmentUser();
    },
    regions: {
        customDepartmentUserListA: "#customDepartmentUserListA",
        customDepartmentUserListAPagination: "#customDepartmentUserListAPagination",
        customDepartmentUserListB: "#customDepartmentUserListB",
        customDepartmentUserListBPagination: "#customDepartmentUserListBPagination"
        //information: "#customDepartmentInformation",
    },
    onBeforeShow: function () {

        this.setupCollection();

        this.customDepartmentUserListA.show(new View_CustomDepartmentUserListA({ collection: this.app.entities.collections.customDepartmentUser, app: this.app, parentModel: this.options.parentModel }));
        this.customDepartmentUserListAPagination.show(new View_Pagination({ collection: this.app.entities.collections.customDepartmentUser, app: this.app }));
        this.customDepartmentUserListB.show(new View_CustomDepartmentUserListB({ collection: this.collectionB, app: this.app }));
        this.customDepartmentUserListBPagination.show(new View_Pagination({ collection: this.collectionB, app: this.app }));

        //  this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    setupCollection: function () {
        var self = this;
        this.collectionB = new Entities_MemoryPageable.CollectionPagination();
        this.collectionB.fullCollection.comparator = this.collectionB.comparator;

        this.collectionB_full = new Entities_MemoryPageable.Collection();
        

        this.app.entities.collections.customUser.fullCollection.forEach(function (entry) {
            var foundModel = self.app.entities.collections.customDepartmentUser.fullCollection.where({ User: entry.attributes.id })[0];

            if (foundModel == undefined) {
                var model = new Entities_MemoryPageable.Model();
                model.attributes.id = entry.attributes.id;
                model.attributes.AuthUserId = entry.attributes.AuthUserId;
                model.attributes.Name = entry.attributes.Name;
                model.attributes.CallerId = entry.attributes.CallerId;
                model.attributes.Email = entry.attributes.Email;
                model.attributes.Color = entry.attributes.Color;
                model.attributes.ImageUrl = entry.attributes.ImageUrl;

                self.collectionB.fullCollection.add(model);
                self.collectionB_full.add(model);
            }
        });
    },
    ui: {
        'customDepartmentUserListAA': '#customDepartmentUserListAA',
        'customDepartmentUserListBB': '#customDepartmentUserListBB',
        'customDepartmentUserListA_inputSearch': '#customDepartmentUserListA_inputSearch',
        'customDepartmentUserListA_btnSearch': '#customDepartmentUserListA_btnSearch',
        'customDepartmentUserListB_inputSearch': '#customDepartmentUserListB_inputSearch',
        'customDepartmentUserListB_btnSearch': '#customDepartmentUserListB_btnSearch',
        'help': '#help',
        'customDepartment_inputSearch': '#customDepartment_inputSearch',
        'customDepartment_btnSearch': '#customDepartment_btnSearch',
    },
    events: {
        'click #help': 'help',
        'click #customDepartment_btnSearch': 'search',
        'click #customDepartmentUserListA_btnSearch': 'searchA',
        'click #customDepartmentUserListB_btnSearch': 'searchB'

    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customDepartmentUserList');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },
    onRender: function () {
        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        //if (this.collection.models.length <= 0) {
        //    var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X200090, Information: resources.X200092 });
        //    this.informationCollection.add(tmpModel);
        //}
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
    },
    onAttach() {
        var self = this;

        var callbackRefresh = function () {
            self.app.entities.collections.customDepartmentUser.fullCollection.sort();
            if (self.app.entities.collections.customDepartmentUser.state.currentPage < self.app.entities.collections.customDepartmentUser.state.lastPage) {
                self.app.entities.collections.customDepartmentUser.getFirstPage({ fetch: false });
                //self.app.entities.collections.customDepartmentUser.getPage(self.app.entities.collections.customDepartmentUser.state.currentPage);
            }
            else {
                self.app.entities.collections.customDepartmentUser.getLastPage();
            }

            self.collectionB.fullCollection.sort();
            if (self.collectionB.state.currentPage < self.collectionB.state.lastPage) {
                self.collectionB.getPage(self.collectionB.state.currentPage);
            }
            else {
                self.collectionB.getLastPage();
            }
        }

        dragula([document.getElementById("customDepartmentUserListAA"), document.getElementById("customDepartmentUserListBB")]).on('drag', function (el) {
            el.className = el.className.replace('ex-moved', '');
        }).on('drop', function (el, target, source, sibling) {
            var id = parseInt($(el).attr('data-id'));

            if (target.id == 'customDepartmentUserListAA') {
                var foundModel = self.collectionB.fullCollection.where({ id: id })[0];
                if (foundModel != undefined) {
                    var tmpModel = new self.app.entities.collections.customDepartmentUser.model();
                    tmpModel.attributes.AuthUserId = config.authUserId();
                    tmpModel.attributes.Department = self.options.department;
                    tmpModel.attributes.User = foundModel.attributes.id;

                    tmpModel.attributes.Name = foundModel.attributes.Name;
                    tmpModel.attributes.Email = foundModel.attributes.Email;
                    tmpModel.attributes.CallerId = foundModel.attributes.CallerId;
                    tmpModel.attributes.Color = foundModel.attributes.Color;
                    tmpModel.attributes.ImageUrl = foundModel.attributes.ImageUrl;

                    self.app.entities.collections.customDepartmentUser.fullCollection.create(tmpModel, {
                        //silent: true,
                        wait: true,
                        success: function (response) {
                            self.collectionB_full.remove(foundModel);
                            self.collectionB.fullCollection.remove(foundModel);

                            callbackRefresh();
                        },
                        error: function (mode, response) {
                        }
                    });
                }
            }

            if (target.id == 'customDepartmentUserListBB') {
                var foundModel = self.app.entities.collections.customDepartmentUser.fullCollection.where({ id: id })[0];

                if (foundModel != undefined) {

                    foundModel.destroy({
                        wait: true,
                        //silent: true,
                        success: function (model, response) {
                            var callbackDelete = function () {
                                
                                //Add model to B
                                var model = new Entities_MemoryPageable.Model();
                                model.attributes.id = foundModel.attributes.id;
                                model.attributes.AuthUserId = foundModel.attributes.AuthUserId;
                                model.attributes.Name = foundModel.attributes.Name;
                                model.attributes.CallerId = foundModel.attributes.CallerId;
                                model.attributes.Email = foundModel.attributes.Email;
                                model.attributes.Color = foundModel.attributes.Color;
                                model.attributes.ImageUrl = foundModel.attributes.ImageUrl;

                                self.collectionB.fullCollection.add(model);
                                self.collectionB_full.add(model);

                                callbackRefresh();
                            }

                            callbackDelete();
                        },
                        error: function (model, response) {
                        }
                    });
                }
            }
        });
    },
    search: function () {
        var self = this;

        var information = this.ui.customDepartment_inputSearch.val();

        this.app.entities.collections.customDepartment.fetch({
            route: 'route02',
            Information: encodeURIComponent(information),
            success: function (data) {

                var d = data;
                //dialogWaiting.triggerMethod('dialog:close');

                /*var timeout_id = setTimeout(function () {
                    document.body.className = document.body.className.replace("modal-open", "");
                    $('.modal.fade.show').remove();
                    $('.modal-backdrop').remove();
                    self.triggerMethod('rerender');
                }, 1000);*/
            }
        });
    },
    searchA: function () {
        var self = this;

        var information = this.ui.customDepartmentUserListA_inputSearch.val();

        this.app.entities.collections.customDepartmentUser.fetch({
            route: 'route02',
            department: self.options.department,
            Information: encodeURIComponent(information),
            success: function (data) {
            }
        });
    },
    searchB: function () {
        var self = this;

        var information = this.ui.customDepartmentUserListB_inputSearch.val();

        //var collection = this.collectionB_full.search(encodeURIComponent(information));
        var collection = this.collectionB_full.search(information);

        this.collectionB.fullCollection.reset(collection, { silent: true });
        this.collectionB.state.totalRecords = this.collectionB.fullCollection.length;
        this.collectionB.setPageSize(parent.app.entities.collections.helpPagination.state.pageSize, { first: true });

    }
}, Backbone.Radio.Requests);