﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartmentImportListItem from './tpl/customDepartmentImportListItem.html';
import language from '../../../common/language';
import View_CustomDepartmentImportListItem from './customDepartmentImportListItem';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_customDepartmentImportListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'customDepartmentImportListItem_btnDelete': '#customDepartmentImportListItem_btnDelete',
        'customDepartmentImportListItem_btnEdit': '#customDepartmentImportListItem_btnEdit',
        'customDepartmentImportListItem_btnPreview': '#customDepartmentImportListItem_btnPreview',
        'customDepartmentImportListItem_aQRCode': '#customDepartmentImportListItem_aQRCode',
        'customDepartmentImportListItem_profileImage': '#customDepartmentImportListItem_profileImage',
        'customDepartmentImportListItem_imageUrl': '#customDepartmentImportListItem_imageUrl'
    },
    events: {
        'click @ui.customDepartmentImportListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customDepartmentImportListItem_btnEdit': 'btnEditClick',
        'click @ui.customDepartmentImportListItem_btnPreview': 'btnPreviewClick',
        'click @ui.customDepartmentImportListItem_aQRCode': 'aQRCodeClick'
    },
    onRender: function () {

        var nameArray = this.model.attributes.Name.split(' ', 4);
        var intials = '';

        if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
        if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        this.ui.customDepartmentImportListItem_profileImage.text(intials);

        if (this.model.attributes.ImageUrl != '' && this.model.attributes.ImageUrl.length > 0) {
            this.ui.customDepartmentImportListItem_imageUrl.show();
            this.ui.customDepartmentImportListItem_profileImage.css('display', 'none');
        } else {
            this.ui.customDepartmentImportListItem_imageUrl.css('display', 'none');
            this.ui.customDepartmentImportListItem_profileImage.show();
        }
    },
    onAttach() {
        var nameArray = this.model.attributes.Name.split(' ', 4);
        var intials = '';

        if (nameArray.length > 0) intials += nameArray[0].charAt(0).toUpperCase();
        if (nameArray.length > 1) intials += nameArray[1].charAt(0).toUpperCase();
        if (nameArray.length > 2) intials += nameArray[2].charAt(0).toUpperCase();
        if (nameArray.length > 3) intials += nameArray[3].charAt(0).toUpperCase();

        this.ui.customDepartmentImportListItem_profileImage.text(intials);
    },
    btnEditClick: function () {
        var url = 'customDepartmentImportEditItem/' + this.model.attributes.id;
        this.app.router.navigate(url);
        this.app.router.customDepartmentImportEditItem(this.model.attributes.id);

    },
    aQRCodeClick: function () {
        this.btnEditClick();
    },
    btnDeleteClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200101;
        dialog2ButtonModel.attributes.Text = resources.X200105;
        dialog2ButtonModel.attributes.Button01 = resources.X200095;
        dialog2ButtonModel.attributes.Button02 = resources.X200096;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {

            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200101;
            dialogWaitingModel.attributes.Text = resources.X200098;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
            var callbackWaiting = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

            self.app.dialogRegion.show(dialogWaiting);

            var collection = self.app.entities.collections.customDepartmentTmp;

            self.model.destroy({
                wait: true,
                //silent: true,
                success: function (model, response) {
                    var callbackDelete = function () {
                        collection.fullCollection.sort();
                        if (collection.state.currentPage < collection.state.lastPage) {
                            collection.getPage(collection.state.currentPage);
                        }
                        else {
                            collection.getLastPage();
                        }

                        dialogWaiting.triggerMethod('dialog:close');
                    }

                    callbackDelete();
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);
        this.app.dialogRegion.show(dialog2Button);
    }
});
