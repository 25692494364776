var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="top_empty"> <div id="main-nav" class="navbar navbar-default hide-icons navbar-fixed-top"> <div class="container" style="border-top-width: 0px; width:95%"> <div class="navbar-header"> <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> <span class="fa fa-bars fa-lg"></span> </button> <a class="navbar-brand" href="#index"> <img src="/Images/Events4us.png"> </a>  </div> <div class="navbar-collapse collapse"> <ul class="nav navbar-nav nav-buttons navbar-right"> <li><a class="nav-btn bg-1" href="#contact"><i class="fa fa-envelope"></i><span class="xs-only">'+
((__t=( resources['X100604'] ))==null?'':__t)+
'</span></a></li> <li><span style="margin-right:5px"></span></li>  </ul> <ul class="nav navbar-nav navbar-right">  <li class="active"><a href="#index" data-res="Home"><i class="icon fa fa-home"></i>'+
((__t=( resources['X100138'] ))==null?'':__t)+
'</a></li> <li class="active"><a href="#price" data-res="Home"><i class="icon fa fa-home"></i>'+
((__t=( resources['X100560'] ))==null?'':__t)+
'</a></li> <li class="active"><a href="#searchEvent" data-res="Home"><i class="icon fa fa-home"></i>'+
((__t=( resources['X100027'] ))==null?'':__t)+
'</a></li> <li class="active"><a href="#about" data-res="OurService"><i class="icon fa fa-home"></i>'+
((__t=( resources['X100605'] ))==null?'':__t)+
'</a></li> <li id="menu_li_portal" class="active"><a href="#portal" data-res="Portal"><i class="icon fa fa-home"></i>'+
((__t=( resources['X100069'] ))==null?'':__t)+
'</a></li>  </ul> </div> </div> </div> </div>';
}
return __p;
};
