﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FilePond from 'filepond';

import tpl_htmlSlideList from './tpl/htmlSlideList.html';
import View_HtmlSlideListList from './htmlSlideListList';
import * as Entities_Memory_Position from '../../../entities/memoryPosition';


export default Marionette.LayoutView.extend({
    dialogRegion: '#htmlSlideList_modal',
    getTemplate: function () {
        return tpl_htmlSlideList({ model: this.model.toJSON() });
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'htmlSlideList:reOrder', function () {
            self.reOrder();;
        });
    },
    regions: {
        htmlSlideListList: "#htmlSlideListList"//,
        //htmlSlideListPagination: "#htmlSlideListPagination",
    },
    ui: {
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    onBeforeShow: function () {
        this.htmlSlideListList.show(new View_HtmlSlideListList({ app: this.app, collection: this.collection, model : this.model, parentModel : this.options.parentModel }));
        //this.htmlSlideListPagination.show(new View_Pagination({ collection: this.collection }));
    },
    onAttach() {
        //this.calcPageSize();
        FilePond.parse(document.body);
    },
    onRender: function () {
        //this.calcPageSize();
    },
    calcPageSize: function () {
        //className: ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2';

        var maxWidth = $('.card-body').width();

        var factor = 2;

        if (maxWidth < 576) factor = 2;
        if (maxWidth >= 576) factor = 2;
        if (maxWidth > 720) factor = 2;
        if (maxWidth > 960) factor = 4;
        if (maxWidth > 1140) factor = 6;

        //this.collection.fullCollection.pageableCollection.state.pageSize = factor * 2;
        //this.collection.fullCollection.pageableCollection.state.pageSize = 12;

    },
    events: {
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02",
        "click #dialog2Button_btn03": "onBtn03"
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    nextCollectionId() {
        var ret = 0;
        this.collection.forEach(function (entry) {
            if (entry.id > ret) ret = entry.id;
        });

        return ret;
    },
    reOrder() {
        var self = this;
        var position = 0;
        var foundSelected = false;

        self.collection.forEach(function (entry) {
            if (entry.attributes.Selected == true) {
                foundSelected = true;
            }
        });
        
        $('#htmlSlideListListItem .myDiv').each(function (index, item) {
            var id = parseInt($(item).attr('data-id'));
            if (id != undefined) {
                var model = self.collection.where({ id: id })[0];
                if (!foundSelected) {
                    model.set({ Selected: true });
                    foundSelected = true;
                }
                model.set({ Position: position });
                $(item).attr('data-position', position);
                position++;
            }
        });
    },
    onBtn03: function () {
        var self = this;
        var defaultModel = this.app.entities.collections.Module.where({ id: this.options.parentModel.attributes.ModuleType })[0];
        var newModel = new Entities_Memory_Position.Model();


        newModel.attributes.id = this.nextCollectionId() + 1;
        newModel.attributes.Data = defaultModel.attributes.Data.Collection[0].Data;
        newModel.attributes.Selected = false;
        newModel.attributes.Position = this.collection.length;
        newModel.attributes.Duration = defaultModel.attributes.Data.Collection[0].Duration;
        newModel.attributes.AnimationGroup = defaultModel.attributes.Data.Collection[0].AnimationGroup;
        newModel.attributes.Animation = defaultModel.attributes.Data.Collection[0].Animation;

        this.collection.add(newModel);
        this.reOrder();
    },
    setModel: function () {
        this.model.set({
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    },
}, Backbone.Radio.Requests);
