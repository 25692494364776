var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventLive_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customSchemaLive">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customEventLiveInformation" class="e4usInformation" style="display:none"></div> <div id="customEventLive_div_anySchema"> <h4 class="card-title">'+
((__t=( resources['X101058'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customEventLive_inputNameGroup" class="control-group customEventLive_selectEvent"> <div class="form-group"> <label for="customEventLive_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventLive_selectEvent" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </form> </div> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="padding-bottom: 0px"> <h4 class="card-title">'+
((__t=( resources['X100396'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="form-group" id=""> <div class="row"> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100397'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customEventLive_input_SchemaBigscreenShowHeader" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100398'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customEventLive_input_SchemaBigscreenShowSearchBar" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> </div> </div> <div class="control-group customEventLive_select_SchemaBigscreenRefreshInterval"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenRefreshInterval">'+
((__t=( resources['X100478'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenRefreshInterval" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100447'] ))==null?'':__t)+
'</option> <option value="1">1</option> <option value="5">5</option> <option value="10">10</option> <option value="15">15</option> <option value="20">20</option> <option value="25">25</option> <option value="30">30</option> <option value="35">35</option> <option value="40">40</option> <option value="45">45</option> <option value="50">50</option> <option value="55">55</option> <option value="60">60</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </form> </div> <div class="card-body" style="padding-top: 0px;padding-bottom:0px"> <p class="card-description">'+
((__t=( resources['X100526'] ))==null?'':__t)+
'</p> <form class="forms-sample"> <div class="control-group customEventLive_select_SchemaBigscreenQueryField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenQueryField">'+
((__t=( resources['X100400'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenQueryField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100401'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventLive_input_SchemaBigscreenQueryValue" style="margin-bottom:0px"> </div> </div> </form> </div> <div class="card-body" style="padding-top: 0px"> <p class="card-description">'+
((__t=( resources['X100527'] ))==null?'':__t)+
'</p> <form class="forms-sample"> <div class="control-group customEventLive_select_SchemaBigscreenQuerySortField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenQuerySortField">'+
((__t=( resources['X100486'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenQuerySortField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-group customEventLive_select_SchemaBigscreenQuerySortValue"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaBigscreenQuerySortValue">'+
((__t=( resources['X100487'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaBigscreenQuerySortValue" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100488'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100489'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventLive_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body" style="padding-bottom: 0px"> <h4 class="card-title">'+
((__t=( resources['X100402'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="row"> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100403'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customEventLive_input_SchemaShowHeader" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> <div class="control-label col-md-6 form-check"> <label class="form-check-label" id=""> '+
((__t=( resources['X100404'] ))==null?'':__t)+
' <input class="form-check-input" type="checkbox" id="customEventLive_input_SchemaShowSearchBar" checked="checked" style="margin-bottom:0px"> <i class="input-helper"></i> </label> </div> </div> <div class="control-group customEventLive_select_SchemaRefreshInterval"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaRefreshInterval">'+
((__t=( resources['X100478'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaRefreshInterval" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100447'] ))==null?'':__t)+
'</option> <option value="1">1</option> <option value="5">5</option> <option value="10">10</option> <option value="15">15</option> <option value="20">20</option> <option value="25">25</option> <option value="30">30</option> <option value="35">35</option> <option value="40">40</option> <option value="45">45</option> <option value="50">50</option> <option value="55">55</option> <option value="60">60</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </form> </div> <div class="card-body" style="padding-top: 0px;padding-bottom:0px"> <p class="card-description">'+
((__t=( resources['X100526'] ))==null?'':__t)+
'</p> <form class="forms-sample"> <div class="control-group customEventLive_select_SchemaQueryField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaQueryField">'+
((__t=( resources['X100406'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaQueryField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100401'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control" id="customEventLive_input_SchemaQueryValue" style="margin-bottom:0px"> </div> </div> </div> </form> </div> <div class="card-body" style="padding-top: 0px"> <p class="card-description">'+
((__t=( resources['X100527'] ))==null?'':__t)+
'</p> <form class="forms-sample"> <div class="control-group customEventLive_select_SchemaQuerySortField"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaQuerySortField">'+
((__t=( resources['X100486'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaQuerySortField" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-group customEventLive_select_SchemaQuerySortValue"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customEventLive_select_SchemaQuerySortValue">'+
((__t=( resources['X100487'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventLive_select_SchemaQuerySortValue" style="font-size:12px"> <option value="0">'+
((__t=( resources['X100488'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X100489'] ))==null?'':__t)+
'</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventLive_btnSubmit02">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customEventLive_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
