﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventListItem from './tpl/customEventListItem.html';
import language from '../../../common/language';
import View_CustomEventListItem from './customEventListItem';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    // tagName: 'li',
    // className: '',
    getTemplate: function () {
        return tpl_customEventListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customEventListItem_btnDelete': '#customEventListItem_btnDelete',
        'customEventListItem_btnEdit': '#customEventListItem_btnEdit',
        'customEventListItem_btnPreview': '#customEventListItem_btnPreview',
        'customEventListItem_aQRCode': '#customEventListItem_aQRCode'
    },
    events: {
        'click @ui.customEventListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customEventListItem_btnEdit': 'btnEditClick',
        'click @ui.customEventListItem_btnPreview': 'btnPreviewClick',
        'click @ui.customEventListItem_aQRCode': 'aQRCodeClick'
    },
    onRender: function () {
        if (this.model.attributes.QRCodeImage == null) {
            this.model.attributes.QRCodeImage = '../../../../images/defaultQRCode.jpg';
        }
        if (this.model.attributes.WebpageImage == null) {
            this.model.attributes.WebpageImage = '../../../../images/IPhone5.png';
        }

        this.model.attributes.WebpageUrl = config.webApiUrl() + '/Events/' + this.model.attributes.id + '_imageView.html?bust=' + (new Date()).getTime() + "'";
    },
    btnEditClick: function () {
        this.app.router.customEventEditItem(this.model.attributes.id);
        var url = 'customEventEditItem/' + this.model.attributes.id;
        this.app.router.navigate(url);
    },
    aQRCodeClick: function () {
        this.btnEditClick();
    },
    btnPreviewClick: function (event) {
        var self = this;
        if (this.model.attributes.Webpage > 0) {
            var friendlyUrl = self.model.attributes.id;
            if (self.model.attributes.FriendlyUrl && self.model.attributes.FriendlyUrl.length > 0) {
                friendlyUrl = self.model.attributes.FriendlyUrl;
            }

            var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

            var dialogPreviewWebpageModel = new Entities_Empty.Model();
            dialogPreviewWebpageModel.attributes.Title = resources.X100276;
            dialogPreviewWebpageModel.attributes.Iframe = webpageEvent;
            dialogPreviewWebpageModel.attributes.Button = resources.X100279;

            var dialogPreviewWebpage = new View_DialogPreviewWebpage({ model: dialogPreviewWebpageModel, parent: this });
            var callback_dialogPreviewWebpage = function (options) {
                self.stopListening(dialogPreviewWebpage, "dialog:okclicked", callback);
            }
            self.listenTo(dialogPreviewWebpage, "dialog:okclicked", callback_dialogPreviewWebpage);
            self.app.dialogRegion.show(dialogPreviewWebpage);

        } else {
            var dialogBoxModelNoWebpage = new Entities_Empty.Model();
            dialogBoxModelNoWebpage.attributes.Title = resources.X100229;
            dialogBoxModelNoWebpage.attributes.Text = resources.X100481;
            dialogBoxModelNoWebpage.attributes.Button = resources.X100279;

            var dialogBoxNoWebpage = new View_DialogBox({ model: dialogBoxModelNoWebpage, parent: self });
            var callbackNoWebpage = function (options) {
                self.stopListening(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
            }
            self.listenTo(dialogBoxNoWebpage, "dialog:okclicked", callbackNoWebpage);
            self.app.dialogRegion.show(dialogBoxNoWebpage);
        }
    },
    DeleteAll: function (name, amodel, callback) {
        var self = this;

        var callback01 = function () {
            var tmpSchemaModel = self.app.entities.collections.customSchemaModel.fullCollection.where({ id: amodel.attributes.SchemaModel });

            if (tmpSchemaModel.length > 0 && tmpSchemaModel[0].attributes.Name != null) {
                tmpSchemaModel[0].destroy({
                    wait: true,
                    success: function (model, response) {
                        callback02();
                    },
                    error: function (model, response) {
                        callback02();
                    }
                });
            }
        }

        var callback02 = function () {
            var tmpFormModel = self.app.entities.collections.customFormModel.fullCollection.where({ id: amodel.attributes.FormModel });
            if (tmpFormModel.length > 0 && tmpFormModel[0].attributes.Name != null) {
                tmpFormModel[0].destroy({
                    wait: true,
                    success: function (model, response) {
                        callback03();
                    },
                    error: function (model, response) {
                        callback03();
                    }
                });

            }
        }

        var callback03 = function () {
            var tmpProductSheetModel = self.app.entities.collections.customProductSheetModel.fullCollection.where({ id: amodel.attributes.productSheetModel });
            if (tmpProductSheetModel.length > 0 && tmpProductSheetModel[0].attributes.Name != null) {
                tmpProductSheetModel[0].destroy({

                    wait: true,
                    success: function (model, response) {
                        callback04();
                    },
                    error: function (model, response) {
                        callback04();
                    }
                });

            }
            else {
                callback04();
            }
        }

        var callback04 = function () {
            var tmpQRCode = self.app.entities.collections.customQRCode.fullCollection.where({ id: amodel.attributes.QRCode });

            if (tmpQRCode[0].attributes.Name != null) {
                tmpQRCode[0].destroy({
                    wait: true,
                    success: function (model, response) {
                        callback05();
                    },
                    error: function (model, response) {
                        callback05();
                    }
                });

            }
        }

        var callback05 = function () {
            var tmpWebpage = self.app.entities.collections.customWebpage.fullCollection.where({ id: amodel.attributes.Webpage });
            if (tmpWebpage[0].attributes.Name != null) {
                tmpWebpage[0].destroy({
                    wait: true,
                    success: function (model, response) {
                        callback06();
                    },
                    error: function (model, response) {
                        callback06();
                    }
                });
            }
        }

        var callback06 = function () {
            self.app.entities.collections.customWebpageItem.fetch({
                silent: true,
                wait: true,
                success: function (model, response) {
                    callback();
                },
                error: function (model, response) {
                    callback();
                }
            });
        }


        callback01();
    },
    btnDeleteClick: function (event) {
        var self = this;

        if (!this.model.attributes.AdminEnableDelete) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            dialogBoxModel.attributes.Text = resources.X100132;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        } else {

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100070;
            dialog2ButtonModel.attributes.Text = resources.X101480;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

            var callback01 = function (options) {

                this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                var dialogWaitingModel = new Entities_Empty.Model();
                dialogWaitingModel.attributes.Title = resources.X100070;
                dialogWaitingModel.attributes.Text = resources.X100304;
                dialogWaitingModel.attributes.Button = resources.X100279;

                var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: self });
                var callbackWaiting = function (options) {
                    self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
                }
                self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

                self.app.dialogRegion.show(dialogWaiting);

                //Pause
                //var timerId = window.setInterval(function () {
                //    self.app.dialogRegion.show(dialogWaiting);
                //    clearInterval(timerId);
                //}, 1000);

                var collection = self.app.entities.collections.customEvent;

                self.model.destroy({
                    wait: true,
                    silent: true,
                    success: function (model, response) {
                        var callbackDelete = function () {
                            collection.fullCollection.sort();
                            if (collection.state.currentPage < collection.state.lastPage) {
                                collection.getPage(collection.state.currentPage);
                            }
                            else {
                                collection.getLastPage();
                            }

                            dialogWaiting.triggerMethod('dialog:close');
                        }
                        self.DeleteAll(model.attributes.Name, model, callbackDelete);

                    },
                    error: function (model, response) {
                    }
                });
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);
        }
    }
});
