var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventLive_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customSchemaSetting">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customSchemaSettingInformation" class="e4usInformation" style="display:none"></div> <div id="customSchemaSetting_div_anySchema"> <h4 class="card-title">'+
((__t=( resources['X101256'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group customSchemaSetting_inputSchemaMenuName"> <span class="help-inline" style="color:red"></span> </div> <div id="customSchemaSetting_inputNameGroup" class="control-group customEventLive_selectEvent"> <div class="form-group"> <label for="customSchemaSetting_selectEvent">'+
((__t=( resources['X101257'] ))==null?'':__t)+
'</label> <div class="form-group" id="customSchemaSetting_divSchemaMenuName"> <div class="row"> <div class="control-label col-md-6"> <label style="padding-top:12px">'+
((__t=( resources['X100102'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <input class="form-control form-control-sm" id="customSchemaSetting_inputSchemaMenuName" style="margin-bottom:0px" value="'+
((__t=( model.SchemaMenuName ))==null?'':__t)+
'"> </div> </div> </div> </div> </div> <div class="control-label col-md-12"> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customSchemaSetting_btnSubmit">'+
((__t=( resources['X100275'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customSchemaSetting_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> </div> </form> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
