var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customIPAddressEditItem_empty"> <div class="content-wrapper" data-help="customIPAddressEditItem"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200101'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200109'] ))==null?'':__t)+
'</h4> <form class="forms-sample template-demo"> <div id="customIPAddressEditItemInformation" class="e4usInformation" style="display:none"></div> <div id="customIPAddressEditItem_inputNameGroup" class="control-group customIPAddressEditItem_inputName"> <div class="form-group"> <label for="custom_customIPAddressEditItem_inputName">'+
((__t=( resources['X200101'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customIPAddressEditItem_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customIPAddressEditItem_btnSubmit">'+
((__t=( resources['X200106'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customIPAddressEditItem_btnCancel">'+
((__t=( resources['X200110'] ))==null?'':__t)+
'</button> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
