﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_rss from './tpl/digitalClock.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_rss();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.setupSetting, this);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'rightContent:setupCssStyle', function () {
            this.setupStyle();
        });
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'digitalClock': '#digitalClock',
        'clock': '#clock',
        'divStamp': '#divStamp',
        'ampm': '#ampm'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        var self = this;
        var nowLocalDate = new Date();
        this.editorClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();

        this.ui.divStamp.addClass(this.editorClass);
        this.searchElement = $('.' + this.editorClass)[0];

        this.setupSetting();
    },
    onDestroy: function () {
    },
    setupStyle: function () {
        var self = this;

        this.ui.digitalClock.find('.mydate').empty();
        var html = self.app.entities.collections.DigitalClockDateFormat.where({ Value: this.model.attributes.Data.DateFormat })[0];
        this.ui.digitalClock.find('.mydate').html(html.attributes.Html);

        var days = [];
        var months = [];
        var ampm = [];

        self.app.entities.collections.DigitalClockHelper.models[0].attributes.Days.forEach(function (entry) {
            days.push(entry.Name);
        });
        self.app.entities.collections.DigitalClockHelper.models[0].attributes.Months.forEach(function (entry) {
            months.push(entry.Name);
        });
        self.app.entities.collections.DigitalClockHelper.models[0].attributes.Ampms.forEach(function (entry) {
            ampm.push(entry.Name);
        });

        switch (this.model.attributes.Data.Enable24Hours) {
            case 0: break;
            case 1: ampm[0] = ''; ampm[1] = ''; break;
        }

        if (W3FCT.lang.Lang != undefined) {
            W3FCT.lang.Lang.days = days;
            W3FCT.lang.Lang.months = months;
            W3FCT.lang.Lang.ampm = ampm;
        }
        else {

            W3FCT.lang.Lang = {
                days: days,
                months: months,
                ampm: ampm
            };
        }

        this.ui.digitalClock[0].W3FClockTimer({
            device: {
                //offset: -10800
            },
            lang: 'Lang'
        });

        //switch (this.model.attributes.Data.Enable24Hours) {
        //    case 0: $('#ampm').show(); break; //this.ui.ampm.show(); break;
        //    case 1: $('#ampm').css('display', 'none'); break;// this.ui.ampm.css('display', 'none'); break;
        //}

        switch (this.model.attributes.Data.EnableSeconds) {
            case 0: this.ui.digitalClock.find('.dititalClock_second').css('display', 'none'); break;
            case 1: this.ui.digitalClock.find('.dititalClock_second').show(); break;
        }

        switch (this.model.attributes.Data.EnableClock) {
            case 0: this.ui.digitalClock.find('.dititalClock_time').css('display', 'none');
                this.ui.digitalClock.find('.dititalClock_date.dcspace').css('display', 'none');
                break;// $('#ampm').css('display', 'none'); break;
            case 1: this.ui.digitalClock.find('.dititalClock_time').show();
                this.ui.digitalClock.find('.dititalClock_date.dcspace').show();
                break;


        }

        

        this.ui.digitalClock.find('.mytime').css('margin-top', this.model.attributes.Data.Margin1_Top + 'px');
        this.ui.digitalClock.find('.mytime').css('margin-bottom', this.model.attributes.Data.Margin1_Bottom + 'px');
        this.ui.digitalClock.find('.mytime').css('margin-left', this.model.attributes.Data.Margin1_Left + 'px');
        this.ui.digitalClock.find('.mytime').css('margin-right', this.model.attributes.Data.Margin1_Right + 'px');

        var font1_textAlign = this.app.entities.collections.TextAlign.where({
            Value: this.model.attributes.Data.Font1_TextAlign
        });

        font1_textAlign[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.digitalClock.find('.mytime').css(entry.Css, entry.Value);
        });

        //$('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1);

        //switch (this.model.attributes.Data.Font1_FontType) {
        //    case '0': $('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1_Select); break;
        //    case '1': $('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1); break;
        //}

        this.ui.digitalClock.find('.dititalClock_time').css('font-family', this.model.attributes.Data.Font1);

        this.ui.digitalClock.find('.dititalClock_time').css('font-size', this.model.attributes.Data.Font1_Size);

        //switch (this.model.attributes.Data.Font1_ColorType) {
        //    case '0': $('.dititalClock_time').css('color', this.model.attributes.Data.Font1_ColorSelect); break;
        //    case '1': $('.dititalClock_time').css('color', this.model.attributes.Data.Font1_Color); break;
        //}

        this.ui.digitalClock.find('.dititalClock_time').css('color', this.model.attributes.Data.Font1_Color);


        var font1_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font1_Style
        });

        font1_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.digitalClock.find('.dititalClock_time').css(entry.Css, entry.Value);
        });

        this.ui.digitalClock.find('.mydate').css('margin-top', this.model.attributes.Data.Margin2_Top + 'px');
        this.ui.digitalClock.find('.mydate').css('margin-bottom', this.model.attributes.Data.Margin2_Bottom + 'px');
        this.ui.digitalClock.find('.mydate').css('margin-left', this.model.attributes.Data.Margin2_Left + 'px');
        this.ui.digitalClock.find('.mydate').css('margin-right', this.model.attributes.Data.Margin2_Right + 'px');

        var font2_textAlign = this.app.entities.collections.TextAlign.where({
            Value: this.model.attributes.Data.Font2_TextAlign
        });

        font2_textAlign[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.digitalClock.find('.mydate').css(entry.Css, entry.Value);
        });

        //$('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2);

        switch (this.model.attributes.Data.EnableDate) {
            case 0: this.ui.digitalClock.find('.mydate').css('display', 'none'); break;
            case '1': this.ui.digitalClock.find('.mydate').show(); break;
        }

        //switch (this.model.attributes.Data.Font2_FontType) {
        //    case '0': $('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2_Select); break;
        //    case '1': $('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2); break;
        //}

        this.ui.digitalClock.find('.dititalClock_date').css('font-family', this.model.attributes.Data.Font2);

        this.ui.digitalClock.find('.dititalClock_date').css('font-size', this.model.attributes.Data.Font2_Size);

        //switch (this.model.attributes.Data.Font2_ColorType) {
        //    case '0': $('.dititalClock_date').css('color', this.model.attributes.Data.Font2_ColorSelect); break;
        //    case '1': $('.dititalClock_date').css('color', this.model.attributes.Data.Font2_Color); break;
        //}



        this.ui.digitalClock.find('.dititalClock_date').css('color', this.model.attributes.Data.Font2_Color);


        var font2_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font2_Style
        });

        font2_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.digitalClock.find('.dititalClock_date').css(entry.Css, entry.Value);
        });

        this.ui.digitalClock.find('.mydate').css('background-color', this.model.attributes.Data.Background1_Color);
        this.ui.digitalClock.find('.mytime').css('background-color', this.model.attributes.Data.Background1_Color);

        //$('.dcspace').show();
    }
}, Backbone.Radio.Requests);
