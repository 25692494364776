var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div>  <section class="content-block default-bg"> <div class="container"> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div class="dot-opacity-loader" style="text-align:center"> <span></span> <span></span> <span></span> </div> <h4 class="text-center" data-backdrop="static">'+
((__t=( model.Spin ))==null?'':__t)+
'</h4> </div> </div> </div> </section> </div>';
}
return __p;
};
