var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div> <div id="htmlSlideListItem_div_inner" class="d-flex" style="margin:3px;border-width: 3px; border-color: black;border-style: none;max-width:125px;position:relative"> <div id="htmlSlideListItem_div_innerinner" class="d-flex" style="border-width: 3px; border-color: rgb(108, 114, 147);border-style:solid;max-width:112px;max-height:140px;position:relative"> <div style="position:absolute; top:4px; right:6px"> <i id="htmlSlideListListItem_iHtmlSlide" style="margin-top:3px; color: #6c7293 !important" class="fa fa-lg fa-lg fa-cog"></i> <i id="htmlSlideListListItem_iDelete" style="color: #6c7293 !important" class="far fa-lg fa-lg fa-times-circle py-1 text-info mdi-24px"></i> </div>  <div style="transform: scale(0.11);margin-top:26px; transform-origin: 0% 0%; min-height:100vh;min-width:100vh"> <div id="htmlSlideListItem_div_htmlSlide" class="infopage" style="height:981px;width:962px"> <p style="height:0px" class="infopage">sfassdfasfdas</p> '+
((__t=( model.Data))==null?'':__t)+
' </div> </div> </div> </div> </div>';
}
return __p;
};
