﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_owlCarouselListItem from './tpl/owlCarouselListItem.html';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        return tpl_owlCarouselListItem({ model: this.model.toJSON() });
    },

    attributes: function () {
        /*return {
            class: 'col-lg-6 grid-margin card',
        }*/
    },
    ui: {
        'owlCarouselListItem_webpageEvent': '#owlCarouselListItem_webpageEvent',
        'owlCarouselListItem_webpageInfo': '#owlCarouselListItem_webpageInfo',
        'owlCarouselListItem_webpagePoster': '#owlCarouselListItem_webpagePoster'
    },
    initialize: function () {
        this.app = this.options.app;

        /*if (this.model.attributes.QRCodeImageUrl == null) {
            this.model.attributes.QRCodeImageUrl = '../../../../images/defaultQRCode.jpg';
        }
        if (this.model.attributes.WebpageImageUrl == null) {
            this.model.attributes.WebpageImageUrl = '../../../../images/imagePrintUnderConstruction6.png';
        }*/
    },
    onRender: function () {
        //this.setupUrl();
    },
    setupUrl: function () {
        var friendlyUrl = this.model.attributes.Event;
        /*if (this.model.attributes.FriendlyUrl && this.model.attributes.FriendlyUrl.length > 0) {
            friendlyUrl = this.model.attributes.FriendlyUrl;
        }*/

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;
        var webpageInfo = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100454.toLowerCase();
        var webpagePoster = config.webApiUrl() + '/events/' + friendlyUrl + '#' + resources.X100141.toLowerCase();

        this.ui.owlCarouselListItem_webpageEvent.attr('href', webpageEvent);
        this.ui.owlCarouselListItem_webpageInfo.attr('href', webpageInfo);
        this.ui.owlCarouselListItem_webpagePoster.attr('href', webpagePoster);
    }
});