﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageList from './tpl/customWebpageList.html';
import View_CustomWebpageListItem from './customWebpageListItem';

export default Marionette.CollectionView.extend({
    tagName: 'customWebpageList',
    childViewContainer: '#customWebpageList',
    childView: View_CustomWebpageListItem,
    getTemplate: function () {
        return tpl_customWebpageList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});
