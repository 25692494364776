﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customProductSheetModelEditItem from './tpl/customProductSheetModelEditItem.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import language from '../../../common/language';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Render from '../render/render';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customProductSheetModelEditItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        this.setHasChanged(false);
        this.oldModel = this.model.clone();
    },
    help: function () {
        Help.help(this, true, 'customProductSheetModelEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged : function () {
        var changes = false;

        this.setModel(this.model);

        if ((this.model.attributes.Name != this.oldModel.attributes.Name) ||
           (this.model.attributes.FieldUsed != this.oldModel.attributes.FieldUsed) ||
           (this.model.attributes.PositionWebpage  != this.oldModel.attributes.PositionWebpage) ||
           (this.model.attributes.PositionProductName  != this.oldModel.attributes.PositionProductName) ||
           (this.model.attributes.PositionImage  != this.oldModel.attributes.PositionImage) ||
           (this.model.attributes.PositionFeature  != this.oldModel.attributes.PositionFeature) ||
           (this.model.attributes.PositionDescription  != this.oldModel.attributes.PositionDescription) ||
           (this.model.attributes.PositionSchema  != this.oldModel.attributes.PositionSchema) ||
           (this.model.attributes.FontSizeProductName  != this.oldModel.attributes.FontSizeProductName) ||
           (this.model.attributes.FontSizeImage  != this.oldModel.attributes.FontSizeImage) ||
           (this.model.attributes.FontSizeFeature  != this.oldModel.attributes.FontSizeFeature) ||
           (this.model.attributes.FontSizeDescription  != this.oldModel.attributes.FontSizeDescription) ||
           (this.model.attributes.FontSizeSchema  != this.oldModel.attributes.FontSizeSchema) ||
           (this.model.attributes.FieldPosition != this.oldModel.attributes.FieldPosition) ||
           (this.model.attributes.Field01 != this.oldModel.attributes.Field01) ||
           (this.model.attributes.FieldType01 != this.oldModel.attributes.FieldType01) ||
           (this.model.attributes.FieldValue01 != this.oldModel.attributes.FieldValue01) ||
           (this.model.attributes.FieldSubject01 != this.oldModel.attributes.FieldSubject01) ||
           (this.model.attributes.Field02 != this.oldModel.attributes.Field02) ||
           (this.model.attributes.FieldType02 != this.oldModel.attributes.FieldType02) ||
           (this.model.attributes.FieldValue02 != this.oldModel.attributes.FieldValue02) ||
           (this.model.attributes.FieldSubject02 != this.oldModel.attributes.FieldSubject02) ||
           (this.model.attributes.Field03 != this.oldModel.attributes.Field03) ||
           (this.model.attributes.FieldType03 != this.oldModel.attributes.FieldType03) ||
           (this.model.attributes.FieldValue03 != this.oldModel.attributes.FieldValue03) ||
           (this.model.attributes.FieldSubject03 != this.oldModel.attributes.FieldSubject03) ||
           (this.model.attributes.Field04 != this.oldModel.attributes.Field04) ||
           (this.model.attributes.FieldType04 != this.oldModel.attributes.FieldType04) ||
           (this.model.attributes.FieldValue04 != this.oldModel.attributes.FieldValue04) ||
           (this.model.attributes.FieldSubject04 != this.oldModel.attributes.FieldSubject04) ||
           (this.model.attributes.Field05 != this.oldModel.attributes.Field05) ||
           (this.model.attributes.FieldType05 != this.oldModel.attributes.FieldType05) ||
           (this.model.attributes.FieldValue05 != this.oldModel.attributes.FieldValue05) ||
           (this.model.attributes.FieldSubject05 != this.oldModel.attributes.FieldSubject05) ||
           (this.model.attributes.Field06 != this.oldModel.attributes.Field06) ||
           (this.model.attributes.FieldType06 != this.oldModel.attributes.FieldType06) ||
           (this.model.attributes.FieldValue06 != this.oldModel.attributes.FieldValue06) ||
           (this.model.attributes.FieldSubject06 != this.oldModel.attributes.FieldSubject06) ||
           (this.model.attributes.Field07 != this.oldModel.attributes.Field07) ||
           (this.model.attributes.FieldType07 != this.oldModel.attributes.FieldType07) ||
           (this.model.attributes.FieldValue07 != this.oldModel.attributes.FieldValue07) ||
           (this.model.attributes.FieldSubject07 != this.oldModel.attributes.FieldSubject07) ||
           (this.model.attributes.Field08 != this.oldModel.attributes.Field08) ||
           (this.model.attributes.FieldType08 != this.oldModel.attributes.FieldType08) ||
           (this.model.attributes.FieldValue08 != this.oldModel.attributes.FieldValue08) ||
           (this.model.attributes.FieldSubject08 != this.oldModel.attributes.FieldSubject08) ||
           (this.model.attributes.Field09 != this.oldModel.attributes.Field09) ||
           (this.model.attributes.FieldType09 != this.oldModel.attributes.FieldType09) ||
           (this.model.attributes.FieldValue09 != this.oldModel.attributes.FieldValue09) ||
           (this.model.attributes.FieldSubject09 != this.oldModel.attributes.FieldSubject09) ||
           (this.model.attributes.Field10 != this.oldModel.attributes.Field10) ||
           (this.model.attributes.FieldType10 != this.oldModel.attributes.FieldType10) ||
           (this.model.attributes.FieldValue10 != this.oldModel.attributes.FieldValue10) ||
           (this.model.attributes.FieldSubject10 != this.oldModel.attributes.FieldSubject10) ||
           (this.model.attributes.Field11 != this.oldModel.attributes.Field11) ||
           (this.model.attributes.FieldType11 != this.oldModel.attributes.FieldType11) ||
           (this.model.attributes.FieldValue11 != this.oldModel.attributes.FieldValue11) ||
           (this.model.attributes.FieldSubject11 != this.oldModel.attributes.FieldSubject11) ||
           (this.model.attributes.Field12 != this.oldModel.attributes.Field12) ||
           (this.model.attributes.FieldType12 != this.oldModel.attributes.FieldType12) ||
           (this.model.attributes.FieldValue12 != this.oldModel.attributes.FieldValue12) ||
           (this.model.attributes.FieldSubject12 != this.oldModel.attributes.FieldSubject12) ||
           (this.model.attributes.Field13 != this.oldModel.attributes.Field13) ||
           (this.model.attributes.FieldType13 != this.oldModel.attributes.FieldType13) ||
           (this.model.attributes.FieldValue13 != this.oldModel.attributes.FieldValue13) ||
           (this.model.attributes.FieldSubject13 != this.oldModel.attributes.FieldSubject13) ||
           (this.model.attributes.Field14 != this.oldModel.attributes.Field14) ||
           (this.model.attributes.FieldType14 != this.oldModel.attributes.FieldType14) ||
           (this.model.attributes.FieldValue14 != this.oldModel.attributes.FieldValue14) ||
           (this.model.attributes.FieldSubject14 != this.oldModel.attributes.FieldSubject14) ||
           (this.model.attributes.Field15 != this.oldModel.attributes.Field15) ||
           (this.model.attributes.FieldType15 != this.oldModel.attributes.FieldType15) ||
           (this.model.attributes.FieldValue15 != this.oldModel.attributes.FieldValue15) ||
           (this.model.attributes.FieldSubject15 != this.oldModel.attributes.FieldSubject15) ||
           (this.model.attributes.Field16 != this.oldModel.attributes.Field16) ||
           (this.model.attributes.FieldType16 != this.oldModel.attributes.FieldType16) ||
           (this.model.attributes.FieldValue16 != this.oldModel.attributes.FieldValue16) ||
           (this.model.attributes.FieldSubject16 != this.oldModel.attributes.FieldSubject16) ||
           (this.model.attributes.Field17 != this.oldModel.attributes.Field17) ||
           (this.model.attributes.FieldType17 != this.oldModel.attributes.FieldType17) ||
           (this.model.attributes.FieldValue17 != this.oldModel.attributes.FieldValue17) ||
           (this.model.attributes.FieldSubject17 != this.oldModel.attributes.FieldSubject17) ||
           (this.model.attributes.Field18 != this.oldModel.attributes.Field18) ||
           (this.model.attributes.FieldType18 != this.oldModel.attributes.FieldType18) ||
           (this.model.attributes.FieldValue18 != this.oldModel.attributes.FieldValue18) ||
           (this.model.attributes.FieldSubject18 != this.oldModel.attributes.FieldSubject18) ||
           (this.model.attributes.Field19 != this.oldModel.attributes.Field19) ||
           (this.model.attributes.FieldType19 != this.oldModel.attributes.FieldType19) ||
           (this.model.attributes.FieldValue19 != this.oldModel.attributes.FieldValue19) ||
           (this.model.attributes.FieldSubject19 != this.oldModel.attributes.FieldSubject19) ||
           (this.model.attributes.Field20 != this.oldModel.attributes.Field20) ||
           (this.model.attributes.FieldType20 != this.oldModel.attributes.FieldType20) ||
           (this.model.attributes.FieldValue20 != this.oldModel.attributes.FieldValue20) ||
           (this.model.attributes.FieldSubject20 != this.oldModel.attributes.FieldSubject20) ||
           (this.model.attributes.Field21 != this.oldModel.attributes.Field21) ||
           (this.model.attributes.FieldType21 != this.oldModel.attributes.FieldType21) ||
           (this.model.attributes.FieldValue21 != this.oldModel.attributes.FieldValue21) ||
           (this.model.attributes.FieldSubject21 != this.oldModel.attributes.FieldSubject21) ||
           (this.model.attributes.Field22 != this.oldModel.attributes.Field22) ||
           (this.model.attributes.FieldType22 != this.oldModel.attributes.FieldType22) ||
           (this.model.attributes.FieldValue22 != this.oldModel.attributes.FieldValue22) ||
           (this.model.attributes.FieldSubject22 != this.oldModel.attributes.FieldSubject22) ||
           (this.model.attributes.Field23 != this.oldModel.attributes.Field23) ||
           (this.model.attributes.FieldType23 != this.oldModel.attributes.FieldType23) ||
           (this.model.attributes.FieldValue23 != this.oldModel.attributes.FieldValue23) ||
           (this.model.attributes.FieldSubject23 != this.oldModel.attributes.FieldSubject23) ||
           (this.model.attributes.Field24 != this.oldModel.attributes.Field24) ||
           (this.model.attributes.FieldType24 != this.oldModel.attributes.FieldType24) ||
           (this.model.attributes.FieldValue24 != this.oldModel.attributes.FieldValue24) ||
           (this.model.attributes.FieldSubject24 != this.oldModel.attributes.FieldSubject24) ||
           (this.model.attributes.Field25 != this.oldModel.attributes.Field25) ||
           (this.model.attributes.FieldType25 != this.oldModel.attributes.FieldType25) ||
           (this.model.attributes.FieldValue25 != this.oldModel.attributes.FieldValue25) ||
           (this.model.attributes.FieldSubject25 != this.oldModel.attributes.FieldSubject25) ||
           (this.model.attributes.Field26 != this.oldModel.attributes.Field26) ||
           (this.model.attributes.FieldType26 != this.oldModel.attributes.FieldType26) ||
           (this.model.attributes.FieldValue26 != this.oldModel.attributes.FieldValue26) ||
           (this.model.attributes.FieldSubject26 != this.oldModel.attributes.FieldSubject26) ||
           (this.model.attributes.Field27 != this.oldModel.attributes.Field27) ||
           (this.model.attributes.FieldType27 != this.oldModel.attributes.FieldType27) ||
           (this.model.attributes.FieldValue27 != this.oldModel.attributes.FieldValue27) ||
           (this.model.attributes.FieldSubject27 != this.oldModel.attributes.FieldSubject27) ||
           (this.model.attributes.Field28 != this.oldModel.attributes.Field28) ||
           (this.model.attributes.FieldType28 != this.oldModel.attributes.FieldType28) ||
           (this.model.attributes.FieldValue28 != this.oldModel.attributes.FieldValue28) ||
           (this.model.attributes.FieldSubject28 != this.oldModel.attributes.FieldSubject28) ||
           (this.model.attributes.Field29 != this.oldModel.attributes.Field29) ||
           (this.model.attributes.FieldType29 != this.oldModel.attributes.FieldType29) ||
           (this.model.attributes.FieldValue29 != this.oldModel.attributes.FieldValue29) ||
           (this.model.attributes.FieldSubject29 != this.oldModel.attributes.FieldSubject29) ||
           (this.model.attributes.Field30 != this.oldModel.attributes.Field30) ||
           (this.model.attributes.FieldType30 != this.oldModel.attributes.FieldType30) ||
           (this.model.attributes.FieldValue30 != this.oldModel.attributes.FieldValue30) ||
           (this.model.attributes.FieldSubject30 != this.oldModel.attributes.FieldSubject30)){
            changes = true;
        }

        this.setHasChanged(changes);
    },
    ui: {
        'customProductSheetModelEditItem_btnSubmit': '#customProductSheetModelEditItem_btnSubmit',
        'customProductSheetModelEditItem_btnReset': '#customProductSheetModelEditItem_btnReset',
        'customProductSheetModelEditItem_select_FieldUsed': '#customProductSheetModelEditItem_select_FieldUsed',
        'customProductSheetModelEditItem_select_FieldPosition': '#customProductSheetModelEditItem_select_FieldPosition',
        'customProductSheetModelEditItem_inputName': '#customProductSheetModelEditItem_inputName',
        'customProductSheetModelEditItem_select_PositionWebpage' :'#customProductSheetModelEditItem_select_PositionWebpage',
        'customProductSheetModelEditItem_select_PositionProductName' :'#customProductSheetModelEditItem_select_PositionProductName',
        'customProductSheetModelEditItem_select_PositionImage' :'#customProductSheetModelEditItem_select_PositionImage',
        'customProductSheetModelEditItem_select_PositionFeature' :'#customProductSheetModelEditItem_select_PositionFeature',
        'customProductSheetModelEditItem_select_PositionDescription' :'#customProductSheetModelEditItem_select_PositionDescription',
        'customProductSheetModelEditItem_select_PositionSchema' :'#customProductSheetModelEditItem_select_PositionSchema',
        'customProductSheetModelEditItem_select_FontSizeProductName' :'#customProductSheetModelEditItem_select_FontSizeProductName',
        'customProductSheetModelEditItem_select_FontSizeImage' :'#customProductSheetModelEditItem_select_FontSizeImage',
        'customProductSheetModelEditItem_select_FontSizeFeature' :'#customProductSheetModelEditItem_select_FontSizeFeature',
        'customProductSheetModelEditItem_select_FontSizeDescription' :'#customProductSheetModelEditItem_select_FontSizeDescription',
        'customProductSheetModelEditItem_select_FontSizeSchema' :'#customProductSheetModelEditItem_select_FontSizeSchema',
        'customProductSheetModelEditItem_input_Field01': '#customProductSheetModelEditItem_input_Field01',
        'customProductSheetModelEditItem_input_Field02': '#customProductSheetModelEditItem_input_Field02',
        'customProductSheetModelEditItem_input_Field03': '#customProductSheetModelEditItem_input_Field03',
        'customProductSheetModelEditItem_input_Field04': '#customProductSheetModelEditItem_input_Field04',
        'customProductSheetModelEditItem_input_Field05': '#customProductSheetModelEditItem_input_Field05',
        'customProductSheetModelEditItem_input_Field06': '#customProductSheetModelEditItem_input_Field06',
        'customProductSheetModelEditItem_input_Field07': '#customProductSheetModelEditItem_input_Field07',
        'customProductSheetModelEditItem_input_Field08': '#customProductSheetModelEditItem_input_Field08',
        'customProductSheetModelEditItem_input_Field09': '#customProductSheetModelEditItem_input_Field09',
        'customProductSheetModelEditItem_input_Field10': '#customProductSheetModelEditItem_input_Field10',
        'customProductSheetModelEditItem_input_Field11': '#customProductSheetModelEditItem_input_Field11',
        'customProductSheetModelEditItem_input_Field12': '#customProductSheetModelEditItem_input_Field12',
        'customProductSheetModelEditItem_input_Field13': '#customProductSheetModelEditItem_input_Field13',
        'customProductSheetModelEditItem_input_Field14': '#customProductSheetModelEditItem_input_Field14',
        'customProductSheetModelEditItem_input_Field15': '#customProductSheetModelEditItem_input_Field15',
        'customProductSheetModelEditItem_input_Field16': '#customProductSheetModelEditItem_input_Field16',
        'customProductSheetModelEditItem_input_Field17': '#customProductSheetModelEditItem_input_Field17',
        'customProductSheetModelEditItem_input_Field18': '#customProductSheetModelEditItem_input_Field18',
        'customProductSheetModelEditItem_input_Field19': '#customProductSheetModelEditItem_input_Field19',
        'customProductSheetModelEditItem_input_Field20': '#customProductSheetModelEditItem_input_Field20',
        'customProductSheetModelEditItem_input_Field21': '#customProductSheetModelEditItem_input_Field21',
        'customProductSheetModelEditItem_input_Field22': '#customProductSheetModelEditItem_input_Field22',
        'customProductSheetModelEditItem_input_Field23': '#customProductSheetModelEditItem_input_Field23',
        'customProductSheetModelEditItem_input_Field24': '#customProductSheetModelEditItem_input_Field24',
        'customProductSheetModelEditItem_input_Field25': '#customProductSheetModelEditItem_input_Field25',
        'customProductSheetModelEditItem_input_Field26': '#customProductSheetModelEditItem_input_Field26',
        'customProductSheetModelEditItem_input_Field27': '#customProductSheetModelEditItem_input_Field27',
        'customProductSheetModelEditItem_input_Field28': '#customProductSheetModelEditItem_input_Field28',
        'customProductSheetModelEditItem_input_Field29': '#customProductSheetModelEditItem_input_Field29',
        'customProductSheetModelEditItem_input_Field30': '#customProductSheetModelEditItem_input_Field30',
        'customProductSheetModelEditItem_select_FieldType01' : '#customProductSheetModelEditItem_select_FieldType01',
        'customProductSheetModelEditItem_select_FieldType02' : '#customProductSheetModelEditItem_select_FieldType02',
        'customProductSheetModelEditItem_select_FieldType03' : '#customProductSheetModelEditItem_select_FieldType03',
        'customProductSheetModelEditItem_select_FieldType04' : '#customProductSheetModelEditItem_select_FieldType04',
        'customProductSheetModelEditItem_select_FieldType05' : '#customProductSheetModelEditItem_select_FieldType05',
        'customProductSheetModelEditItem_select_FieldType06' : '#customProductSheetModelEditItem_select_FieldType06',
        'customProductSheetModelEditItem_select_FieldType07' : '#customProductSheetModelEditItem_select_FieldType07',
        'customProductSheetModelEditItem_select_FieldType08' : '#customProductSheetModelEditItem_select_FieldType08',
        'customProductSheetModelEditItem_select_FieldType09' : '#customProductSheetModelEditItem_select_FieldType09',
        'customProductSheetModelEditItem_select_FieldType10' : '#customProductSheetModelEditItem_select_FieldType10',
        'customProductSheetModelEditItem_select_FieldType11' : '#customProductSheetModelEditItem_select_FieldType11',
        'customProductSheetModelEditItem_select_FieldType12' : '#customProductSheetModelEditItem_select_FieldType12',
        'customProductSheetModelEditItem_select_FieldType13' : '#customProductSheetModelEditItem_select_FieldType13',
        'customProductSheetModelEditItem_select_FieldType14' : '#customProductSheetModelEditItem_select_FieldType14',
        'customProductSheetModelEditItem_select_FieldType15' : '#customProductSheetModelEditItem_select_FieldType15',
        'customProductSheetModelEditItem_select_FieldType16' : '#customProductSheetModelEditItem_select_FieldType16',
        'customProductSheetModelEditItem_select_FieldType17' : '#customProductSheetModelEditItem_select_FieldType17',
        'customProductSheetModelEditItem_select_FieldType18' : '#customProductSheetModelEditItem_select_FieldType18',
        'customProductSheetModelEditItem_select_FieldType19' : '#customProductSheetModelEditItem_select_FieldType19',
        'customProductSheetModelEditItem_select_FieldType20' : '#customProductSheetModelEditItem_select_FieldType20',
        'customProductSheetModelEditItem_select_FieldType21' : '#customProductSheetModelEditItem_select_FieldType21',
        'customProductSheetModelEditItem_select_FieldType22' : '#customProductSheetModelEditItem_select_FieldType22',
        'customProductSheetModelEditItem_select_FieldType23' : '#customProductSheetModelEditItem_select_FieldType23',
        'customProductSheetModelEditItem_select_FieldType24' : '#customProductSheetModelEditItem_select_FieldType24',
        'customProductSheetModelEditItem_select_FieldType25' : '#customProductSheetModelEditItem_select_FieldType25',
        'customProductSheetModelEditItem_select_FieldType26' : '#customProductSheetModelEditItem_select_FieldType26',
        'customProductSheetModelEditItem_select_FieldType27' : '#customProductSheetModelEditItem_select_FieldType27',
        'customProductSheetModelEditItem_select_FieldType28' : '#customProductSheetModelEditItem_select_FieldType28',
        'customProductSheetModelEditItem_select_FieldType29' : '#customProductSheetModelEditItem_select_FieldType29',
        'customProductSheetModelEditItem_select_FieldType30' : '#customProductSheetModelEditItem_select_FieldType30',
        'customProductSheetModelEditItem_textarea_FieldValue01': '#customProductSheetModelEditItem_textarea_FieldValue01',
        'customProductSheetModelEditItem_textarea_FieldValue02': '#customProductSheetModelEditItem_textarea_FieldValue02',
        'customProductSheetModelEditItem_textarea_FieldValue03': '#customProductSheetModelEditItem_textarea_FieldValue03',
        'customProductSheetModelEditItem_textarea_FieldValue04': '#customProductSheetModelEditItem_textarea_FieldValue04',
        'customProductSheetModelEditItem_textarea_FieldValue05': '#customProductSheetModelEditItem_textarea_FieldValue05',
        'customProductSheetModelEditItem_textarea_FieldValue06': '#customProductSheetModelEditItem_textarea_FieldValue06',
        'customProductSheetModelEditItem_textarea_FieldValue07': '#customProductSheetModelEditItem_textarea_FieldValue07',
        'customProductSheetModelEditItem_textarea_FieldValue08': '#customProductSheetModelEditItem_textarea_FieldValue08',
        'customProductSheetModelEditItem_textarea_FieldValue09': '#customProductSheetModelEditItem_textarea_FieldValue09',
        'customProductSheetModelEditItem_textarea_FieldValue10': '#customProductSheetModelEditItem_textarea_FieldValue10',
        'customProductSheetModelEditItem_textarea_FieldValue11': '#customProductSheetModelEditItem_textarea_FieldValue11',
        'customProductSheetModelEditItem_textarea_FieldValue12': '#customProductSheetModelEditItem_textarea_FieldValue12',
        'customProductSheetModelEditItem_textarea_FieldValue13': '#customProductSheetModelEditItem_textarea_FieldValue13',
        'customProductSheetModelEditItem_textarea_FieldValue14': '#customProductSheetModelEditItem_textarea_FieldValue14',
        'customProductSheetModelEditItem_textarea_FieldValue15': '#customProductSheetModelEditItem_textarea_FieldValue15',
        'customProductSheetModelEditItem_textarea_FieldValue16': '#customProductSheetModelEditItem_textarea_FieldValue16',
        'customProductSheetModelEditItem_textarea_FieldValue17': '#customProductSheetModelEditItem_textarea_FieldValue17',
        'customProductSheetModelEditItem_textarea_FieldValue18': '#customProductSheetModelEditItem_textarea_FieldValue18',
        'customProductSheetModelEditItem_textarea_FieldValue19': '#customProductSheetModelEditItem_textarea_FieldValue19',
        'customProductSheetModelEditItem_textarea_FieldValue20': '#customProductSheetModelEditItem_textarea_FieldValue20',
        'customProductSheetModelEditItem_textarea_FieldValue21': '#customProductSheetModelEditItem_textarea_FieldValue21',
        'customProductSheetModelEditItem_textarea_FieldValue22': '#customProductSheetModelEditItem_textarea_FieldValue22',
        'customProductSheetModelEditItem_textarea_FieldValue23': '#customProductSheetModelEditItem_textarea_FieldValue23',
        'customProductSheetModelEditItem_textarea_FieldValue24': '#customProductSheetModelEditItem_textarea_FieldValue24',
        'customProductSheetModelEditItem_textarea_FieldValue25': '#customProductSheetModelEditItem_textarea_FieldValue25',
        'customProductSheetModelEditItem_textarea_FieldValue26': '#customProductSheetModelEditItem_textarea_FieldValue26',
        'customProductSheetModelEditItem_textarea_FieldValue27': '#customProductSheetModelEditItem_textarea_FieldValue27',
        'customProductSheetModelEditItem_textarea_FieldValue28': '#customProductSheetModelEditItem_textarea_FieldValue28',
        'customProductSheetModelEditItem_textarea_FieldValue29': '#customProductSheetModelEditItem_textarea_FieldValue29',
        'customProductSheetModelEditItem_textarea_FieldValue30': '#customProductSheetModelEditItem_textarea_FieldValue30',
        'customProductSheetModelEditItem_div_FieldValue01': '#customProductSheetModelEditItem_div_FieldValue01',
        'customProductSheetModelEditItem_div_FieldValue02': '#customProductSheetModelEditItem_div_FieldValue02',
        'customProductSheetModelEditItem_div_FieldValue03': '#customProductSheetModelEditItem_div_FieldValue03',
        'customProductSheetModelEditItem_div_FieldValue04': '#customProductSheetModelEditItem_div_FieldValue04',
        'customProductSheetModelEditItem_div_FieldValue05': '#customProductSheetModelEditItem_div_FieldValue05',
        'customProductSheetModelEditItem_div_FieldValue06': '#customProductSheetModelEditItem_div_FieldValue06',
        'customProductSheetModelEditItem_div_FieldValue07': '#customProductSheetModelEditItem_div_FieldValue07',
        'customProductSheetModelEditItem_div_FieldValue08': '#customProductSheetModelEditItem_div_FieldValue08',
        'customProductSheetModelEditItem_div_FieldValue09': '#customProductSheetModelEditItem_div_FieldValue09',
        'customProductSheetModelEditItem_div_FieldValue10': '#customProductSheetModelEditItem_div_FieldValue10',
        'customProductSheetModelEditItem_div_FieldValue11': '#customProductSheetModelEditItem_div_FieldValue11',
        'customProductSheetModelEditItem_div_FieldValue12': '#customProductSheetModelEditItem_div_FieldValue12',
        'customProductSheetModelEditItem_div_FieldValue13': '#customProductSheetModelEditItem_div_FieldValue13',
        'customProductSheetModelEditItem_div_FieldValue14': '#customProductSheetModelEditItem_div_FieldValue14',
        'customProductSheetModelEditItem_div_FieldValue15': '#customProductSheetModelEditItem_div_FieldValue15',
        'customProductSheetModelEditItem_div_FieldValue16': '#customProductSheetModelEditItem_div_FieldValue16',
        'customProductSheetModelEditItem_div_FieldValue17': '#customProductSheetModelEditItem_div_FieldValue17',
        'customProductSheetModelEditItem_div_FieldValue18': '#customProductSheetModelEditItem_div_FieldValue18',
        'customProductSheetModelEditItem_div_FieldValue19': '#customProductSheetModelEditItem_div_FieldValue19',
        'customProductSheetModelEditItem_div_FieldValue20': '#customProductSheetModelEditItem_div_FieldValue20',
        'customProductSheetModelEditItem_div_FieldValue21': '#customProductSheetModelEditItem_div_FieldValue21',
        'customProductSheetModelEditItem_div_FieldValue22': '#customProductSheetModelEditItem_div_FieldValue22',
        'customProductSheetModelEditItem_div_FieldValue23': '#customProductSheetModelEditItem_div_FieldValue23',
        'customProductSheetModelEditItem_div_FieldValue24': '#customProductSheetModelEditItem_div_FieldValue24',
        'customProductSheetModelEditItem_div_FieldValue25': '#customProductSheetModelEditItem_div_FieldValue25',
        'customProductSheetModelEditItem_div_FieldValue26': '#customProductSheetModelEditItem_div_FieldValue26',
        'customProductSheetModelEditItem_div_FieldValue27': '#customProductSheetModelEditItem_div_FieldValue27',
        'customProductSheetModelEditItem_div_FieldValue28': '#customProductSheetModelEditItem_div_FieldValue28',
        'customProductSheetModelEditItem_div_FieldValue29': '#customProductSheetModelEditItem_div_FieldValue29',
        'customProductSheetModelEditItem_div_FieldValue30': '#customProductSheetModelEditItem_div_FieldValue30',
        'customProductSheetModelEditItem_label_FieldValue01': '#customProductSheetModelEditItem_label_FieldValue01',
        'customProductSheetModelEditItem_label_FieldValue02': '#customProductSheetModelEditItem_label_FieldValue02',
        'customProductSheetModelEditItem_label_FieldValue03': '#customProductSheetModelEditItem_label_FieldValue03',
        'customProductSheetModelEditItem_label_FieldValue04': '#customProductSheetModelEditItem_label_FieldValue04',
        'customProductSheetModelEditItem_label_FieldValue05': '#customProductSheetModelEditItem_label_FieldValue05',
        'customProductSheetModelEditItem_label_FieldValue06': '#customProductSheetModelEditItem_label_FieldValue06',
        'customProductSheetModelEditItem_label_FieldValue07': '#customProductSheetModelEditItem_label_FieldValue07',
        'customProductSheetModelEditItem_label_FieldValue08': '#customProductSheetModelEditItem_label_FieldValue08',
        'customProductSheetModelEditItem_label_FieldValue09': '#customProductSheetModelEditItem_label_FieldValue09',
        'customProductSheetModelEditItem_label_FieldValue10': '#customProductSheetModelEditItem_label_FieldValue10',
        'customProductSheetModelEditItem_label_FieldValue11': '#customProductSheetModelEditItem_label_FieldValue11',
        'customProductSheetModelEditItem_label_FieldValue12': '#customProductSheetModelEditItem_label_FieldValue12',
        'customProductSheetModelEditItem_label_FieldValue13': '#customProductSheetModelEditItem_label_FieldValue13',
        'customProductSheetModelEditItem_label_FieldValue14': '#customProductSheetModelEditItem_label_FieldValue14',
        'customProductSheetModelEditItem_label_FieldValue15': '#customProductSheetModelEditItem_label_FieldValue15',
        'customProductSheetModelEditItem_label_FieldValue16': '#customProductSheetModelEditItem_label_FieldValue16',
        'customProductSheetModelEditItem_label_FieldValue17': '#customProductSheetModelEditItem_label_FieldValue17',
        'customProductSheetModelEditItem_label_FieldValue18': '#customProductSheetModelEditItem_label_FieldValue18',
        'customProductSheetModelEditItem_label_FieldValue19': '#customProductSheetModelEditItem_label_FieldValue19',
        'customProductSheetModelEditItem_label_FieldValue20': '#customProductSheetModelEditItem_label_FieldValue20',
        'customProductSheetModelEditItem_label_FieldValue21': '#customProductSheetModelEditItem_label_FieldValue21',
        'customProductSheetModelEditItem_label_FieldValue22': '#customProductSheetModelEditItem_label_FieldValue22',
        'customProductSheetModelEditItem_label_FieldValue23': '#customProductSheetModelEditItem_label_FieldValue23',
        'customProductSheetModelEditItem_label_FieldValue24': '#customProductSheetModelEditItem_label_FieldValue24',
        'customProductSheetModelEditItem_label_FieldValue25': '#customProductSheetModelEditItem_label_FieldValue25',
        'customProductSheetModelEditItem_label_FieldValue26': '#customProductSheetModelEditItem_label_FieldValue26',
        'customProductSheetModelEditItem_label_FieldValue27': '#customProductSheetModelEditItem_label_FieldValue27',
        'customProductSheetModelEditItem_label_FieldValue28': '#customProductSheetModelEditItem_label_FieldValue28',
        'customProductSheetModelEditItem_label_FieldValue29': '#customProductSheetModelEditItem_label_FieldValue29',
        'customProductSheetModelEditItem_label_FieldValue30': '#customProductSheetModelEditItem_label_FieldValue30',
        'customProductSheetModelEditItem_input_FieldSubject01': '#customProductSheetModelEditItem_input_FieldSubject01',
        'customProductSheetModelEditItem_input_FieldSubject02': '#customProductSheetModelEditItem_input_FieldSubject02',
        'customProductSheetModelEditItem_input_FieldSubject03': '#customProductSheetModelEditItem_input_FieldSubject03',
        'customProductSheetModelEditItem_input_FieldSubject04': '#customProductSheetModelEditItem_input_FieldSubject04',
        'customProductSheetModelEditItem_input_FieldSubject05': '#customProductSheetModelEditItem_input_FieldSubject05',
        'customProductSheetModelEditItem_input_FieldSubject06': '#customProductSheetModelEditItem_input_FieldSubject06',
        'customProductSheetModelEditItem_input_FieldSubject07': '#customProductSheetModelEditItem_input_FieldSubject07',
        'customProductSheetModelEditItem_input_FieldSubject08': '#customProductSheetModelEditItem_input_FieldSubject08',
        'customProductSheetModelEditItem_input_FieldSubject09': '#customProductSheetModelEditItem_input_FieldSubject09',
        'customProductSheetModelEditItem_input_FieldSubject10': '#customProductSheetModelEditItem_input_FieldSubject10',
        'customProductSheetModelEditItem_input_FieldSubject11': '#customProductSheetModelEditItem_input_FieldSubject11',
        'customProductSheetModelEditItem_input_FieldSubject12': '#customProductSheetModelEditItem_input_FieldSubject12',
        'customProductSheetModelEditItem_input_FieldSubject13': '#customProductSheetModelEditItem_input_FieldSubject13',
        'customProductSheetModelEditItem_input_FieldSubject14': '#customProductSheetModelEditItem_input_FieldSubject14',
        'customProductSheetModelEditItem_input_FieldSubject15': '#customProductSheetModelEditItem_input_FieldSubject15',
        'customProductSheetModelEditItem_input_FieldSubject16': '#customProductSheetModelEditItem_input_FieldSubject16',
        'customProductSheetModelEditItem_input_FieldSubject17': '#customProductSheetModelEditItem_input_FieldSubject17',
        'customProductSheetModelEditItem_input_FieldSubject18': '#customProductSheetModelEditItem_input_FieldSubject18',
        'customProductSheetModelEditItem_input_FieldSubject19': '#customProductSheetModelEditItem_input_FieldSubject19',
        'customProductSheetModelEditItem_input_FieldSubject20': '#customProductSheetModelEditItem_input_FieldSubject20',
        'customProductSheetModelEditItem_input_FieldSubject21': '#customProductSheetModelEditItem_input_FieldSubject21',
        'customProductSheetModelEditItem_input_FieldSubject22': '#customProductSheetModelEditItem_input_FieldSubject22',
        'customProductSheetModelEditItem_input_FieldSubject23': '#customProductSheetModelEditItem_input_FieldSubject23',
        'customProductSheetModelEditItem_input_FieldSubject24': '#customProductSheetModelEditItem_input_FieldSubject24',
        'customProductSheetModelEditItem_input_FieldSubject25': '#customProductSheetModelEditItem_input_FieldSubject25',
        'customProductSheetModelEditItem_input_FieldSubject26': '#customProductSheetModelEditItem_input_FieldSubject26',
        'customProductSheetModelEditItem_input_FieldSubject27': '#customProductSheetModelEditItem_input_FieldSubject27',
        'customProductSheetModelEditItem_input_FieldSubject28': '#customProductSheetModelEditItem_input_FieldSubject28',
        'customProductSheetModelEditItem_input_FieldSubject29': '#customProductSheetModelEditItem_input_FieldSubject29',
        'customProductSheetModelEditItem_input_FieldSubject30': '#customProductSheetModelEditItem_input_FieldSubject30',
        'help': '#help'
    },
    events: {
        'click @ui.customProductSheetModelEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customProductSheetModelEditItem_btnReset': 'btnResetClick',
        'change @ui.customProductSheetModelEditItem_select_ConfigCustomProductSheetModel': 'selectConfigCustomProductSheetModel',
        'change @ui.customProductSheetModelEditItem_select_FieldUsed': 'selectFieldUsedChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType01' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType02' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType03' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType04' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType05' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType06' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType07' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType08' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType09' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType10' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType11' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType12' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType13' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType14' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType15' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType16' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType17' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType18' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType19' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType20' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType21' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType22' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType23' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType24' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType25' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType26' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType27' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType28' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType29' : 'selectFieldTypeChange',
        'change @ui.customProductSheetModelEditItem_select_FieldType30' : 'selectFieldTypeChange',
        'click #help': 'help'
    },
    onRender: function () {
        this.setupFieldUsed(this.ui.customProductSheetModelEditItem_select_FieldUsed);
        this.ui.customProductSheetModelEditItem_select_FieldUsed.val(this.model.attributes.FieldUsed);

        this.selectFieldUsedChange();

        this.setupPosition();
        this.setupFontSize();

        this.setupFieldType();

        return this;
    },
    customViewEvent_setActive: function () {
        var array = [];
        
        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ ProductSheetModel: this.model.attributes.id });

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();
    },
    setupFieldUsed: function (element) {
        var FieldUsedStr = "";

        for (var i = 1; i <= 30; i++) {
            if (i < 10) {
                FieldUsedStr = "0" + i.toString();
            }
            else {
                FieldUsedStr = "" + i.toString();
            }

            element.append('<option value="' + i + '">' + FieldUsedStr + '</option>');
        }
    },
    setupPosition: function (element) {
        this.setupPositionElement(this.ui.customProductSheetModelEditItem_select_PositionWebpage);
        this.ui.customProductSheetModelEditItem_select_PositionWebpage.val(this.model.attributes.PositionWebpage);
        this.setupPositionElement(this.ui.customProductSheetModelEditItem_select_PositionProductName);
        this.ui.customProductSheetModelEditItem_select_PositionProductName.val(this.model.attributes.PositionProductName);
        this.setupPositionElement(this.ui.customProductSheetModelEditItem_select_PositionImage);
        this.ui.customProductSheetModelEditItem_select_PositionImage.val(this.model.attributes.PositionImage);
        this.setupPositionElement(this.ui.customProductSheetModelEditItem_select_PositionFeature);
        this.ui.customProductSheetModelEditItem_select_PositionFeature.val(this.model.attributes.PositionFeature);
        this.setupPositionElement(this.ui.customProductSheetModelEditItem_select_PositionDescription);
        this.ui.customProductSheetModelEditItem_select_PositionDescription.val(this.model.attributes.PositionDescription);
        this.setupPositionElement(this.ui.customProductSheetModelEditItem_select_PositionSchema);
        this.ui.customProductSheetModelEditItem_select_PositionSchema.val(this.model.attributes.PositionSchema);
    },
    setupPositionElement: function (element, value) {
        var text = ''
        for (var i = 1; i <= 6; i++) {
            if (value == 0) {
                text = resources.X101136;
            } else {
                text = i;
            }
            
            element.append('<option value="' + i + '" >' + text + '</option>');
        }
    },
    setupFontSize: function (element) {
        this.setupFontSizeElement(this.ui.customProductSheetModelEditItem_select_FontSizeProductName);
        this.ui.customProductSheetModelEditItem_select_FontSizeProductName.val(this.model.attributes.FontSizeProductName);
        this.setupFontSizeElement(this.ui.customProductSheetModelEditItem_select_FontSizeImage);
        this.ui.customProductSheetModelEditItem_select_FontSizeImage.val(this.model.attributes.FontSizeImage);
        this.setupFontSizeElement(this.ui.customProductSheetModelEditItem_select_FontSizeFeature);
        this.ui.customProductSheetModelEditItem_select_FontSizeFeature.val(this.model.attributes.FontSizeFeature);
        this.setupFontSizeElement(this.ui.customProductSheetModelEditItem_select_FontSizeDescription);
        this.ui.customProductSheetModelEditItem_select_FontSizeDescription.val(this.model.attributes.FontSizeDescription);
        this.setupFontSizeElement(this.ui.customProductSheetModelEditItem_select_FontSizeSchema);
        this.ui.customProductSheetModelEditItem_select_FontSizeSchema.val(this.model.attributes.FontSizeSchema);
    },
    setupFontSizeElement: function (element) {
        for (var i = 5; i <= 100; i++) {
            element.append('<option value="' + i + '">' + i + '</option>');
        }
    },
    setupFieldType: function () {
        for (var i = 1; i <= 30; i++) {
            var element_select_fieldType;
            var element_label_fieldValue;
            var element_div_fieldValue;
            var fieldValueType;
            switch(i) {
                case 1 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType01; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue01; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue01; 
                    fieldValueType = this.model.get('FieldType01');
                    break;
                case 2 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType02; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue02; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue02; 
                    fieldValueType = this.model.get('FieldType02');
                    break;
                case 3 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType03; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue03; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue03; 
                    fieldValueType = this.model.get('FieldType03');
                    break;
                case 4 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType04; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue04; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue04; 
                    fieldValueType = this.model.get('FieldType04');
                    break;
                case 5 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType05; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue05; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue05; 
                    fieldValueType = this.model.get('FieldType05');
                    break;
                case 6 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType06; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue06; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue06; 
                    fieldValueType = this.model.get('FieldType06');
                    break;
                case 7 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType07; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue07; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue07; 
                    fieldValueType = this.model.get('FieldType07');
                    break;
                case 8 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType08; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue08; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue08; 
                    fieldValueType = this.model.get('FieldType08');
                    break;
                case 9 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType09; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue09; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue09; 
                    fieldValueType = this.model.get('FieldType09');
                    break;
                case 10 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType10; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue10; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue10; 
                    fieldValueType = this.model.get('FieldType10');
                    break;
                case 11 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType11; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue11; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue11; 
                    fieldValueType = this.model.get('FieldType11');
                    break;
                case 12 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType12; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue12; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue12; 
                    fieldValueType = this.model.get('FieldType12');
                    break;
                case 13 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType13; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue13; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue13; 
                    fieldValueType = this.model.get('FieldType13');
                    break;
                case 14 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType14; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue14; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue14; 
                    fieldValueType = this.model.get('FieldType14');
                    break;
                case 15 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType15; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue15; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue15; 
                    fieldValueType = this.model.get('FieldType15');
                    break;
                case 16 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType16; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue16; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue16; 
                    fieldValueType = this.model.get('FieldType16');
                    break;
                case 17 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType17; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue17; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue17; 
                    fieldValueType = this.model.get('FieldType17');
                    break;
                case 18 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType18; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue18; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue18; 
                    fieldValueType = this.model.get('FieldType18');
                    break;
                case 19 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType19; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue19; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue19; 
                    fieldValueType = this.model.get('FieldType19');
                    break;
                case 20 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType20; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue20; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue20; 
                    fieldValueType = this.model.get('FieldType20');
                    break;
                case 21 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType21; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue21; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue21; 
                    fieldValueType = this.model.get('FieldType21');
                    break;
                case 22 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType22; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue22; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue22; 
                    fieldValueType = this.model.get('FieldType22');
                    break;
                case 23 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType23; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue23; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue23; 
                    fieldValueType = this.model.get('FieldType23');
                    break;
                case 24 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType24; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue24; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue24; 
                    fieldValueType = this.model.get('FieldType24');
                    break;
                case 25 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType25; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue25; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue25; 
                    fieldValueType = this.model.get('FieldType25');
                    break;
                case 26 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType26; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue26; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue26; 
                    fieldValueType = this.model.get('FieldType26');
                    break;
                case 27 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType27; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue27; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue27; 
                    fieldValueType = this.model.get('FieldType27');
                    break;
                case 28 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType28; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue28; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue28; 
                    fieldValueType = this.model.get('FieldType28');
                    break;
                case 29 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType29; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue29; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue29; 
                    fieldValueType = this.model.get('FieldType29');
                    break;
                case 30 :    element_select_fieldType = this.ui.customProductSheetModelEditItem_select_FieldType30; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue30; 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue30; 
                    fieldValueType = this.model.get('FieldType30');
                    break;
            }

            if (fieldValueType == 1) {
                element_select_fieldType.append('<option value="1" selected="selected">' + resources.X100221 + '</option>');
                element_label_fieldValue.text(resources.X100225);
            }
            else {
                element_select_fieldType.append('<option value="1" >' + resources.X100221 + '</option>');
            }

            if (fieldValueType == 2) {
                element_select_fieldType.append('<option value="2" selected="selected">' + resources.X100425 + '</option>');
                element_label_fieldValue.text(resources.X100426);
            }
            else {
                element_select_fieldType.append('<option value="2" >' + resources.X100425 + '</option>');
            }

            if (fieldValueType == 3) {
                element_select_fieldType.append('<option value="3" selected="selected">' + resources.X100222 + '</option>');
                element_label_fieldValue.text(resources.X100226);
            }
            else {
                element_select_fieldType.append('<option value="3" >' + resources.X100222 + '</option>');
            }

            if (fieldValueType == 4) {
                element_select_fieldType.append('<option value="4" selected="selected">' + resources.X100223 + '</option>');
                element_label_fieldValue.text(resources.X100227);
            }
            else {
                element_select_fieldType.append('<option value="4" >' + resources.X100223 + '</option>');
            }

            if (fieldValueType == 5) {
                element_select_fieldType.append('<option value="5" selected="selected">' + resources.X100224 + '</option>');
                element_label_fieldValue.text(resources.X100228);
            }
            else {
                element_select_fieldType.append('<option value="5" >' + resources.X100224 + '</option>');
            }

            if (fieldValueType == 6) {
                element_select_fieldType.append('<option value="6" selected="selected">' + resources.X100393+ '</option>');
                element_label_fieldValue.text(resources.X100394);
            }
            else {
                element_select_fieldType.append('<option value="6" >' + resources.X100393 + '</option>');
            }

            switch (fieldValueType) {
                case 1 : 
                case 2 : 
                case 3 : 
                case 4 : element_div_fieldValue.css('display', 'none'); break;
                case 5 : element_div_fieldValue.show(); break;
                case 6 : element_div_fieldValue.show(); break;
            }
        }
    },
    selectFieldTypeChange: function (event) {
        if (event != null) {
            event.preventDefault();

            var element_div_fieldValue;
            var element_label_fieldValue;

            switch (event.target.id) {
                case 'customProductSheetModelEditItem_select_FieldType01' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue01; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue01; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType02' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue02; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue02; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType03' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue03; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue03; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType04' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue04; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue04; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType05' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue05; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue05; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType06' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue06; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue06; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType07' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue07; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue07; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType08' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue08; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue08; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType09' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue09; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue09; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType10' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue10; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue10; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType11' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue11; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue11; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType12' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue12; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue12; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType13' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue13; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue13; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType14' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue14; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue14; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType15' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue15; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue15; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType16' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue16; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue16; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType17' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue17; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue17; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType18' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue18; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue18; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType19' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue19; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue19; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType20' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue20; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue20; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType21' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue21; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue21; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType22' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue22; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue22; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType23' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue23; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue23; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType24' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue24; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue24; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType25' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue25; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue25; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType26' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue26; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue26; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType27' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue27; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue27; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType28' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue28; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue28; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType29' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue29; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue29; 
                    break;
                case 'customProductSheetModelEditItem_select_FieldType30' : 
                    element_div_fieldValue = this.ui.customProductSheetModelEditItem_div_FieldValue30; 
                    element_label_fieldValue = this.ui.customProductSheetModelEditItem_label_FieldValue30; 
                    break;
            }

            switch (event.target.value) {
                case '1' : 
                case '2' : 
                case '3' : 
                case '4' : element_div_fieldValue.css('display', 'none'); break;
                case '5' : element_label_fieldValue.text(resources.X100228); 
                    element_div_fieldValue.show(); break;
                case '6' : element_label_fieldValue.text(resources.X100394); 
                    element_div_fieldValue.show(); break;
            }
        }
    },
    selectFieldUsedChange: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.model.attributes.FieldUsed = parseInt(this.ui.customProductSheetModelEditItem_select_FieldUsed.val());

        for (i = 1; i <= 30; i++) {
            var sId = '';
            if (i < 10) {
                sId = '#customProductSheetModelEditItem_div_Field0' + i;
            }
            else {
                sId = '#customProductSheetModelEditItem_div_Field' + i;
            }

            if (i <= this.model.attributes.FieldUsed) {
                this.$el.find(sId).show();
            }
            else {
                this.$el.find(sId).css('display', 'none');
            }
        }
    },
    setModel : function(tmpModel) {
        tmpModel.attributes.Name = this.ui.customProductSheetModelEditItem_inputName.val();

        tmpModel.attributes.FieldUsed = parseInt(this.ui.customProductSheetModelEditItem_select_FieldUsed.val());

        tmpModel.attributes.PositionWebpage = parseInt(this.ui.customProductSheetModelEditItem_select_PositionWebpage.val());
        tmpModel.attributes.PositionProductName = parseInt(this.ui.customProductSheetModelEditItem_select_PositionProductName.val());
        tmpModel.attributes.PositionImage = parseInt(this.ui.customProductSheetModelEditItem_select_PositionImage.val());
        tmpModel.attributes.PositionFeature = parseInt(this.ui.customProductSheetModelEditItem_select_PositionFeature.val());
        tmpModel.attributes.PositionDescription = parseInt(this.ui.customProductSheetModelEditItem_select_PositionDescription.val());
        tmpModel.attributes.PositionSchema = parseInt(this.ui.customProductSheetModelEditItem_select_PositionSchema.val());

        tmpModel.attributes.FontSizeProductName = parseInt(this.ui.customProductSheetModelEditItem_select_FontSizeProductName.val());
        tmpModel.attributes.FontSizeImage = parseInt(this.ui.customProductSheetModelEditItem_select_FontSizeImage.val());
        tmpModel.attributes.FontSizeFeature = parseInt(this.ui.customProductSheetModelEditItem_select_FontSizeFeature.val());
        tmpModel.attributes.FontSizeDescription = parseInt(this.ui.customProductSheetModelEditItem_select_FontSizeDescription.val());
        tmpModel.attributes.FontSizeSchema = parseInt(this.ui.customProductSheetModelEditItem_select_FontSizeSchema.val());
                                                                                                     
        tmpModel.attributes.Field01 = this.ui.customProductSheetModelEditItem_input_Field01.val();   
        tmpModel.attributes.FieldType01 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType01.val());
        tmpModel.attributes.FieldValue01 = this.ui.customProductSheetModelEditItem_textarea_FieldValue01.val();   
        tmpModel.attributes.FieldSubject01 = this.ui.customProductSheetModelEditItem_input_FieldSubject01.val();   

        tmpModel.attributes.Field02 = this.ui.customProductSheetModelEditItem_input_Field02.val();   
        tmpModel.attributes.FieldType02 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType02.val());
        tmpModel.attributes.FieldValue02 = this.ui.customProductSheetModelEditItem_textarea_FieldValue02.val();   
        tmpModel.attributes.FieldSubject02 = this.ui.customProductSheetModelEditItem_input_FieldSubject02.val();   

        tmpModel.attributes.Field03 = this.ui.customProductSheetModelEditItem_input_Field03.val();   
        tmpModel.attributes.FieldType03 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType03.val());
        tmpModel.attributes.FieldValue03 = this.ui.customProductSheetModelEditItem_textarea_FieldValue03.val();   
        tmpModel.attributes.FieldSubject03 = this.ui.customProductSheetModelEditItem_input_FieldSubject03.val();   

        tmpModel.attributes.Field04 = this.ui.customProductSheetModelEditItem_input_Field04.val();   
        tmpModel.attributes.FieldType04 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType04.val());
        tmpModel.attributes.FieldValue04 = this.ui.customProductSheetModelEditItem_textarea_FieldValue04.val();   
        tmpModel.attributes.FieldSubject04 = this.ui.customProductSheetModelEditItem_input_FieldSubject04.val();   
        
        tmpModel.attributes.Field05 = this.ui.customProductSheetModelEditItem_input_Field05.val();   
        tmpModel.attributes.FieldType05 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType05.val());
        tmpModel.attributes.FieldValue05 = this.ui.customProductSheetModelEditItem_textarea_FieldValue05.val();   
        tmpModel.attributes.FieldSubject05 = this.ui.customProductSheetModelEditItem_input_FieldSubject05.val();   

        tmpModel.attributes.Field06 = this.ui.customProductSheetModelEditItem_input_Field06.val();   
        tmpModel.attributes.FieldType06 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType06.val());
        tmpModel.attributes.FieldValue06 = this.ui.customProductSheetModelEditItem_textarea_FieldValue06.val();   
        tmpModel.attributes.FieldSubject06 = this.ui.customProductSheetModelEditItem_input_FieldSubject06.val();   

        tmpModel.attributes.Field07 = this.ui.customProductSheetModelEditItem_input_Field07.val();   
        tmpModel.attributes.FieldType07 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType07.val());
        tmpModel.attributes.FieldValue07 = this.ui.customProductSheetModelEditItem_textarea_FieldValue07.val();   
        tmpModel.attributes.FieldSubject07 = this.ui.customProductSheetModelEditItem_input_FieldSubject07.val();   

        tmpModel.attributes.Field08 = this.ui.customProductSheetModelEditItem_input_Field08.val();   
        tmpModel.attributes.FieldType08 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType08.val());
        tmpModel.attributes.FieldValue08 = this.ui.customProductSheetModelEditItem_textarea_FieldValue08.val();   
        tmpModel.attributes.FieldSubject08 = this.ui.customProductSheetModelEditItem_input_FieldSubject08.val();   

        tmpModel.attributes.Field09 = this.ui.customProductSheetModelEditItem_input_Field09.val();   
        tmpModel.attributes.FieldType09 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType09.val());
        tmpModel.attributes.FieldValue09 = this.ui.customProductSheetModelEditItem_textarea_FieldValue09.val();   
        tmpModel.attributes.FieldSubject09 = this.ui.customProductSheetModelEditItem_input_FieldSubject09.val();   

        tmpModel.attributes.Field10 = this.ui.customProductSheetModelEditItem_input_Field10.val();   
        tmpModel.attributes.FieldType10 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType10.val());
        tmpModel.attributes.FieldValue10 = this.ui.customProductSheetModelEditItem_textarea_FieldValue10.val();   
        tmpModel.attributes.FieldSubject10 = this.ui.customProductSheetModelEditItem_input_FieldSubject10.val();   

        tmpModel.attributes.Field11 = this.ui.customProductSheetModelEditItem_input_Field11.val();   
        tmpModel.attributes.FieldType11 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType11.val());
        tmpModel.attributes.FieldValue11 = this.ui.customProductSheetModelEditItem_textarea_FieldValue11.val();   
        tmpModel.attributes.FieldSubject11 = this.ui.customProductSheetModelEditItem_input_FieldSubject11.val();   

        tmpModel.attributes.Field12 = this.ui.customProductSheetModelEditItem_input_Field12.val();   
        tmpModel.attributes.FieldType12 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType12.val());
        tmpModel.attributes.FieldValue12 = this.ui.customProductSheetModelEditItem_textarea_FieldValue12.val();   
        tmpModel.attributes.FieldSubject12 = this.ui.customProductSheetModelEditItem_input_FieldSubject12.val();   

        tmpModel.attributes.Field13 = this.ui.customProductSheetModelEditItem_input_Field13.val();   
        tmpModel.attributes.FieldType13 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType13.val());
        tmpModel.attributes.FieldValue13 = this.ui.customProductSheetModelEditItem_textarea_FieldValue13.val();   
        tmpModel.attributes.FieldSubject13 = this.ui.customProductSheetModelEditItem_input_FieldSubject13.val();   

        tmpModel.attributes.Field14 = this.ui.customProductSheetModelEditItem_input_Field14.val();   
        tmpModel.attributes.FieldType14 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType14.val());
        tmpModel.attributes.FieldValue14 = this.ui.customProductSheetModelEditItem_textarea_FieldValue14.val();   
        tmpModel.attributes.FieldSubject14 = this.ui.customProductSheetModelEditItem_input_FieldSubject14.val();   
        
        tmpModel.attributes.Field15 = this.ui.customProductSheetModelEditItem_input_Field15.val();   
        tmpModel.attributes.FieldType15 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType15.val());
        tmpModel.attributes.FieldValue15 = this.ui.customProductSheetModelEditItem_textarea_FieldValue15.val();   
        tmpModel.attributes.FieldSubject15 = this.ui.customProductSheetModelEditItem_input_FieldSubject15.val();   

        tmpModel.attributes.Field16 = this.ui.customProductSheetModelEditItem_input_Field16.val();   
        tmpModel.attributes.FieldType16 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType16.val());
        tmpModel.attributes.FieldValue16 = this.ui.customProductSheetModelEditItem_textarea_FieldValue16.val();   
        tmpModel.attributes.FieldSubject16 = this.ui.customProductSheetModelEditItem_input_FieldSubject16.val();   

        tmpModel.attributes.Field17 = this.ui.customProductSheetModelEditItem_input_Field17.val();   
        tmpModel.attributes.FieldType17 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType17.val());
        tmpModel.attributes.FieldValue17 = this.ui.customProductSheetModelEditItem_textarea_FieldValue17.val();   
        tmpModel.attributes.FieldSubject17 = this.ui.customProductSheetModelEditItem_input_FieldSubject17.val();   

        tmpModel.attributes.Field18 = this.ui.customProductSheetModelEditItem_input_Field18.val();   
        tmpModel.attributes.FieldType18 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType18.val());
        tmpModel.attributes.FieldValue18 = this.ui.customProductSheetModelEditItem_textarea_FieldValue18.val();   
        tmpModel.attributes.FieldSubject18 = this.ui.customProductSheetModelEditItem_input_FieldSubject18.val();   

        tmpModel.attributes.Field19 = this.ui.customProductSheetModelEditItem_input_Field19.val();   
        tmpModel.attributes.FieldType19 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType19.val());
        tmpModel.attributes.FieldValue19 = this.ui.customProductSheetModelEditItem_textarea_FieldValue19.val();   
        tmpModel.attributes.FieldSubject19 = this.ui.customProductSheetModelEditItem_input_FieldSubject19.val();   

        tmpModel.attributes.Field20 = this.ui.customProductSheetModelEditItem_input_Field20.val();   
        tmpModel.attributes.FieldType20 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType20.val());
        tmpModel.attributes.FieldValue20 = this.ui.customProductSheetModelEditItem_textarea_FieldValue20.val();   
        tmpModel.attributes.FieldSubject20 = this.ui.customProductSheetModelEditItem_input_FieldSubject20.val();   

        tmpModel.attributes.Field21 = this.ui.customProductSheetModelEditItem_input_Field21.val();   
        tmpModel.attributes.FieldType21 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType21.val());
        tmpModel.attributes.FieldValue21 = this.ui.customProductSheetModelEditItem_textarea_FieldValue21.val();   
        tmpModel.attributes.FieldSubject21 = this.ui.customProductSheetModelEditItem_input_FieldSubject21.val();   

        tmpModel.attributes.Field22 = this.ui.customProductSheetModelEditItem_input_Field22.val();   
        tmpModel.attributes.FieldType22 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType22.val());
        tmpModel.attributes.FieldValue22 = this.ui.customProductSheetModelEditItem_textarea_FieldValue22.val();   
        tmpModel.attributes.FieldSubject22 = this.ui.customProductSheetModelEditItem_input_FieldSubject22.val();   

        tmpModel.attributes.Field23 = this.ui.customProductSheetModelEditItem_input_Field23.val();   
        tmpModel.attributes.FieldType23 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType23.val());
        tmpModel.attributes.FieldValue23 = this.ui.customProductSheetModelEditItem_textarea_FieldValue23.val();   
        tmpModel.attributes.FieldSubject23 = this.ui.customProductSheetModelEditItem_input_FieldSubject23.val();   

        tmpModel.attributes.Field24 = this.ui.customProductSheetModelEditItem_input_Field24.val();   
        tmpModel.attributes.FieldType24 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType24.val());
        tmpModel.attributes.FieldValue24 = this.ui.customProductSheetModelEditItem_textarea_FieldValue24.val();   
        tmpModel.attributes.FieldSubject24 = this.ui.customProductSheetModelEditItem_input_FieldSubject24.val();   
        
        tmpModel.attributes.Field25 = this.ui.customProductSheetModelEditItem_input_Field25.val();   
        tmpModel.attributes.FieldType25 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType25.val());
        tmpModel.attributes.FieldValue25 = this.ui.customProductSheetModelEditItem_textarea_FieldValue25.val();   
        tmpModel.attributes.FieldSubject25 = this.ui.customProductSheetModelEditItem_input_FieldSubject25.val();   

        tmpModel.attributes.Field26 = this.ui.customProductSheetModelEditItem_input_Field26.val();   
        tmpModel.attributes.FieldType26 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType26.val());
        tmpModel.attributes.FieldValue26 = this.ui.customProductSheetModelEditItem_textarea_FieldValue26.val();   
        tmpModel.attributes.FieldSubject26 = this.ui.customProductSheetModelEditItem_input_FieldSubject26.val();   

        tmpModel.attributes.Field27 = this.ui.customProductSheetModelEditItem_input_Field27.val();   
        tmpModel.attributes.FieldType27 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType27.val());
        tmpModel.attributes.FieldValue27 = this.ui.customProductSheetModelEditItem_textarea_FieldValue27.val();   
        tmpModel.attributes.FieldSubject27 = this.ui.customProductSheetModelEditItem_input_FieldSubject27.val();   

        tmpModel.attributes.Field28 = this.ui.customProductSheetModelEditItem_input_Field28.val();   
        tmpModel.attributes.FieldType28 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType28.val());
        tmpModel.attributes.FieldValue28 = this.ui.customProductSheetModelEditItem_textarea_FieldValue28.val();   
        tmpModel.attributes.FieldSubject28 = this.ui.customProductSheetModelEditItem_input_FieldSubject28.val();   

        tmpModel.attributes.Field29 = this.ui.customProductSheetModelEditItem_input_Field29.val();   
        tmpModel.attributes.FieldType29 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType29.val());
        tmpModel.attributes.FieldValue29 = this.ui.customProductSheetModelEditItem_textarea_FieldValue29.val();   
        tmpModel.attributes.FieldSubject29 = this.ui.customProductSheetModelEditItem_input_FieldSubject29.val();   

        tmpModel.attributes.Field30 = this.ui.customProductSheetModelEditItem_input_Field30.val();   
        tmpModel.attributes.FieldType30 = parseInt(this.ui.customProductSheetModelEditItem_select_FieldType30.val());
        tmpModel.attributes.FieldValue30 = this.ui.customProductSheetModelEditItem_textarea_FieldValue30.val();   
        tmpModel.attributes.FieldSubject30 = this.ui.customProductSheetModelEditItem_input_FieldSubject30.val();   

    },
    btnSubmitClick: function (event) {
        event.preventDefault();
        var self = this;
    
        this.setModel(this.model);

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
        }
        else {
            this.hideErrors();

            var self = this;

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100408;
            dialogWaitingModel.attributes.Text = resources.X101103;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);

            this.model.save(this.model.attributes, {
                silent: true,
                wait: true,
                success: function () {
                    dialogWaiting.triggerMethod('dialog:close');

                    self.oldModel = self.model.clone();

                    var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ ProductSheetModel: self.model.attributes.id })

                    if (customEvent.length > 0) {
                        var render = new View_Render({ collection: customEvent, app : self.app });
                        self.app.renderRegion.show(render);

                        var renderFinishCallback = function (options) {
                        }
                        render.doIt(renderFinishCallback, false, 'EVENT_REFRESH', false);
                    }
                }
            });
        }
    },
    btnResetClick: function () {

    }
}, Backbone.Radio.Requests);