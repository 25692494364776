﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import config from '../../../config';
import language from '../../../language';
import tpl_header from './tpl/header.html';

import * as Entities_Empty from '../../../../lib/entities/empty';

export default Marionette.LayoutView.extend({
    //tagName: 'nav',
    initialize: function () {
        this.app = this.options.app;

        this.model = new Entities_Empty.Model();
        this.model.attributes.CultureName = language.getLanguageFromCodeCultureName(config.cultureName());

        this.modelCustom = this.options.modelCustom;

        var userChannel = Backbone.Radio.channel('app');

        var self = this;

        userChannel.on('Region_Header:login', function() {
            self.header_btnAuthClick();
        });

        userChannel.on('Region_Header:setLoginName', function() {
            if (self.app.mgr.profile !== undefined) {
                self.ui.header_btnAuth.css('display', 'none');
                self.ui.header_btnAuthLogoff.show();
                self.ui.header_btnForgotPassword.css('display', 'none');
                self.ui.header_li_price.css('display', 'none');
                self.ui.header_loginName.show();
            
                var events4usUser_FirstName = localStorage.getItem('events4usUser_FirstName');
                var events4usUser_AttPerson = localStorage.getItem('events4usUser_AttPerson');
                var events4usUser_Organisation = localStorage.getItem('events4usUser_Organisation');

                var displayName = events4usUser_FirstName;

                switch (events4usUser_Organisation) {
                    case "0" : displayName = events4usUser_FirstName; break;
                    case "1" : displayName = events4usUser_AttPerson; break;
                    case "2" : displayName = events4usUser_AttPerson; break;
                }

                if (displayName.length <= 0) {
                    //self.ui.header_li_loginName.html('');
                    //$(self.ui.header_li_loginName).html('');

                    //var html = '<a href="#price" style="font-size:14px"><i class="fa fa-tag"></i>' + resources['X100602'] + '</a>'
                    //self.ui.header_li_price.html(html);
                    //$(self.ui.header_li_loginName).html(html);

                    self.ui.header_loginName.html('');

                }else {
                    //self.ui.header_li_price.html('');
                    //$(self.ui.header_li_loginName).html('');

                    //var html = '<a id="header_loginName" style="color:rgb(220,220,220);font-size:14px;padding-top:10px;"><i class="fa fa-user"></i>' + ' ' + resources.X100278 +  model.attributes.FirstName + '!' + '</a>';
                    //var html = '<a id="header_loginName" style="font-size:14px;padding-top:10px;"><i class="fa fa-user"></i>' + ' ' + resources.X100278 +  ' ' + displayName + '!' + '</a>';

                    //self.ui.header_li_loginName.html(html);
                    //$(self.ui.header_li_loginName).html(html);

                    var userTag = ' ' + resources.X100278 +  ' ' + displayName + '!';

                    self.ui.header_loginName.html('<i class="fa fa-user"></i>' + ' ' + resources.X100278 +  ' ' + displayName + '!');
                }
            } else {
                self.ui.header_btnAuth.show();
                self.ui.header_btnAuthLogoff.css('display', 'none');
                self.ui.header_btnForgotPassword.show();
                self.ui.header_li_price.show();
                //$(self.ui.header_li_loginName).html('');
                self.ui.header_loginName.css('display', 'none');
            }
        });


        ////userChannel.on('customMenu:enable', function() {
        ////    self.options.enable = true;
        ////    self.initMenu(true);
        ////});
        //this.listenTo(userChannel, 'customMenu:enable', function() {
        //    self.options.enable = true;
        //    self.initMenu(true);
        //});

        ////userChannel.on('customMenu:disable', function() {
        ////    self.options.enable = false;
        ////    self.initMenu(false);
        ////});
        //this.listenTo(userChannel, 'customMenu:disable', function() {
        //    self.options.enable = false;
        //    self.initMenu(false);
        //});
    },
    getTemplate: function () {
        

        return tpl_header({ model: this.model.toJSON() });
        //return tpl_header();
    },
    ui: {
        'header_selectFlagstrip': '#header_selectFlagstrip',
        'header_loginName' : '#header_loginName',
        'header_btnAuth' : '#header_btnAuth',
        'header_btnAuthLogoff' : '#header_btnAuthLogoff',
        'header_li_price' : '#header_li_price',
        'header_li_loginName' : '#header_li_loginName',
        'header_btnForgotPassword' : '#header_btnForgotPassword'
    },
    events: {
        //'click #custom_menu11_a': 'help',
        
        'click @ui.header_btnAuth' : 'header_btnAuthClick',
        'click @ui.header_btnAuthLogoff' : 'header_btnAuthLogoffClick',
        'click @ui.header_btnForgotPassword' : 'header_btnForgotPasswordClick'
    },
    onRender: function () {
        //var userChannel = Backbone.Radio.channel('app');
        //userChannel.trigger('Region_Header:setLoginName');
    },
    onAttach(){
        $('#header_selectFlagstrip').flagStrap({
            countries: {
                "DK": "Denmark",
                "GB": "United Kingdom"
            },
            buttonSize: "btn-sm",
            buttonType: "btn-info",
            labelMargin: "10px",
            scrollable: false,
            scrollableHeight: "350px",
        });

        $('.dropdown-toggle').css('background-color', 'transparent');
        $('.dropdown-toggle').css('padding-top', '4px');
        $('.dropdown-toggle').css('color', 'rgb(248,201,196)');
        $('.dropdown-toggle').css('font-size', '14px');

        $('.dropdown-menu').css('margin-top','0px');

        //$('.dropdown-menu').val(language.getLanguageFromCodeCultureName(config.cultureName()));

        if (this.app.mgr.profile !== undefined) {
            this.ui.header_btnAuth.css('display', 'none');
            this.ui.header_btnAuthLogoff.show();
            this.ui.header_btnForgotPassword.css('display', 'none');
            this.ui.header_li_price.css('display', 'none');
            this.ui.header_loginName.show();
        } else {
            this.ui.header_btnAuth.show();
            this.ui.header_btnAuthLogoff.css('display', 'none');
            this.ui.header_btnForgotPassword.show();
            this.ui.header_li_price.show();
            this.ui.header_loginName.css('display', 'none');
        }
    },
    header_btnAuthClick: function (event) {
        if (event) {
            event.preventDefault();
        }

        window.mgr.redirectForToken().then(function () {
            //alert('Success: ');
        }, function (err) {
            //alert('Error: ');
        });
    },
    header_btnAuthLogoffClick: function (event) {
        event.preventDefault();

        mgr.redirectForLogout();
    },
    header_btnForgotPasswordClick: function (event) {
        event.preventDefault();

        window.app.router.navigate("#forgotPassword");
        window.app.router.forgotPassword();
    }

}, Backbone.Radio.Requests);
