var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div>  <div id="pictureSlideListItem_div_inner" class="d-flex align-items-center" style="margin:3px;border-width: 3px; border-color: black;border-style: none;max-width:150px; position:relative;background-color:rgb(192,192,192)"> <div id="pictureSlideListItem_div_innerinner" class="d-flex align-items-center" style="border-width: 3px; border-color: rgb(108, 114, 147);border-style:solid;max-width:140px"> <div style="position:absolute; top:4px; right:6px"> <i id="pictureSlideListItem_iHtml" style="margin-top:3px; color: #6c7293 !important" class="fa fa-lg fa-lg fa-cog"></i> <i id="pictureSlideListItem_iDelete" style="color: #6c7293 !important" class="far fa-lg fa-lg fa-times-circle py-1 text-info mdi-24px"></i> </div> <img style="margin-top:25px;max-width:135px;height:100px" src="'+
((__t=( model.InfoImage_ImageUrl))==null?'':__t)+
'">  </div> </div>  </div>';
}
return __p;
};
