﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
    },
    // different url for read, update
    "sync": function (method, model, options) {
        if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customUser/-1?AuthUserId=' + config.authUserId();
        }
        else if (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customUser/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            if (options.route == 'route01') {
                options.url = config.webApiUrl() + '/api/customUser/route01/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
            } else if (options.route == 'route02') {
                options.url = config.webApiUrl() + '/api/customUser/route02/' + model.attributes.id + '?AuthUserId=' + config.authUserId() + '&dummy=1';
            }
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    validateNoBackbone: function (app, insert) {
        this.errors = [];

        if (this.attributes.Name == "") {
            this.errors.push({ name: 'customUserInsert_inputName', message: resources.X200107 });
            this.errors.push({ name: 'customUserEditItem_inputName', message: resources.X200107 });
        }
        /*else {
            const regexS = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
            var regex = new RegExp(regexS);
            var results = regex.exec(this.attributes.Name);
            if (results == null) {
                this.errors.push({ name: 'customUserInsert_inputName', message: resources.X200111 });
                this.errors.push({ name: 'customUserEditItem_inputName', message: resources.X200111 });
            }
        }*/

        var count = 0;

        var self = this;
        for (var i = 0; i < app.entities.collections.customUser.fullCollection.length; i++) {
            if (app.entities.collections.customUser.fullCollection.models[i].attributes.id != this.attributes.id) {
                if (this.attributes.Name == app.entities.collections.customUser.fullCollection.models[i].attributes.Name) {
                    this.errors.push({ name: 'customUserInsert_inputName', message: resources.X200144 });
                    this.errors.push({ name: 'customUserEditItem_inputName', message: resources.X200144 });
                }
            }

            if (app.entities.collections.customUser.fullCollection.models[i].attributes.Enable == true) count++;
        };


        if (insert == true && this.attributes.Enable == true) count ++;
        
        if (count > app.entities.models.customItem.attributes.Count) {
            var message = String.format(resources.X200397, app.entities.models.customItem.attributes.Count, "http://shopify");
            this.errors.push({ name: 'customUserInsert_checkboxEnable', message: message });
            this.errors.push({ name: 'customUserEditItem_checkboxEnable', message: message });
        }

        if (this.attributes.CallerId == "") {
            this.errors.push({ name: 'customUserInsert_inputCallerId', message: resources.X200107 });
            this.errors.push({ name: 'customUserEditItem_inputCallerId', message: resources.X200107 });
        }

        for (var i = 0; i < app.entities.collections.customUser.fullCollection.length; i++) {
            if (app.entities.collections.customUser.fullCollection.models[i].attributes.id != this.attributes.id) {
                if (this.attributes.CallerId == app.entities.collections.customUser.fullCollection.models[i].attributes.CallerId) {
                    this.errors.push({ name: 'customUserInsert_inputCallerId', message: resources.X200120 });
                    this.errors.push({ name: 'customUserEditItem_inputCallerId', message: resources.X200120 });
                }
            }
        };

        for (var i = 0; i < app.entities.collections.customUser.fullCollection.length; i++) {
            if (app.entities.collections.customUser.fullCollection.models[i].attributes.id != this.attributes.id) {
                if (this.attributes.CallerId == app.entities.collections.customUser.fullCollection.models[i].attributes.Email) {
                    this.errors.push({ name: 'customUserInsert_inputEmail', message: resources.X200145 });
                    this.errors.push({ name: 'customUserEditItem_inputEmail', message: resources.X200145 });
                }
            }
        };

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

});

export var Collection = PageableCollection.extend({
    mode: "client",
    model: Model,
    state: {
        pageSize: 6,
    },
    queryParams: {
        sortKey: 'id',
        order: "direction",
        directions: {
            "-1": "asc",
            "1": "desc"
        }
    },
    options: {
        full: false
    },
    "sync": function (method, model, options) {
        if (method == 'read' && options.route == 'route01') {
            options.url = config.webApiUrl() + '/api/customUser/route01/-1?AuthUserId=' + config.authUserId();
        }
        else if (method == 'read' && options.route == 'route02') {
            options.url = config.webApiUrl() + '/api/customUser/route02/-1?AuthUserId=' + config.authUserId() + '&Information=' + options.Information;
        }

        return Backbone.sync(method, model, options);
    },
    comparator: function (a, b) {
        return a.attributes.id > b.attributes.id ? -1
            : a.attributes.id < b.attributes.id ? 1
                : 0;
    }
});



