﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

//import * as SignalR from 'signalr';

import tpl_customEventPush from './tpl/customEventPush.html';
import tpl_customEventPushDesign from './tpl/customEventPushDesign.html';

import language from '../../../common/language';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomEventPushInsert from './customEventPushInsert';
import View_CustomEventPushList from './customEventPushList';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_CustomPush from '../../entities/customPush';
import * as Entities_UserAnalytic from '../../entities/userAnalytic';

import View_CustomHeader from '../region/customHeader/customHeader';

import config from '../../../common/config';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customEventPushDesign();
        } else {
            return tpl_customEventPush();
        }
    },
    regions: {
        customHeader: "#customHeader",
        customEventPushInsert : '#customEventPushInsert',
        customEventPushList : '#customEventPushList',
        customEventPushPagination : '#customEventPushPagination',
        information: "#customEventPushInformation"
    },
    onBeforeShow: function () {
        this.customEventPushInsert.show(new View_CustomEventPushInsert({ collection: this.collection, localSetting: this.localSetting, app : this.app }));
        this.customEventPushList.show(new View_CustomEventPushList({ collection: this.app.entities.collections.customEventPush,localSetting: this.localSetting, app : this.app }));
        this.customEventPushPagination.show(new View_Pagination({ collection: this.app.entities.collections.customEventPush, app : this.app }));

        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.event});
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
        } else {
            customHeaderModel.attributes.Name = "";
        }

        customHeaderModel.attributes.Title = resources.X100237;
        customHeaderModel.attributes.ShowSave = false;
        customHeaderModel.attributes.ShowBack = true;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    initialize: function () {
        this.app = this.options.app;
        
        //this.listenTo(this.collection, 'change', this.render, this);
        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;
        //userChannel.on('customEventPush:pushMessage', function() {
        //    self.pushMessage(self.localSetting.attributes.LastMessage);
        //});
        this.listenTo(userChannel,'customEventPush:pushMessage', function() {
            self.pushMessage(self.localSetting.attributes.LastMessage);
        });

        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customEventPush_btnBack_click();
        });	

        this.signalR_init();

        this.setHasChanged(false);
    },
    signalR_init: function () {
        //this.IWannaChat = $.connection.LuxaforboardHub;
        //$.connection.hub.start();
    },
    pushMessage: function(message)
    {
        var tmpMessage = this.localSetting.attributes.EventId + "#¤%&/()=?" + message;
        //this.IWannaChat.server.send(tmpMessage);

        var tmpModel = new Entities_CustomPush.Model();
        tmpModel.attributes.Message = tmpMessage;
        tmpModel.attributes.id = 0;
        tmpModel.attributes.TokenKey = "";
        
        tmpModel.save(tmpModel, {
            silens :true,
            wait: true,
            success: function (response) {
                
            }});
    },
    help: function () {
        Help.help(this, true, 'customEventPush');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    ui: {
        'customEventPush_btnBack': '#customEventPush_btnBack',
        'customEventPush_selectEvent': '#customEventPush_selectEvent',
        'customEventPush_btnDeleteAll': '#customEventPush_btnDeleteAll',
        'customEventPush_btnCreateTest' : '#customEventPush_btnCreateTest',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk',
        'customEventPush_inputNameGroup': '#customEventPush_inputNameGroup'
    },
    events: {
        'click @ui.customEventPush_btnBack': 'customEventPush_btnBack_click',
        'click @ui.customEventPush_btnPushSubmit': 'btnPushSubmit',
        'click @ui.customEventPush_btnDeleteAll': 'btnDeleteAll',
        'click @ui.customEventPush_btnCreateTest': 'btnCreateTest',
        'change @ui.customEventPush_selectEvent': 'selectEventChange',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk'
    },
    onRender: function () {
        this.setupEvent();

        this.renderInformation();

        if (this.app.mode == 1) {
            this.ui.customEventPush_inputNameGroup.css('display', 'none');
        }

        return this;
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }

        //Push not enable
        if (this.app.entities.models.configSubscription[0].attributes.EnablePush == false) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100346 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        if (this.localSetting.attributes.EventId != -1) {
            var tmp = { "id": this.localSetting.attributes.EventId };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        //this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        
    },
    selectEventChange : function () {
        this.localSetting.attributes.EventId = parseInt(this.ui.customEventPush_selectEvent.val());
        this.customViewEvent_setActive();

        //read the push collection
        var self = this;
        this.app.entities.collections.customEventPush.fetch({
            EventId: this.localSetting.attributes.EventId,
            readlist : true,
            success: function () {
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customEventPush:eventChanged');
            },
            error: function () {
            }
        });
    },
    setupEvent: function () {
        var self = this;
        this.collection.fullCollection.forEach(function (entry) {
            self.ui.customEventPush_selectEvent.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
        });

        if (this.options.event != undefined && this.options.event != -1) {
            this.ui.customEventPush_selectEvent.val(this.options.event.toString());
        }
        
        this.selectEventChange();
    },
    btnDeleteAll: function (event) {
        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100237;
        dialog2ButtonModel.attributes.Text = resources.X100241;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100237;
            dialogWaitingModel.attributes.Text = resources.X100242;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            app.dialogRegion.show(dialogWaiting);

            var tmpModel = new this.app.entities.collections.customEventPush.model();
            tmpModel.id = 1;
            tmpModel.attributes.id = 1;
            tmpModel.attributes.EventId = this.localSetting.attributes.EventId;
            tmpModel.attributes.AuthUserId = config.authUserId();
            var self = this;

            var collection = this.app.entities.collections.customEventPush;

            tmpModel.destroy({
                wait: true,
                success: function (model, response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    self.selectEventChange();
                    //collection.fullCollection.sort();
                    //if (collection.state.currentPage < collection.state.lastPage) {
                    //    collection.getPage(collection.state.currentPage);
                    //}
                    //else {
                    //    collection.getLastPage();
                    //}
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnCreateTest: function (event) {
        var model = new Entities_UserAnalytic.Model();

        var startDate = new Date();

        var strUtcDate = startDate.getUTCFullYear().toString() + '-' +
                    (startDate.getUTCMonth() + 1) + '-' +
                    startDate.getUTCDate().toString() + ' ' + 
                    startDate.getUTCHours() + ':' + 
                    startDate.getUTCMinutes() + ':' + 
                    startDate.getUTCSeconds();

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(1);

        var strUtcStartDate = startDate.getUTCFullYear().toString() + '-' +
                (startDate.getUTCMonth() + 1) + '-' +
                startDate.getUTCDate().toString() + ' ' +
                startDate.getUTCHours().toString() + ':' +
                startDate.getUTCMinutes().toString() + ':' +
                startDate.getUTCSeconds().toString() ;

        model.attributes.id = -1;
        model.attributes.EventId = this.localSetting.attributes.EventId;
        model.attributes.AuthUserId = config.authUserId();
        model.attributes.UtcDate = strUtcStartDate;
        model.attributes.Days = 360;
        model.attributes.MaxCount = 1000;

        model.save( model, {
            silent: true,
            wait:true,
            success: function (response) {
            }
        });
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    },
    customEventPush_btnBack_click: function (event) {
        self.app.router.navigate("#customEventEditItem?id=" + this.options.eventId);
        self.app.router.customEventEditItem(this.options.event, this.options.scroll, this.options.module_selected);
    }
}, Backbone.Radio.Requests);
