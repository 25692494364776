﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';


import * as Entities_Empty from '../../../../lib/entities/empty';

import tpl_copyTemplate from './tpl/copyTemplate.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#copyTemplate_modal',
    errors: [],
    getTemplate: function () {
        return tpl_copyTemplate({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'copyTemplate_selectWebpage': '#copyTemplate_selectWebpage'
    },
    events: {
        "click #copyTemplate_btn01": "onBtn01",
        "click #copyTemplate_btn02": "onBtn02"
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;

        var checked = this.model.attributes.Status == 1 ? true : false;

        self.ui.copyTemplate_selectWebpage.append('<option value="' + -1 + '" selected="selected">' + ""/*resources.X100292*/ + '</option>');

        this.collection.fullCollection.forEach(function (entry) {
            if (entry.id != self.model.attributes.id && entry.attributes.DisplayType == self.model.attributes.DisplayType) {
                self.ui.copyTemplate_selectWebpage.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            }
        });
    },
    onBtn01: function () {
        this.model.set({ Webpage: parseInt(this.ui.copyTemplate_selectWebpage.val()) });
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    }
});