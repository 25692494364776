var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="col-4 col-lg-6 col-xs-6 col-md-4 col-sm-2"> <div class="image" style="text-align:center" id="dialogPaintStrapListItem_btnSubmit"> <img alt="image" src="'+
((__t=( model.Image ))==null?'':__t)+
'" style="width:155px;height:187px;border-style:solid; border-width:5px" id="dialogPaintStrapListItem_image"> <p>'+
((__t=( model.Name ))==null?'':__t)+
'</p> </div> </div>';
}
return __p;
};
