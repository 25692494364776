var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="customSecurity_empty"> <div class="content-wrapper" data-help="customSecurity"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200040'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3" style=""> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X200040'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group customSecurity_inputSecretKey"> <div class="form-group"> <label id="customSecurity_labelName" for="customSecurity_inputSecretKey">'+
((__t=( resources['X200112'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SecretKey))==null?'':__t)+
'" id="customSecurity_inputSecretKey" readonly="readonly"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customSecurity_inputSecretCode"> <div class="form-group"> <label id="customSecurity_labelName" for="customSecurity_inputSecretCode">'+
((__t=( resources['X200113'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SecretCode))==null?'':__t)+
'" id="customSecurity_inputSecretCode" readonly="readonly"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary btn-sm" id="customSecurity_btnSubmit">'+
((__t=( resources['X200114'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm" id="customSecurity_btnSendPomodoroMail">'+
((__t=( resources['X200365'] ))==null?'':__t)+
'</button> <br> <br> <small id="customSecurity_copyWebhookURL" style="font-size: 0.9em; cursor: pointer" class="text-muted mb-0"><i class="mdi mdi-clipboard mr-1"></i>'+
((__t=( resources['X200251'] ))==null?'':__t)+
'</small> <br> <small id="customSecurity_copyWebhookURLMuteButton" style="font-size: 0.9em; cursor: pointer" class="text-muted mb-0"><i class="mdi mdi-clipboard mr-1"></i>'+
((__t=( resources['X200324'] ))==null?'':__t)+
'</small> <br> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
