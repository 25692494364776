var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFormModel_empty"> <div class="content-wrapper" data-help="customFormModel">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101067'] ))==null?'':__t)+
'</h4> <div id="customFormModelInformation" class="e4usInformation" style="display:none"></div> <div id="customFormModelInsert"></div> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101068'] ))==null?'':__t)+
'</h4> <div class="list-wrapper" id="customFormModelList"></div> <div class="row"> <div class="col-md-12"> <div id="customFormModelPagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
