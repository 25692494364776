﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_settingList from './tpl/settingList.html';
import View_SettingListList from './settingListList';

export default Marionette.LayoutView.extend({
    dialogRegion: '#settingList_modal',
    getTemplate: function () {
        return tpl_settingList({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    regions: {
        settingListList: "#settingListList"
        //settingListPagination: "#settingListPagination",
    },
    onBeforeShow: function () {
        this.settingListList.show(new View_SettingListList({ app: this.app, collection: this.collection }));
        //this.settingListPagination.show(new View_Pagination({ collection: this.collection }));
    },
    onAttach() {
        //this.calcPageSize();
    },
    onRender: function () {
        //this.calcPageSize();
    },
    calcPageSize: function () {
        //className: ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2';

        var maxWidth = $('.card-body').width();

        var factor = 2;

        if (maxWidth < 576) factor = 2;
        if (maxWidth >= 576) factor = 2;
        if (maxWidth > 720) factor = 2;
        if (maxWidth > 960) factor = 4;
        if (maxWidth > 1140) factor = 6;

        //this.collection.fullCollection.pageableCollection.state.pageSize = factor * 2;
        this.collection.fullCollection.pageableCollection.state.pageSize = 12;

    },
    events: {
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02",
        "click #dialog2Button_btn03": "onBtn03",
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    nextCollectionId() {
        var ret = 0;
        this.collection.forEach(function (entry) {
            if (entry.id > ret) ret = entry.id;
        });
        return ret;
    },
    onBtn03: function () {
        var defaultModel = this.collection.where({ Default: true })[0];
        var newModel = defaultModel.clone();
        newModel.attributes.Default = false;
        newModel.attributes.id = this.nextCollectionId()+1;
        newModel.attributes.Selected = false;
        newModel.attributes.Standard = 2;
        newModel.id = newModel.attributes.id;

        this.collection.add(newModel, {
            wait: true,
            success: function (response) {

            }
        });
    }
});
