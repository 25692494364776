var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="font_empty"> <div class="modal fade" id="font_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X200243'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X200220'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home4-tab-1" data-toggle="tab" href="#home4-1" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X200220'] ))==null?'':__t)+
'</a> </li> <li class="nav-item" style="display:none"> <a class="nav-link" id="home4-tab-2" data-toggle="tab" href="#home4-2" role="tab" aria-controls="home4-1" aria-selected="true">'+
((__t=( resources['X200221'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home4-1" role="tabpanel" aria-labelledby="home4-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="form-group"> <label for="module_ColorPickerSliders_BackgroundColor">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</label> <div id="module_ColorPickerSliders_BackgroundColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label id="module_input_ZoomHValue" for="module_input_ZoomH">'+
((__t=( resources['X200330'] ))==null?'':__t)+
': '+
((__t=( model.ZoomH))==null?'':__t)+
'</label> <div id="module_input_ZoomH" class="ul-slider slider-success" style="height: 20px"></div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label id="module_input_ZoomVValue" for="module_input_ZoomV">'+
((__t=( resources['X200331'] ))==null?'':__t)+
': '+
((__t=( model.ZoomV))==null?'':__t)+
'</label> <div id="module_input_ZoomV" class="ul-slider slider-success" style="height: 20px"></div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label id="divtest" for="test">'+
((__t=( resources['X200346'] ))==null?'':__t)+
'</label> <div id="test"></div> </div> </div> </div> <div class="form-group"> <label style="" class="">'+
((__t=( resources['X200347'] ))==null?'':__t)+
'</label> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="d-flex align-items-center"> <div class="col"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="module_input_WakeLock"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label id="divtest" for="test">'+
((__t=( resources['X200353'] ))==null?'':__t)+
'</label> <div id="test"></div> </div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home4-2" role="tabpanel" aria-labelledby="home4-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X200222'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X200223'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X200224'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X200225'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-2"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2"> '+
((__t=( resources['X200244'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-2" class="collapse" role="tabpanel" aria-labelledby="heading-2" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist">  <li class="nav-item"> <a class="nav-link" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home-2" aria-selected="true">'+
((__t=( resources['X200227'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-3" data-toggle="tab" href="#home-3" role="tab" aria-controls="home-3" aria-selected="false">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-4" data-toggle="tab" href="#home-4" role="tab" aria-controls="home-4" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content">  <div class="tab-pane fade show active" id="home-2" role="tabpanel" aria-labelledby="home-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="font_dropDown_Font1">'+
((__t=( resources['X200230'] ))==null?'':__t)+
'</label> <div id="font_formgroup_Font1" class="form-group"> <input id="font_dropDown_Font1" class="form-control"> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X200231'] ))==null?'':__t)+
'</label> <div id="font_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font1_Size">'+
((__t=( resources['X200232'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font1_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font1_Style">'+
((__t=( resources['X200233'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font1_Style" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font1_TextAlign">'+
((__t=( resources['X200234'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font1_TextAlign" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-3" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_ColorPickerSliders_Background1_Color">'+
((__t=( resources['X100358'] ))==null?'':__t)+
'</label> <div id="font_ColorPickerSliders_Background1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-4" role="tabpanel" aria-labelledby="home-tab-4"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group font_input_margin1_Top"> <div class="control-label"> <label for="font_input_margin1_Top">'+
((__t=( resources['X200235'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Top))==null?'':__t)+
'" id="font_input_margin1_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group font_input_margin1_Bottom"> <div class="control-label"> <label for="font_input_margin1_Bottom">'+
((__t=( resources['X200236'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Bottom))==null?'':__t)+
'" id="font_input_margin1_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group font_input_margin1_Left"> <div class="control-label"> <label for="font_input_margin1_Left">'+
((__t=( resources['X200237'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Left))==null?'':__t)+
'" id="font_input_margin1_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group font_input_margin1_Right"> <div class="control-label"> <label for="font_input_margin1_Right">'+
((__t=( resources['X200238'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Margin1_Right))==null?'':__t)+
'" id="font_input_margin1_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-3"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3"> '+
((__t=( resources['X200245'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-3" class="collapse" role="tabpanel" aria-labelledby="heading-3" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist">  <li class="nav-item"> <a class="nav-link" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home-2" aria-selected="true">'+
((__t=( resources['X200227'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content">  <div class="tab-pane fade show active" id="home-2" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="font_dropDown_Font2">'+
((__t=( resources['X200230'] ))==null?'':__t)+
'</label> <div id="font_formgroup_Font2" class="form-group"> <input id="font_dropDown_Font2" class="form-control"> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_ColorPickerSliders_Font2_Color">'+
((__t=( resources['X200231'] ))==null?'':__t)+
'</label> <div id="font_ColorPickerSliders_Font2_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_Size_01">'+
((__t=( resources['X200246'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_Size_01" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_Size_02">'+
((__t=( resources['X200247'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_Size_02" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_Size_03">'+
((__t=( resources['X200248'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_Size_03" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_Size_04">'+
((__t=( resources['X200249'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_Size_04" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_Size_05">'+
((__t=( resources['X200250'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_Size_05" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_Style">'+
((__t=( resources['X200233'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_Style" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="font_dropDown_Font2_TextAlign">'+
((__t=( resources['X200234'] ))==null?'':__t)+
'</label> <select id="font_dropDown_Font2_TextAlign" class="form-control"></select> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="font_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="font_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
