﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customAdBigImage from './tpl/customAdBigImage.html';
//import tpl_customAdBigImageDesign from './tpl/customAdBigImageDesign.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomAdBigImageInsert from './customAdBigImageInsert';
import View_CustomAdBigImageList from './customAdBigImageList';
import Help from '../../helper/help';

import View_CustomHeader from '../region/customHeader/customHeader';

export default Marionette.LayoutView.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customAdBigImage();
        } else {
            return tpl_customAdBigImage();
        }
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        //this.collection.on('change', this.render, this);
        this.listenTo(this.collection, 'change', this.render, this);

        this.informationCollection = new Entities_Empty.Collection();
        this.localSetting = new Entities_Empty.Model();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'customHeader:iconSave', function () {
            //self.btnSubmitClick(null);
        });
        this.listenTo(userChannel, 'customHeader:iconBack', function () {
            self.customAdBigImage_btnBack_click();
        });	

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customAdBigImage');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },
    regions: {
        customAdBigImageInsert: "#customAdBigImageInsert",
        customAdBigImageList: "#customAdBigImageList",
        customAdBigImagePagination: "#customAdBigImagePagination",
        information: "#customAdBigImageInformation",
        customHeader: "#customHeader"
    },
    onBeforeShow: function () {
        this.customAdBigImageInsert.show(new View_CustomAdBigImageInsert({ collection: this.collection, localSetting: this.localSetting, app: this.app }));
        this.customAdBigImageList.show(new View_CustomAdBigImageList({ collection: this.collection, app: this.app }));
        this.customAdBigImagePagination.show(new View_Pagination({ collection: this.collection, app: this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));

        var customHeaderModel = new Entities_Empty.Model();

        var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
        if (customEvent.length > 0) {
            customHeaderModel.attributes.Name = customEvent[0].attributes.Name;
            customHeaderModel.attributes.ShowBack = true;
        } else {
            customHeaderModel.attributes.Name = "";
            customHeaderModel.attributes.ShowBack = false;
        }

        customHeaderModel.attributes.Title = resources.X101469;
        customHeaderModel.attributes.ShowSave = false;

        this.customHeader.show(new View_CustomHeader({ app: this.app, model: customHeaderModel }));
    },
    ui: {
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk'
    },
    events: {
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk'
    },
    onRender: function () {
        this.renderInformation();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No image yes
        if (this.collection.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100339 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        this.customViewEvent_setActive();
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');
        
    },
    customAdBigImage_btnBack_click: function (event) {
        switch (this.options.viewType) {
            case 0:
                var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: this.options.eventId });
                var customWebpage = self.app.entities.collections.customWebpage.fullCollection.where({ Name: customEvent[0].attributes.Name });

                this.app.router.navigate("#customWebpageEditItem?id=" + customWebpage[0].attributes.id);
                this.app.router.customWebpageEditItem(customWebpage[0].attributes.id, this.options.eventId, this.options.scroll, this.options.module_selected  );
                break;
        }
        //this.app.router.navigate("#customEventEditItem?id=" + this.options.eventId);
        //this.app.router.customEventEditItem(this.options.eventId, this.options.scroll, 4);
    }
}, Backbone.Radio.Requests);