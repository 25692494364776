﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customIPAddress from './tpl/customIPAddress.html';
import language from '../../../common/language';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomIPAddressInsert from './customIPAddressInsert';
import View_CustomIPAddressList from './customIPAddressList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customIPAddress();
    },
    regions: {
        customIPAddressInsert: "#customIPAddressInsert",
        customIPAddressList: "#customIPAddressList",
        customIPAddressPagination: "#customIPAddressPagination",
        information: "#customIPAddressInformation",
    },
    onBeforeShow: function () {
        this.customIPAddressInsert.show(new View_CustomIPAddressInsert({ collection: this.collection, app : this.app }));
        this.customIPAddressList.show(new View_CustomIPAddressList({ collection: this.collection, app : this.app }));
        this.customIPAddressPagination.show(new View_Pagination({ collection: this.collection, app : this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    ui : {
        'help': '#help'
        
    },
    events: {
        'click #help': 'help'
    },
    initialize: function () {
        this.app = this.options.app;

        //this.collection.on('change', this.render, this);
        //this.listenTo(this.collection, 'change', this.render, this);
        this.informationCollection = new Entities_Empty.Collection();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customIPAddress');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged: function () {
    },
    onRender: function () {
        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No events
        if (this.collection.models.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X200090, Information: resources.X200092 });
            this.informationCollection.add(tmpModel);
        }
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
    },
    onAttach(){
    }
}, Backbone.Radio.Requests);