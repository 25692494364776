﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartmentResetListItem from './tpl/customDepartmentResetListItem.html';
import language from '../../../common/language';
import View_CustomDepartmentResetListItem from './customDepartmentResetListItem';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import CustomDepartmentResetEditItem from './CustomDepartmentResetEditItem';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Dialog2Button2 from '../../../lib/views/dialog2Button2/dialog2Button2';
import View_DialogWaiting2 from '../../../lib/views/dialogWaiting2/dialogWaiting2';
import View_DialogBox2 from '../../../lib/views/dialogBox2/dialogBox2';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    // tagName: 'li',
    // className: '',
    getTemplate: function () {
        return tpl_customDepartmentResetListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.model.attributes.TmpTime = this.secondsToHms(this.model.attributes.Time);

        this.listenTo(this.model, 'change', this.render);

        this.app = this.options.app;

        /*if (this.model.attributes.ActiveTab == resources.X200311) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.Custom_Color_Default: this.model.attributes.Text = this.app.entities.models.custom.attributes.Custom_Text_Default; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200270) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.SolidColor_Color_Off: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Off; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Busy: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Busy; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Available: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Available; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Blue: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Blue; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Yellow: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Yellow; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Cyan: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Cyan; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_Magenta: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Magenta; break;
                case this.app.entities.models.custom.attributes.SolidColor_Color_CustomColor: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_CustomColor; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200275) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.MSTeams_Color_Online: this.model.attributes.Text = this.app.entities.models.custom.attributes.MSTeams_Text_Online; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_Busy: this.model.attributes.Text = this.app.entities.models.custom.attributes.MSTeams_Text_Busy; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_DoNotDisturb: this.model.attributes.Text = this.app.entities.models.custom.attributes.MSTeams_Text_DoNotDisturb; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_BeRightBack: this.model.attributes.Text = this.app.entities.models.custom.attributes.MSTeams_Text_BeRightBack; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_ShowAsNotPresent: this.model.attributes.Text = this.app.entities.models.custom.attributes.MSTeams_Text_ShowAsNotPresent; break;
                case this.app.entities.models.custom.attributes.MSTeams_Color_ShowAsOffline: this.model.attributes.Text = this.app.entities.models.custom.attributes.MSTeams_Text_ShowAsOffline; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200312) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.Mutebutton_Color_On: this.model.attributes.Text = this.app.entities.models.custom.attributes.Mutebutton_Text_On; break;
                case this.app.entities.models.custom.attributes.Mutebutton_Color_Off: this.model.attributes.Text = this.app.entities.models.custom.attributes.Mutebutton_Text_Off; break;
            }
        }
        else if (this.model.attributes.ActiveTab == resources.X200286) {
            switch (this.model.attributes.Color) {
                case this.app.entities.models.custom.attributes.Pomodoro_Color_Time: this.model.attributes.Text = this.app.entities.models.custom.attributes.Pomodoro_Text_Time; break;
                case this.app.entities.models.custom.attributes.Pomodoro_Color_ShortBreak: this.model.attributes.Text = this.app.entities.models.custom.attributes.Pomodoro_Text_ShortBreak; break;
                case this.app.entities.models.custom.attributes.Pomodoro_Color_LongBreak: this.model.attributes.Text = this.app.entities.models.custom.attributes.Pomodoro_Text_LongBreak; break;
                
            }
        }*/
    },
    ui: {
        'customDepartmentResetListItem_btnDelete': '#customDepartmentResetListItem_btnDelete',
        'customDepartmentResetListItem_btnEdit': '#customDepartmentResetListItem_btnEdit',
        'customDepartmentResetListItem_btnPreview': '#customDepartmentResetListItem_btnPreview',
        'customDepartmentResetListItem_aQRCode': '#customDepartmentResetListItem_aQRCode',
        'customDepartmentResetListItem_div': '#customDepartmentResetListItem_div',
        'customDepartmentResetListItem_divText': 'customDepartmentResetListItem_divText',
        'customDepartmentResetListItem_divTime': 'customDepartmentResetListItem_divTime',
    },
    events: {
        'click @ui.customDepartmentResetListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customDepartmentResetListItem_btnEdit': 'btnEditClick',
        'click @ui.customDepartmentResetListItem_btnPreview': 'btnPreviewClick',
        'click @ui.customDepartmentResetListItem_aQRCode': 'aQRCodeClick'
    },
    onRender: function () {
        if (this.model.attributes.QRCodeImage == null) {
            this.model.attributes.QRCodeImage = '../../../../images/defaultQRCode.jpg';
        }
        if (this.model.attributes.WebpageImage == null) {
            this.model.attributes.WebpageImage = '../../../../images/IPhone5.png';
        }

        this.model.attributes.WebpageUrl = config.webApiUrl() + '/Events/' + this.model.attributes.id + '_imageView.html?bust=' + (new Date()).getTime() + "'";

        this.ui.customDepartmentResetListItem_div.css('background-color', this.model.attributes.Color);

        //switch (this.model.attributes.Color) {
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Off: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Off; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Busy: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Busy; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Available: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Available; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Blue: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Blue; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Yellow: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Yellow; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Cyan: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Cyan; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_Magenta: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_Magenta; break;
        //    case this.app.entities.models.custom.attributes.SolidColor_Color_CustomColor: this.model.attributes.Text = this.app.entities.models.custom.attributes.SolidColor_Text_CustomColor; break;

        //}

        this.model.attributes.TmpTime = this.secondsToHms(this.model.attributes.Time);
        //this.ui.customDepartmentResetListItem_divTime.html(this.model.attributes.TmpTime);
        
    },
    secondsToHms: function(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h;// > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m;// > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s;// > 0 ? s + (s == 1 ? " second" : " seconds") : "";

        if (h < 10) hDisplay = '0' + hDisplay;
        if (m < 10) mDisplay = '0' + mDisplay;
        if (s < 10) sDisplay = '0' + sDisplay;
        

        return hDisplay + ':' + mDisplay;// + ':' +  sDisplay;
    },
    btnEditClick: function () {
        $('#dialogRegion').css('opacity', '0.99');
        //var url = 'customDepartmentResetEditItem/' + this.model.attributes.id;
        //this.app.router.navigate(url);
        //this.app.router.customDepartmentResetEditItem(this.model.attributes.id);

        var self = this;

        
        var customDepartmentResetEditItem = new CustomDepartmentResetEditItem({ app: this.app, model: this.model, parent: self });


        var callback01 = function (options) {
            self.stopListening(customDepartmentResetEditItem, "dialog:btn01clicked", callback01);
            self.stopListening(customDepartmentResetEditItem, "dialog:btn02clicked", callback02);
            $('#dialogRegion').css('opacity', '1');
            //self.model.attributes.Data = htmlSingle.model.toJSON();
            //self.model.trigger('setupSetting');
            //self.model.trigger('change');
        }
        self.listenTo(customDepartmentResetEditItem, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            $('#dialogRegion').css('opacity', '1');
            self.stopListening(customDepartmentResetEditItem, "dialog:btn01clicked", callback01);
            self.stopListening(customDepartmentResetEditItem, "dialog:btn02clicked", callback02);
        }
        self.listenTo(customDepartmentResetEditItem, "dialog:btn02clicked", callback02);

        self.app.dialogRegion2.show(customDepartmentResetEditItem);
        
    },
    aQRCodeClick: function () {
        this.btnEditClick();
    },
    btnDeleteClick: function (event) {
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X200258;
        dialog2ButtonModel.attributes.Text = resources.X200268;
        dialog2ButtonModel.attributes.Button01 = resources.X200095;
        dialog2ButtonModel.attributes.Button02 = resources.X200096;
        var dialog2Button = new View_Dialog2Button2({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {

            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200101;
            dialogWaitingModel.attributes.Text = resources.X200098;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: self });
            var callbackWaiting = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callbackWaiting);
            }
            self.listenTo(dialogWaiting, "dialog:okclicked", callbackWaiting);

            self.app.dialogRegion2.show(dialogWaiting);

            //var collection = self.app.entities.collections.customDepartmentReset;

            self.model.destroy({
                wait: true,
                //silent: true,
                success: function (model, response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    //var callbackDelete = function () {
                    //    self.model.collection.fullCollection.sort();
                    //    if (self.model.collection.state.currentPage < self.model.collection.state.lastPage) {
                    //        self.model.collection.getPage(self.collection.state.currentPage);
                    //    }
                    //    else {
                    //        self.model.collection.getLastPage();
                    //    }

                    //    //dialogWaiting.triggerMethod('dialog:close');
                    //}

                    //callbackDelete();
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);
        this.app.dialogRegion2.show(dialog2Button);
    }
});
