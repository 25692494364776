﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';


import * as Entities_Empty from '../../../../lib/entities/empty';

import tpl_schemaSetting from './tpl/schemaSetting.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#schemaSetting_modal',
    errors: [],
    getTemplate: function () {
        return tpl_schemaSetting({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'schemaSetting_inputPosition': '#schemaSetting_inputPosition'
    },
    events: {
        "click #schemaSetting_btn01": "onBtn01",
        "click #schemaSetting_btn02": "onBtn02"
    },
    onRender: function () {
    },
    onBtn01: function () {
        var self = this;

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();

        var position = parseInt(this.ui.schemaSetting_inputPosition.val());

        if (position > 0 && position <= this.model.attributes.FieldUsed) {
            this.model.attributes.Position = position;
        } else {
            var Text_1 = String.format(resources.X101024, this.model.attributes.FieldUsed);
            this.errors.push({ name: 'schemaSetting_inputPosition', message: Text_1});
        }
        

        if (this.errors.length > 0) {
            this.showErrors(this.errors);
        } else {
            callback();
        }
    }
});