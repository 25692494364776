'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customSchemaDataInsert from './tpl/customSchemaDataInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import config from '../../../common/config';
import * as Entities_CustomSchemaData from '../../entities/customSchemaData';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customSchemaDataInsert();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;

        this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_add', this.render);
        this.listenTo(this.app.entities.collections.customSchemaSchema, 'customSchemaSchema_remove', this.render);

        var self = this;
        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel,'customSchemaDataInsert:setSchemaModel', function(schemaModel) {
            self.options.schemaModel = schemaModel;
            //this.settingModel.attributes.SchemaSchemaId = self.options.schemaModel.id;
            self.render();
        });

    },
    ui: {
        'customSchemaDataInsert_btnSubmit': '#customSchemaDataInsert_btnSubmit',
        'customSchemaDataInsert_input_Field01': '#customSchemaDataInsert_input_Field01',
        'customSchemaDataInsert_input_Field02': '#customSchemaDataInsert_input_Field02',
        'customSchemaDataInsert_input_Field03': '#customSchemaDataInsert_input_Field03',
        'customSchemaDataInsert_input_Field04': '#customSchemaDataInsert_input_Field04',
        'customSchemaDataInsert_input_Field05': '#customSchemaDataInsert_input_Field05',
        'customSchemaDataInsert_input_Field06': '#customSchemaDataInsert_input_Field06',
        'customSchemaDataInsert_input_Field07': '#customSchemaDataInsert_input_Field07',
        'customSchemaDataInsert_input_Field08': '#customSchemaDataInsert_input_Field08',
        'customSchemaDataInsert_input_Field09': '#customSchemaDataInsert_input_Field09',
        'customSchemaDataInsert_input_Field10': '#customSchemaDataInsert_input_Field10',
        'customSchemaDataInsert_input_Field11': '#customSchemaDataInsert_input_Field11',
        'customSchemaDataInsert_input_Field12': '#customSchemaDataInsert_input_Field12',
        'customSchemaDataInsert_label_Field01': '#customSchemaDataInsert_label_Field01',
        'customSchemaDataInsert_label_Field02': '#customSchemaDataInsert_label_Field02',
        'customSchemaDataInsert_label_Field03': '#customSchemaDataInsert_label_Field03',
        'customSchemaDataInsert_label_Field04': '#customSchemaDataInsert_label_Field04',
        'customSchemaDataInsert_label_Field05': '#customSchemaDataInsert_label_Field05',
        'customSchemaDataInsert_label_Field06': '#customSchemaDataInsert_label_Field06',
        'customSchemaDataInsert_label_Field07': '#customSchemaDataInsert_label_Field07',
        'customSchemaDataInsert_label_Field08': '#customSchemaDataInsert_label_Field08',
        'customSchemaDataInsert_label_Field09': '#customSchemaDataInsert_label_Field09',
        'customSchemaDataInsert_label_Field10': '#customSchemaDataInsert_label_Field10',
        'customSchemaDataInsert_label_Field11': '#customSchemaDataInsert_label_Field11',
        'customSchemaDataInsert_label_Field12': '#customSchemaDataInsert_label_Field12',
        'customSchemaDataInsert_input_ShowSchema' :'#customSchemaDataInsert_input_ShowSchema',
        'customSchemaDataInsert_input_ShowSchemaBigscreen' :'#customSchemaDataInsert_input_ShowSchemaBigscreen'


    },
    events: {
        'click @ui.customSchemaDataInsert_btnSubmit': 'btnSubmitClick',
    },
    btnSubmitClick: function (event) {
        event.preventDefault();
        this.hideErrors();
        //var tmpModel = new this.app.entities.collections.customSchemaData.model();
        var tmpModel = new Entities_CustomSchemaData.Model(); 
        //tmpModel.attributes.id = null;

        tmpModel.attributes.Field01 = '';
        tmpModel.attributes.Field02 = '';
        tmpModel.attributes.Field03 = '';
        tmpModel.attributes.Field04 = '';
        tmpModel.attributes.Field05 = '';
        tmpModel.attributes.Field06 = '';
        tmpModel.attributes.Field07 = '';
        tmpModel.attributes.Field08 = '';
        tmpModel.attributes.Field09 = '';
        tmpModel.attributes.Field10 = '';
        tmpModel.attributes.Field11 = '';
        tmpModel.attributes.Field12 = '';

        tmpModel.attributes.EventId = this.settingModel.attributes.EventId;
        tmpModel.attributes.id = 1;//app.views.customSchemaDataList.table_getFirstVisibleItem();
        tmpModel.attributes.orgid = -1;
        tmpModel.attributes.Schema =  this.settingModel.attributes.SchemaSchemaId;
        tmpModel.attributes.Field01 = this.ui.customSchemaDataInsert_input_Field01.val();
        tmpModel.attributes.Field02 = this.ui.customSchemaDataInsert_input_Field02.val();
        tmpModel.attributes.Field03 = this.ui.customSchemaDataInsert_input_Field03.val();
        tmpModel.attributes.Field04 = this.ui.customSchemaDataInsert_input_Field04.val();
        tmpModel.attributes.Field05 = this.ui.customSchemaDataInsert_input_Field05.val();
        tmpModel.attributes.Field06 = this.ui.customSchemaDataInsert_input_Field06.val();
        tmpModel.attributes.Field07 = this.ui.customSchemaDataInsert_input_Field07.val();
        tmpModel.attributes.Field08 = this.ui.customSchemaDataInsert_input_Field08.val();
        tmpModel.attributes.Field09 = this.ui.customSchemaDataInsert_input_Field09.val();
        tmpModel.attributes.Field10 = this.ui.customSchemaDataInsert_input_Field10.val();
        tmpModel.attributes.Field11 = this.ui.customSchemaDataInsert_input_Field11.val();
        tmpModel.attributes.Field12 = this.ui.customSchemaDataInsert_input_Field12.val();

        this.ui.customSchemaDataInsert_input_ShowSchema.is(':checked') ? tmpModel.attributes.ShowSchema = true : tmpModel.attributes.ShowSchema = false;
        this.ui.customSchemaDataInsert_input_ShowSchemaBigscreen.is(':checked') ? tmpModel.attributes.ShowSchemaBigscreen = true : tmpModel.attributes.ShowSchemaBigscreen = false;

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(app);
        if (ret == false) {
            this.ui.customSchemaDataInsert_input_Field01.val('');
            this.ui.customSchemaDataInsert_input_Field02.val('');
            this.ui.customSchemaDataInsert_input_Field03.val('');
            this.ui.customSchemaDataInsert_input_Field04.val('');
            this.ui.customSchemaDataInsert_input_Field05.val('');
            this.ui.customSchemaDataInsert_input_Field06.val('');
            this.ui.customSchemaDataInsert_input_Field07.val('');
            this.ui.customSchemaDataInsert_input_Field08.val('');
            this.ui.customSchemaDataInsert_input_Field09.val('');
            this.ui.customSchemaDataInsert_input_Field10.val('');
            this.ui.customSchemaDataInsert_input_Field11.val('');
            this.ui.customSchemaDataInsert_input_Field12.val('');

            var self = this;
            //app.views.customSchemaDataList.table_addModel(tmpModel, true, tmpModel.attributes.id, true);
            var msg = {
                tmpModel: tmpModel,
                jumpToData: true,
                jumpFirst: tmpModel.attributes.id,
                ignoreEmptyContent: true
            }
            this.triggerMethod('table_addModel', msg);
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    },
    onRender: function () {
        this.ui.customSchemaDataInsert_label_Field01.text(this.options.schemaModel.attributes.Field01);
        this.ui.customSchemaDataInsert_label_Field02.text(this.options.schemaModel.attributes.Field02);
        this.ui.customSchemaDataInsert_label_Field03.text(this.options.schemaModel.attributes.Field03);
        this.ui.customSchemaDataInsert_label_Field04.text(this.options.schemaModel.attributes.Field04);
        this.ui.customSchemaDataInsert_label_Field05.text(this.options.schemaModel.attributes.Field05);
        this.ui.customSchemaDataInsert_label_Field06.text(this.options.schemaModel.attributes.Field06);
        this.ui.customSchemaDataInsert_label_Field07.text(this.options.schemaModel.attributes.Field07);
        this.ui.customSchemaDataInsert_label_Field08.text(this.options.schemaModel.attributes.Field08);
        this.ui.customSchemaDataInsert_label_Field09.text(this.options.schemaModel.attributes.Field09);
        this.ui.customSchemaDataInsert_label_Field10.text(this.options.schemaModel.attributes.Field10);
        this.ui.customSchemaDataInsert_label_Field11.text(this.options.schemaModel.attributes.Field11);
        this.ui.customSchemaDataInsert_label_Field12.text(this.options.schemaModel.attributes.Field12);

        this.selectFieldUsedChange();

        if (this.settingModel.attributes.EventId > 0 && this.settingModel.attributes.SchemaSchemaId > 0) {
            this.ui.customSchemaDataInsert_btnSubmit.prop('disabled', false);
        }
        else {
            this.ui.customSchemaDataInsert_btnSubmit.prop('disabled', true);
        }

        return this;
    },
    selectFieldUsedChange: function () {
        for (var i = 1; i <= 12; i++) {
            var sId = '';
            if (i < 10) {
                sId = '#customSchemaDataInsert_div_Field0' + i;
            }
            else {
                sId = '#customSchemaDataInsert_div_Field' + i;
            }

            if (i <= this.options.schemaModel.attributes.FieldUsed) {
                this.$el.find(sId).show();
            }
            else {
                this.$el.find(sId).css('display', 'none');
            }
        }
    }
});