﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_infoPage from './tpl/infoPage.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_infoPageList from './infoPageList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_infoPage();
    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();
        this.localSetting = new Entities_Empty.Model();

        this.setHasChanged(false);
    },
    help: function () {
        switch(this.model.attributes.DisplayType) {
            case 0 : Help.help(this, true, 'infoPage'); break;
            case 1 : Help.help(this, true, 'infoPage'); break;
        }
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    regions: {
        infoPageList: "#infoPageList"
    },
    onBeforeShow: function () {
        this.infoPageList.show(new View_infoPageList({ collection: this.collection, app : this.app }));
    },
    ui : {
        'help': '#help'
    },
    events: {
        'click #help': 'help'
    },
    onRender: function () {
    },
    onAttach(){
    }
}, Backbone.Radio.Requests);