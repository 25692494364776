﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageEditItemTopbar from './tpl/customWebpageEditItemTopbar.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import config from '../../../common/config';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    className: 'row',
    getTemplate: function () {
        return tpl_customWebpageEditItemTopbar({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.listenTo(userChannel, 'customWebpageEditor:saveContent', function () {
        });

        this.listenTo(userChannel, 'customWebpageEditor:setOldContent', function (content) {
        });

        this.localSetting.attributes.themeChanged = false;

        this.listenTo(userChannel, 'customWebpageEditor:themeChange', function () {
            self.localSetting.attributes.themeChanged = true;


        });

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customWebpageEditItemTopbar');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmit(null, false, true, callback, false);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'customHeader_iconSave': '#customHeader_iconSave',
        'customHeader_iconBack': '#customHeader_iconBack',
        'customWebpageEditItemTopbar_iconSave': '#customWebpageEditItemTopbar_iconSave',
        'customWebpageEditItemTopbar_iconFileCode': '#customWebpageEditItemTopbar_iconFileCode',
        'customWebpageEditItemTopbar_iconFile': '#customWebpageEditItemTopbar_iconFile',
        'customWebpageEditItemTopbar_iconImage': '#customWebpageEditItemTopbar_iconImage',
        'customWebpageEditItemTopbar_iconPalette': '#customWebpageEditItemTopbar_iconPalette',
        'customWebpageEditItemTopbar_iconCog': '#customWebpageEditItemTopbar_iconCog',
        'customWebpageEditItemTopbar_iconBars': '#customWebpageEditItemTopbar_iconBars',
        'customWebpageEditItemTopbar_iconCaretDown': '#customWebpageEditItemTopbar_iconCaretDown',
        'customWebpageEditItemTopbar_iconCopy': '#customWebpageEditItemTopbar_iconCopy',
        'customWebpageEditItemTopbar_iconArrowsAlt': '#customWebpageEditItemTopbar_iconArrowsAlt',
        'customWebpageEditItemTopbar_tablet_alt': '#customWebpageEditItemTopbar_tablet_alt',
        'customWebpageEditItemTopbar_mobile_alt': '#customWebpageEditItemTopbar_mobile_alt',
        'customWebpageEditItemTopbar_tv': '#customWebpageEditItemTopbar_tv',
        'customWebpageEditItemTopbar_wpforms': '#customWebpageEditItemTopbar_wpforms',
        'help': '#help'
    },
    events: {
        'click @ui.customHeader_iconSave': 'iconSaveClick',
        'click @ui.customHeader_iconBack': 'iconBackClick',
        'click @ui.customWebpageEditItemTopbar_iconSave': 'iconSaveClick',
        'click @ui.customWebpageEditItemTopbar_iconFileCode': 'iconFileCodeClick',
        'click @ui.customWebpageEditItemTopbar_iconFile': 'iconFileClick',
        'click @ui.customWebpageEditItemTopbar_iconImage': 'iconImageClick',
        'click @ui.customWebpageEditItemTopbar_iconPalette': 'iconPaletteClick',
        'click @ui.customWebpageEditItemTopbar_iconCog': 'iconCogClick',
        'click @ui.customWebpageEditItemTopbar_iconBars': 'iconBarsClick',
        'click @ui.customWebpageEditItemTopbar_iconCaretDown': 'iconCaretDownClick',
        'click @ui.customWebpageEditItemTopbar_iconCopy': 'iconCopyClick',
        'click @ui.customWebpageEditItemTopbar_iconArrowsAlt': 'iconArrowsAltClick',
        'click @ui.customWebpageEditItemTopbar_tablet_alt': 'iconTabletAltClick',
        'click @ui.customWebpageEditItemTopbar_mobile_alt': 'iconMobileAltClick',
        'click @ui.customWebpageEditItemTopbar_tv': 'iconTvClick',
        'click @ui.customWebpageEditItemTopbar_wpforms': 'iconWpClick',
        'click #help': 'help'
    },
    onRender: function () {
    },
    onAttach() {
    },
    /*iconSaveClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customHeader:iconSave');
    },*/
    iconBackClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customHeader:iconBack');
    },
    iconSaveClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconSave');
    },
    iconFileCodeClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconFileCode');
    },
    iconFileClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconFile');
    },
    iconImageClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconImage');
    },
    iconPaletteClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconPalette');
    },
    iconCogClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconCog');
    },
    iconBarsClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconBars');
    },
    iconCaretDownClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconCaretDown');
    },
    iconCopyClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconCopy');
    },
    iconArrowsAltClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconArrowsAlt', true);
    },
    iconTabletAltClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconTabletAlt');
    },
    iconMobileAltClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconMobileAlt');
    },
    iconTvClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconTv');
    },
    iconWpClick: function (e) {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemTopbar:iconWpforms');
    }
}, Backbone.Radio.Requests);
