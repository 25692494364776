﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customInfopageEditItemListItemH from './tpl/customInfopageEditItemListItemH.html';
import tpl_customInfopageEditItemListItemV from './tpl/customInfopageEditItemListItemV.html';
import language from '../../../common/language';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_HFSetting from './setting/hfSetting';
import View_PageSetting from './setting/PageSetting';
import View_PrinterSetting from './setting/PrinterSetting';
//import tpl_001_item from '../../info/template/tpl/001_item.html';
//import tpl_002_item from '../../info/template/tpl/002_item.html';

export default Marionette.ItemView.extend({
    tagName: 'li',
    attributes: function () {
        return {
            id: 'customInfopageEditItemListItem_li_' + this.model.attributes.id,
            'data-id': this.model.id,
            'data-position': this.model.attributes.Position,
            class: 'fffggg'
        }

    },
    getTemplate: function () {
        var tmpModel = this.app.entities.collections.Template.where({ id: this.model.attributes.Template })[0];

        switch (tmpModel.attributes.Direction) {
            case 0: return tpl_customInfopageEditItemListItemH({ model: this.model.toJSON() }); break;
            case 1: return tpl_customInfopageEditItemListItemV({ model: this.model.toJSON() }); break;
        }
    },
    initialize: function () {
        this.app = this.options.app;

        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'grid_container': '#grid_container',
        'customInfopageEditItemListItem_div_inner': '#customInfopageEditItemListItem_div_inner',
        'customInfopageEditItemListItem_btnEdit': '#customInfopageEditItemListItem_btnEdit',
        'customInfopageEditItemListItem_btnDelete': '#customInfopageEditItemListItem_btnDelete',
        'customInfopageEditItemListItem_btnSetting': '#customInfopageEditItemListItem_btnSetting',
        'customInfopageEditItemListItem_inputDuration': '#customInfopageEditItemListItem_inputDuration',
        'customInfopageEditItemListItem_li': '#customInfopageEditItemListItem_li',
        'customInfopageEditItemListItem_a': '#customInfopageEditItemListItem_a',
        'customInfopageEditItemListItem_btnDropdown': '#customInfopageEditItemListItem_btnDropdown',
        'customInfopageEditItemListItem_div': '#customInfopageEditItemListItem_div',
    },
    events: {
        'click @ui.customInfopageEditItemListItem_a': 'btnEditClick',
        //   'click @ui.customInfopageEditItemListItem_li': 'btnEditClick',
        'click @ui.customInfopageEditItemListItem_btnEdit': 'btnEditClick',
        'click @ui.customInfopageEditItemListItem_btnDelete': 'btnDeleteClick',
        'click @ui.customInfopageEditItemListItem_btnSetting': 'btnSettingClick',
        'change @ui.customInfopageEditItemListItem_inputDuration': 'inputDurationChange'
    },
    onAttach: function () {
    },
    getTemplateItem() {
        var ret = '';

        /*switch (this.model.attributes.TemplateItem) {
            case '001_item.html': ret = tpl_001_item({}); break;
            case '002_item.html': ret = tpl_002_item({}); break;
        }*/

        var tmpModel = this.app.entities.collections.Template.where({ id: this.model.attributes.Template })[0];
        ret = window.atob(tmpModel.attributes.TemplateItemBase64);



        return ret;
    },
    getTemplateClass() {
        var ret = '';

        var tmpModel = this.app.entities.collections.Template.where({ id: this.model.attributes.Template })[0];
        ret = tmpModel.attributes.TemplateItemClass;

        return ret;
    },
    onRender: function () {
        this.ui.grid_container.addClass(this.getTemplateClass());
        this.ui.grid_container.html(this.getTemplateItem());

        switch (this.model.attributes.Selected) {
            case 1:
                this.ui.customInfopageEditItemListItem_div_inner.css('border-style', 'solid');
                this.ui.customInfopageEditItemListItem_div_inner.css('border-color', 'black');
                break;
            case 0:
                this.ui.customInfopageEditItemListItem_div_inner.css('border-style', 'solid');
                this.ui.customInfopageEditItemListItem_div_inner.css('border-color', 'white');
                break;
        }

        switch (this.model.attributes.EnableDelete) {
            case 1:
                this.ui.customInfopageEditItemListItem_btnDelete.show();
                break;
            case 0:
                this.ui.customInfopageEditItemListItem_btnDelete.css('display', 'none');
                break;
        }
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;

        switch (this.model.attributes.DisplayType) {
            case 0: customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); break;
            case 1: customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Infopage: this.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    btnDeleteClick: function (event) {
        event.preventDefault();

        var self = this;
        //Do not delete if there is only 1 element
        var length = 0;

        switch (this.model.attributes.DisplayType) {
            case 0: var length = this.model.collection.where({ Tag: 1 }).length; break;
            case 1: var length = this.model.collection.where({ Tag: 9 }).length; break;
        }

        var lengthStatus = 0;
        var errorLengthStatus = false;
        switch (this.model.attributes.DisplayType) {
            case 0: var lengthStatus = this.model.collection.where({ Tag: 1, Status: 1 }).length; break;
            case 1: var lengthStatus = this.model.collection.where({ Tag: 9, Status: 1 }).length; break;
        }
        if ((lengthStatus <= 1) && (this.model.attributes.Status == 1)) {
            errorLengthStatus = true;
        }

        var callbackDelete = function () {
            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customInfopageEditItemListItem:deleteModel', self.model);
        }

        if (length <= 1) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            switch (this.model.attributes.DisplayType) {
                case 0: dialogBoxModel.attributes.Text = resources.X100437; break;
                case 1: dialogBoxModel.attributes.Text = resources.X100970; break;
            }
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        } else if (errorLengthStatus) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X100229;
            switch (this.model.attributes.DisplayType) {
                case 0: dialogBoxModel.attributes.Text = resources.X100971; break;
                case 1: dialogBoxModel.attributes.Text = resources.X100972; break;
            }
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        } else {
            var text = '';
            switch (this.model.attributes.DisplayType) {
                case 0: text = String.format(resources.X100558, this.model.attributes.Name); break;
                case 1: text = String.format(resources.X100559, this.model.attributes.Name); break;
            }

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X100229;
            dialog2ButtonModel.attributes.Text = text;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;

            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

            var callback01 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                //self.saveSelectedModel();
                callbackDelete();
            }

            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion.show(dialog2Button);

        }
    },
    btnEditClick: function (event) {
        event.preventDefault();
        event.stopPropagation();

        /*var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemListItem:saveSelectedModel');
        userChannel.trigger('customInfopageEditItemListItem:disabledWebpageContent');*/

        this.model.collection.forEach(function (entry) {
            entry.set({ Selected: 0 });
        });

        this.model.set({ Selected: 1 });
        //this.model.collection.trigger('change');

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customInfopageEditItemListItem:setSelectedModel');


    },
    inputDurationChange: function (event) {
        event.preventDefault();

        this.model.attributes.Duration = parseInt(this.ui.customInfopageEditItemListItem_inputDuration.val());
    },
    hfSetting: function () {
        var self = this;

        var hfSettingModel = new Entities_Empty.Model();
        hfSettingModel.attributes.Title = resources.X100917;
        hfSettingModel.attributes.Text = '';
        hfSettingModel.attributes.Button01 = resources.X100279;
        hfSettingModel.attributes.Button02 = resources.X100280;
        hfSettingModel.attributes.Name = this.model.attributes.Name;
        hfSettingModel.attributes.Status = this.model.attributes.Status;
        hfSettingModel.attributes.Tag = this.model.attributes.Tag;
        hfSettingModel.attributes.OldId = this.model.attributes.id;

        var hfSetting = new View_HFSetting({ app: this.app, model: hfSettingModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(hfSetting, "dialog:btn01clicked", callback01);
            self.stopListening(hfSetting, "dialog:btn02clicked", callback02);

            self.model.set({ Status: hfSettingModel.attributes.Status });
        }
        this.listenTo(hfSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(hfSetting, "dialog:btn01clicked", callback01);
            self.stopListening(hfSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(hfSetting, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(hfSetting);
    },
    pageSetting: function () {
        var self = this;

        var pageSettingModel = new Entities_Empty.Model();
        pageSettingModel.attributes.id = this.model.attributes.id;
        //pageSettingModel.attributes.Webpage = this.model.attributes.Webpage;
        pageSettingModel.attributes.Name = this.model.attributes.Name;
        pageSettingModel.attributes.Duration = this.model.attributes.Duration;
        pageSettingModel.attributes.Status = this.model.attributes.Status;
        pageSettingModel.attributes.Tag = this.model.attributes.Tag;

        //var model = this.app.entities.collections.Template.where({ id: iId });

        var collection = new Entities_Empty.Collection();

        this.app.entities.collections.Template.forEach(function (entry) {
            if (entry.attributes.ModuleType.includes(self.options.customModuleModel.attributes.ModuleType)) {
                if (self.model.attributes.Template == entry.attributes.id) {
                    entry.attributes.Selected = 1;
                }
                else {
                    entry.attributes.Selected = 0;
                }

                collection.add(entry);
            }
        });

        var pageSetting = new View_PageSetting({ app: this.app, model: pageSettingModel, collection: collection/*this.app.entities.collections.Template*//*this.model.collection*/, parent: self });

        var templateSelected = -1;
        var templateCollection = [];

        var callback01 = function () {
            self.stopListening(pageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(pageSetting, "dialog:btn02clicked", callback02);

            self.model.set({ Name: pageSettingModel.attributes.Name });
            self.model.set({ Duration: pageSettingModel.attributes.Duration });
            self.model.set({ Status: pageSettingModel.attributes.Status });

            collection.forEach(function (entry) {
                if (entry.attributes.Selected == 1) {
                    templateSelected = entry.attributes.id;
                    templateCollection = entry.attributes.Collection;
                }
            });

            if (self.model.attributes.id != templateSelected) {
                var dialog2ButtonModel = new Entities_Empty.Model();
                dialog2ButtonModel.attributes.Title = resources.X100229;
                dialog2ButtonModel.attributes.Text = resources.X101475;
                dialog2ButtonModel.attributes.Button01 = resources.X100281;
                dialog2ButtonModel.attributes.Button02 = resources.X100282;

                var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

                var callback01 = function (options) {
                    self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                    self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                    self.model.set({ Template: templateSelected });
                    self.model.set({ Collection: templateCollection });
                }

                this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

                var callback02 = function (options) {
                    self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                    self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                }
                this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

                this.app.dialogRegion.show(dialog2Button);

            }
        }
        this.listenTo(pageSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(pageSetting, "dialog:btn01clicked", callback01);
            self.stopListening(pageSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(pageSetting, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(pageSetting);
    },
    getexp: function (Css, key1, key2, defaultValue) {
        var ret = {};

        var tmpCss = Css;
        var subStrA = tmpCss.match(key1);
        var subStrB = tmpCss.match(key2);
        var value = tmpCss.substring(subStrA.index + key1.length, subStrB.index);
        if (value.length > 0) {
            tmpCss = tmpCss.replace(tmpCss.substring(subStrA.index, subStrB.index + key2.length), "");
            ret = { Css: tmpCss, value: value };
        } else {
            ret = { Css: Css, value: defaultValue };
        }

        return ret;
    },
    addPrinterSetting: function (model) {
        var tmpCss = model.attributes.Css;

        var ret = this.getexp(tmpCss, "margin-top:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginTop = ret.value;

        var ret = this.getexp(tmpCss, "margin-bottom:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginBottom = ret.value;

        var ret = this.getexp(tmpCss, "margin-left:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginLeft = ret.value;

        var ret = this.getexp(tmpCss, "margin-right:", "px;", "0");
        var tmpCss = ret.Css;
        model.attributes.MarginRight = ret.value;
    },
    printerSetting: function () {
        var self = this;

        var printerSettingModel = new Entities_Empty.Model();
        printerSettingModel.attributes.Title = resources.X100917;
        printerSettingModel.attributes.Text = '';
        printerSettingModel.attributes.Button01 = resources.X100279;
        printerSettingModel.attributes.Button02 = resources.X100280;
        printerSettingModel.attributes.Name = this.model.attributes.Name;
        printerSettingModel.attributes.OldId = this.model.attributes.id;
        printerSettingModel.attributes.Css = this.model.attributes.Css;
        printerSettingModel.attributes.Status = this.model.attributes.Status;


        this.addPrinterSetting(printerSettingModel);

        var printerSetting = new View_PrinterSetting({ app: this.app, model: printerSettingModel, collection: this.model.collection, parent: self });

        var callback01 = function () {
            self.stopListening(printerSetting, "dialog:btn01clicked", callback01);
            self.stopListening(printerSetting, "dialog:btn02clicked", callback02);

            self.model.set({ Name: printerSettingModel.attributes.Name, Css: printerSettingModel.attributes.Css });
            self.model.set({ Status: printerSettingModel.attributes.Status });
        }
        this.listenTo(printerSetting, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(printerSetting, "dialog:btn01clicked", callback01);
            self.stopListening(printerSetting, "dialog:btn02clicked", callback02);

        }
        this.listenTo(printerSetting, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(printerSetting);
    },
    btnSettingClick: function (event) {
        event.preventDefault();

        switch (this.model.attributes.Tag) {
            case 0: this.pageSetting();
            case 2: this.hfSetting(); break;
            case 1: this.pageSetting();
                break;
            case 3: break;
            case 4: this.printerSetting();
                break;
            case 7:
            case 8: break;
            case 9: this.pageSetting();
                break;
        }
    },
    saveSelectedModel: function () {

    }
}, Backbone.Radio.Requests);
