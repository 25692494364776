var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="htmlSlideListListItemProperties_empty"> <div class="modal fade" id="htmlSlideListListItemProperties_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101425'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion-1" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X101419'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion-1"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home-tab-1" data-toggle="tab" href="#home-1" role="tab" aria-controls="home-1" aria-selected="true">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home-1" role="tabpanel" aria-labelledby="home-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label for="htmlSlideListListItemProperties_dropDown_AnimationGroup">'+
((__t=( resources['X101428'] ))==null?'':__t)+
'</label> <select id="htmlSlideListListItemProperties_dropDown_AnimationGroup" class="form-control"></select> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label for="htmlSlideListListItemProperties_dropDown_Animation">'+
((__t=( resources['X101426'] ))==null?'':__t)+
'</label> <select id="htmlSlideListListItemProperties_dropDown_Animation" class="form-control"></select> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div id="htmlSlideListListItemProperties_group_Duration" class="form-group"> <label>'+
((__t=( resources['X101427'] ))==null?'':__t)+
'</label> <input class="form-control" id="htmlSlideListListItemProperties_input_Duration" value="'+
((__t=( model.Duration ))==null?'':__t)+
'"> </div> </div> <p>Seconds: <span id="htmlSlideListListItemProperties_label_Duration"></span></p> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="htmlSlideListListItemProperties_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="htmlSlideListListItemProperties_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div></div>';
}
return __p;
};
