﻿import * as Backbone from 'backbone';
import * as Backbone_Picky from 'backbone.picky';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    initialize: function(){
        var selectable = new Backbone.Picky.Selectable(this);
        _.extend(this, selectable);
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,

    initialize: function(){
        var singleSelect = new Backbone.Picky.SingleSelect(this);
        _.extend(this, singleSelect);
    }
});