'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginFileValidateType from '../../plugins/filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';



import tpl_customDepartmentImportImage from './tpl/customDepartmentImportImage.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import helper_typo from '../../helper/typo';

import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customDepartmentImportImage();
    },
    initialize: function () {
        this.app = this.options.app;

        this.localSetting = new Entities_Empty.Model();
    },
    ui: {
        'customDepartmentImportImage_fileImagePond': '#customDepartmentImportImage_fileImagePond'

    },
    events: {
    },
    childEvents: {
    },
    onRender: function () {

    },
    onAttach() {
        var self = this;

        FilePond.registerPlugin(
            FilePondPluginFileValidateType,
            FilePondPluginFileEncode,
            //FilePondPluginFileEncode,
            //FilePondPluginImageCrop,
            //FilePondPluginImageResize,
            //FilePondPluginImageTransform,
            //FilePondPluginImagePreview,
            //FilePondPluginImageEdit,
        );

        var labelIdle = resources.X200141 + ' <span class="filepond--label-action">' + resources.X200142 + '</span>';

        var pondImage = FilePond.create(this.ui.customDepartmentImportImage_fileImagePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: true,
            //maxFiles:5,
            //maxParallelUploads: 0,
            //itemInsertInterval: 500,
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            //imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            allowImageTransform: true,
            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var userModel = self.collection.fullCollection.where({ File: fileItem.filename })[0];

                    //return true;
                    if (userModel != undefined) {
                        var base64 = fileItem.getFileEncodeDataURL();
                        //var base64 = fileItem.getFileEncodeBase64String();

                        //var canvas = document.createElement('canvas');
                        //canvas.width = 200;
                        //canvas.height = 200;

                        //var context = canvas.getContext('2d');

                        //var image = new Image();
                        //image.src = base64;

                        //image.onload = function () {


                        //    var wrh = image.width / image.height;
                        //    var newWidth = canvas.width;
                        //    var newHeight = newWidth / wrh;
                        //    if (newHeight > canvas.height) {
                        //        newHeight = canvas.height;
                        //        newWidth = newHeight * wrh;
                        //    }
                        //    var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
                        //    var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;


                        //    context.beginPath();
                        //    context.drawImage(image, xOffset, yOffset, newWidth, newHeight);

                        //    base64 = canvas.toDataURL();

                        var searchString = ";base64,";
                        var pos = base64.indexOf(searchString);
                        var imageMime = base64.substring(5, pos);
                        var imageData = base64.substring(pos + searchString.length, base64.length);

                        var utcDateTime = helper_typo.getDBUtcDataTime();

                        var sizeText = Math.round(200) + 'X' + Math.round(200);

                        var callbackSaveImage = function (model) {
                            pondImage.removeFile(fileItem);

                            userModel.attributes.ImageUrl = model.attributes.ImageUrl;
                            userModel.save(userModel.attributes, {
                                success: function () {
                                    userModel.trigger('change');
                                },
                                error: function (mode, response) {
                                }

                            });

                        }

                        self.saveImage(fileItem.filename, resources.X100500, resources.X100493, imageData, imageMime, 1, sizeText, 2, 1, utcDateTime, callbackSaveImage);
                    }
                    else {
                        var dialogBoxModel = new Entities_Empty.Model();
                        dialogBoxModel.attributes.Title = resources.X100229;
                        dialogBoxModel.attributes.Text = resources.X200134;
                        dialogBoxModel.attributes.Button = resources.X100279;

                        var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: self });
                        var callback2 = function (options) {
                            self.stopListening(dialogBox, "dialog:okclicked", callback2);
                            pondImage.removeFile(fileItem);
                        }
                        self.listenTo(dialogBox, "dialog:okclicked", callback2);
                        self.app.dialogRegion.show(dialogBox);

                    }
                } else {
                    alert("FEJL");
                }
            }
        });
    },
    saveImage: function (filename, filenameText, nameText, imageData, imageMimeType, sizeType, sizeText, tableNum, tableId, strUtcDate, callback) {
        var searchString = ";base64,";
        var pos = 0;
        var ticks = ((new Date().getTime() * 10000) + 621355968000000000);

        //var now = new Date();
        //var utcDateTime = now.getUTCFullYear().toString() + '-' +
        //    (now.getUTCMonth() + 1) + '-' +
        //    now.getUTCDate().toString() + ' ' +
        //    now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();

        var tmpModel = new this.app.entities.collections.customImageTmp.model();
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = 'Department' + '_' + ticks;
        tmpModel.attributes.Filename = 'Department' + '_' + ticks + '.png';
        tmpModel.attributes.ImageData = imageData;
        tmpModel.attributes.ImageMimeType = imageMimeType;
        tmpModel.attributes.ImageUpdate = true;
        tmpModel.attributes.SizeType = sizeType;
        tmpModel.attributes.SizeText = sizeText;
        tmpModel.attributes.TableNum = tableNum;
        tmpModel.attributes.TableId = tableId;
        tmpModel.attributes.UtcDate = strUtcDate;
        tmpModel.attributes.ImageUrl = '';
        

        this.app.entities.collections.customImageTmp.create(tmpModel, {
            wait: true,
            success: function (response) {
                callback(response);
            },
            error: function (mode, response) {
            }
        });
    }
});