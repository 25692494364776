var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customWebpageListSmall_empty">  <li class="dropdown" id="customWebpageEditItemListItemSmall"> <a id="" href="#" class="dropdown-toggle active" data-toggle="dropdown" style="cursor: pointer; color: red; height:20px"><i class="icon fa fa-file-text"></i>'+
((__t=( resources['X100532'] ))==null?'':__t)+
'</a>  </li> </div>';
}
return __p;
};
