﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tinycolor from 'tinycolor';

import tpl_eventImagePrintTemplate from '../tplRender/eventImagePrintTemplate.html';
import tpl_eventTemplate from '../tplRender/eventTemplate.html';
import tpl_eventSchemaTemplate from '../tplRender/eventSchemaTemplate.html';
import tpl_eventProductSheetTemplate from '../tplRender/eventProductSheetTemplate.html';
import tpl_eventSchemaBigscreenTemplate from '../tplRender/eventSchemaBigscreenTemplate.html';
import tpl_eventFormTemplate from '../tplRender/eventFormTemplate.html';
import tpl_eventInfoTemplate from '../tplRender/eventInfoTemplate.html';
import tpl_eventPosterTemplate from '../tplRender/eventPosterTemplate.html';
import tpl_eventQRTemplate from '../tplRender/eventQRTemplate.html';
import tpl_eventLocationTemplate from '../tplRender/eventLocationTemplate.html';
import tpl_eventCalendarTemplate from '../tplRender/eventCalendarTemplate.html';

import tpl_eventIndexTemplate from '../tplRender/eventIndexTemplate.html';
import tpl_eventIndexImagePrintTemplate from '../tplRender/eventIndexImagePrintTemplate.html';

import * as Entities_Event from '../entities/event';
import * as Entities_Empty from '../../lib/entities/empty';
import * as Entities_AzureEvent from '../entities/azureEvent';
import * as Entities_Page2Image from '../entities/page2image';
import * as Entities_QRCode from '../entities/qrcode';

import * as Entities_Header from '../../event/entities/header';


import config from '../../common/config';
import renderError from './renderError';

var HelperInfopage = {
    getStyleSheet: function (unique_title) {
        for (var i = 0; i < document.styleSheets.length; i++) {
            var sheet = document.styleSheets[i];
            if (sheet.title == unique_title) {
                return sheet;
            }
        }
    },
    getRuleIndex: function (style, className) {
        var ret = -1;

        for (var i = 0; i < style.cssRules.length; i++) {
            var cssRule = style.cssRules[i].selectorText;
            if (style.cssRules[i].selectorText == className) {
                ret = i;
            }
        }

        return ret;
    },
    setupCssStyle: function (app) {
        var style = this.getStyleSheet('infopage');
        var cssRulesIdx = this.getRuleIndex(style, '.infopage > h2');
        if (cssRulesIdx != -1) {
            style.deleteRule(cssRulesIdx);

            var font1_style = app.entities.collections.FontStyle.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font1_Style
            });

            var font1_color = app.entities.models.InfoPageSetting.attributes.Font1_Color;
            /*switch (app.entities.models.InfoPageSetting.attributes.Font1_ColorType) {
                case "0": font1_color = app.entities.models.InfoPageSetting.attributes.Font1_ColorApp; break;
                case "1": font1_color = app.entities.models.InfoPageSetting.attributes.Font1_Color; break;
            }*/

            var font1_lineHeight = app.entities.collections.LineHeight.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font1_LineHeight
            });

            var font1 = app.entities.models.InfoPageSetting.attributes.Font1;
            switch (app.entities.models.InfoPageSetting.attributes.Font1_FontType) {
                case "0": font1 = app.entities.models.InfoPageSetting.attributes.Font1_App; break;
                case "1": font1 = app.entities.models.InfoPageSetting.attributes.Font1; break;
           }

            var rule = String.format(".infopage > h2 {font-family:{0};font-size:{1};color:{2};{3};{4} }",
                font1,
                app.entities.models.InfoPageSetting.attributes.Font1_Size,
                font1_color,
                font1_lineHeight[0].attributes.Style,
                font1_style[0].attributes.Style);
            style.insertRule(rule);
        }
        var cssRulesIdx = this.getRuleIndex(style, '.infopage > h3');
        if (cssRulesIdx != -1) {
            style.deleteRule(cssRulesIdx);

            var font2_style = app.entities.collections.FontStyle.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font2_Style
            });

            var font2_color = app.entities.models.InfoPageSetting.attributes.Font2_Color;
            /*switch (app.entities.models.InfoPageSetting.attributes.Font2_ColorType) {
                case "0": font2_color = app.entities.models.InfoPageSetting.attributes.Font2_ColorApp; break;
                case "1": font2_color = app.entities.models.InfoPageSetting.attributes.Font2_Color; break;
            }*/

            var font2_lineHeight = app.entities.collections.LineHeight.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font2_LineHeight
            });

            var font2 = app.entities.models.InfoPageSetting.attributes.Font2;
            switch (app.entities.models.InfoPageSetting.attributes.Font2_FontType) {
                case "0": font2 = app.entities.models.InfoPageSetting.attributes.Font2_App; break;
                case "1": font2 = app.entities.models.InfoPageSetting.attributes.Font2; break;
            }


            var rule = String.format(".infopage > h3 {font-family:{0};font-size:{1};color:{2};{3};{4} }",
                font2,
                app.entities.models.InfoPageSetting.attributes.Font2_Size,
                font2_color,
                font2_lineHeight[0].attributes.Style,
                font2_style[0].attributes.Style);
            style.insertRule(rule);
        }
        var cssRulesIdx = this.getRuleIndex(style, '.infopage > h4');
        if (cssRulesIdx != -1) {
            style.deleteRule(cssRulesIdx);

            var font3_style = app.entities.collections.FontStyle.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font3_Style
            });

            var font3_color = app.entities.models.InfoPageSetting.attributes.Font3_Color;
            /*switch (app.entities.models.InfoPageSetting.attributes.Font3_ColorType) {
                case "0": font3_color = app.entities.models.InfoPageSetting.attributes.Font3_ColorApp; break;
                case "1": font3_color = app.entities.models.InfoPageSetting.attributes.Font3_Color; break;
            }*/

            var font3_lineHeight = app.entities.collections.LineHeight.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font3_LineHeight
            });

            var font3 = app.entities.models.InfoPageSetting.attributes.Font3;
            switch (app.entities.models.InfoPageSetting.attributes.Font3_FontType) {
                case "0": font3 = app.entities.models.InfoPageSetting.attributes.Font3_App; break;
                case "1": font3 = app.entities.models.InfoPageSetting.attributes.Font3; break;
            }

            var rule = String.format(".infopage > h4 {font-family:{0};font-size:{1};color:{2};{3};{4} }",
                font3,
                app.entities.models.InfoPageSetting.attributes.Font3_Size,
                font3_color,
                font3_lineHeight[0].attributes.Style,
                font3_style[0].attributes.Style);
            style.insertRule(rule);
        }
        var cssRulesIdx = this.getRuleIndex(style, '.infopage > p');
        if (cssRulesIdx != -1) {
            style.deleteRule(cssRulesIdx);

            var font4_style = app.entities.collections.FontStyle.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font4_Style
            });

            var font4_color = app.entities.models.InfoPageSetting.attributes.Font4_Color;
            /*switch (app.entities.models.InfoPageSetting.attributes.Font4_ColorType) {
                case "0": font4_color = app.entities.models.InfoPageSetting.attributes.Font4_ColorApp; break;
                case "1": font4_color = app.entities.models.InfoPageSetting.attributes.Font4_Color; break;
            }*/

            var font4_lineHeight = app.entities.collections.LineHeight.where({
                Value: app.entities.models.InfoPageSetting.attributes.Font4_LineHeight
            });

            var font4 = app.entities.models.InfoPageSetting.attributes.Font4;
            switch (app.entities.models.InfoPageSetting.attributes.Font4_FontType) {
                case "0": font4 = app.entities.models.InfoPageSetting.attributes.Font4_App; break;
                case "1": font4 = app.entities.models.InfoPageSetting.attributes.Font4; break;
            }

            var rule = String.format(".infopage > p {font-family:{0};font-size:{1};color:{2};{3};{4} }",
                font4,
                app.entities.models.InfoPageSetting.attributes.Font4_Size,
                font4_color,
                font4_lineHeight[0].attributes.Style,
                font4_style[0].attributes.Style);
            style.insertRule(rule);
        }

        this.setupModels(app);
    },
    setupModels: function (app) {

    },
    getSelectedFont: function (app, id, font) {
        var ret;
        switch (id) {
            case "0": ret = app.entities.models.InfoPageSetting.attributes.Font1; break;
            case "1": ret = app.entities.models.InfoPageSetting.attributes.Font2; break;
            case "2": ret = app.entities.models.InfoPageSetting.attributes.Font3; break;
            case "3": ret = app.entities.models.InfoPageSetting.attributes.Font4; break;
            case "10": ret = font; break;
        }

        return ret;
    },
    getSelectedFontApp: function (app, id, font, fontApp) {
        var ret;
        switch (id) {
            case "0": ret = fontApp; break;
            case "10": ret = font; break;
        }

        return ret;
    },
    getSelectedColor: function (app, id, color) {
        var ret;
        switch (id) {
            case "0": ret = app.entities.models.InfoPageSetting.attributes.Font1_Color; break;
            case "1": ret = app.entities.models.InfoPageSetting.attributes.Font2_Color; break;
            case "2": ret = app.entities.models.InfoPageSetting.attributes.Font3_Color; break;
            case "3": ret = app.entities.models.InfoPageSetting.attributes.Font4_Color; break;
            case "10": ret = color; break;
        }

        return ret;
    },
    getSelectedColorApp: function (app, id, color, colorApp) {
        var ret;
        switch (id) {
            case "0": ret = colorApp; break;
            case "10": ret = color; break;
        }

        return ret;
    },
    getSelectedFont_enabled: function (id) {
        var ret;
        switch (id) {
            case "0": ret = false; break;
            case "1": ret = false; break;
            case "2": ret = false; break;
            case "3": ret = false; break;
            case "10": ret = true; break;
        }

        return ret;
    },
    getSelectedColor_enabled: function (id) {
        var ret;
        switch (id) {
            case "0": ret = false; break;
            case "1": ret = false; break;
            case "2": ret = false; break;
            case "3": ret = false; break;
            case "10": ret = true; break;
        }

        return ret;
    },
}

export default HelperInfopage;