var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetData_empty"> <div class="panel"> <form class="contact-form"> <h5 class="widget-title"><i class="fa fa-2x fa-table" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X101113'] ))==null?'':__t)+
'</h5> <div id="customProductSheetDataInformation"></div> <div class="form-group" id="customProductSheetData_divConfigSubscription"> <div class="control-group customProductSheetData_selectEvent"> <div class="row"> <div class="control-label col-md-6"> <label for="customProductSheetData_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" id="customProductSheetData_selectEvent"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div id="customFormForm"></div> <br> <h5 class="widget-title">'+
((__t=( resources['X101112'] ))==null?'':__t)+
'<i class="fa fa-list"></i></h5> <div id="customProductSheetDataList"></div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-12"> <button type="button" class="btn btn-secondary btnProductSheetData" id="customProductSheetData_btnDeleteAllSubmit">'+
((__t=( resources['X100376'] ))==null?'':__t)+
'</button> </div> </div> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-4"> <a class="btn btn-secondary btnProductSheetData" id="customProductSheetData_btnExport">'+
((__t=( resources['X100284'] ))==null?'':__t)+
'</a> </div> </div>  <div style="height:5px"></div> <div class="row"> </div> <h5 class="widget-title">'+
((__t=( resources['X100390'] ))==null?'':__t)+
'<i class="fa fa-list"></i></h5> <article class="topcontent"> <div id="customProductSheetDataInsert"></div> </article> <br> </div> <div style="height:10px"></div> </form> </div> </div>';
}
return __p;
};
