var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="empty"> <div id="container" class="container e4us-container"> <div class="row"> <div class="col-xs-12 col-md-12"> '+
((__t=( model.Header ))==null?'':__t)+
' </div> </div> <div class="row"> <div class="col-md-8"> <img id="QRCodeImageUrl" src="#modelbegin# QRCodeImageUrl #modelend#" alt=""> </div> <div class="col-md-4"> <h4 class="size-21" style="letter-spacing: 2px">#modelbegin# resources[\'X101228\'] #modelend#</h4> <p>#modelbegin# resources[\'X101229\'] #modelend#</p> </div> </div> <div class="row"> <div class="col-xs-12 col-md-12"> '+
((__t=( model.Footer ))==null?'':__t)+
' </div> </div> <div class="row" id="divEvents4us_header" style="display:none"> <div class="col-xs-12 col-md-12"> <hr> </div> </div> <div class="row" id="divEvents4us_1" style="display:none"> <div class="col-xs-12 col-md-12"> <p style="text-align:center">#modelbegin# CopyrightText01 #modelend#<strong><a href="#about">#modelbegin# CopyrightText02 #modelend#</a></strong>#modelbegin# CopyrightText03 #modelend#</p> </div> </div> <div class="row clearfix" id="divFacebook" style="display:none"> <div class="column full center"> <div style="margin:1em 0 2.5em"> <a id="btnFacebook" style="display:none" title="#modelbegin# Facebook_title #modelend#" class="btn btn-facebook"><i class="fab fa-facebook-f"></i> #modelbegin# Facebook_text #modelend#</a> <a id="btnEvents4us" style="display:none" title="#modelbegin# Events4us_title #modelend#" class="btn btn-events4us"><span class="events4us"></span> #modelbegin# Events4us_text #modelend#</a> </div> </div> </div> </div> </div>';
}
return __p;
};
