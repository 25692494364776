var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li><a href="#"><img src="'+
((__t=(model.InfoImage_ImageUrl ))==null?'':__t)+
'" class="'+
((__t=(model.Animation ))==null?'':__t)+
'" data-customerid="1"></a><div class="label_text"><p class="adSlide_p">'+
((__t=(model.Text ))==null?'':__t)+
'</p></div></li>';
}
return __p;
};
