var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="nav-item dropdown"> <a style="color:#007bff; cursor:pointer" class="nav-link dropdown-toggle d-flex justify-content-center align-items-center font-weight-medium" id="languageSelectorDropdown" href="#" data-toggle="dropdown"> '+
((__t=(model.Name ))==null?'':__t)+
' </a> <div id="languageSelectorList"> </div> </li>';
}
return __p;
};
