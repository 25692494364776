﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import config from '../../../../common/config';

import * as Entities_Memory_Position from '../../../entities/memoryposition';

import tpl_adSlideListItemProperties from './tpl/adSlideListItemProperties.html';

export default Marionette.LayoutView.extend({
    dialogRegion2: '#adSlideListItemProperties_modal',
    errors: [],
    getTemplate: function () {
        return tpl_adSlideListItemProperties({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    regions: {
    },
    onBeforeShow: function () {
    },
    ui: {
        'adSlideListItemProperties_group_Duration': '#adSlideListItemProperties_group_Duration',
        'adSlideListItemProperties_input_Duration': '#adSlideListItemProperties_input_Duration',
        'adSlideListItemProperties_label_Duration': '#adSlideListItemProperties_label_Duration',
        'adSlideListItemProperties_dropDown_Animation': '#adSlideListItemProperties_dropDown_Animation',
        'adSlideListItemProperties_input_Text': '#adSlideListItemProperties_input_Text',

        'adSlideListItemProperties_image': '#adSlideListItemProperties_image',
        'adSlideListItemProperties_btn_crop_zoom_plus': '#adSlideListItemProperties_btn_crop_zoom_plus',
        'adSlideListItemProperties_btn_crop_zoom_minus': '#adSlideListItemProperties_btn_crop_zoom_minus',
        'adSlideListItemProperties_btn_crop_move_left': '#adSlideListItemProperties_btn_crop_move_left',
        'adSlideListItemProperties_btn_crop_move_right': '#adSlideListItemProperties_btn_crop_move_right',
        'adSlideListItemProperties_btn_crop_move_top': '#adSlideListItemProperties_btn_crop_move_top',
        'adSlideListItemProperties_btn_crop_move_buttom': '#adSlideListItemProperties_btn_crop_move_buttom',
        'adSlideListItemProperties_btn_crop_rotate_left': '#adSlideListItemProperties_btn_crop_rotate_left',
        'adSlideListItemProperties_btn_crop_rotate_right': '#adSlideListItemProperties_btn_crop_rotate_right',
        'adSlideListItemProperties_btn_crop_scale_x': '#adSlideListItemProperties_btn_crop_scale_x',
        'adSlideListItemProperties_btn_crop_scale_y': '#adSlideListItemProperties_btn_crop_scale_y',
        'adSlideListItemProperties_btnSubmit': '#adSlideListItemProperties_btnSubmit',

        'adSlideListItemProperties_btn01': '#adSlideListItemProperties_btn01',
        'adSlideListItemProperties_btn02': '#adSlideListItemProperties_btn02'
    },
    events: {
        'click @ui.adSlideListItemProperties_btn01': 'onBtn01',
        'click @ui.adSlideListItemProperties_btn02': 'onBtn02',

        'click @ui.adSlideListItemProperties_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.adSlideListItemProperties_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.adSlideListItemProperties_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.adSlideListItemProperties_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.adSlideListItemProperties_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.adSlideListItemProperties_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.adSlideListItemProperties_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.adSlideListItemProperties_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.adSlideListItemProperties_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.adSlideListItemProperties_btn_crop_scale_y': 'btnScaleYClick',
        'click @ui.adSlideListItemProperties_btnSubmit': 'btnSetImage',
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;

        this.ui.adSlideListItemProperties_input_Duration.durationPicker({
            showSeconds: true,
            onChanged: function (newVal) {
                self.ui.adSlideListItemProperties_label_Duration.text(newVal);
                self.model.set({
                    Duration: newVal
                });
            }
        });

        this.setupAnimation();

        $('#adSlideListItemProperties_group_Duration').find('.input-sm').width(90);
        //this.ui.adSlideListItemProperties_group_Duration.find('.input-sm').css('width', '90px;');

        this.setupCropper();
    },
    setupAnimation: function () {
        var self = this;

        this.ui.adSlideListItemProperties_dropDown_Animation.empty();

        var selected = '';

        self.app.entities.collections.PictureSlideAnimation.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Animation == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.adSlideListItemProperties_dropDown_Animation.append(options);
        });
    },
    setupDuration: function () {
        var self = this;

        this.ui.adSlideListItemProperties_dropDown_Duration.empty();

        var selected = '';

        self.app.entities.collections.AdSlideDuration.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Duration == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.adSlideListItemProperties_dropDown_Duration.append(options);
        });
    },
    setupCropper: function () {
        this.ui.adSlideListItemProperties_image.attr('src', this.model.attributes.InfoImage_ImageUrl);

        //var previews = document.querySelectorAll('.cropper-img-preview');
        var imageDefault = document.querySelector('#adSlideListItemProperties_image');

        var cropBoxData;
        var canvasData;


        this.cropper = new Cropper(imageDefault, {
            aspectRatio: 1 / 1,
            minContainerWidth: 300,
            minContainerHeight: 300,
            autoCropArea: 0.8,
            zoomOnTouch: false,
            zoomOnWheel: false,
            cropBoxResizable: true,
        });
    },
    getImage: function (width, height) {
        if (this.cropper) {
            var cropBoxData = this.cropper.getCropBoxData();
            var croppedCanvas;
            var canvas;

            croppedCanvas = this.cropper.getCroppedCanvas();
            canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

            return canvas.toDataURL();
        }
    },
    fillup: function (text) {
        if (text.length <= 1) {
            text = '0' + text;
        }
        return text;
    },
    UUID: function () {
        var lut = [];
        for (var i = 0; i < 256; i++) {
            lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
        }

        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;

        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    },
    saveImage: function (callback, imageData, imageMime) {
        var nowLocalDate = new Date();

        var strLocalDate = nowLocalDate.getFullYear().toString() +
            this.fillup((nowLocalDate.getMonth() + 1).toString()) +
            this.fillup(nowLocalDate.getDate().toString()) +
            this.fillup(nowLocalDate.getHours().toString()) +
            this.fillup(nowLocalDate.getMinutes().toString()) +
            this.fillup(nowLocalDate.getSeconds().toString()) +
            nowLocalDate.getMilliseconds().toString();


        var filename = strLocalDate + " - " + "Test";
        var sizeType = 1;
        var sizeText = "750X750";
        var sortType = 1;
        var uuid = this.UUID();

        var changeDate = new Date();
        var strUtcDate = changeDate.getUTCFullYear().toString() + '-' +
            (changeDate.getUTCMonth() + 1) + '-' +
            changeDate.getUTCDate().toString() + ' ' +
            changeDate.getUTCHours() + ':' +
            changeDate.getUTCMinutes() + ':' +
            changeDate.getUTCSeconds() + '.';



        var tmpModel = new this.app.entities.collections.customInfoImage.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = filename;
        tmpModel.attributes.ImageData = imageData;
        tmpModel.attributes.ImageMimeType = imageMime;
        tmpModel.attributes.ImageUpdate = true;
        tmpModel.attributes.SizeType = sizeType;
        tmpModel.attributes.SizeText = sizeText;
        tmpModel.attributes.SortType = sortType;
        tmpModel.attributes.CommonId = uuid;
        tmpModel.attributes.UtcDate = strUtcDate;
        tmpModel.attributes.Filename = filename + '.png';

        this.app.entities.collections.customInfoImage.create(tmpModel, {
            wait: true,
            success: function (response) {
                callback(response);
            }
        });

    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    },
    btnSetImage: function (event) {
        event.preventDefault();

        var self = this;

        var base64 = self.getImage(750, 750);
        var searchString = ";base64,";
        var pos = base64.indexOf(searchString);
        var imageMime = base64.substring(5, pos);
        var imageData = base64.substring(pos + searchString.length, base64.length);

        var callbackSaveImage = function (model) {
            var oldInfoImage = self.model.attributes.InfoImage_id;
            self.model.attributes.id = model.attributes.id;
            self.model.attributes.InfoImage_id = model.attributes.id;
            self.model.attributes.InfoImage_ImageUrl = model.attributes.ImageUrl;
            self.model.attributes.InfoImage_Name = model.attributes.Name;

            /*var oldInfoImage = self.model.attributes.id;

            self.model.attributes.ImageUrl = model.attributes.ImageUrl;
            self.model.attributes.CommonId = model.attributes.CommonId;
            self.model.attributes.id = model.attributes.id;*/

            //Remove old model in both this collection and CustomImageInfo
            var customInfoImageModel = self.app.entities.collections.customInfoImage.where({ id: oldInfoImage })[0];
            if (customInfoImageModel != undefined) {
                customInfoImageModel.destroy({
                    wait: true,
                    success: function (model, response) {
                    },
                    error: function (model, response) {
                    }
                });
            }
        }

        self.saveImage(callbackSaveImage, imageData, imageMime);
    },
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    setModel: function () {
        this.model.set({
            Animation: this.ui.adSlideListItemProperties_dropDown_Animation.val(),
            Text: this.ui.adSlideListItemProperties_input_Text.val()
        });
    },
    setupCssStyle: function () {
    }
});