﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FilePond from 'filepond';

import tpl_htmlSingle from './tpl/htmlSingle.html';

export default Marionette.LayoutView.extend({
    dialogRegion: '#htmlSingle_modal',
    getTemplate: function () {
        return tpl_htmlSingle({ model: this.model.toJSON() });
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        var userChannel = Backbone.Radio.channel('app');
    },
    regions: {
    },
    ui: {
        'htmlSingle_group_Duration': '#htmlSingle_group_Duration',
        'htmlSingle_input_Duration': '#htmlSingle_input_Duration',
        'htmlSingle_label_Duration': '#htmlSingle_label_Duration',
        'htmlSingle_dropDown_AnimationGroup': '#htmlSingle_dropDown_AnimationGroup',
        'htmlSingle_dropDown_Animation': '#htmlSingle_dropDown_Animation',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    onBeforeShow: function () {
       
    },
    onAttach() {
    },
    onRender: function () {
        
    },
    onAttach() {
        var self = this;

        this.ui.htmlSingle_input_Duration.durationPicker({
            showSeconds: true,
            onChanged: function (newVal) {
                self.ui.htmlSingle_label_Duration.text(newVal);
                self.model.set({
                    Duration: newVal
                });
            }
        });

        this.setupAnimationGroup();
        this.setupAnimation();

        $('#htmlSingle_group_Duration').find('.input-sm').width(90);
    },
    selectionChangeAnimationGroup: function () {
        this.model.attributes.AnimationGroup = this.ui.htmlSingle_dropDown_AnimationGroup.val();
        this.setupAnimation();
    },
    setupAnimationGroup: function () {
        var self = this;

        this.ui.htmlSingle_dropDown_AnimationGroup.empty();

        var selected = '';

        self.app.entities.collections.HtmlSlideAnimationGroup.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.AnimationGroup == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.htmlSingle_dropDown_AnimationGroup.append(options);
        });
    },
    setupAnimation: function () {
        var self = this;

        var found = false;

        this.ui.htmlSingle_dropDown_Animation.empty();

        var selected = '';

        self.app.entities.collections.HtmlSlideAnimation.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Animation == entry.attributes.Value) {
                selected = 'selected = "selected"';
                found = true;
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            if (entry.attributes.Group == self.model.attributes.AnimationGroup) {
                self.ui.htmlSingle_dropDown_Animation.append(options);
            }
        });

        if (!found) {
            self.ui.htmlSingle_dropDown_Animation.selectedIndex = 0;
        }

    },
    setupDuration: function () {
        var self = this;

        this.ui.htmlSingle_dropDown_Duration.empty();

        var selected = '';

        self.app.entities.collections.PictureSlideDuration.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Duration == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.htmlSingle_dropDown_Duration.append(options);
        });
    },
    events: {
        'change @ui.htmlSingle_dropDown_AnimationGroup': 'selectionChangeAnimationGroup',
        "click #dialog2Button_btn01": "onBtn01",
        "click #dialog2Button_btn02": "onBtn02",
        "click #dialog2Button_btn03": "onBtn03"
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    setModel: function () {
        this.model.set({
            AnimationGroup: this.ui.htmlSingle_dropDown_AnimationGroup.val(),
            Animation: this.ui.htmlSingle_dropDown_Animation.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    },
}, Backbone.Radio.Requests);
