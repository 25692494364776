var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="information_empty"> <div class="alert alert-warning" id="information_content"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> <strong>'+
((__t=( resources['X100230'] ))==null?'':__t)+
'</strong> <div id="informationList"> </div> </div> </div>';
}
return __p;
};
