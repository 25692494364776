﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tinycolor from 'tinycolor';

import View_DialogWaiting from '../../lib/views/dialogWaiting/dialogWaiting';

import tpl_eventImagePrintTemplate from '../tplRender/eventImagePrintTemplate.html';
import tpl_eventTemplate from '../tplRender/eventTemplate.html';
import tpl_eventSchemaTemplate from '../tplRender/eventSchemaTemplate.html';
import tpl_eventProductSheetTemplate from '../tplRender/eventProductSheetTemplate.html';
import tpl_eventSchemaBigscreenTemplate from '../tplRender/eventSchemaBigscreenTemplate.html';
import tpl_eventFormTemplate from '../tplRender/eventFormTemplate.html';
import tpl_eventInfoTemplate from '../tplRender/eventInfoTemplate.html';
import tpl_eventPosterTemplate from '../tplRender/eventPosterTemplate.html';
import tpl_eventQRTemplate from '../tplRender/eventQRTemplate.html';
import tpl_eventLocationTemplate from '../tplRender/eventLocationTemplate.html';
import tpl_eventCalendarTemplate from '../tplRender/eventCalendarTemplate.html';

import tpl_eventIndexTemplate from '../tplRender/eventIndexTemplate.html';
import tpl_eventIndexImagePrintTemplate from '../tplRender/eventIndexImagePrintTemplate.html';

import * as Entities_Event from '../entities/event';
import * as Entities_Empty from '../../lib/entities/empty';
import * as Entities_AzureEvent from '../entities/azureEvent';
import * as Entities_Page2Image from '../entities/page2image';
import * as Entities_QRCode from '../entities/qrcode';

import * as Entities_Header from '../../event/entities/header';

import helper_createWebpage from './createWebpage';


import config from '../../common/config';
import renderError from './renderError';

var HelperCreateEvent = {
    getStandardModel: function (app, lat, lng, name) {
        var tmpModel = new app.entities.collections.customEvent.fullCollection.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Version = app.entities.models.customEventVersion.attributes.Value;
        tmpModel.attributes.Name = name;
        tmpModel.attributes.Description = '';
        tmpModel.attributes.EventName = tmpModel.attributes.Name;
        tmpModel.attributes.FriendlyUrl = '';
        tmpModel.attributes.Information = '';
        //tmpModel.attributes.Webpage = -1;
        tmpModel.attributes.Infopage = -1;
        //tmpModel.attributes.SchemaModel = -1;
        tmpModel.attributes.SchemaMenuName = resources.X100101;
        //tmpModel.attributes.FormModel = -1;
        tmpModel.attributes.FormMenuName = resources.X100103;
        tmpModel.attributes.FormSubmitBtnName = resources.X100104;
        tmpModel.attributes.FormCancelBtnName = resources.X100392;
        tmpModel.attributes.FormSubmitBtnSucces = resources.X100105;
        tmpModel.attributes.FormNotifyEmail = 0;
        tmpModel.attributes.FormNotifyEmailAddress = '';

        //tmpModel.attributes.ProductSheetModel = -1;
        tmpModel.attributes.ProductSheetMenuName = resources.X101118;
        tmpModel.attributes.ProductSheetPreviusBtnName = resources.X101119;
        tmpModel.attributes.ProductSheetNextBtnName = resources.X101120;

        //tmpModel.attributes.SchemaBigscreenShowHeader = false;
        //tmpModel.attributes.SchemaBigscreenShowSearchBar = false;
        //tmpModel.attributes.SchemaBigscreenQueryField = 0;
        //tmpModel.attributes.SchemaBigscreenQueryValue = '';

        tmpModel.attributes.Publicly = 0;
        tmpModel.attributes.Facebook = 0;

        tmpModel.attributes.Copyright = 1;
        tmpModel.attributes.InfopageShowQRCode = 1;
        tmpModel.attributes.SchemaBigScreenShowQRCode = 1;

        tmpModel.attributes.QRCodeTextPoster = resources.X100067;
        tmpModel.attributes.QRCodeTextInfoscreen = resources.X100073;
        tmpModel.attributes.QRCodeTextBigSchemaScreen = resources.X100073;
        tmpModel.attributes.QRCodeTextQRCode = resources.X100073;
        tmpModel.attributes.QRCodeFontSizePoster = 13;
        tmpModel.attributes.QRCodeFontSizeInfoscreen = 16;
        tmpModel.attributes.QRCodeFontSizeBigSchemaScreen = 16;
        tmpModel.attributes.QRCodeFontSizeQRCode = 16;
        tmpModel.attributes.AdEnable = 0;
        tmpModel.attributes.AdStatus = 0;
        tmpModel.attributes.AdImageId = -1;
        tmpModel.attributes.AdImageUrl = '';

        tmpModel.attributes.AdBigEnable = 0;
        tmpModel.attributes.AdBigStatus = 0;
        tmpModel.attributes.AdBigImageId = -1;
        tmpModel.attributes.AdBigImageUrl = '';

        tmpModel.attributes.AdCategory01 = resources.X100963;
        tmpModel.attributes.AdCategory02 = resources.X100963;
        tmpModel.attributes.AdCategory03 = resources.X100963;
        tmpModel.attributes.AdBigCategory01 = resources.X100963;
        tmpModel.attributes.AdBigCategory02 = resources.X100963;
        tmpModel.attributes.AdBigCategory03 = resources.X100963;

        tmpModel.attributes.Category01 = resources.X100963;
        tmpModel.attributes.Category02 = resources.X100963;
        tmpModel.attributes.Category03 = resources.X100963;

        tmpModel.attributes.AdminDisable = false;
        tmpModel.attributes.AdminDisableReason = 0;
        tmpModel.attributes.AdminEnableDelete = true;
        tmpModel.attributes.AdminInformation = '';

        tmpModel.attributes.ShowInfo = app.entities.models.configSubscription[0].attributes.EnableInfo,
            tmpModel.attributes.ShowPoster = app.entities.models.configSubscription[0].attributes.EnablePoster,
            //tmpModel.attributes.PubliclyAddress = '';
            //tmpModel.attributes.PubliclyLatitude = 0;
            //tmpModel.attributes.PubliclyLongitude = 0;
            tmpModel.attributes.Address_lat = lat;
        tmpModel.attributes.Address_lng = lng;

        tmpModel.attributes.HelpBtn = true;
        tmpModel.attributes.HomeBtn = false;

        tmpModel.attributes.Module_QRCode = 1;
        tmpModel.attributes.Module_Facebook = 1;
        tmpModel.attributes.Module_SchemaModel = 0;
        tmpModel.attributes.Module_FormModel = 0;
        tmpModel.attributes.Module_ProductSheetModel = 0;
        tmpModel.attributes.Module_Ad = 0;

        tmpModel.attributes.Module_Ad = 0;

        tmpModel.attributes.Module_SchemaScreen_Direction_H = 1;
        tmpModel.attributes.Module_SchemaScreen_Direction_V = 0;
        tmpModel.attributes.Module_InfoScreen_Direction_H = 1;
        tmpModel.attributes.Module_InfoScreen_Direction_V = 0;
        tmpModel.attributes.Module_Poster_Direction_H = 1;
        tmpModel.attributes.Module_Poster_Direction_V = 0;
        tmpModel.attributes.Module_CityInfo_Direction_H = 0;
        tmpModel.attributes.Module_CityInfo_Direction_V = 1;


        //    tmpModel.attributes.JsonFileData = JSON.stringify(response);

        tmpModel.attributes.Address = app.entities.models.custom.attributes.Address_country;
        tmpModel.attributes.Address_country = app.entities.models.custom.attributes.Address_country;

        var startDate = new Date();

        var strUtcDate = startDate.getUTCFullYear().toString() + '-' +
            (startDate.getUTCMonth() + 1) + '-' +
            startDate.getUTCDate().toString() + ' ' +
            startDate.getUTCHours() + ':' +
            startDate.getUTCMinutes() + ':' +
            startDate.getUTCSeconds();

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(1);

        var strUtcStartDate = startDate.getUTCFullYear().toString() + '-' +
            (startDate.getUTCMonth() + 1) + '-' +
            startDate.getUTCDate().toString() + ' ' +
            startDate.getUTCHours().toString() + ':' +
            startDate.getUTCMinutes().toString() + ':' +
            startDate.getUTCSeconds().toString();


        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 7);

        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        var strUtcEndDate = endDate.getUTCFullYear().toString() + '-' +
            (endDate.getUTCMonth() + 1) + '-' +
            endDate.getUTCDate().toString() + ' ' +
            endDate.getUTCHours().toString() + ':' +
            endDate.getUTCMinutes().toString() + ':' +
            endDate.getUTCSeconds().toString();

        tmpModel.attributes.UtcDate = strUtcDate;
        tmpModel.attributes.UseDate = 0;
        tmpModel.attributes.UtcStartDate = strUtcStartDate;
        tmpModel.attributes.UtcEndDate = strUtcEndDate;

        return tmpModel;
    },
    createEventFromTemplate: function (app, parent, lat, lng, name, callbackDone) {
        var self = parent;

        var tmpModel = HelperCreateEvent.getStandardModel(app, lat, lng, name);

        app.entities.collections.customEvent.fullCollection.create(tmpModel, {
            wait: true,
            success: function (response) {
                tmpModel.attributes.id = response.attributes.id;

                callbackDone(tmpModel.attributes.id);
            }
        });
    },
    createEventDefault : function (app, parent, lat, lng, name, callbackDone) {
        var self = parent;

        var tmpModel = HelperCreateEvent.getStandardModel(app, lat, lng, name);    

        var callback = function () {
            app.entities.collections.customEvent.fullCollection.create(tmpModel, {
                wait: true,
                success: function (response) {
                    tmpModel.attributes.id = response.attributes.id;

                    var callbackModuleSetting = function () {
                        HelperCreateEvent.MakeAllModule(app, tmpModel, callbackDone);
                    }

                    HelperCreateEvent.MakeAllModuleSetting(app, tmpModel, callbackModuleSetting);
                }
            });
        }

        HelperCreateEvent.makeAll(app, name, tmpModel, callback);

    },
    makeAll: function (app, name, model, callback) {
        var self = this;

        var callback01 = function () {
            var tmpSchemaModel = HelperCreateEvent.makeSchemaModel(app, name);

            app.entities.collections.customSchemaModel.fullCollection.create(tmpSchemaModel, {
                wait: true,
                success: function (response) {
                    model.attributes.SchemaModel = response.attributes.id;
                    callback02();
                },
                error: function (mode, response) {
                    model.attributes.SchemaModel = -1;
                    callback02();
                }
            });
        }

        var callback02 = function () {
            var tmpFormModel = HelperCreateEvent.makeFormModel(app, name);

            app.entities.collections.customFormModel.fullCollection.create(tmpFormModel, {
                wait: true,
                success: function (response) {
                    model.attributes.FormModel = response.attributes.id;
                    callback03();
                },
                error: function (mode, response) {
                    model.attributes.FormModel = -1;
                    callback03();
                }
            });

        }

        var callback03 = function () {
            var tmpProductSheetModel = HelperCreateEvent.makeProductSheetModel(app, name);

            app.entities.collections.customProductSheetModel.fullCollection.create(tmpProductSheetModel, {
                wait: true,
                success: function (response) {
                    model.attributes.ProductSheetModel = response.attributes.id;
                    callback04();
                },
                error: function (mode, response) {
                    model.attributes.ProductSheetModel = -1;
                    callback04();
                }
            });

        }

        var callback04 = function () {

            var callbackQRCode = function (tmpQRCode) {
                app.entities.collections.customQRCode.fullCollection.create(tmpQRCode, {
                    wait: true,
                    success: function (response) {
                        model.attributes.QRCode = response.attributes.id;
                        callback05();
                    },
                    error: function (mode, response) {
                        model.attributes.QRCode = -1;
                        callback05();
                    }
                });
            }

            HelperCreateEvent.makeQRCodeModel(app, name, callbackQRCode);

        }
        var callback05 = function () {
            var tmpWebpage = self.makeWebpage(app, name);

            app.entities.collections.customWebpage.fullCollection.create(tmpWebpage, {
                wait: true,
                success: function (response) {
                    model.attributes.Webpage = response.attributes.id;
                    callback06();
                },
                error: function (mode, response) {
                    model.attributes.Webpage = -1;
                    callback06();
                }
            });

        }

        var callback06 = function () {
            app.entities.collections.customWebpageItem.fetch({
                silent: true,
                wait: true,
                cache: false,
                success: function (mode, response) {
                    callback();
                },
                error: function (mode, response) {
                    callback();
                }
            });
        }

        callback01();
    },
    MakeAllModule: function (app, model, callback) {
        var self = this;

        var callback01 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 1);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback02();
                },
                error: function (mode, response) {
                    callback02();
                }
            });
        }

        var callback02 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 2);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback03();
                },
                error: function (mode, response) {
                    callback03();
                }
            });
        }

        var callback03 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 3);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback04();
                },
                error: function (mode, response) {
                    callback04();
                }
            });
        }

        var callback04 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 4);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback05();
                },
                error: function (mode, response) {
                    callback05();
                }
            });
        }

        var callback05 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 5);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback06();
                },
                error: function (mode, response) {
                    callback06();
                }
            });
        }

        var callback06 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 6);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback07();
                },
                error: function (mode, response) {
                    callback07();
                }
            });
        }

        var callback07 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 7);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback08();
                },
                error: function (mode, response) {
                    callback08();
                }
            });
        }

        var callback08 = function () {
            var tmpModule = HelperCreateEvent.makeModule(app, model.attributes.id, 8);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback09();
                },
                error: function (mode, response) {
                    callback09();
                }
            });
        }

        var callback09 = function () {
            var tmpModule = HelperCreateEvent.makeEventLive(app, model.attributes.id);

            app.entities.collections.customEventLive.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback(model.attributes.id);
                },
                error: function (mode, response) {
                    callback(model.attributes.id);
                }
            });
        }

        callback01();
    },
    MakeAllModuleSetting: function (app, model, callback) {
        var self = this;

        var callback01 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 11);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback02();
                },
                error: function (mode, response) {
                    callback02();
                }
            });
        }

        var callback02 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 12);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback03();
                },
                error: function (mode, response) {
                    callback03();
                }
            });
        }

        var callback03 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 13);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback04();
                },
                error: function (mode, response) {
                    callback04();
                }
            });
        }

        var callback04 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 14);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback05();
                },
                error: function (mode, response) {
                    callback05();
                }
            });
        }

        var callback05 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 15);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback06();
                },
                error: function (mode, response) {
                    callback06();
                }
            });
        }

        var callback06 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 16);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback07();
                },
                error: function (mode, response) {
                    callback07();
                }
            });
        }

        var callback07 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 17);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback08();
                },
                error: function (mode, response) {
                    callback08();
                }
            });
        }

        var callback08 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 18);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback09();
                },
                error: function (mode, response) {
                    callback09();
                }
            });
        }

        var callback09 = function () {
            var tmpModule = HelperCreateEvent.makeModuleSetting(app, model.attributes.id, 100);

            app.entities.collections.customModule.create(tmpModule, {
                wait: true,
                success: function (response) {
                    callback();
                },
                error: function (mode, response) {
                    callback();
                }
            });
        }

        callback01();
    },
    makeWebpage: function (app, name) {
        var tmpModel = new app.entities.collections.customWebpage.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = name;

        tmpModel.attributes.DisplayType = 0;
        tmpModel.attributes.CustomWebpage_Header = resources.X100137;
        tmpModel.attributes.CustomWebpage_BodyIndex = resources.X100138;
        tmpModel.attributes.CustomWebpage_Footer = resources.X100139;
        tmpModel.attributes.CustomWebpage_Info = resources.X100140;
        tmpModel.attributes.CustomWebpage_Poster = resources.X100141;
        tmpModel.attributes.CustomWebpage_ImagePrint = resources.X100142;
        tmpModel.attributes.CustomWebpage_Schema = resources.X100143;
        tmpModel.attributes.CustomWebpage_SchemaBigscreen = resources.X100506;
        tmpModel.attributes.CustomWebpage_ProductSheet = resources.X101138;
        tmpModel.attributes.CustomWebpage_QR = resources.X101230;
        tmpModel.attributes.CustomWebpage_Location = resources.X101231;
        tmpModel.attributes.CustomWebpage_Calendar = resources.X101234;
        tmpModel.attributes.CustomWebpage_Form = resources.X100144;

        var themeModel = app.entities.collections.paintStrap.fullCollection.where({ Default: true });

        if (themeModel.length > 0) {
            tmpModel.attributes.Theme = themeModel[0].attributes.id;
            tmpModel.attributes.CssFile = themeModel[0].attributes.CssFile;
            tmpModel.attributes.CssUpdate = false;
        }

        return tmpModel;
    },
    makeModule: function (app, sourceId, moduleType) {
        var defaultTemplateModel = app.entities.collections.DefaultTemplate.where({ ModuleType: moduleType })[0];
        var collection = new Backbone.Collection(defaultTemplateModel.attributes.Collection);
        var strJson = JSON.stringify(collection.toJSON());

        var tmpModel = new app.entities.collections.customModule.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.EventId = sourceId;
        tmpModel.attributes.ModuleType = moduleType;
        tmpModel.attributes.Data = '[{ "Collection" : ' + strJson + '}]';

        return tmpModel;
    },
    makeModuleSetting: function (app, sourceId, moduleType) {
        var defaultTemplateModel = app.entities.collections.DefaultTemplate.where({ ModuleType: moduleType })[0];
        var Data = new Backbone.Model(defaultTemplateModel.attributes.Data);
        var strJson = JSON.stringify(Data.toJSON());

        var tmpModel = new app.entities.collections.customModule.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.EventId = sourceId;
        tmpModel.attributes.ModuleType = moduleType;
        tmpModel.attributes.Data = '[{ "Data" : ' + strJson + '}]';

        return tmpModel;
    },
    makeEventLive: function (app, sourceId) {
        var tmpModel = new app.entities.collections.customEventLive.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.EventId = sourceId;
        tmpModel.attributes.SchemaBigscreenShowHeader = false;
        tmpModel.attributes.SchemaBigscreenShowSearchBar = false;
        tmpModel.attributes.SchemaBigscreenQueryField = -1;
        tmpModel.attributes.SchemaBigscreenQueryValue = '';
        tmpModel.attributes.SchemaBigscreenRefreshInterval = 15;
        tmpModel.attributes.SchemaBigscreenQuerySortField = -1;
        tmpModel.attributes.SchemaBigscreenQuerySortValue = 0;

        tmpModel.attributes.SchemaShowHeader = true;
        tmpModel.attributes.SchemaShowSearchBar = true;
        tmpModel.attributes.SchemaQueryField = -1;
        tmpModel.attributes.SchemaQueryValue = '';
        tmpModel.attributes.SchemaRefreshInterval = 0;
        tmpModel.attributes.SchemaQuerySortField = -1;
        tmpModel.attributes.SchemaQuerySortValue = 0;

        return tmpModel;
    },
    makeQRCodeModel: function (app, name, callback) {
        var self = this;
        var tmpModel = new app.entities.collections.customQRCode.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = name;

        tmpModel.attributes.Render = "canvas";
        tmpModel.attributes.EcLevel = "H";
        tmpModel.attributes.MinVersion = 6;
        tmpModel.attributes.Fill = "Rgb(0,0,0)";
        tmpModel.attributes.Background = "Rgb(255,255,255)";
        tmpModel.attributes.Text = resources.X100068;
        tmpModel.attributes.Size = 300;
        tmpModel.attributes.Radius = 0;
        tmpModel.attributes.Quiet = 1;
        tmpModel.attributes.Mode = 3;
        tmpModel.attributes.MSize = 11;
        tmpModel.attributes.MPosX = 50;
        tmpModel.attributes.MPosY = 50;
        tmpModel.attributes.Label = "Events4us";
        tmpModel.attributes.FontName = "Arial,Arial,Helvetica,sans-serif";
        tmpModel.attributes.FontColor = "Rgb(255,0,0)";
        tmpModel.attributes.FontStyle = 0;
        tmpModel.attributes.FontSize = "30px";
        tmpModel.attributes.Logo = "/images/Events4us.png";

        $('#customQRCodeInsert_qrCodeLogo').load(function () {
            var tmpModelQRCode = new Entities_Empty.Model();
            //tmpModelQRCode.attributes.id = -1;

            tmpModelQRCode = helper_createWebpage.createQRCodeImage(tmpModel, tmpModelQRCode, $('#customQRCodeInsert_qrCodeLogo'), $('#customQRCodeInsert_qrCodeContainer'), false, app);

            tmpModel.attributes.ImageData = tmpModelQRCode.attributes.ImageData;
            tmpModel.attributes.ImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
            tmpModel.attributes.ImageUpdate = tmpModelQRCode.attributes.ImageUpdate;
            tmpModel.attributes.Filename = tmpModel.attributes.Name + '_' + new Date().getTime() + '.jpg';

            $('#customQRCodeInsert_container').empty();
            $('#customQRCodeInsert_qrCodeLogo').off("load");

            callback(tmpModel);
        });
        $('#customQRCodeInsert_qrCodeLogo').attr("src", tmpModel.attributes.Logo);

        //return tmpModel;
    },
    makeProductSheetModel: function (app, name) {
        var tmpModel = new app.entities.collections.customProductSheetModel.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = name;

        tmpModel.attributes.FieldUsed = 30;

        tmpModel.attributes.PositionWebpage = 1;
        tmpModel.attributes.PositionProductName = 2;
        tmpModel.attributes.PositionImage = 3;
        tmpModel.attributes.PositionFeature = 4;
        tmpModel.attributes.PositionDescription = 5;
        tmpModel.attributes.PositionSchema = 6;

        tmpModel.attributes.FontSizeProductName = 10;
        tmpModel.attributes.FontSizeImage = 11;
        tmpModel.attributes.FontSizeFeature = 12;
        tmpModel.attributes.FontSizeDescription = 13;
        tmpModel.attributes.FontSizeSchema = 14;

        tmpModel.attributes.Field01 = '';
        tmpModel.attributes.Field02 = '';
        tmpModel.attributes.Field03 = '';
        tmpModel.attributes.Field04 = '';
        tmpModel.attributes.Field05 = '';
        tmpModel.attributes.Field06 = '';
        tmpModel.attributes.Field07 = '';
        tmpModel.attributes.Field08 = '';
        tmpModel.attributes.Field09 = '';
        tmpModel.attributes.Field10 = '';
        tmpModel.attributes.Field11 = '';
        tmpModel.attributes.Field12 = '';
        tmpModel.attributes.Field13 = '';
        tmpModel.attributes.Field14 = '';
        tmpModel.attributes.Field15 = '';
        tmpModel.attributes.Field16 = '';
        tmpModel.attributes.Field17 = '';
        tmpModel.attributes.Field18 = '';
        tmpModel.attributes.Field19 = '';
        tmpModel.attributes.Field20 = '';
        tmpModel.attributes.Field21 = '';
        tmpModel.attributes.Field22 = '';
        tmpModel.attributes.Field23 = '';
        tmpModel.attributes.Field24 = '';
        tmpModel.attributes.Field25 = '';
        tmpModel.attributes.Field26 = '';
        tmpModel.attributes.Field27 = '';
        tmpModel.attributes.Field28 = '';
        tmpModel.attributes.Field29 = '';
        tmpModel.attributes.Field30 = '';

        tmpModel.attributes.FieldType01 = 1;
        tmpModel.attributes.FieldType02 = 1;
        tmpModel.attributes.FieldType03 = 1;
        tmpModel.attributes.FieldType04 = 1;
        tmpModel.attributes.FieldType05 = 1;
        tmpModel.attributes.FieldType06 = 1;
        tmpModel.attributes.FieldType07 = 1;
        tmpModel.attributes.FieldType08 = 1;
        tmpModel.attributes.FieldType09 = 1;
        tmpModel.attributes.FieldType10 = 1;
        tmpModel.attributes.FieldType11 = 1;
        tmpModel.attributes.FieldType12 = 1;
        tmpModel.attributes.FieldType13 = 1;
        tmpModel.attributes.FieldType14 = 1;
        tmpModel.attributes.FieldType15 = 1;
        tmpModel.attributes.FieldType16 = 1;
        tmpModel.attributes.FieldType17 = 1;
        tmpModel.attributes.FieldType18 = 1;
        tmpModel.attributes.FieldType19 = 1;
        tmpModel.attributes.FieldType20 = 1;
        tmpModel.attributes.FieldType21 = 1;
        tmpModel.attributes.FieldType22 = 1;
        tmpModel.attributes.FieldType23 = 1;
        tmpModel.attributes.FieldType24 = 1;
        tmpModel.attributes.FieldType25 = 1;
        tmpModel.attributes.FieldType26 = 1;
        tmpModel.attributes.FieldType27 = 1;
        tmpModel.attributes.FieldType28 = 1;
        tmpModel.attributes.FieldType29 = 1;
        tmpModel.attributes.FieldType30 = 1;

        tmpModel.attributes.FieldValue01 = '';
        tmpModel.attributes.FieldValue02 = '';
        tmpModel.attributes.FieldValue03 = '';
        tmpModel.attributes.FieldValue04 = '';
        tmpModel.attributes.FieldValue05 = '';
        tmpModel.attributes.FieldValue06 = '';
        tmpModel.attributes.FieldValue07 = '';
        tmpModel.attributes.FieldValue08 = '';
        tmpModel.attributes.FieldValue09 = '';
        tmpModel.attributes.FieldValue10 = '';
        tmpModel.attributes.FieldValue11 = '';
        tmpModel.attributes.FieldValue12 = '';
        tmpModel.attributes.FieldValue13 = '';
        tmpModel.attributes.FieldValue14 = '';
        tmpModel.attributes.FieldValue15 = '';
        tmpModel.attributes.FieldValue16 = '';
        tmpModel.attributes.FieldValue17 = '';
        tmpModel.attributes.FieldValue18 = '';
        tmpModel.attributes.FieldValue19 = '';
        tmpModel.attributes.FieldValue20 = '';
        tmpModel.attributes.FieldValue21 = '';
        tmpModel.attributes.FieldValue22 = '';
        tmpModel.attributes.FieldValue23 = '';
        tmpModel.attributes.FieldValue24 = '';
        tmpModel.attributes.FieldValue25 = '';
        tmpModel.attributes.FieldValue26 = '';
        tmpModel.attributes.FieldValue27 = '';
        tmpModel.attributes.FieldValue28 = '';
        tmpModel.attributes.FieldValue29 = '';
        tmpModel.attributes.FieldValue30 = '';

        tmpModel.attributes.FieldSubject01 = '';
        tmpModel.attributes.FieldSubject02 = '';
        tmpModel.attributes.FieldSubject03 = '';
        tmpModel.attributes.FieldSubject04 = '';
        tmpModel.attributes.FieldSubject05 = '';
        tmpModel.attributes.FieldSubject06 = '';
        tmpModel.attributes.FieldSubject07 = '';
        tmpModel.attributes.FieldSubject08 = '';
        tmpModel.attributes.FieldSubject09 = '';
        tmpModel.attributes.FieldSubject10 = '';
        tmpModel.attributes.FieldSubject11 = '';
        tmpModel.attributes.FieldSubject12 = '';
        tmpModel.attributes.FieldSubject13 = '';
        tmpModel.attributes.FieldSubject14 = '';
        tmpModel.attributes.FieldSubject15 = '';
        tmpModel.attributes.FieldSubject16 = '';
        tmpModel.attributes.FieldSubject17 = '';
        tmpModel.attributes.FieldSubject18 = '';
        tmpModel.attributes.FieldSubject19 = '';
        tmpModel.attributes.FieldSubject20 = '';
        tmpModel.attributes.FieldSubject21 = '';
        tmpModel.attributes.FieldSubject22 = '';
        tmpModel.attributes.FieldSubject23 = '';
        tmpModel.attributes.FieldSubject24 = '';
        tmpModel.attributes.FieldSubject25 = '';
        tmpModel.attributes.FieldSubject26 = '';
        tmpModel.attributes.FieldSubject27 = '';
        tmpModel.attributes.FieldSubject28 = '';
        tmpModel.attributes.FieldSubject29 = '';
        tmpModel.attributes.FieldSubject30 = '';

        return tmpModel;
    },
    makeFormModel: function (app, name) {
        var tmpModel = new app.entities.collections.customFormModel.model();

        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = name;

        tmpModel.attributes.FieldUsed = 5;
        tmpModel.attributes.FieldPosition = 1;

        tmpModel.attributes.Field01 = '';
        tmpModel.attributes.Field02 = '';
        tmpModel.attributes.Field03 = '';
        tmpModel.attributes.Field04 = '';
        tmpModel.attributes.Field05 = '';
        tmpModel.attributes.Field06 = '';
        tmpModel.attributes.Field07 = '';
        tmpModel.attributes.Field08 = '';
        tmpModel.attributes.Field09 = '';
        tmpModel.attributes.Field10 = '';
        tmpModel.attributes.Field11 = '';
        tmpModel.attributes.Field12 = '';
        tmpModel.attributes.Field13 = '';
        tmpModel.attributes.Field14 = '';
        tmpModel.attributes.Field15 = '';
        tmpModel.attributes.Field16 = '';
        tmpModel.attributes.Field17 = '';
        tmpModel.attributes.Field18 = '';
        tmpModel.attributes.Field19 = '';
        tmpModel.attributes.Field20 = '';
        tmpModel.attributes.Field21 = '';
        tmpModel.attributes.Field22 = '';
        tmpModel.attributes.Field23 = '';
        tmpModel.attributes.Field24 = '';
        tmpModel.attributes.Field25 = '';
        tmpModel.attributes.Field26 = '';
        tmpModel.attributes.Field27 = '';
        tmpModel.attributes.Field28 = '';
        tmpModel.attributes.Field29 = '';
        tmpModel.attributes.Field30 = '';

        tmpModel.attributes.FieldType01 = 1;
        tmpModel.attributes.FieldType02 = 1;
        tmpModel.attributes.FieldType03 = 1;
        tmpModel.attributes.FieldType04 = 1;
        tmpModel.attributes.FieldType05 = 1;
        tmpModel.attributes.FieldType06 = 1;
        tmpModel.attributes.FieldType07 = 1;
        tmpModel.attributes.FieldType08 = 1;
        tmpModel.attributes.FieldType09 = 1;
        tmpModel.attributes.FieldType10 = 1;
        tmpModel.attributes.FieldType11 = 1;
        tmpModel.attributes.FieldType12 = 1;
        tmpModel.attributes.FieldType13 = 1;
        tmpModel.attributes.FieldType14 = 1;
        tmpModel.attributes.FieldType15 = 1;
        tmpModel.attributes.FieldType16 = 1;
        tmpModel.attributes.FieldType17 = 1;
        tmpModel.attributes.FieldType18 = 1;
        tmpModel.attributes.FieldType19 = 1;
        tmpModel.attributes.FieldType20 = 1;
        tmpModel.attributes.FieldType21 = 1;
        tmpModel.attributes.FieldType22 = 1;
        tmpModel.attributes.FieldType23 = 1;
        tmpModel.attributes.FieldType24 = 1;
        tmpModel.attributes.FieldType25 = 1;
        tmpModel.attributes.FieldType26 = 1;
        tmpModel.attributes.FieldType27 = 1;
        tmpModel.attributes.FieldType28 = 1;
        tmpModel.attributes.FieldType29 = 1;
        tmpModel.attributes.FieldType30 = 1;

        tmpModel.attributes.FieldValue01 = '';
        tmpModel.attributes.FieldValue02 = '';
        tmpModel.attributes.FieldValue03 = '';
        tmpModel.attributes.FieldValue04 = '';
        tmpModel.attributes.FieldValue05 = '';
        tmpModel.attributes.FieldValue06 = '';
        tmpModel.attributes.FieldValue07 = '';
        tmpModel.attributes.FieldValue08 = '';
        tmpModel.attributes.FieldValue09 = '';
        tmpModel.attributes.FieldValue10 = '';
        tmpModel.attributes.FieldValue11 = '';
        tmpModel.attributes.FieldValue12 = '';
        tmpModel.attributes.FieldValue13 = '';
        tmpModel.attributes.FieldValue14 = '';
        tmpModel.attributes.FieldValue15 = '';
        tmpModel.attributes.FieldValue16 = '';
        tmpModel.attributes.FieldValue17 = '';
        tmpModel.attributes.FieldValue18 = '';
        tmpModel.attributes.FieldValue19 = '';
        tmpModel.attributes.FieldValue20 = '';
        tmpModel.attributes.FieldValue21 = '';
        tmpModel.attributes.FieldValue22 = '';
        tmpModel.attributes.FieldValue23 = '';
        tmpModel.attributes.FieldValue24 = '';
        tmpModel.attributes.FieldValue25 = '';
        tmpModel.attributes.FieldValue26 = '';
        tmpModel.attributes.FieldValue27 = '';
        tmpModel.attributes.FieldValue28 = '';
        tmpModel.attributes.FieldValue29 = '';
        tmpModel.attributes.FieldValue30 = '';

        return tmpModel;

    },
    makeSchemaModel: function (app, name) {
        var tmpModel = new app.entities.collections.customSchemaModel.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = name;

        tmpModel.attributes.FieldUsed = 12;

        tmpModel.attributes.FontSizeSchema = 6;
        tmpModel.attributes.MarginLeftSchema = 1;
        tmpModel.attributes.MarginRightSchema = 1;

        tmpModel.attributes.FontSizeSchemaBigscreen = 13;
        tmpModel.attributes.MarginLeftSchemaBigscreen = 2;
        tmpModel.attributes.MarginRightSchemaBigscreen = 2;

        tmpModel.attributes.Field01 = '';
        tmpModel.attributes.Field02 = '';
        tmpModel.attributes.Field03 = '';
        tmpModel.attributes.Field04 = '';
        tmpModel.attributes.Field05 = '';
        tmpModel.attributes.Field06 = '';
        tmpModel.attributes.Field07 = '';
        tmpModel.attributes.Field08 = '';
        tmpModel.attributes.Field09 = '';
        tmpModel.attributes.Field10 = '';
        tmpModel.attributes.Field11 = '';
        tmpModel.attributes.Field12 = '';

        tmpModel.attributes.FieldEvent01 = true;
        tmpModel.attributes.FieldEvent02 = true;
        tmpModel.attributes.FieldEvent03 = true;
        tmpModel.attributes.FieldEvent04 = true;
        tmpModel.attributes.FieldEvent05 = true;
        tmpModel.attributes.FieldEvent06 = true;
        tmpModel.attributes.FieldEvent07 = true;
        tmpModel.attributes.FieldEvent08 = true;
        tmpModel.attributes.FieldEvent09 = true;
        tmpModel.attributes.FieldEvent10 = true;
        tmpModel.attributes.FieldEvent11 = true;
        tmpModel.attributes.FieldEvent12 = true;

        tmpModel.attributes.FieldBigscreen = 1;
        tmpModel.attributes.FieldBigscreen01 = true;
        tmpModel.attributes.FieldBigscreen02 = true;
        tmpModel.attributes.FieldBigscreen03 = true;
        tmpModel.attributes.FieldBigscreen04 = true;
        tmpModel.attributes.FieldBigscreen05 = true;
        tmpModel.attributes.FieldBigscreen06 = true;
        tmpModel.attributes.FieldBigscreen07 = true;
        tmpModel.attributes.FieldBigscreen08 = true;
        tmpModel.attributes.FieldBigscreen09 = true;
        tmpModel.attributes.FieldBigscreen10 = true;
        tmpModel.attributes.FieldBigscreen11 = true;
        tmpModel.attributes.FieldBigscreen12 = true;

        return tmpModel;
    },
}

export default HelperCreateEvent;