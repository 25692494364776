var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetModel_empty"> <div class="panel"> <h5 class="widget-title"><i class="fa fa-2x fa-pencil-square-o" style="padding-left:30px"></i><i id="help" style="cursor:pointer" class="fa fa-2x fa-question-circle"></i>'+
((__t=( resources['X101102'] ))==null?'':__t)+
'</h5> <div id="customProductSheetModelInformation"></div> <div id="customProductSheetModelInsert"></div> <div class="row"> <div class="col-md-12"> <div id="customProductSheetModelList"></div> </div> </div> <div class="row"> <div class="col-md-12"> <div id="customProductSheetModelPagination" style="text-align:center"></div> </div> </div> </div> </div>';
}
return __p;
};
