'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogPaintStrapListItem from './tpl/dialogPaintStrapListItem.html';

export default Marionette.ItemView.extend({
    className: ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2',
    getTemplate: function () {
        return tpl_dialogPaintStrapListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'dialogPaintStrapListItem_btnSubmit': '#dialogPaintStrapListItem_btnSubmit',
        'dialogPaintStrapListItem_image': '#dialogPaintStrapListItem_image'
    },
    events: {
        'click @ui.dialogPaintStrapListItem_btnSubmit': 'btnSubmitClick'
    },
    btnSubmitClick: function (event) {
        this.model.attributes.DisplayText = resources.X100417;

        var self = this;
        this.model.collection.fullCollection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: false });
            }
        });

        this.model.set({ Selected: true });
        this.model.collection.trigger('change');
    },
    onRender: function () {
        if (this.model.attributes.Selected) {
            this.ui.dialogPaintStrapListItem_image.css('border-style', 'solid');
        }
        else {
            this.ui.dialogPaintStrapListItem_image.css('border-style', 'none');
        }
    }
});