'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customProductSheetDataTableEdit from './tpl/customProductSheetDataTableEdit.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import config from '../../../common/config';

import * as Entities_CustomProductSheetData from '../../entities/customProductSheetData';
import * as Entities_CustomProductSheetImage from '../../entities/customProductSheetImage';

import View_CustomProductSheetImageTable from './customProductSheetImageTable';
import buildProductSheetInput from './buildProductSheetInput';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customProductSheetDataTableEdit();
    },
    initialize: function () {
        this.app = this.options.app;
        this.settingModel = this.options.settingModel;
        this.schemaDataModel = this.options.schemaDataModel;
    },
    regions: {
        customProductSheetImageList: "#customProductSheetDataTableEdit_div_ImageList",
    },
    onBeforeShow: function () {
        this.collection = new Entities_CustomProductSheetImage.Collection();
        this.collection.fullCollection.comparator = this.collection.comparator;

        var list = this.app.entities.collections.customProductSheetImage.fullCollection.where( {CommonId : this.schemaDataModel.CommonId });

        for (var i = 0; i < list.length; i++) {
            this.collection.fullCollection.add(list[i]);
        }

        this.customProductSheetImageList.show(new View_CustomProductSheetImageTable({ collection: this.collection, settingModel: this.settingModel, app : this.app }));
    },
    ui: {
        'customProductSheetDataTableEdit_divForm' : '#customProductSheetDataTableEdit_divForm',
        'customProductSheetDataTableEdit_btnSubmit': '#customProductSheetDataTableEdit_btnSubmit',

        'customProductSheetDataTableEdit_textarea_ProductName': '#customProductSheetDataTableEdit_textarea_ProductName',
        'customProductSheetDataTableEdit_textarea_Feature': '#customProductSheetDataTableEdit_textarea_Feature',
        'customProductSheetDataTableEdit_textarea_Description': '#customProductSheetDataTableEdit_textarea_Description'
    },
    events: {
        'click @ui.customProductSheetDataTableEdit_btnSubmit': 'btnSubmitClick'
    },
    btnSubmitClick: function (event) {
        event.preventDefault();
        this.hideErrors();
        //var tmpModel = new this.app.entities.collections.customProductSheetData.model();
        var tmpModel = new Entities_CustomProductSheetData.Model(); 
        //tmpModel.attributes.id = null;

        tmpModel.attributes.ProductName = '';
        tmpModel.attributes.Feature = '';
        tmpModel.attributes.Description = '';

        tmpModel.attributes.Value01 = '';
        tmpModel.attributes.Value02 = '';
        tmpModel.attributes.Value03 = '';
        tmpModel.attributes.Value04 = '';
        tmpModel.attributes.Value05 = '';
        tmpModel.attributes.Value06 = '';
        tmpModel.attributes.Value07 = '';
        tmpModel.attributes.Value08 = '';
        tmpModel.attributes.Value09 = '';
        tmpModel.attributes.Value10 = '';
        tmpModel.attributes.Value11 = '';
        tmpModel.attributes.Value12 = '';
        tmpModel.attributes.Value13 = '';
        tmpModel.attributes.Value14 = '';
        tmpModel.attributes.Value15 = '';
        tmpModel.attributes.Value16 = '';
        tmpModel.attributes.Value17 = '';
        tmpModel.attributes.Value18 = '';
        tmpModel.attributes.Value19 = '';
        tmpModel.attributes.Value20 = '';
        tmpModel.attributes.Value21 = '';
        tmpModel.attributes.Value22 = '';
        tmpModel.attributes.Value23 = '';
        tmpModel.attributes.Value24 = '';
        tmpModel.attributes.Value25 = '';
        tmpModel.attributes.Value26 = '';
        tmpModel.attributes.Value27 = '';
        tmpModel.attributes.Value28 = '';
        tmpModel.attributes.Value29 = '';
        tmpModel.attributes.Value30 = '';

        tmpModel.attributes.EventId = this.settingModel.attributes.EventId;
        tmpModel.attributes.id = this.schemaDataModel.id;//app.views.customProductSheetDataList.table_getFirstVisibleItem();
        tmpModel.attributes.orgid = this.schemaDataModel.orgid;

        tmpModel.attributes.CommonId =  this.schemaDataModel.CommonId;

        tmpModel.attributes.ProductName = this.ui.customProductSheetDataTableEdit_textarea_ProductName.val();
        tmpModel.attributes.Feature = this.ui.customProductSheetDataTableEdit_textarea_Feature.val();
        tmpModel.attributes.Description = this.ui.customProductSheetDataTableEdit_textarea_Description.val();

        tmpModel = buildProductSheetInput.getData(tmpModel, this.options.productSheetModel);

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(app);
        if (ret == false) {
            this.ui.customProductSheetDataTableEdit_textarea_ProductName.val('');
            this.ui.customProductSheetDataTableEdit_textarea_Feature.val('');
            this.ui.customProductSheetDataTableEdit_textarea_Description.val('');

            var self = this;
            //app.views.customProductSheetDataList.table_addModel(tmpModel, true, tmpModel.attributes.id, true);
            var msg = {
                tmpModel: tmpModel,
                jumpToData: true,
                jumpFirst: tmpModel.attributes.id,
                ignoreEmptyContent: true
            }
            //this.triggerMethod('table_addModel', msg);

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customProductSheetDataList:table_editModel', msg);

            //this._parent.reset();
            
            /*var userChannel = Backbone.Radio.channel('app');
            this.collection.fullCollection.reset();
            userChannel.trigger('customProductSheetData:setUUID');*/
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    },
    onRender: function () {
        this.ui.customProductSheetDataTableEdit_textarea_ProductName.val(this.schemaDataModel.ProductName);
        this.ui.customProductSheetDataTableEdit_textarea_Feature.val(this.schemaDataModel.Feature);
        this.ui.customProductSheetDataTableEdit_textarea_Description.val(this.schemaDataModel.Description);

        buildProductSheetInput.form_init(this.settingModel.attributes.EventId,
                this.options.productSheetModel,
                this.ui.customProductSheetDataTableEdit_divForm);
        
        if (this.app.entities.models.configSubscription[0].attributes.EnableProdutSheetModel == false) {
            this.ui.customProductSheetDataTableEdit_btnSubmit.prop('disabled', true);
        } else if (this.settingModel.attributes.EventId > 0) {
            this.ui.customProductSheetDataTableEdit_btnSubmit.prop('disabled', false);
        }
        else {
            this.ui.customProductSheetDataTableEdit_btnSubmit.prop('disabled', true);
        }

        

        return this;
    },
    onAttach(){
        buildProductSheetInput.form_attach(this.settingModel.attributes.EventId,
                this.options.productSheetModel,
                this.ui.customProductSheetDataTableEdit_divForm);

        buildProductSheetInput.form_setModelValue(this.settingModel.attributes.EventId,
                this.options.productSheetModel,
                this.ui.customProductSheetDataTableEdit_divForm, 
                this.schemaDataModel);


    }
});