var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="test"> <div id="moduleDiv" class="moduleDiv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101340'] ))==null?'':__t)+
'"> <i id="moduleDiv_i" class="mdi mdi-view-module mdi-36px moduleDivIcon"></i> </div> <div id="moduleEditDiv" class="moduleEditDiv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101342'] ))==null?'':__t)+
'"> <i class="mdi mdi-square-edit-outline mdi-36px moduleEditDivIcon"></i> </div> <div id="settingDiv" class="settingDiv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101341'] ))==null?'':__t)+
'"> <i id="settingDiv_i" class="settingDivIcon mdi mdi-cogs mdi-36px settingDivIcon"></i> </div> <div id="emptyDiv" class="emptyDiv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body"> </div> <div id="playDiv" class="playDiv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101429'] ))==null?'':__t)+
'"> <i id="playDiv_i" class="playDivIcon mdi mdi-play mdi-36px playDivIcon"></i> </div> <div id="sizeDiv" class="sizeDiv" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101343'] ))==null?'':__t)+
'"> <p id="sizeDiv_p">100X100</p> </div> </div>';
}
return __p;
};
