'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customProductSheetModelInsert from './tpl/customProductSheetModelInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customProductSheetModelInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customProductSheetModelInsert_btnSubmit': '#customProductSheetModelInsert_btnSubmit',
        'customProductSheetModelInsert_inputName': '#customProductSheetModelInsert_inputName'
    },
    events: {
        'click @ui.customProductSheetModelInsert_btnSubmit': 'btnSubmitClick'
    },
    btnSubmitClick: function () {
        this.hideErrors();
        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.ui.customProductSheetModelInsert_inputName.val();

        tmpModel.attributes.FieldUsed = 30;
        
        tmpModel.attributes.PositionWebpage = 1;
        tmpModel.attributes.PositionProductName = 2;
        tmpModel.attributes.PositionImage = 3;
        tmpModel.attributes.PositionFeature = 4;
        tmpModel.attributes.PositionDescription = 5;
        tmpModel.attributes.PositionSchema = 6;
        
        tmpModel.attributes.FontSizeProductName = 10;
        tmpModel.attributes.FontSizeImage = 11;
        tmpModel.attributes.FontSizeFeature = 12;
        tmpModel.attributes.FontSizeDescription = 13;
        tmpModel.attributes.FontSizeSchema = 14;

        tmpModel.attributes.Field01 = '';
        tmpModel.attributes.Field02 = '';
        tmpModel.attributes.Field03 = '';
        tmpModel.attributes.Field04 = '';
        tmpModel.attributes.Field05 = '';
        tmpModel.attributes.Field06 = '';
        tmpModel.attributes.Field07 = '';
        tmpModel.attributes.Field08 = '';
        tmpModel.attributes.Field09 = '';
        tmpModel.attributes.Field10 = '';
        tmpModel.attributes.Field11 = '';
        tmpModel.attributes.Field12 = '';
        tmpModel.attributes.Field13 = '';
        tmpModel.attributes.Field14 = '';
        tmpModel.attributes.Field15 = '';
        tmpModel.attributes.Field16 = '';
        tmpModel.attributes.Field17 = '';
        tmpModel.attributes.Field18 = '';
        tmpModel.attributes.Field19 = '';
        tmpModel.attributes.Field20 = '';
        tmpModel.attributes.Field21 = '';
        tmpModel.attributes.Field22 = '';
        tmpModel.attributes.Field23 = '';
        tmpModel.attributes.Field24 = '';
        tmpModel.attributes.Field25 = '';
        tmpModel.attributes.Field26 = '';
        tmpModel.attributes.Field27 = '';
        tmpModel.attributes.Field28 = '';
        tmpModel.attributes.Field29 = '';
        tmpModel.attributes.Field30 = '';

        tmpModel.attributes.FieldType01 = 1;
        tmpModel.attributes.FieldType02 = 1;
        tmpModel.attributes.FieldType03 = 1;
        tmpModel.attributes.FieldType04 = 1;
        tmpModel.attributes.FieldType05 = 1;
        tmpModel.attributes.FieldType06 = 1;
        tmpModel.attributes.FieldType07 = 1;
        tmpModel.attributes.FieldType08 = 1;
        tmpModel.attributes.FieldType09 = 1;
        tmpModel.attributes.FieldType10 = 1;
        tmpModel.attributes.FieldType11 = 1;
        tmpModel.attributes.FieldType12 = 1;
        tmpModel.attributes.FieldType13 = 1;
        tmpModel.attributes.FieldType14 = 1;
        tmpModel.attributes.FieldType15 = 1;
        tmpModel.attributes.FieldType16 = 1;
        tmpModel.attributes.FieldType17 = 1;
        tmpModel.attributes.FieldType18 = 1;
        tmpModel.attributes.FieldType19 = 1;
        tmpModel.attributes.FieldType20 = 1;
        tmpModel.attributes.FieldType21 = 1;
        tmpModel.attributes.FieldType22 = 1;
        tmpModel.attributes.FieldType23 = 1;
        tmpModel.attributes.FieldType24 = 1;
        tmpModel.attributes.FieldType25 = 1;
        tmpModel.attributes.FieldType26 = 1;
        tmpModel.attributes.FieldType27 = 1;
        tmpModel.attributes.FieldType28 = 1;
        tmpModel.attributes.FieldType29 = 1;
        tmpModel.attributes.FieldType30 = 1;
        
        tmpModel.attributes.FieldValue01 = '';
        tmpModel.attributes.FieldValue02 = '';
        tmpModel.attributes.FieldValue03 = '';
        tmpModel.attributes.FieldValue04 = '';
        tmpModel.attributes.FieldValue05 = '';
        tmpModel.attributes.FieldValue06 = '';
        tmpModel.attributes.FieldValue07 = '';
        tmpModel.attributes.FieldValue08 = '';
        tmpModel.attributes.FieldValue09 = '';
        tmpModel.attributes.FieldValue10 = '';
        tmpModel.attributes.FieldValue11 = '';
        tmpModel.attributes.FieldValue12 = '';
        tmpModel.attributes.FieldValue13 = '';
        tmpModel.attributes.FieldValue14 = '';
        tmpModel.attributes.FieldValue15 = '';
        tmpModel.attributes.FieldValue16 = '';
        tmpModel.attributes.FieldValue17 = '';
        tmpModel.attributes.FieldValue18 = '';
        tmpModel.attributes.FieldValue19 = '';
        tmpModel.attributes.FieldValue20 = '';
        tmpModel.attributes.FieldValue21 = '';
        tmpModel.attributes.FieldValue22 = '';
        tmpModel.attributes.FieldValue23 = '';
        tmpModel.attributes.FieldValue24 = '';
        tmpModel.attributes.FieldValue25 = '';
        tmpModel.attributes.FieldValue26 = '';
        tmpModel.attributes.FieldValue27 = '';
        tmpModel.attributes.FieldValue28 = '';
        tmpModel.attributes.FieldValue29 = '';
        tmpModel.attributes.FieldValue30 = '';

        tmpModel.attributes.FieldSubject01 = '';
        tmpModel.attributes.FieldSubject02 = '';
        tmpModel.attributes.FieldSubject03 = '';
        tmpModel.attributes.FieldSubject04 = '';
        tmpModel.attributes.FieldSubject05 = '';
        tmpModel.attributes.FieldSubject06 = '';
        tmpModel.attributes.FieldSubject07 = '';
        tmpModel.attributes.FieldSubject08 = '';
        tmpModel.attributes.FieldSubject09 = '';
        tmpModel.attributes.FieldSubject10 = '';
        tmpModel.attributes.FieldSubject11 = '';
        tmpModel.attributes.FieldSubject12 = '';
        tmpModel.attributes.FieldSubject13 = '';
        tmpModel.attributes.FieldSubject14 = '';
        tmpModel.attributes.FieldSubject15 = '';
        tmpModel.attributes.FieldSubject16 = '';
        tmpModel.attributes.FieldSubject17 = '';
        tmpModel.attributes.FieldSubject18 = '';
        tmpModel.attributes.FieldSubject19 = '';
        tmpModel.attributes.FieldSubject20 = '';
        tmpModel.attributes.FieldSubject21 = '';
        tmpModel.attributes.FieldSubject22 = '';
        tmpModel.attributes.FieldSubject23 = '';
        tmpModel.attributes.FieldSubject24 = '';
        tmpModel.attributes.FieldSubject25 = '';
        tmpModel.attributes.FieldSubject26 = '';
        tmpModel.attributes.FieldSubject27 = '';
        tmpModel.attributes.FieldSubject28 = '';
        tmpModel.attributes.FieldSubject29 = '';
        tmpModel.attributes.FieldSubject30 = '';

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(app);
        if (ret == false) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100180;
            dialogWaitingModel.attributes.Text = resources.X101103;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);

            var self = this;

            this.collection.fullCollection.create(tmpModel, {
                wait: true,
                success: function (response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    self.ui.customProductSheetModelInsert_inputName.val('');
                }
            });
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    }
});

