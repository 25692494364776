﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import helper_infopage from '../../helper/infopage';

import View_Module from './module';

export default View_LayoutError.extend({
    className: 'div100',
    tagName: 'div',
    attributes: function () {
        return {
            id: "setting",
        };
    },
    destroy: function () {
        this.undelegateEvents();
        this.$el.removeData().unbind();

        this.remove();
        //this.$el.empty();
    },
    onRenderComplete: function () {

        // check every 200ms to see if this.el has been injected
        var moduleRegion = this.ui.setting_margin.closest('.moduleRegion');
        var moduleRegion_height = $(moduleRegion).height();

        if (moduleRegion_height == null) {
            var that = this;
            setTimeout(function () {
                that.setupMargin();
            }, 200);
            return;
        } 
    },
    setupMargin() {
        var moduleRegion = this.ui.setting_margin.closest('.moduleRegion');
        var moduleRegion_height = $(moduleRegion).height();

        if (moduleRegion_height != null) {
            var module_height = this.model.attributes.Data.Margin_Bottom - this.model.attributes.Data.Margin_Top;

            this.ui.module_margin.css('height', module_height + 'px');
            this.ui.module_margin.css('min-height', module_height + 'px');
            this.ui.module_margin.css('max-height', module_height + 'px');

            this.ui.module_margin.css('padding-top', this.model.attributes.Data.ModuleMargin_Top + 'px');
            this.ui.module_margin.css('padding-bottom', this.model.attributes.Data.ModuleMargin_Bottom + 'px');
            this.ui.module_margin.css('padding-left', this.model.attributes.Data.ModuleMargin_Left + 'px');
            this.ui.module_margin.css('padding-right', this.model.attributes.Data.ModuleMargin_Right + 'px');
        } else {
            this.onRenderComplete();
        }
    }
}, Backbone.Radio.Requests);
