﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import helper_infopage from '../../../helper/infopage';

import tpl_digitalClock from './tpl/digitalClock.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#digitalClock_modal',
    errors: [],
    getTemplate: function () {
        return tpl_digitalClock({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'digitalClock_checkboxEnableModule': '#digitalClock_checkboxEnableModule',
        'digitalClock_ColorPickerSliders_Background1_Color': '#digitalClock_ColorPickerSliders_Background1_Color',
        'digitalClock_checkboxEnableClock': '#digitalClock_checkboxEnableClock',
        'digitalClock_checkboxEnable24Hours': '#digitalClock_checkboxEnable24Hours',
        'digitalClock_checkboxEnableSeconds': '#digitalClock_checkboxEnableSeconds',
        'digitalClock_ColorPickerSliders_Font1_Color': '#digitalClock_ColorPickerSliders_Font1_Color',
        
        'digitalClock_dropDown_Font1_Size': '#digitalClock_dropDown_Font1_Size',
        
        'digitalClock_dropDown_Font1': '#digitalClock_dropDown_Font1',
        'digitalClock_formgroup_Font1': '#digitalClock_formgroup_Font1',
        'digitalClock': '#digitalClock',
        'digitalClock_dropDown_Font1_Style': '#digitalClock_dropDown_Font1_Style',
        'digitalClock_dropDown_Font1_TextAlign': '#digitalClock_dropDown_Font1_TextAlign',
        'digitalClock_input_margin1_Top': '#digitalClock_input_margin1_Top',
        'digitalClock_input_margin1_Bottom': '#digitalClock_input_margin1_Bottom',
        'digitalClock_input_margin1_Left': '#digitalClock_input_margin1_Left',
        'digitalClock_input_margin1_Right': '#digitalClock_input_margin1_Right',

        'digitalClock_checkboxEnableDate': '#digitalClock_checkboxEnableDate',
        'digitalClock_dropDown_DateFormat': '#digitalClock_dropDown_DateFormat',
        'digitalClock_ColorPickerSliders_Font2_Color': '#digitalClock_ColorPickerSliders_Font2_Color',
        'digitalClock_dropDown_Font2_Size': '#digitalClock_dropDown_Font2_Size',
        
        'digitalClock_dropDown_Font2': '#digitalClock_dropDown_Font2',
        'digitalClock_formgroup_Font2': '#digitalClock_formgroup_Font2',
        'digitalClock_dropDown_Font2_Style': '#digitalClock_dropDown_Font2_Style',
        'digitalClock_dropDown_Font2_TextAlign': '#digitalClock_dropDown_Font2_TextAlign',
        'digitalClock_input_margin2_Top': '#digitalClock_input_margin2_Top',
        'digitalClock_input_margin2_Bottom': '#digitalClock_input_margin2_Bottom',
        'digitalClock_input_margin2_Left': '#digitalClock_input_margin2_Left',
        'digitalClock_input_margin2_Right': '#digitalClock_input_margin2_Right',
        'digitalClock_btn01':'#digitalClock_btn01',
        'digitalClock_btn02': '#digitalClock_btn02',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    events: {
        'colorpickerChange @ui.digitalClock_ColorPickerSliders_Background1_Color': 'colorChange_Background1_Color',
        'colorpickerChange @ui.digitalClock_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.digitalClock_ColorPickerSliders_Font2_Color': 'colorChange_Font2Color',
        'click @ui.digitalClock_btn01': 'onBtn01',
        'click @ui.digitalClock_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    
    onAttach() {
        this.setupEnableType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupFontTextAlign();
        this.setupDateFormat();
    },
    setupEnableType: function () {
        var checked = this.model.attributes.EnableModule == 1 ? true : false;
        this.ui.digitalClock_checkboxEnableModule.attr('checked', checked);

        var checked = this.model.attributes.EnableClock == 1 ? true : false;
        this.ui.digitalClock_checkboxEnableClock.attr('checked', checked);

        var checked = this.model.attributes.Enable24Hours == 1 ? true : false;
        this.ui.digitalClock_checkboxEnable24Hours.attr('checked', checked);

        var checked = this.model.attributes.EnableSeconds == 1 ? true : false;
        this.ui.digitalClock_checkboxEnableSeconds.attr('checked', checked);

        var checked = this.model.attributes.EnableDate == 1 ? true : false;
        this.ui.digitalClock_checkboxEnableDate.attr('checked', checked);
    },
    
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.digitalClock_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        this.ui.digitalClock_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
        });
        this.ui.digitalClock_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);


        this.ui.digitalClock_ColorPickerSliders_Background1_Color.colorpicker({
            color: self.model.attributes.Background1_Color,
            format: "rgb"
        });


        this.ui.digitalClock_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        this.ui.digitalClock_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font2: this.value });
            self.model.set({ Font2_Select: this.value });
        });
        this.ui.digitalClock_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);
    },
    setupFontSize: function () {
        var self = this;

        this.ui.digitalClock_dropDown_Font1_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.digitalClock_dropDown_Font1_Size.append(options);
        }

        this.ui.digitalClock_dropDown_Font2_Size.empty();

        selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.digitalClock_dropDown_Font2_Size.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.digitalClock_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.digitalClock_dropDown_Font1_Style.append(options);
        });

        self.ui.digitalClock_dropDown_Font2_Style.empty();

        selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.digitalClock_dropDown_Font2_Style.append(options);
        });
    },
    setupFontTextAlign: function () {
        var self = this;

        self.ui.digitalClock_dropDown_Font1_TextAlign.empty();
        
        var selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.digitalClock_dropDown_Font1_TextAlign.append(options);
        });

        self.ui.digitalClock_dropDown_Font2_TextAlign.empty();

        selected = '';

        self.app.entities.collections.TextAlign.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_TextAlign == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.digitalClock_dropDown_Font2_TextAlign.append(options);
        });
    },
    setupDateFormat: function () {
        var self = this;

        self.ui.digitalClock_dropDown_DateFormat.empty();

        var selected = '';

        var date = new Date();
        date.setDate(1);


        var vA = date.getDate();
        if (vA < 10) vA = "0" + vA;

        var vB = date.getMonth() + 1;
        if (vB < 10) vB = "0" + vB;

        var vC = date.getFullYear();

        var vF = date.getDate();
        var vG = date.getMonth() + 1;

        var dayOffWeek = date.getDay();
        var vD = self.app.entities.collections.DigitalClockHelper.models[0].attributes.Days[dayOffWeek].Name;
        var dayOffMonth = date.getMonth();
        var vE = self.app.entities.collections.DigitalClockHelper.models[0].attributes.Months[dayOffMonth].Name;
        self.app.entities.collections.DigitalClockDateFormat.forEach(function (entry) {

            var name = entry.attributes.Name;

            name = name.replaceAll("*A", vA);
            name = name.replaceAll("*B", vB);
            name = name.replaceAll("*C", vC);
            name = name.replaceAll("*D", vD);
            name = name.replaceAll("*E", vE);
            name = name.replaceAll("*F", vF);
            name = name.replaceAll("*G", vG);

            selected = '';
            if (self.model.attributes.DateFormat == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', name, entry.attributes.Value, selected);
            self.ui.digitalClock_dropDown_DateFormat.append(options);
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    colorChange_Background1_Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Background1_Color = e.value;
        }
        else {
            this.model.attributes.Background1_Color = e.value.toRgbString();
        }
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }
    },
    colorChange_Font2Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }
    },
    setModel: function () {
        var EnableModule = this.model.attributes.EnableModule;
        this.ui.digitalClock_checkboxEnableModule.is(':checked') ? EnableModule = 1 : EnableModule = 0;


        var EnableClock = this.model.attributes.EnableClock;
        this.ui.digitalClock_checkboxEnableClock.is(':checked') ? EnableClock = 1 : EnableClock = 0;

        var Enable24Hours = this.model.attributes.Enable24Hours;
        this.ui.digitalClock_checkboxEnable24Hours.is(':checked') ? Enable24Hours = 1 : Enable24Hours = 0;

        var EnableSeconds = this.model.attributes.EnableSeconds;
        this.ui.digitalClock_checkboxEnableSeconds.is(':checked') ? EnableSeconds = 1 : EnableSeconds = 0;

        var EnableDate = this.model.attributes.EnableDate;
        this.ui.digitalClock_checkboxEnableDate.is(':checked') ? EnableDate = 1 : EnableDate = 0;

        this.model.set({
            EnableModule: EnableModule,
            EnableClock: EnableClock,
            Enable24Hours: Enable24Hours,
            EnableSeconds: EnableSeconds,
            EnableDate: EnableDate,
            Font1_Font: this.ui.digitalClock_dropDown_Font1.val(),
            Font1_Size: this.ui.digitalClock_dropDown_Font1_Size.val(),
            Font1_Style: this.ui.digitalClock_dropDown_Font1_Style.val(),
            Font1_TextAlign: this.ui.digitalClock_dropDown_Font1_TextAlign.val(),
            Margin1_Top: this.ui.digitalClock_input_margin1_Top.val(),
            Margin1_Bottom: this.ui.digitalClock_input_margin1_Bottom.val(),
            Margin1_Left: this.ui.digitalClock_input_margin1_Left.val(),
            Margin1_Right: this.ui.digitalClock_input_margin1_Right.val(),
            DateFormat: this.ui.digitalClock_dropDown_DateFormat.val(),
            Font2_Font: this.ui.digitalClock_dropDown_Font2.val(),
            Font2_Size: this.ui.digitalClock_dropDown_Font2_Size.val(),
            Font2_Style: this.ui.digitalClock_dropDown_Font2_Style.val(),
            Font2_TextAlign: this.ui.digitalClock_dropDown_Font2_TextAlign.val(),
            Margin2_Top: this.ui.digitalClock_input_margin2_Top.val(),
            Margin2_Bottom: this.ui.digitalClock_input_margin2_Bottom.val(),
            Margin2_Left: this.ui.digitalClock_input_margin2_Left.val(),
            Margin2_Right: this.ui.digitalClock_input_margin2_Right.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    },
    setupCssStyle: function () {
    }
});