var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="infoPageListItem_empty"> <div class="d-flex align-items-center pb-3" style="padding-bottom: 5px !important"> <div style="margin-top: 0px !important" class="app-categories-legend mt-4"> <div style="border-top-width: 0px" class="d-flex justify-content-between legend-label align-items-center"> <div class="d-flex align-items-center"> <span id="infoPageListItem_light" class="bg-danger" style="height: 70px"></span> '+
((__t=( model.FirstName ))==null?'':__t)+
'<br> '+
((__t=( model.ActiveTab ))==null?'':__t)+
'<br> Optaget<br> 12:00 </div> </div> </div> <div style="padding-top: 0px;padding-bottom: 0px" class="card-body text-right"> <div style="font-size:14px;margin-top:5px" class="profileImage_small" id="infoPageListItem_profileImage"></div> <img id="infoPageListItem_imageUrl" src="'+
((__t=( model.ImageUrl ))==null?'':__t)+
'" class="img-lg rounded-circle mb-2" alt="profile image" style="width:50px;height:50px;margin-bottom: 0px !important;margin-top:5px"> <br> <p style="font-family: Roboto,sans-serif;font-size: .875rem;color: #001737;font-weight: 700" class="mt-2 card-text"> 00:25 </p> </div> </div> </div>';
}
return __p;
};
