﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import helper_infopage from '../../../helper/infopage';

import tpl_schemaScreen from './tpl/schemaScreen.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#schemaScreen_modal',
    errors: [],
    getTemplate: function () {
        return tpl_schemaScreen({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'schemaScreen_checkboxFeedType': '#schemaScreen_checkboxFeedType',
        'schemaScreen_dropDown_feedProvider': '#schemaScreen_dropDown_feedProvider',
        'schemaScreen_input_feedUrl': '#schemaScreen_input_feedUrl',
        'schemaScreen_dropDown_feedCount': '#schemaScreen_dropDown_feedCount',
        'schemaScreen_input_feedSpeed': '#schemaScreen_input_feedSpeed',
        'schemaScreen_select_Font1_ColorType': '#schemaScreen_select_Font1_ColorType',
        'schemaScreen_ColorPickerSliders_Font1_Color': '#schemaScreen_ColorPickerSliders_Font1_Color',
        'schemaScreen_select_Font1_FontType': '#schemaScreen_select_Font1_FontType',
        'schemaScreen_dropDown_Font1': '#schemaScreen_dropDown_Font1',
        'schemaScreen_formgroup_Font1': '#schemaScreen_formgroup_Font1',
        'schemaScreen_dropDown_Font1_Size': '#schemaScreen_dropDown_Font1_Size',
        'schemaScreen_dropDown_Font1_Style': '#schemaScreen_dropDown_Font1_Style',
        'schemaScreen_select_Font2_ColorType': '#schemaScreen_select_Font2_ColorType',
        'schemaScreen_ColorPickerSliders_Font2_Color': '#schemaScreen_ColorPickerSliders_Font2_Color',
        'schemaScreen_select_Font2_FontType': '#schemaScreen_select_Font2_FontType',
        'schemaScreen_dropDown_Font2': '#schemaScreen_dropDown_Font2',
        'schemaScreen_formgroup_Font2': '#schemaScreen_formgroup_Font2',
        'schemaScreen_dropDown_Font2_Size': '#schemaScreen_dropDown_Font2_Size',
        'schemaScreen_dropDown_Font2_Style': '#schemaScreen_dropDown_Font2_Style',

        'schemaScreen_ColorPickerSliders_Even_Color': '#schemaScreen_ColorPickerSliders_Even_Color',
        'schemaScreen_ColorPickerSliders_Odd_Color': '#schemaScreen_ColorPickerSliders_Odd_Color',

        'schemaScreen_input_Margin1_Top': '#schemaScreen_input_Margin1_Top',
        'schemaScreen_input_Margin1_Bottom': '#schemaScreen_input_Margin1_Bottom',
        'schemaScreen_input_Margin1_Left': '#schemaScreen_input_Margin1_Left',
        'schemaScreen_input_Margin1_Right': '#schemaScreen_input_Margin1_Right',

        'schemaScreen_input_Margin2_Top': '#schemaScreen_input_Margin2_Top',
        'schemaScreen_input_Margin2_Bottom': '#schemaScreen_input_Margin2_Bottom',
        'schemaScreen_input_Margin2_Left': '#schemaScreen_input_Margin2_Left',
        'schemaScreen_input_Margin2_Right': '#schemaScreen_input_Margin2_Right',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    events: {
        'change @ui.schemaScreen_select_Font1_FontType': 'change_Font1_FontType',
        'change @ui.schemaScreen_select_Font1_ColorType': 'change_Font1_ColorType',
        'colorpickerChange @ui.schemaScreen_ColorPickerSliders_Font1_Color': 'colorChange_Font1_Color',
        'change @ui.schemaScreen_select_Font2_FontType': 'change_Font2_FontType',
        'change @ui.schemaScreen_select_Font2_ColorType': 'change_Font2_ColorType',
        'colorpickerChange @ui.schemaScreen_ColorPickerSliders_Font2_Color': 'colorChange_Font2_Color',
        'colorpickerChange @ui.schemaScreen_ColorPickerSliders_Even_Color': 'colorChange_EvenColor',
        'colorpickerChange @ui.schemaScreen_ColorPickerSliders_Odd_Color': 'colorChange_OddColor',
        'click #schemaScreen_btn01': 'onBtn01',
        'click #schemaScreen_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    onAttach() {
        this.setupFontColorType();
        this.setupFontType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
        this.setupEvenOdd();
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.schemaScreen_select_Font1_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.schemaScreen_select_Font1_ColorType.append(options);
        });

        self.ui.schemaScreen_select_Font2_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.schemaScreen_select_Font2_ColorType.append(options);
        });
    },
    setupFontType: function () {
        var self = this;

        self.ui.schemaScreen_select_Font1_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.schemaScreen_select_Font1_FontType.append(options);
        });

        self.ui.schemaScreen_select_Font2_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.schemaScreen_select_Font2_FontType.append(options);
        });

    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.schemaScreen_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font1: this.value });
            self.model.set({ Font1_Select: this.value });
        });
        this.ui.schemaScreen_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.schemaScreen_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.schemaScreen_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }

        this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.schemaScreen_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font2: this.value });
            self.model.set({ Font2_Select: this.value });
        });
        this.ui.schemaScreen_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.schemaScreen_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.schemaScreen_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    setupFontSize: function () {
        var self = this;

        this.ui.schemaScreen_dropDown_Font1_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.schemaScreen_dropDown_Font1_Size.append(options);
        }

        this.ui.schemaScreen_dropDown_Font2_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.schemaScreen_dropDown_Font2_Size.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.schemaScreen_dropDown_Font1_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.schemaScreen_dropDown_Font1_Style.append(options);
        });

        self.ui.schemaScreen_dropDown_Font2_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.schemaScreen_dropDown_Font2_Style.append(options);
        });
    },
    setupEvenOdd: function () {
        this.ui.schemaScreen_ColorPickerSliders_Even_Color.colorpicker({
            color: self.model.attributes.Data.Even_Color,
            format: "rgb"
        });

        this.ui.schemaScreen_ColorPickerSliders_Odd_Color.colorpicker({
            color: self.model.attributes.Data.Odd_Color,
            format: "rgb"
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    change_Font1_FontType: function () {
        this.model.set({
            Font1_FontType: this.ui.schemaScreen_select_Font1_FontType.val()
        });

        this.model.set({
            Font: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font1_FontType, this.model.attributes.Font1_Select)
        });

        this.ui.schemaScreen_dropDown_Font1.trigger('setFont', this.model.attributes.Font);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font1_FontType)) {
            case false: this.ui.schemaScreen_formgroup_Font1.addClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font1.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.schemaScreen_formgroup_Font1.removeClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font1.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font2_FontType: function () {
        this.model.set({
            Font2_FontType: this.ui.schemaScreen_select_Font2_FontType.val()
        });

        this.model.set({
            Font: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font2_FontType, this.model.attributes.Font2_Select)
        });

        this.ui.schemaScreen_dropDown_Font2.trigger('setFont', this.model.attributes.Font);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font2_FontType)) {
            case false: this.ui.schemaScreen_formgroup_Font2.addClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font2.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.schemaScreen_formgroup_Font2.removeClass('disabledFontSelect_FormGroup');
                this.ui.schemaScreen_formgroup_Font2.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font1_ColorType: function () {
        this.model.set({
            Font1_ColorType: this.ui.schemaScreen_select_Font1_ColorType.val()
        });

        this.model.set({
            Font1_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font1_ColorType, this.model.attributes.Font1_ColorSelect)
        });

        this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable();
        this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font1_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            case true: this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.schemaScreen_ColorPickerSliders_Font1_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    change_Font2_ColorType: function () {
        this.model.set({
            Font2_ColorType: this.ui.schemaScreen_select_Font2_ColorType.val()
        });

        this.model.set({
            Font2_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font2_ColorType, this.model.attributes.Font2_ColorSelect)
        });

        this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable();
        this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font2_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            case true: this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.schemaScreen_ColorPickerSliders_Font2_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    colorChange_Font1_Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }
    },
    colorChange_Font2_Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font2_ColorType)) {
            this.model.attributes.Font2_ColorSelect = this.model.attributes.Font2_Color;
        }
    },
    colorChange_EvenColor: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Even_Color = e.value;
        }
        else {
            this.model.attributes.Even_Color = e.value.toRgbString();
        }

        /*if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }*/
    },
    colorChange_OddColor: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Odd_Color = e.value;
        }
        else {
            this.model.attributes.Odd_Color = e.value.toRgbString();
        }

        /*if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font1_ColorType)) {
            this.model.attributes.Font1_ColorSelect = this.model.attributes.Font1_Color;
        }*/
    },
    setModel: function () {

        var FeedType = this.model.attributes.FeedType;
        this.ui.schemaScreen_checkboxFeedType.is(':checked') ? FeedType = 1 : FeedType = 0;

        var a = this.ui.schemaScreen_dropDown_feedCount.val();

        this.model.set({
            Font1_ColorType: this.ui.schemaScreen_select_Font1_ColorType.val(),
            Font1_FontType: this.ui.schemaScreen_select_Font1_FontType.val(),
            Font1_Font: this.ui.schemaScreen_dropDown_Font1.val(),
            Font1_Size: this.ui.schemaScreen_dropDown_Font1_Size.val(),
            Font1_Style: this.ui.schemaScreen_dropDown_Font1_Style.val(),
            Font2_ColorType: this.ui.schemaScreen_select_Font2_ColorType.val(),
            Font2_FontType: this.ui.schemaScreen_select_Font2_FontType.val(),
            Font2_Font: this.ui.schemaScreen_dropDown_Font2.val(),
            Font2_Size: this.ui.schemaScreen_dropDown_Font2_Size.val(),
            Font2_Style: this.ui.schemaScreen_dropDown_Font2_Style.val(),
            Margin1_Top: this.ui.schemaScreen_input_Margin1_Top.val(),
            Margin1_Bottom: this.ui.schemaScreen_input_Margin1_Bottom.val(),
            Margin1_Left: this.ui.schemaScreen_input_Margin1_Left.val(),
            Margin1_Right: this.ui.schemaScreen_input_Margin1_Right.val(),
            Margin2_Top: this.ui.schemaScreen_input_Margin2_Top.val(),
            Margin2_Bottom: this.ui.schemaScreen_input_Margin2_Bottom.val(),
            Margin2_Left: this.ui.schemaScreen_input_Margin2_Left.val(),
            Margin2_Right: this.ui.schemaScreen_input_Margin2_Right.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    }
});