var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customInfopageList_empty"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="dias-tab" data-toggle="tab" href="#dias" role="tab" aria-controls="home-1" aria-selected="true">'+
((__t=( resources['X101313'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="css-tab" data-toggle="tab" href="#css" role="tab" aria-controls="profile-1" aria-selected="false">'+
((__t=( resources['X101314'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content" style="padding-left:0px; padding-right:0px;padding-top:0px"> <div class="tab-pane fade show active" id="dias" role="tabpanel" aria-labelledby="dias-tab"> <div class="media"> <div class="media-body"> <div class="card" style="background-color:rgb(234, 234, 234) !important"> <div class="card-body" style="padding-left:2px;padding-right:2px; padding-top:0px"> <div class="mt-4"> <div class="accordion accordion-bordered" id="accordion-1" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-14"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-14" aria-expanded="true" aria-controls="collapse-14"> '+
((__t=( resources['X101313'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-14" class="collapse show" role="tabpanel" aria-labelledby="heading-14" data-parent="#accordion-1"> <div class="card-body"> <div id="customInfopageEditItemList"></div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade" id="css" role="tabpanel" aria-labelledby="css-tab"> <div class="media"> <div class="media-body"> <div class="card" style="background-color:rgb(234, 234, 234) !important"> <div class="card-body" style="padding-left:2px;padding-right:2px; padding-top:0px"> <div class="mt-4"> <div class="accordion accordion-bordered" id="accordion-2" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-4"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4"> '+
((__t=( resources['X101360'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-4" class="collapse" role="tabpanel" aria-labelledby="heading-4" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_BackGroundType">'+
((__t=( resources['X101328'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_BackGroundType" class="form-control"></select> </div> <img id="customInfopageEditItemRightContent_image_background" class="mr-3 rounded" style="max-width:100%;width:100%;height:auto" src=""> <label type="button" for="customInfopageEditItemRightContent_btnImage" class="btn btn-sm col-sm-12 btn-secondary">'+
((__t=( resources['X100295'] ))==null?'':__t)+
'</label> <div> <input id="customInfopageEditItemRightContent_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div>  <div style="height:10px"></div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_backGroundImagePosition">'+
((__t=( resources['X101364'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_backGroundImagePosition" class="form-control"></select> </div> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColorApp">'+
((__t=( resources['X101359'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColor">'+
((__t=( resources['X101318'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_BackGroundColor" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div>  </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-5"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5"> '+
((__t=( resources['X101320'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-5" class="collapse" role="tabpanel" aria-labelledby="heading-5" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font1_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font1_FontType" class="form-control"></select> </div> <div id="customInfopageEditItemRightContent_formgroup_Font1" class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font1">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <input id="customInfopageEditItemRightContent_dropDown_Font1"> </div> <div class="form-group" style="display:none"> <label for="digitalClock_dropDown_Font1_App">'+
((__t=( resources['X101391'] ))==null?'':__t)+
'</label> <select disabled="disabled" id="customInfopageEditItemRightContent_dropDown_FontApp1" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font1_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font1_ColorType" class="form-control"></select> </div> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font1_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> <div class="form-group" style="display:none"> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font1_ColorApp">'+
((__t=( resources['X101363'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font1_ColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Size1">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Size1" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Style1">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Style1" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_LineHeight1">'+
((__t=( resources['X101327'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_LineHeight1" class="form-control"></select> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-6"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-6" aria-expanded="true" aria-controls="collapse-6"> '+
((__t=( resources['X101321'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-6" class="collapse" role="tabpanel" aria-labelledby="heading-6" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font2_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font2_FontType" class="form-control"></select> </div> <div id="customInfopageEditItemRightContent_formgroup_Font2" class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font2">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <input id="customInfopageEditItemRightContent_dropDown_Font2"> </div> <div class="form-group" style="display:none"> <label for="digitalClock_dropDown_Font2_App">'+
((__t=( resources['X101391'] ))==null?'':__t)+
'</label> <select disabled="disabled" id="customInfopageEditItemRightContent_dropDown_FontApp2" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font2_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font2_ColorType" class="form-control"></select> </div> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font2_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> <div class="form-group" style="display:none"> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font2_ColorApp">'+
((__t=( resources['X101363'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font2_ColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Size2">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Size2" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Style2">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Style2" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_LineHeight2">'+
((__t=( resources['X101327'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_LineHeight2" class="form-control"></select> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-7"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-7" aria-expanded="true" aria-controls="collapse-7"> '+
((__t=( resources['X101368'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-7" class="collapse" role="tabpanel" aria-labelledby="heading-7" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font3_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font3_FontType" class="form-control"></select> </div> <div id="customInfopageEditItemRightContent_formgroup_Font3" class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font3">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <input id="customInfopageEditItemRightContent_dropDown_Font3"> </div> <div class="form-group" style="display:none"> <label for="digitalClock_dropDown_Font3_App">'+
((__t=( resources['X101391'] ))==null?'':__t)+
'</label> <select disabled="disabled" id="customInfopageEditItemRightContent_dropDown_FontApp3" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font3_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font3_ColorType" class="form-control"></select> </div> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font3_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> <div class="form-group" style="display:none"> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font3_ColorApp">'+
((__t=( resources['X101363'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font3_ColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Size3">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Size3" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Style3">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Style3" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_LineHeight3">'+
((__t=( resources['X101327'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_LineHeight3" class="form-control"></select> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-8"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-8" aria-expanded="true" aria-controls="collapse-8"> '+
((__t=( resources['X101322'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-8" class="collapse" role="tabpanel" aria-labelledby="heading-8" data-parent="#accordion-2"> <div class="card-body"> <div class="form-group"> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font4_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font4_FontType" class="form-control"></select> </div> <div id="customInfopageEditItemRightContent_formgroup_Font4" class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font4">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <input id="customInfopageEditItemRightContent_dropDown_Font4"> </div> <div class="form-group" style="display:none"> <label for="digitalClock_dropDown_Font4_App">'+
((__t=( resources['X101391'] ))==null?'':__t)+
'</label> <select disabled="disabled" id="customInfopageEditItemRightContent_dropDown_FontApp4" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_select_Font4_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_select_Font4_ColorType" class="form-control"></select> </div> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font4_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> <div class="form-group" style="display:none"> <label for="customInfopageEditItemRightContent_ColorPickerSliders_Font4_ColorApp">'+
((__t=( resources['X101363'] ))==null?'':__t)+
'</label> <div id="customInfopageEditItemRightContent_ColorPickerSliders_Font4_ColorApp" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Size4">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Size4" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_Style4">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_Style4" class="form-control"></select> </div> <div class="form-group"> <label for="customInfopageEditItemRightContent_dropDown_Font_LineHeight4">'+
((__t=( resources['X101327'] ))==null?'':__t)+
'</label> <select id="customInfopageEditItemRightContent_dropDown_Font_LineHeight4" class="form-control"></select> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
