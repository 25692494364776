var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventTemplate_empty"> <div class="content-wrapper" data-help="customEventTemplate"> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customEventTemplateInformation" class="e4usInformation"></div> <h4 class="card-title">'+
((__t=( resources['X100244'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="control-group customEventTemplate_selectSubject"> <div class="form-group"> <label for="customEventTemplate_selectSubject">'+
((__t=( resources['X100248'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customEventTemplate_selectSubject" style="font-size:12px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </form> <div class="control-group customEventTemplate_inputName"> <div class="form-group"> <label for="customEventTemplate_inputName">'+
((__t=( resources['X101480'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customEventTemplate_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-12"> <div id="customEventTemplateList"></div> </div> <div class="row"> <div id="main-col" class="col-sm-12 col-md-12"> <div id="customEventTemplatePagination" style="text-align:center"></div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
