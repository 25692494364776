﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_officeExcel from './tpl/officeExcel.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';


import View_Module from './module';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({

    getTemplate: function () {
        return tpl_officeExcel();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.render, this);
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'officeExcel_officeExcel': '#officeExcel_officeExcel'
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        this.setupStyle();
    },
    onAttach() {
        this.setupSetting();
    },
    setupStyle() {
        var src = String.format(this.model.attributes.Data.Url, this.model.attributes.Data.InfoFile_FileUrl);
        this.ui.officeExcel_officeExcel.attr('src', src);
        //this.ui.officeExcel_officeExcel.attr('src', this.model.attributes.Data.VideoId);
    },
    onDestroy: function () {
    },
}, Backbone.Radio.Requests);
