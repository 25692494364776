﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_module from './tpl/module.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import config from '../../../common/config';
import View_Setting from '../grid/setting';
import View_SettingList from '../grid/settingList';
import View_Module from '../grid/module';
import View_ModuleList from '../grid/moduleList';
import * as Entities_Empty from '../../../lib/entities/empty';
import * as Entities_ModuleSetting from '../../entities/moduleSetting';

import View_HtmlSingle from './setting/htmlSingle';
import View_HtmlSlideList from './setting/htmlSlideList';
import View_PictureSlide from './setting/pictureSlide';
import View_PictureSingle from './setting/pictureSingle';
import View_Rss from './setting/rss';
import View_RssCustom from './setting/rssCustom';
import View_DigitalClock from './setting/digitalClock';
import View_AnalogClock from './setting/analogClock';
import View_IFrame from './setting/iframe';
import View_YouTube from './setting/youTube';
import View_Weather from './setting/weather';
import * as Entities_Memory_Position from '../../entities/memoryPosition';
import View_OfficeWord from './setting/officeWord';
import View_OfficeExcel from './setting/officeExcel';
import View_OfficePowerPoint from './setting/officePowerPoint';
import View_Pdf from './setting/pdf';
import View_Video from './setting/video';
import View_SchemaScreen from './setting/schemaScreen';
import View_AdSlide from './setting/adSlide';
import View_EventApp from './setting/eventApp';
import View_QRCode from './setting/qrcode';

import * as Entities_ModuleData from '../../entities/moduleData';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_module();
    },
    regions: {
    },
    initialize: function () {
        this.app = this.options.app;
        this.live = this.options.live;

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.settingModel = this.app.entities.collections.ModuleSetting.where({ id: this.model.attributes.ModuleSetting })[0];
        this.listenTo(this.settingModel, 'setupSetting', this.setupSetting, this);
        //this.listenTo(this.model, 'change', this.render, this);

        /*this.listenTo(userChannel, 'module:setupSetting', function () {
            self.setupSetting();
        });*/
    },
    ui: {
        'moduleDiv': '#moduleDiv',
        'moduleDiv_i': '#moduleDiv_i',
        'moduleEditDiv': '#moduleEditDiv',
        'settingDiv': '#settingDiv',
        'settingDiv_i': '#settingDiv_i',
        'emptyDiv': '#emptyDiv',
        'playDiv': '#playDiv',
        'playDiv_i': '#playDiv_i',
        'sizeDiv': '#sizeDiv',
        'sizeDiv_p': '#sizeDiv_p'
    },
    events: {
        'click @ui.moduleDiv': 'moduleDivClick',
        'click @ui.moduleEditDiv': 'moduleEditDivClick',
        'click @ui.settingDiv': 'settingDivClick',
        'click @ui.playDiv': 'playDivClick'
    },
    onRender: function () {
        this.setupSetting();
    },
    onAttach() {
        var height = this.ui.sizeDiv.closest(".moduleRegion").height();
        var width = this.ui.sizeDiv.closest(".moduleRegion").width();

        var text = width.toString().slice(0, -1) + '0' + 'X' + height.toString().slice(0, -1) + '0';
        this.ui.sizeDiv_p.text(text);

        this.setupSetting();
        this.setupLive();
    },
    setupSetting() {
        var self = this;

        var element = this.ui.sizeDiv.closest(".moduleRegion");

        //element.css('all','unset');
        //this.settingModel = this.app.entities.collections.ModuleSetting.where({ id: this.model.attributes.ModuleSetting })[0];

        var style = this.app.entities.collections.ModuleBorderStyle.where({ Value: this.settingModel.attributes.BorderStyle })[0].attributes.Style;

        if (this.settingModel.attributes.Border) {
            style.forEach(styleValue => element.css(styleValue.Name, styleValue.Value));
            element.css('border-color', this.settingModel.attributes.BorderColor);
            element.css('border-width', this.settingModel.attributes.BorderSize);
        }
        else {
            element.css('border-style', 'none');
        }

        /*var setting_margin = element.find('#setting_margin');
        setting_margin.css('margin-top', this.settingModel.attributes.MarginTop + 'px');
        setting_margin.css('margin-bottom', this.settingModel.attributes.MarginBottom + 'px');
        setting_margin.css('margin-left', this.settingModel.attributes.MarginLeft + 'px');
        setting_margin.css('margin-right', this.settingModel.attributes.MarginRight + 'px');*/

        switch (this.settingModel.attributes.BackGroundType) {
            case "1": element.css('background-color', this.settingModel.attributes.BackGroundColor); break;
            case "0": element.css('background-color', this.settingModel.attributes.BackGroundColorApp); break;
        }

        //  make every thing disaepper when 100%
        element.css('opacity', this.settingModel.attributes.BackGroundColorOpacity / 100);

        var style = self.app.entities.collections.ModuleBackGroundImagePosition.where({ Value: self.settingModel.attributes.BackGroundImagePosition })[0].attributes.Style;
        style.forEach(styleValue => element.css(styleValue.Name, styleValue.Value));

        switch (this.settingModel.attributes.BackGroundType) {
            case "0": element.css('background-image', 'none'); break;
            case "1": element.css('background-image', 'none'); break;
            case "2": element.css('background-image', String.format("url('{0}'", self.settingModel.attributes.BackGroundImage));
                break;
        }

        this.setupSpecific();
        this.setupPlay();
    },
    setupLive: function () {
        if (this.live) {
            this.ui.moduleDiv.css('display', 'None');
            this.ui.moduleEditDiv.css('display', 'None');
            this.ui.settingDiv.css('display', 'None');
            this.ui.emptyDiv.css('display', 'None');
            this.ui.playDiv.css('display', 'None');
            this.ui.sizeDiv.css('display', 'None');
        }
    },
    getEnablePlay: function (enablePlay) {
        var ret = enablePlay;
        switch (this.model.attributes.ModuleType) {
            case 0: break;
            case 1: break;
            case 2: break;
            case 3: break;
            case 4: break;
            case 5: break;
            case 6: break;
            case 7: break;
            case 8: break;
            case 15:
                ret = this.model.attributes.Data.EnablePlay; break;
                break;
            case 16:
                ret = this.model.attributes.Data.EnablePlay; break;
                break;
        }

        return ret;
    },
    setupSpecific: function () {
        var enablePlay = false;
        var enableSetting = false;

        var tmpModel = this.app.entities.collections.Module.where({ id: this.model.attributes.ModuleType })[0];

        switch (this.getEnablePlay(tmpModel.attributes.EnablePlay)) {
            case 0:
                this.ui.playDiv_i.addClass('e4us_fa_disabled');
                break;
            case 1:
                this.ui.playDiv_i.removeClass('e4us_fa_disabled');
                break;
        }

        switch (tmpModel.attributes.EnableSetting) {
            case 0:
                this.ui.settingDiv_i.addClass('e4us_fa_disabled');
                break;
            case 1:
                this.ui.settingDiv_i.removeClass('e4us_fa_disabled');
                break;
        }

        var dataModuleEnabled = this.ui.moduleDiv.closest(".moduleRegion").attr('data-module-enabled');
        switch (dataModuleEnabled) {
            case "0":
                this.ui.moduleDiv_i.addClass('e4us_fa_disabled');
                break;
            case "1":
                this.ui.moduleDiv_i.removeClass('e4us_fa_disabled');
                break;
        }
    },
    setupPlay: function () {
        var enablePlay = false;

        switch (this.model.attributes.Play) {
            case 1: this.ui.playDiv_i.removeClass('mdi-play').addClass('mdi-pause'); break;
            case 0: this.ui.playDiv_i.removeClass('mdi-pause').addClass('mdi-play'); break;
        }
    },
    onDestroy: function () {
    },
    moduleDivClick: function () {
        if (!this.ui.moduleDiv_i.hasClass('e4us_fa_disabled')) {
            var self = this;


            var dataModuleSupported = self.ui.moduleDiv.closest(".moduleRegion").attr('data-module-supported');
            var moduleSupported = dataModuleSupported.split(',');

            var moduleSettingCollection = new Entities_ModuleSetting.Collection(window.module.Module);

            moduleSettingCollection.each(function (model) {
                model.attributes.Selected = model.attributes.ModuleType == self.model.attributes.ModuleType ? true : false;
            });

            var moduleListModel = new Entities_Empty.Model();
            var moduleList = new View_ModuleList({ app: this.app, model: moduleListModel, parent: self, collection: moduleSettingCollection, moduleSupported: moduleSupported });

            var callback01 = function (options) {
                self.stopListening(moduleList, "dialog:btn01clicked", callback01);
                self.stopListening(moduleList, "dialog:btn02clicked", callback02);

                var selectedModel = moduleSettingCollection.where({ Selected: true })[0];

                var found = this.model.collection.where({
                    ModuleType: selectedModel.attributes.ModuleType, ModuleRegion: this.model.attributes.ModuleRegion
                });

                this.model.attributes.Active = false;

                if (found && found.length >= 1) {
                    //this.model = found[0];
                    found[0].attributes.Active = true;
                    found[0].collection.trigger('change');
                } else {

                    //Get from default
                    var found = self.app.entities.collections.Module.where({
                        ModuleType: selectedModel.attributes.ModuleType
                    });

                    if (found && found.length >= 1) {
                        var newModel = new Entities_ModuleData.Model();
                        newModel.id = 1223523532;
                        newModel.attributes.ModuleType = found[0].attributes.ModuleType;
                        newModel.attributes.ModuleRegion = this.model.attributes.ModuleRegion;
                        newModel.attributes.ModuleDiv = this.model.attributes.ModuleDiv;
                        newModel.attributes.Data = found[0].attributes.Data;
                        newModel.attributes.Active = true;
                        newModel.attributes.Play = 0;
                        newModel.attributes.Data = found[0].attributes.Data;
                        newModel.attributes.ModuleSetting = this.model.attributes.ModuleSetting;

                        this.model.collection.add(newModel);
                        this.model.collection.trigger('change');
                    }
                }
            }
            self.listenTo(moduleList, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(moduleList, "dialog:btn01clicked", callback01);
                self.stopListening(moduleList, "dialog:btn02clicked", callback02);
            }
            self.listenTo(moduleList, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(moduleList);
        }
    },
    moduleEditDivClick: function () {
        var self = this;

        //Set selected model in the collection
        var selectedModel = this.model.attributes.ModuleSetting;

        this.app.entities.collections.ModuleSetting.each(function (model) {
            model.attributes.Selected = model.id == selectedModel ? 1 : 0;
        });

        var settingListModel = new Entities_Empty.Model();
        var settingList = new View_SettingList({ app: this.app, model: settingListModel, parent: self, collection: this.app.entities.collections.ModuleSetting });

        var callback01 = function (options) {
            self.stopListening(settingList, "dialog:btn01clicked", callback01);
            self.stopListening(settingList, "dialog:btn02clicked", callback02);

            var tmpModel = self.app.entities.collections.ModuleSetting.where({ 'Selected': 1 });
            if (tmpModel.length > 0) {
                if (self.model.attributes.ModuleSetting != tmpModel[0].attributes.id) {
                    self.settingModel = this.app.entities.collections.ModuleSetting.where({ id: tmpModel[0].attributes.id })[0];
                }
                self.model.attributes.ModuleSetting = tmpModel[0].attributes.id;

                //Trigger all the model with the same ModuleSetting
                self.model.collection.forEach(function (entry) {
                    var settingModel = self.app.entities.collections.ModuleSetting.where({ id: entry.attributes.ModuleSetting })[0];
                    settingModel.trigger('setupSetting');
                    entry.trigger('setupSetting');
                });
            }
        }
        self.listenTo(settingList, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(settingList, "dialog:btn01clicked", callback01);
            self.stopListening(settingList, "dialog:btn02clicked", callback02);
        }
        self.listenTo(settingList, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(settingList);
    },
    settingDivClick: function () {
        if (!this.ui.settingDiv_i.hasClass('e4us_fa_disabled')) {
            this.model.attributes.Play = 0;
            this.setupPlay();


            switch (this.model.attributes.ModuleType) {
                case 0: break;
                case 1: this.setting_htmlSlide(); break;
                case 2: this.setting_pictureSlide(); break;
                case 3: this.setting_rss(); break;
                case 4: this.setting_digitalClock(); break;
                case 5: this.setting_analogClock(); break;
                case 6: this.setting_iframe(); break;
                case 7: this.aaa(); break;
                case 8: this.setting_weather(); break;
                case 9: this.setting_htmlSingle(); break;
                case 10: this.setting_pictureSingle(); break;
                case 11: this.setting_rssCustom(); break;
                case 12: this.setting_officeWord(); break;
                case 13: this.setting_officeExcel(); break;
                case 14: this.setting_officePowerPoint(); break;
                case 15: this.setting_pdf(); break;
                case 16: this.setting_video(); break;
                case 17: this.setting_schemaScreen(); break;
                case 18: this.setting_adSlide(); break;
                case 19: this.setting_eventApp(); break;
                case 20: this.setting_qrcode(); break;
            }
        }
    },
    playDivClick: function () {
        var ModuleDiv = this.model.attributes.ModuleDiv;
        var ModuleRegion = this.model.attributes.ModuleRegion;

        switch (this.model.attributes.ModuleType) {
            case 0: break;
            case 1:
                switch (this.model.attributes.Play) {
                    case 0: this.model.attributes.Play = 1; this.setupPlay(); break;
                    case 1: this.model.attributes.Play = 0; this.setupPlay(); break;
                }
                this.dataCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);
                var oldSelected = this.dataCollection.where({ Selected: true })[0].id;

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('htmlSlide:play', ModuleDiv, ModuleRegion, oldSelected);//this.options.parent);
                break;
            case 2: break;
            case 3: break;
            case 4: break;
            case 5: break;
            case 6: break;
            case 7: break;
            case 8: break;
            case 15:
                switch (this.model.attributes.Play) {
                    case 0: this.model.attributes.Play = 1; this.setupPlay(); break;
                    case 1: this.model.attributes.Play = 0; this.setupPlay(); break;
                }
                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('pdf:play', /*this.options.parent*/this.model.attributes.ModuleDiv, this.model.attributes.ModuleRegion);
                break;
            case 16:
                switch (this.model.attributes.Play) {
                    case 0: this.model.attributes.Play = 1; this.setupPlay(); break;
                    case 1: this.model.attributes.Play = 0; this.setupPlay(); break;
                }
                var userChannel = Backbone.Radio.channel('app');
                //userChannel.trigger('video:play', this.options.parent);
                userChannel.trigger('video:play', this.model.attributes.ModuleDiv, this.model.attributes.ModuleRegion);

                break;

        }
    },
    compare: function (a, b) {
        if (a.Position < b.Position) {
            return -1;
        }
        if (a.Position > b.Position) {
            return 1;
        }
        return 0;
    },
    setting_htmlSlide: function () {
        var self = this;

        //Set selected model in the collection
        var selectedModel = 1;


        var ModuleDiv = this.model.attributes.ModuleDiv;

        this.dataCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);
        var oldSelected = this.dataCollection.where({ Selected: true })[0].id;

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('htmlSlide:changeModelBefore', ModuleDiv, oldSelected);//this.options.parent, oldSelected);

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);
        var tmpCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);
        tmpCollection.sortByPosition();

        //this.dataCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);

        //var moduleSettingCollection = new Entities_ModuleSetting.Collection(window.module.ModuleSetting);

        /*moduleSettingCollection.fullCollection.each(function (model) {
            model.attributes.Selected = model.id == selectedModel ? 1 : 0;
        });*/

        var htmlSlideList = new View_HtmlSlideList({ app: this.app, model: tmpModel, parentModel: this.model, parent: self, collection: tmpCollection });


        var callback01 = function (options) {
            self.stopListening(htmlSlideList, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlideList, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = htmlSlideList.model.toJSON();
            self.model.attributes.Data.Collection = htmlSlideList.collection.toJSON();
            self.model.attributes.Data.Collection.sort(this.compare);
            //self.model.attributes.Data.Collection = self.dataCollection.toJSON();

            self.model.trigger('setupSetting');

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('htmlSlide:changeModelAfter', ModuleDiv, oldSelected);//this.options.parent, oldSelected);

        }
        self.listenTo(htmlSlideList, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(htmlSlideList, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlideList, "dialog:btn02clicked", callback02);
        }
        self.listenTo(htmlSlideList, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(htmlSlideList);
    },
    setting_htmlSingle: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);
        var htmlSingle = new View_HtmlSingle({ app: this.app, model: tmpModel, parentModel: this.model, parent: self });


        var callback01 = function (options) {
            self.stopListening(htmlSingle, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSingle, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = htmlSingle.model.toJSON();
            self.model.trigger('setupSetting');
            //self.model.trigger('change');
        }
        self.listenTo(htmlSingle, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(htmlSingle, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSingle, "dialog:btn02clicked", callback02);
        }
        self.listenTo(htmlSingle, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(htmlSingle);
    },
    setting_pictureSlide: function () {
        var self = this;

        var selectedModel = 1;
        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);
        var tmpCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);
        tmpCollection.sortByPosition();

        var pictureSlide = new View_PictureSlide({ app: this.app, model: tmpModel, parent: self, collection: tmpCollection });

        var callback01 = function (options) {
            self.stopListening(pictureSlide, "dialog:btn01clicked", callback01);
            self.stopListening(pictureSlide, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = pictureSlide.model.toJSON();
            self.model.attributes.Data.Collection = pictureSlide.collection.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(pictureSlide, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(pictureSlide, "dialog:btn01clicked", callback01);
            self.stopListening(pictureSlide, "dialog:btn02clicked", callback02);
        }
        self.listenTo(pictureSlide, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(pictureSlide);
    },
    setting_pictureSingle: function () {
        var self = this;

        var selectedModel = 1;
        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);
        var tmpCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);

        var pictureSingle = new View_PictureSingle({ app: this.app, model: tmpModel, parent: self, collection: tmpCollection });

        var callback01 = function (options) {
            self.stopListening(pictureSingle, "dialog:btn01clicked", callback01);
            self.stopListening(pictureSingle, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = pictureSingle.model.toJSON();
            self.model.attributes.Data.Collection = pictureSingle.collection.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(pictureSingle, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(pictureSingle, "dialog:btn01clicked", callback01);
            self.stopListening(pictureSingle, "dialog:btn02clicked", callback02);
        }
        self.listenTo(pictureSingle, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(pictureSingle);
    },
    setting_rss: function () {
        var self = this;

        //Set selected model in the collection
        var selectedModel = 1;

        //this.dataCollection = new Backbone.Collection(this.model.attributes.Data);
        //var oldSelected = this.dataCollection.where({ Selected: true })[0].id;


        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var rss = new View_Rss({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });


        var callback01 = function (options) {
            self.stopListening(rss, "dialog:btn01clicked", callback01);
            self.stopListening(rss, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = rss.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(rss, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(rss, "dialog:btn01clicked", callback01);
            self.stopListening(rss, "dialog:btn02clicked", callback02);
        }
        self.listenTo(rss, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(rss);
    },
    setting_rssCustom: function () {
        var self = this;

        //Set selected model in the collection
        var selectedModel = 1;

        //this.dataCollection = new Backbone.Collection(this.model.attributes.Data);
        //var oldSelected = this.dataCollection.where({ Selected: true })[0].id;


        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var rssCustom = new View_RssCustom({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });


        var callback01 = function (options) {
            self.stopListening(rssCustom, "dialog:btn01clicked", callback01);
            self.stopListening(rssCustom, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = rssCustom.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(rssCustom, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(rssCustom, "dialog:btn01clicked", callback01);
            self.stopListening(rssCustom, "dialog:btn02clicked", callback02);
        }
        self.listenTo(rssCustom, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(rssCustom);
    },
    setting_digitalClock: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var digitalClock = new View_DigitalClock({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(digitalClock, "dialog:btn01clicked", callback01);
            self.stopListening(digitalClock, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = digitalClock.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(digitalClock, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(digitalClock, "dialog:btn01clicked", callback01);
            self.stopListening(digitalClock, "dialog:btn02clicked", callback02);
        }
        self.listenTo(digitalClock, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(digitalClock);
    },
    setting_analogClock: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var analogClock = new View_AnalogClock({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(analogClock, "dialog:btn01clicked", callback01);
            self.stopListening(analogClock, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = analogClock.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(analogClock, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(analogClock, "dialog:btn01clicked", callback01);
            self.stopListening(analogClock, "dialog:btn02clicked", callback02);
        }
        self.listenTo(analogClock, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(analogClock);
    },
    setting_iframe: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var iframe = new View_IFrame({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(iframe, "dialog:btn01clicked", callback01);
            self.stopListening(iframe, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = iframe.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(iframe, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(iframe, "dialog:btn01clicked", callback01);
            self.stopListening(iframe, "dialog:btn02clicked", callback02);
        }
        self.listenTo(iframe, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(iframe);
    },
    aaa: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var youTube = new View_YouTube({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(youTube, "dialog:btn01clicked", callback01);
            self.stopListening(youTube, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = youTube.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(youTube, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(youTube, "dialog:btn01clicked", callback01);
            self.stopListening(youTube, "dialog:btn02clicked", callback02);
        }
        self.listenTo(youTube, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(youTube);
    },
    setting_weather: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var weather = new View_Weather({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(weather, "dialog:btn01clicked", callback01);
            self.stopListening(weather, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = weather.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(weather, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(weather, "dialog:btn01clicked", callback01);
            self.stopListening(weather, "dialog:btn02clicked", callback02);
        }
        self.listenTo(weather, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(weather);
    },
    setting_officeWord: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var officeWord = new View_OfficeWord({ app: this.app, model: tmpModel, parent: self, collection: this.app.entities.collections.customInfoFile/*collection: this.dataCollection*/ });

        var callback01 = function (options) {
            self.stopListening(officeWord, "dialog:btn01clicked", callback01);
            self.stopListening(officeWord, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = officeWord.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(officeWord, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(officeWord, "dialog:btn01clicked", callback01);
            self.stopListening(officeWord, "dialog:btn02clicked", callback02);
        }
        self.listenTo(officeWord, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(officeWord);
    },
    setting_officeExcel: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var officeExcel = new View_OfficeExcel({ app: this.app, model: tmpModel, parent: self, collection: this.app.entities.collections.customInfoFile/*collection: this.dataCollection*/ });

        var callback01 = function (options) {
            self.stopListening(officeExcel, "dialog:btn01clicked", callback01);
            self.stopListening(officeExcel, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = officeExcel.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(officeExcel, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(officeExcel, "dialog:btn01clicked", callback01);
            self.stopListening(officeExcel, "dialog:btn02clicked", callback02);
        }
        self.listenTo(officeExcel, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(officeExcel);
    },
    setting_officePowerPoint: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var officePowerPoint = new View_OfficePowerPoint({ app: this.app, model: tmpModel, parent: self, collection: this.app.entities.collections.customInfoFile/*collection: this.dataCollection*/ });

        var callback01 = function (options) {
            self.stopListening(officePowerPoint, "dialog:btn01clicked", callback01);
            self.stopListening(officePowerPoint, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = officePowerPoint.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(officePowerPoint, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(officePowerPoint, "dialog:btn01clicked", callback01);
            self.stopListening(officePowerPoint, "dialog:btn02clicked", callback02);
        }
        self.listenTo(officePowerPoint, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(officePowerPoint);
    },
    setting_pdf: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var pdf = new View_Pdf({ app: this.app, model: tmpModel, parent: self, collection: this.app.entities.collections.customInfoFile/*collection: this.dataCollection*/ });

        var callback01 = function (options) {
            self.stopListening(pdf, "dialog:btn01clicked", callback01);
            self.stopListening(pdf, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = pdf.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(pdf, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(pdf, "dialog:btn01clicked", callback01);
            self.stopListening(pdf, "dialog:btn02clicked", callback02);
        }
        self.listenTo(pdf, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(pdf);
    },
    setting_video: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var video = new View_Video({ app: this.app, model: tmpModel, parent: self, collection: this.app.entities.collections.customInfoFile/*collection: this.dataCollection*/ });

        var callback01 = function (options) {
            self.stopListening(video, "dialog:btn01clicked", callback01);
            self.stopListening(video, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = video.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(video, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(video, "dialog:btn01clicked", callback01);
            self.stopListening(video, "dialog:btn02clicked", callback02);
        }
        self.listenTo(video, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(video);
    },
    setting_schemaScreen: function () {
        var self = this;

        //Set selected model in the collection
        var selectedModel = 1;

        //this.dataCollection = new Backbone.Collection(this.model.attributes.Data);
        //var oldSelected = this.dataCollection.where({ Selected: true })[0].id;


        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var schemaScreen = new View_SchemaScreen({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });


        var callback01 = function (options) {
            self.stopListening(schemaScreen, "dialog:btn01clicked", callback01);
            self.stopListening(schemaScreen, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = schemaScreen.model.toJSON();
            self.model.trigger('setupSetting');
        }
        self.listenTo(schemaScreen, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(schemaScreen, "dialog:btn01clicked", callback01);
            self.stopListening(schemaScreen, "dialog:btn02clicked", callback02);
        }
        self.listenTo(schemaScreen, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(schemaScreen);
    },
    setting_adSlide: function () {
        var self = this;

        var selectedModel = 1;
        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);
        var tmpCollection = new Entities_Memory_Position.Collection(this.model.attributes.Data.Collection);
        tmpCollection.sortByPosition();

        var adSlide = new View_AdSlide({ app: this.app, model: tmpModel, parent: self, collection: tmpCollection });

        var callback01 = function (options) {
            self.stopListening(adSlide, "dialog:btn01clicked", callback01);
            self.stopListening(adSlide, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = adSlide.model.toJSON();
            self.model.attributes.Data.Collection = adSlide.collection.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(adSlide, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(adSlide, "dialog:btn01clicked", callback01);
            self.stopListening(adSlide, "dialog:btn02clicked", callback02);
        }
        self.listenTo(adSlide, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(adSlide);
    },
    setting_eventApp: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var eventApp = new View_EventApp({ app: this.app, model: tmpModel, parent: self, collection: this.app.entities.collections.customInfoFile/*collection: this.dataCollection*/ });

        var callback01 = function (options) {
            self.stopListening(eventApp, "dialog:btn01clicked", callback01);
            self.stopListening(eventApp, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = eventApp.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(eventApp, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(eventApp, "dialog:btn01clicked", callback01);
            self.stopListening(eventApp, "dialog:btn02clicked", callback02);
        }
        self.listenTo(eventApp, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(eventApp);
    },
    setting_qrcode: function () {
        var self = this;

        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        var qrcode = new View_QRCode({ app: this.app, model: tmpModel, parent: self, collection: this.dataCollection });

        var callback01 = function (options) {
            self.stopListening(qrcode, "dialog:btn01clicked", callback01);
            self.stopListening(qrcode, "dialog:btn02clicked", callback02);

            self.model.attributes.Data = qrcode.model.toJSON();
            self.model.trigger('change');
        }
        self.listenTo(qrcode, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(qrcode, "dialog:btn01clicked", callback01);
            self.stopListening(qrcode, "dialog:btn02clicked", callback02);
        }
        self.listenTo(qrcode, "dialog:btn02clicked", callback02);

        self.app.dialogRegion.show(qrcode);
    },
    settingDivClick1: function () {
        var self = this;

        var htmlSlideModel = new Entities_Empty.Model();

        htmlSlideModel.attributes.Name = this.model.attributes.Name;
        htmlSlideModel.attributes.BackGroundType = "1";
        htmlSlideModel.attributes.BackGroundImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAqCAIAAAB6LU8tAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFPSURBVFhHzZWxjcMwDEVdpHATIIBxq2Qfb+MlsoGbLOE2dXZwlQUuF+uQE58tiZQPYIDXPFmACH9RbL4fnwXdHbo7dHfo7tDdobtDd4fuDt0dujt0G+Px0nwNEZfhwD1G6Cbuvahm4XRfbTNBt9BeWc2L64htNugGxhNK+aVvudMCXU+UVzcNcXG7UqOrifI6H2cZ357U6ErmoXsfHzpLXPAdqdF1HKbz+/huui2L4krVp0ZXcYuen1deYf1/UqNrWOcVEKn9FWqDrmArr4BITX5SQy+znVdApFY3RuhFUnktxD+vMjV6CXHk+ubG5dalRi+QGhcJKlKj59ka71nsqdGzbI/3PNYHiZ5D+RbHbfiDcYzQM6inlew14xihpzFMBtlrttToSUyv8I7U6CnmuKDyATK1vtX3Gt0dujt0d+ju0N2hu0N3h+4O3ZlH8wQP3TsorYs4YQAAAABJRU5ErkJggg==";
        htmlSlideModel.attributes.BackGroundColor = "rgb(67, 81, 21)";
        htmlSlideModel.attributes.BackGroundColorApp = "rgb(121, 121, 121)";
        htmlSlideModel.attributes.BorderColor = "rgb(167, 181, 121)";
        htmlSlideModel.attributes.BackGroundColorOpacity = 30;
        htmlSlideModel.attributes.Border = true;
        htmlSlideModel.attributes.BorderStyle = 1;
        htmlSlideModel.attributes.BorderSize = "50px";
        htmlSlideModel.attributes.MarginTop = 10;
        htmlSlideModel.attributes.MarginBottom = 20;
        htmlSlideModel.attributes.MarginLeft = 30;
        htmlSlideModel.attributes.MarginRight = 40;

        var htmlSlide = new View_HtmlSlide({ app: this.app, model: htmlSlideModel, parent: self });

        var callback01 = function () {
            self.stopListening(htmlSlide, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlide, "dialog:btn02clicked", callback02);

            /*self.model.set({ Name: settingModel.attributes.Name });
            self.model.set({ BackGroundType: settingModel.attributes.BackGroundType });
            self.model.set({ BackGroundImage: settingModel.attributes.BackGroundImage });
            self.model.set({ BackGroundColor: settingModel.attributes.BackGroundColor });
            self.model.set({ BackGroundColorApp: settingModel.attributes.BackGroundColorApp });
            self.model.set({ BorderColor: settingModel.attributes.BorderColor });
            self.model.set({ BackGroundColorOpacity: settingModel.attributes.BackGroundColorOpacity });
            self.model.set({ Border: settingModel.attributes.Border });
            self.model.set({ BorderStyle: settingModel.attributes.BorderStyle });
            self.model.set({ BorderSize: settingModel.attributes.BorderSize });
            self.model.set({ MarginTop: settingModel.attributes.MarginTop });
            self.model.set({ MarginBottom: settingModel.attributes.MarginBottom });
            self.model.set({ MarginLeft: settingModel.attributes.MarginLeft });
            self.model.set({ MarginRight: settingModel.attributes.MarginRight });*/
        }
        this.listenTo(htmlSlide, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(htmlSlide, "dialog:btn01clicked", callback01);
            self.stopListening(htmlSlide, "dialog:btn02clicked", callback02);

        }
        this.listenTo(htmlSlide, "dialog:btn02clicked", callback02);


        self.app.dialogRegion.show(htmlSlide);
    }
}, Backbone.Radio.Requests);
