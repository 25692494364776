﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

//import * as SignalR from 'signalr';

import tpl_render from './tpl/render.html';
import View_Dialog2Process from '../../../lib/views/dialog2Process/dialog2Process';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';
//import * as md5 from 'js-md5';
import * as Entities_CustomPush from '../../entities/customPush';


import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_render();
    },
    initialize: function () {
        this.app = this.options.app;

        this.localSetting = new Entities_Empty.Model();
        this.model = new Entities_Empty.Model();

        this.signalR_init();
    },
    ui: {
        'customEventRender_container': '#customEventRender_container',
        'customEventRender_qrCodeLogo': '#customEventRender_qrCodeLogo',
        'customEventRender_qrCodeContainer': '#customEventRender_qrCodeContainer',
        'customEventRender_iframeHide': '#customEventRender_iframeHide',
        'customEventRender_iframe': '#customEventRender_iframe'
    },
    handlingWebpageImageUrl: function (count, publish, eventUpdate) {

        var array = {};

        var customSetting = this.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });

        array.count = count;
        array.generate = false;

        //0 - Standard          (Ask before update)
        //1 - Publish           (Never ask, and only update when publish button is used)
        //2 - Automatisk        (Never ask, and only update automatic)
        //3 - Never             (Never ask, never update)

        if (eventUpdate) {
            array.ask = false;
            array.generate = true;
        } else {
            switch (customSetting[0].attributes.Value) {
                case '0':
                    if (array.count == 0) {
                        array.ask = false;
                    } else {
                        array.ask = true;
                    }
                    break;
                case '1': array.ask = false;
                    if ((array.count > 0) && (publish)) {
                        array.generate = true;
                    }
                    break;
                case '2': array.ask = false;
                    if (array.count > 0) {
                        array.generate = true;
                    }
                    break;
                case '3': array.ask = false;
                    break;

            }
        }

        return array;
    },
    preCalcChecksumWebpageImageUrl: function () {
        var buildWebpageImageUrlCount = 0;

        for (var i = 0; i < this.collection.length; i++) {
            var customEvent = app.entities.collections.customEvent.fullCollection.where({ id: this.collection[i].attributes.id });
            var customWebpage = this.app.entities.collections.customWebpage.fullCollection.where({ id: customEvent[0].attributes.Webpage });
            var customWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage });
            var customQRCode = app.entities.collections.customQRCode.fullCollection.where({ id: customEvent[0].attributes.QRCode });

            var checksumWebpageImageIndex = this.getChecksumWebpageImageIndex(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
            var checksumWebpageImageUrl = this.getChecksumWebpageImageUrl(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);

            if ((checksumWebpageImageIndex != customEvent[0].attributes.ChecksumWebpageImageIndex) || (checksumWebpageImageUrl != customEvent[0].attributes.ChecksumWebpageImageUrl)) {
                buildWebpageImageUrlCount += 1;
            }
        }

        return buildWebpageImageUrlCount;
    },
    getChecksumEventHeader: function (customEvent, customWebpage, customWebpageItem, customQRCode) {
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_Webpage = customEvent.attributes.Webpage;
        var criteria_CustomEvent_Infopage = customEvent.attributes.Infopage;
        var criteria_CustomEvent_SchemaModel = customEvent.attributes.SchemaModel;
        var criteria_CustomEvent_SchemaMenuName = customEvent.attributes.SchemaMenuName;
        var criteria_CustomEvent_FormModel = customEvent.attributes.FormModel;
        var criteria_CustomEvent_FormMenuName = customEvent.attributes.FormMenuName;
        var criteria_CustomEvent_HelpBtn = customEvent.attributes.HelpBtn;
        var criteria_CustomEvent_HomeBtn = customEvent.attributes.HomeBtn;
        var criteria_CustomEvent_Publicly = customEvent.attributes.Publicly;

        var criteria_CustomEvent_Module_QRCode = customEvent.attributes.Module_QRCode;
        var criteria_CustomEvent_Module_Facebook = customEvent.attributes.Module_Facebook;
        var criteria_CustomEvent_Module_SchemaModel = customEvent.attributes.Module_SchemaModel;
        var criteria_CustomEvent_Module_FormModel = customEvent.attributes.Module_FormModel;
        var criteria_CustomEvent_Module_ProductSheetModel = customEvent.attributes.Module_ProductSheetModel;
        var criteria_CustomEvent_Module_Ad = customEvent.attributes.Module_Ad;

        // css for info page is in the menu header for events
        var criteria_CustomEvent_Infopage_Theme = '';
        if (customEvent.attributes.Infopage != -1) {
            var customWebpage_info = this.app.entities.collections.customInfopage.fullCollection.where({ id: customEvent.attributes.Infopage });
            if (customWebpage_info.length > 0) {
                criteria_CustomEvent_Infopage_Theme = customWebpage_info[0].attributes.Theme;
            }
        }

        var criteria_CustomWebpageItem_Body = '';
        customWebpageItem.forEach(function (model) {
            criteria_CustomWebpageItem_Body += model.attributes.Body;
        });

        var criteria_CustomWebpageItem_Name = '';
        customWebpageItem.forEach(function (model) {
            criteria_CustomWebpageItem_Name += model.attributes.Name;
        });

        var criteria_CustomWebpageItem_Position = '';
        customWebpageItem.forEach(function (model) {
            criteria_CustomWebpageItem_Position += (model.attributes.id + model.attributes.Position);
        });

        var criteria_CustomWebpageItem_Css = '';
        customWebpageItem.forEach(function (model) {
            criteria_CustomWebpageItem_Css += model.attributes.Css;
        });

        var criteria_CustomWebpageItem_Status = '';
        customWebpageItem.forEach(function (model) {
            criteria_CustomWebpageItem_Status += model.attributes.Status;
        });

        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_id +
            criteria_CustomEvent_Webpage +
            criteria_CustomEvent_Infopage +
            criteria_CustomEvent_SchemaModel +
            criteria_CustomEvent_SchemaMenuName +
            criteria_CustomEvent_FormModel +
            criteria_CustomEvent_FormMenuName +
            criteria_CustomEvent_HelpBtn +
            criteria_CustomEvent_HomeBtn +
            criteria_CustomEvent_Publicly +
            criteria_CustomEvent_Module_QRCode +
            criteria_CustomEvent_Module_Facebook +
            criteria_CustomEvent_Module_SchemaModel +
            criteria_CustomEvent_Module_FormModel +
            criteria_CustomEvent_Module_ProductSheetModel +
            criteria_CustomEvent_Module_Ad +
            criteria_CustomEvent_Infopage_Theme +
            criteria_CustomWebpageItem_Body +
            criteria_CustomWebpageItem_Name +
            criteria_CustomWebpageItem_Position +
            criteria_CustomWebpageItem_Css +
            criteria_CustomWebpageItem_Status;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumEventTemplate: function (customEvent, customWebpage, customWebpageItem, customQRCode) {
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_Webpage = customEvent.attributes.Webpage;
        var criteria_CustomEvent_SchemaModel = customEvent.attributes.SchemaModel;
        //var criteria_CustomEvent_SchemaMenuName = customEvent.attributes.SchemaMenuName;
        var criteria_CustomEvent_FormModel = customEvent.attributes.FormModel;
        //var criteria_CustomEvent_FormMenuName = customEvent.attributes.FormMenuName;
        //var criteria_CustomEvent_Copyright = customEvent.attributes.Copyright;
        //var criteria_CustomEvent_SchemaBigScreenShowQRCode = customEvent.attributes.SchemaBigScreenShowQRCode;

        var criteria_CustomWebpageItem_Body = '';
        customWebpageItem.forEach(function (model) {
            criteria_CustomWebpageItem_Body += model.attributes.Body;
        });

        //If add a new item with a empty template, still generate that template so it can find int on app
        var criteria_CustomWebpageItem_Length = customWebpageItem.length;

        //var criteria_CustomWebpageItem_Name = '';
        //customWebpageItem.forEach(function (model) {
        //    criteria_CustomWebpageItem_Name += model.attributes.Name;
        //});

        //var criteria_CustomWebpageItem_Position = '';
        //customWebpageItem.forEach(function (model) {
        //    criteria_CustomWebpageItem_Position += model.attributes.Position;
        //});

        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_id +
            criteria_CustomEvent_Webpage +
            criteria_CustomEvent_SchemaModel +
            //criteria_CustomEvent_SchemaMenuName +
            criteria_CustomEvent_FormModel +
            //criteria_CustomEvent_FormMenuName +
            //criteria_CustomEvent_Copyright +
            //criteria_CustomEvent_SchemaBigScreenShowQRCode +
            criteria_CustomWebpageItem_Body +
            criteria_CustomWebpageItem_Length;// +
        //criteria_CustomWebpageItem_Name;// +
        //criteria_CustomWebpageItem_Position;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumEventInfoHeader: function (customEvent, customWebpage_info, customWebpageItem_info, customQRCode) {
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_Infopage = customEvent.attributes.Infopage;

        var criteria_CustomWebpageItem_info_Body = '';
        customWebpageItem_info.forEach(function (model) {
            criteria_CustomWebpageItem_info_Body += model.attributes.Body;
        });

        var criteria_CustomWebpageItem_info_Name = '';
        customWebpageItem_info.forEach(function (model) {
            criteria_CustomWebpageItem_info_Name += model.attributes.Name;
        });

        var criteria_CustomWebpageItem_info_Position = '';
        customWebpageItem_info.forEach(function (model) {
            criteria_CustomWebpageItem_info_Position += model.attributes.Position;
        });

        var criteria_CustomWebpageItem_info_Duration = '';
        customWebpageItem_info.forEach(function (model) {
            criteria_CustomWebpageItem_info_Duration += model.attributes.Duration;
        });

        var criteria_CustomWebpageItem_info_Status = '';
        customWebpageItem_info.forEach(function (model) {
            criteria_CustomWebpageItem_info_Status += model.attributes.Status;
        });

        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_id +
            criteria_CustomEvent_Infopage +
            criteria_CustomWebpageItem_info_Body +
            criteria_CustomWebpageItem_info_Name +
            criteria_CustomWebpageItem_info_Position +
            criteria_CustomWebpageItem_info_Duration +
            criteria_CustomWebpageItem_info_Status;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumEventInfoTemplate: function (customEvent, customWebpage_info, customWebpageItem_info, customQRCode) {
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_Infopage = customEvent.attributes.Infopage;
        //var criteria_CustomEvent_Copyright = customEvent.attributes.Copyright;
        //var criteria_CustomEvent_InfopageShowQRCode = customEvent.attributes.InfopageShowQRCode;


        var criteria_CustomWebpageItem_info_Body = '';
        customWebpageItem_info.forEach(function (model) {
            criteria_CustomWebpageItem_info_Body += model.attributes.Body;
        });

        //var criteria_CustomWebpageItem_info_Name = '';
        //customWebpageItem_info.forEach(function (model) {
        //    criteria_CustomWebpageItem_info_Name += model.attributes.Name;
        //});

        //var criteria_CustomWebpageItem_info_Position = '';
        //customWebpageItem_info.forEach(function (model) {
        //    criteria_CustomWebpageItem_info_Position += model.attributes.Position;
        //});

        //var criteria_CustomWebpageItem_info_Duration = '';
        //customWebpageItem_info.forEach(function (model) {
        //    criteria_CustomWebpageItem_info_Duration += model.attributes.Duration;
        //});

        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_id +
            criteria_CustomEvent_Infopage +
            //criteria_CustomEvent_Copyright +
            //criteria_CustomEvent_InfopageShowQRCode +
            criteria_CustomWebpageItem_info_Body;// +
        //criteria_CustomWebpageItem_info_Name +
        //criteria_CustomWebpageItem_info_Position + 
        //criteria_CustomWebpageItem_info_Duration;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumEventIndex: function (customEvent, customWebpage, customWebpageItem, customQRCode) {
        var criteria_CustomEvent_Name = customEvent.attributes.Name;
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        //var criteria_CustomEvent_Description = customEvent.attributes.Description;
        var criteria_CustomEvent_FriendlyUrl = customEvent.attributes.FriendlyUrl;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_SchemaModel = customEvent.attributes.SchemaModel;
        var criteria_CustomEvent_FormModel = customEvent.attributes.FormModel;
        //var criteria_CustomEvent_FormSubmitBtnName = customEvent.attributes.FormSubmitBtnName;
        //var criteria_CustomEvent_FormCancelBtnName = customEvent.attributes.FormCancelBtnName;
        //var criteria_CustomEvent_FormSubmitBtnSucces = customEvent.attributes.FormSubmitBtnSucces;
        var criteria_CustomEvent_Publicly = customEvent.attributes.Publicly;
        var criteria_CustomEvent_Facebook = customEvent.attributes.Facebook;
        var criteria_CustomEvent_Copyright = customEvent.attributes.Copyright;
        var criteria_CustomEvent_Infopage = customEvent.attributes.Infopage;
        var criteria_CustomEvent_InfopageShowQRCode = customEvent.attributes.InfopageShowQRCode;
        var criteria_CustomEvent_SchemaBigScreenShowQRCode = customEvent.attributes.SchemaBigScreenShowQRCode;


        //criteria_CustomEvent_Copyright +
        // +

        var criteria_CustomEvent_SchemaModel_FontSizeSchema = '';
        var criteria_CustomEvent_SchemaModel_MarginLeftSchema = '';
        var criteria_CustomEvent_SchemaModel_MarginRightSchema = '';

        var criteria_CustomEvent_SchemaModel_FontSizeSchemaBigscreen = '';
        var criteria_CustomEvent_SchemaModel_MarginLeftSchemaBigscreen = '';
        var criteria_CustomEvent_SchemaModel_MarginRightSchemaBigscreen = '';

        var criteria_CustomEvent_SchemaModel_FieldBigscreen = '';

        if (customEvent.attributes.SchemaModel != -1) {
            var schemaModel = app.entities.collections.customSchemaModel.fullCollection.where({ id: customEvent.attributes.SchemaModel });
            if (schemaModel.length > 0) {
                criteria_CustomEvent_SchemaModel_FontSizeSchema = schemaModel[0].attributes.FontSizeSchema;
                criteria_CustomEvent_SchemaModel_MarginLeftSchema = schemaModel[0].attributes.MarginLeftSchema;
                criteria_CustomEvent_SchemaModel_MarginRightSchema = schemaModel[0].attributes.MarginRightSchema;

                criteria_CustomEvent_SchemaModel_FontSizeSchemaBigscreen = schemaModel[0].attributes.FontSizeSchemaBigscreen;
                criteria_CustomEvent_SchemaModel_MarginLeftSchemaBigscreen = schemaModel[0].attributes.MarginLeftSchemaBigscreen;
                criteria_CustomEvent_SchemaModel_MarginRightSchemaBigscreen = schemaModel[0].attributes.MarginRightSchemaBigscreen;

                criteria_CustomEvent_SchemaModel_FieldBigscreen = schemaModel[0].attributes.FieldBigscreen;
            }
        }

        var criteria_CustomWebpage_Theme = '';
        if (customWebpage) {
            criteria_CustomWebpage_Theme = customWebpage.attributes.Theme;
        }



        var criteria_CustomEvent_QRCodeTextPoster = customEvent.attributes.QRCodeTextPoster;
        var criteria_CustomEvent_QRCodeTextInfoscreen = customEvent.attributes.QRCodeTextInfoscreen;
        var criteria_CustomEvent_QRCodeTextBigSchemaScreen = customEvent.attributes.QRCodeTextBigSchemaScreen;
        var criteria_CustomEvent_QRCodeTextQRCode = customEvent.attributes.QRCodeTextQRCode;

        var criteria_CustomEvent_QRCodeFontSizePoster = customEvent.attributes.QRCodeFontSizePoster;
        var criteria_CustomEvent_QRCodeFontSizeInfoscreen = customEvent.attributes.QRCodeFontSizeInfoscreen;
        var criteria_CustomEvent_QRCodeFontSizeBigSchemaScreen = customEvent.attributes.QRCodeFontSizeBigSchemaScreen;
        var criteria_CustomEvent_QRCodeFontSizeQRCode = customEvent.attributes.QRCodeFontSizeQRCode;

        var criteria_CustomEvent_Module_QRCode = customEvent.attributes.Module_QRCode;
        var criteria_CustomEvent_Module_Facebook = customEvent.attributes.Module_Facebook;
        var criteria_CustomEvent_Module_SchemaModel = customEvent.attributes.Module_SchemaModel;
        var criteria_CustomEvent_Module_FormModel = customEvent.attributes.Module_FormModel;
        var criteria_CustomEvent_Module_ProductSheetModel = customEvent.attributes.Module_ProductSheetModel;
        var criteria_CustomEvent_Module_Ad = customEvent.attributes.Module_Ad;


        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_EventName +
            //criteria_CustomEvent_Description +
            criteria_CustomEvent_FriendlyUrl +
            criteria_CustomEvent_id +
            criteria_CustomEvent_SchemaModel +
            criteria_CustomEvent_FormModel +
            //criteria_CustomEvent_FormSubmitBtnName +
            //criteria_CustomEvent_FormCancelBtnName +
            //criteria_CustomEvent_FormSubmitBtnSucces +
            criteria_CustomEvent_Publicly +
            criteria_CustomEvent_Facebook +
            criteria_CustomEvent_Copyright +
            criteria_CustomEvent_Infopage +
            criteria_CustomEvent_InfopageShowQRCode +
            criteria_CustomEvent_SchemaBigScreenShowQRCode +
            criteria_CustomEvent_SchemaModel_FontSizeSchema +
            criteria_CustomEvent_SchemaModel_MarginLeftSchema +
            criteria_CustomEvent_SchemaModel_MarginRightSchema +
            criteria_CustomEvent_SchemaModel_FontSizeSchemaBigscreen +
            criteria_CustomEvent_SchemaModel_MarginLeftSchemaBigscreen +
            criteria_CustomEvent_SchemaModel_MarginRightSchemaBigscreen +
            criteria_CustomEvent_SchemaModel_FieldBigscreen +
            criteria_CustomWebpage_Theme +
            criteria_CustomEvent_QRCodeTextPoster +
            criteria_CustomEvent_QRCodeTextInfoscreen +
            criteria_CustomEvent_QRCodeTextBigSchemaScreen +
            criteria_CustomEvent_QRCodeTextQRCode +
            criteria_CustomEvent_QRCodeFontSizePoster +
            criteria_CustomEvent_QRCodeFontSizeInfoscreen +
            criteria_CustomEvent_QRCodeFontSizeBigSchemaScreen +
            criteria_CustomEvent_QRCodeFontSizeQRCode +
            criteria_CustomEvent_Module_QRCode +
            criteria_CustomEvent_Module_Facebook +
            criteria_CustomEvent_Module_SchemaModel +
            criteria_CustomEvent_Module_FormModel +
            criteria_CustomEvent_Module_ProductSheetModel +
            criteria_CustomEvent_Module_Ad;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumEventTextFileJson: function (customEvent) {
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_Description = customEvent.attributes.Description;
        var criteria_CustomEvent_FormSubmitBtnName = customEvent.attributes.FormSubmitBtnName;
        var criteria_CustomEvent_FormCancelBtnName = customEvent.attributes.FormCancelBtnName;
        var criteria_CustomEvent_FormSubmitBtnSucces = customEvent.attributes.FormSubmitBtnSucces;

        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_Description +
            criteria_CustomEvent_FormSubmitBtnName +
            criteria_CustomEvent_FormCancelBtnName +
            criteria_CustomEvent_FormSubmitBtnSucces;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumCodeImageUrl: function (customEvent, customWebpage, customWebpageItem, customQRCode) {
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_QRCode = customEvent.attributes.QRCode;
        var criteria_CustomEvent_FriendlyUrl = customEvent.attributes.FriendlyUrl;

        var criteria_CustomQRCode_Render = customQRCode.attributes.Render;
        var criteria_CustomQRCode_EcLevel = customQRCode.attributes.EcLevel;
        var criteria_CustomQRCode_MinVersion = customQRCode.attributes.MinVersion;
        var criteria_CustomQRCode_Fill = customQRCode.attributes.Fill;
        var criteria_CustomQRCode_Background = customQRCode.attributes.Background
        var criteria_CustomQRCode_Text = customQRCode.attributes.Text;
        var criteria_CustomQRCode_Size = customQRCode.attributes.Size;
        var criteria_CustomQRCode_Radius = customQRCode.attributes.Radius;
        var criteria_CustomQRCode_Quiet = customQRCode.attributes.Quiet;
        var criteria_CustomQRCode_Mode = customQRCode.attributes.Mode;
        var criteria_CustomQRCode_MSize = customQRCode.attributes.MSize;
        var criteria_CustomQRCode_MPosX = customQRCode.attributes.MPosX;
        var criteria_CustomQRCode_MPosY = customQRCode.attributes.MPosY;
        var criteria_CustomQRCode_Label = customQRCode.attributes.Label;
        var criteria_CustomQRCode_FontName = customQRCode.attributes.FontName;
        var criteria_CustomQRCode_FontColor = customQRCode.attributes.FontColor;
        var criteria_CustomQRCode_FontStyle = customQRCode.attributes.FontStyle;
        var criteria_CustomQRCode_FontSize = customQRCode.attributes.FontSize;
        var criteria_CustomQRCode_Logo = customQRCode.attributes.Logo;
        var criteria_CustomQRCode_ImageUrl = customQRCode.attributes.ImageUrl;

        var criteria = criteria_CustomEvent_id +
            criteria_CustomEvent_QRCode +
            criteria_CustomEvent_FriendlyUrl +
            criteria_CustomQRCode_Render +
            criteria_CustomQRCode_EcLevel +
            criteria_CustomQRCode_MinVersion +
            criteria_CustomQRCode_Fill +
            criteria_CustomQRCode_Background +
            criteria_CustomQRCode_Text +
            criteria_CustomQRCode_Size +
            criteria_CustomQRCode_Radius +
            criteria_CustomQRCode_Quiet +
            criteria_CustomQRCode_Mode +
            criteria_CustomQRCode_MSize +
            criteria_CustomQRCode_MPosX +
            criteria_CustomQRCode_MPosY +
            criteria_CustomQRCode_Label +
            criteria_CustomQRCode_FontName +
            criteria_CustomQRCode_FontColor +
            criteria_CustomQRCode_FontStyle +
            criteria_CustomQRCode_FontSize +
            criteria_CustomQRCode_Logo +
            criteria_CustomQRCode_ImageUrl;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumWebpageImageIndex: function (customEvent, customWebpage, customWebpageItem, customQRCode) {
        var criteria_CustomEvent_Publicly = customEvent.attributes.Publicly;
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_Facebook = customEvent.attributes.Facebook;
        var criteria_CustomEvent_Copyright = customEvent.attributes.Copyright;

        var criteria_CustomWebpage_Theme = '';
        if (customWebpage) {
            criteria_CustomWebpage_Theme = customWebpage.attributes.Theme;
        }

        var criteria = criteria_CustomEvent_Publicly + criteria_CustomEvent_EventName +
            criteria_CustomEvent_id +
            criteria_CustomEvent_Facebook +
            criteria_CustomEvent_Copyright +
            criteria_CustomWebpage_Theme;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumWebpageImageUrl: function (customEvent, customWebpage, customWebpageItem, customQRCode) {
        var criteria_CustomEvent_EventName = customEvent.attributes.EventName;
        var criteria_CustomEvent_id = customEvent.attributes.id;
        var criteria_CustomEvent_Webpage = customEvent.attributes.Webpage;

        var criteria_CustomWebpage_Theme = '';
        if (customWebpage) {
            criteria_CustomWebpage_Theme = customWebpage.attributes.Theme;
        }

        //find lowest webpage
        var lowestPosition = 2147483647;
        customWebpageItem.forEach(function (model) {
            if (model.attributes.Position != 0) {
                if (model.attributes.Position < lowestPosition) {
                    lowestPosition = model.attributes.Position;
                }
            }
        });

        var criteria_CustomWebpageItem_Body = '';
        customWebpageItem.forEach(function (model) {
            if ((/*(model.attributes.Position == 0) || (*/model.attributes.Position == lowestPosition)/*)*/ && (model.attributes.Status == 1)) {
                criteria_CustomWebpageItem_Body = model.attributes.Body;
            }
        });

        var criteria_CustomWebpageItem_Header = '';
        customWebpageItem.forEach(function (model) {
            if (model.attributes.Tag == 0) {
                criteria_CustomWebpageItem_Header = model.attributes.Body + model.attributes.Status;
            }
        });

        var criteria_CustomWebpageItem_Footer = '';
        customWebpageItem.forEach(function (model) {
            if (model.attributes.Tag == 2) {
                criteria_CustomWebpageItem_Footer = model.attributes.Body;
            }
        });

        var criteria = criteria_CustomEvent_EventName +
            criteria_CustomEvent_id +
            criteria_CustomEvent_Webpage +
            criteria_CustomWebpage_Theme +
            criteria_CustomWebpageItem_Body +
            criteria_CustomWebpageItem_Header +
            criteria_CustomWebpageItem_Footer;

        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getChecksumEventImageFile: function (customEvent, criteria_checksumCodeImageUrl, criteria_checksumWebpageImageUrl) {
        var criteria_CustomEvent_id = customEvent.attributes.id;

        var criteria = criteria_CustomEvent_id +
            criteria_checksumCodeImageUrl +
            criteria_checksumWebpageImageUrl;


        var md5 = require('js-md5');
        var result = md5(criteria);
        return result;
    },
    getModel: function (customEvent) {
        var renderModel = new Entities_Empty.Model();

        var customWebpage = this.app.entities.collections.customWebpage.fullCollection.where({ id: customEvent[0].attributes.Webpage });
        var customWebpage_info = this.app.entities.collections.customInfopage.fullCollection.where({ id: customEvent[0].attributes.Infopage });

        var customWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage });
        var customWebpageItem_info = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Infopage });
        var customQRCode = app.entities.collections.customQRCode.fullCollection.where({ id: customEvent[0].attributes.QRCode });

        var checksumEventHeader = this.getChecksumEventHeader(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
        var checksumEventTemplate = this.getChecksumEventTemplate(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
        var checksumEventInfoHeader = this.getChecksumEventInfoHeader(customEvent[0], customWebpage_info[0], customWebpageItem_info, customQRCode[0]);
        var checksumEventInfoTemplate = this.getChecksumEventInfoTemplate(customEvent[0], customWebpage_info[0], customWebpageItem_info, customQRCode[0]);

        var checksumEventIndex = this.getChecksumEventIndex(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
        var checksumEventTextFileJson = this.getChecksumEventTextFileJson(customEvent[0]);

        var checksumQRCodeImageUrl = this.getChecksumCodeImageUrl(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
        var checksumWebpageImageIndex = this.getChecksumWebpageImageIndex(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
        var checksumWebpageImageUrl = this.getChecksumWebpageImageUrl(customEvent[0], customWebpage[0], customWebpageItem, customQRCode[0]);
        var checksumEventImageFile = this.getChecksumEventImageFile(customEvent[0], checksumQRCodeImageUrl, checksumWebpageImageUrl);

        renderModel.attributes.updateEventHeader = false;
        renderModel.attributes.updateEventTemplate = false;
        renderModel.attributes.updateEventInfoHeader = false;
        renderModel.attributes.updateEventInfoTemplate = false;
        renderModel.attributes.updateEventIndex = false;
        renderModel.attributes.updateEventTextFileJson = false;
        renderModel.attributes.updateQRCodeImageUrl = false;
        renderModel.attributes.updateWebpageImageIndex = false;
        renderModel.attributes.updateWebpageImageUrl = false;
        renderModel.attributes.updateEventImageFile = false;




        if (checksumEventHeader != customEvent[0].attributes.ChecksumEventHeader) {
            renderModel.attributes.updateEventHeader = true;
            renderModel.attributes.updateEventTemplate = true;
        }

        if (checksumEventTemplate != customEvent[0].attributes.ChecksumEventTemplate) {
            renderModel.attributes.updateEventTemplate = true;
            renderModel.attributes.updateEventHeader = true;
        }

        if (checksumEventInfoHeader != customEvent[0].attributes.ChecksumEventInfoHeader) {
            renderModel.attributes.updateEventInfoHeader = true;
            renderModel.attributes.updateEventInfoTemplate = true;
        }

        if (checksumEventInfoTemplate != customEvent[0].attributes.ChecksumEventInfoTemplate) {
            renderModel.attributes.updateEventInfoTemplate = true;
            renderModel.attributes.updateEventInfoHeader = true;
        }

        if (checksumEventIndex != customEvent[0].attributes.ChecksumEventIndex) {
            renderModel.attributes.updateEventIndex = true;
        }

        if (checksumEventTextFileJson != customEvent[0].attributes.ChecksumEventTextFileJson) {
            renderModel.attributes.updateEventTextFileJson = true;
        }

        if (checksumQRCodeImageUrl != customEvent[0].attributes.ChecksumQRCodeImageUrl) {
            renderModel.attributes.updateQRCodeImageUrl = true;
            renderModel.attributes.updateEventImageFile = true;
        }

        if (checksumWebpageImageIndex != customEvent[0].attributes.ChecksumWebpageImageIndex) {
            renderModel.attributes.updateWebpageImageIndex = true;
            renderModel.attributes.updateWebpageImageUrl = true;
            renderModel.attributes.updateEventImageFile = true;
        }

        if (checksumWebpageImageUrl != customEvent[0].attributes.ChecksumWebpageImageUrl) {
            renderModel.attributes.updateWebpageImageUrl = true;
            renderModel.attributes.updateEventImageFile = true;
        }

        if (checksumEventImageFile != customEvent[0].attributes.ChecksumEventImageFile) {
            renderModel.attributes.updateEventImageFile = true;
        }

        if (customEvent[0].attributes.Infopage == -1) {
            renderModel.attributes.updateEventInfoHeader = false;
            renderModel.attributes.updateEventInfoTemplate = false;
        }

        if (customEvent[0].attributes.Webpage == -1) {
            renderModel.attributes.updateEventHeader = false;
            renderModel.attributes.updateEventTemplate = false;
            renderModel.attributes.updateEventInfoHeader = false;
            renderModel.attributes.updateEventInfoTemplate = false;
            renderModel.attributes.updateEventIndex = false;
            renderModel.attributes.updateEventTextFileJson = false;
            renderModel.attributes.updateQRCodeImageUrl = false;
            renderModel.attributes.updateWebpageImageIndex = false;
            renderModel.attributes.updateWebpageImageUrl = false;
            renderModel.attributes.updateEventImageFile = false;
        } else {
            renderModel.attributes.checksumEventHeader = checksumEventHeader;
            renderModel.attributes.checksumEventTemplate = checksumEventTemplate;
            renderModel.attributes.checksumEventInfoHeader = checksumEventInfoHeader;
            renderModel.attributes.checksumEventInfoTemplate = checksumEventInfoTemplate;
            renderModel.attributes.checksumEventIndex = checksumEventIndex;
            renderModel.attributes.checksumEventTextFileJson = checksumEventTextFileJson;
            renderModel.attributes.checksumQRCodeImageUrl = checksumQRCodeImageUrl;
            renderModel.attributes.checksumWebpageImageIndex = checksumWebpageImageIndex;
            renderModel.attributes.checksumWebpageImageUrl = checksumWebpageImageUrl;
            renderModel.attributes.checksumEventImageFile = checksumEventImageFile;
        }

        return renderModel;
    },
    getDialogText: function (customEvent, renderModel, currentEvent, totalEvent) {
        var Title = resources.X100026;
        var Text_1 = resources.X100435;
        var Text_2 = '';

        Text_1 = String.format(Text_1, currentEvent, totalEvent);

        if ((renderModel.attributes.updateEvent) || (renderModel.attributes.updateQRCodeImageUrl) || (renderModel.attributes.updateWebpageImageUrl)) {
            Text_2 = '';
        } else {
            Text_2 = resources.X100433;
        }

        return { Title: Title, Text_1: Text_1, Text_2: Text_2 };
    },
    getDialogProgress_2_totalCount: function (customEvent, renderModel) {
        var progress_totalCount = 1;

        if (renderModel.attributes.updateEventHeader) {
            progress_totalCount += 1;
        }
        if (renderModel.attributes.updateEventTemplate) {
            //progress_totalCount += 1;

            //build template
            var length = this.app.entities.collections.customWebpageItem.filter(function (n) { return ((n.get('Webpage') == customEvent.attributes.Webpage)); }).length;
            progress_totalCount += length - 2;

            //delete template
            //progress_totalCount += (length -2) * 3;
        }
        if (renderModel.attributes.updateEventInfoHeader) {
            progress_totalCount += 1;
        }
        if (renderModel.attributes.updateEventInfoTemplate) {
            //progress_totalCount += 1;

            //build template
            var length = this.app.entities.collections.customWebpageItem.filter(function (n) { return ((n.get('Webpage') == customEvent.attributes.Infopage)); }).length;
            progress_totalCount += length;

            //delete template
            //progress_totalCount += (length) * 3;
        }
        if (renderModel.attributes.updateEventIndex) {
            progress_totalCount += 1;
        }
        if (renderModel.attributes.updateEventTextFileJson) {
            progress_totalCount += 1;
        }
        if (renderModel.attributes.updateQRCodeImageUrl) {
            progress_totalCount += 2;
        }
        if (renderModel.attributes.updateWebpageImageIndex) {
            progress_totalCount += 1;
        }
        if (renderModel.attributes.updateWebpageImageUrl) {
            progress_totalCount += 2;
            progress_totalCount += helper_createWebpage.setting.webSnapShotTimerCount;
        }
        if (renderModel.attributes.updateEventImageFile) {
            progress_totalCount += 1;
        }

        return progress_totalCount;
    },
    doIt: function (renderFinishCallback, publish, refreshType, eventUpdate) {
        try {
            /*var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('customViewEvent:setDisable');*/

            var self = this;
            var dialog2Process_showed = false;

            var array = this.handlingWebpageImageUrl(this.preCalcChecksumWebpageImageUrl(), publish, eventUpdate);


            if (this.collection.length > 0) {
                var callbackFinish = function () {
                    /*var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('customViewEvent:setEnable');*/

                    renderFinishCallback();
                }

                var dialog2ProcessModel = new Entities_Empty.Model();
                dialog2ProcessModel.attributes.Button = resources.X100279;

                var dialog2Process = new View_Dialog2Process({ model: dialog2ProcessModel, parent: this });
                var callback = function (options) {
                    self.stopListening(dialog2Process, "dialog:okclicked", callback);
                }
                this.listenTo(dialog2Process, "dialog:okclicked", callback);


                var callback_count = 0;

                //create new data
                var customEventSaved = 0;
                var changeDate = new Date();
                var strUtcDate = changeDate.getUTCFullYear().toString() + '-' +
                    (changeDate.getUTCMonth() + 1) + '-' +
                    changeDate.getUTCDate().toString() + ' ' +
                    changeDate.getUTCHours() + ':' +
                    changeDate.getUTCMinutes() + ':' +
                    changeDate.getUTCSeconds();

                //Updates everything
                var callbackRender = function (i) {
                    if (!dialog2Process_showed) {
                        self.app.dialogRegion.show(dialog2Process);
                        dialog2Process_showed = true;
                    }

                    var customEvent = self.app.entities.collections.customEvent.fullCollection.where({ id: self.collection[i].attributes.id });

                    var renderModel = self.getModel(customEvent);

                    if (!array.generate) {
                        renderModel.attributes.updateWebpageImageUrl = false;
                        //renderModel.attributes.updateWebpageImageIndex = false;

                        if (!renderModel.attributes.updateQRCodeImageUrl) {
                            renderModel.attributes.updateEventImageFile = false;
                        }
                    }

                    //Testonly
                    if (renderModel.attributes.updateWebpageImageUrl == true) {
                        //alert("updateWebpageImageUrl");
                        //renderModel.attributes.updateWebpageImageUrl = false;
                    }

                    var dialog_text = self.getDialogText(customEvent[0], renderModel, i + 1, self.collection.length);
                    dialog2Process.changeTitle(dialog_text.Title);
                    dialog2Process.changeText_1(dialog_text.Text_1);
                    dialog2Process.changeText_2(dialog_text.Text_2);

                    dialog2ProcessModel.attributes.progress_count_1 = i;
                    dialog2ProcessModel.attributes.progress_totalCount_1 = self.collection.length;
                    dialog2Process.changeProgress_1(dialog2ProcessModel.attributes.progress_count_1, dialog2ProcessModel.attributes.progress_totalCount_1);

                    dialog2ProcessModel.attributes.progress_count_2 = 0;
                    dialog2ProcessModel.attributes.progress_totalCount_2 = self.getDialogProgress_2_totalCount(customEvent[0], renderModel);
                    dialog2Process.changeProgress_2(dialog2ProcessModel.attributes.progress_count_2, dialog2ProcessModel.attributes.progress_totalCount_2);

                    helper_createWebpage.createWebpage(dialog2ProcessModel, customEvent[0].attributes.id, dialog2Process,
                        self.ui.customEventRender_container, self.ui.customEventRender_qrCodeLogo, self.ui.customEventRender_qrCodeContainer,
                        self.ui.customEventRender_iframeHide, self.ui.customEventRender_iframe,
                        function (id) {
                            customEvent[0].attributes.ChecksumEventHeader = renderModel.attributes.checksumEventHeader;
                            customEvent[0].attributes.ChecksumEventTemplate = renderModel.attributes.checksumEventTemplate;
                            customEvent[0].attributes.ChecksumEventInfoHeader = renderModel.attributes.checksumEventInfoHeader;
                            customEvent[0].attributes.ChecksumEventInfoTemplate = renderModel.attributes.checksumEventInfoTemplate;
                            customEvent[0].attributes.ChecksumEventIndex = renderModel.attributes.checksumEventIndex;
                            customEvent[0].attributes.ChecksumEventTextFileJson = renderModel.attributes.checksumEventTextFileJson;
                            customEvent[0].attributes.ChecksumQRCodeImageUrl = renderModel.attributes.checksumQRCodeImageUrl;

                            if (renderModel.attributes.updateQRCodeImageUrl || renderModel.attributes.updateWebpageImageUrl) {
                                customEvent[0].attributes.ChecksumEventImageFile = renderModel.attributes.checksumEventImageFile;
                            }

                            var allowSave = true;
                            var customSetting = self.app.entities.collections.customSetting.where({ Name: 'WebpageImageUrlMethod' });
                            if (customSetting[0].attributes.Value == '1') {
                                allowSave = publish;
                            }
                            if (allowSave) {
                                customEvent[0].attributes.ChecksumWebpageImageIndex = renderModel.attributes.checksumWebpageImageIndex;
                                customEvent[0].attributes.ChecksumWebpageImageUrl = renderModel.attributes.checksumWebpageImageUrl;
                            }

                            customEvent[0].save(customEvent[0].attributes, { wait: true, silent: true });

                            callback_count++;
                            self.pushMessage(customEvent[0].attributes.id, refreshType);

                            if (callback_count == self.collection.length) {
                                dialog2Process.triggerMethod('dialog:close');
                                callbackFinish();
                            } else {
                                callbackRender(callback_count);
                            }

                        }, 0, renderModel, self.app);
                }

                var callbackStart = function () {
                    //cycle throug list og event that need to be updated
                    for (var i = 0; i < self.collection.length; i++) {
                        var customEvent = app.entities.collections.customEvent.fullCollection.where({ id: self.collection[i].attributes.id });

                        customEvent[0].set({ UtcDate: strUtcDate }, { silent: true });
                        customEvent[0].save(customEvent[0].attributes, {
                            wait: true,
                            silent: true,
                            success: function () {
                                customEventSaved++;

                                //All items is saved, begin render
                                if (customEventSaved == self.collection.length) {
                                    callbackRender(0);
                                }
                            }
                        });
                    }
                }

                if (array.ask) {
                    var dialog2ButtonModel = new Entities_Empty.Model();
                    dialog2ButtonModel.attributes.Title = resources.X100229;
                    dialog2ButtonModel.attributes.Text = resources.X100470;
                    dialog2ButtonModel.attributes.Button01 = resources.X100281;
                    dialog2ButtonModel.attributes.Button02 = resources.X100282;

                    var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel });

                    var callback01 = function (options) {
                        self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                        self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                        array.generate = true;
                        callbackStart();
                    }

                    this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

                    var callback02 = function (options) {
                        self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                        self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                        array.generate = false;
                        callbackStart();
                    }
                    this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

                    this.app.dialogRegion.show(dialog2Button);
                } else {
                    callbackStart();
                }
            } else {
                /*var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('customViewEvent:setEnable');*/

                if (renderFinishCallback) {
                    renderFinishCallback();
                }
            }
        } catch (err) {
            //alert("error");
            alert(err);

        }
    },
    signalR_init: function () {
        //webonline
        //this.IWannaChat = $.connection.LuxaforboardHub;
        //$.connection.hub.start();
    },
    sendMessage: function (id, message) {
        var tmpMessage = id + "#¤%&/()=?" + message;
        //this.IWannaChat.server.send(tmpMessage);

        this.pushMessageServer(tmpMessage);

    },
    pushMessageServer: function (message) {
        var tmpMessage = message;
        //this.IWannaChat.server.send(tmpMessage);

        var tmpModel = new Entities_CustomPush.Model();
        tmpModel.attributes.Message = tmpMessage;
        tmpModel.attributes.id = 0;
        tmpModel.attributes.TokenKey = "";

        tmpModel.save(tmpModel, {
            silens: true,
            wait: true,
            success: function (response) {

            }
        });
    },
    pushMessage: function (id, refreshType) {
        //webonline
        var message = 'EVENT_REFRESH';

        switch (refreshType) {
            case 'EVENTINFO_REFRESH':
                var message = 'EVENT_REFRESH';
                this.sendMessage(id, message);
                var message = 'INFO_REFRESH';
                this.sendMessage(id, message);
                break;
            case 'EVENT_REFRESH':
                var message = 'EVENT_REFRESH';
                this.sendMessage(id, message);
                break;
            case 'INFO_REFRESH':
                var message = 'INFO_REFRESH';
                this.sendMessage(id, message);
                break;
        }
    }
})

