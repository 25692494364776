import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Calculation: {}
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    validateNoBackbone: function (app) {
        this.errors = [];

        if (this.attributes.Name == "") {
            this.errors.push({ name: 'customSchemaModelInsert_inputName', message: resources.X100324 });
        }

        var self = this;
        for (var i = 0; i < app.entities.collections.customSchemaModel.fullCollection.length; i++) {
            if (this.attributes.Name == app.entities.collections.customSchemaModel.fullCollection.models[i].attributes.Name) {
                this.errors.push({ name: 'customSchemaModelInsert_inputName', message: resources.X100332 });
            }
        };

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    },
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url =  config.webApiUrl() + '/api/configSchemaModel/-1?dummy=dummy';
        }
        return Backbone.sync(method, model, options);
    }
});

