﻿import $ from 'jquery';


//require('jqplot-bower');

//require('jqplot/dateAxisRenderer');
//require('jqplot/logAxisRenderer');
//require('jqplot/canvasTextRenderer');
//require('jqplot/canvasAxisTickRenderer');
//require('jqplot/highlighter');

//JQPlot

//NPM
//require('as-jqplot/dist/jquery.jqplot');
//require('as-jqplot/dist/plugins/jqplot.dateAxisRenderer');
//require('as-jqplot/dist/plugins/jqplot.logAxisRenderer');
//require('as-jqplot/dist/plugins/jqplot.canvasTextRenderer');
//require('as-jqplot/dist/plugins/jqplot.canvasAxisTickRenderer');
//require('as-jqplot/dist/plugins/jqplot.highlighter');

//Bower addon file
//require('jqplot-bower');
//require('jqplot/dateAxisRenderer');
//require('jqplot/logAxisRenderer');
//require('jqplot/canvasTextRenderer');
//require('jqplot/canvasAxisTickRenderer');
//require('jqplot/highlighter');

require('jqplot-bower');
require('jqplot-bower/plugins/jqplot.dateAxisRenderer');
require('jqplot-bower/plugins/jqplot.logAxisRenderer');
require('jqplot-bower/plugins/jqplot.canvasTextRenderer');
require('jqplot-bower/plugins/jqplot.canvasAxisTickRenderer');
require('jqplot-bower/plugins/jqplot.highlighter');
require('jqplot-bower/plugins/jqplot.ohlcRenderer');
require('jqplot-bower/plugins/jqplot.cursor');
