var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li style="display:inline"> <div id="customWebpageEditItemListItem_div" class="menuDiv" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px; border-top-width: 1px;border-right-width: 1px;border-bottom-width: 1px;border-left-width: 1px"> <div class="card" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px"> <div class="card-body" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px;    border-bottom-width: 0px;    border-left-width: 0px"> <div class="d-sm-flex flex-row flex-wrap text-center text-sm-left align-items-center" style="padding-bottom: 0px;padding-right: 0px;padding-top: 0px;padding-left: 0px;border-top-width: 0px;border-right-width: 0px;    border-bottom-width: 0px;    border-left-width: 0px"> <a id="customWebpageEditItemListItem_a" data_id="'+
((__t=( model.id ))==null?'':__t)+
'" data_orgid="'+
((__t=( model.orgId ))==null?'':__t)+
'" data-toggle="collapse" class="ancorli '+
((__t=( model.ClassnameA ))==null?'':__t)+
'" data-parent="#accordion" href="#" style="width:100%"> <div class="d-flex align-items-center py-3 border-bottom" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;padding-top:0px !important;padding-bottom:0px !important"> <img class="img-lg rounded" src="../../../../images/faces/face6.jpg" alt="profile" style="width:50px; height:50px"> <div class="ml-3" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small class="text-muted mb-0"><i class="mdi mdi-map-marker mr-1"></i>Florida, USA</small> </div> </div>  <i id="customWebpageEditItemListItem_btnDelete" style="float: right; color: #6c7293 !important" class="far fa-lg fa-lg fa-times-circle px-1 py-1 text-info mdi-24px"></i> <i id="customWebpageEditItemListItem_btnSetting" style="float: right;margin-top:3px; color: #6c7293 !important" class="fa fa-lg fa-´lg fa-cog"></i> </a> </div> </div> </div> </div> <ol id="customWebpageEditItemListItem_ol_'+
((__t=( model.id ))==null?'':__t)+
'" class="mjs_parent_ol"></ol> </li>';
}
return __p;
};
