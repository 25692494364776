﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';



import tpl_youTube from './tpl/youTube.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#youTube_modal',
    errors: [],
    getTemplate: function () {
        return tpl_youTube({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'youTube_input_VideoId': '#youTube_input_VideoId',
        'youTube_input_VideoList': '#youTube_input_VideoList',
        'youTube_input_feedSpeed': '#youTube_input_feedSpeed',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    events: {
        'click #youTube_btn01': 'onBtn01',
        'click #youTube_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    onAttach() {
        //this.setupFeedSpeed();
    },
    setupFeedSpeed: function () {
        var self = this;

        if ($("#youTube_input_feedSpeed").length) {
            var startSlider = document.getElementById('youTube_input_feedSpeed');
            nouislider.create(startSlider, {
                start: self.model.attributes.FeedSpeed,
                connect: [true, false],
                range: {
                    min: 50,
                    max: 300
                },
                step: 1
            });
            startSlider.noUiSlider.on('set', function (e) {
                self.model.attributes.FeedSpeed = parseInt(e);
            });
        }
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    setModel: function () {
        this.model.set({
            VideoId: this.ui.youTube_input_VideoId.val(),
            VideoList: this.ui.youTube_input_VideoList.val(),
            //FeedCount: parseInt(this.ui.youTube_dropDown_feedCount.val()),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    }
});