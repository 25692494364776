var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFileInsert_empty"> <div class="forms-sample"> <div class="control-group File" style="display:none"> <div class=""> <label type="button" for="customFileInsert_btnFile" class="btn btn-primary btn-sm col-sm-3">'+
((__t=( resources['X100299'] ))==null?'':__t)+
'</label> <input id="customFileInsert_btnFile" type="file" accept="application/pdf, text/plain, .doc, .docx, .xlx, .ppt" style="font-size: 20px; display: none"> <br> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customFileInsert_inputName"> <div class="form-group"> <label style="display:none" for="customFileInsert_inputName">'+
((__t=( resources['X100300'] ))==null?'':__t)+
'</label> <input style="display:none" class="form-control" id="customFileInsert_inputName"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-12"> <input id="customFileInsert_filePond" type="file" class="filepond"> </div> <button style="display:none" type="button" class="btn btn-primary btn-sm col-sm-3" id="customFileInsert_btnSubmit">'+
((__t=( resources['X100283'] ))==null?'':__t)+
'</button> </div> </div>';
}
return __p;
};
