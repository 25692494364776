﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as FileApi from 'fileapi';
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from '../../plugins/filepond-plugin-file-encode';
import FilePondPluginImageCrop from '../../plugins/filepond-plugin-image-crop';
import FilePondPluginImageResize from '../../plugins/filepond-plugin-image-resize';
import FilePondPluginImageTransform from '../../plugins/filepond-plugin-image-transform';
import FilePondPluginImagePreview from '../../plugins/filepond-plugin-image-preview';
import FilePondPluginImageEdit from '../../plugins/filepond-plugin-image-edit';
import FilePondPluginImageSizeMetaData from '../../plugins/filepond-plugin-image-size-metadata';

import * as Entities_CustomPush from '../../entities/customPush';

import * as plugins_qrcode from '../../plugins/qrcode';
import * as nouislider from 'nouislider';


import tpl_customUserEditItem from './tpl/customUserEditItem.html';

import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_DialogWaiting2 from '../../../lib/views/dialogWaiting2/dialogWaiting2';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customUserEditItem({ model: this.model.toJSON() });
    },
    regions: {
        information: "#customUserEditItemInformation",
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');

        this.localSetting = new Entities_Empty.Model();
        this.localSetting.attributes.ImageSelected = false;
        this.localSetting.attributes.AspectText = resources.X101203;
        this.localSetting.attributes.ImageModify = false;
        this.localSetting.attributes.AutoCrop = false;



        //this.model = new this.app.entities.collections.customUser.model();
        //this.model.attributes.Color = this.app.entities.models.customColor.attributes.SolidColor_Color_Off;

        this.model.attributes.tmp = {};

        this.model.attributes.tmp.Custom_Color_Default = this.app.entities.models.customColor.attributes.Custom_Color_Default;
        this.model.attributes.tmp.Custom_Text_Default = resources.X200311;// + ' (' + this.app.entities.models.customColor.attributes.Custom_Text_Default + ')';

        this.model.attributes.tmp.SolidColor_Color_Off = this.app.entities.models.customColor.attributes.SolidColor_Color_Off;
        this.model.attributes.tmp.SolidColor_Text_Off = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Off + ')';
        this.model.attributes.tmp.SelectedOff = '';

        this.model.attributes.tmp.SolidColor_Color_Busy = this.app.entities.models.customColor.attributes.SolidColor_Color_Busy;
        this.model.attributes.tmp.SolidColor_Text_Busy = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Busy + ')';
        this.model.attributes.tmp.SelectedBusy = '';

        this.model.attributes.tmp.SolidColor_Color_Available = this.app.entities.models.customColor.attributes.SolidColor_Color_Available;
        this.model.attributes.tmp.SolidColor_Text_Available = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Available + ')';
        this.model.attributes.tmp.SelectedAvailable = '';

        this.model.attributes.tmp.SolidColor_Color_Blue = this.app.entities.models.customColor.attributes.SolidColor_Color_Blue;
        this.model.attributes.tmp.SolidColor_Text_Blue = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Blue + ')';
        this.model.attributes.tmp.SelectedBlue = '';

        this.model.attributes.tmp.SolidColor_Color_Yellow = this.app.entities.models.customColor.attributes.SolidColor_Color_Yellow;
        this.model.attributes.tmp.SolidColor_Text_Yellow = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow + ')';
        this.model.attributes.tmp.SelectedYellow = '';

        this.model.attributes.tmp.SolidColor_Color_Cyan = this.app.entities.models.customColor.attributes.SolidColor_Color_Cyan;
        this.model.attributes.tmp.SolidColor_Text_Cyan = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan + ')';
        this.model.attributes.tmp.SelectedCyan = '';

        this.model.attributes.tmp.SolidColor_Color_Magenta = this.app.entities.models.customColor.attributes.SolidColor_Color_Magenta;
        this.model.attributes.tmp.SolidColor_Text_Magenta = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta + ')';
        this.model.attributes.tmp.SelectedMagenta = '';

        this.model.attributes.tmp.SolidColor_Color_CustomColor = this.app.entities.models.customColor.attributes.SolidColor_Color_CustomColor;
        this.model.attributes.tmp.SolidColor_Text_CustomColor = resources.X200270 + ' (' + this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor + ')';
        this.model.attributes.tmp.SelectedCustomColor = '';

        this.model.attributes.tmp.MSTeams_Color_Online = this.app.entities.models.customColor.attributes.MSTeams_Color_Online;
        this.model.attributes.tmp.MSTeams_Text_Online = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Online + ')';

        this.model.attributes.tmp.MSTeams_Color_Busy = this.app.entities.models.customColor.attributes.MSTeams_Color_Busy;
        this.model.attributes.tmp.MSTeams_Text_Busy = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_Busy + ')';

        this.model.attributes.tmp.MSTeams_Color_DoNotDisturb = this.app.entities.models.customColor.attributes.MSTeams_Color_DoNotDisturb;
        this.model.attributes.tmp.MSTeams_Text_DoNotDisturb = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb + ')';

        this.model.attributes.tmp.MSTeams_Color_BeRightBack = this.app.entities.models.customColor.attributes.MSTeams_Color_BeRightBack;
        this.model.attributes.tmp.MSTeams_Text_BeRightBack = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsNotPresent = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsNotPresent;
        this.model.attributes.tmp.MSTeams_Text_ShowAsNotPresent = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent + ')';

        this.model.attributes.tmp.MSTeams_Color_ShowAsOffline = this.app.entities.models.customColor.attributes.MSTeams_Color_ShowAsOffline;
        this.model.attributes.tmp.MSTeams_Text_ShowAsOffline = resources.X200303 + ' (' + this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline + ')';

        this.model.attributes.tmp.Mutebutton_Color_On = this.app.entities.models.customColor.attributes.Mutebutton_Color_On;
        this.model.attributes.tmp.Mutebutton_Text_On = resources.X200312 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_On + ')';

        this.model.attributes.tmp.Mutebutton_Color_Off = this.app.entities.models.customColor.attributes.Mutebutton_Color_Off;
        this.model.attributes.tmp.Mutebutton_Text_Off = resources.X200312 + ' (' + this.app.entities.models.customColor.attributes.Mutebutton_Text_Off + ')';

        this.model.attributes.tmp.Pomodoro_Color_Time = this.app.entities.models.customColor.attributes.Pomodoro_Color_Time;
        this.model.attributes.tmp.Pomodoro_Text_Time = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_Time + ')';

        this.model.attributes.tmp.Pomodoro_Color_ShortBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_ShortBreak;
        this.model.attributes.tmp.Pomodoro_Text_ShortBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak + ')';

        this.model.attributes.tmp.Pomodoro_Color_LongBreak = this.app.entities.models.customColor.attributes.Pomodoro_Color_LongBreak;
        this.model.attributes.tmp.Pomodoro_Text_LongBreak = resources.X200286 + ' (' + this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak + ')';
    },
    help: function () {
        Help.help(this, true, 'customUserEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick(null, callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        /*if (this.model.attributes.Name != this.oldModel.attributes.Name)  {
            changes = true;
        }*/

        this.setHasChanged(changes);
    },
    ui: {
        'customUserEditItem_inputName': '#customUserEditItem_inputName',
        'customUserEditItem_inputLuxaforId': '#customUserEditItem_inputLuxaforId',
        'customUserEditItem_inputCallerId': '#customUserEditItem_inputCallerId',
        'customUserEditItem_inputEmail': '#customUserEditItem_inputEmail',
        'customUserEditItem_selectStatus': '#customUserEditItem_selectStatus',
        'customUserEditItem_checkboxEnableUserText': '#customUserEditItem_checkboxEnableUserText',
        'customUserEditItem_inputUserText': '#customUserEditItem_inputUserText',
        'customUserEditItem_checkboxEnablePomodoro': '#customUserEditItem_checkboxEnablePomodoro',
        'customUserEditItem_checkboxEnable': '#customUserEditItem_checkboxEnable',
        'customUserEditItem_btnSubmit': '#customUserEditItem_btnSubmit',
        'customUserEditItem_btnCancel': '#customUserEditItem_btnCancel',
        'customUserEditItem_btnMailQRCode': '#customUserEditItem_btnMailQRCode',
        'customUserEditItem_btnRemoveImage': '#customUserEditItem_btnRemoveImage',
        'customUserEdit_filePond': '#customUserEdit_filePond',
        'customUserEdit_image': '#customUserEdit_image',
        'customUserEdit_btn_crop_zoom_plus': '#customUserEdit_btn_crop_zoom_plus',
        'customUserEdit_btn_crop_zoom_minus': '#customUserEdit_btn_crop_zoom_minus',
        'customUserEdit_btn_crop_move_left': '#customUserEdit_btn_crop_move_left',
        'customUserEdit_btn_crop_move_right': '#customUserEdit_btn_crop_move_right',
        'customUserEdit_btn_crop_move_top': '#customUserEdit_btn_crop_move_top',
        'customUserEdit_btn_crop_move_buttom': '#customUserEdit_btn_crop_move_buttom',
        'customUserEdit_btn_crop_rotate_left': '#customUserEdit_btn_crop_rotate_left',
        'customUserEdit_btn_crop_rotate_right': '#customUserEdit_btn_crop_rotate_right',
        'customUserEdit_btn_crop_scale_x': '#customUserEdit_btn_crop_scale_x',
        'customUserEdit_btn_crop_scale_y': '#customUserEdit_btn_crop_scale_y',
        'help': '#help',
    },
    events: {
        'click @ui.customUserEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customUserEditItem_btnCancel': 'btnCancelClick',
        'click @ui.customUserEditItem_btnMailQRCode': 'btnMailQRCodeClick',
        'click @ui.customUserEditItem_btnRemoveImage': 'btnRemoveImageClick',
        'click @ui.customUserEdit_btn_crop_zoom_plus': 'btnZoomPlusClick',
        'click @ui.customUserEdit_btn_crop_zoom_minus': 'btnZoomMinusClick',
        'click @ui.customUserEdit_btn_crop_move_left': 'btnMoveLeftClick',
        'click @ui.customUserEdit_btn_crop_move_right': 'btnMoveRightClick',
        'click @ui.customUserEdit_btn_crop_move_top': 'btnMoveTopClick',
        'click @ui.customUserEdit_btn_crop_move_buttom': 'btnMoveButtomClick',
        'click @ui.customUserEdit_btn_crop_rotate_left': 'btnRotateLeftClick',
        'click @ui.customUserEdit_btn_crop_rotate_right': 'btnRotateRightClick',
        'click @ui.customUserEdit_btn_crop_scale_x': 'btnScaleXClick',
        'click @ui.customUserEdit_btn_crop_scale_y': 'btnScaleYClick',
        'click #help': 'help'

    },
    renderInformation: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: resources.X100072 });
        this.informationCollection.add(tmpModel);

    },
    onRender() {
    },
    onAttach() {
        var self = this;

        this.ui.customUserEdit_image.attr('src', this.model.attributes.ImageUrl);

        FilePond.registerPlugin(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImageResize,
            FilePondPluginImageTransform,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageSizeMetaData
        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.customUserEdit_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            imageResizeTargetWidth: 350,
            imageResizeTargetHeight: null,
            imageResizeMode: 'contain',
            imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,

            /*
             * allowMultiple: false,
            //allowReorder: true,
            allowFileEncode: true,
            //allowImageCrop: true,
            //imageCropAspectRatio:"1:1",
            //allowImageResize: true,
            //imageResizeTargetWidth: 750,
            //imageResizeTargetHeight: 750,
            imageResizeMode: 'contain',
            //imageResizeUpscale: true,
            //imageCropAspectRatio: 1,
            allowImageTransform: true,
            //allowImagePreview: true,
            //allowImageEdit:true,
             */

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var base64 = fileItem.getFileEncodeDataURL();

                    self.localSetting.attributes.ImageSelected = true;

                    if (self.cropper) {
                        self.cropper.destroy();
                    }
                    self.ui.customUserEdit_image.attr('src', base64);
                    //self.ui.customUserEdit_roundImage.attr('src', base64);

                    //var mimeType = self.base64MimeType(base64);
                    //var fileData = base64.split(',')[1];

                    self.localSetting.attributes.FileName = fileItem.filenameWithoutExtension;

                    //var previews = document.querySelectorAll('.cropper-img-preview');
                    //var imageDefault = document.querySelector('#customUserEdit_image');

                    //self.cropper = new Cropper(imageDefault, {
                    //    aspectRatio: NaN,
                    //    autoCropArea: 1,
                    //    zoomOnTouch: false,
                    //    zoomOnWheel: false,
                    //    cropBoxResizable: true,
                    //    build: function () {
                    //        var clone = this.cloneNode();

                    //        clone.style.cssText = (
                    //            'display: block;' +
                    //            'width: 100%;' +
                    //            'minWidth: 0;' +
                    //            'minHeight: 0;' +
                    //            'maxWidth: none;' +
                    //            'maxHeight: none;'
                    //        );
                    //    },

                    //    crop: function (e) {
                    //        /*var data = e.detail;
                    //        var cropper = this.cropper;
                    //        var imageData = cropper.getImageData();
                    //        var previewAspectRatio = data.width / data.height;*/
                    //    }
                    //});

                    //self.setupBtn();

                    self.setupCropper();
                    self.localSetting.attributes.ImageModify = true;

                    pond.removeFile(fileItem);
                };
            }
        });

        this.setupCropper();
        this.setupStatus();

        $('.btn.dropdown-toggle.btn-light').css("width", this.ui.customUserEditItem_inputEmail.width());

        var checked = this.model.attributes.EnableUserText == 1 ? true : false;
        this.ui.customUserEditItem_checkboxEnableUserText.attr('checked', checked);

        var checked = this.model.attributes.EnablePomodoro == 1 ? true : false;
        this.ui.customUserEditItem_checkboxEnablePomodoro.attr('checked', checked);

        var checked = this.model.attributes.Enable == 1 ? true : false;
        this.ui.customUserEditItem_checkboxEnable.attr('checked', checked);
    },
    setupCropper: function () {
        var self = this;
        if (this.model != undefined) {
            var self = this;


            if (this.cropper) {
                this.cropper.destroy();
            }

            //var previews = document.querySelectorAll('.cropper-img-preview');
            var imageDefault = document.querySelector('#customUserEdit_image');

            var cropBoxData;
            var canvasData;



            this.cropper = new Cropper(imageDefault, {
                aspectRatio: NaN,
                autoCropArea: 1,
                //autoCrop:false,
                zoomOnTouch: false,
                zoomOnWheel: false,
                cropBoxResizable: true,
                build: function () {
                    var clone = this.cloneNode();

                    clone.style.cssText = (
                        'display: block;' +
                        'width: 100%;' +
                        'minWidth: 0;' +
                        'minHeight: 0;' +
                        'maxWidth: none;' +
                        'maxHeight: none;'
                    );
                },

                crop: function (e) {
                    //First time when autoCrop er true it will trigger crop
                    if (!self.localSetting.attributes.AutoCrop) {
                        self.localSetting.attributes.AutoCrop = true;
                    } else {
                        self.localSetting.attributes.ImageModify = true;
                    }
                    /*var data = e.detail;
                    var cropper = this.cropper;
                    var imageData = cropper.getImageData();
                    var previewAspectRatio = data.width / data.height;*/
                }
            });
        }
    },
    setupStatus() {
        var self = this;

        //this.ui.customUserEditItem_selectStatus.empty();

        var selected = '';

        

        self.ui.customUserEditItem_selectStatus.selectpicker({ size: 10 });

        $('select[name=selValue]').val(self.model.attributes.Color);
        self.ui.customUserEditItem_selectStatus.selectpicker('refresh')

        selected = '';
        //if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Off) selected = 'selected = "selected"';
        //var options = String.format('<option data-content="<span style="background-color:{1};color:{1};width:20px;max-width:20px;" class="badge badge-success">b</span><span style="margin-left:10px;" class="">{0}</span></option>', this.app.entities.models.custom.attributes.SolidColor_Text_Off, this.app.entities.models.custom.attributes.SolidColor_Color_Off, selected);
        //self.ui.customUserEditItem_selectStatus.find('filter-option-inner-inner').append('<span style="background-color:red;color:red;width:20px;max-width:20px;" class="badge badge-success">b</span><span style="margin-left:10px;" class="">aaaa</span>');
        
        /*selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Off) selected = 'selected = "selected"';
        var options = String.format('<option data-content="<span class="badge badge-success">Relish</span>" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Off, this.app.entities.models.custom.attributes.SolidColor_Color_Off, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Busy) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Busy, this.app.entities.models.custom.attributes.SolidColor_Color_Busy, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Available) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Available, this.app.entities.models.custom.attributes.SolidColor_Color_Available, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Blue) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Blue, this.app.entities.models.custom.attributes.SolidColor_Color_Blue, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Yellow) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Yellow, this.app.entities.models.custom.attributes.SolidColor_Color_Yellow, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Cyan) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Cyan, this.app.entities.models.custom.attributes.SolidColor_Color_Cyan, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_Magenta) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_Magenta, this.app.entities.models.custom.attributes.SolidColor_Color_Magenta, selected);
        self.ui.customUserEditItem_selectStatus.append(options);

        selected = '';
        if (self.model.attributes.Color == this.app.entities.models.custom.attributes.SolidColor_Color_CustomColor) selected = 'selected = "selected"';
        var options = String.format('<option style="background-color:{1}" value="{1}" {2}>{0}</option>', this.app.entities.models.custom.attributes.SolidColor_Text_CustomColor, this.app.entities.models.custom.attributes.SolidColor_Color_CustomColor, selected);
        self.ui.customUserEditItem_selectStatus.append(options);*/

        
    },
    onDestroy() {
    },
    validate: function () {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customUserEditItem_inputName.val();
        this.model.attributes.LuxaforId = this.ui.customUserEditItem_inputLuxaforId.val();
        this.model.attributes.CallerId = this.ui.customUserEditItem_inputCallerId.val();
        this.model.attributes.Email = this.ui.customUserEditItem_inputEmail.val();

        var enableUserText = this.model.attributes.EnableUserText;
        this.ui.customUserEditItem_checkboxEnableUserText.is(':checked') ? enableUserText = true : enableUserText = false;
        this.model.attributes.EnableUserText = enableUserText;
        
        this.model.attributes.UserText = this.ui.customUserEditItem_inputUserText.val();

        var enablePomodoro = this.model.attributes.EnablePomodoro;
        this.ui.customUserEditItem_checkboxEnablePomodoro.is(':checked') ? enablePomodoro = true : enablePomodoro = false;
        this.model.attributes.EnablePomodoro = enablePomodoro;

        var enable = this.model.attributes.Enable;
        this.ui.customUserEditItem_checkboxEnable.is(':checked') ? enable = true : enable = false;
        this.model.attributes.Enable = enable;

        this.model.attributes.EncryptText01 = resources.X200366;
        this.model.attributes.EncryptText02 = resources.X200367;
        this.model.attributes.EncryptText03 = resources.X200368;
        this.model.attributes.EncryptText04 = resources.X200369;
        this.model.attributes.EncryptText05 = resources.X200370;
        this.model.attributes.EncryptText06 = resources.X200371;
        this.model.attributes.EncryptText07 = resources.X200364;

        this.getImageData(this.model);

        this.model.attributes.Color = this.ui.customUserEditItem_selectStatus.val();

        var data_id = this.ui.customUserEditItem_selectStatus.find(':selected').attr('data-id');

        var tmpActiveTab = this.app.entities.models.customColor.attributes.Custom_Text_Default;

        switch (data_id) {
            case "0": tmpActiveTab = this.app.entities.models.customColor.attributes.Custom_Text_Default;
                tmpActiveTab = resources.X200311;
                break;
            case "1": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Off;
                tmpActiveTab = resources.X200270;
                break;
            case "2": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Busy;
                tmpActiveTab = resources.X200270;
                break;
            case "3": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Available;
                tmpActiveTab = resources.X200270;
                break;
            case "4": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Blue;
                tmpActiveTab = resources.X200270;
                break;
            case "5": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Yellow;
                tmpActiveTab = resources.X200270;
                break;
            case "6": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Cyan;
                tmpActiveTab = resources.X200270;
                break;
            case "7": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_Magenta;
                tmpActiveTab = resources.X200270;
                break;
            case "8": tmpActiveTab = this.app.entities.models.customColor.attributes.SolidColor_Text_CustomColor;
                tmpActiveTab = resources.X200270;
                break;
            case "9": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_Online;
                tmpActiveTab = resources.X200303;
                break;
            case "10": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_Busy;
                tmpActiveTab = resources.X200303;
                break;
            case "11": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_DoNotDisturb;
                tmpActiveTab = resources.X200303;
                break;
            case "12": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_BeRightBack;
                tmpActiveTab = resources.X200303;
                break;
            case "13": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsNotPresent;
                tmpActiveTab = resources.X200303;
                break;
            case "14": tmpActiveTab = this.app.entities.models.customColor.attributes.MSTeams_Text_ShowAsOffline;
                tmpActiveTab = resources.X200303;
                break;
            case "15": tmpActiveTab = this.app.entities.models.customColor.attributes.Mutebutton_Text_On;
                tmpActiveTab = resources.X200312;
                break;
            case "16": tmpActiveTab = this.app.entities.models.customColor.attributes.Mutebutton_Text_Off;
                tmpActiveTab = resources.X200312;
                break;
            case "17": tmpActiveTab = this.app.entities.models.customColor.attributes.Pomodoro_Text_Time;
                tmpActiveTab = resources.X200286
                break;
            case "18": tmpActiveTab = this.app.entities.models.customColor.attributes.Pomodoro_Text_ShortBreak;
                tmpActiveTab = resources.X200286
                break;
            case "19": tmpActiveTab = this.app.entities.models.customColor.attributes.Pomodoro_Text_LongBreak;
                tmpActiveTab = resources.X200286
                break;

        }

        this.model.attributes.ActiveTab = tmpActiveTab;

        var modelErrors = this.model.validateNoBackbone(app, false);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            //this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        return true;
    },
    pushMessage: function () {
        var message = String.format("?SecretKey={0}&SecretCode={1}&Refresh=PortalAllDepartment",
            this.app.entities.models.custom.attributes.SecretKey,
            this.app.entities.models.custom.attributes.SecretCode,
            this.model.attributes.id);

        var tmpModel = new Entities_CustomPush.Model();
        tmpModel.attributes.Message = message;
        tmpModel.attributes.id = 0;
        tmpModel.attributes.TokenKey = "";

        tmpModel.save(tmpModel, {
            silens: true,
            wait: true,
            success: function (response) {
            }
        });
    },
    btnSubmitClick: function (event, callbackMenu) {
        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.setHasChanged(false);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200036;
            dialogWaitingModel.attributes.Text = resources.X200376;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function () {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            this.model.attributes.DeleteImage = false;
            self.model.save(this.model.attributes, {
                route: 'route01',
                success: function () {
                    self.pushMessage();

                    dialogWaiting.triggerMethod('dialog:okclicked');
                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    if (callbackMenu != undefined) {
                        callbackMenu();
                    } else {
                        $('.modal-backdrop').remove();
                        self.app.router.navigate("#customUser");
                        self.app.router.customUser();
                    }
                }
            });
        }
    },
    btnCancelClick: function (event) {

        if (event != null) {
            event.preventDefault();
        }

        this.model.attributes.Name = this.oldModel.attributes.Name;
        this.model.attributes.CallerId = this.oldModel.attributes.CallerId;
        this.model.attributes.LuxaforId = this.oldModel.attributes.LuxaforId;
        this.model.attributes.Email = this.oldModel.attributes.Email;
        this.model.attributes.EnableUserText = this.oldModel.attributes.EnableUserText;
        this.model.attributes.UserText = this.oldModel.attributes.UserText;

        self.app.router.navigate("#customUser");
        self.app.router.customUser();
    },
    btnMailQRCodeClick: function (event, callbackMenu) {
        if (event != null) {
            event.preventDefault();
        }

        var self = this;

        if (this.model.attributes.EnablePomodoro == false) {
            var dialogBoxModel = new Entities_Empty.Model();
            dialogBoxModel.attributes.Title = resources.X200122;
            dialogBoxModel.attributes.Text = resources.X200396;
            dialogBoxModel.attributes.Button = resources.X100279;

            var dialogBox = new View_DialogBox({ model: dialogBoxModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogBox, "dialog:okclicked", callback);
            }
            this.listenTo(dialogBox, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogBox);
        }
        else {
            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X200392;
            dialog2ButtonModel.attributes.Text = resources.X200395;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;

            var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: self });

            var callback01 = function () {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
                $('.modal-backdrop').remove();
                self.sendPomodoroMail();
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                self.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                self.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
                self.stopListening(dialog2Button, "dialog:closeclicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            self.app.dialogRegion.show(dialog2Button);
        }
    },
    sendPomodoroMail: function () {
        var self = this;

        var dialogWaitingModel = new Entities_Empty.Model();
        dialogWaitingModel.attributes.Title = resources.X200378;
        dialogWaitingModel.attributes.Text = resources.X200392;
        dialogWaitingModel.attributes.Button = resources.X200025;

        var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: this });
        var callback = function () {
            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
        }
        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

        self.app.dialogRegion2.show(dialogWaiting);

        var self = this;

        this.model.attributes.EncryptText01 = resources.X200366;
        this.model.attributes.EncryptText02 = resources.X200367;
        this.model.attributes.EncryptText03 = resources.X200368;
        this.model.attributes.EncryptText04 = resources.X200369;
        this.model.attributes.EncryptText05 = resources.X200370;
        this.model.attributes.EncryptText06 = resources.X200371;
        this.model.attributes.EncryptText07 = resources.X200364;
        this.model.attributes.ImageData = '';
        this.model.attributes.ImageFilename = '';

        this.model.attributes.DeleteImage = false;
        self.model.save(this.model.attributes, {
            route: 'route02',
            success: function () {
                dialogWaiting.triggerMethod('dialog:close');
            }
        });
    },
    btnRemoveImageClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.setHasChanged(false);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200036;
            dialogWaitingModel.attributes.Text = resources.X200375;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function () {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            this.model.attributes.DeleteImage = true;
            self.model.save(this.model.attributes, {
                route: 'route01',
                success: function () {
                    self.pushMessage();

                    dialogWaiting.triggerMethod('dialog:okclicked');
                    //self.collection.fullCollection.sort();
                    //self.collection.getFirstPage({ fetch: false });

                    $('.modal-backdrop').remove();

                    var url = 'customUserEditItem/' + self.model.attributes.id;
                    self.app.router.navigate(url);
                    self.app.router.customUserEditItem(self.model.attributes.id);

                    //self.app.router.navigate("#customUser");
                    //self.app.router.customUser();
                }
            });
        }
    },
    //btnSubmitClick: function (event, callbackMenu) {
    //    if (event != null) {
    //        event.preventDefault();
    //    }
    //    var self = this;
    //    if (this.validate()) {
    //        this.setHasChanged(false);

    //        var dialogWaitingModel = new Entities_Empty.Model();
    //        dialogWaitingModel.attributes.Title = resources.X200101;
    //        dialogWaitingModel.attributes.Text = resources.X200093;
    //        dialogWaitingModel.attributes.Button = resources.X200025;

    //        var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
    //        var callback = function () {
    //            this.stopListening(dialogWaiting, "dialog:okclicked", callback);
    //        }
    //        this.listenTo(dialogWaiting, "dialog:okclicked", callback);

    //        self.app.dialogRegion.show(dialogWaiting);

    //        self.model.save(this.model.attributes, {
    //            route: 'route01',
    //            success: function () {
    //                dialogWaiting.triggerMethod('dialog:okclicked');
    //                $('.modal-backdrop').remove();
    //            }
    //        });
    //    }
    //},
    btnZoomPlusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(0.1);
    },
    btnZoomMinusClick: function (event) {
        event.preventDefault();
        this.cropper.zoom(-0.1);
    },
    btnMoveLeftClick: function (event) {
        event.preventDefault();
        this.cropper.move(-10, 0);
    },
    btnMoveRightClick: function (event) {
        event.preventDefault();
        this.cropper.move(10, 0);
    },
    btnMoveTopClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, -10);
    },
    btnMoveButtomClick: function (event) {
        event.preventDefault();
        this.cropper.move(0, 10);
    },
    btnRotateLeftClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(-90);
    },
    btnRotateRightClick: function (event) {
        event.preventDefault();
        this.cropper.rotate(90);
    },
    btnScaleXClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleX) {
            case 1: this.cropper.scaleX(-1); break;
            case -1: this.cropper.scaleX(1); break;
        }
    },
    btnScaleYClick: function (event) {
        event.preventDefault();

        switch (this.cropper.getData().scaleY) {
            case 1: this.cropper.scaleY(-1); break;
            case -1: this.cropper.scaleY(1); break;
        }
    },
    getWeboptimizeImage: function (width, height) {
        if (this.cropper) {
            //Calc height
            var cropBoxData = this.cropper.getCropBoxData();
            if (cropBoxData.width != undefined) {
                var aspectRatio = /*cropBoxData.height /*/ cropBoxData.width / cropBoxData.height;
                height = 100;
                width = height * aspectRatio;
                //height = width * aspectRatio;

                var croppedCanvas;
                var canvas;
                var image;

                // Crop
                croppedCanvas = this.cropper.getCroppedCanvas();

                // Round
                canvas = this.getWeboptimizeCanvas(croppedCanvas, width, height);

                var struct = {
                    width: width,
                    height: height,
                    data: canvas.toDataURL('image/png', 0.5)
                }

                return struct;
            }
            return null;
        }
    },
    getImageData: function (tmpModel) {
        var searchString = ";base64,";
        var pos = 0;

        var image515 = this.getWeboptimizeImage(200, 100);

        if (image515 != null && this.localSetting.attributes.ImageModify == true) {
            var imageData512 = image515.data;
            pos = imageData512.indexOf(searchString);
            var imageMimeType512 = imageData512.substring(5, pos);
            imageData512 = imageData512.substring(pos + searchString.length, imageData512.length);

            tmpModel.attributes.ImageFilename = 'User';//resources.X200122;
            tmpModel.attributes.ImageData = imageData512;
        } else {
            tmpModel.attributes.ImageFilename = '';
            tmpModel.attributes.ImageData = '';
        }
    },
    getWeboptimizeCanvas: function (sourceCanvas, width, height) {
        var canvas = document.createElement('canvas');

        var context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.drawImage(sourceCanvas, 0, 0, width, height);

        return canvas;
    }
}, Backbone.Radio.Requests);