import $ from 'jquery';

(function ($) {
    'use strict';
    $(function () {
        $.fn.showToast = function (toastType, heading, text) {
           'use strict';
            var hideAfter = 5000;
            switch (toastType) {
                case 0: this.showSuccessToast(heading, text, hideAfter); break;
                case 1: this.showInfoToast(heading, text, hideAfter); break;
                case 2: this.showWarningToast(heading, text, hideAfter); break;
                case 3: this.showDangerToast(heading, text, hideAfter); break;
            }
        };

        $.fn.showSuccessToast = function (heading, text, hideAfter) {
            'use strict';
            this.resetToastPosition();
            $.toast({
                heading: heading,
                text: text,
                showHideTransition: 'slide',
                icon: 'success',
                loaderBg: '#f96868',
                position: 'top-right',
                hideAfter: hideAfter
            })
        };

        $.fn.showInfoToast = function (heading, text, hideAfter) {
            'use strict';
            this.resetToastPosition();
            $.toast({
                heading: heading,
                text: text,
                showHideTransition: 'slide',
                icon: 'info',
                loaderBg: '#46c35f',
                position: 'top-right',
                hideAfter: hideAfter
            })
        };

        $.fn.showWarningToast = function (heading, text, hideAfter) {
            'use strict';
            this.resetToastPosition();
            $.toast({
                heading: heading,
                text: text,
                showHideTransition: 'slide',
                icon: 'warning',
                loaderBg: '#57c7d4',
                position: 'top-right',
                hideAfter: hideAfter
            })
        };

        $.fn.showDangerToast = function (heading, text, hideAfter) {
            'use strict';
            this.resetToastPosition();
            $.toast({
                heading: heading,
                text: text,
                showHideTransition: 'slide',
                icon: 'error',
                loaderBg: '#f2a654',
                position: 'top-right',
                hideAfter: hideAfter
            })
        };

        $.fn.showToastPosition = function (position, heading, text, hideAfter) {
            'use strict';
            this.resetToastPosition();
            $.toast({
                heading: heading,
                text: text,
                position: String(position),
                icon: 'info',
                stack: false,
                loaderBg: '#f96868',
                hideAfter: hideAfter
            })
        };

        $.fn.showToastInCustomPosition = function (heading, text, hideAfter) {
            'use strict';
            this.resetToastPosition();
            $.toast({
                heading: heading,
                text: text,
                icon: 'info',
                position: {
                    left: 120,
                    top: 120
                },
                stack: false,
                loaderBg: '#f96868',
                hideAfter: hideAfter
            })
        };

        $.fn.resetToastPosition = function () {
            $('.jq-toast-wrap').removeClass('bottom-left bottom-right top-left top-right mid-center'); // to remove previous position class
            $(".jq-toast-wrap").css({
                "top": "",
                "left": "",
                "bottom": "",
                "right": ""
            }); //to remove previous position style
        };
    });
})(jQuery);

export default {};