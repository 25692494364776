'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customAdBigImageListItem from './tpl/customAdBigImageListItem.html';
//import tpl_customAdBigImageListItem500 from './tpl/customAdBigImageListItem500.html';
//import tpl_customAdBigImageListItem1000 from './tpl/customAdBigImageListItem1000.html';
import language from '../../../common/language';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';

export default Marionette.ItemView.extend({
    getTemplate: function () {
        switch (this.model.attributes.SizeType) {
            case 0 : this.model.attributes.RibbonText = resources.X100492; 
                return tpl_customAdBigImageListItem({ model: this.model.toJSON() });
                break;
            case 1: this.model.attributes.RibbonText = resources.X101289; 
                return tpl_customAdBigImageListItem({ model: this.model.toJSON() });
                break;
            case 2 : this.model.attributes.RibbonText = resources.X100494; 
                return tpl_customAdBigImageListItem1000({ model: this.model.toJSON() });
                break;
            case 3 : this.model.attributes.RibbonText = resources.X100495; 
                return tpl_customAdBigImageListItem500({ model: this.model.toJSON() });
                break;
            case 4 : this.model.attributes.RibbonText = resources.X100496; 
                return tpl_customAdBigImageListItem1000({ model: this.model.toJSON() });
                break;
            case 5 : this.model.attributes.RibbonText = resources.X100959; 
                return tpl_customAdBigImageListItem({ model: this.model.toJSON() });
                break;
            case 6: this.model.attributes.RibbonText = resources.X101201;
                return tpl_customAdBigImageListItem({ model: this.model.toJSON() });
                break;
        }
        
        return tpl_customAdBigImageListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customAdBigImageListItem_btnDelete': '#customAdBigImageListItem_btnDelete'
    },
    events: {
        'click #customAdBigImageListItem_btnDelete': 'btnDeleteClick'
    },
    btnDeleteClick: function (event) {
        event.preventDefault();

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100070;
        dialog2ButtonModel.attributes.Text = resources.X100386;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100321;
            dialogWaitingModel.attributes.Text = resources.X100298;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            app.dialogRegion.show(dialogWaiting);

            var collection = this.app.entities.collections.customAdBigImage;

            this.model.destroy({
                wait: true,
                success: function (model, response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    collection.fullCollection.sort();
                    if (collection.state.currentPage < collection.state.lastPage) {
                        collection.getPage(collection.state.currentPage);
                    }
                    else {
                        collection.getLastPage();
                    }
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    }
});