﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_pagination from './tpl/pagination.html';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_pagination({ model: this.collection });
    },
    initialize: function () {
        this.listenTo(this.collection, 'all', this.renderPagination);
        //this.listenTo(this.collection, 'add', this.renderPagination);
    },
    renderPagination: function () {
        //this.empty();
        this.$el.empty();
        this.render();
    },
    ui: {
        'first': '.first',
        'prev': '.prev',
        'next': '.next',
        'last': '.last',
        'page': '.page',
    },
    events: {
        'click @ui.first': 'gotoFirst',
        'click @ui.prev': 'gotoPrev',
        'click @ui.next': 'gotoNext',
        'click @ui.last': 'gotoLast',
        'click @ui.page': 'gotoPage',
    },
    gotoFirst: function (e) {
        this.collection.getPage(this.collection.state.firstPage);
    },
    gotoPrev: function (e) {
        this.collection.getPreviousPage();
    },
    gotoNext: function (e) {
        this.collection.getNextPage();
    },
    gotoLast: function (e) {
        this.collection.getPage(this.collection.state.lastPage);
    },
    gotoPage: function (e) {
        var page = $(e.target).text();
        this.collection.getPage(parseInt(page));
    }
});