'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as FileApi from 'fileapi';

import tpl_customSchemaModelInsert from './tpl/customSchemaModelInsert.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customSchemaModelInsert();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'customSchemaModelInsert_btnSubmit': '#customSchemaModelInsert_btnSubmit',
        'customSchemaModelInsert_inputName': '#customSchemaModelInsert_inputName'
    },
    events: {
        'click @ui.customSchemaModelInsert_btnSubmit': 'btnSubmitClick'
    },
    btnSubmitClick: function () {
        this.hideErrors();
        var tmpModel = new this.collection.model();
        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.Name = this.ui.customSchemaModelInsert_inputName.val();

        tmpModel.attributes.FieldUsed = 12;

        tmpModel.attributes.FontSizeSchema = 6;
        tmpModel.attributes.MarginLeftSchema = 1;
        tmpModel.attributes.MarginRightSchema = 1;

        tmpModel.attributes.FontSizeSchemaBigscreen = 13;
        tmpModel.attributes.MarginLeftSchemaBigscreen = 2;
        tmpModel.attributes.MarginRightSchemaBigscreen = 2;

        tmpModel.attributes.Field01 = '';
        tmpModel.attributes.Field02 = '';
        tmpModel.attributes.Field03 = '';
        tmpModel.attributes.Field04 = '';
        tmpModel.attributes.Field05 = '';
        tmpModel.attributes.Field06 = '';
        tmpModel.attributes.Field07 = '';
        tmpModel.attributes.Field08 = '';
        tmpModel.attributes.Field09 = '';
        tmpModel.attributes.Field10 = '';
        tmpModel.attributes.Field11 = '';
        tmpModel.attributes.Field12 = '';

        tmpModel.attributes.FieldEvent01 = true;
        tmpModel.attributes.FieldEvent02 = true;
        tmpModel.attributes.FieldEvent03 = true;
        tmpModel.attributes.FieldEvent04 = true;
        tmpModel.attributes.FieldEvent05 = true;
        tmpModel.attributes.FieldEvent06 = true;
        tmpModel.attributes.FieldEvent07 = true;
        tmpModel.attributes.FieldEvent08 = true;
        tmpModel.attributes.FieldEvent09 = true;
        tmpModel.attributes.FieldEvent10 = true;
        tmpModel.attributes.FieldEvent11 = true;
        tmpModel.attributes.FieldEvent12 = true;

        tmpModel.attributes.FieldBigscreen = 1;
        tmpModel.attributes.FieldBigscreen01 = true;
        tmpModel.attributes.FieldBigscreen02 = true;
        tmpModel.attributes.FieldBigscreen03 = true;
        tmpModel.attributes.FieldBigscreen04 = true;
        tmpModel.attributes.FieldBigscreen05 = true;
        tmpModel.attributes.FieldBigscreen06 = true;
        tmpModel.attributes.FieldBigscreen07 = true;
        tmpModel.attributes.FieldBigscreen08 = true;
        tmpModel.attributes.FieldBigscreen09 = true;
        tmpModel.attributes.FieldBigscreen10 = true;
        tmpModel.attributes.FieldBigscreen11 = true;
        tmpModel.attributes.FieldBigscreen12 = true;

        this.hideErrors();

        var ret = tmpModel.validateNoBackbone(app);
        if (ret == false) {
            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100150;
            dialogWaitingModel.attributes.Text = resources.X100311;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);

            var self = this;

            this.collection.fullCollection.create(tmpModel, {
                wait: true,
                success: function (response) {
                    dialogWaiting.triggerMethod('dialog:close');
                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    self.ui.customSchemaModelInsert_inputName.val('');
                }
            });
        }
        else {
            this.showErrors(tmpModel.errors);
        }
    }
});

