var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customUserEditItem_empty"> <div class="content-wrapper" data-help="customUserEditItem"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200036'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200036'] ))==null?'':__t)+
'</h4> <form class="forms-sample template-demo"> <div id="customUserEditItemInformation" class="e4usInformation" style="display:none"></div> <div class="control-group customUserEditItem_inputName"> <div class="form-group"> <label for="customUserEditItem_inputName">'+
((__t=( resources['X200051'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customUserEditItem_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserEditItem_inputLuxaforId"> <div class="form-group"> <label for="customUserEditItem_inputLuxaforId">'+
((__t=( resources['X200285'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.LuxaforId))==null?'':__t)+
'" id="customUserEditItem_inputLuxaforId" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserEditItem_inputCallerId"> <div class="form-group"> <label for="customUserEditItem_inputCallerId">'+
((__t=( resources['X200118'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.CallerId))==null?'':__t)+
'" id="customUserEditItem_inputCallerId" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserEditItem_inputEmail"> <div class="form-group"> <label for="customUserEditItem_inputEmail">'+
((__t=( resources['X200119'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Email))==null?'':__t)+
'" id="customUserEditItem_inputEmail" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div>  <div class="control-group customUserEditItem_selectStatus"> <div class="form-group"> <label for="customUserEditItem_selectStatus">'+
((__t=( resources['X200257'] ))==null?'':__t)+
'</label> <br> <select name="selValue" class="selectpicker" id="customUserEditItem_selectStatus"> <option data-id="0" value="'+
((__t=( model.tmp.Custom_Color_Default))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Custom_Color_Default))==null?'':__t)+
';color:'+
((__t=( model.tmp.Custom_Color_Default))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Custom_Text_Default))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Custom_Text_Default))==null?'':__t)+
'</option> <option data-id="1" value="'+
((__t=( model.tmp.SolidColor_Color_Off))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Off))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Off))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Off))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Off))==null?'':__t)+
'</option> <option data-id="2" value="'+
((__t=( model.tmp.SolidColor_Color_Busy))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Busy))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Busy))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Busy))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Busy))==null?'':__t)+
'</option> <option data-id="3" value="'+
((__t=( model.tmp.SolidColor_Color_Available))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Available))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Available))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Available))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Available))==null?'':__t)+
'</option> <option data-id="4" value="'+
((__t=( model.tmp.SolidColor_Color_Blue))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Blue))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Blue))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Blue))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Blue))==null?'':__t)+
'</option> <option data-id="5" value="'+
((__t=( model.tmp.SolidColor_Color_Yellow))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Yellow))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Yellow))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Yellow))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Yellow))==null?'':__t)+
'</option> <option data-id="6" value="'+
((__t=( model.tmp.SolidColor_Color_Cyan))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Cyan))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Cyan))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Cyan))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Cyan))==null?'':__t)+
'</option> <option data-id="7" value="'+
((__t=( model.tmp.SolidColor_Color_Magenta))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Magenta))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Magenta))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Magenta))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Magenta))==null?'':__t)+
'</option> <option data-id="8" value="'+
((__t=( model.tmp.SolidColor_Color_CustomColor))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_CustomColor))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_CustomColor))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_CustomColor))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_CustomColor))==null?'':__t)+
'</option> <option data-id="9" value="'+
((__t=( model.tmp.MSTeams_Color_Online))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_Online))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_Online))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_Online))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_Online))==null?'':__t)+
'</option> <option data-id="10" value="'+
((__t=( model.tmp.MSTeams_Color_Busy))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_Busy))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_Busy))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_Busy))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_Busy))==null?'':__t)+
'</option> <option data-id="11" value="'+
((__t=( model.tmp.MSTeams_Color_DoNotDisturb))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_DoNotDisturb))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_DoNotDisturb))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_DoNotDisturb))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_DoNotDisturb))==null?'':__t)+
'</option> <option data-id="12" value="'+
((__t=( model.tmp.MSTeams_Color_BeRightBack))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_BeRightBack))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_BeRightBack))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_BeRightBack))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_BeRightBack))==null?'':__t)+
'</option> <option data-id="13" value="'+
((__t=( model.tmp.MSTeams_Color_ShowAsNotPresent))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsNotPresent))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsNotPresent))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_ShowAsNotPresent))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_ShowAsNotPresent))==null?'':__t)+
'</option> <option data-id="14" value="'+
((__t=( model.tmp.MSTeams_Color_ShowAsOffline))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsOffline))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsOffline))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_ShowAsOffline))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_ShowAsOffline))==null?'':__t)+
'</option> <option data-id="15" value="'+
((__t=( model.tmp.Mutebutton_Color_On))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Mutebutton_Color_On))==null?'':__t)+
';color:'+
((__t=( model.tmp.Mutebutton_Color_On))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Mutebutton_Text_On))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Mutebutton_Text_On))==null?'':__t)+
'</option> <option data-id="16" value="'+
((__t=( model.tmp.Mutebutton_Color_Off))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Mutebutton_Color_Off))==null?'':__t)+
';color:'+
((__t=( model.tmp.Mutebutton_Color_Off))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Mutebutton_Text_Off))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Mutebutton_Text_Off))==null?'':__t)+
'</option> <option data-id="17" value="'+
((__t=( model.tmp.Pomodoro_Color_Time))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Pomodoro_Color_Time))==null?'':__t)+
';color:'+
((__t=( model.tmp.Pomodoro_Color_Time))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Pomodoro_Text_Time))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Pomodoro_Text_Time))==null?'':__t)+
'</option> <option data-id="18" value="'+
((__t=( model.tmp.Pomodoro_Color_ShortBreak))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Pomodoro_Color_ShortBreak))==null?'':__t)+
';color:'+
((__t=( model.tmp.Pomodoro_Color_ShortBreak))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Pomodoro_Text_ShortBreak))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Pomodoro_Text_ShortBreak))==null?'':__t)+
'</option> <option data-id="19" value="'+
((__t=( model.tmp.Pomodoro_Color_LongBreak))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Pomodoro_Color_LongBreak))==null?'':__t)+
';color:'+
((__t=( model.tmp.Pomodoro_Color_LongBreak))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Pomodoro_Text_LongBreak))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Pomodoro_Text_LongBreak))==null?'':__t)+
'</option> </select> </div> </div> <div class="control-group customUserEditItem_inputEnableUserText"> <div class="form-group"> <div class="control-label col-md-6"> <label for="customUserEditItem_checkboxEnableUserText">'+
((__t=( resources['X101487'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customUserEditItem_checkboxEnableUserText"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> <div class="control-group customUserEditItem_inputUserText"> <div class="form-group"> <label for="customUserEditItem_inputUserText">'+
((__t=( resources['X101488'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.UserText))==null?'':__t)+
'" id="customUserEditItem_inputUserText" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserEditItem_inputEnablePomodoro"> <div class="form-group"> <div class="control-label col-md-6"> <label for="customUserEditItem_checkboxEnablePomodoro">'+
((__t=( resources['X101489'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customUserEditItem_checkboxEnablePomodoro"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> <div class="control-group customUserEditItem_checkboxEnable"> <div class="form-group"> <div class="control-label col-md-6"> <label for="customUserEditItem_checkboxEnable">'+
((__t=( resources['X200399'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customUserEditItem_checkboxEnable"> <span class="toggle-slider round"></span> </label> </div> </div> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customUserEditItem_btnSubmit">'+
((__t=( resources['X200106'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customUserEditItem_btnMailQRCode">'+
((__t=( resources['X200365'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customUserEditItem_btnRemoveImage">'+
((__t=( resources['X200374'] ))==null?'':__t)+
'</button> <button type="button" class="btn btn-secondary btn-sm col-sm-3" id="customUserEditItem_btnCancel">'+
((__t=( resources['X200110'] ))==null?'':__t)+
'</button> </form> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200121'] ))==null?'':__t)+
'</h4> <form class="contact-form"> <div class="row" style="margin-left:0px;margin-top:25px"> <div class="col-md-6" style="padding-left:0px"> <div class="control-group Image" style="display:none"> <div class=""> <label type="button" for="customUserEdit_btnImage" class="btn btn-primary">'+
((__t=( resources['X200062'] ))==null?'':__t)+
'</label> <div id="customUserEdit_div_clone"> <input id="customUserEdit_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> <br> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-md-12"> <input id="customUserEdit_filePond" type="file" class="filepond"> </div> </div> <div class="row"> <div class="row" style="margin-left:0px;margin-right:0px"> <div class="control-label col-md-6" style="padding-left:0px"> <div class="cropper-col cropper-img-container"> <img id="customUserEdit_image" alt="" src="/images/cropper.png"> </div> <div class="cropper-col cropper-img-preview"></div> </div> </div> </div> <div class="row" style="margin-top:50px"> <div class=""> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserEdit_btn_crop_zoom_plus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200063'] ))==null?'':__t)+
'"><i class="fa fa-search-plus"></i></button> <button type="button" id="customUserEdit_btn_crop_zoom_minus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200064'] ))==null?'':__t)+
'"><i class="fa fa-search-minus"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserEdit_btn_crop_move_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200065'] ))==null?'':__t)+
'"><i class="fa fa-arrow-left"></i></button> <button type="button" id="customUserEdit_btn_crop_move_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200066'] ))==null?'':__t)+
'"><i class="fa fa-arrow-right"></i></button> <button type="button" id="customUserEdit_btn_crop_move_top" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200067'] ))==null?'':__t)+
'"><i class="fa fa-arrow-up"></i></button> <button type="button" id="customUserEdit_btn_crop_move_buttom" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200068'] ))==null?'':__t)+
'"><i class="fa fa-arrow-down"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserEdit_btn_crop_rotate_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200069'] ))==null?'':__t)+
'"><i class="fa fa-undo-alt"></i></button> <button type="button" id="customUserEdit_btn_crop_rotate_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200070'] ))==null?'':__t)+
'"><i class="fa fa-redo-alt"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserEdit_btn_crop_scale_x" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200071'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-h"></i></button> <button type="button" id="customUserEdit_btn_crop_scale_y" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200072'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-v"></i></button> </div> </div> </div> <br> </form> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
