var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customSchemaData_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customSchemaData">  <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customSchemaDataInformation" class="e4usInformation" style="display:none"></div> <h4 class="card-title">'+
((__t=( resources['X101054'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customSchemaData_inputNameGroup" class="control-group customSchemaData_selectEvent"> <div class="form-group"> <label for="customSchemaData_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customSchemaData_selectEvent"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X100155'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div style="height:5px"></div> <div id="customSchemaSchema"></div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X100390'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="form-group" id="customSchemaData_div_SchemaSchema"> <div class="row"> <div class="control-label col-md-6"> <label id="customSchemaData_label_SchemaSchema">'+
((__t=( resources['X100133'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <select class="form-control" type="text" id="customSchemaData_select_SchemaSchema" style="margin-bottom:0px"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div id="customSchemaDataInsert"></div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X100250'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customSchemaDataList"></div> <div style="height:5px"></div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customSchemaData_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> <div style="height:5px"></div> <div class="row"> <div class="control-label col-md-12"> <button type="button" class="btn btn-secondary btn-sm col-sm-3 btnSchemaData" id="customSchemaData_btnDeleteAllSubmit">'+
((__t=( resources['X100376'] ))==null?'':__t)+
'</button> </div> </div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101048'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customSchemaDataImport"></div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101049'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="row"> <div class="control-label col-md-12"> <a class="btn btn-secondary btn-sm col-sm-3 btnSchemaData" id="customSchemaData_btnExport">'+
((__t=( resources['X100284'] ))==null?'':__t)+
'</a> </div> </div> </form> </div> </div> </div> <div style="display:none" id="customSchemaData_div_newfunction"> <div style="height:3px"></div> <div class="row"> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customSchemaData_btnWebpageSchemaBigscreen">'+
((__t=( resources['X100421'] ))==null?'':__t)+
'</button> </div> <div class="control-label col-md-6"> <button type="button" class="btn btn-secondary" style="width:100%" id="customSchemaData_btnWebpageSchemaApp">'+
((__t=( resources['X100508'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
