﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import helper_infopage from '../../../helper/infopage';

import tpl_pomodoro from './tpl/pomodoro.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#pomodoro_modal',
    errors: [],
    getTemplate: function () {
        return tpl_pomodoro({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        //this.model.attributes.tmp = {};
        //this.model.attributes.tmp.SolidColor_Color_Off = this.app.entities.models.custom.attributes.SolidColor_Color_Off;
        //this.model.attributes.tmp.SolidColor_Text_Off = this.app.entities.models.custom.attributes.SolidColor_Text_Off;
        //this.model.attributes.tmp.SelectedOff = '';

        //this.model.attributes.tmp.SolidColor_Color_Busy = this.app.entities.models.custom.attributes.SolidColor_Color_Busy;
        //this.model.attributes.tmp.SolidColor_Text_Busy = this.app.entities.models.custom.attributes.SolidColor_Text_Busy;
        //this.model.attributes.tmp.SelectedBusy = '';

        //this.model.attributes.tmp.SolidColor_Color_Available = this.app.entities.models.custom.attributes.SolidColor_Color_Available;
        //this.model.attributes.tmp.SolidColor_Text_Available = this.app.entities.models.custom.attributes.SolidColor_Text_Available;
        //this.model.attributes.tmp.SelectedAvailable = '';

        //this.model.attributes.tmp.SolidColor_Color_Blue = this.app.entities.models.custom.attributes.SolidColor_Color_Blue;
        //this.model.attributes.tmp.SolidColor_Text_Blue = this.app.entities.models.custom.attributes.SolidColor_Text_Blue;
        //this.model.attributes.tmp.SelectedBlue = '';

        //this.model.attributes.tmp.SolidColor_Color_Yellow = this.app.entities.models.custom.attributes.SolidColor_Color_Yellow;
        //this.model.attributes.tmp.SolidColor_Text_Yellow = this.app.entities.models.custom.attributes.SolidColor_Text_Yellow;
        //this.model.attributes.tmp.SelectedYellow = '';

        //this.model.attributes.tmp.SolidColor_Color_Cyan = this.app.entities.models.custom.attributes.SolidColor_Color_Cyan;
        //this.model.attributes.tmp.SolidColor_Text_Cyan = this.app.entities.models.custom.attributes.SolidColor_Text_Cyan;
        //this.model.attributes.tmp.SelectedCyan = '';

        //this.model.attributes.tmp.SolidColor_Color_Magenta = this.app.entities.models.custom.attributes.SolidColor_Color_Magenta;
        //this.model.attributes.tmp.SolidColor_Text_Magenta = this.app.entities.models.custom.attributes.SolidColor_Text_Magenta;
        //this.model.attributes.tmp.SelectedMagenta = '';

        //this.model.attributes.tmp.SolidColor_Color_CustomColor = this.app.entities.models.custom.attributes.SolidColor_Color_CustomColor;
        //this.model.attributes.tmp.SolidColor_Text_CustomColor = this.app.entities.models.custom.attributes.SolidColor_Text_CustomColor;
        //this.model.attributes.tmp.SelectedCustomColor = '';
    },
    ui: {
        'pomodoro_inputPomodoroTime': '#pomodoro_inputPomodoroTime',
        //'pomodoro_selectPomodoroTimeColor': '#pomodoro_selectPomodoroTimeColor',
        'pomodoro_group_PomodoroTime': '#pomodoro_group_PomodoroTime',

        'pomodoro_inputShortBreak': '#pomodoro_inputShortBreak',
        //'pomodoro_selectShortBreakColor': '#pomodoro_selectShortBreakColor',
        'pomodoro_group_ShortBreak': '#pomodoro_group_ShortBreak',

        'pomodoro_inputLongBreak': '#pomodoro_inputLongBreak',
        //'pomodoro_selectLongBreakColor': '#pomodoro_selectLongBreakColor',
        'pomodoro_group_LongBreak': '#pomodoro_group_LongBreak',

        'pomodoro_input_LongBreakCycle': '#pomodoro_input_LongBreakCycle',
        'pomodoro_input_LongBreakCycleValue': '#pomodoro_input_LongBreakCycleValue',

        'pomodoro_btn01': '#pomodoro_btn01',
        'pomodoro_btn02': '#pomodoro_btn02'
    },
    events: {
        'click @ui.pomodoro_btn01': 'onBtn01',
        'click @ui.pomodoro_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;

        this.ui.pomodoro_inputPomodoroTime.durationPicker({
            translations: {
                day: resources.X200259,
                hour: resources.X200260,
                minute: resources.X200261,
                second: resources.X200262,
                days: resources.X200263,
                hours: resources.X200264,
                minutes: resources.X200265,
                seconds: resources.X200266,
            },
            showSeconds: false,
            showDays: false,


            onChanged: function (newVal) {
                self.model.set({
                    PomodoroTime: newVal
                });
            }
        });

        $('.bdp-block.hidden').css('display', 'none');
        $('#pomodoro_group_PomodoroTime').find('.input-sm').width(90);

        this.ui.pomodoro_inputShortBreak.durationPicker({
            translations: {
                day: resources.X200259,
                hour: resources.X200260,
                minute: resources.X200261,
                second: resources.X200262,
                days: resources.X200263,
                hours: resources.X200264,
                minutes: resources.X200265,
                seconds: resources.X200266,
            },
            showSeconds: false,
            showDays: false,


            onChanged: function (newVal) {
                self.model.set({
                    ShortBreak: newVal
                });
            }
        });

        $('.bdp-block.hidden').css('display', 'none');
        $('#pomodoro_group_ShortBreak').find('.input-sm').width(90);

        this.ui.pomodoro_inputLongBreak.durationPicker({
            translations: {
                day: resources.X200259,
                hour: resources.X200260,
                minute: resources.X200261,
                second: resources.X200262,
                days: resources.X200263,
                hours: resources.X200264,
                minutes: resources.X200265,
                seconds: resources.X200266,
            },
            showSeconds: false,
            showDays: false,


            onChanged: function (newVal) {
                self.model.set({
                    LongBreak: newVal
                });
            }
        });

        $('.bdp-block.hidden').css('display', 'none');
        $('#pomodoro_group_LongBreak').find('.input-sm').width(90);

        if ($("#pomodoro_input_LongBreakCycle").length) {
            var startSlider1 = document.getElementById('pomodoro_input_LongBreakCycle');
            nouislider.create(startSlider1, {
                start: self.model.attributes.LongBreakCycle,
                behaviour: 'snap',
                //connect: [true, false],
                range: {
                    min: 1,
                    max: 50
                },
                step: 1
            });
            startSlider1.noUiSlider.on('set', function (e) {
                self.model.attributes.LongBreakCycle = parseInt(e);
                self.ui.pomodoro_input_LongBreakCycleValue.html(parseInt(e));
            });
        }

        this.setupStatus();
    },
    setupStatus() {
        var self = this;

        //this.ui.pomodoro_selectPomodoroTimeColor.selectpicker();
        //$('select[name=selValue1]').val(this.model.attributes.PomodoroTimeColor);
        //this.ui.pomodoro_selectPomodoroTimeColor.selectpicker('refresh');

        //this.ui.pomodoro_selectShortBreakColor.selectpicker();
        //$('select[name=selValue2]').val(this.model.attributes.ShortBreakColor);
        //this.ui.pomodoro_selectShortBreakColor.selectpicker('refresh');

        //this.ui.pomodoro_selectLongBreakColor.selectpicker();
        //$('select[name=selValue3]').val(this.model.attributes.LongBreakColor);
        //this.ui.pomodoro_selectLongBreakColor.selectpicker('refresh');
    },
    setModel: function () {
        //this.model.set({
        //    PomodoroTimeColor: this.ui.pomodoro_selectPomodoroTimeColor.val(),
        //    ShortBreakColor: this.ui.pomodoro_selectShortBreakColor.val(),
        //    LongBreakColor: this.ui.pomodoro_selectLongBreakColor.val()
        //});
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        callback();
        //this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
});