var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div> <a class="dropdown-item preview-item" id="languageSelectorListItemItem" style="cursor:pointer"> '+
((__t=(model.Name ))==null?'':__t)+
' </a> </div>';
}
return __p;
};
