var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="pictureSingleListItemProperties_empty"> <div class="modal fade" id="pictureSingleListItemProperties_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101425'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="accordion accordion-bordered" id="accordion" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-1"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"> '+
((__t=( resources['X101419'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="homec-tab-1" data-toggle="tab" href="#homec-1" role="tab" aria-controls="homec-1" aria-selected="true">'+
((__t=( resources['X101330'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="homec-tab-2" data-toggle="tab" href="#homec-2" role="tab" aria-controls="homec-2" aria-selected="false">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="homec-1" role="tabpanel" aria-labelledby="homec-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="img-container"> <img id="pictureSingleListItemProperties_image" alt="" src="/images/cropper.png" style="max-width: 100%"> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="pictureSingleListItemProperties_btn_crop_zoom_plus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101208'] ))==null?'':__t)+
'"><i class="fa fa-search-plus"></i></button> <button type="button" id="pictureSingleListItemProperties_btn_crop_zoom_minus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101209'] ))==null?'':__t)+
'"><i class="fa fa-search-minus"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="pictureSingleListItemProperties_btn_crop_move_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101210'] ))==null?'':__t)+
'"><i class="fa fa-arrow-left"></i></button> <button type="button" id="pictureSingleListItemProperties_btn_crop_move_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101211'] ))==null?'':__t)+
'"><i class="fa fa-arrow-right"></i></button> <button type="button" id="pictureSingleListItemProperties_btn_crop_move_top" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101212'] ))==null?'':__t)+
'"><i class="fa fa-arrow-up"></i></button> <button type="button" id="pictureSingleListItemProperties_btn_crop_move_buttom" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101213'] ))==null?'':__t)+
'"><i class="fa fa-arrow-down"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="pictureSingleListItemProperties_btn_crop_rotate_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101214'] ))==null?'':__t)+
'"><i class="fa fa-undo-alt"></i></button> <button type="button" id="pictureSingleListItemProperties_btn_crop_rotate_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101215'] ))==null?'':__t)+
'"><i class="fa fa-redo-alt"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="pictureSingleListItemProperties_btn_crop_scale_x" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101216'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-h"></i></button> <button type="button" id="pictureSingleListItemProperties_btn_crop_scale_y" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X101217'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-v"></i></button> </div> <button type="button" class="btn btn-primary" id="pictureSingleListItemProperties_btnSubmit" style=""> '+
((__t=( resources['X101458'] ))==null?'':__t)+
' </button> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="homec-2" role="tabpanel" aria-labelledby="homec-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label for="pictureSingleListItemProperties_dropDown_Animation">'+
((__t=( resources['X101426'] ))==null?'':__t)+
'</label> <select id="pictureSingleListItemProperties_dropDown_Animation" class="form-control"></select> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12" style="display:none"> <div id="pictureSingleListItemProperties_group_Duration" class="form-group"> <label>'+
((__t=( resources['X101427'] ))==null?'':__t)+
'</label> <input class="form-control" id="pictureSingleListItemProperties_input_Duration" value="'+
((__t=( model.Duration ))==null?'':__t)+
'"> </div> </div> <p style="display:none">Seconds: <span id="pictureSingleListItemProperties_label_Duration"></span></p> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group control-group pictureSingleListItemProperties_input_Text"> <label for="iframe_input_Url">'+
((__t=( resources['X101418'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Text))==null?'':__t)+
'" id="pictureSingleListItemProperties_input_Text"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="pictureSingleListItemProperties_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="pictureSingleListItemProperties_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div></div>';
}
return __p;
};
