var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="custom_empty"> <div class="content-wrapper" data-help="custom"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200162'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customInformation" class="e4usInformation" style="display:none"></div> <div class="accordion accordion-bordered" id="accordion-6" role="tablist"> <div class="card"> <div class="card-header" role="tab" id="heading-16"> <h6 class="mb-0"> <a data-toggle="collapse" href="#collapse-16" aria-expanded="true" aria-controls="collapse-16"> '+
((__t=( resources['X200310'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-16" class="collapse show" role="tabpanel" aria-labelledby="heading-16" data-parent="#accordion-6"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12">  <form class="forms-sample"> <div class="form-group"> <div class="row"> <div class="col-md-6" style="display:none"> <div class="control-group customColor_inputCustom_Text_Default"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputCustom_Text_Default">'+
((__t=( resources['X200310'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Custom_Text_Default))==null?'':__t)+
'" id="customColor_inputCustom_Text_Default"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_Custom_Color_Default">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_Custom_Color_Default" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </form> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-17"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-17" aria-expanded="false" aria-controls="collapse-17"> '+
((__t=( resources['X200302'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-17" class="collapse" role="tabpanel" aria-labelledby="heading-17" data-parent="#accordion-6"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12">  <form class="forms-sample"> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Off"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Off">'+
((__t=( resources['X200163'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Off))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Off"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Off">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Off" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Busy"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Busy">'+
((__t=( resources['X200164'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Busy))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Busy"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Busy">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Busy" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Available"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Available">'+
((__t=( resources['X200165'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Available))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Available"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Available">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Available" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Blue"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Blue">'+
((__t=( resources['X200166'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Blue))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Blue"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Blue">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Blue" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Yellow"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Yellow">'+
((__t=( resources['X200167'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Yellow))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Yellow"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Yellow">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Yellow" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Cyan"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Cyan">'+
((__t=( resources['X200168'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Cyan))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Cyan"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Cyan">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Cyan" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputSolidColor_Text_Magenta"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputSolidColor_Text_Magenta">'+
((__t=( resources['X200169'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.SolidColor_Text_Magenta))==null?'':__t)+
'" id="customColor_inputSolidColor_Text_Magenta"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_SolidColor_Color_Magenta">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_SolidColor_Color_Magenta" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </form> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-18"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-18" aria-expanded="false" aria-controls="collapse-18"> '+
((__t=( resources['X200303'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-18" class="collapse" role="tabpanel" aria-labelledby="heading-18" data-parent="#accordion-6"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12">  <form class="forms-sample"> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMSTeams_Text_Online"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMSTeams_Text_Online">'+
((__t=( resources['X200304'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.MSTeams_Text_Online))==null?'':__t)+
'" id="customColor_inputMSTeams_Text_Online"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_MSTeams_Color_Online">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_MSTeams_Color_Online" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMSTeams_Text_Busy"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMSTeams_Text_Busy">'+
((__t=( resources['X200305'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.MSTeams_Text_Busy))==null?'':__t)+
'" id="customColor_inputMSTeams_Text_Busy"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_MSTeams_Color_Busy">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_MSTeams_Color_Busy" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMSTeams_Text_DoNotDisturb"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMSTeams_Text_DoNotDisturb">'+
((__t=( resources['X200306'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.MSTeams_Text_DoNotDisturb))==null?'':__t)+
'" id="customColor_inputMSTeams_Text_DoNotDisturb"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_MSTeams_Color_DoNotDisturb">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_MSTeams_Color_DoNotDisturb" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMSTeams_Text_BeRightBack"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMSTeams_Text_BeRightBack">'+
((__t=( resources['X200307'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.MSTeams_Text_BeRightBack))==null?'':__t)+
'" id="customColor_inputMSTeams_Text_BeRightBack"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_MSTeams_Color_BeRightBack">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_MSTeams_Color_BeRightBack" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMSTeams_Text_ShowAsNotPresent"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMSTeams_Text_ShowAsNotPresent">'+
((__t=( resources['X200308'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.MSTeams_Text_ShowAsNotPresent))==null?'':__t)+
'" id="customColor_inputMSTeams_Text_ShowAsNotPresent"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_MSTeams_Color_ShowAsNotPresent">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_MSTeams_Color_ShowAsNotPresent" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMSTeams_Text_ShowAsOffline"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMSTeams_Text_ShowAsOffline">'+
((__t=( resources['X200309'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.MSTeams_Text_ShowAsOffline))==null?'':__t)+
'" id="customColor_inputMSTeams_Text_ShowAsOffline"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_MSTeams_Color_ShowAsOffline">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_MSTeams_Color_ShowAsOffline" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </form> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-19"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-19" aria-expanded="false" aria-controls="collapse-19"> '+
((__t=( resources['X200312'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-19" class="collapse" role="tabpanel" aria-labelledby="heading-19" data-parent="#accordion-6"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12">  <form class="forms-sample"> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMutebutton_Text_On"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMutebutton_Text_On">'+
((__t=( resources['X200313'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Mutebutton_Text_On))==null?'':__t)+
'" id="customColor_inputMutebutton_Text_On"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_Mutebutton_Color_On">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_Mutebutton_Color_On" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputMutebutton_Text_Off"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputMutebutton_Text_Off">'+
((__t=( resources['X200314'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Mutebutton_Text_Off))==null?'':__t)+
'" id="customColor_inputMutebutton_Text_Off"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_Mutebutton_Color_Off">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_Mutebutton_Color_Off" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </form> </div> </div> </div> </div> </div> <div class="card"> <div class="card-header" role="tab" id="heading-20"> <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapse-20" aria-expanded="false" aria-controls="collapse-20"> '+
((__t=( resources['X200286'] ))==null?'':__t)+
' </a> </h6> </div> <div id="collapse-20" class="collapse" role="tabpanel" aria-labelledby="heading-20" data-parent="#accordion-6"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12">  <form class="forms-sample"> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputPomodoro_Text_Time"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputPomodoro_Text_Time">'+
((__t=( resources['X200287'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Pomodoro_Text_Time))==null?'':__t)+
'" id="customColor_inputPomodoro_Text_Time"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_Pomodoro_Color_Time">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_Pomodoro_Color_Time" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputPomodoro_Text_ShortBreak"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputPomodoro_Text_ShortBreak">'+
((__t=( resources['X200288'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Pomodoro_Text_ShortBreak))==null?'':__t)+
'" id="customColor_inputPomodoro_Text_ShortBreak"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_Pomodoro_Color_ShortBreak">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_Pomodoro_Color_ShortBreak" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> <div class="form-group"> <div class="row"> <div class="col-md-6"> <div class="control-group customColor_inputPomodoro_Text_LongBreak"> <label>'+
((__t=( resources['X200178'] ))==null?'':__t)+
'</label> <label for="customColor_inputPomodoro_Text_LongBreak">'+
((__t=( resources['X200289'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Pomodoro_Text_LongBreak))==null?'':__t)+
'" id="customColor_inputPomodoro_Text_LongBreak"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-md-6"> <label for="customColor_ColorPickerSliders_Pomodoro_Color_LongBreak">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="customColor_ColorPickerSliders_Pomodoro_Color_LongBreak" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> </div> </form> </div> </div> </div> </div> </div> </div> <br> <button type="button" class="btn btn-primary btn-sm" id="customColor_btnSubmit">'+
((__t=( resources['X200059'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
