﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpageEditItemListItemSmall from './tpl/customWebpageEditItemListItemSmall.html';
import language from '../../../common/language';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import * as Entities_Empty from '../../../lib/entities/empty';



export default Marionette.ItemView.extend({
    tagName: 'li',
    attributes : function () {
        // Return model data
        return {
            class : this.getClassLi(),
            style : this.getStyleLi(),
            'data-id': this.getData_idLi(),
            id : 'customWebpageEditItemListItemSmall_li'
            
        }
            
    },
    getTemplate: function () {
        return tpl_customWebpageEditItemListItemSmall({ model: this.model.toJSON() });
    },
    /*serialize: function(){
        return {model: this.model};
    },*/
    initialize: function () {
        this.app = this.options.app;

        this.listenTo(this.model, 'change', this.render);
    },
    ui: {
        'customWebpageEditItemListItemSmall_li': '#customWebpageEditItemListItemSmall_li',
        'customWebpageEditItemListItemSmall_a': '#customWebpageEditItemListItemSmall_a'
    },
    events: {
        'click @ui.customWebpageEditItemListItemSmall_a': 'btnEditClick'
    },
    onRender: function () {
        this.ui.customWebpageEditItemListItemSmall_a.attr('class', '');
        this.ui.customWebpageEditItemListItemSmall_a.addClass(this.getClassA());//this.model.attributes.ClassnameA);

        this.ui.customWebpageEditItemListItemSmall_a.parent().attr('class', '');
        this.ui.customWebpageEditItemListItemSmall_a.parent().addClass(this.getClassLi());
    },
    customViewEvent_setActive: function () {
        var array = [];
        var customEvent = null;

        switch(this.model.attributes.DisplayType) {
            case 0 : customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Webpage: this.model.attributes.id }); break;
            case 1 : customEvent = this.app.entities.collections.customEvent.fullCollection.where({ Infopage: this.model.attributes.id }); break;
        }

        if (customEvent && customEvent.length > 0) {
            customEvent.forEach(function (entry) {
                var tmp = { "id": entry.attributes.id };
                array.push(tmp);
            });
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    getClassLi : function () {
        var classnameLi  = '';
        if (this.model.attributes.Tag != 3 && this.model.attributes.Tag != 6) {
            

            switch (this.model.attributes.Tag) {
                case 0 : classnameLi = 'clearfix ui-state-disabled li_Header li_DummyHeader '; break;
                case 2 : classnameLi = 'clearfix ui-state-disabled li_Footer li_DummyFooter '; break;
                case 1 : classnameLi = 'clearfix li_Body li_DummyBody ui-state-default ui-sortable-handle'; break;
                case 3 : classnameLi = 'clearfix ui-state-disabled li_SchemaBigscreen li_DummySchemaBigscreen '; break;
                case 4 : classnameLi = 'clearfix ui-state-disabled li_Poster li_DummyPoster ';break;
                case 7 : classnameLi = 'clearfix ui-state-disabled li_Form li_DummyForm '; break;
                case 8 : classnameLi = 'clearfix ui-state-disabled li_ProductSheet li_DummyProductSheet '; break;
                case 9 : classnameLi = 'clearfix li_Info li_DummyInfo ui-state-default ui-sortable-handle'; break;
            }

            if (this.model.attributes.Selected == true) {
                classnameLi += 'ui-state-selected li_Selected';

                classnameLi = classnameLi.replace('li_Header','');
                classnameLi = classnameLi.replace('li_Footer','');
                classnameLi = classnameLi.replace('li_Body','');
                classnameLi = classnameLi.replace('li_SchemaBigscreen','');
                classnameLi = classnameLi.replace('li_Poster','');
                classnameLi = classnameLi.replace('li_Form','');
                classnameLi = classnameLi.replace('li_ProductSheet','');
                classnameLi = classnameLi.replace('li_Info','');

            }
            //return classnameLi;
            return '';
        }
    },
    getStyleLi : function () {
        var stylenameLi  = '';
        switch (this.model.attributes.Tag) {
            case 0 : 
            case 2 : 
            case 1 : 
            case 3 : 
            case 4 : break;
            case 5 : stylenameLi += 'display:none;'; break;
            case 6 : stylenameLi += 'display:none;'; break;
            case 7 : 
            case 8 : 
            case 9 : break;
        }
        return stylenameLi;
    },
    getData_idLi :function () {
        return this.model.id;
    },
    getAllClassA : function () {
        var classnameA = 'ancorli li_aSelected';
        //return classnameA;
        return '';
    },
    getClassA : function () {
        var classnameA  = 'ancorli';
        if (this.model.attributes.Selected) {
            classnameA = 'ancorli li_aSelected';
        }

        //return classnameA;
        return '';
    },
    setupClass: function () {
        var classnameLi  = '';
        var classnameA = '';

        classnameLi = this.getClassLi();
        classnameA = this.classnameA();

        this.model.attributes.ClassnameLi = classnameLi;
        this.model.attributes.ClassnameA = classnameA;

        /*if (this.model.attributes.Tag != 3 && this.model.attributes.Tag != 6) {
            var classnameLi  = '';
            var classnameA = 'ancorli ';

            switch (this.model.attributes.Tag) {
                case 0 : classnameLi = 'ui-state-disabled li_Header li_DummyHeader '; break;
                case 2 : classnameLi = 'ui-state-disabled li_Footer li_DummyFooter '; break;
                case 1 : classnameLi = 'li_Body li_DummyBody '; break;
                case 3 : classnameLi = 'ui-state-disabled li_SchemaBigscreen li_DummySchemaBigscreen '; break;
                case 4 : classnameLi = 'ui-state-disabled li_Poster li_DummyPoster ';break;
                case 7 : classnameLi = 'ui-state-disabled li_Form li_DummyForm '; break;
                case 8 : classnameLi = 'ui-state-disabled li_ProductSheet li_DummyProductSheet '; break;
                case 9 : classnameLi = 'li_Info li_DummyInfo '; break;
            }

            if (this.model.attributes.Selected == true) {
                classnameLi += 'ui-state-selected li_Selected';
                classnameA += 'li_aSelected';
            }
            this.model.attributes.ClassnameLi = classnameLi;
            this.model.attributes.ClassnameA = classnameA;
        }*/
    },
    btnEditClick: function (event) {
        event.preventDefault();

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemListItem:saveSelectedModel');

        userChannel.trigger('customWebpageEditItemListItem:disabledWebpageContent');
        
        this.model.collection.forEach(function (entry) {
            entry.set({ Selected: false});
        });

        this.model.set({ Selected: true });
        //this.model.collection.trigger('change');

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customWebpageEditItemListItem:setSelectedModel');

        
    },
    getexp : function(Css, key1, key2, defaultValue) {
        var ret = {};

        var tmpCss = Css;
        var subStrA = tmpCss.match(key1);
        var subStrB = tmpCss.match(key2);
        var value = tmpCss.substring(subStrA.index + key1.length, subStrB.index);
        if (value.length > 0) {
            tmpCss = tmpCss.replace(tmpCss.substring(subStrA.index, subStrB.index+key2.length), "");
            ret = { Css: tmpCss, value : value };
        } else {
            ret = { Css: Css, value : defaultValue };
        }

        return ret;
    },
    saveSelectedModel: function () {
    
    },
    selectModelCollection: function () {
        var self = this;
        var nextPosition = 2147483647;
        var valueNextPosition = -1;
        this.collection.forEach(function (entry) {
            if (entry.attributes.Webpage == self.model.attributes.id
                && entry.attributes.Position <= nextPosition) {
                nextPosition = entry.attributes.Position;
                valueNextPosition = entry.attributes.id;
            }
        });
    }
}, Backbone.Radio.Requests);
