﻿// Init misc overriden/extended functionality

import es6_promise from 'es6-promise';
es6_promise.polyfill();                         // Require polyfill for es6 promises

import $ from 'jquery';                         // Twitter Bootstrap needs jQuery at global object. Don't use that!
//import moment from 'moment';
//require('moment');

window['jQuery'] = window['$'] = $;

require('jquery-ui');
require('nestedSortable2.1a');
require('jquery-toast-plugin');
require('chart.js');

require('bootstrap-select');
require('bootstrap-duration-picker');

require('fancyBox');

require('owl-carousel2');
require('jquery-steps');
require('jquery.validate');


//import tempusdominusbootstrap4 from 'tempusdominus-bootstrap-4';

import Popper from 'popper.js';

//import qrcode from '../plugins/qrcode';



window.require('bootstrap');                    // Attaches itself as a number of jQuery pluginsvar _ = require('underscore');
//require('popper.js');





//require('tempusdominus-bootstrap-4');

require('bootstrap-timepicker');


import * as _ from 'underscore';
window['_'] = _;                                // Compiled underscore templates need this for calling _.escape(...)

//import * as gsap from 'gsap';

//const activated = [
//    gsap
//];

//var gsap = require('gsap');
//import * as gsap from 'gsap';
//gsap.registerPlugin(MotionPathPlugin, ScrollToPlugin, TextPlugin);
//window.require('gsap');
//import "gsap";
//import TweenMax from "gsap";
//import { TweenLite, TimelineMax, Linear, Back, Sine } from 'gsap';


//import * as Backbone from 'backbone';
//import * as Backbone_localStorage from 'backbone.localStorage';
//import * as Backbone_Syphon from 'backbone.syphon'; // Attaches itself to Backbone

//window.Backbone = Backbone;
//window['Backbone'] = require('backbone');


var Backbone = require('backbone');
require('backbone.localStorage');
require('backbone.syphon');                     // Attaches itself to Backbone
import * as Backbone_Picky from 'backbone.picky';

// Globally change Backbone.sync to Set Authorization header if we have a token
var originalSync = Backbone.sync;

//$.ajaxSetup({
//    beforeSend: function (jqXHR, settings) {
//        if (window['mgr'] !== undefined && window['mgr'].access_token !== undefined) {
//            jqXHR.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token));
//        }
//    }
//});

Backbone.sync = function (method, model, options) {
    //options || (options = {});

    //var deferred = $.Deferred();
    //deferred.then(options.success, options.error);

    // Authorization if we have a token

    if (options.skipAuthorization == undefined) {
        options.skipAuthorization = false;
    }

    if (options.skipAuthorization == false) {
        options.beforeSend = function (xhr) {
            //if (window['mgr'] !== undefined && window['mgr'].access_token !== undefined) {
            if (window['user'] !== undefined && window['user'].access_token !== undefined) {
                xhr.setRequestHeader('Authorization', "Bearer ".concat(window['user'].access_token));
            }
    
        };
    }

    //var response = originalSync(method, model, _.omit(options, 'success', 'error'));
    var response = originalSync(method, model, options);

    return response;

    // Success callback just call the resolve-method on our promise
    //response.done(deferred.resolve);

    //response.fail(function () {
    //    // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
    //    if (response.status === 401) {
    //        //alert("This action isn't authorized!");
    //        Radio.trigger('navigation', 'login:show');
    //    }
    //    else if (response.status === 403) {
    //        alert(response.responseJSON.message);
    //        Radio.trigger('navigation', 'accessdenied:show');
    //    }
    //    else {
    //        deferred.rejectWith(response, arguments);
    //    }
    //});

    //return deferred.promise();
};


import * as Marionette from 'backbone.marionette';
import * as Radio from 'backbone.radio';        // Attaches itself to Backbone and returns Radio at the same time for use in shim below

var Cropper = require('cropperjs');        // Attaches itself to Backbone and returns Radio at the same time for use in shim below

window.Cropper = Cropper;



// Shim that make Marionette 2.x use Backbone.Radio instead of Backbone.Wreqr (though Mariontte will import Wreqr)
(Marionette.Application).prototype._initChannel = function () {
    this.channelName = _.result(this, 'channelName') || 'global';
    this.channel = _.result(this, 'channel') || Radio.channel(this.channelName);
}

// start the marionette inspector (only works in Chrome)
//if (window.__agent) {
//    window.__agent.start(Backbone, Marionette);
//}



//require('marionette-autocomplete');             // Attaches itself to marionette

//import * as marionette_autocomplete from 'marionette-autocomplete';




require('../../common/globalfunction');

require("crypto-js");

//Global function
//import template from './js/template';
//template.render

//require('../plugins/flatWeatherPlugin');
require('../plugins/jqplot');
require('../plugins/datatables');
require('../plugins/template');
require('../plugins/hoverable-collapse');
require('../plugins/toastDemo');
require('../plugins/tooltips');
require('dragula');
require('../plugins/w3fclocktimer');
//require('../plugins/wizard');

//require('dragula');

//import dragula from 'dragula';


require('../../plugin/oidc-client-ts');




$.jqplot.config.enablePlugins = true;

//$.ajaxSetup({
//    statusCode: {
//        401: function(){
//            // Redirec the to the login page.
//            window.location.replace('/#login');
         
//        },
//        403: function() {
//            // 403 -- Access denied
//            window.location.replace('/#denied');
//        }
//    }
//});

Backbone.ajax = function() {
    // Invoke $.ajaxSetup in the context of Backbone.$
    Backbone.$.ajaxSetup.call(Backbone.$, {
        statusCode: {
            401: function(){
                // Redirect the to the login page.
                window.app.router.navigate("#accessTokenLifetime");
                window.app.router.accessTokenLifetime();
            },
            403: function() {
                // 403 -- Access denied
                window.app.router.navigate("#accessTokenLifetime");
                window.app.router.accessTokenLifetime();
            }
        }
    });
    return Backbone.$.ajax.apply(Backbone.$, arguments);
}; 

