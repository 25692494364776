var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFormData_empty"> <div id="designRegion_body"></div> <div id="designRegion_btnOk"></div> <div class="content-wrapper" data-help="customFormData"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X100260'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div id="customHeader"></div> <div class="row mt-3" style="margin-top: 50px !important"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <div id="customFormDataInformation"></div> <h4 class="card-title">'+
((__t=( resources['X101055'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customFormData_inputNameGroup" class="control-group customFormData_selectEvent"> <div class="form-group"> <label for="customFormData_selectEvent">'+
((__t=( resources['X100269'] ))==null?'':__t)+
'</label> <div class="options-box"> <select class="form-control" id="customFormData_selectEvent"></select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X100390'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customFormDataInsert"></div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X100261'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div id="customFormDataList"></div> <div style="height:5px"></div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customFormData_btnBack">'+
((__t=( resources['X101239'] ))==null?'':__t)+
'</button> <div style="height:5px"></div> <div class="row"> <div class="control-label col-md-12"> <button type="button" class="btn btn-secondary btn-sm col-sm-3 btnFormData" id="customFormData_btnDeleteAllSubmit">'+
((__t=( resources['X100376'] ))==null?'':__t)+
'</button> </div> </div> </form> </div> </div> </div> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X101049'] ))==null?'':__t)+
'</h4> <form class="forms-sample"> <div class="row"> <div class="control-label col-md-12"> <a class="btn btn-secondary btn-sm col-sm-3 btnFormData" id="customFormData_btnExport">'+
((__t=( resources['X100284'] ))==null?'':__t)+
'</a> </div> </div> </form> </div> </div> </div> <div id="customFormForm"></div>  </div> </div> </div>';
}
return __p;
};
