var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="htmlSlide_empty"> <div class="modal fade" id="htmlSingle_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101433'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div class="card"> <div class="card-body"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home-tab-1" data-toggle="tab" href="#home-1" role="tab" aria-controls="home-1" aria-selected="true">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home-2" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home-1" role="tabpanel" aria-labelledby="home-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label for="htmlSingle_dropDown_AnimationGroup">'+
((__t=( resources['X101428'] ))==null?'':__t)+
'</label> <select id="htmlSingle_dropDown_AnimationGroup" class="form-control"></select> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div class="form-group"> <label for="htmlSingle_dropDown_Animation">'+
((__t=( resources['X101426'] ))==null?'':__t)+
'</label> <select id="htmlSingle_dropDown_Animation" class="form-control"></select> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12"> <div id="htmlSingle_group_Duration" class="form-group"> <label>'+
((__t=( resources['X101427'] ))==null?'':__t)+
'</label> <input class="form-control" id="htmlSingle_input_Duration" value="'+
((__t=( model.Duration ))==null?'':__t)+
'"> </div> </div> <p>Seconds: <span id="htmlSingle_label_Duration"></span></p> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-2" role="tabpanel" aria-labelledby="home-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="dialog2Button_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="dialog2Button_btn02" class="btn btn-light">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
