'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import View_DialogWaiting from '../../../../lib/views/dialogWaiting/dialogWaiting';
import View_Dialog2Button2 from '../../../../lib/views/dialog2Button2/dialog2Button2';
import View_DialogBox2 from '../../../../lib/views/dialogBox2/dialogBox2';
import * as Entities_Empty from '../../../../lib/entities/empty';

import View_AdSlideListItemProperties from './adSlideListItemProperties';

import tpl_adSlideListItem from './tpl/adSlideListItem.html';

export default Marionette.ItemView.extend({
    className: 'myDiv',
    tagName: 'div',
    attributes: function () {
        return {
            id: "adSlideListItem_tr",// + this.model.attributes.id,
            style: "border-width: 3px; border-color: black;border-style: none; max-width:150px;display:inline-block;",
            'data-id': this.model.attributes.id,
            'data-position': this.model.attributes.Position
        };
    },
    getTemplate: function () {
        return tpl_adSlideListItem({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
        this.listenTo(this.model, 'change', this.render);
        //this.model.bind('destroy', this.remove, this);
    },
    ui: {
        'adSlideListItem_tr': '#adSlideListItem_tr',
        'adSlideListItem_div': '#adSlideListItem_div',
        'adSlideListItem_div_inner': '#adSlideListItem_div_inner',
        'adSlideListItem_iHtml': '#adSlideListItem_iHtml',
        'adSlideListItem_iDelete': '#adSlideListItem_iDelete'
    },
    events: {
        'click @ui.adSlideListItem_div_inner': 'adSlideListItem_div_inner_Click',
        'click @ui.adSlideListItem_iHtml': 'iHtmlClick',
        'click @ui.adSlideListItem_iDelete': 'iDeleteClick'
    },
    onAttach: function () {
    },
    onRender: function () {
        if (this.model.attributes.Selected) {
            this.ui.adSlideListItem_div_inner.css('border-style', 'solid');
            this.ui.adSlideListItem_div_inner.css('border-color', 'black');
        }
        else {
            this.ui.adSlideListItem_div_inner.css('border-style', 'solid');
            this.ui.adSlideListItem_div_inner.css('border-color', 'white');
        }
    },
    adSlideListItem_div_inner_Click: function (event) {
        var self = this;
        this.model.collection.each(function (tmpModel) {
            if ((tmpModel.attributes.Selected == true) && (tmpModel.attributes.id != self.model.attributes.id)) {
                tmpModel.set({ Selected: false });
            }
        });

        this.model.set({ Selected: true });
        this.model.collection.trigger('change');
    },
    iHtmlClick: function (event) {
        var self = this;

        var properties = new View_AdSlideListItemProperties({ app: this.app, model: this.model, parent: self });

        var callback01 = function () {
            self.stopListening(properties, "dialog:btn01clicked", callback01);
            self.stopListening(properties, "dialog:btn02clicked", callback02);
            self.model.trigger('change');
        }
        this.listenTo(properties, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            self.stopListening(properties, "dialog:btn01clicked", callback01);
            self.stopListening(properties, "dialog:btn02clicked", callback02);

        }
        this.listenTo(properties, "dialog:btn02clicked", callback02);

        self.app.dialogRegion2.show(properties);
    },
    iDeleteClick: function (event) {
        if (this.model.collection.length <= 1) {
            this.message(resources.X101459);
        }
        else {
            var self = this;

            event.preventDefault();

            var dialog2ButtonModel = new Entities_Empty.Model();
            dialog2ButtonModel.attributes.Title = resources.X101341;
            dialog2ButtonModel.attributes.Text = resources.X101365;
            dialog2ButtonModel.attributes.Button01 = resources.X100281;
            dialog2ButtonModel.attributes.Button02 = resources.X100282;
            var dialog2Button = new View_Dialog2Button2({ model: dialog2ButtonModel, parent: this });

            var callback01 = function (options) {
                this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

                this.model.collection.remove(this.model);
                var customInfoImageModel = this.app.entities.collections.customInfoImage.where({ id: this.model.attributes.id })[0];
                customInfoImageModel.destroy();

                var userChannel = Backbone.Radio.channel('app');
                userChannel.trigger('adSlideList:reOrder');
            }
            this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

            var callback02 = function (options) {
                this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
                this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
            }
            this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

            this.app.dialogRegion2.show(dialog2Button);
        }
    },
    message: function (text) {
        var self = this;

        var dialogBoxModel = new Entities_Empty.Model();
        dialogBoxModel.attributes.Title = resources.X100229;
        dialogBoxModel.attributes.Text = text;
        dialogBoxModel.attributes.Button = resources.X100279;

        var dialogBox = new View_DialogBox2({ model: dialogBoxModel, parent: this });
        var callback = function (options) {
        }
        this.listenTo(dialogBox, "dialog:okclicked", callback);
        this.app.dialogRegion2.show(dialogBox);
    }
});