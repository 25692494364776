﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogHelp from './tpl/dialogHelp.html';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_DialogHelpList from './dialogHelpList';

export default Marionette.LayoutView.extend({
    dialogRegion: '#dialogHelp_modal',
    getTemplate: function () {
        return tpl_dialogHelp({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'help_btnSearch': '#help_btnSearch',
        'help_btnReset': '#help_btnReset',
        'help_input' : '#help_input'
    },
    events: {
        "click #dialog2Button_btn01": "onBtn01",
        'click @ui.help_btnSearch': 'btnSearchClick',
        'click @ui.help_btnReset': 'btnResetClick'
    },
    regions: {
        dialogHelpList: "#dialogHelpList",
        dialogHelpPagination: "#dialogHelpPagination",
    },
    onBeforeShow: function () {
        this.dialogHelpList.show(new View_DialogHelpList({ collection: this.collection }));
        this.dialogHelpPagination.show(new View_Pagination({ collection: this.collection }));
    },
    onBtn01: function () {
        this.triggerMethod('dialog:btn01clicked', "msg");
        this.trigger("dialog:close");
    },
    btnResetClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        this.ui.help_input.val('');
    },
    btnSearchClick: function (event) {
        var collection = this.app.entities.collections.help.search(this.ui.help_input.val());
        
        this.app.entities.collections.helpPagination.fullCollection.reset(collection, { silent : true });
        this.app.entities.collections.helpPagination.state.totalRecords = this.app.entities.collections.helpPagination.fullCollection.length;
        this.app.entities.collections.helpPagination.setPageSize(this.app.entities.collections.helpPagination.state.pageSize, {first : true});

        this.collection.trigger('change');
        this.collection.trigger('all');
    }
});
