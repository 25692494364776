﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialog2Process from './tpl/dialog2Process.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#dialog2Process_modal',
    getTemplate: function () {
        return tpl_dialog2Process({ model: this.model.toJSON() });
    },
    ui: {
        'dialog2Process_h4_title' : '#dialog2Process_h4_title',
        'dialog2Process_progress_1': '#dialog2Process_progress_1',
        'dialog2Process_h2_text_1': '#dialog2Process_h2_text_1',
        'dialog2Process_progress_2': '#dialog2Process_progress_2',
        'dialog2Process_h2_text_2': '#dialog2Process_h2_text_2'
    },
    events: {
        "click #dialog2Process_btnOk": "onOK"
    },
    onOK: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    },
    changeTitle: function (title) {
        this.model.attributes.Title = title;
        try {
            this.ui.dialog2Process_h4_title.text(this.model.attributes.Title);
        } catch(err) {
        }
    },
    changeText_1: function (text) {
        this.model.attributes.Text_1 = text;
        try {
            this.ui.dialog2Process_h2_text_1.text(this.model.attributes.Text_1);
        } catch(err) {
        }
    },
    changeText_2: function (text) {
        this.model.attributes.Text_2 = text;
        try {
            this.ui.dialog2Process_h2_text_2.text(this.model.attributes.Text_2);
        } catch(err) {
        }
    },
    wait: function (ms) {
        var d = new Date();
        var d2 = null;
        do { d2 = new Date(); }
        while (d2 - d < ms);
    },
    changeProgress_1: function (counter, max) {
        var value = parseInt((100 / max) * counter);
        if (this.ui.dialog2Process_progress_1) {
            $(this.ui.dialog2Process_progress_1).css("width", value + "%");
        }
    },
    changeProgress_2: function (counter, max) {
        var value = parseInt((100 / max) * counter);
        if (this.ui.dialog2Process_progress_2) {
            $(this.ui.dialog2Process_progress_2).css("width", value + "%");
        }
    },
    onRender: function () {
    }
});
