﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

//import tpl_template001 from './tpl/001.html';
//import tpl_template002 from './tpl/002.html';

import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_Editor from '../module/editor';
import View_Empty from '../module/empty';
import View_HtmlSlide from '../module/htmlSlide';
import View_HtmlSingle from '../module/htmlSingle';
import View_PictureSlide from '../module/pictureSlide';
import View_PictureSingle from '../module/pictureSingle';
import View_Rss from '../module/Rss';
import View_RssCustom from '../module/RssCustom';
import View_DigitalClock from '../module/digitalClock';
import View_AnalogClock from '../module/analogClock';
import View_IFrame from '../module/iframe';
import View_YouTube from '../module/youTube';
import View_Weather from '../module/weather';
import View_OfficeWord from '../module/OfficeWord';
import View_OfficeExcel from '../module/OfficeExcel';
import View_OfficePowerPoint from '../module/OfficePowerPoint';
import View_Pdf from '../module/Pdf';
import View_Video from '../module/Video';
import View_SchemaScreen from '../module/SchemaScreen';
import View_AdSlide from '../module/adSlide';
import View_EventApp from '../module/eventApp';
import View_QRCode from '../module/qrcode';

import View_Module from '../module/module';

import helper_infopage from '../../helper/infopage';

import Help from '../../helper/help';

export default View_LayoutError.extend({
    attributes: function () {
        return {
            class: '',
            id: 'templateDiv'
        }
    },
    getTemplate: function () {
        var tmpModel = this.app.entities.collections.Template.where({ id: this.model.attributes.Template })[0];

        return window.atob(tmpModel.attributes.TemplateBase64);

        /*switch (this.model.attributes.Template) {
            case '001.html': return tpl_template001({}); break;
            case '002.html': return tpl_template002({}); break;
        }*/
    },
    regions: {
        moduleSetting001: "#moduleSetting001",
        moduleSetting002: "#moduleSetting002",
        moduleSetting003: "#moduleSetting003",
        moduleSetting004: "#moduleSetting004",

        module001: "#module001",
        module002: "#module002",
        module003: "#module003",
        module004: "#module004",
        module005: "#module005",
        module006: "#module006",
        module007: "#module007",
        module008: "#module008",
        module009: "#module009",
        module010: "#module010",
        module011: "#module011",
        module012: "#module012",
        module013: "#module013",
        module014: "#module014",
        module015: "#module015",
        module016: "#module016",
        module017: "#module017",
        module018: "#module018",
        module019: "#module019",
        module020: "#module020"
    },
    onBeforeShow: function () {
        /*var module001Model = this.collection.where({ Active: true, ModuleDiv: 'module001' })[0];
        var module002Model = this.collection.where({ Active: true, ModuleDiv: 'module002' })[0];
        var module003Model = this.collection.where({ Active: true, ModuleDiv: 'module003' })[0];
        var module004Model = this.collection.where({ Active: true, ModuleDiv: 'module004' })[0];
        var module005Model = this.collection.where({ Active: true, ModuleDiv: 'module005' })[0];
        var module006Model = this.collection.where({ Active: true, ModuleDiv: 'module006' })[0];
        var module007Model = this.collection.where({ Active: true, ModuleDiv: 'module007' })[0];
        var module008Model = this.collection.where({ Active: true, ModuleDiv: 'module008' })[0];
        var module009Model = this.collection.where({ Active: true, ModuleDiv: 'module009' })[0];
        var module010Model = this.collection.where({ Active: true, ModuleDiv: 'module010' })[0];
        var module011Model = this.collection.where({ Active: true, ModuleDiv: 'module011' })[0];
        var module012Model = this.collection.where({ Active: true, ModuleDiv: 'module012' })[0];
        var module013Model = this.collection.where({ Active: true, ModuleDiv: 'module013' })[0];
        var module014Model = this.collection.where({ Active: true, ModuleDiv: 'module014' })[0];
        var module015Model = this.collection.where({ Active: true, ModuleDiv: 'module015' })[0];
        var module016Model = this.collection.where({ Active: true, ModuleDiv: 'module016' })[0];
        var module017Model = this.collection.where({ Active: true, ModuleDiv: 'module017' })[0];
        var module018Model = this.collection.where({ Active: true, ModuleDiv: 'module018' })[0];
        var module019Model = this.collection.where({ Active: true, ModuleDiv: 'module019' })[0];
        var module020Model = this.collection.where({ Active: true, ModuleDiv: 'module020' })[0];*/

        /*
        var module001Model = this.collection.where({ Active: true, ModuleDiv: 'module001' })[0];
        var module002Model = this.collection.where({ Active: true, ModuleDiv: 'module002' })[0];
        var module003Model = this.collection.where({ Active: true, ModuleDiv: 'module003' })[0];
        var module004Model = this.collection.where({ Active: true, ModuleDiv: 'module004' })[0];

        this.module001.show(this.initModule(module001Model));
        this.module002.show(this.initModule(module002Model));
        this.module003.show(this.initModule(module003Model));
        this.module004.show(this.initModule(module004Model));
        */


    },
    initModule(model) {
        var ret;

        switch (model.attributes.ModuleType) {
            case 0: ret = new View_Empty({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 1: ret = new View_HtmlSlide({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 2: ret = new View_PictureSlide({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 3: ret = new View_Rss({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 4: ret = new View_DigitalClock({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 5: ret = new View_AnalogClock({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 6: ret = new View_IFrame({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 7: ret = new View_YouTube({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 8: ret = new View_Weather({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 9: ret = new View_HtmlSingle({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 10: ret = new View_PictureSingle({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 11: ret = new View_RssCustom({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 12: ret = new View_OfficeWord({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 13: ret = new View_OfficeExcel({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 14: ret = new View_OfficePowerPoint({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 15: ret = new View_Pdf({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 16: ret = new View_Video({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 17: ret = new View_SchemaScreen({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 18: ret = new View_AdSlide({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 19: ret = new View_EventApp({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;
            case 20: ret = new View_QRCode({ app: this.app, model: model, templateModel: this.model, customEvent: this.options.customEvent });
                break;

        }

        return ret;
    },
    setupCssStyle: function () {
        var self = this;

        this.collection.forEach(function (entry) {
            switch (entry.attributes.ModuleType) {
                case 2: self.changeModel_PictureSlide(entry); break;
                case 3: self.changeModel_Rss(entry); break;
                case 4: self.changeModel_DigitalClock(entry); break;
                case 5: self.changeModel_AnalogClock(entry); break;
                case 8: self.changeModel_Weather(entry); break;
                case 10: self.changeModel_PictureSingle(entry); break;
                case 11: self.changeModel_RssCustom(entry); break;
                case 18: self.changeModel_AdSlide(entry); break;
                case 20: self.changeModel_QRCode(entry); break;
            }
        });

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('rightContent:setupCssStyle');
    },
    changeModel_PictureSlide: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
    },
    changeModel_PictureSingle: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
    },
    changeModel_AdSlide: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
    },
    changeModel_Rss: function (model) {
        model.attributes.Data.Font = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font_FontType, model.attributes.Data.Font_Select);
        model.attributes.Data.Font_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font_ColorType, model.attributes.Data.Font_ColorSelect);
    },
    changeModel_RssCustom: function (model) {
        model.attributes.Data.Font = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font_FontType, model.attributes.Data.Font_Select);
        model.attributes.Data.Font_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font_ColorType, model.attributes.Data.Font_ColorSelect);
    },
    changeModel_DigitalClock: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
        model.attributes.Data.Font2 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font2_FontType, model.attributes.Data.Font2_Select);
        model.attributes.Data.Font2_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font2_ColorType, model.attributes.Data.Font2_ColorSelect);
    },
    changeModel_AnalogClock: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
        model.attributes.Data.Font2 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font2_FontType, model.attributes.Data.Font2_Select);
        model.attributes.Data.Font2_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font2_ColorType, model.attributes.Data.Font2_ColorSelect);
        model.attributes.Data.Font3 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font3_FontType, model.attributes.Data.Font3_Select);
        model.attributes.Data.Font3_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font3_ColorType, model.attributes.Data.Font3_ColorSelect);
    },
    changeModel_Weather: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
    },
    changeModel_QRCode: function (model) {
        model.attributes.Data.Font1 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font1_FontType, model.attributes.Data.Font1_Select);
        model.attributes.Data.Font1_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font1_ColorType, model.attributes.Data.Font1_ColorSelect);
        model.attributes.Data.Font2 = helper_infopage.getSelectedFont(this.app, model.attributes.Data.Font2_FontType, model.attributes.Data.Font2_Select);
        model.attributes.Data.Font2_Color = helper_infopage.getSelectedColor(this.app, model.attributes.Data.Font2_ColorType, model.attributes.Data.Font2_ColorSelect);
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        //this.render = _.wrap(this.render, function (render) {
        //    this.beforeRender();
        //    render();
        //    this.afterRender();
        //});

        this.live = false;
        if ($('#customInfopageEditItemLeftContent').hasClass('notused')) {
            this.live = true;
        }

        var userChannel = Backbone.Radio.channel('app');

        this.listenTo(userChannel, 'html:setupCssStyle', function () {
            self.setupCssStyle();
        });

        this.listenTo(userChannel, 'template:transform', function (value) {
            self.model.set({ Transform: value });
        });

        this.listenTo(userChannel, 'template:TransformOrigin', function (value) {
            self.model.set({ TransformOrigin: value });
        });

        this.listenTo(userChannel, 'template:setZoom', function () {
            var zoom = 0;
            if ($('#customInfopageEditItemLeftContent').hasClass('notused')) {
                if (window.innerHeight == screen.height) {
                    zoom = 1;
                    self.updateZoomLive(zoom);
                } else {
                    zoom = self.calcZoomLive();
                    self.updateZoomLive(Number(zoom) / 100);
                }
            }
            else {
                zoom = self.calcZoom();
                self.updateZoom(zoom);
            }
        });

        this.listenTo(userChannel, 'template:setZoomBySlider', function (zoom) {
            self.updateZoom(zoom);
        });

        //this.listenTo(userChannel, 'muduleChange', function () {
        //    self.moduleChange();
        //});

        this.listenTo(userChannel, 'InfoPageSetting:change', function () {
            self.setupBackground();
        });

        this.listenTo(this.collection, 'change', this.render, this);
        this.listenTo(this.collection, 'change', this.save, this);

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customInfopageEditItemTopbar');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmit(null, false, true, callback, false);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'templateBg': '#templateBg'
        //'templateDiv': '#templateDiv',
    },
    events: {
    },

    /*onRenderComplete: function () {

        // check every 200ms to see if this.el has been injected
        if (!$.contains(document.documentElement, this.el)) {
            var that = this;
            setTimeout(function () {
                that.afterRender();
            }, 200);
            return;
        } else {
            this.afterRender();
        }
        
    },
    beforeRender: function () {
    },*/
    onRender: function () {
        //this.onRenderComplete(); 
        this.afterRender();
    },
    onSave: function () {

    },
    afterRender: function () {



        //if ($('#templateDiv').hasClass('.zoomContainerH')) {
        if ($('#templateDiv').find('.zoomContainerH')[0]) {
            $('.zoomContainerH').css('transform', this.model.attributes.Transform);
            $('.zoomContainerH').css('transform-origin', this.model.attributes.TransformOrigin);
        }

        //if ($('#templateDiv').hasClass('.zoomContainerV')) {
        if ($('#templateDiv').find('.zoomContainerV')[0]) {
            $('.zoomContainerV').css('transform', this.model.attributes.Transform);
            $('.zoomContainerV').css('transform-origin', this.model.attributes.TransformOrigin);
        }



        var module001Model = this.collection.where({ Active: true, ModuleDiv: 'module001' })[0];
        var module002Model = this.collection.where({ Active: true, ModuleDiv: 'module002' })[0];
        var module003Model = this.collection.where({ Active: true, ModuleDiv: 'module003' })[0];
        var module004Model = this.collection.where({ Active: true, ModuleDiv: 'module004' })[0];
        var module005Model = this.collection.where({ Active: true, ModuleDiv: 'module005' })[0];
        var module006Model = this.collection.where({ Active: true, ModuleDiv: 'module006' })[0];
        var module007Model = this.collection.where({ Active: true, ModuleDiv: 'module007' })[0];
        var module008Model = this.collection.where({ Active: true, ModuleDiv: 'module008' })[0];
        var module009Model = this.collection.where({ Active: true, ModuleDiv: 'module009' })[0];
        var module010Model = this.collection.where({ Active: true, ModuleDiv: 'module010' })[0];
        var module011Model = this.collection.where({ Active: true, ModuleDiv: 'module011' })[0];
        var module012Model = this.collection.where({ Active: true, ModuleDiv: 'module012' })[0];
        var module013Model = this.collection.where({ Active: true, ModuleDiv: 'module013' })[0];
        var module014Model = this.collection.where({ Active: true, ModuleDiv: 'module014' })[0];
        var module015Model = this.collection.where({ Active: true, ModuleDiv: 'module015' })[0];
        var module016Model = this.collection.where({ Active: true, ModuleDiv: 'module016' })[0];
        var module017Model = this.collection.where({ Active: true, ModuleDiv: 'module017' })[0];
        var module018Model = this.collection.where({ Active: true, ModuleDiv: 'module018' })[0];
        var module019Model = this.collection.where({ Active: true, ModuleDiv: 'module019' })[0];
        var module020Model = this.collection.where({ Active: true, ModuleDiv: 'module020' })[0];

        if (this.module001 != undefined) {
            this.module001.destroy();
        }
        if (this.moduleSetting001 != undefined) {
            this.moduleSetting001.destroy();
        }
        if (this.module002 != undefined) {
            this.module002.destroy();
        }
        if (this.moduleSetting002 != undefined) {
            this.moduleSetting002.destroy();
        }
        if (this.module003 != undefined) {
            this.module003.destroy();
        }
        if (this.moduleSetting003 != undefined) {
            this.moduleSetting003.destroy();
        }
        if (this.module004 != undefined) {
            this.module004.destroy();
        }
        if (this.moduleSetting004 != undefined) {
            this.moduleSetting004.destroy();
        }
        if (this.module005 != undefined) {
            this.module005.destroy();
        }
        if (this.moduleSetting005 != undefined) {
            this.moduleSetting005.destroy();
        }
        if (this.module006 != undefined) {
            this.module006.destroy();
        }
        if (this.moduleSetting006 != undefined) {
            this.moduleSetting006.destroy();
        }
        if (this.module007 != undefined) {
            this.module007.destroy();
        }
        if (this.moduleSetting007 != undefined) {
            this.moduleSetting007.destroy();
        }
        if (this.module008 != undefined) {
            this.module008.destroy();
        }
        if (this.moduleSetting008 != undefined) {
            this.moduleSetting008.destroy();
        }
        if (this.module009 != undefined) {
            this.module009.destroy();
        }
        if (this.moduleSetting009 != undefined) {
            this.moduleSetting009.destroy();
        }
        if (this.module010 != undefined) {
            this.module010.destroy();
        }
        if (this.moduleSetting010 != undefined) {
            this.moduleSetting010.destroy();
        }
        if (this.module011 != undefined) {
            this.module011.destroy();
        }
        if (this.moduleSetting011 != undefined) {
            this.moduleSetting011.destroy();
        }
        if (this.module012 != undefined) {
            this.module012.destroy();
        }
        if (this.moduleSetting012 != undefined) {
            this.moduleSetting012.destroy();
        }
        if (this.module013 != undefined) {
            this.module013.destroy();
        }
        if (this.moduleSetting013 != undefined) {
            this.moduleSetting013.destroy();
        }
        if (this.module014 != undefined) {
            this.module014.destroy();
        }
        if (this.moduleSetting014 != undefined) {
            this.moduleSetting014.destroy();
        }
        if (this.module015 != undefined) {
            this.module015.destroy();
        }
        if (this.moduleSetting015 != undefined) {
            this.moduleSetting015.destroy();
        }
        if (this.module016 != undefined) {
            this.module016.destroy();
        }
        if (this.moduleSetting016 != undefined) {
            this.moduleSetting016.destroy();
        }
        if (this.module017 != undefined) {
            this.module017.destroy();
        }
        if (this.moduleSetting017 != undefined) {
            this.moduleSetting017.destroy();
        }
        if (this.module018 != undefined) {
            this.module018.destroy();
        }
        if (this.moduleSetting018 != undefined) {
            this.moduleSetting018.destroy();
        }
        if (this.module019 != undefined) {
            this.module019.destroy();
        }
        if (this.moduleSetting019 != undefined) {
            this.moduleSetting019.destroy();
        }
        if (this.module020 != undefined) {
            this.module020.destroy();
        }
        if (this.moduleSetting020 != undefined) {
            this.moduleSetting020.destroy();
        }




        if (module001Model != undefined) {
            this.module001.destroy();
            this.module001.show(this.initModule(module001Model));
            this.moduleSetting001.show(new View_Module({ model: module001Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module002Model != undefined) {
            this.module002.destroy();
            this.module002.show(this.initModule(module002Model));
            this.moduleSetting002.show(new View_Module({ model: module002Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module003Model != undefined) {
            this.module003.destroy();
            this.module003.show(this.initModule(module003Model));
            this.moduleSetting003.show(new View_Module({ model: module003Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module004Model != undefined) {
            this.module004.destroy();
            this.module004.show(this.initModule(module004Model));
            this.moduleSetting004.show(new View_Module({ model: module004Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module005Model != undefined) {
            this.module005.show(this.initModule(module005Model));
            this.moduleSetting005.show(new View_Module({ model: module005Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module006Model != undefined) {
            this.module006.show(this.initModule(module006Model));
            this.moduleSetting006.show(new View_Module({ model: module006Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module007Model != undefined) {
            this.module007.show(this.initModule(module007Model));
            this.moduleSetting007.show(new View_Module({ model: module007Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module008Model != undefined) {
            this.module008.show(this.initModule(module008Model));
            this.moduleSetting008.show(new View_Module({ model: module008Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module009Model != undefined) {
            this.module009.show(this.initModule(module009Model));
            this.moduleSetting009.show(new View_Module({ model: module009Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module010Model != undefined) {
            this.module010.show(this.initModule(module010Model));
            this.moduleSetting010.show(new View_Module({ model: module010Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module011Model != undefined) {
            this.module011.show(this.initModule(module011Model));
            this.moduleSetting011.show(new View_Module({ model: module011Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module012Model != undefined) {
            this.module012.show(this.initModule(module012Model));
            this.moduleSetting012.show(new View_Module({ model: module012Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module013Model != undefined) {
            this.module013.show(this.initModule(module013Model));
            this.moduleSetting013.show(new View_Module({ model: module013Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module014Model != undefined) {
            this.module014.show(this.initModule(module014Model));
            this.moduleSetting014.show(new View_Module({ model: module014Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module015Model != undefined) {
            this.module015.show(this.initModule(module015Model));
            this.moduleSetting015.show(new View_Module({ model: module015Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module016Model != undefined) {
            this.module016.show(this.initModule(module016Model));
            this.moduleSetting016.show(new View_Module({ model: module016Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module017Model != undefined) {
            this.module017.show(this.initModule(module017Model));
            this.moduleSetting017.show(new View_Module({ model: module017Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module018Model != undefined) {
            this.module018.show(this.initModule(module018Model));
            this.moduleSetting018.show(new View_Module({ model: module018Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module019Model != undefined) {
            this.module019.show(this.initModule(module019Model));
            this.moduleSetting019.show(new View_Module({ model: module019Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }
        if (module020Model != undefined) {
            this.module020.show(this.initModule(module020Model));
            this.moduleSetting020.show(new View_Module({ model: module020Model, templateModel: this.model, app: this.app, parent: this, live: this.live }));
        }

        this.setupBackground();

    },
    onAttach() {
        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('template:setZoom');

        if (window.luxaforboard.infopage != undefined && window.luxaforboard.infopage.Transform != undefined && window.luxaforboard.infopage.Transform == 0) {
            $('.zoomContainerH').css('transform', "scale(1)");
        }
    },
    setupBackground() {



        switch (this.app.entities.models.InfoPageSetting.attributes.BackGroundType) {
            case '0': this.ui.templateBg.css('background-color', this.app.entities.models.InfoPageSetting.attributes.BackGroundColorApp);
                this.ui.templateBg.css('background-image', 'none');
                break;
            case '1': this.ui.templateBg.css('background-color', this.app.entities.models.InfoPageSetting.attributes.BackGroundColor);
                this.ui.templateBg.css('background-image', 'none');
                break;
            case '2': this.ui.templateBg.css('background-image', String.format("url('{0}'", this.app.entities.models.InfoPageSetting.attributes.BackGroundImage));

                break;
        }

        var style = self.app.entities.collections.ModuleBackGroundImagePosition.where({ Value: this.app.entities.models.InfoPageSetting.attributes.BackGroundImagePosition })[0].attributes.Style;
        style.forEach(styleValue => this.ui.templateBg.css(styleValue.Name, styleValue.Value));
    },
    calcZoom() {

        var editorPos = $('#customInfopageEditItemLeftContent').offset();
        var editorHeight = (window.innerHeight - editorPos.top);

        var contentHeight = editorHeight;
        $('#customInfopageEditItemLeftContent').css('height', contentHeight + 'px');

        $('#rowChangeHeight').css('height', (contentHeight - 8) + 'px');
        $('#cardBodyChangeHeight').css('height', contentHeight + 'px');
        $('#formsSampleChangeHeight').css('height', contentHeight + 'px');
        $('#template').css('height', contentHeight + 'px');
        $('#templateDiv').css('height', contentHeight + 'px');

        $('#customInfopageEditItemRightContent').css('height', contentHeight + 'px');

        var maxHeight = 0;
        var maxWidth = 0;

        if ($('#templateDiv').find('.zoomContainerH')[0] != undefined) {
            var maxHeight = parseInt(($('#customInfopageEditItemLeftContent').height() / 1200) * 100);
            var maxWidth = parseInt(($('#customInfopageEditItemLeftContent').width() / 1980) * 100);
        }

        if ($('#templateDiv').find('.zoomContainerV')[0] != undefined) {
            var maxHeight = parseInt(($('#customInfopageEditItemLeftContent').height() / 1980) * 100);
            var maxWidth = parseInt(($('#customInfopageEditItemLeftContent').width() / 1200) * 100);
        }

        var ret = Math.min(maxHeight, maxWidth);

        return ret;
    },
    calcZoomLive() {

        //var editorPos = $('#customInfopageEditItemLeftContent').offset();
        var editorHeight = (window.innerHeight);

        var contentHeight = editorHeight;
        //$('#customInfopageEditItemLeftContent').css('height', contentHeight + 'px');

        $('#rowChangeHeight').css('height', (contentHeight - 8) + 'px');
        $('#cardBodyChangeHeight').css('height', contentHeight + 'px');
        $('#formsSampleChangeHeight').css('height', contentHeight + 'px');
        $('#template').css('height', contentHeight + 'px');
        $('#templateDiv').css('height', contentHeight + 'px');

        $('#customInfopageEditItemRightContent').css('height', contentHeight + 'px');

        var maxHeight = 0;
        var maxWidth = 0;

        if ($('#templateDiv').find('.zoomContainerH')[0] != undefined) {
            var maxHeight = (window.innerHeight / 1080) * 100; //parseInt(($('#customInfopageEditItemLeftContent').height() / 1200) * 100);
            var maxWidth = (window.innerWidth / 1980) * 100; //parseInt(($('#customInfopageEditItemLeftContent').width() / 1980) * 100);
        }

        if ($('#templateDiv').find('.zoomContainerV')[0] != undefined) {
            var maxHeight = (window.innerHeight / 1080) * 100;//parseInt(($('#customInfopageEditItemLeftContent').height() / 1980) * 100);
            var maxWidth = (window.innerWidth / 1980) * 100; // parseInt(($('#customInfopageEditItemLeftContent').width() / 1200) * 100);
        }

        var ret = Math.min(maxHeight, maxWidth);

        return ret;
    },
    setZoom(zoom, el) {

        if (el != undefined) {

            var transformOrigin = [0, 0];
            el = el || instance.getContainer();
            var p = ["webkit", "moz", "ms", "o"],
                s = "scale(" + zoom + ")",
                oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

            for (var i = 0; i < p.length; i++) {
                el.style[p[i] + "Transform"] = s;
                el.style[p[i] + "TransformOrigin"] = oString;
            }

            el.style["transform"] = s;
            el.style["transformOrigin"] = oString;

            var userChannel = Backbone.Radio.channel('app');
            userChannel.trigger('template:transform', s);
            userChannel.trigger('template:transformOrigin', oString);
        }
    },
    updateZoom(a) {
        var zoomScale = Number(a) / 100;

        if (document.getElementsByClassName('zoomContainerH') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerH')[0]);
        }

        if (document.getElementsByClassName('zoomContainerV') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerV')[0]);
        }

        var zoomNotScale = 1 / zoomScale;
        var x = document.querySelectorAll(".zoomNot");
        var i;
        for (i = 0; i < x.length; i++) {
            this.setZoomNot(zoomNotScale, x[i]);
        }

        /*$('.infopageeditor').each(function (i, obj) {
            var element = obj.closest(".moduleRegion");
            $(obj).css('max-height', (($(element).height() - 60) - (parseInt($(element).css('border-width').slice(0, -2) * 2))) + 'px');
        });*/
    },
    updateZoomLive(zoomScale) {
        $('.grid-gap-class').each(function (i, obj) {
            $(obj).css('grid-gap', '0px');
        });



        if (document.getElementsByClassName('zoomContainerH') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerH')[0]);
        }

        if (document.getElementsByClassName('zoomContainerV') != undefined) {
            this.setZoom(zoomScale, document.getElementsByClassName('zoomContainerV')[0]);
        }

        var zoomNotScale = 1 / zoomScale;
        var x = document.querySelectorAll(".zoomNot");
        var i;
        for (i = 0; i < x.length; i++) {
            this.setZoomNot(zoomNotScale, x[i]);
        }



        /*$('.infopageeditor').each(function (i, obj) {
            var element = obj.closest(".moduleRegion");
            $(obj).css('max-height', (($(element).height() - 60) - (parseInt($(element).css('border-width').slice(0, -2) * 2))) + 'px');
        });*/
    },
    setZoomNot(zoom, el) {

        var transformOrigin = [0, 0];
        el = el || instance.getContainer();
        var p = ["webkit", "moz", "ms", "o"],
            s = "scale(" + zoom + ")",
            oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

        for (var i = 0; i < p.length; i++) {
            el.style[p[i] + "Transform"] = s;
            //el.style[p[i] + "TransformOrigin"] = oString;
        }

        el.style["transform"] = s;
        //el.style["transformOrigin"] = oString;
    }
}, Backbone.Radio.Requests);
