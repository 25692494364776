﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customSubscription from './tpl/customSubscription.html';
import language from '../../../common/language';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomSubscriptionList from './customSubscriptionList';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Information from '../../../lib/views/information/information';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_Render from '../render/render';
import config from '../../../common/config';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customSubscription();
    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();
        this.localSetting = new Entities_Empty.Model();

        this.localSetting.attributes.AllowSave = true;

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customSubscription');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        if (!this.localSetting.attributes.AllowSave) {
            return false;
        }

        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        
        this.btnSubmitClick(null, callback);
        
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
        var changes = false;

        var GlobalId = parseInt(this.ui.customSubscription_selectSubscription.val());
        var selectedConfigSubscription = this.app.entities.collections.configSubscription.where({ GlobalId: GlobalId });
        if ((selectedConfigSubscription[0].attributes.GlobalId != this.app.entities.models.configSubscription[0].attributes.GlobalId) && (!this.localSetting.attributes.CreatedToday)) {
            changes = true;
        }

        this.setHasChanged(changes);
    },
    regions: {
        customSubscriptionList : '#customSubscriptionList',
        customSubscriptionPagination : '#customSubscriptionPagination',
        information: "#customSubscriptionInformation"
    },
    onBeforeShow: function () {
        this.customSubscriptionList.show(new View_CustomSubscriptionList({ collection: this.app.entities.collections.customSubscription,localSetting: this.localSetting, app : this.app }));
        this.customSubscriptionPagination.show(new View_Pagination({ collection: this.app.entities.collections.customSubscription, app : this.app }));

        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    events: {
        'click @ui.customSubscription_btnSubmit': 'btnSubmitClick',
        'change @ui.customSubscription_selectSubscription': 'selectSubscription',
        'click #customSubscription_btnTEST': 'btnTESTClick',
        'click #help': 'help'
    },
    ui: {
        'customSubscription_btnSubmit': '#customSubscription_btnSubmit',
        'customSubscription_selectSubscription': '#customSubscription_selectSubscription',
        'customSubscription_selectDescription': '#customSubscription_selectDescription',
        'help': '#help'
    },
    btnTESTClick: function () {
        var tmpModel = new this.informationCollection.model({ Information: "TEST" });
        this.informationCollection.add(tmpModel);
        this.informationCollection.trigger('change');
    },
    onRender: function () {
        this.setupSubscriptions();
        this.buildInformation();
        this.updateInformationCollection();

        //Allways false, because no other items is selected yet
        this.btnSubmitsShow(false);

        this.setupMenu();
    },
    reRender : function () {
        this.setupSubscriptions();
        this.buildInformation();
        this.updateInformationCollection();

        //Allways false, because no other items is selected yet
        this.btnSubmitsShow(false);
    },
    renderInformation: function () {
        this.informationCollection.reset();

        if (this.localSetting.attributes.DeleteEvents) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: this.localSetting.attributes.DeleteEventsInformation });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.CreatedToday) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100082, id: 100 });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnableAdvertising) {
            var tmpModel = new this.informationCollection.model({ Information: resources.X100089 });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnableQRCode) {
            var tmpModel = new this.informationCollection.model({ Information: resources.X100086});
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnableSchemaModel) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100087 });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnableFormModel) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100088 });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnableProductSheetModel) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X101122 });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnableInfo) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100090 });
            this.informationCollection.add(tmpModel);
        }

        if (this.localSetting.attributes.ChangeEnablePoster) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100091 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    setupMenu : function () {
        //var userChannel = Backbone.Radio.channel('app');
        //var foundModelCustomSubscription = this.app.entities.collections.customSubscription.models[0];

        //switch (foundModelCustomSubscription.attributes.DineroInvoicePaymentStatus) {
        //    case 'Draft' : 
        //    case 'Booked' : userChannel.trigger('customMenu:disable'); break;
        //    case 'Paid' : 
        //    case 'OverPaid' : userChannel.trigger('customMenu:enable'); break;
        //    case 'Overdue' : userChannel.trigger('customMenu:disable'); break;
        //}
    },
    buildInformation: function () {
        this.setupCreatedToday();

        var GlobalId = parseInt(this.ui.customSubscription_selectSubscription.val());
        var selectedConfigSubscription = this.app.entities.collections.configSubscription.where({ GlobalId : GlobalId });

        var changeEnableQRCode = false;
        var changeEnableSchemaModel = false;
        var changeEnableFormModel = false;
        var changeEnableProductSheetModel = false;
        var changeEnableAdvertising = false;
        var changeEnableInfo = false;
        var changeEnablePoster = false;

        this.localSetting.attributes.DeleteEvents = false;
        this.localSetting.attributes.DeleteEventsInformation = '';

        this.localSetting.attributes.ChangeEnableAdvertising = false;
        this.localSetting.attributes.ChangeEnableQRCode = false;
        this.localSetting.attributes.ChangeEnableSchemaModel = false;
        this.localSetting.attributes.ChangeEnableFormModel = false;
        this.localSetting.attributes.ChangeEnableProductSheetModel = false;
        this.localSetting.attributes.ChangeEnableInfo = false;
        this.localSetting.attributes.ChangeEnablePoster = false;

        if (this.app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            if (selectedConfigSubscription[0].attributes.EnableAdvertising != this.app.entities.models.configSubscription[0].attributes.EnableAdvertising) {
                this.localSetting.attributes.ChangeEnableAdvertising = true;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableQRCode == true) {
            if (selectedConfigSubscription[0].attributes.EnableQRCode != this.app.entities.models.configSubscription[0].attributes.EnableQRCode) {
                this.localSetting.attributes.ChangeEnableQRCode = true;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableSchemaModel == true) {
            if (selectedConfigSubscription[0].attributes.EnableSchemaModel != this.app.entities.models.configSubscription[0].attributes.EnableSchemaModel) {
                this.localSetting.attributes.ChangeEnableSchemaModel = true;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableFormModel == true) {
            if (selectedConfigSubscription[0].attributes.EnableFormModel != this.app.entities.models.configSubscription[0].attributes.EnableFormModel) {
                this.localSetting.attributes.ChangeEnableFormModel = true;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableProductSheetModel == true) {
            if (selectedConfigSubscription[0].attributes.EnableProductSheetModel != this.app.entities.models.configSubscription[0].attributes.EnableProductSheetModel) {
                this.localSetting.attributes.ChangeEnableProductSheetModel = true;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnableInfo == true) {
            if (selectedConfigSubscription[0].attributes.EnableInfo != this.app.entities.models.configSubscription[0].attributes.EnableInfo) {
                this.localSetting.attributes.ChangeEnableInfo = true;
            }
        }

        if (this.app.entities.models.configSubscription[0].attributes.EnablePoster == true) {
            if (selectedConfigSubscription[0].attributes.EnablePoster != this.app.entities.models.configSubscription[0].attributes.EnablePoster) {
                this.localSetting.attributes.ChangeEnablePoster = true;
            }
        }
    },
    customViewEvent_setActive: function () {
        var array = [];

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();
    },
    setupCreatedToday: function () {
        var self = this;
        this.localSetting.attributes.CreatedToday = false;

        var now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(1);

        var ekstraMounth = '';
        if ((now.getUTCMonth() + 1) < 10) {
            ekstraMounth = '0';
        }

        var ekstraDay = '';
        if (now.getUTCDate().toString() < 10) {
            ekstraDay = '0';
        }

        var date = now.getUTCFullYear().toString() + '-' +
        ekstraMounth + (now.getUTCMonth() + 1) + '-' +
        ekstraDay + now.getUTCDate().toString();

        this.collection.forEach(function (entry) {
            var utcDate = entry.attributes.UtcDate.substr(0,10);
            if (utcDate == date) {
                self.localSetting.attributes.CreatedToday = true;
            }
        });
    },
    setupSubscriptions: function () {
        var description = '';
        var self = this;

        self.ui.customSubscription_selectSubscription.empty();

        this.app.entities.collections.configSubscription.forEach(function (entry) {
            if (entry.attributes.GlobalId == self.app.entities.models.configSubscription[0].attributes.GlobalId) {
                self.ui.customSubscription_selectSubscription.append('<option value="' + entry.attributes.GlobalId + '" selected="selected">' + entry.attributes.Name + '</option>');
                description = entry.attributes.Description;
            }
            else {
                if (entry.attributes.Downgrade == true) {
                    self.ui.customSubscription_selectSubscription.append('<option value="' + entry.attributes.GlobalId + '">' + entry.attributes.Name + '</option>');
                }
            }
        });

        self.ui.customSubscription_selectDescription.text(description);

        if (self.localSetting.attributes.CreatedToday) {
            this.ui.customSubscription_selectSubscription.prop("disabled", true);
        }
    },
    selectSubscription: function () {
        var GlobalId = parseInt(this.ui.customSubscription_selectSubscription.val());

        var selectedConfigSubscription = this.app.entities.collections.configSubscription.where({ GlobalId: GlobalId });
        this.ui.customSubscription_selectDescription.text(selectedConfigSubscription[0].attributes.Description);

        this.buildInformation();

        var eventsTotal = this.app.entities.collections.customEvent.fullCollection.length;
        var deleteTotal = eventsTotal - selectedConfigSubscription[0].attributes.EventQty;

        if ((selectedConfigSubscription[0].attributes.EventQty < this.app.entities.models.configSubscription[0].attributes.EventQty) && (deleteTotal > 0)) {
            this.localSetting.attributes.DeleteEvents = true;
            this.localSetting.attributes.DeleteEventsInformation = String.format(resources.X100083, selectedConfigSubscription[0].attributes.EventQty, eventsTotal, deleteTotal);

            this.btnSubmitsShow(false);
            this.localSetting.attributes.AllowSave = false;

        } else {
            if ((selectedConfigSubscription[0].attributes.GlobalId != this.app.entities.models.configSubscription[0].attributes.GlobalId) && (!this.localSetting.attributes.CreatedToday)) {
                this.btnSubmitsShow(true);
                this.localSetting.attributes.AllowSave = true;

            } else {
                this.btnSubmitsShow(false);
                this.localSetting.attributes.AllowSave = false;
            }
        }


        this.updateInformationCollection();
    },
    btnSubmitsShow: function (show) {
        if (show) {
            this.$el.find('#customSubscription_btnSubmit').removeClass('btn-secondary');
            this.$el.find('#customSubscription_btnSubmit').addClass('btn-primary');
            this.$el.find('#customSubscription_btnSubmit').prop('disabled', false);
        } else {
            this.$el.find('#customSubscription_btnSubmit').removeClass('btn-primary');
            this.$el.find('#customSubscription_btnSubmit').addClass('btn-secondary');
            this.$el.find('#customSubscription_btnSubmit').prop('disabled', true);
        }
    },
    btnSubmitClick: function (event, callback) {
        if (event) {
            event.preventDefault();
        }

        var self = this;

        var GlobalId = parseInt(this.ui.customSubscription_selectSubscription.val());
        var selectedConfigSubscription = this.app.entities.collections.configSubscription.where({ GlobalId: GlobalId });

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100081;
        dialog2ButtonModel.attributes.Text = resources.X100084;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            self.save(selectedConfigSubscription, callback);
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);

            callback();
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    save: function (selectedConfigSubscription, callback) {
        var now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(1);

        var utcDateTime = now.getUTCFullYear().toString() + '-' +
                   (now.getUTCMonth() + 1) + '-' +
                   now.getUTCDate().toString() + ' ' + 
                   now.getUTCHours() + ':' + 
                   now.getUTCMinutes() + ':' + 
                   now.getUTCSeconds();

        var tmpModel = new this.app.entities.collections.customSubscription.model();

        //tmpModel.attributes.id = null;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.UtcDate = utcDateTime;
        tmpModel.attributes.ConfigSubscriptionGlobalId = parseInt(this.ui.customSubscription_selectSubscription.val());

        var self = this;

        var callbackRender = function() {
            var customEvent = self.app.entities.collections.customEvent.fullCollection.where({});
            var render = new View_Render({ collection: customEvent, app : self.app });
            self.app.renderRegion.show(render);

            var renderFinishCallback = function () {
                if (callback) {
                    callback();
                }
                else {
                    self.reRender();
                }
            }
            render.doIt(renderFinishCallback, true, 'EVENT_REFRESH', false);
        }

        this.collection.create(tmpModel, {
            wait: true,
            success: function (response) {
                self.app.entities.models.custom.attributes.CustomSubscription = response.attributes.id;
                var customSubscription = self.app.entities.collections.customSubscription.where({ id: self.app.entities.models.custom.attributes.CustomSubscription });
                self.app.entities.models.configSubscription = self.app.entities.collections.configSubscription.where({ GlobalId: customSubscription[0].attributes.ConfigSubscriptionGlobalId })

                var event_saved = 0;
                var customQRCode = self.app.entities.collections.customQRCode.fullCollection.where({ Name: resources.X100123 });

                self.app.entities.collections.customEvent.fullCollection.forEach(function (entry) {
                    //No QRCode enable any more
                    if (self.localSetting.attributes.ChangeEnableQRCode) {
                        if (customQRCode.length >= 1) {
                            entry.attributes.QRCode = customQRCode[0].attributes.id;
                        }

                        //No Schema enable any more
                        if (self.localSetting.attributes.ChangeEnableSchemaModel) {
                            entry.attributes.SchemaModel = -1;
                        }

                        //No Info enable any more
                        if (self.localSetting.attributes.ChangeEnableInfo ) {
                            entry.attributes.Infopage = -1;
                        }

                        //No Form enable any more
                        if (self.localSetting.attributes.ChangeEnableFormModel ) {
                            entry.attributes.FormModel = -1;
                        }

                        //No Product sheet any more
                        if (self.localSetting.attributes.ChangeEnableProductSheetModel) {
                            entry.attributes.ProductSheetModel = -1;
                        }

                        entry.save(entry.attributes, {
                            silent: true,
                            updateVersion : false,
                            success: function () {
                                event_saved ++;
                                if (event_saved = self.app.entities.collections.customEvent.fullCollection.length) {
                                    callbackRender();
                                }
                            }
                        });
                    }
                })
            }
        });
    }
}, Backbone.Radio.Requests);
