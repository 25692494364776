var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div> <div style="margin-top:10px"> <ul class="pagination d-flex flex-wrap justify-content-center"> ';
 if (model.hasPreviousPage()) { 
__p+=' <li class="page-item"> <a style="cursor:pointer" class="first page-link">&lsaquo;</a> </li> <li class="page-item"> <a style="cursor:pointer" class="prev page-link">&laquo;</a> </li> ';
 } 
__p+=' ';
 for(i = 1; i <= model.state.totalPages; i++) { 
__p+=' ';
 if (model.state.currentPage == i) { 
__p+=' <li class="page-item active"><a style="cursor:pointer" class="page selected page-link">'+
((__t=( i ))==null?'':__t)+
'</a></li> ';
 } else { 
__p+=' <li class="page-item"><a style="cursor:pointer" class="page page-link">'+
((__t=( i ))==null?'':__t)+
'</a></li> ';
 } 
__p+=' ';
 } 
__p+=' ';
 if (model.hasNextPage()) { 
__p+=' <li class="page-item"> <a style="cursor:pointer" class="next page-link">&raquo;</a> </li> <li class="page-item"> <a style="cursor:pointer" class="last page-link">&rsaquo;</a> </li> ';
 } 
__p+=' </ul> </div> </div>';
}
return __p;
};
