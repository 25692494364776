var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customFileListItem_empty"> <div class="d-flex align-items-center pb-3 border-bottom" style="margin-top: 15px"> <div class="ml-3"> <a target="_blank" id="customFileListItem_aFile" href="'+
((__t=( model.FileUrl ))==null?'':__t)+
'"> <h6 class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> </a> </div> <div class="ml-auto"> <input id="customFileListItem_inputUrl'+
((__t=( model.id ))==null?'':__t)+
'" style="height:0px;padding: 0px; border : 0px" value="'+
((__t=( model.FileUrl ))==null?'':__t)+
'"> <i id="customFileListItem_btnCopyUrl" style="color: #6c7293 !important" class="fa fa-lg fa-1x fa-copy font-weight-bold px-1 py-1 text-info mdi-24px"></i> <i id="customFileListItem_btnDelete" style="color: #6c7293 !important" class="far fa-lg fa-1x fa-times-circle px-1 py-1 text-info mdi-24px"></i> </div> </div> </div>';
}
return __p;
};
