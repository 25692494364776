var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customProductSheetImageInsert_empty"> <form class="contact-form"> <div class="control-group Image"> <div class="form-group"> <label type="button" for="customProductSheetImageInsert_btnImage" class="btn btn-secondary">'+
((__t=( resources['X100295'] ))==null?'':__t)+
'</label> <input id="customProductSheetImageInsert_btnImage" type="file" accept="image/*" style="font-size: 20px; display: none"> <br> <span class="help-inline" style="color:red"></span> </div> </div> </form> </div>';
}
return __p;
};
