﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    }
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if ((method == 'read')  && (options.tmpModel.Method == 'GetFriendlyUrlUsed')) {
            options.url = config.webApiUrl() + '/api/customOther/' + options.tmpModel.Id + '?AuthUserId=' + config.authUserId() + '&FriendlyUrl=' + options.tmpModel.FriendlyUrl;
        }
        return Backbone.sync(method, model, options);
    }
});





