﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as nouislider from 'nouislider';

import * as FilePond from 'filepond';

import FilePondPluginFileEncode from '../../../plugins/filepond-plugin-file-encode';
import FilePondPluginFileValidateType from '../../../plugins/filepond-plugin-file-validate-type';

import tpl_video from './tpl/video.html';

import View_DialogWaiting2 from '../../../../lib/views/dialogWaiting2/dialogWaiting2';
import View_DialogWaiting from '../../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../../lib/entities/empty';


import config from '../../../../common/config';

export default Marionette.ItemView.extend({
    dialogRegion: '#video_modal',
    errors: [],
    getTemplate: function () {
        return tpl_video({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;

        //this.listenTo(this.model, 'change', this.render, this);
    },
    ui: {
        'video_input_File_Name': '#video_input_File_Name',
        'video_filePond': '#video_filePond',
        'video_checkbox_Loop': '#video_checkbox_Loop',
        'video_input_Volume ' : '#video_input_Volume',
        'video_checkbox_EnablePlay': '#video_checkbox_EnablePlay',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right'
    },
    events: {
        'click #video_btn01': 'onBtn01',
        'click #video_btn02': 'onBtn02'
    },
    onRender: function () {
        this.setupEnableType();
    },
    getFileType: function (mimeType) {
        var ret = 'mp4';

        switch (mimeType) {
            case 'video/ogg': ret = 'ogg'; break;
            case 'video/mp4': ret = 'mp4'; break;
            case 'video/avi': ret = 'avi'; break;
            case 'video/webm': ret = 'webm'; break;
        }

        return ret;
    },
    base64MimeType: function (encoded) {
        var result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    },
    onAttach() {
        var self = this;

        FilePond.registerPlugin(
            FilePondPluginFileValidateType,
            FilePondPluginFileEncode,
        );

        var labelIdle = resources.X101435 + ' <span class="filepond--label-action">' + resources.X101437 + '</span>';

        var pond = FilePond.create(this.ui.video_filePond[0], {
            labelIdle: [
                labelIdle,
                ''
            ],
            acceptedFileTypes: [
                'video/ogg',
                'video/mp4',
                'video/avi',
                'video/webm'
            ],
            allowMultiple: false,

            onaddfile: (err, fileItem) => {
                if (err == null) {
                    var dialogWaitingModel = new Entities_Empty.Model();
                    dialogWaitingModel.attributes.Title = resources.X100322;
                    dialogWaitingModel.attributes.Text = resources.X100301;
                    dialogWaitingModel.attributes.Button = resources.X100279;

                    var dialogWaiting = new View_DialogWaiting2({ model: dialogWaitingModel, parent: self });
                    var callback = function (options) {
                        this.stopListening(dialogWaiting, "dialog:okclicked", callback);
                    }
                    this.listenTo(dialogWaiting, "dialog:okclicked", callback);
                    app.dialogRegion2.show(dialogWaiting);


                    //console.log(err, fileItem.getMetadata('resize'));
                    var base64 = fileItem.getFileEncodeDataURL();
                    var mimeType = self.base64MimeType(base64);
                    var fileData = base64.split(',')[1];
                    //var base64 = fileItem.getFileEncodeBase64String();

                    var tmpModel = new self.collection.model();
                    tmpModel.attributes.AuthUserId = config.authUserId();
                    tmpModel.attributes.FileData = fileData;
                    tmpModel.attributes.FileMimeType = mimeType;
                    tmpModel.attributes.FileUpdate = true;

                    var filename = 'video' + '_' + + new Date().getTime() + '.' + self.getFileType(mimeType);
                    tmpModel.attributes.Filename = filename;//fileItem.filename;
                    tmpModel.attributes.Name = fileItem.filename;
                    tmpModel.attributes.EventId = self.options.parent.options.templateModel.attributes.EventId;
                    tmpModel.attributes.TemplateId = self.options.parent.options.templateModel.attributes.id;
                    tmpModel.attributes.ModuleRegion = self.options.parent.model.attributes.ModuleRegion;
                    tmpModel.attributes.ModuleDiv = self.options.parent.model.attributes.ModuleDiv;
                    tmpModel.attributes.ModuleType = self.options.parent.model.attributes.ModuleType;

                    self.collection.create(tmpModel, {
                        wait: true,
                        success: function (response) {
                            self.model.attributes.id = response.attributes.id;
                            self.model.attributes.InfoFile_id = response.attributes.id;
                            self.model.attributes.InfoFile_FileUrl = response.attributes.FileUrl;
                            self.model.attributes.InfoFile_Name = response.attributes.Name;

                            //dialogWaiting.triggerMethod('dialog:close');

                            //self.collection.fullCollection.sort();
                            //self.collection.getFirstPage({ fetch: false });
                            pond.removeFile(fileItem);

                            self.ui.video_input_File_Name.val(self.model.attributes.InfoFile_Name);
                            //self.model.trigger('change');
                            dialogWaiting.triggerMethod('dialog:close');
                        }
                    });
                }
            }
        });

        if ($("#video_input_Volume").length) {
            var startSlider1 = document.getElementById('video_input_Volume');
            nouislider.create(startSlider1, {
                start: self.model.attributes.Volume,
                connect: [true, false],
                range: {
                    min: 0,
                    max: 100
                },
                step : 10
            });
            startSlider1.noUiSlider.on('set', function (e) {
                self.model.attributes.Volume = parseInt(e);
            });
        }
    },
    setupEnableType: function () {
        var checked = this.model.attributes.Loop == 1 ? true : false;
        this.ui.video_checkbox_Loop.attr('checked', checked);
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    setModel: function () {
        //var Loop = this.model.attributes.EnablePlay;
        //this.ui.video_checkbox_EnablePlay.is(':checked') ? Loop = 1 : Loop = 0;
        var Loop = 1;

        this.ui.video_checkbox_Loop.is(':checked') ? Loop = 1 : Loop = 0;

        this.model.set({
            Loop: Loop,
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    }
});