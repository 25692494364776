var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customIPAddressInsert_empty"> <form class="contact-form" style="margin-bottom:10px"> <div class="control-group customIPAddressInsert_inputName"> <div class="form-group"> <label for="customIPAddressInsert_inputName">'+
((__t=( resources['X200104'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customIPAddressInsert_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customIPAddressInsert_btnSubmit">'+
((__t=( resources['X200089'] ))==null?'':__t)+
'</button> </form> </div>';
}
return __p;
};
