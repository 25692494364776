﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as plugins_jqplot from '../../plugins/jqplot'

import tpl_customEventCounter from './tpl/customEventCounter.html';
import tpl_customEventCounterDesign from './tpl/customEventCounterDesign.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import * as Entities_UserAnalytic from '../../entities/userAnalytic';
import * as Entities_ResetUserAnalytic from '../../entities/resetUserAnalytic';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import Help from '../../helper/help';

export default View_LayoutError.extend({
    designRegion: '#designRegion_modal',
    getTemplate: function () {
        if (this.options.designMode) {
            return tpl_customEventCounterDesign();
        } else {
            return tpl_customEventCounter();
        }
    },
    initialize: function () {
        this.app = this.options.app;

        this.userAnalyticCollection = new Entities_UserAnalytic.Collection();
        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);

        this.currYear = [];
        //this.total = 0;

        this.currentButton = "#customEventCounter_btnZoomIn";
    },
    help: function () {
        Help.help(this, true, 'customEventCounter');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
    },
    regions: {
        information: "#customEventCounterInformation"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    ui: {
        'customEventCounter_btnReset': '#customEventCounter_btnReset',
        'customEventCounter_btnZoomOut': '#customEventCounter_btnZoomOut',
        'customEventCounter_btnZoomIn': '#customEventCounter_btnZoomIn',
        'customEventCounter_selectEvent': '#customEventCounter_selectEvent',
        'customEventCounter_divEventName': '#customEventCounter_divEventName',
        'customEventCounter_chart': '#customEventCounter_chart',
        'customEventCounter_chartdiv': '#customEventCounter_chartdiv',
        'help': '#help',
        'designRegion_body': '#designRegion_body',
        'designRegion_btnOk': '#designRegion_btnOk'
    },
    events: {
        'click @ui.customEventCounter_btnReset': 'btnReset',
        'click @ui.customEventCounter_btnZoomOut': 'btnZoomOut',
        'click @ui.customEventCounter_btnZoomIn': 'btnZoomIn',
        'change @ui.customEventCounter_selectEvent': 'selectEventChange',
        'click #help': 'help',
        'click @ui.designRegion_btnOk': 'designRegion_btnOk'
    },
    onRender: function () {
        this.setupResetBtn();
        this.renderInformation();
    },
    setupZoomBtn: function () {
        /*switch (this.currentButton) {
            case '#customEventCounter_btnZoomIn':
                this.ui.customEventCounter_btnZoomIn.css('background-color', 'rgb(0,102,153)');
                this.ui.customEventCounter_btnZoomOut.css('background-color', '#5b616a');
                break;
            case '#customEventCounter_btnZoomOut':
                this.ui.customEventCounter_btnZoomOut.css('background-color', 'rgb(0,102,153)');
                this.ui.customEventCounter_btnZoomIn.css('background-color', '#5b616a');
                break;
        }*/
    },
    customViewEvent_setActive: function () {
        var array = [];

        var value = parseInt(this.ui.customEventCounter_selectEvent.val());

        if (value != -1) {
            var tmp = { "id": value };
            array.push(tmp);
        }

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach() {
        var renderGraph = this.setupEvent();
        this.customViewEvent_setActive();

        if (this.options.designMode) {
            var height = $(window).height() * 0.8;
            this.ui.designRegion_body.height(height);
        }

        if (this.options.event != undefined && this.options.event != -1) {
            this.ui.customEventCounter_selectEvent.val(this.options.event.toString());
        } else {
            //this.ui.customEventCounter_selectEvent.val(this.ui.customEventCounter_selectEvent.first().val())
        }

        if (renderGraph) {
            this.selectEventChange(null);
        }
    },
    onDestroy() {
        this.running = false;
        this.ui.customEventCounter_chart.emptyForce();
        this.informationCollection.reset();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No Event yet
        if (this.collection.fullCollection.length <= 0) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100334 });
            this.informationCollection.add(tmpModel);
        }

        var tmpModel = new this.informationCollection.model({ toastType: 1, header: resources.X100230, Information: resources.X100876 });
        this.informationCollection.add(tmpModel);

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    setupResetBtn: function () {
        var enable = true;
        if (this.collection.fullCollection.length <= 0) {
            enable = false;
        }

        if (enable) {
            this.ui.customEventCounter_btnReset.removeClass('btn-secondary');
            this.ui.customEventCounter_btnReset.addClass('btn-primary');
            this.ui.customEventCounter_btnReset.prop('disabled', false);
        } else {
            this.ui.customEventCounter_btnReset.removeClass('btn-primary');
            this.ui.customEventCounter_btnReset.addClass('btn-secondary');
            this.ui.customEventCounter_btnReset.prop('disabled', true);
        }
    },
    setupEvent: function () {
        var self = this;
        var renderGraph = false;
        this.collection.fullCollection.forEach(function (entry) {
            self.ui.customEventCounter_selectEvent.append('<option value="' + entry.attributes.id + '">' + entry.attributes.Name + '</option>');
            renderGraph = true;
        });

        //if (renderGraph) {
        //    this.selectEventChange(null);
        //}
        return renderGraph;
    },
    btnReset: function (event) {
        event.preventDefault();
        var self = this;

        var tmpModel = new Entities_ResetUserAnalytic.Model();
        var eventId = this.ui.customEventCounter_selectEvent.val();
        tmpModel.id = 1;
        tmpModel.attributes.id = 1;
        var self = this;

        var dialog2ButtonModel = new Entities_Empty.Model();
        dialog2ButtonModel.attributes.Title = resources.X100070;
        dialog2ButtonModel.attributes.Text = resources.X100388;
        dialog2ButtonModel.attributes.Button01 = resources.X100281;
        dialog2ButtonModel.attributes.Button02 = resources.X100282;
        var dialog2Button = new View_Dialog2Button({ model: dialog2ButtonModel, parent: this });

        var callback01 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);


            tmpModel.destroy({
                wait: true,
                eventId: eventId,
                success: function (model, response) {
                    self.currentButton = "#customEventCounter_btnZoomIn";
                    self.setupZoomBtn();
                    self.selectEventChange(null);
                },
                error: function (model, response) {
                }
            });
        }
        this.listenTo(dialog2Button, "dialog:btn01clicked", callback01);

        var callback02 = function (options) {
            this.stopListening(dialog2Button, "dialog:btn01clicked", callback01);
            this.stopListening(dialog2Button, "dialog:btn02clicked", callback02);
        }
        this.listenTo(dialog2Button, "dialog:btn02clicked", callback02);

        this.app.dialogRegion.show(dialog2Button);
    },
    btnZoomOut: function (event) {
        this.currentButton = "#customEventCounter_btnZoomOut";
        this.setupZoomBtn();

        if (this.currYear) {
            this.drawPlot(this.currYear);
        }
    },
    btnZoomIn: function (event) {
        this.currentButton = "#customEventCounter_btnZoomIn";
        this.setupZoomBtn();

        if (this.currYear) {
            var showDay = 7;
            var tmpCurrYear = [];
            if (this.currYear.length > showDay) {
                for (var i = this.currYear.length - showDay; i < this.currYear.length; i++) {
                    tmpCurrYear.push(this.currYear[i]);
                }
                this.drawPlot(tmpCurrYear);
            } else {
                this.drawPlot(this.currYear);
            }
        }
    },
    setupLastDays: function (currYear) {
        currYear.sort(function (a, b) {
            if (a[0] > b[0]) { return 1 }
            if (a[0] < b[0]) { return -1 }
            return 0;
        });

        var days = 6;
        for (var i = days; i >= 0; i--) {
            currYear[i]

            var now = new Date();
            now.setDate(now.getDate() - i);

            var sMonth = '';
            var month = ((now.getMonth() + 1));
            if (month < 10) {
                sMonth = '0' + month;
            } else {
                sMonth = month;
            }

            var sDay = '';
            var day = now.getDate();
            if (day < 10) {
                sDay = '0' + day
            } else sDay = day;

            var dateTime = now.getFullYear().toString() + '-' +
                sMonth + '-' +
                sDay;

            var found = false;
            for (var i1 = 0; i1 < currYear.length; i1++) {
                if (currYear[i1][0] === dateTime) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                var obj = [dateTime, 0];
                currYear.push(obj);
            }
        }
        currYear.sort(function (a, b) {
            if (a[0] > b[0]) { return 1 }
            if (a[0] < b[0]) { return -1 }
            return 0;
        });

        return currYear;
    },
    selectEventChange: function (event) {
        var self = this;
        if (event) {
            event.preventDefault();
        }
        var value = this.ui.customEventCounter_selectEvent.val();
        var eventName = this.ui.customEventCounter_selectEvent.find('option:selected').text();
        this.ui.customEventCounter_divEventName.text(eventName);

        this.ui.customEventCounter_chart.empty();
        this.customViewEvent_setActive();

        this.userAnalyticCollection.fetch({
            id: value,
            success: function (mode, response) {
                //$.jqplot._noToImageButton = true;
                //$.jqplot.config.enablePlugins = true;

                self.currYear = [];
                //self.total = 0;
                response.forEach(function (item) {
                    var date = item.UtcDate.substring(0, item.UtcDate.lastIndexOf("T"));
                    //self.total += item.Count;
                    var obj = [date, item.Count];
                    self.currYear.push(obj);
                });

                self.currYear = self.setupLastDays(self.currYear);

                //if (response.length == 0) {
                //    var now = new Date();
                //    var dateTime = now.getUTCFullYear().toString() + '-' +
                //     (now.getUTCMonth() + 1) + '-' +
                //     now.getUTCDate().toString();
                //    var obj = [dateTime, 0];
                //    self.currYear.push(obj);
                //}
                /*
                var plot1 = $.jqplot("customEventCounter_chart", [currYear], {
                    seriesColors: ["rgba(78, 135, 194, 0.7)", "rgb(211, 235, 59)"],
                    title: { 
                        text : resurces.X100233
                    },
                    highlighter: {
                        show: true,
                        sizeAdjust: 1,
                        tooltipOffset: 9,
                    },
                    grid: {
                        background: 'rgba(157,157,57,0.0)',
                        drawBorder: false,
                        shadow: false,
                        gridLineColor: '#666666',
                        
                        gridLineWidth: 2,
                    },
                    legend: {
                        show: true,
                        placement: 'inside',
                        showSwatches : false
                    },
                    seriesDefaults: {
                        rendererOptions: {
                            smooth: true,
                            animation: {
                                show: true
                            }
                        },
                        showMarker: true
                    },
                    series: [
                        {
                            fill: false,
                            label: resources.X100235 + self.total,
                        },
                        {
                            label: '',
                        },
                        
                    ],
                    axesDefaults: {
                        rendererOptions: {
                            baselineWidth: 1.5,
                            baselineColor: '#444444',
                            drawBaseline: false,
                            fontSize : '15pt'
                        }
                    },
                    axes: {
                        xaxis: {
                            renderer: $.jqplot.DateAxisRenderer,
                            //tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                            rendererOptions:{
                                tickRenderer:$.jqplot.CanvasAxisTickRenderer
                            },
                            tickOptions: {
                                formatString: "%b %e",
                                angle: -30,
                                textColor: '#dddddd',
                                fontSize : '15pt'
                            },
                            //tickInterval: "30 days",
                            tickInterval:'2 month',
                            drawMajorGridlines: false
                        },
                        yaxis: {
                            renderer: $.jqplot.LogAxisRenderer,
                            pad: 0,
                            rendererOptions: {
                                tickRenderer:$.jqplot.CanvasAxisTickRenderer,
                                minorTicks: 1
                            },
                            tickOptions: {
                                formatString: "%'d",
                                //showMark: false,
                                fontSize : '15pt'
                            }
                        },
                        cursor:{
                            zoom:true,
                            looseZoom: true
                        }
    
                    }
                });
                */

                var showDay = 7;
                var tmpCurrYear = [];
                if (self.currYear.length > showDay) {
                    for (var i = self.currYear.length - showDay; i < self.currYear.length; i++) {
                        tmpCurrYear.push(self.currYear[i]);
                    }
                    //tmpCurrYear = self.setupLastDays(self.tmpCurrYear);
                    self.drawPlot(tmpCurrYear);
                } else {
                    self.drawPlot(self.currYear);
                }

                /*var plot1 = $.jqplot("customEventCounter_chart", [this.currYear], {
                    seriesColors: ["rgba(78, 135, 194, 0.7)", "rgb(211, 235, 59)"],
                    title: { 
                        text : resources.X100233
                    },
                    grid: {
                        background: 'rgba(157,157,57,0.0)',
                        drawBorder: false,
                        shadow: false,
                        gridLineColor: '#666666',
                        
                        gridLineWidth: 2,
                    },
                    legend: {
                        show: true,
                        placement: 'inside',
                        showSwatches : false
                    },
                    axesDefaults: {
                        rendererOptions: {
                            baselineWidth: 1.5,
                            baselineColor: '#444444',
                            drawBaseline: false,
                            fontSize : '15pt'
                        }
                    },
                    axes:{
                        xaxis: {
                            renderer: $.jqplot.DateAxisRenderer,
                            //tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                            rendererOptions:{
                                tickRenderer:$.jqplot.CanvasAxisTickRenderer
                            },
                            tickOptions: {
                                //formatString: "%b %e ",
                                angle: -30,
                                textColor: '#dddddd',
                                fontSize : '15pt'
                            },
                            //tickInterval: "30 days",
                            tickInterval:'2 month',
                            drawMajorGridlines: false
                        },
                        yaxis:{
                            renderer: $.jqplot.LogAxisRenderer,
                            pad: 0,
                            rendererOptions: {
                                tickRenderer:$.jqplot.CanvasAxisTickRenderer,
                                minorTicks: 1
                            },
                            tickOptions: {
                                formatString: "%'d",
                                //showMark: false,
                                fontSize : '15pt'
                            }
                        }
                    },
                    series:[{ 
                        lineWidth:4, 
                        markerOptions:{ style:'square' },
                        fill: false,
                        label: resources.X100235 + self.total
                    }],
                    cursor:{
                        zoom:true,
                        looseZoom: false
                    }
                });*/


                //self.ui.customEventCounter_divEventName.css('font-size', '25px');
                //self.ui.customEventCounter_divEventName.css('color', '#dddddd');
                //self.ui.customEventCounter_chartdiv.css('padding-top','20px');

            },
            error: function (mode, response) {
            }
        });
    },
    drawPlot: function (currYear) {
        var total = 0;
        for (var i = 0; i < currYear.length; i++) {
            total += currYear[i][1];
        }

        this.ui.customEventCounter_chart.empty();
        this.setupZoomBtn();

        var plot1 = $.jqplot("customEventCounter_chart", [currYear], {
            seriesColors: ["rgba(78, 135, 194, 0.7)", "rgb(211, 235, 59)"],
            title: {
                text: resources.X100233
            },
            grid: {
                background: 'rgba(157,157,57,0.0)',
                drawBorder: false,
                shadow: false,
                gridLineColor: '#666666',

                gridLineWidth: 2,
            },
            legend: {
                show: true,
                placement: 'inside',
                showSwatches: false
            },
            axesDefaults: {
                rendererOptions: {
                    baselineWidth: 1.5,
                    baselineColor: '#444444',
                    drawBaseline: false,
                    fontSize: '15pt'
                }
            },
            //axesDefaults: {
            //    tickRenderer: $.jqplot.CanvasAxisTickRenderer ,
            //    tickOptions: {
            //        angle:-90,
            //        fontSize: '15pt',
            //        formatString: resources.X100877
            //    }
            //},
            axes: {
                xaxis: {
                    tickOptions: {
                        formatString: resources.X100877,
                        fontSize: '12pt',
                    },
                    //min:1,
                    //max: 7,
                    //tickInterval: "1 day",
                    //minTickSize: [1, 'day'],
                    //maxTickSize: [1, 'day'],
                    renderer: $.jqplot.DateAxisRenderer,
                    //tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                    //rendererOptions:{
                    //    tickRenderer:$.jqplot.CanvasAxisTickRenderer
                    //},
                    //tickOptions: {
                    //    //formatString: "%b %e ",
                    //    angle: -30,
                    //    //angle: -180,
                    //    textColor: '#dddddd',
                    //    fontSize : '15pt',
                    //    formatString: resources.X100877,
                    //    //mode : "day",
                    //    //tickSize: [1, "day"] ,
                    //    //minTickSize: [5, 'day']
                    //    //tickInterval: "1 day",
                    //    //numberTicks: 7
                    //},
                    //tickInterval: "30 days",
                    //tickInterval:'2 month',
                    drawMajorGridlines: false,
                    //mode : "day",
                    //minTickSize: [5, 'day'],
                    //tickSize: [1, "day"] 
                    //tickInterval: 'day'
                    //minTickInterval: '1 day'
                    //numberTicks: 7,
                    //min: '6/22/2017',
                    //max:   '6/29/2017',
                },
                yaxis: {
                    renderer: $.jqplot.LogAxisRenderer,
                    pad: 0,
                    rendererOptions: {
                        tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                        minorTicks: 1
                    },
                    tickOptions: {
                        formatString: "%'d",
                        //showMark: false,
                        fontSize: '15pt'
                    }
                }
            },
            series: [{
                lineWidth: 4,
                markerOptions: { style: 'square' },
                fill: false,
                label: resources.X100235 + total
            }],
            cursor: {
                zoom: true,
                looseZoom: false
            }
        });

        this.ui.customEventCounter_divEventName.css('font-size', '25px');
        this.ui.customEventCounter_divEventName.css('color', '#dddddd');
        this.ui.customEventCounter_chartdiv.css('padding-top', '20px');
    },
    designRegion_btnOk() {
        this.triggerMethod('dialog:close');

    }
});
