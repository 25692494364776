var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customUserInsert_empty">  <div class="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card" style="padding-left: 0px; padding-right: 0px"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200115'] ))==null?'':__t)+
'</h4> <form class="contact-form" style="margin-bottom:10px"> <div class="control-group customUserInsert_inputName"> <div class="form-group"> <label for="customUserInsert_inputName">'+
((__t=( resources['X200117'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customUserInsert_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserInsert_inputLuxaforId"> <div class="form-group"> <label for="customUserInsert_inputLuxaforId">'+
((__t=( resources['X200285'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customUserInsert_inputLuxaforId" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserInsert_inputCallerId"> <div class="form-group"> <label for="customUserInsert_inputCallerId">'+
((__t=( resources['X200118'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customUserInsert_inputCallerId" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserInsert_inputEmail"> <div class="form-group"> <label for="customUserInsert_inputEmail">'+
((__t=( resources['X200119'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" id="customUserInsert_inputEmail" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserInsert_selectStatus"> <div class="form-group"> <label for="customUserInsert_selectStatus">'+
((__t=( resources['X200257'] ))==null?'':__t)+
'</label> <br> <select name="selValue" class="selectpicker" id="customUserInsert_selectStatus"> <option value="'+
((__t=( model.tmp.Custom_Color_Default))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Custom_Color_Default))==null?'':__t)+
';color:'+
((__t=( model.tmp.Custom_Color_Default))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Custom_Text_Default))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Custom_Text_Default))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Off))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Off))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Off))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Off))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Off))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Busy))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Busy))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Busy))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Busy))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Busy))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Available))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Available))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Available))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Available))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Available))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Blue))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Blue))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Blue))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Blue))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Blue))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Yellow))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Yellow))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Yellow))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Yellow))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Yellow))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Cyan))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Cyan))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Cyan))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Cyan))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Cyan))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_Magenta))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_Magenta))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_Magenta))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_Magenta))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_Magenta))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.SolidColor_Color_CustomColor))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.SolidColor_Color_CustomColor))==null?'':__t)+
';color:'+
((__t=( model.tmp.SolidColor_Color_CustomColor))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.SolidColor_Text_CustomColor))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.SolidColor_Text_CustomColor))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.MSTeams_Color_Online))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_Online))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_Online))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_Online))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_Online))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.MSTeams_Color_Busy))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_Busy))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_Busy))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_Busy))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_Busy))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.MSTeams_Color_DoNotDisturb))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_DoNotDisturb))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_DoNotDisturb))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_DoNotDisturb))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_DoNotDisturb))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.MSTeams_Color_BeRightBack))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_BeRightBack))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_BeRightBack))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_BeRightBack))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_BeRightBack))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.MSTeams_Color_ShowAsNotPresent))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsNotPresent))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsNotPresent))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_ShowAsNotPresent))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_ShowAsNotPresent))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.MSTeams_Color_ShowAsOffline))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsOffline))==null?'':__t)+
';color:'+
((__t=( model.tmp.MSTeams_Color_ShowAsOffline))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.MSTeams_Text_ShowAsOffline))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.MSTeams_Text_ShowAsOffline))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.Mutebutton_Color_On))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Mutebutton_Color_On))==null?'':__t)+
';color:'+
((__t=( model.tmp.Mutebutton_Color_On))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Mutebutton_Text_On))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Mutebutton_Text_On))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.Mutebutton_Color_Off))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Mutebutton_Color_Off))==null?'':__t)+
';color:'+
((__t=( model.tmp.Mutebutton_Color_Off))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Mutebutton_Text_Off))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Mutebutton_Text_Off))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.Pomodoro_Color_Time))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Pomodoro_Color_Time))==null?'':__t)+
';color:'+
((__t=( model.tmp.Pomodoro_Color_Time))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Pomodoro_Text_Time))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Pomodoro_Text_Time))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.Pomodoro_Color_ShortBreak))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Pomodoro_Color_ShortBreak))==null?'':__t)+
';color:'+
((__t=( model.tmp.Pomodoro_Color_ShortBreak))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Pomodoro_Text_ShortBreak))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Pomodoro_Text_ShortBreak))==null?'':__t)+
'</option> <option value="'+
((__t=( model.tmp.Pomodoro_Color_LongBreak))==null?'':__t)+
'" data-content="<span style=&quot;background-color:'+
((__t=( model.tmp.Pomodoro_Color_LongBreak))==null?'':__t)+
';color:'+
((__t=( model.tmp.Pomodoro_Color_LongBreak))==null?'':__t)+
';width:20px;max-width:20px;&quot; class=&quot;badge badge-success&quot;>b</span><span style=&quot;margin-left:10px;&quot; class=&quot;&quot;>'+
((__t=( model.tmp.Pomodoro_Text_LongBreak))==null?'':__t)+
'</span>">'+
((__t=( model.tmp.Pomodoro_Text_LongBreak))==null?'':__t)+
'</option> </select> </div> </div> <div class="control-group customUserInsert_inputEnableUserText"> <div class="form-group"> <div class="control-label col-md-6"> <label for="customUserInsert_checkboxEnableUserText">'+
((__t=( resources['X101487'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customUserInsert_checkboxEnableUserText"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> <div class="control-group customUserInsert_inputUserText"> <div class="form-group"> <label for="customUserInsert_inputUserText">'+
((__t=( resources['X101488'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.UserText))==null?'':__t)+
'" id="customUserInsert_inputUserText" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="control-group customUserInsert_inputEnablePomodoro"> <div class="form-group"> <div class="control-label col-md-6"> <label for="customUserInsert_checkboxEnablePomodoro">'+
((__t=( resources['X101489'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customUserInsert_checkboxEnablePomodoro"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> <div class="control-group customUserInsert_checkboxEnable"> <div class="form-group"> <div class="control-label col-md-6"> <label for="customUserInsert_checkboxEnable">'+
((__t=( resources['X200399'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customUserInsert_checkboxEnable"> <span class="toggle-slider round"></span> </label> </div> <span class="help-inline" style="color:red"></span> </div> </div> </div> <button type="button" class="btn btn-primary btn-sm col-sm-3" id="customUserInsert_btnSubmit">'+
((__t=( resources['X200089'] ))==null?'':__t)+
'</button> </form> </div> </div> </div> <div class="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card" style="padding-left: 0px; padding-right: 0px"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200121'] ))==null?'':__t)+
'</h4> <form class="contact-form"> <div class="row" style="margin-left:0px;margin-top:25px"> <div class="col-md-6" style="padding-left:0px"> <div class="control-group Image" style="display:none"> <div class=""> <label type="button" for="customUserInsert_btnImage" class="btn btn-primary">'+
((__t=( resources['X200062'] ))==null?'':__t)+
'</label> <div id="customUserInsert_div_clone"> <input id="customUserInsert_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> <br> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-md-12"> <input id="customUserInsert_filePond" type="file" class="filepond"> </div> </div> <div class="row"> <div class="row" style="margin-left:0px;margin-right:0px"> <div class="control-label col-md-6" style="padding-left:0px"> <div class="cropper-col cropper-img-container"> <img id="customUserInsert_image" alt="" src="/images/cropper.png"> </div> <div class="cropper-col cropper-img-preview"></div> </div> </div> </div> <div class="row" style="margin-top:50px"> <div class=""> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserInsert_btn_crop_zoom_plus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200063'] ))==null?'':__t)+
'"><i class="fa fa-search-plus"></i></button> <button type="button" id="customUserInsert_btn_crop_zoom_minus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200064'] ))==null?'':__t)+
'"><i class="fa fa-search-minus"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserInsert_btn_crop_move_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200065'] ))==null?'':__t)+
'"><i class="fa fa-arrow-left"></i></button> <button type="button" id="customUserInsert_btn_crop_move_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200066'] ))==null?'':__t)+
'"><i class="fa fa-arrow-right"></i></button> <button type="button" id="customUserInsert_btn_crop_move_top" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200067'] ))==null?'':__t)+
'"><i class="fa fa-arrow-up"></i></button> <button type="button" id="customUserInsert_btn_crop_move_buttom" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200068'] ))==null?'':__t)+
'"><i class="fa fa-arrow-down"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserInsert_btn_crop_rotate_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200069'] ))==null?'':__t)+
'"><i class="fa fa-undo-alt"></i></button> <button type="button" id="customUserInsert_btn_crop_rotate_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200070'] ))==null?'':__t)+
'"><i class="fa fa-redo-alt"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customUserInsert_btn_crop_scale_x" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200071'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-h"></i></button> <button type="button" id="customUserInsert_btn_crop_scale_y" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200072'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-v"></i></button> </div> </div> </div> <br> </form> </div> </div> </div> </div> ';
}
return __p;
};
