﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_waiting from './tpl/waiting.html';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_waiting({ model: this.model.toJSON() });
    },
    initialize: function () {
    },
    ui: {
        waiting_spin: '#waiting_spin'
    },
    onRender: function () {
    }
});
