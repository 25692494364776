﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as nouislider from 'nouislider';

import helper_infopage from '../../../helper/infopage';

import tpl_rssCustom from './tpl/rssCustom.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#rssCustom_modal',
    errors: [],
    getTemplate: function () {
        return tpl_rssCustom({ model: this.model.toJSON() });
    },
    initialize: function () {
        this.app = this.options.app;
    },
    ui: {
        'rssCustom_textarea_feed': '#rssCustom_textarea_feed',
        'rssCustom_input_feedSpeed': '#rssCustom_input_feedSpeed',
        'rssCustom_select_Font_ColorType': '#rssCustom_select_Font_ColorType',
        'rssCustom_ColorPickerSliders_Font_Color': '#rssCustom_ColorPickerSliders_Font_Color',
        'rssCustom_select_Font_FontType': '#rssCustom_select_Font_FontType',
        'rssCustom_dropDown_Font': '#rssCustom_dropDown_Font',
        'rssCustom_formgroup_Font': '#rssCustom_formgroup_Font',
        'rssCustom_dropDown_Font_Size': '#rssCustom_dropDown_Font_Size',
        'rssCustom_dropDown_Font_Style': '#rssCustom_dropDown_Font_Style',
        'module_input_ModuleMargin_Top': '#module_input_ModuleMargin_Top',
        'module_input_ModuleMargin_Bottom': '#module_input_ModuleMargin_Bottom',
        'module_input_ModuleMargin_Left': '#module_input_ModuleMargin_Left',
        'module_input_ModuleMargin_Right': '#module_input_ModuleMargin_Right',
    },
    events: {
        'change @ui.rssCustom_select_Font_FontType': 'change_Font_FontType',
        'change @ui.rssCustom_select_Font_ColorType': 'change_Font_ColorType',
        'colorpickerChange @ui.rssCustom_ColorPickerSliders_Font_Color': 'colorChange_FontColor',
        'click #rssCustom_btn01': 'onBtn01',
        'click #rssCustom_btn02': 'onBtn02'
    },
    onRender: function () {
    },
    onAttach() {
        this.setupFeedSpeed();
        this.setupFontColorType();
        this.setupFontType();
        this.setupFont();
        this.setupFontSize();
        this.setupFontStyle();
    },
    setupFeedSpeed: function () {
        var self = this;

        if ($("#rssCustom_input_feedSpeed").length) {
            var startSlider = document.getElementById('rssCustom_input_feedSpeed');
            nouislider.create(startSlider, {
                start: self.model.attributes.FeedSpeed,
                connect: [true, false],
                range: {
                    min: 50,
                    max: 300
                },
                step: 1
            });
            startSlider.noUiSlider.on('set', function (e) {
                self.model.attributes.FeedSpeed = parseInt(e);
            });
        }
    },
    setupFontColorType: function () {
        var self = this;

        self.ui.rssCustom_select_Font_ColorType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontColorType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font_ColorType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.rssCustom_select_Font_ColorType.append(options);
        });
    },
    setupFontType: function () {
        var self = this;

        self.ui.rssCustom_select_Font_FontType.empty();

        var selected = '';

        self.app.entities.collections.InfoFontType.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font_FontType == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.rssCustom_select_Font_FontType.append(options);
        });

    },
    setupFont: function () {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker({
            color: self.model.attributes.Font_Color,
            format: "rgb"
        });

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font_ColorType)) {
            case true: this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker('colorpicker').disable(); break;
        }

        this.ui.rssCustom_dropDown_Font.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.set({ Font: this.value });
            self.model.set({ Font_Select: this.value });
        });
        this.ui.rssCustom_dropDown_Font.trigger('setFont', self.model.attributes.Font);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font_FontType)) {
            case false: this.ui.rssCustom_formgroup_Font.addClass('disabledFontSelect_FormGroup');
                this.ui.rssCustom_formgroup_Font.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.rssCustom_formgroup_Font.removeClass('disabledFontSelect_FormGroup');
                this.ui.rssCustom_formgroup_Font.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    setupFontSize: function () {
        var self = this;

        this.ui.rssCustom_dropDown_Font_Size.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.rssCustom_dropDown_Font_Size.append(options);
        }
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.rssCustom_dropDown_Font_Style.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font.Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.rssCustom_dropDown_Font_Style.append(options);
        });
    },
    onBtn01: function () {
        var self = this;

        self.setModel();

        var callback = function () {
            self.trigger("dialog:close");
            self.triggerMethod('dialog:btn01clicked', "msg");
        }

        this.validate(callback);
    },
    onBtn02: function () {
        this.triggerMethod('dialog:btn02clicked', "msg");
        this.trigger("dialog:close");
    },
    showErrors: function (errors) {
        _.each(errors, function (error) {
            var controlGroup = this.$('.' + error.name);
            controlGroup.addClass('error');
            controlGroup.find('.help-inline').text(error.message);
        }, this);
    },
    hideErrors: function () {
        this.$('.control-group').removeClass('error');
        this.$('.help-inline').text('');

        this.errors = [];
    },
    validate: function (callback) {
        var self = this;
        this.hideErrors();
        callback();
    },
    change_Font_FontType: function () {
        this.model.set({
            Font_FontType: this.ui.rssCustom_select_Font_FontType.val()
        });

        this.model.set({
            Font: helper_infopage.getSelectedFont(this.app, this.model.attributes.Font_FontType, this.model.attributes.Font_Select)
        });

        this.ui.rssCustom_dropDown_Font.trigger('setFont', this.model.attributes.Font);

        switch (helper_infopage.getSelectedFont_enabled(this.model.attributes.Font_FontType)) {
            case false: this.ui.rssCustom_formgroup_Font.addClass('disabledFontSelect_FormGroup');
                this.ui.rssCustom_formgroup_Font.find('.font-select').addClass('disabledFontSelect_FontSelect');
                break;
            case true: this.ui.rssCustom_formgroup_Font.removeClass('disabledFontSelect_FormGroup');
                this.ui.rssCustom_formgroup_Font.find('.font-select').removeClass('disabledFontSelect_FontSelect');
                break;
        }
    },
    change_Font_ColorType: function () {
        this.model.set({
            Font_ColorType: this.ui.rssCustom_select_Font_ColorType.val()
        });

        this.model.set({
            Font_Color: helper_infopage.getSelectedColor(this.app, this.model.attributes.Font_ColorType, this.model.attributes.Font_ColorSelect)
        });

        this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker('colorpicker').enable();
        this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker('colorpicker').setValue(this.model.attributes.Font_Color);

        switch (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font_ColorType)) {
            case true: this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker('colorpicker').enable(); break;
            case false: this.ui.rssCustom_ColorPickerSliders_Font_Color.colorpicker('colorpicker').disable(); break;
        }
    },
    colorChange_FontColor: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font_Color = e.value;
        }
        else {
            this.model.attributes.Font_Color = e.value.toRgbString();
        }

        if (helper_infopage.getSelectedColor_enabled(this.model.attributes.Font_ColorType)) {
            this.model.attributes.Font_ColorSelect = this.model.attributes.Font_Color;
        }
    },
    setModel: function () {
        this.model.set({
            Feed: this.ui.rssCustom_textarea_feed.val(),
            Font_ColorType: this.ui.rssCustom_select_Font_ColorType.val(),
            Font_FontType: this.ui.rssCustom_select_Font_FontType.val(),
            Font_Font: this.ui.rssCustom_dropDown_Font.val(),
            Font_Size: this.ui.rssCustom_dropDown_Font_Size.val(),
            Font_Style: this.ui.rssCustom_dropDown_Font_Style.val(),
            ModuleMargin_Top: this.ui.module_input_ModuleMargin_Top.val(),
            ModuleMargin_Bottom: this.ui.module_input_ModuleMargin_Bottom.val(),
            ModuleMargin_Left: this.ui.module_input_ModuleMargin_Left.val(),
            ModuleMargin_Right: this.ui.module_input_ModuleMargin_Right.val()
        });
    }
});