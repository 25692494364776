var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=""> <div id="moduleListListItem_div" style="min-width:200px; min-height:100px;max-width:200px; max-height:100px;margin-top:5px;margin-right:5px; border-style:solid; border-color:rgb(233, 233, 233); border-width:1px" class="d-flex align-items-center pb-3"> <i style="color:rgb(108, 114, 147); margin-left:5px" class="'+
((__t=( model.Fa ))==null?'':__t)+
'" data-toggle="tooltip" data-custom-class="tooltip-info" data-placement="right" container="body" title="'+
((__t=( resources['X101312'] ))==null?'':__t)+
'"></i> <div class="ml-3"> <h6 style="margin-top:5px" class="mb-1">'+
((__t=( model.Name ))==null?'':__t)+
'</h6> <small class="text-muted mb-0"><i class="mdi mdi-information mr-1"></i>'+
((__t=( model.Description ))==null?'':__t)+
'</small> </div> </div> </div>';
}
return __p;
};
