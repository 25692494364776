﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';
import PageableCollection from 'backbone.paginator';

import config from '../config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    },
    initialize: function () {
    },
    "sync": function (method, model, options) {
        if ((method == 'read')  && (options.filename)) {
            options.url = options.filename;
        }
        else if (method == 'read') {
            //options.url = config.webApiUrl() + '/mainjs/ImportFileLanguage/dummy/dummy/dummy/';
            //options.url = config.webApiUrl() + window.luxaforboard.config.languageJson;
            options.url =  config.moduleJson();
        }
/*        else if  (method == 'delete') {
            options.url = config.webApiUrl() + '/api/customImage/' + '' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }*/
        return Backbone.sync(method, model, options);
    },
    parse:function(response, options) {
        return options.parseField ? response[options.parseField] : response;
    }
});

