﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tinycolor from 'tinycolor';

import tpl_eventImagePrintTemplate from '../tplRender/eventImagePrintTemplate.html';
import tpl_eventTemplate from '../tplRender/eventTemplate.html';
import tpl_eventSchemaTemplate from '../tplRender/eventSchemaTemplate.html';
import tpl_eventProductSheetTemplate from '../tplRender/eventProductSheetTemplate.html';
import tpl_eventSchemaBigscreenTemplate from '../tplRender/eventSchemaBigscreenTemplate.html';
import tpl_eventFormTemplate from '../tplRender/eventFormTemplate.html';
import tpl_eventInfoTemplate from '../tplRender/eventInfoTemplate.html';
import tpl_eventPosterTemplate from '../tplRender/eventPosterTemplate.html';
import tpl_eventQRTemplate from '../tplRender/eventQRTemplate.html';
import tpl_eventLocationTemplate from '../tplRender/eventLocationTemplate.html';
import tpl_eventCalendarTemplate from '../tplRender/eventCalendarTemplate.html';

import tpl_eventIndexTemplate from '../tplRender/eventIndexTemplate.html';
import tpl_eventIndexImagePrintTemplate from '../tplRender/eventIndexImagePrintTemplate.html';

import * as Entities_Event from '../entities/event';
import * as Entities_Empty from '../../lib/entities/empty';
import * as Entities_AzureEvent from '../entities/azureEvent';
import * as Entities_Page2Image from '../entities/page2image';
import * as Entities_QRCode from '../entities/qrcode';

import * as Entities_Header from '../../event/entities/header';


import config from '../../common/config';
import renderError from './renderError';

var HelperCreateWebpage = {
    setting : { webSnapShotTimerCount : 30 },
    createWebpage: function (processModel, id, view, customEventRender_container, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, customEventRender_iframeHide, customEventRender_iframe, callback, callback_id,/* createQRCode, createWebSnapShot*/renderModel, app) {

        var retry = 3;

        var self = this;
        var customEvent = app.entities.collections.customEvent.fullCollection.where({ id: id });
        var tmpSchemaModel = { id : -1, FontSizeSchema : -1, MarginLeftSchema : 1, MarginRightSchema : 1, FontSizeSchemaBigscreen : -1, MarginLeftSchemaBigscreen : 1, MarginRightSchemaBigscreen : 1, FieldSchemaBigscreen : 1 };
        if (customEvent[0].attributes.SchemaModel != -1) {
            var schemaModel = app.entities.collections.customSchemaModel.fullCollection.where({ id: customEvent[0].attributes.SchemaModel });
            tmpSchemaModel = { id : schemaModel[0].attributes.id, 
                FontSizeSchema : schemaModel[0].attributes.FontSizeSchema, 
                MarginLeftSchema : schemaModel[0].attributes.MarginLeftSchema, 
                MarginRightSchema : schemaModel[0].attributes.MarginRightSchema, 
                FontSizeSchemaBigscreen : schemaModel[0].attributes.FontSizeSchemaBigscreen, 
                MarginLeftSchemaBigscreen : schemaModel[0].attributes.MarginLeftSchemaBigscreen, 
                MarginRightSchemaBigscreen : schemaModel[0].attributes.MarginRightSchemaBigscreen, 
                FieldBigscreen  : schemaModel[0].attributes.FieldBigscreen};
        }

        var tmpProductSheetModel = { id : -1, FontSizeSchema : -1};
        if (customEvent[0].attributes.ProductSheetModel != -1) {
            var productSheetModel = app.entities.collections.customProductSheetModel.fullCollection.where({ id: customEvent[0].attributes.ProductSheetModel });
            tmpProductSheetModel = { id : productSheetModel[0].attributes.id, FontSizeProductSheet : '15px'  };
        }

        var QRCodeImageUrl = '';
        var WebpageImageUrl = '';

        var collectionHeaderWebpage = 'headerWebpage' + '.json';
        var collectionHeaderInfopage  = 'headerInfopage.json';
        var collectionText  = 'text.json';
        var QRCodeFilename = 'qrcode_' + new Date().getTime() + '.jpg';
        var WebpageFilename = 'imagePrint_' + new Date().getTime() + '.jpg';

        var templatePrefix = new Date().getTime();

        var tmpCallback_id = 0;
        
        if (renderModel.attributes.updateQRCodeImageUrl) {
            QRCodeImageUrl = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/image/' + QRCodeFilename;
        }
        else {
            QRCodeImageUrl = customEvent[0].attributes.QRCodeImageUrl;
        }
        
        if (renderModel.attributes.updateWebpageImageUrl) {
            WebpageImageUrl = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/image/' + WebpageFilename;
        }
        else {
            WebpageImageUrl = customEvent[0].attributes.WebpageImageUrl;
        }

        customEvent[0].attributes.QRCodeImageUrl = QRCodeImageUrl;
        customEvent[0].attributes.WebpageImageUrl = WebpageImageUrl;

        var buildCompletedCallback = function () {
            processModel.attributes.progress_count_2++;
            view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
            callback(callback_id);
        }

        var checksumEventImageFile = function (timer) {

            if (timer)
            {
                clearTimeout(timer);
            }
            if (renderModel.attributes.updateEventImageFile) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100459 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildImageFilesJson(customEvent, 'image.json',  buildCompletedCallback, retry, 0);
            }
            else {
                buildCompletedCallback();
            }
        }

        var checksumWebpageImageUrl = function () {
            if (renderModel.attributes.updateWebpageImageUrl) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100432 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildImagePrint(customEvent, WebpageFilename, processModel, view, app, checksumEventImageFile, retry, 0);
            }
            else {
                checksumEventImageFile(1,0,false);
            }
        }

        var checksumWebpageImageIndex = function () {
            if (renderModel.attributes.updateWebpageImageIndex) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100458 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildIndex(true, customEvent, tmpSchemaModel,tmpProductSheetModel, collectionHeaderWebpage, collectionHeaderInfopage, collectionFilesUsed, app, checksumWebpageImageUrl, retry, 0);    
            }
            else {
                checksumWebpageImageUrl(1,0,false);
            }
        }

        var checksumQRCodeImageUrl = function () {
            if (renderModel.attributes.updateQRCodeImageUrl) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100431 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildQRCode(customEvent, QRCodeFilename, processModel, view, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, app, checksumWebpageImageIndex, retry, 0);
            }
            else {
                checksumWebpageImageIndex();
            }
        }

        var checksumEventTextFileJson = function () {
            if (renderModel.attributes.updateEventTextFileJson) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100523 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildTextFilesJson(customEvent, collectionText, checksumQRCodeImageUrl, retry, 0);
            }
            else {
                checksumQRCodeImageUrl();
            }
            
        }

        var checksumEventIndex = function (tmpCallback_id, count, update) {
            /*if (update) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
            }

            if (tmpCallback_id >= count){
                if (renderModel.attributes.updateEventIndex) {
                    processModel.attributes.progress_count_2++;
                    view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                    var Text_2 = String.format(resources.X100429 + resources.X100436, customEvent[0].attributes.Name);
                    view.changeText_2(Text_2);

                    self.buildIndex(false, customEvent, tmpSchemaModel,tmpProductSheetModel, collectionHeaderWebpage, collectionHeaderInfopage, collectionFilesUsed, app, checksumEventTextFileJson, retry, 0);
                }
                else {
                    checksumEventTextFileJson();
                }
            }*/
            if (renderModel.attributes.updateEventIndex) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429 + resources.X100436, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildIndex(false, customEvent, tmpSchemaModel,tmpProductSheetModel, collectionHeaderWebpage, collectionHeaderInfopage, collectionFilesUsed, app, checksumEventTextFileJson, retry, 0);
            }
            else {
                checksumEventTextFileJson();
            }
        }

        

        var checksumEventInfoTemplate = function () {
            if (renderModel.attributes.updateEventInfoTemplate) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100455 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildTemplate(customEvent, app.entities.collections.headerInfopage, true, tmpSchemaModel,tmpProductSheetModel, QRCodeImageUrl, WebpageImageUrl, templatePrefix, view, app, processModel, checksumEventIndex, retry, 0);
            } else {
                checksumEventIndex(1,0, false);
            }
        }

        var checksumEventInfoHeader = function () {
            if (renderModel.attributes.updateEventInfoHeader) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100457 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildHeaderInfoJson(customEvent, collectionHeaderInfopage, templatePrefix, checksumEventInfoTemplate, retry, 0);
            }
            else {
                checksumEventInfoTemplate();
            }
        }

        var checksumEventTemplate = function () {
            if (renderModel.attributes.updateEventTemplate) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100430 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildTemplate(customEvent, app.entities.collections.headerWebpage, false, tmpSchemaModel,tmpProductSheetModel, QRCodeImageUrl, WebpageImageUrl, templatePrefix, view, app, processModel, checksumEventInfoHeader, retry, 0);
            }else{
                checksumEventInfoHeader(1,0, false);
            }

        }

        var checksumEventHeader = function () {
            if (renderModel.attributes.updateEventHeader) {
                processModel.attributes.progress_count_2++;
                view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
                var Text_2 = String.format(resources.X100429+ resources.X100456 + resources.X100434, customEvent[0].attributes.Name);
                view.changeText_2(Text_2);

                self.buildHeaderJson(customEvent, collectionHeaderWebpage, templatePrefix, checksumEventTemplate, retry, 0);
            } else{
                checksumEventTemplate();
            }
        }

        var bust = (new Date()).getTime();
        var collectionFilesUsed  = new Entities_Empty.Collection();
        collectionFilesUsed.fetch({
            silent: true,
            wait:true,
            dataType:"json",
            skipAuthorization : true,
            //contentType:"text/json",
            url : config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/file/filesUsed.json' + "?bust=" + bust,
            success: function (mode, response) {
                //processModel.attributes.progress_count_2++;
                checksumEventHeader();
            }
        });
    },
    getMenuItem : function (item, path, templatePrefix, customEvent, firstMenuItem, displayType, generateTemplate, headerBody, footerBody, headerStatus, footerStatus) {
        var menuItem = new Entities_Empty.Model();
        menuItem.attributes.enable = false;

        if (item.attributes.Tag != 0 && item.attributes.Tag != 2) {
            menuItem.attributes.name = item.attributes.Name;

            menuItem.attributes.default = false;
            if (item.attributes.Position == firstMenuItem) {
                menuItem.attributes.default = true;
            }

            var navigationTrigger = ''; 
            var enable = true;
            var enableMenu = false;
            var enableAdvertising = true; 
            var css = '';
            var swipe = true;

            var navigationName = '';

            switch (displayType) {
                case 0 : 
                    if (item.attributes.Tag == 1) {
                        navigationName = resources.X100528.toLowerCase() + item.attributes.Position;
                    } else {
                        navigationName = menuItem.attributes.name.toLowerCase();
                    }
                    break;
                case 1 : 
                    navigationName = menuItem.attributes.name.toLowerCase();
                    break;
            }

            switch (item.attributes.Tag) {
                case 0,2 : break;
                case 1 : navigationTrigger = 'page'; enableMenu = true; enableAdvertising = true; break;
                case 3 : navigationTrigger = 'schemaBigscreen'; enableMenu = false; enableAdvertising = false; 
                    enable = app.entities.models.configSubscription[0].attributes.EnableSchemaModel;
                    if (customEvent[0].attributes.SchemaModel == -1) {
                        enable = false;
                    }
                    swipe = false;
                    navigationName = resources.X100419.toLowerCase();
                    break;
                case 4 : navigationTrigger = 'poster'; enableMenu = false; enableAdvertising = false; 
                    enable = app.entities.models.configSubscription[0].attributes.EnablePoster;
                    swipe = false;
                    break;
                case 5 : navigationTrigger = 'schema'; enableMenu = true; enableAdvertising = true; 
                    enable = app.entities.models.configSubscription[0].attributes.EnableSchemaModel;
                    if (customEvent[0].attributes.Module_SchemaModel == false) {
                    //if (customEvent[0].attributes.SchemaModel == -1) {
                        enable = false;
                    }

                    if (customEvent[0].attributes.SchemaMenuName && customEvent[0].attributes.SchemaMenuName.length > 0) {
                        menuItem.attributes.name = customEvent[0].attributes.SchemaMenuName;
                    }
                    break;
                case 6 : navigationTrigger = 'page'; enableMenu = false; enableAdvertising = false; 
                    swipe = false;
                    break;
                case 7 : navigationTrigger = 'form'; enableMenu = true; enableAdvertising = true; 
                    enable = app.entities.models.configSubscription[0].attributes.EnableFormModel;
                    if (customEvent[0].attributes.Module_FormModel == false) {
                    //if (customEvent[0].attributes.FormModel == -1) {
                        enable = false;
                    }

                    if (customEvent[0].attributes.FormMenuName && customEvent[0].attributes.FormMenuName.length > 0) {
                        menuItem.attributes.name = customEvent[0].attributes.FormMenuName;
                    }
                    break;
                case 8 : navigationTrigger = 'productSheet'; enableMenu = true; enableAdvertising = true; 
                    enable = app.entities.models.configSubscription[0].attributes.EnableProductSheetModel;
                    if (customEvent[0].attributes.Module_ProductSheetModel == false) {
                    //if (customEvent[0].attributes.ProductSheetModel == -1) {
                        enable = false;
                    }

                    if (customEvent[0].attributes.ProductSheetMenuName && customEvent[0].attributes.ProductSheetMenuName.length > 0) {
                        menuItem.attributes.name = customEvent[0].attributes.ProductSheetMenuName;
                    }
                    break;
                case 9 : navigationTrigger = 'info'; enableMenu = false; enableAdvertising = false; 
                    enable = app.entities.models.configSubscription[0].attributes.EnableInfo;
                    swipe = false;
                    break;
                case 10: navigationTrigger = 'page'; enableMenu = true; 
                    break;

                case 11: navigationTrigger = 'qr'; enableAdvertising = true;
                    enableMenu = false; 
                    enable = true;
                    swipe = false;
                    break;
                case 12: navigationTrigger = 'location'; enableAdvertising = true;
                    enableMenu = false; //customEvent[0].attributes.Publicly;
                    enable = true;
                    swipe = false;
                    break;
                case 13: navigationTrigger = 'calendar'; enableAdvertising = true;
                    enableMenu = false; //customEvent[0].attributes.Publicly;
                    enable = true;
                    swipe = false;
                    break;
            }

            menuItem.attributes.parent = item.attributes.Parent;
            menuItem.attributes.dropdown = item.attributes.Dropdown;

            menuItem.attributes.navigationTrigger = navigationTrigger;
            menuItem.attributes.url = navigationTrigger;
            menuItem.attributes.navigationName = navigationName;
            
            menuItem.attributes.enableMenu = enableMenu;
            menuItem.attributes.enableAdvertising = enableAdvertising;
            menuItem.attributes.duration = item.attributes.Duration;
            menuItem.attributes.position = item.attributes.Position;
            menuItem.attributes.css = item.attributes.Css;
            menuItem.attributes.enable = enable;
            menuItem.attributes.status = item.attributes.Status;
            menuItem.attributes.paintstrapCss = '';
            menuItem.attributes.swipe = swipe;
            menuItem.attributes.lastItem = false;
            //menuItem.attributes.id = item.attributes.id;
            menuItem.attributes.menuitemId = item.attributes.id;
            menuItem.attributes.checksum = this.calcmd5(item.attributes.id, item.attributes.Body, headerBody, footerBody, item.attributes.Tag, headerStatus, footerStatus);
            menuItem.attributes.generateTemplate = generateTemplate;

            //menuItem.attributes.template = path + item.attributes.Name + '_' + templatePrefix + '.html';
            menuItem.attributes.template = path + /*item.attributes.Name + '_' + */menuItem.attributes.checksum + '.html';
        }

        return menuItem;
    },
    calcmd5 : function (id, body, headerBody, footerBody, tag, headerStatus, footerStatus) {
        var checksumBody = body;

        switch (tag) {
            case 1 :
            case 4 :
            case 5 :
            case 7 : 
            case 8:
            case 10:
            case 11:
            case 12: checksumBody = body + headerBody + footerBody + headerStatus + footerStatus; break;
        }

        var md5 = require('js-md5');
        return md5(checksumBody + id);
    },
    generateTemplate : function (tmpCollection, id, body, headerBody, footerBody, tag, headerStatus, footerStatus) {
        var generateTemplate = true;

        var tmpModel = tmpCollection.where({ menuitemId: id });
        if (tmpModel.length > 0) {
            if (tmpModel[0].attributes.checksum == this.calcmd5(id, body, headerBody, footerBody, tag, headerStatus, footerStatus)) {
                generateTemplate = false;
            }
        }
        return generateTemplate;
    },
    buildHeaderJson : function (customEvent,collectionHeaderWebpage, templatePrefix, callback, retry, buildRetry) {
        var self = this;
        var customWebpageItem_header = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage, Tag: 0 });
        var customWebpageItem_footer = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage, Tag: 2 });

        var callbackLoadHeaderWebpage = function() {
            app.entities.collections.customWebpageItem.sort();
            var customWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage });

            var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/template/'; 
            var menuItem = new Entities_Empty.Model();
            //var collection = new Entities_Empty.Collection();
            var collection = new Entities_Header.Collection();
            var id = 0;


            //find lowest webpage
            var lowestPosition = 2147483647;
            customWebpageItem.forEach(function (model) {
                if (model.attributes.Position != 0) {
                    if (model.attributes.Position < lowestPosition) {
                        lowestPosition = model.attributes.Position;
                    }
                }
            });

        

            //for (var menu = 0; menu < customWebpageItem.length; menu++) {
            for (var menu = 0; menu < app.entities.collections.customWebpageItem.length; menu++) {
                //var item = customWebpageItem[menu];
                var item = app.entities.collections.customWebpageItem.models[menu];

                if (item.attributes.Webpage == customEvent[0].attributes.Webpage) {
                    var generateTemplate = self.generateTemplate(app.entities.collections.headerWebpage, item.attributes.id, item.attributes.Body, customWebpageItem_header[0].attributes.Body, customWebpageItem_footer[0].attributes.Body, item.attributes.Tag, customWebpageItem_header[0].attributes.Status, customWebpageItem_footer[0].attributes.Status);

                    var menuItem = self.getMenuItem(item, path, templatePrefix, customEvent, lowestPosition, 0, generateTemplate, customWebpageItem_header[0].attributes.Body, customWebpageItem_footer[0].attributes.Body, customWebpageItem_header[0].attributes.Status, customWebpageItem_footer[0].attributes.Status);

                    if (menuItem.attributes.enable) {
                        menuItem.attributes.id = id;
                        collection.add(menuItem);
                        id++;
                    }
                }
            }

            var menuItem = new Entities_Empty.Model();
            if (customEvent[0].attributes.Infopage != -1 && app.entities.models.configSubscription[0].attributes.EnableInfo){

                var webpageModel = app.entities.collections.customInfopage.where({ id: customEvent[0].attributes.Infopage });
                var paintStrapModel = app.entities.collections.paintStrap.fullCollection.where( { id : webpageModel[0].attributes.Theme});

                menuItem.attributes.parent = -1;
                menuItem.attributes.dropdown = false;
                menuItem.attributes.default = false;
                menuItem.attributes.navigationTrigger = 'info'; 
                menuItem.attributes.url = menuItem.attributes.navigationTrigger;
                menuItem.attributes.navigationName = resources.X100454.toLowerCase();
                menuItem.attributes.template = '';
                menuItem.attributes.enableMenu = false; 
                menuItem.attributes.enableAdvertising = false;
                menuItem.attributes.template = '';
                menuItem.attributes.duration = 0;
                menuItem.attributes.css = '';
                menuItem.attributes.status = 1;
                menuItem.attributes.swipe = false;
                menuItem.attributes.lastItem = false;
                menuItem.attributes.paintstrapCss = paintStrapModel[0].attributes.CssFile;
                menuItem.attributes.id = id;
                menuItem.attributes.menuitemId = -1;
                menuItem.attributes.checksum = '';
                menuItem.attributes.generateTemplate = false;

                collection.add(menuItem);
                id++;
            }
           
            if (customEvent[0].attributes.HelpBtn) {
                var menuItem = new Entities_Empty.Model();

                menuItem.attributes.parent = -1;
                menuItem.attributes.dropdown = false;
                menuItem.attributes.name = resources['X100077'];
                menuItem.attributes.navigationTrigger = 'external';
                menuItem.attributes.url = 'external';
                menuItem.attributes.navigationName = resources['X100077'].toLowerCase() ;
                menuItem.attributes.template = '';
                menuItem.attributes.enableMenu = false;//true;
                menuItem.attributes.enableAdvertising = false;
                menuItem.attributes.duration = 0;
                menuItem.attributes.css = '';
                menuItem.attributes.status = 1;
                menuItem.attributes.paintstrapCss = '';
                menuItem.attributes.swipe = false;
                menuItem.attributes.lastItem = false;
                menuItem.attributes.id = id;
                menuItem.attributes.menuitemId = -1;
                menuItem.attributes.checksum = '';
                menuItem.attributes.generateTemplate = false;

                collection.add(menuItem);
                id++;
            }

            if (customEvent[0].attributes.HomeBtn) {
                var menuItem = new Entities_Empty.Model();

                menuItem.attributes.parent = -1;
                menuItem.attributes.dropdown = false;
                menuItem.attributes.name = resources['X100078'] + resources['X100424'];
                menuItem.attributes.navigationTrigger = 'external';
                menuItem.attributes.url = 'external';
                menuItem.attributes.navigationName = resources['X100078'] + resources['X100424'].toLowerCase() ;
                menuItem.attributes.template = '';
                menuItem.attributes.enableMenu = true;
                menuItem.attributes.enableAdvertising = false;
                menuItem.attributes.duration = 0;
                menuItem.attributes.css = '';
                menuItem.attributes.status = 1;
                menuItem.attributes.paintstrapCss = '';
                menuItem.attributes.swipe = false;
                menuItem.attributes.lastItem = false;
                menuItem.attributes.id = id;
                menuItem.attributes.menuitemId = -1;
                menuItem.attributes.checksum = '';
                menuItem.attributes.generateTemplate = false;

                collection.add(menuItem);
                id++;
            }

            //Make a dummy menu item that reparsent id off webpage.
            var menuItem = new Entities_Empty.Model();

            menuItem.attributes.parent = -1;
            menuItem.attributes.dropdown = false;
            menuItem.attributes.name = customEvent[0].attributes.Webpage;
            menuItem.attributes.navigationTrigger = 'Webpage';
            menuItem.attributes.url = 'external';
            menuItem.attributes.navigationName = '';
            menuItem.attributes.template = '';
            menuItem.attributes.enableMenu = false;
            menuItem.attributes.enableAdvertising = false;
            menuItem.attributes.duration = 0;
            menuItem.attributes.css = '';
            menuItem.attributes.status = 1;
            menuItem.attributes.paintstrapCss = '';
            menuItem.attributes.swipe = false;
            menuItem.attributes.lastItem = false;
            menuItem.attributes.id = id;
            menuItem.attributes.menuitemId = -1;
            menuItem.attributes.checksum = '';
            menuItem.attributes.generateTemplate = false;

            collection.add(menuItem);
            id++;

            var lastItem = 0;
            collection.forEach(function (entry) {
                if (entry.attributes.enableMenu) {
                    if (entry.attributes.id > lastItem) {
                        lastItem = entry.attributes.id;
                    }
                }
            });

            var entry = collection.where({ id : lastItem});
            if (entry.length > 0) {
                entry[0].attributes.lastItem = true;
            }

            var json = JSON.stringify(collection.toJSON());

            var eventAzureModel = new Entities_AzureEvent.Model();
            eventAzureModel.attributes.AuthUserId = config.authUserId();
            eventAzureModel.attributes.EventId = customEvent[0].id;
            eventAzureModel.attributes.Filename = collectionHeaderWebpage;
            eventAzureModel.attributes.MimeType = 'application/json';
        
            eventAzureModel.attributes.Data = json;
            eventAzureModel.attributes.Directory = 'Directory_file';
            eventAzureModel.attributes.DeleteDirectory = false; //true before
            eventAzureModel.attributes.OnlyDeleteDirectory = false;
            eventAzureModel.attributes.DeleteNoneExitFiles = false;
            eventAzureModel.attributes.DeleteWildcards = 'headerWebpage';

            eventAzureModel.save(eventAzureModel.attributes, {
                wait: true,
                silent: true,
                success: function () {
                    app.entities.collections.headerWebpage.reset();

                    collection.forEach(function (entry) {
                        app.entities.collections.headerWebpage.add(entry);
                    });

                    callback();
                }, 
                error: function () {
                    buildRetry++;
                    if (buildRetry < retry) {
                        self.buildHeaderJson(customEvent,collectionHeaderWebpage, templatePrefix, callback, retry, buildRetry);
                    }
                }
            });
        }

        //var loadHeaderWebpage = true;
        var loadHeaderWebpage = false;
        if (app.entities.collections.headerWebpage.length > 0) {
            var tmpModel =  app.entities.collections.headerWebpage.where({ name : customEvent[0].attributes.Webpage, navigationTrigger : 'Webpage' });
            if (tmpModel.length == 0) {
                app.entities.collections.headerWebpage.reset();
                loadHeaderWebpage = true;
            }
        } else {
            loadHeaderWebpage = true;
        }

        if (loadHeaderWebpage) {
            app.entities.collections.headerWebpage.fetch({
                silent: true,
                wait:true,
                skipAuthorization:true,
                url : config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/file/headerWebpage.json' + '?bust=' + new Date().getTime(),
                success: function (mode, response) {
                    callbackLoadHeaderWebpage();
                },
                error: function (mode, response) {
                    callbackLoadHeaderWebpage();
                }
            });
        } else {
            callbackLoadHeaderWebpage();
        }
    },
    buildHeaderInfoJson : function (customEvent,collectionHeaderInfopage, templatePrefix, callback, retry, buildRetry) {
        var self = this;

        var callbackLoadHeaderInfopage = function() {
            app.entities.collections.customWebpageItem.sort();
            var customWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Infopage });

            var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/templateInfo/'; 
            var menuItem = new Entities_Empty.Model();
            var collection = new Entities_Empty.Collection();
            var id = 0;

            //Add advertising to infoscreen
            if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
                if (app.entities.collections.customWebpageItem.length > 0) {
                    var item = app.entities.collections.customWebpageItem.where( { Webpage : customEvent[0].attributes.Infopage} );

                    var body = app.entities.collections.configSetting.where({ CultureName : config.cultureName(), ItemId : 'InfoScreenEnableAdvertisingHtml'});
                    var duration = app.entities.collections.configSetting.where({ CultureName : config.cultureName(), ItemId : 'InfoScreenEnableAdvertisingDuration'});
                    var position = app.entities.collections.configSetting.where({ CultureName : config.cultureName(), ItemId : 'InfoScreenEnableAdvertisingPosition'});

                    if ((body.length > 0) &&  (duration.length > 0)) {
                        var advertisingModel = new app.entities.collections.customWebpageItem.model();
                        advertisingModel.attributes.AuthUserId = customEvent[0].attributes.AuthUserId;
                        advertisingModel.attributes.Webpage = customEvent[0].attributes.Infopage;
                        advertisingModel.attributes.Name = resources.X100835;
                        advertisingModel.attributes.Tag = 9;
                        advertisingModel.attributes.Position = parseInt(position[0].attributes.Value);
                        advertisingModel.attributes.Body = '';//body[0].attributes.Value;
                        advertisingModel.attributes.Duration = parseInt(duration[0].attributes.Value);
                        advertisingModel.attributes.Css = '';
                        advertisingModel.attributes.Status = 1;
                        advertisingModel.attributes.menuitemId = -1;
                        advertisingModel.attributes.id = -1;
                        advertisingModel.attributes.checksum = '';//self.calcmd5(advertisingModel.attributes.id, advertisingModel.attributes.Body, '', '', advertisingModel.attributes.Tag);
                        advertisingModel.attributes.generateTemplate = false;

                        //advertisingModel.attributes.menuitemId = -1;
                        var menuItem = self.getMenuItem(advertisingModel, path, templatePrefix, customEvent, 0, 1, true, '' , '', 0, 0);
                        menuItem.attributes.checksum = '';
                        menuItem.attributes.generateTemplate = false;
                        menuItem.attributes.Position = parseInt(position[0].attributes.Value);
                        
                        collection.add(menuItem);
                    }
                }
            }

            //for (var menu = 0; menu < customWebpageItem.length; menu++) {
            for (var menu = 0; menu < app.entities.collections.customWebpageItem.length; menu++) {
                //var item = customWebpageItem[menu];
                var item = app.entities.collections.customWebpageItem.models[menu];

                if (item.attributes.Webpage == customEvent[0].attributes.Infopage) {

                    var generateTemplate = self.generateTemplate(app.entities.collections.headerInfopage, item.attributes.id, item.attributes.Body, '', '', item.attributes.Tag,  0, 0);

                    //var menuItem = self.getMenuItem(item, path, templatePrefix, customEvent, lowestPosition, 0, generateTemplate, customWebpageItem_header[0].attributes.Body, customWebpageItem_footer[0].attributes.Body);

                    var menuItem = self.getMenuItem(item, path, templatePrefix, customEvent, 0, 1, generateTemplate, '', '', 0, 0);

                    if (menuItem.attributes.enable) {
                        menuItem.attributes.id = id;
                        collection.add(menuItem);

                        id++;
                    }
                }
            }

            var json = JSON.stringify(collection.toJSON());

            var eventAzureModel = new Entities_AzureEvent.Model();
            eventAzureModel.attributes.AuthUserId = config.authUserId();
            eventAzureModel.attributes.EventId = customEvent[0].id;
            eventAzureModel.attributes.Filename = collectionHeaderInfopage;
            eventAzureModel.attributes.MimeType = 'application/json';
        
            eventAzureModel.attributes.Data = json;
            eventAzureModel.attributes.Directory = 'Directory_file';
            eventAzureModel.attributes.DeleteDirectory = false; //true before
            eventAzureModel.attributes.OnlyDeleteDirectory = false;
            eventAzureModel.attributes.DeleteNoneExitFiles = false;
            eventAzureModel.attributes.DeleteWildcards = 'headerInfopage';
        
            eventAzureModel.save(eventAzureModel.attributes, {
                wait: true,
                silent: true,
                success: function () {
                    app.entities.collections.headerInfopage.reset();

                    collection.forEach(function (entry) {
                        app.entities.collections.headerInfopage.add(entry);
                    });

                    callback();
                },
                error: function () {
                    buildRetry++;
                    if (buildRetry < retry) {
                        self.buildHeaderInfoJson(customEvent,collectionHeaderInfopage, templatePrefix, callback, retry, buildRetry);
                    }
                }
            });
        }

        //var loadHeaderWebpageInfo = true;
        var loadHeaderInfopage = false;
        if (app.entities.collections.headerInfopage.length > 0) {
            var tmpModel =  app.entities.collections.headerInfopage.where({ name : customEvent[0].attributes.Webpage, navigationTrigger : 'Webpage' });
            if (tmpModel.length == 0) {
                app.entities.collections.headerInfopage.reset();
                loadHeaderInfopage = true;
            }
        } else {
            loadHeaderInfopage = true;
        }

        if (loadHeaderInfopage) {
            app.entities.collections.headerInfopage.fetch({
                silent: true,
                wait:true,
                skipAuthorization:true,
                url : config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/file/headerInfopage.json' + '?bust=' + new Date().getTime(),
                success: function (mode, response) {
                    callbackLoadHeaderInfopage();
                },
                error: function (mode, response) {
                    callbackLoadHeaderInfopage();
                }
            });
        } else {
            callbackLoadHeaderInfopage();
        }
    },
    /*buildFilesUsedJson : function (customEvent,filename, callback, retry, buildRetry) {
        var self = this;

        var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/file/'; 
        var collection = new Entities_Empty.Collection();

        var item = new Entities_Empty.Model();

        var fileModel = app.entities.collections.file.where({ Name : 'event_page_style' });
        item.attributes.name = 'event_page_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_schema_style' });
        item.attributes.name = 'event_schema_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_productSheet_style' });
        item.attributes.name = 'event_productSheet_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_schemaBigscreen_style' });
        item.attributes.name = 'event_schemaBigscreen_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_form_style' });
        item.attributes.name = 'event_form_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_info_style' });
        item.attributes.name = 'event_info_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_poster_style' });
        item.attributes.name = 'event_poster_style';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'main_vendor' });
        item.attributes.name = 'main_vendor';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        fileModel = app.entities.collections.file.where({ Name : 'event_app' });
        item.attributes.name = 'event_app';
        item.attributes.cdn = path + '/' + fileModel[0].attributes.Dest;
        collection.add(item);

        var json = JSON.stringify(collection.toJSON());

        var eventAzureModel = new Entities_AzureEvent.Model();
        eventAzureModel.attributes.AuthUserId = config.authUserId();
        eventAzureModel.attributes.EventId = customEvent[0].id;
        eventAzureModel.attributes.Filename = filename;
        eventAzureModel.attributes.MimeType = 'application/json';
        
        eventAzureModel.attributes.Data = json;
        eventAzureModel.attributes.Directory = 'Directory_file';
        eventAzureModel.attributes.DeleteDirectory = false; //true before
        eventAzureModel.attributes.OnlyDeleteDirectory = false;
        eventAzureModel.attributes.DeleteNoneExitFiles = false;
        eventAzureModel.attributes.DeleteWildcards = '';
        
        eventAzureModel.save(eventAzureModel.attributes, {
            wait: true,
            silent: true,
            success: function () {
                app.entities.collections.headerWebpageInfo.reset();

                collection.forEach(function (entry) {
                    app.entities.collections.headerWebpageInfo.add(entry);
                });

                callback();
            },
            error: function () {
                buildRetry++;
                if (buildRetry < retry) {
                    self.buildFilesUsedJson(customEvent,filename, callback, retry, buildRetry);
                }
            }
        });
    },*/
    removeCharactor : function(text) {
        if (text.length > 0) {
            text = text.replace(/\n|<br\s*\/?>/gi, "\r");
            text = this.replaceAll("'", '', text);
            text = this.replaceAll('"', '', text);
            text = this.replaceAll('\\\\', '', text);
            text = this.replaceAll('&', '', text);
        }
        return text;
    },
    changeCharactor : function(text) {
        if (text.length > 0) {
            text = text.replace(/\n|<br\s*\/?>/gi, "&#8629");
            text = text.replace(/\r|<br\s*\/?>/gi, "&#8629");
            text = this.replaceAll('&', '&#38;', text);
            text = this.replaceAll("'", "&#39;", text);
            text = this.replaceAll('"', '&#34;', text);
            text = this.replaceAll('\n', '&#8629;', text);
            text = this.replaceAll('\\\\', '', text);
            
            //text = this.replaceAll('\', '\', text);
        }
        return text;
    },
    changeCharactorBack : function(text) {
        if (text.length > 0) {
            text = this.replaceAll('&#38;','&', text);
            text = this.replaceAll("&#39;", "'", text);
            text = this.replaceAll('&#34;', '"', text);
            text = this.replaceAll('&#8629;', '\n', text);
        }
        return text;
    },
    buildIndex : function (imagePrint, customEvent, tmpSchemaModel, tmpProductSheetModel, collectionHeaderWebpage,collectionHeaderInfopage, collectionFilesUsed, app, callback, retry, buildRetry) {
        var self = this;

        var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id; 

        var renderedTemplate = '';
        if (imagePrint) {
            renderedTemplate = tpl_eventIndexImagePrintTemplate();
        }else{
            renderedTemplate = tpl_eventIndexTemplate();
        }

        renderedTemplate = renderedTemplate.replace("# tmpModel.EventName", customEvent[0].attributes.EventName);

        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.config.webApiUrl", "'" + config.webApiUrl() + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.config.signalRUrl", "'" + config.signalRUrl() + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.config.cultureName","'" +  config.cultureName() + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.config.online", config.online());

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.eventId", customEvent[0].id);

        if (imagePrint) {
            renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.enableAdvertisingForApplication",  "false");
        } else {
            if (app.entities.models.configSubscription[0].attributes.EnableAdvertising) {
                renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.enableAdvertisingForApplication",  "true");
            }
            else {
                renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.enableAdvertisingForApplication",  "false");
            }
        }
        //V2.0
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.eventName", "'" + customEvent[0].attributes.EventName + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.eventName", "''");
        

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.collectionHeaderWebpage", "'" + path + '/file/' + collectionHeaderWebpage + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.collectionHeaderInfopage", "'" + path + '/file/' + collectionHeaderInfopage + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.collectionImage", "'" + path + '/file/image.json' + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.collectionText", "'" + path + '/file/text.json' + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schema.module", customEvent[0].attributes.Module_SchemaModel ? 1 : 0);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schema.schemaModelId", tmpSchemaModel.id);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schema.table_fontSize", "'" + tmpSchemaModel.FontSizeSchema + 'px' + "'" );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schema.table_marginLeft", "'" + tmpSchemaModel.MarginLeftSchema + 'px' + "'" );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schema.table_marginRight", "'" + tmpSchemaModel.MarginRightSchema + 'px' + "'" );
        
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.schemaModelId", tmpSchemaModel.id);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.table_fontSize", "'" + tmpSchemaModel.FontSizeSchemaBigscreen + 'px' + "'" );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.table_marginLeft", "'" + tmpSchemaModel.MarginLeftSchemaBigscreen + 'px' + "'" );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.table_marginRight", "'" + tmpSchemaModel.MarginRightSchemaBigscreen + 'px' + "'" );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.QRCodeTextBigSchemaScreen", '"' + encodeURIComponent(customEvent[0].attributes.QRCodeTextBigSchemaScreen) + '"' );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.QRCodeFontSizeBigSchemaScreen", "'" + customEvent[0].attributes.QRCodeFontSizeBigSchemaScreen + 'px' + "'" );
        
        
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.productSheet.module", customEvent[0].attributes.Module_ProductSheeetModel ? 1 : 0);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.productSheet.productSheetModelId", tmpProductSheetModel.id);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.productSheet.table_fontSize", "'" + '15px' + "'" );

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.info.infopage", customEvent[0].attributes.Infopage);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.info.QRCodeTextInfoscreen", '"' + encodeURIComponent(customEvent[0].attributes.QRCodeTextInfoscreen) + '"' );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.info.QRCodeFontSizeInfoscreen", "'" + customEvent[0].attributes.QRCodeFontSizeInfoscreen + 'px' + "'" );

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.module", customEvent[0].attributes.Module_FormModel ? 1 : 0);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formModelId", customEvent[0].attributes.FormModel);

        //V2.0
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formSubmitBtnName", "'" + customEvent[0].attributes.FormSubmitBtnName + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formCancelBtnName", "'" + customEvent[0].attributes.FormCancelBtnName + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formSubmitBtnSucces", "'" + customEvent[0].attributes.FormSubmitBtnSucces + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formSubmitBtnName", "''");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formCancelBtnName", "''");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.form.formSubmitBtnSucces", "''");

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.public.publicly", customEvent[0].attributes.Publicly);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.public.facebook", customEvent[0].attributes.Facebook);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.public.copyright", customEvent[0].attributes.Copyright);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.public.infopageShowQRCode", customEvent[0].attributes.InfopageShowQRCode);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.public.schemaBigScreenShowQRCode", customEvent[0].attributes.SchemaBigScreenShowQRCode);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.public.address", "'" + customEvent[0].attributes.Address + "'");

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.ad.category01", "'" +customEvent[0].attributes.Category01 + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.ad.category02", "'" +customEvent[0].attributes.Category02 + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.ad.category03", "'" +customEvent[0].attributes.Category03 + "'");

        var eventName = customEvent[0].attributes.EventName;
        if (eventName.length <= 0) {
            eventName = resources.X100514;
        }

        //V2.0
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.name", "'" + this.changeCharactor(eventName) + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.description", "'" + this.changeCharactor(customEvent[0].attributes.Description) + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.caption", "'" + this.changeCharactor(resources.X100474) + "'");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.name", "''");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.description", "''");
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.caption", "''");
        
        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }
        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.module", customEvent[0].attributes.Module_Facebook ? 1 : 0);
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.fb.link", "'" + webpageEvent + "'");

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.imagePrint.url", "'" + webpageEvent + "'" );

        
        switch (tmpSchemaModel.FieldBigscreen) {
            case 1: 
                renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.renderElement", "'#schemaDataBefore'");
                break;
            case 2: 
                renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.renderElement", "'#schemaDataAfter'");
                break;
            default: renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.renderElement", "'#schemaDataBefore'"); break;

        }
        
        //renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.schemaBigscreen.table_fontSize", "'" + tmpSchemaModel.FontSizeSchemaBigscreen + "px'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.poster.webPageSnapShotUrl",  "'" + path + '/image/' + 'imagePrint.jpg' + "'");
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.poster.QRCodeTextPoster", '"' + encodeURIComponent(customEvent[0].attributes.QRCodeTextPoster) + '"' );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.poster.QRCodeFontSizePoster", "'" + customEvent[0].attributes.QRCodeFontSizePoster + 'px' + "'" );

        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.qrcode.QRCodeTextQRCode", '"' + encodeURIComponent(customEvent[0].attributes.QRCodeTextQRCode) + '"' );
        renderedTemplate = renderedTemplate.replace("'#' + window.luxaforboard.event.qrcode.QRCodeFontSizeQRCode", "'" + customEvent[0].attributes.QRCodeFontSizeQRCode + 'px' + "'" );


        var webpageModel = '';
        var paintStrapModel = '';

        if (imagePrint) {
            var webpageModel = app.entities.collections.customWebpage.fullCollection.where({ id: customEvent[0].attributes.Webpage });
            var paintStrapModel = app.entities.collections.paintStrap.fullCollection.where( { id : webpageModel[0].attributes.Theme});
        }else {
            var webpageModel = app.entities.collections.customWebpage.fullCollection.where({ id: customEvent[0].attributes.Webpage });
            var paintStrapModel = app.entities.collections.paintStrap.fullCollection.where( { id : webpageModel[0].attributes.Theme});
        }
        
        renderedTemplate = renderedTemplate.replace('paintStrap.css',  paintStrapModel[0].attributes.CssFile);

        var fileModel = collectionFilesUsed.where({ Name : 'event_page_style' });
        renderedTemplate = renderedTemplate.replace('event_page_style',  fileModel[0].attributes.Source);

        var fileModel_post = collectionFilesUsed.where({ Name: 'event_page_style_post' });
        renderedTemplate = renderedTemplate.replace('event_page_style_post', fileModel_post[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_schema_style' });
        renderedTemplate = renderedTemplate.replace('event_schema_style', fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_productSheet_style' });
        if (fileModel.length > 0) {
            renderedTemplate = renderedTemplate.replace('event_productSheet_style', fileModel[0].attributes.Source);
        }

        fileModel = collectionFilesUsed.where({ Name : 'event_schemaBigscreen_style' });
        renderedTemplate = renderedTemplate.replace('event_schemaBigscreen_style', fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_form_style' });
        renderedTemplate = renderedTemplate.replace('event_form_style', fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_info_style' });
        renderedTemplate = renderedTemplate.replace('event_info_style', fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_poster_style' });
        renderedTemplate = renderedTemplate.replace('event_poster_style', fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'main_vendor' });
        renderedTemplate = renderedTemplate.replace('main_vendor',  fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_app' });
        renderedTemplate = renderedTemplate.replace('event_app',  fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name : 'event_language' });
        renderedTemplate = renderedTemplate.replace('event_language', fileModel[0].attributes.Source);

        fileModel = collectionFilesUsed.where({ Name: 'event_font' });
        renderedTemplate = renderedTemplate.replace('event_font', fileModel[0].attributes.Source);

        renderedTemplate = renderedTemplate.replace("/signalr/js",  config.signalRUrl() + "/signalr/js");

        renderedTemplate = renderedTemplate.replace("template_url1" , "#<%= url %>");
        renderedTemplate = renderedTemplate.replace("template_name1", "<%= name %>");
        renderedTemplate = renderedTemplate.replace("template_name2", "<%= name %>");
        renderedTemplate = renderedTemplate.replace("template_id2", "id_<%= menuitemId %>");
        renderedTemplate = renderedTemplate.replace("template_url3", "#<%= url %>");
        renderedTemplate = renderedTemplate.replace("template_name3", "<%= name %>");
        renderedTemplate = renderedTemplate.replace("template_id3", "id_<%= menuitemId %>");



        var eventAzureModel = new Entities_AzureEvent.Model();
        eventAzureModel.attributes.AuthUserId = config.authUserId();
        eventAzureModel.attributes.EventId = customEvent[0].id;

        if (imagePrint) {
            eventAzureModel.attributes.Filename = 'indexImagePrint.html';
        } else {
            eventAzureModel.attributes.Filename = 'index.html';
        }

        eventAzureModel.attributes.MimeType = 'text/html';
        
        eventAzureModel.attributes.Data = renderedTemplate;
        eventAzureModel.attributes.Directory = 'Directory_index';
        eventAzureModel.attributes.DeleteDirectory = false;
        eventAzureModel.attributes.OnlyDeleteDirectory = false;
        eventAzureModel.attributes.DeleteNoneExitFiles = false;
        eventAzureModel.attributes.DeleteWildcards = '';
        
        eventAzureModel.save(eventAzureModel.attributes, {
            wait: true,
            silent: true,
            success: function () {
                callback();
            }, 
            error: function () {
                buildRetry++;
                if (buildRetry < retry) {
                    self.buildIndex(imagePrint, customEvent, tmpSchemaModel, tmpProductSheetModel, collectionHeaderWebpage,collectionHeaderInfopage, collectionFilesUsed, app, callback, retry, buildRetry);
                }
            }
        });

        return renderedTemplate;
    },
    buildTemplate : function (customEvent, collectionHeader, buildinfo, tmpSchemaModel,tmpProductSheetModel, QRCodeImageUrl, WebpageImageUrl, templatePrefix, view, app, processModel, callback, retry, buildRetry) {
        var self = this;

        var countTotal = collectionHeader.where({ generateTemplate : true }).length;
        countTotal++;
        var tmpCallback_id = 0;

        //processModel.attributes.progress_totalCount_2 += countTotal;
        

        //if (buildinfo) {
        //    var tmpCustomWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Infopage });
        //    var countTotal = (tmpCustomWebpageItem.length) * 3;
        //} else {
        //    var tmpCustomWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage });
        //    var countTotal = (tmpCustomWebpageItem.length-2) * 3;
        //}

        //var updateStatusbar = false;
        //var timerCount = 0;
        //var timer = setInterval(function () {
        //    processModel.attributes.progress_count_2++;
        //    timerCount++;
        //    view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);

        //    if (updateStatusbar) {
        //        var left = countTotal - timerCount;

        //        if (left > 0) {
        //            processModel.attributes.progress_count_2+= left;
        //            timerCount+= left;
        //            view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
        //        }
        //    }

        //    if (timerCount == countTotal) {
        //        clearTimeout(timer);
        //    }
        //}, 300);

        var callbackUpdateStatusBar = function (i) {
            processModel.attributes.progress_count_2 += i;
            view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);
        }

        var callbackDeleteNoneExits = function () {
            var exitTemplate = '';
            //var tmpCollection = collectionHeader.where({ generateTemplate : true });
            collectionHeader.forEach(function (entry) {
                if (entry.attributes.checksum.length > 0) {
                    exitTemplate += entry.attributes.checksum + '.html' + ',';
                }
            });

            /*if (exitTemplate.length > 0) {
                exitTemplate = exitTemplate.substr(0, exitTemplate.length - 1);
            }*/
            exitTemplate += 'dummy.txt';

            var eventAzureModel1 = new Entities_AzureEvent.Model();
            eventAzureModel1.attributes.AuthUserId = config.authUserId();
            eventAzureModel1.attributes.EventId = customEvent[0].id;
            eventAzureModel1.attributes.Filename = '';
            eventAzureModel1.attributes.MimeType = '';
        
            eventAzureModel1.attributes.Data = '';
            if (buildinfo) {
                eventAzureModel1.attributes.Directory = 'Directory_templateInfo';
            } else {
                eventAzureModel1.attributes.Directory = 'Directory_template';
            }

            eventAzureModel1.attributes.DeleteDirectory = false;
            eventAzureModel1.attributes.OnlyDeleteDirectory  = false;
            eventAzureModel1.attributes.DeleteNoneExitFiles  = false;//true;
            eventAzureModel1.attributes.DeleteWildcards = exitTemplate;

            eventAzureModel1.save(eventAzureModel1.attributes, {
                wait: true,
                silent: true,
                success: function () {
                    tmpCallback_id++;
                    callbackUpdateStatusBar(1);
                    callbackBuildTemplate();
                },
                error: function () {
                    buildRetry++;
                    if (buildRetry < retry) {
                        self.buildTemplate(customEvent, buildinfo, tmpSchemaModel,tmpProductSheetModel, QRCodeImageUrl, WebpageImageUrl, templatePrefix, view, app, processModel, callback, retry, buildRetry);
                    }
                }
            });
        }

        var callbackBuildTemplate = function () {
            
            var customWebpageItem = {};

            if (buildinfo) {
                customWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Infopage });
                
                //Add advertising to infoscreen
                /*if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == true) {
                    var body = app.entities.collections.configSetting.where({ CultureName : config.cultureName(), ItemId : 'InfoScreenEnableAdvertisingHtml'});
                    var duration = app.entities.collections.configSetting.where({ CultureName : config.cultureName(), ItemId : 'InfoScreenEnableAdvertisingDuration'});
                    var position = app.entities.collections.configSetting.where({ CultureName : config.cultureName(), ItemId : 'InfoScreenEnableAdvertisingPosition'});

                    if ((body.length > 0) &&  (duration.length > 0)) {
                        var advertisingModel = new app.entities.collections.customWebpageItem.model();
                        advertisingModel.attributes.AuthUserId = customEvent[0].attributes.AuthUserId;
                        advertisingModel.attributes.Webpage = customEvent[0].attributes.Infopage;
                        advertisingModel.attributes.Name = resources.X100835;
                        advertisingModel.attributes.Tag = 9;
                        advertisingModel.attributes.Position = parseInt(position[0].attributes.Value);
                        advertisingModel.attributes.Body = body[0].attributes.Value;
                        advertisingModel.attributes.Duration = parseInt(duration[0].attributes.Value);
                        advertisingModel.attributes.Css = '';
                        advertisingModel.attributes.menuitemId = -1;
                        advertisingModel.attributes.id = -1;
                        advertisingModel.attributes.checksum = self.calcmd5(advertisingModel.attributes.id, advertisingModel.attributes.Body, '', '', advertisingModel.attributes.Tag);
                        advertisingModel.attributes.generateTemplate = true;
                    }

                    customWebpageItem.push(advertisingModel);
                }*/
            } else {
                customWebpageItem = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage });
            }

            var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/template/'; 
            var menuItem = new Entities_Empty.Model();
            var collection = new Entities_Empty.Collection();

            var tmpModel = {};
        
            //tmpModel.EventName = customEvent[0].attributes.EventName;

            //tmpModel.WebpageImageUrl = WebpageImageUrl;
            //tmpModel.QRCodeImageUrl = QRCodeImageUrl;

            var customWebpageItem_header = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage, Tag: 0 });
            var customWebpageItem_footer = app.entities.collections.customWebpageItem.where({ Webpage: customEvent[0].attributes.Webpage, Tag: 2 });

            if (customWebpageItem_header[0].attributes.Status == 0) {
                tmpModel.Header = '';
            } else {
                tmpModel.Header = customWebpageItem_header[0].attributes.Body;
            }
            
            if (customWebpageItem_footer[0].attributes.Status == 0) {
                tmpModel.Footer = '';
            } else {
                tmpModel.Footer = customWebpageItem_footer[0].attributes.Body;
            }

            //Copyright text
            //if (app.entities.models.configSubscription[0].attributes.EnableAdvertising == false) {
            //    tmpModel.CopyrightText01 = resources['X100020'];
            //    tmpModel.CopyrightText02 = resources['X100021'];
            //    tmpModel.CopyrightText03 = resources['X100022'];
            //}
            //else{
            //    tmpModel.CopyrightText01 = resources['X100023'];
            //    tmpModel.CopyrightText02 = resources['X100024'];
            //    tmpModel.CopyrightText03 = resources['X100025'];
            //}

            //switch (customEvent[0].attributes.Copyright) {
            //    case 0 : tmpModel.enableCopyright = 'display:none;'; break;
            //    case 1 : tmpModel.enableCopyright = ''; break;
            //}

            //switch (customEvent[0].attributes.InfopageShowQRCode) {
            //    case 0 : tmpModel.enableInfopageShowQRCode = 'display:none;'; break;
            //    case 1 : tmpModel.enableInfopageShowQRCode = ''; break;
            //}

            //switch (customEvent[0].attributes.SchemaBigScreenShowQRCode) {
            //    case 0 : tmpModel.enableSchemaBigScreenShowQRCode = 'display:none;'; break;
            //    case 1 : tmpModel.enableSchemaBigScreenShowQRCode = ''; break;
            //}

            //tmpModel.enableFixedFoooter = '';
            //if ((customEvent[0].attributes.SchemaBigScreenShowQRCode == 0) && (customEvent[0].attributes.Copyright == 0)) {
            //    tmpModel.enableFixedFoooter = 'display:none;';
            //}

            //tmpModel.Events4us_text = resources['X100908'];
            //tmpModel.Events4us_title = resources['X100475'];

            //tmpModel.Facebook_text = resources['X100471'];
            //tmpModel.Facebook_title = resources['X100472'];

            //tmpModel.EventSpinText = resources['X100019'];

            //var friendlyUrl = customEvent[0].attributes.id;
            //if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            //    friendlyUrl = customEvent[0].attributes.FriendlyUrl;
            //}

            //var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;
            //tmpModel.ImageView = webpageEvent;

            //Count total updates
            var countGenerateTemplate = 0;
            /*for (var menu = 0; menu < customWebpageItem.length; menu++) {
                var item = customWebpageItem[menu];

                var generateTemplate = false;
                var tmpModel =  collectionHeader.where({ menuitemId : item.attributes.id });
                if (tmpModel.length > 0) {
                    if (tmpModel[0].attributes.generateTemplate == true) {
                        countGenerateTemplate ++;
                    }
                }
            }*/

            countGenerateTemplate =  collectionHeader.where({ generateTemplate : true }).length;

            if (countGenerateTemplate == 0) {
                callback(2,1,false);
            }

            for (var menu = 0; menu < customWebpageItem.length; menu++) {
                var item = customWebpageItem[menu];

                var generateTemplate = false;
                var headerWebpageModel = collectionHeader.where({ menuitemId : item.attributes.id });
                if (headerWebpageModel.length > 0) {
                    generateTemplate = headerWebpageModel[0].attributes.generateTemplate;
                }

                if (generateTemplate) {

                    tmpModel.Content = item.attributes.Body;
                    tmpModel.Duration = item.attributes.Duration;

                    var renderedTemplate = '';

                    var emptyModel = new Entities_Empty.Model();

                    if (item.attributes.Tag != 0 && item.attributes.Tag != 2) {
                        switch (item.attributes.Tag) {
                            case 0,2 : break;
                            case 1: renderedTemplate = tpl_eventTemplate({ model: tmpModel }); break
                            case 10 : renderedTemplate = tpl_eventTemplate({ model: tmpModel }); break;
                            case 3 : renderedTemplate = tpl_eventSchemaBigscreenTemplate({ model: tmpModel }); break;
                            case 4 : renderedTemplate = tpl_eventPosterTemplate({ model: tmpModel }); break;
                            case 5 : renderedTemplate = tpl_eventSchemaTemplate({ model: tmpModel }); break;
                            case 6 : renderedTemplate = tpl_eventImagePrintTemplate({ model: tmpModel }); break;
                            case 7 : renderedTemplate = tpl_eventFormTemplate({ model: tmpModel }); break;
                            case 8 : renderedTemplate = tpl_eventProductSheetTemplate({ model: tmpModel }); break;
                            case 9: renderedTemplate = tpl_eventInfoTemplate({ model: tmpModel }); break;
                            case 11: renderedTemplate = tpl_eventQRTemplate({ model: tmpModel }); break;
                            case 12: renderedTemplate = tpl_eventLocationTemplate({ model: tmpModel }); break;
                            case 13: renderedTemplate = tpl_eventCalendarTemplate({ model: tmpModel }); break;
                        }

                        renderedTemplate = self.replaceAll('#modelbegin#', '<%=', renderedTemplate);
                        renderedTemplate = self.replaceAll('#modelend#', '%>', renderedTemplate);

                        var eventAzureModel = new Entities_AzureEvent.Model();
                        eventAzureModel.attributes.AuthUserId = config.authUserId();
                        eventAzureModel.attributes.EventId = customEvent[0].id;
                        eventAzureModel.attributes.Filename = /*item.attributes.Name + '_' + */headerWebpageModel[0].attributes.checksum/*templatePrefix*/ + '.html';
                        eventAzureModel.attributes.MimeType = 'text/html';
        
                        eventAzureModel.attributes.Data = renderedTemplate;
                        if (buildinfo) {
                            eventAzureModel.attributes.Directory = 'Directory_templateInfo';
                        } else {
                            eventAzureModel.attributes.Directory = 'Directory_template';
                        }

                        eventAzureModel.attributes.DeleteDirectory = false;
                        eventAzureModel.attributes.OnlyDeleteDirectory = false;
                        eventAzureModel.attributes.DeleteNoneExitFiles = false;
                        //eventAzureModel.attributes.DeleteDirectory = true;
                        //eventAzureModel.attributes.DeleteWildcards = item.attributes.Name;


                        eventAzureModel.save(eventAzureModel.attributes, {
                            wait: true,
                            //silent: true,
                            success: function () {
                                tmpCallback_id ++;
                                callbackUpdateStatusBar(1);

                                if (buildinfo) {
                                    //Info
                                    if (tmpCallback_id == countTotal) {
                                        callbackUpdateStatusBar((customWebpageItem.length) - countTotal);
                                        //callback(tmpCallback_id , (customWebpageItem.length), true);
                                        callback(2,1,false);
                                    }
                                } else {
                                    //Minus header and footer on event + 
                                    callbackUpdateStatusBar((customWebpageItem.length - 2) - countTotal);
                                    if (tmpCallback_id == countTotal) {
                                        callback(2,1,false);
                                    }
                                }
                            }, 
                            error: function () {
                                buildRetry++;
                                if (buildRetry < retry) {
                                    self.buildTemplate(customEvent, buildinfo, tmpSchemaModel,tmpProductSheetModel, QRCodeImageUrl, WebpageImageUrl, templatePrefix, view, app, processModel, callback, retry, buildRetry);
                                }
                            }
                        });
                    }
                }
            }
        }

        callbackDeleteNoneExits();
    },
    buildQRCode : function (customEvent, QRCodeFilename, processModel, view, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, app, callback, retry, buildRetry) {
        var self = this;

        var tmpModel = new Entities_QRCode.Model();

        //tmpModel.attributes.Id = -1;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.EventId = customEvent[0].attributes.id;
        tmpModel.attributes.Filename  = QRCodeFilename;

        var tmpModelQRCode = new Entities_Empty.Model();
        tmpModelQRCode.attributes.id = customEvent[0].attributes.id;

        customEventRender_qrCodeLogo.off("load");
        customEventRender_qrCodeLogo.load(function () { 
            customEventRender_qrCodeLogo.off("load");

            processModel.attributes.progress_count_2++;
            view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);

            tmpModelQRCode = self.createQRCodeImage(data.customQRCode, tmpModelQRCode, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, true, app);
            tmpModel.attributes.QRCodeImageData = tmpModelQRCode.attributes.ImageData;
            tmpModel.attributes.QRCodeImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
            tmpModel.attributes.ImageUpdate = tmpModelQRCode.attributes.ImageUpdate;
            tmpModel.attributes.Filename = QRCodeFilename;
            
            tmpModel.save(tmpModel.attributes, {
                wait: true,
                silent : true,
                success: function () {
                    //callback(3,4,true);
                    callback();
                },
                error: function () {
                    buildRetry++;
                    if (buildRetry < retry) {
                        self.buildQRCode(customEvent, QRCodeFilename, processModel, view, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, app, callback, retry, buildRetry);
                    }
                }
            });
        });
        
        var data = this.getQRCodeLogo(customEvent[0],app);

        $.ajax({
            url:data.imageUrl,
            type:'GET',
            beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization', "Bearer ".concat(window['mgr'].access_token)); },
            error: function()
            {
                renderError.messageBox(app, view, resources.X100026, resources.X100554);
            },
            success: function()
            {
                customEventRender_qrCodeLogo.attr("src", data.imageUrl);
            }
        });



        //try {
        //    customEventRender_qrCodeLogo.attr("src", data.imageUrl);
        //} catch(err) {
        //    alert("error");
        //    alert(err);

        //}
    },
    buildImagePrint : function (customEvent, WebpageFilename, processModel, view, app, callback, retry, buildRetry) {
        var self = this;

        var tmpModel = new Entities_Page2Image.Model();

        //tmpModel.attributes.Id = -1;
        tmpModel.attributes.AuthUserId = config.authUserId();
        tmpModel.attributes.EventId = customEvent[0].attributes.id;
        tmpModel.attributes.Filename = WebpageFilename;

        var timerCount = 0;
        var timer = setInterval(function () {
            processModel.attributes.progress_count_2++;
            timerCount++;
            view.changeProgress_2(processModel.attributes.progress_count_2, processModel.attributes.progress_totalCount_2);

            if (timerCount == self.setting.webSnapShotTimerCount) {
                clearTimeout(timer);
            }
        }, 500);

        tmpModel.save(tmpModel.attributes, {
            wait: true,
            silent : true,
            success: function () {
                callback(timer);
            },
            error: function () {
                buildRetry++;
                if (buildRetry < retry) {
                    self.buildImagePrint(customEvent, WebpageFilename, processModel, view, app, callback, retry, buildRetry);
                }
            }
        });
    },
    buildTextFilesJson : function (customEvent,filename, callback, retry, buildRetry) {
        var self = this;
        
        var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/file/'; 
        var collection = new Entities_Empty.Collection();

        var item = new Entities_Empty.Model();
        item.attributes.name = 'event_eventName';
        item.attributes.value = customEvent[0].attributes.EventName;
        collection.add(item);

        var item = new Entities_Empty.Model();
        item.attributes.name = 'form_formSubmitBtnName';
        item.attributes.value = customEvent[0].attributes.FormSubmitBtnName;
        collection.add(item);

        var item = new Entities_Empty.Model();
        item.attributes.name = 'form_formCancelBtnName';
        item.attributes.value = customEvent[0].attributes.FormCancelBtnName;
        collection.add(item);

        var item = new Entities_Empty.Model();
        item.attributes.name = 'form_formSubmitBtnSucces';
        item.attributes.value = customEvent[0].attributes.FormSubmitBtnSucces;
        collection.add(item);

        var eventName = customEvent[0].attributes.EventName;
        if (eventName.length <= 0) {
            eventName = resources.X100514;
        }

        var item = new Entities_Empty.Model();
        item.attributes.name = 'fb_name';
        item.attributes.value = eventName;
        collection.add(item);

        var item = new Entities_Empty.Model();
        item.attributes.name = 'fb_description';
        item.attributes.value = customEvent[0].attributes.Description;
        collection.add(item);

        var item = new Entities_Empty.Model();
        item.attributes.name = 'fb_caption';
        item.attributes.value = resources.X100474;
        collection.add(item);

        var json = JSON.stringify(collection.toJSON());

        var eventAzureModel = new Entities_AzureEvent.Model();
        eventAzureModel.attributes.AuthUserId = config.authUserId();
        eventAzureModel.attributes.EventId = customEvent[0].id;
        eventAzureModel.attributes.Filename = filename;
        eventAzureModel.attributes.MimeType = 'application/json';
        
        eventAzureModel.attributes.Data = json;
        eventAzureModel.attributes.Directory = 'Directory_file';
        eventAzureModel.attributes.DeleteDirectory = false;
        eventAzureModel.attributes.OnlyDeleteDirectory = false;
        eventAzureModel.attributes.DeleteNoneExitFiles = false;
        eventAzureModel.attributes.DeleteWildcards = '';
        
        eventAzureModel.save(eventAzureModel.attributes, {
            wait: true,
            silent: true,
            success: function () {
                callback();
            }, 
            error: function () {
                buildRetry++;
                if (buildRetry < retry) {
                    self.buildTextFilesJson(customEvent,filename, callback, retry, buildRetry);
                }
            }
        });
    },
    buildImageFilesJson : function (customEvent,filename, callback, retry, buildRetry) {
        var self = this;

        var path = config.cdn() + '/' + config.authUserId() + '/event/' + customEvent[0].id + '/file/'; 
        var collection = new Entities_Empty.Collection();

        var item = new Entities_Empty.Model();
        item.attributes.name = 'QRCodeImageUrl';
        item.attributes.cdn = customEvent[0].attributes.QRCodeImageUrl;
        collection.add(item);

        var item = new Entities_Empty.Model();
        item.attributes.name = 'WebpageImageUrl';
        item.attributes.cdn = customEvent[0].attributes.WebpageImageUrl;
        collection.add(item);

        var json = JSON.stringify(collection.toJSON());

        var eventAzureModel = new Entities_AzureEvent.Model();
        eventAzureModel.attributes.AuthUserId = config.authUserId();
        eventAzureModel.attributes.EventId = customEvent[0].id;
        eventAzureModel.attributes.Filename = filename;
        eventAzureModel.attributes.MimeType = 'application/json';
        
        eventAzureModel.attributes.Data = json;
        eventAzureModel.attributes.Directory = 'Directory_file';
        eventAzureModel.attributes.DeleteDirectory = false;
        eventAzureModel.attributes.OnlyDeleteDirectory = false;
        eventAzureModel.attributes.DeleteNoneExitFiles = false;
        eventAzureModel.attributes.DeleteWildcards = '';
        
        eventAzureModel.save(eventAzureModel.attributes, {
            wait: true,
            silent: true,
            success: function () {
                callback();
            },
            error: function () {
                buildRetry++;
                if (buildRetry < retry) {
                    self.buildImageFilesJson(customEvent,filename, callback, retry, buildRetry);
                }
            }
        });
    },
    getQRCodeLogo: function (tmpModel, app) {
        //There is allway a picture in the list, therefor a qrcode with text still load a image without using it
        var QRCode = parseInt(tmpModel.attributes.QRCode);
        var customQRCode = app.entities.collections.customQRCode.fullCollection.where({ id: QRCode });
        var imageUrl = '';

        if (customQRCode.length == 1) {
            var logo = parseInt(customQRCode[0].attributes.Logo);

            if (isNaN(logo)) {
                imageUrl = customQRCode[0].attributes.Logo;
            } else {
                //var customLogo = app.entities.collections.customLogo.fullCollection.where({ id: logo });
                imageUrl = config.webApiUrl() + '/api/PublicCustomImage/route01/' + logo;
            }
        }

        return { imageUrl: imageUrl, customQRCode: customQRCode[0] };
    },
    getUrl: function (id, app) {
        var customEvent = app.entities.collections.customEvent.fullCollection.where({ id: id });

        var friendlyUrl = customEvent[0].attributes.id;
        if (customEvent[0].attributes.FriendlyUrl && customEvent[0].attributes.FriendlyUrl.length > 0) {
            friendlyUrl = customEvent[0].attributes.FriendlyUrl;
        }

        var webpageEvent = config.webApiUrl() + '/events/' + friendlyUrl;

        return webpageEvent;
    },
    replaceAll: function (find, replace, str) {
        return str.replace(new RegExp(find, 'g'), replace)
    },
    createQRCodeImage: function (customQRCode, tmpModel, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, useUrl, app) {
        var fill = new tinycolor(customQRCode.attributes.Fill).toHex();
        var background = new tinycolor(customQRCode.attributes.Background).toHex();
        var fontcolor = new tinycolor(customQRCode.attributes.FontColor).toHex();
        var fontstyle = app.entities.collections.QRCodeFontStyle.where({ Value: customQRCode.attributes.FontStyle })[0];
        

        var url = customQRCode.attributes.Text;
        /*if (useUrl) {
            var url = this.getUrl(tmpModel.attributes.id, app);
        }*/

        var options = {
            render: customQRCode.attributes.Render,
            ecLevel: customQRCode.attributes.EcLevel,
            minVersion: customQRCode.attributes.MinVersion,
            fill: '#' + fill,
            background: '#' + background,
            text: url, //customQRCode.attributes.Text,
            size: customQRCode.attributes.Size,
            radius: customQRCode.attributes.Radius * 0.01,
            quiet: customQRCode.attributes.Quiet,
            mode: customQRCode.attributes.Mode,
            mSize: customQRCode.attributes.MSize * 0.01,
            mPosX: customQRCode.attributes.MPosX * 0.01,
            mPosY: customQRCode.attributes.MPosY * 0.01,
            label: customQRCode.attributes.Label,
            fontname: customQRCode.attributes.FontName,
            fontcolor: '#' + fontcolor,
            fontstyle: fontstyle.attributes.Style,
            fontsize: customQRCode.attributes.FontSize,
            image: customEventRender_qrCodeLogo[0],//$(customEventRender_qrCodeLogo)[0],
            customEventRender_qrCodeContainer: customEventRender_qrCodeContainer
        };

        var element = $(customEventRender_qrCodeContainer).empty();
        //element.qrcodeEvents4us(options);
        element.qrcode(options);

        //var dataURL = null;
        var canvasQRCode = $(customEventRender_qrCodeContainer).attr('id') + '_canvasQRCode';
        var canvas = document.getElementById(canvasQRCode);
        if (canvas) {
            var ctx = canvas.getContext("2d");
            var dataURL = ctx.canvas.toDataURL('image/jpeg');
            if (dataURL != null) {
                var searchString = ";base64,";
                var pos = dataURL.indexOf(searchString);

                var imageMimeType = dataURL.substring(5, pos);
                var imageData = dataURL.substring(pos + searchString.length, dataURL.length);
                tmpModel.attributes.ImageData = imageData;
                tmpModel.attributes.ImageMimeType = imageMimeType;
                tmpModel.attributes.ImageUpdate = true;
            }
        } else {
            var a = "error";
        }

        return tmpModel;
    },
    createQRCodeImageInfo: function (customQRCode, tmpModel, customEventRender_qrCodeLogo, customEventRender_qrCodeContainer, useUrl, app) {
        var fill = new tinycolor(customQRCode.attributes.Fill).toHex();
        var background = new tinycolor(customQRCode.attributes.Background).toHex();
        var fontcolor = new tinycolor(customQRCode.attributes.Font1_Color).toHex();
        var fontstyle = app.entities.collections.QRCodeFontStyle.where({ Value: parseInt(customQRCode.attributes.Font1_Style) })[0];


        var url = customQRCode.attributes.Url;
        /*if (useUrl) {
            var url = this.getUrl(tmpModel.attributes.id, app);
        }*/

        var options = {
            render: customQRCode.attributes.Render,
            ecLevel: customQRCode.attributes.EcLevel,
            minVersion: customQRCode.attributes.MinVersion,
            fill: '#' + fill,
            background: '#' + background,
            text: url, //customQRCode.attributes.Text,
            size: customQRCode.attributes.Size,
            radius: customQRCode.attributes.Radius * 0.01,
            quiet: customQRCode.attributes.Quiet,
            mode: customQRCode.attributes.Mode,
            mSize: customQRCode.attributes.MSize * 0.01,
            mPosX: customQRCode.attributes.MPosX * 0.01,
            mPosY: customQRCode.attributes.MPosY * 0.01,
            label: customQRCode.attributes.Label,
            fontname: customQRCode.attributes.Font1,
            fontcolor: '#' + fontcolor,
            fontstyle: fontstyle.attributes.Style,
            fontsize: customQRCode.attributes.Font1_Size,
            image: customEventRender_qrCodeLogo[0],
            customEventRender_qrCodeContainer: customEventRender_qrCodeContainer
        };

        //var element = $(customEventRender_qrCodeContainer).empty();
        var element = customEventRender_qrCodeContainer.empty();
        //element.qrcodeEvents4us(options);
        element.qrcode(options);

        //var dataURL = null;
        var canvas = element.find('#qrcode_qrCodeContainer_2_canvasQRCode')[0];// $(customEventRender_qrCodeContainer).attr('id') + '_canvasQRCode';
        //var canvas = document.getElementById(canvasQRCode);
        if (canvas) {
            var ctx = canvas.getContext("2d");
            var dataURL = ctx.canvas.toDataURL('image/jpeg');
            if (dataURL != null) {
                var searchString = ";base64,";
                var pos = dataURL.indexOf(searchString);

                var imageMimeType = dataURL.substring(5, pos);
                var imageData = dataURL;// dataURL.substring(pos + searchString.length, dataURL.length);
                tmpModel.attributes.ImageData = imageData;
                tmpModel.attributes.ImageMimeType = imageMimeType;
                tmpModel.attributes.ImageUpdate = true;
            }
        } else {
            var a = "error";
        }

        return tmpModel;
    }
}

export default HelperCreateWebpage;