﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customWebpage from './tpl/customWebpage.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import View_Pagination from '../../../lib/views/pagination/pagination';
import View_CustomWebpageInsert from './customWebpageInsert';
import View_CustomWebpageList from './customWebpageList';
import Help from '../../helper/help';

export default Marionette.LayoutView.extend({
    getTemplate: function () {
        return tpl_customWebpage();
    },
    initialize: function () {
        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();
        this.localSetting = new Entities_Empty.Model();

        this.listenTo(this.collection, 'add', this.updateInformationCollection, this);
        this.listenTo(this.collection, 'remove', this.updateInformationCollection, this);

        this.setHasChanged(false);
    },
    help: function () {
        switch(this.model.attributes.DisplayType) {
            case 0 : Help.help(this, true, 'customWebpage'); break;
            case 1 : Help.help(this, true, 'customInfopage'); break;
        }
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        callback();
    },
    cancelHasChanged : function() {
    },
    modelHasChanged : function () {
    },
    regions: {
        customWebpageInsert: "#customWebpageInsert",
        customWebpageList: "#customWebpageList",
        customWebpagePagination: "#customWebpagePagination",
        information: "#customWebpageInformation"
    },
    onBeforeShow: function () {
        this.customWebpageInsert.show(new View_CustomWebpageInsert({ collection: this.collection, localSetting: this.localSetting, app : this.app,  model : this.model}));
        this.customWebpageList.show(new View_CustomWebpageList({ collection: this.collection, app : this.app }));
        this.customWebpagePagination.show(new View_Pagination({ collection: this.collection, app : this.app }));
        this.information.show(new View_Information({ collection: this.informationCollection, app : this.app }));
    },
    ui : {
        'customWebpage_h5' : '#customWebpage_h5',
        'help': '#help'
    },
    events: {
        'click #help': 'help'
    },
    onRender: function () {
        var text = '';
        switch (this.model.attributes.DisplayType)  {
            case 0 :
                var text = resources['X100134'];
                text = '<i class="fa fa-2x fa-html5" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
                break;
            case 1 :
                var text = resources['X100451'];
                text = '<i class="fa fa-2x fa-television" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
                break;
        }

        //text = '<i class="fa fa-2x fa-pencil-square-o" style="padding-left:30px;"></i><i id="help" style="cursor:pointer;" class="fa fa-2x fa-question-circle"></i>' + text;
        this.ui.customWebpage_h5.html(text);

        this.renderInformation();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        switch (this.model.attributes.DisplayType)  {
            case 0 :
                //No webpage yet
                if (this.collection.length <= 0) {
                    var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100337 });
                    this.informationCollection.add(tmpModel);
                }
                break;
            case 1 :
                //No infopage yet
                if (this.collection.length <= 0) {
                    var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100469 });
                    this.informationCollection.add(tmpModel);
                }
                break;
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    customViewEvent_setActive: function () {
        var array = [];

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('customViewEvent:setActive', array);
    },
    onAttach(){
        this.customViewEvent_setActive();
    }
}, Backbone.Radio.Requests);