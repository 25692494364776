import * as Backbone from 'backbone';
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    constructor: function () {
        this.defaults = _.extend({}, this.defaults, {
            Calculation: {}
        });

        Backbone.Model.prototype.constructor.apply(this, arguments);
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/PublicCustomEventLive/-1?EventId=' + options.eventId;
        }
        return Backbone.sync(method, model, options);
    }
});

    