﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customDepartmentUserList from './tpl/customDepartmentUserListA.html';
import View_CustomDepartmentUserListItem from './customDepartmentUserListItem';

export default Marionette.CollectionView.extend({
    attributes: function () {
        return {
            id: 'customDepartmentUserListAA',
            style: "min-height:50px;"// border : 2px solid; border-color:black;"
        }
    },
    childViewContainer: '#customDepartmentUserListAA',
    childView: View_CustomDepartmentUserListItem,
    getTemplate: function () {
        return tpl_customDepartmentUserList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        options.parentModel = this.options.parentModel;
        var view = new ChildViewClass(options);
        return view;
    }
});