//import * as Backbone from 'backbone';
var Backbone = require('backbone');
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
        var that = this;
    },
    "sync": function (method, model, options) {
        if (method == 'read') {
            options.url = config.webApiUrl() + '/api/customColor/-1?AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/customColor/route01/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/customColor/-1?AuthUserId=' + config.authUserId();
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    save: function (attributes, options) {
        Backbone.Model.prototype.save.call(this, attributes, options);
    },
    successOnSave: function (model, response) {
        model = response;
    },
    errorOnSave: function (model, response) {
        alert("Error");
    }
});

