﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_customEventList from './tpl/customEventList.html';
import View_CustomEventListItem from './customEventListItem';

export default Marionette.CollectionView.extend({
    //tagName: 'customEventList',
   // tagName: 'ul',
  //  className: 'd-flex flex-column-reverse todo-list',
    childViewContainer: '#customEventList',
    childView: View_CustomEventListItem,
    getTemplate: function () {
        return tpl_customEventList();
    },
    initialize: function () {
        this.app = this.options.app;
    },
    buildChildView: function(child, ChildViewClass, childViewOptions){
        var options = _.extend({model: child}, childViewOptions);
        options.app = this.app;
        var view = new ChildViewClass(options);
        return view;
    }
});