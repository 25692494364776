﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_dialogBox from './tpl/dialogBox.html';

export default Marionette.ItemView.extend({
    dialogRegion: '#dialogBox_modal',
    getTemplate: function () {
        return tpl_dialogBox({ model: this.model.toJSON() });
    },
    onRender: function () {
        
    },
    events: {
        "click #dialogBox_btnOk": "onOK",
        "click #dialogBox_btnClose": "onClose"
    },
    onOK: function () {
        this.triggerMethod('dialog:okclicked', "msg");
        this.trigger("dialog:close");
    },
    onClose: function () {
        this.triggerMethod('dialog:closeclicked', "msg")
        this.trigger("dialog:close");
    },
    setBackgroundColor : function() {
        var backgroundColor = this.getBackgroundColor();
        $('.modal-dialog').css('background-color', backgroundColor);
    },
    getBackgroundColor : function() {
        var backgroundColor = $("body").css("background-color");
        var hexdec = this.hexc(backgroundColor);
        var colorLuminance = this.colorLuminance(hexdec, 0.2);
        return colorLuminance;
    },
    hexc : function(colorval) {
        var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        delete(parts[0]);
        for (var i = 1; i <= 3; ++i) {
            parts[i] = parseInt(parts[i]).toString(16);
            if (parts[i].length == 1) parts[i] = '0' + parts[i];
        }
        return '#' + parts.join('');
    },
    colorLuminance : function(hex, lum) {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }

        return rgb;
    }
});
