﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as plugins_qrcode from '../../plugins/qrcode';
import * as nouislider from 'nouislider';


import tpl_customIPAddressEditItem from './tpl/customIPAddressEditItem.html';

import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';

import View_CustomHeader from '../region/customHeader/customHeader';

export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_customIPAddressEditItem({ model: this.model.toJSON() });
    },
    regions: {
        information: "#customIPAddressEditItemInformation",
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    initialize: function () {
        var self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();

        var userChannel = Backbone.Radio.channel('app');
    },
    help: function () {
        Help.help(this, true, 'customIPAddressEditItem');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick(null, callback);
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        /*if (this.model.attributes.Name != this.oldModel.attributes.Name)  {
            changes = true;
        }*/

        this.setHasChanged(changes);
    },
    ui: {
        'customIPAddressEditItem_inputName': '#customIPAddressEditItem_inputName',
        'customIPAddressEditItem_btnSubmit': '#customIPAddressEditItem_btnSubmit',
        'customIPAddressEditItem_btnCancel': '#customIPAddressEditItem_btnCancel',
        'help': '#help',
    },
    events: {
        'click @ui.customIPAddressEditItem_btnSubmit': 'btnSubmitClick',
        'click @ui.customIPAddressEditItem_btnCancel': 'btnCancelClick',
        'click #help': 'help'

    },
    renderInformation: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: resources.X100072 });
        this.informationCollection.add(tmpModel);

    },
    onAttach() {
        var self = this;
    },
    onDestroy() {
    },
    validate: function () {
        var self = this;
        var oldname = this.model.attributes.Name;

        this.model.attributes.Name = this.ui.customIPAddressEditItem_inputName.val();

        var modelErrors = this.model.validateNoBackbone(app);
        if (modelErrors) {
            this.showErrors(this.model.errors);
            this.model.attributes.Name = oldname;
            return false;
        }
        else {
            this.hideErrors();
        }

        return true;
    },
    btnSubmitClick: function (event, callbackMenu) {
        if (event != null) {
            event.preventDefault();
        }
        var self = this;
        if (this.validate()) {
            this.setHasChanged(false);

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X200101;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X200025;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function () {
                this.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);

            self.app.dialogRegion.show(dialogWaiting);

            self.model.save(this.model.attributes, {
                success: function () {
                    dialogWaiting.triggerMethod('dialog:okclicked');
                    self.collection.fullCollection.sort();
                    self.collection.getFirstPage({ fetch: false });

                    if (callbackMenu != undefined) {
                        callbackMenu();
                    } else {
                        $('.modal-backdrop').remove();
                        self.app.router.navigate("#customIPAddress");
                        self.app.router.customIPAddress();
                    }
                }
            });
        }
    },
    btnCancelClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }
        self.app.router.navigate("#customIPAddress");
        self.app.router.customIPAddress();
    }
}, Backbone.Radio.Requests);