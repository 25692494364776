//import * as Backbone from 'backbone';
var Backbone = require('backbone');
import * as _ from 'underscore';

import config from '../../common/config';

export var Model = Backbone.Model.extend({
    errors: [],
    bust: function () {
        return this.mdUSERID + '?bust=' + new Date().getTime();
    },

    initialize: function () {
        var that = this;

    },
    getMenuStatus: function () {
        var ret = !this.validateNoBackbone(this.attributes);

        if (ret) {
            if (this.attributes.Address_lat == 0 || this.attributes.Address_lat == 0 ||
                this.attributes.Address_lat == null || this.attributes.Address_lat == null) {
                ret = false;
            }
        }
        return ret;
    },
    validateNoBackbone: function (attrs) {
        this.errors = [];

        if (!attrs.Name) {
            this.errors.push({ name: 'custom_inputName', message: resources.X100324 });
        }

        //if (!attrs.Contactperson_Name) {
        //    this.errors.push({ name: 'custom_inputContactperson_Name', message: resources.X100324 });
        //}

        //if (!attrs.Contactperson_Phone) {
        //    this.errors.push({ name: 'custom_inputContactperson_Phone', message: resources.X100324 });
        //}

        //if (!attrs.Contactperson_Email) {
        //    this.errors.push({ name: 'custom_inputContactperson_Email', message: resources.X100324 });
        //}

        //if (!attrs.Address01) {
        //    this.errors.push({ name: 'custom_inputAddress01', message: resources.X100324 });
        //}

        if (this.errors.length > 0) {
            return true
        }
        else {
            return false;
        }
    },
    "sync": function (method, model, options) {
        if ((method == 'read') && (options.tmpModel != undefined) && (options.tmpModel.Method == 'GetEmailUsed')) {
            options.url = config.webApiUrl() + '/api/custom/route01/-1?Email=' + options.tmpModel.Value;
        }
        else if (method == 'read') {

            var now = new Date();

            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(1);

            var utcDateTime = now.getUTCFullYear().toString() + '-' +
                (now.getUTCMonth() + 1) + '-' +
                now.getUTCDate().toString() + ' ' +
                now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();

            //options.url = config.webApiUrl() + '/api/custom/-1?AuthUserId=' + config.authUserId() + '&UtcDateTime=' + utcDateTime + '&CultureName=' + config.cultureName();
            options.url = config.webApiUrl() + '/api/custom/-1?AuthUserId=' + config.authUserId();
        }
        //else if (method == 'delete') {
        //    options.url = config.webApiUrl() + '/api/custom/' + model.attributes.id + '?mdUSERID=' + config.authUserId();
        //}
        else if (method == 'update' && options.route == 'route01') {
            options.url = config.webApiUrl() + '/api/custom/route01/' + model.attributes.id + '?Dummy=1&AuthUserId=' + config.authUserId();
        }
        else if (method == 'update' && options.route == 'route04') {
            options.url = config.webApiUrl() + '/api/custom/route04/' + model.attributes.id + '?Dummy01=1&Dummy02=2&AuthUserId=' + config.authUserId();
        }
        else if (method == 'update') {
            options.url = config.webApiUrl() + '/api/custom/route02/' + model.attributes.id + '?AuthUserId=' + config.authUserId();
        }
        else if (method == 'create') {
            options.url = config.webApiUrl() + '/api/custom/-1?AuthUserId=' + config.authUserId();
        }
        else {
            options.url = '';
        }

        var m = Backbone.sync(method, model, options);

        return m;
    },
    save: function (attributes, options) {
        Backbone.Model.prototype.save.call(this, attributes, options);
    },
    successOnSave: function (model, response) {
        model = response;
    },
    errorOnSave: function (model, response) {
        alert("Error");
    }
});

