﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import * as bootstrap_switch from 'bootstrap-switch';

import tpl_rss from './tpl/qrcode.html';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Module from './module';

import * as Entities_Empty from '../../../lib/entities/empty';

import helper_createWebpage from '../../helper/createWebpage';

import View_ModuleItem from './moduleItem';

export default View_ModuleItem.extend({
    getTemplate: function () {
        return tpl_rss();
    },
    regions: {
        'modal': '#modal'
    },
    initialize: function () {
        var self = this;
        this.app = this.options.app;

        this.listenTo(this.model, 'setupSetting', this.setupSetting, this);

        this.listenTo(this.model, 'change', this.setupSetting, this);

        var userChannel = Backbone.Radio.channel('app');
        this.listenTo(userChannel, 'rightContent:setupCssStyle', function () {
            this.redrawQRCode();
            
        });
    },
    ui: {
        'setting_margin': '#setting_margin',
        'module_margin': '#module_margin',
        'qrcode': '#qrcode',
        'qrcode_img': '#qrcode_img',
        'text': '#text',
        'divStamp': '#divStamp',
        'qrcode_qrCodeContainer_2': '#qrcode_qrCodeContainer_2',
        'qrcode_container_2': '#qrcode_container_2',
        'qrcode_qrCodeLogo_2': '#qrcode_qrCodeLogo_2',
        'qrcode_img_OrgImage_2': '#qrcode_img_OrgImage_2'
    },
    updateLogo: function (event) {
        var self = this;

        if (this.model.attributes.Data.SetDefaultImage == 1 && this.model.attributes.Data.OrgImage == "") {
            this.ui.qrcode_img_OrgImage_2.off("load");
            this.ui.qrcode_img_OrgImage_2.on('load', function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                // Set width and height
                canvas.width = self.ui.qrcode_img_OrgImage_2[0].width;
                canvas.height = self.ui.qrcode_img_OrgImage_2[0].height;
                // Draw the image
                ctx.drawImage(self.ui.qrcode_img_OrgImage_2[0], 0, 0);
                var ret = canvas.toDataURL('image/png');

                self.model.attributes.Data.OrgImage = ret;
                self.model.attributes.Data.Image = ret;

                self.ui.qrcode_img_OrgImage_2.off("load");

                self.redrawQRCode();
            });
            this.ui.qrcode_img_OrgImage_2.attr("src", this.model.attributes.Data.DefaultImage);
        } 

        /*var self = this;
        this.ui.qrcode_qrCodeLogo_2.off("load");
        this.ui.qrcode_qrCodeLogo_2.on('load', function () {
            self.redrawQRCode();
            self.ui.qrcode_qrCodeLogo_2.off("load");
        });
        this.ui.qrcode_qrCodeLogo_2.attr("src", this.model.attributes.Data.Image);*/
    },
    redrawQRCode: function () {
        var self = this;
        this.ui.qrcode_container_2.empty();


        var tmpModel = new Entities_Empty.Model(this.model.attributes.Data);

        this.ui.qrcode_img_OrgImage_2.off("load");
        this.ui.qrcode_img_OrgImage_2.on('load', function () {
            var tmpModelQRCode = new Entities_Empty.Model();

            var tmpModelQRCode = helper_createWebpage.createQRCodeImageInfo(tmpModel, tmpModelQRCode, self.ui.qrcode_img_OrgImage_2, self.ui.qrcode_qrCodeContainer_2, false, self.app);

            self.model.attributes.Data.ImageData = tmpModelQRCode.attributes.ImageData;
            self.model.attributes.Data.ImageMimeType = tmpModelQRCode.attributes.ImageMimeType;
            self.setupStyle();
        });
        this.ui.qrcode_img_OrgImage_2.attr("src", this.model.attributes.Data.Image);
    },
    events: {
    },
    onRender: function () {
        this.setupSetting();
    },
    setupSetting() {
        this.setupMargin();
        //this.setupStyle();
        this.redrawQRCode();
    },
    onAttach() {
        var self = this;
        var nowLocalDate = new Date();
        this.editorClass = nowLocalDate.getFullYear().toString() +
            (nowLocalDate.getMonth() + 1).toString() +
            nowLocalDate.getDate().toString() +
            nowLocalDate.getHours().toString() +
            nowLocalDate.getMinutes().toString() +
            nowLocalDate.getSeconds().toString() +
            nowLocalDate.getMilliseconds().toString();

        this.ui.divStamp.addClass(this.editorClass);
        this.searchElement = $('.' + this.editorClass)[0];

        

        if (this.model.attributes.Data.ImageData == "") {
            this.setupMargin();
            this.updateLogo();
        } else {
            this.setupSetting();
        }
    },
    onDestroy: function () {
    },
    setupStyle: function () {
        var self = this;



        this.ui.qrcode_img.attr('src', this.model.attributes.Data.ImageData);

        //this.ui.qrcode.find('.mydate').empty();
        //this.ui.qrcode.find('.mydate').html(html.attributes.Html);

        this.ui.qrcode.find('.myqrcode').css('margin-top', this.model.attributes.Data.Margin1_Top + 'px');
        this.ui.qrcode.find('.myqrcode').css('margin-bottom', this.model.attributes.Data.Margin1_Bottom + 'px');
        this.ui.qrcode.find('.myqrcode').css('margin-left', this.model.attributes.Data.Margin1_Left + 'px');
        this.ui.qrcode.find('.myqrcode').css('margin-right', this.model.attributes.Data.Margin1_Right + 'px');


        this.ui.qrcode.find('.mytext').css('margin-top', this.model.attributes.Data.Margin2_Top + 'px');
        this.ui.qrcode.find('.mytext').css('margin-bottom', this.model.attributes.Data.Margin2_Bottom + 'px');
        this.ui.qrcode.find('.mytext').css('margin-left', this.model.attributes.Data.Margin2_Left + 'px');
        this.ui.qrcode.find('.mytext').css('margin-right', this.model.attributes.Data.Margin2_Right + 'px');

        var font2_textAlign = this.app.entities.collections.TextAlign.where({
            Value: this.model.attributes.Data.Font2_TextAlign
        });

        font2_textAlign[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.text.css(entry.Css, entry.Value);
        });

        switch (this.model.attributes.Data.EnableTextField) {
            case 0: this.ui.text.css('display', 'none'); break;
            case 1: this.ui.text.show(); break;
        }

        this.ui.qrcode.find('.qrcode_text').css('font-family', this.model.attributes.Data.Font2);
        this.ui.qrcode.find('.qrcode_text').css('font-size', this.model.attributes.Data.Font2_Size);
        this.ui.qrcode.find('.qrcode_text').css('color', this.model.attributes.Data.Font2_Color);
        this.ui.qrcode.find('.qrcode_text').text(this.model.attributes.Data.TextField);


        var font2_style = this.app.entities.collections.FontStyle.where({
            Value: this.model.attributes.Data.Font2_Style
        });

        font2_style[0].attributes.CssStyle.forEach(function (entry) {
            self.ui.qrcode.find('.qrcode_text').css(entry.Css, entry.Value);
        });
    }
}, Backbone.Radio.Requests);
