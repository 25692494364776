﻿import * as Backbone from 'backbone';
import * as _ from 'underscore';

export var Model = Backbone.Model.extend({
    initialize: function () {
    },
});

export var Collection = Backbone.Collection.extend({
    model: Model,
    constructor: function () {
        Backbone.Collection.prototype.constructor.apply(this, arguments);
    }
});

