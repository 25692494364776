var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="copyTemplate_empty"> <div class="modal fade" id="copyTemplate_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> <div class="modal-content modal-dialog"> <div class="modal-header"> <h5 class="modal-title">'+
((__t=( model.Title ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div> <div class="modal-body"> <div class="control-group copyTemplate_selectWebpage"> <div class="form-group"> <label id="copyTemplate_labelselectWebpage" for="Name">'+
((__t=( resources['X100148'] ))==null?'':__t)+
'</label> <div class="row"> <div class="col-md-10"> <select class="form-control" type="text" id="copyTemplate_selectWebpage"></select> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="copyTemplate_btn01" class="btn btn-primary btn-sm">'+
((__t=( model.Button01 ))==null?'':__t)+
'</button> <button type="button" id="copyTemplate_btn02" class="btn btn-secondary btn-sm">'+
((__t=( model.Button02 ))==null?'':__t)+
'</button> </div> </div> </div> </div>';
}
return __p;
};
