var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="empty" style="margin-top:3px"> <div class="row"> <div class="control-label col-md-12 eventsNoMargen"> <label class="control-label" for="'+
((__t=( model.RefId ))==null?'':__t)+
'">'+
((__t=( model.Field ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-12 eventsNoMargen"> <input class="form-control" id="'+
((__t=( model.RefId ))==null?'':__t)+
'" value=""> </div> </div> </div>';
}
return __p;
};
