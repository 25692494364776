﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import * as nouislider from 'nouislider';


import * as plugins_jquery_fontselect from '../../plugins/jquery.fontselect'

import tpl_customWebpageEditItemRightContent from './tpl/customWebpageEditItemRightContent.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_DialogBox from '../../../lib/views/dialogBox/dialogBox';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import View_DialogPaintStrap from '../dialogPaintStrap/dialogPaintStrap';
import View_DialogPreviewWebpage from '../dialogPreview/dialogPreviewWebpage';
import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import * as Entities_Empty from '../../../lib/entities/empty';

import * as Entities_customWebpageItem from '../../entities/customWebpageItem';
import * as Entities_CustomWebpageItem from '../../entities/customWebpageItem';
import * as Entities_CustomBuilder from '../../entities/customBuilder';

import View_CustomWebpageEditItemList from './customWebpageEditItemList';
import View_CustomWebpageEditItemListTop from './customWebpageEditItemListTop';
import View_CustomWebpageEditItemListBottom from './customWebpageEditItemListBottom';


import config from '../../../common/config';
import Help from '../../helper/help';

export default View_LayoutError.extend({
    className: 'row',
    getTemplate: function () {
        return tpl_customWebpageEditItemRightContent({ model: this.model.toJSON() });
    },
    regions: {
        customWebpageEditItemListTop: "#customWebpageEditItemListTop",
        customWebpageEditItemList: "#customWebpageEditItemList",
        customWebpageEditItemListBottom: "#customWebpageEditItemListBottom",
    },
    onBeforeShow: function () {
        var self = this;

        this.customWebpageEditItemListTop.show(new View_CustomWebpageEditItemListTop({ collection: this.collection, app: this.app, childViewContainer: '#customWebpageEditItemListTop' }));
        this.customWebpageEditItemList.show(new View_CustomWebpageEditItemList({ collection: this.collection, app: this.app, childViewContainer: '#customWebpageEditItemList' }));
        this.customWebpageEditItemListBottom.show(new View_CustomWebpageEditItemListBottom({ collection: this.collection, app: this.app, childViewContainer: '#customWebpageEditItemListBottom' }));
    },
    initialize: function () {
        this.app = this.options.app;
        this.localSetting = new Entities_Empty.Model();

        var userChannel = Backbone.Radio.channel('app');
        var self = this;

        this.setHasChanged(false);
    },
    help: function () {
        Help.help(this, true, 'customWebpageEditItemTopbar');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmit(null, false, true, callback, false);
    },
    cancelHasChanged: function () {
    },
    modelHasChanged: function () {
        var changes = false;
        this.setHasChanged(changes);
    },
    ui: {
        'customWebpageEditItemRightContent_select_BGType': '#customWebpageEditItemRightContent_select_BGType',
        'customWebpageEditItemRightContent_btnImage': '#customWebpageEditItemRightContent_btnImage',
        'customWebpageEditItemRightContent_inputImage': '#customWebpageEditItemRightContent_inputImage',
        'customWebpageEditItemRightContent_image_background': '#customWebpageEditItemRightContent_image_background',
        'customWebpageEditItemRightContent_btnDeleteImage': '#customWebpageEditItemRightContent_btnDeleteImage',
        'customWebpageEditItemRightContent_ColorPickerSliders_BGColor': '#customWebpageEditItemRightContent_ColorPickerSliders_BGColor',
        'customWebpageEditItemRightContent_btnClearBGColor': '#customWebpageEditItemRightContent_btnClearBGColor',
        'customWebpageEditItemRightContent_inputImage': '#customWebpageEditItemRightContent_inputImage',
        'customWebpageEditItemRightContent_dropDown_Font1': '#customWebpageEditItemRightContent_dropDown_Font1',
        'customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color': '#customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color',
        'customWebpageEditItemRightContent_dropDown_Font_Size1': '#customWebpageEditItemRightContent_dropDown_Font_Size1',
        'customWebpageEditItemRightContent_dropDown_Font_LineHeight1': '#customWebpageEditItemRightContent_dropDown_Font_LineHeight1',
        'customWebpageEditItemRightContent_dropDown_Font2': '#customWebpageEditItemRightContent_dropDown_Font2',
        'customWebpageEditItemRightContent_dropDown_Font_Style1': '#customWebpageEditItemRightContent_dropDown_Font_Style1',
        'customWebpageEditItemRightContent_ColorPickerSliders_Font2_Color': '#customWebpageEditItemRightContent_ColorPickerSliders_Font2_Color',
        'customWebpageEditItemRightContent_dropDown_Font_Size2': '#customWebpageEditItemRightContent_dropDown_Font_Size2',
        'customWebpageEditItemRightContent_dropDown_Font_Style2': '#customWebpageEditItemRightContent_dropDown_Font_Style2',
        'customWebpageEditItemRightContent_dropDown_Font_LineHeight2': '#customWebpageEditItemRightContent_dropDown_Font_LineHeight2',
        'customWebpageEditItemRightContent_dropDown_Font3': '#customWebpageEditItemRightContent_dropDown_Font3',
        'customWebpageEditItemRightContent_ColorPickerSliders_Font3_Color': '#customWebpageEditItemRightContent_ColorPickerSliders_Font3_Color',
        'customWebpageEditItemRightContent_dropDown_Font_Size3': '#customWebpageEditItemRightContent_dropDown_Font_Size3',
        'customWebpageEditItemRightContent_dropDown_Font_Style3': '#customWebpageEditItemRightContent_dropDown_Font_Style3',
        'customWebpageEditItemRightContent_dropDown_Font_LineHeight3': '#customWebpageEditItemRightContent_dropDown_Font_LineHeight3',
        'customWebpageEditItemRightContent_checkbox_EnableWindowPage': '#customWebpageEditItemRightContent_checkbox_EnableWindowPage',

        'demoBasic': '#demoBasic',
        'font1': '#font1'
    },
    events: {
        'colorpickerChange @ui.customWebpageEditItemRightContent_ColorPickerSliders_BGColor': 'colorChange_BGColor',
        'change @ui.customWebpageEditItemRightContent_btnImage': 'btnImageChange',
        'click @ui.customWebpageEditItemRightContent_btnDeleteImage': 'btnImageDelete',
        'change @ui.customWebpageEditItemRightContent_select_BGType': 'modifyRest',

        'colorpickerChange @ui.customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color': 'colorChange_Font1Color',
        'colorpickerChange @ui.customWebpageEditItemRightContent_ColorPickerSliders_Font2_Color': 'colorChange_Font2Color',
        'change @ui.customWebpageEditItemRightContent_dropDown_Font_Size1': 'modifyRest',
        'change @ui.customWebpageEditItemRightContent_dropDown_Font_Size2': 'modifyRest',
        'change @ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight1': 'modifyRest',
        'change @ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight2': 'modifyRest',
        'change @ui.customWebpageEditItemRightContent_dropDown_Font_Style1': 'modifyRest',
        'change @ui.customWebpageEditItemRightContent_dropDown_Font_Style2': 'modifyRest',
        'change @ui.customWebpageEditItemRightContent_checkbox_EnableWindowPage': 'modifyRest',
    },
    onRender: function () {
        this.setupFont();

        var checked = this.model.attributes.EnableWindowPage == 1 ? true : false;
        this.ui.customWebpageEditItemRightContent_checkbox_EnableWindowPage.attr('checked', checked);
    },
    onAttach() {
        var self = this;

        var fonts = [];

        self.app.entities.collections.Font.forEach(function (entry) {
            fonts.push(entry.attributes.data_font_family);
        });

        this.ui.customWebpageEditItemRightContent_ColorPickerSliders_BGColor.colorpicker({
            color: self.model.attributes.BGColor,
            format: "rgb"
        });

        this.ui.customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color.colorpicker({
            color: self.model.attributes.Font1_Color,
            format: "rgb"
        });

        this.ui.customWebpageEditItemRightContent_dropDown_Font1.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.attributes.Font1 = this.value;
        });
        this.ui.customWebpageEditItemRightContent_dropDown_Font1.trigger('setFont', self.model.attributes.Font1);

        this.ui.customWebpageEditItemRightContent_ColorPickerSliders_Font2_Color.colorpicker({
            color: self.model.attributes.Font2_Color,
            format: "rgb"
        });

        this.ui.customWebpageEditItemRightContent_dropDown_Font2.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.attributes.Font2 = this.value;
        });
        this.ui.customWebpageEditItemRightContent_dropDown_Font2.trigger('setFont', self.model.attributes.Font2);

        this.ui.customWebpageEditItemRightContent_ColorPickerSliders_Font3_Color.colorpicker({
            color: self.model.attributes.Font3_Color,
            format: "rgb"
        });

        this.ui.customWebpageEditItemRightContent_dropDown_Font3.fontselect({
            googleFonts: fonts
        }).on('change', function () {
            self.model.attributes.Font3 = this.value;
        });
        this.ui.customWebpageEditItemRightContent_dropDown_Font3.trigger('setFont', self.model.attributes.Font3);


        //var width = this.ui.customWebpageEditItemRightContent_dropDown_Font1.css('width');
        $('.font-select').css('width', '100%');

        self.setupBGType();
        self.setupFontSize();
        self.setupFontStyle();
        self.setupFontLineHeight();
        self.setupImage();

        //customWebpageEditItemRightContent_ColorPickerSliders_Font1_Color
    },
    setupBGType: function () {
        var self = this;

        this.ui.customWebpageEditItemRightContent_select_BGType.empty();

        var selected = '';

        if (self.model.attributes.BackGroundType == "0") {
            selected = 'selected = "selected"';
        }

        var options = String.format('<option {1} value="0">{0}</option>', resources.X101329, selected);
        this.ui.customWebpageEditItemRightContent_select_BGType.append(options);

        selected = '';

        if (self.model.attributes.BackGroundType == "1") {
            selected = 'selected = "selected"';
        }

        var options = String.format('<option {1} value="1">{0}</option>', resources.X101330, selected);
        this.ui.customWebpageEditItemRightContent_select_BGType.append(options);
    },
    setupFontSize: function () {
        var self = this;

        this.ui.customWebpageEditItemRightContent_dropDown_Font_Size1.empty();
        this.ui.customWebpageEditItemRightContent_dropDown_Font_Size2.empty();
        //this.ui.customWebpageEditItemRightContent_dropDown_Font_Size3.empty();

        var selected = '';

        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font1_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customWebpageEditItemRightContent_dropDown_Font_Size1.append(options);
        }
        for (var i = 10; i <= 200; i++) {
            selected = '';

            if (self.model.attributes.Font2_Size == String.format('{0}{1}', i, 'px')) {
                selected = 'selected = "selected"';
            }

            var options = String.format('<option {1}>{0}px</option>', i, selected);
            this.ui.customWebpageEditItemRightContent_dropDown_Font_Size2.append(options);
        }
        //for (var i = 10; i <= 200; i++) {
        //    selected = '';

        //    if (self.model.attributes.Font3_Size == String.format('{0}{1}', i, 'px')) {
        //        selected = 'selected = "selected"';
        //    }

        //    var options = String.format('<option {1}>{0}px</option>', i, selected);
        //    this.ui.customWebpageEditItemRightContent_dropDown_Font_Size3.append(options);
        //}
    },
    setupFontStyle: function () {
        var self = this;
        self.ui.customWebpageEditItemRightContent_dropDown_Font_Style1.empty();
        self.ui.customWebpageEditItemRightContent_dropDown_Font_Style2.empty();
        //self.ui.customWebpageEditItemRightContent_dropDown_Font_Style3.empty();

        var selected = '';

        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customWebpageEditItemRightContent_dropDown_Font_Style1.append(options);
        });
        self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_Style == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customWebpageEditItemRightContent_dropDown_Font_Style2.append(options);
        });
        /*self.app.entities.collections.FontStyle.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_Style == entry.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.Name, entry.Value, selected);
            self.ui.customWebpageEditItemRightContent_dropDown_Font_Style3.append(options);
        });*/

    },
    setupFontLineHeight: function () {
        var self = this;
        self.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight1.empty();
        self.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight2.empty();
        //self.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight3.empty();

        var selected = '';

        self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font1_LineHeight == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight1.append(options);
        });
        self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font2_LineHeight == entry.attributes.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.attributes.Name, entry.attributes.Value, selected);
            self.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight2.append(options);
        });
        /*self.app.entities.collections.LineHeight.forEach(function (entry) {
            selected = '';
            if (self.model.attributes.Font3_LineHeight == entry.Value) {
                selected = 'selected = "selected"';
            }
            var options = String.format('<option value="{1}" {2}>{0}</option>', entry.Name, entry.Value, selected);
            self.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight3.append(options);
        });*/
    },
    setupImage: function () {
        if (this.model.attributes.BGImage != '') {
            this.ui.customWebpageEditItemRightContent_image_background.attr('src', this.model.attributes.BGImage);
        }
    },
    setupFont: function () {
        this.ui.customWebpageEditItemRightContent_dropDown_Font1.empty();
        var self = this;
        var i = 0;
        self.app.entities.collections.Font.forEach(function (entry) {
            i++;

            if (i < 100) {
                var options = String.format("<option data-content=\"<span style=\"font-family:'{0}'\" class='text'>{1}</span>\">{1}</option>", entry.image, entry.data_font_family);
                self.ui.customWebpageEditItemRightContent_dropDown_Font1.append(options);
            }
        });

        this.ui.customWebpageEditItemRightContent_dropDown_Font2.empty();
        var self = this;
        var i = 0;
        self.app.entities.collections.Font.forEach(function (entry) {
            i++;

            if (i < 100) {
                var options = String.format("<option data-content=\"<span style=\"font-family:'{0}'\" class='text'>{1}</span>\">{1}</option>", entry.image, entry.data_font_family);
                self.ui.customWebpageEditItemRightContent_dropDown_Font2.append(options);
            }
        });
    },
    colorChange_BGColor: function (e) {
        this.model.attributes.BGColor = e.value.toRgbString();
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf(".");
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1, filename.length).toLowerCase();

            switch (ext) {
                case 'gif': return true; break;
                case 'jpg': return true; break;
                case 'png': return true; break;
                case 'bmp': return true; break;
                case 'tif': return true; break;
                case 'tiff': return true; break;
            }
        }

        return false;
    },
    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();

        if (this.validateExt(filename)) {

            //remove extension
            var dotPosition = filename.lastIndexOf(".");
            if (dotPosition > 0) {
                filename = filename.substring(0, dotPosition);
            }

            this.file = FileAPI.getFiles(event)[0];

            if (this.file) {


                if (this.file.size < 40000000) {

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        self.ui.customWebpageEditItemRightContent_image_background.attr('src', e.target.result);
                        self.model.attributes.BGImage = e.target.result;

                    }
                    reader.readAsDataURL(this.file);
                }
            }
        }
    },
    btnImageDelete: function (event) {
        this.ui.customWebpageEditItemRightContent_image_background.attr('src', '');
        self.model.attributes.BGImage = "";
    },
    colorChange_Font1Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font1_Color = e.value;
        }
        else {
            this.model.attributes.Font1_Color = e.value.toRgbString();
        }
        this.updateAll();
    },
    colorChange_Font2Color: function (e) {
        if (typeof e.value === 'string') {
            this.model.attributes.Font2_Color = e.value;
        }
        else {
            this.model.attributes.Font2_Color = e.value.toRgbString();
        }
        
        this.updateAll();
    },
    modifyRest: function () {
        this.model.attributes.BackGroundType = this.ui.customWebpageEditItemRightContent_select_BGType.val();

        this.model.attributes.Font1_Size = this.ui.customWebpageEditItemRightContent_dropDown_Font_Size1.val();
        this.model.attributes.Font2_Size = this.ui.customWebpageEditItemRightContent_dropDown_Font_Size2.val();

        this.model.attributes.Font1_LineHeight = this.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight1.val();
        this.model.attributes.Font2_LineHeight = this.ui.customWebpageEditItemRightContent_dropDown_Font_LineHeight2.val();

        this.model.attributes.Font1_Style = this.ui.customWebpageEditItemRightContent_dropDown_Font_Style1.val();
        this.model.attributes.Font2_Style = this.ui.customWebpageEditItemRightContent_dropDown_Font_Style2.val();

        var EnableWindowPage = this.model.attributes.EnableWindowPage;
        this.ui.customWebpageEditItemRightContent_checkbox_EnableWindowPage.is(':checked') ? EnableWindowPage = 1 : EnableWindowPage = 0;
        this.model.attributes.EnableWindowPage = EnableWindowPage;
    },
    updateAll: function () {
        /*this.model.trigger('change');

        var userChannel = Backbone.Radio.channel('app');
        userChannel.trigger('InfoPageSetting:change');*/
    },
}, Backbone.Radio.Requests);
