var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customEventPushListItem_empty"> <div class="pt-3 pb-3 border-bottom"> <div class="row"> <div class="col-lg-3 col-md-3 col-sm-3 border-right-lg border-right-md-0"> <div class="d-flex justify-content-center align-items-center"> <h1 class="mb-0 mr-2 text-primary font-weight-normal">'+
((__t=( model.TmpDate ))==null?'':__t)+
'</h1> <div> <p class="font-weight-bold mb-0 text-dark">'+
((__t=( model.TmpMonth ))==null?'':__t)+
'</p> <p class="mb-0">'+
((__t=( model.TmpYear ))==null?'':__t)+
'</p> </div> </div> </div> <div class="col-lg-7 col-md-8 col-sm-8 pl-3"> <p class="text-dark font-weight-bold mb-0">'+
((__t=( model.TmpMessage ))==null?'':__t)+
'</p> <p class="mb-0">'+
((__t=( model.TmpTime ))==null?'':__t)+
'</p> </div> <div class="col-lg-1 col-md-1 col-sm-1 pl-3"> <div class=""><i id="customEventPushListItem_btnResend" style="color: #6c7293 !important" class="fa fa-lg fa-1x fa-comments-o font-weight-bold px-1 py-1 text-info mdi-24px" data-toggle="tooltip" data-placement="right" data-original-title="Basic tooltip"></i></div> </div> <div class="col-lg-1 col-md-1 col-sm-1 pl-3"> <div class=""><i id="customEventPushListItem_btnDelete" style="color: #6c7293 !important" class="far fa-lg fa-1x fa-times-circle px-1 py-1 text-info mdi-24px"></i></div>  </div> </div> </div> </div>';
}
return __p;
};
