var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="rss_empty"> <div class="modal fade" id="rss_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> <div class="modal-dialog modal-lg" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="exampleModalLabel">'+
((__t=( resources['X101369'] ))==null?'':__t)+
'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion-1"> <ul class="nav nav-tabs" role="tablist"> <li class="nav-item"> <a class="nav-link active" id="home-tab-1" data-toggle="tab" href="#home-1" role="tab" aria-controls="home-1" aria-selected="true">'+
((__t=( resources['X101392'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home-2" aria-selected="false">'+
((__t=( resources['X101393'] ))==null?'':__t)+
'</a> </li> <li class="nav-item"> <a class="nav-link" id="home-tab-3" data-toggle="tab" href="#home-3" role="tab" aria-controls="home-3" aria-selected="false">'+
((__t=( resources['X101394'] ))==null?'':__t)+
'</a> </li> </ul> <div class="tab-content"> <div class="tab-pane fade show active" id="home-1" role="tabpanel" aria-labelledby="home-tab-1"> <div class="card"> <div class="card-body"> <div class="row"> <div class="control-label col-md-6"> <label for="rss_selectFeedType">'+
((__t=( resources['X101373'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="rss_checkboxFeedType"> <span class="toggle-slider round"></span> </label> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_dropDown_feedProvider">'+
((__t=( resources['X101370'] ))==null?'':__t)+
'</label> <select id="rss_dropDown_feedProvider" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group rss_input_feedUrl"> <label for="rss_input_feedUrl">'+
((__t=( resources['X101374'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.FeedUrl))==null?'':__t)+
'" id="rss_input_feedUrl"> <span class="help-inline" style="color:red"></span> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_dropDown_feedCount">'+
((__t=( resources['X101371'] ))==null?'':__t)+
'</label> <select id="rss_dropDown_feedCount" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_input_feedSpeed">'+
((__t=( resources['X101372'] ))==null?'':__t)+
'</label> <div id="rss_input_feedSpeed" class="ul-slider slider-success" style="height: 20px"></div> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-2" role="tabpanel" aria-labelledby="home-tab-2"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_select_Font_FontType">'+
((__t=( resources['X101390'] ))==null?'':__t)+
'</label> <select id="rss_select_Font_FontType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <label style="font-size:12px" class="form-group" for="rss_dropDown_Font">'+
((__t=( resources['X101324'] ))==null?'':__t)+
'</label> <div id="rss_formgroup_Font" class="form-group"> <input id="rss_dropDown_Font" class="form-control"> </div> </div> </div> <div class="row"> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_select_Font_ColorType">'+
((__t=( resources['X101362'] ))==null?'':__t)+
'</label> <select id="rss_select_Font_ColorType" class="form-control"></select> </div> </div> <div class="col-6 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_ColorPickerSliders_Font_Color">'+
((__t=( resources['X101323'] ))==null?'':__t)+
'</label> <div id="rss_ColorPickerSliders_Font_Color" class="input-group" title="Using data-color attribute in the colorpicker element"> <input class="form-control input-lg"> <span class="input-group-append"> <span class="input-group-text colorpicker-input-addon"><i></i></span> </span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_dropDown_Font_Size">'+
((__t=( resources['X101325'] ))==null?'':__t)+
'</label> <select id="rss_dropDown_Font_Size" class="form-control"></select> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group"> <label for="rss_dropDown_Font_Style">'+
((__t=( resources['X101326'] ))==null?'':__t)+
'</label> <select id="rss_dropDown_Font_Style" class="form-control"></select> </div> </div> </div> </div> </div> </div> <div class="tab-pane fade show" id="home-3" role="tabpanel" aria-labelledby="home-tab-3"> <div class="card"> <div class="card-body"> <div class="row"> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Top"> <div class="control-label"> <label for="module_input_ModuleMargin_Top">'+
((__t=( resources['X100921'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Top))==null?'':__t)+
'" id="module_input_ModuleMargin_Top"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Bottom"> <div class="control-label"> <label for="module_input_ModuleMargin_Bottom">'+
((__t=( resources['X100922'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Bottom))==null?'':__t)+
'" id="module_input_ModuleMargin_Bottom"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Left"> <div class="control-label"> <label for="module_input_ModuleMargin_Left">'+
((__t=( resources['X100919'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Left))==null?'':__t)+
'" id="module_input_ModuleMargin_Left"> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6"> <div class="form-group control-group module_input_ModuleMargin_Right"> <div class="control-label"> <label for="module_input_ModuleMargin_Right">'+
((__t=( resources['X100920'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.ModuleMargin_Right))==null?'':__t)+
'" id="module_input_ModuleMargin_Right"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" id="rss_btn01" class="btn btn-success">'+
((__t=( resources['X100279'] ))==null?'':__t)+
'</button> <button type="button" id="rss_btn02" class="btn btn-light" data-dismiss="modal">'+
((__t=( resources['X100280'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </div>';
}
return __p;
};
