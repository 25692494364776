﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';
import dummy from '../../app/plugins';

//No internet
require('typeahead-addresspicker');

import tpl_custom from './tpl/custom.html';
import language from '../../../common/language';
import View_LayoutError from '../../../lib/views/layoutError/layoutError';
import View_Information from '../../../lib/views/information/information';
import * as Entities_Empty from '../../../lib/entities/empty';

import View_DialogWaiting from '../../../lib/views/dialogWaiting/dialogWaiting';
import helper_createWebpage from '../../helper/createWebpage';
import config from '../../../common/config';
import Help from '../../helper/help';
import View_Dialog2Button from '../../../lib/views/dialog2Button/dialog2Button';
import EventUpdate from '../../helper/eventUpdate';
import View_CustomHeader from '../region/customHeader/customHeader';


export default View_LayoutError.extend({
    getTemplate: function () {
        return tpl_custom({ model: this.model.toJSON() });
    },
    attributes: function () {
        return {
            style: "",
        }
    },
    initialize: function () {
        self = this;

        this.app = this.options.app;

        this.informationCollection = new Entities_Empty.Collection();

        this.setHasChanged(false);
        this.oldModel = this.model.clone();
    },
    help: function () {
        Help.help(this, true, 'custom');
    },
    setHasChanged: function (enable) {
        this.hasChanged = enable;
    },
    getHasChanged: function () {
        return this.hasChanged;
    },
    saveHasChanged: function (callback) {
        this.btnSubmitClick();
        callback();
    },
    cancelHasChanged: function () {
        this.model.attributes = this.oldModel.attributes;
    },
    modelHasChanged: function () {
        var changes = false;

        this.setModel();

        //If culture has change do not ask to save when goes to another menu item
        if ((this.model.attributes.Name == this.oldModel.attributes.Name) &&
            (this.model.attributes.Contactperson_Name == this.oldModel.attributes.Contactperson_Name) &&
            (this.model.attributes.Contactperson_Phone == this.oldModel.attributes.Contactperson_Phone) &&
            (this.model.attributes.Contactperson_Email == this.oldModel.attributes.Contactperson_Email) &&
            (this.model.attributes.CultureName == this.oldModel.attributes.CultureName) &&
            (this.model.attributes.Address01 == this.oldModel.attributes.Address01) &&
            (this.model.attributes.Address01Supplement == this.oldModel.attributes.Address01Supplement) &&
            (this.model.attributes.Address02 == this.oldModel.attributes.Address02) &&
            (this.model.attributes.Address02Supplement == this.oldModel.attributes.Address02Supplement)) {
            changes = false;
            this.btnSubmitClick();
        } else if ((this.model.attributes.Name != this.oldModel.attributes.Name) ||
            (this.model.attributes.Contactperson_Name == this.oldModel.attributes.Contactperson_Name) &&
            (this.model.attributes.Contactperson_Phone == this.oldModel.attributes.Contactperson_Phone) &&
            (this.model.attributes.Contactperson_Email == this.oldModel.attributes.Contactperson_Email) &&
            (this.model.attributes.Address01 == this.oldModel.attributes.Address01) &&
            (this.model.attributes.Address01Supplement == this.oldModel.attributes.Address01Supplement) &&
            (this.model.attributes.Address02 == this.oldModel.attributes.Address02) &&
            (this.model.attributes.Address02Supplement == this.oldModel.attributes.Address02Supplement)) {
            changes = true;
        }

        this.setHasChanged(changes);
    },
    test: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: "TEST" });
        this.informationCollection.add(tmpModel);
        this.informationCollection.trigger('change');
    },
    test2: function () {
        this.informationCollection.reset();

        var tmpModel = new this.informationCollection.model({ Information: "TEST" });
        this.informationCollection.add(tmpModel);
        this.informationCollection.trigger('change');
    },
    regions: {
        information: "#customInformation"
    },
    onBeforeShow: function () {
        this.information.show(new View_Information({ collection: this.informationCollection, app: this.app }));
    },
    ui: {
        'custom_btnSubmit': '#custom_btnSubmit',
        'custom_btnChangePassword': '#custom_btnChangePassword',
        'custom_btnGoogle': '#custom_btnGoogleFind',

        'custom_inputName': '#custom_inputName',
        'custom_inputContactperson_Name': '#custom_inputContactperson_Name',
        'custom_inputContactperson_Phone': '#custom_inputContactperson_Phone',
        'custom_inputContactperson_Email': '#custom_inputContactperson_Email',
        'custom_inputAddress01': '#custom_inputAddress01',
        'custom_inputAddress01_Supplement': '#custom_inputAddress01_Supplement',
        //'custom_inputAddress02': '#custom_inputAddress02',
        //'custom_inputAddress02_Supplement': '#custom_inputAddressSupplement',
        'custom_btnImage': '#custom_btnImage',
        'custom_inputImage': '#custom_inputImage',
        'custom_image_background': '#custom_image_background',
        'help': '#help'
    },
    events: {
        'click #custom_btnSubmit': 'btnSubmitClick',
        'click #custom_btnChangePassword': 'btnChangePassword',
        'click #custom_btnGoogleSubmit': 'btnGoogleFind',
        'change @ui.custom_btnImage': 'btnImageChange',
        'click #help': 'help'
    },
    onRender: function () {
    },
    onAttach() {
        var self = this;
        
        this.addressPicker01 = new AddressPicker();
        this.ui.custom_inputAddress01.typeahead(null, {
            displayKey: 'description',
            source: self.addressPicker01.ttAdapter()
        });

        this.addressPicker01.bindDefaultTypeaheadEvent(this.ui.custom_inputAddress01);
        $(this.addressPicker01).on('addresspicker:selected', function (event, parsedGeocodeResult) {
            var _parsedGeocodeResult = self._parseGeocodeResult(parsedGeocodeResult.placeResult);
            if (_parsedGeocodeResult.type != "street_address") {
            }
            else {
                self.setModel(_parsedGeocodeResult, null);
            }
            self.updateInformationCollection();
        });

        this.addressPicker02 = new AddressPicker();
        this.ui.custom_inputAddress02.typeahead(null, {
            displayKey: 'description',
            source: self.addressPicker02.ttAdapter()
        });

        this.addressPicker02.bindDefaultTypeaheadEvent(this.ui.custom_inputAddress02);
        $(this.addressPicker02).on('addresspicker:selected', function (event, parsedGeocodeResult) {
            var _parsedGeocodeResult = self._parseGeocodeResult(parsedGeocodeResult.placeResult);
            if (_parsedGeocodeResult.type != "street_address") {
            }
            else {
                self.setModel(null, _parsedGeocodeResult);
            }
            self.updateInformationCollection();
        });

        //this.setupImage();
    },
    onDestroy() {
        $(this.addressPicker01).unbind();
        $(this.addressPicker02).unbind();
    },
    renderInformation: function () {
        this.informationCollection.reset();

        //No customYet yet
        if (this.model.validateNoBackbone(this.model.attributes)) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100333 });
            this.informationCollection.add(tmpModel);
        }

        if (this.model.attributes.Address01_lat == 0 || this.model.attributes.Address01_lat == 0 ||
            this.model.attributes.Address01_lat == null || this.model.attributes.Address01_lat == null) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100287 });
            this.informationCollection.add(tmpModel);
        }

        if (this.model.attributes.Address02_lat == 0 || this.model.attributes.Address02_lat == 0 ||
            this.model.attributes.Address02_lat == null || this.model.attributes.Address02_lat == null) {
            var tmpModel = new this.informationCollection.model({ toastType: 3, header: resources.X100229, Information: resources.X100287 });
            this.informationCollection.add(tmpModel);
        }

        this.app.renderInformation({ informationCollection: this.informationCollection });
    },
    updateInformationCollection: function () {
        this.renderInformation();
        this.informationCollection.trigger('change');
    },
    btnSubmitClick: function (event) {
        if (event != null) {
            event.preventDefault();
        }

        if (this.setModel()) {
            this.save();
        }
    },
    btnChangePassword: function (event) {
        //window.location.href = config.changePasswordUrl()  + "/Identity/Account/Manage/ChangePassword?ui_locales=da-DK";
        //window.location.href = config.changePasswordUrl() + "/Identity/Account/Manage/ChangePassword?ui_locales=fr-FR&culture=fr-FR";
        window.location.href = config.changePasswordUrl() + "/Identity/Account/Manage/ChangePassword?culture=da-DK&ui-culture=da-DK&ui_locales=da-DK";

        //window.app.router.navigate("#customCrediential");
        //window.app.router.customCrediential();
    },
    setModel: function (parsedGeocodeResult01, parsedGeocodeResult02) {
        var administrative_area_level_1 = '';
        var administrative_area_level_2 = '';
        var administrative_area_level_3 = '';
        var country = '';
        var lat = 0;
        var lng = 0;
        var locality = '';
        var postal_code = '';
        var route = '';
        var street_number = '';
        var sublocality = '';

        if (parsedGeocodeResult01 != undefined) {
            if (parsedGeocodeResult01.administrative_area_level_1 != false) {
                administrative_area_level_1 = parsedGeocodeResult01.administrative_area_level_1;
            }
            if (parsedGeocodeResult01.administrative_area_level_2 != false) {
                administrative_area_level_2 = parsedGeocodeResult01.administrative_area_level_2;
            }
            if (parsedGeocodeResult01.administrative_area_level_3 != false) {
                administrative_area_level_3 = parsedGeocodeResult01.administrative_area_level_3;
            }
            if (parsedGeocodeResult01.country != false) {
                country = parsedGeocodeResult01.country;
            }
            if (parsedGeocodeResult01.lat != false) {
                lat = parsedGeocodeResult01.lat;
            }
            if (parsedGeocodeResult01.lng != false) {
                lng = parsedGeocodeResult01.lng;
            }
            if (parsedGeocodeResult01.locality != false) {
                locality = parsedGeocodeResult01.locality;
            }
            if (parsedGeocodeResult01.postal_code != false) {
                postal_code = parsedGeocodeResult01.postal_code;
            }
            if (parsedGeocodeResult01.route != false) {
                route = parsedGeocodeResult01.route;
            }
            if (parsedGeocodeResult01.street_number != false) {
                street_number = parsedGeocodeResult01.street_number;
            }
            if (parsedGeocodeResult01.sublocality != false) {
                sublocality = parsedGeocodeResult01.sublocality;
            }

            this.model.set({
                Address01: this.ui.custom_inputAddress01.val(),
                Address01_administrative_area_level_1: administrative_area_level_1,
                Address01_administrative_area_level_2: administrative_area_level_2,
                Address01_administrative_area_level_3: administrative_area_level_3,
                Address01_country: country,
                Address01_lat: lat,
                Address01_lng: lng,
                Address01_locality: locality,
                Address01_postal_code: postal_code,
                Address01_route: route,
                Address01_street_number: street_number,
                Address01_sublocality: sublocality
            });
        }

        if (parsedGeocodeResult02 != undefined) {
            if (parsedGeocodeResult02.administrative_area_level_1 != false) {
                administrative_area_level_1 = parsedGeocodeResult02.administrative_area_level_1;
            }
            if (parsedGeocodeResult02.administrative_area_level_2 != false) {
                administrative_area_level_2 = parsedGeocodeResult02.administrative_area_level_2;
            }
            if (parsedGeocodeResult02.administrative_area_level_3 != false) {
                administrative_area_level_3 = parsedGeocodeResult02.administrative_area_level_3;
            }
            if (parsedGeocodeResult02.country != false) {
                country = parsedGeocodeResult02.country;
            }
            if (parsedGeocodeResult02.lat != false) {
                lat = parsedGeocodeResult02.lat;
            }
            if (parsedGeocodeResult02.lng != false) {
                lng = parsedGeocodeResult02.lng;
            }
            if (parsedGeocodeResult02.locality != false) {
                locality = parsedGeocodeResult02.locality;
            }
            if (parsedGeocodeResult02.postal_code != false) {
                postal_code = parsedGeocodeResult02.postal_code;
            }
            if (parsedGeocodeResult02.route != false) {
                route = parsedGeocodeResult02.route;
            }
            if (parsedGeocodeResult02.street_number != false) {
                street_number = parsedGeocodeResult02.street_number;
            }
            if (parsedGeocodeResult02.sublocality != false) {
                sublocality = parsedGeocodeResult02.sublocality;
            }

            this.model.set({
                Address02: this.ui.custom_inputAddress02.val(),
                Address02_administrative_area_level_1: administrative_area_level_1,
                Address02_administrative_area_level_2: administrative_area_level_2,
                Address02_administrative_area_level_3: administrative_area_level_3,
                Address02_country: country,
                Address02_lat: lat,
                Address02_lng: lng,
                Address02_locality: locality,
                Address02_postal_code: postal_code,
                Address02_route: route,
                Address02_street_number: street_number,
                Address02_sublocality: sublocality
            });
        }

        this.model.set({
            Name: this.ui.custom_inputName.val(),
            Contactperson_Name: this.ui.custom_inputContactperson_Name.val(),
            Contactperson_Phone: this.ui.custom_inputContactperson_Phone.val(),
            Contactperson_Email: this.ui.custom_inputContactperson_Email.val(),
            Address01: this.ui.custom_inputAddress01.val(),
            Address01_Supplement: this.ui.custom_inputAddress01_Supplement.val(),
            //Address02_Supplement: this.ui.custom_inputAddress02_Supplement.val()
        });

        return true;
    },
    save: function () {
        var self = this;

        this.hideErrors();

        var ret = this.model.validateNoBackbone(this.model.attributes);
        if (ret == false) {

            var dialogWaitingModel = new Entities_Empty.Model();
            dialogWaitingModel.attributes.Title = resources.X100232;
            dialogWaitingModel.attributes.Text = resources.X200093;
            dialogWaitingModel.attributes.Button = resources.X100279;

            var dialogWaiting = new View_DialogWaiting({ model: dialogWaitingModel, parent: this });
            var callback = function (options) {
                self.stopListening(dialogWaiting, "dialog:okclicked", callback);
            }
            this.listenTo(dialogWaiting, "dialog:okclicked", callback);
            this.app.dialogRegion.show(dialogWaiting);


            this.model.save(this.model.attributes, {
                route01: false,
                success: function () {
                    dialogWaiting.triggerMethod('dialog:close');

                    var userChannel = Backbone.Radio.channel('app');
                    userChannel.trigger('Region_Header:setLoginName');
                }
            });
        } else {
            this.showErrors(this.model.errors);
        }
    },
    _parseGeocodeResult: function (geocodeResult) {
        this._addressParts = {
            street_number: null, route: null, locality: null, sublocality: null,
            administrative_area_level_3: null, administrative_area_level_2: null,
            administrative_area_level_1: null, country: null, postal_code: null, type: null
        };

        this._parseLatAndLng = function (location) {
            var longitude, latitude;

            if (typeof (location.lat) === 'function') {
                latitude = location.lat();
                longitude = location.lng();
            } else {
                latitude = location.lat;
                longitude = location.lng;
            }

            return { lat: latitude, lng: longitude };
        };

        this._findInfo = function (result, type) {
            for (var i = 0; i < result.address_components.length; i++) {
                var component = result.address_components[i];
                if (component.types.indexOf(type) != -1) {
                    return component.long_name;
                }
            }
            return false;
        };

        var parsed = this._parseLatAndLng(geocodeResult.geometry.location);

        for (var addressPart in this._addressParts) {
            parsed[addressPart] = this._findInfo(geocodeResult, addressPart);
        }

        parsed.type = geocodeResult.types[0];

        return parsed;
    },
    //setupImage: function () {
    //    if (this.model.attributes.LogoUrl != '') {
    //        this.ui.custom_image_background.attr('src', this.model.attributes.LogoUrl);
    //    }
    //},
    btnImageChange: function (event) {
        event.preventDefault();

        var self = this;

        //remove path
        var filename = event.target.value.split('\\').pop();

        if (this.validateExt(filename)) {

            //remove extension
            var dotPosition = filename.lastIndexOf(".");
            if (dotPosition > 0) {
                filename = filename.substring(0, dotPosition);
            }

            this.file = FileAPI.getFiles(event)[0];

            if (this.file) {


                if (this.file.size < 40000000) {

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        self.ui.custom_image_background.attr('src', e.target.result);
                        self.model.attributes.BGImage = e.target.result;

                    }
                    reader.readAsDataURL(this.file);
                }
            }
        }
    },
    validateExt(filename) {
        var dotPosition = filename.lastIndexOf(".");
        if (dotPosition > 0) {
            var ext = filename.substring(dotPosition + 1, filename.length).toLowerCase();

            switch (ext) {
                case 'gif': return true; break;
                case 'jpg': return true; break;
                case 'png': return true; break;
                case 'bmp': return true; break;
                case 'tif': return true; break;
                case 'tiff': return true; break;
            }
        }

        return false;
    },
}, Backbone.Radio.Requests);
