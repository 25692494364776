﻿'use strict'

import $ from 'jquery';
import * as Backbone from 'backbone';
import * as Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import * as Bootstrap from 'bootstrap';

import tpl_informationList from './tpl/informationList.html';
import View_InformationListItem from './informationListItem';

export default Marionette.CollectionView.extend({
    tagName: 'informationList',
    childViewContainer: '#informationList',
    childView: View_InformationListItem,
    getTemplate: function () {
        return tpl_informationList();
    },
    initialize: function () {
        this.listenTo(this.collection, 'change', this.render);
    },
});