var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="empty"> <div id="container" style="padding: 0px 0px 0px 0px" class="container-fluid"> <div class="row"> <div class="col-xs-12 col-md-12"> '+
((__t=( model.Content ))==null?'':__t)+
' </div> </div> <div id="public_infopageShowQRCode" style="position: fixed; bottom: 0;left: 0; background-color:white; width:200px; height:170px"> <img id="QRCodeImageUrl" style="margin-left:50px;margin-right:50px;margin-top:20px;width:100px;height:100px" alt="" src="#modelbegin# QRCodeImageUrl #modelend#"> <p id="infoTemplate_header01" style="text-align: center;margin-top:0px; margin-bottom:0px;color:black">#modelbegin# QRCodeTextInfoscreen #modelend#</p>  </div> <div id="public_copyright" style="position: fixed; bottom: 0;right: 0; background-color:white; width:200px; height:140px"> <p style="text-align: center; margin-top:30px;color:black">#modelbegin# resources[\'X100460\'] #modelend#</p> <a href="events4us.net"><img style="margin-top:0px; margin-left:21px; width:158px;height:20px" alt="" src="/images/Events4us.png"></a> <p style="margin-top:15px; text-align: center;color:black">#modelbegin# resources[\'X100076\'] #modelend#</p> </div>  </div> </div>';
}
return __p;
};
