var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="empty" style="margin-top:3px"> <div class="row"> <div class="col-xs-12 col-md-12"> <div class="row clearfix"> <div style="height:5px"></div> <div class="column full center"> <button type="button" class="btn btn-primary" id="btnSubmit">'+
((__t=( model.FieldSubmit ))==null?'':__t)+
'</button> <button type="button" class="btn btn-primary" id="btnCancel">'+
((__t=( model.FieldCancel ))==null?'':__t)+
'</button> </div> <div style="height:5px"></div> </div> </div> </div> </div>';
}
return __p;
};
