var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="customDepartmentEditItem_empty"> <div class="content-wrapper" data-help="customDepartmentEditItem"> <div class="row"> <div class="col-sm-9"> <div class="row"> <div class="col-sm-12 pr-0"> <h3 class="text-dark font-weight-bold mb-0 mr-5">'+
((__t=( resources['X200085'] ))==null?'':__t)+
'</h3> </div> </div> </div> </div> <div class="row mt-3"> <div class="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200085'] ))==null?'':__t)+
'</h4> <form class="forms-sample template-demo"> <div id="customDepartmentEditItemInformation" class="e4usInformation" style="display:none"></div> <div class="row form-group"> <div class="control-label col-md-12"> <div class="control-group customDepartmentEditItem_inputName"> <div class="form-group"> <label for="customDepartmentEditItem_inputName">'+
((__t=( resources['X200051'] ))==null?'':__t)+
'</label> <input class="form-control form-control-sm" value="'+
((__t=( model.Name))==null?'':__t)+
'" id="customDepartmentEditItem_inputName" maxlength="100"> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div>  <div class="row form-group" style="display:none"> <div class="control-label col-md-9"> <div class="control-group customDepartmentEditItem_checkbox_Module_DigitalClock"> <div class="form-group"> <div class="d-flex justify-content-between align-items-center"> <div> <label style="margin-right:10px" for="customDepartmentEditItem_checkbox_Module_DigitalClock">'+
((__t=( resources['X200172'] ))==null?'':__t)+
'</label> </div> <div> <div class="d-flex align-items-center"> <label style="text-align:right" class="toggle-switch"> <input type="checkbox" id="customDepartmentEditItem_checkbox_Module_DigitalClock"> <span class="toggle-slider round"></span> </label> </div> </div> </div> </div> </div> </div> </div> <div id="customEventEditItem_divPersonOnView" style=""> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectPersonOnView">'+
((__t=( resources['X200253'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectPersonOnView" style="font-size:12px"> <option value="10">'+
((__t=( resources['X200323'] ))==null?'':__t)+
'</option> <option value="20">'+
((__t=( resources['X200256'] ))==null?'':__t)+
'</option> <option value="30">'+
((__t=( resources['X200255'] ))==null?'':__t)+
'</option> <option value="42">'+
((__t=( resources['X200254'] ))==null?'':__t)+
'</option> <option value="110">'+
((__t=( resources['X200280'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div id="customEventEditItem_divDigitalClock" style="display:none"> <div class="row form-group"> <div class="control-label col-md-6"> <label for="customEventEditItem_selectDigitalClock">'+
((__t=( resources['X200172'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customEventEditItem_selectDigitalClock" style="font-size:12px"> <option value="0">'+
((__t=( resources['X200096'] ))==null?'':__t)+
'</option> <option value="1">'+
((__t=( resources['X200095'] ))==null?'':__t)+
'</option> </select> </div> </div> </div> </div> <div class="control-group customDepartmentEditItem_select_TimerView"> <div class="form-group"> <div class="row"> <div class="control-label col-md-6"> <label for="customDepartmentEditItem_select_TimerView">'+
((__t=( resources['X200174'] ))==null?'':__t)+
'</label> </div> <div class="control-label col-md-6"> <div class="options-box"> <select class="form-control" id="customDepartmentEditItem_select_TimerView" style="font-size:12px"> <option value="05">5</option> <option value="10">10</option> <option value="15">15</option> <option value="20">20</option> <option value="25">25</option> <option value="30">30</option> <option value="35">35</option> <option value="40">40</option> <option value="45">45</option> <option value="50">50</option> <option value="55">55</option> <option value="60">60</option> </select> <span class="help-inline" style="color:red"></span> </div> </div> </div> </div> </div> <div class="row form-group"> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnSetting"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnSetting">'+
((__t=( resources['X200328'] ))==null?'':__t)+
'</button> </div> </div> </div> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnDigitalClock"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnDigitalClock">'+
((__t=( resources['X200327'] ))==null?'':__t)+
'</button> </div> </div> </div> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnCustomDepartmentPomodoro"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnCustomDepartmentPomodoro">'+
((__t=( resources['X200286'] ))==null?'':__t)+
'</button> </div> </div> </div> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnCustomDepartmentReset"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnCustomDepartmentReset">'+
((__t=( resources['X200258'] ))==null?'':__t)+
'</button> </div> </div> </div>  </div> <div class="row form-group"> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnSubmit"> <div class="form-group"> <button type="button" class="form-control btn btn-primary btn-sm" id="customDepartmentEditItem_btnSubmit">'+
((__t=( resources['X200106'] ))==null?'':__t)+
'</button> </div> </div> </div> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnCancel"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnCancel">'+
((__t=( resources['X200110'] ))==null?'':__t)+
'</button> </div> </div> </div> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnCancel"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnRemoveImage">'+
((__t=( resources['X200374'] ))==null?'':__t)+
'</button> </div> </div> </div> <div class="control-label col-md-3"> <div class="control-group customDepartmentEditItem_btnSendPomodoroMail"> <div class="form-group"> <button type="button" class="form-control btn btn-secondary btn-sm" id="customDepartmentEditItem_btnSendPomodoroMail">'+
((__t=( resources['X200365'] ))==null?'':__t)+
'</button> </div> </div> </div> </div> </form> </div> </div> </div> <div class="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200121'] ))==null?'':__t)+
'</h4> <form class="contact-form"> <div class="row" style="margin-left:0px;margin-top:25px"> <div class="col-md-6" style="padding-left:0px"> <div class="control-group Image" style="display:none"> <div class=""> <label type="button" for="customDepartmentEdit_btnImage" class="btn btn-primary">'+
((__t=( resources['X200062'] ))==null?'':__t)+
'</label> <div id="customDepartmentEdit_div_clone"> <input id="customDepartmentEdit_btnImage" type="file" accept="image/gif, image/jpeg, image/png, image/bmp, image/tif, image/tiff" style="font-size: 20px; display: none"> </div> <br> <span class="help-inline" style="color:red"></span> </div> </div> </div> <div class="col-md-12"> <input id="customDepartmentEdit_filePond" type="file" class="filepond"> </div> </div> <div class="row"> <div class="row" style="margin-left:0px;margin-right:0px"> <div class="control-label col-md-6" style="padding-left:0px"> <div class="cropper-col cropper-img-container"> <img id="customDepartmentEdit_image" alt="" src="/images/cropper.png"> </div> <div class="cropper-col cropper-img-preview"></div> </div> </div> </div> <div class="row" style="margin-top:50px"> <div class=""> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentEdit_btn_crop_zoom_plus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200063'] ))==null?'':__t)+
'"><i class="fa fa-search-plus"></i></button> <button type="button" id="customDepartmentEdit_btn_crop_zoom_minus" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200064'] ))==null?'':__t)+
'"><i class="fa fa-search-minus"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentEdit_btn_crop_move_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200065'] ))==null?'':__t)+
'"><i class="fa fa-arrow-left"></i></button> <button type="button" id="customDepartmentEdit_btn_crop_move_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200066'] ))==null?'':__t)+
'"><i class="fa fa-arrow-right"></i></button> <button type="button" id="customDepartmentEdit_btn_crop_move_top" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200067'] ))==null?'':__t)+
'"><i class="fa fa-arrow-up"></i></button> <button type="button" id="customDepartmentEdit_btn_crop_move_buttom" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200068'] ))==null?'':__t)+
'"><i class="fa fa-arrow-down"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentEdit_btn_crop_rotate_left" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200069'] ))==null?'':__t)+
'"><i class="fa fa-undo-alt"></i></button> <button type="button" id="customDepartmentEdit_btn_crop_rotate_right" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200070'] ))==null?'':__t)+
'"><i class="fa fa-redo-alt"></i></button> </div> <div class="btn-group" role="group" aria-label="Basic example"> <button type="button" id="customDepartmentEdit_btn_crop_scale_x" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200071'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-h"></i></button> <button type="button" id="customDepartmentEdit_btn_crop_scale_y" class="btn btn-outline-secondary" data-toggle="tooltip" data-custom-class="tooltip-primary" data-placement="top" title="'+
((__t=( resources['X200072'] ))==null?'':__t)+
'"><i class="fa fa-arrows-alt-v"></i></button> </div> </div> </div> <br> </form> </div> </div> </div> </div> <div class="row"> <div class="col-12 grid-margin stretch-card"> <div class="card"> <div class="card-body"> <h4 class="card-title">'+
((__t=( resources['X200116'] ))==null?'':__t)+
'</h4> <div class="" id="customDepartmentUser"></div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
